import { defineComponent } from "vue";
import appStore, { AUTH_ACTIONS, APP_ACTIONS } from "../../../store/app";
//import ClickOutside from 'vue-click-outside'

export default defineComponent({
  props: {
    compts: Function,
    isShowLeftMenu: Boolean,
    hideMenu: Function,
  },
  // directives: {
  //     ClickOutside
  // },
  data() {
    return {
      tabs: [
        {
          component: "intro",
          text: "Intro Video",
          isExternalLink: false,
          link: "#",
        },
        {
          component: "overview",
          text: "Overview",
          isExternalLink: false,
          link: "#",
          permission: "View_Overview",
        },
        {
          component: "newsletter",
          text: "Newsletter",
          isExternalLink: false,
          link: "#",
          permission: "View_Newsletter",
        },
        {
          component: "replay",
          text: "Investor Call Replay",
          isExternalLink: false,
          link: "#",
          cssClass: "multiLine",
          permission: "View_Replay",
        },
        {
          component: "co-investment-opportunities",
          text: "Co-Investment Opportunities",
          isExternalLink: false,
          link: "#",
          cssClass: "multiLine",
          permission: "View_InvestmentOppotunities",
        },
        {
          component: "seeded-companies",
          text: "Portfolio Companies",
          isExternalLink: false,
          link: "#",
          cssClass: "multiLine",
          permission: "View_SeededCompanies",
        },
        {
          component: "",
          text: "Cornerstone Vault",
          isExternalLink: true,
          link: "https://fuelvc.arkpes.com/login",
          cssClass: "multiLine",
          permission: "View_CornerstonePortal",
        },
        {
          component: "data-room",
          text: "Data Room",
          isExternalLink: false,
          link: "#",
        },
        {
          component: "event",
          text: "Event",
          isExternalLink: false,
          link: "#",
          permission: "Event_Read",
        },
      ],
      pageSection: "",
    };
  },
  methods: {
    handleChangeFund() {
      appStore.dispatch(APP_ACTIONS.set_show_lp_funds_investor_popup, true);
    },
    routePage: function (link) {
      // this.$router.push({
      //   path: this.$router.currentRoute.value.path,
      //   query: { t: link },
      // });
      this.pageSection = link;
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
    hide() {
      //do something
    },
    menuClass(item) {
      return `${item.cssClass ? item.cssClass : ""} ${
        this.pageSection === item.component
          ? "router-link-exact-active active"
          : ""
      }`;
    },
  },
  mounted() {
    if (!this.$router.currentRoute.value.query.t) {
      this.pageSection = "intro";
    } else {
      let tab = this.tabs.find(
        (x) => x.component === this.$router.currentRoute.value.query.t
      );
      if (tab) {
        if (tab.permission) {
          if (this.hasPermission(tab.permission)) {
            this.pageSection = tab.component;
          } else {
            this.$router.push({
              path: this.$router.currentRoute.value.path,
              query: { t: "intro" },
            });
            this.pageSection = "intro";
          }
        } else {
          this.pageSection = tab.component;
        }
      } else {
        this.$router.push({
          path: this.$router.currentRoute.value.path,
          query: { t: "intro" },
        });
        this.pageSection = "intro";
      }
    }
  },
  computed: {
    isShowSelectLpFund() {
      return appStore.state.profile.lpFunds.length !== 1;
    },
    profile() {
      return appStore.state.profile;
    },
    investorInfo() {
      return appStore.state.investorInfo;
    },
    username() {
      return (
        this.profile &&
        `${this.profile.firstName} ${this.profile.lastName} ${
          this.investorInfo ? `(${this.investorInfo.selected_fund.name})` : ""
        }`
      );
    },
  },
  watch: {
    pageSection: function (v) {
      this.compts(v);
    },
  },
});
