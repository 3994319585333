<template>
  <div class="adminContent p-3">
    <div class="data">
      <div class="row p-3">
        <div class="col-12">
          <h5 class="m-0">
            Surveys
            <button
              class="btn dropdown-toggle p-0"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ typeSurvey.name }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span
                v-for="item in optionTypeSurvey"
                class="dropdown-item pointer py-2"
                @click="changeType(item)"
                :key="item"
                >{{ item.name }}</span
              >
            </div>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table table-hover admin-table mb-0" ref="table">
            <div class="header">
              <div class="date">No.</div>
              <div>Detail survey</div>
              <div>Time</div>
              <div>Status</div>
            </div>
            <div class="body">
              <div
                class="dataRow"
                v-for="(item, index) in listData"
                :key="item"
              >
                <div class="date">{{ index + 1 }}</div>
                <div>
                  <router-link :to="`/rfm/survey/${item.survey_id}`">{{
                    item.name
                  }}</router-link>
                </div>
                <div>{{ $filters.parseEstTime(item.finished_at) }}</div>
                <div>{{ item.stt }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pager
      :loadContentAtPage="loadPage"
      :page="paging.page"
      :size="paging.size"
      :total="paging.total"
      :pagerFull="false"
      v-if="paging.total > paging.page"
    ></pager>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
h5 {
  button {
    &.btn.dropdown-toggle {
      outline: 0 !important;
      box-shadow: unset;
      background-color: transparent;
      color: #212529;
      font-size: 20px;
      &:after {
        transform: translateY(3px);
      }
    }
  }
}
</style>
