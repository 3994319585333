import validateEmail from '../../../../misc/checkemail'
import AdminServices from '@/services/admin'
import notify from '../../../../services/notify'
import { defineComponent } from 'vue'
import TextField from '@/components/TextField'
import SelectField from '@/components/SelectField'

export default defineComponent({
    props: ['hideAddUser', 'addNewUser', 'dataInfo', 'updateUser', 'roleList', 'userTypeList', 'carrierPhoneList'],
    components: {
        TextField,
        SelectField
    },
    data() {
        return {
            form: {
                firstName: null,
                id: null,
                lastName: null,
                password: null,
                email: null,
                roleId: null,
                userType: null,
                phoneNumber: null,
                phoneCarrierId: null

            },
            errorMsg: {
                fname: null,
                lname: null,
                password: null,
                email: null,
                roleId: null,
                userType: null,
                phone: null,
                phoneCarrierId: null

            },
            type: 'Add',
            showPassword: false
        }
    },
    created() {
        if (this.dataInfo) {
            this.type = 'Edit'
            this.form.firstName = this.dataInfo.fname
            this.form.lastName = this.dataInfo.lname
            this.form.email = this.dataInfo.email
            this.form.roleId = this.dataInfo.role
            this.form.userType = this.dataInfo.type
            this.form.phoneNumber = this.dataInfo.phone
            this.form.phoneCarrierId = `${this.dataInfo.phone_carrier_id}`
        }
    },
    computed: {
        filtered_roleList() {
            if(this.form.userType)
                return this.roleList.filter(x => x.id !== '' && x.type == this.form.userType)
            else 
                return []
        }
    },
    mounted(){
        function setInputFilter(textbox, inputFilter) {
            ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
              textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                  this.oldValue = this.value;
                  this.oldSelectionStart = this.selectionStart;
                  this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                  this.value = this.oldValue;
                  this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                  this.value = "";
                }
              });
            });
          }
        setInputFilter(document.getElementById("phone"), function(value) {
            return /^\d*$/.test(value);
        });
    },
    methods: {
        mappingChoiceOptions(data) {
            return data.map(p => {
                p.valueProp = p.id
                p.labelBy = p.name
                return p
            })
        },
        resetPassword(id) {
            if (!this.form.password || $.trim(this.form.password) === '' || !this.form.password || this.form.password && this.form.password.length < 6) {
                this.errorMsg.password = 'The Password must be at least 6 characters long.'
                return
            }
            AdminServices.resetUserPassword(id, { password: this.form.password }).then(resp => {
                if (resp.data.ok) {
                    notify.success('Reset password success!')
                }
            })
        },
        isNumber: function(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43) {
                e.preventDefault()
            } else {
                return true
            }
        },
        validData() {
            let flag = true
            if (!this.form.firstName || this.form.firstName.trim() === '') {
                this.errorMsg.fname = 'Please input your first name'
                flag = false
            }
            if (!this.form.lastName || this.form.lastName.trim() === '') {
                this.errorMsg.lname = 'Please input your last name'
                flag = false
            }
            if (!this.dataInfo) {
                if (this.form.password.trim() === '' || !this.form.password || this.form.password && this.form.password.length < 6) {
                    this.errorMsg.password = 'The Password must be at least 6 characters long.'
                    flag = false
                }
            }
            if (!this.form.roleId) {
                this.errorMsg.roleId = 'Please select role'
                flag = false
            }
            if (!this.form.userType) {
                this.errorMsg.userType = 'Please select user type'
                flag = false
            }
            if (!validateEmail.validateEmail(this.form.email) && !this.dataInfo) {
                this.errorMsg.email = 'Please input correct email'
                flag = false
            }
            if (flag) {
                if (!this.dataInfo) {
                    this.addNewUser(this.form)
                } else {
                    this.updateUser(this.dataInfo.id, { firstName: this.form.firstName, lastName: this.form.lastName, roleId: this.form.roleId, phoneNumber: this.form.phoneNumber, phonecarrierid: this.form.phoneCarrierId })
                }
            }
        },
        isNumber: function(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault()
            } else {
                return true
            }
        }
    },
    watch: {
        'form.firstName': function (v) {
            this.errorMsg.fname = null
        },
        'form.lastName': function (v) {
            this.errorMsg.lname = null
        },
        'form.email': function (v) {
            this.errorMsg.email = null
        },
        'form.password': function (v) {
            this.errorMsg.password = null
        },
        'form.roleId': function(v) {
            this.errorMsg.roleId = null
        },
        'form.userType': function (v) {
            if (this.type == 'Add') {
                this.form.roleId = null
            }
            this.errorMsg.userType = null
        }
    }
})