<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideSearchItem"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <div class="modal-title w-100">
              <h4>Search Dataroom Item</h4>
              <div class="row mb-2">
                <div class="col col-12">
                  <div class="search-wrapper">
                    <input
                      type="text"
                      class="input-section"
                      placeholder="enter to search..."
                      v-model="searchKey"
                      @keyup.enter="searchItems(searchKey)"
                    />
                    <i
                      class="fas fa-search btn-icon"
                      :class="{ disabled: !searchKey.trim() }"
                      @click="searchItems(searchKey)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body custom-modal-body" v-loading="loading">
            <div class="row mb-2">
              <div class="col col-12 table-responsive">
                <table class="table table-hover mb-0 table-sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Size</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="item-row"
                      v-for="(item, index) in searchedItems"
                      :key="index"
                      :class="{ pointer: item.type === 'Folder' }"
                    >
                      <td class="noselect">
                        <div>
                          <span
                            class="pr-1 item-icon"
                            :style="{
                              color: getDataRoomItemIconColor(
                                item.type,
                                item.current_extension
                              ),
                            }"
                          >
                            <i
                              :class="
                                getDataRoomItemIconClass(
                                  item.type,
                                  item.current_extension
                                )
                              "
                            ></i>
                          </span>
                          <span>{{ item.name }}</span>
                        </div>
                        <div class="sub-item">
                          <span>Fuel VC</span>
                          <span
                            v-for="(folder, pidx) in item.folder_path"
                            :key="pidx"
                          >
                            <i class="fas fa-chevron-right mx-1"></i>
                            {{ folder }}
                          </span>
                        </div>
                      </td>
                      <td class="noselect">
                        <span v-if="item.type === 'File'">{{
                          $filters.formatBytes(item.file_size)
                        }}</span>
                      </td>
                      <td class="noselect">
                        {{ $filters.parseEstTime(item.last_modified_datetime) }}
                      </td>
                      <td class="text-center noselect">
                        <button
                          type="button"
                          class="btn btn-default btn-small"
                          @click="goToItemLocation(item)"
                        >
                          <i class="fas fa-compress"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5">
                        <pager
                          :loadContentAtPage="loadPage"
                          :page="searchParams.paging.page"
                          :size="searchParams.paging.size"
                          :total="searchParams.paging.total"
                          :pagerFull="false"
                          v-if="
                            searchParams.paging.total > searchParams.paging.size
                          "
                        ></pager>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="hideSearchItem">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.item-title {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 180px);
}
.table-sm {
  font-size: 14px;
}
.sub-item {
  font-size: 10px;
  color: $text-color--secondary;
}
.search-wrapper {
  position: relative;
  width: calc(100% - 30px);
  height: 30px;
  overflow: hidden;
  .input-section {
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    box-sizing: border-box;
    font-size: 14px;
    background: 0 0;
    border: 1px solid #ddd;
    padding-left: 12px;
    padding-right: 30px;
    width: calc(100% - 30px);
    height: 30px !important;
    line-height: normal !important;
    border-radius: 16px;
    color: $text-color--secondary;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      opacity: 1;
      border-color: $color-primary;
      outline: 0;
    }
  }
  .btn-icon {
    position: absolute;
    top: 4px;
    right: 9px;
    z-index: 9;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 22px;
    color: #666;
    border: none;
    background: 0 0;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}
</style>
