import { defineComponent } from "vue";
import adminServices from "@/services/admin";
import notify from "@/services/notify";

import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import MessageManageModel from "./components/MessageManageModel";
import Dialog from "@/components/Dialog";

export default defineComponent({
  components: {
    Table,
    TextField,
    SelectField,
    Dialog,
    MessageManageModel,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },

      filteredName: "",
      filteredSubject: "",
      filteredBody: "",
      messageManageModelKey: 0,
    };
  },
  watch: {
    paging: {
      deep: true,
      handler(v) {
        this.getAll();
      },
    },
    filteredName() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredSubject() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredBody() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  computed: {
    columns() {
      return [
        {
          id: "name",
          name: "Name",
        },
        {
          id: "subject",
          name: "Subject",
        },
        {
          id: "body",
          name: "Body",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-right",
          columnClass: "text-right action-icon-group",
        },
      ];
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getAllMessageTemplates({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredName: this.filteredName,
        filteredSubject: this.filteredSubject,
        filteredBody: this.filteredBody,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async deleteMessage(item) {
      let message = {
        title: "Delete Template",
        body: "Press button confirm to delete this template",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteMessageTemplate(item.id);
          if (res.data.ok) {
            notify.success("Deleted template successfully!");
            await this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async addMessage() {
      this.messageManageModelKey++;
      this.$nextTick(() => {
        this.$refs.messageManageModel.showAdd();
      });
    },
    async updateMessage(item) {
      this.messageManageModelKey++;
      this.$nextTick(() => {
        this.$refs.messageManageModel.showUpdate(item);
      });
    },
    truncate(text, length) {
      return text.length > length ? `${text.substring(0, length)}...` : text;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
