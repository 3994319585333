import AdminServices from "../../../services/admin";
import router from "@/config/router";
import listSurvey from "../listSurvey";
import appStore, { APP_MUTATION } from "../../../store/app";
import VueApexCharts from "apexcharts";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      readyRender: false,
      // totalFounder: 0,
      // completedSurveys: 0,
      // approvedSurveys: 0,
      // rejectedSurveys: 0,
      reviewSurveyPath: router.reviewSurvey.path,
      chartHeight: 0,
    };
  },
  components: {
    listSurvey,
    apexchart: VueApexCharts,
  },
  mounted() {
    if (this.profile) {
      this.readyRender = true;
      // this.getOverviewSurvey()
    }
    setTimeout(() => {
      this.renderBarChart();
      this.renderBarStackedChart();
    }, 1000);
    window.addEventListener("resize", this.onResize());
    this.onResize();
  },
  methods: {
    // getOverviewSurvey() {
    // AdminServices.getOverviewSurvey().then(resp => {
    //     if (resp.data.ok) {
    //         this.totalFounder = resp.data.d.total_founder
    //         this.completedSurveys = resp.data.d.completed
    //         this.approvedSurveys = resp.data.d.approved
    //         this.rejectedSurveys = resp.data.d.rejected
    //     }
    // })
    // },
    renderBarChart() {
      if (this.$refs.barchart)
        new VueApexCharts(this.$refs.barchart, {
          chart: {
            toolbar: {
              show: false,
            },
            type: "bar",
            height: 125,
          },
          annotations: {
            position: "front",
            xaxis: [
              {
                x: 100,
                borderColor: "transparent",
                label: {
                  borderColor: "transparent",
                  borderWidth: 0,
                  // text: '55%',
                  offsetY: 40,
                  orientation: "horizontal",
                  textAnchor: "end",
                },
              },
            ],
          },
          colors: ["#3E99ED"],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "90%",
            },
          },
          legend: {
            show: false,
          },
          grid: {
            show: true,
            borderColor: "#90A4AE",
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
          },
          dataLabels: {
            enabled: true,
            textAnchor: "end",
            offsetX: 0,
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          series: [
            {
              name: "Amount",
              data: [44, 55, 41, 37],
            },
          ],
          xaxis: {
            categories: ["SAAS", "AI", "Robot", "Entertaiment"],

            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          tooltip: {
            enabled: false,
          },
        }).render();
    },
    renderBarStackedChart() {
      if (this.$refs.barstackedchart)
        new VueApexCharts(this.$refs.barstackedchart, {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: {
              show: false,
            },
            height: this.chartHeight,
            width: "100%",
          },
          fill: {
            colors: ["#3E99ED", "#FF7777", "#C9C9C9"],
          },
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: "100%",
            },
          },
          grid: {
            show: true,
            borderColor: "#f00",
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
            textAnchor: "end",
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
          },
          // stroke: {
          //     width: 1,
          //     colors: ['#fff']
          // },
          series: [
            {
              name: "a",
              backgroundColor: "#f00",
              data: [44, 55, 41, 64, 22, 43, 21, 10, 70, 20, 40, 48],
            },
            {
              name: "b",
              data: [53, 32, 33, 52, 13, 44, 32, 65, 72, 13, 28, 64],
            },
            {
              name: "c",
              data: [54, 59, 80, 66, 50, 90, 100, 25, 36, 55, 17, 33],
            },
          ],
          crosshairs: {
            show: true,
          },
          xaxis: {
            categories: [
              "Apr 2019",
              "May 2019",
              "Jun 2019",
              "Jul 2019",
              "Aug 2019",
              "Sep 2019",
              "Oct 2019",
              "Nov 2019",
              "Dec 2019",
              "Jan 2020",
              "Feb 2020",
              "Mar 2020",
            ],
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#78909C",
            },
            axisTicks: {
              show: true,
              color: "#78909C",
            },
          },
          yaxis: {
            axisBorder: {
              show: true,
              color: "#78909C",
            },
            axisTicks: {
              show: true,
              color: "#78909C",
            },
          },
          legend: {
            show: false,
            position: "right",
            verticalAlign: "top",
            containerMargin: {
              left: 35,
              right: 60,
            },
          },
          tooltip: {
            enabled: true,
            fixed: {
              enabled: false,
              position: "bottomLeft",
              offsetX: 0,
              offsetY: 0,
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              let number = 0;
              let ar = [];
              series.map((item) => {
                number += item[dataPointIndex];
                ar.push(item[dataPointIndex]);
              });
              return (
                '<div class="box">' +
                '<div class="box-body">' +
                '<span class="iconColor iconGray">' +
                ar[2] +
                "</span>" +
                '<span class="iconColor iconRed">' +
                ar[1] +
                "</span>" +
                '<span class="iconColor iconBlue">' +
                ar[0] +
                "</span>" +
                "</div>" +
                "</div>"
              );
            },
          },
        }).render();
    },
    onResize() {
      this.chartHeight = window.innerHeight - 500;
    },
  },
  computed: {
    profile() {
      return appStore.state.profile;
    },
  },
});
