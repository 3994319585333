import _ from "lodash";
import VueCookies from "vue-cookies";

const $instance = {
  String: "String",
  Number: "Number",
  Boolean: "Boolean",
  Object: "Object",
  Array: "Array",
  Any: "Any",
  DefaultValue: {
    String: "",
    Number: 0,
    Boolean: false,
    Object: {},
    Array: [],
  },
  removeLocalStorage(name) {
    localStorage.removeItem(name);
  },
  setJsonLocalStorage(name, data) {
    const dataString = JSON.stringify(data);
    localStorage.setItem(name, dataString);
  },
  getJsonLocalStorage(name) {
    const value = localStorage.getItem(name);
    if (value !== "undefined" && value !== null) {
      return JSON.parse(value);
    }
  },
  //session storage
  removeSessionStorage(name) {
    sessionStorage.removeItem(name);
  },
  setJsonSessionStorage(name, data) {
    const dataString = JSON.stringify(data);
    sessionStorage.setItem(name, dataString);
  },
  getJsonSessionStorage(name) {
    const value = sessionStorage.getItem(name);
    if (value !== "undefined") {
      return JSON.parse(value);
    }
  },

  //cookie storage
  removeCookieStorage(name) {
    VueCookies.remove(name);
  },
  setJsonCookieStorage(name, data) {
    const dataString = JSON.stringify(data);
    VueCookies.set(name, dataString, 1000 * 60 * 60 * 24 * 10);
  },
  getJsonCookieStorage(name) {
    const value = VueCookies.get(name);
    if (value !== "undefined") {
      return value;
    }
  },

  dataValid(value, dataType) {
    let flag = false;
    switch (dataType) {
      case this.String:
        flag = _.isString(value);
        break;
      case this.Number:
        if (_.isString(value)) {
          const tmp = parseFloat(value);
          flag = !_.isNaN(tmp) && _.isFinite(tmp);
        }
        flag = flag || _.isNumber(value);
        break;
      case this.Boolean:
        flag = _.isBoolean(value);
        break;
      case this.Object:
        flag = _.isObject(value);
        break;
      case this.Array:
        flag = _.isArray(value);
        break;
      case this.Any:
        flag = true;
        break;
      default:
        break;
    }
    return flag;
  },
  parseValue(value, dataType) {
    let res;
    switch (dataType) {
      case this.String:
        res = _.isString(value) ? value : this.DefaultValue.String;
        break;
      case this.Number:
        if (_.isString(value)) {
          value = parseFloat(value);
          if (_.isNaN(value) || !_.isFinite(value)) {
            value = this.DefaultValue.Number;
          }
        }
        res = _.isNumber(value) ? value : this.DefaultValue.Number;
        break;
      case this.Boolean:
        res = _.isBoolean(value) ? value : this.DefaultValue.Boolean;
        break;
      case this.Object:
        res = _.isObject(value) ? value : this.DefaultValue.Object;
        break;
      case this.Array:
        res = _.isArray(value) ? value : this.DefaultValue.Array;
        break;
      case this.Any:
        res = value;
        break;
      default:
        break;
    }
    return res;
  },
  selectValue(object, key, dataType) {
    object = _.isObject(object) ? object : {};
    dataType = dataType || this.String;
    key = _.isString(key) ? key : "";
    const stackKeys = key.split(".").reverse();
    let tmpObj = object;
    while (stackKeys.length) {
      tmpObj = tmpObj[stackKeys.pop()] || {};
    }
    return this.parseValue(tmpObj, dataType);
  },
  setValue(object, key, dataType, value) {
    object = _.isObject(object) ? object : {};
    dataType = dataType || this.String;
    key = _.isString(key) ? key : "";
    const stackKeys = key.split(".").reverse();
    let tmpObj = object;
    while (stackKeys.length > 1) {
      const keyStack = stackKeys.pop();
      tmpObj[keyStack] = tmpObj[keyStack] || {};
      tmpObj = tmpObj[keyStack];
    }
    if (stackKeys[0]) {
      tmpObj[stackKeys[0]] = this.dataValid(value, dataType)
        ? value
        : this.DefaultValue[dataType];
    }
    return object;
  },
};

export class Entity {
  constructor(data, mapping) {
    this.$parsingData(data, mapping);
  }
  $parsingData(data, mapping) {
    data = _.isObject(data) ? data : {};
    this.$mapping = mapping || this.$$mapping;
    for (const k in this.$mapping) {
      const item = _.isArray(this.$mapping[k]) ? this.$mapping[k] : [];
      this[k] = $instance.selectValue(data, item[0], item[1]);
    }
  }
  set $mapping(value) {
    this.$$mapping = _.isObject(value) ? value : {};
  }
  get $mapping() {
    return _.isObject(this.$$mapping) ? this.$$mapping : {};
  }
  get serialize() {
    const res = {};
    for (const k in this.$mapping) {
      const item = _.isArray(this.$mapping[k]) ? this.$mapping[k] : [];
      $instance.setValue(res, item[0], item[1], this[k]);
    }
    return res;
  }
}

export const generic = {
  ...$instance,
  Entity,
};

export default generic;
