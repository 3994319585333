export const LOADING_CLASS_NAME = {
  panel: "ld-loading",
  button: "fa fa-circle-o-notch fa-spin",
  icon: "fa-spin",
};
export const loadingProcess = {
  apply(el, loading) {
    //let $ele = $(el)
    this.panel(el, loading);
    // if ($ele.hasClass('panel')) {
    //     this.panel(el, loading)
    // } else if ($ele.hasClass('btn')) {
    //     this.button(el, loading)
    // } else if ($ele.hasClass('fa')) {
    //     this.icon(el, loading)
    // }
  },
  icon(el, loading) {
    const icon = $(el);
    if (loading) {
      icon.addClass(LOADING_CLASS_NAME.icon);
    } else {
      icon.removeClass(LOADING_CLASS_NAME.icon);
    }
  },
  panel(el, loading) {
    const panel = $(el);
    let loader = panel.find(">.loader");
    if (!loader.length) {
      loader = $(
        '<div class="loader"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>'
      );
      panel.prepend(loader);
    }
    if (loading) {
      panel.addClass(LOADING_CLASS_NAME.panel);
    } else {
      panel.removeClass(LOADING_CLASS_NAME.panel);
    }
  },
  button(el, loading) {
    const panel = $(el);
    let loader = panel.find(".lds-ellipsis");
    if (!loader.length) {
      loader = $(
        '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>'
      );
      panel.prepend(loader);
    }
    if (loading) {
      panel.addClass(LOADING_CLASS_NAME.button);
    } else {
      panel.removeClass(LOADING_CLASS_NAME.button);
    }
  },
};

// const processPanel = (el, loading) => {
//     let panel = $(el)
//     let loader = panel.find('.loader')
//     if (!loader.length) {
//         loader = $('<div class="loader"><div class="loader-bar"></div></div>')
//         panel.prepend(loader)
//     }
//     if (loading) {
//         panel.addClass(LOADING_CLASS_NAME.panel)
//     } else {
//         panel.removeClass(LOADING_CLASS_NAME.panel)
//     }
// }

// Vue.directive('loading', {
//     bind: function (el, binding) {
//         loadingProcess.apply(el, binding.value)
//     },
//     update: function (el, binding) {
//         loadingProcess.apply(el, binding.value)
//     }
// })

// Vue.directive('count', {
//     inserted(el, binding) {
//         animateValue('value', 100, 25, 5000)
//     }
// })

const LOADING = {
  // called before bound element's attributes
  // or event listeners are applied
  created(el, binding, vnode, prevVnode) {
    loadingProcess.apply(el, binding.value);
  },
  // called right before the element is inserted into the DOM.
  beforeMount() {},
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted() {},
  // called before the parent component is updated
  beforeUpdate() {},
  // called after the parent component and
  // all of its children have updated
  updated(el, binding) {
    loadingProcess.apply(el, binding.value);
  },
  // called before the parent component is unmounted
  beforeUnmount() {},
  // called when the parent component is unmounted
  unmounted() {},
};
export default LOADING;
