import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    indexField: { type: Number, default: 0 },
    isHorizontal: { type: Boolean, default: false },
  },
  emits: ['update:value', 'update:errorMsg'],
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val = false) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
