import AdminServices from '@/services/admin'
import notify from '../../../../services/notify'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'moveItems',
    props: ['parentId', 'items', 'hideMoveItems'],
    data() {
        return {
            loadingFolder: false,
            loading: false,
            loadingInitial: false,
            folderDetail: null,
            selectedFolderId: null,
            itemsToMove: [],
            delay: 300,
            clicks: 0,
            timer: null,
            completed: false
        }
    },
    created() {
        this.itemsToMove = this.items
        this.goToFolder(this.parentId)
    },
    methods: {
        async loadFolderDetail(id) {
            let _folder_resp = await AdminServices.getDataroomItemWithChildren(id)
            if (_folder_resp.data.ok) {
                this.folderDetail = _folder_resp.data.d 
            } else {
                this.folderDetail = null
            }
        },

        async goToFolder(id) {
            this.loadingFolder = true
            this.selectedFolderId = id
            await this.loadFolderDetail(id)
            this.loadingFolder = false
        },
        
        oneClick(item) {
            if (this.checkIfFolderSelectable(item)) {
                this.clicks++ 
                if (this.clicks === 1) {
                    let self = this
                    this.timer = setTimeout(() => {
                        self.clicks = 0
                    }, this.delay)
                } else {
                    clearTimeout(this.timer)
                    this.goToFolder(item.id)
                    this.clicks = 0
                }
            }
        },

        checkIfFolderSelectable(item) {
            let _idArr = this.itemsToMove.map(x => x.id)
            if (_idArr.includes(item.id)) {
                return false
            } else {
                if (item && item.type === 'Folder') {
                    return true
                } else {
                    return false
                }
            }
        },

        move() {
            if (this.ableToMoveToSelectedFolder()) {
                this.loading = true
                AdminServices.moveDataroomItems({
                    ids: this.itemsToMove.map(x => x.id),
                    parentId: this.selectedFolderId
                }).then(resp => {
                    this.completed = true
                    if (resp.data.ok) {
                        this.itemsToMove = this.itemsToMove.map(x => {
                            return {
                                ...x,
                                isMoved: true
                            }
                        })
                        notify.success('Move items successfully!')
                        //this.goToFolder(this.selectedFolderId)
                        this.hideMoveItems()
                    }
                }).finally(() => { this.loading = false })
            }
        },

        ableToMoveToSelectedFolder() {
            if (this.completed) {
                return false
            }
            if (this.selectedFolderId === this.parentId) {
                return false
            }
            //check if any file having the same name in 2 clusters
            //itemsToMove && folderDetail.children
            let _itemsFileNames = []
            let _childrenFileNames = []
            if (this.itemsToMove) {
                _itemsFileNames = this.itemsToMove.filter(x => x.type === 'File').map(x => x.name)
            }
            if (this.folderDetail && this.folderDetail.children) {
                _childrenFileNames = this.folderDetail.children.filter(x => x.type === 'File').map(x => x.name)
            }
            let intersections = _itemsFileNames.filter(e => _childrenFileNames.indexOf(e) !== -1)
            if (intersections && intersections.length > 0) {
                return false
            } else {
                return true
            }
        }
    }
})