<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideManageVersions"></div>
      <div class="modal-dialog-md">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Manage versions</h4>
          </div>
          <div class="modal-body custom-modal-body" v-loading="loading">
            <template v-if="sameNameFiles && sameNameFiles.length > 0">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <span style="font-size: 15px"
                    >This file is also available at:
                  </span>
                </div>
                <div class="col-12">
                  <ul class="list-group constraint-list">
                    <li
                      class="list-group-item card-item"
                      v-for="(_item, idx) in sameNameFiles"
                      :key="idx"
                    >
                      <div>
                        <span>FVC</span>
                        <span
                          v-for="(folder, index) in _item.folder_path"
                          :key="index"
                        >
                          <i class="fas fa-chevron-right mx-1"></i>
                          {{ folder }}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <hr class="my-2" />
            </template>
            <form @submit.prevent="validateForm" autocomplete="false">
              <div class="row mb-2" v-if="dataroomItem">
                <div class="col-12">
                  <div class="form-group mb-1">
                    <input
                      type="file"
                      id="files"
                      ref="files"
                      @change="handleFilesUpload()"
                    />
                    <template>
                      <transition
                        name="fade"
                        v-for="(fileItem, idx) in files"
                        :key="idx"
                      >
                        <div
                          class="file-item mt-2"
                          :class="{ error: !fileItem.isNameValid }"
                        >
                          <span
                            class="pr-1 item-icon"
                            :style="{
                              color: getDataRoomItemIconColorByTypeAndFileName(
                                'File',
                                fileItem.file.name
                              ),
                            }"
                          >
                            <i
                              :class="
                                getDataRoomItemIconClassByTypeAndFileName(
                                  'File',
                                  fileItem.file.name
                                )
                              "
                            ></i>
                          </span>
                          <span class="info">{{ fileItem.file.name }}</span>
                          <div
                            class="error-handling-wrapper"
                            v-if="!fileItem.isNameValid"
                          >
                            <div class="info error-handling">
                              <span class="me-2">{{
                                specialCharacterMsg
                              }}</span>
                            </div>
                          </div>
                          <div
                            class="error-handling-wrapper"
                            v-else-if="
                              fileItem.errorMsg && !fileItem.isUploading
                            "
                          >
                            <div class="info error-handling">
                              <span class="me-2">{{ fileItem.errorMsg }}</span>
                            </div>
                          </div>
                          <div
                            v-if="fileItem.isUploading"
                            class="progress progress-indicator"
                          >
                            <div
                              class="progress-bar progress-bar-info progress-bar-striped"
                              role="progressbar"
                              :aria-valuenow="progress"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              :style="{ width: fileItem.progress + '%' }"
                            >
                              {{ fileItem.progress }}%
                            </div>
                          </div>
                          <span
                            v-else-if="fileItem.isUploaded"
                            class="icon success"
                            ><i class="fas fa-check-double"></i
                          ></span>
                        </div>
                      </transition>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-12">
                  <div class="d-flex justify-content-end" v-if="!isConfirming">
                    <button
                      type="button"
                      :disabled="loading"
                      class="btn btn-primary btn-small"
                      @click="addFiles"
                    >
                      Upload New Version
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-end"
                    v-else
                  >
                    <span class="warn-msg me-3"
                      >This action will affect all above files</span
                    >
                    <button
                      type="button"
                      class="btn btn-primary btn-small me-1"
                      @click="confirmUpload"
                    >
                      Ok
                    </button>
                    <button
                      type="button"
                      class="btn btn-default btn-small"
                      @click="cancelUpload"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <hr class="my-2" />

            <div class="row" v-if="dataroomItem">
              <div class="col-12">
                <ul class="list-group" v-if="dataroomItem.versions">
                  <li
                    class="list-group-item card-item"
                    v-for="(_item, idx) in dataroomItem.versions"
                    :key="idx"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="doc-type-icon"
                        :style="{
                          color: getDataRoomItemIconColor(
                            'File',
                            _item.Extension
                          ),
                        }"
                      >
                        <i
                          :class="
                            getDataRoomItemIconClass('File', _item.Extension)
                          "
                        ></i>
                      </span>
                      <div>
                        <span class="version me-1">{{
                          idx === 0
                            ? "Current version: "
                            : "Version " +
                              (dataroomItem.versions.length - idx) +
                              ": "
                        }}</span>
                        <span class="name"
                          >{{ _item.Name }}{{ _item.Extension }}</span
                        >
                        <br />
                        <span class="small me-1">{{
                          $filters.parseEstTime(_item.CreatedDatetime)
                        }}</span>
                        <span class="small sub-footer-item">{{
                          _item.UploadedUserFullname
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="icon more-action-btn"
                      @click.prevent.stop="handleClick($event, _item, idx)"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button
              type="button"
              class="btn btn-default"
              @click="hideManageVersions"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <context-menu
        :key="contextMenuComponentKey"
        :elementId="'versionMenu'"
        :options="menuOptions"
        :ref="'versionMenu'"
        @option-clicked="optionClicked"
      />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.open {
  padding-top: 7px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}
.custom-label {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  font-size: 13px;
  //line-height: 20px;
  vertical-align: middle;
}
.constraint-list {
  max-height: 100px;
  overflow-y: auto;
}
.list-group-item {
  border: none;
  background-color: transparent;
  &:not(:first-child) {
    border-top: 1px solid $color-default;
  }
  padding: 0.35rem 1.25rem;
}
.more-action-btn {
  cursor: pointer;
  display: flex;
  margin-left: 2px;
  min-width: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 10px;
  color: #9e9e9e;
  background-color: $color-default;
  &:hover {
    background-color: $color-grey;
  }
  i {
    margin: auto;
  }
}
.warn-msg {
  font-size: 14px;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: 400px;
}
.card-item {
  font-size: 13px;
  font-weight: 200;
  display: flex;
  align-items: center;
  background-color: transparent; //$color-background-gray;
  color: $text-color;
  .doc-type-icon {
    font-size: 1rem;
    margin-right: 0.75rem;
  }
  .icon {
    cursor: pointer;
    margin-left: auto;
  }
  .version {
    color: $text-color--tertiary;
  }
  .small {
    font-size: 12px;
    color: $text-color--tertiary;
  }
  .sub-footer-item {
    color: #949ba2;
  }
  &.active {
    color: $color-primary;
  }
}
input[type="file"] {
  position: absolute;
  top: -500px;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
textarea {
  resize: none;
}
.modal-footer {
  display: block;
}
.custom-file {
  height: 150px;
  .custom-file-label {
    top: 30px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    background: #d9d9d9;
    height: 120px;
    &::after {
      display: none;
    }
    .dataImg {
      height: 105px;
    }
    i {
      font-size: 80px;
      &.dataPhoto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
  }
  .custom-file-input {
    height: 100%;
  }
}
.custom-row {
  height: 160px;
  @media screen and (max-width: 767.98px) {
    height: unset;
    .custom-file {
      height: 160px;
    }
    .custom-file-input {
      height: 100%;
    }
  }
}
.customUploadFile {
  background-color: #41505e;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  display: block;
  color: #fff;
  opacity: 0.7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.file-item {
  display: flex;
  align-items: center;
  color: #afafaf;
  font-weight: 400;
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  .progress-indicator {
    flex-grow: 1;
    margin: 0 10px;
  }
  .error-handling-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: auto;
  }
  .info {
    font-size: 0.8rem;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    &.error-handling {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 0.8rem;
      color: red;
      .inline-label {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        font-size: 0.8rem;
        color: red;
        line-height: 20px;
        vertical-align: middle;
      }
    }
  }
  .icon {
    margin-left: auto;
    font-size: 1rem;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    float: none;
    &.close {
      cursor: pointer;
    }
    &.success {
      color: #409eff;
    }
  }
  &.error {
    border-color: red;
  }
}
</style>
<script src="./index.js"></script>
