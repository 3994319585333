<template>
  <div class="adminContent p-3">
    <div class="data p-3">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center mb-2">
          <!-- <Datepicker
                        placeholder="From"
                        name="from"
                        id="from"
                        :format="customFormatter"
                        field="black"
                        fieldRequire
                        v-model:value="pickerFrom"
                        :maxDate="pickerTo"
                    /> -->
          <Datepicker
            placeholder="From"
            field="black"
            fieldRequire
            id="from"
            name="from"
            v-model:value="pickerFrom"
            :maxDate="pickerTo ? parseLocalTime(pickerTo) : null"
            mask="dd MMM yyyy"
          />
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center mb-2">
          <!-- <Datepicker
                        placeholder="To"
                        name="to"
                        id="to"
                        :format="customFormatter"
                        field="black"
                        fieldRequire
                        :value="pickerTo"
                        v-model:value="pickerTo"
                        :minDate="pickerFrom" /> -->
          <Datepicker
            placeholder="To"
            field="black"
            fieldRequire
            id="to"
            name="to"
            v-model:value="pickerTo"
            :minDate="pickerFrom ? parseLocalTime(pickerFrom) : null"
            mask="dd MMM yyyy"
          />
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-2">
          <customSelect
            v-model:value="typesSelected"
            :options="investorTypes"
            isMultiple
            label="Select investor types"
            class="w-100"
          />
        </div>
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-2">
          <button
            class="btn btn-primary btn-search"
            @click="searchBy"
            :disabled="disabled"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <div class="data p-3 mt-3 overflow-auto">
      <div class="usersReport">
        <div>
          <h5>Top 30 Files</h5>
          <div class="inTime d-flex">
            <div class="col-6 col-md-6 pl-1">From: {{ model.displayFrom }}</div>
            <div class="col-6 col-md-6">To: {{ model.displayTo }}</div>
          </div>
          <hr class="separator" />
        </div>
        <Table
          :paging="searchParams.paging"
          @updatePaging="updatePaging"
          :columns="columns"
          :rows="files"
          :total-items="searchParams.totalItems"
          :showNoRow="false"
          cursorPointer
          @rowClick="viewDetail"
          v-loading="loading"
          useSimpleLayout
        >
          <template #row_fileName="{ props }">
            {{ props.name }}
            <div class="sub-text">
              <span>FVC</span>
              <span v-for="(folder, index) in props.folder_path" :key="index">
                <i class="fas fa-chevron-right mx-1"></i>
                {{ folder }}
              </span>
            </div>
          </template>
          <template #row_investorRead="{ props }">
            {{ props.view_count }}
          </template>
        </Table>
      </div>
    </div>

    <detailFile
      v-if="viewDetailFile"
      :hideModal="hideFile"
      :itemId="selectedItemId"
      :model="model"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.adminContent {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  h5 {
    color: #fff;
  }
  .usersReport {
    .separator {
      background: #ccc;
    }
    table {
      width: 100%;
      max-width: 100%;
      thead {
        th {
          padding: 0 7px;
          font-weight: 400;
        }
      }
    }
  }
}
.label-sm {
  margin-bottom: 0px;
  font-size: 14px;
}

.sub-text {
  font-size: 10px;
  color: $text-color--secondary;
}
</style>
