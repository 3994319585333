<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideShareItem"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Share Unauthenticated Dataroom Item</h4>
          </div>
          <div
            class="modal-body custom-modal-body"
            v-loading="loading || loadingInitial"
          >
            <div v-if="!loadingInitial">
              <div class="mb-3 text-center" v-if="dataroomItem">
                <span class="item-title"
                  >{{ dataroomItem.type }}: {{ dataroomItem.name }}</span
                >
                <div class="item-subtitle mt-3">
                  <span v-if="dataroomItem.type === 'File'"
                    >Current version: {{ dataroomItem.versions[0].Name
                    }}{{ dataroomItem.versions[0].Extension }}</span
                  >
                  <span v-if="dataroomItem.type === 'Bookmark'"
                    >Url: {{ dataroomItem.bookmark_url }}</span
                  >
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-6">
                  <div class="search-wrapper mb-1">
                    <input
                      type="text"
                      placeholder="Enter to search investors"
                      class="input-section"
                      v-model="searchKey"
                      @input="delayedFilter"
                    />
                    <i
                      v-if="searchKey"
                      class="fa fa-times btn-icon"
                      @click="clearSearchUsers"
                    ></i>
                  </div>
                  <div
                    class="search-header"
                    v-if="searchedUsers && searchedUsers.length > 0"
                  >
                    <span>Searched Investors</span>
                    <button
                      class="btn btn-primary btn-small"
                      @click="selectAll"
                      v-if="!searchKey"
                    >
                      Select All
                    </button>
                  </div>
                  <ul
                    class="list-group"
                    v-if="searchedUsers && searchedUsers.length > 0"
                  >
                    <li
                      class="list-group-item card-item"
                      v-for="(item, idx) in searchedUsers"
                      :key="idx"
                    >
                      <div>
                        <span>{{ item.fname }} {{ item.lname }}</span>
                        <span class="item-subtext">{{ item.user_type }}</span>
                        <br />
                        <span>{{ item.email }}</span>
                      </div>
                      <div class="icon" @click="addUser(item, idx)">
                        <i class="fas fa-user-plus"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col col-6">
                  <div class="search-wrapper mb-1">
                    <input
                      type="text"
                      placeholder="Enter to search shared users"
                      class="input-section"
                      v-model="searchSharedKey"
                      @input="delayedFilterShared"
                    />
                    <i
                      v-if="searchSharedKey"
                      class="fa fa-times btn-icon"
                      @click="clearSearchSharedUsers"
                    ></i>
                  </div>
                  <div
                    class="search-header"
                    v-if="dataroomItem.sharedUserDetails"
                  >
                    <span>Shared Investors</span>
                  </div>
                  <ul class="list-group" v-if="dataroomItem.sharedUserDetails">
                    <li
                      class="list-group-item card-item"
                      v-for="(item, idx) in filteredSharedUsers"
                      :key="idx"
                    >
                      <div>
                        <span>{{ item.fname }} {{ item.lname }}</span>
                        <span class="item-subtext">{{ item.user_type }}</span>
                        <div>{{ item.email }}</div>
                        <div v-if="item.sharing_info" class="item-subtext">
                          Shared by {{ item.sharing_info.fname }}
                          {{ item.sharing_info.lname }} on
                          {{ $filters.parseEstTime(item.sharing_info.shared_on) }}
                        </div>
                        <label
                          class="pointer inline-label"
                          v-if="['Folder', 'File'].includes(dataroomItem.type)"
                        >
                          <input
                            type="checkbox"
                            v-model="item.is_allow_download"
                            value="true"
                          />
                          <span class="ml-1">Download</span>
                        </label>
                      </div>

                      <div
                        class="icon more-action-btn"
                        @click="removeUser(item)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </li>
                    <li
                      class="list-group-item card-item item-ancestor"
                      v-for="(item, idx) in searchedAncestorSharedUsers"
                      :key="idx + '-ancestor'"
                    >
                      <div>
                        <span>{{ item.fname }} {{ item.lname }}</span>
                        <span class="item-subtext">{{ item.user_type }}</span>
                        <div>{{ item.email }}</div>
                        <div v-if="item.sharing_info" class="item-subtext">
                          Shared by {{ item.sharing_info.fname }}
                          {{ item.sharing_info.lname }} on
                          {{ $filters.parseEstTime(item.sharing_info.shared_on) }}
                        </div>
                        <label
                          class="inline-label"
                          v-if="['Folder', 'File'].includes(dataroomItem.type)"
                        >
                          <input
                            disabled
                            type="checkbox"
                            v-model="item.is_allow_download"
                            value="true"
                          />
                          <span class="ml-1">Download</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="hideShareItem">
              Cancel
            </button>
            <button class="btn btn-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.item-title {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.item-subtitle {
  font-size: 12px;
  font-weight: 300;
  color: $text-color--secondary;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: 500px;
}
.search-header {
  font-size: 14px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid $color-background-gray-dark;
  border-bottom: none;
  padding: 0.75rem 1.25rem;
  display: flex;
  justify-content: space-between;

  button {
    font-size: 0.8em;
    padding: 0 0.3rem;
  }
}
.list-group {
  overflow-y: auto;
  max-height: 300px;
}
.list-group-item {
  &.custom-list-group-item-header {
    background-color: transparent;
    border-color: $color-background-gray-dark;
    font-size: 14px;
  }
  &.card-item {
    line-height: 1.2;
    padding: 0.3rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 200;
    display: flex;
    align-items: center;
    background-color: $color-background-gray;
    color: $text-color;
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .icon {
      cursor: pointer;
      margin-left: auto;
    }
    &.item-ancestor {
      background-color: $color-background-gray-dark;
      //color: $color-grey;
    }
    .item-sub-header {
      font-size: 12px;
      font-weight: 500;
      color: #fffaf0;
    }
    .item-subtext {
      font-size: 10px;
      font-weight: 300;
      color: $color-grey;
    }
  }
}
.btn.btn-addon {
  margin-left: -40px;
  z-index: 100;
}
.more-action-btn {
  cursor: pointer;
  display: flex;
  margin-left: 2px;
  min-width: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 10px;
  //color: #9E9E9E;
  background-color: $color-default;
  &:hover {
    background-color: $color-grey;
    color: $color-dark;
  }
  i {
    margin: auto;
  }
}

.inline-label {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  //font-size: 0.8rem;
  //line-height: 20px;
  vertical-align: middle;
}

.search-wrapper {
  position: relative;
  width: 100%;
  height: 30px;
  overflow: hidden;
  .input-section {
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    box-sizing: border-box;
    font-size: 14px;
    background: 0 0;
    background-color: #494b54;
    border: 1px solid #494b54;
    padding-left: 12px;
    padding-right: 30px;
    width: 100%;
    height: 30px !important;
    line-height: normal !important;
    border-radius: 5px;
    color: $text-color--secondary;
    opacity: 0.9;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      opacity: 1;
      border-color: $color-primary;
      outline: 0;
    }
  }
  .btn-icon {
    position: absolute;
    top: 4px;
    right: 9px;
    z-index: 9;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 22px;
    color: #666;
    border: none;
    background: 0 0;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}
</style>
