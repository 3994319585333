<template>
  <div
    class="bannerHeader backgroundImg"
    :style="{ backgroundImage: 'url(' + bannerInfo.image + ')' }"
  >
    <div class="bannerInfo d-flex justify-content-center align-items-center">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-8">
            <span class="bannerName">{{ bannerInfo.name }}</span>
          </div>
          <div class="col-lg-4 col-md-4 pl-lg-5">
            <span class="shortDescription">{{
              bannerInfo.shortDescription
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.bannerInfo {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .bannerName {
    font-size: 5rem;
    font-weight: 300;
    color: #fff;
    padding-left: 50px;
  }
  .shortDescription {
    font-size: 1rem;
  }
}

@media (min-width: 1196px) and (max-width: 1600px) {
  .bannerInfo {
    .bannerName {
      font-size: 4rem;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .bannerInfo {
    .bannerName {
      line-height: 64px;
    }
  }
}
@media (max-width: 575.98px) {
  .bannerInfo {
    .bannerName {
      font-size: 2rem;
    }
  }
}
</style>
