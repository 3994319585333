<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">
              {{ id ? "Edit Fund" : "Add Fund" }}
            </h4>
          </div>
          <div class="modal-body" v-loading="loading">
            <!-- <form @submit.prevent="onSave" autocomplete="false"> -->
              <div class="row mb-2">
                <div class="col col-6">
                  <div class="form-group">
                    <label class="mb-0">
                      Name <small class="colorRed">*</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      v-model="form.name"
                    />
                    <p class="m-0" v-if="errorMsg.name">
                      <small class="colorRed">{{ errorMsg.name }}</small>
                    </p>
                    <p class="m-0" v-if="form.name && form.name.length > 250">
                      <small class="colorRed"
                        >Max length is 250 characters</small
                      >
                    </p>
                  </div>
                </div>
                <div class="col col-6">
                  <div class="custom-switch-wrapper mt-4">
                    <Switcher
                      v-model:value="form.is_enabled"
                      :title="form.is_enabled ? 'Active' : 'Inactive'"
                      :isHorizontal="true"
                      titleDirection="right"
                      :isShowText="false"
                      name="fund-edit"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-6">
                  <div class="form-group position-relative">
                    <label class="mb-0">
                      Background color <small class="colorRed">*</small></label
                    >
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        v-model="displayColor"
                      />
                    </div>
                    <div class="color-display">
                      <pick-colors v-model:value="displayColor" show-alpha/>
                    </div>
                    <p class="m-0" v-if="errorMsg.bg">
                      <small class="colorRed">{{ errorMsg.bg }}</small>
                    </p>
                  </div>
                </div>
                <div class="col col-6">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Video <small class="colorRed">*</small></label
                    >
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control  me-1"
                        readonly
                        v-model="form.video_name"
                      />
                      <button
                        class="btn btn-secondary btn-small ml-1"
                        style="min-width: 85px;"
                        @click="selectDataroomItem"
                      >
                        <div class="search-btn">
                          <i class="fas fa-search iconSearch"></i>
                          <span class="m-1">Search</span>
                        </div>
                      </button>
                    </div>
                    <span class="texterror colorRed" v-if="errorMsg.video"
                      ><small>Please choose video</small></span
                    >
                  </div>
                </div>
                <div class="col col-6">
                  <div class="custom-file form-group">
                    <label for="customFileLang" class="mb-0"
                      >Image (1500x500 px)<small class="colorRed">*</small>
                    </label>
                    <label
                      class="custom-file-label mb-0 pointer"
                      for="customFileLang"
                    >
                      <i
                        class="fas fa-cloud-upload-alt"
                        :class="{ dataLogo: dataLogo }"
                      ></i>

                      <p class="text-center mb-0" v-if="!dataLogo">
                        click to upload
                      </p>
                      <img
                        :src="dataLogo"
                        alt=""
                        class="dataImg"
                        v-else
                      />
                      <input
                        type="file"
                        id="customFileLang"
                        class="custom-file-input"
                        ref="logoimg"
                        @change="renderLogoImg"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                    </label>
                  </div>
                  <span class="texterror colorRed" v-if="errorMsg.logo"
                    ><small>{{ errorMsg.logo }}</small></span
                  >
                </div>

              </div>
              <!-- <div class="row mb-2">
                <div class="col col-12">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Description <small class="colorRed">*</small></label
                    >
                    <textarea
                      v-model="form.description"
                      class="form-control"
                      rows="5"
                      maxlength="4000"
                      placeholder="Maximum 4000 characters"
                    ></textarea>
                    <p class="m-0" v-if="errorMsg.description">
                      <small class="colorRed">{{ errorMsg.description }}</small>
                    </p>
                  </div>
                </div>
              </div> -->
            <!-- </form> -->
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="closeModal">Close</button>
            <button class="btn btn-primary" @click="onSave">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <publicFileDataroom
      v-if="isShowPublicDataroom"
      :mediaType="selectedMediaType"
      @close="closeSearchDataroomItem"
      @completed="completeSelectDataroomItem"
    />
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.custom-file {
  height: 150px;
  .custom-file-label {
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    height: 102px;
    position: relative;
    overflow: hidden;
    &::after {
      display: none;
    }
    .dataImg {
      height: 100%;
      width: auto;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
    i {
      font-size: 80px;
      &.dataLogo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
    input {
      opacity: 0;
      width: 1px;
      height: 1px;
      display: block;
      overflow: hidden;
    }
  }
  .custom-file-input {
    height: 0;
  }
}

textarea {
  resize: none;
}
</style>

<style lang="scss">
.color-display{
  position: absolute;
  top: 34px;
  right: 5px;
}

</style>