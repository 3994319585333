<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          name="filteredName"
          placeholder="Type to search .."
          hideError
          :value="filteredName"
          @update:value="filteredName = $event"
        />
      </template>
      <template #head_email>
        <TextField
          name="filteredEmail"
          placeholder="Type to search .."
          hideError
          :autocomplete="false"
          v-model:value="filteredEmail"
        />
      </template>
      <template #head_stt>
        <SelectField
          v-model:value="filteredStatus"
          :options="statusList"
          hideError
        />
      </template>
      <template #head_role_name>
        <SelectField
          v-model:value="filteredRole"
          :options="roleList"
          hideError
          valueProp="id"
        />
      </template>
      <template #head_sent_by>
        <TextField
          name="filteredSentBy"
          placeholder="Type to search .."
          hideError
          v-model:value="filteredSentBy"
        />
      </template>
      <template #head_nda>
        <SelectField v-model:value="filteredNda" :options="ndaList" hideError />
      </template>
      <template #row_name="{ props }">
        {{ props.fname }} {{ props.lname }}
      </template>
      <template #row_stt="{ props }">
        <div>{{ $filters.getName(props.stt, statusList) }}</div>
        <small>{{ $filters.parseEstTime(props.activated_datetime) }}</small>
        <small v-if="isTemporarilyLockedout(props.lockout_end_datetime)">
          Locked Until: {{ $filters.parseEstTime(props.lockout_end_datetime) }}
        </small>
      </template>
      <template #row_sent_by="{ props }">
        <div>{{ props.invitator_fullname }}</div>
        <small>{{ $filters.parseEstTime(props.invitation_datetime) }}</small>
      </template>
      <template #row_nda="{ props }">
        <div>{{ $filters.getNameByCode(props.nda, ndaList) }}</div>
        <small v-if="props.nda === 'SIGNED'">{{
          $filters.parseEstTime(props.nda_signed_datetime)
        }}</small>
      </template>
      <template #row_actions="{ props }">
        <div
          class="dropdown"
          v-if="
            hasPermissions([
              PERMISSIONS.Config_Investors_Update,
              PERMISSIONS.Investors_Update,
            ])
          "
        >
          <button
            class="btn btn-primary dropdown-toggle custom-menu-dropdown"
            type="button"
            id="userManagement"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="userManagement"
          >
            <li>
              <span
                class="dropdown-item pointer"
                @click="reinviteInvestor(props)"
                title="Re-invite"
                v-if="props.stt === 'INVITATION_SENT'"
              >
                <i class="far fa-envelope-open me-1"></i>Re-invite
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="updateInvestor(props)"
                title="Edit"
              >
                <i class="fas fa-comment-alt me-1"></i>Edit
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="updateStatus(props)"
                :title="props.locked ? 'Activate' : 'Inactivate'"
              >
                <template v-if="!props.locked">
                  <i class="fas fa-lock me-1"></i>Inactivate
                </template>
                <template v-else>
                  <i class="fas fa-lock-open me-1"></i>Activate
                </template>
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="resetUserLockout(props)"
                v-if="isTemporarilyLockedout(props.lockout_end_datetime)"
              >
                <i class="fas fa-history me-1"></i>Unlock user
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="deleteUser(props)"
                v-if="hasPermission(PERMISSIONS.Users_Delete)"
              >
                <i class="far fa-trash-alt me-1"></i>Delete
              </span>
            </li>
          </ul>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="addInvestor">
          Invite Investor
        </button>
      </template>
    </Table>

    <Dialog ref="dialog" />

    <AddInvestorModel
      ref="addInvestorModel"
      :key="addInvestorModelKey"
      :roleList="roleList"
      :investorTypeList="investorTypeList"
      @refresh="getAll"
    />

    <UpdateInvestorModel
      ref="updateInvestorModel"
      :key="updateInvestorModelKey"
      :roleList="roleList"
      :investorTypeList="investorTypeList"
      @refresh="getAll"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss">
.custom-menu-dropdown {
  > i {
    display: block;
    line-height: 22px;
  }
}
</style>
