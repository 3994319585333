<template>
  <div class="portfolio-item-main">
    <div class="portfolio-img-wrapper">
      <div class="img">
        <div v-if="data.is_exited" class="corner-label">
          <div class="corner-label-txt">
            <div
              :style="`background-color: #FFF; padding: 5px 0px; font-size: 13px; color: rgb(0, 149, 255);`"
            >
              Exited
              <div>Nasdaq</div>
            </div>
          </div>
          <div
            class="corner-label-none"
            style="
              background-color: rgb(0, 149, 255);
              color: #fff;
              padding: 5px 0px;
              font-size: 13px;
            "
          >
            Exited
            <br />
            <div>Nasdaq</div>
          </div>
        </div>

        <img
          :srcset="data.thumbnail.s3_key"
          alt="Thumbnail"
          onerror="this.onerror=null;this.src='/public/images/default.jpeg';"
        />
      </div>
      <div class="title">
        {{ data.company_name }}
      </div>
      <div class="founder">
        <div
          v-for="(item, index) in data.founder_info"
          :key="index"
          class="founder-item"
        >
          {{ `${item.founderTitle}: ${item.founderName}` }}
          <a
            v-if="item.founderLinkedin"
            :href="item.founderLinkedin"
            target="_blank"
            class="linkedin-link"
          >
            <img
              src="../../../../assets/images/v2/linkedin.png"
              alt="linkedin"
            />
          </a>

          <a
            v-if="item.founderTwitter"
            :href="item.founderTwitter"
            target="_blank"
            class="linkedin-link twitter-link"
          >
            <img src="../../../../assets/images/v2/twitter.png" alt="Twitter" />
          </a>
        </div>
      </div>
    </div>

    <div class="portfolio-item-content">
      <div class="title-item">{{ data.title }}</div>
      <div class="content-item">{{ data.description }}</div>
      <a class="btn-arrow" :href="data.website" target="_blank">
        Visit Website
        <svg
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1107_16134)">
            <path
              d="M7.28027 6.09801L11.7803 3.50001L7.28027 0.902008V3.05001H0.755875V3.95001H7.28027V6.09801Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1107_16134">
              <rect
                width="12"
                height="6"
                fill="white"
                transform="matrix(-1 0 0 1 12.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: { description: "", title: " ", content: "", thumnail: "" },
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin title-font {
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;

  @media (min-width: 992px) {
    font-size: 32px;
  }
}

.portfolio-item-main {
  position: relative;

  &:not(:last-child) {
    padding-right: 20px;
    @media (min-width: 768px) {
      padding-right: 0;
    }
  }

  min-width: 95%;

  @media (min-width: 768px) {
    min-width: 150%;
  }

  @media (min-width: 992px) {
    min-width: 125%;
  }

  @media (min-width: 1440px) {
    min-width: 95%;
  }

  --img-width: 100%;
  --img-height: 450px;
  @media screen and (min-width: 576px) {
    --img-width: 720px;
    --img-height: 412px;
  }

  @media screen and (min-width: 1500px) {
    --img-width: 720px;
    --img-height: 450px;
  }

  .portfolio-img-wrapper {
    .img {
      position: relative;
      overflow: hidden;
      height: 215px;

      @media screen and (min-width: 576px) {
        width: var(--img-width);
        height: var(--img-height);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .corner-label-txt {
        text-align: center;
        display: none;
      }
      .corner-label-none {
        text-align: center;
        display: block;
      }
      &:hover {
        .corner-label-txt {
          display: block;
          cursor: pointer;
        }
        .corner-label-none {
          display: none;
        }
      }
      .corner-label {
        position: absolute;
        top: 18px;
        left: -108px;

        padding: 4px 16px;
        text-transform: uppercase;
        transform: rotate(-45deg);
        transform-origin: center;
        width: 300px;
      }
    }

    .title {
      max-width: var(--img-width);
      @include title-font;
      text-transform: uppercase;
      color: #000000;
      border-bottom: 1px solid #000000;
    }

    .founder {
      max-width: var(--img-width);

      .founder-item {
        font-size: 14px;
        line-height: 14px;
        @media screen and (min-width: 576px) {
          font-size: 18px;
          line-height: 18px;
          margin-left: 20px;
        }

        .linkedin-link {
          margin-left: 10px;
          margin-top: 3px;
          display: inline-block;
          cursor: pointer;
          img {
            width: 24px;
          }
        }

        .twitter-link {
          img {
            border-radius: 6px;
            overflow: hidden;
          }
        }
      }
    }
  }

  .portfolio-item-content {
    position: relative;
    background: #ffffff;
    z-index: 1;
    margin-top: 24px;
    max-width: 450px;

    @media screen and (min-width: 576px) {
      position: absolute;
      top: 43%;
      left: 0px;
      height: 350px;
      padding: 16px;
      margin: 0;
    }

    @media screen and (min-width: 768px) {
      top: 40%;
      left: 450px;
      height: 350px;
    }

    @media screen and (min-width: 1024px) {
      width: 650px;
      top: 32%;
      left: 540px;
    }

    @media screen and (min-width: 1500px) {
      top: 43%;
    }

    .title-item {
      @include title-font;
      color: #000000;
    }

    .content-item {
      font-family: "SF Pro Text";
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      margin: 16px 0;

      /* overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical; */
    }
  }
}
</style>
