<template>
  <div class="header headerInvestor" :class="deviceVersion">
    <div class="headerWrapper">
      <div class="textLogo px-1">
        <a href="/">
          <logo :classExt="'pc'" />
        </a>
      </div>
      <div class="menu" v-if="deviceVersion === 'pc'" :class="pageSection">
        <ul>
          <template
            v-for="item in tabs.filter(
              (x) => !x.permission || hasPermission(x.permission)
            )"
            :key="item"
          >
            <li
              v-if="
                item.component !== 'request-form' ||
                (item.component === 'request-form' && profile.allow_referral)
              "
            >
              <a
                v-if="!item.isExternalLink"
                :href="item.link"
                @click.prevent="routePage(item.component)"
                :class="menuClass(item)"
              >
                <span>{{ item.text }}</span>
              </a>
              <a
                v-else
                :href="item.link"
                target="_blank"
                :class="menuClass(item)"
              >
                <span>{{ item.text }}</span>
              </a>
            </li>
          </template>
        </ul>
        <div class="user" v-if="profile && profile.email">
          <div class="dropdown">
            <button
              class="btn colorWhite dropdown-toggle w-100"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div>
                <div class="el-username">{{ username }}</div>
                <div class="el-fund-name">
                  {{ investorInfo ? investorInfo.selected_fund.name : "-" }}
                </div>
              </div>
            </button>
            <div
              class="dropdown-menu logout-menu"
              aria-labelledby="dropdownMenuLink"
            >
              <router-link
                class="dropdown-item"
                :to="profilePath"
                v-if="profile.userType === 'Investor'"
                >{{ profileName }}</router-link
              >
              <a
                v-if="isShowSelectLpFund"
                class="dropdown-item cursor-pointer"
                style="display: flex; justify-content: space-between; gap: 10px"
                @click.prevent="handleChangeFund"
                ><span>Return to fund list </span>
                <span> &#8594;</span>
              </a>
              <a class="dropdown-item" href="#logout" @click.prevent="logout"
                >Logout</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  z-index: 1;
  display: block;
  height: 65px;
  width: 100%;
  background-color: #cccccc;
  padding: 0;
  &.headerInvestor {
    position: static;
  }
  .headerWrapper {
    display: flex;
    padding-left: 20px;
    .textLogo {
      flex: 0 0 150px;
      padding: 10px 0;
      text-align: center;
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        // margin-left: 30px;
      }
      img {
        width: 100%;
        vertical-align: middle;
        display: inline-block;
        margin: 9px 0;
      }
    }
    .menu {
      flex: 1;
      display: flex;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex: 1;
        width: 100%;
        li {
          flex: 1;
          text-align: center;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            height: 65px;
            // line-height: 35px;
            max-width: 130px;
            color: rgb(0, 0, 0);
            padding: 0;
            &.multiLine {
              //   text-align: center;
              line-height: 18px;
              //   vertical-align: middle;
              //   padding: 15px 0;
            }
            &:hover {
              color: #5897e7;
            }
            &.active {
              position: relative;
              color: #5897e7;

              &:before {
                content: "";
                display: block;
                overflow: hidden;
                width: 100%;
                height: 5px;
                background-color: #5897e7;
                left: 0;
                bottom: 0;
                position: absolute;
              }
            }
            > span {
              vertical-align: middle;
            }
          }
        }
      }
      .user {
        flex: 0 0 160px;
        height: 65px;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 10px;
        text-align: right;
        background-color: #313131;
        .dropdown {
          button {
            border: 0px;
          }
        }
        .logout-menu {
          transform: translate3d(-10px, 30px, 0px) !important;
        }
        .btn {
          background: transparent;
          color: #fff;
          padding: 0 10px;
          line-height: 30px;

          display: flex;
          align-items: center;
          justify-content: space-between;
          &:focus {
            box-shadow: unset;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023.98px) {
  .header {
    background-color: #000;
    padding-top: 14px;
    .headerWrapper {
      padding-left: 54px;
      .menu {
        .user {
          padding: 4px 10px;
        }
      }
    }
  }
}

.el-username,
.el-fund-name {
  line-height: 120%;

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-username {
  font-size: 17px;
}

.el-fund-name {
  font-size: 14px;
}
</style>
