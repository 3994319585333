<template>
  <Modal title="Invite Founder" v-model:value="isShow">
    <form @submit.prevent="save" autocomplete="false">
      <div
        class="row position-relative"
        v-for="(user, userI) in form.users"
        :key="`${userI}userI`"
      >
        <div class="remove-user" v-if="userI > 0" @click="removeUser(userI)">
          <i class="fas fa-minus-circle"></i>
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="First Name"
            :name="`fname${userI}`"
            :autocomplete="false"
            :errorMsg="errorMsg.users[userI].firstname"
            v-model:value="user.firstname"
            @handleValue="handleFirstName"
          />
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="Last name"
            :name="`lname${userI}`"
            :autocomplete="false"
            :errorMsg="errorMsg.users[userI].lastname"
            :value="user.lastname"
            @update:value="user.lastname = $event"
            @handleValue="handleLastName"
          />
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="Email"
            :name="`email${userI}`"
            :autocomplete="false"
            :errorMsg="errorMsg.users[userI].email"
            v-model:value="user.email"
            @handleValue="handleEmail"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <span class="btn btn-primary btn-small" @click="addUser">+</span>
        </div>
        <div class="col col-12 col-md-4">
          <SelectField
            title="Portfolio"
            v-model:value="form.portfolioId"
            :options="partnerList"
            :errorMsg="errorMsg.portfolioId"
          />
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <NavTab v-model:value="selectedTab" :tabs="tabs">
            <template #email_content>
              <div class="row">
                <div class="col col-12 col-md-12">
                  <TextField
                    title="Subject"
                    name="subject"
                    :autocomplete="false"
                    :errorMsg="errorMsg.subject"
                    v-model:value="form.subject"
                    :fieldRequire="true"
                  />
                </div>
                <div class="col col-12 col-md-12">
                  <TextArea
                    v-model:value="form.body"
                    title="Body"
                    name="body"
                    :autocomplete="false"
                    :errorMsg="errorMsg.body"
                    :fieldRequire="true"
                  />
                </div>
              </div>
            </template>
            <template #saved_templates>
              <SelectEmailTemplate v-model:value="appliedTmpl" />
            </template>
          </NavTab>
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="validData" :loading="loading"> Invite </NewButton>
    </template>
  </Modal>
</template>

<script src="./AddFounderModel.js"></script>
<style lang="scss" scoped>
.remove-user {
  color: #ff7171;
  width: auto;
  font-size: 1.2em;
  position: absolute;
  padding: 5px;
  right: 0px;
  top: 7px;
  z-index: 1;
  cursor: pointer;
}
</style>
