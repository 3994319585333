<template>
  <div class="adminContent p-3">
    <div class="data p-3" v-loading="loading" v-if="readyRender">
      <div class="row mb-3" v-if="false">
        <div class="col-12 text-right">
          <div class="submit pointer mb-3" @click="addNewPartner">
            <span class="icon"><i class="fas fa-plus"></i></span
            ><span class="text">Add Partner</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive-sm">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Company</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listData" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.cname }}</td>
                  <td class="text-end">
                    <router-link
                      class="btn btn-primary btn-small me-1"
                      :to="{
                        path: ownershipPath,
                        name: ownershipName,
                        params: { userId: item.id },
                      }"
                      title="Ownership"
                      ><i class="fas fa-hand-holding-usd"></i
                    ></router-link>
                    <router-link
                      class="btn btn-primary btn-small me-1"
                      :to="{
                        path: partnerGoalPath,
                        name: partnerGoalName,
                        params: { userId: item.id },
                      }"
                      title="Goals"
                      ><i class="fas fa-tasks"></i
                    ></router-link>
                    <router-link
                      class="btn btn-primary btn-small me-1"
                      :to="{
                        path: partnerDataViewPath,
                        name: partnerDataViewName,
                        params: { userId: item.id },
                      }"
                      title="Chart view"
                      ><i class="far fa-chart-bar"></i
                    ></router-link>
                    <router-link
                      class="btn btn-primary btn-small"
                      :to="{
                        path: partnerKpiPath,
                        name: partnerKpiName,
                        params: { portfolioId: item.id },
                      }"
                      title="Kpi input"
                      ><i class="fa fa-list-alt"></i
                    ></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pager
            :loadContentAtPage="loadPage"
            :page="paging.page"
            :size="paging.size"
            :total="paging.total"
            :pagerFull="false"
            v-if="paging.total > paging.size"
          ></pager>
        </div>
      </div>
    </div>
    <addPartner
      v-if="isShowAddPartner"
      :hideAddPartner="hideAddPartner"
      :updatePartner="getPartners"
    />
  </div>
</template>
<script src="./index.js"></script>
