import { defineComponent } from "vue";
import appStore from "@/store/app";
import AdminServices from "../../../services/admin";

export default defineComponent({
  name: "intro",
  data() {
    return {
      showPlayButton: true,
      checkInterval: null,
      introVideo_URL: "",
    };
  },
  computed: {
    investorInfo() {
      return appStore.state.investorInfo;
    },
  },
  watch: {
    investorInfo: {
      deep: true,
      handler(v) {
        this.initialLoading();
      },
    },
  },
  methods: {
    play() {
      this.showPlayButton = false;
      this.$refs.introVideo.play();
    },
    checkPlaying() {
      if (this.$refs.introVideo && !this.$refs.introVideo.paused) {
        this.showPlayButton = false;
        this.removeCheckPlaying();
        return false;
      }
      return true;
    },
    removeCheckPlaying() {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    },
    initialLoading() {
      this.loading = true;
      const videoId = this.investorInfo && this.investorInfo.selected_fund
        ? this.investorInfo.selected_fund.video_id
        : null;
      if (videoId != null) {
        AdminServices.requestDownloadCIOAndCountView(videoId, 'View')
        .then((resp) => {
          if (resp.data.ok) {
            this.introVideo_URL = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialLoading();
      this.checkInterval = setInterval(this.checkPlaying, 100);
    });
  },
  beforeUnmount() {
    this.removeCheckPlaying();
  },
});
