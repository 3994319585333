import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: "tree-item",
    props: {
        item: Object,
        selectedId: Number,
        selectedParentId: Number,
        isMainTree: Boolean
    },
    data() {
        return {
            isOpen: false,
            result: [],
            delay: 300,
            clicks: 0,
            timer: null
        }
    },
    computed: {
        isFolder() {
          return this.item.type === 'Folder'
        },
        children() {
            return _.orderBy(this.item.children, ['order_number'], ['asc']);
        }
    },
    watch: {
        selectedParentId(newVal, oldVal) {
            if (newVal === this.item.id) {
                this.isOpen = true
            }
        }
    },
    methods: {
        toggle() {
            if (this.isFolder) {
                this.isOpen = !this.isOpen
            }
        },
        selectFolder() {
            this.$emit("select-folder", this.item)
        },
        selectAndToogle() {
            this.toggle()
            this.selectFolder()
        },
        oneClick(){
            this.clicks++ 
            if (this.clicks === 1) {
              let self = this
              this.timer = setTimeout(() => {
                this.selectFolder()
                self.clicks = 0
              }, this.delay)
            } else {
               clearTimeout(this.timer)
               this.selectAndToogle()
               this.clicks = 0
            }        	
        }
    }
})