import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'
import notify from '../../../../services/notify'

export default defineComponent({
    name: 'restoreFileItems',
    props: ['items', 'hideRestoreFileItems'],
    data() {
        return {
            loadingFolder: false,
            loading: false,
            folderDetail: null,
            selectedFolderId: null,
            itemsToRestore: [],
            delay: 300,
            clicks: 0,
            timer: null,
            completed: false
        }
    },
    created() {
        this.itemsToRestore = this.items
        this.goToFolder(0)
    },
    computed: {
        isValidToProcess() {
            if (this.completed) {
                return false
            } else {
                let _selectedItemNames = this.itemsToRestore.map(y => y.name)
                if (_selectedItemNames.length > 0) {
                    let intersections = _selectedItemNames.filter((e, index) => _selectedItemNames.indexOf(e) != index)
                    if (intersections && intersections.length > 0) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            }
        }
    },
    methods: {
        async loadFolderDetail(id) {
            let _folder_resp = await AdminServices.getDataroomItemWithChildren(id)
            if (_folder_resp.data.ok) {
                this.folderDetail = _folder_resp.data.d 
            } else {
                this.folderDetail = null
            }
        },

        async goToFolder(id) {
            this.loadingFolder = true
            this.selectedFolderId = id
            await this.loadFolderDetail(id)
            this.loadingFolder = false
        },
        
        oneClick(item) {
            if (item.type === 'Folder') {
                this.clicks++ 
                if (this.clicks === 1) {
                    let self = this
                    this.timer = setTimeout(() => {
                        self.clicks = 0
                    }, this.delay)
                } else {
                    clearTimeout(this.timer)
                    this.goToFolder(item.id)
                    this.clicks = 0
                }
            }
        },

        restore() {
            if (this.isValidToProcess) {
                this.loading = true
                AdminServices.restoreDataroomFileItems({
                    ids: this.itemsToRestore.map(x => x.id),
                    parentId: this.selectedFolderId
                }).then(resp => {
                    this.completed = true
                    if (resp.data.ok) {
                        this.itemsToRestore = this.itemsToRestore.map(x => {
                            return {
                                ...x,
                                isRestored: true
                            }
                        })
                        notify.success('Restore items successfully!')
                        this.hideRestoreFileItems()
                    }
                }).finally(() => { this.loading = false })
            }
        },
    }
})