<template>
  <div class="form-control-2-wrapper">
    <div class="form-control-2" :class="[isOpen || selected ? 'open' : '']">
      <VueMultiselect
        :class="`select-style-v2`"
        :id="name"
        v-model="selected"
        :options="options"
        :multiple="multiple"
        :close-on-select="multiple ? false : true"
        :clear-on-select="false"
        :searchable="false"
        placeholder=""
        :index="indexField"
        :taggable="true"
        @open="onOpen"
        @close="onClose"
      >
        <template slot="selection" slot-scope="{ values, isOpen }"
          ><span class="multiselect__single" v-if="values.length && !isOpen"
            >{{ values.length }} options selected</span
          ></template
        >
      </VueMultiselect>

      <label for="field" class="form-label"
        >{{ title }}
        <!-- <template v-if="fieldRequire"
          ><small class="require">*</small></template
        > -->
      </label>
    </div>
    <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import VueMultiselect from "vue-multiselect";

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: [String, Object], default: "" },
    options: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    color: { type: String, default: "#000" },
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    indexField: { type: Number, default: 0 },
    name: { type: String, default: null },
    field: { type: String, default: "" },
  },
  components: {
    VueMultiselect,
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      isOpen: false,
      selected: null,
    };
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
    selected(v) {
      this.$emit("update:value", v);
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        if (this.selected) return "";
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  methods: {
    onOpen() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.form-control-2 {
  .multiselect__tags {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    background: transparent;
    padding-left: 0;
    padding-top: 0;
    border-radius: 0;

    min-height: 45px;
  }

  .multiselect__tags-wrap {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .multiselect__single,
  .multiselect__input {
    /* padding: 10px 0 !important; */
    background: transparent;
  }

  .multiselect__single {
    margin: 0;

    padding: 15px 0 10px;
  }

  .multiselect__input {
    margin-top: 8px !important;
    font-size: 20px !important;
  }

  .multiselect__single {
    font-size: 20px !important;
  }
}
</style>
<style lang="scss" scoped>
.form-control-2-wrapper {
  .md-error {
    color: #ee6e73;
    font-size: 14px;
  }
}

.form {
  font-family: "SF Pro Text";

  &-control-2 {
    position: relative;
  }

  &-label {
    position: absolute;
    font-family: inherit;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    top: 0.5rem;
    left: 0;
    width: 100%;
    color: #aaaaaa;
    transition: all 0.2s ease;
    z-index: -1;

    @media (min-width: 768px) {
      font-size: 22px;
    }

    .require {
      color: #ee6e73;
    }
  }

  &-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 20px;
    padding: 10px 0;
    display: block;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }
  }
}

.open {
  .form-label {
    top: -0.75rem;
    left: 0;
    font-size: 14px;
    z-index: 9;
    transition: all 0.3s ease-in-out;
  }
}

.form-control-2 {
  padding-top: 6px;
  &:focus {
    .form-label {
      background: red;
      top: -0.75rem;
      left: 0;
      font-size: 14px;
      z-index: 9;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
    }
  }
}
</style>
