<template>
  <Modal title="Edit Investor Information" v-model:value="isShow">
    <NavTab v-model:value="selectedTab" :tabs="tabs">
      <template #profile>
        <!-- <form @submit.prevent="save" :autocomplete="false"> -->
        <div class="row">
          <div class="col-12 mb-4">
            <Switcher
              v-model:value="form.allow_referral"
              name="allow_referral"
              title="Allow Referral"
            />
          </div>
          <div class="col-12 col-md-6">
            <TextField
              title="First Name"
              name="fname"
              :autocomplete="false"
              :errorMsg="errorMsg.fname"
              v-model:value="form.fname"
            />
          </div>
          <div class="col-12 col-md-6">
            <TextField
              title="Last Name"
              name="lname"
              :autocomplete="false"
              :errorMsg="errorMsg.lname"
              v-model:value="form.lname"
            />
          </div>
          <div class="col-12 col-md-6">
            <TextField
              title="Email"
              name="email"
              type="email"
              :disabled="true"
              :autocomplete="false"
              :errorMsg="errorMsg.email"
              v-model:value="form.email"
            />
          </div>
          <div class="col-12 col-md-6">
            <Datepicker
              placeholder="MM/DD/YYYY"
              :format="customFormatter"
              field="black"
              :errorMsg="errorMsg.expires_datetime"
              title="Expired Datetime"
              name="Expired_Datetime"
              v-model:value="tmpExpiredTime"
            />
          </div>
          <div class="col-12 col-md-6">
            <SelectField
              v-model:value="form.investor_type"
              title="Type"
              name="investor-type"
              :options="investorTypeList.filter((x) => x.code !== '')"
              :errorMsg="errorMsg.investor_type"
            />
          </div>
          <div class="col-12 col-md-6">
            <SelectField
              v-model:value="form.role_id"
              title="Role"
              name="role"
              :options="roleList.filter((x) => x.id !== '')"
              valueProp="id"
              :errorMsg="errorMsg.role_id"
            />
          </div>
          <div
            class="col col-12 col-md-6 w-100 position-relative"
            style="margin-bottom: 25px"
          >
            <label class="title mb-0">
              Select Funds
              <small class="require" style="color: #f52727">*</small></label
            >
            <customSelect
              v-model:value="form.lp_fund_ids"
              :options="lpFundOptions"
              isMultiple
              required
              label="Select Funds"
              class="w-100"
            />
            <div v-if="errorMsg.lp_fund_ids" class="md-error">
              {{ errorMsg.lp_fund_ids }}
            </div>
          </div>

          <div class="col-12 col-md-6">
            <TextField
              title="Password"
              name="pass"
              :autocomplete="false"
              :errorMsg="errorMsg.password"
              type="password"
              :hideError="false"
              v-model:value="form.password"
              iconPasswordColor="white"
            />
          </div>
          <div class="col-12 col-md-6">
            <br />
            <NewButton :loading="loading" @click="resetPassword()"
              >Reset password</NewButton
            >
          </div>
        </div>
        <!-- </form> -->
      </template>
      <template #nda>
        <div class="row" v-if="!form.old_investor">
          <div class="col col-12">
            <div class="form-group mb-0">
              <label class="pointer noselect">
                <input
                  class="me-1"
                  :disabled="form.nda_signed"
                  type="checkbox"
                  v-model="form.nda_sign_required"
                  value="true"
                /><span class="ml-1">NDA Required</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col col-12 col-md-6 form-group"
            v-if="!(form.old_investor && !form.nda_signed_datetime)"
          >
            <div class="d-flex flex-column" v-if="form.nda_signed_datetime">
              <label>
                <span>Signing Datetime: </span>
                <span class="ml-1">{{
                  $filters.parseEstTime(form.nda_signed_datetime)
                }}</span>
              </label>
              <button
                class="btn btn-primary btn-small"
                @click="downloadInvestorNdaForm"
              >
                <i class="fas fa-file-download me-1"></i> Download NDA
              </button>
            </div>
            <div v-else-if="ndaSignRequired">
              <span class="warning">This user has not signed the NDA Form</span>
            </div>
          </div>
          <div
            class="col col-12 col-md-6"
            v-if="!form.nda_signed && form.old_investor"
          >
            <div class="form-group mb-1">
              <label for="ndaFile" class="mb-0">Upload NDA</label>
              <input
                class="pointer"
                type="file"
                ref="file"
                id="ndaFile"
                style="width: 100%"
                accept="application/pdf"
                @change="renderFileNda"
              />
              <template>
                <transition name="fade">
                  <div
                    class="fileinfo mt-2 d-flex align-items-baseline"
                    v-if="fileUploaded"
                  >
                    <i class="far fa-file-alt me-1"></i>
                    <span class="info">{{ fileUploaded }}</span>
                    <span class="close ml-2" @click="removeFileUploaded"
                      ><i class="fas fa-times"></i
                    ></span>
                  </div>
                </transition>
              </template>
            </div>
          </div>
        </div>
      </template>
    </NavTab>
    <template #footer>
      <NewButton @click="save" :loading="loading">Update</NewButton>
    </template>
  </Modal>
</template>

<script src="./UpdateInvestorModel.js"></script>

<style lang="scss" scoped>
.fileinfo {
  color: #afafaf;
  font-weight: 400;
  .info {
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  .close {
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    float: none;
  }
}
.warning {
  font-size: 14px;
  color: orange;
}

.md-error {
  position: absolute;
  color: #f52727;
  font-size: 0.8em;
}
</style>
