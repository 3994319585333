<template>
  <div class="mapData" :class="coms">
    <LPFundModal />
    <div>
      <div
        class="btn-swap pointer"
        :class="{ show: isShowLeftMenu }"
        @click="swapmenu"
        v-if="deviceVersion !== 'pc'"
      >
        <span class="pe pe-7s-close" v-if="isShowLeftMenu"></span>
        <div class="bm-burger-button" v-else>
          <span class="bm-burger-bars line-style" style="top: 0%"></span>
          <span class="bm-burger-bars line-style" style="top: 40%"></span>
          <span class="bm-burger-bars line-style" style="top: 80%"></span>
        </div>
      </div>
      <div class="logo-mobile" v-if="deviceVersion !== 'pc'">
        <a href="/">
          <logo :classExt="'not-pc'" width="160px" />
        </a>
      </div>
      <topHeader :compts="compts" v-if="screenSize > 1023.97"></topHeader>
      <div class="contentComponents" :class="coms" v-else>
        <leftMenu
          :compts="compts"
          :isShowLeftMenu="isShowLeftMenu"
          :hideMenu="hideMenu"
          v-if="deviceVersion !== 'pc'"
        />
      </div>
      <div class="contentComponents-custom">
        <component v-bind:is="coms" v-if="coms"></component>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mapData {
  background: #141518;
  display: block;
  overflow: hidden;
  &.replay {
    height: 100vh;
  }
  .btn-swap {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 8px;
    display: block;
    overflow: hidden;
    width: 40px;
    // background-color: rgba(0, 0, 0, 0.8);
    height: 40px;
    padding: 10px 5px 10px 5px;
    .bm-burger-button {
      top: 9px;
    }
    .pe {
      color: #fff;
      font-size: 28px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
  .container {
    max-width: 1276px;
    width: 100%;
    background: #1e2128;
    padding: 0px;
  }
  .contentComponents {
    background-color: #1e2128;
    // height: 65px;
    &.event {
      height: auto;
    }
  }
  .contentComponents-custom {
    background-color: #1e2128;
    height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .logo-mobile {
    height: 65px;
    flex: 0 0 150px;
    padding: 18px 0;
    margin-left: 60px;
    img {
      height: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
.map {
  min-height: 500px;
}
</style>
