import recaptcha from "./recaptcha";
import sparkline from "./sparkline";
import qrCode from "./qr-code";
import inputNumber from "./input-number";
import ratio from "./ratio";
import pager from "./pager";
import paging from "./paging";
import collapse from "./collapse";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import Keyboard from './keyboard'
// import NumbericKeyboard from './numberic-keyboard'
import buttonOnOff from "./toggle-button";
import bannerHeader from "../commons/banner-header";
import chartView from "../commons/chartView";
import contextMenu from "../commons/contextMenu";
import dropdownMenu from "../commons/dropdownMenu";
import logoComponent from "../commons/logo";

export default [
  recaptcha,
  sparkline,
  qrCode,
  inputNumber,
  ratio,
  pager,
  paging,
  collapse,
  VuePerfectScrollbar,
  // Keyboard,
  // NumbericKeyboard,
  buttonOnOff,
  bannerHeader,
  chartView,
  contextMenu,
  dropdownMenu,
  logoComponent,
];
