<template>
    <transition name="fade">
        <div class="termandconditions">
            <div class="wrap">
                <div class="content colorBlack">
                    <div class="a4">
                    <h4 class="nda-title">NON-DISCLOSURE AGREEMENT</h4>
                    <p>THIS AGREEMENT (the <b>“Agreement”</b>) is entered into on this ____ day of ___________ by and between <b>Fuel Venture Capital</b>, (the <b>“Disclosing Party”</b>), and ___________________________ (the <b>“Receiving Party”</b>).</p>
                    <p>The Receiving Party hereto desires to participate in discussions regarding Fuel Venture Capital investment offering(s) (the <b>“Fund”</b>). During these discussions, Disclosing Party may share certain proprietary information with the Receiving Party. Therefore, in consideration of the mutual promises and covenants contained in this Agreement, and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties hereto agree as follows:</p>
                    <h5>1. Definition of Confidential Information.</h5>
                    <p>(a) For purposes of this Agreement, <b>“Confidential Information”</b> means any data or information that is
proprietary to the Disclosing Party and not generally known to the public, whether in tangible or
intangible form, in whatever medium provided, whether unmodified or modified by Receiving Party or its
Representatives (as defined herein), whenever and however disclosed, including, but not limited to: (i)
any marketing strategies, plans, financial information, or projections, operations, sales estimates, business
plans, investment methodologies, and performance results relating to the past, present or future business
activities of such party, its affiliates, subsidiaries and affiliated companies; (ii) plans for products or
services, and customer or supplier lists; (iii) any scientific or technical information, invention, design,
process, procedure, formula, improvement, technology or method; (iv) portfolio companies, potential
portfolio companies, business prospects, any concepts, reports, data, know-how, works-in-progress,
designs, development tools, specifications, computer software, source code, object code, flow charts,
databases, inventions, information and trade secrets; (v) any other information that should reasonably be
recognized as confidential information of the Disclosing Party; and (vi) any information generated by the
Receiving Party or by its Representatives that contains, reflects, or is derived from any of the foregoing.
Confidential Information need not be novel, unique, patentable, copyrightable or constitute a trade secret
in order to be designated Confidential Information. The Receiving Party acknowledges that the
Confidential Information is proprietary to the Disclosing Party, has been developed and obtained through
great efforts by the Disclosing Party and that Disclosing Party regards all of its Confidential Information
as trade secrets.</p>
                <p>(b) Notwithstanding anything in the foregoing to the contrary, Confidential Information shall not include
information which: a) was lawfully possessed, as evidenced by the Receiving Party’s records, by the
Receiving Party prior to receiving the Confidential Information from the Disclosing Party; (b) becomes
rightfully known by the Receiving Party from a third-party source not under an obligation to Disclosing
Party to maintain confidentiality; (c) is generally known by the public through no fault of or failure to act
by the Receiving Party inconsistent with its obligations under this Agreement; (d) is required to be
disclosed in a judicial or administrative proceeding, or is otherwise requested or required to be disclosed
by law or regulation, although the requirements of paragraph 4 hereof shall apply prior to any disclosure
being made; and (e) is or has been independently developed by employees, consultants or agents of the
Receiving Party without violation of the terms of this Agreement, as evidenced by the Receiving Party’s
records, and without reference or access to any Confidential Information.</p>
                <h5>2. Disclosure of Confidential Information.</h5>
                <p>From time to time, the Disclosing Party including all its officers, affiliates, employees or representatives
may disclose Confidential Information to the Receiving Party. The Receiving Party will: (a) limit 
disclosure of any Confidential Information to its acquaintances, relatives, directors, officers, employees,
or agents (collectively <b>“Representatives”</b>) who have a need to know such Confidential Information in
connection with the current or contemplated business relationship between the parties to which this
Agreement relates, and only for that purpose; (b) advise its Representatives of the proprietary nature of
the Confidential Information and of the obligations set forth in this Agreement, require such
Representatives to be bound by written confidentiality restrictions no less stringent than those contained
herein, and assume full liability for acts or omissions by its Representatives that are inconsistent with its
obligations under this Agreement; (c) keep all Confidential Information strictly confidential by using a
reasonable degree of care, but not less than the degree of care used by it in safeguarding its own
confidential information; and (d) not disclose any Confidential Information received by it to any third
parties (except as otherwise provided for herein).</p>
                <h5>3. Use of Confidential Information.</h5>
                <p>The Receiving Party agrees to use the Confidential Information solely in connection with the current or
contemplated business relationship between the parties and not for any purpose other than as authorized
by this Agreement without the prior written consent of an authorized representative of the Disclosing
Party. No other right or license, whether expressed or implied, in the Confidential Information is granted
to the Receiving Party hereunder. Title to the Confidential Information will remain solely in the
Disclosing Party. All use of Confidential Information by the Receiving Party shall be for the benefit of
the Disclosing Party and any modifications and improvements thereof by the Receiving Party shall be the
sole property of the Disclosing Party.</p>
                <h5>4. Compelled Disclosure of Confidential Information.</h5>
                <p>Notwithstanding anything in the foregoing to the contrary, the Receiving Party may disclose Confidential
Information pursuant to any governmental, judicial, or administrative order, subpoena, discovery request,
regulatory request or similar method, provided that the Receiving Party promptly notifies, to the extent
practicable, the Disclosing Party in writing of such demand for disclosure so that the Disclosing Party, at
its sole expense, may seek to make such disclosure subject to a protective order or other appropriate
remedy to preserve the confidentiality of the Confidential Information; provided that the Receiving Party
will disclose only that portion of the requested Confidential Information that, in the written opinion of its
legal counsel, it is required to disclose. The Receiving Party agrees that it shall not oppose and shall
cooperate with efforts by, to the extent practicable, the Disclosing Party with respect to any such request
for a protective order or other relief. Notwithstanding the foregoing, if the Disclosing Party is unable to
obtain or does not seek a protective order and the Receiving Party is legally requested or required to
disclose such Confidential Information, disclosure of such Confidential Information may be made without
liability.</p>
                <h5>5. Term.</h5>
                <p>Notwithstanding the foregoing, the Receiving Party’s duty to hold in confidence Confidential Information
that was disclosed during term shall remain in effect for 10 years from the date hereof, unless Disclosing Party
expressly provides in writing permission to share specific Confidential Information per occurrence.
Permission to use Confidential Information is a one-time permission for a selected period of time and is
not a permission indefinitely. Additional usage of already permitted Confidential Information is not
permitted without additional written permission per occurrence. All items that logically ought to survive
the term of this Agreement shall survive.</p>
                <h5>6. Remedies.</h5>
                <p>Both parties acknowledge that the Confidential Information to be disclosed hereunder is of a unique and
valuable character, and that the unauthorized dissemination of the Confidential Information would destroy
or diminish the value of such information. The damages to Disclosing Party that would result from the
unauthorized dissemination of the Confidential Information would be impossible to calculate. Therefore,
both parties hereby agree that the Disclosing Party shall be entitled to injunctive relief preventing the
dissemination of any Confidential Information in violation of the terms hereof. Such injunctive relief shall
be in addition to any other remedies available hereunder, whether at law or in equity. Disclosing Party
shall be entitled to recover its costs and fees, including reasonable attorneys’ fees, incurred in obtaining
any such relief. Further, in the event of litigation relating to this Agreement, the prevailing party shall be
entitled to recover its reasonable attorney’s fees and expenses.</p>
                <h5>7. Return of Confidential Information.</h5>
                <p>Receiving Party shall immediately return and redeliver to Disclosing Party all tangible material
embodying any Confidential Information provided hereunder and all notes, summaries, memoranda,
drawings, manuals, records, excerpts or derivative information deriving therefrom, and all other
documents or materials (“Notes”) (and all copies of any of the foregoing, including “copies” that have
been converted to computerized media in the form of image, data, word processing, or other types of files
either manually or by image capture) based on or including any Confidential Information, in whatever
form of storage or retrieval, upon the earlier of (i) the completion or termination of the dealings between
the parties contemplated hereunder; (ii) the termination of this Agreement; or (iii) at such time as the
Disclosing Party may so request. Alternatively, the Receiving Party, with the written consent of the
Disclosing Party shall immediately destroy any of the foregoing embodying Confidential Information
and, upon request, certify in writing such destruction by an authorized officer of the Receiving Party
supervising the destruction.</p>
                <h5>8. Notice of Breach.</h5>
                <p>Receiving Party shall notify the Disclosing Party immediately upon discovery of, or suspicion of, (1) any
unauthorized use or disclosure of Confidential Information by Receiving Party or its Representatives; or
(2) any actions by Receiving Party or its Representatives inconsistent with their respective obligations
under this Agreement, Receiving Party shall cooperate with any and all efforts of the Disclosing Party to
help the Disclosing Party regain possession of Confidential Information and prevent its further
unauthorized use.</p>
                <h5>9. No Binding Agreement for Transaction.</h5>
                <p>The parties agree that neither party will be under any legal obligation of any kind whatsoever with respect
to a Transaction by virtue of this Agreement, except for the matters agreed to herein. The parties further
acknowledge and agree that they each reserve the right, in their sole and absolute discretion, to reject any
and all proposals and to terminate discussions and negotiations with respect to a Transaction at any time.
This Agreement does not create a joint venture or partnership between the parties.</p>
                <h5>10. Miscellaneous.</h5>
                <p>(a) This Agreement constitutes the entire understanding between the parties and supersedes any and all
prior or contemporaneous understandings and agreements, whether oral or written, between the parties, 
with respect to the subject matter hereof. This Agreement can only be modified by a written amendment
signed by the party against whom enforcement of such modification is sought.</p>
                <p>(b) The validity, construction and performance of this Agreement shall be governed and construed in
accordance with the laws of Florida applicable to contracts made and to be wholly performed within such
state, without giving effect to any conflict of law’s provisions thereof. The Federal and state courts
located in Florida have sole and exclusive jurisdiction over any disputes arising under, or in any way
connected with or related to, the terms of this Agreement and Receiving Party: (i) consents to personal
jurisdiction therein; and (ii) waives the right to raise <i>forum non conveniens</i> or any similar objection</p>
                <p>(c) Any failure by either party to enforce the other party’s strict performance of any provision of this
Agreement will not constitute a waiver of its right to subsequently enforce such provision or any other
provision of this Agreement.</p>
                <p>(d) Although the restrictions contained in this Agreement are considered by the parties to be reasonable
for the purpose of protecting the Confidential Information, if any such restriction is found by a court of
competent jurisdiction to be unenforceable, such provision will be modified, rewritten or interpreted to
include as much of its nature and scope as will render it enforceable. If it cannot be so modified, rewritten
or interpreted to be enforceable in any respect, it will not be given effect, and the remainder of the
Agreement will be enforced as if such provision was not included.</p>
                <p>(e) Any notices or communications required or permitted to be given hereunder may be delivered by
hand, deposited with a nationally recognized overnight carrier, electronic-mail, or mailed by certified
mail, return receipt requested, postage prepaid, in each case, to the address of the other party first
indicated above (or such other addressee as may be furnished by a party in accordance with this
paragraph). All such notices or communications shall be deemed to have been given and received (a) in
the case of personal delivery or electronic-mail, on the date of such delivery, (b) in the case of delivery by
a nationally recognized overnight carrier, on the third business day following dispatch and (c) in the case
of mailing, on the seventh business day following such mailing.</p>
                <p>(f) This Agreement is personal in nature, and neither party may directly or indirectly assign or transfer it
by operation of law or otherwise without the prior written consent of the other party, which consent will
not be unreasonably withheld. All obligations contained in this Agreement shall extend to and be binding
upon the parties to this Agreement and their respective successors, assigns and designees.</p>
                <p>(g) Paragraph headings used in this Agreement are for reference only and shall not be used or relied upon
in the interpretation of this Agreement.</p>
                <p><b>IN WITNESS WHEREOF</b>, the parties hereto have executed this Agreement as of the date first above
written. </p>
</div>
                </div>
                <!-- <div class="robotic-block-bottom">
                    
                    <div class="robotic-block-left text-left">
                        <button class="btn btn-link ml-3 back pointer" @click="hideTermAndConditions">Back</button>  
                    </div>
                    <div class="robotic-block-right text-right">
                        <button class="btn btn-outline-light me-3 pointer" :class="acceptTOS?'border':''" :disabled="!acceptTOS" @click="moveSignature">Next</button>
                    </div>
                </div> -->
            </div>
        </div>
    </transition>
</template>
<style lang="scss" scoped>
.termandconditions{
    // position: absolute;
    // z-index: 6;
    // top: 0;
    // left: 0;
    color: #fff;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    font-family: 'Raleway';
    // background: url('../../../../src/assets/images/robotic/wallpaper.png') no-repeat center center #000;
    .wrap{
        h3{
            font-size: 40px;
            padding: 20px 0;
            font-family: 'Raleway';
        }
        .content{
            display: block;
            overflow: auto;
            border: 1px solid #fff;
            height: calc(100vh - 270px);
            margin: 0;

            .a4 {
                width: 100%;
                // background: #fff;
                margin: 20px auto;
                padding: 10px 30px;
                font-size: 15px;
                // font-family: "Times New Roman", Times, serif;
                color: #fff;

                h4.nda-title {
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                h5 {
                    font-size: 15px;
                    font-weight: bold;
                }
                b {
                    font-weight: bold;
                }
                i {
                    font-style: italic;
                }
            }
        }
        .acceptRule{
            .blockRule{
                display: inline-block;
                .customCheckbox{
                    display: inline-block;
                    overflow: hidden;
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    vertical-align: middle;
                    margin-right: 5px;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        display: block;
                        overflow: hidden;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #fff;
                        opacity: 0;
                        transition: opacity 300ms linear;
                    }
                    &.active{
                        &:before{
                            opacity: 1;
                        }
                    }
                }
                > span{
                    line-height: 26px;
                    vertical-align: middle;
                }
            }            
        }
        .robotic-block-bottom{
            position: absolute;
            z-index: 2;
            bottom: 47px;
            left: 0;
            display: flex;
            width: 100%;
            .robotic-block-left{
                flex: 1;
            }
            .robotic-block-center{
                flex: 1;
            }
            .robotic-block-right{
                flex: 1;
            }
            button{
                width: 156px;
                text-decoration: none;
                border-radius: 25px;
                &.btn-link{
                    color: #fff;
                }
                &.border{
                    border-radius: 25px;                    
                }
                &.no-border{
                    border: 0 !important;
                    cursor: no-drop !important;
                }
                &.back{
                    &:before{
                        content: '';
                        background: url('../../../../src/assets/images/robotic/left-arrow.svg') no-repeat;
                        display: inline-block;
                        vertical-align: middle;
                        width: 9px;
                        height: 16px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
</style>
<script src="./index.js"></script>

