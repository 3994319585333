<template>
  <div class="h-100 container-fluid" v-if="useEmptyLayout">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="row justify-content-center">
          <div
            class="col-sm-9 align-items-center justify-content-center"
            v-loading="loading"
          >
            <div class="d-flex align-items-center mt-3 mb-5">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>
            <div class="w-45 max-width margin-auto">
              <div v-if="success" class="colorWhite text-center">
                <h4>"Verify confirm invitation successfully!"</h4>
                <p class="colorGray">
                  "Your account has been created and will be logged in shortly.
                  If not logged in automatically, please click the link below."
                  <br />
                  <router-link
                    place="link"
                    class="colorWhite"
                    :to="{ path: loginPath, query: { ut: 'Investor' } }"
                    >Login</router-link
                  >
                </p>
              </div>
              <form
                id="loginForm"
                class="colorWhite"
                v-else
                :disabled="disabled"
              >
                <h2 class="font-weight-bold mb-2">Confirm Invitation</h2>
                <p class="">Enter password to complete invitation</p>
                <!-- <md-field class="mb-0">
                  <md-input :value="email" type="email" disabled></md-input>
                </md-field> -->

                <TextField
                  class="mb-0"
                  name="email"
                  :indexField="1"
                  :fieldRequire="true"
                  v-model:value="email"
                  type="email"
                  disabled
                />

                <!-- <md-field
                  class="mb-0 input-count"
                  md-has-password
                  v-bind:class="{ 'md-invalid': messageError.password }"
                >
                  <md-input
                    v-model="password"
                    type="password"
                    maxlength="20"
                    :placeholder="New password"
                  ></md-input>
                </md-field> -->

                <TextField
                  class="mb-0 input-count"
                  name="password"
                  :indexField="2"
                  :fieldRequire="true"
                  v-model:value="password"
                  type="password"
                  maxlength="20"
                  iconPasswordColor="white"
                  placeholder="New password"
                />

                <!-- <md-field
                  class="mb-4"
                  v-bind:class="{ 'md-invalid': messageError.password }"
                >
                  <md-input
                    v-model="confirmPassword"
                    type="password"
                    :placeholder="Confirm password"
                  ></md-input>
                  <small class="md-error">{{ messageError.password }}</small>
                </md-field> -->

                <TextField
                  class="mb-4"
                  :indexField="2"
                  :fieldRequire="true"
                  v-model:value="confirmPassword"
                  type="password"
                  name="confirm-password"
                  iconPasswordColor="white"
                  placeholder="Confirm password"
                  :errorMsg="messageError.password"
                />

                <div class="form-group">
                  <button
                    @click.prevent="confirm"
                    class="btn button btn-large btn-primary btn-bounce btn-active w-100"
                  >
                    Create account
                  </button>
                </div>
              </form>
              <p class="text-center colorGray">
                <span
                  >Already have an account?
                  <router-link class="link" :to="loginPath"
                    >Login now!</router-link
                  >
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-0">
        <div class="boxFix"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.colorDarkGray {
  color: #2e384d;
}
.colorGray {
  color: #b0bac9;
}
.logoLogin {
  img {
    object-fit: cover;
    width: 200px;
    height: auto;
  }
}
// .wrapper {
//   background-color: #fff;
// }
#loginForm {
  font-size: 0.9375rem;
  margin-top: 2rem;
  padding: 2rem;
  //color: #2e384d;
  // opacity: 0.8;
  // background-color: #fff;
  .signInText {
    font-size: 2.125rem;
  }
}
</style>
