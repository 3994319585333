import { defineComponent } from "vue";
import _ from "lodash";
import adminServices from "@/services/admin";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import FileField2 from "@/components/FileField2";
import Modal from "@/components/Modal";
import TextArea from "@/components/TextArea";
import Switcher from "@/components/Switcher";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import notify from "@/services/notify";
import NewButton from "@/components/NewButton";

export default defineComponent({
  components: {
    Modal,
    TextField,
    SelectField,
    FileField2,
    TextArea,
    Switcher,
    NewButton,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: null,
      formData: {
        memberId: null,
        title: null,
        description: null,
        thumbnail: {
          name: null,
          s3Key: null,
        },
        pdfFile: {
          name: null,
          s3Key: null,
        },
        isActive: true,
        itemImage: null,
        itemPdf: null,
      },
      errorMsg: {
        memberId: null,
        title: null,
        description: null,
        thumbnail: null,
        pdfFile: null,
      },
      id: null,
      itemImage: null,
      itemPdf: null,
      memberList: [],
      imageMime: ["image/jpeg", "image/png", "image/jpg"],
    };
  },
  methods: {
    show(props, memberList) {
      this.state = "UPDATE";
      this.isShow = true;
      this.id = props.perspective_id;
      if (memberList != null) {
        this.memberList = memberList.filter((x) => x.name != "All");
      }
      this.formData.memberId = props.member_id;
      this.formData.title = props.title;
      this.formData.description = props.description;
      this.formData.thumbnail = props.thumbnail;
      this.formData.pdfFile = props.pdf_file;
      this.formData.isActive = props.is_active;
    },

    create(memberList) {
      this.state = "CREATE";
      this.isShow = true;
      if (memberList != null) {
        this.memberList = memberList.filter((x) => x.name != "All");
        this.formData.memberId = this.memberList[0]["code"];
      }
    },

    watch: {
      "formData.title"() {
        if ($.trim(this.formData.title) === "") return;
        this.errorMsg.title = "";
      },
      "formData.description"() {
        if ($.trim(this.formData.description) === "") return;
        this.errorMsg.description = "";
      },
      "formData.memberId"() {
        if (this.formData.memberId == null) return;
        this.errorMsg.memberId = "";
      },
    },

    async save() {
      if (!this.validData()) return;
      this.loading = true;
      if (this.itemImage != null) {
        this.formData.thumbnail = await this.uploadFiles(
          this.itemImage,
          this.itemImage.type
        );
      }

      if (this.itemPdf != null) {
        this.formData.pdfFile = await this.uploadFiles(
          this.itemPdf,
          this.itemPdf.type
        );
      }

      if (this.state == "CREATE") {
        await this.addItem(this.formData);
      } else {
        await this.updateItem(this.formData);
      }

      this.loading = false;
      this.$emit("refresh");
    },

    validData() {
      let flag = true;
      if ($.trim(this.formData.title) === "") {
        this.errorMsg.title = "Please input title";
        flag = false;
      }
      if ($.trim(this.formData.description) === "") {
        this.errorMsg.description = "Please input description";
        flag = false;
      }

      if (this.formData.memberId == null) {
        this.errorMsg.memberId = "Please select member";
        flag = false;
      }
      if ($.trim(this.formData.title) === "") {
        this.errorMsg.title = "Please input title";
        flag = false;
      }

      if (this.itemImage == null && this.formData.thumbnail["s3_key"] == null) {
        this.errorMsg.thumbnail = "Please select thumbnail";
        flag = false;
      }

      if (this.itemPdf == null && this.formData.pdfFile["s3_key"] == null) {
        this.errorMsg.pdfFile = "Please select pdf file";
        flag = false;
      }

      return flag;
    },

    async addItem(formData) {
      this.loading = true;
      await adminServices
        .createPerspective(formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async updateItem(formData) {
      this.loading = true;
      await adminServices
        .updatePerspective(this.id, formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Edit successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async uploadFiles(item, type) {
      var _awsCred = await adminServices.getPublicSessionToken();
      AWS.config.update({
        region: _awsCred.data.d.region,
        credentials: new AWS.Credentials(
          _awsCred.data.d.access_key,
          _awsCred.data.d.secret_key,
          _awsCred.data.d.session_token
        ),
      });

      var folder = "";
      if (this.imageMime.includes(type)) {
        folder = "perspective/images/";
      } else {
        folder = "perspective/docs/";
      }

      var fileName = item.name.split(".")[0].replace(/[^a-zA-Z0-9]/g, "");
      let lastDot = item.name.lastIndexOf(".");
      var keyname =
        folder +
        fileName +
        "-" +
        this.getRandomKey(5) +
        item.name.substring(lastDot);
      let upload = new S3.ManagedUpload({
        params: {
          Bucket: _awsCred.data.d.bucket,
          Key: keyname,
          Body: item,
          ContentType: item.type,
          ACL: "public-read",
        },
      });
      var s3Key = (await upload.promise()).Key;
      return {
        name: item.name,
        s3_key: s3Key,
      };
    },

    async changeFile(file) {
      if (file == null) return;
      if (this.imageMime.includes(file.type)) {
        this.errorMsg.thumbnail = "";
      } else {
        this.errorMsg.pdfFile = "";
      }
    },
  },
});
