import { defineComponent } from "vue";
import eventInfo from "../../event-info";
import milestone from "../milestone-tab";

export default defineComponent({
  props: {
    dataEvent: { type: Object, default: null },
    eventInfoData: { type: Object, default: null },
  },
  components: {
    eventInfo,
    milestone,
  },
  emits: ["reloadEventInfo"],
  data() {
    return {
      milestoneMinStartDate: null,
      milestoneMaxEndDate: null,
    };
  },
  computed: {
    screenSize: {
      get() {
        return this.$store.state.mobile.size;
      },
      set(value) {
        return parseInt(value);
      },
    },
  },
  methods: {
    refresh() {
      this.$emit("reloadEventInfo");
    },
    updateDateMilestoneMain(minDate, maxDate) {
      if (minDate) this.milestoneMinStartDate = minDate;
      if (maxDate) this.milestoneMaxEndDate = maxDate;
    },
  },
});
