import { defineComponent } from "vue";
import appStore, { AUTH_ACTIONS, APP_MUTATION } from "../../../store/app";
import router from "@/config/router";
import _ from "lodash";

import Verify2faLogin from "../verify2faLogin/index";
import validate_email from "../../../misc/checkemail";
import generic from "../../../misc/generic";
import moment from "moment";

import TextField from "@/components/TextField";
import LogoIcon from "@/components/commons/logo";

export const AccountType = {
  Founder: "Founder",
  Founder_Potential: "Founder_Potential",
  Rokker: "Rokker",
  Investor: "Investor",
};

export const RedirectLink = {
  Founder: "/rfm/dashboard",
  Rokker: "/rfm/overview",
  Investor: "/partner",
};

export default defineComponent({
  props: ["redirect"],
  data() {
    return {
      loading: false,
      model: {
        clientId: "web-client",
        grant_type: "password",
        userType: "",
        email: "",
        password: "",
        refresh_token: "",
      },
      registerPath: router.register.path,
      homePath: router.home.path,
      forgotPasswordPath: router.forgotPassword.path,

      frmInvalidate: false,
      messageError: {
        email: null,
        password: null,
      },
      clicked: false,
      AccountType: AccountType,
      currentAccountType: "",
      timeout: null,
      timing: 1000,
    };
  },
  components: {
    "verify-2fa-modal": Verify2faLogin,
    TextField,
    LogoIcon,
  },
  computed: {
    disabled() {
      return (
        this.loading ||
        !this.model.email ||
        !this.model.password ||
        !this.model.recaptcha
      );
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
    currentPath() {
      return "";
    },
  },
  watch: {
    currentAccountType(value) {
      const date = generic.getJsonLocalStorage("checkUser");
      if (!date) return;
      if (moment(date).diff(moment().format("YYYY-MM-DD")) === 0) {
        const user = JSON.parse(generic.getJsonLocalStorage("userRegister"));
        if (!user) return;
        const findEmail = user.find((el) => el.order === 2).data;
        const email = findEmail.find((em) => em.question_id === 5).ans;
        this.model.email = email;
        this.model.password = "";
      } else {
        generic.removeLocalStorage("userRegister");
        generic.removeLocalStorage("checkUser");
      }
    },
  },
  created: function () {
    $("body").addClass("bglogin");
  },

  methods: {
    changeAccountType(accountType) {
      this.currentAccountType = accountType;
    },
    validate() {
      this.resetVaildate();
      if (!this.model.email) {
        this.messageError.email = "Email field is not empty";
        this.frmInvalidate = true;
      }
      if (!validate_email.validateEmail(this.model.email)) {
        this.messageError.email = "Your email is invalid";
        this.frmInvalidate = true;
      }
      if (!this.model.password) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }

      this.$refs.recaptcha?.execute();
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        clientId: null,
        userType: null,
        email: null,
        password: null,
      };
    },
    login() {
      this.model.userType = this.currentAccountType;
      this.resetVaildate();
      if (!this.model.email) {
        this.messageError.email = "Email field is not empty";
        this.frmInvalidate = true;
      }
      if (!validate_email.validateEmail(this.model.email)) {
        if (this.model.email.length) {
          this.messageError.email = "Your email is invalid";
        } else {
          this.messageError.email = "Email field is not empty";
        }
        this.frmInvalidate = true;
      }
      if (!this.model.password) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;

      appStore
        .dispatch(AUTH_ACTIONS.login, {
          ...this.model,
          redirectExternal: this.redirect
            ? this.redirect
            : RedirectLink[this.currentAccountType],
        })
        .then((resp) => {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.loading = false;
          }, this.timing);
        });
    },
    close() {
      let previous = appStore.state.previousRoute;
      if (previous.fullPath.includes(router.login.path)) {
        return this.$router.push(router.home.path);
      }
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
    setTimeout(() => {
      if (
        $("body,html")
          .find("form.colorWhite .md-input")
          .css("background-color") === "rgb(250, 255, 189)"
      ) {
        this.clicked = true;
      }
    }, 50);

    if (
      !!this.$route.query.ut &&
      ["Founder", "Rokker", "Investor"].includes(this.$route.query.ut)
    ) {
      this.currentAccountType = this.$route.query.ut;
    }

    if (generic.getJsonLocalStorage("userRegister")) {
      this.currentAccountType = AccountType.Founder;
    }
  },
});
