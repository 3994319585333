<template>
  <Modal
    :title="state === 'CREATE' ? 'Create Notifications' : 'View Notifications'"
    v-model:value="isShow"
    closeBtnText="Close"
    :showCloseBtn="false"
    classWrapper="notification-modal-wrapper"
  >
    <div class="container createNotifications" v-loading="loadingView">
      <div v-show="!addNotiUsers">
        <div class="row">
          <div class="col-12 col-md-12">
            <TextField
              :disabled="state !== 'CREATE'"
              title="Title"
              :errorMsg="errorMsg.title"
              :fieldRequire="true"
              v-model:value="title"
            />
            <TextArea
              :disabled="state !== 'CREATE'"
              v-model:value="content"
              title="Content"
              :errorMsg="errorMsg.content"
              :maxLength="2000"
              :fieldRequire="true"
              class="mb-3"
            />
          </div>
        </div>

        <div class="row">
          <div :class="`col-12 col-md-6 mb-4 position-relative`">
            <div v-if="state === 'CREATE'">
              <label class="title me-2">Notify to: </label>
              <button
                class="btn btn-secondary btn-w-md"
                @click="showAddNotiUsers"
              >
                {{
                  usersGetNoti && usersGetNoti.length ? usersGetNoti.length : 0
                }}
                User{{ usersGetNoti && usersGetNoti.length > 1 ? "s" : "" }}
              </button>
            </div>
            <div class="md-error" :style="{ left: '13px' }">
              {{ errorMsg.selectUser }}
            </div>
          </div>
        </div>

        <div class="col-12" v-if="state === 'VIEW'">
          <div>
            {{ rows.length }} user{{ rows && rows.length > 1 ? "s" : "" }}
          </div>
          <div class="custom-scrollbar">
            <table class="table">
              <thead class="table-header-sticky">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in rows" :key="`${i}-user`">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.display_name }}</td>
                  <td>{{ item.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="users" v-if="addNotiUsers">
        <!-- <div class="custom-switch-wrapper mb-2">
          <label class="switch me-1">
            <input type="checkbox" v-model="isAllUser" @change="getAllUser()" />
            <span class="slider round"></span>
          </label>
          <span style="color: #c4cad6">All User</span>
        </div> -->

        <Table
          useSimpleLayout
          v-loading="investorDataLoading"
          :paging="paging"
          :columns="columns"
          :rows="rows"
          :showNoRow="true"
          :checkAll="true"
          :hasCheckbox="true"
          isFixedHeader
          :idsSelected="targetUsersIds"
          @updatePaging="updatePaging"
          @onSelectAll="onSelectAllUser"
          @onChangeCheckbox="selectUser"
        >
          <template #head_name>
            <TextField
              placeholder="Type to search .."
              hideError
              v-model:value="filteredName"
            />
          </template>
          <template #head_email>
            <TextField
              placeholder="Type to search .."
              hideError
              v-model:value="filteredEmail"
            />
          </template>
          <template #head_is_device>
            <SelectField
              v-model:value="filteredStatus"
              :options="selectDeviceList"
              hideError
            />
          </template>
          <template #row_name="{ props }">
            {{ props.fname }} {{ props.lname }}
          </template>
          <template #row_is_device="{ props }">
            {{ props.is_device ? "Installed" : "-" }}
          </template>

          <!-- <template #row_actions="{ props }">
            <template v-if="isUserSelecting(props)">
              <i class="fas fa-check-circle"></i>
            </template>
            <template v-else>
              <div class="empty-icon"></div>
            </template>
          </template> -->
        </Table>
      </div>
    </div>
    <template #footer="{ closeMethod }">
      <div class="text-end" v-if="!addNotiUsers">
        <NewButton
          v-if="state === 'CREATE'"
          @click="sendNoti()"
          :loading="loading"
          class="me-1"
        >
          Create Notification
        </NewButton>
        <button ref="close" class="btn btn-secondary" @click="closeMethod">
          Close
        </button>
      </div>
      <div class="w-100" v-else>
        <template v-if="state === 'CREATE'">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              {{ usersGetNoti.length }} user{{
                usersGetNoti && usersGetNoti.length > 1 ? "s" : ""
              }}
              selected
            </div>
            <div>
              <button class="btn btn-info me-1" @click="addNotiUsers = false">
                Finish
              </button>
              <!-- <button class="btn btn-secondary me-1" @click="getAllUsers()">
                Get All
              </button> -->
              <!-- <button class="btn btn-secondary" @click="clearUsers()">
                Clear All
              </button> -->
            </div>
          </div>
        </template>
        <template v-else>
          <button class="btn btn-secondary" @click="addNotiUsers = false">
            Close
          </button>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script src="./NotificationModal.js"></script>

<style lang="scss" scoped>
.fa-check-circle {
  color: #00c500;
}
.empty-icon {
  width: 14px;
  height: 14px;
}
.notification-modal-wrapper {
  :deep() .modal-body {
    padding-top: 0;
  }
}

.createNotifications {
  :deep() .img-preview {
    background-color: #494b54 !important;
  }
  :deep() .notificationToUsers {
    position: relative;
    label {
      display: block;
    }
    button {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  :deep() .file-upload {
    .img-preview {
      height: 193px;
      margin-bottom: 30px;
    }
  }
  :deep() .field-color-hidden-icon {
    .dp__icon {
      display: none !important;
    }
    .dp__input_icon_pad {
      padding-left: 12px !important;
      color: #949ba2;
    }
  }
  .md-error {
    position: absolute;
    bottom: -24px;
    left: 0;
    color: #f52727;
    font-size: 0.8em;
    font-weight: 400;
  }
  .useSchedule {
    color: #fff;
    position: relative;
    margin-bottom: 4px;
    // &::before{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    // }
    svg {
      content: "";
      position: absolute;
      top: 4px;
      right: -14px;
      display: inline-block;
      width: 8px;
      height: 14px;
      line-height: 20px;
      transform: rotate(-90deg);
    }
    &.active {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.select-field {
  .multiselect {
    .multiselect__tag {
      margin-bottom: 0;
    }
  }
}

.selectDate {
  label {
    color: #fff;
  }
  .vdp-datepicker {
    input {
      line-height: 1.4;
      &::placeholder {
        color: #91989f;
      }
    }
  }
}
.custom-scrollbar {
  max-height: 300px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #212529;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }
}
.table-header-sticky {
  position: sticky;
  top: 0;
  background: #393c45;
}
</style>
