import AdminServices from "../../../services/admin";
import _ from "lodash";
import appStore from "../../../store/app";
import { router, USER_TYPE } from "@/config/router";
import moment from "moment";
import notify from "../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["portfolioId"],
  data() {
    return {
      loading: false,
      textMonth: 0,
      textYear: new Date().getFullYear(),
      submitDate: null,
      kpiInfo: {},
      dashboardPath: router.dashboard.path,
      welcomeSurveyPath: router.welcomeSurvey.path,
      partnerPath: router.partner.path,
      partnerRouteName: router.partner.name,
      kpiFields: [],
      kpiHistory: [],
      fieldTooltips: {},
      isRokker: false,
      dealFounderPath: router.dealFounder.path,
      dealFounderName: router.dealFounder.name,
    };
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
  },
  mounted() {
    if (this.profile.userType === USER_TYPE.FOUNDER_POTENTIAL)
      this.$router.push({ path: this.dealFounderPath });
    if (this.profile && !this.isRokker) {
      if (
        !this.profile.is_partner &&
        this.profile.welcome_survey_stt !== "APPROVED"
      ) {
        this.$router.push(this.welcomeSurveyPath);
      } else if (!this.profile.is_partner) {
        this.$router.push(this.dashboardPath);
      }
    }
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("resize", this.__resizeHandler);
    $(this.$refs.monthlyTab).off("shown.bs.tab", this.__resizeHandler);
    next();
  },
  created() {
    this.isRokker = this.$route.meta.userTypes.includes("Rokker");
    this.getPartner();
  },
  methods: {
    getPartner: function () {
      this.loading = true;
      let infoPromise = this.isRokker
        ? AdminServices.getPartnerKpiFields(this.portfolioId)
        : AdminServices.getPartnerInfo();
      infoPromise.then((res) => {
        if (!res.data.d) {
          this.$router.push(this.dashboardPath);
        } else {
          this.kpiFields = res.data.d.kpi_fields;

          this.changeMonth(new Date().getMonth() + 1);
          //this.getKPIHistory()
          this.$nextTick().then(() => {
            $(this.$refs.monthlyTab).off("shown.bs.tab", this.__resizeHandler);
            $(this.$refs.monthlyTab).on("shown.bs.tab", this.__resizeHandler);
          });
        }
        this.loading = false;
      });
    },
    changeMonth: function (str) {
      if (this.textMonth != str) {
        this.textMonth = str;
        this.getKPI();
      }
    },
    changeYear: function (str) {
      if (this.textYear != str) {
        this.textYear = str;
        this.getKPI();
      }
    },
    getKPIHistory: function () {
      AdminServices.getKPIHistory().then((resp) => {
        if (resp.data.ok) {
          this.kpiHistory = resp.data.d;
        } else {
          this.kpiHistory = [];
        }
      });
    },
    getKPI: function () {
      let getPromise = this.isRokker
        ? AdminServices.getPartnerKpi(
            this.portfolioId,
            parseInt(this.textYear),
            parseInt(this.textMonth)
          )
        : AdminServices.getKPI(
            parseInt(this.textYear),
            parseInt(this.textMonth)
          );
      getPromise.then((resp) => {
        if (resp.data.ok) {
          this.fieldTooltips = {};
          if (resp.data.d.length > 0) {
            this.submitDate = resp.data.d[0].created_at;
            this.kpiInfo = resp.data.d[0].data;

            let currentVal = _.cloneDeep(resp.data.d[0]);
            let histories = resp.data.d.slice(1);
            for (let i = 0; i < histories.length; i++) {
              this.kpiFields.forEach((fieldGroup) => {
                fieldGroup.fields.forEach((field) => {
                  if (
                    currentVal.data[field.name] !==
                    histories[i].data[field.name]
                  ) {
                    this.fieldTooltips[field.name] = this.fieldTooltips[
                      field.name
                    ]
                      ? this.fieldTooltips[field.name] +
                        `<br />${this.buildTooltip(histories[i], field.name)}`
                      : `${this.buildTooltip(histories[i], field.name)}`;

                    currentVal.data[field.name] = histories[i].data[field.name];
                  }
                });
              });
            }
          } else {
            this.submitDate = null;
            this.kpiInfo = {};
          }

          this.$nextTick().then(() => {
            this.__resizeHandler();
          });
        }
      });
    },
    buildTooltip(item, property) {
      let value = item.data[property];
      if (!value) {
        value = "None";
      }

      let result = `${moment(item.created_at).format(
        "MMM DD, YY HH:mm:ss"
      )} - <span class="his-data" style="color: #ffc107;">${value}</span>`;

      return result;
    },
    saveKpi: function () {
      this.kpiFields.forEach((fieldGroup) => {
        fieldGroup.fields.forEach((field) => {
          if (this.kpiInfo[field.name] && this.kpiInfo[field.name] !== "") {
            this.kpiInfo[field.name] = parseFloat(this.kpiInfo[field.name]);
          }
        });
      });

      let savePromise = this.isRokker
        ? AdminServices.savePartnerKpi(
            this.portfolioId,
            parseInt(this.textYear),
            parseInt(this.textMonth),
            this.kpiInfo
          )
        : AdminServices.saveKPI(
            parseInt(this.textYear),
            parseInt(this.textMonth),
            this.kpiInfo
          );
      savePromise.then((resp) => {
        if (resp.data.ok) {
        }
        notify.success("Kpi submitted succesfully.");
      });
    },
    __resizeHandler: function () {
      this.kpiFields.forEach((fieldGroup) => {
        let maxHeight = 0;
        let subs = $(this.$refs[fieldGroup.groupName]).find("p");
        subs.each((_, ele) => {
          $(ele).css("height", "");
          if (maxHeight < ele.clientHeight) {
            maxHeight = ele.clientHeight;
          }
        });
        subs.each((_, ele) => {
          if (ele.clientHeight < maxHeight) {
            $(ele).css("height", `${maxHeight}px`);
          }
        });
      });
    },
  },
  computed: {
    profile() {
      return appStore.state.profile;
    },
  },
  watch: {
    kpiHistory() {},
  },
});
