import { defineComponent } from "vue";
import Table from "@/components/Table";

import adminServices from "@/services/admin";
import notify from "@/services/notify";
import AddEditComponent from "./addEditItem";
import { LP_MEDIA_TYPES } from "@/misc/constants";
import Dialog from "@/components/Dialog";
import NotificationModal from "../notification-modal/NotificationModal";

const columnData = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "photoName",
    name: "Description",
    columnClass: "multiple-line",
  },
  {
    id: "lp_funds",
    name: "Funds",
    columnClass: "multiple-line",
  },
  {
    id: "active",
    name: "Status",
  },
  {
    id: "modified_at",
    name: "Last modified",
    columnClass: "multiple-line",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "d-flex justify-content-center",
    columnClass: "text-center",
  },
];

export default defineComponent({
  components: {
    Table,
    AddEditComponent,
    Dialog,
    NotificationModal,
  },
  data() {
    return {
      rows: [],
      loading: false,
      selectedItemId: null,
      isShowAddEdit: false,
      totalItems: 0,
      size: 10,
      paging: {
        sorts: null,
        size: 10,
        page: 1,
      },
      currentPage: 1,
      columns: columnData,
      notificationModalKey: 0,
    };
  },
  mounted() {
    this.$nextTick(() => [this.getItems()]);
  },
  methods: {
    viewNotification(props) {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.show(props);
      });
    },
    createNotification(props) {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.create(props);
      });
    },
    async getItems() {
      this.loading = true;
      const res = await adminServices.getLPMediaItemsByTypeWithoutPagination(
        LP_MEDIA_TYPES.SEEDED_COMPANIES
      );

      if (res.data.ok) {
        this.rows = res.data.d;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async orderChanged(items) {
      let tmpData = _.cloneDeep(items);
      tmpData = tmpData.map((x, idx) => {
        x.order_number = idx;
        return x;
      });
      this.loading = true;
      const res = await adminServices.sortLPMediaItems(tmpData);
      if (res.data.ok) {
        notify.success("Ordered successfully!");
        this.getItems();
      }
      this.loading = false;
    },
    openDialogToAddEdit(itemId) {
      this.selectedItemId = itemId;
      this.$nextTick(() => {
        this.isShowAddEdit = true;
      });
    },
    closeAddEdit() {
      this.isShowAddEdit = false;
      this.selectedItemId = null;
    },
    onCompleteAddEdit(data) {
      const { id, formType } = data;
      if (formType === "Edit") {
        notify.success("Update successfully");
      } else if (formType === "Add") {
        notify.success("Add successfully");
      }
      this.closeAddEdit();
      this.getItems();
    },
    async removeItem(id) {
      let message = {
        title: "Warning",
        body: "This will permanently delete this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteLPMediaItem(id);
          if (res.data.ok) {
            notify.success("Deleted successfully!");
            this.getItems();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    loadPage(page) {},
  },
});
