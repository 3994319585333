<template>
  <div class="adminContent p-3" v-loading="loading">
    <div class="treeList me-2">
      <div
        class="data-content py-2 pr-2 data-tree content-left-scrollable scrollbar"
      >
        <ul class="mainTree">
          <tree-item
            class="item"
            :item="treeData"
            @select-folder="selectFolder"
            :selectedId="selectedFolderId"
            :selectedParentId="selectedParentId"
          />
        </ul>
      </div>
    </div>
    <div class="treeDetailContent">
      <div class="data-content px-2 py-2">
        <div class="path-section">
          <div class="truncate">
            <span v-for="(item, idx) in parentPath" :key="idx">
              <i
                v-if="idx > 0"
                class="fas fa-chevron-right mx-1 breadcrum-next-icon"
              ></i
              ><span
                v-if="idx < parentPath.length - 1"
                class="noselect path-item"
                @click="selectFolder(item)"
                >{{ item.name }}</span
              ><span v-else class="noselect path-item">{{ item.name }}</span>
            </span>
          </div>
          <div class="path-action">
            <button
              v-if="selectedFolderId > 0"
              class="btn btn-primary btn-small"
              @click="openDialogToUploadFile(selectedFolderId)"
            >
              <i class="fas fa-file-upload me-2"></i>File upload
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <div class="row">
          <div class="col-12 content-scrollable scrollbar">
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Title</th>
                    <th>Size</th>
                    <th>Views</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="item-row pointer"
                    v-for="(item, index) in selectedChildrenItems"
                    :key="index"
                    @click="oneClick(item)"
                  >
                    <td class="noselect">{{ index + 1 }}</td>
                    <td class="noselect">
                      <span
                        class="pr-1 item-icon"
                        :style="{
                          color: getDataRoomItemIconColor(
                            item.type,
                            item.current_extension
                          ),
                        }"
                      >
                        <i
                          :class="
                            getDataRoomItemIconClass(
                              item.type,
                              item.current_extension
                            )
                          "
                        ></i>
                      </span>
                      <span>{{ item.name }}</span>
                    </td>
                    <td class="noselect">
                      <span v-if="item.type === 'Folder'"
                        >{{ countDescendantFiles(item) }} files</span
                      >
                      <span v-if="item.type === 'File'">{{
                        $filters.formatBytes(item.file_size)
                      }}</span>
                    </td>
                    <td class="noselect">
                      <template v-if="['File', 'Bookmark'].includes(item.type)">
                        {{ $filters.numberFormat(countView(item)) }}
                      </template>
                    </td>
                    <td class="noselect small-text">
                      <div class="subtext">
                        <i
                          class="fas fa-plus me-1 subtext-icon"
                          v-if="item.modified_at"
                        ></i>
                        {{ $filters.parseEstTime(item.created_at) }}<br /><span
                          class="thin-text"
                          >{{ item.created_by }}</span
                        >
                      </div>
                      <div v-if="item.modified_at" class="subtext mt-1">
                        <i class="fas fa-pencil-alt me-1 subtext-icon"></i>
                        {{ $filters.parseEstTime(item.modified_at) }}<br /><span
                          class="thin-text"
                          >{{ item.modified_by }}</span
                        >
                      </div>
                    </td>
                    <td class="text-center noselect">
                      <button
                        type="button"
                        class="btn btn-default btn-small btn-more-action"
                        @click.prevent.stop="handleClick($event, item)"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <context-menu
      :key="contextMenuComponentKey"
      :elementId="'dataRoomMenu'"
      :options="menuOptions"
      :ref="'dataRoomMenu'"
      @option-clicked="optionClicked"
    />

    <uploadFile
      v-if="isShowUploadFile"
      :hideUploadFile="hideUploadFile"
      :parentId="selectedParentFolderId"
      @upload-complete="handleUploadFileComplete"
    />

    <manageVersions
      v-if="isShowManageVersions"
      :hideManageVersions="hideManageVersions"
      :item="selectedItem"
    />

    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />

    <downloadItem
      v-if="isShowDownloadItem"
      :fileToken="fileToken"
      :hideDownloadItem="hideDownloadItem"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";
.adminContent {
  display: flex;
  .treeList {
    flex: 0 0 300px;
    max-width: 300px;
  }
  .treeDetailContent {
    flex: 1;
  }
}
ul {
  line-height: 1.5;
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
  }
}
.data-content {
  min-height: calc(100vh - 70px - 2rem);
  background-color: $color-background-secondary;
  display: block;
  border-radius: 3px;
  color: $text-color;
  height: 100%;
}
.content-scrollable {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.content-left-scrollable {
  max-height: calc(100vh - 76px);
  overflow-y: auto;
}

.data-section-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #409eff;
}
.item-row {
  font-size: 0.9rem;
  .item-icon {
    width: 20px;
    display: inline-flex;
  }
}
.btn-more-action {
  min-width: 29px;
}

.truncate {
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  .breadcrum-next-icon {
    font-size: 14px;
  }
}
.small-text {
  font-size: 12px;
}
.subtext {
  line-height: 1.2;
  .thin-text {
    font-weight: 200;
  }
  .subtext-icon {
    font-size: 8px;
    color: #e1d2bc;
    opacity: 0.5;
  }
}

.path-section {
  display: flex;
  align-items: center;
  .path-item {
    font-size: 16px;
    padding: 6px 12px 6px;
    border-radius: 8px;
    line-height: 26px;
    cursor: pointer;
    display: inline-block;
    padding: 1px 12px;
    &:hover {
      background-color: $color-grey; //#eeeeee;
      color: $color-dark;
    }
    i {
      color: #5f6368;
    }
  }
  .path-action {
    .dropdown-menu {
      background-color: $color-background-tertiary;
      .dropdown-item {
        color: $color-grey;
        &:hover {
          background-color: $color-grey;
          color: $color-dark;
        }
      }
    }
  }
}
</style>
