<template>
  <div class="h-100 container-fluid" v-if="useEmptyLayout">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="row justify-content-center content-section">
          <div class="col-lg-9 align-items-center" v-loading="loading">
            <div class="d-flex align-items-center mt-3 mb-4">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>
            <div class="w-45 max-width margin-auto">
              <div v-if="success" class="text-center mt-5">
                <h4>Request a new password successfully</h4>
                <p class="colorGray">A verification link has been sent to</p>
              </div>
              <form
                id="loginForm"
                class="colorWhite"
                v-else
                :disabled="disabled"
              >
                <p class="signInText mb-lg-2 mb-4">Forgot password</p>
                <!-- <md-field class="mb-4 forgot-password"> -->
                <!-- <label>Email</label> -->
                <TextField
                  class="mb-3 forgot-password"
                  :placeholder="'Email'"
                  name="email"
                  :errorMsg="messageError.email"
                  v-model:value="email"
                />

                <!-- <md-input v-model="email" placeholder="Email"></md-input>
                                <small class="md-error">{{messageError.email}}</small>
                                </md-field>   -->
                <!-- <div class="form-group text-center">
                                 <recaptcha ref="recaptcha" v-model="recaptcha" :instance="captchaInstance" :dataCallback="submit" /> 
                            </div> -->
                <div class="form-group">
                  <button
                    @click.prevent="validate"
                    class="btn button btn-large btn-active btn-primary w-100"
                    v-if="count > 1"
                  >
                    Resend email
                  </button>
                  <button
                    @click.prevent="validate"
                    class="btn button btn-large btn-active btn-primary w-100"
                    v-else
                  >
                    Send email
                  </button>
                </div>
              </form>

              <div class="text-center my-4" v-if="success">
                <router-link :to="loginPath">Back to login page</router-link>
              </div>
              <div class="text-center my-4" v-else>
                <span class="colorGray mr-b-10 des-ita me-1"
                  >Remember your account?</span
                >
                <router-link class="link" :to="loginPath"
                  >Login now!</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 login-section px-0">
        <div class="boxFix"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.colorDarkGray {
  color: #2e384d;
}
.colorGray {
  color: #b0bac9;
}
.logoLogin {
  img {
    object-fit: cover;
    width: 190px;
    height: auto;
  }
}
// .wrapper {
//   background-color: #fff;
// }
#loginForm {
  font-size: 16px;
  padding: 0;
  //color: #2e384d;
  //opacity: 0.8;
  //background-color: #fff;
  margin: 0;

  .signInText {
    line-height: normal;
  }
}
@media (max-width: 767.98px) {
  #loginForm .signInText {
    font-size: 26px;
    margin-bottom: 0;
  }
}
</style>
