<template>
  <Draggable
    :id="id"
    v-model="_items"
    :class="componentClass"
    :tag="componentTag"
    v-bind="dragOptions"
    item-key="order"
    @start="start"
    @end="end"
  >
    <transition-group>
      <component
        v-for="(element, idx) in _items"
        :key="idx"
        :is="dragElementTag"
        @click="rowClick(element)"
      >
        <slot :props="element" :index="idx" />
      </component>
    </transition-group>
  </Draggable>
</template>

<script>
import { defineComponent } from "vue";
// import Draggable from 'vuedraggable' //#todo update module dragable
import { VueDraggableNext } from "vue-draggable-next";

export default defineComponent({
  name: "Dragger",
  emits: ["update:orderChanged", "rowClick", "value", "update:value"],
  components: {
    Draggable: VueDraggableNext,
  },
  props: {
    id: { type: String, default: "" },
    value: { type: Array, default: () => [] },
    componentTag: { type: String, default: "ul" },
    componentClass: { type: String, default: "list-group" },
    dragElementTag: { type: String, default: "li" },
  },
  data() {
    return {
      drag: false,
      oldData: undefined,
    };
  },
  computed: {
    _items: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("value", val);
        this.$emit("update:value", val);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    start() {
      this.oldData = this.value;
      this.drag = true;
    },
    end() {
      this.drag = false;
      this.$nextTick(() => {
        if (this.oldData !== this._items) {
          this.$emit("update:orderChanged", this.value);
        }
      });
    },
    rowClick(el) {
      this.$emit("rowClick", el);
    },
  },
});
</script>

<style lang="scss" scoped>
.item {
  list-style: none;
}
ul {
  list-style: none;
  padding-left: 0;
}
</style>
