<template>
  <Modal
    :title="state === 'CREATE' ? 'Create Schedule' : 'View Schedule'"
    v-model:value="isShow"
    closeBtnText="Close"
    :showCloseBtn="false"
  >
    <div class="mb-4" v-loading="loading">
      <div class="row">
        <div class="title-date">Schedule Date (EST)</div>
        <div class="col-7">
          <Datepicker
            v-model:value="scheduleDate"
            placeholder="MM/DD/YYYY"
            :enableTimePicker="false"
            :autocomplete="false"
            :fieldRequire="true"
            field="black"
            :autoApply="false"
            :minDate="currentDateTime"
          />
        </div>
        <div class="col-5">
          <Datepicker
            v-model:value="scheduleTime"
            placeholder="--:-- --"
            :autocomplete="false"
            :fieldRequire="true"
            :enableTimePicker="true"
            field="hidden-icon"
            :autoApply="false"
            isTimePicker
            :is24="false"
          />
        </div>
        <div class="md-error">
          {{ errorMsg.scheduleDate ? errorMsg.scheduleDate : "" }}
        </div>
      </div>
    </div>
    <template #footer="{ closeMethod }">
      <div class="text-right">
        <NewButton
          ref="getAll"
          class="btn btn-secondary me-1"
          @click="sendSchedule"
          >Send</NewButton
        >
        <NewButton ref="close" class="btn btn-secondary" @click="closeMethod"
          >Close</NewButton
        >
      </div>
    </template>
  </Modal>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.title-date {
  font-family: "ProximaNova";
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 5px;
  &::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}
.md-error {
  width: auto;
  color: #f52727;
  font-size: 0.8em;
  height: 5px;
}
</style>
