import authService from "../../../services/auth";
import notify from "../../../services/notify";
import router from "@/config/router";
import appStore, { APP_MUTATION, AUTH_ACTIONS } from "../../../store/app";
import _ from "lodash";
import TextField from "@/components/TextField";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  components: {
    LogoIcon,
    TextField,
  },
  data() {
    return {
      email: "",
      //recaptcha: '',
      password: "",
      confirmPassword: "",
      captchaInstance: {},
      loading: false,
      loginPath: router.login.path,
      homePath: router.home.path,
      success: false,
      code: "",
      userType: "",
      frmInvalidate: false,
      messageError: {
        password: null,
      },
      loginModel: {
        clientId: "web-client",
        grant_type: "password",
        userType: "Investor",
        email: "",
        password: "",
        refresh_token: "",
      },
      timeout: null,
      timing: 1000,
    };
  },
  computed: {
    disabled() {
      return (
        this.loading ||
        !this.email ||
        //!this.recaptcha ||
        !this.password
      );
      //||!(this.password === this.confirmPassword)
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
    this.email = this.$route.query.e;
    this.code = this.$route.query.c;
    this.userType = this.$route.query.t || "Investor";
  },
  methods: {
    validate() {
      this.resetVaildate();
      if (this.password !== this.confirmPassword) {
        this.messageError.password = "Your passwords does not match.";
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        password: null,
      };
    },
    confirm() {
      this.resetVaildate();
      if (this.password !== this.confirmPassword) {
        this.messageError.password = "Your passwords does not match.";
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      authService
        .resetPassword(
          this.email,
          this.code,
          this.password,
          this.confirmPassword
        )
        .then((resp) => {
          if (resp.error) {
            let that = this;
            const list = resp.data.d;
            if (!!list && list.length > 0) {
              list.forEach(function (element) {
                notify.error(element.err, element.err);
              });
            }
            this.success = false;
            this.loading = false;
          } else {
            notify.success(
              "You have been created successfully",
              "Please login and enjoy"
            );
            // this.$router.push({
            //     path: this.loginPath
            // })
            this.success = true;
            this.loginModel = {
              ...this.loginModel,
              email: this.email,
              password: this.password,
              userType: this.userType,
            };
            appStore
              .dispatch(AUTH_ACTIONS.login, {
                ...this.loginModel,
              })
              .then((resp) => {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  this.loading = false;
                }, this.timing);
              });
          }
        });
    },
    // resetRecaptcha() {
    //     if (_.isFunction(this.captchaInstance.reset)) {
    //         this.captchaInstance.reset()
    //     }
    // },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
  },
});
