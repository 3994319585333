<template>
  <div class="form-control-2-wrapper mb-5">
    <div class="form-control-2">
      <template v-if="type === 'number'">
        <input
          placeholder="none"
          :class="`field-color-${field}`"
          class="form-input"
          :index="indexField"
          v-model="_value"
          :disabled="disabled"
          :id="name"
          :tabindex="tabindex"
          :type="type"
          :autocomplete="autocomplete ? '' : 'off'"
          :maxlength="maxLength"
          @blur="changeNumber($event.target)"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </template>

      <template v-else-if="type === 'CURRENCY'">
        <input
          placeholder="none"
          class="form-input"
          :index="indexField"
          type="text"
          v-model="displayValue"
          @blur="
            isInputActive = false;
            changeCurrency($event.target);
          "
          @focus="isInputActive = true"
          @keypress="isNumber($event)"
        />
      </template>
      <label for="field" class="form-label"
        >{{ title }}
        <template v-if="fieldRequire"
          ><small class="require">*</small></template
        ></label
      >
    </div>
    <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { felixNumber } from "@/misc/commons";

export default defineComponent({
  name: "NumberField",
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "number" },
    autocomplete: { type: String, default: "" },
    value: { default: "0" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    maxLength: { default: 10 },
    indexField: { type: Number, default: 0 },
    fieldRequire: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      isInputActive: false,
      isDirty: false,
    };
  },
  mounted() {
    var inputBox = this.$el.querySelector("input");
    var invalidChars = ["-", "+", "e", "E"];
    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("input", function () {
      this.value = this.value.replace(/[e\+\-]/gi, "");
    });
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  methods: {
    isNumber: function (e) {
      e = e ? e : window.event;
      var charCode = e.which ? e.which : e.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    changeNumber: function (el) {
      this._value = Math.floor(el.value) <= 0 ? 1 : Math.floor(el.value);
    },
    changeCurrency: function (el) {
      this._value = felixNumber(el.value, null, 2);
    },
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          if (this.value) return this.value.toString();
          return "";
        } else {
          // User is not modifying now. Format display value for user interface
          if (this.value) {
            return (
              "$ " +
              Number(this.value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
          }
          return !this.isDirty ? "" : "$ 0.00";
        }
      },
      set: function (modifiedValue) {
        this.isDirty = true;

        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("update:value", newValue.toString());
      },
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        if (this._value) return "";
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.form-control-2-wrapper {
  .md-error {
    position: absolute;
    color: #ee6e73;
    font-size: 14px;
  }
}

.form {
  font-family: "SF Pro Text";

  &-control-2 {
    position: relative;
  }

  &-label {
    position: absolute;
    font-family: inherit;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    top: 0.5rem;
    left: 0;
    width: 100%;
    color: #aaaaaa;
    transition: all 0.2s ease;
    z-index: -1;

    @media (min-width: 768px) {
      font-size: 22px;
    }

    .require {
      color: #ee6e73;
    }
  }

  &-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 20px;
    padding: 10px 0;
    display: block;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }

    &:focus ~ .form-label,
    &:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
      top: -0.75rem;
      left: 0;
      font-size: 14px;
      z-index: 9;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
    }
  }
}
</style>
