<template>
    <div class="signature">
        <div class="wrap">
            <div class="content mb-3" v-on:mousemove="test" v-on:touchmove="test">
                <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="100%"
                    ref="signaturePad"
                />
            </div>
            <p class="text-center m-0">
                <button class="btn custombtnclear btn-outline-light" @click="clear">Clear</button>
            </p>   
            <div class="robotic-block-bottom text-center">
                <div class="agreeform pointer mb-3" @click="changeAcceptTerm">
                    <span class="customCheckbox me-1" :class="{active: acceptTerm}"></span>
                    <span>I have read and agree that form</span>
                </div>
                <button class="btn btn-secondary border pointer" v-if="empty || !acceptTerm" disabled>Submit</button>
                <button class="btn btn-primary border pointer" v-else @click="saveImg">Submit</button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.signature{
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;    
    .wrap{
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        max-width: 592px;
        margin: 0 auto;
        h3{
            font-size: 57px;
            padding: 20px 0;
            margin-bottom: 15px;
        }        
        .content{
            display: block;
            overflow: hidden;
            background-color: #fff;
            border-radius: 5px;
            width: 592px;
            height: 150px;
            margin: 0 auto;
            position: relative;
            input[type="text"]{
                border: 0;
                background-color: transparent !important;
                border-bottom: 1px solid #000;
                color: #000;
                font-size: 50px;
                padding: 5px;
                width: 100%;
                outline: 0 !important;
                box-shadow: unset !important;
            }
            .customFile{
                display: block;
                overflow: hidden;
                width: 90%;
                height: 100px;
                background-color: #fff;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-bottom: 1px solid #000;
                cursor: pointer;
                text-align: center;
                &.no-border{
                    border-bottom: 0;
                }
                input[type="file"]{
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
        .custombtnclear{
            position: absolute;
            top: 0;
            right: 0;
            padding: 2px 10px;
        }
        .custombtnclear:not(:hover) {
            background: rgba(0, 0, 0, 0.7);
        }
        .robotic-block-bottom{
            display: block;
            width: 100%;
            .agreeform{
                span{
                    vertical-align: middle;
                }
                .customCheckbox{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    border-radius: 3px;
                    border: 1px solid #fff;
                    &.active{
                        position: relative;
                        &:before{
                            content: "";
                            display: block;
                            overflow: hidden;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: #fff;
                            margin: 3px;
                        }
                    }
                }
            }
            button{
                width: 156px;
                text-decoration: none;
                &:disabled{
                    opacity: 1;
                    cursor: no-drop;
                }
                &.btn-link{
                    color: #fff;
                }
                &.border{
                    border-radius: 25px;     
                    border: 0px !important;               
                }
                &.no-border{
                    border: 0 !important;
                    cursor: no-drop !important;
                }
                &.back{
                    &:before{
                        content: '';
                        background: url('../../../../src/assets/images/robotic/left-arrow.svg') no-repeat;
                        display: inline-block;
                        vertical-align: middle;
                        width: 9px;
                        height: 16px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
</style>
<script src="./index.js"></script>

