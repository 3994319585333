<template>
    <transition name="slide">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideAddPartner"></div>
            <div class="modal-dialog">

            <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">Add New Partner</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-0">
                            <div class="col col-12">
                                <div class="form-group mb-1">
                                    <div class="form-group mb-1">
                                        <div class="row">
                                            <input type="text" class="form-control" v-model="modal.email" style="width: calc(100% - 130px); margin-right: 10px;" />
                                            <button class="btn btn-primary w100" @click="getMailList" :disabled="emailDisabled" :class="{'no-drop': emailDisabled}">Search</button>
                                        </div>                                                                              
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-0">
                            <div class="col-12 p-0">
                                <div class="table table-hover admin-table mb-0" ref="table">
                                    <div class="header">
                                        <div class="date">No.</div>
                                        <div>Name</div>
                                        <div class="email">Email</div>
                                        <div class="action">Actions</div>
                                    </div>
                                    <div class="body" v-if="errMsg">
                                        <div class="dataRow">
                                            <div>{{errMsg}}</div>
                                        </div>
                                    </div>
                                    <div class="body" v-else>
                                        <label class="dataRow pointer setmask" :for="`select_${index}`" :ref="`select_${index}`" v-for="(item, index) in tableData" :key="item" @click.prevent="selectItem(`select_${index}` ,item)">
                                            <div class="date">{{index+1}}</div>
                                            <div>{{userName(item)}}</div>
                                            <div class="email">{{item.email}}</div>
                                            <div class="action">
                                                <div class="form-data">
                                                    <input type="checkbox" :id="`select_${index}`">
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-secondary" @click="hideAddPartner">Cancel</button>
                        <template v-if="dataEmail.length > 0">
                            <button class="btn btn-primary" @click="addPartner">Add New</button>
                        </template>
                        <template v-else>
                            <button class="btn btn-primary no-drop" disabled>Add New</button>
                        </template>                        
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>
<style lang="scss" scoped>
.setmask{
    position: relative;
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 1;
        content: '';
    }
}
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
textarea{
    resize: none;
}
.admin-table{
    .body{
        overflow: auto;
        max-height: calc(100vh - 300px);
        display: block;
    }
}
.modal-footer{
    display: block;
}
.fueltype{
    position: relative;
    .dropdown-menu{
        position: absolute !important;
        top: 45px !important;
        transform: translate3d(0, 0, 0) !important;
    }
    #dropdownMenuLink{
        border: 1px solid #e0e7ff;
        color: #bbbcbe;
        padding-left: 10px;
        padding-right: 10px;
        height: 46px !important;
        line-height: 44px !important;
        border-radius: 4px;
        overflow: hidden;
        background-color: #f9faff;
        position: relative;
        display: inline-block;
        width: 100%;
        &.hasData{
            color: #000;
            background-color: #e8f0fe;
        }
        &:after{
            position: absolute;
            top: 50%;
            right: 10px;
        }
        .dropdown-item.active, .dropdown-item:active{
            background-color: #3a87da;
        }
    }
}
.custom-file{
    height: 150px;
    .custom-file-label{
        top: 30px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        display: block;
        height: 120px;
        &::after{
            display: none;
        }
        .dataImg{
            height: 105px;
        }
        i{
            font-size: 80px;
            &.dataPhoto{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 40px;
            }            
        }
    }
    .custom-file-input{
        height: 100%;
    }
}
.custom-row{
    height: 160px;
    @media screen and (max-width: 767.98px) {
        height: unset;
        .custom-file{
            height: 160px;
        }
        .custom-file-input{
            height: 100%;
        }
    }
}
.customUploadFile{
    background-color: #41505e;
    width: 120px;
    padding-left: 0;
    padding-right: 0;
    display: block;
    color: #fff;
    opacity: 0.7;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.fileinfo{
    color: #afafaf;
    font-weight: 400;
    .info{
        font-size: 12px;
        line-height: 20px;
        display: inline-block;
        vertical-align: middle;
    }
    .close{
        font-size: 12px;
        display: inline-block;
        line-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
    }
}
</style>
<script src="./index.js"></script>