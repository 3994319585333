<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      :columns="columns"
      :rows="filteredData"
      :total-items="totalItems"
      showNoRow
      @orderChanged="orderChanged"
      v-loading="loading"
      :isSizeChange="true"
      :sizeChange="2"
      :customCurrentPage="customPaging.current * customPaging.size"
    >
      <template #row_name="{ props }">
        <div class="textOverflow1">
          {{ props.name }}
        </div>
      </template>
      <template #row_PublishDate="{ props }">
        {{ $filters.shortDateNotDay(props.data.PublishDate) }}
      </template>
      <template #row_active="{ props }">
        <div class="stt">
          {{ props.active ? "ACTIVE" : "INACTIVE" }}
        </div>
      </template>
      <template #row_lp_funds="{ props }">
        <div class="ellipsis-3">
          <template v-if="props.lp_funds.length > 0">
            <div
              v-for="item in props.lp_funds"
              :key="item.lp_fund_id"
              style="word-break: break-word"
            >
              {{ item.name }}
            </div>
          </template>
          <p v-else class="ms-3">-</p>
        </div>
      </template>
      <template #row_created_at="{ props }">
        <template v-if="!props.modified_at">
          <div>{{ $filters.parseEstTime(props.created_at) }}</div>
          <div>{{ props.created_by }}</div>
        </template>
        <template v-else>
          <div>{{ $filters.parseEstTime(props.modified_at) }}</div>
          <div>{{ props.modified_by }}</div>
        </template>
      </template>

      <template #row_actions="{ props }">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle custom-menu-dropdown"
            type="button"
            id="newsletterManagement"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="newsletterManagement"
          >
            <li>
              <span
                class="dropdown-item pointer"
                @click="editNewletter(props)"
                title="Edit"
              >
                <i class="fas fa-comment-alt me-1"></i>Edit
              </span>
            </li>
            <li v-if="!props.is_send_notification">
              <span
                v-if="props.active"
                class="dropdown-item pointer"
                @click="createNotification(props)"
                title="Edit"
              >
                <i class="fas fa-bell me-1"></i>Send notification
              </span>
            </li>
            <li v-else>
              <span
                class="dropdown-item pointer"
                @click="viewNotification(props)"
                title="Edit"
              >
                <i class="fas fa-eye me-1"></i>View notification
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="lockNewletter(props)"
                :title="props.active ? 'Activate' : 'Inactivate'"
              >
                <template v-if="props.active">
                  <i class="fas fa-lock me-1"></i>Inactivate
                </template>
                <template v-else>
                  <i class="fas fa-lock-open me-1"></i>Activate
                </template>
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="deleteNewletter(props.id)"
              >
                <i class="far fa-trash-alt me-1"></i>Delete
              </span>
            </li>
          </ul>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="addNewletter">
          <span class="text">Add Newsletter</span>
        </button>
      </template>
    </Table>

    <div class="table-nav">
      <ul class="pagination mb-2" v-if="this.rows.length > customPaging.size">
        <li
          v-for="(item, idx) in customPaging.total"
          :key="idx"
          class="page-item"
        >
          <a
            class="page-link"
            :class="{ active: pageSelected === idx }"
            href="javascript:void(0)"
            @click="filterData(idx)"
          >
            <span>{{ idx + 1 }}</span>
          </a>
        </li>
      </ul>
      <!-- <pager :loadContentAtPage="loadPage" :page="customPaging.current" :size="customPaging.size" :total="customPaging.total" :pagerFull='false' v-if="customPaging.total > customPaging.size"></pager> -->
    </div>

    <!-- <Popper /> -->
    <Dialog ref="dialog" />

    <NotificationModal
      ref="notificationModal"
      :key="notificationModalKey"
      :reload="getItems"
      @refresh="getItems"
    />

    <NewletterModal
      ref="newletterModal"
      :key="newletterModalKey"
      @refresh="getItems"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Table, { DropdownItem, Dropdown } from "@/components/Table";

import adminServices from "@/services/admin";
import notify from "@/services/notify.js";
import { LP_MEDIA_TYPES } from "@/misc/constants";
import Dialog from "@/components/Dialog";
import NewletterModal from "./NewletterModal.vue";
import NotificationModal from "../notification-modal/NotificationModal";

const columnData = [
  {
    id: "name",
    name: "Title",
    _width: "55%",
    _wordBreak: "break-word",
    _minWidth: "280px",
  },
  {
    id: "created_at",
    name: "Created By",
    columnClass: "multiple-line",
  },
  {
    id: "lp_funds",
    name: "Funds",
    columnClass: "multiple-line",
  },
  {
    id: "PublishDate",
    name: "Publication month",
    columnClass: "multiple-line",
    _width: "15%",
  },
  {
    id: "active",
    name: "Status",
    _width: "15%",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "d-flex justify-content-center",
    columnClass: "text-center",
  },
];

export default defineComponent({
  components: {
    Table,
    DropdownItem,
    Dropdown,
    Dialog,
    NewletterModal,
    NotificationModal,
  },
  data() {
    return {
      rows: [],
      loading: false,
      paging: {
        sorts: null,
        size: 10,
        page: 1,
      },
      selectedItemId: null,
      isShowAddEdit: false,
      totalItems: 0,
      filteredData: [],
      sizeOptions: [10, 20, 50],
      size: 10,
      currentPage: 1,
      dataRows: [],
      customPaging: {
        size: 10,
        total: 0,
        current: 0,
      },
      columns: columnData,
      newletterModalKey: 0,
      statusList: [
        {
          name: "ACTIVE",
          code: true,
        },
        {
          name: "INACTIVE",
          code: false,
        },
      ],
      notificationModalKey: 0,
    };
  },
  mounted() {
    this.$nextTick(() => [this.getItems()]);
  },
  watch: {
    paging(v) {
      this.getItems();
    },
    rows: function (v) {
      this.customPaging.total = Math.ceil(v.length / this.customPaging.size);
      this.filterData(this.customPaging.current);
    },
  },
  computed: {
    _paging() {
      const totalPage = Math.ceil(this.totalItems / 1);
      if (totalPage == 1) return [];
      const showNumber = 5;
      const side = Math.floor(showNumber / 2);
      var arr = [];
      var left = [];
      var right = [];
      for (let i = 0; i < side; i++) {
        const res = this.currentPage - (side - i);
        if (res > 0) left.push({ id: res, target: res });
      }
      for (let i = 1; i <= side; i++) {
        const res = this.currentPage + i;
        if (res <= totalPage) right.push({ id: res, target: res });
      }
      if (left.length >= 1) {
        arr.push({ id: "first", target: 1 });
        arr.push({ id: "prev", target: this.currentPage - 1 });
      }
      arr.push(...left);
      arr.push({ id: this.currentPage, target: this.currentPage });
      arr.push(...right);
      if (right.length >= 1) {
        arr.push({ id: "next", target: this.currentPage + 1 });
        arr.push({ id: "last", target: totalPage });
      }
      return arr;
    },
  },
  methods: {
    viewNotification(props) {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.show(props);
      });
    },
    createNotification(props) {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.create(props);
      });
    },
    async addNewletter() {
      this.newletterModalKey++;
      this.$nextTick(() => {
        this.$refs.newletterModal.create();
      });
    },
    async editNewletter(props) {
      this.newletterModalKey++;
      this.$nextTick(() => {
        this.$refs.newletterModal.show(props);
      });
    },
    async lockNewletter(props) {
      this.loading = true;
      var isActive = true;
      if (props.active) {
        isActive = false;
      }
      const res = await adminServices.updateStatusLpMedia(props.id, isActive);
      if (res.data.ok) {
        notify.success("Update successfully!");
        this.getItems();
      }
      this.loading = false;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
    filterData: function (idx) {
      this.pageSelected = idx;
      this.customPaging.current = idx;
      this.filteredData = [];
      this.rows.map((p, index) => {
        if (
          index >= this.customPaging.current * this.customPaging.size &&
          index <
            this.customPaging.current * this.customPaging.size +
              this.customPaging.size
        ) {
          this.filteredData.push(p);
        }
      });
    },
    async getItems() {
      this.loading = true;
      const res = await adminServices.getLPMediaItemsByType(
        LP_MEDIA_TYPES.NEWSLETTER,
        this.paging
      );

      if (res.data.ok) {
        this.rows = res.data.d;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async orderChanged(items) {
      let tmpData = _.cloneDeep(items);
      tmpData = tmpData.map((x, idx) => {
        x.order_number = idx;
        return x;
      });
      this.loading = true;
      const res = await adminServices.sortLPMediaItems(tmpData);
      if (res.data.ok) {
        notify.success("Ordered successfully!");
        this.getItems();
      }
      this.loading = false;
    },
    openDialogToAddEdit(itemId) {
      this.selectedItemId = itemId;
      this.$nextTick(() => {
        this.isShowAddEdit = true;
      });
    },
    closeAddEdit() {
      this.isShowAddEdit = false;
      this.selectedItemId = null;
    },
    onCompleteAddEdit(data) {
      const { id, formType } = data;
      if (formType === "Edit") {
        notify.success("Update successfully");
      } else if (formType === "Add") {
        notify.success("Add successfully");
      }
      this.closeAddEdit();
      this.getItems();
    },
    async deleteNewletter(id) {
      let message = {
        title: "Warning",
        body: "This will permanently delete this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteLPMediaItem(id);
          if (res.data.ok) {
            notify.success("Deleted successfully!");
            this.getItems();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    loadPage(page) {},
    mapStatus(val) {
      const item = this.statusList.find((x) => x.code == val);
      return item && item.name ? item.name : null;
    },
  },
});
</script>
<style lang="scss" scoped>
.page-item {
  .page-link {
    border: none;
    color: #fff !important;
    font-weight: 600;
    background: transparent;
    transition: ease all 0.4s;
    border: 1px solid #585d6e;
    width: 35px;
    height: 35px;
  }
  .active {
    background-color: #949ba2 !important;
    color: white;
  }
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-end !important;
  align-items: center;
  margin-top: 10px;
}
</style>
