import { defineComponent } from 'vue'
import DealServices from '../../../../../services/deal'
import notify from '../../../../../services/notify'
import reviewer from './reviewers.vue'
import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'          
import AdminServices from '@/services/admin' 

export default defineComponent({
    props: ['specifyIdDeal', 'currentStage', 'totalStage'],
    components: {
        reviewer
    },
    emits: ['reloadDealMessage', 'reloadFileUploaded'],
    data() {
        return {
            loading: false,
            currentType: null,
            valueMessage: false,
            valueFile: false,
            reviewers: [],
            value: null,
            process_flag: false,
            cancelFlg: false,
            upload: null,
            isUploading: false
        }
    },
    mounted() {
        this.currentType = 'comment'
    },
    methods: {
        showConfigNoti: function () {
            this.$refs.reviewer.showModal()
        },
        action: function (type) {
            this.currentType = type
        },
        submitComment: function () {
            this.loading = true
            if (this.$refs.message.value.trim() !== '') {
                DealServices.submitDealmessage(this.specifyIdDeal, { stage_id: this.totalStage[this.currentStage].id, message: this.$refs.message.value.trim(), notify: this.reviewers }).then(resp => {
                    if (resp.data.ok) {
                        this.reviewers = []
                        this.$refs.message.value = ''
                        this.valueMessage = false
                        this.$emit('reloadDealMessage', true)
                        notify.success('Your comment was sent successfully!')
                    }
                }).finally(() => {
                    this.loading = false
                })
            } else {
                //do something
            }
        },
        valueMessageFnc: function (el) {
            this.valueMessage = false
            if (this.$refs[el].value.trim() !== '') {
                this.valueMessage = true
            }
        },
        valueDocumentFnc: function (el) {
            this.valueFile = false
            if (this.$refs[el].value) {
                this.valueFile = true
                this.$refs[el].parentNode.setAttribute('data-before', this.$refs[el].files[0].name)
            }
        },
        clearFileUpload: function() {
            this.$refs.file.parentNode.setAttribute('data-before', '')
            this.$refs.file.value = null
            this.valueFile = false
            this.process_flag = false
            this.isUploading = false
            try {
                if(this.upload) {
                    this.upload.abort()
                }
            } catch(e) {
                console.log(e)
            }
        },
        isLargeFile(fileSize) {
            return fileSize <= 536870912
        },
        async submitUpload() {
            this.isUploading = true
            this.process_flag = true
            this.value = 0.1
            let that = this
            var _awsCred = await AdminServices.getSessionToken()
            var file = this.$refs.file.files[0]
            var docPro = document.getElementById("progress");
            AWS.config.update({
                region: _awsCred.data.d.region,
                credentials: new AWS.Credentials(_awsCred.data.d.access_key, _awsCred.data.d.secret_key, _awsCred.data.d.session_token)
            })
            if (that.isLargeFile(file.size)) {
                let lastDot = file.name.lastIndexOf('.')
                this.upload = new S3.ManagedUpload({
                    params: {
                        Bucket: _awsCred.data.d.bucket,
                        Key: this.getRandomKey(12) + file.name.substring(lastDot),
                        Body: file
                    }
                })
                this.upload.on('httpUploadProgress', progress => {
                    if (progress.total) {
                         docPro.value = file.progress = this.value = Math.round((100 * progress.loaded) / progress.total) === 0 ? 0.1 : Math.round((100 * progress.loaded) / progress.total)
                   }
                })
                
                let formData = new FormData()
                if (file) {
                    formData.append('FileExtension', file.name.substring(lastDot))
                    formData.append('FileName', file.name.substring(0, lastDot))
                    formData.append('FileSize', file.size)
                    formData.append('FileS3Url', (await this.upload.promise()).Key)
                    formData.append('StageId', this.totalStage[this.currentStage].id)
                    DealServices.submitDealDocument(this.specifyIdDeal, formData).then(resp => {
                        if (resp.data.ok) {
                            this.$emit('reloadFileUploaded', true)
                            notify.success('The document was uploaded successfully!')
                        }
                    }).finally(() => {
                        this.value = null
                        this.process_flag = false
                        this.loading = false
                        this.isUploading = false
                        this.$refs.file.value = ''
                        this.valueFile = false
                        this.$refs['file'].parentNode.setAttribute('data-before', '')
                    })
                }         
            } 
            else {
                notify.error('The document can\'t upload!')
            }  
        },
        changeReviewers(data) {
            this.reviewers = data
        }
    },
    watch: {
        currentStage: function (v) {
            if (v) {
                this.reviewers = []
            }
        }
    }
})