<template>
    <div class="adminContent container-fluid">
      <backToList class="mb-3" :props="null" />
      <div class="row">
        <div class="col-12 col-xl-6">
          <eventInfo />
        </div>
      </div>
    </div>
  </template>
  
  <script src="./index.js"></script>
  