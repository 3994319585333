<template>
  <Modal :title="`${state}`" v-model:value="isShow">
    <form @submit.prevent="save" autocomplete="false">
      <div v-if="groupName == 'regionchart' || groupName == 'stagechart'">
        <template v-for="(item, idx) in chartData" :key="idx">
          <div class="row">
            <div class="col-6">
              <TextField
                v-model:value="item.category"
                title="Category"
                name="category"
                :maxLength=15
                :autocomplete="false"
                :fieldRequire="true"
                :errorMsg="!!!item.category ? 'Please input category' : ''"
              />
            </div>
            <div class="col-6">
              <TextField
                v-model:value="item.value"
                title="Value"
                name="value"
                type="number"
                :maxLength="4"
                :autocomplete="false"
                :fieldRequire="true"
                :errorMsg="!!!item.value ? 'Please input value' : ''"
              />
            </div>
          </div>
        </template>
      </div>
      <div v-else class="row">
        <div :class="groupData.text ? `col-6` : `col-12`">
          <TextField
            v-model:value="groupData.value"
            title="Value"
            name="value"
            :maxLength=6
            :type="groupName != 'moic' ? 'number' : 'text'"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.groupDataValue"
          />
        </div>
        <div class="col-6">
          <TextField
            v-model:value="groupData.text"
            title="Text"
            name="text"
            :maxLength=50
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.groupDataText"
            v-if="groupData.text"
          />
        </div>
      </div>
      <div
        v-if="isCount"
        :style="`position: fixed;color:#f52727;font-size: 0.8em;`"
      >
        Total value equal to 100%
      </div>
    </form>
    <template #footer>
      <NewButton @click="save" :loading="loading">
        Update
      </NewButton>
    </template>
  </Modal>
</template>

<script src="./MapchartModel.js"></script>
