<template>
  <div class="h-100 container-fluid" v-if="useEmptyLayout">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="row justify-content-center">
          <div
            class="col-sm-9 align-items-center justify-content-center"
            v-loading="loading"
          >
            <div class="d-flex align-items-center mt-3">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>
            <div
              class="wrapper w-45 max-width margin-auto"
              :class="success ? 'mt-lg-5' : ''"
            >
              <div v-if="success" class="colorGray text-center">
                <h4 class="colorWhite">Confirmation email sent!</h4>
                <p>
                  A verification link has been sent <br />Please check your Spam
                  or Junk folder in case you don’t see the email in Inbox.
                </p>
              </div>
              <form
                id="loginForm"
                class="colorWhite"
                v-else
                :disabled="disabled"
              >
                <h2 class="font-weight-bold mb-2">Resend confirmation email</h2>
                <p class="text-center">Please enter your email</p>
                <!-- <md-field class="mb-4">
                  <label>Email</label>
                  <md-input v-model="email" type="email"></md-input>
                </md-field> -->

                <TextField
                  class="mb-4"
                  title="Email"
                  :indexField="1"
                  :fieldRequire="true"
                  v-model:value="email"
                  type="email"
                  :errorMsg="errorMsg.email"
                />

                <div class="form-group text-center">
                  <!-- <recaptcha
                    ref="recaptcha"
                    v-model="recaptcha"
                    :instance="captchaInstance"
                    :dataCallback="submit"
                  /> -->
                  <ReCaptcha
                    ref="recaptcha"
                    v-model:value="recaptcha"
                    :errorMsg="errorMsg.captcha"
                  />
                </div>
                <div class="form-group">
                  <div class="row">
                    <div
                      class="col-12 col-lg-12 col-md-12 col-sm-12 text-center"
                    >
                      <button
                        @click.prevent="validate"
                        class="btn button btn-large btn-primary btn-bounce btn-active btnReg w-100"
                      >
                        Resend
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="text-center colorWhite">
                <span class="colorGray me-1">Already have an account?</span>
                <router-link class="link" :to="loginPath">Sign in</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-0">
        <div class="boxFix"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.colorDarkGray {
  color: #2e384d;
}
.colorGray {
  color: #b0bac9;
}
.logoLogin {
  img {
    object-fit: cover;
    width: 190px;
    height: auto;
  }
}
// .wrapper {
//   background-color: #fff;
// }
#loginForm {
  font-size: 0.9375rem;
  margin-top: 2rem;
  padding: 2rem;
  // color: #2e384d;
  // opacity: 0.8;
  // background-color: #fff;
  .signInText {
    font-size: 2.125rem;
  }
}
</style>
