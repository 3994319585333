<template>
  <div class="adminContent container-fluid">
    <backToList :props="form" />

    <div class="inner-page">
      <div class="col nav-right">
        <ul class="nav">
          <li class="nav-item">
            <a
              class="nav-link px-4 pointer"
              :class="{ active: currentTab === 'infoTab' }"
              @click.prevent="toTab('infoTab')"
              >Event Info & Session</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link px-4 pointer"
              :class="{ active: currentTab === 'attendeeTab' }"
              @click.prevent="toTab('attendeeTab')"
              >Attendee</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link px-4 pointer"
              :class="{ active: currentTab === 'galleryTab' }"
              @click.prevent="toTab('galleryTab')"
              >Gallery</a
            >
          </li>
        </ul>
      </div>
      <div class="container-fluid">
        <component
          :is="currentTab"
          :eventInfoData="eventInfoData"
          @reloadEventInfo="getAll(form.eventId)"
        ></component>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.nav .nav-item .nav-link.active:after {
  height: 2px;
  background-color: #0095ff;
}

.nav-item {
  .nav-link {
    color: #cdcdcd;
    &:hover {
      color: #7e7e7e;
    }
  }
}
</style>
