import { defineComponent } from "vue";

export default defineComponent({
    name: 'buttonOnOff',
    props: {
        labels: String,
        value: Boolean,
        onChangeOption: Function
    },
    data() {
        return {
            text: {
                0: 'On',
                1: 'Off'
            }
        }
    },
    methods: {
        change() {
            let obj = {
                value: !this.value
            }
            this.onChangeOption(obj)
        }
    }
})