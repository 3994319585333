<template>
  <div class="content" v-loading="loading">
    <div class="row">
      <div class="col leftContent text-center">
        <template v-if="isInvitation">
          <div class="row">
            <div
              class="col-12"
              v-for="(event, idx) in events"
              :key="`event_${idx}`"
            >
              <div
                class="dateItem pointer"
                :class="{
                  'active-info': event.eventId === eventActive.eventId,
                }"
                @click="setActive(event)"
              >
                <div>{{ event.name }}</div>
                <div>
                  <small
                    ><span :style="{ color: 'gray' }">Form:</span>
                    {{ $filters.parseEstTime(event.startDateTime) }}</small
                  >
                </div>
                <div>
                  <small
                    ><span :style="{ color: 'gray' }">To:</span>
                    {{ $filters.parseEstTime(event.endDateTime) }}</small
                  >
                </div>
                <div>
                  <small>{{ convertStatus(event.status) }}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12 d-flex justify-content-center mt-4 noData">
              No data
            </div>
          </div>
        </template>
      </div>
      <div class="col rightContent">
        <template v-if="isInvitation">
          <div class="event-content" v-if="eventActive != null">
            <div class="left-section">
              <div class="row mb-3">
                <div class="col">
                  <label class="subject"></label>
                </div>
              </div>
              <div class="row">
                <div class="col col-name">
                  <label class="lb_text"> Event Name:</label>
                </div>
                <div class="col col-value">
                  <label class="sub_text">{{ eventActive.name }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col col-name">
                  <label class="lb_text"> Location:</label>
                </div>
                <div class="col col-value">
                  <label class="sub_text">{{ eventActive.location }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col col-name">
                  <label class="lb_text"> Description:</label>
                </div>
                <div class="col col-value">
                  <span class="sub_text">{{ eventActive.description }}</span>
                </div>
              </div>
            </div>
            <div class="right-section">
              <div class="row mb-2">
                <div class="col">
                  <label class="subject">SESSION</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <template v-if="milestones.length > 0">
                    <div
                      class="milestone"
                      v-for="(milestone, idx) in milestones"
                      :key="idx"
                    >
                      <div class="row">
                        <div class="col col-name">
                          <label class="lb_text"> Name:</label>
                        </div>
                        <div class="col col-value">
                          <label class="sub_text">{{ milestone.name }}</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col col-name">
                          <label class="lb_text"> Check-in:</label>
                        </div>
                        <div class="col col-value">
                          <label class="sub_text">
                            {{ $filters.parseEstTime(milestone.checkInDate) }}
                            <a>(EST)</a>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col col-name">
                          <label class="lb_text"> Start:</label>
                        </div>
                        <div class="col col-value">
                          <label class="sub_text">
                            {{ $filters.parseEstTime(milestone.startDate) }}
                            <a>(EST)</a>
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col col-name">
                          <label class="lb_text"> End:</label>
                        </div>
                        <div class="col col-value">
                          <label class="sub_text">
                            {{ $filters.parseEstTime(milestone.endDate) }}
                            <a>(EST)</a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="d-flex justify-content-center mt-4 noData">
                      No data
                    </div>
                  </template>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <template
                    v-if="pagingMilestone.total >= pagingMilestone.size"
                  >
                    <ul
                      v-if="_paging.length >= 1"
                      class="pagination d-flex justify-content-center"
                    >
                      <li
                        v-for="(page, pageI) in _paging"
                        :key="`${pageI}-pageI`"
                        :class="`page-item ${
                          page.id == currentPage ? 'active' : ''
                        }`"
                        @click="pageClick(page)"
                      >
                        <a class="page-link">
                          <span v-if="page.id == 'next'"
                            ><i class="fas fa-angle-right"
                          /></span>
                          <span v-else-if="page.id == 'prev'"
                            ><i class="fas fa-angle-left"
                          /></span>
                          <span v-else>{{ page.id }}</span>
                        </a>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </div>
            <div class="left-section">
              <div class="row mb-3">
                <div class="col header-section">
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label class="subject">INVITATION</label>
                    </div>
                    <div
                      class="col d-flex align-items-center justify-content-end"
                    >
                      <div class="mr-2">
                        <label class="subject me-1">Status:</label>
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle btn-dropdown"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{
                            activeAnwser == null ? "No Select" : activeAnwser
                          }}
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item"
                            @click="
                              checkResponse(confirmationCode.answerYes, 'YES')
                            "
                            >Yes</a
                          >
                          <a
                            class="dropdown-item"
                            @click="
                              checkResponse(confirmationCode.answerNo, 'NO')
                            "
                            >No</a
                          >
                          <a
                            class="dropdown-item"
                            @click="
                              checkResponse(
                                confirmationCode.answerMaybe,
                                'MAYBE'
                              )
                            "
                            >Maybe</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="isInvitation">
                <div class="row">
                  <div class="col-12 mb-2">
                    <label class="lb_text"
                      >Show this QR code to our staff as a ticket at the
                      security check to enter event</label
                    >
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <img
                      id="qr_code"
                      class="qrCode"
                      :src="`https://chart.googleapis.com/chart?chs=200x200&amp;cht=qr&chld=M%7C0&amp;chl=${invitationKey}`"
                    />
                  </div>
                </div>
                <!-- <div
                  class="row"
                  v-if="activeAnwser === 'NO' || activeAnwser === 'MAYBE'"
                >
                  <div
                    class="
                      col-12
                      mb-2
                      d-flex
                      justify-content-center
                      mt-4
                      noData
                    "
                  >
                    <label class="lb_text"
                      >Confirmation status is
                      {{ convertInvitationStatus(activeAnwser) }}</label
                    >
                  </div>
                </div> -->
              </template>
              <template v-else>
                <div class="col-12 d-flex justify-content-center mt-4 noData">
                  No data
                </div>
              </template>
            </div>
            <div class="right-section">
              <div class="row">
                <div class="col header-section">
                  <div class="row d-flex align-items-center">
                    <div class="col pt-1">
                      <label class="subject">GALLERY</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gallery">
                <template v-if="galleries.length > 0">
                  <CardItem
                    v-for="(item, index) in galleries"
                    :item="item"
                    :url="item.url"
                    :key="`car-${index}`"
                  />
                  <div class="col-12 mt-3 px-2">
                    <pager
                      :loadContentAtPage="loadPageGalleries"
                      :page="pagingGallery.page"
                      :size="pagingGallery.size"
                      :total="pagingGallery.total"
                      :pagerFull="false"
                      v-if="pagingGallery.total > pagingGallery.size"
                    >
                    </pager>
                  </div>
                </template>
                <template v-else>
                  <div class="col-12 d-flex justify-content-center mt-4 noData">
                    No data
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12 d-flex justify-content-center mt-4 noData">
              No data
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

.event-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin: 20px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.leftContent {
  flex: 0 0 12%;
  min-width: 300px;
  padding-right: 0;
  border-right: 1px solid #585d6e;
  min-height: calc(100vh - 70px - 2rem);
  @media screen and (max-width: 564px) {
    padding-right: 15px;
  }

  @media screen and (max-width: 590px) {
    flex: 0 0 100%;
    min-height: auto;
    .dateItem {
      margin: 20px 32px 0 10px;
    }
  }
  @media screen and (max-width: 564px) {
    .dateItem {
      margin: 20px 17px 0 20px;
    }
  }
}

.rightContent {
  flex: 1;
  padding-left: 0;
  @media screen and (max-width: 564px) {
    padding-left: 15px;
  }

  .left-section {
    border-radius: 5px;
    background-color: #292c33;
    border: 1px solid rgba(213, 213, 213, 0.1);
    padding: 20px 30px;
    .header-section {
      .dropdown {
        .dropdown-menu {
          left: -32px !important;
        }
      }
    }
  }

  .right-section {
    border-radius: 5px;
    background-color: #292c33;
    border: 1px solid rgba(213, 213, 213, 0.1);
    padding: 20px 30px;
  }
}
.dateItem {
  background-color: #292c33;
  color: #a7a7a7;
  border-radius: 5px;
  margin: 20px 20px 0px 20px;
  padding: 10px;
  border: 1px solid transparent;

  &:hover {
    border-color: #0095ff;
  }
}

.active-info {
  border: 1px solid #0095ff;
  color: #fff;
}

.subject {
  font-weight: 600;
  color: #f1f1f1;
}

.lb_text {
  display: flex;
  font-weight: 300;
  color: #a7a7a7;
}

.milestone {
  border-bottom: 1px solid rgba(213, 213, 213, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  label {
    margin-bottom: 1px;
  }
}

.sub_text {
  word-break: break-word;
  font-weight: 400;
  color: #f1f1f1;
}

.page-item {
  cursor: pointer;

  .page-link {
    background-color: transparent;
    border: 1px solid #585d6e;
    width: 35px;
    height: 35px;
    color: #949ba2;
    display: block;
    text-align: center;
  }

  &.active {
    .page-link {
      background-color: #4b596a;
      color: #f1f1f1;
    }
  }
}

.gallery {
  padding: 10px;
}

.noData {
  color: #a7a7a7;
}

.qrCode {
  border-radius: 5px;
}

.col-name {
  flex: 0 0 120px;
  max-width: unset;
}

.col-value {
  flex: 1;
  max-width: unset;
}

.btn-dropdown {
  width: 100px;
}
</style>
