import { defineComponent } from "vue";
import { QrcodeStream } from "vue3-qrcode-reader";
import adminServices from "@/services/admin";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import checkOk from "@/assets/images/check-ok.png";
import caution from "@/assets/images/caution.png";
import notify from "@/services/notify";

export default defineComponent({
  name: "scan-qr-code",
  components: {
    QrcodeStream,
    Table,
    TextField,
  },
  data() {
    return {
      eventId: "",
      milestoneId: "",
      isShowScanQrcode: null,
      checkOk,
      caution,
      paging: {
        size: 10,
        total: 0,
        page: 1,
      },
      form: {
        startDateTime: null,
        endDateTime: null,
        name: "",
        location: "",
        description: "",
      },
      timezone: "America/New_York",
      status: "",
      messageError: "",
      rows: [],
      totalItems: 0,
      loading: false,
      paging: undefined,
      filteredEmail: "",
      columns: [
        {
          id: "email",
          name: "Email",
        },
        {
          id: "checkInDate",
          name: "Check in",
        },
      ],
    };
  },
  watch: {
    "paging.page": function (v) {
      debounce(this.getAll);
    },
    filteredEmail() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isShowScanQrcode = true;
      this.eventId = this.$route.params.id;
      this.milestoneId = this.$route.query.m;
      if (this.eventId) this.getEventById();
    });
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async onDecode(invitationCode) {
      this.isShowScanQrcode = false;
      this.qrscanResult = {};
      this.status = "";
      const res = await adminServices.attendeeCheckIn({
        eventId: this.eventId,
        milestoneId: this.milestoneId,
        invitationCode: invitationCode,
      });
      if (res.data.ok) {
        this.qrscanResult = res.data.d;
        this.status = "success";
        this.getAll();
        notify.success("Check-in successfully");
      } else {
        if (res.data.c !== null) {
          this.messageError = "";
          this.status = "error";
          this.messageError = res.data.m;
        }
      }
    },
    nextScan() {
      this.isShowScanQrcode = true;
    },
    handleBackEvent() {
      this.$router.push(`/rfm/event/${this.eventId}`);
    },
    getEventById() {
      adminServices.getEvent(this.eventId).then((resp) => {
        if (resp.data.ok) {
          const obj = {
            startDateTime: resp.data.d.startDateTime,
            endDateTime: resp.data.d.endDateTime,
            ...resp.data.d,
          };
          this.form = obj;
        }
      });
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getAllEvtCheckinByMilestone(
        {
          page: this.paging.page,
          size: this.paging.size,
        },
        this.eventId,
        this.milestoneId,
        this.filteredEmail
      );

      if (res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    updatePaging(v) {
      if (v) this.paging = { ...this.paging, ...v };
    },
  },
});
