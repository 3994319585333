import { defineComponent } from "vue";

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    autocomplete: { type: Boolean, default: false },
    value: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    field: { type: String, default: "" },
    color: { type: String, default: "" },
    maxLength: { type: Number, default: 5000 },
    fieldRequire: { type: Boolean, default: false },
    indexField: { type: Number, default: 0 },
    rowHeight: { type: Number, default: 5 },
  },
  emits: ["update:value", "update:errorMsg"],
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  methods: {
    checkLength() {
      if (this.maxlength)
        this._value = this.$el
          .querySelector("textarea")
          .value.substring(0, this.maxlength);
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
