import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'renameItem',
    props: ['dataInfo', 'hideRenameItem'],
    emits: ['rename-complete'],
    data() {
        return {
            form: {
                id: null,
                name: null,
                type: null,
                extension: null,
                newName: null,
                renameAll: false
            },
            errorMsg: {
                newName: null
            },
            loading: false,
            sameNameFiles: []
        }
    },
    created() {
        if (this.dataInfo) {
            this.form.id = this.dataInfo.id
            this.form.name = this.dataInfo.name,
            this.form.newName = this.dataInfo.name,
            this.form.type = this.dataInfo.type,
            this.form.extension = this.dataInfo.extension
            if (this.dataInfo.type === 'File') {
                this.loading = true
                this.getDataroomFileItemsOfSameName().finally(() => { this.loading = false })
            }
        }
    },

    methods: {
        validData() {
            let flag = true
            if (this.form.newName.trim() === '') {
                this.errorMsg.newName = 'Please input new name'
                flag = false
            } else if (!this.validateFileAndDirectoryName(this.form.newName.trim())) {
                this.errorMsg.newName = 'Name cannot contain characters * : " \\ | < > \/ ?'
                flag = false
            }
            if (flag) {
                this.loading = true
                if (this.form.newName.trim() === this.dataInfo.name.trim()) {
                    this.loading = false
                    this.hideRenameItem()
                } else {
                    AdminServices.renameDataroomItem({id: this.form.id, name: this.form.newName, renameAll: this.form.renameAll})
                    .then(resp => {
                        if (resp.data.ok) {
                            this.$emit('rename-complete', {id: this.form.id, name: this.form.newName})
                        }
                    })
                    .finally(() => this.loading = false)
                }
            }
        },
        getDataroomFileItemsOfSameName() {
            return new Promise((resolve, ) => {
                AdminServices.getDataroomFileItemsOfSameName(this.dataInfo.id)
                .then(resp => {
                    if (resp.data.ok) {
                        this.sameNameFiles = resp.data.d.filter(x => x.id !== this.dataInfo.id)
                    }
                    resolve(true)
                })
            })
        },
    },
    watch: {
        'form.newName': function (v) {
            this.errorMsg.newName = null
        },
    }
})