<template>
  <Modal :title="`Notification`" v-model:value="isShow" closeBtnText="Close">
    <template #header>
      <NewButton @click="openAddNotifyUserModel" class="add-user"
        >Add User</NewButton
      >
    </template>
    <div class="row">
      <div class="col-12">
        <div class="data">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Notify Sms</th>
                  <th>Notify Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reviewer, index) in relReviewers"
                  :key="`reviewer_${index}`"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ reviewer.user_name }}</td>
                  <td>
                    <div class="custom-control custom-switch">
                      <Switcher
                        v-model:value="reviewer.notify_sms"
                        :name="`sms_${index}`"
                        size="small"
                        :isShowText="false"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-switch">
                      <Switcher
                        v-model:value="reviewer.notify_email"
                        :name="`email_${index}`"
                        size="small"
                        :isShowText="false"
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn btn-danger"
                      @click="addUser({ id: reviewer.user_id })"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddNotifyUserModal
      ref="addNotifyUserModal"
      :key="addNotifyUserModalKey"
      :users="relReviewers"
      @addUser="addUser"
    />
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import NewButton from "@/components/NewButton";
import Switcher from "@/components/Switcher";
import AddNotifyUserModal from "./AddNotifyUserModal.vue";

export default defineComponent({
  props: {
    totalStage: { type: Array, default: [] },
    currentStage: { type: Number, default: null },
    relReviewers: { type: Array, default: null },
  },
  components: {
    Modal,
    NewButton,
    Switcher,
    AddNotifyUserModal,
  },
  emits: ["changeRelReviewers"],
  data() {
    return {
      isShow: false,
      addNotifyUserModalKey: 0,
    };
  },
  computed: {
    _relReviewers: {
      get() {
        return this.relReviewers;
      },
      set(val) {
        this.$emit("changeRelReviewers", val);
      },
    },
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    addUser(user) {
      let valid = this._relReviewers.find((x) => x.user_id === user.id);
      if (valid) {
        this.$emit(
          "changeRelReviewers",
          this._relReviewers.filter((x) => x.user_id !== user.id)
        );
      } else {
        this.$emit("changeRelReviewers", [
          ...this._relReviewers,
          {
            user_id: user.id,
            user_name: user.name,
            notify_email: true,
            notify_sms: true,
          },
        ]);
      }
    },
    openAddNotifyUserModel() {
      this.addNotifyUserModalKey++;
      this.$nextTick(() => {
        this.$refs.addNotifyUserModal.show();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.add-user {
  position: absolute !important;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
  padding: 3px 5px;
}
</style>
