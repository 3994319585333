<template>
  <div class="switchButton" :class="[`button-${size}`]">
    <template v-if="!isHorizontal">
      <div v-if="title" class="title">{{ title }}</div>
      <label
        class="switch custom-control-label"
        :class="[isShowText ? 'me-2' : '']"
        :for="name"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="_value"
          value="true"
          :id="name"
        />
        <span class="slider round"></span>
      </label>
      <!-- <span v-if="isShowText" class="text">{{
        _value ? "enable" : "disable"
      }}</span> -->
    </template>
    <template v-else>
      <div
        :class="`custom-control pl-0 custom-switch-horizontal-${titleDirection}`"
      >
        <div v-if="title" class="title">{{ title }}</div>
        <label
          class="switch custom-control-label"
          :class="[isShowText ? 'me-2' : '']"
          :for="name"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            v-model="_value"
            value="true"
            :id="name"
          />
          <span class="slider round"></span>
        </label>
        <!-- <span v-if="isShowText" class="text">{{
          _value ? "enable" : "disable"
        }}</span> -->
      </div>
    </template>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.custom-control {
  margin-bottom: 15px;
  label {
    line-height: 1.5;
  }
}

//new style
.switchButton {
  &.button-small {
    input:checked + .slider {
      &:before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
      }
    }

    .switch {
      width: 34px;
      height: 20px;
    }

    .slider {
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
  input:checked + .slider {
    background-color: #2196f3;
    &:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 26px;
    > input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }

  .text {
    line-height: 26px;
    display: inline-block;
  }
}
.custom-control {
  display: flex;
  align-items: flex-end;
  .title {
    margin-right: 5px;
  }
}
.custom-switch-horizontal-right {
  flex-direction: row-reverse;
  .title {
    margin-left: 5px;
  }
}
.custom-control-label {
  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}
</style>
