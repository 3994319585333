import moment from "moment";
import Modal from "@/components/Modal";
import Datepicker from "@/components/DatePicker";

import { defineComponent } from "vue";

export default defineComponent({
  name: "considerTillDate",
  props: {
    hideConsiderModal: Function,
    hasFloatingCloseButton: Boolean,
  },
  components: {
    Modal,
    Datepicker,
  },
  data() {
    return {
      customFormatterDate: moment().format("MM/DD/YYYY"),
      customFormatterTime: moment().format("hh:mm a"),
      isShow: false,
      select_date: {
        days: "",
        months: "",
        years: "",
      },
      select_time: {
        hours: "",
        minutes: "",
      },
      formData: {
        dateTime: "",
      },
      validDate: null,
      errorMsg: {
        notify_before_hour: null,
      },
    };
  },
  computed: {
    yesterday() {
      return moment().format("yyyy-MM-DD");
    },
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
    },
    hide: function () {
      this.hideConsiderModal(this.formData);
      this.hideModal();
    },
  },
  watch: {
    select_date(v) {
      if (v) {
        this.validDate = true;
        this.formData.dateTime = { ...v, ...this.select_time };
      }
    },
    select_time(v) {
      if (v) {
        this.formData.dateTime = { ...this.select_date, ...v };
      }
    },
  },
});
