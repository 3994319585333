<template>
  <transition name="fade" v-if="isShow">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideModal"></div>
      <div class="modal-dialog" ref="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" v-loading="loading">
          <div class="modal-header text-center">
            <h4 class="modal-title">{{ typeName }} Team Member</h4>
          </div>
          <div class="modal-body">
            <div id="exTab1" class="container-fliud p-0">
              <ul class="nav nav-pills mb-3" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="active show"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    >Profile</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    data-bs-toggle="tab"
                    data-bs-target="#social"
                    type="button"
                    role="tab"
                    aria-controls="social"
                    aria-selected="true"
                    >Social</a
                  >
                </li>
              </ul>

              <div class="tab-content clearfix">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="row mb-0">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Name"
                        name="name"
                        fieldRequire
                        :autocomplete="false"
                        :errorMsg="error.name"
                        placeholder="Enter name of member"
                        :maxLength="250"
                        v-model:value="formData.name"
                      />
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Position"
                        name="Position"
                        fieldRequire
                        :autocomplete="false"
                        :errorMsg="error.title"
                        placeholder="Enter Position of member"
                        :maxLength="250"
                        v-model:value="formData.title"
                      />
                    </div>
                  </div>
                  <div class="row mb-0 custom-row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Email"
                        color="white"
                        field="black"
                        name="email"
                        type="email"
                        :autocomplete="false"
                        placeholder="Enter email of member"
                        v-model:value="formData.email"
                      />

                      <div class="form-group mt-3">
                        <input
                          type="checkbox"
                          class="me-1"
                          v-model="formData.is_enabled"
                        />
                        <span class="mb-0">Enabled</span>
                      </div>
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Home town"
                        name="hometown"
                        :autocomplete="false"
                        placeholder="Enter home town of member"
                        :maxLength="100"
                        v-model:value="formData.home_town"
                      />
                      <div class="form-group mt-3">
                        <input
                          type="checkbox"
                          class="me-1"
                          v-model="formData.perspective_status"
                        />
                        <span class="mb-0">Fuel Perspective Author</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <div class="custom-file">
                          <label for="customFileLang" class="mb-0"
                            >Photo <small class="colorRed">*</small>
                          </label>
                          <label
                            class="custom-file-label mb-0 pointer"
                            for="customFileLang"
                          >
                            <i
                              class="fas fa-cloud-upload-alt"
                              :class="{ dataPhoto: dataPhoto }"
                            ></i>

                            <p class="text-center mb-0" v-if="!dataPhoto">
                              click to upload
                            </p>
                            <img
                              :src="dataPhoto"
                              alt=""
                              class="dataImg"
                              v-else
                            />
                            <input
                              type="file"
                              id="customFileLang"
                              class="custom-file-input"
                              ref="fileimg"
                              @change="renderFileImg"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                          </label>
                        </div>
                        <span class="texterror colorRed" v-if="error.photo"
                          ><small>Please choose one picture</small></span
                        >
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <div class="custom-file">
                          <label for="customFile" class="mb-0"
                            >Signature
                          </label>
                          <label
                            class="custom-file-label mb-0 pointer"
                            for="customFile"
                          >
                            <i
                              class="fas fa-cloud-upload-alt"
                              :class="{ dataSignature: dataSignature }"
                            ></i>
                            <p class="text-center mb-0" v-if="!dataSignature">
                              click to upload
                            </p>
                            <img
                              :src="dataSignature"
                              alt=""
                              class="dataImg"
                              v-else
                            />
                          </label>
                          <input
                            type="file"
                            id="customFile"
                            class="custom-file-input"
                            ref="fileimgSignature"
                            @change="renderFileSignature"
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-12">
                      <div class="form-group">
                        <label for="introduction" class="mb-0">
                          Introduction <small class="colorRed">*</small>
                        </label>
                        <ckeditor
                          id="edit-team-member"
                          :editor="editor"
                          :config="editorConfig"
                          v-model="editorData"
                          :maxWordCount="4000"
                          @ready="prefill"
                          :isReadOnly="false"
                          :enableReadOnlyMode="'id'"
                        ></ckeditor>
                        <span class="texterror colorRed" v-if="error.intro"
                          ><small>Please input introduction</small></span
                        >
                        <div style="text-align: right">
                          {{ !editorData ? 0 : editorData.length }}/4000
                        </div>
                        <span
                          class="texterror colorRed"
                          v-if="error.introLength"
                          ><small>Exceed 4000 words</small></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="social"
                  role="tabpanel"
                  aria-labelledby="social-tab"
                >
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Facebook"
                        id="fb"
                        name="fb"
                        :autocomplete="false"
                        placeholder="Link to Facebook"
                        v-model:value="formData.ext.Facebook"
                      />
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Twitter"
                        id="tw"
                        name="tw"
                        :autocomplete="false"
                        placeholder="Link to Twitter"
                        v-model:value="formData.ext.Twitter"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Linked in"
                        id="li"
                        name="li"
                        :autocomplete="false"
                        placeholder="Link to Linked in"
                        v-model:value="formData.ext.Linkedin"
                      />
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Instagram"
                        id="it"
                        name="it"
                        :autocomplete="false"
                        placeholder="Link to Instagram"
                        v-model:value="formData.ext.Instagram"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Medium"
                        :autocomplete="false"
                        placeholder="Link to Medium"
                        v-model:value="formData.ext.Medium"
                      />
                    </div>
                    <div class="col col-md-6 col-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="hideModal">Cancel</button>
            <button class="btn btn-primary" @click="validateForm">
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
textarea {
  resize: none;
}
.modal-footer {
  display: block;
}
.custom-file {
  height: 150px;
  .custom-file-label {
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    height: 120px;
    position: relative;
    overflow: hidden;
    &::after {
      display: none;
    }
    .dataImg {
      height: 100%;
      width: auto;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
    i {
      font-size: 80px;
      &.dataPhoto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }

      &.dataSignature {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
    input {
      opacity: 0;
      width: 1px;
      height: 1px;
      display: block;
      overflow: hidden;
    }
  }
  .custom-file-input {
    height: 0;
  }
}
.custom-row {
  height: 150px;

  @media screen and (max-width: 767.98px) {
    height: unset;
    .custom-file {
      height: 150px;
    }
    .custom-file-input {
      height: 0;
    }
  }
}
</style>
