import AdminServices from '@/services/admin'
import notify from '../../../../services/notify'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        id: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            members: [],
            loading: false
        }
    },
    computed: {
        havingSelectedItems() {
            return this.members.filter(x => x.isSelected === true).length > 0
        }
    },
    created() {
        if (this.id) {
            this.loadData(this.id)
        }
    },
    methods: {
        async loadData(id) {
            this.loading = true
            try {
                const _allMembers = await this.getTeamData()
                const _membersOfFund = await this.fetchMembersByFundId(id)
                const _memberIdsOfFund = _membersOfFund.map(x => x.id)
                _allMembers.forEach(x => {
                    if (!_memberIdsOfFund.includes(x.id)) {
                        this.members.push({
                            ...x,
                            isSelected: false
                        })
                    }
                })
            } catch (err) {
                console.log(err)
                notify.error('Error', 'Some errors occurred.')
            }
            this.loading = false
        },
        async getTeamData() {
            try {
                const _allMemberRes = await AdminServices.getTeamData()
                if (!_allMemberRes.error) {
                  return _allMemberRes.data.d
                } else {
                  return []
                }
              } catch(err) {
                console.log(err)
                return []
              }
        },
        async fetchMembersByFundId(id) {
            try {
                const _memberRes = await AdminServices.findMembersByFundId(id)
                if (!_memberRes.error) {
                  return _memberRes.data.d
                } else {
                  return []
                }
              } catch(err) {
                console.log(err)
                return []
              }
        },
        onSave() {
            if (this.havingSelectedItems) {
                this.loading = true
                const _memberIdsToAdd = this.members.filter(x => x.isSelected === true).map(y => y.id)
                AdminServices.addMembersToFund({id: this.id, members: _memberIdsToAdd})
                .then(resp => {
                    if (resp.data.ok) {
                        this.$emit('complete')
                    } else {
                        notify.error('Error', resp.data.m)
                    }
                })
                .finally(() => this.loading = false)
            }
        },
        closeModal() {
            this.$emit('close')
        }
    }
})