import AdminServices from '@/services/admin'
import treeItem from '@/components/commons/treeItem'
import selectUser from './selectUser'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'viewSharedItems',
    components: {
        treeItem,
        selectUser
    },
    props: {
        hideViewSharedItems: {
            type: Function,
            required: true
        }
    },
    emits: ['goToItemLocation'],
    data() {
        return {
            loading: false,
            loadingInitial: true,
            searchKey: '',
            users: [],
            filterTimer: null,
            // userId: null,
            rawTree: [],
            rootDataroomItem: {
                id: 0,
                name: 'Shared with me',
                type: 'Folder'
            },
            selectedFolder: null,
            selectedChildrenItems: [],
            parentPath: [],
            delay: 300,
            clicks: 0,
            timer: null,
        }
    },
    computed: {
        treeData() {
            return this.unflatten(this.rawTree)
        },
        selectedFolderId() {
            return this.selectedFolder ? this.selectedFolder.id : null
        },
        selectedParentId() {
            return this.selectedFolder ? this.selectedFolder.parent_id : null
        }
    },
    created() {
        this.firstDataLoading()
    },
    // watch: {
    //     userId: function(v) {
    //       if(!v) {
    //         this.rawTree = []
    //       } else {
    //         this.loadTree(v)
    //       }
    //     }
    // },
    methods: {
        firstDataLoading() {
            this.loadingInitial = true
            AdminServices.findUsersToShareDataroom()
                .then(resp => {
                    if (resp.data.ok) {
                        this.users = resp.data.d.map(x => {
                            return {
                                ...x,
                                value: x.id,
                                text: `${x.fname} ${x.lname}`
                            }
                        })
                    }
                }).finally(() => { this.loadingInitial = false })
        },
        loadTree(userId) {
            if (!userId) {
                this.rawTree = []
            } else {
                this.loading = true
                AdminServices.getSharedDataroomItemsBySharedUserId(userId).then(resp => {
                    if (resp.data.ok) {
                        this.rawTree = resp.data.d
                        this.selectFolder(this.rootDataroomItem)
                    }
                }).finally(() => this.loading = false)
            }
        },
        selectFolder(item) {
            this.selectedFolder = item
            this.selectedChildrenItems = this.rawTree.filter(x => x.parent_id === item.id)
            this.parentPath = this.findAncestors(this.selectedFolder, this.rawTree)
        },
        unflatten(rawArr) {
            let arr = rawArr.filter(x => x.type === 'Folder')
            let tree = [],
                mappedArr = {},
                arrElem,
                mappedElem;
        
            // First map the nodes of the array to an object -> create a hash table.
            for(let i = 0; i < arr.length; i++) {
                arrElem = arr[i]
                mappedArr[arrElem.id] = arrElem
                mappedArr[arrElem.id]['children'] = []
            }
        
            for (let id in mappedArr) {
                if (mappedArr.hasOwnProperty(id)) {
                    mappedElem = mappedArr[id]
                    // If the element is not at the root level, add it to its parent array of children.
                    if (mappedElem.parent_id) {
                        mappedArr[mappedElem['parent_id']]['children'].push(mappedElem)
                    }
                    // If the element is at the root level, add it to first level elements array.
                    else {
                        tree.push(mappedElem)
                    }
                }
            }
            return {
                ...this.rootDataroomItem,
                children: tree
            }
        },
        findAncestors(item, rawArr) {
            if (item.id === 0) {
                return [this.rootDataroomItem]
            }
            let ancestors = []
            let parentId = item.parent_id
            ancestors.unshift(item)
            while(!!parentId) {
                ancestors.unshift(rawArr.find(x => x.id === parentId))
                let _next_item = rawArr.find(x => x.id === parentId)
                parentId = _next_item ? _next_item.parent_id : undefined
            }
            ancestors.unshift(this.rootDataroomItem)
            return ancestors
        },
        oneClick(item){
            this.clicks++ 
            if (this.clicks === 1) {
              let self = this
              this.timer = setTimeout(() => {
                self.clicks = 0
              }, this.delay)
            } else {
               clearTimeout(this.timer)
               if (item.type === 'Folder') {
                this.selectFolder(item)
               }
               this.clicks = 0
            }        	
        },
        goToItemLocation(item) {
            this.$emit('goToItemLocation', item.original_parent_id ? item.original_parent_id : 0)
        }
    }
})