<template>
  <div class="robotic">
    <div class="content">
      <template v-if="!isHideLoading">
        <transition name="fade">
          <div class="animationLoadingRobot">
            <div class="loader">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line"></div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line"></div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line"></div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line"></div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line"></div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </template>
      <div class="robotic-block" v-if="dataReady">
        <div
          class="robotic-block-top"
          v-if="
            step !== 'failed' &&
            step !== 'failsubmit' &&
            step !== 'cover' &&
            step !== 'thankyou'
          "
        >
          <div class="border pointer" @click="backToPrevious">
            <div class="wrap">
              <h5>Say Back</h5>
              <p class="m-0">to back previous</p>
            </div>
          </div>
        </div>
        <div class="robotic-block-left" ref="blockstep">
          <template v-if="step === 'failed'">
            <div class="wrap failed">
              <div class="detail main">
                <h2>Failed to open</h2>
                <p class="m-0">Please try again</p>
              </div>
            </div>
          </template>
          <template v-if="step === 'failsubmit'">
            <div class="wrap failsubmit">
              <div class="detail main">
                <h2>Failed to submit</h2>
                <p class="m-0">Please try again</p>
              </div>
            </div>
          </template>
          <template v-else-if="step === 'cover' || step === 'NaN'">
            <div class="wrap cover">
              <div class="detail main" style="display: none">
                <h2>Say <span class="weight">Hello</span> to begin.</h2>
                <p class="m-0">or click Start to begin</p>
              </div>
              <div class="detail main pointer" @click="increaseStep">
                <h2 class="m-0">
                  Click <span class="weight">Start</span> to begin
                </h2>
              </div>
            </div>
          </template>
          <template v-else-if="step === 'thankyou'">
            <div class="wrap thankyou">
              <div class="detail main">
                <h2>
                  Thanks
                  <span class="weight"
                    >{{ formData[0].value }} {{ formData[1].value }}</span
                  >!
                </h2>
                <h3 class="text-center">Welcome to Fuel Venture Capital</h3>
              </div>
              <div class="text-center mt-3">
                <button
                  class="btn btn-outline-light border"
                  @click="backToFirst"
                >
                  Start again
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="wrap">
              <div class="wrapInner">
                <div
                  class="control prev"
                  :class="{ pointer: formDataAbove.length > 0 }"
                  v-if="valid(step) === 'number'"
                >
                  <div v-if="step >= 1" @click="prev">
                    <div
                      v-for="(item, index) in formDataAbove"
                      class="detail py-2"
                      :key="item"
                      :style="`${item.style}`"
                    >
                      <h2 class="m-0">
                        <span>{{ index + 1 }}.</span>
                        <span>{{ item.title }}</span>
                      </h2>
                      <p class="m-0 py-2 text-center event">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
                <template>
                  <div class="detail py-2">
                    <h2
                      class="m-0 colorOrange"
                      v-if="formData && formData[step]"
                    >
                      <span>
                        <img
                          src="../../assets/images/robotic/voice-message.svg"
                        />
                      </span>
                      <span v-if="formData && formData[step]">{{
                        formData[step].title
                      }}</span>
                    </h2>
                    <form
                      class="m-0 py-2 text-center event"
                      autocomplete="off"
                      @submit.prevent="increaseStep"
                      v-if="formData && formData[step]"
                    >
                      <input
                        type="text"
                        autocomplete="off"
                        class="inlineInput"
                        ref="formEditText"
                        name="inputValue"
                        v-model="inputValue"
                      />
                      <p class="text-center m-0" v-if="errorMsg">
                        <small class="colorRed">{{ errorMsg }}</small>
                      </p>
                    </form>
                  </div>
                </template>
                <div
                  class="control next"
                  :class="{ pointer: formDataBelow.length > 0 }"
                  v-if="valid(step) === 'number'"
                >
                  <div v-if="step <= formData.length - 1" @click="next">
                    <div
                      v-for="(item, index) in formDataBelow"
                      class="detail py-2"
                      :key="item"
                      :style="`${item.style}`"
                    >
                      <h2 class="m-0">
                        <span>{{ index + step + 2 }}.</span>
                        <span>{{ item.title }}</span>
                      </h2>
                      <p class="m-0 py-2 text-center event">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="logo">
          <LogoIcon :size-width="200" />
        </div>
        <div class="robotic-block-bottom">
          <div class="prev-btn text-left">
            <button
              class="btn btn-link ml-3 back pointer"
              v-if="
                step !== 'failed' &&
                step !== 'failsubmit' &&
                step !== 'cover' &&
                step !== 'thankyou' &&
                step !== 0
              "
              @click="decreaseStep"
            >
              Back
            </button>
            <button
              class="btn btn-link ml-3 back pointer"
              v-if="step === 'failsubmit'"
              @click="backToFirst"
            >
              Back
            </button>
          </div>

          <div class="next-btn text-right">
            <template v-if="valid(step) === 'number' && step <= 5">
              <button
                class="btn btn-outline-light me-3 pointer border"
                :class="{ 'no-drop': !formData[step].value }"
                :disabled="!inputValue && !formData[step].value"
                @click="increaseStep"
              >
                Next
              </button>
              <!-- <button class="btn btn-outline-light me-3 pointer border" :class="{'no-drop': !formData[step].value}" :disabled="!inputValue && !formData[step].value" @click="termAndConditions" v-else>Next</button> -->
            </template>
          </div>
        </div>
      </div>
      <div :class="{ hideTOS: !isShowTOS }">
        <tos
          :hideTermAndConditions="hideTermAndConditions"
          :moveSignature="moveSignature"
          :acceptTOS="acceptTOS"
        />
      </div>
      <signature
        :finishSurvey="finishSurvey"
        :hideSignature="hideSignature"
        :sourceFileData="sourceFileData"
        v-if="isShowSignature"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hideTOS {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 1px;
  height: 1px;
  top: -10px;
  left: -10px;
  z-index: 101;
}
.robotic {
  display: block;
  overflow: hidden;
  background-color: #000;
  position: relative;
  width: 100%;
  /* min-width: 1024px; */
  height: 100vh;
  background: url("../../../src/assets/images/robotic/wallpaper.png") no-repeat
    center center #000;
  background-size: 100%;
  /* padding-top: 75%; */
  h2 {
    font-size: 40px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    // max-width: calc(100% - 20px);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    clear: both;
    float: left;
    line-height: 1.2;
    img {
      height: auto;
      width: 42px;
      vertical-align: text-bottom;
    }
    span {
      vertical-align: initial;
    }
  }
  h3 {
    font-size: 32px;
    display: block;
    width: 100%;
    clear: both;
    float: left;
    line-height: 1.2;
  }
  p {
    font-size: 24px;
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    float: left;
    overflow: hidden;
    line-height: 1.2;
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 20px;
    color: white;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .robotic-block {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      .robotic-block-top {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        display: none;
        > div {
          display: block;
          overflow: hidden;
          width: 265px;
          height: 281px;
          border-radius: 50%;
          transform: translate(20px, -145px);
          &.border {
            border: 1px solid #fff;
            .wrap {
              transform: translate(60px, 160px);
              h5 {
                font-size: 26px;
              }
              p {
                font-size: 20px;
              }
            }
          }
        }
      }
      .robotic-block-left {
        flex: 1;
        position: relative;
        .wrap {
          position: absolute;
          display: block;
          // overflow: hidden;
          width: 80%;
          height: auto;
          bottom: calc(75% - 170px);
          left: 50%;
          transform: translateX(-50%);
          // transition: transform 200ms linear;
          &.cover {
            bottom: calc(75% - 23px);
          }
          &.failed,
          &.failsubmit {
            bottom: calc(75% - 48px);
          }
          &.thankyou {
            bottom: calc(75% - 83px);
            button.border {
              border-radius: 25px;
            }
          }
          .detail {
            // transition: all 200ms linear;
            display: block;
            overflow: hidden;
            clear: both;
            position: relative;
            p {
              border: 0px;
              font-size: 34px;
              outline: none !important;
              box-shadow: unset !important;
              color: #cccccc;
              text-align: center;
              border-bottom: 0px;
              small {
                font-size: 50%;
                font-weight: normal;
              }
            }
            .inlineInput {
              background-color: transparent !important;
              border: 0px;
              border-bottom: 1px solid #cccccc !important;
              font-size: 30px;
              outline: none !important;
              box-shadow: unset !important;
              color: #cccccc !important;
              text-align: center;
              width: 400px;
              user-select: text;
              -moz-user-select: text;
              -webkit-user-select: text;

              &:-internal-autofill-selected {
                background-color: transparent !important;
                color: #cccccc !important;
                background-image: none !important;
                border: 0 !important;
              }
            }
          }
          .control {
            position: relative;
            height: 114px;
            display: block;
            width: 100%;
            &.prev {
              background: none;
              > div {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
              .detail {
                transform-origin: center bottom;
              }
            }
            &.next {
              background: none;
              > div {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
              }
              .detail {
                transform-origin: center top;
              }
            }
          }
        }
      }
      .robotic-block-right {
        flex: 0 0 330px;
        position: relative;
        .thumb img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          opacity: 0;
          transition: opacity 500ms linear;
          &.active {
            opacity: 1;
          }
        }
      }
      .robotic-block-bottom {
        position: absolute;
        z-index: 2;
        bottom: calc(75% + 23px);
        left: 0;
        display: flex;
        width: 100%;
        .prev-btn {
          position: absolute;
          left: 15px;
        }
        .next-btn {
          position: absolute;
          right: 15px;
        }
        button {
          width: 156px;
          text-decoration: none;
          &.btn-link {
            color: #fff;
          }
          &.border {
            border-radius: 25px;
          }
          &.back {
            &:before {
              content: "";
              background: url("../../../src/assets/images/robotic/left-arrow.svg")
                no-repeat;
              display: inline-block;
              vertical-align: middle;
              width: 9px;
              height: 16px;
              margin-right: 20px;
            }
          }
        }
      }
      .logo {
        position: absolute;
        bottom: calc(25% - 14px);
        left: calc(50% - 142px);
      }
    }
  }
}
</style>
<script src="./index.js"></script>
