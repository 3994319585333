import conf from "../../../config";
import _ from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "recaptcha",
  props: ["value", "instance", "dataCallback"],
  data() {
    return {
      renderId: null,
      timeoutId: null,
      destroyed: false,
    };
  },
  mounted() {
    if (_.isObject(this.instance)) {
      this.instance.reset = () => this.reset();
    }
    this.render();
  },
  beforeUnmount() {
    this.destroyed = true;
    this.clearTimeout();
  },
  methods: {
    clearTimeout() {
      if (this.timeoutId != null) {
        clearTimeout(this.timeoutId);
      }
    },
    execute() {
      window.grecaptcha.execute(this.renderId);
    },
    render() {
      let that = this;
      if (
        window.grecaptcha &&
        typeof window.grecaptcha.render !== "undefined"
      ) {
        $(this.$el).empty();
        this.renderId = window.grecaptcha.render(this.$el, {
          sitekey: conf.recaptcha_key,
          size: "invisible",
          callback: (response) => {
            that.dataCallback(response);
          },
        });
      } else if (!this.destroyed) {
        $(this.$el).html("Loading...");
        this.clearTimeout();
        this.timeoutId = setTimeout(() => this.render(), 1000);
      }
    },
    reset() {
      if (this.renderId !== null) {
        this.$emit("input", "");
        window.grecaptcha.reset(this.renderId);
      }
    },
  },
});
