import authService from "@/services/auth";
import notify from "@/services/notify";
import router from "@/config/router";
import appStore, { APP_MUTATION } from "@/store/app";
import validate_email from "@/misc/checkemail";
import _ from "lodash";
import TextField from "@/components/TextField";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  components: {
    TextField,
    LogoIcon,
  },
  data() {
    return {
      email: "",
      // recaptcha: '',
      captchaInstance: {},
      loading: false,
      loginPath: router.login.path,
      homePath: router.home.path,
      success: false,
      messageError: {
        email: null,
      },
      frmInvalidate: false,
      count: 0,
    };
  },
  computed: {
    disabled() {
      return this.loading;
      // return this.loading || !this.recaptcha
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
  },
  methods: {
    // submit(token) {
    // submit() {
    //     this.loading = true
    //     // this.recaptcha = token
    //     // authService.forgotPassword(this.email, this.recaptcha).then(resp => {
    //     authService.forgotPassword(this.email).then(resp => {
    //         if (resp.error) {
    //             // notify.error('Request new password fail', resp.errorMsg)
    //             //this.resetRecaptcha()
    //             this.success = false
    //         } else {
    //             this.success = true
    //         }
    //         this.loading = false
    //     })
    // },
    validate() {
      this.resetVaildate();

      if (!validate_email.validateEmail(this.email)) {
        if (this.email.length) {
          this.messageError.email = "Your email is invalid";
        } else {
          this.messageError.email = "Email field is not empty";
        }
        this.frmInvalidate = true;
      }

      if (this.frmInvalidate) {
        return;
      }
      this.confirm();
      // this.$refs.recaptcha.execute()
    },
    confirm() {
      this.loading = true;
      // authService.forgotPassword(this.email, this.recaptcha).then(resp => {
      authService.forgotPassword(this.email).then((resp) => {
        if (resp.error) {
          //notify.error('Request new password fail', resp.errorMsg)
          // this.resetRecaptcha()
          this.success = false;
        } else {
          this.success = true;
          switch (resp.data.d) {
            case "Account is locked":
            case "Account not activated":
            case "Email not found":
              this.success = false;
              notify.error(resp.data.d);
              break;
            default:
              break;
          }
        }
        this.loading = false;
      });
    },
    // resetRecaptcha() {
    //     if (_.isFunction(this.captchaInstance.reset)) {
    //         this.captchaInstance.reset()
    //     }
    // },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        email: null,
      };
    },
  },
});
