import moment from "moment";
import axios from "axios";
import conf from "../config";
import { generic } from "../misc/generic";
import appStore, { AUTH_ACTIONS, LOCAL_STORAGES } from "../store/app";

const isExpiredTime = (timestamp) => {
  if (!timestamp) {
    return true;
  }
  return timestamp <= moment.utc().valueOf();
};

const setHeader = (config) => {
  const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
  if (token) {
    config.headers["Authorization"] = `Bearer ${token.access_token}`;
  }
};

let authTokenRequest;

const resetAuthTokenRequest = () => {
  authTokenRequest = null;
};

const getToken = (config) => {
  const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
  if (!token) {
    return Promise.reject("invalid token");
  }

  if (!authTokenRequest) {
    authTokenRequest = appStore.dispatch(AUTH_ACTIONS.login, {
      clientId: "web-client",
      grant_type: "refresh_token",
      refresh_token: token.refresh_token,
      userType: token.user_type,
    });
    authTokenRequest
      .then((resp) => {
        resetAuthTokenRequest();
        return resp;
      })
      .catch((error) => {
        resetAuthTokenRequest();
        return Promise.reject(error);
      });
  }

  return authTokenRequest;
};

const isTokenApi = (config) => config.url === `${conf.api}/auth/token`;

const resetAuthToken = (config) => {
  const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
  if (!token) {
    return Promise.reject("invalid token");
  }

  if (isExpiredTime(token.access_token_expires)) {
    //check refresh token expiredtime
    if (isExpiredTime(token.refresh_token_expires)) {
      return appStore.dispatch(AUTH_ACTIONS.not_auth);
    }
  }
  return getToken(config);
};

//interceptors handler
axios.interceptors.request.use(
  (config) => {
    const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
    if (!token) {
      if (config.requiredAuth) {
        throw new axios.Cancel("Operation canceled.");
      }
    } else {
      if (
        !isExpiredTime(token.access_token_expires) ||
        config.requiredAuth === false
      ) {
        setHeader(config);
        return config;
      }
      if (!isTokenApi(config)) {
        return resetAuthToken(config).then((resp) => {
          if (resp && !resp.error) {
            setHeader(config);
            return config;
          }
          throw new axios.Cancel("token expire.");
        });
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error.response && error.response.status === 429) {
      alert("Please try again later.");
      return new Promise(() => {});
    }
    if (error.response && error.request.status === 401) {
      return resetAuthToken(error.response.config).then((resp) => {
        if (resp) {
          setHeader(error.response.config);
          return axios(error.response.config);
        }
        return false;
      });
    }

    return Promise.reject(error);
  }
);
