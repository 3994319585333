import AdminServices from "@/services/admin";
import _ from "lodash";
import router from "@/config/router";
import moment from "moment";
import notify from "../../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["userId"],
  data() {
    return {
      loading: false,
      partnerPath: router.partner.path,
      partnerRouteName: router.partner.name,
      charts: [],
      isFounder: false,
    };
  },
  mounted() {
    if (!this.isFounder && !this.userId) {
      this.$router.push(this.partnerPath);
    }
  },
  created() {
    this.isFounder = this.$route.meta.userTypes.includes("Founder");
    this.getPartnerDataView();
  },
  methods: {
    getPartnerDataView: function () {
      this.loading = true;
      let getDataPromise = this.isFounder
        ? AdminServices.getKpiView()
        : AdminServices.getPartnerDataView(this.userId);
      getDataPromise.then((res) => {
        if (!res.data.ok) {
          this.$router.push(this.partnerPath);
        } else {
          this.charts = res.data.d.charts;
        }
        this.loading = false;
      });
    },
    saveDataView: function () {
      let chartIds = this.charts
        .filter((x) => {
          return x.selected;
        })
        .map((x) => {
          return x.id;
        });
      let saveDataPromise = this.isFounder
        ? AdminServices.saveKpiView(chartIds)
        : AdminServices.savePartnerDataView(this.userId, chartIds);
      saveDataPromise.then((resp) => {
        if (resp.data.ok) {
          notify.success("Update succesfully.");
        } else {
          notify.error("Update error.");
        }
      });
    },
  },
});
