<template>
  <div class="row pl-2 pt-2" v-loading="loading">
    <DropZone accept="image/*" @change="fileChange" v-if="form && form.status !== 'CANCELLED'" />
    <template v-if="form && (items.length == 0 && form.status == 'CANCELLED')">
      <div class="col d-flex align-items-center justify-content-center noData mt-3">
        No Data
      </div>
    </template>

    <CardItem v-for="(item, index) in items" :item="item" :url="item.url" :editHandler="editHandler"
      :status="form && form.status" :deleteHandler="deleteHandler" :key="`car-${index}`" />
    <div class="col-12 px-2">
      <pager :loadContentAtPage="loadPage" :page="paging.page" :size="paging.size" :total="paging.total"
        :pagerFull="false" v-if="paging.total > paging.size" />
    </div>

    <ModalCreateFile ref="modalCreateFile" :key="modalCreateFileKey" @refresh="getAll" />
    <ModalUpdateFile @refresh="getAll" ref="modalUpdateFile" :key="modalUpdateFileKey" />

    <Dialog ref="dialog" />
  </div>
</template>
  
<script src="./index.js"></script>
  