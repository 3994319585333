<template>
    <div>
        <VueRecaptcha 
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            :sitekey="siteKey"
            :loadRecaptchaScript="true"
        ></VueRecaptcha>
        <div v-if="errorMsg" class="md-error">{{_errorMsg}}</div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.md-error {
    color: #f52727;
    font-size: 0.8em;
}
</style>