<template>
  <div class="adminContent p-3" v-loading="loading">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
    >
      <template #head_email>
        <TextField
          v-model:value="filteredEmail"
          placeholder="Type to search .."
          hideError
        />
      </template>
      <template #head_platform>
        <SelectField
          v-model:value="filteredPlatform"
          :options="platformList"
          hideError
        />
      </template>
      <template #row_email="{ props }">
        <div>{{ props.email }}</div>
      </template>
      <template #row_platform="{ props }">
        <div>{{ convertPlatform(props.platform) }}</div>
      </template>
      <template #row_userAgents="{ props }">
        <div>{{ props.user_agents }}</div>
      </template>
      <template #row_ipAddress="{ props }">
        <div>{{ props.ip_address }}</div>
      </template>
      <template #row_country="{ props }">
        <div>{{ convertContry(props.country) }}</div>
      </template>
      <template #row_status="{ props }">
        <div>{{ props.status }}</div>
        <div>{{ $filters.parseEstTime(props.created_at) }}</div>
      </template>
    </Table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import adminServices from "@/services/admin";
import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import _ from "lodash";

export default defineComponent({
  components: {
    Table,
    TextField,
    SelectField,
    DropdownItem,
    Dropdown,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredEmail: "",
      filteredPlatform: "",
      platformList: [
        {
          name: "All",
          code: "",
        },
        {
          name: "Website",
          code: "web",
        },
        {
          name: "Android",
          code: "android",
        },
        {
          name: "IOS",
          code: "ios",
        },
      ],
    };
  },
  watch: {
   
    paging: {
      deep: true,
      handler(v) {
        debounce(this.getAll);
      },
    },
    filteredEmail() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredPlatform() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  computed: {
    columns() {
      return [
        {
          id: "email",
          name: "Email",
        },
        {
          id: "platform",
          name: "Platform",
        },
        {
          id: "userAgents",
          name: "User Agents",
        },
        {
          id: "ipAddress",
          name: "Ip Address",
        },
        {
          id: "country",
          name: "Country",
        },
        {
          id: "status",
          name: "Status",
          columnClass: "multiple-line",
        },
      ];
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getUserLoginHistories({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredEmail: this.filteredEmail,
        filteredPlatform: this.filteredPlatform,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    convertPlatform(platform) {
      if (platform == null) return;
      if (platform == "android") return "Android";
      else if (platform == "ios") return "IOS";
      else return "Website";
    },
    convertContry(country) {
      if (country == null) return;
      if (country != "{}") return JSON.parse(country).Name;
      else return "-";
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
</script>
<style lang="scss" scoped></style>
