import { defineComponent } from "vue";
import notify from "@/services/notify";
import Table from "@/components/Table";
import AdminServices from "@/services/admin";
import NotificationModal from "./NotificationModal.vue";
import Dialog from "@/components/Dialog";

export default defineComponent({
  name: "notifications",
  components: {
    Table,
    NotificationModal,
    Dialog,
  },
  data() {
    return {
      loading: false,
      notificationModalKey: 0,
      paging: {
        page: 1,
        size: 10,
      },
      rows: [],
      totalItems: 0,
    };
  },
  watch: {
    "paging.page": {
      deep: true,
      handler() {
        this.getAll();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAll();
    });
  },
  computed: {
    columns() {
      return [
        {
          id: "title",
          name: "Title",
        },
        {
          id: "target_users",
          name: "Target users",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-end",
          columnClass: "text-end action-icon-group",
        },
      ];
    },
  },
  methods: {
    async getAll() {
      this.loading = true;
      const res = await AdminServices.getNotification({
        SortField: "",
        SortDirection: "",
        PageNumber: this.paging.page,
        PageSize: this.paging.size,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async viewNotification(props) {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.show(props);
      });
    },
    async createNotification() {
      this.notificationModalKey++;
      this.$nextTick(() => {
        this.$refs.notificationModal.create();
      });
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },

    deleteNotification(param) {
      let message = {
        title: "Delete Notification",
        body: "Are you sure to delete the notification? If you delete, the notification cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await AdminServices.deleteNotification(
            param.notification_id
          );
          if (res && res.data && res.data.ok) {
            notify.success("Delete successfully");
            this.getAll();
          } else {
            notify.error(res.data.errorMsg);
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(function () {});
    },
  },
});
