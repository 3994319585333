import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: { type: Boolean, default: false },
    spinnerCenter: { type: Boolean, default: true },
    theStyle: { type: Boolean, default: true },
    color: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
  },
  emits: ["click"],
  methods: {
    click() {
      if (this.disabled) return;
      if (!this.loading) {
        this.$emit("click");
      }
    },
  },
});
