import router from "@/config/router";
import notify from "../../../../services/notify";
import config from "../../../../config";
import { copyToClipboard } from "../../../../misc/commons";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'getLink',
  props: ["item", "hideGetLink"],
  data() {
    return {
      loading: false,
      unauthDocViewerPath: router.unauthDocViewer.path,
      docViewerPath: router.docViewer.path,
    };
  },
  computed: {
    docViewerLink() {
      if (this.item) {
        if (this.item.is_unauthenticated) {
          return `${config.app_url}${this.unauthDocViewerPath}?unauthenid=${this.item.id}`;
        } else {
          return `${config.app_url}${this.docViewerPath}?itemid=${this.item.id}`;
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    copyLink() {
      let copied = copyToClipboard(this.$refs.docViewerLink);
      if (copied) {
        notify.success("Copied");
      } else {
        notify.error("Oops, unable to copy");
      }
    },
  },
});
