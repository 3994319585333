import { mapState } from "vuex";
import appStore, { APP_ACTIONS } from "@/store/app";
import pdf from "@/components/commons/pdf-viewer";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      fileUrl: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({ perspectivesList: (state) => state.perspectivesList }),
    scale() {
      return appStore.state.mobile.isMobile ? "page-width" : "page-width";
    },
  },
  async created() {
    if (this.perspectivesList.length === 0) {
      this.loading = true;
      await appStore.dispatch(APP_ACTIONS.fetch_list_perspectives, null);
      this.loading = false;
    }
    this.renderPdf();
  },
  methods: {
    renderPdf() {
      const author = this.$route.params.id;
      const pdfFileName = this.$route.params.pdf;
      const findAuthor = this.perspectivesList.find((x) => x.path === author);
      if (!findAuthor)
        return this.$router.push(
          `/fuel-the-mind/perspectives/${this.$route.id}`
        );

      const findPdf = findAuthor.extra_info.find(
        (x) => x.pdfFileName === pdfFileName
      );

      if (!findPdf)
        return this.$router.push(
          `/fuel-the-mind/perspectives/${this.$route.id}`
        );

      this.fileUrl = findPdf.pdf_file;
    },
    hideFunc() {
      const history = window.history.length;
      if (history > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push(`/fuel-the-mind/portfolio-news`);
      }
    },
  },
};
