<template>
  <div class="portfolio-page">
    <Section1 />
    <Section2 />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Section1 from "./comps/Section1.vue";
import Section2 from "./comps/Section2.vue";

export default defineComponent({
  components: {
    Section1,
    Section2,
  },
});
</script>

<style></style>
