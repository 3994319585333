import listSurvey from "./listSurvey";
import appStore from "../../../store/app";
import router from "@/config/router";
import AdminServices from "../../../services/admin";
import moment from "moment";
import { defineComponent } from "vue";
import slider from "vue-3-slider-component";
import Datepicker from "@/components/DatePicker";
import TextField from "@/components/TextField";

const confPrice = {
  min: 0,
  max: 10000000,
};

export default defineComponent({
  name: "reviewSurvey",
  data() {
    return {
      dashboardName: router.dashboard.name,
      dashboardPath: router.dashboard.path,
      range: null,
      value: 30,
      minValue: 30,
      textPriceRange: "Price Range",
      rangevalue: [0, 100],
      minPrice: 0,
      maxPrice: 0,
      isShowRange: false,
      isShowTechnology: false,
      isShowIndustry: false,
      isShowDevelopment: false,
      isShowFinancing: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      dataSearch: {
        surveyStatus: "COMPLETED",
        founderName: "",
        company: "",
        email: "",
        technology: [],
        industry: [],
        dateTime: [null, null],
        price: [],
        development: [],
        financing: [],
      },
      dataTechnology: [
        "Artificial Intelligence",
        "Big Data",
        "Blockchain",
        "Internet of Things",
        "Biotechnology & Bioinformatics",
        "Nanomaterials & Nanotechnology",
        "Networks & Sensors",
        "Digital Manufacturing",
        "Medicine",
        "Computational Systems",
        "Robotics",
        "Virtual and Augmented Reality",
      ],
      dataIndustry: [
        "Fintech/ Insurtech",
        "Marketing/ Advertisement",
        "Real Estate Tech",
        "Services",
      ],
      dataDevelopment: [
        "Idea",
        "Product Development",
        "Validation",
        "Revenue Generating",
        "Scaling",
      ],
      dataFinancing: ["Pre-Seed", "Seed", "Series A", "Series B", "Series C+"],
      listData: null,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  components: {
    listSurvey,
    Datepicker,
    "vue3-slider": slider,
    TextField,
  },
  mounted() {
    this.calculatePrice(this.rangevalue);
    if ($("body")) {
      $("body").bind({ click: this.bodyEvent });
    }
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
  },
  methods: {
    ShowHideFinancing: function () {
      this.isShowFinancing = !this.isShowFinancing;
    },
    ShowHideDevelopment: function () {
      this.isShowDevelopment = !this.isShowDevelopment;
    },
    ShowHideIndustry: function () {
      this.isShowIndustry = !this.isShowIndustry;
    },
    ShowHideisShowTechnology: function () {
      this.isShowTechnology = !this.isShowTechnology;
    },
    ShowHideRange: function () {
      this.isShowRange = !this.isShowRange;
      this.textPriceRange = null;
      if (this.rangevalue.length === 0) {
        this.rangevalue = [10, 20];
      }
    },
    calculatePrice(value) {
      this.minPrice = (confPrice.max / 100) * value[0];
      this.maxPrice = (confPrice.max / 100) * value[1];
      this.dataSearch.price = [];
      this.dataSearch.price[0] = this.minPrice;
      this.dataSearch.price[1] = this.maxPrice;
    },
    bodyEvent(e) {
      if (!$(e.target).hasClass("priceRange")) {
        if ($(e.target).closest(".blockrangewrap").length === 0) {
          this.isShowRange = false;
        }
      }
      if (!$(e.target).hasClass("technology")) {
        if ($(e.target).closest(".blockrangewrap").length === 0) {
          this.isShowTechnology = false;
        }
      }
      if (!$(e.target).hasClass("industry")) {
        if ($(e.target).closest(".blockrangewrap").length === 0) {
          this.isShowIndustry = false;
        }
      }
      if (!$(e.target).hasClass("development")) {
        if ($(e.target).closest(".blockrangewrap").length === 0) {
          this.isShowDevelopment = false;
        }
      }
      if (!$(e.target).hasClass("financing")) {
        if ($(e.target).closest(".blockrangewrap").length === 0) {
          this.isShowFinancing = false;
        }
      }
    },
    selectAll(e, str) {
      let parent = this.$refs[str];
      $(parent)
        .find('input[type="checkbox"]')
        .prop("checked", $(e.target).prop("checked"));
      if ($(e.target).prop("checked")) {
        this.dataSearch.technology = this.dataTechnology;
      } else {
        this.dataSearch.technology = [];
      }
    },
    searchSurvey() {
      let data = {
        status: this.dataSearch.surveyStatus,
        founderName: this.dataSearch.founderName.trim()
          ? this.dataSearch.founderName.trim()
          : "",
        company: this.dataSearch.company.trim()
          ? this.dataSearch.company.trim()
          : "",
        email: this.dataSearch.email.trim() ? this.dataSearch.email.trim() : "",
        submittedFrom: this.dataSearch.dateTime[0]
          ? moment(
              `${this.dataSearch.dateTime[0].years}-${this.dataSearch.dateTime[0].months}-${this.dataSearch.dateTime[0].days}`
            ).format("MM-DD-YYYY")
          : "",
        submittedTo: this.dataSearch.dateTime[1]
          ? moment(
              `${this.dataSearch.dateTime[1].years}-${this.dataSearch.dateTime[1].months}-${this.dataSearch.dateTime[1].days}`
            ).format("MM-DD-YYYY")
          : "",
        companyLeverage:
          this.dataSearch.technology.length > 0
            ? this.dataSearch.technology.toString()
            : "",
        industryVerticals:
          this.dataSearch.industry.length > 0
            ? this.dataSearch.industry.toString()
            : "",
        stageOfDevelopment:
          this.dataSearch.development.length > 0
            ? this.dataSearch.development.toString()
            : "",
        stageOfFinancing:
          this.dataSearch.financing.length > 0
            ? this.dataSearch.financing.toString()
            : "",
        preMoneyValuationFrom: this.minPrice,
        preMoneyValuationTo: this.maxPrice,
        page: this.paging.page,
        size: this.paging.size,
      };
      AdminServices.searchSurvey(data).then((resp) => {
        if (resp.data.ok) {
          this.listData = resp.data.d.c;
          this.paging.page = resp.data.d.p;
          this.paging.total = resp.data.d.t;
          this.paging.size = resp.data.d.s;
        }
      });
    },
    loadPage(value) {
      this.paging.page = value;
      this.searchSurvey();
    },
  },
  watch: {
    rangevalue: function (v) {
      this.calculatePrice(v);
    },
  },
  beforeUnmount() {
    $("body").unbind({ click: this.bodyEvent });
  },
});
