import { defineComponent } from "vue";
import NewButton from "@/components/NewButton";

export default defineComponent({
  name: "Modal",
  emits: ["update:value", "value"],
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    size: { type: String, default: "md" },
    direction: { type: String, default: "" },
    closeBtnText: { type: String, default: "Cancel" },
    showCloseBtn: { type: Boolean, default: true },
    hasFloatingCloseButton: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    isVisible: { type: Boolean, default: false },
    alignHeader: { type: String, default: "left" },
    classWrapper: { type: String, default: "" },
  },
  components: {
    NewButton,
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("value", val);
      },
    },
  },
  methods: {
    close() {
      this.$emit("update:value", false);
    },
  },
});
