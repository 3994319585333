import authService from "../../../services/auth";
import notify from "../../../services/notify";
import router from "@/config/router";
import appStore, { APP_MUTATION } from "../../../store/app";
import _ from "lodash";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";
import TextField from "@/components/TextField";

export default defineComponent({
  components: {
    LogoIcon,
    TextField,
  },
  data() {
    return {
      email: "",
      //recaptcha: '',
      password: "",
      confirmPassword: "",
      captchaInstance: {},
      loading: false,
      loginPath: router.login.path,
      homePath: router.home.path,
      success: false,
      code: "",
      frmInvalidate: false,
      messageError: {
        password: null,
      },
    };
  },
  computed: {
    disabled() {
      return (
        this.loading ||
        !this.email ||
        //!this.recaptcha ||
        !this.password
      );
      //||!(this.password === this.confirmPassword)
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
    this.email = this.$route.query.e;
    this.code = this.$route.query.c;
  },
  methods: {
    submit() {
      this.resetVaildate();
      this.loading = true;
      //this.recaptcha = token
      authService
        .resetPassword(
          this.email,
          this.code,
          this.password,
          this.confirmPassword
        )
        .then((resp) => {
          if (resp.error) {
            let that = this;
            const list = resp.data.d;
            if (list.length > 0) {
              list.forEach(function (element) {
                notify.error(element);
              });
            }
            // note lai element === 'The VerifyCode field is required.'
            // notify.error('Reset password fail', resp.errorMsg)
            //this.resetRecaptcha()
            this.success = false;
          } else {
            notify.success("Reset password success", "Please login and enjoy");
            this.$router.push({
              path: this.loginPath,
            });
            this.success = true;
          }
        });
    },
    validate() {
      this.resetVaildate();

      if (this.password !== this.confirmPassword) {
        this.messageError.password = "Your passwords does not match.";
        this.frmInvalidate = true;
      }

      if (this.password.length) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }

      if (this.frmInvalidate) {
        return;
      }
      //this.$refs.recaptcha?.execute()
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        password: null,
      };
    },
    confirm() {
      this.resetVaildate();

      if (this.password.length > 0) {
        if (this.password !== this.confirmPassword) {
          this.messageError.password = "Your passwords does not match";
          this.frmInvalidate = true;
        }
      } else {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }

      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      authService
        .resetPassword(
          this.email,
          this.code,
          this.password,
          this.confirmPassword
        )
        .then((resp) => {
          if (resp.error) {
            let that = this;
            const list = resp.data.d;
            if (list.length > 0) {
              list.forEach(function (element) {
                notify.error(element.err, element.err);
              });
            }
            // note lai element === 'The VerifyCode field is required.'
            // notify.error('Reset password fail', resp.errorMsg)
            //this.resetRecaptcha()
            this.success = false;
          } else {
            notify.success(
              "Your password has been reset successfully",
              "Please login and enjoy"
            );
            this.$router.push({
              path: this.loginPath,
            });
            this.success = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // resetRecaptcha() {
    //     if (_.isFunction(this.captchaInstance.reset)) {
    //         this.captchaInstance.reset()
    //     }
    // },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
  },
});
