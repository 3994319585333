const NOT_FOUND_KEY = "not-found-directive";
const notFoundTemplate = `<div data-key="${NOT_FOUND_KEY}" class="flex-content-container">
<div class="text-center push-50 push-50-t">
    <h1 class="font-s64 font-w300 text-city animated flipInX">404</h1>
    <h2 class="h4 font-w300 animated fadeInUp">No data.</h2>
</div>
</div>`;

const processNotFound = (el, isNotFound) => {
  const $el = window.jQuery(el);
  el.$notFound = $el.next();
  if (el.$notFound.attr("data-key") !== NOT_FOUND_KEY) {
    el.$notFound = window.jQuery(notFoundTemplate);
    el.$notFound.insertAfter($el);
  }
  if (isNotFound) {
    el.$notFound.removeClass("hidden");
    $el.addClass("hidden");
  } else {
    el.$notFound.addClass("hidden");
    $el.removeClass("hidden");
  }
};

// Vue.directive('not-found', {
//     bind(el, binding) {
//         processNotFound(el, binding.value)
//     },
//     update(el, binding) {
//         processNotFound(el, binding.value)
//     },
//     unbind(el, binding) {
//         el.$notFound.remove()
//     }
// })

const NOTFOUND = {
  // called before bound element's attributes
  // or event listeners are applied
  created(el, binding, vnode, prevVnode) {
    processNotFound(el, binding.value);
  },
  // called right before the element is inserted into the DOM.
  beforeMount() {},
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted() {},
  // called before the parent component is updated
  beforeUpdate() {},
  // called after the parent component and
  // all of its children have updated
  updated() {
    processNotFound(el, binding.value);
  },
  // called before the parent component is unmounted
  beforeUnmount() {},
  // called when the parent component is unmounted
  unmounted() {},
};

export default NOTFOUND;
