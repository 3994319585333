import { defineComponent } from "vue";

export default defineComponent({
  name: "contextMenu",
  emits: ['option-clicked'],
  props: {
    elementId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    lightTheme: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      item: null,
      menuWidth: null,
      menuHeight: null,
    };
  },
  methods: {
    showMenu(event, item) {
      this.item = item;
      let menu = document.getElementById(this.elementId);
      if (!menu) {
        return;
      }
      if (!this.menuWidth || !this.menuHeight) {
        menu.style.visibility = "hidden";
        menu.style.display = "block";
        this.menuWidth = menu.offsetWidth;
        this.menuHeight = menu.offsetHeight;
        menu.removeAttribute("style");
      }
      if (this.menuWidth + event.pageX >= window.innerWidth) {
        menu.style.left = event.pageX - this.menuWidth + 2 + "px";
      } else {
        menu.style.left = event.pageX - 2 + "px";
      }
      if (this.menuHeight + event.pageY >= window.innerHeight) {
        menu.style.top = event.pageY - this.menuHeight + 2 + "px";
      } else {
        menu.style.top = event.pageY - 2 + "px";
      }
      menu.classList.add("rk-vue-simple-context-menu--active");
    },
    hideContextMenu() {
      let element = document.getElementById(this.elementId);
      if (element) {
        element.classList.remove("rk-vue-simple-context-menu--active");
      }
    },
    onClickOutside() {
      this.hideContextMenu();
    },
    optionClicked(option) {
      this.hideContextMenu();
      this.$emit("option-clicked", {
        item: this.item,
        option: option,
      });
    },
    onEscKeyRelease(event) {
      if (event.keyCode === 27) {
        this.hideContextMenu();
      }
    },
  },
  mounted() {
    document.body.addEventListener("keyup", this.onEscKeyRelease);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.onEscKeyRelease);
  },
});
