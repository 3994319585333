import authHttp, { API_ROOT } from '../auth-http'

export const SocialService = {
    getAll(data) {
        let param = ''
        if (data.size !== undefined) {
            if (param === '') {
                param = param + '?size=' + data.size
            } else {
                param = param + '&size=' + data.size
            }
        }
        if (data.page !== undefined) {
            if (param === '') {
                param = param + '?page=' + data.page
            } else {
                param = param + '&page=' + data.page
            }
        }
        return authHttp.get(`${API_ROOT}/public/social/${param}`)
    },
    getIgAT() {
        return authHttp.get(`${API_ROOT}/public/social/ig-access-token`)
    }
}

export default SocialService