<template>
  <div class="section-1">
    <div class="content">
      <div class="heading">
        <div class="text-a-wrapper s-text-1">
          <h2 class="text-animation">Fueling the future</h2>
        </div>

        <div class="line-s"></div>
      </div>

      <div class="desc s-text s-text-2">
        We believe that relationships are a line not a dot
      </div>
      <div class="desc s-text s-text-3">
        - it&#39;s never too early to speak with us.
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import ScrollOut from "scroll-out";

export default {
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [],
      haveIt3: [],
      translateY: 150,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.translateY = 60;
    }
    if (window.innerWidth < 1024) {
      this.translateY = 120;
    }
    this.handleScrollTextReveal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();

      ScrollOut({
        targets: ".s-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".s-text-2",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText2();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".s-text-3",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText3();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });
    },

    handleSliceText() {
      // Wrap every letter in a span
      var textWrapper1 = document.querySelector(".s-text-1 .text-animation");
      textWrapper1.innerHTML = textWrapper1.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );
      // Wrap every letter in a span
      var textWrapper2 = [...document.querySelectorAll(".s-text")];

      textWrapper2.map((el) => {
        if (el.querySelector(".t-words")) return;
        el.innerHTML = el.innerHTML.replace(
          /(^|<\/?[^>]+>|\s+)([^\s<]+)/g,
          function (match, p1, p2) {
            return `${p1}<div class="t-words"><p class="letter">${p2}</p></div>`;
          }
        );
      });
    },

    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".s-text-1 .text-animation");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".s-text-1 .letter",
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });

      anime({
        targets: ".line-s",
        opacity: [0, 1],
        delay: 700,
        easing: this.easing,
        translateY: [10, 0],
      });
    },

    animationText2() {
      anime({
        targets: ".s-text-2 .letter",
        opacity: [0, 1],
        easing: this.easing,
        delay: 600,
        translateY: [24, 0],
      });
    },

    animationText3() {
      anime({
        targets: ".s-text-3 .letter",
        opacity: [0, 1],
        easing: this.easing,
        delay: 600,
        translateY: [24, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-1 {
  height: 70vh;
  color: #000;
  background: #fff;

  display: flex;
  justify-content: center;

  @media (min-width: 992px) {
    height: 100vh;
  }

  .content {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .heading {
      padding-bottom: 20px;
      .line-s {
        width: 100%;
        height: 1px;
        background: #000;
      }
    }

    .text-animation {
      font-size: 40px;
      font-weight: 700;
      line-height: 100%;

      @media (min-width: 576px) {
        font-size: 60px;
      }

      @media (min-width: 768px) {
        font-size: 90px;
      }

      @media (min-width: 1024px) {
        font-size: 125px;
        line-height: 150px;
      }

      @media (min-width: 1400px) {
        font-size: 140px;
        line-height: 175px;
      }
    }

    .desc {
      font-family: "SF Pro Text";
      font-size: 14px;
      font-weight: 400;
      line-height: 110%;

      margin-top: auto;

      @media (min-width: 576px) {
        font-size: 16px;
      }

      @media (min-width: 768px) {
        font-size: 18px;
      }

      @media (min-width: 1024px) {
        font-size: 24px;
        font-weight: 400;
        line-height: 150%;
        position: relative;
        text-align: right;
      }
    }
  }
}
</style>
