import pdf from "@/components/commons/pdf-viewer";
import AdminServices from "@/services/admin";
import { Carousel3d, Slide } from "vue3-carousel-3d";
import "vue3-carousel-3d/dist/index.css";
// import conf from '@config'
import filePreview, { FileTypes } from "@/components/commons/filePreview";
import { LP_MEDIA_TYPES } from "@/misc/constants";
import { defineComponent } from "vue";
import appStore from "@/store/app";

export default defineComponent({
  data() {
    return {
      loadingFiles: true,
      loading: false,
      items: [],
      carouselItems: [],
      itemWidth: 713,
      itemHeight: 468,
      itemImgHeight: 400,
      FileTypes: FileTypes,
      preview: {
        isShowPreview: false,
        fileUrl: null,
        fileType: null,
      },
      currentSlide: 0,
    };
  },
  components: {
    pdf,
    Carousel3d,
    Slide,
    filePreview,
  },
  computed: {
    investorInfo() {
      return appStore.state.investorInfo;
    },
  },
  watch: {
    investorInfo: {
      deep: true,
      handler(v) {
        this.getItems();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getItems();
      window.addEventListener("resize", this.onResize);
      this.onResize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getItems() {
      this.loadingFiles = true;
      this.carouselItems = [];
      const lpFundId = this.investorInfo
        ? this.investorInfo.selected_fund.lp_fund_id
        : null;
      AdminServices.getLPMediaItemsByTypeAndLpFundId(
        LP_MEDIA_TYPES.INVESTOR_CALL_REPLAY,
        lpFundId
      )
        .then((resp) => {
          if (resp.data.ok) {
            this.items = resp.data.d;
            this.items.forEach((x) => {
              this.carouselItems.push({
                name: x.name,
                thumbnail: x.photoUrl,
                video: x.data.VideoId,
                pdf: x.data.PdfId,
                docTitle: x.data.DocTitle,
              });
            });
          }
        })
        .finally(() => {
          this.loadingFiles = false;
        });
    },
    showPreview(itemId, fileType, idx, disabled) {
      if (idx === this.currentSlide && !disabled) {
        this.loading = true;
        AdminServices.requestDownloadCIOAndCountView(itemId, "View")
          .then((resp) => {
            if (resp.data.ok) {
              this.fixedBody();
              this.preview.fileUrl = resp.data.d;
              this.preview.fileType = fileType;
              this.$refs.filePreview.showModal();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    hidePreview() {
      this.staticBody();
      this.preview.isShowPreview = false;
      this.preview.fileUrl = null;
      this.preview.fileType = null;
    },
    onResize() {
      this.itemWidth = parseInt(window.innerWidth / 2);
      this.itemImgHeight = parseInt((this.itemWidth * 9) / 16);
      this.itemHeight = this.itemImgHeight + 68;
    },
    onAfterSlideChange(index) {
      this.currentSlide = index;
    },
  },
});
