<template>
  <div
    class="modal fade gg-auth-wrap"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="handleHiddenBox">
      <div class="modal-dialog simple gg-auth">
        <!-- <router-link :to="homePath" class="logoLogin">Win fun</router-link>
                <button type="button" aria-label="Close" data-dismiss="modal" @click="hide" class="close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#ffffff" stroke="#ffffff" class="nc-icon-wrapper"><g class="nc-interact_menu-close-2-o-32"><path fill="none" stroke="#ffffff" stroke-miterlimit="10" d="M2 6h28" transform="translate(0 10.00) rotate(45.00 16 6)"></path> <path data-color="color-2" fill="none" stroke-miterlimit="10" d="M2 16h28" opacity="0"></path> <path fill="none" stroke="#ffffff" stroke-miterlimit="10" d="M2 26h28" transform="translate(0 -10) rotate(-45 16 26)"></path></g></g></svg></button>
                <form id="loginForm" @submit.prevent="submit()">
                    <div class="modal-content" v-loading="loading">
                        <div class="p-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="text-center">
                                        Google authentication
                                    </h2>
                                    <div class="code-field">
                                         <md-field class="numberVerify">
                                          <label>Code</label>
                                          <md-input name="number" v-model="code"></md-input>
                                        </md-field>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button :disabled="!code" type="submit" class="btnReg btn button btn-active btn-primary w-100">Submit</button>
                        </div>
                    </div>
                </form> -->
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.white-theme {
  .close {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
  .modal .modal-dialog.gg-auth .code-field input {
    color: #262626;
  }
  .link {
    text-decoration: underline !important;
  }
  .w-45 {
    width: 473px !important;
  }
  .opa-5 {
    opacity: 1;
  }
  a {
    &.colorWhite {
      text-decoration: none;
      &:hover {
        color: #2177ff;
      }
    }
  }
  .button.btn-gray.btn-border {
    border: 1px solid #fff;
    &:hover {
      background-color: #fff;
    }
  }
  .md-field {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    padding: 15px 5px;
    border: 1px solid lightgrey;
  }
  .md-field label {
    color: #000;
    position: static;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    width: 100%;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    border: 1px solid #868f93;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    color: #000;
  }
  .md-field label {
    position: static;
    padding: 0;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    height: 40px;
    line-height: 40px;
  }
  .logoLogin {
    display: block;
    height: 40px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  #loginForm {
    h2 {
      margin: 0 !important;
    }
    background-color: #fff;
    color: #262626;
    padding: 2rem;
    border-radius: 5px;
    a {
      &.colorWhite {
        font-style: italic;
        color: #000;
        margin-top: 0.25rem;
        &:hover {
          background-color: #fff;
          color: #2177ff;
        }
      }
    }
  }
  .button.btn-primary {
    background: #2177ff;
  }
  .button.btn-primary.btn-bounce:hover {
    -webkit-box-shadow: 0px 0px 19px 1px #2177ff;
    -moz-box-shadow: 0px 0px 19px 1px #2177ff;
    box-shadow: 0px 0px 19px 1px #2177ff;
  }
  .button.btn-primary.btn-active {
    background: #2177ff;
  }
  .colorGray {
    color: #fff;
  }
}
.modal .modal-dialog {
  margin: 0 auto;
}
.handleHiddenBox {
  height: 100%;
  width: 100%;
}
.numberVerify {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 1024px) {
  .white-theme {
    h2 {
      font-size: 20px !important;
    }
    .modal-dialog.gg-auth {
      #loginForm {
        background: #fff;
        height: auto;
        padding: 0;
      }
    }
  }
}
</style>
