import { defineComponent } from "vue";
import uploadicon from "@/assets/images/upload.png";
import ModalCreateFile from "../gallery-components/ModalCreateFile.vue";
import ModalUpdateFile from "../gallery-components/ModalUpdateFile.vue";
import CardItem from "../gallery-components/CardItem.vue";
import DropZone from "../gallery-components/DropZone.vue";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Dialog from "@/components/Dialog";

export default defineComponent({
  name: "GalleryTab",
  inject: ["eventInfoData"],
  components: {
    ModalCreateFile,
    CardItem,
    ModalUpdateFile,
    DropZone,
    Dialog,
  },
  data() {
    return {
      loading: false,
      uploadicon,
      modalUpdateFileKey: 0,
      modalCreateFileKey: 0,
      editFileModalKey: 0,
      files: "",
      items: [],
      totalItems: 0,
      paging: {
        page: 1,
        size: 11,
        total: 0,
      },
      confirmText: {
        header: "",
        body: "",
      },
      form: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.eventInfoData) {
        this.form = this.eventInfoData;
      }
      this.getAll();
    });
  },
  methods: {
    fileChange(files) {
      this.modalCreateFileKey++;
      this.$nextTick(() => {
        this.$refs.modalCreateFile.show(files, this.form.eventId);
      });
    },
    async getAll(callback = undefined) {
      if (!this.form?.eventId) return;
      this.loading = true;
      const res = await adminServices.getGaleriesOfEvent({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        eventId: this.form.eventId,
      });
      if (res.data.ok) {
        this.items = res.data.d.c;
        this.totalItems = res.data.d.t;
        this.paging.total = res.data.d.t;
      }
      this.loading = false;
      if (callback) {
        callback();
      }
    },
    editHandler(item) {
      this.modalUpdateFileKey++;
      this.$nextTick(() => {
        this.$refs.modalUpdateFile.show(item, this.form.eventId);
      });
    },
    async deleteHandler(item) {
      let message = {
        title: "Delete item",
        body: "Are you sure to delete this item? If you delete it, this item cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteGallery(
            item.eventId,
            item.galleryId
          );
          if (res.data.d.succeeded) {
            notify.success("Delete successfully");
            this.getAll();
          } else {
            notify.error(res.data.d.errorMessage);
          }
          this.loading = false;
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    async confirmFunc(param, action) {
      this.loading = true;
      if (param) {
        if (action == "delete") {
          const res = await adminServices.deleteGallery(
            param.eventId,
            param.galleryId
          );
          if (res.data.d.succeeded) {
            notify.success("Delete successfully");
            this.getAll();
          } else {
            notify.error(res.data.d.errorMessage);
          }
        }
      }
      this.loading = false;
    },
    loadPage(page) {
      this.paging.page = page;
      this.getAll();
    },
  },
});
