<template>
    <div class="custom-modal" ref="modalContent" v-if="isShow">
        <div class="content">
            <button class="close" @click="close">
                <span class="pe pe-7s-close"></span>
            </button>
            <div class="content-wrapper">
                <img :src="img" alt="">
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.custom-modal{
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    transition: all 500ms ease;
    opacity: 0;
    &.visible {
        opacity: 1;
    }

    .content {
        max-width: 800px;
        height: 70vh;
        margin: auto;
        display: flex;
        position: relative;
        .close {
            position: absolute;
            right: -12px;
            top: -12px;
            background: black;
            border: 1px solid white;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 896px) {
                right: 5px;
                top: 5px;
            }
        }
        .content-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            overflow: hidden;
        }
    }
}
</style>