<template>
  <header id="mainHeader" v-if="$store.mobile?.isMobile">
    <Slide
      left
      width="294"
      @openMenu="handleOpenMenu"
      @closeMenu="handleCloseMenu"
    >
      <nav class="navbar navbar-expand-lg container mb-lg-4">
        <router-link class="navbar-brand logo" exact to="/">
          <logo :size-width="200" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav>
      <div class="mainNav" id="navbarSupportedContent">
        <ul class="navbar-nav w-100">
          <li class="nav-item">
            <router-link exact to="/" class="nav-link">Home</router-link>
          </li>
          <!-- <li class="nav-item right-to-left">
            <router-link :to="whyFuelPath" class="nav-link" id="team"
              >Why Fuel</router-link
            >
          </li> -->
          <li class="nav-item">
            <router-link :to="ourTeamPath" class="nav-link">Team</router-link>
          </li>
          <li class="nav-item active">
            <router-link :to="portfoliosPath" class="nav-link"
              >Portfolios</router-link
            >
          </li>
          <!-- <li class="nav-item">
            <router-link :to="newsPath" class="nav-link"
              >Fuel the Mind</router-link
            >
          </li> -->
          <li class="nav-item border-bottom"></li>
          <li class="nav-item" v-if="!profile.email">
            <router-link class="nav-link" :to="loginPath">Login</router-link>
          </li>
          <template v-if="profile.email">
            <template
              v-if="
                profile.userType === 'Founder' &&
                (profile.welcome_survey_stt === 'NOT_STARTED' ||
                  profile.welcome_survey_stt === 'IN_PROGRESS' ||
                  profile.welcome_survey_stt === 'REVIEWING' ||
                  profile.welcome_survey_stt === 'REJECTED' ||
                  profile.welcome_survey_stt === 'COMPLETED')
              "
            >
              <a class="nav-link" :href="welcomeSurveyPath">Welcome Survey</a>
            </template>
            <template v-else-if="profile.userType === 'Founder'">
              <li class="nav-item">
                <a class="nav-link" :href="adminProfilePath">Profile</a>
              </li>
            </template>
            <template v-else-if="profile.userType === 'Investor'">
              <li class="nav-item">
                <a class="nav-link" :href="investorPartnerOverview">Portal</a>
              </li>
            </template>
            <template v-else>
              <li class="nav-item">
                <a class="nav-link" :href="adminRokkerPath">Admin</a>
              </li>
            </template>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="logout">Logout</a>
            </li>
          </template>
        </ul>
      </div>
    </Slide>
    <div class="logo d-sm-block p-0">
      <div class="mainContainer">
        <router-link class="navbar-brand logo ml-lg-4" exact to="/">
          <logo :size-width="200" />
        </router-link>
      </div>
    </div>
    <div class="login-m d-sm-block" v-if="!profile.email">
      <router-link class="nav-link" :to="loginPath">Login</router-link>
    </div>
  </header>
  <div v-else>
    <header id="mainHeader" class="mainHeader">
      <nav class="navbar navbar-expand-lg navbar-light w-100">
        <router-link class="navbar-brand logo" exact to="/">
          <logo :size-width="200" />
        </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav justify-content-end w-100">
            <li class="nav-item">
              <router-link exact to="/" class="nav-link">Home</router-link>
            </li>
            <!-- <li class="nav-item right-to-left">
              <router-link :to="whyFuelPath" class="nav-link" id="team"
                >Why Fuel</router-link
              >
            </li> -->
            <li class="nav-item right-to-left">
              <router-link :to="ourTeamPath" class="nav-link" id="team"
                >Team</router-link
              >
            </li>
            <li class="nav-item right-to-left">
              <router-link :to="portfoliosPath" class="nav-link" id="portfolios"
                >Portfolios</router-link
              >
            </li>
            <!-- <li class="nav-item left-to-right">
              <router-link :to="newsPath" class="nav-link"
                >Fuel the Mind</router-link
              >
            </li> -->
            <li class="nav-item left-to-right">
              <div class="rightNav">
                <div class="dropdown" v-if="profile.email">
                  <a
                    class="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ fullName }}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      :href="welcomeSurveyPath"
                      v-if="
                        profile.userType === 'Founder' &&
                        (profile.welcome_survey_stt === 'NOT_STARTED' ||
                          profile.welcome_survey_stt === 'IN_PROGRESS' ||
                          profile.welcome_survey_stt === 'REVIEWING' ||
                          profile.welcome_survey_stt === 'REJECTED' ||
                          profile.welcome_survey_stt === 'COMPLETED')
                      "
                      >Welcome Survey</a
                    >
                    <a
                      class="dropdown-item"
                      :href="adminProfilePath"
                      v-else-if="profile.userType === 'Founder'"
                      >Profile</a
                    >
                    <a
                      class="dropdown-item"
                      :href="adminRokkerPath"
                      v-else-if="profile.userType !== 'Investor'"
                      >Admin</a
                    >
                    <a
                      class="dropdown-item"
                      :href="investorPartnerOverview"
                      v-else-if="profile.userType === 'Investor'"
                      >Portal</a
                    >
                    <a class="dropdown-item" @click.prevent="logout">Logout</a>
                  </div>
                </div>
                <div v-else>
                  <router-link
                    class="nav-link btnAuth"
                    id="btnAuth"
                    :to="loginPath"
                    >Login</router-link
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/config/router";
import { Slide, Push } from "vue3-burger-menu";
// import ModelLogin from "@/components/security/login"
import ModalRegister from "@/components/security/register";
import appStore, { AUTH_ACTIONS } from "@/store/app";
import conf from "@/config";
import logo from "@/components/commons/logo";

export default defineComponent({
  components: {
    Slide,
    Push,
    // ModelLogin,
    ModalRegister,
    logo,
  },
  data() {
    return {
      homePath: router.home.path,
      aboutUsPath: "/who-are-you",
      // whyFuelPath: router.whyFuel.path,
      ourTeamPath: "/our-team",
      portfoliosPath: "/portfolio",
      // fuelStationPath: router.fuelStation.path,
      // newsPath: router.news.path,
      loginPath: router.login.path,
      registerPath: router.register.path,
      // adminPath: conf.url_admin,
      adminProfilePath: router.profile.path,
      adminRokkerPath: conf.url_admin_rokker,
      welcomeSurveyPath: router.welcomeSurvey.path,
      investorPartnerOverview: router.investorpartner.path,
      offset: {},
      shouldStick: false,
      timeout: null,
      timing: 2000,
    };
  },
  mounted() {
    let me = this;
    this.animationMenu();
  },
  methods: {
    animationMenu() {
      let el = document.querySelectorAll(".nav-item");
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        el.forEach((p) => {
          p.style.animationPlayState = "paused";
        });
      }, this.timing);
    },
    handleScroll() {
      var scrollTop = window.scrollY;
      if (scrollTop <= 68) {
        $(".mainHeader").removeClass("fixed");
      } else if (scrollTop > 68) {
        $(".mainHeader").addClass("fixed");
      }
    },
    handleOpenMenu() {
      $(".bm-menu").css({
        transition: "0.4s ease 0s, opacity 0.2s ease 0.2s",
        opacity: 1,
      });
    },
    handleCloseMenu() {
      $(".bm-menu").css({
        transition:
          "0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s,opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s",
        opacity: 0,
      });
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
  },
  computed: {
    fullName: {
      get() {
        return `${this.profile.firstName} ${this.profile.lastName}`;
      },
      set(value) {
        return value;
      },
    },
    isAuth() {
      return appStore.state.auth;
    },
    profile() {
      return appStore.state.profile || {};
    },
    isMobile: {
      get() {
        return appStore.state.mobile.isMobile || false;
      },
      set(newValue) {
        return newValue;
      },
    },
    store() {
      return appStore.state;
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
</script>

<style lang="scss" scoped>
// .top-to-bottom{
//    -webkit-animation: topToBottom 2s infinite; /* Safari 4.0 - 8.0 */
//   animation: topToBottom 2s infinite;
// }
// .right-to-left{
//   -webkit-animation: rightToLeft 2s infinite; /* Safari 4.0 - 8.0 */
//   animation: rightToLeft 2s infinite;
// }

// .left-to-right{
//   -webkit-animation: leftToRight 2s infinite; /* Safari 4.0 - 8.0 */
//   animation: leftToRight 2s infinite;
// }

/* Safari 4.0 - 8.0 */
@-webkit-keyframes topToBottom {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes topToBottom {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes rightToLeft {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Standard syntax */
@keyframes rightToLeft {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes leftToRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Standard syntax */
@keyframes leftToRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
.rightNav {
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdown {
    .dropdown-toggle {
      color: #fff;
      font-weight: 600;
      font-size: 0.9rem;
      &:hover {
        color: #388cde;
      }
    }
    .dropdown-menu {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      left: auto;
      right: 0;
      .dropdown-item {
        cursor: pointer;
        font-weight: 600;
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        &:hover {
          color: #fff;
          background-color: #388cde;
        }
      }
    }
  }
  #btnAuth {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.75rem 2.5rem !important;
    border-radius: 5px;
    // &:hover{
    //   color: #fff !important
    // }
  }
}
#mainHeader {
  &.fixed {
    position: fixed;
    background-color: #000;
  }
}

//https://github.com/mbj36/vue-burger-menu

// #navbarSupportedContent {
//   .nav-item {
//     width: 100%;
//     margin-bottom: 2rem;
//     .nav-link {
//       white-space: nowrap;
//       width: 100%;
//       padding-bottom: 0;
//       font-size: 1.875rem;
//       font-weight: 900;
//       color: #fff;
//       text-decoration: none;
//       position: relative;
//       &.active {
//         color: #3e99ed;
//         &:after {
//           content: "";
//           display: block;
//           width: 60px;
//           height: 4px;
//           background-color: #3e99ed;
//           position: absolute;
//           bottom: 0;
//           left: 60%;
//         }
//       }
//     }
//   }
// }
.noteMenu {
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  color: #fff;
  white-space: nowrap !important;
}
// .btn {
//   color: #fff;
//   border: none;
//   background-image: linear-gradient(to right, #3e99ed, #20295b);
//   &.btnLoginOut {
//     position: absolute;
//     top: 0;
//     left: 1725px;
//   }
//   &.btnRegister {
//     position: absolute;
//     top: 60px;
//     left: 1725px;
//   }

//   @media (min-width: 1200px) and (max-width: 1600px) {
//     &.btnLoginOut {
//       position: absolute;
//       top: 30px;
//       left: 1130px;
//     }
//     &.btnRegister {
//       position: absolute;
//       top: 100px;
//       left: 1130px;
//     }
//   }
// }
@media (max-width: 575.98px) {
  .btnLoginOut {
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .btnLoginOut {
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .btnLoginOut {
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .btnLoginOut {
  }
}
@media (min-width: 1200px) and (max-width: 1365.98px) {
  .btnLoginOut {
  }
}
</style>
