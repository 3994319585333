import { ValueAxis } from "@amcharts/amcharts4/charts";
import _ from "lodash";
import { defineComponent } from "vue";
import Dragger from "@/components/Dragger";
import { swapObject } from "@/misc/commons";

export const DropdownItem = defineComponent({
  template: `
        <a class="dropdown-item pointer" :title="title" @click="click">
            <slot />
        </a>`,
  props: { title: { type: String, default: "" } },
  methods: {
    click() {
      this.$emit("click");
    },
  },
});

export const Dropdown = defineComponent({
  template: `
		<div class="custom-menu-dropdown dropdown">
			<a class="dropdown-toggle btn btn-small btn-primary" title="Actions" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<i class="fas fa-ellipsis-h"></i>
			</a>
			<div class="dropdown-menu dropdown-menu-right">
				<slot />
			</div>
		</div>`,
});

export const debounce = _.debounce((func) => func(), 500);

export default defineComponent({
  name: "Table",
  components: {
    Dragger,
  },
  emits: [
    "rows",
    "updatePaging",
    "columns",
    "orderChanged",
    "rowClick",
    "onSelectAll",
    "onChangeCheckbox",
  ],
  props: {
    showNoRow: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    columns: { type: Array, default: () => [] },
    rows: { type: Array, default: () => [] },
    customRowsClass: { type: String, default: "" },
    multiSort: { type: Boolean, default: false },
    totalItems: { type: Number, default: 0 },
    paging: { type: Object, default: undefined },
    removePadding: { type: Boolean, default: false },
    cursorPointer: { type: Boolean, default: false },
    useDrag: { type: Boolean, default: false },
    useSimpleLayout: { type: Boolean, default: false },
    isSizeChange: { type: Boolean, default: false },
    sizeChange: { type: Number, default: 0 },
    customCurrentPage: { type: Number, default: null },
    hideHeader: { type: Boolean, default: false },
    hasCheckbox: { type: Boolean, default: false },
    checkAll: { type: Boolean, default: false },
    hasScrollY: { type: Boolean, default: true },
    idsSelected: { type: Array, default: () => [] },
    isFixedHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      checked: false,
      sorts: [],
      sizeOptions: [10, 20, 50],
      size: this.paging ? this.paging.size : 10,
      currentPage: this.customCurrentPage
        ? this.customCurrentPage
        : this.paging
        ? this.paging.page
        : 1,
    };
  },
  computed: {
    _rows: {
      get() {
        if (this.hasCheckbox) {
          var rows = this.rows.map((p, index) => {
            let isChecked = false;
            if (this.idsSelected.length > 0) {
              const available = this.idsSelected.find((x) => x === p.id);
              isChecked = !!available;
            }
            return {
              ...p,
              checkbox: isChecked,
            };
          });
          rows.map((x) => {
            if (x.hasOwnProperty("userId")) {
              let row = this.rows.filter((row) => row.userId == x.userId);
              if (row && row[0].hasOwnProperty("checkbox"))
                x.checkbox = row[0].checkbox;
            }
          });
        }
        return this.hasCheckbox ? rows : this.rows;
      },
      set(value) {
        this.$emit("rows", value);
      },
    },
    _columns() {
      let vue = this;
      var cols = this.columns;

      cols.map((el, idx) => {
        const titleClass = _.union(
          ["header-cell", "text-nowrap", "align-top"],
          el.titleClass ? el.titleClass.split(" ") : ""
        );
        el.titleClass = titleClass.join(" ");

        const columnClass = _.union(
          ["align-middle"],
          el.columnClass ? el.columnClass.split(" ") : ""
        );

        el.columnClass = columnClass.join(" ");

        el.sort = el.sort ? true : false;
        el.sort && this.sorts.push({ id: el.id, sort: el.direction || "asc" });

        if (vue.hasCheckbox) {
          el.cellId = idx;
          el.checkbox = false;
        }
        return el;
      });

      return cols;
    },
    pageInfo() {
      var sizeNum = this.size * this.currentPage;
      return {
        from: sizeNum - this.size + 1,
        to: sizeNum >= this.totalItems ? this.totalItems : sizeNum,
      };
    },
    _paging() {
      const totalPage = Math.ceil(this.totalItems / this.size);
      if (totalPage == 1) return [];
      const showNumber = 5;
      const side = Math.floor(showNumber / 2);
      var arr = [];
      var left = [];
      var right = [];
      for (let i = 0; i < side; i++) {
        const res = this.currentPage - (side - i);
        if (res > 0) left.push({ id: res, target: res });
      }
      for (let i = 1; i <= side; i++) {
        const res = this.currentPage + i;
        if (res <= totalPage) right.push({ id: res, target: res });
      }
      if (left.length >= 1) {
        arr.push({ id: "first", target: 1 });
        arr.push({ id: "prev", target: this.currentPage - 1 });
      }
      arr.push(...left);
      arr.push({ id: this.currentPage, target: this.currentPage });
      arr.push(...right);
      if (right.length >= 1) {
        arr.push({ id: "next", target: this.currentPage + 1 });
        arr.push({ id: "last", target: totalPage });
      }
      return arr;
    },
  },
  created() {
    this.updatePaging();
  },
  methods: {
    detectColSpanNoData() {
      if (this.checkAll) return this._columns.length + 2;
      if (this.showNoRow) return this._columns.length + 1;
      return this.columns.length;
    },
    rowClass(data) {
      return data && data.rowClass ? data.rowClass : "";
    },
    pageClick(event) {
      this.currentPage = event.target;
      this.updatePaging();
    },
    selectSize(event) {
      this.size = Number.parseInt(event.target.value);
      this.currentPage = 1;
      this.updatePaging();
    },
    rowClick(data) {
      this.$emit("rowClick", data);
    },
    // headerCellClick(column, inputSort) {
    // 	if(!column.sort || !column.id) return
    // 	const sortId = this.sorts.findIndex((x) => x.id == column.id)
    // 	const sort = { id: column.id, sort: inputSort }
    // 	if (sortId < 0) {
    // 		if (this.multiSort) {
    // 			this.sorts.push(sort)
    // 		} else {
    // 			this.sorts = [sort]
    // 		}
    // 	} else if (sortId >= 0 && this.sorts[sortId].sort == 'asc' && inputSort == 'asc') {
    // 		this.sorts.splice(sortId, 1)
    // 	} else if (sortId >= 0 && this.sorts[sortId].sort == 'asc' && inputSort == 'desc') {
    // 		this.sorts[sortId].sort = 'desc'
    // 	} else if (sortId >= 0 && this.sorts[sortId].sort == 'desc') {
    // 		this.sorts.splice(sortId, 1)
    // 	} else if (sortId >= 0 && this.sorts[sortId].sort == 'desc' && inputSort == 'asc') {
    // 		this.sorts[sortId].sort = 'asc'
    // 	}
    // 	this.currentPage = 1
    // 	this.updatePaging()
    // },
    headerCellClick(column) {
      if (!column.sort || !column.id) return;
      const sortId = this.sorts.findIndex((x) => x.id == column.id);
      const sort = { id: column.id, sort: column.direction || "asc" };
      if (sortId < 0) {
        if (this.multiSort) {
          this.sorts.push(sort);
        } else {
          this.sorts = [sort];
        }
      } else {
        this.sorts[sortId].sort =
          this.sorts[sortId].sort == "asc" ? "desc" : "asc";
      }

      this._columns.map((p) => {
        if (typeof p.sort === "boolean" && p.sort) {
          if (p.id === column.id) {
            p.direction = column.direction === "asc" ? "desc" : "asc";
          } else {
            p.direction = "";
          }
        }
      });
      this.currentPage = 1;
      this.updatePaging();
    },
    updatePaging() {
      this.size = this.isSizeChange ? this.sizeChange : this.size;
      this.$emit("updatePaging", {
        sorts: this.sorts,
        size: this.size,
        page: this.currentPage,
      });
    },
    orderChanged(item) {
      const reorder = swapObject(item, this._rows);
      this.$emit("orderChanged", reorder);
    },
    onChangeCheckbox(event, row) {
      if (row) {
        this.$emit("onChangeCheckbox", row);
      }
    },
    selectAll(e) {
      let isChecked = e.target.checked;
      this._rows.map((x) => {
        x.checkbox = isChecked;
      });
      this.$emit("onSelectAll", this._rows, isChecked);
    },
  },
  watch: {
    _rows: {
      deep: true,
      handler(v) {
        if (this.hasCheckbox) {
          if (
            this.idsSelected.length !== 0 &&
            v.length !== 0 &&
            this.idsSelected.length === v.length
          ) {
            this.checked = true;
          } else {
            this.checked = false;
          }
        }
      },
    },
    "paging.page": function (v) {
      this.currentPage = v;
    },
    _columns: {
      deep: true,
      handler(v) {
        this.$emit("columns", {});
      },
    },
  },
});
