import { defineComponent } from "vue";
import AdminServices from "@/services/admin";
import { PERMISSIONS } from "@/config/router";
import notify from "@/services/notify";
import moment from "moment";
import TextField from "@/components/TextField";
import Datepicker from "@/components/DatePicker";
import Switcher from "@/components/Switcher";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import Dialog from "@/components/Dialog";
import AddReviewerModel from "./AddReviewerModel";
import {
  parseTimeToUTC,
  mapStringToTime,
  parseUTCTimeToLocal,
} from "@/misc/commons";

const dataColumns = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "sms",
    name: "Notify Sms",
  },
  {
    id: "notify_email",
    name: "Notify Email",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-right",
    columnClass: "text-right action-icon-group",
  },
];

export default defineComponent({
  components: {
    TextField,
    Switcher,
    Modal,
    Table,
    AddReviewerModel,
    Datepicker,
    Dialog,
  },
  emits: ["refresh"],
  data() {
    return {
      stageInfo: null,
      componentInit: false,
      columns: dataColumns,
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
      },
      interval_in_day: null,
      death_line_in_day: null,
      death_line_in_day_public: null,
      death_line_in_day_investor: null,
      notify_hour: null,
      name: "",
      roleList: [],
      isShow: false,
      errorMsg: {
        death_line_in_day: null,
        death_line_in_day_public: null,
        death_line_in_day_investor: null,
        notify_interval_in_hour: null,
        notify_before_hour: null,
      },
      PERMISSIONS: PERMISSIONS,
      addReviewerModelkey: 0,
      modalFirstTime: null,
      tabs: [
        {
          id: 0,
          code: "dead_line_notification",
          name: "Deadline Notification",
        },
        {
          id: 1,
          code: "reviewers_management",
          name: "Reviewers Management",
        },
      ],
      tab_selected: null,
      exceptUserIds: [],
    };
  },
  watch: {
    paging: {
      deep: true,
      handler() {
        this.getItems();
      },
    },
    rows: {
      deep: true,
      handler(v) {
        this.exceptUserIds = [];
        if (v) {
          v.map((p) => p.user_id && this.exceptUserIds.push(p.user_id));
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.tab_selected) this.tab_selected = this.tabs[0];
    });
  },
  methods: {
    mapTime(time) {
      return mapStringToTime(time);
    },
    async getItems() {
      if (!this.stageInfo) return;
      this.loading = true;

      const res = await AdminServices.getStageById(this.stageInfo.stage_id);
      if (res && res.data && res.data.ok) {
        this.name = res.data.d.name;
        this.interval_in_day = res.data.d.interval_in_day.toString();
        this.death_line_in_day = res.data.d.death_line_in_day.toString();
        this.death_line_in_day_public =
          res.data.d.death_line_in_day_public.toString();
        this.death_line_in_day_investor =
          res.data.d.death_line_in_day_investor.toString();
        this.notify_hour = this.mapTime(res.data.d.notify_hour);
        this.rows = [res.data.d];
        await this.getReviewers();
      }
      this.loading = false;
      this.componentInit = true;
    },
    async getReviewers() {
      this.loading = true;
      const res = await AdminServices.getStageReviewers(
        this.stageInfo.stage_id,
        {
          paging: {
            page: this.paging.page,
            size: this.paging.size,
          },
        }
      );
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    validData() {
      let flag = true;

      function validTime(time) {
        let flag = true;

        if (
          time &&
          (isNaN(parseInt(time.hours)) || isNaN(parseInt(time.minutes)))
        ) {
          flag = false;
        }
        return flag;
      }

      if (this.interval_in_day.trim() === "") {
        this.errorMsg.notify_interval_in_hour = "Please input hour";
        flag = false;
      }
      if (this.death_line_in_day.trim() === "") {
        this.errorMsg.death_line_in_day = "Please input day";
        flag = false;
      }
      if (this.death_line_in_day_public.trim() === "") {
        this.errorMsg.death_line_in_day_public = "Please input day";
        flag = false;
      }
      if (this.death_line_in_day_investor.trim() === "") {
        this.errorMsg.death_line_in_day_investor = "Please input day";
        flag = false;
      }
      if (!validTime(this.notify_hour)) {
        if (this.notify_hour.hours === "" || this.notify_hour.minutes === "") {
          this.errorMsg.notify_before_hour = "Please input correct time";
          flag = false;
        }
      }
      return flag;
    },
    async save() {
      if (!this.validData()) return;

      let data = {
        death_line_in_day: this.death_line_in_day,
        death_line_in_day_public: this.death_line_in_day_public,
        death_line_in_day_investor: this.death_line_in_day_investor,
        interval_in_day: this.interval_in_day,
        notify_hour: this.parseTime(this.notify_hour, "HH:mm"),
      };
      this.loading = true;
      const res = await AdminServices.updateStage(
        this.stageInfo.stage_id,
        data
      );
      if (res.data.ok) {
        notify.success("Update Successfully");
        this.$emit("refresh");
      }
      this.loading = false;
      this.closeModal();
    },
    changeReviewSetting(key, props) {
      var value = false;
      if (key == "notify_sms") {
        value = props.notify_sms;
      }
      if (key == "notify_email") {
        value = props.notify_email;
      }
      this.loading = true;
      AdminServices.updateStageReviewer(
        this.stageInfo.stage_id,
        props.user_id,
        key,
        value
      )
        .then((res) => {
          if (res.data.ok) {
            notify.success("Update Successfully");
            this.$emit("refresh");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async deleteReviewer(item) {
      let message = {
        title: "Warning",
        body: "This will permanently delete this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await AdminServices.deleteReviewerItem(
            this.stageInfo.stage_id,
            item.user_id
          );
          if (res.data.ok) {
            notify.success("Delete success");
            this.isChange = true;
            this.getReviewers();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    showModal(props) {
      this.stageInfo = props;
      this.isShow = true;
    },
    async AddReviewer() {
      this.addReviewerModelkey++;
      this.$nextTick(() => {
        this.$refs.addReviewerModel.showAdd(this.stageInfo.stage_id);
      });
    },
    parseTime(param, format) {
      if (!param.hours || !param.minutes) return;

      let tmpTime = {
        years: moment().format("YYYYY"),
        months: moment().format("MM"),
        days: moment().format("DD"),
        hours: param.hours,
        minutes: param.minutes,
      };

      return parseTimeToUTC(tmpTime, format);
    },
    changeTabs: function (item) {
      this.tab_selected = item;
    },
    closeModal() {
      this.isShow = false;
    },
    updatePaging(value) {
      this.paging = Object.assign(this.paging, value);
    },
    refresh() {
      this.$emit("refresh");
    },
  },
});
