<template>
  <div class="adminContent p-3" v-loading="loading">
    <div class="left-section me-2">
      <div class="data-content py-2 content-left-scrollable scrollbar">
        <div class="d-flex justify-content-end header-section">
          <button class="btn btn-primary" @click="openDialogToAddEdit(null)">
            <i class="fas fa-plus me-1"></i>
            <span>Add Fund</span>
          </button>
        </div>
        <hr />

        <Dragger
          id="fund"
          v-model:value="items"
          ghost-class="ghost"
          component-class="handle"
          handle=".handle"
          @change="onSortFundEnd"
        >
          <template #default="{ props, index }">
            <div
              :key="`fd${index}`"
              class="fund-card"
              :class="{ selected: props.id === displayedItemId }"
            >
              <div class="fund-content">
                <div class="title-wrapper">
                  <div
                    class="title pointer"
                    :class="{ inactive: !props.is_enabled }"
                    @click="selectFund(props)"
                  >
                    {{ props.name }}
                  </div>
                  <div class="actions" :style="{ position: 'relative' }">
                    <div class="custom-menu-dropdown dropdown me-1">
                      <a
                        class="dropdown-toggle btn btn-small btn-primary btn-action"
                        title="Actions"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-ellipsis-h"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item pointer"
                          @click="openDialogToAddEdit(props.id)"
                          title="Edit"
                        >
                          <i class="far fa-edit me-1"></i> Edit
                        </a>
                        <a
                          class="dropdown-item pointer"
                          @click="openModalConfirm('deleteFund', props)"
                        >
                          <i class="far fa-trash-alt me-1"></i> Delete
                        </a>
                      </div>
                    </div>
                    <div>
                      <a
                        class="btn btn-default btn-small handle all-scroll cursor-move btn-action"
                        ><i class="fas fa-bars"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="desc">{{ props.description }}</div>
              </div>
            </div>
          </template>
        </Dragger>
      </div>
    </div>
    <div class="right-section">
      <div class="data-content py-2 content-scrollable scrollbar">
        <div class="header-section" v-if="displayedItemId">
          <div class="title wordBreak">
            <span class="not-emphasized">Members of fund </span>
            <br />
            {{ displayedItem.name }}
          </div>
          <button class="btn btn-primary" @click="openDialogToAddMemberToFund">
            <i class="fas fa-plus me-1"></i>
            <span>Add members to fund</span>
          </button>
        </div>
        <hr />
        <div>
          <Dragger
            :id="'members'"
            v-model:value="members"
            handle=".handle"
            ghost-class="ghost"
            component-tag="ul"
            drag-element-tag="li"
            @change="onSortMemberEnd"
          >
            <template #default="{ props, index }">
              <div class="member-card" :key="`mb-${index}`">
                <div class="member-content">
                  <div class="title" :class="{ inactive: !props.is_enabled }">
                    {{ props.name }}
                  </div>
                  <div class="desc">{{ props.title }}</div>
                </div>
                <div class="actions">
                  <a
                    class="btn btn-danger btn-small btn-action me-1"
                    @click="openModalConfirm('removeMember', props)"
                    ><i class="far fa-trash-alt"></i
                  ></a>
                  <a
                    class="btn btn-default btn-small handle all-scroll cursor-move btn-action"
                    ><i class="fas fa-bars"></i
                  ></a>
                </div>
              </div>
            </template>
          </Dragger>
        </div>
      </div>
    </div>

    <addEditFund
      v-if="isShowAddEdit"
      :id="editItemId"
      @close="closeAddEdit"
      @complete="onCompleteAddEdit($event)"
    />

    <Dialog ref="dialog"></Dialog>

    <addMemberToFund
      v-if="isShowAddMemberToFund"
      :id="displayedItemId"
      @close="closeAddMemberToFund"
      @complete="onCompleteAddMemberToFund"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

.adminContent {
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  display: flex;
  .left-section {
    flex: 0 0 400px;
    max-width: 400px;
    .header-section {
      margin: 0 10px;
    }
  }
  .right-section {
    flex: 1;
    .header-section {
      margin: 0 10px;
      display: flex;
      align-items: center;
      .title {
        flex-grow: 1;
        .not-emphasized {
          color: $text-color--secondary;
        }
      }
    }
  }
}
.data-content {
  min-height: calc(100vh - 70px - 2rem);
  background-color: $color-background-secondary;
  display: block;
  border-radius: 3px;
  color: $text-color;
}
.content-scrollable {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.content-left-scrollable {
  max-height: calc(100vh - 76px);
  overflow-y: auto;
}
.fund-card {
  margin: 10px;
  padding: 5px;
  border: 1px solid $color-input-background;
  border-radius: 5px;
  &.selected {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  .fund-content {
    display: flex;
    flex-direction: column;
    .title-wrapper {
      display: flex;
      align-items: center;
      padding-top: 14px;
      padding-bottom: 14px;
      // margin-bottom: 8px;
      border-bottom: 1px solid $color-input-background;
      .title {
        flex-grow: 1;
        font-size: 16px;
        color: $text-color;
        word-break: break-all;
        &.inactive {
          color: $color-danger;
        }
      }
      .actions {
        display: flex;
        .btn-action {
          font-size: 10px;
        }
      }
    }
    .desc {
      font-size: 12px;
      color: $text-color--secondary;
      text-align: justify;
      word-break: break-all;
      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.member-card {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 5px;
  border: 1px solid $color-input-background;
  border-radius: 5px;
  .member-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 10px;
    .title {
      font-size: 16px;
      color: $text-color;
      word-break: break-all;
      &.inactive {
        color: $color-danger;
      }
    }
    .desc {
      font-size: 12px;
      color: $text-color--secondary;
      text-align: justify;
      word-break: break-all;
      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .actions {
    display: flex;
    .btn-action {
      font-size: 10px;
    }
  }
}

.custom-menu-dropdown.dropdown {
  position: unset;
  .dropdown-toggle:after {
    content: none;
  }
  .dropdown-menu {
    background-color: $color-background-tertiary;
    .dropdown-item {
      color: $color-grey;
      &.router-link-active {
        color: #fff;
      }
      &:hover {
        background-color: $color-grey;
        color: $color-dark;
      }
    }
  }
}
</style>
