<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideModal"></div>
      <div class="modal-dialog-md">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Details</h4>
          </div>
          <div
            class="modal-body custom-modal-body scrollbar"
            v-loading="loading"
          >
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0" v-if="detailInfo">
                <thead>
                  <tr>
                    <th class="fileName">Investor</th>
                    <th class="viewCount">Views</th>
                    <th class="viewCount">Last tracked datetime</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, idx) in detailInfo.user_details" :key="idx">
                    <td>
                      <span>{{ user.fname }} {{ user.lname }}</span>
                      <div class="sub-text">{{ user.username }}</div>
                      <div class="sub-text">{{ user.investor_type }}</div>
                      <div class="sub-text" v-if="user.inviter_id">
                        invited by {{ user.inviter_fname }}
                        {{ user.inviter_lname }}
                      </div>
                    </td>
                    <td>{{ user.view_count }}</td>
                    <td>
                      {{ $filters.parseEstTime(user.last_tracked_timestamp) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-default" @click="hideModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 160px);
  padding-top: 0.3rem;
}
.modal-content {
  border: 1px solid #525252;
}
.modal-footer {
  display: block;
  border-top: 1px solid #525252;
}
h5 {
  color: #fff;
}
.close {
  color: #fff;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  color: #fff;
}
table > thead:first-child > tr:first-child > th {
  border-top: 0;
  border-bottom: 0;
  font-weight: 600;
}
.table > tbody > tr > td {
  color: #949ba2;
  vertical-align: middle;
}
.sub-text {
  font-size: 11px;
  color: $text-color--secondary;
}
</style>
<script src="./index.js"></script>
