import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import AdminServices from "@/services/admin";
import notify from "../../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["item", "hideManageVersions"],
  data() {
    return {
      loading: false,
      dataroomItem: null,
      files: [],
      menuOptions: [{ name: "Download" }],
      sameNameFiles: [],
      isConfirming: false,
      specialCharacterMsg: 'Name cannot contain characters * : " \\ | < > / ?',
    };
  },
  computed: {
    needConfirm() {
      return this.sameNameFiles && this.sameNameFiles.length > 0;
    },
  },
  created() {
    if (this.item && this.item.id) {
      this.loading = true;
      this.getDataroomFileItemsOfSameName().then((res) => {
        if (res) {
          this.getDataroomItemDetail();
        }
      });
    } else {
      this.hideManageVersions;
    }
  },
  beforeUnmount() {
    $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
  },
  methods: {
    getDataroomFileItemsOfSameName() {
      return new Promise((resolve) => {
        AdminServices.getDataroomFileItemsOfSameName(this.item.id).then(
          (resp) => {
            if (resp.data.ok) {
              this.sameNameFiles = resp.data.d.filter(
                (x) => x.id !== this.item.id
              );
            }
            resolve(true);
          }
        );
      });
    },
    getDataroomItemDetail() {
      this.loading = true;
      AdminServices.getDataroomItemDetail(this.item.id).then((resp) => {
        if (resp.data.ok) {
          this.dataroomItem = resp.data.d;
        }
        this.loading = false;
      });
    },
    validateForm() {
      if (this.needConfirm && !this.isConfirming) {
        this.isConfirming = true;
      } else {
        this.doUpload();
      }
    },
    confirmUpload() {
      this.doUpload();
    },
    cancelUpload() {
      this.isConfirming = false;
    },
    doUpload() {
      this.loading = true;
      this.uploadFiles()
        .then((res) => {
          if (res) {
            this.files = [];
            this.getDataroomItemDetail();
            notify.success("Success");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.isConfirming = false;
        });
    },
    uploadFiles() {
      return new Promise((resolve, reject) => {
        let fileToUpload = this.files[0];
        if (!fileToUpload.isUploaded) {
          AdminServices.validateBeforeAddingVersionFile(this.item.id).then(
            (_validatedRes) => {
              if (_validatedRes.data.ok) {
                AdminServices.getSessionToken().then((r) => {
                  AWS.config.update({
                    region: r.data.d.region,
                    credentials: new AWS.Credentials(
                      r.data.d.access_key,
                      r.data.d.secret_key,
                      r.data.d.session_token
                    ),
                  });

                  fileToUpload.isUploading = true;
                  let lastDot = fileToUpload.file.name.lastIndexOf(".");
                  let upload = new S3.ManagedUpload({
                    params: {
                      Bucket: r.data.d.bucket,
                      Key:
                        this.getRandomKey(12) +
                        fileToUpload.file.name.substring(lastDot),
                      Body: fileToUpload.file,
                    },
                  });
                  upload.on("httpUploadProgress", (progress) => {
                    if (progress.total) {
                      fileToUpload.progress = Math.round(
                        (100 * progress.loaded) / progress.total
                      );
                    }
                  });

                  upload.promise().then((_awsRes) => {
                    AdminServices.addVersionToDataroomFileV2(this.item.id, {
                      fileName: fileToUpload.file.name.substring(0, lastDot),
                      fileExtension: fileToUpload.file.name.substring(lastDot),
                      fileSize: fileToUpload.file.size,
                      fileS3Url: _awsRes.Key,
                    })
                      .then((_uploadRes) => {
                        if (_uploadRes.data.ok) {
                          fileToUpload.isUploaded = true;
                          fileToUpload.errorMsg = "";
                          resolve(true);
                        } else {
                          this.handleUploadFileError(_uploadRes);
                          resolve(false);
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        reject(false);
                      })
                      .finally(() => {
                        fileToUpload.isUploading = false;
                        fileToUpload.progress = 0;
                      });
                  });
                });
              } else {
                this.handleUploadFileError(_validatedRes);
                resolve(false);
              }
            }
          );
        } else {
          reject(false);
        }
      });
    },
    handleUploadFileError(res) {
      if (res.data.m) {
        this.files[0].errorMsg = res.data.m;
      } else {
        this.files[0].errorMsg = "Oops! An error has occurred. Please retry.";
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      let _uploadedFile = this.$refs.files.files[0];
      if (_uploadedFile) {
        this.files = [];
        this.files.push({
          file: _uploadedFile,
          isUploaded: false,
          isUploading: false,
          progress: 0,
          errorMsg: "",
          isNameValid: this.validateFileAndDirectoryName(_uploadedFile.name),
        });
        this.validateForm();
      }
      this.$refs.files.value = "";
    },
    handleClick(event, item) {
      if (this.dataroomItem && this.dataroomItem.versions.length < 2) {
        this.menuOptions = [{ name: "Download" }];
      }
      this.$nextTick(() => {
        this.$refs.versionMenu.showMenu(event, item);
      });
    },
    optionClicked(ev) {
      let { item, option } = ev;
      if (option.name === "Download") {
        this.handleDownloadFileVersion(item);
      }
    },
    handleDownloadFileVersion(item) {
      this.loading = true;
      AdminServices.requestDownloadCIO(
        this.item.id,
        "Download",
        item.DataRoomItemVersionId
      )
        .then((resp) => {
          if (resp.data.ok) {
            window.open(resp.data.d, "_blank");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
