<template>
  <div class="portfolioPage">
    <PartialsPortfolio
      :portfolios="portfolios"
      :showPortfolioSeemore="showPortfolioSeemore"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PartialsPortfolio from "./_PartialsPortfolio.vue";
import PortfoliosService from "@/services/portfolios";

export default defineComponent({
  components: {
    PartialsPortfolio,
  },
  data() {
    return {
      portfolios: [],
      showPortfolioSeemore: false,
    };
  },
  created() {
    this.getPortfolios();
  },
  methods: {
    getPortfolios() {
      PortfoliosService.getPortfolios().then((resp) => {
        if (resp.data.ok) {
          this.portfolios = resp.data.d;
        }
      });
    },
  },
});
</script>

<style></style>
