<template>
  <div class="adminContent p-3">
    <Table
      :paging="searchParams.paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="tableData"
      :total-items="searchParams.totalItems"
      :showNoRow="false"
      v-loading="loading"
    >
      <template #head_title>
        <TextField
          v-model:value="searchParams.filteredTitle"
          :autocomplete="false"
        />
      </template>

      <template #head_type>
        <select
          class="form-control form-control-sm"
          v-model="searchParams.filteredType"
          @change="doFilter"
        >
          <option
            v-for="(item, idx) in fuelTypes"
            :key="idx"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
      </template>

      <template #row_date="{ props }">
        {{ $filters.parseEstTime(props.pub_date) }}
      </template>

      <template #row_title="{ props }">
        {{ props.title }}
      </template>

      <template #row_type="{ props }">
        {{ props.type }}
      </template>

      <template #row_uploadImage="{ props }">
        <a class="nowrap" :href="props.photo" target="_blank" v-if="props.photo"
          >View photo</a
        >
        <span v-else>None</span>
      </template>

      <template #row_link="{ props }">
        <a
          class="nowrap"
          :href="
            props.link.includes(prefixWeb) ? props.link : '//' + props.link
          "
          target="_blank"
          v-if="props.link"
          >View link</a
        >
        <span v-else>None</span>
      </template>

      <template #row_actions="{ props }">
        <div class="nowrap">
          <a
            class="btn btn-primary btn-small me-1"
            @click="editFueled(props.id)"
          >
            <i class="far fa-edit"></i>
          </a>
          <a class="btn btn-danger btn-small" @click="removeFueled(props.id)">
            <i class="far fa-trash-alt"></i>
          </a>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="showAddFueled">
          <i class="fas fa-plus me-1"></i>
          <span>Add New</span>
        </button>
      </template>
    </Table>

    <Dialog ref="dialog"></Dialog>

    <popupAddEditFueled
      ref="popupAddEditFueled"
      @reloadInfo="getFuelAndMindData"
    />
  </div>
</template>

<script src="./index.js"></script>
<style>
.width-80 {
  width: 80px;
}
.width-103 {
  width: 103px;
}
.width-110 {
  width: 110px;
}
.w-data {
  width: 100px;
  min-width: 100px;
}
.w-title {
  width: 450px;
}
.w-type {
  width: 250px;
  min-width: 250px;
}
.table thead th {
  vertical-align: top !important;
}

@media only screen and (max-width: 768px) {
  .w-type {
    width: 125px;
    min-width: 125px;
  }
}
</style>
<style lang="scss" scoped>
.form-control-sm {
  min-height: 34px;
}
</style>
