import _ from "lodash";
import moment from "moment";
import notify from "@/services/notify";
import appStore from "@/store/app";
import config from "@/config";
import { USER_TYPE } from "@/config/router";
import momentTz from "moment-timezone";

export const copyToClipboard = (doCopy) => {
  if (doCopy) {
    const el = doCopy;
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const editable = el.contentEditable;
      const readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      doCopy.select();
    }
    document.execCommand("copy");
    el.blur();
    const langjson = require(`../config/lang/${appStore.state.currentLanguage.code}.json`);
    notify.success(langjson.copied_to_clipboard);
    return true;
  }
  return false;
};

export const copyHidenToClipboard = (ele) => {
  const doCopy = document.querySelector("#" + ele);
  doCopy.setAttribute("type", "text");
  doCopy.select();
  const langjson = require(`../config/lang/${appStore.state.currentLanguage.code}.json`);

  try {
    const successful = document.execCommand("copy");
    if (successful) {
      notify.success(langjson.copied_to_clipboard);
    } else {
      notify.error(langjson.oop_unable_copy);
    }
  } catch (err) {
    notify.error(langjson.oop_unable_copy);
  }

  doCopy.setAttribute("type", "hidden");
  window.getSelection().removeAllRanges();
  return false;
};

export const buildRouterConfig = (object) => {
  const res = [];
  if (_.isObject) {
    for (const k in object) {
      res.push(object[k]);
    }
  }
  return res;
};

export const resolvePath = (pathConfig, key, value) => {
  return pathConfig.replace(key, value);
};

export const number = {
  between(number, fromValue, toValue) {
    return number >= fromValue && number <= toValue;
  },
};

export const buildArrayConfig = (values, names, ignoreValue, extras) => {
  const res = [];
  const hasExtras = _.isObject(extras);
  for (const k in values) {
    const val = values[k];
    if (ignoreValue === val) {
      continue;
    }
    const item = {
      value: val,
      name: names[val],
    };
    if (hasExtras) {
      for (const ekey in extras) {
        item[ekey] = _.isObject(extras[ekey]) ? extras[ekey][val] : null;
      }
    }
    res.push(item);
  }
  return res;
};

export const PAGING_DEFAULT = {
  size: 20,
  min_page: 1,
  page: 1,
  total: 0,
  num_items: 2,
};

export class Paging {
  constructor(
    page = PAGING_DEFAULT.page,
    size = PAGING_DEFAULT.size,
    total = PAGING_DEFAULT.total,
    numItems = PAGING_DEFAULT.num_items
  ) {
    this.page = Math.max(PAGING_DEFAULT.min_page, page);
    this.size = size;
    this.total = total;
    this.minPage = PAGING_DEFAULT.min_page;
    this.maxPage = Math.max(this.minPage, Math.ceil(total / size));
    this.items = [];
    let min = Math.max(1, page - numItems);
    if (page <= this.maxPage - numItems * 2) {
      min = page - numItems;
    } else {
      min = this.maxPage - numItems * 2;
    }
    min = Math.max(1, min);
    for (let i = min; i <= min + numItems * 2; i++) {
      if (i > this.maxPage) break;
      this.items.push(i);
    }
  }
  get from() {
    return (this.page - 1) * this.size + 1;
  }
  get to() {
    return Math.min(this.from + this.size - 1, this.total);
  }
  get empty() {
    return this.items.length <= 1;
  }
}

export const commons = {
  paging(page, size, total) {
    return new Paging(page, size, total);
  },
  scrollTop() {
    const body = window.jQuery("html, body");
    body.stop().animate(
      {
        scrollTop: 0,
      },
      300,
      "swing"
    );
  },
};

export const hexToGrb = (hex) => {
  hex = hex.replace("#", "");
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return r + "," + g + "," + b;
};

export const formatNumber = (number, defaultText = "") => {
  const arr = `${parseFloat(number || 0) || defaultText}`.split(".");
  return `${arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    arr.length > 1 ? `.${arr[1]}` : ""
  }`;
};

export const formatDecimal = (number, defaultText = "", digrit = 3) => {
  const val = parseFloat(number || 0).toFixed(digrit);
  const arr = `${val || defaultText}`.split(".");
  return `${arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    arr.length > 1 ? `.${arr[1]}` : ""
  }`;
};

export const formatDecimalFloor = (number, maxDigrit = 3) => {
  let val = parseFloat(number || 0).toFixed(maxDigrit);
  val = parseFloat(val);
  const arr = `${val || 0}`.split(".");
  return `${arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    arr.length > 1 ? `.${arr[1]}` : ""
  }`;
};

export const formatDecimalNotRound = (
  number,
  defaultText = "",
  digrit = 6,
  fulldigit = true
) => {
  let val = parseFloat(number.toString().replace(",", "") || 0).toFixed(8);
  val = parseFloat(val);
  const arr = `${val || defaultText}`.split(".");
  if (arr.length === 1) {
    if (fulldigit) {
      let results = "";
      for (let i = 0; i < digrit; i++) {
        results += results + "0";
      }
      return arr[0] ? arr[0] + "." + results : Number(arr[0]);
    } else {
      return Number(arr[0]);
    }
  }
  if (fulldigit) {
    if (arr[1].length < digrit) {
      const count = digrit - arr[1].length;
      for (let i = 0; i < count; i++) {
        arr[1] += arr[1] + "0";
      }
      return arr[0] + "." + arr[1].substr(0, digrit);
    } else {
      return arr[0] + "." + arr[1].substr(0, digrit);
    }
  } else {
    return Number(arr[0] + "." + arr[1].substr(0, digrit));
  }
};

export const numberFormat = (number) => {
  if (number === undefined) return;
  const val = number.toString();
  const main = val.split(".")[0];
  const sub = val.split(".")[1] === undefined ? "" : "." + val.split(".")[1];
  return main.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sub;
};

export const handleErrors = (res) => {
  const lang = require(`../../src/config/lang/${appStore.state.currentLanguage.code}.json`);
  if (res.data.d) {
    let m = "";
    if (res.data.d.length && res.data.d.length > 0) {
      m = res.data.d[0];
    } else {
      m = "error";
      if (res.data.d.err_code === "invalid-2fa-code") {
        m = lang.google_authentication_incorrect;
      }
      if (res.data.d.err_code === "err_wait_after_24h") {
        m = lang.err_wait_after_24h;
      }
      if (res.data.d.err_code === "used-2fa-code") {
        m = lang.google_code_has_been_use;
      }
      if (res.data.d.err_code === "required-2fa-code") {
        m = lang.required_2fa_code;
      }
      if (res.data.d.err_code === "err_nickname_invalid") {
        m = lang.err_nickname_invalid;
      }
      if (res.data.d.err_code === "err_send_yourself") {
        m = lang.err_send_yourself;
      }
      if (res.data.d.err_code === "err_amount_must_greater_than_min_amount") {
        m = lang.err_amount_must_greater_than_min_amount;
      }
      if (res.data.d.err_code === "err_balance_not_enough") {
        m = lang.your_balance_is_not_enough;
      }
      if (res.data.d.err_code === "err_unknow_exception") {
        m = lang.err_unknow_exception;
      }
      if (res.data.d.err_code === "incorrect-password") {
        m = lang.incorrect_password;
      }
      if (res.data.d.err_code === "err_wait_after_24h") {
        m = lang.err_wait_after_24h;
      }
      if (res.data.d.err_code === "err_must_cancel_reserved_package") {
        m = lang.err_must_cancel_reserved_package;
      }
      if (res.data.d.err_code === "err_must_cancel_reserved_package") {
        m = lang.err_invalid_package;
      }
      if (res.data.d.err_code === "invalid-data") {
        m = lang["invalid-data"];
      }
      if (res.data.d.err_code === "err_invest_upgrade_wait_hour_24") {
        m = lang.err_invest_upgrade_wait_hour_24;
      }
    }

    notify.error(m);
  } else {
    notify.error(res.data.m);
  }
};

export const felixNumber = (number, type = "odds", digrit = 3) => {
  let val = parseFloat(number || 0).toFixed(digrit);
  val = parseFloat(val);
  const maxDigrit = 2;
  const arr = `${val}`.split(".");
  if (arr.length === 1) {
    let results = "";
    for (let i = 0; i < maxDigrit; i++) {
      results += results + "0";
    }
    return arr[0] ? arr[0] + "." + results : 0;
  }
  if (arr[1].length < maxDigrit) {
    const count = maxDigrit - arr[1].length;
    for (let i = 0; i < count; i++) {
      arr[1] += arr[1] + "0";
    }
  } else if (arr[1].length > digrit) {
    return felixNumber(arr[0] + "." + arr[1].substr(0, digrit));
  }
  return arr[0] + "." + arr[1].substr(0, digrit);
};

export const checkUserTypeFounder = () => {
  const storeUserType = appStore.state.profile.userType;
  let isFounder = false;
  if (
    storeUserType == USER_TYPE.FOUNDER ||
    storeUserType == USER_TYPE.FOUNDER_POTENTIAL
  ) {
    isFounder = true;
  }
  return isFounder;
};

export const lowercase = (value) => {
  return value?.toString().toLowerCase();
};

export const swapObject = (item, array) => {
  let tmpArr = _.cloneDeep(array);
  let newEl = _.cloneDeep(tmpArr[item.moved.newIndex]);
  // let oldEl = _.cloneDeep(tmpArr[items.moved.oldIndex])
  tmpArr[item.moved.oldIndex] = newEl;
  tmpArr[item.moved.newIndex] = item.moved.element;
  return tmpArr;
};

export const parseDateTime = (date, time) => {
  if (!time) return moment(`${date} 00:00:00`).utc().format();
  let hours = time.split(":")[0];
  let minutes = time.split(":")[1].split(" ")[0];
  let A = time.split(":")[1].split(" ")[1];

  if (parseInt(hours) === 12 && A === "AM") {
    hours = "00";
  } else if (A === "PM") {
    if (parseInt(hours) === 12) {
      hours = hours;
    } else {
      hours = `${12 + parseInt(hours)}`;
    }
  } else {
    hours = hours;
  }
  return moment(`${date} ${hours}:${minutes}:00`).utc().format();
};

export const swapTimetampToObj = (time) => {
  return {
    days: moment(time).format("DD"),
    months: moment(time).format("MM"),
    years: moment(time).format("YYYY"),
    hours: moment(time).format("HH"),
    minutes: moment(time).format("mm"),
    seconds: moment(time).format("ss"),
  };
};

export const parseUtcTimeToTzUtc = (time, format) => {
  if (typeof time !== "object") return time;
  let configTz = config.time_zone_default;

  let convertObjToTime = `${time.years}-${time.months}-${time.days}T${
    time.hours
  }:${time.minutes}:${time.seconds ? time.seconds : "00"}`;
  return moment
    .utc(moment.tz(convertObjToTime, configTz))
    .format(format ? format : "MM-DD-YYYY HH:mm:ss");
};

export const parseUTCTimeToLocal = (time, format) => {
  return moment(moment.utc(time).toDate()).format(format);
};

export const parseTimeToUTC = (time, format) => {
  if (typeof time !== "object") return time;
  return moment
    .utc(
      `${time.years}-${time.months}-${time.days} ${time.hours}:${time.minutes}`
    )
    .format(format ? format : "");
};

export const mapStringToTime = (time) => {
  if (!time) return time;
  let obj = time.split(":");
  return {
    hours: obj[0],
    minutes: obj[1] ? obj[1] : "00",
    seconds: 0,
    full: `${
      obj[0] >= 12
        ? obj[0] - 12 < 10
          ? "0" + (obj[0] - 12)
          : obj[0] - 12
        : obj[0]
    }:${obj[1]} ${obj[0] >= 12 ? "PM" : "AM"}`,
  };
};

export const parseTimeTz = (dateTime) => {
  let localTime = null;
  if (typeof dateTime === "object") {
    localTime = `${dateTime.years}-${dateTime.months}-${dateTime.days} ${dateTime.hours}:${dateTime.minutes}`;
  } else {
    localTime = dateTime;
  }
  return momentTz.tz(localTime, config.time_zone_default).utc().format();
};
const getTimezoneOffset = (atTime, timeZone) => {
  const localizedTime = new Date(atTime.toLocaleString("en-US", { timeZone }));
  const utcTime = new Date(atTime.toLocaleString("en-US", { timeZone: "UTC" }));
  return (localizedTime.getTime() - utcTime.getTime()) / (60 * 60 * 1000);
};
export const convertTZ = (dateTime) => {
  if (dateTime == null) return;
  dateTime = new Date(dateTime);
  const timezoneHour = getTimezoneOffset(new Date(), config.time_zone_default);
  const dateConvert = new Date(
    dateTime.getTime() +
      dateTime.getTimezoneOffset() * 60 * 1000 +
      timezoneHour * 60 * 60 * 1000
  );
  return dateConvert;
};
export const mapTime = (timetamp) => {
  return {
    years: moment
      .tz(moment.utc(timetamp), config.time_zone_default)
      .format("YYYY"),
    months: moment
      .tz(moment.utc(timetamp), config.time_zone_default)
      .format("MM"),
    days: moment
      .tz(moment.utc(timetamp), config.time_zone_default)
      .format("DD"),
    hours: moment
      .tz(moment.utc(timetamp), config.time_zone_default)
      .format("HH"),
    minutes: moment
      .tz(moment.utc(timetamp), config.time_zone_default)
      .format("mm"),
  };
};
export const convertTimeTZ = (dateTime) => {
  const formatDateTime = "MM/DD/YYYY HH:mm";
  const timezoneMX = momentTz(dateTime)
    .tz(config.time_zone_default)
    .format(formatDateTime);
  return {
    hours: moment(timezoneMX).hours() === 0 ? 24 : moment(timezoneMX).hours(),
    minutes: moment(timezoneMX).minutes(),
  };
};
