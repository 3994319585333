import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectField",
  props: {
    type: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    value: { type: [String, Number, Object], default: "" },
    options: { type: Array, default: () => [] },
    labelBy: { type: String, default: "name" },
    valueProp: { type: String, default: "code" },
    title: { type: String, default: "" },
    name: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    tabindex: { type: Number, default: 1 },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    // todo check after fieldRequire
    fieldRequire: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:errorMsg"],
  mounted() {
    if (this.options && this.options.length > 0 && !this._value)
      this.setFirstItemIsActive();
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
    options: {
      deep: true,
      handler(v) {
        if (v.length > 0 && !this._value) this.setFirstItemIsActive();
      },
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  methods: {
    setFirstItemIsActive() {
      this._value = this.options[0][this.valueProp];
    },
  },
});
