<template>
  <div class="fuelPage">
    <!-- <banner-header :bannerInfo="bannerInfo"/> -->
    <div class="container">
      <div class="row">
        <div class="col col-12 py-4" v-html="textContent"></div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped></style>
