<template>
  <div class="wrapper page page1">
    <header class="site-header">
        <Header :showNavbar="showNavbar" />
    </header>
    <div class="site-content">
        <div class="container container-fluid">
            <div class="block-blackstone-page">
                <div class="block-blackstone-page-header__image-container">
                    <img :src="imgHeader" :srcset="`${imgHeader} 2226w, ${imgHeader}?resize=300,169 300w, ${imgHeader}?resize=768,433 768w, ${imgHeader}?resize=1024,577 1024w, ${imgHeader}?resize=1536,866 1536w, ${imgHeader}?resize=2048,1155 2048w, ${imgHeader}?resize=776,438 776w, ${imgHeader}?resize=800,451 800w, ${imgHeader}?resize=1000,564 1000w`" sizes="100vw" alt="" class="blackstone-page-header__image" :style="{'object-position': '50% 50%'}">
                </div>
                <div class="block-blackstone-page-header__inner-container container">
                    <div class="row">
                        <div class="col-12 text-white">
                            <div class="block-blackstone-page-header__title-container">
                                <h1 class="block-blackstone-page-header__title is-style-h1 text-center">Financial Advisors & Family Offices</h1>
                                <p class="block-blackstone-page-header__text text-center">Private Wealth Solutions’ mission is to deliver innovative solutions with exceptional service to advisors and their clients. We are committed to helping more investors unlock differentiated opportunities beyond traditional asset classes.</p>
                            </div>
                        </div>
                        <div class="col-12">
                            <span class="horizontal-line d-block mx-auto"></span>
                            <a href="/static/page1#learn-more" id="learn-more" name="learn-more" class="lean-more d-block w-100 text-center text-white text-uppercase">Learn More</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="block-blackstone-stats" >
                <div class="block-stats">
                    <div class="stats-info text-center">
                        <div class="column">
                            <h2>$239B</h2>
                            <h5>Retail Assets Under Management</h5>
                        </div>
                        <div class="column">
                            <h2>11</h2>
                            <h5>Years Serving Clients</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-blackstone-target">
                <h6>FINANCIAL ADVISORS & FAMILY OFFICES</h6>
                <h2>Through our Private Wealth Solutions business, we provide financial advisors and family offices access to institutional-quality alternative investments for their clients.</h2>
                <div class="block-text">
                    <div class="cover" :style="`background: url(${imgText});background-position: 50% 50%;`">
                        <img :src="imgText" :srcset="`${imgText} 1024w, ${imgText}?resize=300,175 300w, ${imgText}?resize=768,447 768w, ${imgText}?resize=800,466 800w, ${imgText}?resize=1000,582 1000w`" alt="">
                    </div>
                    <div class="text-content">
                        <h4>Access</h4>
                        <p class="text">Through our diversified platform, we offer solutions that help meet a complete range of portfolio construction goals.</p>
                        <hr class="line" />
                        <h4>Education</h4>
                        <p class="text">We work with financial advisors and family offices to provide investment insights, opportunities and educational support, helping them better understand the structure and attributes of private market investing.</p>
                        <hr class="line" />
                        <h4>Partnership</h4>
                        <p class="text">Most of our distinguished professionals have spent their careers working with financial advisors and family offices, giving them direct perspective into these clients’ unique needs.</p>
                    </div>
                </div>
                <div class="note">
                    <p>Note: All figures are as of December 31, 2022, unless otherwise indicated.</p>
                </div>
                <div class="btn-block text-center">
                    <a href="#" class="btn btn-our-site">Visit our site</a>
                </div>
            </div>
        </div>
        <div class="block-blackstone-quote mb-0">
            <div class="cover">
                <img :src="imgClient" alt="">
            </div>
            <div class="text container">
                <div class="row">
                    <div class="space-column col"></div>
                    <div class="text-column col text-white">
                        <p class="quote-text">“We want to be an authoritative resource for information and insight, making Blackstone and alternatives more accessible to a wider audience of investors.”</p>
                        <p class="mb-0"><span class="quote-name">JOAN SOLOTAR</span></p>
                        <p class="mb-0"><span class="quote-jd">GLOBAL HEAD OF PRIVATE WEALTH SOLUTIONS</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="site-footer">
        <Footer />
    </div>
    <button class="scroll-to-top-btn" @click="scrollToTop">
      <svg
        data-name="1-Arrow Up"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <path
          d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import '../style.scss'
import {defineComponent} from 'vue'
import imgHeader from '@/assets/static/page1/PWS-Bridge_Smaller-Size-Copy-edited.jpg'
import imgText from '@/assets/static/page1/colton-duke-unsplash.jpg'
import imgClient from '@/assets/static/page1/our_clients_joan_quote.jpg'

import Header from "@/layouts/header/Header";
import Footer from  "@/layouts/footer/Footer";
export default defineComponent({
    components: {
        Header,
        Footer
    },
    data() {
        return {
            imgHeader,
            imgText,
            imgClient,
            showNavbar: true,
            lastScrollPosition: 0,
            displayMenu: false,
        }
    },
    watch: {
        "$route.path": {
            handler: function(path) {
                if (path === "/") {
                // sticky header
                window.addEventListener("scroll", this.onScroll);
                } else {
                window.removeEventListener("scroll", this.onScroll);
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleScrollBackToTop() {
            let scrollToTopBtn = document.querySelector(".scroll-to-top-btn");
            let rootElement = document.documentElement;
            // Do something on scroll - 0.15 is the percentage the page has to scroll before the button appears
            // This can be changed - experiment
            let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
            if (rootElement.scrollTop / scrollTotal > 0.15) {
                // Show button
                scrollToTopBtn.classList.add("show-btn");
            } else {
                // Hide button
                scrollToTopBtn.classList.remove("show-btn");
            }
        },
        scrollToTop() {
            document.documentElement.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        onScroll() {
            const currentScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition < 0) {
                return;
            }
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return;
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition;
        },
    }
})
</script>