<template>
  <div class="container-v2">
    <div class="line-space"></div>
    <section class="h-section-5">
      <div class="fusion-wrapper">
        <div class="fusion-box f-box-1">
          <div class="text-a-wrapper">
            <p class="number number-5-1">$550MM</p>
          </div>

          <p class="desc">Total AUM</p>
        </div>

        <div class="fusion-box f-box-2">
          <div class="text-a-wrapper">
            <p class="number number-5-2">{{ ">130" }}</p>
          </div>
          <p class="desc global-family">
            Global family offices, ultra-high net-worth individuals and
            institutions as limited partners
          </p>
        </div>

        <div class="fusion-box f-box-3">
          <div class="text-a-wrapper">
            <p class="number number-5-3">80+</p>
          </div>
          <p class="desc">Collective years of experience</p>
        </div>

        <div class="fusion-box f-box-4">
          <div class="text-a-wrapper">
            <p class="number number-5-4">4,500</p>
          </div>
          <p class="desc">Jobs creation across all portfolio companies</p>
        </div>

        <div class="fusion-box f-box-5">
          <div class="text-a-wrapper">
            <p class="number number-5-5">32</p>
          </div>
          <p class="desc" style="max-width: 300px">
            Countries represented amongst our founders
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import anime from "animejs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollOut from "scroll-out";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      controller: null,
      translateYText: 170,
      easing: "cubicBezier(.45,0,.3,1)",
      randomArray: [],
    };
  },
  mounted() {
    this.handleScrollTextReveal();
  },
  methods: {
    handleSliceText() {
      const textWrapper = document.querySelectorAll(".fusion-wrapper .number");
      textWrapper.forEach((el) => {
        el.innerHTML = el.textContent.replace(
          /\S/g,
          "<span class='letter'>$&</span>"
        );
      });
    },

    handleScrollTextReveal() {
      this.handleSliceText();

      var textWrapper = document.querySelectorAll(".fusion-box");
      textWrapper.forEach((sct, index) => {
        ScrollOut({
          targets: `.f-box-${index + 1}`,
          onShown: (el) => {
            el.style.opacity = 1;
            this.animationText1(index);
          },
          onHidden: function (el) {
            el.style.opacity = 0;
          },
        });
      });
    },

    animationText1(index) {
      anime({
        targets: `.fusion-wrapper .number-5-${index + 1} .letter`,
        delay: (el, i) => {
          this.randomArray = [];

          const textLengthObj = {
            0: 6, // $700MM
            1: 4, // >130
            2: 3, // 80+
            3: 5, // 4,500
            4: 2, // 32
          };

          const random =
            this.generateUniqueRandom(textLengthObj[index], "randomArray") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateYText, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line-space {
  width: 100%;
}

.h-section-5 {
  color: #000;
  position: relative;
  z-index: 6;

  max-width: 870px;
  margin: 40px auto;
  height: 100%;

  @media screen and (min-width: 992px) {
    max-width: 888px;
  }

  @media screen and (min-width: 1500px) {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  @media screen and (min-width: 1700px) {
    max-width: 1024px;
  }

  .fusion-wrapper {
    display: grid;
    grid: auto-flow min-content/ 1fr;

    gap: 10px 40px;

    @media screen and (min-width: 992px) {
      grid: auto-flow min-content/ 50% 50%;
    }

    @media screen and (min-width: 1024px) {
      gap: 56px 0;
    }

    @media screen and (min-width: 1700px) {
      gap: 80px 0;
    }

    .fusion-box {
      position: relative;

      @media screen and (min-width: 992px) {
        width: max-content;
      }

      .number {
        font-size: 82px;
        font-weight: 700;
        line-height: 80%;
        padding-bottom: 8px;

        @media screen and (min-width: 576px) {
          font-size: 130px;
          font-weight: 700;
        }

        @media screen and (min-width: 992px) {
          font-size: 170px;
        }

        @media screen and (min-width: 1700px) {
          font-size: 200px;
        }
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        margin: 0;
        padding-top: 10px;

        border-top: 1px solid #000;

        @media screen and (min-width: 1024px) {
          font-size: 30px;
        }
      }
    }

    .f-box-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @media screen and (min-width: 768px) {
        grid-column: 1 / 3;
        text-align: center;
      }
    }

    .f-box-2,
    .f-box-3,
    .f-box-5 {
      @media screen and (min-width: 992px) {
        text-align: right;
        margin-left: auto;
      }
    }
  }
}

.global-family {
  word-break: break-word;
  max-width: unset;

  @media screen and (min-width: 992px) {
    max-width: 410px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 490px;
  }
}
</style>
