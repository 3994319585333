<template>
    <div class="col-lg-2 col-md-4 col-sm-6 p-2 the-card">
        <slot/>
    </div>
</template>

<style lang="scss" scoped>
.the-card {
    padding-left: 0px;
}
.the-card :deep() .styled-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 1px;
    background: white;
    width: 100%;
}

.the-card :deep() .drop-zone {
    width: 100%;
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    transition: 0.3s;
    border: 1px dashed #fff;
    cursor: pointer;
    color: #cccccc;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}
</style>