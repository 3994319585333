<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Add members to fund</h4>
          </div>
          <div class="modal-body custom-modal-body" v-loading="loading">
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Positions</th>
                  </tr>
                </thead>
                <tbody v-if="members.length != 0">
                  <tr v-for="(item, index) in members" :key="index">
                    <td class="noselect">
                      <input
                        type="checkbox"
                        v-model="item.isSelected"
                        :value="true"
                        class="pointer"
                      />
                    </td>
                    <td :class="{ inactive: !item.is_enabled }">
                      {{ item.name }}
                    </td>
                    <td>{{ item.title }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="3">No data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="closeModal">Close</button>
            <button
              class="btn btn-primary"
              @click="onSave"
              :disabled="!havingSelectedItems"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 180px);
}
.inactive {
  color: $color-danger;
}
</style>
