<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      :columns="columns"
      :rows="rows"
      showNoRow
      useDrag
      @orderChanged="orderChanged"
      v-loading="loading"
      :isSizeChange="true"
    >
      <template #row_name="{ props }">
        {{ props.name }}
      </template>
      <template #row_photoName="{ props }">
        <div>Photo: {{ props.photoName }}</div>
        <div>Video: {{ props.videoName }}</div>
        <div>Pdf: {{ props.pdfName }}</div>
      </template>

      <template #row_active="{ props }">
        {{ props.active ? "Displayed" : "Hidden" }}
      </template>

      <template #row_modified_at="{ props }">
        <template v-if="!props.modified_at">
          <div>{{ $filters.parseEstTime(props.created_at) }}</div>
          <div>{{ props.created_by }}</div>
        </template>
        <template v-else>
          <div>{{ $filters.parseEstTime(props.modified_at) }}</div>
          <div>{{ props.modified_by }}</div>
        </template>
      </template>

      <template #row_lp_funds="{ props }">
        <div class="ellipsis-3">
          <template v-if="props.lp_funds.length > 0">
            <div
              v-for="item in props.lp_funds"
              :key="item.lp_fund_id"
              style="word-break: break-word"
            >
              {{ item.name }}
            </div>
          </template>
          <p v-else class="ms-3">-</p>
        </div>
      </template>

      <template #row_actions="{ props }">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle custom-menu-dropdown"
            type="button"
            id="newsletterManagement"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="newsletterManagement"
          >
            <li>
              <span
                class="dropdown-item pointer"
                @click="openDialogToAddEdit(props.id)"
                title="Edit"
              >
                <i class="fas fa-comment-alt me-1"></i>Edit
              </span>
            </li>

            <li v-if="!props.is_send_notification">
              <span
                v-if="props.active"
                class="dropdown-item pointer"
                @click="createNotification(props)"
                title="Edit"
              >
                <i class="fas fa-bell me-1"></i>Send notification
              </span>
            </li>
            <li v-else>
              <span
                class="dropdown-item pointer"
                @click="viewNotification(props)"
                title="Edit"
              >
                <i class="fas fa-eye me-1"></i>View notification
              </span>
            </li>
            <li>
              <span class="dropdown-item pointer" @click="removeItem(props.id)">
                <i class="far fa-trash-alt me-1"></i>Delete
              </span>
            </li>
          </ul>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="openDialogToAddEdit(null)">
          <i class="fas fa-plus me-1"></i>
          <span class="text">Add</span>
        </button>
      </template>
    </Table>

    <Dialog ref="dialog"></Dialog>

    <NotificationModal
      ref="notificationModal"
      :key="notificationModalKey"
      :reload="getItems"
      @refresh="getItems"
    />

    <editCallReplayManagement
      v-if="isShowAddEdit"
      :id="selectedItemId"
      @close="closeAddEdit"
      @completed="onCompleteAddEdit($event)"
    />
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.page-item {
  .page-link {
    border: none;
    color: #fff !important;
    font-weight: 600;
    background: transparent;
    transition: ease all 0.4s;
    border: 1px solid #585d6e;
    width: 35px;
    height: 35px;
  }
  .active {
    background-color: #949ba2 !important;
    color: white;
  }
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-end !important;
  align-items: center;
  margin-top: 10px;
}
</style>
