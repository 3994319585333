<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #row_group_name="{ props }">
        {{ convertName(props.group_name) }}
      </template>
      <template #row_param_value="{ props }">
        <div v-html='convertValue(props)'></div>
      </template>
      <template #row_modified="{ props }">
        {{ $filters.parseEstTime(props.modified_at) }} <br/>
        {{ props.modified_by }}
      </template>
      <template #row_actions="{ props }">
        <button class="btn btn-primary btn-small" @click="updateMapchart(props)">
          <i class="far fa-edit"></i>
        </button>
      </template>
    </Table>
    
    <MapchartModel
      ref="itemModal"
      :key="itemModalKey"
      @refresh="getMapchart"
    />
  </div>
</template>

<script src="./index.js"></script>
