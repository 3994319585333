<template>
  <div
    class="header-page"
    :class="{ scrollUp: !showNavbar }"
    :style="{
      backgroundColor: styleHeader.backgroundColor,
      color: styleHeader.color,
      fill: styleHeader.color,
    }"
  >
    <div class="container-v2 inner">
      <div :class="['hamburger', displayMenu ? 'active' : '']">
        <TheSidenav :show="displayMenu" @toggle="showMenu" />
        <div class="d-flex align-items-center style-black" @click="showMenu">
          <div :class="['hamburger-svg', displayMenu ? 'opened' : '']">
            <svg width="40" height="40" viewBox="0 0 100 100">
              <path
                class="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path class="line line2" d="M 20,50 H 80" />
              <path
                class="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </div>

          <div class="menu">MENU</div>
        </div>
      </div>
      <router-link exact to="/" class="left">
        <div class="img" @click="redirectToHome">
          <svg
            width="195"
            height="40"
            viewBox="0 0 195 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4962 3.12999C14.3422 2.08666 16.189 1.04333 18.0366 0C18.038 9.91058 18.038 19.8212 18.0366 29.7318L0 39.7839V34.3264C4.16568 32.0191 8.33108 29.7113 12.4962 27.4031C12.4949 25.1768 12.4949 22.9504 12.4962 20.7238C9.75784 22.2235 7.02039 23.7234 4.28389 25.2234C4.28255 23.4042 4.28255 21.5859 4.28389 19.7683C7.01958 18.2686 9.75609 16.7688 12.4934 15.2688C12.4985 11.2238 12.4995 7.17759 12.4962 3.12999Z"
              fill="#0095FF"
            />
            <path
              d="M20.9453 0C22.7927 1.04199 24.6394 2.08532 26.4857 3.12999C26.4857 7.17705 26.4857 11.2237 26.4857 15.27C29.2227 16.7681 31.9584 18.2674 34.6928 19.7679C34.6914 21.5871 34.6914 23.4054 34.6928 25.223C31.9571 23.7232 29.2214 22.2239 26.4857 20.725C26.4843 22.9513 26.4843 25.1777 26.4857 27.4043C30.65 29.7144 34.8154 32.0221 38.9819 34.3276C38.9806 36.1468 38.9806 37.9651 38.9819 39.7826C32.9697 36.4333 26.9582 33.0831 20.9473 29.7322L20.9453 0Z"
              fill="#0095FF"
            />
            <path
              d="M50.9883 0.00120384C57.6613 -0.000401282 64.3355 -0.000401282 71.011 0.00120384C71.011 1.12479 71.011 2.2465 71.011 3.36635C65.7806 3.36474 60.5503 3.36474 55.3199 3.36635C55.3167 5.7101 55.3167 8.05438 55.3199 10.3992C59.8458 10.4008 64.3721 10.4008 68.8987 10.3992C68.9003 11.5228 68.9003 12.6456 68.8987 13.7676C64.3729 13.7692 59.8466 13.7692 55.3199 13.7676V24.8136C54.0495 24.8136 52.7802 24.8147 51.512 24.8169C51.2875 24.8249 51.1399 24.6282 50.9891 24.5014C50.9929 16.3362 50.9926 8.16942 50.9883 0.00120384Z"
              fill="black"
            />
            <path
              d="M90.1885 0C91.6309 0 93.0733 0 94.5157 0C94.5189 4.77204 94.52 9.54421 94.5189 14.3165C94.4791 15.6745 94.6381 17.0311 94.9909 18.3438C95.3547 19.4409 96.1366 20.3533 97.1694 20.8859C98.7365 21.7286 100.571 21.8831 102.321 21.8181C103.972 21.7434 105.716 21.4926 107.09 20.5119C108.047 19.8353 108.526 18.7033 108.784 17.6038C109.033 16.4164 109.144 15.2047 109.116 13.9923C109.116 9.32912 109.116 4.66596 109.116 0.00280437C110.556 -0.000405886 111.997 -0.000405886 113.438 0.00280437C113.44 4.77324 113.44 9.54434 113.438 14.3161C113.428 16.3281 113.22 18.3887 112.397 20.2495C111.41 22.4826 109.188 23.9172 106.897 24.5889C104.072 25.3196 101.123 25.4444 98.2456 24.9549C95.7427 24.54 93.1096 23.4649 91.7414 21.223C90.1877 18.6776 90.1546 15.5874 90.1857 12.7018L90.1885 0Z"
              fill="black"
            />
            <path
              d="M134.261 0.00195312C141.401 0.00195312 148.542 0.00195312 155.682 0.00195312V3.36389C149.984 3.36549 144.287 3.36549 138.59 3.36389C138.587 5.67527 138.587 7.98759 138.59 10.3008H154.597C154.597 11.4216 154.597 12.5436 154.597 13.666C149.261 13.6676 143.925 13.6676 138.589 13.666V21.4456C144.509 21.4473 150.431 21.4473 156.352 21.4456C156.354 22.5692 156.354 23.6909 156.352 24.8108C149.161 24.814 141.97 24.8151 134.779 24.814C134.556 24.8236 134.405 24.6254 134.258 24.4986C134.264 16.3352 134.265 8.16964 134.261 0.00195312Z"
              fill="black"
            />
            <path
              d="M175.703 0.00120384C177.144 -0.000401282 178.585 -0.000401282 180.025 0.00120384C180.027 7.15124 180.027 14.3007 180.025 21.4497H194.275C194.275 22.5733 194.275 23.6969 194.275 24.8205C188.084 24.8172 181.893 24.8172 175.703 24.8205C175.703 16.5479 175.703 8.27483 175.703 0.00120384Z"
              fill="black"
            />
            <path
              d="M132.586 31.6824C133.302 31.2037 134.115 30.8878 134.968 30.7573C135.821 30.6268 136.692 30.685 137.52 30.9276C138.095 31.0848 138.622 31.3782 139.057 31.7825C139.493 32.1868 139.822 32.6899 140.018 33.2486C139.565 33.3397 139.115 33.4292 138.662 33.5155C138.415 32.8533 137.926 32.3072 137.292 31.985C136.347 31.6098 135.295 31.5965 134.34 31.9477C133.812 32.1507 133.351 32.4975 133.012 32.9483C132.673 33.3991 132.47 33.9356 132.424 34.4962C132.296 35.6672 132.424 37.0139 133.311 37.8874C133.88 38.3822 134.59 38.6906 135.343 38.7703C136.095 38.8501 136.855 38.6975 137.517 38.3333C138.167 37.932 138.632 37.2919 138.808 36.5524C139.269 36.6483 139.73 36.7442 140.192 36.8453C140.037 37.3943 139.762 37.9027 139.386 38.3345C139.011 38.7662 138.544 39.1107 138.02 39.3437C136.521 39.9415 134.845 39.9316 133.353 39.316C130.505 38.0126 130.072 33.5155 132.586 31.6824Z"
              fill="black"
            />
            <path
              d="M50.2734 30.8606C50.7733 30.8606 51.2715 30.8606 51.7698 30.8606C52.8467 33.4597 54.0417 36.0118 55.0301 38.6455C56.0448 36.0058 57.292 33.4601 58.4085 30.8606C58.879 30.859 59.3494 30.859 59.8199 30.8606C58.4611 33.7851 57.1011 36.7079 55.7398 39.629C55.2661 39.6274 54.7935 39.6274 54.322 39.629C52.9694 36.7077 51.6198 33.7849 50.2734 30.8606Z"
              fill="black"
            />
            <path
              d="M61.872 30.8606C64.3866 30.859 66.9016 30.859 69.4171 30.8606C69.4154 31.2054 69.4154 31.5508 69.4171 31.8967C67.3647 31.8967 65.3127 31.8967 63.2603 31.8967V34.5672C65.1829 34.5704 67.1059 34.5672 69.0284 34.5672C69.0268 34.9137 69.0268 35.2614 69.0284 35.6106C67.1059 35.6106 65.1829 35.6106 63.262 35.6106C63.2603 36.6004 63.2603 37.5919 63.262 38.5852C65.3962 38.5869 67.5304 38.5869 69.6646 38.5852C69.6646 38.9299 69.6613 39.2751 69.6646 39.623C67.068 39.6214 64.471 39.6214 61.8736 39.623C61.8709 36.704 61.8704 33.7832 61.872 30.8606Z"
              fill="black"
            />
            <path
              d="M72.322 30.8599C72.7923 30.8599 73.2614 30.8567 73.7318 30.8535C75.571 33.1408 77.3872 35.4498 79.2196 37.7451C79.2228 35.4484 79.2228 33.1535 79.2196 30.8603C79.6604 30.8603 80.1 30.8603 80.5408 30.8603C80.5424 33.7833 80.5424 36.7055 80.5408 39.6271H79.1213C77.2985 37.3305 75.4757 35.0343 73.6529 32.7387C73.6497 35.0353 73.6529 37.3302 73.6529 39.6271C73.2105 39.6271 72.766 39.6271 72.3236 39.6271C72.3198 36.7053 72.3193 33.7829 72.322 30.8599Z"
              fill="black"
            />
            <path
              d="M82.9141 30.8606C85.6746 30.859 88.4344 30.859 91.1935 30.8606C91.1903 31.2054 91.1903 31.5508 91.1935 31.8967C90.0412 31.8983 88.89 31.8983 87.7399 31.8967C87.7399 34.4748 87.7399 37.0517 87.7399 39.6274H86.3515V31.8979C85.2058 31.8947 84.0602 31.8979 82.9141 31.8979C82.9157 31.5504 82.9157 31.2046 82.9141 30.8606Z"
              fill="black"
            />
            <path
              d="M93.3786 36.1263C93.3705 34.3699 93.3786 32.6147 93.3786 30.8599C93.8391 30.8583 94.2997 30.8583 94.7605 30.8599C94.7621 32.5028 94.7621 34.1465 94.7605 35.7913C94.7819 36.4843 94.7508 37.2487 95.1588 37.8503C95.6243 38.4923 96.4719 38.6994 97.229 38.7222C98.0042 38.7303 98.8567 38.6733 99.4937 38.1837C100.085 37.6793 100.161 36.8386 100.192 36.1163C100.205 34.3615 100.192 32.6079 100.197 30.8547C100.657 30.8531 101.119 30.8531 101.58 30.8547C101.58 32.5 101.58 34.1452 101.58 35.7905C101.574 36.7483 101.504 37.8025 100.867 38.5782C100.097 39.4694 98.8547 39.7507 97.7187 39.7736C96.5762 39.79 95.327 39.6729 94.4074 38.9309C93.595 38.2475 93.4016 37.1267 93.3786 36.1263Z"
              fill="black"
            />
            <path
              d="M104.691 39.6274C104.691 36.7045 104.691 33.7822 104.691 30.8606C106.36 30.8638 108.027 30.8542 109.694 30.8638C110.594 30.8883 111.595 30.9517 112.291 31.5941C112.711 31.9642 112.973 32.4796 113.023 33.0349C113.073 33.5902 112.908 34.1436 112.561 34.5821C111.958 35.2751 111.012 35.5122 110.135 35.6362C110.9 35.9861 111.56 36.529 112.048 37.2109C112.667 38.0134 113.279 38.8228 113.895 39.6278C113.319 39.6278 112.746 39.6278 112.172 39.6374C111.261 38.5443 110.51 37.3148 109.486 36.3192C108.569 35.4589 107.21 35.8084 106.076 35.7354C106.076 37.0335 106.076 38.3312 106.076 39.6278C105.616 39.6262 105.155 39.6262 104.694 39.6278L104.691 39.6274ZM106.078 31.8321C106.078 32.7952 106.078 33.7627 106.078 34.7273C107.17 34.7225 108.264 34.7402 109.359 34.7193C110.032 34.6964 110.796 34.625 111.277 34.0965C111.502 33.8527 111.62 33.5307 111.606 33.2007C111.592 32.8707 111.448 32.5594 111.204 32.3349C110.672 31.9606 110.026 31.7828 109.376 31.8325C108.273 31.8265 107.175 31.8329 106.075 31.8313L106.078 31.8321Z"
              fill="black"
            />
            <path
              d="M116.039 30.8606C118.554 30.859 121.068 30.859 123.583 30.8606C123.581 31.2054 123.582 31.5513 123.586 31.8983C121.532 31.8999 119.479 31.8999 117.427 31.8983C117.426 32.7881 117.426 33.6788 117.427 34.5704C119.35 34.5704 121.273 34.5704 123.195 34.5704C123.192 34.9151 123.195 35.2634 123.195 35.6113C121.273 35.6113 119.35 35.6113 117.427 35.6113C117.426 36.6036 117.426 37.5957 117.427 38.5876C119.562 38.5893 121.696 38.5893 123.83 38.5876C123.828 38.9325 123.828 39.2778 123.83 39.6237C121.233 39.6254 118.636 39.6254 116.039 39.6237C116.039 36.7032 116.039 33.7822 116.039 30.8606Z"
              fill="black"
            />
            <path
              d="M141.547 39.6274C142.888 36.7044 144.229 33.7822 145.571 30.8606C146.065 30.859 146.558 30.859 147.053 30.8606C148.477 33.7835 149.901 36.7063 151.325 39.629C150.795 39.629 150.267 39.629 149.74 39.629C149.333 38.7461 148.933 37.8561 148.523 36.9745C147.064 36.9761 145.605 36.9761 144.145 36.9745C143.765 37.8573 143.388 38.7425 143.013 39.6274C142.524 39.6274 142.037 39.6274 141.549 39.6274H141.547ZM144.554 36.0231C145.732 36.0247 146.912 36.0247 148.093 36.0231C147.486 34.6186 146.801 33.245 146.281 31.8056C145.897 33.2823 145.143 34.6242 144.552 36.0231H144.554Z"
              fill="black"
            />
            <path
              d="M153.204 39.6273C153.203 36.7043 153.203 33.7821 153.204 30.8605C154.871 30.8749 156.538 30.8247 158.204 30.8898C159.141 30.9223 160.156 31.2136 160.733 32.0005C160.942 32.3066 161.081 32.6551 161.138 33.0208C161.195 33.3864 161.17 33.7601 161.064 34.1148C160.957 34.4696 160.773 34.7965 160.524 35.0718C160.275 35.3472 159.967 35.5642 159.623 35.7071C158.012 36.283 156.263 35.9917 154.587 36.0651C154.587 37.2525 154.587 38.4399 154.587 39.6289C154.125 39.6273 153.664 39.6273 153.206 39.6289L153.204 39.6273ZM154.589 31.8998C154.587 32.9407 154.587 33.9816 154.589 35.0226C155.691 35.0081 156.794 35.0583 157.893 34.9965C158.581 34.9491 159.408 34.7051 159.649 33.9864C159.727 33.7838 159.76 33.5668 159.745 33.3505C159.73 33.1341 159.667 32.9236 159.562 32.7336C159.457 32.5435 159.311 32.3785 159.135 32.25C158.959 32.1214 158.757 32.0325 158.543 31.9893C157.234 31.8087 155.904 31.9339 154.587 31.8982L154.589 31.8998Z"
              fill="black"
            />
            <path
              d="M163.995 39.6274C163.991 36.7045 163.991 33.7812 163.995 30.8574C164.453 30.8574 164.914 30.8574 165.376 30.8574C165.376 33.7804 165.376 36.7032 165.376 39.6258C164.915 39.6253 164.454 39.6258 163.995 39.6274Z"
              fill="black"
            />
            <path
              d="M167.82 30.8606C170.577 30.859 173.335 30.859 176.093 30.8606C176.09 31.2053 176.093 31.5504 176.093 31.8967H172.641C172.64 34.4748 172.64 37.0522 172.641 39.629C172.179 39.629 171.72 39.629 171.259 39.629V31.8979C170.114 31.8947 168.968 31.8979 167.822 31.8979L167.82 30.8606Z"
              fill="black"
            />
            <path
              d="M176.07 39.6274C177.409 36.7029 178.751 33.7796 180.096 30.8574H181.573C183.002 33.7785 184.427 36.7013 185.848 39.6258C185.317 39.6258 184.788 39.6258 184.26 39.6258C183.858 38.743 183.451 37.8602 183.05 36.9774C181.588 36.9758 180.126 36.9758 178.664 36.9774C178.287 37.8602 177.912 38.747 177.531 39.6302C177.045 39.6286 176.558 39.6286 176.073 39.6302L176.07 39.6274ZM180.804 31.8052C180.423 33.2836 179.668 34.6239 179.077 36.0227C180.255 36.0243 181.435 36.0243 182.616 36.0227C182.006 34.6178 181.322 33.2434 180.801 31.804L180.804 31.8052Z"
              fill="black"
            />
            <path
              d="M187.647 30.8606C188.108 30.859 188.569 30.859 189.031 30.8606C189.032 33.4371 189.032 36.0135 189.031 38.5897C190.744 38.5913 192.456 38.5913 194.167 38.5897C194.167 38.9344 194.167 39.2775 194.167 39.6258C191.992 39.6274 189.818 39.6274 187.645 39.6258C187.646 36.7039 187.647 33.7822 187.647 30.8606Z"
              fill="black"
            />
          </svg>
        </div>
      </router-link>

      <div class="right">
        <span>{{ titlePage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidenav from "./Navigation/TheSidenav.vue";

export default {
  components: { TheSidenav },
  props: {
    showNavbar: { type: Boolean, default: false },
  },
  data() {
    return {
      displayMenu: false,
      backgroundColor: "#fff",
      color: "#000",
    };
  },
  watch: {
    displayMenu() {
      const body = document.querySelector("body");
      body.classList.toggle("disable-scroll");
    },
  },
  computed: {
    isLogoBlackColor() {
      if (this.isFuelLogoBlack) return true;
      return false;
    },

    styleHeader() {
      return { color: "#000", backgroundColor: "#fff" };
    },
    titlePage() {
      const titleList = {
        Home: "WELCOME TO FUEL",
        Funds: "FUNDS",
        "Our Team": "OUR TEAM",
        "Who We Are": "WHO WE ARE",
        "Fuel The Mind": "FUEL THE MIND",
        "Fueling the future": "Fueling the future",
        Portfolio: "PORTFOLIO",
        Page1: "WELCOME TO FUEL",

      };
      return titleList[this.$route.name] || "";
    },
  },
  methods: {
    redirectToHome() {
      window.scrollTo({ top: 0, behavior: "instant" });
    },
    showMenu() {
      this.displayMenu = !this.displayMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-page {
  font-family: "SF Pro Text";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  overflow: hidden;

  z-index: 998;
  transition: all 0.6s;
  &.scrollUp {
    transform: translateY(-84px);
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 14px;
    padding-bottom: 14px;
  }

  .left {
    display: flex;
    align-items: center;
    z-index: 6;

    .img {
      width: 194px;
      height: 46px;
      fill: inherit;

      margin: 0;
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hamburger {
    height: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (min-width: 576px) {
      min-width: 220px;
    }

    .menu {
      z-index: 1201;
    }

    span {
      background-color: #fff;
      content: "";
      display: block;
      position: relative;
      transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
        transform 0.2s linear;

      &:before,
      &:after {
        background-color: inherit;
        color: inherit;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transform-origin: 50% 50%;
        transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
          transform 0.2s linear;
        width: 26px;
      }

      &:before {
        top: 7px;
      }

      &:after {
        top: -7px;
      }
    }

    &.active {
      span {
        z-index: 1200;
        background-color: #fff;
        transition: background-color 0.2s ease-out;

        &:before,
        &:after {
          background-color: inherit;
          transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
        }

        &:before {
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }

        &:after {
          top: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    }
  }

  .right {
    display: none;

    @media screen and (min-width: 576px) {
      min-width: 220px;
      display: flex;
      justify-content: flex-end;
    }

    span {
      color: inherit;
      font-family: inherit;
      font-size: 18px;
      font-weight: 400;
      padding: 0;

      @media screen and (min-width: 992px) {
        font-size: 22px;
      }
    }
  }
}

.active {
  .style-black {
    stroke: #000 !important;
    .menu {
      color: #000 !important;
    }
  }
}

.style-black {
  stroke: #000;

  .menu {
    font-size: 18px;

    color: #000;
    @media screen and (min-width: 992px) {
      font-size: 22px;
    }
  }
}

.style-white {
  stroke: #fff;
}

.hamburger-svg {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 1201;
  margin-right: 10px;

  .line {
    fill: none;
    stroke: inherit;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 4;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 4;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 4;
  }
}

.opened {
  .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 4;
  }

  .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 4;
  }

  .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 4;
  }
}
</style>
