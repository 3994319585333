import { defineComponent } from 'vue'
import adminServices from "@/services/admin";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import MapchartModel from "./components/MapchartModel";

export default defineComponent({
  name: "mapchart",
  components: {
    Table,
    TextField,
    SelectField,
    MapchartModel,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 100,
        total: 0
      },
      itemModalKey: 0,
      columns: [
        {
          id: "group_name",
          name: "Group Name",
        },
        {
          id: "param_value",
          name: "Value",
        },
        {
          id: "modified",
          name: "Modified",
          columnClass: "multiple-line",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-right",
          columnClass: "text-right action-icon-group",
        },
      ],
    };
  },
  watch: {
    paging(v) {

    }
  },
  mounted() {
    this.getMapchart();
  },
  methods: {
    async getMapchart() {
      this.loading = true;
      const res = await adminServices.findMapchart();
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.filter(x => x.group_name != 'startups' && x.group_name != 'mapchart_enddate');
      }
      this.loading = false;
    },
    convertName(name) {
      if (name == "moic")
        return "MOIC"
      else if (name == "gross_irr")
        return "Gross IRR"
      else if (name == "board_positions")
        return "Board Positions";
      else if (name == "regionchart") {
        return "Region Distribution";
      } else if (name == "stagechart") {
        return "Stage Distribution";
      }
    },
    convertValue(info) {
      if (info.group_name == "moic")
        return (
          JSON.parse(info.param_value)["value"] +
          "<br />" +
          JSON.parse(info.param_value)["text"]
        );
      else if (info.group_name == "gross_irr")
        return (
          JSON.parse(info.param_value)["value"] + "%" +
          "<br />" +
          JSON.parse(info.param_value)["text"]
        );
      else if (info.group_name == "board_positions")
        return JSON.parse(info.param_value)["value"];
      else if (info.group_name == "regionchart" || info.group_name == "stagechart") {
        const params = JSON.parse(info.param_value);
        let valueText = "";
        params.forEach((e) => {
          valueText = valueText + e["category"] + ": " + e["value"] + "<br />";
        });
        return valueText;
      }
    },
    updateMapchart(item){
      this.itemModalKey++
      this.$nextTick(() => {
        this.$refs.itemModal.showUpdate(item)
      })
    },
    updatePaging(v) {
        this.paging = Object.assign(this.paging, v)
    }
  }
});