import VueInstagram from "vue-instagram";
import OtherService from "../../../services/other";
import SocialService from "../../../services/news/social";
import appStore from "../../../store/app";
import { defineComponent } from "vue";
import InstagramIcon from "@/icons/svg/instagram.svg";

export default defineComponent({
  data() {
    return {
      data: {
        igAccessToken: "",
        igProfile: {
          username: "",
          media_count: 0,
        },
        igNextPage: "",
        igItems: [],
      },
      itemsPerPage: 9,
      loading: false,
      nextPageLoadingOffset: 300,
      nextPageLoading: false,
    };
  },
  components: { InstagramIcon },
  created() {
    this.loading = true;
    SocialService.getIgAT().then((resp) => {
      if (resp.data.ok) {
        this.data.igAccessToken = resp.data.d.access_token;
        OtherService.getInstagramProfile(this.data.igAccessToken, (data) => {
          this.data.igProfile.username = data.username;
          this.data.igProfile.media_count = data.media_count;
        });
        OtherService.getInstagramMedias(
          this.data.igAccessToken,
          this.itemsPerPage,
          (data) => {
            this.data.igItems = data.data;
            if (data.paging) {
              this.data.igNextPage = data.paging.next;
            }
          }
        );
      }
      this.loading = false;
    });

    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let aboutToReachBottom =
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) +
          window.innerHeight >=
        document.body.offsetHeight - this.nextPageLoadingOffset;

      if (aboutToReachBottom && this.data.igNextPage && !this.nextPageLoading) {
        this.nextPageLoading = true;
        OtherService.getInstagramMediaByPagingUrl(
          this.data.igNextPage,
          (data) => {
            this.data.igItems = this.data.igItems.concat(data.data);
            if (data.paging) {
              this.data.igNextPage = data.paging.next;
            }
            this.nextPageLoading = false;
          }
        );
      }
    },
  },
  mounted() {},
});
