import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'uploadFolder',
    props: ['parentId', 'hideUploadFolder'],
    data() {
        return {
            loading: false,
            folderGroups: [],
            specialCharacterMsg: 'Name cannot contain characters * : " \\ | < > \/ ?',
            uploadKey: 0
        }
    },
    computed: {
        numberFilesToupload() {
            let allFiles = []
            this.folderGroups.forEach(x => {
                allFiles.push(...x.files)
            })
            let _files = allFiles.filter( x => !x.isUploaded && x.isNameValid && (!x.isDuplicated || (x.isDuplicated && ['UPGRADE'].includes(x.duplicationMode))) )
            return _files.length
        },
        duplicatedFileNames() {
            let allFileNames = []
            this.folderGroups.forEach(x => {
                allFileNames.push(...x.files.map(y => y.file.name))
            })
            return allFileNames.filter((item, index) => allFileNames.indexOf(item) != index)
        }
    },
    methods: {
        async validateForm() {
            if (!this.duplicatedFileNames.length && !!this.numberFilesToupload) {
                this.loading = true
                await this.uploadFolderAndFiles()
                let _countItemsNotUploaded = 0
                for (let i = 0; i < this.folderGroups.length; i++) {
                    if (_countItemsNotUploaded > 0) {
                        break
                    }
                    if (!this.folderGroups[i].isUploaded) {
                        _countItemsNotUploaded++
                        break
                    }
                    for (let j = 0; j < this.folderGroups[i].files.length; j++) {
                        if (!this.folderGroups[i].files[j].isUploaded) {
                            _countItemsNotUploaded++
                            break
                        }
                    }
                }
                if (_countItemsNotUploaded === 0) {
                    this.hideUploadFolder()
                }
                this.loading = false
            }
        },

        async uploadFolderAndFiles() {
            let okToProceed = true
            // create all folders first
            for (let index = 0; index < this.folderGroups.length; index++) {
                if (!this.folderGroups[index].isNameValid) {
                    okToProceed = false
                    break
                }
                if (!this.folderGroups[index].isUploaded && okToProceed) {
                    this.folderGroups[index].isUploading = true
                    this.folderGroups[index].errorMsg = ''
                    let parentId = this.folderGroups[index].parent_path === '' ? this.parentId : _.find(this.folderGroups, x => x.folder_path === this.folderGroups[index].parent_path).id
                    let _folder_resp = await AdminServices.createDataroomFolder({parentId, name: this.folderGroups[index].folder_name, isUploadFolder: true})
                    //validate folder response...
                    if (_folder_resp.data.ok) {
                        this.folderGroups[index].isUploaded = true
                        this.folderGroups[index].id = _folder_resp.data.d
                        this.folderGroups[index].errorMsg = ''
                    } else {
                        if (_folder_resp.data.m) {
                            this.folderGroups[index].errorMsg = _folder_resp.data.m
                        } else {
                            this.folderGroups[index].errorMsg = 'Oops! An error has occurred. Please retry.'
                        }
                        okToProceed = false
                    }
                    this.folderGroups[index].isUploading = false   
                }
            }
            // upload files later
            if (okToProceed) {
                let _awsCred = await AdminServices.getSessionToken()
                AWS.config.update({
                    region: _awsCred.data.d.region,
                    credentials: new AWS.Credentials(_awsCred.data.d.access_key, _awsCred.data.d.secret_key, _awsCred.data.d.session_token)
                })

                for (let index = 0; index < this.folderGroups.length; index++) {
                    for (let idx = 0; idx < this.folderGroups[index].files.length; idx++) {
                        const _fileItem = this.folderGroups[index].files[idx]
                        document.getElementById(_fileItem.key).scrollIntoView()
                        if (!_fileItem.isUploaded && _fileItem.isNameValid && (!_fileItem.isDuplicated || (_fileItem.isDuplicated && ['UPGRADE'].includes(_fileItem.duplicationMode)))) {
                            this.folderGroups[index].files[idx].isUploading = true
                            let lastDot = _fileItem.file.name.lastIndexOf('.')

                            //pre-validate file before uploading to AWS
                            const preValidatedRes = await AdminServices.validateDataroomFile({
                                parentId: this.folderGroups[index].id,
                                duplicationMode: _fileItem.duplicationMode,
                                fileName: _fileItem.file.name.substring(0, lastDot),
                                fileExtension: _fileItem.file.name.substring(lastDot)
                            })
                            
                            if (preValidatedRes.data.ok) {
                                let upload = new S3.ManagedUpload({
                                    params: {
                                        Bucket: _awsCred.data.d.bucket,
                                        Key: this.getRandomKey(12) + _fileItem.file.name.substring(lastDot),
                                        Body: _fileItem.file,
                                    }
                                })
                                upload.on('httpUploadProgress', progress => {
                                    if (progress.total) {
                                        _fileItem.progress = Math.round((100 * progress.loaded) / progress.total)
                                    }
                                })
    
                                let _awsRes = await upload.promise()
    
                                let _file_resp = await AdminServices.createDataroomFileV2({
                                    parentId: this.folderGroups[index].id,
                                    duplicationMode: _fileItem.duplicationMode,
                                    fileName: _fileItem.file.name.substring(0, lastDot),
                                    fileExtension: _fileItem.file.name.substring(lastDot),
                                    fileSize: _fileItem.file.size,
                                    fileS3Url: _awsRes.Key,
                                    isUploadFolder: true
                                })
    
                                //validate file response
                                if (_file_resp.data.ok) {
                                    this.folderGroups[index].files[idx].isUploaded = true
                                    this.folderGroups[index].files[idx].isDuplicated = false
                                    this.folderGroups[index].files[idx].otherErrorMsg = ''
                                } else {
                                    this.handleFileUploadError(index, idx, _file_resp)
                                }
                                this.folderGroups[index].files[idx].isUploading = false
                                this.folderGroups[index].files[idx].progress = 0
                            } else {
                                this.handleFileUploadError(index, idx, preValidatedRes)
                                this.folderGroups[index].files[idx].isUploading = false
                                this.folderGroups[index].files[idx].progress = 0
                            }
                        }
                    }
                }
            }
        },

        handleFileUploadError(folderIdx, fileIdx, res) {
            if (res.data.c === 'file_name_existed') {
                this.folderGroups[folderIdx].files[fileIdx].isDuplicated = true
                this.folderGroups[folderIdx].files[fileIdx].otherErrorMsg = ''
            } else if (res.data.m) {
                this.folderGroups[folderIdx].files[fileIdx].otherErrorMsg = res.data.m
            } else {
                this.folderGroups[folderIdx].files[fileIdx].otherErrorMsg = 'Oops! An error has occurred. Please retry.'
            }
        },

        addFolder() {
            this.$refs.files.click()
        },
        handleUpload() {
            this.loading = true
            let validFiles = []
            for (const _file of this.$refs.files.files) {
                if (_file.name.lastIndexOf('.') > 0) {
                    validFiles.push(_file)
                }
            }
            let uploadedFiles = validFiles
            this.folderGroups = []
            let _folders = []
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                let _pathArray = uploadedFiles[i].webkitRelativePath.split('/')
                _pathArray.pop()
                let _folderPath = ''
                
                for (let j = 0; j < _pathArray.length; j++) {
                    let parent_path = _folderPath
                    _folderPath += (j > 0 ? '/' : '') + _pathArray[j]
                    if (_folders.findIndex(x => x.folder_path === _folderPath) === -1) {
                        _folders.push({
                            folder_name: _pathArray[j],
                            folder_path: _folderPath,
                            parent_path: parent_path,
                            isUploaded: false,
                            isUploading: false,
                            progress: 0,
                            errorMsg: '',
                            files: [],
                            isNameValid: this.validateFileAndDirectoryName(_pathArray[j])
                        })
                    }
                }

                let _index = _folders.findIndex(x => x.folder_path === _folderPath)
                _folders[_index].files.push({
                    file: uploadedFiles[i],
                    key: `${_folderPath.replace(/\//g, '_')}_${uploadedFiles[i].name}`,
                    isDuplicated: false,
                    duplicationMode: '',
                    isUploaded: false,
                    isUploading: false,
                    progress: 0,
                    otherErrorMsg: '',
                    isNameValid: this.validateFileAndDirectoryName(uploadedFiles[i].name)
                })
            }
            _folders = _.orderBy(_folders, ['folder_path'], ['asc'])
            this.folderGroups = _.cloneDeep(_folders)
            this.$refs.files.value = ''
            this.loading = false
        },

        isDuplicatedItem(item) {
            if (this.duplicatedFileNames.includes(item.file.name)) {
                return true
            } else {
                return false
            }
        },

        removeFile(folderIndex, fileIndex) {
            this.folderGroups[folderIndex].files.splice(fileIndex, 1)
            this.$nextTick(() => { this.uploadKey += 1 })
        }
    },
    beforeUnmount() {
        $('.custom-modal .modal-dialog').css({ 'transform': '', top: '' })
    }
    
})