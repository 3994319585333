<template>
    <div class="video">
        <Modal v-model:value="isShow" :isVisible="true" :hasFloatingCloseButton="true" :hideFooter="true" :hideHeader="true" size="2xlg" >
                <video autoplay muted loop playsinline controls width="1600">
	                <source :src="fileVideoUrl" type="video/mp4">
                </video>
        </Modal>
    </div>
</template>

<script>
    import { defineComponent } from "vue";
    import Modal from "@/components/Modal";
    import VueLazyLoadVideo from 'vue-lazyload-video';

    export default defineComponent({
        props: {
            fileVideoUrl: {
                type: String, default: ''
            }
        },
        data() {
            return {
                isShow: false,
                videoUrl: ''
            }
        },
        components: {
            Modal,
            VueLazyLoadVideo
        },
        methods: {
            
        },
        watch: {
            fileVideoUrl(v) {
                if(v && v!=='') {
                    this.videoUrl = v;
                    this.isShow = true;
                }
            },
            isShow(v) {
                if(!v) {
                    this.videoUrl = '';
                }
            }
        }
    })
</script>