<template>
  <transition name="fade" v-if="isShow">
    <div class="popupInfo">
      <div class="popupInfoWrap container">
        <div class="popupInfoControl">
          <button class="btn close" @click="hideModal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 46.899 46.899"
            >
              <g id="c-remove" transform="translate(-1 -1)">
                <g
                  id="Group_36"
                  data-name="Group 36"
                  transform="translate(2 2)"
                >
                  <g id="Group_42" data-name="Group 42">
                    <line
                      id="Line_1"
                      data-name="Line 1"
                      x1="16.145"
                      y2="16.145"
                      transform="translate(14.377 14.377)"
                      fill="none"
                      stroke="#ffffff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></line>
                    <line
                      id="Line_2"
                      data-name="Line 2"
                      x1="16.145"
                      y1="16.145"
                      transform="translate(14.377 14.377)"
                      fill="none"
                      stroke="#ffffff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></line>
                  </g>
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="22.449"
                    cy="22.449"
                    r="22.449"
                    fill="none"
                    stroke="#ffffff"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="2"
                  ></circle>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="popupInfoDetail" v-if="fileUrl">
          <pdf
            ref="myPdfComponent"
            v-if="fileType === FileTypes.PDF"
            :src="fileUrl"
            :scale="scale"
            :text="false"
            :resize="false"
            style="height: 100%; width: 100%; position: relative"
          ></pdf>
          <img
            :src="fileUrl"
            v-if="fileType === FileTypes.IMAGE"
            style="height: 100%; width: 100%; position: relative"
          />
          <video
            ref="video"
            v-if="fileType === FileTypes.VIDEO"
            controls
            autoplay
            controlsList="nodownload"
            :src="fileUrl"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.popupInfo {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: #0d1d29;
  .popupInfoWrap {
    display: block;
    height: 100%;
    max-width: 1140px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .popupInfoControl {
      display: block;
      overflow: hidden;
      padding: 10px 0;
    }
    .popupInfoDetail {
      top: 60px;
      height: calc(100% - 90px);
      text-align: center;
      video{
        width: 100%;
      }
    }
    .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
    }
  }
}
</style>

<script src="./index.js"></script>
