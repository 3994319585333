<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480.402 99.142"
    :width="sizeWidth ? sizeWidth : '100%'"
    :class="classExt"
  >
    <g
      id="Fuel_Venture_Capital-_Blue"
      data-name="Fuel Venture Capital- Blue"
      transform="translate(38.828 -220.752)"
    >
      <g
        id="_0095ffff"
        data-name="#0095ffff"
        transform="translate(-38.828 220.752)"
      >
        <path
          id="Path_35094"
          data-name="Path 35094"
          d="M405.4,92.77q6.847-3.9,13.7-7.8.005,37.046,0,74.092L374.5,184.112v-13.6q15.451-8.625,30.9-17.253-.005-8.322,0-16.645-10.157,5.606-20.307,11.213-.005-6.8,0-13.594,10.147-5.606,20.3-11.213Q405.412,107.9,405.4,92.77Z"
          transform="translate(-374.5 -84.97)"
          fill="#0095ff"
        />
        <path
          id="Path_35095"
          data-name="Path 35095"
          d="M526.09,84.97q6.852,3.895,13.7,7.8,0,15.128,0,30.253,10.152,5.6,20.294,11.209-.005,6.8,0,13.594-10.147-5.606-20.294-11.209-.005,8.322,0,16.645,15.446,8.635,30.9,17.253-.005,6.8,0,13.594-22.3-12.52-44.595-25.046Z"
          transform="translate(-474.3 -84.97)"
          fill="#0095ff"
        />
      </g>
      <g
        id="_000000ff"
        data-name="#000000ff"
        transform="translate(85.49 220.752)"
      >
        <path
          id="Path_35096"
          data-name="Path 35096"
          d="M89.838,419.967q24.751-.006,49.511,0,0,4.2,0,8.386-19.4-.006-38.8,0-.012,8.761,0,17.526,16.787.006,33.577,0,.006,4.2,0,8.394-16.787.006-33.577,0V481.8q-4.712,0-9.416.008c-.555.02-.92-.47-1.293-.786Q89.854,450.5,89.838,419.967Z"
          transform="translate(-88.071 -419.966)"
          fill="#fff"
        />
        <path
          id="Path_35097"
          data-name="Path 35097"
          d="M328.978,419.967q5.35,0,10.7,0,.012,17.838.008,35.677a35,35,0,0,0,1.167,10.036,11.021,11.021,0,0,0,5.387,6.335c3.875,2.1,8.411,2.485,12.739,2.323,4.082-.186,8.394-.811,11.791-3.255,2.367-1.686,3.551-4.507,4.191-7.247a39.693,39.693,0,0,0,.819-9q0-17.431,0-34.862,5.344-.012,10.688,0,.006,17.832,0,35.669c-.024,5.014-.539,10.149-2.574,14.786-2.44,5.565-7.936,9.14-13.6,10.814a50.785,50.785,0,0,1-21.393.912c-6.189-1.034-12.7-3.713-16.083-9.3-3.842-6.343-3.924-14.044-3.847-21.235Z"
          transform="translate(-230.277 -419.966)"
          fill="#fff"
        />
        <path
          id="Path_35098"
          data-name="Path 35098"
          d="M597.838,419.969q26.484,0,52.968,0v8.378q-21.134.006-42.263,0-.012,8.64,0,17.287h39.58c0,2.793,0,5.589,0,8.386q-19.79.006-39.584,0v19.387q21.961.006,43.925,0,.006,4.2,0,8.386-26.672.012-53.345.008c-.551.024-.924-.47-1.289-.786Q597.854,450.5,597.838,419.969Z"
          transform="translate(-390.165 -419.967)"
          fill="#fff"
        />
        <path
          id="Path_35099"
          data-name="Path 35099"
          d="M850.687,419.967q5.344-.006,10.688,0,.006,26.727,0,53.45h35.235q0,4.2,0,8.4-22.964-.012-45.923,0Q850.687,450.894,850.687,419.967Z"
          transform="translate(-540.532 -419.966)"
          fill="#fff"
        />
        <path
          id="Path_35100"
          data-name="Path 35100"
          d="M581.642,611.156a14.483,14.483,0,0,1,12.2-1.881,9.1,9.1,0,0,1,6.177,5.784c-1.119.227-2.233.45-3.352.665a7.039,7.039,0,0,0-3.388-3.814,10.154,10.154,0,0,0-7.3-.093,7.452,7.452,0,0,0-4.738,6.351c-.316,2.918,0,6.274,2.193,8.451a9.094,9.094,0,0,0,10.4,1.111,7.176,7.176,0,0,0,3.194-4.438c1.139.239,2.278.478,3.421.73a9.69,9.69,0,0,1-5.371,6.226,15.247,15.247,0,0,1-11.54-.069C576.495,626.931,575.425,615.724,581.642,611.156Z"
          transform="translate(-378.108 -532.205)"
          fill="#fff"
        />
        <path
          id="Path_35101"
          data-name="Path 35101"
          d="M85.49,609.686c1.236,0,2.468,0,3.7,0,2.663,6.477,5.618,12.837,8.062,19.4,2.509-6.578,5.593-12.922,8.354-19.4q1.745-.006,3.49,0-5.04,10.932-10.089,21.851-1.757-.006-3.506,0Q90.484,620.617,85.49,609.686Z"
          transform="translate(-85.49 -532.785)"
          fill="#fff"
        />
        <path
          id="Path_35102"
          data-name="Path 35102"
          d="M156.247,609.686q9.327-.006,18.657,0-.006,1.289,0,2.582c-5.075,0-10.149,0-15.224,0v6.655c4.754.008,9.509,0,14.263,0q-.006,1.295,0,2.6c-4.754,0-9.509,0-14.259,0q-.006,3.7,0,7.413,7.916.006,15.832,0c0,.859-.008,1.719,0,2.586q-9.631-.006-19.265,0Q156.241,620.611,156.247,609.686Z"
          transform="translate(-127.567 -532.785)"
          fill="#fff"
        />
        <path
          id="Path_35103"
          data-name="Path 35103"
          d="M220,609.666c1.163,0,2.323-.008,3.486-.016,4.548,5.7,9.039,11.454,13.57,17.174q.012-8.585,0-17.157c1.09,0,2.177,0,3.267,0q.006,10.926,0,21.847h-3.51q-6.761-8.585-13.522-17.166c-.008,5.723,0,11.442,0,17.166-1.094,0-2.193,0-3.287,0Q219.99,620.592,220,609.666Z"
          transform="translate(-165.478 -532.766)"
          fill="#fff"
        />
        <path
          id="Path_35104"
          data-name="Path 35104"
          d="M284.62,609.686q10.239-.006,20.473,0-.012,1.289,0,2.582-4.274.006-8.54,0,0,9.637,0,19.265H293.12V612.271c-2.833-.008-5.666,0-8.5,0Q284.626,610.972,284.62,609.686Z"
          transform="translate(-203.907 -532.785)"
          fill="#fff"
        />
        <path
          id="Path_35105"
          data-name="Path 35105"
          d="M348.435,622.792c-.02-4.377,0-8.751,0-13.124q1.708-.006,3.417,0,.006,6.141,0,12.289c.053,1.727-.024,3.632.985,5.131,1.151,1.6,3.247,2.116,5.119,2.173,1.917.02,4.025-.122,5.6-1.342,1.463-1.257,1.65-3.352,1.727-5.152.032-4.373,0-8.743.012-13.112q1.708-.006,3.421,0c0,4.1,0,8.2,0,12.3-.016,2.387-.19,5.014-1.763,6.947-1.905,2.221-4.977,2.922-7.786,2.979-2.825.041-5.914-.251-8.188-2.1C348.97,628.078,348.492,625.285,348.435,622.792Z"
          transform="translate(-241.851 -532.767)"
          fill="#fff"
        />
        <path
          id="Path_35106"
          data-name="Path 35106"
          d="M417.467,631.533q0-10.926,0-21.847c4.126.008,8.248-.016,12.371.008,2.225.061,4.7.219,6.42,1.82a5.453,5.453,0,0,1,.669,7.446c-1.492,1.727-3.83,2.318-6,2.627a11.915,11.915,0,0,1,4.73,3.924c1.532,2,3.044,4.017,4.568,6.023-1.423,0-2.841,0-4.26.024-2.254-2.724-4.11-5.788-6.643-8.269-2.266-2.144-5.626-1.273-8.431-1.455,0,3.235,0,6.469,0,9.7q-1.708-.006-3.417,0m3.421-19.427c0,2.4,0,4.811,0,7.215,2.7-.012,5.407.032,8.115-.02,1.662-.057,3.551-.235,4.742-1.552a3.118,3.118,0,0,0-.182-4.39,6.891,6.891,0,0,0-4.519-1.252C426.324,612.093,423.608,612.109,420.888,612.105Z"
          transform="translate(-282.907 -532.785)"
          fill="#fff"
        />
        <path
          id="Path_35107"
          data-name="Path 35107"
          d="M486.687,609.684q9.327-.006,18.653,0-.006,1.289.008,2.586-7.618.006-15.228,0-.006,3.326,0,6.659c4.754,0,9.509,0,14.263,0-.008.859,0,1.727,0,2.594q-7.132,0-14.263,0-.006,3.709,0,7.417,7.916.006,15.832,0-.006,1.289,0,2.582-9.631.006-19.265,0Q486.687,620.605,486.687,609.684Z"
          transform="translate(-324.071 -532.784)"
          fill="#fff"
        />
        <path
          id="Path_35108"
          data-name="Path 35108"
          d="M642.33,631.531q4.973-10.926,9.951-21.847,1.83-.006,3.664,0,5.283,10.926,10.563,21.851-1.964,0-3.919,0c-1.005-2.2-1.994-4.418-3.008-6.615q-5.411.006-10.826,0c-.94,2.2-1.873,4.406-2.8,6.611-1.208,0-2.412,0-3.62,0m7.43-8.982q4.371.006,8.751,0c-1.5-3.5-3.194-6.923-4.479-10.51C653.087,615.719,651.223,619.063,649.76,622.549Z"
          transform="translate(-416.628 -532.784)"
          fill="#fff"
        />
        <path
          id="Path_35109"
          data-name="Path 35109"
          d="M713.436,631.519q-.006-10.926,0-21.847c4.122.036,8.244-.089,12.362.073,2.318.081,4.827.807,6.254,2.768a6.223,6.223,0,0,1-2.744,9.237c-3.984,1.435-8.309.709-12.452.892,0,2.959,0,5.918,0,8.881q-1.715-.006-3.417,0m3.421-19.261q-.006,3.891,0,7.782c2.724-.036,5.452.089,8.171-.065,1.7-.118,3.745-.726,4.341-2.517a3.723,3.723,0,0,0-2.736-4.977C723.4,612.035,720.111,612.347,716.856,612.258Z"
          transform="translate(-458.911 -532.772)"
          fill="#fff"
        />
        <path
          id="Path_35110"
          data-name="Path 35110"
          d="M779.251,631.535q-.012-10.926,0-21.855,1.7,0,3.417,0,0,10.926,0,21.851Q780.955,631.529,779.251,631.535Z"
          transform="translate(-498.048 -532.784)"
          fill="#fff"
        />
        <path
          id="Path_35111"
          data-name="Path 35111"
          d="M802.59,609.686q10.226-.006,20.457,0c-.008.859,0,1.719,0,2.582h-8.536q-.006,9.637,0,19.269c-1.143,0-2.278,0-3.417,0V612.271c-2.833-.008-5.667,0-8.5,0Z"
          transform="translate(-511.931 -532.785)"
          fill="#fff"
        />
        <path
          id="Path_35112"
          data-name="Path 35112"
          d="M852.93,631.535q4.967-10.932,9.955-21.855h3.652q5.3,10.919,10.571,21.851c-1.313,0-2.622,0-3.928,0-.993-2.2-2-4.4-2.991-6.6q-5.423-.006-10.846,0c-.932,2.2-1.86,4.41-2.8,6.611q-1.806-.006-3.607,0m11.7-19.5c-.944,3.684-2.809,7.024-4.272,10.51q4.371.006,8.751,0C867.607,619.051,865.917,615.626,864.628,612.039Z"
          transform="translate(-541.867 -532.784)"
          fill="#fff"
        />
        <path
          id="Path_35113"
          data-name="Path 35113"
          d="M923.557,609.686q1.708-.006,3.421,0,.006,9.631,0,19.261,6.354.006,12.7,0c0,.859,0,1.714,0,2.582q-8.068.006-16.128,0Q923.557,620.607,923.557,609.686Z"
          transform="translate(-583.866 -532.785)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "logo-component",
  props: {
    classExt: { type: String, default: "" },
    sizeWidth: { type: [Number, String], default: "100%" },
  },
  data() {
    return {};
  },
});
</script>
