<template>
  <transition name="slide">
    <div
      class="popupDetailSurvey"
      v-loading="loading"
      v-if="profile.userType === 'Founder'"
    >
      <template
        v-if="
          info.stt === 'COMPLETED' ||
          info.stt === 'REJECTED' ||
          info.stt === 'REVIEWING' ||
          info.stt === 'APPROVED'
        "
      >
        <div class="fullheight">
          <template v-if="info.stt === 'COMPLETED'">
            <div class="popupHeader">
              <template
                v-if="
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'NOT_STARTED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'IN_PROGRESS') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'COMPLETED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REVIEWING') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REJECTED')
                "
              >
                <router-link class="logo" :to="homePath">
                  <logo :size-width="200" v-if="deviceVersion === 'pc'" />
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 600 600"
                    style="
                      enable-background: new 0 0 600 600;
                      width: 45px;
                      height: 45px;
                    "
                    xml:space="preserve"
                    v-else
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M284.3,294.1c5.1,2.9,8.3,8.8,8.3,14.9l-0.2,75.2c0,6.1-3.3,7.9-8.4,5l-50.3-29L302,463l0,1.3L277.3,450l-72.6-111l0-13.6
                                            l69.9,40.3l0.2-58.3l-92-53.1l-0.4,140.9l-17.8-10.3l0.5-159.7L284.3,294.1z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        d="M431.4,205.4c5.3,3,4.9,6.8-0.3,9.9L389,239.7c-5.1,2.9-8.7,3.4-15.4,1L334,226.5l24.5,23c3.8,3.5,3.8,5.8-1.1,8.6
                                            l-47.2,27.4c-5.2,3-11.8,3.3-17,0.2l-115.8-66.9c-5.1-2.9-4.9-6.8,0.3-9.9l37-21.5l17.8,10.3l-29.5,17.1l96.9,56l34.5-20.1
                                            l-40-41.7l9-5.2l72.2,23l29.8-17.3l-96.6-55.8L282,169.4l-17.8-10.3l34.7-20.2c5.2-3,11.7-3.3,17-0.2L431.4,205.4z"
                      />
                    </g>
                    <g>
                      <polygon
                        fill="#fff"
                        class="st0"
                        points="434.3,233.6 434.3,252.4 337.3,308.4 337.5,360.2 417.4,314 417.4,332.1 337.5,378.2 337.7,449.6 
                                            319.9,459.9 319.5,299.9"
                      />
                    </g>
                  </svg>
                </router-link>
              </template>
              <h4 class="title titleBlack text-center m-0">
                {{ info.title }}
                <button
                  class="btn btn-sub-primary mx-2 px-3 py-2"
                  @click="logout"
                >
                  Logout
                </button>
              </h4>
            </div>
            <div class="popupContent text-center p-3">
              <CompletedIcon class="mb-3" />
              <p class="colorGreen mb-3">
                Thank you for completing the survey.<br />Fuel Venture Capital
                Team will review it soon.
              </p>
              <router-link :to="homePath" class="btn btn-primary"
                >Back to Home</router-link
              >
            </div>
          </template>
          <template v-if="info.stt === 'REJECTED'">
            <div class="popupHeader">
              <template
                v-if="
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'NOT_STARTED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'IN_PROGRESS') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'COMPLETED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REVIEWING') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REJECTED')
                "
              >
                <router-link class="logo" :to="homePath">
                  <logo :size-width="200" v-if="deviceVersion === 'pc'" />
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 600 600"
                    style="
                      enable-background: new 0 0 600 600;
                      width: 45px;
                      height: 45px;
                    "
                    xml:space="preserve"
                    v-else
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M284.3,294.1c5.1,2.9,8.3,8.8,8.3,14.9l-0.2,75.2c0,6.1-3.3,7.9-8.4,5l-50.3-29L302,463l0,1.3L277.3,450l-72.6-111l0-13.6
                                            l69.9,40.3l0.2-58.3l-92-53.1l-0.4,140.9l-17.8-10.3l0.5-159.7L284.3,294.1z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        d="M431.4,205.4c5.3,3,4.9,6.8-0.3,9.9L389,239.7c-5.1,2.9-8.7,3.4-15.4,1L334,226.5l24.5,23c3.8,3.5,3.8,5.8-1.1,8.6
                                            l-47.2,27.4c-5.2,3-11.8,3.3-17,0.2l-115.8-66.9c-5.1-2.9-4.9-6.8,0.3-9.9l37-21.5l17.8,10.3l-29.5,17.1l96.9,56l34.5-20.1
                                            l-40-41.7l9-5.2l72.2,23l29.8-17.3l-96.6-55.8L282,169.4l-17.8-10.3l34.7-20.2c5.2-3,11.7-3.3,17-0.2L431.4,205.4z"
                      />
                    </g>
                    <g>
                      <polygon
                        fill="#fff"
                        class="st0"
                        points="434.3,233.6 434.3,252.4 337.3,308.4 337.5,360.2 417.4,314 417.4,332.1 337.5,378.2 337.7,449.6 
                                            319.9,459.9 319.5,299.9"
                      />
                    </g>
                  </svg>
                </router-link>
              </template>
              <h4 class="title titleBlack text-center m-0">
                {{ info.title }}
                <button
                  class="btn btn-sub-primary mx-2 px-3 py-2"
                  @click="logout"
                >
                  Logout
                </button>
              </h4>
            </div>
            <div class="popupContent text-center p-3">
              <RejectedIcon class="mb-3" />
              <p class="colorRed mb-3">Sorry, your survey has been rejected.</p>
              <router-link :to="homePath" class="btn btn-primary"
                >Back to Home</router-link
              >
            </div>
          </template>
          <template v-if="info.stt === 'REVIEWING' || info.stt === 'APPROVED'">
            <div class="popupHeader">
              <template
                v-if="
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'NOT_STARTED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'IN_PROGRESS') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'COMPLETED') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REVIEWING') ||
                  (profile.userType === 'Founder' &&
                    profile.welcome_survey_stt === 'REJECTED')
                "
              >
                <router-link class="logo" :to="homePath">
                  <logo :size-width="200" v-if="deviceVersion === 'pc'" />
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 600 600"
                    style="
                      enable-background: new 0 0 600 600;
                      width: 45px;
                      height: 45px;
                    "
                    xml:space="preserve"
                    v-else
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M284.3,294.1c5.1,2.9,8.3,8.8,8.3,14.9l-0.2,75.2c0,6.1-3.3,7.9-8.4,5l-50.3-29L302,463l0,1.3L277.3,450l-72.6-111l0-13.6
                                            l69.9,40.3l0.2-58.3l-92-53.1l-0.4,140.9l-17.8-10.3l0.5-159.7L284.3,294.1z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        d="M431.4,205.4c5.3,3,4.9,6.8-0.3,9.9L389,239.7c-5.1,2.9-8.7,3.4-15.4,1L334,226.5l24.5,23c3.8,3.5,3.8,5.8-1.1,8.6
                                            l-47.2,27.4c-5.2,3-11.8,3.3-17,0.2l-115.8-66.9c-5.1-2.9-4.9-6.8,0.3-9.9l37-21.5l17.8,10.3l-29.5,17.1l96.9,56l34.5-20.1
                                            l-40-41.7l9-5.2l72.2,23l29.8-17.3l-96.6-55.8L282,169.4l-17.8-10.3l34.7-20.2c5.2-3,11.7-3.3,17-0.2L431.4,205.4z"
                      />
                    </g>
                    <g>
                      <polygon
                        fill="#fff"
                        class="st0"
                        points="434.3,233.6 434.3,252.4 337.3,308.4 337.5,360.2 417.4,314 417.4,332.1 337.5,378.2 337.7,449.6 
                                            319.9,459.9 319.5,299.9"
                      />
                    </g>
                  </svg>
                </router-link>
              </template>
              <h4 class="title titleBlack text-center m-0">
                {{ info.title }}
                <button
                  class="btn btn-sub-primary mx-2 px-3 py-2"
                  @click="logout"
                >
                  Logout
                </button>
              </h4>
            </div>
            <div class="popupContent text-center p-3">
              <ReviewedIcon class="mb-3" />
              <p class="colorYellow mb-3">Your survey is being reviewed…</p>
              <router-link :to="homePath" class="btn btn-primary"
                >Back to Home</router-link
              >
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="popupHeader">
          <template
            v-if="
              profile.welcome_survey_stt === 'NOT_STARTED' ||
              profile.welcome_survey_stt === 'IN_PROGRESS'
            "
          >
            <router-link class="logo" :to="homePath">
              <logo :size-width="200" v-if="deviceVersion === 'pc'" />
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 600 600"
                :style="`'enable-background': new 0 0 600 600;
                  width: '45px';
                  height: '45px';`"
                xml:space="preserve"
                v-else
              >
                <g>
                  <path
                    fill="#fff"
                    d="M284.3,294.1c5.1,2.9,8.3,8.8,8.3,14.9l-0.2,75.2c0,6.1-3.3,7.9-8.4,5l-50.3-29L302,463l0,1.3L277.3,450l-72.6-111l0-13.6
                                            l69.9,40.3l0.2-58.3l-92-53.1l-0.4,140.9l-17.8-10.3l0.5-159.7L284.3,294.1z"
                  />
                </g>
                <g>
                  <path
                    fill="#fff"
                    d="M431.4,205.4c5.3,3,4.9,6.8-0.3,9.9L389,239.7c-5.1,2.9-8.7,3.4-15.4,1L334,226.5l24.5,23c3.8,3.5,3.8,5.8-1.1,8.6
                                            l-47.2,27.4c-5.2,3-11.8,3.3-17,0.2l-115.8-66.9c-5.1-2.9-4.9-6.8,0.3-9.9l37-21.5l17.8,10.3l-29.5,17.1l96.9,56l34.5-20.1
                                            l-40-41.7l9-5.2l72.2,23l29.8-17.3l-96.6-55.8L282,169.4l-17.8-10.3l34.7-20.2c5.2-3,11.7-3.3,17-0.2L431.4,205.4z"
                  />
                </g>
                <g>
                  <polygon
                    fill="#fff"
                    class="st0"
                    points="434.3,233.6 434.3,252.4 337.3,308.4 337.5,360.2 417.4,314 417.4,332.1 337.5,378.2 337.7,449.6 
                                            319.9,459.9 319.5,299.9"
                  />
                </g>
              </svg>
            </router-link>
          </template>
          <h4 class="title titleBlack text-center m-0">
            {{ info.title }}
            <button class="btn btn-sub-primary mx-2 px-3 py-2" @click="logout">
              Logout
            </button>
          </h4>
        </div>
        <div class="popupContent" ref="popupContent">
          <div class="container pt-3" v-for="item in info.section" :key="item">
            <template v-if="item.id === page.page">
              <h5 class="text-right mb-3" v-if="item.name">{{ item.name }}</h5>
              <div
                class="form-group"
                v-for="(q, idx) in item.questions"
                :key="idx"
              >
                <label class="question-title">{{ `${q.id}. ${q.name}` }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-if="q.type === 'INPUT'"
                  v-model="q.answers[0]"
                  @change="textChange(`${q.type}_${q.id}`, q)"
                  :ref="`${q.type}_${q.id}`"
                />
                <div v-else-if="q.type === 'MULTI'">
                  <div v-for="n in q.details" :key="n" class="multiAnswer">
                    <input
                      type="checkbox"
                      :id="`${q.id}_${n.Id}`"
                      :checked="checkActivebox(n.Id, q.answers)"
                      @change="check(q, n)"
                    />
                    <label
                      class="pointer"
                      :for="`${q.id}_${n.Id}`"
                      :class="{ active: checkActivebox(n.Id, q.answers) }"
                      >{{ n.Answer }}</label
                    >
                  </div>
                </div>
                <div v-else-if="q.type === 'ONE'">
                  <div
                    v-for="n in q.details"
                    :key="n"
                    class="oneAnswer"
                    :ref="q.id"
                    :id="n.Id"
                  >
                    <input
                      type="radio"
                      :name="q.id"
                      :id="`${q.id}_${n.Id}`"
                      :checked="checkActivebox(n.Id, q.answers)"
                      @change="check(q, n, q.id)"
                    />
                    <label
                      class="pointer"
                      :for="`${q.id}_${n.Id}`"
                      :class="{ active: checkActivebox(n.Id, q.answers) }"
                      >{{ n.Answer }}</label
                    >
                  </div>
                </div>
                <div v-else-if="q.type === 'MULTI-INPUT'">
                  <div v-for="n in q.details" :key="n" class="multiAnswer">
                    <template v-if="n.AnswerType === 'LABEL'">
                      <input
                        type="checkbox"
                        :id="`${q.id}_${n.Id}`"
                        :checked="checkActivebox(n.Id, q.answers)"
                        @change="check(q, n)"
                      />
                      <label
                        class="pointer"
                        :for="`${q.id}_${n.Id}`"
                        :class="{ active: checkActivebox(n.Id, q.answers) }"
                        >{{ n.Answer }}</label
                      >
                    </template>
                    <template v-if="n.AnswerType === 'INPUT'">
                      <input
                        type="checkbox"
                        :id="`${q.id}_${n.Id}`"
                        :checked="checkActivebox(n.Id, q.answers)"
                        @change="othercheck(q, n, `${q.id}_${n.Id}`)"
                      />
                      <label
                        class="pointer"
                        :for="`${q.id}_${n.Id}`"
                        :class="{ active: checkActivebox(n.Id, q.answers) }"
                        >Other:</label
                      >
                      <!-- <input type="text" v-model="q.answers[0].Answer" class="form-control other" :class="{'active': checkActivebox(n.Id, q.answers)}" @change="inputChange(`${q.id}_${n.Id}`, q, n)" :ref="`${q.id}_${n.Id}`" /> -->

                      <template v-if="checkInputValue(q.answers, n).status">
                        <input
                          type="text"
                          :value="checkInputValue(q.answers, n).value"
                          class="form-control other"
                          @change="inputChange(`${q.id}_${n.Id}`, q, n)"
                          :ref="`${q.id}_${n.Id}`"
                        />
                      </template>
                      <template v-else>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :ref="`${q.id}_${n.Id}`"
                        />
                      </template>
                    </template>
                  </div>
                </div>
                <div v-else-if="q.type === 'FILE'">
                  <a
                    class="pointer link"
                    @click="downloadFile(q.answers[0].S3Key)"
                    v-if="q.answers.length"
                    >{{ q.answers[0].Name }}</a
                  >
                  <input
                    type="file"
                    @change="updateFile(`${q.type}_${q.id}`, q)"
                    :ref="`${q.type}_${q.id}`"
                    v-else
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- <div class="popupFooter" :style="`width: ${fWidth}px;`"> -->
        <div class="popupFooter" style="width: 100%">
          <div class="finish py-2 mb-2">
            <div class="container">
              <div class="row">
                <div class="col-12 text-center">
                  <template v-if="page.page === page.total">
                    <button
                      class="btn btn-primary pointer mb-2"
                      @click="finishSurvey(detailSurveyId)"
                      v-if="
                        profile.welcome_survey_stt === 'NOT_STARTED' ||
                        profile.welcome_survey_stt === 'IN_PROGRESS'
                      "
                    >
                      Save</button
                    ><br />
                    <router-link :to="homePath"
                      ><img
                        src="../../../assets/images/Logo-Rokk3r.png"
                        alt=""
                        style="max-width: 200px"
                    /></router-link>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="control colorWhite pt-2 pb-2">
            <div class="container">
              <div class="row">
                <div class="col col-3 text-center">
                  <logo
                    :size-width="200"
                    :class-ext="logo - f"
                    v-if="deviceVersion === 'pc'"
                  />
                  <img
                    class="logo-f"
                    src="../../../../src/assets/images/logo-f.png"
                    alt=""
                    v-else
                  />
                </div>
                <div class="col col-6 text-center">
                  <div class="blockProgress">
                    <div class="text text-left">
                      {{ countQuestionInfo.ans }} of
                      {{ countQuestionInfo.total }}
                      <template v-if="deviceVersion !== 'mobile'"
                        >answered</template
                      >
                    </div>
                    <div class="progress">
                      <span
                        :style="`width: ${
                          (countQuestionInfo.ans / countQuestionInfo.total) *
                          100
                        }%;`"
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- <div class="col col-4 text-center" v-if="sectionsStep.sections"> -->
                <div
                  class="col col-3 text-center controlSection"
                  v-if="sectionsStep.sections && sectionComplete"
                >
                  <!-- button next -->
                  <template v-if="sectionsStep.currentStep === 0">
                    <button class="btn btn-default back" disabled>
                      <CtrlLeftIcon />
                    </button>
                  </template>
                  <template v-else>
                    <button class="btn btn-default back" @click="previousStep">
                      <CtrlLeftIcon />
                    </button>
                  </template>

                  <!-- button next -->
                  <template
                    v-if="
                      sectionsStep.sections[sectionsStep.currentStep].number !==
                      sectionsStep.sections[sectionsStep.currentStep].progress
                    "
                  >
                    <button class="btn btn-primary next" disabled></button>
                  </template>
                  <template v-else>
                    <button
                      class="btn btn-primary next"
                      @click="nextStep"
                    ></button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="pagination colorWhite">
                        <div class="container">
                            <pager :loadContentAtPage="loadPage" :page="page.page" :size="page.size" :total="page.total" :pagerFull='false'></pager>
                        </div>
                    </div> -->
        </div>
      </template>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

.btn-sub-primary {
  background-color: transparent;
  color: #000 !important;
  border-color: $color-primary;
  &:hover {
    background-color: $color-primary--shaded;
    border-color: $color-primary;
    color: #000 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem $color-primary--shaded;
  }
  &:not(:disabled):not(.disabled) {
    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #000 !important;
    }
    &:active:focus {
      box-shadow: 0 0 0 0.1rem $color-primary--shaded;
    }
  }
}
.blockProgress {
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  min-width: auto;
  max-width: 100%;
  .text {
    flex: 0 0 125px;
    font-size: 14px;
    @media (max-width: 767.98px) {
      flex: 0 0 60px;
    }
  }
  .progress {
    vertical-align: middle;
    flex: 1;
    height: 5px;
    position: relative;
    background-color: #7b7b7b;
    margin-top: 7px;
    span {
      transition: width 300ms linear;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      z-index: 1;
      background-color: #fff;
      border-radius: 0.25rem;
      height: 100%;
    }
  }
}
.popupDetailSurvey {
  position: static;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
  background-color: $color-background-secondary; //#fff;
  clip-path: inset(0 0 0 0);
  .popupHeader {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 52px;
    background-color: $color-grey; //#1E2128; //#409eff;
    position: relative;
    .logo {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      display: block;
      overflow: hidden;
      //background-color: #fff;
      border-radius: 4px;
      padding: 10px;
      z-index: 2;
    }
    h4 {
      line-height: 52px;
      font-weight: 400;
      position: relative;
      button {
        position: absolute;
        right: 0px;
        top: calc(50% - 21px);
      }
    }
    .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 1;
      color: #fff;
      text-shadow: unset;
      z-index: 2;
    }
    .goback {
      width: 22px;
    }
    @media (max-width: 767.98px) {
      text-align: center;
      .logo {
        position: static;
        transform: unset;
        padding: 0 5px;
        display: inline-block;
        width: 100px;
      }
      h4 {
        line-height: 26px;
        position: static;
      }
    }
  }
  .popupContent {
    display: block;
    .container {
      width: 100%;
      max-width: 610px;
      padding-bottom: 100px;
      color: #c0c4cc;
      h5 {
        border-bottom: 1px solid #a9a9a9;
        line-height: 30px;
      }
      label {
        &.question-title {
          color: #ffffff; //#6b6b6b;
        }
      }
      input {
        color: #949ba2; //#585858;
      }
      .multiAnswer,
      .oneAnswer {
        position: relative;
        input[type="checkbox"],
        input[type="selectbox"] {
          vertical-align: top;
          margin-top: 3px;
        }
        label {
          display: inline-block;
          width: calc(100% - 20px);
          vertical-align: top;
          &.active {
            color: #ffffff; //#001dff;
            font-weight: 600;
          }
        }
        .pre {
          color: #c0c4cc;
        }
      }
    }
  }
  .popupFooter {
    position: absolute;
    bottom: 0;
    // left: 0;
    display: block;
    overflow: hidden;
    // width: 100%;
    .control {
      display: block;
      overflow: hidden;
      width: 100%;
      background-color: #000;
      span {
        line-height: 32px;
        display: inline-block;
        margin-right: 5px;
      }
      button {
        margin-right: 5px;
        padding: 5px 2rem;
      }
      .logo-f {
        height: auto;
        max-height: 22px;
      }
      .controlSection {
        button {
          display: inline-block;
          padding: 0 20px;
          line-height: 22px;
          &.back {
            &::after {
              content: "Back";
            }
            background-image: none;
          }
          &.next {
            &:after {
              content: "Next";
            }
            background-image: none;
          }
          @media (max-width: 1023.98px) {
            padding: 0;
            flex: 1;
          }
        }
        @media (max-width: 1023.98px) {
          display: flex;
        }
        @media (max-width: 767.98px) {
          button {
            display: inline-block;
            padding: 0 20px;
            line-height: 30px;
            &:hover {
              background-color: #000;
            }
          }
          &.next {
            &:before {
              content: "";
              display: inline-block;
              overflow: hidden;
              width: 16px;
              height: 12px;
              background: url("../../../../src/assets/images/ctrl-right.svg")
                no-repeat center center;
            }
          }
        }
      }
    }
  }
  .pagination {
    display: block;
    overflow: hidden;
    width: 100%;
    max-height: 52px;
    background-color: #1e2128; //#3e99ed;
    padding: 5px 0;
  }
  // .finish{
  //     button{
  //         background-color: #3e99ed;
  //         border: 1px solid #3e99ed;
  //     }
  // }
}
</style>
<script src="./index.js"></script>
