<template>
  <section class="section-1">
    <div class="box-1" :class="isShowWrapper ? 'show' : 'hidden'">
      <div class="box-1-inner">
        <div class="logo-brand-wrapper">
          <div class="logo-brand">
            <img src="../../../../assets/images/v2/fvc-logo.png" />
          </div>
        </div>

        <div class="text-right-wrapper">
          <div class="fade-content line-1">
            <div class="text-a-wrapper">
              <p class="txt step-1">Fuel</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-2-wrapper">
      <div class="text-a-wrapper" :class="isShowWrapper ? 'show' : 'hidden'">
        <p class="txt step-2">Venture Capital</p>
      </div>

      <div class="box-2" :class="isShowTextLoop ? 'show' : 'hidden'">
        <div class="bottom-content">
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-1">Founders</p>
          </div>
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-2">Investors</p>
          </div>
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-3">Disruptive Companies</p>
          </div>
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-4">Technological Innovation</p>
          </div>
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-5">Positive Impact</p>
          </div>
          <div class="step-3-wrapper text-a-wrapper">
            <p class="txt step-4 txt-loop-6">A Better Future</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import anime from "animejs";

export default {
  data() {
    return {
      isShowWrapper: false,
      isShowTextLoop: false,
      controller: null,
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [], // text 1 in
      haveIt2: [], // text 2 in

      haveIt3: [], // text 1 out
      haveIt4: [], // text 1 out

      haveIt5: [], // text 3

      translateY3: 30, // text-loop

      timelineText0: null,
      timelineText1: null,
      processText1: 0,
      timelineText2: null,
      processText2: 0,

      processText4: 0,

      init: true,
    };
  },

  watch: {
    screenSize(v) {
      this.onResize();
    },
  },
  computed: {
    screenSize() {
      return this.$store.state.mobile.size;
    },
  },

  mounted() {
    this.controller = new ScrollMagic.Controller();
    this.getSizeText();
    setTimeout(() => {
      this.isShowWrapper = true;
      this.initAnimation();
    }, 700);
  },

  beforeUnmount() {
    this.resetAnimation();
  },

  methods: {
    onResize: _.debounce(function (string) {
      // reset text when resize window
      this.haveIt1 = [];
      this.haveIt2 = [];
      this.haveIt3 = [];
      this.haveIt4 = [];
      this.haveIt5 = [];
      this.isShowTextLoop = false;
      this.resetAnimation();
      this.init = true;

      this.initAnimation();
    }, 500),

    resetAnimation() {
      this.init = false;
      anime.remove(".letter");
      if (this.timelineText0) {
        this.timelineText0.pause();
        this.timelineText0.remove();
        this.timelineText0 = null;
      }
      if (this.timelineText1) {
        this.timelineText1.pause();
        this.timelineText1.remove();
        this.timelineText1 = null;
      }
      if (this.timelineText2) {
        this.timelineText2.pause();
        this.timelineText2.remove();
        this.timelineText2 = null;
      }
    },
    getSizeText() {
      this.translateY3 = 30;
      const width = window.innerWidth;

      if (width >= 576 && width < 768) {
        this.translateY3 = 46;
        return;
      }
      if (width >= 768 && width < 992) {
        this.translateY3 = 52;
        return;
      }
      if (width >= 992 && width < 1200) {
        this.translateY3 = 67;
        return;
      }

      if (width >= 1200 && width < 1500) {
        this.translateY3 = 84;
        return;
      }

      if (width >= 1500 && width < 1800) {
        this.translateY3 = 105;
        return;
      }

      if (width >= 1800) {
        this.translateY3 = 120;
      }
    },

    initAnimation() {
      // time offsets: https://animejs.com/documentation/#timelineOffsets
      const logoStart = 200;
      const textStart1 = 1200;
      const textStart2 = "-=1200";
      const textStart2Out = "+=1000";
      const textStart3 = "-=1300";

      // slice word
      const textWrapper = document.querySelectorAll(".txt");
      textWrapper.forEach((el) => {
        el.innerHTML = el.textContent.replace(
          /\S/g,
          "<span class='letter'>$&</span>"
        );
      });

      this.timelineText0 = anime
        .timeline({
          update: (anim) => {
            if (!this.init) {
              this.resetAnimation();
              return;
            }
            const progress = parseInt(anim.progress);
            const startTime = 76;
            if (progress >= startTime && this.processText4 !== startTime) {
              this.processText4 = startTime;
              this.loopTextAnimation1();
            }
            if (progress === 100) {
              this.processText4 = 0;
            }
          },
        })
        // logo in
        .add(
          {
            targets: ".logo-brand",
            easing: this.easing,
            duration: 1500,
            translateY: () => {
              const el = document.querySelector(".logo-brand");
              const translateY = el.offsetHeight || 296;
              return [translateY, 0];
            },
          },
          logoStart
        )
        // text 1 in
        .add(
          {
            targets: ".step-1 .letter",
            delay: (el, i) => {
              const random = this.generateUniqueRandom(4, "haveIt1") || 1;
              return 50 + 50 * random;
            },
            duration: 1200,
            easing: this.easing,
            translateY: () => {
              const el = document.querySelector(".step-1");
              const translateY = el.offsetHeight ? el.offsetHeight + 10 : 208;
              return [translateY, 0];
            },
          },
          textStart1
        )
        // text 2 in
        .add(
          {
            targets: ".step-2 .letter",
            delay: (el, i) => {
              const random = this.generateUniqueRandom(14, "haveIt2") || 1;
              return 50 + 50 * random;
            },
            duration: 900,
            easing: this.easing,
            translateY: () => {
              const el = document.querySelector(".step-2");
              const translateY = el.offsetHeight || 2;
              return [translateY, 0];
            },
          },
          textStart2
        )
        // text 2 out
        .add(
          {
            targets: ".step-2 .letter",
            delay: (el, i) => {
              const random = this.generateUniqueRandom(14, "haveIt4") || 1;
              return 50 + 50 * random;
            },
            duration: 900,
            easing: this.easing,
            translateY: () => {
              const el = document.querySelector(".step-2");
              const translateY = el.offsetHeight || 208;
              return [0, -Math.abs(translateY)];
            },
          },
          textStart2Out
        )
        // text 3 in
        .add(
          {
            targets: ".step-3 .letter",
            delay: (el, i) => {
              const random = this.generateUniqueRandom(9, "haveIt5") || 1;
              return 50 + 50 * random;
            },
            duration: 1200,
            easing: this.easing,
            translateY: () => {
              const el = document.querySelector(".step-3");
              const translateY = el.offsetHeight || 208;
              return [translateY, 0];
            },
          },
          textStart3
        );
    },

    getTimelineText4() {
      const tl1 = this.addTimelineText(".txt-loop-1", "in");
      const tl2 = this.addTimelineText(".txt-loop-1", "out");
      const tl3 = this.addTimelineText(".txt-loop-2", "in");
      const tl4 = this.addTimelineText(".txt-loop-2", "out");
      const tl5 = this.addTimelineText(".txt-loop-3", "in");
      const tl6 = this.addTimelineText(".txt-loop-3", "out");
      const tl7 = this.addTimelineText(".txt-loop-4", "in");
      const tl8 = this.addTimelineText(".txt-loop-4", "out");
      const tl9 = this.addTimelineText(".txt-loop-5", "in");
      const tl10 = this.addTimelineText(".txt-loop-5", "out");
      const tl11 = this.addTimelineText(".txt-loop-6", "in");
      const tl12 = this.addTimelineText(".txt-loop-6", "out");

      return { tl1, tl2, tl3, tl4, tl5, tl6, tl7, tl8, tl9, tl10, tl11, tl12 };
    },

    addTimelineText(elName, action) {
      const tl = {
        targets: `${elName} .letter`,
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(12, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: () => {
          const el = document.querySelector(elName);
          const translateY = el.offsetHeight || this.translateY3;

          if (action === "in") {
            return [translateY, 0];
          } else {
            return [0, -Math.abs(translateY)];
          }
        },
      };
      return tl;
    },

    loopTextAnimation1() {
      this.isShowTextLoop = true;
      const step = "-=1400";
      const delay = "+=700";

      const { tl1, tl2, tl3, tl4, tl5, tl6, tl7, tl8, tl9, tl10, tl11, tl12 } =
        this.getTimelineText4();

      this.timelineText1 = anime
        .timeline({
          easing: this.easing, // Can be inherited
          loop: false, // Is not inherited
          update: (anim) => {
            if (!this.init) {
              this.resetAnimation();
              return;
            }
            const progress = parseInt(anim.progress);
            const startTime = 92;
            if (progress >= startTime && this.processText1 !== startTime) {
              this.haveIt1 = [];
              this.loopTextAnimation2();
              this.processText1 = startTime;
            }
            if (progress === 100) {
              this.processText1 = 0;
            }
          },
        })
        // text 1
        .add(tl1)
        .add(tl2, delay)
        // text 2
        .add(tl3, step)
        .add(tl4, delay)
        // text 3
        .add(tl5, step)
        .add(tl6, delay)
        // text 4
        .add(tl7, step)
        .add(tl8, delay)
        // text 5
        .add(tl9, step)
        .add(tl10, delay)
        // text 6
        .add(tl11, step)
        .add(tl12, delay);
    },

    loopTextAnimation2() {
      this.isShowTextLoop = true;
      const step = "-=1400";
      const delay = "+=700";

      const { tl1, tl2, tl3, tl4, tl5, tl6, tl7, tl8, tl9, tl10, tl11, tl12 } =
        this.getTimelineText4();

      this.timelineText2 = anime
        .timeline({
          easing: this.easing, // Can be inherited
          loop: false, // Is not inherited
          update: (anim) => {
            if (!this.init) {
              this.resetAnimation();
              return;
            }
            const progress = parseInt(anim.progress);
            const startTime = 92;
            if (progress >= startTime && this.processText2 !== startTime) {
              this.haveIt1 = [];
              this.loopTextAnimation1();
              this.processText2 = startTime;
            }
            if (progress === 100) {
              this.processText2 = 0;
            }
          },
        })
        // text 1
        .add(tl1)
        .add(tl2, delay)
        // text 2
        .add(tl3, step)
        .add(tl4, delay)
        // text 3
        .add(tl5, step)
        .add(tl6, delay)
        // text 4
        .add(tl7, step)
        .add(tl8, delay)
        // text 5
        .add(tl9, step)
        .add(tl10, delay)
        // text 6
        .add(tl11, step)
        .add(tl12, delay);
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show {
  opacity: 1;
  visibility: visible;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.section-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 74px);
  padding-bottom: 60px;

  user-select: none;
}

.logo-brand-wrapper {
  overflow: hidden;
  .logo-brand {
    flex-shrink: 0;
    width: 80px;
    margin-right: 12px;

    @media (min-width: 576px) {
      width: 120px;
    }

    @media (min-width: 768px) {
      margin-right: 28px;
      width: 140px;
    }

    @media (min-width: 992px) {
      width: 180px;
    }

    @media (min-width: 1200px) {
      width: 210px;
    }

    @media (min-width: 1500px) {
      width: 290px;
    }
  }
}

.box-1 {
  position: relative;
  z-index: 6;
  margin-bottom: 5px;

  @media (min-width: 576px) {
    margin-bottom: 20px;
  }

  .box-1-inner {
    display: flex;
  }

  .text-right-wrapper {
    position: relative;
    width: 268px;
    max-width: 944px;
    text-transform: uppercase;
    overflow: hidden;

    display: flex;
    align-items: center;

    @media (min-width: 576px) {
      width: 412px;
    }

    @media (min-width: 768px) {
      width: 468px;
    }

    @media (min-width: 992px) {
      width: 603px;
    }

    @media (min-width: 1200px) {
      width: 710px;
    }

    @media (min-width: 1500px) {
      width: 944px;
    }

    .step-1,
    .step-2,
    .step-3,
    .step-4 {
      white-space: nowrap;
    }

    .step-1 {
      font-size: 72px;
      font-weight: 400;
      letter-spacing: 29px;
      line-height: 74%;
      margin-left: -2%;

      @media (min-width: 576px) {
        font-size: 107px;
        font-weight: 400;
        letter-spacing: 50px;
      }

      @media (min-width: 768px) {
        font-size: 123px;
        font-weight: 400;
        letter-spacing: 59px;
      }

      @media (min-width: 992px) {
        font-size: 156px;
        font-weight: 400;
        letter-spacing: 78px;
      }

      @media (min-width: 1200px) {
        font-size: 230px;
        font-weight: 400;
        letter-spacing: 62px;
        /* font-size: 184px;
        font-weight: 400;
        letter-spacing: 92px; */
      }

      @media (min-width: 1500px) {
        font-size: 292px;
        font-weight: 400;
        letter-spacing: 100px;
      }
    }
  }
}

.box-2-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
}

.box-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .bottom-content {
    position: relative;
    width: 100%;
    z-index: 6;

    .step-3-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      text-align: center;
    }
  }
}

.box-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  z-index: 6;

  @media (min-width: 768px) {
    gap: 30px;
  }
}

.step-2 {
  text-align: center;
}

.step-2,
.step-3,
.step-4 {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0.04em;

  font-weight: bold;
  margin: 0;
  white-space: nowrap;

  @media (min-width: 576px) {
    font-size: 36px;
    line-height: 33px;
    height: 33px;
    letter-spacing: 0.03em;
  }

  @media (min-width: 768px) {
    font-size: 49px;
    line-height: 44px;
    height: 44px;
    letter-spacing: 0.03em;
  }

  @media (min-width: 992px) {
    font-size: 63px;
    line-height: 56px;
    height: 56px;
    letter-spacing: 0.03em;
  }

  @media (min-width: 1200px) {
    font-size: 78px;
    line-height: 68px;
    height: 68px;
    letter-spacing: 0;
  }
  

  @media (min-width: 1300px) {
    font-size: 85px;
    line-height: 75px;
    height: 75px;
    letter-spacing: 0;
  }

  @media (min-width: 1400px) {
    font-size: 90px;
    line-height: 80px;
    height: 80px;
    letter-spacing: 0;
  }

  @media (min-width: 1520px) {
    font-size: 98px;
    line-height: 85px;
    height: 85px;

    letter-spacing: 0.03em;
  }

  @media (min-width: 1650px) {
    font-size: 110px;
    line-height: 100px;
    height: 100px;
  }
}

.step-4 {
  color: #0095ff;
}
</style>
