<template>
  <div class="home-page page-v2">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <template v-if="listMeetOurFounder.length > 0">
      <Section9 :listMeetOurFounder="listMeetOurFounder" />
    </template>
    <Section5 />
    <!-- <Section6 /> -->
    <!-- <Section7 /> -->
    <Section10 />
  </div>
</template>

<script>
import Section1 from "./comps/Section1.vue";
import Section2 from "./comps/Section2.vue";
import Section3 from "./comps/Section3.vue";
import Section4 from "./comps/Section4.vue";
import Section5 from "./comps/Section5.vue";
import Section6 from "./comps/Section6.vue";
import Section9 from "./comps/Section9.vue";
import Section10 from "./comps/Section10.vue";
import HomeService from "@/services/home";

export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section9,
    Section10,
  },

  data() {
    return {
      listMeetOurFounder: [], // Meet Our Founders and CEOs
    };
  },

  async created() {
    const result = await HomeService.getMeetOurFounders();
    if (!result.data.ok) return;

    // validate url
    const convertData = result.data.d.map((v) => {
      if (v.website.indexOf("https") === -1) {
        v.website = `https://${v.website}`;
      }

      const founder_info = v.founder_info.map((x) => {
        if (x.founderLinkedin.indexOf("https") === -1) {
          x.founderLinkedin = x.founderLinkedin
            ? `https://${x.founderLinkedin}`
            : "";
        }
        if (x.founderTwitter.indexOf("https") === -1) {
          x.founderTwitter = x.founderTwitter
            ? `https://${x.founderTwitter}`
            : "";
        }
        return { ...x };
      });
      return { ...v, founder_info };
    });

    this.listMeetOurFounder = result.data.d || [];
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  color: #000;
  width: 100%;
  overflow: hidden;
}

#scroll-container {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.canvas-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #ff4b4b;
}
</style>
