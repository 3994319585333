<template>
    <div class="signNDA">
        <div class="container">
            <div class="submitted" v-if="status == 'signed'">
                <div class="signed">
                    <h3 class="text-center mb-3">You have already checked-in</h3>
                </div>
            </div>
            <div class="submitted" v-else-if="status == 'token_expired'">
                <div class="signed">
                    <h3 class="text-center mb-3">Token Expired</h3>
                </div>
            </div>
            <div class="submitted" v-else-if="status == 'invalid_token'">
                <div class="signed">
                    <h3 class="text-center mb-3">Invalid Token</h3>
                </div>
            </div>
            <div class="ndaform py-3" v-loading="formLoading" v-else>
                <div class="page mb-5">
                    <div class="wrap">
                        <h2 class="text-center mb-3">Hi, {{username}}</h2>
                        <div class="form mb-3">
                            <form>
                                <div class="form-group">
                                    <label for="firstname">First Name</label>
                                    <input type="text" id="firstname" class="form-control" v-model="formData.firstname" />
                                    <small class="colorRed" v-if="errorMsg.firstname">{{errorMsg.firstname}}</small>
                                </div>

                                <div class="form-group">
                                    <label for="lastname">Last Name</label>
                                    <input type="text" id="lastname" class="form-control" v-model="formData.lastname" />
                                    <small class="colorRed" v-if="errorMsg.lastname">{{errorMsg.lastname}}</small>
                                </div>

                                <div class="form-group">
                                    <label for="phone">Phone Number</label>
                                    <input type="tel" id="phone" class="form-control" v-model="formData.phone" @keypress="validKey($event)" />
                                    <small class="colorRed" v-if="errorMsg.phone">{{errorMsg.phone}}</small>
                                </div>

                                <div class="form-group">
                                    <label for="company">Company Name</label>
                                    <input type="text" id="company" class="form-control" v-model="formData.company" />
                                    <small class="colorRed" v-if="errorMsg.company">{{errorMsg.company}}</small>
                                </div>

                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <input type="text" id="title" class="form-control" v-model="formData.title" />
                                    <small class="colorRed" v-if="errorMsg.title">{{errorMsg.title}}</small>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="page mb-5">
                    <div class="wrap">
                        <termconditions />
                    </div>
                </div>
                <div class="page">
                    <div class="wrap">
                        <signature :sourceFileData="sourceFileData" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.signNDA{
    font-family: 'Raleway';
    display: block;
    overflow: hidden;
    width: 100%;
    background: url('../../assets/images/signNDA/wallpaper.png') no-repeat center center #000;
    overflow-y: auto;
    .container{
        width: 100% !important;
        max-width: 960px !important;
        height: 100vh;
    }
    .submitted{
        color: #fff;
        font-weight: 300;
        .signed{
            display: block;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;
        }
        h2{
            font-size: 40px;
            font-weight: 300;
        }
        h3{
            font-size: 32px;
            font-weight: 300;
        }
    }
    .ndaform{
        color: #fff;
        font-weight: 300;
        .page{
            display: block;
            padding-top: 20px;
            .wrap{
                position: relative;
                display: block;
            }
        }
        h2{
            font-size: 40px;
            font-weight: 300;
        }
        .form{
            form{
                input{
                    background-color: rgba(255, 255, 255, 0.15) !important;
                    color: #fff;
                    font-weight: 300;
                }
            }
        }
    }
}
</style>