<template>
  <div class="adminContent" v-loading="loading">
    <Modal
      :title="state === 'CREATE' ? 'Create Perspective' : 'Edit Perspective'"
      v-model:value="isShow"
      :loading="loading"
    >
      <form @submit.prevent="save" autocomplete="false">
        <div class="row">
          <div class="col col-12">
            <Switcher
              v-model:value="formData.isActive"
              title="Active"
              name="active"
              :isHorizontal="true"
            />
          </div>

          <div class="col col-12 col-md-12">
            <SelectField
              v-model:value="formData.memberId"
              title="Member"
              :options="memberList"
              :fieldRequire="true"
              :errorMsg="errorMsg.memberId"
            />
          </div>

          <div class="col col-12 col-md-12">
            <TextField
              v-model:value="formData.title"
              title="Title"
              :autocomplete="false"
              :errorMsg="errorMsg.title"
              :fieldRequire="true"
              placeholder="Enter title"
            />
          </div>
          <div class="col col-12 col-md-12">
            <TextArea
              v-model:value="formData.description"
              title="Description"
              :autocomplete="false"
              :errorMsg="errorMsg.description"
              :fieldRequire="true"
              placeholder="Enter description"
            />
          </div>

          <div class="col-12 col-md-6">
            <FileField2
              v-model:value="itemImage"
              title="Thumbnail"
              :accept="`image/jpeg, image/png, image/jpg`"
              :init-preview-url="formData.thumbnail['s3_key']"
              class="uploadfile"
              :fieldRequire="true"
              :errorMsg="errorMsg.thumbnail"
              @changeValue="changeFile"
            />
          </div>

          <div class="col-12 col-md-6">
            <FileField2
              v-model:value="itemPdf"
              title="Pdf file"
              :accept="`application/pdf`"
              :init-preview-url="formData.pdfFile['s3_key']"
              class="uploadfile"
              :fieldRequire="true"
              :errorMsg="errorMsg.pdfFile"
              :mimeType="true"
              :classWrapper="`wrapper-text`"
              :fileName="formData.pdfFile['name']"
              :uploadBtnText="`Select pdf`"
              @changeValue="changeFile"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <NewButton @click="save()"> Save </NewButton>
      </template>
    </Modal>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.adminContent {
  position: initial;
}
</style>
