import { PORTFOLIO_ICONS } from "@/misc/constants";
import { FileTypes } from "@/components/commons/filePreview";
import { defineComponent } from "vue";
import TextField from "@/components/TextField";

export default defineComponent({
  name: "popupAddEditCompany",
  props: {
    hideAddCompany: { type: Function, default: () => {} },
    confirmAddCompany: { type: Function, default: () => {} },
    infoCompanyEdit: { type: Object, default: {} },
    confirmUpdateCompany: { type: Function, default: () => {} },
    type: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    showFilePreview: { type: Function, default: () => {} },
    hideFilePreview: { type: Function, default: () => {} },
  },
  components: { TextField },
  data() {
    return {
      formError: false,
      error: {
        name: "",
        fueled: "",
        photo: false,
        file: false,
        invalidImage: false,
        invalidVideo: false,
      },
      dataPhoto: "",
      fileUploaded: null,
      fileToUpload: null,
      videoUploaded: null,
      videoToUpload: null,
      backgroundUploaded: null,
      backgroundToUpload: null,
      managed_by_idc: null,
      formData: {
        managed_by: [],
        name: "",
        fueled: "",
        photo: "",
        file: "",
        video: "",
        background: "",
        ext: {
          Website: "",
          Facebook: "",
          Instagram: "",
          Linkedin: "",
          Medium: "",
          Twitter: "",
          PrimaryColor: "",
        },
        corner_label: "",
        icon: null,
        is_active: true,
      },
      typeName: "",
      portfolioIcons: PORTFOLIO_ICONS,
      fileTypes: FileTypes,
    };
  },
  mounted() {
    this.typeName = "Add";
    if (_.size(this.infoCompanyEdit) > 0) {
      this.formData = this.infoCompanyEdit;
      this.dataPhoto = this.infoCompanyEdit.photo;
      this.fileUploaded = this.getFilename(this.infoCompanyEdit.file);
      this.videoUploaded = this.getFilename(this.infoCompanyEdit.video);
      this.backgroundUploaded = this.getFilename(
        this.infoCompanyEdit.background
      );
      this.typeName = "Edit";
    }
    if (
      this.infoCompanyEdit &&
      this.infoCompanyEdit.managed_by &&
      this.infoCompanyEdit.managed_by.includes("IDC")
    ) {
      this.managed_by_idc = true;
    } else {
      this.managed_by_idc = false;
    }
    window.addEventListener("resize", this.onResize);
    this.onResize();

    var inputBox = document.getElementById("fuel");
    var invalidChars = ["-", "+", "e", "E"];
    inputBox?.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox?.addEventListener("input", function () {
      this.value = this.value.replace(/[e\+\-]/gi, "");
    });
  },
  watch: {
    managed_by_idc: function (v) {
      if (v === true) {
        this.formData.managed_by = ["Rokker", "IDC"];
      } else {
        this.formData.managed_by = ["Rokker"];
      }
    },
    "formData.name": function (v) {
      if (v && v.trim().length > 0) this.error.name = "";
    },
    "formData.fueled": function (v) {
      if (v && v > 0) this.error.fueled = "";
    },
    "formData.photo": function (v) {
      if (_.size(v) > 0) this.error.photo = false;
    },
  },
  methods: {
    getFilename(str) {
      return str ? str.split("/").slice(-1)[0] : "";
    },
    validateForm() {
      this.formError = false;

      this.error.name = false;
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.error.name = "Please input name of company";
        this.formError = true;
      }

      this.error.fueled = false;
      if (!this.formData.fueled || this.formData.fueled < 1) {
        this.error.fueled = "Please input fueled year";
        this.formError = true;
      }

      // this.error.file = false
      // if (!this.formData.file) {
      //     this.error.file = true
      //     this.formError = true
      // }

      this.error.size = false;
      this.error.photo = false;
      if (!this.formData.photo) {
        this.error.photo = true;
        this.formError = true;
      }

      if (this.formError) {
        return;
      }

      let formData = new FormData();
      formData.append("name", this.formData.name);
      formData.append("fueled", this.formData.fueled);
      formData.append("contentFile", this.formData.file);
      formData.append("contentVideo", this.formData.video);
      formData.append("photo", this.formData.photo);
      formData.append("background", this.formData.background);
      formData.append("extraInfo", JSON.stringify(this.formData.ext));
      formData.append("managedBy", JSON.stringify(this.formData.managed_by));
      formData.append("cornerLabel", this.formData.corner_label);
      formData.append("icon", this.formData.icon ? this.formData.icon : "");
      formData.append("isActive", this.formData.is_active);

      if (this.typeName != "Edit") {
        this.confirmAddCompany(formData);
      } else {
        formData.append("id", this.formData.id);
        this.confirmUpdateCompany(formData);
      }
    },
    renderFileImg() {
      let ExtAllow = [
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      let img = this.$refs.fileimg.files[0];
      this.error.invalidImage = false;
      if (!img) {
        return;
      }

      if (!ExtAllow.includes(img.type)) {
        this.error.invalidImage = true;
        return;
      }

      let reader = new FileReader();

      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.dataPhoto = e.target.result;
        if (this.dataPhoto) {
          this.formData.photo = img;
          this.error.photo = false;
        }
      };
      this.error.size = false;
    },
    renderVideo() {
      let file = this.$refs.video.files[0];
      this.error.invalidVideo = false;
      if (!file) {
        return;
      }

      if (file.type !== "video/mp4") {
        this.error.invalidVideo = true;
        return;
      }

      this.videoToUpload = file.name;
      this.formData.video = file;
      this.error.video = false;
    },
    renderFile() {
      let file = this.$refs.file.files[0];
      if (file && file.type !== "application/pdf") {
        return;
      }
      if (file) {
        this.fileToUpload = file.name;
        this.formData.file = file;
        this.error.file = false;
      }
    },
    renderBackground() {
      let ExtAllow = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      let background = this.$refs.background.files[0];
      if (!ExtAllow.includes(background.type)) {
        return;
      }
      if (background) {
        this.backgroundToUpload = background.name;
        this.formData.background = background;
      }
      this.error.size = false;
    },
    removeVideoUploaded() {
      this.$refs.video.value = "";
      this.videoToUpload = "";
      this.formData.video = "";
    },
    removeFileUploaded() {
      this.$refs.file.value = "";
      this.fileToUpload = "";
      this.formData.file = "";
    },
    removeBackgroundUploaded() {
      this.$refs.background.value = "";
      this.backgroundToUpload = "";
      this.formData.background = "";
    },
    onResize() {
      if ($(".custom-modal .modal-dialog").height() > $(window).height()) {
        $(".custom-modal .modal-dialog").css({ transform: "unset", top: "0" });
      } else {
        $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
      }
    },
  },
  beforeUnmount() {
    $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
    this.typeName = "";
  },
});
