<template>
  <div class="file-upload">
    <div v-if="title" class="title">
      {{ title }}
      <template v-if="fieldRequire"><small class="require">*</small></template>
    </div>
    <div v-if="note" class="note">
      {{ note }}
    </div>
    <div class="img-preview" @click="labelClick">
      <div v-if="url" :class="classWrapper">
        <div v-if="!is_image">
          <div>Name: {{ file.name }}</div>
        </div>
        <img v-else :src="url" alt="IMAGE" />
      </div>
      <div v-else-if="initPreviewUrl" :class="classWrapper">
        <img v-if="is_image" :src="initPreviewUrl" alt="IMAGE" />
        <div v-else>
          <div>Name: {{ fileName }}</div>
        </div>
      </div>
      <div v-if="!disabled" class="text">
        {{ url || initPreviewUrl ? "Reselect" : uploadBtnText }}
      </div>
    </div>
    <input
      ref="input"
      type="file"
      hidden
      :disabled="disabled"
      @change="onFileChange"
      :accept="accept"
    />
    <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.title {
  font-size: 0.9em;
  margin-top: 0;
  height: 24px;
  .require {
    color: #f52727;
  }
}

.md-error {
  top: 33px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
  margin-top: 3px;
  margin-bottom: 3px;
}

.note {
  font-size: 0.8em;
  margin-bottom: 0.3em;
  font-style: italic;
}

.img-preview {
  height: 150px;
  border-radius: 0.35em;
  background: #2a3038;
  cursor: pointer;
  position: relative;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0.35em;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .wrapper-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0.35em;
    word-break: break-all;
    padding: 25px;
  }

  .text {
    padding: 3px;
    border-radius: 0.5em;
    position: absolute;
    border: 1px solid white;
    font-size: 0.8em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.336);
    opacity: 0.5;
    line-height: 28px;
    height: 30px;
    padding: 0 10px;
  }
}
</style>
