import { defineComponent } from "vue";
import _ from "lodash";

import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import TextArea from "@/components/TextArea";
import NewButton from "@/components/NewButton";

export default defineComponent({
    name: "MessageManageModel",
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
    TextArea,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: "INSERT",
      form: {
        id: null,
        name: null,
        subject: null,
        body: null,
      },
      errorMsg: {
        name: null,
        subject: null,
        body: null,
      },
    };
  },
  methods: {
    showAdd() {
      this.state = "INSERT";
      this.isShow = true;
    },
    showUpdate(item) {
      this.state = "UPDATE";
      this.isShow = true;
      const _item = _.cloneDeep(item);

      this.form.id = _item.id;
      this.form.name = _item.name;
      this.form.subject = _item.subject;
      this.form.body = _item.body;
    },
    save() {
      if (!this.validData()) return;

      if (this.state == "INSERT") {
        this.addNewMessage();
      } else if (this.state == "UPDATE") {
        this.updateMessage();
      }
    },
    async addNewMessage() {
      this.loading = true;
      const res = await adminServices.createMessageTemplate(this.form);
      if (res.data.ok) {
        notify.success("Add template success!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't add this template!");
      }
      this.loading = false;
    },
    async updateMessage() {
      this.loading = true;
      const res = await adminServices.updateMessageTemplate(this.form.id, {
        name: this.form.name,
        subject: this.form.subject,
        body: this.form.body,
      });
      if (res.data.ok) {
        notify.success("Update template success!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't update this template!");
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if ($.trim(this.form.name) === "") {
        this.errorMsg.name = "Please input name";
        flag = false;
      }
      if ($.trim(this.form.subject) === "") {
        this.errorMsg.subject = "Please input subject";
        flag = false;
      }
      if ($.trim(this.form.body) === "") {
        this.errorMsg.body = "Please input body";
        flag = false;
      }
      return flag;
    },
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = null;
    },
    "form.subject": function (v) {
      this.errorMsg.subject = null;
    },
    "form.body": function (v) {
      this.errorMsg.body = null;
    },
  },
});