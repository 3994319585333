<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #row_title="{ props }">
        {{ props.content_title }}
      </template>
      <template #row_target_users="{ props }">
        {{ props.target_users.length }}
      </template>
      <template #row_schedule="{ props }">
        {{ convertSchedule(props) }}
      </template>
      <template #row_created_by="{ props }">
        {{ props.created_by }} <br />
        {{ $filters.parseEstTime(props.created_at) }}<br />
      </template>

      <template #row_actions="{ props }">
        <button
          @click="viewNotification(props)"
          class="btn btn-primary btn-small me-2"
        >
          <i class="far fa-eye"></i>
        </button>
        <button
          @click="deleteNotification(props)"
          class="btn btn-danger btn-small"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="createNotification">
          <span class="text"><i class="fas fa-plus me-1"></i>Add New</span>
        </button>
      </template>
    </Table>
    <NotificationModal
      ref="notificationModal"
      :key="notificationModalKey"
      :reload="getAll"
      @refresh="getAll"
    />
    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>
