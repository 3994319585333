import arrowLeft from "@/assets/images/arrowLeft.svg";
import Checkbox from "@/components/Checkbox";
import Dialog from "@/components/Dialog";
import Datepicker from "@/components/DatePicker";
import FileField2 from "@/components/FileField2";
import NewButton from "@/components/NewButton";
import TextArea from "@/components/TextArea";
import TextField from "@/components/TextField";
import config from "@/config";
import paths from "@/config/router";
import { EVENT_USER_TYPES } from "@/misc/constants";
import { generic } from "@/misc/generic";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import moment from "moment";
import momentTz from "moment-timezone";
import { defineComponent } from "vue";
import ScheduleModel from "../tabs/attendee-components/schedule-modal";
import { parseTimeTz, convertTZ, mapTime, convertTimeTZ } from "@/misc/commons";
const statusList = [
  {
    name: "Completed",
    code: "COMPLETED",
  },
  {
    name: "Inprogress",
    code: "INPROGRESS",
  },
  {
    name: "Cancelled",
    code: "CANCELLED",
  },
];

export default defineComponent({
  name: "event-info",
  emits: ["refresh"],
  props: {
    milestoneMinStartDate: { type: String, default: null },
    milestoneMaxEndDate: { type: String, default: null },
    eventInfoData: { type: Object, default: null },
  },
  components: {
    NewButton,
    TextField,
    TextArea,
    FileField2,
    Checkbox,
    Datepicker,
    ScheduleModel,
    Dialog,
  },
  data() {
    return {
      loading: false,
      convertTZ,
      errorMsg: {
        name: "",
        description: "",
        location: "",
        userType: "",
        startDate: "",
        endDate: "",
      },
      form: {
        eventId: null,
        name: null,
        description: null,
        location: null,
        startDateTime: null,
        endDateTime: null,
        userTypes: [],
      },
      startDateTime: null,
      endDateTime: null,
      startTime: null,
      endTime: null,
      eventPath: paths.event.path,
      eventEditPath: paths.editEvent.path,
      eventEditName: paths.editEvent.name,
      arrowLeft,
      investor: true,
      founder: false,
      member: false,
      isShowCheckbox: false,
      userIds: [],
      statusList: statusList,
      maxStartDate: null,
      startDateMilestone: null,
      endDateMilestone: null,
      confirmText: {
        header: "",
        body: "",
      },
      itemScheduleModalKey: 0,
      newButtons: [
        {
          name: "Schedule to send",
          action: "scheduleSend",
        },
        {
          name: "Send invitation",
          action: "invitationSend",
        },
      ],
      emails: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.eventInfoData) {
        this.setFormData(this.eventInfoData);
        // this.form = _.cloneDeep(this.eventInfoData);
        if (!this.startDateTime) {
          this.startDateTime = mapTime(this.eventInfoData.startDateTime);
          this.startTime = convertTimeTZ(this.eventInfoData.startDateTime);
        }
        if (!this.endDateTime) {
          this.endDateTime = mapTime(this.eventInfoData.endDateTime);
          this.endTime = convertTimeTZ(this.eventInfoData.endDateTime);
        }
        this.getAttendee();
      }
    });
  },
  methods: {
    async getAttendee() {
      if (this.status !== "Edit") return;
      this.loading = true;
      const res = await adminServices.getAttendeesByEventId({
        eventId: this.eventInfoData.eventId,
      });
      if (res && res.data && res.data.ok) {
        res.data.d.map((el) => {
          if (el.userId) {
            this.userIds.push(el.userId);
          }
          if (el.email) {
            this.emails.push(el.email);
          }
        });
      }
      this.loading = false;
    },
    createEvent() {
      if (!this.validData()) return;
      this.loading = true;
      this.form.userTypes = [];
      if (this.investor) this.form.userTypes.push(EVENT_USER_TYPES.INVESTOR);

      if (this.founder) this.form.userTypes.push(EVENT_USER_TYPES.FOUNDER);

      if (this.member) this.form.userTypes.push(EVENT_USER_TYPES.MEMBER);

      this.form.startDateTime = parseTimeTz({
        ...this.startDateTime,
        ...this.startTime,
      });
      this.form.endDateTime = parseTimeTz({
        ...this.endDateTime,
        ...this.endTime,
      });

      adminServices
        .createEvent(this.form)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add success!");
            generic.setJsonLocalStorage("currentTabEvent", "infoTab");
            this.$router.push("/rfm/event/" + resp.data.d);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateEvent() {
      if (!this.validData()) return;
      this.loading = true;
      let obj = {};
      obj.userTypes = [];
      if (this.investor) obj.userTypes.push(EVENT_USER_TYPES.INVESTOR);
      if (this.founder) obj.userTypes.push(EVENT_USER_TYPES.FOUNDER);
      if (this.member) obj.userTypes.push(EVENT_USER_TYPES.MEMBER);
      obj.name = this.form.name;
      obj.location = this.form.location;
      obj.description = this.form.description;
      obj.startDateTime = parseTimeTz({
        ...this.startDateTime,
        ...this.startTime,
      });
      obj.endDateTime = parseTimeTz({ ...this.endDateTime, ...this.endTime });
      adminServices
        .updateEvent(this.form.eventId, obj)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Update success!");
            this.$emit("refresh");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    completeEvent(param) {
      let message = {
        title: "Complete Event",
        body: "Are you sure to complete this event? If you complete, you cannot be reverted",
      };

      let options = {
        html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .show({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },

    backToList() {
      this.$router.push({ path: this.eventPath });
    },
    validData() {
      let flag = true;

      if ((this.form.name && this.form.name.trim() === "") || !this.form.name) {
        this.errorMsg.name = "Please input Event name";
        flag = false;
      }

      if (
        (this.form.location && this.form.location.trim() === "") ||
        !this.form.location
      ) {
        this.errorMsg.location = "Please input Location";
        flag = false;
      }

      if (
        (this.form.description && this.form.description.trim() === "") ||
        !this.form.description
      ) {
        this.errorMsg.description = "Please input Description";
        flag = false;
      }

      if (!this.investor && !this.founder && !this.member) {
        this.errorMsg.userType = "Please check User type";
        flag = false;
      }
      if (!this.handleValidateDate()) flag = false;
      return flag;
    },
    handleValidateDate() {
      this.errorMsg.startDate = "";
      this.errorMsg.endDate = "";

      if (!this.startTime) {
        this.errorMsg.startDate = "Please input start time";
        return false;
      }
      if (!this.endTime) {
        this.errorMsg.startDate = "Please input end time";
        return false;
      }

      const start = this.startDateTime
        ? `${this.startDateTime.years}-${this.startDateTime.months}-${this.startDateTime.days} ${this.startTime.hours}:${this.startTime.minutes}`
        : null;

      const end = this.endDateTime
        ? `${this.endDateTime.years}-${this.endDateTime.months}-${this.endDateTime.days} ${this.endTime.hours}:${this.endTime.minutes}`
        : null;

      if (!this.startDateTime || !this.startTime) {
        this.errorMsg.startDate = "Please input start time";
        return false;
      } else {
        // start date < current date
        if (moment(start).diff(moment(this.convertTZ(moment()))) < 1) {
          this.errorMsg.startDate =
            "Start time must be greater than current time";
          return false;
        }
        if (this.milestoneMinStartDate) {
          if (
            moment(start).diff(
              moment(this.convertTimeDate(this.milestoneMinStartDate))
            ) > 0
          ) {
            this.errorMsg.startDate =
              "Start time must be less than session time";
            return false;
          }
        }
      }

      if (!this.endDateTime || !this.endTime) {
        this.errorMsg.endDate = "Please input end time";
        return false;
      } else {
        if (this.milestoneMaxEndDate) {
          if (
            moment(end).diff(
              moment(this.convertTimeDate(this.milestoneMaxEndDate))
            ) > 0
          ) {
            this.errorMsg.endDate = "End time must be less than session time";
            return false;
          }
        }
      }
      if (moment(end).diff(moment(start)) < 1) {
        this.errorMsg.startDate = "Start time must be less than end time";
        return false;
      }
      return true;
    },
    convertTimeDate(time) {
      if (!time) return null;
      return moment
        .tz(moment.utc(time), config.time_zone_default)
        .format("YYYY/MM/DD HH:mm:ss ");
    },
    setFormData(data) {
      if (!data) return;
      this.form = _.cloneDeep(data);
    },
    refresh() {
      this.$emit("reloadEventInfo");
    },
    fnWatchTime(v, name) {
      if (typeof v !== "object" || !v.months || !v.days || !v.years) return;
      this.errorMsg[name] = false;
    },
    // add
    async sendCancelInvitation() {
      this.loading = true;
      const res = await adminServices.sendCancelInvitation(
        this.eventInfoData.eventId
      );
      if (res && res.data && res.data.ok) {
        this.cancelEventStatus();
      } else {
        notify.error(res.data.errorMsg);
      }
      this.loading = false;
    },
    async cancelEventStatus() {
      this.loading = true;
      const res = await adminServices.cancelEventStatus(
        this.eventInfoData.eventId
      );
      if (res.data.d.succeeded) {
        notify.success("Cancel successfully");
        this.$emit("refresh");
      } else {
        notify.error(res.data.d.errorMessage);
      }
      this.loading = false;
    },
    async sendSchedule() {
      this.itemScheduleModalKey++;
      this.$nextTick(() => {
        this.$refs.itemScheduleModal.create(
          this.eventInfoData.eventId,
          this.emails
        );
      });
    },
    async sendInvitation() {
      const res = await adminServices.sendInvitation(this.form.eventId);
      if (res && res.data && res.data.ok) {
        this.completeEventStatus();
      } else {
        notify.error(res.data.errorMsg);
      }
    },
    async completeEventStatus() {
      this.loading = true;
      const res = await adminServices.completeEventStatus(
        this.eventInfoData.eventId
      );
      if (res.data.d.succeeded) {
        notify.success("Complete successfully");
        this.$emit("refresh");
      } else {
        notify.error(res.data.d.errorMessage);
      }
      this.loading = false;
    },

    async confirmFunc(action) {
      if (action == "cancelSend") {
        this.sendCancelInvitation();
      }
      if (action == "scheduleSend") {
        this.sendSchedule();
      }
      if (action == "invitationSend") {
        this.sendInvitation();
      }
      this.$refs.dialog.hide();
    },
    cancelEvent() {
      let message = {
        title: "Cancel Event",
        body: "Are you sure to cancel this event? If you cancel, you cannot be reverted.",
      };

      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.confirmFunc("cancelSend");
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
  },
  computed: {
    status: {
      get() {
        return (this.eventInfoData ? "Edit" : "Create") || null;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    "form.name"() {
      if (this.form.name.trim() === "") return;
      this.errorMsg.name = "";
    },
    "form.description"() {
      if (this.form.description.trim() === "") return;
      this.errorMsg.description = "";
    },
    "form.location"() {
      if (this.form.location.trim() === "") return;
      this.errorMsg.location = "";
    },
    startDateTime(v) {
      this.fnWatchTime(this.startDateTime, "startDate");
      // this.startDateTime = { ...v, ...this.startTime };
    },
    startTime(v) {
      this.startDateTime = { ...this.startDateTime, ...v };
    },
    endDateTime(v) {
      this.fnWatchTime(this.endDateTime, "endDate");
      // this.endDateTime = { ...v, ...this.endTime };
    },
    endTime(v) {
      this.endDateTime = { ...this.endDateTime, ...v };
    },
    milestoneMinStartDate() {
      this.startDateMilestone = this.convertTZ(this.milestoneMinStartDate);
    },

    milestoneMaxEndDate() {
      this.endDateMilestone = this.convertTZ(this.milestoneMaxEndDate);
    },
  },
});
