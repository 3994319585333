<template>
  <transition name="fade">
    <div
      class="adminContent p-3 popupDetailSurvey"
      v-loading="loading"
      v-if="isRender"
    >
      <div class="popupHeader">
        <template
          v-if="
            (profile.userType === 'Founder' &&
              profile.welcome_survey_stt === 'NOT_STARTED') ||
            (profile.userType === 'Founder' &&
              profile.welcome_survey_stt === 'IN_PROGRESS') ||
            (profile.userType === 'Founder' &&
              profile.welcome_survey_stt === 'COMPLETED') ||
            (profile.userType === 'Founder' &&
              profile.welcome_survey_stt === 'REVIEWING') ||
            (profile.userType === 'Founder' &&
              profile.welcome_survey_stt === 'REJECTED')
          "
        >
          <router-link class="logo" :to="homePath">
            <logo :size-width="200" />
          </router-link>
        </template>
        <h4 class="title text-center m-0">{{ info.title }}</h4>
        <button class="btn btn-default close" @click="hideSurveyDetail">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="popupContent">
        <div class="container pt-3" v-for="item in info.section" :key="item">
          <template v-if="item.id === page.page">
            <h5 class="text-right mb-3">{{ item.name }}</h5>
            <div
              class="form-group"
              v-for="(q, idx) in item.questions"
              :key="idx"
            >
              <label class="question-title">{{
                `${idx + 1}. ${q.name}`
              }}</label>
              <input
                type="text"
                class="form-control"
                v-if="q.type === 'INPUT'"
                disabled
                readonly
                :value="q.answers"
              />
              <div v-else-if="q.type === 'MULTI'">
                <div v-for="n in q.details" :key="n" class="multiAnswer">
                  <input type="checkbox" disabled readonly />
                  <label>{{ n.Answer }}</label>
                </div>
              </div>
              <div v-else-if="q.type === 'ONE'">
                <div
                  v-for="n in q.details"
                  :key="n"
                  class="oneAnswer"
                  :ref="q.id"
                  :id="n.Id"
                >
                  <input
                    type="radio"
                    :name="q.id"
                    :id="`${q.id}_${n.Id}`"
                    :checked="checkActivebox(n.Id, q.answers)"
                  />
                  <label
                    class="pointer"
                    :for="`${q.id}_${n.Id}`"
                    :class="{ active: checkActivebox(n.Id, q.answers) }"
                    >{{ n.Answer }}</label
                  >
                </div>
              </div>
              <div v-else-if="q.type === 'MULTI-INPUT'">
                <div v-for="n in q.details" :key="n" class="multiAnswer">
                  <template v-if="n.AnswerType === 'LABEL'">
                    <input type="checkbox" disabled readonly />
                    <label>{{ n.Answer }}</label>
                  </template>
                  <template v-if="n.AnswerType === 'INPUT'">
                    <input
                      type="checkbox"
                      disabled
                      readonly
                      :id="`${q.id}_${n.Id}`"
                      :checked="checkActivebox(n.Id, q.answers)"
                    />
                    <label
                      class="pointer"
                      :for="`${q.id}_${n.Id}`"
                      :class="{ active: checkActivebox(n.Id, q.answers) }"
                      >Other:</label
                    >
                    <!-- <input type="text" v-model="q.answers[0].Answer" class="form-control other" :class="{'active': checkActivebox(n.Id, q.answers)}" :ref="`${q.id}_${n.Id}`" /> -->
                    <template v-if="checkInputValue(q.answers, n).status">
                      <input
                        type="text"
                        :value="checkInputValue(q.answers, n).value"
                        disabled
                        readonly
                        class="form-control other"
                        :ref="`${q.id}_${n.Id}`"
                      />
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        :ref="`${q.id}_${n.Id}`"
                      />
                    </template>
                  </template>
                </div>
              </div>
              <div v-else-if="q.type === 'FILE'">
                File:
                <a
                  class="pointer link"
                  @click="downloadFile(q.answers[0].S3Key)"
                  v-if="q.answers"
                  >{{ q.answers[0].Name }}</a
                >
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="popupFooter">
        <div class="control colorWhite pt-2 pb-2">
          <div class="container">
            <div class="row">
              <div class="col col-12 text-center">
                <span>Founder: {{ info.founder }}</span>
                <span>Scored: {{ sumScored }}</span>
                <button
                  class="btn btn-primary"
                  @click="approvedSurvey(surveyDetailID)"
                >
                  Accept
                </button>
                <button
                  class="btn btn-danger"
                  @click="rejectSurvey(surveyDetailID)"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination colorWhite">
          <div class="container">
            <pager
              :loadContentAtPage="loadPage"
              :page="page.page"
              :size="page.size"
              :total="page.total"
              :pagerFull="false"
            ></pager>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.popupDetailSurvey {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
  background-color: #fff;
  .popupHeader {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 52px;
    background-color: #007bff;
    position: relative;
    .logo {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      display: block;
      overflow: hidden;
      background-color: #fff;
      border-radius: 4px;
      padding: 10px;
    }
    h4 {
      line-height: 52px;
      font-weight: 400;
    }
    .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 1;
      color: #fff;
      text-shadow: unset;
      z-index: 2;
    }
    @media (max-width: 767.98px) {
      text-align: center;
      .logo {
        position: static;
        transform: unset;
        padding: 0 5px;
        display: inline-block;
        width: 100px;
      }
      h4 {
        line-height: 26px;
      }
    }
  }
  .popupContent {
    display: block;
    .container {
      width: 100%;
      max-width: 610px;
      padding-bottom: 100px;
      color: #c0c4cc;
      h5 {
        border-bottom: 1px solid #a9a9a9;
        line-height: 30px;
      }
      label {
        &.question-title {
          color: #6b6b6b;
        }
      }
      input {
        color: #c0c4cc;
      }
      .multiAnswer,
      .oneAnswer {
        input[type="checkbox"],
        input[type="selectbox"] {
          vertical-align: top;
          margin-top: 3px;
        }
        label {
          display: inline-block;
          width: calc(100% - 20px);
          vertical-align: top;
        }
      }
    }
  }
  .popupFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    .control {
      display: block;
      overflow: hidden;
      width: 100%;
      background-color: #000;
      span {
        line-height: 32px;
        display: inline-block;
        margin-right: 5px;
      }
      button {
        margin-right: 5px;
        padding: 5px 2rem;
      }
    }
    .pagination {
      display: block;
      overflow: hidden;
      width: 100%;
      max-height: 52px;
      background-color: #3e99ed;
      padding: 5px 0;
    }
  }
}
</style>
<script src="./index.js"></script>
