<template>
  <Modal
    :title="state === 'CREATE' ? 'Create Newletter' : 'Edit Newletter'"
    v-model:value="isShow"
    :customClass="`overflowVisible`"
    class="modal-not-overflow"
  >
    <form @submit.prevent="save" autocomplete="false">
      <div class="row">
        <div class="col col-12 col-md-6">
          <TextField
            v-model:value="data.Name"
            title="Title"
            name="title"
            :autocomplete="false"
            :errorMsg="errorMsg.dataName"
            :fieldRequire="true"
          />
        </div>
        <div class="col col-12 col-md-6">
          <div class="row">
            <div class="col-md-8">
              <TextField
                v-model:value="docName"
                title="Newsletter file"
                name="docFile"
                :autocomplete="false"
                :errorMsg="errorMsg.docName"
                :disabled="true"
                :fieldRequire="true"
              />
            </div>
            <div class="col-md-4 align-self-center">
              <button
                class="btn btn-secondary btn-small ml-1"
                @click="selectDataroomItem('HTML')"
              >
                <div class="search-btn">
                  <i class="fas fa-search iconSearch"></i>
                  <span style="margin: 2px">Search</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-6" style="margin-bottom: 25px">
          <MonthPicker
            v-model:value="data.PublishDate"
            placeholder="Publish month"
            field="black"
            title="Publish month"
            fieldRequire
            isMonthlyPicker
            :dateFormat="`MM/YYYY`"
            :errorMsg="errorMsg.dataPublishDate"
          />
        </div>
        <div class="col col-12 col-md-6 d-flex">
          <!-- <Switcher v-model:value="isActive" title="Active" name="title" /> -->

          <div class="custom-switch-wrapper mb-2">
            <label class="switch me-1">
              <input type="checkbox" v-model="isActive" />
              <span class="slider round"></span>
            </label>
            <span style="color: #c4cad6">{{ isActive ? 'Active' : 'Inactive' }}</span>
          </div>
        </div>

        <div
          class="col col-12 col-md-6 w-100 position-relative"
          style="margin-bottom: 25px"
        >
          <label class="title mb-0">
            Select Funds
            <small class="require" style="color: #f52727">*</small></label
          >
          <customSelect
            v-model:value="lpFundSelected"
            :options="lpFundOptions"
            isMultiple
            label="Select Funds"
            class="w-100"
          />
          <div v-if="errorMsg.lpFundSelected" class="md-error">
            {{ errorMsg.lpFundSelected }}
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save()" :loading="loading"> Save </NewButton>
    </template>
    <publicFileDataroom
      v-if="isShowPublicDataroom"
      :mediaType="selectedMediaType"
      @close="closeSearchDataroomItem"
      @completed="completeSelectDataroomItem"
    />
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import _ from "lodash";
import moment from "moment";
import adminServices from "@/services/admin";
import Modal from "@/components/Modal";
import TextArea from "@/components/TextArea";
import TextField from "@/components/TextField";
import Switcher from "@/components/Switcher";
import NewButton from "@/components/NewButton";
import { LP_MEDIA_TYPES } from "@/misc/constants";
import MonthPicker from "@/components/MonthPicker";
import publicFileDataroom from "../publicFileDataroom";
import FileField2 from "@/components/FileField2";
import notify from "@/services/notify";
import { parseTimeTz } from "@/misc/commons";
import customSelect from "@/components/commons/customSelect";

export default defineComponent({
  components: {
    Modal,
    TextArea,
    TextField,
    NewButton,
    MonthPicker,
    FileField2,
    publicFileDataroom,
    notify,
    Switcher,
    customSelect,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: "CREATE",
      type: LP_MEDIA_TYPES.NEWSLETTER,
      isActive: false,
      // file: '',
      data: {
        Name: "",
        DocId: "",
        PublishDate: "",
        // Description: '',
      },
      errorMsg: {
        name: false,
        dataName: false,
        dataPublishDate: false,
        docName: false,
        // dataDescription: false,
        // file : false,
        lpFundSelected: false,
      },
      selectedMediaType: null,
      isShowPublicDataroom: false,
      docName: "",
      imgAccept: "image/*",
      url: "",
      showAvatarUpload: false,
      selectedMonth: null,
      lpFundOptions: [],
      lpFundSelected: [],
    };
  },
  watch: {
    "data.Name": function (v) {
      this.errorMsg.dataName = false;
    },
    docName: function (v) {
      this.errorMsg.docName = false;
    },
    "data.PublishDate": function (v) {
      this.errorMsg.dataPublishDate = false;
    },
    lpFundSelected: function (v) {
      this.errorMsg.lpFundSelected = false;
    },
  },
  methods: {
    async getAllLpFunds() {
      const res = await adminServices.getAllLpFunds();
      if (res.error) return;

      if (res.data.ok) {
        const temp = res.data.d.map((x) => ({
          value: x.lpfund_id,
          text: x.is_enabled ? x.name : `${x.name} (Inactive)`,
        }));
        this.lpFundOptions = temp || [];
      }
    },
    reuploadImg() {
      this.showAvatarUpload = !this.showAvatarUpload;
    },
    create() {
      this.getAllLpFunds();
      this.isShow = true;
      this.state = "CREATE";
      if (this.state === "CREATE") {
        this.showAvatarUpload = true;
      }
    },
    show(props) {
      this.getAllLpFunds();
      this.isShow = true;
      this.state = "VIEW";
      this.data.Name = props.data.Name;
      this.data.PublishDate = {
        month: Number(moment(props.data.PublishDate).format("M")) - 1,
        year: Number(moment(props.data.PublishDate).format("YYYY")),
      };
      this.data.DocId = props.data.DocId;
      this.docName = props.docName;
      this.isActive = props.active;
      this.id = props.id;
      this.lpFundSelected = props.lp_funds.map((x) => Number(x.lp_fund_id));
    },

    save() {
      if (!this.isShowPublicDataroom) {
        if (!this.validData()) return;
        const cvTime = `${this.data.PublishDate.year}-${
          this.data.PublishDate.month + 1
        }-01 00:00:00`;

        const formData = {
          data: {
            name: this.data.Name,
            doc_id: this.data.DocId,
            publish_date: this.data.PublishDate ? parseTimeTz(cvTime) : "",
          },
          name: this.data.Name,
          type: this.type,
          active: this.isActive,
          lp_fund_ids: this.lpFundSelected,
        };

        if (this.state === "CREATE") {
          this.createItem(formData);
        } else {
          this.updateItem(formData);
        }
      }
    },
    async createItem(formData) {
      this.loading = true;
      adminServices
        .createLPNewsletters(formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add success!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateItem(formData) {
      this.loading = true;
      adminServices
        .updateLPNewsletters(this.id, formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Update success!");
            this.$emit("refresh");
            this.isShow = false;
          } else {
            notify.error("Can't update this newletter!");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validData() {
      let flag = true;
      if ($.trim(this.data.Name) === "") {
        this.errorMsg.dataName = "Please input Title";
        flag = false;
      }
      if ($.trim(this.data.PublishDate) === "") {
        this.errorMsg.dataPublishDate = "Please input Publication Month";
        flag = false;
      }
      if ($.trim(this.docName) === "") {
        this.errorMsg.docName = "Please input Doc file";
        flag = false;
      }
      if (this.lpFundSelected.length === 0) {
        this.errorMsg.lpFundSelected = "Please Select Funds";
        flag = false;
      }
      // if ($.trim(this.data.Description) === '') {
      //     this.errorMsg.dataDescription = 'Please input Description'
      //     flag = false
      // }
      // if ($.trim(this.file) === '' && $.trim(this.url) === '' ) {
      //     this.errorMsg.file = 'Please input Thumbnail'
      //     flag = false
      // }
      return flag;
    },
    selectDataroomItem(type) {
      this.selectedMediaType = type;
      this.$nextTick(() => {
        this.isShowPublicDataroom = true;
      });
    },
    closeSearchDataroomItem() {
      this.isShowPublicDataroom = false;
      this.selectedMediaType = null;
    },
    completeSelectDataroomItem(data) {
      const { dataroomId, type, name } = data;
      this.closeSearchDataroomItem();
      switch (type) {
        case "HTML":
          this.data.DocId = dataroomId;
          this.docName = name;
          break;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.search-btn {
  display: flex;
  align-items: center;
}

.md-error {
  position: absolute;
  color: #f52727;
  font-size: 0.8em;
}
</style>
