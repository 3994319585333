<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
      class="custom-index"
    >
      <template #row_name="{ props }">
        {{ props.name }}
      </template>
      <template #row_number="{ props }">
        {{ props.number_of_users }}
      </template>
      <template #row_deathline="{ props }">
        <div>Portal: {{ props.death_line_in_day }}</div>
        <div>Investor: {{ props.death_line_in_day_investor }}</div>
        <div>Public: {{ props.death_line_in_day_public }}</div>
      </template>
      <template #row_notify="{ props }">
        {{ props.interval_in_day }}
      </template>
      <template #row_notify_before_hour="{ props }">
        {{ mapTime(props.notify_hour).full }}
      </template>
      <template #row_actions="{ props }">
        <button @click="updateStage(props)" class="btn btn-primary btn-small">
          <i class="far fa-edit"></i>
        </button>
      </template>
    </Table>
    <UpdateReviewerModel
      ref="updateReviewerModel"
      :key="updateReviewerModelkey"
      :roleList="roleList"
      @refresh="getAll"
    />
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.custom-index {
  position: relative;
  z-index: 0;
}
</style>
