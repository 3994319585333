import AdminServices from "../../../services/admin";
import { VueRecaptcha } from "vue-recaptcha";
import conf from "../../../config";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["itemId"],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      loading: false,
      username: "",
      reCaptchaSiteKey: conf.recaptcha_key,
      error: {
        username: false,
      },
    };
  },
  watch: {
    username: function (v) {
      this.error.username = false;
    },
  },
  mounted() {
    this.$refs.recaptcha?.reset();
  },
  methods: {
    verify() {
      let flag = true;
      if ($.trim(this.username) === "") {
        this.error.username = true;
        flag = false;
      }
      if (flag) {
        this.$refs.recaptcha?.execute();
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha?.reset();
    },
    onCaptchaVerified(recaptchaToken) {
      let that = this;
      that.loading = true;
      that.$refs.recaptcha.reset();
      AdminServices.verifyUserAndGetLink(
        that.itemId,
        that.username,
        recaptchaToken
      )
        .then((resp) => {
          if (resp.data.ok) {
            const _url = resp.data.d;
            this.$emit("completed", _url);
          } else {
            //do something else
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
  },
});
