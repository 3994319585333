import AdminServices from "@/services/admin";
import treeItem from "@/components/commons/treeItem";
import filePreview, { FileTypes } from "@/components/commons/filePreview";
import { defineComponent } from "vue";
import Modal from "@/components/Modal";

export default defineComponent({
  components: {
    treeItem,
    filePreview,
    Modal,
  },
  data() {
    return {
      loading: true,
      selectedFolder: null,
      selectedChildrenItems: [],
      parentPath: [],
      rawTree: [],
      selectedParentFolderId: null,
      selectedItem: null,
      delay: 300,
      clicks: 0,
      timer: null,
      fileToken: null,
      previewFileType: "",
      allowViewFileExtensions: [
        ".pdf",
        ".mp4",
        ".gif",
        ".jpg",
        ".jpeg",
        ".png",
      ],
      rootDataroomItem: {
        id: 0,
        name: "Shared with me",
        type: "Folder",
      },
      isShow: false,
      fileCantView: null,
    };
  },
  computed: {
    treeData() {
      return this.unflatten(this.rawTree);
    },
    selectedFolderId() {
      return this.selectedFolder ? this.selectedFolder.id : null;
    },
    selectedParentId() {
      return this.selectedFolder ? this.selectedFolder.parent_id : null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialLoading();
    });
  },
  methods: {
    initialLoading() {
      AdminServices.getSharedDataroomItemsByAuthInvestor()
        .then((resp) => {
          if (resp.data.ok) {
            this.rawTree = resp.data.d;
            this.selectFolder(this.rootDataroomItem);
          }
        })
        .finally(() => (this.loading = false));
    },
    getSharedDataroomItems() {
      AdminServices.getSharedDataroomItemsByAuthInvestor()
        .then((resp) => {
          if (resp.data.ok) {
            this.rawTree = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshData() {
      this.loading = true;
      AdminServices.getSharedDataroomItemsByAuthInvestor()
        .then((resp) => {
          if (resp.data.ok) {
            this.rawTree = resp.data.d;
            const _selectedIndex = this.rawTree.findIndex(
              (x) => x.id === this.selectedFolderId
            );
            if (_selectedIndex > -1) {
              this.selectFolder(this.selectedFolder);
            } else {
              this.selectFolder(this.rootDataroomItem);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectFolder(item) {
      this.selectedFolder = item;
      this.selectedChildrenItems = this.rawTree.filter(
        (x) => x.parent_id === item.id
      );
      this.parentPath = this.findAncestors(this.selectedFolder, this.rawTree);
    },
    unflatten(rawArr) {
      let arr = rawArr.filter((x) => x.type === "Folder");
      let tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

      // First map the nodes of the array to an object -> create a hash table.
      for (let i = 0; i < arr.length; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id]["children"] = [];
      }

      for (let id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
          mappedElem = mappedArr[id];
          // If the element is not at the root level, add it to its parent array of children.
          if (mappedElem.parent_id) {
            mappedArr[mappedElem["parent_id"]]["children"].push(mappedElem);
          }
          // If the element is at the root level, add it to first level elements array.
          else {
            tree.push(mappedElem);
          }
        }
      }
      return {
        ...this.rootDataroomItem,
        children: tree,
      };
    },

    countDescendantFiles(item) {
      if (item && item.type === "Folder") {
        let descendants = [item.id];
        while (true) {
          let _subDescendants = this.rawTree.filter(
            (x) =>
              descendants.includes(x.parent_id) && !descendants.includes(x.id)
          );
          if (_subDescendants.length > 0) {
            descendants.push(..._subDescendants.map((x) => x.id));
          } else {
            break;
          }
        }
        return this.rawTree.filter(
          (x) =>
            descendants.includes(x.id) && ["File", "Bookmark"].includes(x.type)
        ).length;
      } else {
        return 0;
      }
    },

    findAncestors(item, rawArr) {
      if (item.id === 0) {
        return [this.rootDataroomItem];
      }
      let ancestors = [];
      let parentId = item.parent_id;
      ancestors.unshift(item);
      while (!!parentId) {
        ancestors.unshift(rawArr.find((x) => x.id === parentId));
        let _next_item = rawArr.find((x) => x.id === parentId);
        parentId = _next_item ? _next_item.parent_id : undefined;
      }
      ancestors.unshift(this.rootDataroomItem);
      return ancestors;
    },

    oneClick(item) {
      this.clicks++;
      if (this.clicks === 1) {
        let self = this;
        this.timer = setTimeout(() => {
          self.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        if (item.type === "Folder") {
          this.selectFolder(item);
        } else if (item.type === "File") {
          if (this.allowViewFileExtensions.includes(item.current_extension)) {
            this.viewFile(item);
          } else {
            // case file not allowed view
            this.fileCantView = item;
            this.isShow = true;
            // this.downloadFileNotAllowed(item);
          }
        } else if (item.type === "Bookmark") {
          this.openBookmarkUrl(item);
        }
        this.clicks = 0;
      }
    },

    viewFile(item) {
      const _fileItem = this.rawTree.find((x) => x.id === item.id);
      if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
        this.loading = true;
        AdminServices.requestDownloadCIOAndCountView(item.id, "View")
          .then((resp) => {
            if (resp.data.ok) {
              this.fileToken = resp.data.d;
              if (_fileItem.current_extension === ".pdf") {
                this.showFilePreview(FileTypes.PDF);
              } else if (_fileItem.current_extension === ".mp4") {
                this.showFilePreview(FileTypes.VIDEO);
              } else {
                this.showFilePreview(FileTypes.IMAGE);
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // case file not allowed view
        this.fileCantView = item;
        this.isShow = true;
        // this.downloadFileNotAllowed(item);
      }
    },

    showFilePreview(previewFileType) {
      this.fixedBody();
      this.previewFileType = previewFileType;
      this.$refs.filePreview.showModal();
    },

    checkItemDownloadable(item) {
      if (item.type === "File") {
        if (item.parent_id !== 0) {
          //depend on the downloadable of the outermost shared parent
          return this.parentPath && this.parentPath.length > 1
            ? !!this.parentPath[1].allow_download
            : false;
        } else {
          return !!item.allow_download; //depend on itself if no shared parents
        }
      }
      return false;
    },

    downloadFileNotAllowed(item) {
      this.handleDownloadFile(item);
    },

    downloadFile(item) {
      if (this.checkItemDownloadable(item)) {
        this.handleDownloadFile(item);
      }
    },

    handleDownloadFile(item) {
      this.loading = true;
      AdminServices.requestDownloadCIOAndCountView(item.id, "Download")
        .then((resp) => {
          if (resp.data.ok) {
            // window.open(resp.data.d, "_blank");
            this.createAndDownloadFile(resp.data.d);
            this.resetDataFileView();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createAndDownloadFile(url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    openBookmarkUrl(item) {
      this.loading = true;
      AdminServices.requestDownloadCIOAndCountView(item.id, "View")
        .then((resp) => {
          if (resp.data.ok) {
            // window.open(resp.data.d, "_blank");
            this.createAndDownloadFile(resp.data.d);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmDownload() {
      if (!this.fileCantView) return;
      this.handleDownloadFile(this.fileCantView);
    },
    resetDataFileView() {
      this.isShow = false;
      this.fileCantView = null;
    },
  },
});
