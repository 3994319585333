import { defineComponent } from "vue";
import _ from "lodash";
import backToList from "../back-to-list";
import eventInfo from "../event-info";
export default defineComponent({
  components: {
    backToList,
    eventInfo,
  },
  provide() {
    return {
      eventInfoData: null,
    };
  },
});
