<template>
  <div class="ourteamPartial" v-if="fundMembers.length">
    <div class="memberWrapper">
      <div class="mainContainer">
        <div
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          class="fund-wrapper mb-5"
          v-for="(fund, idx) in fundMembers"
          :key="`f${idx}`"
        >
          <div v-if="isShowFundName">
            <div class="fund-info">{{ fund.name }}</div>
            <hr class="darker" />
          </div>
          <div class="row">
            <div
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              class="col-lg-4"
              v-for="(item, index) in fund.members"
              :key="index"
            >
              <a
                class="boxItemMember d-block"
                href
                @click.prevent="showInformationPersonal(index, idx)"
              >
                <img :src="item.photo" />
                <div class="boxInfo">
                  <span class="name">{{ item.name }}</span>
                  <br />
                  <span class="position">{{ item.title }}</span>
                  <br />
                  <span class="position">{{ item.home_town }}</span>
                </div>
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center" v-if="showOurTeamSeemore">
            <router-link :to="ourTeamIndexPath">
              <button data-aos="zoom-out-down" class="btn btnBlue">
                See more
              </button>
            </router-link>
          </div>
        </div>
        <InformationPersonal
          ref="informationPersonal"
          :members="membersToShow"
        ></InformationPersonal>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.ourteamPartial {
  background-color: #000;
}
.boxItemMember {
  position: relative;
  margin: 2rem;
  padding: 0 2rem;
  .boxMember {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.avatar {
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  &:hover {
    -webkit-filter: unset;
    filter: unset;
  }
  @media screen and (max-width: 991px) {
    -webkit-filter: unset;
    filter: unset;
  }
}
.boxInfo {
  color: #fff;
  padding: 8px 4px 4px 0px;
  line-height: normal;
  .name {
    font-size: 20px;
    font-weight: 500;
  }
  .position {
    font-size: 16px;
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .boxItemMember {
    margin: 0;
    margin-bottom: 2rem;
  }
  .boxInfo {
    .name {
      font-size: 16px;
      font-weight: 500;
    }
    .position {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}
hr.darker {
  border-top: 1px solid #272626;
}
.fund-info {
  font-size: 25px;
  color: #ffffff;
  word-break: break-all;
}
</style>
