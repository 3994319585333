<template>
  <div class="signature">
    <div class="wrap">
      <h3 class="text-center mt-3">Signature here</h3>
      <div class="content mb-3" v-on:mousemove="test" v-on:touchmove="test">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="100%"
          ref="signaturePad"
        />
      </div>
      <p class="text-center m-0">
        <button class="btn custombtnclear btn-outline-light" @click="clear">
          Clear
        </button>
      </p>
      <div class="logo">
        <logoIcon :size-width="200" />
      </div>
      <div class="robotic-block-bottom">
        <div class="prev-btn text-left">
          <button class="btn btn-link ml-3 back pointer" @click="hideSignature">
            Back
          </button>
        </div>
        <div class="next-btn text-right">
          <button
            class="btn btn-outline-light me-3 pointer"
            :class="!empty ? 'border' : 'no-border'"
            :disabled="empty"
            @click="saveImg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.signature {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../../src/assets/images/robotic/wallpaper.png")
    no-repeat center center #000;
  z-index: 6;
  .wrap {
    h3 {
      font-size: 57px;
      padding: 20px 0;
      margin-bottom: 15px;
    }
    .content {
      display: block;
      overflow: hidden;
      background-color: #fff;
      border-radius: 5px;
      width: 592px;
      height: 150px;
      margin: 0 auto;
      position: relative;
      input[type="text"] {
        border: 0;
        background-color: transparent !important;
        border-bottom: 1px solid #000;
        color: #000;
        font-size: 50px;
        padding: 5px;
        width: 100%;
        outline: 0 !important;
        box-shadow: unset !important;
      }
      .customFile {
        display: block;
        overflow: hidden;
        width: 90%;
        height: 100px;
        background-color: #fff;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-bottom: 1px solid #000;
        cursor: pointer;
        text-align: center;
        &.no-border {
          border-bottom: 0;
        }
        input[type="file"] {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
    .custombtnclear:not(:hover) {
      background: rgba(0, 0, 0, 0.7);
    }
    .robotic-block-bottom {
      position: absolute;
      z-index: 2;
      bottom: calc(75% + 23px);
      left: 0;
      display: flex;
      width: 100%;
      .prev-btn {
        position: absolute;
        left: 15px;
      }
      .next-btn {
        position: absolute;
        right: 15px;
      }
      button {
        width: 156px;
        text-decoration: none;
        &.btn-link {
          color: #fff;
        }
        &.border {
          border-radius: 25px;
        }
        &.no-border {
          border: 0 !important;
          cursor: no-drop !important;
        }
        &.back {
          &:before {
            content: "";
            background: url("../../../../src/assets/images/robotic/left-arrow.svg")
              no-repeat;
            display: inline-block;
            vertical-align: middle;
            width: 9px;
            height: 16px;
            margin-right: 20px;
          }
        }
      }
    }
    .logo {
      position: absolute;
      bottom: calc(25% - 14px);
      left: calc(50% - 142px);
    }
  }
}
</style>
<script src="./index.js"></script>
