<template>
  <transition name="fade" v-if="isShow">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideModal"></div>
      <div class="modal-dialog modal-dialog-scrollable">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">{{ typeName }} News</h4>
          </div>
          <div class="modal-body" v-loading="loading">
            <div class="row">
              <div class="col col-12">
                <div class="form-group mb-1">
                  <TextField
                    title="News Title "
                    name="newTitle"
                    fieldRequire
                    :autocomplete="false"
                    :errorMsg="error.title"
                    placeholder="Enter title of news"
                    v-model:value="formData.title"
                    :maxLength="255"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-0">
              <div class="col col-12 col-md-6">
                <div class="form-group mb-1 fueltype">
                  <SelectField
                    title="News Type"
                    fieldRequire
                    v-model:value="userTypeSelected"
                    :options="newsTabs"
                    labelBy="title"
                    valueProp="name"
                    :errorMsg="error.type"
                  />
                </div>
              </div>
              <div class="col col-12 col-md-6">
                <div class="form-group mb-1">
                  <Datepicker
                    placeholder="Publish date"
                    field="black"
                    :format="customFormatter"
                    title="Publish date"
                    fieldRequire
                    v-model:value="tmpDate"
                    :errorMsg="error.publishDate"
                  ></Datepicker>
                </div>
              </div>
            </div>
            <div class="row mb-0" v-show="formData.type != 'PRIVATE_NEWS'">
              <div class="col col-12">
                <div class="form-group mb-1">
                  <TextField
                    title="Links"
                    name="Links"
                    fieldRequire
                    :autocomplete="false"
                    :errorMsg="error.link"
                    placeholder="Enter link of news"
                    v-model:value="formData.link"
                    :maxLength="255"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-0 custom-row">
              <div class="col col-md-6 col-12">
                <div class="form-group mb-1">
                  <div class="custom-file">
                    <label for="customFileLang" class="mb-0"
                      >Photo <small class="colorRed">*</small>
                    </label>
                    <label
                      class="custom-file-label mb-0 pointer"
                      for="customFileLang"
                    >
                      <i
                        class="fas fa-cloud-upload-alt"
                        :class="{ dataPhoto: dataPhoto }"
                      ></i>
                      <p class="text-center mb-0" v-if="!dataPhoto">
                        click to upload
                      </p>
                      <img :src="dataPhoto" alt="" class="dataImg" v-else />
                      <input
                        type="file"
                        id="customFileLang"
                        class="custom-file-input"
                        ref="fileimg"
                        accept="image/x-png,image/gif,image/jpeg"
                        @change="renderFileImg"
                      />
                    </label>
                  </div>
                  <span class="texterror colorRed" v-if="error.photo"
                    ><small class="mb-1">Please choose one picture</small></span
                  >
                </div>
              </div>
              <div class="col col-md-6 col-12">{{` `}}</div>
            </div>
            <div class="row mb-0" v-show="formData.type == 'PRIVATE_NEWS'">
              <div class="col col-12">
                <div class="form-group mb-1 editor">
                  <label for="company" class="mb-0">Content</label>
                  <div id="editorjs"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button
              class="btn btn-secondary"
              @click="hideModal"
              :disabled="loading"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary"
              @click="validateForm"
              :disabled="loading"
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
#editorjs {
  background: #494b54;
  color: #949ba2;
  border: 1px solid #70707038;
  padding: 10px;
  border-radius: 4px;
  min-height: 100px;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
textarea {
  resize: none;
}
.modal-footer {
  display: block;
}
.fueltype {
  position: relative;
  .dropdown-menu {
    position: absolute !important;
    top: 45px !important;
    transform: translate3d(0, 0, 0) !important;
  }
  #dropdownMenuLink {
    border: 1px solid #e0e7ff;
    color: #bbbcbe;
    padding-left: 10px;
    padding-right: 10px;
    height: 37px !important;
    line-height: 39px !important;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f9faff;
    position: relative;
    display: inline-block;
    width: 100%;
    &.hasData {
      color: #000;
      background-color: #e8f0fe;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 10px;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #3a87da;
    }
  }
}
.custom-file {
  height: 150px;
  .custom-file-label {
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    background: #bbbcbe;
    height: 120px;
    position: relative;
    border-radius: 4px;
    &::after {
      display: none;
    }
    .dataImg {
      height: 105px;
    }
    i {
      font-size: 80px;
      &.dataPhoto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
    input {
      overflow: hidden;
      width: 1px;
      height: 1px;
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
  .custom-file-input {
    height: 100%;
  }
}
.custom-row {
  height: 168px;
  @media screen and (max-width: 767.98px) {
    height: unset;
    .custom-file {
      height: 160px;
      .custom-file-input {
        height: 100%;
      }
    }
  }
}
.customUploadFile {
  background-color: #41505e;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  display: block;
  color: #fff;
  opacity: 0.7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fileinfo {
  color: #afafaf;
  font-weight: 400;
  .info {
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  .close {
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
  }
}
.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
<style scoped>
.editor :deep() .codex-editor__redactor {
  padding: 0 !important;
}
.editor :deep() .ce-block__content .cdx-simple-image__caption {
  display: none;
}
.editor :deep() .codex-editor__redactor .ce-block--selected .ce-block__content {
  background: #52555f;
}
</style>
<script src="./index.js"></script>
