<template>
  <div class="h-100" @contextmenu.prevent="handler">
    <div class="vContainer">
      <template v-if="isShow && fileUrl">
        <pdf v-if="fileType === 'pdf'" :src="fileUrl" :scale="scale" />
        <video v-if="fileType === 'video'" controls controlsList="nodownload" :src="fileUrl"/>
        <img v-if="fileType === 'image'" :src="fileUrl" style="height:100%; width: 100%; position: relative;" />
      </template>
      <template v-else-if="errorMsg">
        <div class="error-wrapper">
          <div class="error-msg">
            <span>{{errorMsg}}</span>
          </div>
        </div>
      </template>
    </div>

    <verifyUser
      v-if="isShowVerifyUser"
      :itemId="itemId"
      @completed="onCompleteVerifyUser($event)"
    />
  </div>
</template>
<style lang="scss" scoped>
.vContainer{
  height: 100% !important;
  text-align: center;
  video {
    outline: none;
  }
  .error-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d1d29;
    .error-msg {
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>

<script src="./index.js"></script>