<template>
  <div class="adminContent p-3" v-loading="loading">
    <router-link
      v-if="isRokker"
      class="pointer link"
      :to="{ path: partnerPath, name: partnerRouteName }"
      >Go back</router-link
    >
    <div
      class="data p-3"
      v-if="!loading && kpiFields.length === 0"
      :class="{ 'mt-3': isRokker }"
    >
      <h5>
        KPI report for this partner is not setup correctly. Please contact our
        admin.
      </h5>
    </div>
    <div v-else-if="!loading" class="data p-3" :class="{ 'mt-3': isRokker }">
      <div class="row mb-1">
        <div class="col-12 col-md-4">
          <label for="">Month</label>
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ textMonth }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span class="dropdown-item" @click="changeMonth('1')">1</span>
              <span class="dropdown-item" @click="changeMonth('2')">2</span>
              <span class="dropdown-item" @click="changeMonth('3')">3</span>
              <span class="dropdown-item" @click="changeMonth('4')">4</span>
              <span class="dropdown-item" @click="changeMonth('5')">5</span>
              <span class="dropdown-item" @click="changeMonth('6')">6</span>
              <span class="dropdown-item" @click="changeMonth('7')">7</span>
              <span class="dropdown-item" @click="changeMonth('8')">8</span>
              <span class="dropdown-item" @click="changeMonth('9')">9</span>
              <span class="dropdown-item" @click="changeMonth('10')">10</span>
              <span class="dropdown-item" @click="changeMonth('11')">11</span>
              <span class="dropdown-item" @click="changeMonth('12')">12</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for="">Year</label>
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ textYear }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span class="dropdown-item" @click="changeYear('2018')"
                >2018</span
              >
              <span class="dropdown-item" @click="changeYear('2019')"
                >2019</span
              >
              <span class="dropdown-item" @click="changeYear('2020')"
                >2020</span
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row mb-3">
          <div class="col-12 col-md-4">
            <small v-if="submitDate" class="text-white-50"
              >Submitted at: {{ $filters.parseEstTime(submitDate) }}</small
            >
          </div>
        </div>

        <div
          class="row"
          v-for="(fieldGroup, pos) in kpiFields"
          :key="pos"
          :ref="fieldGroup.groupName"
        >
          <div class="col-12 field-name">
            {{ `${pos + 1}. ${fieldGroup.groupName}` }}
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 field-group"
            v-for="(field, idx) in fieldGroup.fields"
            :key="idx"
          >
            <p class="m-0 sub">
              <span class="text-white-50">{{ field.displayName }}</span>
              <button
                class="btn-tooltip"
                v-tooltip.top-center="fieldTooltips[field.name]"
                v-if="fieldTooltips[field.name]"
              >
                <i class="far fa-clock"></i>
              </button>
            </p>
            <div class="form-group">
              <input
                type="number"
                v-model="kpiInfo[field.name]"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <button class="btn btn-primary" @click="saveKpi">
              Save kpi data
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="data p-3 history" v-if="!loading && hasCashBalance && kpiHistory.length > 0">
                <h5 class="mb-4">HISTORY</h5>
                <div class="row mb-1">
                    <div class="col-12">
                        <div class="table admin-table" ref="table">
                            <div class="header">
                                <div class="date">Month</div>
                                <div v-for="(fieldGroup, pos) in kpiFields" :key="pos">{{fieldGroup.groupName}}</div>
                                <div class="date">Submitted Date</div>
                            </div>
                            <div class="body">
                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
a.nav-link {
  color: #409eff;
}

.dropdown {
  button {
    color: #949ba2;
    border: 1px solid #70707038;
    background-color: #494b54;
    // background-color: rgba(224,231,255,0.4);
    // border: 1px solid #e0e7ff;
    box-shadow: unset;
    max-width: 200px;
    padding: 10px;
    text-align: left;
    width: 100%;
    position: relative;
    &.disabled {
      cursor: no-drop;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .dropdown-menu {
    max-width: 200px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    span {
      line-height: 30px;
    }
    background-color: #494b54;
  }
  .dropdown-item {
    color: #949ba2;
    &:hover {
      color: #ffffff;
    }
  }
}
.btn-tooltip {
  color: #8798ad;
  line-height: unset;
  border: 0px;
  background-color: transparent;
}
.field-group {
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 0;
  }
  p.sub {
    display: table-cell;
    vertical-align: middle;
  }
}
.field-name {
  text-decoration: underline;
  font-weight: 600;
}
</style>

<script src="./index.js"></script>
