<template>
  <div class="container p-3 eventInfo">
    <div class="row mb-3">
      <div class="col">
        <label class="subject">GENERAL INFORMATION</label>
      </div>
      <div
        class="col d-flex align-items-center justify-content-end"
        v-if="eventInfoData && eventInfoData.status"
      >
        <label class="lb_text me-1">Status:</label>
        <div class="sp_status m-0">
          <span>{{ $filters.getName(eventInfoData.status, statusList) }}</span>
        </div>
      </div>
    </div>
    <div class="row" v-if="isShowCheckbox">
      <div class="col-6 mb-1">
        <a style="color: white; font-weight: 300"
          >User Type
          <i style="color: red; font-weight: 400; font-size: 80%">*</i>
        </a>
      </div>
    </div>
    <div class="row mb-3" v-if="isShowCheckbox">
      <div class="col-2">
        <Checkbox
          title="Investor"
          v-model:value="investor"
          :autocomplete="false"
          :isHorizontal="true"
        />
      </div>
      <div class="col-2">
        <Checkbox
          title="Founder"
          v-model:value="founder"
          :autocomplete="false"
          :isHorizontal="true"
        />
      </div>
      <div class="col-2">
        <Checkbox
          title="Member"
          v-model:value="member"
          :autocomplete="false"
          :isHorizontal="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col error" v-if="errorMsg.userType != ''" style="color: red">
        {{ errorMsg.userType }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TextField
          title="Event Name"
          v-model:value="form.name"
          name="name"
          :autocomplete="false"
          :errorMsg="errorMsg.name"
          :fieldRequire="true"
          placeholder="Enter your event name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TextField
          title="Location"
          v-model:value="form.location"
          name="location"
          :autocomplete="false"
          :errorMsg="errorMsg.location"
          :fieldRequire="true"
          placeholder="Enter your event location"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TextArea
          title="Description"
          v-model:value="form.description"
          name="description"
          :autocomplete="false"
          :errorMsg="errorMsg.description"
          :fieldRequire="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="title-date">Start Date (EST)</div>
          <div class="col-12 col-md-7 mb-3">
            <Datepicker
              v-model:value="startDateTime"
              placeholder="MM/DD/YYYY"
              :enableTimePicker="false"
              :autocomplete="false"
              :fieldRequire="true"
              field="black"
              :autoApply="false"
              :minDate="convertTZ(new Date())"
              :maxDate="startDateMilestone || ''"
            />
          </div>
          <div class="col-12 col-md-5 mb-4">
            <Datepicker
              v-model:value="startTime"
              placeholder="--:-- --"
              :autocomplete="false"
              :fieldRequire="true"
              :enableTimePicker="true"
              field="hidden-icon"
              :autoApply="false"
              isTimePicker
              :is24="false"
            />
          </div>
          <div class="md-error" v-if="errorMsg.startDate">
            {{ errorMsg.startDate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="title-date">End Date (EST)</div>
          <div class="col-12 col-md-7 mb-3">
            <Datepicker
              v-model:value="endDateTime"
              placeholder="MM/DD/YYYY"
              :enableTimePicker="false"
              :autocomplete="false"
              :fieldRequire="true"
              field="black"
              :autoApply="false"
              :disabled="
                !startDateTime ||
                !startDateTime?.years ||
                !startDateTime?.months ||
                !startDateTime?.days
                  ? true
                  : false
              "
              :minDate="
                !endDateMilestone
                  ? new Date(
                      `${startDateTime?.years}-${startDateTime?.months}-${startDateTime?.days}`
                    )
                  : endDateMilestone
              "
            />
          </div>
          <div class="col-12 col-md-5 mb-4">
            <Datepicker
              v-model:value="endTime"
              placeholder="--:-- --"
              :autocomplete="false"
              :fieldRequire="true"
              field="hidden-icon"
              :enableTimePicker="true"
              :autoApply="false"
              isTimePicker
              :is24="false"
              :disabled="
                !startDateTime ||
                !startDateTime?.years ||
                !startDateTime?.months ||
                !startDateTime?.days
                  ? true
                  : false
              "
            />
          </div>
          <div class="md-error" v-if="errorMsg.endDate">
            {{ errorMsg.endDate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <template v-if="eventInfoData">
          <NewButton
            v-if="eventInfoData.status === 'INPROGRESS'"
            class="me-1"
            :loading="loading"
            :disabled="loading"
            @click="updateEvent"
            >Save changes</NewButton
          >
          <NewButton
            v-if="eventInfoData.status === 'INPROGRESS'"
            :loading="loading"
            :disabled="loading"
            @click="completeEvent"
            >Complete Event</NewButton
          >
          <NewButton
            v-if="eventInfoData.status === 'COMPLETED'"
            :loading="loading"
            @click="cancelEvent"
          >
            Cancel Event</NewButton
          >
        </template>
        <template v-if="!eventInfoData">
          <NewButton :loading="loading" :disabled="loading" @click="createEvent"
            >Create event</NewButton
          >
        </template>
      </div>
    </div>

    <ScheduleModel
      ref="itemScheduleModal"
      :key="itemScheduleModalKey"
      @completeEventStatus="completeEventStatus"
    />

    <Dialog ref="dialog">
      <template #customFooter>
        <NewButton @click="confirmFunc('scheduleSend')"
          >Schedule to send</NewButton
        >
        <NewButton @click="confirmFunc('invitationSend')"
          >Send invitation</NewButton
        >
      </template>
    </Dialog>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.eventInfo {
  background: #3a3e47;
  border-radius: 5px;
  background-color: #292c33;

  @media screen and (max-width: 575px) {
    margin-right: 0px !important;
  }

  .header-section {
    margin: 0 10px;
  }

  input {
    background-color: #141e34;
    border: 1px solid rgba(#ffffff, 0.5);
    border-radius: 5px;
    height: 38px;
    line-height: 38px;
    color: #fff;
  }

  .subject {
    font-weight: 700;
    color: #f1f1f1;
  }
}

.title {
  font-weight: 500;
  color: #fff;
}

.sp_status {
  color: #a7a7a7;
  margin: 8.5px 20px 7px 10px;
}

.note {
  border: 1px solid rgba(213, 213, 213, 0.1);
  margin: 0 15px;
  padding: 15px;
  border-radius: 5px;

  .lb_text_note {
    font-weight: 300;
    color: #a7a7a7;
  }
}

.lb_text {
  display: flex;
  font-weight: 300;
  color: #a7a7a7;
  margin-bottom: 0;
}

.title-date {
  font-family: "ProximaNova";
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 5px;

  &::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}

.eventInfo :deep(.field-color-hidden-icon) {
  .dp__icon {
    display: none !important;
  }

  .dp__input_icon_pad {
    padding-left: 12px !important;
    color: #949ba2;
  }
}

.md-error {
  color: #f52727;
  font-size: 0.8em;
  margin-top: 67px;
  position: absolute;
  width: auto;
}

.eventInfo :deep(.modal) {
  .modal-dialog {
    max-width: 800px !important;
  }
}
</style>
