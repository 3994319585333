<template>
  <div class="fuel-the-mind-container">
    <div class="fuel-the-mind-main">
      <div class="fuel-the-mind-inner container-v2">
        <div class="text-a-wrapper f-text-1">
          <h1 class="heading">Fuel The Mind</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import ScrollOut from "scroll-out";

export default {
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [],
      translateY: 110,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.translateY = 48;
    }
    this.handleScrollTextReveal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();

      ScrollOut({
        targets: ".f-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });
    },

    handleSliceText() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".f-text-1 .heading");
      if (!textWrapper) return;
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );
    },

    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".f-text-1 .heading");
      if (!textWrapper) return;
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".f-text-1 .letter",
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fuel-the-mind-container {
  color: #fff;

  background: url("../../../../assets/images/v2/fuel-the-mind-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: center 20%;

  @media (min-width: 1500px) {
    background-position: center 30%;
  }
}
.fuel-the-mind-main {
  width: 100%;
  min-height: calc(100vh - 74px);

  display: flex;
  align-items: center;

  .fuel-the-mind-inner {
    display: flex;
    align-items: center;
    height: 80%;

    margin-top: 10%;

    @media (min-width: 768px) {
      margin-top: 20%;
    }
    @media (min-width: 1500px) {
      margin-top: 15%;
    }
  }

  .heading {
    font-size: 40px;
    font-weight: 900;

    @media (min-width: 576px) {
      font-size: 50px;
    }

    @media (min-width: 768px) {
      font-size: 110px;
      line-height: 110px;
      letter-spacing: -0.02em;
    }
  }
}
</style>
