import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/web/pdf_viewer.css";
import { PDFViewer, EventBus } from "pdfjs-dist/web/pdf_viewer.js";
import resizeSensor from "vue3-resize-sensor";
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`; // 2.0.943

// stable version suggestion:  "pdfjs-dist": "2.0.943",

import { defineComponent } from "vue";

// import * as pdfjsLib from 'pdfjs-dist/webpack.js'
// import 'pdfjs-dist/web/pdf_viewer.css'
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
// import { PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer.js'
// // import resizeSensor from 'vue-resize-sensor'

export default defineComponent({
  components: {
    resizeSensor,
  },
  props: {
    src: {
      type: [String, Object],
      default: "",
    },
    page: {
      type: Number,
      default: 1,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    scale: {
      type: [Number, String],
      default: 1,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      _pdfViewer: null,
      currentScale: null,
      loading: true,
      linkScanner: {
        finished: false,
        interval: null,
        scanCount: 0,
        maxScanCount: 5,
      },
    };
  },
  watch: {
    src: function (_val) {
      this.getDocument();
    },
  },
  methods: {
    resizeScale: function (size) {
      if (this.resize) {
        if (size.width > 1024) {
          this.drawScaled("page-actual");
        } else {
          this.drawScaled("page-width");
        }
      }
    },
    drawScaled: function (scale) {
      if (!scale) {
        switch (this.currentScale) {
          case "page-actual":
            scale = "page-width";
            break;

          case "page-width":
            scale = "page-fit";
            break;

          default:
            scale = "page-actual";
            break;
        }
      }
      this.currentScale = scale;
      this._pdfViewer._setScale(this.currentScale);
    },
    getDocument() {
      pdfjsLib.getDocument(this.src).promise.then((pdfDocument) => {
        this._pdfViewer.setDocument(pdfDocument);
        this.loading = false;
        this.$refs.viewerContainer.addEventListener("click", (e) => {
          if (e.target.href) {
            window.open(e.target.href, "_blank");
            e.preventDefault();
          }
        });
      });
    },
  },
  // doc: mounted hook is not called during server-side rendering.
  mounted: function () {
    // pdfjsLib.disableWorker = true
    let viewerContainer = this.$refs.viewerContainer;
    let viewer = this.$refs.viewer;
    let eventBus = new EventBus();
    eventBus.on("pagesinit", (e) => {
      var containerWidth = e.source.container.clientWidth;
      var pageWidth = e.source._pages[0].viewport.width;

      var scale = this.scale ? this.scale : "page-width";
      if (pageWidth > containerWidth) {
        scale = "page-width";
      }
      this.drawScaled(scale);
    });
    this._pdfViewer = new PDFViewer({
      container: viewerContainer,
      viewer: viewer,
      eventBus: eventBus,
    });
    this.getDocument();
  },
});
