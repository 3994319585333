import AdminServices from "@/services/admin";
import _ from "lodash";
import router from "@/config/router";
import moment from "moment";
import notify from "../../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["userId"],
  data() {
    return {
      loading: false,
      textMonth: 0,
      textYear: new Date().getFullYear(),
      submitDate: null,
      partnerPath: router.partner.path,
      partnerRouteName: router.partner.name,
      goalInfo: {},
      goalFields: [],
      goalHistory: [],
      fieldTooltips: {},
    };
  },
  mounted() {
    if (!this.userId) {
      this.$router.push(this.partnerPath);
    }

    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("resize", this.__resizeHandler);
    next();
  },
  created() {
    this.getPartner();
  },
  methods: {
    getPartner: function () {
      this.loading = true;
      AdminServices.getPartnerGoalFields(this.userId).then((res) => {
        if (!res.data.d) {
          this.$router.push(this.partnerPath);
        } else {
          this.goalFields = res.data.d.goal_fields;
          this.changeMonth(new Date().getMonth() + 1);
        }
        this.loading = false;
      });
    },
    changeMonth: function (str) {
      if (this.textMonth != str) {
        this.textMonth = str;
        this.getGoal();
      }
    },
    changeYear: function (str) {
      if (this.textYear != str) {
        this.textYear = str;
        this.getGoal();
      }
    },
    getGoal: function () {
      AdminServices.getPartnerGoal(
        this.userId,
        parseInt(this.textYear),
        parseInt(this.textMonth)
      ).then((resp) => {
        if (resp.data.ok) {
          this.fieldTooltips = {};
          if (resp.data.d.length > 0) {
            this.submitDate = resp.data.d[0].created_at;
            this.goalInfo = resp.data.d[0].data;

            let currentVal = _.cloneDeep(resp.data.d[0]);
            let histories = resp.data.d.slice(1);
            for (let i = 0; i < histories.length; i++) {
              this.goalFields.forEach((fieldGroup) => {
                fieldGroup.fields.forEach((field) => {
                  if (
                    currentVal.data[field.name] !==
                    histories[i].data[field.name]
                  ) {
                    this.fieldTooltips[field.name] = this.fieldTooltips[
                      field.name
                    ]
                      ? this.fieldTooltips[field.name] +
                        `<br />${this.buildTooltip(histories[i], field.name)}`
                      : `${this.buildTooltip(histories[i], field.name)}`;

                    currentVal.data[field.name] = histories[i].data[field.name];
                  }
                });
              });
            }
          } else {
            this.submitDate = null;
            this.goalInfo = {};
          }

          this.$nextTick().then(() => {
            this.__resizeHandler();
          });
        }
      });
    },
    buildTooltip(item, property) {
      let value = item.data[property];
      if (!value) {
        value = "None";
      }

      let result = `${moment(item.created_at).format(
        "MMM DD, YY HH:mm:ss"
      )} - <span class="his-data" style="color: #ffc107;">${value}</span>`;

      return result;
    },
    saveGoal: function () {
      this.goalFields.forEach((fieldGroup) => {
        fieldGroup.fields.forEach((field) => {
          this.goalInfo[field.name] = parseFloat(this.goalInfo[field.name]);
        });
      });

      AdminServices.saveGoal(
        this.userId,
        parseInt(this.textYear),
        parseInt(this.textMonth),
        this.goalInfo
      ).then((resp) => {
        if (resp.data.ok) {
          notify.success("Success", "Goal submitted succesfully.");
        } else {
          notify.error("Error", "Goal submitted error.");
        }
      });
    },
    getPreviousMonthData: function () {
      let lastMonth = parseInt(this.textMonth) - 1;
      let lastMonthYear = parseInt(this.textYear);
      if (lastMonth === 0) {
        lastMonthYear--;
        lastMonth = 12;
      }

      AdminServices.getPartnerGoal(this.userId, lastMonthYear, lastMonth).then(
        (resp) => {
          if (resp.data.ok) {
            if (resp.data.d.length > 0) {
              this.goalInfo = resp.data.d[0].data;
            } else {
              notify.warn(
                "Previous month data is empty",
                "Previous month data is empty."
              );
            }
          }
        }
      );
    },
    __resizeHandler: function () {
      this.goalFields.forEach((fieldGroup) => {
        let maxHeight = 0;
        let subs = $(this.$refs[fieldGroup.groupName]).find("p");
        subs.each((_, ele) => {
          $(ele).css("height", "");
          if (maxHeight < ele.clientHeight) {
            maxHeight = ele.clientHeight;
          }
        });
        subs.each((_, ele) => {
          if (ele.clientHeight < maxHeight) {
            $(ele).css("height", `${maxHeight}px`);
          }
        });
      });
    },
  },
});
