<template>
  <transition name="fade" v-if="isShow">
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="maskModal" @click="hideModal"></div>
      <div class="modal-dialog m-0" role="document">
        <div class="modal-content py-3">
          <div class="modal-header pt-0 pb-0">
            <h5 class="title">Reviewers rating</h5>
            <button type="button" class="btn close" @click="hideModal">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body py-0">
            <div v-loading="loading">
              <div v-if="dataReviewerRate?.length > 0">
                <div
                  class="col-12 item line py-3"
                  v-for="(reviewer, idx) in dataReviewerRate"
                  :key="`reviewer_${idx}`"
                  :class="{
                    'd-none':
                      reviewer.stage_id !== stageId && currentStage !== -1,
                  }"
                >
                  <div
                    class="row d-flex reviewer"
                    v-if="reviewer.stage_id === stageId || currentStage === -1"
                  >
                    <div class="block">
                      <span class="nickname">{{ reviewer.created_by }} | </span
                      ><span class="email">{{
                        reviewer.created_user_email
                      }}</span
                      ><br />
                      <span class="date">{{
                        $filters.parseEstTime(reviewer.rating_at)
                      }}</span>
                    </div>
                    <div class="block text-right">
                      <span
                        v-for="(item, index) in 5"
                        :key="`star_${index}`"
                        class="star d-inline-block me-2"
                        :class="{ active: index < reviewer.rating }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 25.429 25.289"
                        >
                          <g id="star" transform="translate(0)">
                            <g
                              id="Group_6"
                              data-name="Group 6"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M20.931,5.889l2.244,4.488a2.8,2.8,0,0,0,1.8,1.337l4.058.668c2.594.43,3.2,2.308,1.337,4.185L27.2,19.734a2.748,2.748,0,0,0-.652,2.308l.907,3.915c.716,3.087-.939,4.3-3.66,2.674l-3.8-2.26a2.7,2.7,0,0,0-2.514,0l-3.8,2.26c-2.721,1.607-4.376.414-3.66-2.674l.907-3.915a2.777,2.777,0,0,0-.652-2.308L7.1,16.567c-1.862-1.862-1.257-3.74,1.337-4.185l4.058-.668a2.749,2.749,0,0,0,1.8-1.337l2.244-4.488C17.733,3.454,19.706,3.454,20.931,5.889Z"
                                transform="translate(-6.021 -4.062)"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style scoped lang="scss">
.modal.show {
  display: block;
  .maskModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.72);
  }
  .modal-dialog {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid transparent;
    border-radius: 18px;
    overflow: hidden;
    width: 100%;
    max-width: 464px;
  }
  .modal-header {
    border-bottom: 1px solid rgba(#dee2e6, 0.1);
  }
  .modal-body {
    .item:last-child {
      border-bottom: 0;
    }
    .line {
      border-bottom: 1px solid rgba(#000, 0.1);
    }
    .reviewer {
      .block {
        flex: 1;
      }
      .nickname,
      .email {
        font-size: 14px;
      }
      .date {
        font-size: 12px;
        color: rgba(#ccc, 0.3);
      }
      span.star {
        line-height: 43px;
        path {
          fill: #24262d;
        }
        &.active {
          path {
            fill: #0095ff;
          }
        }
      }
    }
  }
  .modal-content {
    padding: 30px 0;
    background-color: #393c45;
    color: #ccc;
    .title {
      font-weight: bold;
      font-size: 16px;
      color: #ccc;
      line-height: 40px;
    }
    .close {
      line-height: 40px;
      border: 0px;
    }
  }
}
</style>
<script src="./reviewRate.js"></script>
