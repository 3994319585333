<template>
  <div class="custom-mb">
    <label v-if="title" class="title" :for="name"
      >{{ title }}
      <template v-if="fieldRequire"
        ><small class="require">*</small></template
      ></label
    >
    <div
      :class="[
        `text-field ${size ? 'size-' + size : ''} ${
          hideError ? 'hide-error' : ''
        }`,
      ]"
    >
      <template v-if="!isTimePicker">
        <date-picker
          :id="name"
          v-model="valueDatePicker"
          :disabled="disabled"
          :placeholder="placeholder"
          :closeOnAutoApply="true"
          @update:modelValue="handleDate"
          :class="`field-color-${field}`"
          :minDate="minDate ? new Date(minDate) : ''"
          :maxDate="maxDate ? new Date(maxDate) : ''"
          :ignoreTimeValidation="ignoreTimeValidation"
          :enableTimePicker="isMonthlyPicker ? false : enableTimePicker"
          :escClose="escClose"
          :auto-apply="autoApply"
          :clearable="false"
          :range="multiCalendars ? multiCalendars : range"
          :multiCalendars="multiCalendars"
          :monthPicker="isMonthlyPicker"
          :dark="true"
        />
      </template>
      <template v-else>
        <date-picker
          :id="name"
          v-model="valueTimePicker"
          :disabled="disabled"
          :placeholder="placeholder"
          :closeOnAutoApply="true"
          @update:modelValue="handleDate"
          :class="`field-color-${field}`"
          :ignoreTimeValidation="ignoreTimeValidation"
          enableTimePicker
          :escClose="escClose"
          :is24="is24"
          timePicker
          :auto-apply="autoApply"
          :clearable="false"
          :dark="true"
        />
      </template>
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.title {
  margin-top: 0px;
  .require {
    color: #f52727;
  }
}

.text-field {
  position: relative;
  :deep() .dp__disabled {
    cursor: no-drop;
  }
  :deep() .dp__input {
    border: none;
  }
}
input {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 4px;
  min-height: 34px;
  padding: 2px 25px 2px 7px;
  font-size: 0.9em;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #fff;
    color: black;
  }
}
.input-wrapper {
  margin-bottom: 25px;
}
.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.remove-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease;
  &:hover {
    border: 1px solid #4bd;
    color: #000;
  }
}
.field-color-black {
  opacity: 1;
  :deep() .dp__input_wrap {
    .dp__input {
      // background-color: #494b54;
      color: #949ba2;
      border: 0px;
      max-height: 34px;
    }
  }
}
</style>
<style>
.dp__theme_dark {
  --dp-background-color: #494b54 !important;
  --dp-text-color: #ffffff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #ffffff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #494b54;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #fff !important;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
.dp__menu {
  font-size: 14px !important;
}
.dp__calendar_row {
  margin: 0 !important;
}
.dp__cell_inner {
  height: 30px !important;
}
.dp__input {
  max-height: 34px;
}
</style>
