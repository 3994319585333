import { defineComponent } from "vue";
import { felixNumber } from "../../misc/commons";

export default defineComponent({
  name: "NumberField",
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "number" },
    autocomplete: { type: String, default: "" },
    value: { default: "0" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    maxLength: { default: 10 },
    indexField: { type: Number, default: 0 },
    fieldRequire: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      isInputActive: false,
      isDirty: false,
    };
  },
  mounted() {
    var inputBox = this.$el.querySelector("input");
    var invalidChars = ["-", "+", "e", "E"];
    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("input", function () {
      this.value = this.value.replace(/[e\+\-]/gi, "");
    });
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  methods: {
    isNumber: function (e) {
      e = e ? e : window.event;
      var charCode = e.which ? e.which : e.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    changeNumber: function (el) {
      this._value = Math.floor(el.value) <= 0 ? 1 : Math.floor(el.value);
    },
    changeCurrency: function (el) {
      this._value = felixNumber(el.value, null, 2);
    },
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          if (this.value) return this.value.toString();
          return "";
        } else {
          // User is not modifying now. Format display value for user interface
          if (this.value) {
            return (
              "$ " +
              Number(this.value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
          }
          return !this.isDirty ? "" : "$ 0.00";
        }
      },
      set: function (modifiedValue) {
        this.isDirty = true;

        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("update:value", newValue.toString());
      },
    },
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
