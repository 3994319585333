import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import topHeader from '../topHeader'
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        topHeader
    },
    mounted() {
        // Create chart instance
        let chart = am4core.create("logarithmicScaleChart", am4charts.XYChart);

        chart.colors.step = 3;

        // Add data
        chart.data = [{
            "y": 100,
            "x": 14,
            "value": 59
        }, {
            "y": 115,
            "x": 3,
            "value": 50
        }, {
            "y": 100,
            "x": 8,
            "value": 19
        }, {
            "y": 100,
            "x": 5,
            "value": 65
        }, {
            "y": 110,
            "x": -4,
            "value": 92,
        }, {
            "y": 120,
            "x": 1,
            "value": 105
        }, {
            "y": 100,
            "x": 6,
            "value": 100
        }];

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = -5;
        xAxis.max = 20;
        xAxis.renderer.minGridDistance = 100;
        xAxis.renderer.strokeDasharray = 4;
        xAxis.renderer.strokeWidth = 3;
        xAxis.title.text = "Months of life";
        

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = -200;
        yAxis.max = 400;
        yAxis.renderer.minGridDistance = 50;
        yAxis.title.text = "Success rate";

        // Create series #1
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "y";
        series.dataFields.valueX = "x";
        series.strokeOpacity = 0;
        

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.strokeOpacity = 0.2;
        bullet.stroke = am4core.color("#ffffff");
        bullet.nonScalingStroke = true;
        bullet.tooltipText = "x:{valueX} y:{valueY}";
        series.heatRules.push({
            target: bullet.circle,
            min: 10,
            max: 60,
            property: "radius"
        });

        var yrange = yAxis.axisRanges.create();
        yrange.value = 100;
        yrange.grid.stroke = am4core.color("#396478");
        yrange.grid.strokeWidth = 2;
        yrange.grid.strokeOpacity = 1;

        var xrange = xAxis.axisRanges.create();
        xrange.value = 7.5;
        xrange.grid.stroke = am4core.color("#396478");
        xrange.grid.strokeWidth = 2;
        xrange.grid.strokeOpacity = 1;

        
    }
})