<template>
  <div class="adminContent">
    <div class="createEvent pb-3">
      <div v-if="eventInfoData" class="row padding-tab">
        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mr-section left-section px-0 mb-3"
          v-if="screenSize > 991"
        >
          <eventInfo
            key="1"
            @refresh="refresh"
            :eventInfoData="eventInfoData"
            :milestoneMinStartDate="milestoneMinStartDate"
            :milestoneMaxEndDate="milestoneMaxEndDate"
          />
        </div>
        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 right-section px-0 mb-3"
        >
          <milestone @updateDateMilestone="updateDateMilestoneMain" />
        </div>
        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mr-section left-section px-0"
          v-if="screenSize <= 991"
        >
          <eventInfo
            key="1"
            :eventInfoData="eventInfoData"
            :milestoneMinStartDate="milestoneMinStartDate"
            :milestoneMaxEndDate="milestoneMaxEndDate"
            @refresh="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.adminContent {
  .createEvent {
    .padding-tab {
      padding: 15px 0 !important;

      .mr-section {
        margin-right: 15px;
      }
    }

    input {
      background-color: #141e34;
      border: 1px solid rgba(#ffffff, 0.5);
      border-radius: 5px;
      height: 38px;
      line-height: 38px;
      color: #fff;
    }

    .left-section {
      background: #3a3e47;
      border-radius: 5px;
      background-color: #292c33;
      border: 1px solid rgba(213, 213, 213, 0.1);

      @media screen and (max-width: 575px) {
        margin-right: 0px !important;
      }

      .header-section {
        margin: 0 10px;
      }
    }

    .right-section {
      flex: 1;
      background: #3a3e47;
      border-radius: 5px;
      background-color: #292c33;
      border: 1px solid rgba(213, 213, 213, 0.1);

      @media screen and (max-width: 1200px) {
        margin-top: 15px;
        padding-bottom: 15px;
      }
    }

    .milestone-section {
      flex: 1;
      background: #3a3e47;
      border-radius: 5px;
      background-color: #292c33;
      border: 1px solid rgba(213, 213, 213, 0.1);
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .subject {
    font-weight: 700;
    color: #f1f1f1;
    margin-bottom: 0;
  }

  .error {
    position: absolute;
    top: 225px;
    color: #f52727;
    font-size: 0.8em;
    margin-top: 3px;
  }

  .note {
    border: 1px solid rgba(213, 213, 213, 0.1);
    margin: 0 15px;
    padding: 15px;
    border-radius: 5px;
  }

  .milestone {
    border: 1px solid rgba(213, 213, 213, 0.1);
    position: relative;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;

    label {
      margin-bottom: 1px;
    }
  }

  .icon {
    position: absolute;
    right: 0px;
    top: 0px;

    .btn {
      width: 34px;
      height: 34px;

      @media screen and (max-width: 1299px) {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    @media (max-width: 1299px) {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .lb_text_note {
    font-weight: 300;
    color: #a7a7a7;
  }

  .lb_text {
    display: flex;
    font-weight: 300;
    color: #a7a7a7;
    margin-bottom: 0;
  }

  .sub_text {
    width: 70% !important;
    word-break: break-all;
    font-weight: 600;
    color: #f1f1f1;

    @media screen and (max-width: 1350px) {
      width: 60%;
    }
  }

  .sub-menu {
    background: #3a3e47;
  }

  .table-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-item {
    cursor: pointer;

    .page-link {
      background-color: transparent;
      border: 1px solid #585d6e;
      width: 35px;
      height: 35px;
      color: #949ba2;
      display: block;
      text-align: center;
    }

    &.active {
      .page-link {
        background-color: #4b596a;
        color: white;
      }
    }
  }

  .title {
    color: #a7a7a7 !important;
  }

  .col-name {
    flex: 0 0 120px;
    max-width: unset;

    @media screen and (max-width: 768px) {
      flex: 0 0 96px;
    }
  }

  .col-value {
    flex: 1;
    max-width: unset;
  }

  .sub_doc {
    width: 70% !important;
    word-break: break-all;
    font-weight: 600;
    color: #0069d9;

    &:hover {
      color: #f1f1f1;
    }

    @media screen and (max-width: 1350px) {
      width: 60%;
    }
  }

  .styleButton {
    padding-left: 15px;

    @media screen and (max-width: 315px) {
      display: flex;
      flex-direction: column;
    }
  }

  .btnChange {
    @media screen and (max-width: 315px) {
      margin-bottom: 10px;
    }
  }
}
</style>
