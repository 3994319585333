import { defineComponent } from 'vue'
import adminServices from '../../services/admin'

import Table, { debounce } from '../Table'
import TextField from '../TextField'

export default defineComponent({
    name: 'SelectEmailTemplate',
    components: {
        Table,
        TextField,
    },
    props: {
        value: { type: Object, default: {id: null, name: null} }
    },
    emits: ['update:value'],
    data() {
        return {
            rows: [],
            totalItems: 0,
            loading: false,
			paging: {
                page: 1,
                size: 10,
                total: 0
            },
            sortField: '',
            sortDirection: '',
            orderBy: '',
            filteredName: '',
            filteredSubject: '',
            columns: [
				{
					id: 'checkbox',
					name: ''
				},
				{
					id: 'name',
					name: 'Name',
                    sort: true,
                    direction: ''
				},
				{
					id: 'subject',
					name: 'Subject',
				}
			]
        }
    },
	watch: {
		paging: {
			deep: true,
            handler() {
                debounce(this.getAll)
            }
		},
        'filteredName' () {
            this.setPageDefault()
            debounce(this.getAll)
        },
        'filteredSubject' () {
            this.setPageDefault()
            debounce(this.getAll)
        },
        sortDirection(v) {
            this.setPageDefault()
            debounce(this.getAll)
        },
        columns: {
            deep: true,
            handler(v) {
                let col = v.find(p => typeof p.sort === 'boolean' && p.sort && p.direction)
                if (!col) return
                this.orderBy = `${col.id} ${col.direction}`
                debounce(this.getAll)
            }
        }
	},
    computed: {
        _value: {
            get() {
                return this.value && this.value.id ? this.value : null
            },
            set(val) {
                this.$emit('update:value', val && val.id ? val : null)
            }
        }
    },
    methods: {
        setPageDefault() {
            this.paging = {
                page: 1,
                size: 10,
                total: 0,
            };
        },
        async getAll() {
            this.loading = true
            const res = await adminServices.getAllMessageTemplates({
                paging: {
                    page: this.paging.page,
                    size: this.paging.size
                },
                filteredName: this.filteredName,
                filteredSubject: this.filteredSubject,
                orderBy: this.orderBy
            })
            if (res.data.ok) {
                this.rows = res.data.d.c
                this.totalItems = res.data.d.t
            }
            this.loading = false
        },
        truncateText(txt, length) {
            return txt.length > length ? `${txt.substring(0, length)}...` : txt
        },
        rowClick(item) {
            this._value = item
        },
        updatePaging(v) {
            this.paging = Object.assign(this.paging, v)
        }
    }
})