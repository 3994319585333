import { defineComponent } from "vue";
import router, { PERMISSIONS } from "@/config/router";
import appStore, { AUTH_ACTIONS } from "../../../store/app";

export default defineComponent({
  data() {
    return {
      homePath: "/",
      overviewName: router.overview.name,
      overviewPath: router.overview.path,
      surveyName: router.reviewSurvey.name,
      surveyPath: router.reviewSurvey.path,
      teamName: router.team.name,
      teamPath: router.team.path,
      portfolioName: router.portfolios.name,
      portfolioPath: router.portfolios.path,
      fuelTheMindName: router.fuelTheMind.name,
      fuelTheMindPath: router.fuelTheMind.path,
      profileName: router.profile.name,
      profilePath: router.profile.path,
      dashboardName: router.dashboard.name,
      dashboardPath: router.dashboard.path,
      kpiName: router.kpi.name,
      kpiPath: router.kpi.path,
      kpiViewName: router.kpiView.name,
      kpiViewPath: router.kpiView.path,
      partnerPath: router.partner.path,
      partnerName: router.partner.name,
      roboticadminPath: router.roboticadmin.path,
      roboticadminName: router.roboticadmin.name,
      usersName: router.users.name,
      usersPath: router.users.path,
      emailQueueName: router.emailQueue.name,
      emailQueuePath: router.emailQueue.path,
      foundersName: router.founders.name,
      foundersPath: router.founders.path,
      reportPath: router.kpiReport.path,
      reportName: router.kpiReport.name,
      investorsPath: router.investors.path,
      investorsName: router.investors.name,
      investorRolesPath: router.investorRoles.path,
      investorRolesName: router.investorRoles.name,
      messageTmplsPath: router.messageTmpls.path,
      messageTmplsName: router.messageTmpls.name,
      socialPath: router.social.path,
      socialName: router.social.name,
      dataroomPath: router.dataroom.path,
      dataroomName: router.dataroom.name,
      irConfigPath: router.irConfig.path,
      irConfigName: router.irConfig.name,
      imConfigPath: router.imConfig.path,
      imConfigName: router.imConfig.name,
      sharedUserDataroomPath: router.sharedUserDataroom.path,
      sharedUserDataroomName: router.sharedUserDataroom.name,
      rptInvestorEngagementPath: router.rptInvestorEngagement.path,
      rptInvestorEngagementName: router.rptInvestorEngagement.name,
      rptInvestorFocusPath: router.rptInvestorFocus.path,
      rptInvestorFocusName: router.rptInvestorFocus.name,
      lPMediaCallReplayPath: router.lPMediaCallReplay.path,
      lPMediaCallReplayName: router.lPMediaCallReplay.name,
      lPMediaCoInvestmentOpPath: router.lPMediaCoInvestmentOp.path,
      lPMediaCoInvestmentOpName: router.lPMediaCoInvestmentOp.name,
      lPMediaSeededCompaniesPath: router.lPMediaSeededCompanies.path,
      lPMediaSeededCompaniesName: router.lPMediaSeededCompanies.name,
      // lPMediaIntroVideoPath: router.lPMediaIntroVideo.path,
      // lPMediaIntroVideoName: router.lPMediaIntroVideo.name,
      fundPath: router.fund.path,
      fundName: router.fund.name,
      dealPath: router.deal.path,
      dealName: router.deal.name,
      // dealAreaPath: router.dealArea.path,
      // dealAreaName: router.dealArea.name,
      // ownerTypePath: router.ownerType.path,
      // ownerTypeName: router.ownerType.name,
      stagesPath: router.stages.path,
      stagesName: router.stages.name,
      // dealOverviewPath: router.dealOverview.path,
      // dealOverviewName: router.dealOverview.name,
      // dealReportPath: router.dealReport.path,
      // dealReportName: router.dealReport.name,
      notificationsPath: router.notifications.path,
      notificationsName: router.notifications.name,
      dealFounderPath: router.dealFounder.path,
      dealFounderName: router.dealFounder.name,
      eventPath: router.event.path,
      eventName: router.event.name,
      newEventPath: router.newEvent.path,
      newEventName: router.newEvent.name,
      editEventPath: router.editEvent.path,
      editEventName: router.editEvent.name,
      PERMISSIONS: PERMISSIONS,
      windowWidth: window.innerWidth,
      mapchartPath: router.mapchart.path,
      mapchartName: router.mapchart.name,
      perspectiveName: router.perspective.name,
      perspectivePath: router.perspective.path,
      meetFounderName: router.meetFounder.name,
      meetFounderPath: router.meetFounder.path,
      userLoginHistoryName: router.userLoginHistory.name,
      userLoginHistoryPath: router.userLoginHistory.path,
      lpFundPath: router.lpFund.path,
      lpFundName: router.lpFund.name,
      newsLetterPath: router.newsLetter.path,
      newsLetterName: router.newsLetter.name,
    };
  },

  methods: {
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
    isPathActive(paths) {
      const data = paths.some((el) => this.currentRouter.path.includes(el));
      return data;
    },
    renderActive(paths) {
      const pathsEl = paths.split("/");
      const pathsCurrent = this.currentRouter.path.split("/");
      if (pathsEl.length === pathsCurrent.length) {
        return this.currentRouter.path === paths ? "router-link-active" : "";
      } else {
        return this.currentRouter.path.includes(paths)
          ? "router-link-active"
          : "";
      }
    },
  },

  computed: {
    profile() {
      return appStore.state.profile;
    },
    currentRouter() {
      return appStore.state.currentRoute;
    },
    isShowAdminSidebar() {
      return appStore.state.isShowAdminSidebar;
    },
  },
});
