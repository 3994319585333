import { defineComponent } from "vue";
import Timepicker from "vue3-timepicker";

export default defineComponent({
  components: {
    Timepicker,
  },
  props: {
    format: { type: String, default: "h:i" },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    value: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    direction: { type: String, default: "down" },
    customeClass: { type: String, default: "" },
  },
  data() {
    return {
      test: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let els = this.$el
        .querySelector(".time-picker .select-list .hours")
        .querySelectorAll("li");
      els.forEach((element) => {
        if (
          element.getAttribute("data-key") === 12 ||
          element.getAttribute("data-key") === "12"
        ) {
          element.setAttribute("data-key", "00");
          element.textContent = "00";
        }
      });
    });
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    mask: {
      get() {
        let obj = {
          hh: "12",
          mm: "00",
          A: "AM",
        };

        if (this._value) {
          obj.hh = parseInt(this._value.hh) === 12 ? "00" : this._value.hh;
          obj.mm = this._value.mm;
          obj.A =
            this._value.A === "" && this._value.hh && this._value.mm
              ? parseInt(this._value.hh) <= 12
                ? "AM"
                : "PM"
              : this._value.A;
        }
        return `${obj.hh === "" || !obj.hh ? "hh" : obj.hh}:${
          obj.mm === "" || !obj.mm ? "mm" : obj.mm
        } ${obj.A === "" || !obj.A ? "A" : obj.A}`;
      },
    },
    _value: {
      get() {
        let obj = {
          hh: `${
            this.value
              ? parseInt(this.value.hh) === 0 || !this.value.hh
                ? "12"
                : parseInt(this.value.hh) > 12
                ? parseInt(this.value.hh) - 12 < 10
                  ? "0" + (parseInt(this.value.hh) - 12)
                  : parseInt(this.value.hh) - 12
                : this.value.hh
              : "00"
          }`,
          mm: this.value ? this.value.mm : "00",
          A: this.value ? this.value.A : "AM",
        };
        return obj;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
