<template>
  <div>
    <div class="mb-3">
      <div class="reviewSurvey">
        <div class="data">
          <div class="row m-0">
            <div class="col col12">
              <table class="table table-hover admin-table mb-0">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Survey</th>
                    <th>Founder</th>
                    <th>Score</th>
                    <th style="width: 90px">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData" :key="item">
                    <td>
                      {{ $filters.parseEstTime(item.created_at) }}
                    </td>
                    <td>
                      <router-link
                        class="pointer link"
                        :to="{
                          path: reviewSurveyDetailPath,
                          name: reviewSurveyDetailName,
                          params: { surveyID: item.id },
                        }"
                        >{{ item.name }}</router-link
                      >
                    </td>
                    <!-- <td><a class="pointer link" @click="viewSurveyDetail(item.id)">{{ item.name }}</a></td> -->
                    <td>{{ item.username }}</td>
                    <td>{{ item.score }}</td>
                    <td style="width: 90px">
                      <button
                        type="button"
                        class="btn-primary pointer admin-button py-0"
                        @click="approvedSurvey(item.id)"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                      <button
                        type="button"
                        class="btn-danger pointer admin-button py-0"
                        @click="rejectSurvey(item.id)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="mb-3">
                <pager
                  :loadContentAtPage="loadPage"
                  :page="page.page"
                  :size="page.size"
                  :total="page.total"
                  :pagerFull="false"
                ></pager>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <detaiSurvey
      v-if="isShowDetailSurvey"
      :surveyDetailID="surveyDetailID"
      :hideSurveyDetail="hideSurveyDetail"
      :approvedSurvey="approvedSurvey"
      :rejectSurvey="rejectSurvey"
    />
  </div>
</template>

<script src="./index.js"></script>
