<template>
  <div class="adminContent p-3">
    <div class="searchSurvey mb-3">
      <div class="data p-3">
        <div class="row">
          <div class="col-12 col-md-4">
            <TextField
              title="Founder"
              name="founder-name"
              id="founder-name"
              :autocomplete="false"
              placeholder="Enter founder name"
              v-model:value="dataSearch.founderName"
            />
          </div>
          <div class="col-12 col-md-4">
            <TextField
              title="Company"
              name="company-name"
              id="company-name"
              :autocomplete="false"
              placeholder="Enter company name"
              v-model:value="dataSearch.company"
            />
          </div>
          <div class="col-12 col-md-4">
            <TextField
              title="Email"
              name="email"
              id="email"
              :autocomplete="false"
              placeholder="Enter email"
              v-model:value="dataSearch.email"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-4">
            <div class="form-group custom-mb">
              <label>What technology does your company leverage</label>
              <button
                class="btn btn-block technology text-start w-100"
                type="button"
                id="technique"
                @click="ShowHideisShowTechnology"
              >
                All
              </button>
              <div class="blockrangewrap">
                <div
                  class="blockrange p-0"
                  :style="`z-index: ${isShowTechnology ? 1 : -1}`"
                >
                  <div class="blockSelect" ref="technique">
                    <label for="technique_all" class="dropdown-item pointer">
                      <input
                        type="checkbox"
                        id="technique_all"
                        @change="selectAll($event, 'technique')"
                      />
                      All
                    </label>
                    <label
                      :for="'technique_' + (index + 1)"
                      class="dropdown-item pointer"
                      v-for="(item, index) in dataTechnology"
                      :key="item"
                    >
                      <input type="checkbox" :id="'technique_' + (index + 1)" />
                      {{ item }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group custom-mb">
              <label>Industry Verticals</label>
              <button
                class="btn btn-block industry text-start w-100"
                type="button"
                id="industry"
                @click="ShowHideIndustry"
              >
                All
              </button>
              <div class="blockrangewrap">
                <div
                  class="blockrange p-0"
                  :style="`z-index: ${isShowIndustry ? 1 : -1}`"
                >
                  <div class="blockSelect" ref="industry">
                    <label for="industry_all" class="dropdown-item pointer">
                      <input
                        type="checkbox"
                        id="industry_all"
                        @change="selectAll($event, 'industry')"
                      />
                      All
                    </label>
                    <label
                      :for="'industry_' + (index + 1)"
                      class="dropdown-item pointer"
                      v-for="(item, index) in dataIndustry"
                      :key="item"
                    >
                      <input type="checkbox" :id="'industry_' + (index + 1)" />
                      {{ item }}
                    </label>
                    <label class="dropdown-item" for="other"
                      >Others: <input type="text" id="other"
                    /></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <Datepicker
                v-model:value="dataSearch.dateTime"
                title="Date Time"
                :enableTimePicker="false"
                :multiCalendars="true"
                :range="true"
                field="black"
                :autoApply="false"
              />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-4">
            <div class="form-group custom-mb">
              <label for="">Pre-money valuation</label>
              <button
                class="btn btn-block text-start priceRange w-100"
                @click="ShowHideRange"
              >
                {{
                  !textPriceRange ? `${minPrice} - ${maxPrice}` : textPriceRange
                }}
              </button>
              <div class="blockrangewrap">
                <div
                  class="blockrange"
                  :style="`z-index: ${isShowRange ? 1 : -1}`"
                >
                  <div class="row m-0">
                    <div class="col-6 px-1">
                      <label for="from" class="m-0">From</label>
                      <input
                        type="number"
                        id="from"
                        class="form-control"
                        v-model="minPrice"
                      />
                    </div>
                    <div class="col-6 px-1">
                      <label for="from" class="m-0">To</label>
                      <input
                        type="number"
                        id="to"
                        class="form-control"
                        v-model="maxPrice"
                      />
                    </div>
                  </div>
                  <vue3-slider
                    v-model="rangevalue"
                    tooltip="none"
                    :dotStyle="{ backgroundColor: '#3E99ED' }"
                  />
                  <div class="value">
                    <div class="row mb-2">
                      <div class="col-6 text-left text">$0</div>
                      <div class="col-6 text-right text">$10M</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Stage of development</label>
              <button
                class="btn btn-block development text-start w-100"
                type="button"
                id="development"
                @click="ShowHideDevelopment"
              >
                All
              </button>
              <div class="blockrangewrap">
                <div
                  class="blockrange p-0"
                  :style="`z-index: ${isShowDevelopment ? 1 : -1}`"
                >
                  <div class="blockSelect" ref="development">
                    <label for="development_all" class="dropdown-item pointer">
                      <input
                        type="checkbox"
                        id="development_all"
                        @change="selectAll($event, 'development')"
                      />
                      All
                    </label>
                    <label
                      :for="'development_' + (index + 1)"
                      class="dropdown-item pointer"
                      v-for="(item, index) in dataDevelopment"
                      :key="item"
                    >
                      <input
                        type="checkbox"
                        :id="'development_' + (index + 1)"
                      />
                      {{ item }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Stage of financing</label>
              <button
                class="btn btn-block financing text-start w-100"
                type="button"
                id="financing"
                @click="ShowHideFinancing"
              >
                All
              </button>
              <div class="blockrangewrap">
                <div
                  class="blockrange p-0"
                  :style="`z-index: ${isShowFinancing ? 1 : -1}`"
                >
                  <div class="blockSelect" ref="financing">
                    <label for="financing_all" class="dropdown-item pointer">
                      <input
                        type="checkbox"
                        id="financing_all"
                        @change="selectAll($event, 'financing')"
                      />
                      All
                    </label>
                    <label
                      v-for="(item, index) in dataFinancing"
                      :for="'financing_' + (index + 1)"
                      class="dropdown-item pointer"
                      :key="item"
                    >
                      <input type="checkbox" :id="'financing_' + (index + 1)" />
                      {{ item }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 text-end">
            <button class="btn btn-primary" @click="searchSurvey">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <listSurvey
      :listData="listData"
      :paging="paging"
      :loadPage="loadPage"
      v-if="listData"
    />
  </div>
</template>

<style lang="scss" scoped>
button {
  &.btn-block {
    //color: #606266;
    padding: 5px 8px;
    outline: 0 !important;
    box-shadow: unset !important;
    border: 1px solid #70707038;
    //background-color: #f9faff;
    color: #949ba2;
    background-color: #494b54;
    font-size: 1rem !important;
  }
}
.dropdown-toggle {
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.blockrangewrap {
  display: block;
  width: 100%;
  position: relative;
  .blockrange {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    overflow: hidden;
    border: 1px solid #494b54; //#C7C7C7;
    border-radius: 3px;
    width: 100%;
    padding: 10px;
    background-color: #494b54; //#fff;
    .blockSelect {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      max-height: 150px;
    }
    label {
      font-size: 12px;
      &.dropdown-item {
        line-height: 20px;
        color: #949ba2;
        input[type="checkbox"] {
          vertical-align: middle;
        }
        input[type="text"] {
          border: 0px;
          border-bottom: 1px solid #70707038;
          background-color: transparent !important;
          outline: 0 !important;
          color: #949ba2; //#606266;
          padding-left: 5px;
          padding-right: 5px;
        }
        &:hover {
          color: #16181b;
          text-decoration: none;
          background-color: #f8f9fa;
          input[type="text"] {
            color: #16181b;
          }
        }
      }
    }
    .form-control {
      font-size: 12px;
    }
    .text {
      font-size: 12px;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #f4f5f5;
      color: #212529;
    }
  }
}
.slider {
  /* overwrite slider styles */
  width: 200px;
}
.default .content-wrapper {
  width: 40%;
  margin: 0 auto;
  min-width: 185px;
}

.sliderwrap.default {
  margin-top: 40px;
}

.e-bigger .content-wrapper {
  width: 80%;
}

.sliderwrap label {
  padding-bottom: 26px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 15px;
}

.userselect {
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}
.data {
  label {
    margin-bottom: 0.5em;
  }
}
.vue-daterange-picker {
  width: 100%;
  :deep() .reportrange-text {
    padding: 0;
    border: 1px solid #70707038;
    background-color: #494b54;
    input {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      background-color: #494b54;
      color: #949ba2;
      border: 1px solid #70707038;
      padding: 5px 10px;
    }
  }
  :deep() .daterangepicker {
    background-color: #fff;
    .calendar-table {
      background-color: transparent;
      table {
        th {
          font-weight: 600;
        }
        th,
        td {
          color: rgb(33, 37, 41);
          background-color: transparent;
          &.today {
            font-weight: unset;
          }
          &.in-range {
            background-color: #ebf4f8;
            &.active {
              background-color: #357ebd;
              color: #fff;
            }
          }
        }
      }
    }
    .ranges li {
      color: rgb(33, 37, 41);
      font-weight: 600;
    }
    .btn-success {
      background-color: transparent;
      border: 1px solid #3f99ef;
      font-weight: unset;
      &:hover {
        color: #949ba2;
      }
    }
    .btn-secondary {
      background-color: transparent;
      border: 1px solid #6c757d;
      font-weight: unset;
      &:hover {
        color: #949ba2;
      }
    }
  }
}
.dropdown-item {
  padding: 0.25rem 1.5rem;
}
</style>
<script src="./index.js"></script>
