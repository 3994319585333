import { defineComponent } from "vue";
import DealServices from "@/services/deal";
import StarBlueIcon from "@/assets/images/star-blue.svg";
import DealApproveIcon from "@/assets/images/check-blue.svg";
import DealOnholdIcon from "@/assets/images/iconmonstr-clock-thin-blue.svg";
import DealRejectIcon from "@/assets/images/e-remove-blue.svg";
import DealMessageCreateIcon from "@/assets/images/comment-add-blue.svg";
import DealDocumentIcon from "@/assets/images/icon/documents-blue.svg";
import DealStarIcon from "@/assets/images/icon/star.svg";
import DealUpdateIcon from "@/assets/images/edit-2-blue.svg";
import DealReviewIcon from "@/assets/images/list-order-svgrepo-com-blue.svg";

export default defineComponent({
  name: "deal-history",
  props: ["totalStage", "hideModalHistory", "specifyIdDeal"],
  components: {
    StarBlueIcon,
    DealApproveIcon,
    DealOnholdIcon,
    DealRejectIcon,
    DealMessageCreateIcon,
    DealDocumentIcon,
    DealStarIcon,
    DealUpdateIcon,
    DealReviewIcon,
  },
  data() {
    return {
      isShow: false,
      dataReview: null,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDataReview();
    });
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
    },
    getDataReview: function () {
      this.loading = true;
      DealServices.getDealHistory(this.specifyIdDeal)
        .then((resp) => {
          if (resp.data.ok) {
            this.dataReview = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
