import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
  },
  computed: {
    _value: {
      get() {
        return this.value || false;
      },
      set(val) {
        if (val == false) {
          this.staticBody();
          this.$emit("modalClose");
        }
        this.$emit("input", val);
      },
    },
  },
});
