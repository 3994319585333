<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideAddUser"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">{{type}} User</h4>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="validData" autocomplete="off">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="form-group">
                                        <TextField
                                            :indexField="1"
                                            color="black"
                                            title="Email"
                                            name="email"
                                            field="black"
                                            v-model:value="form.email"
                                            :fieldRequire="true"
                                            :disabled="!!dataInfo"
                                            :errorMsg="errorMsg.email"
                                            :autocomplete="false"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-6">
                                    <div class="form-group">
                                        <SelectField
                                            title="User Type"
                                            name="userType"
                                            :options="userTypeList"
                                            valueProp="id"
                                            labelBy="name"
                                            :fieldRequire="true"
                                            :disabled="!!dataInfo"
                                            v-model:value="form.userType"
                                            :errorMsg="errorMsg.userType"
                                        />
                                    </div>
                                </div>
                                <div class="col col-6">
                                    <div class="form-group">
                                        <SelectField
                                            title="Role"
                                            name="role"
                                            :options="filtered_roleList"
                                            valueProp="id"
                                            labelBy="name"
                                            :fieldRequire="true"
                                            v-model:value="form.roleId"
                                            :errorMsg="errorMsg.roleId"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-6">
                                    <div class="form-group">
                                        <TextField
                                            :indexField="1"
                                            color="black"
                                            title="First Name"
                                            name="fname"
                                            field="black"
                                            v-model:value="form.firstName"
                                            :fieldRequire="true"
                                            :errorMsg="errorMsg.fname"
                                            :autocomplete="false"
                                        />
                                    </div>                                
                                </div>
                                <div class="col col-6">
                                    <div class="form-group">
                                        <TextField
                                            :indexField="1"
                                            color="black"
                                            title="Last Name"
                                            name="lname"
                                            field="black"
                                            v-model:value="form.lastName"
                                            :fieldRequire="true"
                                            :errorMsg="errorMsg.lname"
                                            :autocomplete="false"
                                        />
                                    </div>
                                </div>                                
                            </div>
                            <div class="row">
                                <div class="col col-6">
                                    <div class="form-group">
                                        <SelectField
                                            title="Phone Carrier"
                                            name="phoneCarrier"
                                            :options="carrierPhoneList"
                                            valueProp="phone_carrier_id"
                                            labelBy="carrier_name"
                                            :type="1"
                                            v-model:value="form.phoneCarrierId"
                                        />
                                    </div>                                
                                </div>   
                                <div class="col col-6">
                                    <div class="form-group">
                                        <TextField
                                            title="Phone Number"
                                            name="phone"
                                            :value="form.phoneNumber"
                                            class="m-0"
                                            @keypress="isNumber($event)"
                                        />
                                    </div>                                
                                </div>
                                <div class="col col-6">
                                    <div class="form-group">
                                        <TextField
                                            title="Password"
                                            name="password"
                                            :fieldRequire="true"
                                            :value="form.password"
                                            @update:value="form.password = $event"
                                            class="m-0"
                                            type="password"
                                            iconPasswordColor="white"
                                            :errorMsg="errorMsg.password"
                                        />
                                    </div>                                
                                </div>
                                <div class="col col-6">
                                    <template v-if="!dataInfo">{{` `}}</template>
                                    <template v-else>
                                        <br/>
                                        <button class="btn btn-primary resetpassbtn" @click.prevent="resetPassword(dataInfo.id)">Reset password</button>
                                    </template>                                    
                                </div>                             
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-secondary" @click="hideAddUser">Cancel</button>
                        <button class="btn btn-primary" @click="validData" v-if="!dataInfo">Add</button>
                        <button class="btn btn-primary" @click="validData" v-else>Update</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.toggle-show-pw {
    position: absolute;
    right: 10px;
    bottom: 6px;
    cursor: pointer;
}
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
button{
    &.resetpassbtn{
        padding-top: 9px;
        padding-bottom: 9px;
    }
}

</style>