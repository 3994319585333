import { defineComponent } from 'vue'
import conf from '../../../config/'

export default defineComponent({
    props: ['hideTermAndConditions', 'moveSignature', 'acceptTOS'],
    data() {
        return {
            nda_form: conf.nda_form,
            scale: 'page-width'
        }
    },
    methods: {
        acceptRule() {
            this.acceptTOS = !this.acceptTOS
        }
    }
})