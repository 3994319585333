import { defineComponent } from 'vue'
import VueSignaturePad from 'vue-signature-pad'

export default defineComponent({
    // props: ['finishSurvey', 'hideSignature', 'sourceFileData'],
    props: {
        sourceFileData: Function
    },
    components: {
        VueSignaturePad
    },
    data() {
        return {
            // signaturePad: null
            empty: true,
            acceptTerm: false
        }
    },
    methods: {
        clear() {
            this.$refs.signaturePad.clearSignature()
            this.empty = true
        },
        test() {
            this.empty = this.$refs.signaturePad.isEmpty()
        },
        saveImg() {
            if (this.$refs.signaturePad && this.$refs.signaturePad.saveSignature()) {
                let src = this.$refs.signaturePad.saveSignature()
                let img = new Image()
                img.src = src.data
                let data = src.data.split('base64,')[1]
                this.sourceFileData(data)
            }
        },
        changeAcceptTerm() {
            this.acceptTerm = !this.acceptTerm
        }
    },
    computed: {
        isSigned() {
            if (this.$refs.signaturePad && this.$refs.signaturePad.saveSignature())
                return true
            return false
        }
    }
})