<template>
  <div class="adminContent py-3" v-loading="loading">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          v-model:value="filteredName"
          placeholder="Type to search .."
          hideError
        />
      </template>
      <template #row_name="{ props }">
        {{ props.firstName }} {{ props.lastName }}
        <br v-if="props.firstName || props.lastName" />
        {{ props.email }}
      </template>
      <template #row_userTypes="{ props }">
        {{ props.userType }}
      </template>
      <template #row_schedule="{ props }">
        <div v-if="props.scheduleSendDate != null">
          {{ $filters.parseEstTime(props.scheduleSendDate) }}
        </div>
        <div v-else>-</div>
      </template>
      <template #row_invitationSendDate="{ props }">
        <div>{{ invitationSendStatus(props) }}</div>
        <div v-if="props.invitation == 'CANCELLED'">
          {{ $filters.parseEstTime(props.cancellationSendDate) }}
        </div>
        <div v-else>{{ $filters.parseEstTime(props.invitationSendDate) }}</div>
      </template>
      <template #row_answer="{ props }">
        <div>{{ answerStatus(props) }}</div>
        <div v-if="props.answeredDate != null">
          {{ $filters.parseEstTime(props.answeredDate) }}
        </div>
      </template>
      <template #row_actions="{ props }">
        <NewButton
          :loading="loading"
          @click="deleteItem(props)"
          class="btn btn-danger"
          v-if="
            form && form.status != 'CANCELLED' && form.status != 'COMPLETED'
          "
        >
          <i class="far fa-trash-alt"></i>
        </NewButton>
        <div v-else>-</div>
      </template>
      <template #footer v-if="form && form.status != 'CANCELLED'">
        <NewButton :loading="loading" @click="addItem"
          ><i class="fas fa-plus me-1"></i> Add Attendee</NewButton
        >
      </template>
    </Table>

    <AttendeeModal ref="itemModal" :key="itemModalKey" @refresh="getAll" />

    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.sendIvitation {
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: relative !important;
  }
}
</style>
