<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredName"
        />
      </template>
      <template #head_stt>
        <SelectField v-model:value="filteredStatus" :options="statusList" hideError />
      </template>
      <template #row_permissions="{ props }">
        {{ $filters.mapPermissions(props.permissions, permissionList) }}
      </template>
      <template #row_actions="{props}">
        <button
          class="btn btn-primary btn-small"
          v-if="
            hasPermissions([
              PERMISSIONS.Config_InvestorRoles_Update,
              PERMISSIONS.InvestorRoles_Update,
            ])
          "
          @click="updateRole(props)"
        >
          <i class="far fa-edit"></i>
        </button>
      </template>
      <template #footer>
        <button
          class="btn btn-primary"
          v-if="
            hasPermissions([
              PERMISSIONS.Config_InvestorRoles_Create,
              PERMISSIONS.InvestorRoles_Create,
            ])
          "
          @click="addRole"
        >
          <i class="fas fa-plus me-1"></i>
          <span class="text">Add New Role</span>
        </button>
      </template>
    </Table>
    <RoleManageModel
      ref="roleManageModel"
      :key="roleManageModelKey"
      :permissionList="permissionList"
      :statusList="statusList"
      @refresh="getAll"
    />
  </div>
</template>

<script src="./index.js"></script>
