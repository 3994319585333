import { formatDecimalFloor, formatDecimalNotRound } from "../misc/commons";
import moment from "moment";
import momentTz from "moment-timezone";
import config from "@/config";

const bigNumber = (number) => {
  let res = number;
  // let res = formatDecimalFloor(number, 2)
  if (number > 1000000000) {
    res = `${formatDecimalNotRound(res / 1000000000, "", 2)}B`;
  } else if (number > 1000000) {
    res = `${formatDecimalNotRound(res / 1000000, "", 2)}M`;
  } else if (number > 1000) {
    res = `${formatDecimalNotRound(res / 1000, "", 2)}K`;
  } else {
    res = formatDecimalNotRound(res / 1, "", 2);
  }
  return res;
};

const numberFormat = (number) => {
  if (number === undefined) return;
  const val = number.toString();
  const main = val.split(".")[0];
  const sub = val.split(".")[1] === undefined ? "" : "." + val.split(".")[1];
  return main.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sub;
};

const felixNumber = (number, type = "odds", digrit = 3) => {
  let val = parseFloat(number || 0).toFixed(digrit);
  val = parseFloat(val);
  const maxDigrit = 2;
  const arr = `${val}`.split(".");
  if (arr.length === 1) {
    let results = "";
    for (let i = 0; i < maxDigrit; i++) {
      results += results + "0";
    }
    return arr[0] ? arr[0] + "." + results : 0;
  }
  if (arr[1].length < maxDigrit) {
    const count = maxDigrit - arr[1].length;
    for (let i = 0; i < count; i++) {
      arr[1] += arr[1] + "0";
    }
  } else if (arr[1].length > digrit) {
    return felixNumber(arr[0] + "." + arr[1].substr(0, digrit));
  }
  return arr[0] + "." + arr[1].substr(0, digrit);
};

const decimal = (number, digrit = 3) => {
  return formatDecimalFloor(number, digrit);
};

const decimalNotRound = (number, digrit = 8, prettyNumber = false) => {
  if (number) {
    if (prettyNumber) {
      return numberFormat(formatDecimalNotRound(number, "", digrit));
    } else {
      return formatDecimalNotRound(number, "", digrit);
    }
  } else {
    return 0;
  }
};

const absNumber = (number) => {
  return Math.abs(number);
};

const timeStampFormat = (timestamp, format = "MM/DD/YYYY") => {
  return timestamp ? moment(timestamp).format(format) : "";
};

const phonenumber = (phone) => {
  return `${phone || ""}`
    .replace(/^84/, "0")
    .replace(/(\d{3})(\d{3})$/, " $1 $2");
};

const shortDateNotYear = (time) => {
  return time ? moment(time).format("MM/DD") : "";
};

const timetampToDateTime = (time) => {
  return time ? moment(time).format("MM/DD/YYYY hh:mm a") : "";
};

const timetampToDateTimehort = (time) => {
  return time ? moment(time).format("MM/DD/YY h:mm a") : "";
};

const timetampToDateWithoutYear = (time) => {
  return time ? moment(time).format("MM/DD h:mm a") : "";
};

const datetime = (time) => {
  return time ? moment(time).format("MM/DD/YYYY hh:mm A") : "";
};

const date = (time) => {
  return time ? moment(time).format("MM/DD/YYYY") : "";
};

const format = (dateOrTs, format) => {
  return dateOrTs ? moment(dateOrTs).format(format) : "";
};

const currentdate = (time) => {
  const localDate = new Date();
  const serverDate = moment(time);
  if (
    localDate.getDay() === serverDate.day() &&
    localDate.getMonth() === serverDate.month() &&
    localDate.getFullYear() === serverDate.year()
  ) {
    return time ? moment(time - 10000).format("hh:mm A") : "";
  } else {
    return time ? moment(time).format("MM/DD/YY") : "";
  }
};

const shortDateNotDay = (time) => {
  return time ? moment(time).format("MM/YYYY") : "";
};

const unixdate = (time) => {
  return time ? moment.unix(time).format("MM/DD/YYYY") : "";
};

const shortDate = (time) => {
  return time ? moment(time).format("MM/DD/YY") : "";
};

const time = (time) => {
  return time ? moment(time - 10000).format("hh:mm A") : "";
};

const lowercase = (value) => {
  return value.toLowerCase().trim();
};

const uppercase = (value) => {
  return value.toUpperCase().trim();
};

const trim = (string) => {
  return string.trim();
};

const nega = (num = 0) => {
  return num > 0 ? `+${num}` : num;
};

const friendlyname = (str) => {
  return str.toLowerCase().trim().replace(" ", "_");
};

const zerofill = (value) => {
  return (value < 10 && value > -1 ? "0" : "") + value;
};

const censorText = (value) => {
  return value && value.length > 4 ? value.slice(0, 3) + "***" : "";
};

const reverse = (value) => {
  return value.slice().reverse();
};

const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const getNameByCode = (value, arr) => {
  const type = arr.find((x) => x.code === value);
  return type ? type.name : "";
};

const mapStatus = (val, statusList) => {
  const item = statusList.find((x) => x.code == val);
  return item && item.name ? item.name : null;
};

const getName = (val, list) => {
  let type = _.find(list, (x) => x.code == val);
  return type && type.name ? type.name : "";
};

const mapPermissions = (permissions, permissionList) => {
  if (!permissions) return "";
  let perArr = permissions.split(",");
  let result = "";
  permissionList.forEach((item) => {
    if (perArr.includes(item.id)) {
      result += item.desc + ", ";
    }
  });
  if (result.length > 0) {
    result = result.slice(0, -2);
  }
  return result;
};

const parseEstTime = (time, format) => {
  if (!time) return;
  return momentTz(time)
    .tz(config.time_zone_default)
    .format(format ? format : "MM/DD/YYYY hh:mm A");
};

export default {
  bigNumber,
  numberFormat,
  felixNumber,
  decimal,
  decimalNotRound,
  absNumber,
  timeStampFormat,
  phonenumber,
  shortDateNotYear,
  timetampToDateTime,
  timetampToDateTimehort,
  timetampToDateWithoutYear,
  datetime,
  date,
  format,
  currentdate,
  unixdate,
  shortDate,
  time,
  lowercase,
  uppercase,
  trim,
  nega,
  friendlyname,
  zerofill,
  censorText,
  reverse,
  formatBytes,
  getNameByCode,
  mapStatus,
  getName,
  mapPermissions,
  parseEstTime,
  shortDateNotDay,
};
