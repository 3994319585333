import { AnalyticsBrowser } from '@segment/analytics-next'
import conf from "../config";

const analytics = AnalyticsBrowser.load({
	writeKey: conf.segment_token,
	cdnURL: conf.segment_cdn_url
}, {
	integrations: {
		"Segment.io": {
			deliveryStrategy: {
				strategy: "batching",
				config: {
					size: 10,
					timeout: 10000
				}
			},
			apiHost: conf.segment_api_host
		}
	}
})

export const segment = {
	analytics: analytics,
	enabled: conf.segment_enabled
}