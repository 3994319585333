import AdminServices from "@/services/admin"
import moment from 'moment'
import Datepicker from '@/components/DatePicker'
import Table, { debounce } from '@/components/Table'
import { defineComponent } from "vue"
import { swapTimetampToObj } from '@/misc/commons'

const columnsData = [
    {
        id: 'name',
        name: 'Name'
    },
    {
        id: 'email',
        name: 'Email'
    },
    {
        id: 'phone',
        name: 'Phone'
    },
    {
        id: 'company',
        name: 'Company'
    },
    {
        id: 'created',
        name: 'Created'
    },
    {
        id: 'signature',
        name: 'Signature'
    }
]

export default defineComponent({
    data() {
        return {
            loading: false,
            columns: columnsData,
            paging: {
                page: 1,
                size: 10,
                total: 0
            },
            totalItems: 0,
            listData: null,
            fromDate: moment().subtract(7, 'd').format('MM/DD/YYYY'),
            toDate: moment().format('MM/DD/YYYY'),
            tmpFromDate: null,
            tmpToDate: null
        }
    },
    mounted() {
        this.$nextTick(()=> {
            if (!this.tmpFromDate && !this.tmpToDate) {
              this.tmpFromDate = swapTimetampToObj(this.fromDate);
              this.tmpToDate = swapTimetampToObj(this.toDate);
            }
        })
    },
    components: {
        Datepicker,
        Table
    },
    methods: {
        getGuestMember: function () {
            this.loading = true
            AdminServices.getGuestMember({ page: this.paging.page, size: this.paging.size, fromDate: moment(this.fromDate).format('MM/DD/YYYY'), toDate: moment(this.toDate).format('MM/DD/YYYY') }).then(resp => {
                if (resp.data.ok) {
                    this.listData = resp.data.d.c
                    this.paging.page = resp.data.d.p
                    this.paging.size = resp.data.d.s
                    this.paging.total = resp.data.d.t
                    this.totalItems = resp.data.d.t
                }
            }).finally(() => {
                this.loading = false
            })
        },
        loadPage(value) {
            this.paging.page = value
            this.getGuestMember()
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY')
        },
        downloadSig(sigurl) {
            AdminServices.requestDownloadS3(sigurl).then(resp => {
                if (resp.data.ok) {
                    window.open(resp.data.d, '_blank')
                }
            })
        },
        updatePaging(v) {
            this.paging = Object.assign(this.paging, v)
        }
    },
    watch: {
        paging: {
            deep: true,
            handler(v) {
                this.getGuestMember()
            }
        },
        tmpFromDate: function(v) {
            if (!v) return
            this.fromDate = moment(`${v.years}-${v.months}-${v.days}`).format('MM/DD/YYYY')
        },
        tmpToDate: function(v) {
            if (!v) return
            this.toDate = moment(`${v.years}-${v.months}-${v.days}`).format('MM/DD/YYYY')
        }
    }
})