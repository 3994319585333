import AdminServices from "../../../services/admin";
import _ from "lodash";
import conf from "../../../config/index";
import router from "@/config/router";
import notify from "../../../services/notify";
import appStore, { APP_MUTATION, AUTH_ACTIONS } from "../../../store/app";
import { defineComponent } from "vue";
import logo from "@/components/commons/logo";

export default defineComponent({
  name: "ReviewSurvey",
  props: ["surveyID"],
  components: {
    logo,
  },
  data() {
    return {
      homePath: router.home.path,
      reviewPath: router.reviewSurvey.path,
      reviewName: router.reviewSurvey.name,
      dashboardPath: router.dashboard.path,
      dashboardName: router.dashboard.name,
      ready: false,
      loading: false,
      page: {
        page: 1,
        size: 1,
        total: 0,
      },
      info: {
        title: "",
        section: null,
        founder: null,
        stt: null,
      },
      fWidth: 0,
      detailSurveyId: null,
      permission: null,
      readyLoadsurvey: false,
    };
  },
  mounted() {
    this.viewMySurvey(this.surveyID);
  },
  methods: {
    viewMySurvey(id) {
      this.loading = true;
      AdminServices.viewMySurvey(id).then((resp) => {
        if (resp.data.ok) {
          this.info.title = resp.data.d.name;
          this.info.section = resp.data.d.sections;
          this.detailSurveyId = resp.data.d.id;
          this.info.founder = resp.data.d.username;
          this.info.stt = resp.data.d.stt;
          this.page.total = this.info.section.length;
          this.ready = true;
        } else {
          this.$router.push(this.dashboardPath);
        }
        this.loading = false;
      });
    },
    downloadFile(key) {
      this.loading = true;
      AdminServices.requestDownloadS3(key).then((resp) => {
        if (resp.data.ok) {
          window.open(resp.data.d, "_blank");
        }
        this.loading = false;
      });
    },
    checkInputValue(a, b) {
      let value = null;
      let status = false;
      a.map((p) => {
        if (p.Id === b.Id) {
          value = p.Answer;
          status = true;
        }
      });
      return { status: status, value: value };
    },
    loadPage(value) {
      this.page.page = value;
    },
    checkActivebox(id, list) {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          flag = true;
        }
      }
      return flag;
    },
    parseValue(id, list) {
      let str = null;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          str = list[i].Answer;
        }
      }
      return str;
    },
    goback() {
      this.$router.go(-1);
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
  },
  computed: {
    sumScored: {
      default: 0,
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let score = 0;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let child = list[i].questions;
            for (let j = 0; j < child.length; j++) {
              score += child[j].score;
            }
          }
        }
        return score;
      },
    },
    countQuestionInfo: {
      default: {
        ans: 0,
        total: 0,
      },
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let obj = {
          ans: 0,
          total: 0,
        };
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let q = list[i].questions;
            obj.total += q.length;
            for (let j = 0; j < q.length; j++) {
              if (q[j].answers.length) {
                obj.ans += 1;
              }
            }
          }
        }
        return obj;
      },
    },
    profile() {
      return appStore.state.profile;
    },
  },
});
