<template>
  <div id="wrapMainNotify" v-if="list.length > 0">
    <div :id="item.id" v-for="(item, index) in list" :key="index">
      <transition name="slide-fade">
        <div class="wrapNotify" :class="item.status" v-if="item.enable">
          <div class="boxNotify">
            <span class="icon">
              <svg
                v-if="item.status === 'success'"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="check"
                class="svg-inline--fa fa-check fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                />
              </svg>
              <svg
                v-if="item.status === 'error'"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-11"
                role="img"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                />
              </svg>
              <svg
                v-if="item.status === 'warn'"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="exclamation"
                class="svg-inline--fa fa-exclamation fa-w-6"
                role="img"
                viewBox="0 0 192 512"
              >
                <path
                  fill="currentColor"
                  d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"
                />
              </svg>
            </span>

            <div class="boxContent">
              <span v-if="item.status === 'success'" class="title"
                >Success</span
              >
              <span v-if="item.status === 'error'" class="title">Failed</span>
              <span v-if="item.status === 'warn'" class="title">Warning</span>
              <span class="message">{{ item.message }}</span>
            </div>
            <span class="close-notify" @click.prevent="disableNotify(item.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-11"
                role="img"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                />
              </svg>
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script src="./index.js"></script>
