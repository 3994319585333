import commons from "./commons";
import security from "./security";

function registerComponent(components) {
  if (components) {
    return components;
  }
}

export default registerComponent([...commons, ...security]);
