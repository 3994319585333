import { defineComponent } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import conf from "../../config";

export default defineComponent({
  name: "ReCaptcha",
  components: {
    VueRecaptcha,
  },
  props: {
    value: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      siteKey: conf.recaptcha_key,
    };
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  // mounted() {
  //     let vue = this
  //     this.initGrecaptcha()
  // },
  methods: {
    // initGrecaptcha() {
    //     let vue = this

    //     setTimeout(() => {
    //         if (typeof window.grecaptcha === 'undefined') {
    //             vue.initGrecaptcha()
    //         } else {
    //             vue.execute()
    //         }
    //     }, 100)
    // },
    // execute() {
    //     this.$refs.recaptcha.execute()
    // },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this._value = null;
    },
    onCaptchaVerified(recaptchaToken) {
      this._value = recaptchaToken;
    },
  },
});
