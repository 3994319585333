import auth from "../../../services/auth";
import notify from "../../../services/notify";
import appStore, { AUTH_ACTIONS } from "../../../store/app";
import { router } from "@/config/router";
import { BUS_TYPE } from "../../../misc/bus-type";
import { defineComponent } from "vue";

export default defineComponent({
  name: "disable-2fa-modal",
  props: {
    status2faLogin: Boolean,
    close: Function,
  },
  data() {
    return {
      loading: false,
      code: "",
      password: "",
      success: false,
      enable2faLogin: false,
      verifyOldCode: "",
    };
  },
  computed: {
    enabled() {
      return appStore.state.profile && appStore.state.profile.twoFaEnabled;
    },
  },
  mounted() {
    $(this.$el).on("hidden.bs.modal", () => {
      if (!this.success) {
        this.$root.$emit(BUS_TYPE.change_2fa, true);
      }
    });
  },
  methods: {
    show(str) {
      this.enable2faLogin = str;
      this.fixedBody();
      this.code = "";
      this.password = "";
      this.success = false;
      $(this.$el).modal("show");
    },
    hide() {
      this.close();
      this.staticBody();
      $(this.$el).modal("hide");
      this.code = "";
      this.loading = false;
    },
    submit() {
      let that = this;
      this.loading = true;
      if (this.verifyOldCode === this.code) {
        let m = "Google authentication incorrect";
        notify.error(m);
        this.loading = false;
        return;
      } else {
        auth.disable2Fa(this.code, this.password).then((resp) => {
          that.verifyOldCode = that.code;
          if (resp.error) {
            // notify.error('Disable Google TwoFA Failed'), resp.errorMsg || 'Please check your code and try again')
          } else if (resp.data) {
            notify.success("Disable Google TwoFA successfully");
            appStore.dispatch(AUTH_ACTIONS.get_user_profile);
            this.success = true;
            this.$root.$emit(BUS_TYPE.change_2fa, false);
            this.hide();
          } else {
            // notify.error('Disable Google TwoFA failed', 'Please check your code and try again')
          }
          this.loading = false;
        });
      }
    },
    submit2FALogin() {
      let that = this;
      this.loading = true;
      if (this.verifyOldCode === this.code) {
        let m = "Google authentication incorrect";
        notify.error(m);
        this.loading = false;
        return;
      } else {
        auth.enable2FaLogin(this.status2faLogin, this.code).then((resp) => {
          that.verifyOldCode = that.code;
          if (resp.data && resp.data.ok) {
            notify.success("Disable Google TwoFA login successfully");
            appStore.dispatch(AUTH_ACTIONS.get_user_profile);
            this.success = true;
            this.$root.$emit(BUS_TYPE.change_2fa_login, false);
            this.hide();
          } else {
            this.$root.$emit(BUS_TYPE.change_2fa_login, true);
            this.code = "";
            this.loading = false;
            this.hide();
          }
        });
      }
      this.loading = false;
    },
  },
  beforeUnmount() {
    this.clearScrollHeight();
    this.staticBody();
  },
});
