import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        value: { type: Number, default: 0 },
        tabs: { type: Array, default: [] }
    },
    emits: ['update:value'],
    computed: {
        _value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val)
            }
        }
    },
    methods: {
        selectTab(index) {
            this._value = index
        }
    }
})