import { defineComponent } from "vue";
import AdminServices from "@/services/admin";
import { PERMISSIONS } from "@/config/router";
import notify from "@/services/notify";
import { USER_TYPE } from "@/config/router";

import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import SelectField from "@/components/SelectField";
import TextField from "@/components/TextField";
import AddFounderModel from "./components/AddFounderModel";
import EditFounderModel from "./components/EditFounderModel";
import NavTab from "@/components/NavTab";
import Dialog from "@/components/Dialog";

const columnsData = [
  {
    id: "name",
    name: "Name",
    sort: true,
    direction: "",
  },
  {
    id: "email",
    name: "Email",
    sort: true,
    direction: "",
  },
  {
    id: "stt",
    name: "Status",
    columnClass: "multiple-line",
  },
  {
    id: "portfolio",
    name: "Portfolio",
  },
  {
    id: "sentby",
    name: "Sent By",
    columnClass: "multiple-line",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-right",
    columnClass: "text-right",
  },
];

const partnerListData = [
  {
    code: "",
    name: "-- All --",
  },
  {
    code: 0,
    name: "-- No portfolio --",
  },
];

export default defineComponent({
  components: {
    Table,
    SelectField,
    TextField,
    DropdownItem,
    Dropdown,
    AddFounderModel,
    EditFounderModel,
    NavTab,
    Dialog,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredName: "",
      filteredEmail: "",
      filteredStatus: "",
      filteredPortfofiloId: "",
      filteredSentBy: "",
      filteredUserType: "",
      orderBy: "",
      columns: columnsData,
      statusList: [],
      partnerList: partnerListData,
      partnerListForEdit: [
        {
          code: 0,
          name: "-- No portfolio --",
        },
      ],
      tabs: [
        {
          name: "Founder",
          type: USER_TYPE.FOUNDER,
        },
        {
          name: "Potential Founder",
          type: USER_TYPE.FOUNDER_POTENTIAL,
        },
      ],
      tab_active: 0,
      founderType: USER_TYPE.FOUNDER,
      PERMISSIONS: PERMISSIONS,
      editFounderModelKey: 0,
      addFounderModelKey: 0,
    };
  },
  mounted() {
    this.renderStatus();
  },
  watch: {
    "paging.page": {
      deep: true,
      handler(v) {
        debounce(this.getAll);
      },
    },
    filteredName() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredEmail() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredStatus() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredPortfofiloId() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredSentBy() {
      this.setPageDefault()
      debounce(this.getAll);
    },

    columns: {
      deep: true,
      handler: function (v) {
        let col = v.find(
          (p) => typeof p.sort === "boolean" && p.sort && p.direction
        );
        this.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    renderStatus() {
      if (this.tabs[this.tab_active].type == this.founderType) {
        this.statusList = [
          {
            name: "All",
            code: "",
          },
          {
            name: "Active",
            code: "ACTIVE",
          },
          {
            name: "Inactive",
            code: "INACTIVE",
          },
          {
            name: "Invitation Sent",
            code: "INVITATION_SENT",
          },
          {
            name: "Need Email Confirmation",
            code: "NEED_EMAIL_CONFIRMATION",
          },
        ];
      } else {
        this.statusList = [
          {
            name: "All",
            code: "",
          },
          {
            name: "Active",
            code: "ACTIVE",
          },
          {
            name: "Inactive",
            code: "INACTIVE",
          },
        ];
      }
    },
    getPartnerMataList() {
      return new Promise((resolve) => {
        this.loading = true;
        AdminServices.getPartnerMetaList().then((resp) => {
          if (resp.data.ok) {
            let _partnerMetaList = resp.data.d.map((x) => {
              return {
                code: x.id,
                name: x.cname,
              };
            });
            this.partnerList = this.partnerList.concat(_partnerMetaList);
            this.partnerListForEdit =
              this.partnerListForEdit.concat(_partnerMetaList);
            this.loading = false;
            resolve(true);
          }
        });
      });
    },
    async getAll() {
      this.loading = true;
      const res = await AdminServices.getFounders({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        orderBy: this.orderBy,
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
        filteredPortfofiloId: this.filteredPortfofiloId,
        filteredStatus: this.filteredStatus,
        filteredSentBy: this.filteredSentBy,
        filteredUserType: this.filteredUserType,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    changeTab: function (idx) {
      this.tab_active = idx;
      this.filteredUserType = this.tabs[idx].type;
      this.renderStatus();
      this.rows = [];
      debounce(this.getAll);
    },
    async updateStatus(item) {
      let message = {
        title: item.stt === "INACTIVE" ? "Activate User" : "Inactivate User",
        body:
          item.stt === "INACTIVE"
            ? "Press button confirm to activate this user"
            : "Press button confirm to inactivate this user",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;

          this.loading = true;

          if (item.stt === "ACTIVE") {
            item.isLocked = false;
          } else {
            item.isLocked = true;
          }
          const res = await AdminServices.updateFounderUserStatus(item.id, {
            isLocked: item.stt === "ACTIVE" || item.stt === "INVITATION_SENT",
          });
          if (res.data.ok) {
            const message = item.isLocked
              ? "Activate user successfully"
              : "Inactivate user successfully";
            notify.success(message);
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    async unlockUser(item) {
      let message = {
        title: "Unlock User",
        body: "Press button confirm to unlock this user",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClass: "confirmUnlockUser", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$dialog
        .confirm(message, options)
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await AdminServices.resetUserLockout(item.id);
          if (res.data.ok) {
            notify.success("Unlock user successfully");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async confirmEmail(item) {
      let message = {
        title: "Confirm Email",
        body: "Press button confirm to confirm email",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm(message, options)
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await AdminServices.updateFounderConfirmEmail(item.id);
          if (res.data.ok) {
            notify.success("User confirm email successfully");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async reinviteFounder(item) {
      let message = {
        title: "Reinvite Founder",
        body: "Press button confirm to reinvite founder",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm(message, options)
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await AdminServices.reinviteFounder({
            email: item.email,
          });
          if (res.data.ok) {
            notify.success("Reinvite founder successfully!");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async deleteUser(item) {
      let message = {
        title: "Delete User",
        body: "Press button confirm to delete this user",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;

          this.loading = true;
          const res = await AdminServices.deleteUser(item.id);
          if (res.data.ok) {
            notify.success("Delete user successfully");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async convertUserType(item) {
      let message = {
        title: "Convert to Founder",
        body: "Press button confirm to convert this user type",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmUnlockUser", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm(message, options)
        .then(async (d) => {
          if (!d) return;

          this.loading = true;
          const res = await AdminServices.convertUserType(item.id);
          if (res.data.ok) {
            notify.success("Convert user type successfully");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async addUser() {
      this.addFounderModelKey++;
      this.$nextTick(() => {
        this.$refs.addFounderModel.showAdd();
      });
    },
    async editFounder(item) {
      this.editFounderModelKey++;
      this.$nextTick(() => {
        this.$refs.editFounderModel.showUpdate(item);
      });
    },
    updatePaging(value) {
      this.paging = Object.assign(this.paging, value);
      this.getAll();
    },
  },
});
