<template>
  <Modal title="Edit Founder Information" v-model:value="isShow">
    <form @submit.prevent="save" autocomplete="false">
      <div class="row">
        <div class="col-12 col-md-6">
          <TextField
            name="fname"
            title="First Name"
            :indexField="0"
            :fieldRequire="true"
            v-model:value="form.firstName"
            :autocomplete="false"
            :errorMsg="errorMsg.fname"
          />
        </div>
        <div class="col-12 col-md-6">
          <TextField
            name="lname"
            title="Last Name"
            :indexField="1"
            :fieldRequire="true"
            v-model:value="form.lastName"
            :autocomplete="false"
            :errorMsg="errorMsg.lname"
          />
        </div>
        <div class="col-12 col-md-6">
          <TextField
            name="Email"
            title="Email"
            :indexField="2"
            :fieldRequire="true"
            v-model:value="form.email"
            :autocomplete="false"
            :errorMsg="errorMsg.email"
            disabled
          />
        </div>
        <div class="col-12 col-md-6">
          <SelectField
            name="portfolio"
            v-model:value="form.portfolioId"
            title="Portfolio"
            :errorMsg="errorMsg.portfolioId"
            :options="partnerList"
          />
        </div>
        <div class="col-12 col-md-6">
          <TextField
                title="Password"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                :autocomplete="false"
                :errorMsg="errorMsg.password"
                :hideError="false"
                :iconPasswordColor="`white`"
              />
        </div>
        <div class="col-12 col-md-6">
            <br />
            <NewButton @click="resetPassword()" :loading="loading">
              Reset password
            </NewButton>
          </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save" :loading="loading"> Update </NewButton>
    </template>
  </Modal>
</template>

<script src="./EditFounderModel.js"></script>
