<template>
  <Modal
    v-model:value="isShow"
    title="Consider till date"
    :hasFloatingCloseButton="hasFloatingCloseButton"
    :isVisible="true"
  >
    <div class="datepickerWhite">
      <div class="row">
        <div class="col-6 form-group">
          <Datepicker
            title="Select date"
            placeholder="MM/DD/YYYY"
            :format="customFormatterDate"
            v-model:value="select_date"
            :minDate="yesterday"
          />
        </div>
        <div class="col-6 form-group">
          <Datepicker
            title="Select time"
            :placeholder="`hh:mm a`"
            v-model:value="select_time"
            :is24="false"
            :errorMsg.="errorMsg.notify_before_hour"
            :isTimePicker="true"
            :mask="customFormatterTime"
            name="selectTime"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-success btnSubmit colorWhite"
        @click="hide"
        :disabled="!validDate || !select_date"
      >
        Submit
      </button>
    </template>
  </Modal>
</template>
<style lang="scss" scoped>
button.btnSubmit {
  color: #fff !important;
  background-color: #1bbf89;
}
</style>
<script src="./considerTillDate.js"></script>
