import AdminServices from '@/services/admin'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'shareItem',
    props: ['dataInfo', 'hideShareItem'],
    data() {
        return {
            loading: false,
            loadingInitial: true,
            users: [],
            dataroomItem: null,
            searchedUsers: [],
            searchKey: '',
            filterTimer: null,
            searchSharedKey: '',
            searchedSharedUserIds: [],
            searchedAncestorSharedUsers: [],
        }
    },
    created() {
        this.firstDataLoading()
    },
    computed: {
        filteredSharedUsers() {
            if (this.dataroomItem && this.dataroomItem.sharedUserDetails) {
                return this.dataroomItem.sharedUserDetails.filter(x => this.searchedSharedUserIds && this.searchedSharedUserIds.includes(x.id))
            }
            return [];
        }
    },
    methods: {
        firstDataLoading() {
            this.loadingInitial = true
            this.fetchMetaData().then(res => {
                if (res) {
                    AdminServices.getDataroomItemDetail(this.dataInfo.id)
                    .then(resp => {
                        if (resp.data.ok) {
                            const _ancestor_shared_users = resp.data.d.ancestor_shared_users
                            const _all_ancestor_shared_user_ids = _ancestor_shared_users.map(x => x.user_id)
                            
                            this.dataroomItem = {
                                ...resp.data.d,
                                sharedUserDetails: resp.data.d.shared_users
                                    .filter(z => !_all_ancestor_shared_user_ids.includes(z.user_id) && this.users.find(y => y.id === z.user_id))
                                    .map(x => {
                                    const _item = this.users.find(y => y.id === x.user_id)
                                    return {
                                        id: x.user_id,
                                        fname: _item.fname,
                                        lname: _item.lname,
                                        email: _item.email,
                                        user_type: _item.user_type,
                                        is_allow_download: !!x.allow_download,
                                        sharing_info: {
                                            id: x.shared_by_id,
                                            fname: x.shared_by_fname,
                                            lname: x.shared_by_lname,
                                            username: x.shared_by_username,
                                            user_type: x.shared_by_usertype,
                                            shared_on: x.shared_on
                                        }
                                    }
                                }),
                                ancestorSharedUserDetails: _ancestor_shared_users
                                    .filter(x => this.users.find(y => y.id === x.user_id))
                                    .map(x => {
                                        const _item = this.users.find(y => y.id === x.user_id)
                                        return {
                                            id: x.user_id,
                                            fname: _item.fname,
                                            lname: _item.lname,
                                            email: _item.email,
                                            user_type: _item.user_type,
                                            is_allow_download: !!x.allow_download,
                                            sharing_info: {
                                                id: x.shared_by_id,
                                                fname: x.shared_by_fname,
                                                lname: x.shared_by_lname,
                                                username: x.shared_by_username,
                                                user_type: x.shared_by_usertype,
                                                shared_on: x.shared_on
                                            }
                                        }
                                    }),
                            }

                            this.resetSearchSharedUsers()

                            this.loadingInitial = false
                        }
                    })
                }
            })
        },
        fetchMetaData() {
            return new Promise((resolve, reject) => {
                AdminServices.findUsersToShareDataroom()
                .then(resp => {
                    if (resp.data.ok) {
                        this.users = resp.data.d
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            })
        },
        save() {
            this.loading = true
            const sharedUsers = this.dataroomItem.sharedUserDetails.map(x => {
                return {
                    userId: x.id,
                    allowDownload: !!x.is_allow_download
                }
            })
            const sharedUserIds = this.dataroomItem.sharedUserDetails.map(x => x.id)
            let unsharedUserIds = []
            this.dataroomItem.shared_users.forEach(x => {
                if (!sharedUserIds.includes(x.user_id)) {
                    unsharedUserIds.push(x.user_id)
                }
            })
            AdminServices.shareAndUnshareDataroomItem({id: this.dataInfo.id, sharedUsers, unsharedUserIds})
            .then(resp => {
                if (resp.data.ok) {
                    this.$emit('share-complete')
                }
            })
            .finally(() => this.loading = false)
        },

        clearSearchUsers() {
            this.searchKey = ''
            this.searchedUsers = []
        },

        clearSearchSharedUsers() {
            this.searchSharedKey = ''
            this.resetSearchSharedUsers()
        },

        resetSearchSharedUsers() {
            this.searchedSharedUserIds = this.dataroomItem.sharedUserDetails.map(x => x.id)
            this.searchedAncestorSharedUsers = _.cloneDeep(this.dataroomItem.ancestorSharedUserDetails)
        },

        delayedFilterShared() {
            if (this.filterTimer != null) {
                clearTimeout(this.filterTimer)
                this.filterTimer = null
            }
            this.filterTimer = setTimeout(this.doFilterShared, 500)
        },
        doFilterShared() {
            this.filterTimer = null
            this.searchSharedUsers(this.searchSharedKey)
        },

        searchSharedUsers(key) {
            let _key = key.trim().toLowerCase()
            if (_key) {
                this.loading = true
                let sharedUsers = this.users.filter(x => this.dataroomItem.sharedUserDetails.map(x => x.id).includes(x.id))
                let ancestorSharedUsers = this.users.filter(x => this.dataroomItem.ancestorSharedUserDetails.map(x => x.id).includes(x.id))
                this.searchedSharedUserIds = sharedUsers.filter(x => (x.fname && x.fname.toLowerCase().indexOf(_key) > -1) || (x.lname && x.lname.toLowerCase().indexOf(_key) > -1) || (x.fname && x.lname && (x.fname + ' ' + x.lname).toLowerCase().indexOf(_key) > -1) || (x.email && x.email.toLowerCase().indexOf(_key) > -1)).map(x => x.id)
                this.searchedAncestorSharedUsers = ancestorSharedUsers.filter(x => (x.fname && x.fname.toLowerCase().indexOf(_key) > -1) || (x.lname && x.lname.toLowerCase().indexOf(_key) > -1) || (x.fname && x.lname && (x.fname + ' ' + x.lname).toLowerCase().indexOf(_key) > -1) || (x.email && x.email.toLowerCase().indexOf(_key) > -1))
                this.loading = false
            } else {
                this.resetSearchSharedUsers()
            }
        },

        delayedFilter() {
            if (this.filterTimer != null) {
                clearTimeout(this.filterTimer)
                this.filterTimer = null
            }
            this.filterTimer = setTimeout(this.doFilter, 500)
        },
        doFilter() {
            this.filterTimer = null
            this.searchUsers(this.searchKey)
        },

        searchUsers(key) {
            const _key = key.trim().toLowerCase()
            if (_key) {
                this.loading = true
                const sharedIds = this.dataroomItem.sharedUserDetails.map(x => x.id).concat(this.dataroomItem.ancestorSharedUserDetails.map(x => x.id))
                const availableUsers = this.users.filter(x => !sharedIds.includes(x.id))
                this.searchedUsers = availableUsers.filter(x => (x.fname && x.fname.toLowerCase().indexOf(_key) > -1) || (x.lname && x.lname.toLowerCase().indexOf(_key) > -1) || (x.fname && x.lname && (x.fname + ' ' + x.lname).toLowerCase().indexOf(_key) > -1) || (x.email && x.email.toLowerCase().indexOf(_key) > -1))
                this.loading = false
            } else {
                this.searchedUsers = []
            }
        },

        addUser(item, index) {
            this.searchedUsers.splice(index, 1)
            let sharedIds = this.dataroomItem.sharedUserDetails.map(x => x.id).concat(this.dataroomItem.ancestorSharedUserDetails.map(x => x.id))
            if (!sharedIds.includes(item.id)) {
                this.dataroomItem.sharedUserDetails.push({
                    id: item.id,
                    fname: item.fname,
                    lname: item.lname,
                    email: item.email,
                    user_type: item.user_type,
                    is_allow_download: false,
                    sharing_info: null
                })
                this.searchedSharedUserIds.push(item.id)
            }
        },

        removeUser(item) {
            let _indexOfSearchedSharedUsers = this.searchedSharedUserIds.indexOf(item.id)
            this.searchedSharedUserIds.splice(_indexOfSearchedSharedUsers, 1)
            let _indexOfSharedUsers = this.dataroomItem.sharedUserDetails.findIndex(x => x.id === item.id)
            this.dataroomItem.sharedUserDetails.splice(_indexOfSharedUsers, 1)
        },
    }
}
)