import _ from "lodash";
import adminServices from "@/services/admin";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import FileField2 from "@/components/FileField2";
import Modal from "@/components/Modal";
import TextArea from "@/components/TextArea";
import Switcher from "@/components/Switcher";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import notify from "@/services/notify";
import NewButton from "@/components/NewButton";

export class founderInfo {
  constructor() {
    this.founderName = null;
    this.founderTitle = null;
    this.founderTwitter = null;
    this.founderLinkedin = null;
  }
}

export default {
  components: {
    Modal,
    TextField,
    SelectField,
    FileField2,
    TextArea,
    Switcher,
    NewButton,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: null,
      formData: {
        companyName: null,
        title: null,
        description: null,
        thumbnail: {
          name: null,
          s3Key: null,
        },
        isActive: true,
        isExited: false,
        itemImage: null,
        founderInfo: [],
      },
      errorMsg: {
        companyName: null,
        title: null,
        description: null,
        thumbnail: null,
        pdfFile: null,
        website: null,
        founderInfo: [
          {
            founderName: null,
            founderTitle: null,
            founderTwitter: null,
            founderLinkedin: null,
          },
        ],
      },
      founderInfo: [
        {
          founderName: null,
          founderTitle: null,
          founderTwitter: null,
          founderLinkedin: null,
        },
      ],
      id: null,
      itemImage: null,
      imageMime: ["image/jpeg", "image/png", "image/jpg"],
    };
  },
  created() {
    if (this.founderInfo.length == 0) {
      this.founderInfo.push(new founderInfo());
      this.errorMsg.founderInfo.push(new founderInfo());
    }
  },

  watch: {
    "formData.title"() {
      if (this.formData.title.trim() === "") return;
      this.errorMsg.title = "";
    },
    "formData.description"() {
      if (this.formData.description.trim() === "") return;
      this.errorMsg.description = "";
    },
    "formData.companyName"() {
      if (this.formData.companyName.trim() === "") return;
      this.errorMsg.companyName = "";
    },
    "formData.website"() {
      if (this.formData.website.trim() === "") return;
      this.errorMsg.website = "";
    },
  },

  methods: {
    show(props) {
      this.state = "UPDATE";
      this.isShow = true;
      this.id = props.meet_founder_id;
      this.formData.companyName = props.company_name;
      this.formData.title = props.title;
      this.formData.description = props.description;
      this.formData.thumbnail = props.thumbnail;
      this.formData.website = props.website;
      this.formData.pdfFile = props.pdf_file;
      this.formData.isActive = props.is_active;
      this.formData.isExited = props.is_exited;
      this.founderInfo = [...props.founder_info];
      this.founderInfo.forEach((x) => {
        if (x != null) this.errorMsg.founderInfo.push(new founderInfo());
      });
    },

    create() {
      this.state = "CREATE";
      this.isShow = true;
    },

    addFounder() {
      this.founderInfo.push(new founderInfo());
      this.errorMsg.founderInfo.push(new founderInfo());
    },
    removeFounder(index) {
      this.founderInfo.splice(index, 1);
      this.errorMsg.founderInfo.splice(index, 1);
    },

    async save() {
      if (!this.validData()) return;
      this.loading = true;
      if (this.itemImage != null) {
        this.formData.thumbnail = await this.uploadFiles(
          this.itemImage,
          this.itemImage.type
        );
      }

      var listFounderInfo = this.founderInfo.map((x) => {
        return {
          founderName: x.founderName ? x.founderName.trim() : '',
          founderTitle: x.founderTitle ? x.founderTitle.trim() : '',
          founderTwitter: x.founderTwitter ? x.founderTwitter.trim() : '',
          founderLinkedin: x.founderLinkedin ? x.founderLinkedin.trim() : '',
        };
      });
      this.formData.founderInfo = listFounderInfo;
      if (this.state == "CREATE") {
        await this.addItem(this.formData);
      } else {
        await this.updateItem(this.formData);
      }

      this.loading = false;
      this.$emit("refresh");
    },

    validData() {
      let flag = true;
      if (!this.formData.description || this.formData.description.trim() === "") {
        this.errorMsg.description = "Please input description";
        flag = false;
      }

      if (!this.formData.companyName || this.formData.companyName.trim() === "") {
        this.errorMsg.companyName = "Please input company name";
        flag = false;
      }
      if (!this.formData.title || this.formData.title.trim() === "") {
        this.errorMsg.title = "Please input title";
        flag = false;
      }
      if (!this.formData.website || this.formData.website.trim() === "") {
        this.errorMsg.website = "Please input website";
        flag = false;
      }

      if (this.itemImage === null && this.formData.thumbnail["s3_key"] === null) {
        this.errorMsg.thumbnail = "Please select thumbnail";
        flag = false;
      }

      this.founderInfo.forEach((founder, i) => {
        if (!founder.founderName || founder.founderName.trim() === "") {
          this.errorMsg.founderInfo[i].founderName = "Please input name";
          flag = false;
        }

        if (!founder.founderTitle || founder.founderTitle.trim() === "") {
          this.errorMsg.founderInfo[i].founderTitle = "Please input title";
          flag = false;
        }
      });

      return flag;
    },

    async addItem(formData) {
      this.loading = true;
      await adminServices
        .createMeetFounder(formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async updateItem(formData) {
      this.loading = true;
      await adminServices
        .updateMeetFounder(this.id, formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Edit successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async uploadFiles(item, type) {
      var _awsCred = await adminServices.getPublicSessionToken();
      AWS.config.update({
        region: _awsCred.data.d.region,
        credentials: new AWS.Credentials(
          _awsCred.data.d.access_key,
          _awsCred.data.d.secret_key,
          _awsCred.data.d.session_token
        ),
      });

      var folder = "company/images/";
      var fileName = item.name.split(".")[0].replace(/[^a-zA-Z0-9]/g, "");
      let lastDot = item.name.lastIndexOf(".");
      var keyname =
        folder +
        fileName +
        "-" +
        this.getRandomKey(5) +
        item.name.substring(lastDot);
      let upload = new S3.ManagedUpload({
        params: {
          Bucket: _awsCred.data.d.bucket,
          Key: keyname,
          Body: item,
          ContentType: item.type,
          ACL: "public-read",
        },
      });
      var s3Key = (await upload.promise()).Key;
      return {
        name: item.name,
        s3_key: s3Key,
      };
    },

    async changeFile(file) {
      if (file == null) return;
      if (this.imageMime.includes(file.type)) {
        this.errorMsg.thumbnail = "";
      }
    },

    closeModal() {
      this.$emit("refresh");
      this.isShow = false;
    },
  },
};
