<template>
  <div class="pitch-us-page">
    <Section1 />
    <Section2 />
  </div>
</template>

<script>
import Section1 from "./comps/Section1.vue";
import Section2 from "./comps/Section2.vue";

export default {
  components: {
    Section1,
    Section2,
  },
};
</script>
