<template>
  <section class="section-3 home-text-3">
    <div class="line"></div>
    <div class="section-panel-3 step1">
      <div class="border1" />
      <div class="border2" />

      <div class="top">
        <div class="text-a-wrapper">
          <h1 class="h-text h-text-1">Who We Are</h1>
        </div>
      </div>
      <div class="bottom">
        <div class="text-a-wrapper">
          <span class="h-text h-text-2">Fuel Venture Capital</span>
        </div>
      </div>
    </div>

    <div class="section-panel-3 step2">
      <div class="top">
        <div class="container-v2">
          <div class="text-a-wrapper">
            <h5 class="h-text h-text-3">From 2017</h5>
          </div>
        </div>
      </div>
      <div class="bottom container-v2">
        <div class="text-a-wrapper">
          <p class="h-text-desc h-text-4">
            When we launched Fuel Venture Capital in Miami, Florida, this now
            booming town wasn't quite known as the Tech Ecosystem that it is
            today. No one could understand why we would want to launch a venture
            capital fund in Miami. To us, the mission was apparent... investors
            deserve access to a more diversified global portfolio of creative
            economy companies.
          </p>
          <div class="line"></div>
        </div>
      </div>
    </div>

    <div class="section-panel-3 step3">
      <div class="top">
        <div class="container-v2">
          <div class="text-a-wrapper">
            <h5 class="h-text h-text-5">Today</h5>
          </div>
        </div>
      </div>
      <div class="bottom container-v2">
        <div class="text-a-wrapper">
          <p class="h-text-desc h-text-6">
            Fuel Venture Capital has brilliantly executed against this mission
            and has become known and trusted as leaders who are founder-focused
            and investor-driven. Our world-class venture executives have
            deployed over $400MM of capital from our global LP base following a
            disciplined "Phased Investment Thesis" managing risk while driving
            return on investment.
          </p>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="section-panel-3 step4">
      <div class="top">
        <div class="container-v2">
          <div class="text-a-wrapper">
            <h5 class="h-text h-text-7">The Future</h5>
          </div>
        </div>
      </div>
      <div class="bottom container-v2">
        <div class="text-a-wrapper">
          <p class="h-text-desc h-text-8">
            The Future of Fuel Venture Capital will be about continuing our
            leadership position in identifying and Fueling founders and
            groundbreaking companies outside of Silicon Valley. Over the past 5
            years, we have become a dominant player in this new breeding ground
            of tech. Our founders look to us for advice, guidance, leadership
            development, curation, and moreover, Fuel to move through the
            lifecycle of an idea to exit. Fuel will continue to add world-class
            intellectual firepower, founders and investors from around the world
            to accomplish our mission.
          </p>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import anime from "animejs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      controller: null,
      easing: "cubicBezier(.45,0,.3,1)",
      randomArray: [],
      translateYText1: 240,

      flagTextAnimationEnter0: true,
      flagTextAnimationEnter1: true,
      flagTextAnimationEnter2: true,
      flagTextAnimationEnter3: true,

      flagTextAnimationLeave0: false,
      flagTextAnimationLeave1: false,
      flagTextAnimationLeave2: false,
      flagTextAnimationLeave3: false,

      horizontalScrollInterface: null,
    };
  },
  mounted() {
    this.controller = new ScrollMagic.Controller();
    this.handleSliceText();

    this.addAnimationText();

    window.addEventListener("resize", _.debounce(this.onResize, 1500));
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      if (this.isScrolledIntoView(".home-text-3")) {
        location.reload();
      }
    },

    isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      var rootEl = $(elem);
      if (!rootEl || !rootEl.offset()) return;
      var elemTop = rootEl.offset().top;
      return elemTop <= docViewBottom && elemTop >= docViewTop;
    },

    handleSliceText() {
      const textWrapper = document.querySelectorAll(".h-text");
      if (textWrapper) {
        textWrapper.forEach((el) => {
          el.innerHTML = el.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
          );
        });
      }

      // Wrap every letter in a span
      var textWrapper2 = document.querySelectorAll(".h-text-desc");

      if (!textWrapper2) return;
      textWrapper2.forEach((el) => {
        if (el.querySelector(".t-words")) return;

        el.innerHTML = el.innerHTML.replace(
          /(^|<\/?[^>]+>|\s+)([^\s<]+)/g,
          function (match, p1, p2) {
            return `${p1}<div class="t-words"><p class="letter">${p2}</p></div>`;
          }
        );
      });
    },

    addAnimationText() {
      if (window.innerWidth < 1024) {
        return;
      }

      const sections = gsap.utils.toArray(".section-panel-3");
      let maxWidth = 0;

      const getMaxWidth = () => {
        maxWidth = 0;
        sections.forEach((section) => {
          maxWidth += section.offsetWidth;
        });
      };
      getMaxWidth();
      ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

      this.horizontalScrollInterface = gsap.to(sections, {
        x: () => `-${maxWidth - window.innerWidth}`,
        ease: "none",
        scrollTrigger: {
          trigger: ".home-text-3",
          start: "top top",
          pin: true,
          scrub: 0.15,
          end: () => `+=${maxWidth}`,
          invalidateOnRefresh: true,
        },
      });

      sections.forEach((sct, index) => {
        const TEXT_ARRAY = {
          0: ".h-text-1 .letter",
          1: ".h-text-3 .letter",
          2: ".h-text-5 .letter",
          3: ".h-text-7 .letter",
        };

        const TEXT_LENGTH_ARRAY = {
          0: 8, // Who We Are
          1: 8, // From 2017
          2: 5, // Today
          3: 9, // The Future
        };

        let tl1 = anime.timeline({ autoplay: false });
        let s4a1 = {
          targets: TEXT_ARRAY[index],
          delay: (el, i) => {
            this.randomArray = [];
            const random =
              this.generateUniqueRandom(
                TEXT_LENGTH_ARRAY[index],
                "randomArray"
              ) || 8;
            return 50 + 50 * random;
          },
          duration: 2000,
          easing: this.easing,
          translateY: [this.translateYText1, 0],
        };
        tl1.add(s4a1);

        const TEXT_DESC_ARRAY = {
          0: ".h-text-2 .letter",
          1: ".h-text-4 .letter",
          2: ".h-text-6 .letter",
          3: ".h-text-8 .letter",
        };

        let tl2 = anime.timeline({ autoplay: false });
        let s4a2 = {
          targets: TEXT_DESC_ARRAY[index],
          duration: 1000,
          delay: (el, i) => {
            // Fuel Venture Capital
            if (TEXT_DESC_ARRAY[index] === ".h-text-2 .letter") {
              this.randomArray = [];
              const random = this.generateUniqueRandom(18, "randomArray");

              return 50 + 50 * random;
            }

            return 200;
          },
          easing: this.easing,
          translateY: [this.translateYText1, 0],
        };

        tl2.add(s4a2);

        const line = sct.querySelector(".line");
        if (line) {
          let s4a3 = {
            targets: line,
            duration: 600,
            opacity: [0, 1],
            easing: this.easing,
            translateY: [10, 0],
          };
          tl2.add(s4a3, "-=600");
        }

        // after leave all section -> hide all text to scroll up behavior
        const resetTextAnimation = () => {
          const container = document.querySelector(".home-text-3");
          if (!container) return;
          const listText = container.querySelectorAll(".letter");
          if (!listText) return;

          listText.forEach((el) => {
            const heightText = el.clientHeight;
            el.style.transform = `translateY(${heightText}px)`;
          });

          const lines = container.querySelectorAll(".line");

          lines.forEach((el) => {
            el.style.opacity = 0;
            el.style.transform = `translateY(10px)`;
          });
        };

        ScrollTrigger.create({
          trigger: sct,
          start: () => {
            let timeAppear =
              index === 0 ? sct.offsetLeft - 100 : sct.offsetLeft - 300;

            // mobile
            if (window.innerWidth <= 576) timeAppear = sct.offsetLeft;

            return (
              "top top-=" +
              (timeAppear - window.innerWidth / 2) *
                (maxWidth / (maxWidth - window.innerWidth))
            );
          },
          end: () =>
            "+=" +
            sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
          scrub: 0.15,
          onEnter: () => {
            if (this[`flagTextAnimationEnter${index}`]) {
              tl1.play();
              tl2.play();
              this[`flagTextAnimationEnter${index}`] = false;
            }
          },
          onLeave: () => {
            if (index === 3) {
              resetTextAnimation();
              this.flagTextAnimationLeave0 = true;
              this.flagTextAnimationLeave1 = true;
              this.flagTextAnimationLeave2 = true;
              this.flagTextAnimationLeave3 = true;
            }
          },
          onEnterBack: () => {
            if (this[`flagTextAnimationLeave${index}`]) {
              tl1.play();
              tl2.play();
              this[`flagTextAnimationLeave${index}`] = false;
            }
          },
          onLeaveBack: () => {
            if (index === 0) {
              resetTextAnimation();
              this.flagTextAnimationEnter0 = true;
              this.flagTextAnimationEnter1 = true;
              this.flagTextAnimationEnter2 = true;
              this.flagTextAnimationEnter3 = true;
            }
          },
        });
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-3 {
  --border-width: 1px;
  --border-color: #0000006e;

  width: 400%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  gap: 50px;

  z-index: 6;
  margin: 50px 0;

  @media (min-width: 1024px) {
    height: 100vh;
    min-height: 708px !important;
    flex-direction: row;
    gap: 0;
    margin: 0;
  }
}

.section-panel-3 {
  .top {
    position: relative;
    display: flex;

    align-items: flex-end;
    width: 100%;
    height: 50%;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--border-color);
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  .bottom {
    display: flex;
    align-items: flex-start;
    height: 50%;
  }
}

.step1 {
  position: relative;
  width: 100vw;
  height: 50vh;
  z-index: 6;

  @media (min-width: 1024px) {
    height: auto;
  }
  .top {
    justify-content: center;
    .h-text-1 {
      font-size: 60px;
      font-weight: 700;

      @media (min-width: 576px) {
        font-size: 90px;
      }

      @media (min-width: 768px) {
        font-size: 120px;
      }

      @media (min-width: 992px) {
        font-size: 160px;
      }

      @media (min-width: 1024px) {
        font-size: 170px;
      }

      @media (min-width: 1200px) {
        font-size: 200px;
      }
    }
    &::before {
      width: 88%;
    }
  }

  .bottom {
    justify-content: center;
    span {
      font-size: 40px;
      font-weight: 100;
      color: #0095ff;
      line-height: 110%;

      @media (min-width: 576px) {
        font-size: 50px;
        font-weight: 100;
        letter-spacing: 0.115em;
      }

      @media (min-width: 768px) {
        font-size: 60px;
      }

      @media (min-width: 992px) {
        font-size: 80px;
      }

      @media (min-width: 1200px) {
        font-size: 100px;
      }
    }
  }
}

.step2,
.step3,
.step4 {
  width: 100vw;
  z-index: 6;

  @media (min-width: 576px) {
    width: 80vw;
  }
  .top {
    h5 {
      font-size: 50px;
      font-weight: 700;

      @media (min-width: 576px) {
        font-size: 65px;
        line-height: 160%;
      }

      @media (min-width: 768px) {
        font-size: 96px;
      }

      @media (min-width: 1024px) {
        margin-left: 100px;
      }

      @media (min-width: 1200px) {
        font-size: 108px;
        line-height: 160%;
      }
    }
  }

  .bottom {
    @media (min-width: 1024px) {
      border-left: var(--border-width) solid var(--border-color);
      margin-left: 22%;
    }

    @media (min-width: 1500px) {
      margin-left: 18%;
    }

    p {
      position: relative;
      padding-top: 24px;
      max-width: 700px;

      font-size: 18px;
      font-weight: 300;
      line-height: 150%;

      @media (min-width: 576px) {
        font-size: 22px;
      }

      @media (min-width: 1200px) {
        line-height: 110%;
        font-size: 25px;
      }

      @media (min-width: 1500px) {
        line-height: 130%;
      }
    }
  }
}

.step4 {
  z-index: 6;
  @media (min-width: 576px) {
    width: 95vw;
  }
}

.text-a-wrapper {
  .line {
    margin-top: 20px;
    width: 110px;
    height: 4px;
    background: #0095ff;
  }
}
.border1,
.border2 {
  position: absolute;
  width: 90%;
  height: 90%;
  z-index: -1;
  margin: 22px;

  @media (min-width: 576px) {
    margin: 38px;
  }

  &::before {
    display: block;
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
  }

  &::after {
    display: block;
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
  }
}

.border1 {
  &::before {
    bottom: -5px;
    left: -5px;
    border-bottom: var(--border-width) solid var(--border-color);
    border-left: var(--border-width) solid var(--border-color);
  }
  &::after {
    top: -5px;
    right: -5px;

    border-top: var(--border-width) solid var(--border-color);
    border-right: var(--border-width) solid var(--border-color);
  }
}

.border2 {
  &::before {
    top: -5px;
    left: -5px;
    border-top: var(--border-width) solid var(--border-color);
    border-left: var(--border-width) solid var(--border-color);
  }
  &::after {
    right: -5px;
    bottom: -5px;
    border-right: var(--border-width) solid var(--border-color);
    border-bottom: var(--border-width) solid var(--border-color);
  }
}
</style>
