import { defineComponent } from "vue";
import authService from "../../../services/auth";
import notify from "../../../services/notify";
import appStore from "../../../store/app";
import TextField from "@/components/TextField";

export default defineComponent({
  name: "change-password-modal",
  components: { TextField },
  data() {
    return {
      loading: false,
      success: false,
      model: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        twoFaCode: "",
      },
      frmInvalidate: false,
      messageError: {
        password: null,
        new_password: null,
      },
    };
  },
  computed: {
    email() {
      return appStore.state.profile && appStore.state.profile.email;
    },
    disabled() {
      return (
        this.loading && this.model.newPassword && this.model.currentPassword
      );
    },
    twoFaEnbaled() {
      return appStore.state.profile && appStore.state.profile.twoFaEnabled;
    },
  },
  mounted() {
    $(this.$refs.currentPassword).focus();

    // $(this.$el).on('shown.bs.modal', () => {
    // })

    // $(this.$el).on('hidden.bs.modal', () => {
    // })
  },
  methods: {
    show() {
      this.fixedBody();
      $(this.$el).modal("show");
    },
    hide() {
      this.staticBody();
      $(this.$el).modal("hide");
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        password: null,
        new_password: null,
      };
    },
    confirm() {
      this.resetVaildate();
      if (this.model.newPassword !== this.model.confirmNewPassword) {
        this.messageError.password = "Your passwords does not match.";
        this.frmInvalidate = true;
      }
      if (this.model.currentPassword === this.model.newPassword) {
        this.messageError.new_password = "Can not use current password";
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      let that = this;
      authService
        .changePassword(
          this.model.currentPassword,
          this.model.newPassword,
          this.model.confirmNewPassword,
          this.model.twoFaCode
        )
        .then((resp) => {
          if (resp.error) {
            const list = resp.data.d;
            if (list.length > 0) {
              list.forEach(function (element) {
                if (element === "The VerifyCode field is required.") {
                  element = "Verify code field required";
                }
                if (element === "The NewPassword field is required.") {
                  element = "New password field required";
                }
                if (
                  element ===
                  "The NewPassword must be at least 6 characters long."
                ) {
                  element = "New password must be character";
                }
                if (element === "The CurrentPassword field is required.") {
                  element = "Current password field required";
                }
                if (element === "The ConfirmNewPassword field is required.") {
                  element = "Confirm new password field required";
                }
                notify.error(element);
              });
            } else {
              // if (resp.data.d.err_code === 'invalid-2fa-code') {
              //     notify.error('Google authentication incorrect')
              // } else {
              //     notify.error(resp.data.m)
              // }
            }
          } else {
            this.model.twoFaCode = "";
            this.model.currentPassword = "";
            this.model.newPassword = "";
            this.model.confirmNewPassword = "";

            notify.success("Your password has been changed");
            this.hide();
          }
          this.loading = false;
        });
    },
  },
  beforeUnmount() {
    this.clearScrollHeight();
    this.staticBody();
  },
});
