<template>
  <div class="leftMenu" :class="{ show: isShowLeftMenu }">
    <div class="mask" v-if="isShowLeftMenu" @click="hideMenu"></div>
    <div class="wrap" :class="pageSection">
      <ul>
        <li
          v-for="item in tabs.filter(
            (x) => !x.permission || hasPermission(x.permission)
          )"
          :key="item"
        >
          <a
            v-if="!item.isExternalLink"
            :href="item.link"
            @click.prevent="routePage(item.component)"
            :class="menuClass(item)"
          >
            <span>{{ item.text }}</span>
          </a>
          <a v-else :href="item.link" target="_blank" :class="menuClass(item)">
            <span>{{ item.text }}</span>
          </a>
        </li>
      </ul>

      <span class="logout pointer">{{ username }}</span>
      <span
        v-if="isShowSelectLpFund"
        class="logout pointer"
        @click="handleChangeFund"
        >Return to fund list &#8594;</span
      >
      <span class="logout pointer" @click="logout">Logout</span>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.leftMenu {
  position: fixed;
  top: 65px;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 300px;
  z-index: 10;
  transform: translateX(-300px);
  transition: transform 300ms linear;
  &.show {
    width: 100%;
    transform: translateX(0);
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    width: 300px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 2;
  }
  .logout {
    color: #fff;
    font-weight: normal;
    line-height: 52px;
    font-size: 20px;
    margin: 0 10px;
  }
  ul {
    margin: 10px;
    padding: 0;
    border-bottom: 1px solid #dee2e6;
    li {
      a {
        color: #fff;
        font-weight: normal;
        line-height: 52px;
        font-size: 20px;
        &.multiLine {
          line-height: 52px;
        }
        &.active {
          color: #4f8cff;
        }
      }
    }
  }
}
</style>
