import _ from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "collapse",
  props: ["id", "limitedNumber", "limitedNumberMobile", "classList", "items"],
  data() {
    return {
      matches: [],
      itemHeight: 0,
      isCollapse: false,
    };
  },
  computed: {
    matchCount() {
      return this.matches ? this.matches.length : 0;
    },
    itemCount() {
      return this.items ? this.items.length : 0;
    },
    seeMore() {
      return this.itemCount > this.matchCount;
    },
    deviceVersion: function () {
      return this.$store.state.mobile.version;
    },
    screenSize: {
      get() {
        return this.$store.state.mobile.size
      },
      set(value) {
        return value
      }
    }
  },
  watch: {
    screenSize(v) {
      this.onResize();
    }
  },
  mounted() {
    this.processMatches();
    this.onResize();
  },
  methods: {
    onResize() {
      this.processMatches();
    },
    processMatches() {
      if (this.isCollapse) return;
      if (this.items instanceof Array) {
        if (this.currentTab === "Live now") {
          this.matches = this.items;
        } else {
          let results = [];
          let limit_number = 0;

          if (this.deviceVersion === "pc") {
            limit_number = this.limitedNumber;
          } else {
            limit_number = this.limitedNumberMobile;
          }

          if (limit_number > 0) {
            results = this.items.slice(0, parseInt(limit_number));
          }
          if (results.length > 0) {
            this.matches = results;
          } else {
            this.matches = this.items;
          }
        }
      }
    },
    collapse() {
      this.isCollapse = !this.isCollapse;
      this.matches = this.items;
    },
  },
});
