<template>
  <div class="mb-2 custom-mb">
    <label v-if="title" :class="`title title-color-${color}`" :for="name">{{
      title
    }}</label>
    <div class="sub-title mb-3" v-if="fileUploaded">
      Your file uploaded:
      <a
        href="javascript:void(0)"
        title="View File"
        @click="viewFile(fileUploaded)"
        ><b>{{ fileUploaded.file_name }}{{ fileUploaded.extension }}</b></a
      >
    </div>
    <div
      :class="`text-field ${size ? 'size-' + size : ''} ${
        hideError ? 'hide-error' : ''
      }`"
    >
      <div v-if="dataAcceptValue" class="sub-title">
        Accept file type: {{ dataAcceptValue }}
      </div>
      <label
        :for="id"
        :class="{ custom: custom }"
        data-after="..."
        class="label-hover"
      >
        <input
          :class="`field-color-${field}`"
          type="file"
          :id="id"
          :ref="id"
          :accept="`${acceptType === 'FILE_EXTENSION' ? dataAcceptValue : ''}`"
          @change="valueDocumentFnc(id)"
          :index="indexField"
        />
      </label>
      <div v-if="errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.sub-title {
  font-size: 0.85em;
  a {
    text-decoration: underline !important;
  }
}
.label-hover {
  cursor: pointer;
}
.text-field {
  position: relative;
}
input {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 0;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 25px;
  font-size: 0.9em;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
}
.md-error {
  position: absolute;
  //bottom: -10px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
.field-color-gray {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #35495e;
}
.custom {
  border: 1px solid #949ba2;
  padding: 0 10px;
  line-height: 32px;
  border-radius: 6px;
  &:before {
    content: "Select a file ";
  }
  &:after {
    content: attr(data-after);
  }
  > input {
    display: none;
  }
}
</style>
