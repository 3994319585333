<template>
  <Modal
    :title="`${state == 'INSERT' ? 'Add' : 'Update'} Invitation Template`"
    v-model:value="isShow"
  >
    <form @submit.prevent="save" autocomplete="false">
      <div class="row">
        <div class="col-12">
          <TextField
            title="Name"
            name="name"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.name"
            v-model:value="form.name"
          />
        </div>
        <div class="col-12">
          <TextField
            title="Subject"
            name="subject"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.subject"
            v-model:value="form.subject"
          />
        </div>
        <div class="col-12">
          <TextArea
            v-model:value="form.body"
            title="Body"
            name="body"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.body"
          />
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save" :loading="loading">
        {{ state == "INSERT" ? "Add Template" : "Update" }}
      </NewButton>
    </template>
  </Modal>
</template>

<script src="./MessageManageModel.js"></script>
