<template>
  <div class="adminContent" v-loading="loading">
    <Modal
      :title="state === 'CREATE' ? 'Create Company' : 'Edit Company'"
      v-model:value="isShow"
      :loading="loading"
      @close="closeModal"
    >
      <form @submit.prevent="save" autocomplete="false">
        <div class="row">
          <div class="col-6">
            <Switcher v-model:value="formData.isActive" title="Active" name="active" :isShowText="false" :isHorizontal="true"/>
          </div>

          <div class="col-6">
            <Switcher v-model:value="formData.isExited" title="This company is exited" name="exited" :isShowText="false" :isHorizontal="true"/>
          </div>

          <div class="col-12 col-md-12">
            <TextField
              v-model:value="formData.companyName"
              title="Company Name"
              :autocomplete="false"
              :errorMsg="errorMsg.companyName"
              :fieldRequire="true"
              placeholder="Enter title"
            />
          </div>

          <div class="col-12 col-md-12">
            <div class="founder">
              <label>Founder <small class="require">*</small></label>
            </div>
            <div
              class="row"
              v-for="(founder, founderI) in founderInfo"
              :key="`${founderI}`"
            >
              <div class="col-12">
                <div class="founder">
                  <label>No. {{founderI + 1}}</label>
                </div>
              </div>
              <div class="col-6">
                <TextField
                    placeholder="Founder name"
                    v-model:value="founder.founderName"
                    :name="`founderName${founderI}`"
                    :autocomplete="false"
                    :errorMsg="errorMsg.founderInfo[founderI].founderName"
                  />
              </div>

              <div class="col-6">
                <TextField
                    placeholder="Founder title"
                    v-model:value="founder.founderTitle"
                    :name="`founderTitle${founderI}`"
                    :autocomplete="false"
                    :errorMsg="errorMsg.founderInfo[founderI].founderTitle"
                  />
              </div>

              <div class="col-6">
                <TextField
                    placeholder="Twitter"
                    v-model:value="founder.founderTwitter"
                    :name="`founderTwitter${founderI}`"
                    :autocomplete="false"
                  />
              </div>

              <div class="col-6">
                <TextField
                    placeholder="Linkedin"
                    v-model:value="founder.founderLinkedin"
                    :name="`founderLinkedin${founderI}`"
                    :autocomplete="false"
                  />
              </div>

              <div class="col-12 d-flex justify-content-end removeButton">
                <NewButton
                    @click="removeFounder(founderI)"
                    class="btn btn-danger" 
                    v-if="founderI > 0"
                  >
                    <i class="far fa-trash-alt"></i>
                  </NewButton>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-end addButton">
            <NewButton
              @click="addFounder"
              class="btn btn-success"
            >
              <i class="fa fa-plus"></i>
            </NewButton>
          </div>

          <div class="col-12 col-md-12">
            <TextField
              v-model:value="formData.title"
              title="Description title"
              :autocomplete="false"
              :errorMsg="errorMsg.title"
              :fieldRequire="true"
              placeholder="Enter title"
            />
          </div>
          <div class="col-12 col-md-12">
            <TextArea
              v-model:value="formData.description"
              title="Description detail"
              :autocomplete="false"
              :errorMsg="errorMsg.description"
              :fieldRequire="true"
              placeholder="Enter description"
            />
          </div>
          <div class="col-12 col-md-12">
            <TextField
              v-model:value="formData.website"
              title="Website"
              :autocomplete="false"
              :errorMsg="errorMsg.website"
              :fieldRequire="true"
              placeholder="Enter website"
            />
          </div>
          <div class="col-12 col-md-12">
            <FileField2
              v-model:value="itemImage"
              title="Thumbnail"
              :accept="`image/jpeg, image/png, image/jpg`"
              :init-preview-url="formData.thumbnail['s3_key']"
              class="uploadfile"
              :fieldRequire="true"
              :errorMsg="errorMsg.thumbnail"
              @input="changeFile"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <NewButton @click="save()">
          Save
        </NewButton>
      </template>
    </Modal>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.adminContent {
  position: initial;
  .removeButton{
    top:-15px;
    margin-bottom: 10px;
  }

  .founder{
      font-family: "ProximaNova";
      font-weight: 300;
      color: #ffffff;
      .require{
          color: #f52727;
      }
  }
}
</style>
