<template>
  <div class="deal-detail">
    <div class="row">
      <div class="col-8">
        <h2 class="mb-3">Deal details</h2>
      </div>
      <div
        class="col-4 text-end"
        v-if="
          dataDeal &&
          currentStage !== totalStage.length - 1 &&
          !dataDeal.is_rejected
        "
      >
        <EditIcon class="me-1" />
        <a class="edit" href="javascript:void(0)" @click="updateDeal"
          >Edit Submitted Form</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="info">
          <ul class="nav nav-tabs">
            <li v-for="(answer, i) in answers" :key="`answer-tab-${i}`">
              <a
                :class="[selectedTab == answer.segment_id ? 'active show' : '']"
                @click="doActiveTab(answer.segment_id)"
                ><span class="d-inline-block">{{ answer.text }}</span></a
              >
            </li>
          </ul>
          <div class="tab-content" v-if="dataDeal" v-loading="loading">
            <template
              v-for="(answer, i) in answers"
              :key="`answer-content-${i}`"
            >
              <div
                :class="[
                  'tab-pane',
                  selectedTab == answer.segment_id ? 'active show' : '',
                ]"
                v-if="selectedTab == answer.segment_id"
              >
                <div class="row">
                  <div
                    class="col-4 mb-3"
                    v-for="(item, j) in answer.data"
                    :key="`${i}${j}-item-a`"
                  >
                    <span class="value">{{ item.question_title }}</span>
                    <span
                      class="text type-file"
                      v-if="item.question_type == 'FILE'"
                      @click="viewFile(item.file)"
                      title="View File"
                      >{{ item.file.file_name }}{{ item.file.extension }}</span
                    >
                    <span
                      class="text"
                      v-if="item.question_type == 'TEXTFIELD'"
                      >{{ item.text }}</span
                    >
                    <span class="text" v-if="item.question_type == 'LINK'"
                      ><a :href="formatLink(item.text)" target="_blank">{{
                        item.text
                      }}</a></span
                    >
                    <span class="text" v-if="item.question_type == 'NUMBER'">{{
                      Intl.NumberFormat().format(item.number)
                    }}</span>
                    <span
                      class="text"
                      v-if="item.question_type == 'CURRENCY'"
                      >{{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(item.number)
                      }}</span
                    >
                    <span
                      class="text"
                      v-if="item.question_type == 'SINGLE_SELECT'"
                      >{{ item.text }}</span
                    >
                    <span
                      class="text"
                      v-if="item.question_type == 'MULTI_SELECT'"
                      >{{
                        item.array && item.array.length
                          ? item.array.join(", ")
                          : ""
                      }}</span
                    >
                    <span class="text" v-if="item.question_type == 'BOOLEAN'">{{
                      item.boolean ? "Yes" : "No"
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <DealAddUpdateModal
      v-if="
        totalStage[currentStage] &&
        totalStage[currentStage].id !== 'REJECTED' &&
        totalStage[currentStage].id !== 'PORTFOLIO'
      "
      ref="dealAddUpdateModal"
      :key="dealAddUpdateModalKey + 'dealAddUpdateModalKey'"
      @refresh="refreshOnDealUpdate"
    />
    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />
    <!-- <submitForm v-if="showModal" :hideModal="hideModal" /> -->
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.deal-detail {
  background-color: #2e323b;
  padding: 40px 0;
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
  a.edit {
    color: #0095ff;
  }
  .info {
    border-radius: 10px;
    border: 1px solid rgba(#d5d5d5, 0.1);
    padding: 5px 20px 20px 20px;
    background-color: rgba(#000, 0.1);
    .nav-tabs {
      border-bottom: 1px solid rgba(#707070, 0.2);
      li {
        cursor: pointer;
      }
      a {
        color: rgba(#f5f5f5, 0.4);
        font-size: 14px;
        position: relative;
        transition: all 200ms ease;
        word-break: break-all;
        &.active {
          color: #fff;
          &:after {
            content: "";
            display: block;
            overflow: hidden;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: #0095ff;
          }
        }
        span {
          padding: 0 20px;
          line-height: 40px;
          transition: all 200ms ease;
          white-space: nowrap;
        }
      }
    }
    .tab-content {
      padding: 20px 0 0 0;
      .value {
        font-size: 12px;
        color: rgba(#fff, 0.8);
        display: inline-block;
        width: 100%;
      }
      .text {
        color: rgba(#fff, 1);
        font-weight: bold;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        word-break: break-all;
        &.type-file {
          cursor: pointer;
          color: #0095ff;
        }
      }
    }
  }
}
</style>
