import router from "@/config/router";
import InformationPersonal from "../informationPersonal";
import appStore, { APP_MUTATION } from "@/store/app";
import _ from "lodash";

export default {
  name: "listOurTeamWithFund",
  props: {
    fundMembers: {
      type: Array,
      default: () => [],
    },
    showOurTeamSeemore: {
      type: Boolean,
      default: false,
    },
    // isShow: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  components: {
    InformationPersonal,
  },
  data() {
    return {
      ourTeamIndexPath: "/our-team",
      membersToShow: [],
      // isShow: false,
    };
  },
  computed: {
    isShowFundName() {
      return this.fundMembers && this.fundMembers.length > 1;
    },
  },
  methods: {
    showInformationPersonal(index, fundIdx) {
      this.fixedBody();
      appStore.commit(APP_MUTATION.active_slide_our_team, index);
      appStore.commit(APP_MUTATION.change_popup, true);
      this.membersToShow = _.cloneDeep(this.fundMembers[fundIdx].members);
      this.$nextTick(() => {
        this.$refs.informationPersonal.showModal();
        // this.isShow = true;
        // this.$modal.show("modalInformationPersonal");
      });
    },
  },
};
