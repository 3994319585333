<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_564_1026)">
<path d="M17.4928 13.0481C17.8924 12.8484 18.1406 12.4467 18.1406 12V10.8281H18.8725C20.149 10.8281 21.1875 9.78234 21.1875 8.49694V2.33119C21.1875 1.04578 20.149 0 18.8725 0H5.17434C3.89784 0 2.85938 1.04578 2.85938 2.33119V8.49689C2.85938 9.78234 3.89784 10.8281 5.17434 10.8281H5.90625V12C5.90625 12.4467 6.15445 12.8484 6.55406 13.0481C6.72127 13.1317 6.90084 13.173 7.07934 13.173C7.32741 13.1729 7.57345 13.0934 7.78125 12.9375L10.5938 10.8281H13.4531L16.2656 12.9375C16.623 13.2056 17.0932 13.248 17.4928 13.0481ZM6.60938 9.42188H5.17434C4.67325 9.42188 4.26562 9.00694 4.26562 8.49694V2.33119C4.26562 1.82119 4.67325 1.40625 5.17434 1.40625H18.8726C19.3736 1.40625 19.7812 1.82119 19.7812 2.33119V8.49689C19.7812 9.00694 19.3736 9.42183 18.8725 9.42183H17.4375C17.0492 9.42183 16.7344 9.73664 16.7344 10.125V11.5312L14.1094 9.56245C13.9877 9.47119 13.8397 9.42183 13.6875 9.42183H10.3594C10.2072 9.42183 10.0592 9.47119 9.9375 9.56245L7.3125 11.5312V10.125C7.3125 9.73669 6.99769 9.42188 6.60938 9.42188Z" fill="#5F6164" stroke="#292C34" stroke-width="0.228571"/>
<path d="M9.8712 21.1025C9.71239 19.7602 8.80925 18.669 7.60465 18.2206C7.86373 17.8186 8.01476 17.3408 8.01476 16.8281C8.01476 15.4065 6.85822 14.25 5.43664 14.25C4.01506 14.25 2.85851 15.4065 2.85851 16.8281C2.85851 17.3332 3.00509 17.8044 3.25705 18.2026C2.02756 18.6391 1.10159 19.7417 0.940624 21.1026L0.69078 23.2143C0.667202 23.4138 0.730015 23.6138 0.863468 23.764C0.996921 23.9142 1.18817 24 1.38903 24H9.4228C9.62365 24 9.81495 23.914 9.94836 23.7639C10.0818 23.6137 10.1446 23.4137 10.121 23.2142L9.8712 21.1025ZM5.43664 15.6562C6.08281 15.6562 6.60851 16.1819 6.60851 16.8281C6.60851 17.4742 6.08281 18 5.43664 18C4.79047 18 4.26476 17.4742 4.26476 16.8281C4.26476 16.1819 4.79047 15.6562 5.43664 15.6562ZM2.18028 22.5937L2.33717 21.2678C2.4627 20.2065 3.36326 19.4062 4.43192 19.4062H6.37995C7.44861 19.4062 8.34917 20.2065 8.4747 21.2678L8.63159 22.5937H2.18028Z" fill="#5F6164" stroke="#292C34" stroke-width="0.228571"/>
<path d="M23.3092 23.2142L23.0593 21.1025C22.9005 19.7602 21.9974 18.669 20.7928 18.2206C21.0518 17.8186 21.2029 17.3408 21.2029 16.8281C21.2029 15.4065 20.0463 14.25 18.6247 14.25C17.2032 14.25 16.0466 15.4065 16.0466 16.8281C16.0466 17.3332 16.1932 17.8044 16.4452 18.2026C15.2157 18.6391 14.2897 19.7417 14.1287 21.1026L13.8789 23.2143C13.8553 23.4138 13.9181 23.6138 14.0516 23.764C14.185 23.9142 14.3762 24 14.5771 24H22.6109C22.8118 24 23.0031 23.9141 23.1365 23.7639C23.2699 23.6138 23.3327 23.4137 23.3092 23.2142ZM18.6247 15.6562C19.2709 15.6562 19.7966 16.182 19.7966 16.8281C19.7966 17.4743 19.2709 18 18.6247 18C17.9786 18 17.4529 17.4743 17.4529 16.8281C17.4529 16.182 17.9785 15.6562 18.6247 15.6562ZM15.3683 22.5938L15.5252 21.2678C15.6508 20.2065 16.5514 19.4062 17.62 19.4062H19.568C20.6367 19.4062 21.5372 20.2065 21.6628 21.2678L21.8197 22.5938H15.3683Z" fill="#5F6164" stroke="#292C34" stroke-width="0.228571"/>
<path d="M6.89062 4.70557H13.5469C13.9352 4.70557 14.25 4.39075 14.25 4.00244C14.25 3.61413 13.9352 3.29932 13.5469 3.29932H6.89062C6.50231 3.29932 6.1875 3.61413 6.1875 4.00244C6.1875 4.39075 6.50231 4.70557 6.89062 4.70557Z" fill="#5F6164" stroke="#292C34" stroke-width="0.228571"/>
<path d="M17.8594 6.81494C17.8594 6.42663 17.5446 6.11182 17.1562 6.11182H6.89062C6.50231 6.11182 6.1875 6.42663 6.1875 6.81494C6.1875 7.20325 6.50231 7.51807 6.89062 7.51807H17.1562C17.5446 7.51807 17.8594 7.20325 17.8594 6.81494Z" fill="#5F6164" stroke="#292C34" stroke-width="0.228571"/>
</g>
<defs>
<clipPath id="clip0_564_1026">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
