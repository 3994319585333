<template>
  <div class="discussionArea">
    <div class="row mb-3">
      <div class="col-12">
        <h3 class="mb-0">Discussion</h3>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="comment" v-loading="loading">
          <ul class="nav nav-tabs">
            <li>
              <a
                data-bs-toggle="tab"
                class="active show"
                href="#write"
                @click="action('comment')"
                ><span class="d-inline-block">Write</span></a
              >
            </li>
            <li>
              <a data-bs-toggle="tab" href="#upload"
                ><span class="d-inline-block" @click="action('upload')"
                  >Upload file</span
                ></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div id="write" class="tab-pane active show">
              <textarea
                class="mb-2"
                ref="message"
                placeholder="Write Something here"
                @keyup="valueMessageFnc('message')"
              ></textarea>
              <div class="row">
                <div class="col-8 d-flex align-items-center">
                  <div v-if="reviewers">
                    <template
                      v-for="(reviewer, index) in reviewers"
                      :key="`reviewers_${index}`"
                    >
                      <span v-if="reviewer.notify_sms || reviewer.notify_email">
                        <span class="reviewers">
                          {{ reviewer.user_name }}
                          (
                          <i v-if="reviewer.notify_sms">sms</i
                          ><template
                            v-if="reviewer.notify_sms && reviewer.notify_email"
                            >, </template
                          ><i v-if="reviewer.notify_email">email</i>
                          )
                        </span>
                        <span class="reviewers">{{
                          index !== reviewers.length - 1 ? "|" : ""
                        }}</span>
                      </span>
                    </template>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <button class="btn btn-success" @click="showConfigNoti">
                    Notification
                  </button>
                </div>
              </div>
            </div>
            <div id="upload" class="tab-pane">
              <div class="form-group mb-0">
                <input
                  type="file"
                  ref="file"
                  @change="valueDocumentFnc('file')"
                />
                <a
                  href="javascript:void(0)"
                  class="clr-button"
                  v-if="valueFile"
                  @click="clearFileUpload"
                  >Remove File</a
                >
                <div v-if="process_flag" class="process">
                  <!-- Browsers that support HTML5 progress element will ignore the html inside `progress` element. Whereas older browsers will ignore the `progress` element and instead render the html inside it. -->
                  <div class="progress-bar">
                    <span :style="`width: ${value}%`"></span>
                  </div>
                  <p>{{ value }} %</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-end">
        <template v-if="currentType === 'comment'">
          <button
            class="btn btn-info submit"
            @click="submitComment"
            v-if="valueMessage && currentStage >= 0"
            :disabled="loading"
          >
            Comment
          </button>
          <button class="btn btn-info submit" disabled v-else>Comment</button>
        </template>
        <template v-else>
          <button
            class="btn btn-info submit"
            @click="submitUpload"
            v-if="valueFile && currentStage >= 0"
            :disabled="loading || isUploading"
          >
            Upload
          </button>
          <button class="btn btn-info submit" disabled v-else>Upload</button>
        </template>
      </div>
    </div>
    <reviewer
      ref="reviewer"
      @changeRelReviewers="changeReviewers"
      :relReviewers="reviewers"
      :totalStage="totalStage"
      :currentStage="currentStage"
    />
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.discussionArea {
  padding: 28px 0;
  h3 {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
  }
  .comment {
    border-radius: 10px;
    border: 1px solid rgba(213, 213, 213, 0.1);
    padding: 5px 20px 20px 20px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-tabs {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    a {
      color: rgba(245, 245, 245, 0.4);
      font-size: 14px;
      position: relative;
      span {
        padding: 0 20px;
        line-height: 40px;
      }
      &.active {
        color: #fff;
        font-weight: bold;
        &:after {
          content: "";
          display: block;
          overflow: hidden;
          left: 0;
          bottom: 0;
          //   width: 100%;
          height: 3px;
          border-radius: 3px;
          background-color: #0095ff;
        }
      }
    }
  }
  .tab-content {
    padding: 20px 0 0 0;
    #upload input {
      cursor: pointer;
    }
    .reviewers {
      font-size: 12px;
      vertical-align: middle;
      color: rgba(#fff, 0.3);
      > i {
        font-style: italic;
      }
    }
    textarea {
      width: 100%;
      height: 132px;
      resize: none;
      background-color: transparent;
      border: 0px;
      outline: 0;
      font-size: 14px;
      color: rgba(#fff, 0.4);
      border-bottom: 1px solid rgba(#fff, 0.3);
    }
    .form-group {
      position: relative;
      &:before {
        position: absolute;
        bottom: 10px;
        left: 0;
        pointer-events: none;
        width: 100%;
        right: 0;
        height: 57px;
        content: attr(data-before);
        display: block;
        margin: 0 auto;
        color: #2ea591;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
      }
      &:after {
        pointer-events: none;
        position: absolute;
        top: 30px;
        left: 0;
        width: 50px;
        right: 0;
        height: 56px;
        content: "";
        background-image: url("../../../../../assets/images/upload1.png");
        display: block;
        margin: 0 auto 10px;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .clr-button {
        position: absolute;
        bottom: 0;
        left: calc(50% - 44px);
      }
    }
    input[type="file"] {
      color: rgba(#fff, 0.4);
      font-size: 14px;
      padding: 70px 0px 85px 40%;
      text-align: center !important;
      margin: 0;
      width: 100% !important;
      opacity: 0;
    }
  }
  button.submit {
    color: #fff !important;
    //background-color: #0095FF;
    background-color: #1594f0 !important;
    // background-color: #4f97ca;
    line-height: 28px;
    font-weight: bold;
    width: 180px;
    &:disabled {
      //cursor: no-drop !important;
      cursor: no-drop;
    }
  }
  .process {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
/* Fallback technique styles */
.progress-bar {
  background-color: whiteSmoke;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;

  /* Dimensions should be similar to the parent progress element. */
  width: 100%;
  height: 5px;
  max-width: 200px;
}

.progress-bar span {
  background-color: royalblue;
  border-radius: 3px;

  display: block;
  text-indent: -9999px;
  line-height: 5px;
  height: 5px;
  transition: width 0.2s linear;
}
p {
  position: relative;
  color: white;
}
</style>
