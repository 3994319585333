import AdminServices from "../../../services/admin";
import addPartner from "./addPartner";
import router from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      readyRender: false,
      listData: [],
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      isShowAddPartner: false,
      ownershipPath: router.partnerOwnership.path,
      ownershipName: router.partnerOwnership.name,
      partnerGoalPath: router.partnerGoal.path,
      partnerGoalName: router.partnerGoal.name,
      partnerDataViewPath: router.partnerDataView.path,
      partnerDataViewName: router.partnerDataView.name,
      partnerKpiPath: router.partnerKpiInput.path,
      partnerKpiName: router.partnerKpiInput.name,
    };
  },
  components: {
    addPartner,
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    getPartners() {
      this.loading = true;
      this.listData = [];
      AdminServices.getPartners({
        page: this.paging.page,
        size: this.paging.size,
      }).then((resp) => {
        if (resp.data.ok) {
          this.paging.page = resp.data.d.p;
          this.paging.total = resp.data.d.t;
          this.paging.size = resp.data.d.s;

          this.listData = resp.data.d.c;

          this.readyRender = true;
        }
        this.loading = false;
      });
    },
    loadPage(value) {
      this.paging.page = value;
      this.getPartners();
    },
    addNewPartner() {
      this.isShowAddPartner = true;
    },
    hideAddPartner() {
      this.isShowAddPartner = false;
    },
  },
});
