<template>
  <div class="camera">
    <!-- ------------
    <button @click="openStream">open stream</button>
    <button @click="openStream">hide stream</button> -->
    <qrcode-stream></qrcode-stream>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.camera{
  button{}
}
</style>