<template>
  <div class="adminContent p-3">
    <div class="data mb-3" v-loading="loading">
      <h5 class="m-0 p-3">INSTAGRAM</h5>
      <div class="row px-3 mb-1">
        <div class="col-12">
          Please enter Instagram Access Token
          <input
            type="text"
            class="form-control"
            v-model="data.token"
            :disabled="!editIgAT"
          />
          <span class="caption ml-3"
            >Expires at:
            <span class="val" v-if="data.expires_at > 0"
              >{{ $filters.parseEstTime(data.expires_at) }} ({{ daysLeft }} days
              left)</span
            >
            <span class="val" v-else>Unknown</span>
          </span>
        </div>
      </div>
      <div class="row px-3">
        <div class="col-12">
          <button
            class="btn btn-primary pointer mb-3 me-1"
            @click.prevent="updateIgAT"
            v-if="editIgAT"
          >
            Save Access Token
          </button>
          <button
            class="btn pointer mb-3"
            :class="{ 'btn-primary': !editIgAT, 'btn-default': editIgAT }"
            @click.prevent="toggleEditIgAT"
          >
            {{ !editIgAT ? "Edit Access Token" : "Cancel" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.caption {
  color: #aaa;
  font-size: 12px;

  .val {
    color: #777;
    font-style: italic;
  }
}
</style>
