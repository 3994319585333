<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_564_981)">
<path d="M7 17H4C3.86739 17 3.74021 17.0527 3.64645 17.1464C3.55268 17.2402 3.5 17.3674 3.5 17.5V20.5C3.5 20.6326 3.55268 20.7598 3.64645 20.8536C3.74021 20.9473 3.86739 21 4 21H7C7.13261 21 7.25979 20.9473 7.35355 20.8536C7.44732 20.7598 7.5 20.6326 7.5 20.5V17.5C7.5 17.3674 7.44732 17.2402 7.35355 17.1464C7.25979 17.0527 7.13261 17 7 17ZM6.5 20H4.5V18H6.5V20Z" fill="#008AFF"/>
<path d="M7 11.5H4C3.86739 11.5 3.74021 11.5527 3.64645 11.6464C3.55268 11.7402 3.5 11.8674 3.5 12V15C3.5 15.1326 3.55268 15.2598 3.64645 15.3536C3.74021 15.4473 3.86739 15.5 4 15.5H7C7.13261 15.5 7.25979 15.4473 7.35355 15.3536C7.44732 15.2598 7.5 15.1326 7.5 15V12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5ZM6.5 14.5H4.5V12.5H6.5V14.5Z" fill="#008AFF"/>
<path d="M9.5 9.5H16C16.1326 9.5 16.2598 9.44732 16.3536 9.35355C16.4473 9.25979 16.5 9.13261 16.5 9C16.5 8.86739 16.4473 8.74021 16.3536 8.64645C16.2598 8.55268 16.1326 8.5 16 8.5H9.5C9.36739 8.5 9.24021 8.55268 9.14645 8.64645C9.05268 8.74021 9 8.86739 9 9C9 9.13261 9.05268 9.25979 9.14645 9.35355C9.24021 9.44732 9.36739 9.5 9.5 9.5Z" fill="#008AFF"/>
<path d="M9.5 7.5H16C16.1326 7.5 16.2598 7.44732 16.3536 7.35355C16.4473 7.25979 16.5 7.13261 16.5 7C16.5 6.86739 16.4473 6.74021 16.3536 6.64645C16.2598 6.55268 16.1326 6.5 16 6.5H9.5C9.36739 6.5 9.24021 6.55268 9.14645 6.64645C9.05268 6.74021 9 6.86739 9 7C9 7.13261 9.05268 7.25979 9.14645 7.35355C9.24021 7.44732 9.36739 7.5 9.5 7.5Z" fill="#008AFF"/>
<path d="M13.5 14H9.5C9.36739 14 9.24021 14.0527 9.14645 14.1464C9.05268 14.2402 9 14.3674 9 14.5C9 14.6326 9.05268 14.7598 9.14645 14.8536C9.24021 14.9473 9.36739 15 9.5 15H13.5C13.6326 15 13.7598 14.9473 13.8536 14.8536C13.9473 14.7598 14 14.6326 14 14.5C14 14.3674 13.9473 14.2402 13.8536 14.1464C13.7598 14.0527 13.6326 14 13.5 14Z" fill="#008AFF"/>
<path d="M9.5 13H13.5C13.6326 13 13.7598 12.9473 13.8536 12.8536C13.9473 12.7598 14 12.6326 14 12.5C14 12.3674 13.9473 12.2402 13.8536 12.1464C13.7598 12.0527 13.6326 12 13.5 12H9.5C9.36739 12 9.24021 12.0527 9.14645 12.1464C9.05268 12.2402 9 12.3674 9 12.5C9 12.6326 9.05268 12.7598 9.14645 12.8536C9.24021 12.9473 9.36739 13 9.5 13Z" fill="#008AFF"/>
<path d="M12 19.5H9.5C9.36739 19.5 9.24021 19.5527 9.14645 19.6464C9.05268 19.7402 9 19.8674 9 20C9 20.1326 9.05268 20.2598 9.14645 20.3536C9.24021 20.4473 9.36739 20.5 9.5 20.5H12C12.1326 20.5 12.2598 20.4473 12.3536 20.3536C12.4473 20.2598 12.5 20.1326 12.5 20C12.5 19.8674 12.4473 19.7402 12.3536 19.6464C12.2598 19.5527 12.1326 19.5 12 19.5Z" fill="#008AFF"/>
<path d="M12 17.5H9.5C9.36739 17.5 9.24021 17.5527 9.14645 17.6464C9.05268 17.7402 9 17.8674 9 18C9 18.1326 9.05268 18.2598 9.14645 18.3536C9.24021 18.4473 9.36739 18.5 9.5 18.5H12C12.1326 18.5 12.2598 18.4473 12.3536 18.3536C12.4473 18.2598 12.5 18.1326 12.5 18C12.5 17.8674 12.4473 17.7402 12.3536 17.6464C12.2598 17.5527 12.1326 17.5 12 17.5Z" fill="#008AFF"/>
<path d="M6.645 6.64509L5 8.29509L4.355 7.64509C4.30838 7.59847 4.25304 7.56149 4.19213 7.53626C4.13122 7.51103 4.06593 7.49805 4 7.49805C3.93407 7.49805 3.86879 7.51103 3.80788 7.53626C3.74697 7.56149 3.69162 7.59847 3.645 7.64509C3.59838 7.69171 3.5614 7.74706 3.53617 7.80797C3.51094 7.86888 3.49796 7.93416 3.49796 8.00009C3.49796 8.13324 3.55085 8.26094 3.645 8.35509C5.29 9.99509 4.645 10.0551 7.355 7.35509C7.44915 7.26094 7.50205 7.13324 7.50205 7.00009C7.50205 6.86694 7.44915 6.73924 7.355 6.64509C7.26085 6.55094 7.13315 6.49805 7 6.49805C6.86685 6.49805 6.73915 6.55094 6.645 6.64509Z" fill="#008AFF"/>
<path d="M22.125 6.56978C21.7229 6.33919 21.2458 6.27706 20.798 6.39697C20.3502 6.51688 19.9681 6.80908 19.735 7.20978L19 8.49978C19 3.64478 19.04 3.87978 18.88 3.68978C15.585 -0.17022 15.865 0.12978 15.725 0.0597795C15.47 -0.0752205 15.5 -0.000220466 1.5 -0.000220466C1.36739 -0.000220466 1.24021 0.052458 1.14645 0.146226C1.05268 0.239994 1 0.367171 1 0.49978V23.4998C1 23.6324 1.05268 23.7596 1.14645 23.8533C1.24021 23.9471 1.36739 23.9998 1.5 23.9998H18.5C18.6326 23.9998 18.7598 23.9471 18.8536 23.8533C18.9473 23.7596 19 23.6324 19 23.4998V15.4998C20.43 13.0248 21.375 11.3898 22.765 8.99978C22.8862 8.79916 22.9658 8.57625 22.9992 8.34427C23.0326 8.11229 23.019 7.87596 22.9593 7.64932C22.8996 7.42268 22.795 7.21034 22.6517 7.0249C22.5084 6.83945 22.3293 6.68469 22.125 6.56978V6.56978ZM16 1.84978L17.415 3.49978H16V1.84978ZM18 22.9998H2V0.99978H15V3.99978C15 4.13239 15.0527 4.25957 15.1464 4.35333C15.2402 4.4471 15.3674 4.49978 15.5 4.49978H18V10.2148L14.11 16.9548C14.025 17.0998 14.06 16.9548 13.875 19.9548C13.8709 20.0229 13.8808 20.0912 13.9041 20.1553C13.9274 20.2195 13.9636 20.2782 14.0104 20.3279C14.0572 20.3775 14.1138 20.4171 14.1765 20.4441C14.2392 20.471 14.3067 20.4849 14.375 20.4848C14.59 20.4848 14.495 20.4848 16.985 18.8648C17.115 18.7748 17.045 18.8648 17.985 17.2048L18 22.9998ZM15 17.6198C15.4306 17.6825 15.8207 17.908 16.09 18.2498L14.915 19.0248L15 17.6198ZM16.7 17.4298C16.3457 17.0626 15.8952 16.8027 15.4 16.6798L19.455 9.67978L20.755 10.4298L16.7 17.4298ZM21.9 8.45978L21.275 9.54478L20 8.79478L20.625 7.70978C20.739 7.57395 20.8975 7.48291 21.0723 7.45284C21.2471 7.42277 21.4268 7.45561 21.5797 7.54553C21.7326 7.63545 21.8486 7.77663 21.9073 7.94401C21.9659 8.11138 21.9633 8.29412 21.9 8.45978V8.45978Z" fill="#008AFF"/>
</g>
<defs>
<clipPath id="clip0_564_981">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
