<template>
  <div class="modal fade modal-custom-v2" :class="{ 'show-popup': isShow }">
    <!-- <div class="overlay" @click="close"></div> -->
    <div :class="`modal-fullscreen`">
      <div
        class="modal-content modal-content-v2"
        :class="[{ visible: isShow }]"
      >
        <Slider
          :members="members"
          :modalShow="isShow"
          @close="hideModal"
        ></Slider>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Slider from "@/components/commons/slider-ourteam";

export default defineComponent({
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    // isShow: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  components: {
    Slider,
  },
  data() {
    return {
      isShow: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth - 10;
      this.window.height = window.innerHeight;
    },
    hideModal() {
      this.isShow = false;
    },
    showModal() {
      this.isShow = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-custom-v2 {
  height: 100vh;
  overflow: hidden;
}

.show-popup {
  background: #0d1d2a;
  opacity: 1 !important;
  visibility: visible;
  display: block;
}
/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
} */

.modal-content-v2 {
  background: #0d1d29;
}
</style>
