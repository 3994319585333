<template>
  <div>
    <div v-if="!isHorizontal">
      <label
        v-if="title"
        :for="name"
        :class="`custom title title-color-${color} `"
        >{{ title }}</label
      >
      <div :class="`text-field`">
        <input
          type="checkbox"
          :checked="value"
          :id="name"
          v-model="_value"
          :index="indexField"
        />
      </div>
      <div>
        <label
          :for="name"
          :class="`text-field input-checkbox ${value ? 'active' : ''}`"
        ></label>
      </div>
    </div>
    <div v-else>
      <div class="custom-checkbox">
        <input
          class="h-checkbox"
          type="checkbox"
          :checked="value"
          :id="name"
          v-model="_value"
          :index="indexField"
        />
        <label v-if="title" :class="`title title-color-${color}`" :for="name">{{
          title
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    indexField: { type: Number, default: 0 },
    isHorizontal: { type: Boolean, default: false },
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val = false) {
        this.$emit("input", val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.text-field {
  position: relative;
  display: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .h-checkbox {
    margin-right: 10px;
  }
}

input {
  width: 24px;
  height: 24px;

  outline: none;
  border: 0;
  border-radius: 0;
  padding: 2px 7px;
  font-size: 0.9em;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000;
  cursor: pointer;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
.custom.title {
  margin-bottom: 5px;
}
.input-checkbox {
  border: 1px solid #949ba2;
  position: relative;
  border-radius: 3px;
  font-size: 0.8em;
  transition: ease all 0.2s;
  cursor: pointer;
  display: inline-block;
  padding: 6px;
  height: 6px;
  &:before {
    display: none;
    background-color: transparent;
    position: absolute;
    left: 3px;
    top: 0.3px;
    width: 5px;
    content: "";
    border-bottom: 2.5px solid #fff;
    height: 10px;
    border-right: 2.5px solid #fff;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.input-checkbox.active {
  background-color: #007bff;
  border-color: #007bff;
  &:before {
    display: inline;
  }
}
</style>
