import { defineComponent } from "vue";
import AdminServices from "@/services/admin";
import { PERMISSIONS } from "@/config/router";
import notify from "@/services/notify";

import Modal from "@/components/Modal";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";

const columnsData = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-end",
    columnClass: "text-end action-icon-group",
  },
];

export default defineComponent({
  components: {
    Modal,
    Table,
    TextField,
  },
  props: {
    exceptUserIds: { type: Array, default: [] },
    stageId: { type: String, default: null },
    existData: { type: Object, default: null },
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        size: 10,
        total: 0,
        page: 1,
      },
      pageSelected: 1,
      isShow: false,
      searchKey: "",
      user_id: 0,
      stage_id: "",
      notify_sms: true,
      notify_email: true,
      PERMISSIONS: PERMISSIONS,
      filteredData: [],
      filteredHiddenData: [],
      sizeOptions: [10, 20, 50],
      size: 10,
      currentPage: 1,
      dataRows: [],
      columns: columnsData,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.doSearch();
    });
  },
  watch: {
    "paging.page": function (v) {
      debounce(this.doSearch);
    },
    searchKey() {
      this.setPageDefault();
      debounce(this.doSearch);
    },
    rows: function (v) {
      let vue = this;
      this.filteredHiddenData = [];
      v.forEach(function (a) {
        if (!vue.existData.find((p) => p.user_id === a.id)) {
          vue.filteredHiddenData.push(a);
        }
      });
      this.paging.total = Math.ceil(
        this.filteredHiddenData.length / this.paging.size
      );
      this.filterData(this.paging.page);
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    filterData: function (idx) {
      this.pageSelected = idx;
      this.paging.page = idx;
      this.filteredData = [];
      this.filteredHiddenData.map((p, index) => {
        if (
          index >= this.paging.page * this.paging.size &&
          index < this.paging.page * this.paging.size + this.paging.size
        ) {
          this.filteredData.push(p);
        }
      });
    },
    async doSearch() {
      this.loading = true;
      const res = await AdminServices.getAllRokkerUsers({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredType: "Rokker",
        filteredName: this.searchKey.trim(),
        filteredStatus: "ACTIVE",
        exceptUserIds: this.exceptUserIds,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async AddUser(item) {
      this.loading = true;
      const res = await AdminServices.createStageReviewers({
        user_id: item.id,
        stage_id: this.stage_id,
        notify_sms: true,
        notify_email: true,
      });
      if (res.data.ok) {
        notify.success("Add Reviewer success!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't completed!");
      }
      this.loading = false;
    },
    showAdd(stage_id) {
      this.isShow = true;
      this.stage_id = stage_id;
    },
    pageClick(event) {
      this.currentPage = event.target;
      this.changePaging();
    },
    changePaging() {
      if (this.isSizeChange) this.size = this.sizeChange;
      this.$emit("update:paging", {
        sorts: this.sorts,
        size: this.size,
        page: this.currentPage,
      });
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
