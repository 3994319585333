<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="mask" @click.prevent="hide()"></div>
    <div class="modal-dialog simple modal-sm modal-2fa-custom">
      <form
        @submit.prevent="submit()"
        :disabled="!key || success"
        v-if="!enable2faLogin"
      >
        <div class="modal-content" v-loading="loading">
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <g
                class="nc-icon-wrapper"
                stroke-linecap="square"
                stroke-linejoin="miter"
                stroke-width="2"
                fill="#ffffff"
                stroke="#ffffff"
              >
                <g class="nc-interact_menu-close-2-o-32">
                  <path
                    fill="none"
                    stroke="#ffffff"
                    stroke-miterlimit="10"
                    d="M2 6h28"
                    transform="translate(0 10.00) rotate(45.00 16 6)"
                  />
                  <path
                    data-color="color-2"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M2 16h28"
                    opacity="0"
                  />
                  <path
                    fill="none"
                    stroke="#ffffff"
                    stroke-miterlimit="10"
                    d="M2 26h28"
                    transform="translate(0 -10) rotate(-45 16 26)"
                  />
                </g>
              </g>
            </svg>
          </button>
          <div class="modal-body">
            <div class="subtitle">
              <h3 class="font-weight-bold text-center">
                Google authentication
              </h3>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <p>Enter the key to add a token</p>
                <p class="desc">
                  <small class="description">
                    <span class="noted colorRed">
                      Type the provided key into the app
                    </span>
                  </small>
                </p>
              </div>
            </div>
            <div class="boxContent g_auth">
              <div class="row">
                <div class="col-12">
                  <div class="text-center qr-code-wrap">
                    <qr-code :value="deepLink" class="qr-200 mx-auto" />
                  </div>
                  <div class="row backupkey">
                    <div class="col-md-3 label">Backup key:</div>
                    <div class="col-md-9 key pl-0">
                      <div class="grouplabel">
                        <div class="input-group transparent">
                          <input
                            ref="keyEl"
                            :value="key"
                            type="text"
                            readonly
                            class="form-control pl-3 pl-sm-0"
                          />
                          <div class="input-group-append">
                            <a
                              @click.prevent="copyKey"
                              href="#"
                              class="input-group-addon"
                              v-bind:class="{ copied: copied }"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 14 16"
                              >
                                <g
                                  id="single-copy-04"
                                  transform="translate(-2)"
                                >
                                  <g id="Group_190" data-name="Group 190">
                                    <rect
                                      id="Rectangle_502"
                                      data-name="Rectangle 502"
                                      class="icon-copy"
                                      width="10"
                                      height="12"
                                      transform="translate(2.5 3.5)"
                                    />
                                    <path
                                      id="Path_90"
                                      data-name="Path 90"
                                      class="icon-copy"
                                      d="M4.5.5h11v13"
                                    />
                                    <line
                                      id="Line_187"
                                      data-name="Line 187"
                                      class="icon-copy"
                                      x2="4"
                                      transform="translate(5.5 6.5)"
                                    />
                                    <line
                                      id="Line_188"
                                      data-name="Line 188"
                                      class="icon-copy"
                                      x2="4"
                                      transform="translate(5.5 9.5)"
                                    />
                                    <line
                                      id="Line_189"
                                      data-name="Line 189"
                                      class="icon-copy"
                                      x2="4"
                                      transform="translate(5.5 12.5)"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </a>
                            <!-- <span v-if="copied" class="alertCopied bounceIn">Copied</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <md-field>
                                        <label>Password</label>
                                        <md-input v-model="password" type="password"></md-input>
                                    </md-field> -->

                  <TextField
                    title="Password"
                    name="password"
                    :indexField="1"
                    :fieldRequire="true"
                    v-model:value="password"
                    type="password"
                  />

                  <!-- <md-field class="last-child">
                                        <label>TwoFA code</label>
                                        <md-input v-model="code" type="number"></md-input>
                                    </md-field>  -->
                  <TextField
                    class="last-child"
                    title="TwoFA code"
                    :indexField="2"
                    :fieldRequire="true"
                    v-model:value="code"
                    type="number"
                  />
                  <div class="listTools">
                    <div class="row">
                      <div class="col-md-6 offset-md-3">
                        <button
                          v-if="!success"
                          :disabled="!key || !code || !password"
                          type="submit"
                          class="button btn-block btn-large round btn-green btn-hover investor btn-bounce btn-active"
                        >
                          Enable
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form
        @submit.prevent="submit2FALogin()"
        :disabled="!key || success"
        v-else
      >
        <div class="modal-content" v-loading="loading">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click.prevent="hide()"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g
                  class="nc-icon-wrapper"
                  stroke-linecap="square"
                  stroke-linejoin="miter"
                  stroke-width="2"
                  fill="#ffffff"
                  stroke="#ffffff"
                >
                  <g class="nc-interact_menu-close-2-o-32">
                    <path
                      fill="none"
                      stroke="#ffffff"
                      stroke-miterlimit="10"
                      d="M2 6h28"
                      transform="translate(0 10.00) rotate(45.00 16 6)"
                    />
                    <path
                      data-color="color-2"
                      fill="none"
                      stroke-miterlimit="10"
                      d="M2 16h28"
                      opacity="0"
                    />
                    <path
                      fill="none"
                      stroke="#ffffff"
                      stroke-miterlimit="10"
                      d="M2 26h28"
                      transform="translate(0 -10) rotate(-45 16 26)"
                    />
                  </g>
                </g>
              </svg>
            </button>
            <div class="subtitle">
              <h3 class="font-weight-bold text-center">
                Google authentication
              </h3>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="description">
                  <small>Google Twofa enable</small>
                </div>
              </div>
            </div>
            <div class="boxContent">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      ref="codeRef"
                      placeholder="TwoFA code"
                      v-model="code"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6 offset-md-3">
                        <!-- <button v-if="!success" :disabled="!code" type="submit" class="button btn-block btn-large round  btn-green btn-hover investor btn-bounce btn-active">Turn on</button> -->
                        <button
                          :disabled="!code"
                          type="submit"
                          class="button btn-block btn-large round btn-green btn-hover investor btn-bounce btn-active"
                        >
                          Turn on
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="boxGA">
        <form @submit.prevent="submit()" :disabled="!key || success">
            <div class="subtitle">
                <span>Google Authenticator</span>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="description">
                        <span>Open GA and scan the QR code below or enter the key to add a token</span>
                        <span class="noted"> this key is used to retrieve your ga when you change or lose your phone. please save and back up the key before binding ga.</span>
                    </div>
                </div>
            </div>
            <div class="boxContent">
                <div class="row">
                    <div class="col-md-4">
                        <qr-code :value="deepLink" />
                        <div class="row backupkey">
                            <div class="col-md-5 label">Backup key:</div>
                            <div class="col-md-7 key">
                                <div class="grouplabel">
                                    <div class="input-group transparent">
                                        <input ref="keyEl" :value="key" type="text" readonly class="form-control">
                                        <div class="input-group-append">
                                            <a @click.prevent="copyKey" href="#" class="input-group-addon" v-bind:class="{copied: copied}">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
                                                    <g id="single-copy-04" transform="translate(-2)">
                                                        <g id="Group_190" data-name="Group 190">
                                                            <rect id="Rectangle_502" data-name="Rectangle 502" class="icon-copy" width="10" height="12" transform="translate(2.5 3.5)" />
                                                            <path id="Path_90" data-name="Path 90" class="icon-copy" d="M4.5.5h11v13" />
                                                            <line id="Line_187" data-name="Line 187" class="icon-copy" x2="4" transform="translate(5.5 6.5)" />
                                                            <line id="Line_188" data-name="Line 188" class="icon-copy" x2="4" transform="translate(5.5 9.5)" />
                                                            <line id="Line_189" data-name="Line 189" class="icon-copy" x2="4" transform="translate(5.5 12.5)" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                            <span v-if="copied" class="alertCopied bounceIn">Copied</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <input type="password" placeholder="Login password" v-model="password" class="form-control input-green input-border-none">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <input type="text" placeholder="Google Authenticator Code" v-model="code" class="form-control input-green input-border-none">
                            </div>
                        </div>
                        <div class="listTools">
                            <button v-if="!success" :disabled="!key || !code || !password" type="submit" class="button btn-large round  btn-green btn-hover investor btn-bounce btn-active">Enable</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div> -->
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.white-theme {
  .modal .modal-dialog {
    color: #5d6481;
  }
  .backupkey input {
    color: #5d6481;
  }
  .backupkey .input-group-addon .icon-copy {
    stroke: #141414;
  }
  .modal .modal-dialog .modal-content {
    background-color: #fff;
  }
  .backupkey .label {
    color: #5d6481;
  }
  .md-field label {
    color: #5d6481;
    opacity: 0.5;
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .white-theme {
    .modal.show
      .modal-dialog.modal-2fa-custom
      .modal-content
      .boxContent
      .backupkey
      .label {
      color: #5d6481;
      opacity: 1;
    }
    .button.btn-green.btn-bounce:hover {
      color: #2177ff;
    }
  }
}
</style>
