<template>
    <label class="toogle" @click.prevent="change">
        <input type="checkbox" class="switch-input" ref="status" v-if="value" />
        <input type="checkbox" class="switch-input" ref="status" v-else />
        <div class="switch-core" :class="value?'on':'off'">
            <span class="switch-label" :class="value?'left':'right'">{{value?text[0]:text[1]}}</span>
            <div class="switch-button" :class="value?'right':'left'"></div>
        </div>
    </label>
</template>
<style lang="scss" scoped>
label{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    &.toogle{
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }
    .switch-input{
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;
    }
    .switch-core{
        display: block;
        overflow: hidden;
        position: relative;
        width: 60px;
        height: 26px;
        border-radius: 13px;
        cursor: pointer;
        &.on{
            background-color: rgb(117, 199, 145);
        }
        &.off{
            background-color: rgb(191, 203, 217);
             > *{
                opacity: 1;
             }
        }
        .switch-label{
            color: #fff;
            font-size: 10px;
            position: absolute;
            top: 3px;
            font-weight: bold;
            &.left{
                left: 12px;
            }
            &.right{
                left: calc(100% - 27px);
            }
        }
        .switch-button{
            position: absolute;
            top: 3px;
            left: 0;
            z-index: 2;
            background-color: #fff;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            transition: left 300ms linear;
            &.left{
                left: 4px;
            }
            &.right{
                left: calc(100% - 24px);
            }
        }
    }
}
</style>

<script src="./index.js"></script>

