<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideDownloadItem"></div>
      <div class="modal-dialog modal-dialog-sm">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">No preview available</h4>
          </div>
          <div class="modal-body" v-loading="loading">
            <div class="row mb-2">
              <div class="col col-12 text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  title="Download item"
                  @click="downloadFile"
                >
                  <i class="fas fa-download me-2"></i>Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.custom-modal {
  .modal-dialog.modal-dialog-sm {
    max-width: 400px;
  }
}
</style>
