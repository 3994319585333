<template>
    <div class="documentation">
        <div class="sideBar">
            <leftSideBar />
        </div>
        <div class="mainContent p-3">
            dsjhgahjdgsjha
        </div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
    .documentation{
        display: flex;
        >div{
            flex: 1;
            height: 100%;
        }
        .sideBar{
            flex: 0 0 200px;
        }
    }
</style>