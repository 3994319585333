import logo from "@/components/commons/logo";
import topHeader from "./topHeader";
import intro from "./video";
import overview from "./mapChart";
import newsletter from "./newsletter";
import coinvestmentOp from "./coinvestmentOp";
import seededCompanies from "./seededCompanies";
import replay from "./replay";
import appStore, { APP_ACTIONS } from "../../store/app";
import leftMenu from "./leftMenu";
import dataRoom from "./dataRoom";
import requestForm from "./requestForm";
import event from "./event";
import { defineComponent } from "vue";
import LPFundModal from "./lp-funds-modal";

export default defineComponent({
  data() {
    return {
      coms: null,
      isShowLeftMenu: false,
      isShow: false,
    };
  },
  components: {
    topHeader,
    intro,
    overview,
    newsletter,
    "co-investment-opportunities": coinvestmentOp,
    "seeded-companies": seededCompanies,
    replay,
    leftMenu,
    dataRoom,
    "request-form": requestForm,
    event,
    logo,
    LPFundModal,
  },
  methods: {
    compts(params) {
      this.coms = params;
    },
    hideMenu() {
      this.isShowLeftMenu = false;
    },
    swapmenu() {
      this.isShowLeftMenu = !this.isShowLeftMenu;
    },
  },
  computed: {
    deviceVersion() {
      return appStore.state.mobile.version;
    },
    currentRoute() {
      return appStore.state.currentRoute;
    },
    profile() {
      return appStore.state.profile;
    },
    screenSize() {
      return this.$store.state.mobile.size;
    },
    isShowSelectLpFund() {
      return appStore.state.profile.lpFunds.length !== 1;
    },
    investorInfo() {
      return appStore.state.investorInfo;
    },
  },
  created() {
    if (
      !this.investorInfo &&
      this.profile &&
      this.profile.lpFunds &&
      this.profile.lpFunds.length === 1
    ) {
      const lpFundSelected = this.profile.lpFunds[0];
      const pay = {
        selected_fund: lpFundSelected
      };
      appStore.dispatch(APP_ACTIONS.set_lp_investor_info, pay);
      return;
    }
    if (!this.investorInfo) {
      appStore.dispatch(APP_ACTIONS.set_show_lp_funds_investor_popup, true);
    }
  },
  watch: {
    currentRoute(v) {
      if (!v) return;
      this.isShowLeftMenu = false;
    },
  },
});
