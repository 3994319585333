import AdminServices from "@/services/admin";
import detaiSurvey from "../../listSurvey/detailSurvey";
import router from "@/config/router";
import notify from "../../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["listData", "paging", "loadPage"],
  data() {
    return {
      reviewSurveyDetailPath: router.reviewSurveyDetail.path,
      reviewSurveyDetailName: router.reviewSurveyDetail.name,
      page: {
        page: 1,
        size: 10,
        total: 0,
      },
      surveyStatus: "COMPLETED",
      tableData: [],
      isShowDetailSurvey: false,
      surveyDetailID: null,
    };
  },
  components: {
    detaiSurvey,
  },
  created() {
    if (this.listData) {
      this.tableData = this.listData;
      this.page = this.paging;
    }
  },
  methods: {
    viewSurveyDetail(id) {
      this.isShowDetailSurvey = true;
      this.surveyDetailID = id;
    },
    hideSurveyDetail() {
      this.isShowDetailSurvey = false;
    },
    approvedSurvey(id) {
      AdminServices.approvedSurvey(id, { status: "APPROVED" }).then((resp) => {
        if (resp.data.ok) {
          this.surveyStatus = "COMPLETED";
          this.page.page = 1;
          this.page.size = 10;
          notify.success("Survey Approved");
          setTimeout(() => {
            this.loadPage(1);
          }, 1000);
        }
      });
    },
    rejectSurvey(id) {
      AdminServices.approvedSurvey(id, { status: "REJECTED" }).then((resp) => {
        if (resp.data.ok) {
          this.surveyStatus = "COMPLETED";
          this.page.page = 1;
          this.page.size = 10;
          notify.success("Survey Rejected");
          setTimeout(() => {
            this.loadPage(1);
          }, 1000);
        }
      });
    },
  },
  watch: {
    listData: function (v) {
      this.tableData = v;
    },
    paging: function (v) {
      this.page = v;
    },
  },
});
