import { defineComponent } from "vue";

export default defineComponent({
  name: 'downloadItem',
  props: ["fileToken", "hideDownloadItem"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    downloadFile() {
      this.loading = true;
      window.open(this.fileToken, "_blank");
      this.loading = false;
    },
  },
});
