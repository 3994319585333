<template>
  <div class="fuel-the-mind-page">
    <Section1 />

    <div class="fuel-the-mind-tab">
      <div class="inner-tab-wrapper">
        <div class="container-v2">
          <ul class="tabs">
            <router-link v-for="tab in tabs" :key="tab.id" :to="tab.path">
              <li class="tab-item" :class="isActive(tab.title) ? 'active' : ''">
                <span>{{ tab.title }}</span>
              </li>
            </router-link>
          </ul>
        </div>
      </div>

      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Section1 from "./comps/Section1.vue";
import appStore, { APP_ACTIONS } from "@/store/app";

// export const TABS_PERSPECTIVES_FTM = [
//   {
//     id: 0,
//     title: "Jeff Ransdell",
//     path: "jeff-randsdell",
//   },
//   {
//     id: 1,
//     title: "Maggie Vo",
//     path: "maggie-vo",
//   },
//   {
//     id: 2,
//     title: "Mark Bugas",
//     path: "mark-bugas",
//   },
//   {
//     id: 3,
//     title: "Vlad Cazacu",
//     path: "vlad-cazacu",
//   },
// ];

export default {
  components: {
    Section1,
  },
  data() {
    return {
      loading: true,
      // TABS_PERSPECTIVES_FTM,
      tabs: [
        {
          id: "PortfolioNews",
          title: "Portfolio News",
          path: "/fuel-the-mind/portfolio-news",
        },
        {
          id: "SocialMedia",
          title: "Social Media",
          path: "/fuel-the-mind/social-media",
        },
        {
          id: "Fuel Perspectives",
          title: "Fuel Perspectives",
          // path: `/fuel-the-mind/perspectives/${TABS_PERSPECTIVES_FTM[0].path}`,
          path: `/fuel-the-mind/perspectives`,
        },
      ],
    };
  },
  computed: {
    listPerspectives() {
      return this.$store.state.perspectivesList || [];
    },
  },
  async created() {
    await appStore.dispatch(APP_ACTIONS.fetch_list_perspectives, null);
    this.loading = false;
    this.tabs[2].path = `${this.tabs[2].path}/${
      this.listPerspectives.length === 0 ? "" : this.listPerspectives[0].path
    }`;
  },
  methods: {
    isActive(title) {
      return this.$route.name === title;
    },
  },
};
</script>

<style lang="scss" scoped>
.fuel-the-mind-page {
  overflow-x: hidden;
}
.inner-tab-wrapper {
  background: #000;
  position: sticky;
  top: 74px; // -header
  z-index: 101;
}
ul.tabs {
  color: #fff;
  background: #000;
  display: flex;

  list-style: none;

  li.tab-item {
    padding: 12px;
    height: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
    text-align: center;

    @media (min-width: 768px) {
      padding: 18px 48px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;

      @media (min-width: 768px) {
        font-size: 23px;
        line-height: 29px;
      }
    }
  }

  .active {
    color: #000;
    background: #fff;
  }
}
</style>
