<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideGetLink"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">Get link</h4>
                    </div>
                    <div class="modal-body" v-loading="loading">
                        <div class="row mb-2">
                            <div class="col col-12 d-flex flex-row">
                                <input type="text"
                                    readonly
                                    class="form-control"
                                    autocomplete="off"
                                    ref="docViewerLink"
                                    :value="docViewerLink" />
                                <button type="button"
                                    class="btn btn-default ml-1"
                                    title="Copy link"
                                    @click="copyLink">
                                    <i class="far fa-copy"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
</style>