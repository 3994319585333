<template>
  <div class="adminContent p-3">
    <ul class="nav">
      <li
        v-for="(item, idx) in tabs"
        class="nav-item pointer"
        :key="`tabs_${idx}`"
      >
        <a
          class="nav-link pointer"
          :class="{ active: tab_active === idx }"
          href="javascript:void(0)"
          @click="changeTab(idx)"
          ><span>{{ item.name }}</span></a
        >
      </li>
    </ul>
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
      :hasScrollY="false"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredName"
        />
      </template>
      <template #row_name="{ props }">
        {{ props.name }}
      </template>
      <template #row_eventDateTime="{ props }">
        Start: {{ $filters.parseEstTime(props.startDateTime) }} <br />
        End: {{ $filters.parseEstTime(props.endDateTime) }}<br />
      </template>
      <template #row_milestones="{ props }">
        <div v-if="props.milestones.length == 0">-</div>
        <div v-for="(item, idx) in props.milestones" :key="idx">
          {{ item.name }} <br />
        </div>
      </template>
      <template #row_status="{ props }">
        <div>{{ $filters.getName(props.status, tabs) }}</div>
      </template>

      <template #row_actions="{ props }">
        <Popper>
          <button class="btn btn-primary custom-menu-dropdown" type="button">
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <template #content>
            <div class="content-dropdown-popper">
              <div
                class="customer-el pointer mt-2"
                v-if="
                  props.status != 'CANCELLED' && props.status != 'COMPLETED'
                "
                @click="editEvent(props)"
              >
                <span class="dropdown-item-popper">
                  <i class="fas fa-edit"></i> Edit
                </span>
              </div>
              <div
                class="customer-el pointer"
                v-if="
                  props.status == 'CANCELLED' || props.status == 'COMPLETED'
                "
                @click="editEvent(props)"
              >
                <span class="dropdown-item-popper">
                  <i class="fas fa-comment-alt"></i> View
                </span>
              </div>
              <div
                class="customer-el pointer"
                v-if="
                  props.status != 'CANCELLED' && props.status != 'COMPLETED'
                "
                @click="completeEvent(props)"
              >
                <span class="dropdown-item-popper">
                  <i class="fas fa-check"></i> Complete
                </span>
              </div>
              <div
                class="customer-el pointer"
                v-if="
                  props.status == 'COMPLETED' && props.status != 'CANCELLED'
                "
                @click="cancelEvent(props)"
              >
                <span class="dropdown-item-popper">
                  <i class="fas fa-times-circle"></i> Cancel
                </span>
              </div>

              <div class="customer-el pointer mb-2" @click="deleteEvent(props)">
                <span class="dropdown-item-popper">
                  <i class="far fa-trash-alt"></i> Delete
                </span>
              </div>
            </div>
          </template>
        </Popper>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="addEvent">Create Event</button>
      </template>
    </Table>
    <!-- <Popper /> -->
    <Dialog ref="dialog" />
  </div>
</template>
<!-- #todo check source link import script line 120 -->
<script src="./index.js"></script>

<style lang="scss">
@import "@/assets/styles/variables_dark";

.nav li a {
  font-size: 14px;
  color: #cdcdcd;
  line-height: 40px;
  display: flex;
  align-items: center;
  // #todo check line 132 , why add import?
  padding: 0 15px !important;
  border-bottom: 2px solid transparent;
  height: 100%;
  &.active {
    color: #409eff;
    // border-bottom: 2px solid #409eff !important;
  }
}
.nav-tabs {
  border-bottom: 2px solid rgba(225, 225, 225, 0.2);
  margin-bottom: 1em;
  &:after {
    height: 2px;
  }
}
.content-dropdown-popper {
  display: block;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  white-space: nowrap;
  background: #343a40;
  border: 1px solid #292c30;
  border-radius: 8px;
  margin-top: -10px;
  margin-left: -40px;
  .customer-el {
    padding: 6px 16px;
    text-align: left;
    min-width: 135px;
    &:hover {
      background: $color-background-gray-dark;
      color: #fafafa;
    }
  }
}
</style>
