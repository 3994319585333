<template>
  <div class="scan-qr-code">
    <div class="container">
      <button class="btn btn-success" @click="handleBackEvent">
        <span>BACK</span>
      </button>
      <h4 class="mb-5 text-center text-uppercase">scan qr code</h4>
      <div class="row mb-3">
        <!-- scan begin -->
        <div class="col-12 col-md-6" v-if="isShowScanQrcode">
          <QrcodeStream @decode="onDecode"></QrcodeStream>
        </div>
        <!-- scan done -->
        <div class="col-12 col-md-6" v-if="!isShowScanQrcode">
          <div class="wrap">
            <div class="result mb-3">
              <div class="title-evt">ATTENDEE INFO</div>
              <template v-if="status === 'success'">
                <div>
                  <div class="group mb-4 justify-content-center">
                    <img :src="checkOk" alt="" width="90" />
                  </div>
                  <div class="group mb-3">
                    <div class="text">Name:</div>
                    <span class="sub_text">{{ qrscanResult.name }}</span>
                  </div>
                  <div class="group mb-3">
                    <div class="text">Email:</div>
                    <span class="sub_text">{{ qrscanResult.email }}</span>
                  </div>
                  <div class="group mb-3">
                    <div class="text">User Type:</div>
                    <span class="sub_text">{{ qrscanResult.userType }}</span>
                  </div>
                  <div class="group mb-3">
                    <div class="text">Milestone:</div>
                    <span class="sub_text">{{ qrscanResult.milestone }}</span>
                  </div>
                  <div class="group">
                    <div class="text">Check-in At:</div>
                    <span class="sub_text">{{
                      $filters.parseEstTime(qrscanResult.checkInAt)
                    }}</span>
                  </div>
                </div>
              </template>

              <template v-if="status === 'error'">
                <div class="group mb-3 justify-content-center">
                  <img :src="caution" alt="" />
                </div>
                <div class="group mb-2 justify-content-center">
                  <h5 class="text">{{ messageError }}</h5>
                </div>
              </template>
            </div>
            <div class="text-center">
              <button class="btn btn-primary next-scan" @click="nextScan">
                Next Scan
              </button>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="col-12 col-md-6 mb-custom">
          <div class="wrap">
            <div class="result">
              <div class="title-evt">EVENT INFO</div>
              <div class="scroll-result">
                <div class="group mb-3">
                  <div class="text">Event name:</div>
                  <span class="sub_text">{{ form.name }} </span>
                </div>
                <div class="group mb-3">
                  <div class="text">Location:</div>
                  <span class="sub_text"> {{ form.location }}</span>
                </div>
                <div class="group mb-3">
                  <div class="text">Description:</div>
                  <span class="sub_text"> {{ form.description }}</span>
                </div>
                <div class="group mb-3">
                  <div class="text">Start:</div>
                  <span class="sub_text">
                    {{ $filters.parseEstTime(form.startDateTime) }}
                    <a>(EST)</a>
                  </span>
                </div>
                <div class="group mb-3">
                  <div class="text">End:</div>
                  <span class="sub_text">
                    {{ $filters.parseEstTime(form.endDateTime) }}
                    <a>(EST)</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 info-detail-event">
          <Table
            :paging="paging"
            @updatePaging="updatePaging"
            :columns="columns"
            :rows="rows"
            :total-items="totalItems"
            :showNoRow="true"
            v-loading="loading"
            :useSimpleLayout="true"
          >
            <template #head_email>
              <TextField
                v-model:value="filteredEmail"
                placeholder="Type to search .."
                hideError
              />
            </template>
            <template #row_checkInDate="{ props }">
              <div v-if="props.checkInDate != null">
                {{ $filters.parseEstTime(props.checkInDate) }}
              </div>
              <div v-else>-</div>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.scan-qr-code {
  padding: 0.5rem 1rem 1rem 1rem;
  background: #3a3e47;
  background-color: #292c33;
  color: white;
  min-height: 100vh;
  .container {
    max-width: 1024px;
  }

  .wrap {
    border-radius: 5px;
    background: #3a3e47;
    border-radius: 5px;
    background-color: #292c33;
    border: 1px solid rgba(213, 213, 213, 0.1);
    padding: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .info-detail-event {
    margin-top: 12px;
    .data {
      padding: 0;
    }
  }
  .result {
    flex: 1;

    .group {
      display: flex;
      .text {
        font-weight: 300;
        color: #a7a7a7;
        min-width: 100px;
        display: flex;
        align-items: center;
      }
      .sub_text {
        line-height: 1.5;
      }
    }
  }
  .title-evt {
    font-weight: 700;
    font-size: 16px;
    color: #f1f1f1;
    margin-bottom: 40px;
  }
  .scroll-result {
    max-height: 300px;
    overflow: auto;
  }
  @media (max-width: 767px) {
    .mb-custom {
      margin-top: 25px;
    }
    .scroll-result {
      max-height: 100%;
      overflow: hidden;
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #393c45;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 4px;
  transition: all 300ms;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #909090;
  border-radius: 4px;
  transition: all 300ms;
}
</style>
<style lang="scss">
.info-detail-event {
  table.table {
    th,
    td {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
}
</style>
