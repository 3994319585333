<template>
  <div class="signNDA">
    <div class="container" v-loading="formLoading">
      <div class="ndaform py-3" v-if="status !== 'signed'">
        <template v-if="!isAcceptTerm">
          <div class="page mb-3">
            <div class="wrap">
              <pdf
                ref="myPdfComponent"
                class="pdf"
                v-if="url"
                :src="url"
                :scale.sync="scale"
              ></pdf>
            </div>
          </div>
          <div class="page">
            <div class="wrap cta w-100 text-center">
              <button class="btn btn-secondary mx-2" @click="rejectTerm">
                Reject
              </button>
              <button class="btn btn-primary mx-2" @click="acceptTerm">
                Agree
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="page mb-2">
            <div class="wrap">
              <template v-if="!isNoticeSign">
                <div
                  class="d-flex justify-content-center flex-column"
                  style="height: calc(100vh - 100px)"
                >
                  <div class="form mb-3">
                    <div class="form-group">
                      <label for="firstname"
                        ><span class="colorRed">*</span> Sign here</label
                      >
                      <input
                        type="text"
                        id="firstname"
                        class="form-control"
                        v-model="formData.signature"
                      />
                      <small class="colorRed" v-if="errorMsg.signature">{{
                        errorMsg.signature
                      }}</small>
                    </div>
                    <div class="form-group">
                      <label for="company">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        class="form-control"
                        v-model="formData.company"
                      />
                    </div>

                    <div class="form-group">
                      <label for="title">Title</label>
                      <input
                        type="text"
                        id="title"
                        class="form-control"
                        v-model="formData.title"
                      />
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-secondary pointer me-2"
                      @click="goback"
                    >
                      Go back
                    </button>
                    <button
                      class="btn btn-primary pointer"
                      @click="submit"
                      :disabled="formLoading"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="d-flex text-center justify-content-center flex-column"
                  style="height: calc(100vh - 100px)"
                >
                  <h2 class="mb-5">You must sign NDA form to proceed</h2>
                  <p class="w-25 mx-auto">
                    <button
                      class="btn btn-primary text-uppercase w-100"
                      @click="sign"
                    >
                      Sign
                    </button>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <div
          class="signed d-flex text-center justify-content-center flex-column"
          style="height: calc(100vh - 100px)"
        >
          <h2 class="text-center mb-3">You have signed the NDA form</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.pdf {
  height: calc(100vh - 200px);
}
.signNDA {
  font-family: "Raleway";
  display: block;
  overflow: hidden;
  width: 100%;
  background: url("../../../assets/images/signNDA/wallpaper.png") no-repeat
    center center #000;
  overflow-y: auto;
  .container {
    width: 100% !important;
    max-width: 960px !important;
    height: 100vh;
    .signed {
      h2 {
        color: #fff;
      }
    }
  }
  .submitted {
    color: #fff;
    font-weight: 300;
    .signed {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
    }
    h2 {
      font-size: 40px;
      font-weight: 300;
    }
    h3 {
      font-size: 32px;
      font-weight: 300;
    }
  }
  .ndaform {
    color: #fff;
    font-weight: 300;
    .page {
      display: block;
      padding-top: 20px;
      .wrap {
        position: relative;
        display: block;
      }
    }
    h2 {
      font-size: 40px;
      font-weight: 300;
    }
    .form {
      form {
        input {
          background-color: rgba(255, 255, 255, 0.15) !important;
          color: #fff;
          font-weight: 300;
        }
      }
    }
  }
}
</style>
