<template>
  <div class="qr-code-container">
    <canvas :style="style"></canvas>
  </div>
</template>
<script src="./index.js"></script>
<style scoped>
/* .qr-code-container {
  max-width: 100%;
}
.qr-code-container canvas {
  max-width: 100%;
} */
</style>
