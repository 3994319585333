import DealAddUpdateModal from "@/components/deal/DealAddUpdateModal";
import { DEAL_SUBMISSIONS } from "@/misc/constants";
import adminServices from "@/services/admin";
import filePreview, { parseFileType } from "@/components/commons/filePreview";
import { defineComponent } from "vue";
import EditIcon from "@/assets/images/edit-2.svg";

export default defineComponent({
  props: ["dataDeal", "currentStage", "totalStage"],
  components: {
    DealAddUpdateModal,
    filePreview,
    EditIcon,
  },
  emits: ["reloadDeal"],
  data() {
    return {
      formData: {
        name: null,
      },
      loading: false,
      selectedTab: "PITCH_DECK",
      dealAddUpdateModalKey: 0,
      fileToken: null,
      previewFileType: "",
    };
  },
  computed: {
    answers() {
      if (this.dataDeal && this.dataDeal.submission_answers) {
        let group = _.cloneDeep(DEAL_SUBMISSIONS);
        group.forEach((item) => {
          item.data = this.dataDeal.submission_answers.filter(
            (x) => x.segment === item.segment_id
          );
        });
        return group;
      } else {
        return [];
      }
    },
  },
  methods: {
    doActiveTab(id) {
      this.selectedTab = id;
    },
    refreshOnDealUpdate() {
      this.$emit("reloadDeal", this.dataDeal.deal_id, true);
    },
    async updateDeal() {
      this.dealAddUpdateModalKey++;
      this.$nextTick(() => {
        this.$refs.dealAddUpdateModal.showUpdate(this.dataDeal);
      });
    },
    formatLink(text) {
      return text.indexOf("http://") >= 0 || text.indexOf("https://") >= 0
        ? text
        : `https://${text}`;
    },
    async viewFile(item) {
      const fileType = parseFileType(item.extension);
      this.loading = true;
      const res = await adminServices.requestDownloadS3(item.s3key);
      if (res.data.ok) {
        this.showFilePreview(res.data.d, fileType);
      }
      this.loading = false;
    },
    showFilePreview(token, previewFileType) {
      this.fixedBody();
      this.fileToken = token;
      this.previewFileType = previewFileType;
      this.$refs.filePreview.showModal();
    },
  },
});
