<template>
  <div :class="{ 'mb-3': currentStage !== totalStage }">
    <div class="top">
      <div class="row px-3">
        <div class="col-7">
          <span class="stage d-inline-block me-2">Stage</span>
          <h4 class="d-inline-block mb-0">{{ item.stage }}</h4>
        </div>
        <div class="col-5 text-end">
          <a href="javascript:void(0)" class="reviewer" @click="showModalReviewRate(item)" v-if="item.rating && item.rating.length > 0">Reviewers rating</a>
        </div>
      </div>
    </div>
    <div class="content col-12">
      <div
        class="reviewer"
        v-for="(item, index) in item.reviewers"
        :key="`item_${index}`"
      >
        <div class="col-12 reviewer-wrapper">
          <div class="row mb-2">
            <div class="col-8">
              <span class="nickname">{{ item.created_by }} | </span
              ><span class="email">{{ item.created_user_email }}</span>
            </div>
            <div class="col-4 text-end">
              <span class="date">{{
                $filters.parseEstTime(item.created_at)
              }}</span>
            </div>
            <div class="col-12 line"></div>
          </div>
          <div class="row hasNoti">
            <div class="col-12 pb-2">
              <span class="comment">{{ item.message }}</span>
            </div>
            <div
              class="col-12 pt-2 usersNotify"
              v-if="item.notify && item.notify[0] && item.notify[0].user_name"
            >
              <span v-for="(noti, idx) in item.notify" :key="`noti_${idx}`">
                <span
                  v-if="
                    noti.user_name && (noti.notify_sms || noti.notify_email)
                  "
                >
                  {{ noti.user_name }}
                  (
                  <template v-if="noti.notify_sms">sms</template>
                  <template v-if="noti.notify_sms && noti.notify_email"
                    >,
                  </template>
                  <template v-if="noti.notify_email">email</template>
                  )
                </span>
                {{ idx !== item.notify.length - 1 ? "|" : "" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reviewRate ref="showReviewRate" :currentStage="currentStage" :dataReviewerRate="item.rating" />
  </div>
</template>
<style scoped lang="scss">
.top {
  background-color: rgba(#0095ff, 0.2);
  height: 46px;
  padding: 10px 0;
  span.stage {
    display: inline-block;
    border-radius: 6px;
    background-color: #0095ff;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 26px;
    padding: 0 15px;
    vertical-align: middle;
  }
  h4 {
    vertical-align: middle;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
  }
  a.reviewer {
    font-size: 14px;
    color: #0095ff;
    text-decoration: underline !important;
    &:hover {
      color: #fff;
    }
  }
}
.content {
  .reviewer {
    padding-top: 24px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 30px;
      top: 0;
      display: block;
      overflow: hidden;
      width: 4px;
      height: 24px;
      z-index: 1;
      background-color: #373b43;
    }
    > div {
      border-radius: 10px;
      border: 1px solid rgba(#d5d5d5, 0.1);
      background-color: rgba(#000, 0.1);
      padding: 20px;
      z-index: 2;
    }
    .line {
      border-bottom: 1px solid rgba(#707070, 0.2);
    }
    .hasNoti {
      > div {
        border-bottom: 1px solid rgba(#707070, 0.2);
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
    .nickname {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      line-height: 40px;
    }
    .email {
      color: rgba(#f5f5f5, 0.4);
      line-height: 40px;
    }
    .date {
      color: #f5f5f5;
      font-size: 14px;
      line-height: 40px;
    }
    .comment {
      font-size: 14px;
      color: #fff;
    }
    .usersNotify {
      font-size: 14px;
      color: #fff;
      span {
        font-style: italic;
        color: rgba(#fff, 0.5);
      }
    }
    .reviewer-wrapper {
      padding: 0px 15px 10px 15px;
    }
  }
}
</style>
<script src="./detailStage.js"></script>
