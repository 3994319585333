export const LP_MEDIA_TYPES = {
  INVESTOR_CALL_REPLAY: "INVESTOR_CALL_REPLAY",
  CO_INVESTMENT_OP: "CO_INVESTMENT_OP",
  NEWSLETTER: "NEWSLETTER",
  INTRODUCTION_VIDEO: "INTRODUCTION_VIDEO",
  SEEDED_COMPANIES: "SEEDED_COMPANIES"
};

export const PORTFOLIO_ICONS = [
  {
    code: null,
    name: "None",
  },
  {
    code: "unicorn",
    name: "Unicorn",
  },
  {
    code: "soonicorn",
    name: "Soonicorn",
  },
];

export const DEAL_STAGES = [
  {
    id: "STAGE_1",
    order: 0,
    name: "Pre-Filtered",
  },
  {
    id: "STAGE_2",
    order: 1,
    name: "First Pass Checklist (FPC)",
  },
  {
    id: "STAGE_3",
    order: 2,
    name: "First Pass Analysis (FPA)",
  },
  {
    id: "STAGE_4",
    order: 3,
    name: "In Depth Due Diligence",
  },
  {
    id: "STAGE_5",
    order: 4,
    name: "Investment Committee Meeting",
  },
  {
    id: "STAGE_6",
    order: 5,
    name: "Negotiation",
  },
  {
    id: "STAGE_7",
    order: 6,
    name: "Closing",
  },
];

export const DEAL_SUBMISSIONS = [
  {
    order: 1,
    segment_id: "PITCH_DECK",
    text: "Pitch Deck",
  },
  {
    order: 2,
    segment_id: "YOUR_CONTACTS_DETAILS",
    text: "Your Contacts Details",
  },
  {
    order: 3,
    segment_id: "COMPANY_OVERVIEW",
    text: "Company Overview",
  },
  {
    order: 4,
    segment_id: "BUSINESS_OVERVIEW",
    text: "Business Overview",
  },
  {
    order: 5,
    segment_id: "OTHER",
    text: "Other",
  },
];

export const DEAL_REVIEW_QUESTIONS = [
  {
    order: 1,
    code: "QUALITATIVE",
    max_score: 5,
  },
  {
    order: 2,
    code: "QUANTITATIVE",
    max_score: 5,
  },
];

export const EVENT_USER_TYPES = {
  INVESTOR: "INVESTOR",
  FOUNDER: "FOUNDER",
  MEMBER: "ROKKER",
};

export const EVENT_STATUS = {
  NEW: "NEW",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};
