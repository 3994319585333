<template>
  <div v-if="members.length" class="popupTeam">
    <div class="swiper-wrapper flex-column">
      <div class="slide-control">
        <div class="control-container container d-flex py-2 gap-3">
          <div class="justify-content-center arrowLeft">
            <span class="pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 46.899 46.899"
              >
                <g id="c-remove" transform="translate(-1 -1)">
                  <g
                    id="Group_36"
                    data-name="Group 36"
                    transform="translate(2 2)"
                  >
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="22.449"
                      cy="22.449"
                      r="22.449"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></circle>
                    <line
                      id="Line_3"
                      data-name="Line 3"
                      x1="8.354"
                      y2="8.022"
                      transform="translate(25.731 23.399) rotate(180)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></line>
                    <line
                      id="Line_4"
                      data-name="Line 4"
                      x1="8.354"
                      y1="8.123"
                      transform="translate(25.731 31.522) rotate(180)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></line>
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div class="justify-content-center arrowRight">
            <span class="pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 46.899 46.899"
              >
                <g id="c-remove" transform="translate(-1 -1)">
                  <g
                    id="Group_36"
                    data-name="Group 36"
                    transform="translate(2 2)"
                  >
                    <g id="Group_40" data-name="Group 40">
                      <line
                        id="Line_1"
                        data-name="Line 1"
                        x1="8.354"
                        y2="8.022"
                        transform="translate(18.377 22.5)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      ></line>
                      <line
                        id="Line_2"
                        data-name="Line 2"
                        x1="8.354"
                        y1="8.123"
                        transform="translate(18.377 14.377)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      ></line>
                    </g>
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="22.449"
                      cy="22.449"
                      r="22.449"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></circle>
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div class="col"></div>
          <div class="justify-content-center removePopup">
            <span class="pointer" @click="hideSlide">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 46.899 46.899"
              >
                <g id="c-remove" transform="translate(-1 -1)">
                  <g
                    id="Group_36"
                    data-name="Group 36"
                    transform="translate(2 2)"
                  >
                    <g id="Group_42" data-name="Group 42">
                      <line
                        id="Line_1"
                        data-name="Line 1"
                        x1="16.145"
                        y2="16.145"
                        transform="translate(14.377 14.377)"
                        fill="none"
                        stroke="#ffffff"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      ></line>
                      <line
                        id="Line_2"
                        data-name="Line 2"
                        x1="16.145"
                        y1="16.145"
                        transform="translate(14.377 14.377)"
                        fill="none"
                        stroke="#ffffff"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      ></line>
                    </g>
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="22.449"
                      cy="22.449"
                      r="22.449"
                      fill="none"
                      stroke="#ffffff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></circle>
                  </g>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div class="our-team-swiper swiper-container mt-1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in members"
            :key="index"
          >
            <div class="infomationTeam">
              <div
                class="container content-scroll"
                :style="`height: ${windowHeight ? windowHeight : ''}px`"
              >
                <div class="row information-wrapper">
                  <div
                    class="d-flex flex-column align-items-start px-3 profileCover col col-md-3"
                  >
                    <div class="profile-photo">
                      <img class="avatarMember" :src="item.photo" alt="" />
                    </div>
                    <div class="boxInfo pt-3">
                      <span class="name">{{ item.name }}</span>
                      <br />
                      <span class="position">{{ item.title }}</span>
                      <br />
                      <a
                        class="emailInfoTeam"
                        :href="`mailto:${item.email}`"
                        target="_top"
                        >{{ item.email }}</a
                      >
                      <div class="d-flex social-container">
                        <a
                          v-if="item.ext && item.ext.Facebook"
                          :href="`${item.ext.Facebook}`"
                          class="social"
                          target="_blank"
                        >
                          <svg
                            class="iconSocial"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.92"
                            height="19.92"
                            viewBox="0 0 48 48"
                          >
                            <g class="nc-icon-wrapper" fill="#000000">
                              <path
                                fill="#000000"
                                d="M46,0H2C0.89543,0,0,0.89543,0,2v44c0,1.10457,0.89543,2,2,2h23.63829V29.4119h-6.25529v-7.24417h6.25529 v-5.34235c0-6.19977,3.78661-9.57566,9.31723-9.57566c2.64922,0,4.92609,0.19724,5.58963,0.2854v6.47912l-3.83577,0.00174 c-3.00786,0-3.59026,1.42929-3.59026,3.52668v4.62507h7.17347l-0.93401,7.24417h-6.23946V48H46c1.10457,0,2-0.89543,2-2V2 C48,0.89543,47.10457,0,46,0z"
                              />
                            </g>
                          </svg>
                        </a>
                        <a
                          v-if="item.ext && item.ext.Twitter"
                          :href="`${item.ext.Twitter}`"
                          class="social"
                          target="_blank"
                        >
                          <svg
                            class="iconSocial"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.267"
                            height="19.709"
                            viewBox="0 0 24.267 19.709"
                          >
                            <g id="twitter" transform="translate(0 -48.082)">
                              <path
                                id="Path_14324"
                                data-name="Path 14324"
                                d="M21.772,52.994c.015.216.015.431.015.647A14.054,14.054,0,0,1,7.637,67.791,14.055,14.055,0,0,1,0,65.558a10.288,10.288,0,0,0,1.2.062A9.96,9.96,0,0,0,7.376,63.5a4.982,4.982,0,0,1-4.65-3.449,6.272,6.272,0,0,0,.939.077,5.26,5.26,0,0,0,1.309-.169A4.974,4.974,0,0,1,.985,55.073v-.062a5.009,5.009,0,0,0,2.248.631,4.981,4.981,0,0,1-1.54-6.652,14.137,14.137,0,0,0,10.255,5.2,5.615,5.615,0,0,1-.123-1.139,4.978,4.978,0,0,1,8.607-3.4,9.792,9.792,0,0,0,3.157-1.2A4.96,4.96,0,0,1,21.4,51.192a9.97,9.97,0,0,0,2.864-.77,10.691,10.691,0,0,1-2.494,2.571Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </a>
                        <a
                          v-if="item.ext && item.ext.Instagram"
                          :href="`${item.ext.Instagram}`"
                          class="social"
                          target="_blank"
                        >
                          <svg
                            class="iconSocial"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.633"
                            height="19.628"
                            viewBox="0 0 19.633 19.628"
                          >
                            <g
                              id="instagram"
                              transform="translate(0.075 -31.825)"
                              opacity="0.999"
                            >
                              <path
                                id="Path_14325"
                                data-name="Path 14325"
                                d="M9.744,36.607a5.032,5.032,0,1,0,5.032,5.032A5.025,5.025,0,0,0,9.744,36.607Zm0,8.3a3.272,3.272,0,1,1,3.272-3.272,3.278,3.278,0,0,1-3.272,3.272Zm6.412-8.51a1.174,1.174,0,1,1-1.174-1.174A1.171,1.171,0,0,1,16.156,36.4Zm3.333,1.191A5.809,5.809,0,0,0,17.9,33.479a5.847,5.847,0,0,0-4.113-1.586c-1.621-.092-6.478-.092-8.1,0A5.839,5.839,0,0,0,1.58,33.475,5.828,5.828,0,0,0-.006,37.588c-.092,1.621-.092,6.478,0,8.1A5.809,5.809,0,0,0,1.58,49.8a5.855,5.855,0,0,0,4.113,1.586c1.621.092,6.478.092,8.1,0A5.809,5.809,0,0,0,17.9,49.8a5.847,5.847,0,0,0,1.586-4.113C19.581,44.066,19.581,39.213,19.489,37.592ZM17.4,47.425a3.312,3.312,0,0,1-1.866,1.866c-1.292.512-4.358.394-5.786.394s-4.5.114-5.786-.394a3.312,3.312,0,0,1-1.866-1.866C1.58,46.133,1.7,43.067,1.7,41.639s-.114-4.5.394-5.786a3.312,3.312,0,0,1,1.866-1.866c1.292-.512,4.358-.394,5.786-.394s4.5-.114,5.786.394A3.312,3.312,0,0,1,17.4,35.853c.512,1.292.394,4.358.394,5.786S17.908,46.137,17.4,47.425Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </a>
                        <a
                          v-if="item.ext && item.ext.Linkedin"
                          :href="`${item.ext.Linkedin}`"
                          class="social"
                          target="_blank"
                        >
                          <svg
                            class="iconSocial"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.92"
                            height="19.92"
                            viewBox="0 0 19.92 19.92"
                          >
                            <g
                              id="linkedin"
                              transform="translate(0 -32)"
                              opacity="0.999"
                            >
                              <path
                                id="Path_14326"
                                data-name="Path 14326"
                                d="M18.5,32H1.418A1.429,1.429,0,0,0,0,33.436V50.484A1.429,1.429,0,0,0,1.418,51.92H18.5a1.433,1.433,0,0,0,1.423-1.436V33.436A1.433,1.433,0,0,0,18.5,32ZM6.021,49.074H3.068V39.568H6.025v9.507ZM4.544,38.27a1.712,1.712,0,1,1,1.712-1.712A1.713,1.713,0,0,1,4.544,38.27Zm12.543,10.8H14.135V44.45c0-1.1-.022-2.521-1.534-2.521-1.538,0-1.774,1.2-1.774,2.441v4.7H7.875V39.568h2.832v1.3h.04a3.11,3.11,0,0,1,2.8-1.534c2.988,0,3.544,1.97,3.544,4.531Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </a>
                        <a
                          v-if="item.ext && item.ext.Medium"
                          :href="`${item.ext.Medium}`"
                          class="social"
                          target="_blank"
                        >
                          <svg
                            class="iconSocial"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.92"
                            height="19.92"
                            viewBox="0 0 48 48"
                          >
                            <g class="nc-icon-wrapper" fill="#000000">
                              <path
                                d="M46,0H2A2,2,0,0,0,0,2V46a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V2A2,2,0,0,0,46,0ZM39.877,11.373,37.3,13.841a.752.752,0,0,0-.286.722V32.7a.753.753,0,0,0,.286.722l2.514,2.468v.542H27.171v-.542l2.6-2.528c.256-.256.256-.331.256-.722V17.979L22.79,36.371h-.979l-8.43-18.391V30.305a1.7,1.7,0,0,0,.467,1.415l3.387,4.109v.542h-9.6v-.542l3.387-4.109a1.64,1.64,0,0,0,.437-1.415V16.053A1.249,1.249,0,0,0,11.048,15L8.037,11.373v-.542h9.349l7.226,15.848,6.353-15.848h8.912Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col px-3 profileInfo">
                    <div class="contentDesTeam">
                      <!-- <VuePerfectScrollbar
                      class="scroll-area"
                      v-once
                      :settings="settings"
                    >
                     
                    </VuePerfectScrollbar> -->

                      <div class="shortDesTeam" v-html="item.intro"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss">
.modal-custom-v2 {
  .modal-content {
    .popupTeam {
      display: block;
      width: 100%;
      overflow: hidden;
    }
    .our-team-swiper {
      width: 100%;
    }
  }
}
.popupTeam .swiper-container .slide-control {
  height: 60px;
  .container {
    padding-top: 10px;
  }
  .control-container {
    margin: auto 0;
  }
  .arrowLeft {
    margin-right: 20px;
  }
}
.infomationTeam .container {
  width: auto;
}

.avatarMember {
  min-width: 280px;
  object-fit: cover;
}

.contentDesTeam {
  width: 100%;
  position: relative;
  > section {
    @media screen and (min-width: 768px) {
      padding-right: 15px;
    }
  }
  .shortDesTeam {
    font-size: 16px;
    height: calc(100vh - 70px);
  }
}
.social-container {
  padding-top: 8px;
}
.shortDesTeam::-webkit-scrollbar {
  width: 0.3em;
}
.shortDesTeam::-webkit-scrollbar,
.shortDesTeam::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
.shortDesTeam::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}

.cover-bar {
  position: absolute;
  background: transparent;
  height: 100%;
  top: 0;
  right: 0;
  width: 0.3em;
  -webkit-transition: all 0.5s;
  opacity: 1;
}

.contentDesTeam:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all 0.5s;
}

@media (min-width: 1196px) and (max-width: 1367px) {
  .paddingCol {
    flex: 0 0 30.666667%;
    max-width: 30.666667%;
    margin-left: 8%;
  }
}
.removePopup {
  svg {
    circle {
      transition: all ease 0.2s;
    }
    line {
      transition: all ease 0.2s;
    }
    &:hover {
      circle {
        stroke: #3e99ed !important;
      }
      line {
        stroke: #3e99ed !important;
      }
    }
  }
}
.arrowRight,
.arrowLeft {
  outline: none;
  svg {
    circle {
      transition: all ease 0.2s;
    }
    line {
      transition: all ease 0.2s;
    }
    &:hover {
      circle {
        stroke: #3e99ed !important;
      }
      line {
        stroke: #3e99ed !important;
      }
    }
  }
}
.imgEmployers {
  transition: all 0.3s ease;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  &:hover {
    .imgEmployers {
      -webkit-filter: none;
      filter: none;
    }
  }
}

.infomationTeam {
  height: calc(100% - 90px);
  color: #fff;
}
.boxInfo {
  .name {
    font-size: 2rem;
  }
}
.v--modal-overlay .v--modal-box {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
}
.profile-photo {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  .avatarMember {
    width: 100%;
    min-width: unset;
  }
}
@media (max-width: 991px) {
  .popupTeam {
    .slide-control {
      .container {
        max-width: 100%;
      }
    }
  }
  .infomationTeam {
    .container {
      max-width: 100%;
    }
  }
}
@media (max-width: 767.98px) {
  .contentDesTeam {
    .shortDesTeam {
      margin-bottom: 40px;
      height: auto;
    }
  }
  .infomationTeam {
    .content-scroll {
      overflow: hidden;
      overflow-y: scroll;
    }
    .container {
      .row {
        display: block;
      }
    }
    .profileCover {
      margin-bottom: 20px;
    }
    .profileInfo {
      width: calc(100% - 30px);
      padding: 0 1rem !important;
      max-width: 100%;
      flex-grow: unset;
    }
  }
}
@media screen and (min-width: 768px) and (max-height: 610px) {
  .profileCover {
    position: relative;
    .boxInfo {
      position: absolute;
      left: 50%;
      bottom: 0;
      display: block;
      overflow: hidden;
      width: 260px;
      background-color: rgba(255, 255, 255, 0.6);
      color: #000;
      padding: 10px;
      transform: translateX(-50%);
    }
  }
  .profile-photo {
    min-width: 280px;
  }
  .social .iconSocial g path {
    fill: #000;
  }
}
</style>

<style lang="scss">
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.information-wrapper {
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
}
</style>
