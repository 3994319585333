import { defineComponent } from "vue";
// import OurteamPartial from "./listOurTeam";
import OurTeamWithFund from "./listOurTeamWithFund";
import TeamService from "@/services/team";

export default defineComponent({
  components: {
    // OurteamPartial,
    OurTeamWithFund,
  },
  data() {
    return {
      // members: [],
      fundMembers: [],
      bannerInfo: {
        image: require("../../../../assets/images/banner/banner-ourteam-v2.jpg"),
        name: "Our Team",
        shortDescription: "",
      },
    };
  },
  created() {
    this.getListMember();
  },
  methods: {
    getListMember() {
      TeamService.getListMember().then((resp) => {
        if (resp.data.ok) {
          // this.members = resp.data.d
          this.fundMembers = resp.data.d;
        }
      });
    },
  },
});
