import { defineComponent } from 'vue'
import NewsService from '../../services/news/news'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import SimpleImage from '@editorjs/simple-image'
import Embed from '@editorjs/embed'

export default defineComponent({
    data() {
        return {
            newId: undefined,
            post: undefined
        }
    },
    created() {
        this.newId = this.$route && this.$route.params && this.$route.params.id ? this.$route.params.id : undefined

        this.getNewsById()
    },
    methods: {
        async getNewsById() {
            if (!this.newId) return

            const result = await NewsService.getById(this.newId)

            if (result.data.ok) {
                this.post = result.data.d.data
                if (this.post && this.post.content) {
                    new EditorJS({
                        holder: 'editorjs',
                        readOnly: true,
                        data: this.post.content,
                        tools: {
                            header: {
                                class: Header,
                                inlineToolbar: ['link']
                            },
                            list: {
                                class: List,
                                inlineToolbar: true,
                                config: {
                                defaultStyle: 'unordered'
                                }
                            },
                            image: SimpleImage,
                            embed: Embed
                        },
                    })
                    return
                }
            }
            this.$router.push('/')
        }
    }
})
