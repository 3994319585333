<template>
  <div class="h-100">
    <div class="adminContent container-fluid im-bg h-100">
      <div class="row h-100">
        <div
          class="col-10 offset-1 col-md-6 offset-md-3 h-100 d-flex align-items-center justify-content-center"
        >
          <div class="text-center">
            <img class="image" src="../../assets/images/logo_FVC.png" alt="" />
            <template v-if="error">
              <div class="text">
                <h1>{{ error }}</h1>
                <!-- <h1 v-if="a != ''">You will back to page home in {{ countDown }} seconds.</h1> -->
              </div>
            </template>
            <template v-else>
              <div class="text">
                <h1 v-if="a == ''">Updating your response to event...</h1>
                <h1>
                  Thank you for registering for the event. Please check your
                  Fuel Venture Capital App or your email for the QR code that
                  you will use to check in to the event.
                </h1>
                <!-- <h1 v-if="a == 'n'">You've replied “No” to this event.</h1>
                                <h1 v-if="a == 'm'">You've replied “Maybe” to this event.</h1> -->
                <!-- <h1 v-if="a != ''">You will back to page home in {{ countDown }} seconds.</h1> -->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.adminContent {
  background: #000;
}

.image {
  width: 65%;
  padding-bottom: 70px;
}

.text {
  size: 26px;
  color: white;
}
</style>

<script>
import { router } from "@/config/router";
import adminServices from "@/services/admin";

export default {
  data() {
    return {
      loginPath: router.login.path,
      homePath: router.home.path,
      form: {
        eventId: "",
        confirmationCode: "",
      },
      routeQuery: null,
      countDown: 7,
      a: "",
      error: "",
    };
  },
  mounted() {
    this.routeQuery = this.$route.query;
    if (
      this.routeQuery &&
      this.routeQuery.id &&
      this.routeQuery.invitationCode
    ) {
      this.form.eventId = this.routeQuery.id;
      this.form.confirmationCode = this.routeQuery.invitationCode;
      this.a = this.routeQuery.a;
      this.checkResponse();
    }
  },
  created() {
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      // if (this.countDown > 0) {
      //     setTimeout(() => {
      //         this.countDown -= 1
      //         this.countDownTimer()
      //         if (this.countDown == 1) {
      //             this.$router.push('/')
      //         }
      //     }, 1000)
      // }
    },
    async checkResponse() {
      this.loading = true;
      const res = await adminServices.attendeeResponse({
        eventId: this.form.eventId,
        confirmationCode: this.form.confirmationCode,
      });
      if (res && res.data && res.data.ok) {
        //notthing
      } else {
        this.error = res.data.m;
      }
      this.loading = false;
    },
  },
};
</script>
