import AdminServices from "@/services/admin";
import _ from "lodash";
import router from "@/config/router";
import moment from "moment";
import notify from "../../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["userId"],
  data() {
    return {
      loading: false,
      textMonth: new Date().getMonth() + 1,
      textYear: new Date().getFullYear(),
      partnerPath: router.partner.path,
      partnerRouteName: router.partner.name,
      data: {},
      error: {
        cost: false,
        ownership: false,
      },
      startYear: 2017,
      currentYear: moment().year(),
      rangeYear: null,
    };
  },
  mounted() {
    if (!this.userId) {
      this.$router.push(this.partnerPath);
    }
    if (!this.rangeYear) {
      this.rangeYear = [];
      for (let i = this.currentYear; i >= this.startYear; i--) {
        this.rangeYear.push(i);
      }
    }
  },
  created() {
    this.getOwnershipInfo();
  },
  methods: {
    getOwnershipInfo: function () {
      this.resetValidation();
      this.loading = true;
      AdminServices.getPartnerOwnershipInfo(
        this.userId,
        parseInt(this.textYear),
        parseInt(this.textMonth)
      ).then((res) => {
        this.data = res.data.d;
        this.loading = false;
      });
    },
    changeMonth: function (str) {
      if (this.textMonth != str) {
        this.textMonth = str;
        this.getOwnershipInfo();
      }
    },
    changeYear: function (str) {
      if (this.textYear != str) {
        this.textYear = str;
        this.getOwnershipInfo();
      }
    },
    saveOwnership: function () {
      this.error.cost = true;
      if (this.validateForm()) {
        AdminServices.saveOwnership(
          this.userId,
          parseInt(this.textYear),
          parseInt(this.textMonth),
          parseFloat(this.data.cost),
          parseFloat(this.data.ownership)
        ).then((resp) => {
          if (resp.data.ok) {
            this.getOwnershipInfo();
            notify.success("Submitted succesfully.");
          } else {
            notify.error("Submitted error.");
          }
        });
      }
    },
    validateForm() {
      this.resetValidation();

      if (!this.data.cost || this.data.cost.toString().trim() === "") {
        this.error.cost = true;
      }
      if (
        !this.data.ownership ||
        this.data.ownership.toString().trim() === ""
      ) {
        this.error.ownership = true;
      }

      return !this.error.cost && !this.error.ownership;
    },
    resetValidation() {
      this.error.cost = false;
      this.error.ownership = false;
    },
  },
  computed: {
    monthStr() {
      return moment(`${this.textYear}-${this.textMonth}-01`).format("MMM YYYY");
    },
  },
  watch: {
    "data.cost"(v) {
      if (v) this.error.cost = false;
    },
    "data.ownership"(v) {
      if (v) this.error.ownership = false;
    },
  },
});
