<template>
  <div class="custom-v2">
    <div v-loading="loading">
      <div class="container-v2 submit-deal-form">
        <form @submit.prevent="save" autocomplete="false">
          <div class="row form1">
            <template>
              <div class="submit1">
                {{ state == "INSERT" ? "Submit a" : "Update" }} Deal
              </div>
            </template>
          </div>
          <div class="form1">
            <div
              v-for="(deal, index) in dataDealSubmission"
              :key="`dealsubmission_${index}`"
              class="row"
              :class="index + 1 !== dataDealSubmission.length ? 'sub-form' : ''"
            >
              <div class="deal-heading">
                <span class="number"
                  >{{ index > 10 ? "" : "0" }}{{ index + 1 }}</span
                >
                <span class="text">{{ deal.text }}</span>
              </div>
              <div
                v-for="(item, idx) in deal.data"
                :key="`dealsubmission_item_${idx}`"
                class="col-12 col-md-6"
              >
                <TextField
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  name="name"
                  field="gray"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  :fieldRequire="item.is_required"
                  v-if="
                    item.type === 'LINK' ||
                    (item.type === 'TEXTFIELD' &&
                      item.validate_type !== 'TEXT_LENGTH')
                  "
                  :subTitle="`${
                    item.validate_use &&
                    item.validate_type == 'TEXT_LENGTH' &&
                    item.validate_value
                      ? 'Text length: ' + item.validate_value
                      : ''
                  }`"
                />
                <TextField
                  :textarea="true"
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  name="name"
                  field="gray"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  :fieldRequire="item.is_required"
                  :textLength="item.validate_value"
                  v-if="
                    item.type === 'TEXTFIELD' &&
                    item.validate_type == 'TEXT_LENGTH'
                  "
                  :subTitle="`${
                    item.validate_use &&
                    item.validate_type == 'TEXT_LENGTH' &&
                    item.validate_value
                      ? 'Text length: ' + item.validate_value
                      : ''
                  }`"
                />
                <NumberField
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  name="number"
                  field="gray"
                  :type="`number`"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  v-else-if="item.type === 'NUMBER'"
                  :maxlength="item.validate_value.length"
                />
                <NumberField
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  name="name"
                  field="gray"
                  :type="item.type"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  v-else-if="item.type === 'CURRENCY'"
                />
                <FileField
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  name="name"
                  :autocomplete="false"
                  field="gray"
                  :id="`dealsubmission_item_${idx}`"
                  :acceptValue="item.validate_value"
                  :acceptType="item.validate_type"
                  :custom="true"
                  dataField=""
                  :fileUploaded="
                    item.answer.length ? JSON.parse(item.answer) : ''
                  "
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  v-else-if="item.type === 'FILE'"
                />
                <MultiSelectField
                  class="mb-3"
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  field="white"
                  :options="parseArray(item.answers)"
                  :name="`dealsubmission_item_${idx}`"
                  :placeholder="item.question"
                  :multiple="item.type === 'MULTI_SELECT' ? true : false"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  v-else-if="
                    item.type === 'SINGLE_SELECT' ||
                    item.type === 'MULTI_SELECT'
                  "
                />
                <Checkbox
                  :isHorizontal="true"
                  :indexField="idx"
                  color="black"
                  :title="item.question"
                  field="white"
                  :name="`dealsubmission_item_${idx}`"
                  v-model:value="item.ans"
                  :errorMsg="item.errorMsg"
                  v-else-if="item.type === 'BOOLEAN'"
                />
              </div>
            </div>
            <div
              class="row"
              v-if="
                routeQuery &&
                routeQuery.form === 'DEAL_SUBMISSION' &&
                routeQuery.request_id
              "
            >
              <div class="col-12 col-md-6">
                <TextField
                  :indexField="0"
                  v-model:value="form.ReferralPasscode"
                  color="black"
                  title="Passcode"
                  field="white"
                  name="passcode"
                  :autocomplete="false"
                  :errorMsg="errorMsg.passcode"
                />
              </div>
              <div class="col-12 col-md-6">
                <TextField
                  :indexField="0"
                  v-model:value="form.DealSubmissionRequestId"
                  color="black"
                  field="white"
                  name="dealRequestId"
                  type="hidden"
                  :autocomplete="false"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="recaptcha-wrapper">
          <ReCaptcha
            ref="recaptcha"
            v-if="isFromPublic"
            v-model:value="form.captcha"
            :errorMsg="errorMsg.captcha"
          />
        </div>
        <div class="btn-form" v-if="dataDealSubmission.length > 0">
          <!-- <NewButton
            @click="save(true)"
            :loading="loading"
            color="transparent"
            v-if="
              (!isFromPublic && state === 'INSERT') ||
              (state === 'UPDATE' && dataDeal && dataDeal.is_draft === true)
            "
            class="mr-2"
          >
            Save Draft
          </NewButton> -->
          <NewButton @submit="save(false)" :loading="loading">
            {{
              state === "INSERT" ||
              (state === "UPDATE" && dataDeal && dataDeal.is_draft === true)
                ? "Submit Form"
                : "Update"
            }}
          </NewButton>
        </div>
      </div>
    </div>

    <Dialog ref="dialog" />
  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent } from "vue";

import dealServices from "@/services/deal";
import notify from "@/services/notify";

import FormModal from "@/components/deal/FormModal.vue";
import ReCaptcha from "@/components/ReCaptcha";
import TextField from "@/components/v2/TextField.vue";
import NumberField from "@/components/v2/NumberField.vue";
import NewButton from "@/components/v2/NewButton.vue";
// import SelectField from '@/components/SelectField.vue'
import FileField from "@/components/v2/FileField.vue";
import Checkbox from "@/components/v2/Checkbox.vue";
import MultiSelectField from "@/components/v2/MultiSelectField.vue";

import { DEAL_SUBMISSIONS } from "@/misc/constants";
import generic from "@/misc/generic";
import Dialog from "@/components/Dialog";
import { router, USER_TYPE } from "@/config/router";
import moment from "moment";
const debounce = _.debounce((func) => {
  func;
}, 500);

export default defineComponent({
  components: {
    ReCaptcha,
    FormModal,
    TextField,
    NumberField,
    NewButton,
    // SelectField,
    FileField,
    Checkbox,
    MultiSelectField,
    Dialog,
  },
  data() {
    return {
      dataDeal: null,
      loading: false,
      state: "INSERT",
      isFromPublic: false,
      form: {
        id: null,
        captcha: null,
        ReferralPasscode: null,
        DealSubmissionRequestId: -1,
        isDraft: false,
      },
      errorMsg: {
        captcha: null,
        passcode: null,
      },
      dataDealSubmission: [],
      dataAnswers: {
        Answers: [],
      },
      routeQuery: null,
      showModalConfirm: false,
      loginPath: router.login.path,
      checkDataLocal: false,
    };
  },
  created() {
    this.showAddPublic();
  },
  mounted() {
    this.routeQuery = this.$route.query;
    if (this.routeQuery) {
      if (this.routeQuery.request_id) {
        this.form.DealSubmissionRequestId = this.routeQuery.request_id;
      }
    }
  },
  methods: {
    async getDealSubmissionQuestion() {
      const res = await dealServices.getSubmissionQuestions();
      if (res && res.data && res.data.ok) {
        let data = res.data.d;
        let group = _.cloneDeep(DEAL_SUBMISSIONS);
        group.forEach((x) => {
          x.data = data.filter((i) => i.segment === x.segment_id);
          x.data.forEach((i) => {
            i.ans = null;
            i.errorMsg = "";
          });
        });
        this.dataDealSubmission = group;
      }
      this.handleDataLocalStorage();
    },
    async getDealSubmissionQuestionByDealId() {
      const res = await dealServices.getSubmissionQuestionsByDealId(
        this.dataDeal.deal_id
      );
      //console.log(res.data.d, 'getSubmissionQuestionsByDealId')
      if (res && res.data && res.data.ok) {
        let data = res.data.d;
        let group = _.cloneDeep(DEAL_SUBMISSIONS);
        group.forEach((x) => {
          x.data = data.filter((i) => i.segment === x.segment_id);
          x.data.forEach((i) => {
            let ans = this.dataDeal.submission_answers.find(
              (z) => z.question_id == i.question_id
            );
            if (ans) {
              switch (ans.question_type) {
                case "TEXTFIELD":
                case "LINK":
                  i.ans = ans.text;
                  break;
                case "SINGLE_SELECT":
                  i.ans = ans.text;
                  break;
                case "MULTI_SELECT":
                  i.ans = ans.array;
                  break;
                case "FILE":
                  i.ans = ans.file;
                  break;
                case "OPTIONS":
                  i.ans = ans.text;
                  break;
                case "CHECKBOX":
                  i.ans = ans.array;
                  break;
                case "BOOLEAN":
                  i.ans = ans.boolean;
                  break;
                case "NUMBER":
                  i.ans = ans.number;
                  break;
                case "CURRENCY":
                  i.ans = ans.number;
                  break;
              }
            } else {
              i.ans = null;
            }
            i.errorMsg = "";
          });
        });
        this.dataDealSubmission = group;
      }
    },
    async showAdd() {
      this.loading = true;
      this.state = "INSERT";
      this.fixedBody();
      await this.getDealSubmissionQuestion();
      this.loading = false;
    },
    async showAddPublic() {
      this.isFromPublic = true;
      this.loading = true;
      this.state = "INSERT";
      // this.fixedBody();
      await this.getDealSubmissionQuestion();
      this.loading = false;
    },
    async showUpdate(dataDeal) {
      this.loading = true;
      this.dataDeal = _.cloneDeep(dataDeal);
      this.form = {
        id: this.dataDeal.deal_id,
      };
      this.state = "UPDATE";
      await this.getDealSubmissionQuestionByDealId();
      this.loading = false;
    },
    async save(isDraft) {
      this.loading = true;
      this.form.isDraft = isDraft;
      //check login
      this.form.isLogin = this.$store.state.profile.userId === 0 ? false : true;
      if (!isDraft) {
        if (!(await this.validData())) {
          notify.error("Please check your answers!");
          this.loading = false;
          return;
        }
        if (
          this.isFromPublic &&
          !this.form.captcha &&
          this.state !== "UPDATE"
        ) {
          this.errorMsg.captcha = "Invalid Recaptcha";
          this.loading = false;
          return;
        }
      }

      if (this.isFromPublic && !this.form.captcha) {
        this.$refs.recaptcha.onCaptchaVerified();
        debounce(this.save(isDraft));
        this.loading = false;
        return;
      }

      let answers = [];
      this.dataDealSubmission.forEach((g) => {
        if (!g.data.length) return;
        g.data.forEach((x) => {
          let obj = { question_id: x.question_id };
          switch (x.type) {
            case "TEXTFIELD":
            case "LINK":
            case "SINGLE_SELECT":
            case "OPTIONS":
              obj.text = x.ans;
              break;
            case "MULTI_SELECT":
              obj.array = JSON.stringify(!x.ans || !x.ans.length ? [] : x.ans);
              break;
            case "FILE":
              if (x.ans instanceof Blob || x.ans instanceof File) {
                obj.file = x.ans;
              }
              break;
            case "CHECKBOX":
              obj.array = JSON.stringify(!x.ans || !x.ans.length ? [] : x.ans);
              break;
            case "BOOLEAN":
              obj.boolean = x.ans;
              break;
            case "NUMBER":
            case "CURRENCY":
              obj.number = x.ans;
              break;
          }
          answers.push(obj);
        });
      });
      this.form.answers = answers;
      const fd = this.convertFormData(this.form);
      if (this.state === "INSERT") {
        if (this.$store.state.profile.userId === 0) {
          generic.setJsonLocalStorage(
            "userRegister",
            JSON.stringify(this.dataDealSubmission)
          );
          this.convertFileToBase64(this.dataDealSubmission[0].data[0].ans);
        }
        await this.createDeal(fd, isDraft);
      } else if (this.state === "UPDATE") {
        await this.updateDeal(fd);
      }
      this.loading = false;
    },
    async createDeal(data, isDraft) {
      this.loading = true;
      const res = await dealServices.createDeal(data);
      if (res.data.ok) {
        if (isDraft) {
          notify.success("Deal saved as draft successfully!");
        } else {
          notify.success("Deal created successfully!");
        }

        this.$emit("refresh");
        this.staticBody();
        this.removeDataLocal();
        this.$router.push("/");
      } else {
        // console.log('Create deal failed',res)
        if (res.data.c === "email_already_exists") {
          this.confirm({
            body: "Account already exists! Do you want to login?",
          });
          this.checkDataLocal = false;
        }
        // notify.error('Create deal failed!')
      }
      // if (this.isFromPublic)
      //     this.hideLayerMaskCaptcha()
      this.loading = false;
      this.$refs.recaptcha.onCaptchaExpired();
    },
    async updateDeal(data) {
      this.loading = true;
      const res = await dealServices.updateDeal(data, this.dataDeal.deal_id);
      if (res.data.ok) {
        notify.success("Deal updated successfully!");
        this.$emit("refresh");
        // this.$root.$emit('reloadDeal', this.dataDeal.deal_id, true)
        this.staticBody();
      }
      // if (this.isFromPublic)
      //     this.hideLayerMaskCaptcha()
      this.loading = false;
      this.$refs.recaptcha.onCaptchaExpired();
    },
    convertFormData(data) {
      function capitalize(str) {
        if (!str) return;
        const arr = str.split("_");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join("");
      }

      function buildFormData(fd, d, parentKey, arrayKey, beforeKey) {
        if (_.isArray(d)) {
          d.forEach((x, key) => {
            buildFormData(
              fd,
              x,
              parentKey ? `${parentKey}[${key}]` : key,
              key,
              parentKey
            );
          });
        } else if (d instanceof File) {
          // console.log(`${parentKey}`, d)
          fd.append(`${parentKey}`, d);
        } else if (_.isObject(d)) {
          // console.log('--obj', d)
          var a = 0;
          Object.keys(d).forEach((key) => {
            if (parentKey && a == 0) {
              // console.log(`${beforeKey}.Index`, arrayKey)
              fd.append(`${beforeKey}.Index`, arrayKey);
              a++;
            }
            buildFormData(
              fd,
              d[key],
              parentKey ? `${parentKey}.${capitalize(key)}` : capitalize(key)
            );
          });
        } else {
          // console.log(capitalize(parentKey), d == null ? '' : d)
          fd.append(capitalize(parentKey), d == null ? "" : d);
        }
      }

      const fd = new FormData();
      buildFormData(fd, data);
      return fd;
    },
    parseArray(data) {
      return JSON.parse(data);
    },
    async validData() {
      let error = 0;

      // if (this.isFromPublic && $.trim(this.form.captcha) === '') {
      //     this.errorMsg.captcha = 'Invalid Recaptcha'
      //     flag = false
      // }

      this.dataDealSubmission.forEach((data) => {
        data.data.forEach((p) => {
          if (p.answer_process_type === "FILE" && p.ans) {
            if (p.ans.size >= 104857600) {
              p.errorMsg = "Exceeds max allowed file size: 100MB";
              error++;
            }
          }
          if (p.answer_process_type === "FINDUS_OTHER_ANSWER" && !p.ans) {
            let findUsQuestion = data.data.filter(
              (x) => x.answer_process_type === "FINDUS"
            );
            if (
              findUsQuestion &&
              findUsQuestion[0] &&
              findUsQuestion[0].ans &&
              (findUsQuestion[0].ans.toLowerCase() === "personal reference" ||
                findUsQuestion[0].ans.toLowerCase() === "none of the above")
            ) {
              p.errorMsg = "This field cannot be blank";
              error++;
            }
          } else if (
            p.is_required &&
            ["MULTI_SELECT", "CHECKBOX"].includes(p.type) &&
            (!p.ans || !p.ans.length || p.ans.length <= 0)
          ) {
            p.errorMsg = "This field cannot be blank";
            error++;
          } else if (
            p.is_required &&
            p.type !== "BOOLEAN" &&
            (p.ans === "" || p.ans === null || p.ans === undefined)
          ) {
            p.errorMsg = "This field cannot be blank";
            error++;
          }
        });
      });

      if (
        this.routeQuery &&
        this.routeQuery.form === "DEAL_SUBMISSION" &&
        this.routeQuery.request_id &&
        this.form.ReferralPasscode.trim() === ""
      ) {
        this.errorMsg.passcode = "This field cannot be blank";
        error++;
      }
      return error === 0;
    },
    modalClose() {
      if (this.isFromPublic) {
        this.$router.push("/");
      }
      this.$emit("modalClose");
    },
    onReCaptchaVerify(token) {
      this.form.captcha = token;
    },
    onReCaptchaExpire() {
      this.form.captcha = null;
    },
    hideLayerMaskCaptcha: function () {
      $("body").children().last().find(">div").trigger("click");
    },
    confirm: function (param) {
      let message = {
        title: "Confirmation",
        body: param.body,
      };

      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.confirmFunc(param);
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    confirmFunc: function (param) {
      if (!param) {
        this.removeDataLocal();
        return;
      }
      if (this.checkDataLocal) {
        //load data draff
        const dataForm = JSON.parse(
          generic.getJsonLocalStorage("userRegister")
        );
        const file = this.convertBase64ToFile();
        if (dataForm) {
          const obj = {
            s3Key: "",
            file_name: file.name,
            file_size: file.size,
            extend: ".pdf",
          };
          dataForm[0].data[0].ans = file;
          dataForm[0].data[0].answer = JSON.stringify(obj);
          this.dataDealSubmission = dataForm;
        }
        this.removeDataLocal();
      } else {
        generic.setJsonLocalStorage("checkUser", moment().format("YYYY-MM-DD"));
        this.modalClose();
        this.staticBody();
        global.vueRouter.push(router.login.path);
      }
    },
    handleDataLocalStorage() {
      const isLogin = this.$store.state.profile.userId;
      const dataExit = generic.getJsonLocalStorage("checkUser");
      if (isLogin === 0 || !dataExit) return;
      if (moment(dataExit).diff(moment().format("YYYY-MM-DD")) !== 0) {
        this.removeDataLocal();
        return;
      }
      this.confirm({
        body: `You have an unfinished submission form. Do you want to continue?`,
      });
      this.checkDataLocal = true;
    },
    removeDataLocal() {
      generic.removeLocalStorage("userRegister");
      generic.removeLocalStorage("checkUser");
      generic.removeLocalStorage("file");
      generic.removeLocalStorage("name");
    },
    convertFileToBase64: function (file) {
      if (!file) return;
      const reader = new FileReader();
      reader.onload = function (base64) {
        generic.setJsonLocalStorage("file", reader.result);
        generic.setJsonLocalStorage("name", file.name);
      };
      reader.readAsDataURL(file);
    },
    convertBase64ToFile: function () {
      const base64 = generic.getJsonLocalStorage("file");
      if (!base64) return;
      const name = generic.getJsonLocalStorage("name");
      const base64Parts = base64.split(",");
      const fileFormat = base64Parts[0].split(";")[1];
      const fileContent = base64Parts[1];
      const file = new File([fileContent], name, { type: fileFormat });
      return file;
    },
  },
  watch: {
    "form.ReferralPasscode": function (v) {
      this.errorMsg.passcode = null;
    },
  },
  beforeUnmount() {
    this.specifyIdDeal = null;
  },
});
</script>

<style lang="scss" scoped>
.form1 {
  padding-bottom: 10px;
}
.submit1 {
  color: #000 !important;
  margin-left: 15px;
  padding-bottom: 15px;
  font-size: 1.8rem;
  display: block;
  font-weight: 650;
}
.sub-title {
  color: rgb(26, 156, 231) !important;
  font-size: 1rem;
  font-weight: 600;
}
.border {
  border: none;
  border-radius: 5px;
  background: rgb(213, 227, 240);
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.btn-form {
  display: flex;
  justify-content: center;
  padding-bottom: 160px;
}
.pass {
  padding: 0px !important;
}
</style>

<style lang="scss" scoped>
.submit-deal-form {
  font-family: "SF Pro";
  .sub-form {
    padding-bottom: 40px;

    @media (min-width: 768px) {
      padding-bottom: 80px;
    }
  }

  .sub-form-1 {
    .pick-desk {
      font-family: "SF Pro Text";
      label {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        padding-bottom: 8px;
      }

      .note {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;

        letter-spacing: -0.25px;

        color: #aaaaaa;
        padding-bottom: 8px;
      }
    }
  }

  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }

  .deal-heading {
    font-family: "SF Pro Text";
    width: 100%;
    position: relative;
    margin-bottom: 48px;
    .number {
      color: #0095ff;
      font-weight: 700;
      font-size: 128px;
      line-height: 100px;

      opacity: 0.2;
    }

    .text {
      font-size: 26px;
      font-weight: 700;

      @media (min-width: 768px) {
        font-size: 32px;
        font-weight: 700;
        line-height: 22px;
      }

      position: absolute;
      left: 50px;
      bottom: 5px;
    }
  }

  .btn-submit-form {
    padding-bottom: 160px;
    button {
      width: 100%;
    }
  }

  .mb-48 {
    margin-bottom: 48px;
  }

  .recaptcha-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 48px;
  }
}
.custom-v2 {
  :deep() .confirm-btn {
    padding: 6px 12px !important;
  }
}
</style>
