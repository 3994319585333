<template>
  <section class="section-9">
    <div class="wrap-9">
      <div class="container-v2 top-header">
        <div
          class="heading"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h5>Meet Our Founders and CEOs</h5>
          <router-link class="btn-arrow" exact to="/portfolio">
            Portfolios
            <svg
              width="13"
              height="7"
              viewBox="0 0 13 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1107_16134)">
                <path
                  d="M7.28027 6.09801L11.7803 3.50001L7.28027 0.902008V3.05001H0.755875V3.95001H7.28027V6.09801Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1107_16134">
                  <rect
                    width="12"
                    height="6"
                    fill="white"
                    transform="matrix(-1 0 0 1 12.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </router-link>
        </div>
      </div>

      <div
        class="container-v2"
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <div class="container-portfolio">
          <Portfolios
            v-for="(el, index) in listMeetOurFounder"
            :key="index"
            :data="el"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Portfolios from "./Portfolios.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  components: {
    Portfolios,
  },
  props: {
    listMeetOurFounder: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      list: [],
      windowWidth: 0,
    };
  },
  mounted() {
    // mobile is disable horizontal scroll
    if (window.innerWidth < 768) return;

    this.renderScroll();
    // this.onResize();
    // window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    // window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    renderScroll() {
      let sections = gsap.utils.toArray(".portfolio-item-main");
      const wrapper = document.querySelector(".section-9");
      if (!wrapper) return;

      let end = "+=7500";

      if (this.listMeetOurFounder.length < 4) {
        end = "+=" + wrapper.offsetWidth;
      }
      if (this.listMeetOurFounder.length >= 10) {
        end = "+=10500";
      }

      const homeSection = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".section-9",
          invalidateOnRefresh: true,
          start: "top top",
          pin: true,
          scrub: 0.4,
          // markers: true,
          //  end: () => "+=" + wrapper.offsetWidth,
          end,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.section-9 {
  height: 100%;
  position: relative;
  z-index: 5;

  @media screen and (min-width: 1900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top-header {
    margin-bottom: 20px;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;

    h5 {
      font-size: 40px;
      font-weight: 700;
      line-height: 100%;

      @media screen and (min-width: 768px) {
        font-size: 60px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 80px;
      }

      @media screen and (min-width: 1700px) {
        font-size: 88px;
      }
    }
  }

  .container-portfolio {
    display: flex;
    overflow-y: auto;

    @media screen and (min-width: 768px) {
      overflow-y: unset;
    }
  }
}
</style>
