<template>
    <li>
        <div class="tree-node" :class="{ 'active-tree-node': item.id === selectedId }" @click="oneClick($event)">
          <span @click.prevent.stop="toggle" class="caret" v-if="children && children.length > 0 && !isMainTree">
            <i v-if="isOpen" class="fas fa-caret-down"></i>
            <i v-else class="fas fa-caret-right"></i>
          </span>
          
          <div class="tree-node-name noselect" :class="[{ 'active-menu': item.id === selectedId, 'root': item.id === 0 }]">
            <svg v-if="item.id === 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="4.03 0 290.19 290.19" style="margin: 5px">
                <g id="_0095ffff" data-name="#0095ffff" transform="translate(-374.5 -84.97)">
                    <path id="Path_35073" data-name="Path 35073" d="M464.94,107.8q20.04-11.415,40.09-22.83.015,108.435,0,216.87L374.5,375.16v-39.8q45.225-25.245,90.44-50.5-.015-24.36,0-48.72-29.73,16.41-59.44,32.82-.015-19.89,0-39.79,29.7-16.41,59.42-32.82Q464.98,152.085,464.94,107.8Z" fill="#0095ff"/>
                    <path id="Path_35074" data-name="Path 35074" d="M526.09,84.97q20.055,11.4,40.1,22.83,0,44.28.01,88.55,29.715,16.38,59.4,32.81-.015,19.89-.01,39.79-29.7-16.41-59.4-32.81-.015,24.36-.01,48.72,45.21,25.275,90.45,50.5-.015,19.89-.01,39.79-65.265-36.645-130.53-73.31Z" fill="#0095ff"/>
                </g>
            </svg>
            <i v-else-if="isOpen && item.children && item.children.length > 0" class="fas fa-folder-open mx-2 leaf-icon"></i>
            <i v-else class="fas fa-folder mx-2 leaf-icon"></i>
            <span class="leaf-name">{{ item.name }}</span>
          </div>
        </div>
        <ul v-show="item.id === 0 || isOpen" v-if="isFolder" class="subTree">
          <tree-item
            class="item"
            v-for="(child, index) in children"
            :key="index"
            :item="child"
            :selectedId="selectedId"
            :selectedParentId="selectedParentId"
            @select-folder="$emit('select-folder', $event)"
          ></tree-item>
        </ul>
    </li>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

ul {
  list-style: none;
  .subTree {
    padding: 0;
    .caret {
      top: 3px;
    }
    .tree-node {
      padding: 2px 2px 2px 26px;
      .leaf-name {
        width: 190px;
      }
    }
    .subTree {
      .tree-node {
        padding: 2px 2px 2px 42px;
        .leaf-name {
          width: 174px;
        }
      }
      .subTree {
        .tree-node {
          padding: 2px 2px 2px 58px;
          .leaf-name {
            width: 158px;
          }
        }
        .subTree {
          .tree-node {
            padding: 2px 2px 2px 74px;
            .leaf-name {
              width: 142px;
            }
          }
          .subTree {
            .tree-node {
              padding: 2px 2px 2px 90px;
              .leaf-name {
                width: 126px;
              }
            }
            .subTree {
              .tree-node {
                padding: 2px 2px 2px 106px;
                .leaf-name {
                  width: 110px;
                }
              }
              .subTree {
                .tree-node {
                  padding: 2px 2px 2px 122px;
                  .leaf-name {
                    width: 94px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  li {
    cursor: pointer;
  }
}
// .active-menu {
//   color: #409EFF;
// }
// .show-child{
//   position: relative;
//   &:after{
//     content: '[-]';
//   }
// }
// .hide-child{
//   position: relative;
//   &:after{
//     content: '[+]';
//   }
// }
.tree-node {
  padding: 2px 2px 2px 10px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  &:hover {
    background-color: #eeeeee;
    .tree-node-name {
      color: $color-dark;
    }
    .caret {
      color: $color-dark;
    }
  }
  &.active-tree-node {
    background-color: #e8f0fe;
    .caret {
      color: $color-dark;
    }
  }
  display: flex;
  align-items: flex-end;
  color: #BDBDBD; //#5F6368;
  width: 100%;
  .caret {
    position: absolute;
    top: 6px;
    padding: 0 6px;
    color: #BDBDBD;

    .fa-caret-down, .fa-caret-right {
      width: 10px;
    }
  }
  .tree-node-name {
    display: flex;
    color: #BDBDBD; //#5F6368;
    margin-left: 16px;
    &.root {
      align-items: center;
    }
    // &:hover {
    //   color: $color-dark;
    // }
    .leaf-icon {
      font-size: 1.2rem;
      margin-top: 2px;
      width: 22px;
    }
    .leaf-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
    &.active-menu {
      .leaf-name {
        color: #409EFF;
      }
      .leaf-icon {
        color: #5F6368;
      }
    }
  }
}
.st0 {
  fill: #3E99ED;
}
</style>
