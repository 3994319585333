import AdminServices from "@/services/admin";
import notify from "../../../../../services/notify";
import _ from "lodash";
import { defineComponent } from "vue";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";

export default defineComponent({
  components: {
    Table,
    TextField,
    SelectField,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      members: [],
      memberSelected: [],
      columns: [
        {
          id: "name",
          name: "Name",
          // sort: true,
          direction: "",
          _minWidth: "150px",
          titleClass: "text-wrap",
          columnClass: "text-wrap",
        },
        {
          id: "email",
          name: "Email",
          direction: "",
          titleClass: "text-wrap",
          columnClass: "text-wrap",
        },
        {
          id: "is_device",
          name: "App installed",
          direction: "",
        },
      ],
      selectDeviceList: [
        {
          name: "All",
          code: null,
        },
        {
          name: "Installed",
          code: true,
        },
        {
          name: "Not Installed",
          code: false,
        },
      ],
      filteredName: "",
      filteredEmail: "",
      filteredStatus: null,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: false,
    };
  },
  watch: {
    filteredName() {
      debounce(this.getInvestorMembersData);
    },
    filteredEmail() {
      debounce(this.getInvestorMembersData);
    },
    filteredStatus() {
      debounce(this.getInvestorMembersData);
    },
  },
  computed: {
    targetMembersIds() {
      return this.memberSelected.map((x) => x.id);
    },
  },
  created() {
    if (this.id) this.loadData(this.id);
  },
  methods: {
    onSelectAllMember(v, isChecked) {
      if (isChecked) {
        this.getAllMembers();
      } else {
        this.clearMembers();
      }
    },
    clearMembers() {
      this.memberSelected = [];
    },
    getAllMembers() {
      this.memberSelected = [];
      this.members.forEach((el) => {
        this.selectMember(el);
      });
    },
    isMemberSelecting(props) {
      return this.memberSelected.some((x) => x.id === props.id);
    },
    selectMember(item) {
      if (!this.memberSelected.find((p) => p.id === item.id)) {
        this.memberSelected.push(item);
      } else {
        this.memberSelected = this.memberSelected.filter(
          (p) => p.id !== item.id
        );
      }
    },
    async loadData(id) {
      this.loading = true;
      try {
        await this.getInvestorMembersData();
        await this.fetchMembersByFundId(id);
      } catch (err) {
        console.log(err);
        notify.error("Error", "Some errors occurred.");
      }
      this.loading = false;
    },
    async getInvestorMembersData() {
      try {
        const resp = await AdminServices.getInvestorMembersData({
          filteredName: this.filteredName,
          filteredEmail: this.filteredEmail,
          hasDevice: this.filteredStatus,
        });
        this.members = resp.data.d || [];
      } catch (err) {
        console.log(err);
      }
    },
    async fetchMembersByFundId(id) {
      try {
        const _memberRes = await AdminServices.findMembersByLPFundId(id);
        if (_memberRes.error) return;
        this.memberSelected = _memberRes.data.d || [];
      } catch (err) {
        console.log(err);
      }
    },
    onSave() {
      this.loading = true;
      AdminServices.addMembersToLpFund({
        id: this.id,
        members: this.memberSelected,
      })
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("complete");
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    closeModal() {
      this.$emit("close");
    },
  },
});
/* A function that is returning a promise. */
