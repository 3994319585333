import _ from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "customSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    value: {},
    label: String | null,
    isMultiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["update:value"],
  data() {
    return {
      selectedOption: null,
      selectedOptions: [],
      open: false,
      searchKey: "",
      filterTimer: null,
      filteredOptions: [],
    };
  },
  mounted() {
    this.filteredOptions = _.cloneDeep(this.options);

    if (this.isMultiple) {
      this.filteredOptions.forEach((x) => {
        if (this.value?.includes(x.value)) {
          this.selectedOptions.push(x);
        }
      });
    } else {
      this.selectedOption = this.filteredOptions.find(
        (x) => x.value === this.value
      );
    }
  },
  created() {
    window.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  },
  watch: {
    value: {
      deep: true,
      handler(v) {
        if (!v) {
          if (this.isMultiple) {
            this.selectedOptions = [];
          } else {
            this.selectedOption = null;
          }
        }
      },
    },
    options(v) {
      if (this.value && this.value.length > 0) {
        this.value.forEach((x) => {
          const findSelected = v.find((el) => el.value === x);
          if (findSelected) {
            this.selectedOptions.push(findSelected);
          }
        });
      }

      if (this.filteredOptions.length === 0) {
        this.filteredOptions = _.cloneDeep(v);
      }
    },
  },
  methods: {
    closeDropdown(e) {
      if (!this.$refs.select.contains(e.target)) {
        this.open = false;
        this.searchKey = "";
        this.filteredOptions = _.cloneDeep(this.options);
      }
    },
    onSelect(option) {
      if (this.isMultiple) {
        let _index = this.selectedOptions.findIndex(
          (x) => x.value === option.value
        );
        if (_index === -1) {
          this.selectedOptions.push(option);
        } else {
          this.selectedOptions.splice(_index, 1);
        }
        this.$emit(
          "update:value",
          this.selectedOptions.map((x) => x.value)
        );
      } else {
        this.selectedOption = option;
        this.open = false;
        this.$emit("input", option.value);
      }
    },
    onClickSelected() {
      this.open = !this.open;
    },
    checkSelected(option) {
      if (this.isMultiple && this.selectedOptions) {
        return this.selectedOptions.find((x) => x.value === option.value)
          ? true
          : false;
      }
    },

    delayedFilter() {
      if (this.filterTimer != null) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(this.doFilter, 500);
    },
    doFilter() {
      this.filterTimer = null;
      this.onSearch(this.searchKey);
    },

    onSearch(key) {
      const _key = key.trim().toLowerCase();
      if (_key) {
        this.filteredOptions = this.options.filter(
          (x) =>
            (x.fname && x.fname.toLowerCase().indexOf(_key) > -1) ||
            (x.lname && x.lname.toLowerCase().indexOf(_key) > -1) ||
            (x.fname &&
              x.lname &&
              (x.fname + " " + x.lname).toLowerCase().indexOf(_key) > -1) ||
            (x.email && x.email.toLowerCase().indexOf(_key) > -1)
        );
      } else {
        this.filteredOptions = _.cloneDeep(this.options);
      }
    },

    clearSearch() {
      this.searchKey = "";
      this.filteredOptions = _.cloneDeep(this.options);
    },
  },
});
