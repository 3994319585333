<template>
  <div class="video">
    <div class="video-overlay" v-if="showPlayButton">
      <button @click="play" class="btn btnBlue play-btn">Watch Video</button>
    </div>
    <video
      muted
      preload
      autoplay
      loop
      controls
      ref="introVideo"
      :src="introVideo_URL"
    >
      Your browser does not support HTML5 video.
    </video>
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.video {
  width: 100%;
  height: calc(100vh - 65px);
  position: relative;
  @media screen and (max-width: 1023.98px) {
    height: calc(100vh - 65px);
  }

  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000099;

    .play-btn {
      position: absolute;
      top: calc(50% - 17px);
      left: calc(50% - 52px);
    }
  }
  video {
    width: 100%;
  }
}
</style>
