import AdminServices from "@/services/admin";
import addMember from "./addUser";
import notify from "@/services/notify";
import { PERMISSIONS } from "@/config/router";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import Dialog from "@/components/Dialog";
import { defineComponent } from "vue";

const columnsData = [
  {
    id: "name",
    name: "Name",
    sort: true,
    direction: "",
  },
  {
    id: "email",
    name: "Email",
    sort: true,
    direction: "",
  },
  {
    id: "role",
    name: "Role",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "actions",
    name: "Actions",
  },
];

export default defineComponent({
  data() {
    return {
      searchParams: {
        paging: {
          page: 1,
          size: 10,
          total: 0,
        },
        totalItems: 0,
        filteredName: "",
        filterEmail: "",
        filteredStatus: "ACTIVE",
        filteredRole: "",
        orderBy: "",
      },
      tableData: null,
      loading: false,
      isShowAddUser: false,
      // isShowmodalConfirm: false,
      dataInfo: null,
      StatusList: [
        {
          name: "All",
          code: "",
        },
        {
          name: "Active",
          code: "ACTIVE",
        },
        {
          name: "Inactive",
          code: "INACTIVE",
        },
      ],
      roleList: [],
      userTypeList: [
        {
          id: "Rokker",
          name: "FuelVC",
        },
        {
          id: "IDC",
          name: "IDC",
        },
      ],
      carrierPhoneList: [],
      PERMISSIONS: PERMISSIONS,
      columns: columnsData,
    };
  },
  components: {
    Dialog,
    addMember,
    Table,
    TextField,
  },
  mounted() {
    AdminServices.getUserRoles({
      paging: { size: 100, page: 1 },
      filteredStatus: "ACTIVE",
    }).then((resp) => {
      this.roleList = resp.data.d;
      this.roleList.unshift({ id: "", name: "All", stt: "ACTIVE" });
    });
    AdminServices.getPhoneCarrierMeta({}).then((resp) => {
      this.carrierPhoneList = resp.data.d.map((p) => ({
        ...p,
        phone_carrier_id: p.phone_carrier_id + "",
      }));
    });
    this.$nextTick(() => {
      this.getAll();
    });
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    updatePaging(v) {
      this.searchParams.paging = Object.assign(this.searchParams.paging, v);
      this.getAll();
    },
    async getAll() {
      this.loading = true;
      await AdminServices.getAllRokkerUsers(this.searchParams).then((resp) => {
        if (resp.data.ok) {
          this.tableData = resp.data.d.c;
          this.searchParams.paging.page = resp.data.d.p;
          this.searchParams.paging.size = resp.data.d.s;
          this.searchParams.paging.total = resp.data.d.t;
          this.searchParams.totalItems = resp.data.d.t;
          //this.tableData.carrierPhoneList = this.carrierPhoneList
        }
        this.loading = false;
      });
    },
    loadPage(value) {
      this.searchParams.paging.page = value;
      debounce(this.getAll);
    },
    addNewUser(data) {
      let that = this;
      AdminServices.createRokkerUser(data).then((resp) => {
        if (resp.data.ok) {
          notify.success("Add user success!");
          that.loadPage(1);
        }
        that.hideAddUser();
      });
    },
    showAddUser() {
      this.isShowAddUser = true;
    },
    hideAddUser() {
      this.isShowAddUser = false;
      this.dataInfo = null;
    },
    editUserInfo(item) {
      this.dataInfo = item;
      this.showAddUser();
    },
    updateUser(id, data) {
      let that = this;
      this.loading = true;
      AdminServices.updateRokkerUser(id, data).then((resp) => {
        if (resp.data.ok) {
          notify.success("Update user success!");
          that.getAll();
        }
        that.loading = false;
        that.hideAddUser();
      });
    },
    openModalConfirm(type, item) {
      let message = {
        title:
          type === "delete"
            ? "Delete User"
            : item.stt === "INACTIVE"
            ? "Activate User"
            : "Inactivate User",
        body:
          type === "delete"
            ? "Press button confirm to delete this user"
            : item.stt === "INACTIVE"
            ? "Press button confirm to activate this user"
            : "Press button confirm to inactivate this user",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Close",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        // customClass: 'confirmDeleteMember' // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$nextTick(() => {
        this.$refs.dialog
          .confirm({ ...message, ...options })
          .then(async (d) => {
            if (!d) return;

            let _item = Object.assign({}, item);

            if (type === "status") {
              this.updateStatus(_item);
            } else if (type === "delete") {
              this.deleteUser(_item);
            }
          })
          .catch(function () {
            // This will be triggered when user clicks on cancel
          });
      });
    },
    updateStatus(item) {
      let that = this;
      this.loading = true;
      AdminServices.updateRokkerUserStatus(item.id, {
        isLocked: item.stt === "ACTIVE",
      })
        .then((resp) => {
          if (resp.data.ok) {
            const message = item.isLocked
              ? "Activate user successfully"
              : "Inactivate user successfully";
            notify.success(message);
            that.getAll();
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    delayedFilter() {
      if (this.filterTimer != null) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(this.doFilter, 500);
    },
    doFilter() {
      this.filterTimer = null;
      this.loadPage(1);
    },
    onSortBy(type) {
      if (this.sortType === type) {
        this.toggleSortDirection();
      } else {
        this.sortType = type;
        this.sortDirection = "";
      }
      this.searchParams.paging.page = 1;
      this.searchParams.orderBy = `${this.sortType} ${this.sortDirection}`;
      this.getAll();
    },
    toggleSortDirection() {
      if (this.sortDirection === "") {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "";
      }
    },
    checkActiveSort(type, direction) {
      return type === this.sortType && direction === this.sortDirection;
    },
    getName(array, code, prop = "code") {
      let type = _.find(array, (x) => x[prop] == code);
      if (type) {
        return type.name;
      } else {
        return "";
      }
    },
    deleteUser(item) {
      let that = this;
      this.loading = true;
      AdminServices.deleteUser(item.id)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Delete user successfully");
            that.getAll();
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
  },
  watch: {
    "searchParams.filteredName": function (v) {
      this.setPageDefault()
      debounce(this.getAll);
    },
    "searchParams.filteredEmail": function (v) {
      this.setPageDefault()
      debounce(this.getAll);
    },
    "searchParams.paging.page": {
      deep: true,
      handler: function (v) {
        debounce(this.getAll);
      },
    },
    columns: {
      deep: true,
      handler(v) {
        let col = v.find(
          (p) => typeof p.sort === "boolean" && p.sort && p.direction
        );
        this.searchParams.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
});
