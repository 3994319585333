<template>
  <div>
    <label v-if="title" :class="`title title-color-${color}`" :for="name"
      >{{ title }}
      <template v-if="fieldRequire"
        ><small class="require">*</small></template
      ></label
    >
    <div
      :class="`text-field ${size ? 'size-' + size : ''} ${
        hideError ? 'hide-error' : ''
      }`"
    >
      <template v-if="type === 'number'">
        <input
          :class="`field-color-${field}`"
          :index="indexField"
          v-model="_value"
          :disabled="disabled"
          :id="name"
          :placeholder="placeholder"
          :tabindex="tabindex"
          :type="type"
          :autocomplete="autocomplete ? '' : 'off'"
          :maxlength="maxLength"
          @blur="changeNumber($event.target)"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </template>
      <template v-else-if="type === 'CURRENCY'">
        <input
          :index="indexField"
          placeholder="$"
          type="text"
          v-model="displayValue"
          @blur="
            isInputActive = false;
            changeCurrency($event.target);
          "
          @focus="isInputActive = true"
          @keypress="isNumber($event)"
        />
      </template>
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.text-field {
  position: relative;
}
.currency {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9em;
}
input {
  width: 100%;
  outline: none;
  border: 1px solid #949ba2;
  border-radius: 5px;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 25px;
  font-size: 14px;
  background: transparent;
  color: #35495e;

  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  color: #000;
}
.field-color {
  &-white {
    background: #ffff;
    // color: #0000 !important;
    border: 1px solid #949ba2;
    border-radius: 5px;
    color: #949ba2;
  }
  &-gray {
    background: #ffff;
    // color: #0000 !important;
    border: 1px solid #949ba2;
    border-radius: 5px;
    color: #35495e;
  }
  &-dark {
    background: #494b54;
    color: #949ba2;
    border-radius: 4px;
    border: 1px solid #70707038;
  }
}
.title {
  margin-top: 0px;
  .require {
    color: #f52727;
  }
}
</style>
