<template>
    <div class="adminContent p-3" v-loading="loading">
        <router-link v-if="!isFounder" class="pointer link" :to="{ path: partnerPath, name: partnerRouteName}">Go back</router-link>
        <div class="data p-3 mt-3" v-if="!loading && charts.length === 0">
            <h5>This partner has no chart. Please contact our admin.</h5>
        </div>
        <div v-else-if="!loading" class="mt-3 data p-3">
            <div class="row mb-2">
                <div class="col-12 field-name">
                    Kpi Charts
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 field-group" v-for="(chart, index) in charts" :key="chart">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input me-2" :id="`${chart.id}${index}`" v-model="chart.selected" />
                        <label class="custom-control-label" :for="`${chart.id}${index}`">{{chart.name}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <button class="btn btn-primary" @click="saveDataView">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
a.nav-link {
    color: #409eff;
}

.data {
    .field-group {
        margin-bottom: 0.5rem;
        .custom-control-label {
            padding-top: 3px;
            position: relative;
            &:before{
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                pointer-events: none;
                content: "";
                background-color: #fff;
                border: #adb5bd solid 1px;
                color: #fff;
                border-radius: 0.25rem;
                transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            &:after{
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                content: "";
            }
        }
        .custom-control-input:checked + label{
            &:before{
                border-color: #007bff;
                background-color: #007bff;
            }
            &:after{
                background: no-repeat 50%/50% 50%;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }
    }
    .field-name {
        text-decoration: underline;
        font-weight: 600;
    }
 }   
</style>

<script src="./index.js"></script>