import DealServices from "@/services/deal";
import notify from "@/services/notify";
import filePreview, { parseFileType } from "@/components/commons/filePreview";
import adminServices from "@/services/admin";
import { defineComponent } from "vue";
import DownloadIcon from "@/assets/images/download-black.svg";
import RemoveIcon from "@//assets/images/e-remove.svg";
import Menu8Icon from "@/assets/images/menu-8.svg";

export default defineComponent({
  name: "files-upload",
  props: {
    specifyIdDeal: { type: String, default: null },
    currentStage: { type: Number, default: null },
    stageAllow: { type: Boolean, default: false },
    hideBlock: { type: Function, default: null },
    additionalFiles: { type: Array, default: null },
    reload: { type: Boolean, default: false },
  },
  components: {
    filePreview,
    DownloadIcon,
    RemoveIcon,
    Menu8Icon,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      dataDocument: null,
      url: null,
      imgModal: null,
      fileToken: null,
      previewFileType: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDealDocument();
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.imgModal = null;
    },
    getDealDocument: function () {
      this.loading = true;
      DealServices.getDealDocument(this.specifyIdDeal)
        .then((resp) => {
          if (resp.data.ok) {
            this.dataDocument = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download: function (docId, fileName, useS3Key = false) {
      this.loading = true;
      let forceDownload = function (url, fileName) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.responseType = "blob";
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          var tag = document.createElement("a");
          tag.href = imageUrl;
          tag.download = fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        };
        xhr.send();
      };

      if (useS3Key) {
        adminServices
          .requestDownloadS3(docId)
          .then((resp) => {
            if (resp.data.ok) {
              this.url = resp.data.d;
            }
          })
          .finally(() => {
            this.loading = false;
            forceDownload(this.url, fileName);
          });
      } else {
        DealServices.getDealDocumentFile(this.specifyIdDeal, docId)
          .then((resp) => {
            if (resp.data.ok) {
              this.url = resp.data.d;
            }
          })
          .finally(() => {
            this.loading = false;
            forceDownload(this.url, fileName);
          });
      }

      return false;
    },
    remove: function (docId) {
      this.loading = true;
      DealServices.removeDealDocument(this.specifyIdDeal, docId)
        .then((resp) => {
          if (resp.data.ok) {
            this.getDealDocument();
            notify.success("Document removed!");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showFilePreview: function (token, previewFileType) {
      this.fixedBody();
      this.fileToken = token;
      this.previewFileType = previewFileType;
      this.$refs.filePreview.showModal();
    },
    viewFile: async function (item) {
      const fileType = parseFileType(item.extension);
      if (item.s3key && fileType !== "") {
        this.loading = true;
        const res = await adminServices.requestDownloadS3(item.s3key);
        if (res.data.ok) {
          this.showFilePreview(res.data.d, fileType);
        }
        this.loading = false;
      } else if (item.deal_id && item.deal_document_id) {
        this.loading = true;
        const res = await DealServices.getDealDocumentFile(
          item.deal_id,
          item.deal_document_id
        );
        if (res.data.ok) {
          this.showFilePreview(res.data.d, fileType);
        }
        this.loading = false;
      }
    },
  },
  watch: {
    reload() {
      this.getDealDocument();
    },
  },
});
