import http, { API_ROOT, API_ROOT_AUTH, authHttpConfig } from "./auth-http";
import generic from "../misc/generic";
import refreshTokenUrl from "../misc/refreshTokenUrl";

export class UserInfo extends generic.Entity {
  constructor(data) {
    super(data, {
      //profile
      country: ["cc", generic.String],
      email: ["email", generic.String],
      phone: ["phone", generic.String],
      userType: ["type", generic.String],
      firstName: ["fname", generic.String],
      lastName: ["lname", generic.String],
      referralCode: ["rc", generic.String],
      userId: ["id", generic.Number],
      twoFaEnabled: ["2fa", generic.Boolean],
      photo: ["photo", generic.String],
      is_partner: ["is_partner", generic.Boolean],
      welcome_survey_stt: ["welcome_survey_stt", generic.String],
      signNDA: ["nda_signed", generic.Boolean],
      ndaFormUrl: ["nda_form_url", generic.String],
      ndaSignedRequired: ["nda_sign_required", generic.Boolean],
      allow_referral: ["allow_referral", generic.Boolean],
      phonecarrierid: ["phone_carrier_id", generic.Number],
      birthDate: ["bday", generic.String],
      lpFunds: ["lp_funds", generic.Array],
    });
  }
}

class Prepase2FaResp extends generic.Entity {
  constructor(data) {
    super(data, {
      deepLink: ["uri", generic.String],
      key: ["key", generic.String],
    });
  }
}

class LoginResp extends generic.Entity {
  constructor(data) {
    super(data, {
      country: ["country", generic.Any],
      require2Fa: ["2fa", generic.Boolean],
      validateCode: ["t", generic.String],
      token: ["at", generic.String],
      ate: ["ate", generic.Number],
      refreshToken: ["rt", generic.String],
      rte: ["rte", generic.Number],
      secret: ["secret", generic.String],
      err_code: ["err_code", generic.String],
    });
  }
}

const authService = {
  // register(firstName, lastName, email, password, confirmPassword, nickname, captcha, referrerCode, refSource) {
  //     return http.post(`${API_ROOT}/account/register`, {
  //         firstName,
  //         lastName,
  //         email,
  //         password: password,
  //         confirmPassword: confirmPassword,
  //         nickname,
  //         captcha,
  //         referrerCode,
  //         refSource
  //     })
  // },
  refreshToken(clientId, refreshToken) {
    return http.post(refreshTokenUrl.refreshTokenUrl, {
      clientId,
      refreshToken,
    });
  },
  verifyEmail(email, token, captcha) {
    return http.post(`${API_ROOT}/account/verify-email`, {
      email,
      token,
      captcha,
    });
  },
  sendVerifyEmail(email, captcha) {
    return http.post(`${API_ROOT}/account/resend-confirmation-email`, {
      email,
      captcha,
    });
  },
  login(client_id, grant_type, user_type, email, password, refresh_token) {
    return http
      .post(`${API_ROOT}/auth/token`, {
        client_id,
        grant_type,
        user_type,
        email,
        password,
        refresh_token,
      })
      .then((resp) => resp);
  },
  logout() {
    return http.post(`${API_ROOT_AUTH}/auth/logout`);
  },
  forgotPassword(email) {
    // return http.post(`${API_ROOT_AUTH}/account/forgot-password`, {
    //     email,
    //     captcha
    // })
    return http.post(`${API_ROOT}/account/forgot-password`, {
      email,
    });
  },
  resetPassword(email, code, new_password, confirm_password) {
    return http.post(`${API_ROOT}/account/reset-password`, {
      email,
      code,
      confirmPassword: confirm_password,
      password: new_password,
    });
  },
  changePassword(obj) {
    return http.post(`${API_ROOT}/me/change-password`, {
      currentPassword: obj.currentPassword,
      newPassword: obj.newPassword,
      confirmNewPassword: obj.confirmNewPassword,
    });
  },
  getProfile() {
    return http
      .get(`${API_ROOT}/me`, {
        ...authHttpConfig,
      })
      .then((resp) => {
        resp.data = new UserInfo(resp.data.d);
        return resp;
      });
  },
  updateProfile(obj) {
    return http.post(`${API_ROOT}/me`, obj).then((resp) => resp);
  },
  // 2fa
  verifyLoginWith2Fa(validateCode, code) {
    return http
      .post(`${API_ROOT_AUTH}/auth/login-2fa`, {
        token: validateCode,
        code,
      })
      .then((resp) => {
        // resp.data = new LoginResp(resp.data.d)
        return resp; //#todo return resp
      });
  },
  prepare2Fa() {
    return http.get(`${API_ROOT_AUTH}/2fa/g-authenticator`).then((resp) => {
      // resp.data = new Prepase2FaResp(resp.data.d)
      return resp; //#todo return resp
    });
  },
  enable2Fa(code, password) {
    return http
      .post(`${API_ROOT_AUTH}/2fa/g-authenticator`, {
        code,
        password: password,
      })
      .then((resp) => (!resp.error ? resp.data : resp));
  },
  disable2Fa(code, password) {
    return http
      .delete(`${API_ROOT_AUTH}/2fa/g-authenticator`, {
        code,
        password: password,
      })
      .then((resp) => (!resp.error ? resp.data : resp));
  },
  enable2FaLogin(required2faWhenLogin, verifyCode) {
    return http.post(`${API_ROOT_AUTH}/2fa/login-req`, {
      required2faWhenLogin: required2faWhenLogin,
      verifyCode: verifyCode,
    });
  },
  emailNotify(type, enable) {
    return http.post(`${API_ROOT_AUTH}/me/email-noti`, {
      type: type,
      enable: enable,
    });
  },
};

export default authService;
