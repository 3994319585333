import AdminServices from "../../../services/admin";
import moment from "moment";
import detailChart from "./detaiChart";
import appStore from "../../../store/app";
import notify from "../../../services/notify";
import { router, USER_TYPE } from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      isShowDetailChart: false,
      dataCompany: null,
      companySelected: {
        id: null,
        name: null,
      },
      fromDate: {
        month: moment(new Date()).subtract(11, "months").month(),
        year: moment(new Date()).subtract(11, "months").year(),
      },
      toDate: {
        month: moment(new Date()).month(),
        year: moment(new Date()).year(),
      },
      listDataChart: null,
      dataChart: null,
      dealFounderPath: router.dealFounder.path,
      dealFounderName: router.dealFounder.name,
    };
  },
  components: {
    detailChart,
  },
  computed: {
    profile() {
      return appStore.state.profile;
    },
    isRokker: {
      get() {
        return appStore.state.profile.userType === "Rokker";
      },
      set(value) {
        return value;
      },
    },
    isIDC: {
      get() {
        return appStore.state.profile.userType === "IDC";
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    if (this.profile.userType === USER_TYPE.FOUNDER_POTENTIAL)
      this.$router.push({ path: this.dealFounderPath });

    if (this.isRokker || this.isIDC) {
      this.getPartners();
    }
  },
  methods: {
    async getPartners() {
      const resp = await AdminServices.getPartnerWithPortfolioMetaList();
      if (resp.data.ok) {
        this.dataCompany = resp.data.d;
        if (this.dataCompany.length > 0) {
          this.selectCompany(this.dataCompany[0]);
        }
      }
    },
    selectCompany(item) {
      this.companySelected = {};
      this.companySelected.id = item.id;
      this.companySelected.name = item.cname;
    },
    customFormatter(date) {
      return moment(date).format("MM/YYYY");
    },
    showdetail(item) {
      this.dataChart = item;
      this.isShowDetailChart = true;
    },
    hidedetail() {
      this.isShowDetailChart = false;
      this.dataChart = null;
    },
    validateDateRange() {
      const _from = moment(this.fromDate);
      const fromMonth = _from.month();
      const fromYear = _from.year();

      const _to = moment(this.toDate);
      const toMonth = _to.month();
      const toYear = _to.year();

      const monthDifference = (toYear - fromYear) * 12 + (toMonth - fromMonth);
      return monthDifference >= 0 && monthDifference <= 11;
    },
    getDataChart() {
      if (!this.validateDateRange()) {
        notify.error("Invalid date range. Maximum 12-month range.");
      } else {
        this.loading = true;
        if (this.isRokker || this.isIDC) {
          AdminServices.getChartDataByUserId(this.companySelected.id, {
            from: moment(this.fromDate).format("MM-YYYY"),
            to: moment(this.toDate).format("MM-YYYY"),
          })
            .then((resp) => {
              if (resp.data.ok) {
                this.listDataChart = resp.data.d;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          AdminServices.getChartData({
            from: moment(this.fromDate).format("MM-YYYY"),
            to: moment(this.toDate).format("MM-YYYY"),
          })
            .then((resp) => {
              if (resp.data.ok) {
                this.listDataChart = resp.data.d;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    getExcel() {
      this.loading = true;
      AdminServices.getExcelByUserId(this.companySelected.id, {
        from: moment(this.fromDate).format("MM-YYYY"),
        to: moment(this.toDate).format("MM-YYYY"),
      })
        .then((resp) => {
          if (resp.data.ok) {
            var win = window.open(resp.data.d, "_blank");
            win.focus();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    isRokker(v) {
      if (v) {
        this.getPartners();
      }
    },
    isIDC(v) {
      if (v) {
        this.getPartners();
      }
    },
  },
});
