import { defineComponent } from "vue";
import backToList from "../back-to-list";
import generic from "@/misc/generic";

import adminServices from "@/services/admin";
import infoTab from "../tabs/info-tab";
import attendeeTab from "../tabs/attendee-tab";
import galleryTab from "../tabs/gallery-tab";

export default defineComponent({
  components: {
    backToList,
    infoTab,
    attendeeTab,
    galleryTab,
  },
  data() {
    return {
      loading: false,
      currentTab: null,
      form: {
        eventId: null,
        name: null,
        location: null,
        description: null,
        userTypes: null,
        status: null,
        startDateTime: null,
        endDateTime: null,
      },
      eventInfoData: null,
    };
  },
  provide() {
    return {
      eventInfoData: this.form,
    };
  },
  mounted() {
    if (this.$route.params !== null) {
      this.form.eventId = this.$route.params.id;
      this.getAll(this.form.eventId);
    }

    let tab = generic.getJsonLocalStorage("currentTabEvent");
    if (!tab) {
      this.toTab("infoTab");
    } else {
      this.toTab(tab);
    }
  },
  methods: {
    assignData(data) {
      this.form = Object.assign(this.form, data);
      this.eventInfoData = this.form;
    },
    async getAll(eventId) {
      this.loading = true;
      adminServices
        .getEvent(eventId)
        .then((resp) => {
          if (resp.data.ok) {
            const obj = {
              startDateTime: this.converTZ(
                new Date(resp.data.d.startDateTime)
              ).toString(),
              endDateTime: this.converTZ(
                new Date(resp.data.d.endDateTime)
              ).toString(),
              ...resp.data.d,
            };
            this.assignData(obj);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toTab(id) {
      generic.setJsonLocalStorage("currentTabEvent", id);
      this.currentTab = id;
    },
    converTZ(dateTime) {
      if (dateTime == null) return;
      return new Date(
        dateTime.getTime() +
          dateTime.getTimezoneOffset() * 60 * 1000 -
          4 * 60 * 60 * 1000
      );
    },
  },
});
