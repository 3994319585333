import pdf from '@/components/commons/pdf-viewer'
import appStore from '@/store/app'
import conf from '@/config'
import verifyUser from '../verifyUser'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      isShow: false,
      baseDocsUrl: conf.base_docs_url,
      fileUrl: '',
      errorMsg: '',
      originFileUrl: '',
      itemId: null,
      isShowVerifyUser: false
    }
  },
  components: {
    pdf,
    verifyUser
  },
  computed: {
    scale() {
      return appStore.state.mobile.isMobile ? 'page-width' : 'page-actual'
    },
    fileType() {
      if (this.originFileUrl) {
        return this.getFileType(this.originFileUrl)
      } else {
        return ''
      }
    }
  },
  created() {
    if (this.$route.query.unauthenid) {
      this.itemId = this.$route.query.unauthenid
      this.$nextTick(() => { this.isShowVerifyUser = true })
    }
  },
  methods: {
    handler: function(e) {
      e.preventDefault()
    },
    getFileType(fileLink) {
      let ext_part = fileLink.split('.')[fileLink.split('.').length - 1]
      let ext = ext_part ? ext_part.split('&')[0] : ''
      if (['pdf'].includes(ext)) {
        return 'pdf'
      } else if (['gif', 'jpg', 'jpeg', 'png'].includes(ext)) {
        return 'image'
      } else if (['mp4'].includes(ext)) {
          return 'video'
      } else {
        return ''
      }
    },
    onCompleteVerifyUser(url) {
      this.isShowVerifyUser = false
      this.originFileUrl = url
      this.fileUrl = url + '#toolbar=0'
      this.$nextTick(() => { this.isShow = true })
    }
  }
})