<template>
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col header-section">
        <label class="subject">SESSION</label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <label class="lb_text"
          >An event has one or multiple sessions. Please add at least a
          milestone for your event.</label
        >
      </div>
    </div>

    <div class="row mb-3">
      <div class="col d-flex align-items-center">
        <label class="lb_text me-1">Our event has:</label>
        <div
          class="sp_status text-white"
          v-if="
            form && (form.status === 'CANCELLED' || form.status === 'COMPLETED')
          "
        >
          <span>{{ $filters.getName(milestoneType, milestoneList) }}</span>
        </div>
        <div class="dropdown" v-else>
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ $filters.getName(milestoneType, milestoneList) }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <span
              class="dropdown-item pointer"
              @click="changeMilestoneType('ONE')"
              v-if="milestones.length <= 1"
              >One session</span
            >
            <span
              class="dropdown-item pointer"
              @click="changeMilestoneType('MULTIPLE')"
              >Multiple sessions</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div
          class="milestone"
          v-for="(milestone, index) in milestones"
          :key="index"
        >
          <div class="row">
            <div class="col col-name">
              <label class="lb_text"> Name:</label>
            </div>
            <div class="col col-value">
              <label class="sub_text">{{ milestone.name }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col col-name">
              <label class="lb_text"> Check-in:</label>
            </div>
            <div class="col col-value">
              <label class="sub_text">
                {{ $filters.parseEstTime(milestone.checkInDate) }}
                <span>(EST)</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-name">
              <label class="lb_text"> Start:</label>
            </div>
            <div class="col col-value">
              <label class="sub_text">
                {{ $filters.parseEstTime(milestone.startDate) }}
                <span>(EST)</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-name">
              <label class="lb_text"> End:</label>
            </div>
            <div class="col col-value">
              <label class="sub_text">
                {{ $filters.parseEstTime(milestone.endDate) }}
                <span>(EST)</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col col-name">
              <label class="lb_text"> View file:</label>
            </div>
            <div class="col col-value">
              <a
                class="sub_doc"
                href="#"
                @click.prevent="
                  showPreview(milestone.dataRoomItemId, FileTypes.PDF)
                "
              >
                {{ milestone.dataRoomItemName }}
              </a>
            </div>
          </div>
          <div class="icon mt-3 me-3">
            <a
              v-if="eventInfoData && eventInfoData.status == 'COMPLETED'"
              :href="`/rfm/event/${form.eventId}/scan-qr-code?m=${milestone.milestoneId}`"
              target="_blank"
            >
              <button :loading="loadingMilestone" class="btn btn-success py-2 me-2">
                <i class="fa fa-qrcode align-middle d-flex justify-content-center"></i>
              </button>
            </a>
            <button
              :loading="loadingMilestone"
              @click="editMilestone(milestone)"
              class="btn btn-warning py-2 me-1"
              v-if="
                eventInfoData.status !== 'CANCELLED' &&
                eventInfoData.status !== 'COMPLETED'
              "
            >
              <i
                class="far fa-edit align-middle d-flex justify-content-center"
              ></i>
            </button>
            <button
              :loading="loadingMilestone"
              @click="deleteMilestone(milestone)"
              class="btn btn-danger py-2"
              v-if="
                eventInfoData.status != 'CANCELLED' &&
                eventInfoData.status != 'COMPLETED'
              "
            >
              <i
                class="far fa-trash-alt align-middle d-flex justify-content-center"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1">
      <div
        class="col mb-3"
        v-if="
          form &&
          form.status !== 'CANCELLED' &&
          form.status !== 'COMPLETED' &&
          !(milestones.length === 1 && milestoneType === 'ONE')
        "
      >
        <NewButton :loading="loadingMilestone" @click="addMilestone"
          >Add Session</NewButton
        >
      </div>
      <div v-if="totalItems && totalItems >= paging.size" class="col">
        <ul
          v-if="_paging.length >= 1"
          class="pagination d-flex justify-content-end"
        >
          <li
            v-for="(page, pageI) in _paging"
            :key="`${pageI}-pageI`"
            :class="`page-item ${page.id === currentPage ? 'active' : ''}`"
            @click="pageClick(page)"
          >
            <a class="page-link">
              <span v-if="page.id === 'next'"
                ><i class="fas fa-angle-right"
              /></span>
              <span v-else-if="page.id === 'prev'"
                ><i class="fas fa-angle-left"
              /></span>
              <span v-else>{{ page.id }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <MilestoneModal
      ref="itemModal"
      :key="itemModalKey"
      @refresh="getMilestones"
    />

    <filePreview
      ref="filePreview"
      :fileUrl="preview.fileUrl"
      :fileType="preview.fileType"
    />

    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.subject {
  font-weight: 700;
  color: #f1f1f1;
  margin-bottom: 0;
}
.lb_text {
  display: flex;
  font-weight: 300;
  color: #a7a7a7;
  margin-bottom: 0;
}
.milestone {
  border: 1px solid rgba(213, 213, 213, 0.1);
  position: relative;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  .sub_text {
    width: 70% !important;
    word-break: break-all;
    font-weight: 600;
    color: #f1f1f1;
  }
  label {
    margin-bottom: 1px;
  }
  .col-name {
    flex: 0 0 120px;
    max-width: unset;
  }
  .col-value {
    flex: 1;
    max-width: unset;
  }
  .icon {
    position: absolute;
    right: 10px;
    top: 0px;
    @media screen and (max-width: 1299px) {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}
</style>
