<template>
  <div
    class="adminTitlePage p-2 pe-3"
    v-if="
      currentRouter.name !== 'Survey Detail' &&
      currentRouter.name !== 'Welcome Survey' &&
      currentRouter.name !== 'Review Your Survey'
    "
  >
    <div class="admin-title">
      <i
        v-if="profile.userType !== 'Investor'"
        class="fas fa-bars me-3 pointer control-collapsed"
        @click="toogleAdminSidebar"
      ></i>
      <span class="d-inline-block px-3 pointer go-back" v-else @click="goBack">
        <BackIcon />
      </span>
      <span class="header-title m-0">{{ currentRouter.name }}</span>
    </div>
    <router-link to="/" v-if="profile.userType === 'Investor'">
      <img :src="LogoIcon" width="100" alt="" />
    </router-link>
    <div class="dropdown">
      <a
        class="dropdown-toggle text-black-50"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ fullName }}
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <router-link
          class="dropdown-item"
          :to="profilePath"
          router-link-exact-active
          >{{ profileName }}</router-link
        >
        <a
          v-if="isShowSelectLpFund && profile.userType === 'Investor'"
          class="dropdown-item cursor-pointer"
          style="display: flex; justify-content: space-between; gap: 10px"
          @click.prevent="handleChangeFund"
          ><span>Return to fund list </span>
          <span> &#8594;</span>
        </a>
        <a class="dropdown-item pointer" @click.prevent="logout">Logout</a>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss">
.adminTitlePage {
  .go-back {
    svg {
      path {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

.adminTitlePage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .admin-title {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    .header-title {
      font-size: 1.3rem;
      line-height: 20px;
    }
    .control-collapsed {
      font-size: 1.3rem;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .dropdown {
    position: unset;
  }
  .dropdown-menu {
    background-color: $color-background-tertiary;
    .dropdown-item {
      color: $color-grey;
      &.router-link-active {
        color: #fff;
      }
      &:hover {
        background-color: $color-grey;
        color: $color-dark;
      }
    }
  }
}
</style>
