const showDialog = {
    async confirm(options) {
        let vm = window.vm || null
        if (vm) {
            return await vm.$refs.dialog.confirm(options)
        }
        return false
	},
	async show(options) {
        let vm = window.vm || null
        if (vm) {
            return await vm.$refs.dialog.show(options)
        }
        return false
	}
}

export default showDialog
