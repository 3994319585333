<template>
  <div :class="['side-nav', show ? 'open' : '']">
    <nav class="main-nav">
      <ul>
        <li>
          <router-link exact to="/"
            ><span @click="toggle('/')">Home</span></router-link
          >
        </li>
        <li>
          <router-link exact to="/who-we-are"
            ><span @click="toggle('/who-we-are')">Who We Are</span></router-link
          >
        </li>

        <li>
          <router-link exact to="/our-team"
            ><span @click="toggle('/our-team')">Our Team</span></router-link
          >
        </li>

        <li>
          <router-link exact to="/portfolio"
            ><span @click="toggle('/portfolio')">Portfolio</span></router-link
          >
        </li>

        <li>
          <router-link exact to="/fuel-the-mind"
            ><span @click="toggle('/fuel-the-mind')"
              >Fuel The Mind</span
            ></router-link
          >
        </li>
        <li>
          <router-link exact to="/pitch-us"
            ><span @click="toggle('/pitch-us')">Pitch Us</span></router-link
          >
        </li>

        <template v-if="profile.email">
          <template
            v-if="
              profile.userType === 'Founder' &&
              (profile.welcome_survey_stt === 'NOT_STARTED' ||
                profile.welcome_survey_stt === 'IN_PROGRESS' ||
                profile.welcome_survey_stt === 'REVIEWING' ||
                profile.welcome_survey_stt === 'REJECTED' ||
                profile.welcome_survey_stt === 'COMPLETED')
            "
          >
            <router-link exact :to=welcomeSurveyPath
              ><span @click="toggle(welcomeSurveyPath)">Welcome Survey</span></router-link
            >
            <!-- <a :href="welcomeSurveyPath">Welcome Survey</a> -->
          </template>
          <template v-else-if="profile.userType === 'Founder'">
            <li>
              <a :href="adminProfilePath">Profile</a>
            </li>
          </template>
          <template v-else-if="profile.userType === 'Investor'">
            <li>
              <router-link exact :to=investorPartnerOverview
                ><span @click="toggle(investorPartnerOverview)">Login</span></router-link
              >
              <a :href="investorPartnerOverview">Portal</a>
            </li>
          </template>
          <template v-else>
            <li>
              <router-link exact :to=adminRokkerPath
                ><span @click="toggle(adminRokkerPath)">Admin</span></router-link
              >
              <!-- <a :href="adminRokkerPath">Admin</a> -->
            </li>
          </template>

          <li>
            <a @click.prevent="logout($event)"><span>Logout</span></a>
          </li>
        </template>

        <template v-else>
          <li>
            <router-link exact to="/login"
              ><span @click="toggle('/login')">Login</span></router-link
            >
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
import appStore, { AUTH_ACTIONS } from "@/store/app";
import conf from "@/config";
import router from "@/config/router";

export default {
  name: "TheSidenav",
  props: { show: { type: Boolean, default: false } },
  data() {
    return {
      welcomeSurveyPath: router.welcomeSurvey.path,
      adminProfilePath: router.profile.path,
      investorPartnerOverview: router.investorpartner.path,
      adminRokkerPath: conf.url_admin_rokker,
    };
  },
  computed: {
    fullName() {
      return `${this.profile.firstName} ${this.profile.lastName}`;
    },
    profile() {
      return appStore.state.profile || {};
    },
  },
  methods: {
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
      this.toggle("");
    },
    async toggle(path) {
      if (path !== this.$route.path) {
        window.scrollTo({ top: 0, behavior: "instant" });
      }
      this.$emit("toggle");
    }
  },
};
</script>

<style lang="scss" scoped>
$menu-bg: #fff;
$menu-color: #000;
$menu-z-index: 1100;
.nav-home-style {
  $menu-bg: #fff;
  $menu-color: #000;
}
.side-nav {
  background: $menu-bg;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  // For some sweet fade
  visibility: hidden;
  width: 100%;
  z-index: $menu-z-index;

  &.open {
    opacity: 1;
    visibility: visible;
    transition: all 0.7s ease-in-out;

    @for $i from 1 through 10 {
      li:nth-child(#{$i}) {
        animation-delay: $i * 0.1s;
      }
    }

    li {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeInUp;
    }
  }

  ul {
    &:hover {
      a {
        opacity: 0.5;
      }
    }

    a {
      /* -webkit-transform: translateZ(0); */
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  .main-nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-bottom: 20px;
    }

    a {
      color: $menu-color;
      font-family: "SF Pro";
      display: inline-block;
      font-size: 40px;
      line-height: 42px;
      font-weight: 100;
      text-decoration: none;

      @media (min-width: 768px) {
        font-size: 56px;
        line-height: 58px;
      }

      @media (min-width: 1500px) {
        font-size: 64px;
        line-height: 74px;
      }
      &:first {
        padding-top: 0;
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(40px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
