<template>
  <div class="form-control-2-wrapper">
    <div class="form-control-2">
      <template v-if="textarea">
        <textarea
          type="text"
          v-model="_value"
          :disabled="disabled"
          :id="name"
          :tabindex="tabindex"
          :autocomplete="autocomplete ? '' : 'off'"
          :maxlength="maxLength"
          :index="indexField"
          rows="10"
          :oninput="
            type == 'number'
              ? (this.value = this.value.replace(/[^0-9.]/gi, ''))
              : ''
          "
          class="form-input"
          placeholder="none"
        />
      </template>

      <template v-else>
        <input
          type="text"
          v-model="_value"
          :disabled="disabled"
          :id="name"
          :tabindex="tabindex"
          :autocomplete="autocomplete ? '' : 'off'"
          :maxlength="maxLength"
          :index="indexField"
          :oninput="
            type == 'number'
              ? (this.value = this.value.replace(/[^0-9.]/gi, ''))
              : ''
          "
          class="form-input"
          placeholder="none"
        />
      </template>
      <label for="field" class="form-label"
        >{{ title }}
        <template v-if="fieldRequire"
          ><small class="require">*</small></template
        ></label
      >
    </div>
    <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextField",
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    autocomplete: { type: Boolean, default: false },
    value: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    className: { type: String, default: "" },
    maxLength: { type: Number, default: 100 },
    fieldRequire: { type: Boolean, default: false },
    indexField: { type: Number, default: 0 },
    min: { type: String, default: "" },
    iconPasswordColor: { type: String, default: "black" },
    textarea: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      isShowPassword: false,
    };
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  mounted() {
    var intervalEle = document.getElementById("interval_in_day");
    if (intervalEle !== null)
      intervalEle.addEventListener("blur", (event) => {
        if (event.srcElement._value <= 1) this._value = 1;
      });
    var dayPublicEle = document.getElementById("daypublic");
    if (dayPublicEle !== null)
      dayPublicEle.addEventListener("blur", (event_daypublic) => {
        if (event_daypublic.srcElement._value <= 1) this._value = 1;
      });
    var dayInvestorEle = document.getElementById("dayinvestor");
    if (dayInvestorEle !== null)
      dayInvestorEle.addEventListener("blur", (event_dayinvestor) => {
        if (event_dayinvestor.srcElement._value <= 1) this._value = 1;
      });
    var deathlineEle = document.getElementById("deathline");
    if (deathlineEle !== null)
      deathlineEle.addEventListener("blur", (event_deathline) => {
        if (event_deathline.srcElement._value <= 1) this._value = 1;
      });
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
        this.$emit("handleValue", this.name, val);
      },
    },
    _errorMsg: {
      get() {
        if (this.fieldRequire && this._value) {
          return "";
        }
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  methods: {
    toggleShowPassword() {
      this.isShowPassword = !this.isShowPassword;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-control-2-wrapper {
  padding-bottom: 50px;

  .md-error {
    position: absolute;

    color: #ee6e73;
    font-size: 14px;
  }
}

.form {
  font-family: "SF Pro Text";

  &-control-2 {
    position: relative;
  }

  &-label {
    position: absolute;
    font-family: inherit;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    top: 0.5rem;
    left: 0;
    width: 100%;
    color: #aaaaaa;
    transition: all 0.2s ease;
    z-index: -1;

    @media (min-width: 768px) {
      font-size: 22px;
    }

    .require {
      color: #ee6e73;
    }
  }

  &-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 20px;
    padding: 10px 0;
    display: block;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }

    &:focus ~ .form-label,
    &:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
      top: -0.75rem;
      left: 0;
      font-size: 14px;
      z-index: 9;
      white-space: nowrap;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
