import { defineComponent } from "vue";
import _ from "lodash";

import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import Dialog from "@/components/Dialog";
import { mappingDataPagination } from "@/utils/helpers";
import addEditFund from "./addEditFund";
import addMemberToFund from "./addMemberToFund";

const columnsData = [
  {
    id: "name",
    name: "Name",
    _wordBreak: "break-word",
  },
  // {
  //   id: "description",
  //   name: "Description",
  //   columnClass: "multiple-line",
  //   _wordBreak: "break-word",
  // },
  {
    id: "intro_video",
    name: "Video",
  },
  {
    id: "user_ids",
    name: "Members",
    columnClass: "multiple-line",
  },
  {
    id: "is_enabled",
    name: "Status",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-end",
    columnClass: "text-end action-icon-group",
  },
];

export default defineComponent({
  components: {
    Table,
    Dropdown,
    DropdownItem,
    TextField,
    SelectField,
    Dialog,
    addEditFund,
    addMemberToFund,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredName: "",
      filteredStatus: null,
      columns: columnsData,
      editItemId: null,
      isShowAddEdit: false,
      isShowAddMemberToFund: false,
      displayedItemId: null,
      confirmText: {
        header: "",
        body: "",
      },
      statusList: [
        {
          name: "All",
          code: null,
        },
        {
          name: "Active",
          code: true,
        },
        {
          name: "Inactive",
          code: false,
        },
      ],
      timezone: "America/New_York",
    };
  },
  watch: {
    "paging.page": function (v) {
      if (!v) return;
      debounce(this.getAll);
    },
    filteredStatus: {
      deep: true,
      handler(v) {
        this.setPageDefault();
        debounce(this.getAll);
      },
    },
    filteredName() {
      this.setPageDefault();
      debounce(this.getAll);
    },
    columns: {
      deep: true,
      handler(v) {
        let col = v.find(
          (p) => typeof p.sort === "boolean" && p.sort && p.direction
        );
        this.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAll();
    });
  },
  methods: {
    openDialogToAddMemberToFund(id) {
      this.displayedItemId = id;
      this.$nextTick(() => {
        this.isShowAddMemberToFund = true;
      });
    },
    closeAddMemberToFund() {
      this.isShowAddMemberToFund = false;
    },
    onCompleteAddMemberToFund() {
      notify.success(`Update members successfully`);
      this.closeAddMemberToFund();
      this.getAll();
    },

    openDialogToAddEdit(itemId) {
      this.editItemId = itemId;
      this.$nextTick(() => {
        this.isShowAddEdit = true;
      });
    },
    closeAddEdit() {
      this.isShowAddEdit = false;
      this.editItemId = null;
    },
    onCompleteAddEdit(formType) {
      if (formType) {
        notify.success(`${formType} successfully`);
      }
      this.closeAddEdit();
      this.getAll();
    },
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getLpFunds({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredName: this.filteredName,
        filteredStatus: this.filteredStatus,
      });
      const cvData = mappingDataPagination(
        res.data.d.c,
        res.data.d.p,
        Math.ceil(res.data.d.t / res.data.d.s)
      );
      if (res && res.data && res.data.ok) {
        this.rows = cvData;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    deleteEvent(item) {
      let message = {
        title: "Delete LP Fund",
        body: "Are you sure to delete this LP Fund? This action cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;

          adminServices
            .deleteLpFund(item.lpfund_id)
            .then((resp) => {
              if (resp.data.ok) {
                notify.success("Delete successfully");
                if (item.isLastItemInPage) {
                  this.paging.page = this.paging.page - 1;
                }
                this.getAll();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    handleChangeStatus(item) {
      let message = {
        title: "Change status",
        body: "Are you sure to change status this lp fund?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;

          adminServices
            .changeStatusLpFunds({ ...item, is_enabled: !item.is_enabled })
            .then((resp) => {
              if (resp.data.ok) {
                notify.success("Change status successfully");
                this.getAll();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
