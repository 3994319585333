import _ from "lodash";
import AdminServices from "@/services/admin";
import publicFileDataroom from "@/views/admin/dataroom/publicFileDataroom";
import { LP_MEDIA_TYPES } from "../../../../../misc/constants";
import TextField from "@/components/TextField";
import { defineComponent } from "vue";
import customSelect from "@/components/commons/customSelect";
import TextArea from "@/components/TextArea";

export default defineComponent({
  name: "addEditItem",
  components: {
    publicFileDataroom,
    TextField,
    customSelect,
    TextArea,
  },
  props: {
    id: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  emits: ["completed", "close"],
  data() {
    return {
      type: LP_MEDIA_TYPES.SEEDED_COMPANIES,
      loading: false,
      formType: "",
      item: null,
      defaultItem: {
        name: "",
        active: true,
        type: LP_MEDIA_TYPES.SEEDED_COMPANIES,
        data: {
          Name: "",
          Sector: "",
          Hq: "",
          Description: "",
          DocTitle: "",
          DocId: null,
          FuelNote: "",
          ShowFuelPercentage: false,
          FuelPercentage: 0,
          CoInvestors: "",
          PhotoId: null,
          VideoId: null,
        },
        photoName: "",
        videoName: "",
        docName: "",
        lp_fund_ids: [],
      },
      error: {
        name: false,
        dataName: false,
        dataSector: false,
        dataHq: false,
        dataDescription: "",
        dataDocTitle: false,
        dataDocId: false,
        dataFuelNote: false,
        dataFuelPercentage: false,
        dataCoInvestors: "",
        dataPhotoId: false,
        dataVideoId: false,
        lp_fund_ids: false,
      },
      selectedMediaType: null,
      isShowPublicDataroom: false,
      lpFundOptions: [],
    };
  },
  watch: {
    "item.name": function (v) {
      this.error.name = false;
    },
    "item.data.Name": function (v) {
      this.error.dataName = false;
    },
    "item.data.Sector": function (v) {
      this.error.dataSector = false;
    },
    "item.data.Hq": function (v) {
      this.error.dataHq = false;
    },
    "item.data.Description": function (v) {
      this.error.dataDescription = "";
    },
    // "item.data.DocTitle": function (v) {
    //   this.error.dataDocTitle = false;
    // },
    // "item.data.DocId": function (v) {
    //   this.error.dataDocId = false;
    // },
    // 'item.data.FuelNote': function (v) {
    //   this.error.dataFuelNote = false
    // },
    // 'item.data.FuelPercentage': function (v) {
    //   this.error.dataFuelPercentage = false
    // },
    "item.data.CoInvestors": function (v) {
      this.error.dataCoInvestors = "";
    },
    "item.data.PhotoId": function (v) {
      this.error.dataPhotoId = false;
    },
    "item.data.VideoId": function (v) {
      this.error.dataVideoId = false;
    },
    "item.lp_fund_ids": function (v) {
      this.error.lp_fund_ids = false;
    },
  },
  created() {
    if (this.id) {
      this.formType = "Edit";
      this.getItem(this.id);
    } else {
      this.formType = "Add";
      this.item = _.cloneDeep(this.defaultItem);
    }
    this.getAllLpFunds();
  },
  methods: {
    async getAllLpFunds() {
      const res = await AdminServices.getAllLpFunds();
      if (res.error) return;

      if (res.data.ok) {
        const temp = res.data.d.map((x) => ({
          value: x.lpfund_id,
          text: x.is_enabled ? x.name : `${x.name} (Inactive)`,
        }));
        this.lpFundOptions = temp || [];
      }
    },
    getItem(id) {
      this.loading = true;
      AdminServices.getLPMediaItem(id)
        .then((res) => {
          if (res.data.ok) {
            const temp = {
              ...res.data.d,
              lp_fund_ids: res.data.d.lp_funds.map((x) => Number(x.lp_fund_id)),
            };
            this.item = temp;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    save() {
      let flag = true;
      if (this.item.name.trim() === "") {
        this.error.name = true;
        flag = false;
      }
      if (this.item.data.Name.trim() === "") {
        this.error.dataName = true;
        flag = false;
      }
      if (this.item.data.Sector.trim() === "") {
        this.error.dataSector = true;
        flag = false;
      }
      if (this.item.data.Hq.trim() === "") {
        this.error.dataHq = true;
        flag = false;
      }
      if (this.item.data.Description.trim() === "") {
        this.error.dataDescription = "Please input Description";
        flag = false;
      }
      // if (this.item.data.DocTitle.trim() === "") {
      //   this.error.dataDocTitle = true;
      //   flag = false;
      // }
      // if (!this.item.data.DocId) {
      //   this.error.dataDocId = true;
      //   flag = false;
      // }
      if (this.item.lp_fund_ids.length === 0) {
        this.error.lp_fund_ids = true;
        flag = false;
      }
      // if (this.item.data.FuelNote.trim() === '') {
      //   this.error.dataFuelNote = true
      //   flag = false
      // }
      if (this.item.data.CoInvestors.trim() === "") {
        this.error.dataCoInvestors = "Please input Co-investors";
        flag = false;
      }
      if (!this.item.data.PhotoId) {
        this.error.dataPhotoId = true;
        flag = false;
      }
      if (!this.item.data.VideoId) {
        this.error.dataVideoId = true;
        flag = false;
      }

      if (flag) {
        if (this.formType === "Edit") {
          this.updateItem();
        } else {
          this.createItem();
        }
      }
    },

    createItem() {
      this.loading = true;
      AdminServices.createLPSeededCompanies(this.item)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("completed", {
              id: resp.data.d,
              formType: this.formType,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateItem() {
      this.loading = true;
      AdminServices.updateLPSeededCompanies(this.item)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("completed", {
              id: this.item.id,
              formType: this.formType,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectDataroomItem(type) {
      this.selectedMediaType = type;
      this.$nextTick(() => {
        this.isShowPublicDataroom = true;
      });
    },

    clearDataroomItem(type) {
      switch (type) {
        case "PHOTO":
          this.item.data.PhotoId = null;
          this.item.photoName = '';
          break;
        case "VIDEO":
          this.item.data.VideoId = null;
          this.item.videoName = '';
          break;
        case "DOC":
          this.item.data.DocId = null;
          this.item.docName = '';
          break;
      }
    },

    closeSearchDataroomItem() {
      this.isShowPublicDataroom = false;
      this.selectedMediaType = null;
    },

    completeSelectDataroomItem(data) {
      const { dataroomId, type, name } = data;
      this.closeSearchDataroomItem();
      switch (type) {
        case "PHOTO":
          this.item.data.PhotoId = dataroomId;
          this.item.photoName = name;
          break;
        case "VIDEO":
          this.item.data.VideoId = dataroomId;
          this.item.videoName = name;
          break;
        case "DOC":
          this.item.data.DocId = dataroomId;
          this.item.docName = name;
          break;
      }
    },
  },
});
