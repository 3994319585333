<template>
  <div class="adminContent p-3">
    <Table
      :paging="searchParams.paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="tableData"
      :total-items="searchParams.totalItems"
      :showNoRow="true"
      v-loading="loading"
      class="tableBackendUsers"
    >
      <template #head_name>
        <TextField
          :autocomplete="false"
          v-model:value="searchParams.filteredName"
        />
      </template>

      <template #head_email>
        <TextField
          :autocomplete="false"
          v-model:value="searchParams.filteredEmail"
        />
      </template>

      <template #head_status>
        <select
          class="form-control form-control-sm custom-select"
          v-model="searchParams.filteredStatus"
          @change="doFilter"
        >
          <option
            v-for="(item, idx) in StatusList"
            :key="idx"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
      </template>

      <template #head_role>
        <select
          class="form-control form-control-sm custom-select"
          v-model="searchParams.filteredRole"
          @change="doFilter"
        >
          <option v-for="(item, idx) in roleList" :key="idx" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </template>

      <template #row_name="{ props }">
        <span>{{ props.name }}</span>
      </template>

      <template #row_email="{ props }">
        <span>{{ props.email }}</span>
      </template>

      <template #row_role="{ props }">
        <span class="nowrap">{{ getName(roleList, props.role, "id") }}</span>
      </template>

      <template #row_status="{ props }">
        <span>{{ getName(StatusList, props.stt) }}</span>
      </template>

      <template #row_actions="{ props }">
        <div class="nowrap">
          <button
            type="button"
            class="btn btn-primary btn-small me-1"
            @click="editUserInfo(props)"
          >
            <i class="far fa-edit"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-small me-1"
            :class="{
              'btn-warning': props.stt === 'ACTIVE',
              'btn-danger': props.stt === 'INACTIVE',
            }"
            @click="openModalConfirm('status', props)"
            :title="props.stt === 'INACTIVE' ? 'Activate' : 'Inactivate'"
          >
            <i class="fas fa-lock" v-if="props.stt === 'INACTIVE'"></i>
            <i class="fas fa-lock-open" v-else></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-small"
            @click="openModalConfirm('delete', props)"
            v-if="hasPermission(PERMISSIONS.Users_Delete)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-primary btn-add" @click="showAddUser">
          <i class="fas fa-plus me-1"></i>
          <span class="text">Add User</span>
        </button>
      </template>
    </Table>

    <Dialog ref="dialog"></Dialog>

    <addMember
      :hideAddUser="hideAddUser"
      :dataInfo="dataInfo"
      :addNewUser="addNewUser"
      :updateUser="updateUser"
      :roleList="roleList"
      v-if="isShowAddUser"
      :userTypeList="userTypeList"
      :carrierPhoneList="carrierPhoneList"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.tableBackendUsers {
  td {
    span {
      line-height: 46px;
    }
  }
}
.sort-field {
  cursor: pointer;
  i {
    color: #dddddd;
    &.active {
      color: #409eff;
    }
  }
}
.admin-table {
  .status {
    flex: 0 0 120px !important;
  }
}
.table thead th {
  vertical-align: top !important;
}
.table tbody tr td {
  border-bottom: 1px solid #484c5a !important;
}
#paging.paging {
  text-align: center;
  margin-right: -100px;
}
.itemUser,
.itemUser.head {
  border-bottom: 1px solid #484c5a;
  span {
    color: #949ba2;
    line-height: 46px;
  }
  .no {
    flex: 0 0 55px;
  }
  .name,
  .email {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .role {
    flex: 0 0 170px;
  }
  .status {
    flex: 0 0 110px;
  }
  .action {
    flex: 0 0 150px;
    display: inline-flex;
    align-items: center;
    button {
      vertical-align: middle;
      margin-right: 4px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.itemUser.head {
  span {
    color: #fff;
  }
  .action {
    align-items: start;
  }
}
.custom-select {
  border-radius: 0;
  max-height: 34px;
}
</style>
