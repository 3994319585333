import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import topHeader from '../topHeader'
import { defineComponent } from "vue";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);

export default defineComponent({
    data() {
        return {
        }
    },
    mounted() {
        this.mapChart()
        this.regionDistribution()
        this.stageDistribution()
    },
    methods: {
        regionDistribution() {
            const chart = am4core.create('regionchart', am4charts.PieChart)
            chart.data = [
                {
                    'distribution': 'West Coast of the U.S.',
                    'amount': 3,
                },
                {
                    'distribution': 'East Coast of the U.S.',
                    'amount': 11,
                },
                {
                    'distribution': 'Europe',
                    'amount': 6,
                },
                {
                    'distribution': 'LATAM',
                    'amount': 1,
                },
            ]

            chart.innerRadius = am4core.percent(60)

            const label = chart.seriesContainer.createChild(am4core.Label);
            label.text = "Regional\nDistribution";
            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            label.textAlign = 'middle'
            label.color = 'white'
            label.fontSize = 8;

            const pieSeries = chart.series.push(new am4charts.PieSeries())
            pieSeries.dataFields.value = 'amount'
            pieSeries.dataFields.category = 'distribution'
            pieSeries.dataFields.fontSize = 10

            pieSeries.slices.template.stroke = am4core.color('#fff')
            pieSeries.slices.template.strokeWidth = 0
            pieSeries.slices.template.strokeOpacity = 1

            pieSeries.slices.template.fontSize = 14

            pieSeries.hiddenState.properties.opacity = 1
            pieSeries.hiddenState.properties.endAngle = -90
            pieSeries.hiddenState.properties.startAngle = -90

            pieSeries.fontSize = 8
            pieSeries.fixedWidthGrid = true

            pieSeries.tooltip.fixedWidthGrid = true
            pieSeries.tooltip.fontSize = 14
        },
        stageDistribution() {
            const vue = this
            const chart = am4core.create('stagechart', am4charts.PieChart)

            chart.data = [
                {
                    'Serie': '<Series A',
                    'amount': 7,
                }, {
                    'Serie': 'Series A-Series B',
                    'amount': 11,
                }, {
                    'Serie': '>Series B',
                    'amount': 3,
                },
            ]

            chart.innerRadius = am4core.percent(60)

            const label = chart.seriesContainer.createChild(am4core.Label);
            label.text = "Stage\nDistribution";
            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            label.textAlign = 'middle'
            label.color = 'white'
            label.fontSize = 8;

            const pieSeries = chart.series.push(new am4charts.PieSeries())
            pieSeries.dataFields.value = 'amount'
            pieSeries.dataFields.category = 'Serie'
            pieSeries.dataFields.fontSize = 10

            pieSeries.slices.template.stroke = am4core.color('#fff')
            pieSeries.slices.template.strokeWidth = 0
            pieSeries.slices.template.strokeOpacity = 1

            pieSeries.slices.template.fontSize = 14

            pieSeries.hiddenState.properties.opacity = 1
            pieSeries.hiddenState.properties.endAngle = -90
            pieSeries.hiddenState.properties.startAngle = -90

            pieSeries.fontSize = 8
            pieSeries.fixedWidthGrid = true

            pieSeries.tooltip.fixedWidthGrid = true
            pieSeries.tooltip.fontSize = 14
        },
        mapChart() {

            let sliderAnimation
            const countryColor = am4core.color("#3b3b3b");
            const countryStrokeColor = am4core.color("#000000");
            const backgroundColor = am4core.color("#1e2128");

            // BOTTOM CHART
            // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
            const lineChart = chartAndSliderContainer.createChild(am4charts.XYChart);
            lineChart.fontSize = "0.8em";
            lineChart.paddingRight = 30;
            lineChart.paddingLeft = 30;
            lineChart.maskBullets = false;
            lineChart.zoomOutButton.disabled = true;
            lineChart.paddingBottom = 5;
            lineChart.paddingTop = 3;

            // make a copy of data as we will be modifying it
            lineChart.data = JSON.parse(JSON.stringify(slideData));

            // main container
            // https://www.amcharts.com/docs/v4/concepts/svg-engine/containers/
            const container = am4core.create("mapchart", am4core.Container);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);

            container.tooltip = new am4core.Tooltip();
            container.tooltip.background.fill = am4core.color("#000000");
            container.tooltip.fontSize = "0.9em";
            container.tooltip.getFillFromObject = false;
            container.tooltip.getStrokeFromObject = false;

            // MAP CHART
            // https://www.amcharts.com/docs/v4/chart-types/map/
            const mapChart = container.createChild(am4maps.MapChart);
            mapChart.height = am4core.percent(100);

            // https://www.amcharts.com/docs/v4/chart-types/map/#Map_data
            // you can use more accurate world map or map of any other country - a wide selection of maps available at: https://github.com/amcharts/amcharts4-geodata
            mapChart.geodata = am4geodata_worldLow;

            const data = [
                {
                    "date": "2017-04-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Taxfyle",
					"amount": 3775000,
                },
                {
                    "date": "2017-08-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "AdMobilize",
					"amount": 3540000,
                },
                {
                    "date": "2018-03-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Rokk3r",
					"amount": 766507,
                },
                {
					"date": "2018-05-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "So Stereo",
					"amount": 700000,
				},
				{
					"date": "2018-07-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Unitea (Joox)",
					"amount": 550000,
                },
                {
					"date": "2018-07-01",
                    "location": "Washington, DC",
                    "latitude": 47.751076,
                    "longitude": -120.740135,
                    "name": "Eyrus",
					"amount": 1250000,
				},
				{
					"date": "2018-07-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Bolt",
					"amount": 7250000,
                },
                {
					"date": "2019-07-01",
                    "location": "Los Angeles, CA",
                    "latitude": 34.05223,
                    "longitude": -118.24368,
                    "name": "Emerge",
					"amount": 1000000,
				},
				{
					"date": "2019-09-01",
                    "location": "Santa Clara, CA",
                    "latitude": 37.35411,
                    "longitude": -121.95524,
                    "name": "OhmniLabs",
					"amount": 1000000,
                },
                {
					"date": "2019-10-01",
                    "location": "Stockholm, SE",
                    "latitude": 59.33258,
                    "longitude": 18.0649,
                    "name": "Soundtrack",
					"amount": 3000000,
				},
				{
					"date": "2019-12-01",
                    "location": "Tysons Corner, VA",
                    "latitude": 38.917130,
                    "longitude": -77.222237,
                    "name": "PredaSAR",
					"amount": 5000000,
                },
                {
					"date": "2019-12-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Matrix",
					"amount": 0,
				},
				{
					"date": "2019-12-01",
                    "location": "England, UK",
                    "latitude": 51.209000,
                    "longitude": -2.647000,
                    "name": "Curve",
					"amount": 560000,
                },
                {
					"date": "2019-12-01",
                    "location": "London, UK",
                    "latitude": 51.5002,
                    "longitude": -0.1262,
                    "name": "Brandhouse",
					"amount": 280000,
				},
				{
					"date": "2019-12-01",
                    "location": "Miami, FL",
					"latitude": 25.77427,
                    "longitude": -80.19366,
                    "name": "Boatsetter",
					"amount": 252000,
                },
                {
					"date": "2019-12-01",
                    "location": "London, UK",
                    "latitude": 51.5002,
                    "longitude": -0.1262,
                    "name": "Evrythng",
					"amount": 298200,
				},
				{
					"date": "2019-12-01",
                    "location": "New York, NY",
					"latitude": 40.730610,
					"longitude": -73.935242,
                    "name": "Cook Unity",
					"amount": 196000,
                },
                {
					"date": "2019-12-01",
                    "location": "Sao Paulo, BR",
                    "latitude": -23.533773,
                    "longitude": -46.625290,
                    "name": "Recargapay",
					"amount": 175840,
				},
				{
					"date": "2019-12-01",
                    "location": "London, UK",
                    "latitude": 51.5002,
                    "longitude": -0.1262,
                    "name": "Hastee",
					"amount": 170800,
                },
                {
					"date": "2019-12-01",
                    "location": "Madrid, ES",
                    "latitude": 40.4165,
                    "longitude": -3.70256,
                    "name": "Bipi",
					"amount": 201880,
				},
				{
					"date": "2019-12-01",
                    "location": "San Francisco, CA",
                    "latitude": 37.773972,
                    "longitude": -122.431297,
                    "name": "Tradeshift",
					"amount": 143046,
                },
            ]

            // Accepts the array and key
            const groupBy = (array, key) => {
                // Return the end result
                return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
                }, {}); // empty object is the initial value for result object
            };

            const sum = (items, prop) => {
                return items.reduce( function(a, b){
                    return a + b[prop];
                }, 0);
            }

            const dateGroup = groupBy(data, 'date');
            const locationGroup = groupBy(data, 'location');

            let pre_obj = undefined
            const $data = []
            for (const [key, value] of Object.entries(dateGroup)) {
                const obj = {}
                obj.date = key
                obj.number_of_startup = value.length + (pre_obj ? pre_obj.number_of_startup : 0)
                obj.total_amount = sum(value, 'amount') + (pre_obj ? pre_obj.total_amount : 0)
                const list = []
                for (const [_key, _value] of Object.entries(locationGroup)) {
                    const $obj = {}
                    $obj.location = _key
                    $obj.latitude = _value[0].latitude
                    $obj.longitude = _value[0].longitude
                    $obj.value = (_value.filter(item => item.date == key)).length + (pre_obj ? (pre_obj.list.filter(i => i.location == _key))[0].value : 0)
                    list.push($obj)
                }
                obj.list = list
                $data.push(obj)

                pre_obj = obj
            }

            const slideData = $data;
            function getSlideData(index) {
                if (index == undefined) {
                  index = slideData.length - 1;
                }
                const data = slideData[index];
                return data;
            }

            let maxValue = 0
            slideData.forEach(item => {
                let max = 0
                item.list.forEach(location => {
                    if (location.value > max) {
                        max = location.value
                    }
                })

                if (max > maxValue) {
                    maxValue = max
                }
            })

            // update map data
            const updateMapData = (data) => {
                for (let i = 0; i < data.length; i++) {
                    const di = data[i];
                    const image = getObjectByName(bubbleSeries.mapImages, di.location);
                    //let polygon = getObjectByName(polygonSeries.mapPolygons, di.name);

                    if (image) {
                        image.dataItem.dataContext.value = di.value;
                    }

                    // if (polygon) {
                    //     polygon.dataItem.dataContext.value = di.value;
                    // }
                }

                bubbleSeries.invalidateRawData();
                //polygonSeries.invalidateRawData();
            }

            const mapData = JSON.parse(JSON.stringify(getSlideData().list));
            // Set projection
            mapChart.projection = new am4maps.projections.Miller();
            mapChart.panBehavior = "move";

            // Create map polygon series
            const polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
            // polygonSeries.dataFields.id = "name";
            // polygonSeries.dataFields.value = "value";
            // polygonSeries.interpolationDuration = 0;
            polygonSeries.exclude = ["AQ"];
            polygonSeries.useGeodata = true;
            polygonSeries.nonScalingStroke = true;
            polygonSeries.stroke = am4core.color("#000000");
            polygonSeries.strokeWidth = 0.5;
            // this helps to place bubbles in the visual middle of the area
            polygonSeries.calculateVisualCenter = true;
            //polygonSeries.data = mapData;

            // Configure series
            const polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.tooltipText = "{location}";
            polygonTemplate.fill = countryColor;
            polygonTemplate.fillOpacity = 1
            polygonTemplate.stroke = countryStrokeColor;
            polygonTemplate.strokeOpacity = 0.15
            polygonTemplate.setStateOnChildren = true;
            polygonTemplate.tooltipPosition = "fixed";

            // polygonSeries.heatRules.push({
            //     "target": polygonTemplate,
            //     "property": "fill",
            //     "min": am4core.color("#3b3b3b"),
            //     "max": am4core.color("#3b3b3b"),
            //     "dataField": "value"
            //   })

            // Create hover state and set alternative fill color
            const hs = polygonTemplate.states.create("hover");
            hs.properties.fill = mapChart.colors.getIndex(0);

            let bubbleSeries = mapChart.series.push(new am4maps.MapImageSeries());
            bubbleSeries.data = mapData;
            bubbleSeries.dataFields.value = "value";
            bubbleSeries.dataFields.id = "location";
            bubbleSeries.mapImages.template.propertyFields.longitude = "longitude";
            bubbleSeries.mapImages.template.propertyFields.latitude = "latitude";

            bubbleSeries.tooltip.animationDuration = 0;
            bubbleSeries.tooltip.showInViewport = false;
            bubbleSeries.tooltip.background.fillOpacity = 0.2;
            bubbleSeries.tooltip.getStrokeFromObject = true;
            bubbleSeries.tooltip.getFillFromObject = false;
            bubbleSeries.tooltip.background.fillOpacity = 0.2;
            bubbleSeries.tooltip.background.fill = am4core.color("#000000");

            const imageTemplate = bubbleSeries.mapImages.template;
            imageTemplate.nonScaling = true

            const circle = imageTemplate.createChild(am4core.Circle);
            // this makes the circle to pulsate a bit when showing it
            circle.hiddenState.properties.scale = 0.0001;
            circle.hiddenState.transitionDuration = 2000;
            circle.defaultState.transitionDuration = 2000;
            circle.defaultState.transitionEasing = am4core.ease.elasticOut;
            // later we set fill color on template (when changing what type of data the map should show)
            // and all the clones get the color because of this
            circle.applyOnClones = true;
            circle.tooltipText = `{location} has {value} startup(s)`;

            bubbleSeries.heatRules.push({
                "target": circle,
                "property": "radius",
                "min": 3,
                "max": 20,
                "dataField": "value",
                "maxValue": maxValue,
            })
            // when data items validated, hide 0 value bubbles (because min size is set)
            bubbleSeries.events.on("dataitemsvalidated", function() {
                bubbleSeries.dataItems.each((dataItem) => {
                    const mapImage = dataItem.mapImage;
                    const circle = mapImage.children.getIndex(0);
                    if (mapImage.dataItem.value == 0) {
                        circle.hide(0);
                    } else if (circle.isHidden || circle.isHiding) {
                        circle.show();
                    }
                })
            })

            // Chart & slider container
            const chartAndSliderContainer = container.createChild(am4core.Container);
            chartAndSliderContainer.layout = "vertical";
            chartAndSliderContainer.height = am4core.percent(40);
            chartAndSliderContainer.width = am4core.percent(100);
            chartAndSliderContainer.background = new am4core.RoundedRectangle();
            chartAndSliderContainer.background.fill = am4core.color("#000000");
            chartAndSliderContainer.background.cornerRadius(30, 30, 0, 0)
            chartAndSliderContainer.background.fillOpacity = 0.25;
            chartAndSliderContainer.paddingTop = 12;
            chartAndSliderContainer.paddingBottom = 0;
            chartAndSliderContainer.valign = 'bottom';

            // Slider container
            const sliderContainer = chartAndSliderContainer.createChild(am4core.Container);
            sliderContainer.width = am4core.percent(100);
            sliderContainer.padding(0, 15, 15, 10);
            sliderContainer.layout = "horizontal";

            const slider = sliderContainer.createChild(am4core.Slider);
            slider.width = am4core.percent(100);
            slider.valign = "middle";
            slider.background.opacity = 0.4;
            slider.opacity = 0.7;
            slider.background.fill = am4core.color("#ffffff");
            slider.marginLeft = 20;
            slider.marginRight = 35;
            slider.height = 15;
            slider.start = 1;

            // what to do when slider is dragged
            slider.events.on("rangechanged", function(event) {
                const index = Math.round((slideData.length - 1) * slider.start);
                updateMapData(getSlideData(index).list);
                updateTotals(index);
            })
            // stop animation if dragged
            slider.startGrip.events.on("drag", () => {
                stop();
                if (sliderAnimation) {
                    sliderAnimation.setProgress(slider.start);
                }
            });

            // play button
            const playButton = sliderContainer.createChild(am4core.PlayButton);
            playButton.valign = "middle";
            // play button behavior
            playButton.events.on("toggled", function(event) {
                if (event.target.isActive) {
                    play();
                } else {
                    stop();
                }
            })
            // make slider grip look like play button
            slider.startGrip.background.fill = playButton.background.fill;
            slider.startGrip.background.strokeOpacity = 0;
            slider.startGrip.icon.stroke = am4core.color("#ffffff");
            slider.startGrip.background.states.copyFrom(playButton.background.states)

            // play behavior
            function play() {
                if (!sliderAnimation) {
                    sliderAnimation = slider.animate({ property: "start", to: 1, from: 0 }, 50000, am4core.ease.linear).pause();
                    sliderAnimation.events.on("animationended", () => {
                        playButton.isActive = false;
                    })
                }

                if (slider.start >= 1) {
                    slider.start = 0;
                    sliderAnimation.start();
                }
                sliderAnimation.resume();
                playButton.isActive = true;
            }

            // stop behavior
            function stop() {
                if (sliderAnimation) {
                    sliderAnimation.pause();
                }
                playButton.isActive = false;
            }

            function getObjectByName(list, location) {
                let data;
                for (let i = 0; i < list.length; i++) {
                    if (list.getIndex(i).dataItem.dataContext.location == location) {
                        data = list.getIndex(i);
                    }
                }
                return data
            }

            // const series = { active: activeSeries, confirmed: confirmedSeries };
            // add series
            function addSeries(name, color) {
                const series = lineChart.series.push(new am4charts.LineSeries())
                series.dataFields.valueY = name;
                series.dataFields.dateX = "date";
                series.name = name == 'total_amount' ? 'Fuel Investment' : 'Number of Startups';
                series.strokeOpacity = 0.6;
                series.stroke = color;
                series.fill = color;
                series.maskBullets = false;
                series.minBulletDistance = 10;
                series.hidden = true;
                series.hideTooltipWhileZooming = true;


                // series bullet
                const bullet = series.bullets.push(new am4charts.CircleBullet());

                // only needed to pass it to circle
                const bulletHoverState = bullet.states.create("hover");
                bullet.setStateOnChildren = true;

                bullet.circle.fillOpacity = 1;
                bullet.circle.fill = backgroundColor;
                bullet.circle.radius = 2;

                const circleHoverState = bullet.circle.states.create("hover");
                circleHoverState.properties.fillOpacity = 1;
                circleHoverState.properties.fill = color;
                circleHoverState.properties.scale = 1.4;

                // tooltip setup
                series.tooltip.pointerOrientation = "down";
                series.tooltip.getStrokeFromObject = true;
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.fillOpacity = 0.2;
                series.tooltip.background.fill = am4core.color("#000000");
                series.tooltip.dy = -4;
                series.tooltip.fontSize = "0.8em";
                series.tooltipText = "{name}: " + (name == 'total_amount' ? '$' : '') + "{valueY}";

                return series;
            }

            const lastDate = new Date(slideData[slideData.length - 1].date);
            let currentDate = lastDate;
            const activeColor = am4core.color("#ff8726");
            const confirmedColor = am4core.color("#d21a1a");
            let currentIndex;

            // date axis
            // https://www.amcharts.com/docs/v4/concepts/axes/date-axis/
            const dateAxis = lineChart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 50;
            dateAxis.renderer.grid.template.stroke = am4core.color("#000000");
            dateAxis.renderer.grid.template.strokeOpacity = 0.25;
            dateAxis.max = lastDate.getTime() + am4core.time.getDuration("day", 5);
            dateAxis.tooltip.label.fontSize = "0.8em";
            dateAxis.tooltip.background.fill = activeColor;
            dateAxis.tooltip.background.stroke = activeColor;
            dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");

            // value axis
            // https://www.amcharts.com/docs/v4/concepts/axes/value-axis/
            const valueAxis = lineChart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.opposite = true;
            valueAxis.interpolationDuration = 3000;
            valueAxis.renderer.grid.template.stroke = am4core.color("#000000");
            valueAxis.renderer.grid.template.strokeOpacity = 0.25;
            valueAxis.renderer.minGridDistance = 30;
            valueAxis.renderer.maxLabelPosition = 0.98;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.tooltip.disabled = true;
            valueAxis.extraMax = 0.05;
            valueAxis.maxPrecision = 0;
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.verticalCenter = "bottom";
            valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
            valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
            valueAxis.adapter.add("max", function (max, target) {
                if (max < 5) {
                    max = 5
                }
                return max;
            })

            valueAxis.adapter.add("min", function (min, target) {
                return min;
            })

            // cursor
            // https://www.amcharts.com/docs/v4/concepts/chart-cursor/
            lineChart.cursor = new am4charts.XYCursor();
            lineChart.cursor.maxTooltipDistance = 0;
            lineChart.cursor.behavior = "none"; // set zoomX for a zooming possibility
            lineChart.cursor.lineY.disabled = true;
            lineChart.cursor.lineX.stroke = activeColor;
            lineChart.cursor.xAxis = dateAxis;
            // this prevents cursor to move to the clicked location while map is dragged
            am4core.getInteraction().body.events.off("down", lineChart.cursor.handleCursorDown, lineChart.cursor)
            am4core.getInteraction().body.events.off("up", lineChart.cursor.handleCursorUp, lineChart.cursor)

            // legend
            // https://www.amcharts.com/docs/v4/concepts/legend/
            lineChart.legend = new am4charts.Legend();
            lineChart.legend.parent = lineChart.plotContainer;
            lineChart.legend.labels.template.fill = am4core.color("#ffffff");
            lineChart.legend.markers.template.height = 8;
            lineChart.legend.contentAlign = "left";
            lineChart.legend.fontSize = "10px";
            lineChart.legend.itemContainers.template.valign = "middle";
            let legendDown = false;
            lineChart.legend.itemContainers.template.events.on("down", function () {
                legendDown = true;
            })
            lineChart.legend.itemContainers.template.events.on("up", function () {
                setTimeout(function () {
                    legendDown = false;
                }, 100)
            })
            // create series
            const activeSeries = addSeries("total_amount", activeColor);
            // active series is visible initially
            activeSeries.tooltip.disabled = true;
            activeSeries.hidden = false;
            const confirmedSeries = addSeries("number_of_startup", confirmedColor);
            lineChart.plotContainer.events.on("up", function () {
                if (!legendDown) {
                    slider.start = lineChart.cursor.xPosition * ((dateAxis.max - dateAxis.min) / (lastDate.getTime() - dateAxis.min));
                }
            })

            setTimeout(updateSeriesTooltip, 3000);

            function updateSeriesTooltip() {

                let position = dateAxis.dateToPosition(currentDate);
                position = dateAxis.toGlobalPosition(position);
                const x = dateAxis.positionToCoordinate(position);
                lineChart.cursor.triggerMove({ x: x, y: 0 }, "soft", true);
                lineChart.series.each(function (series) {
                    if (!series.isHidden) {
                        series.tooltip.disabled = false;
                        series.showTooltipAtDataItem(series.tooltipDataItem);
                    }
                })
            }

            // update total values in buttons
            function updateTotals(index) {
                if (!isNaN(index)) {
                    const di = slideData[index];
                    const date = new Date(di.date);
                    currentDate = date;

                    //updateCountryName();

                    let position = dateAxis.dateToPosition(date);
                    position = dateAxis.toGlobalPosition(position);
                    const x = dateAxis.positionToCoordinate(position);

                    if (lineChart.cursor) {
                        lineChart.cursor.triggerMove({ x: x, y: 0 }, "soft", true)
                    }

                    currentIndex = index;
                }
            }
        },
    },
    components: {
        topHeader,
    },
})