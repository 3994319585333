import { defineComponent } from "vue";
import authHttp from "../../../services/auth-http";
import adminServices from "../../../services/admin";
import DealServices from "../../../services/deal";
import { DEAL_STAGES } from "../../../misc/constants";
import Table, { debounce } from "../../../components/Table";
import TextField from "../../../components/TextField";
import SelectField from "../../../components/SelectField";
import Dragger from "../../../components/Dragger";
import DealAddUpdateModal from "../../../components/deal/DealAddUpdateModal";
import generic from "../../../misc/generic";
import appStore from "../../../store/app";

export default defineComponent({
  components: {
    Table,
    TextField,
    DealAddUpdateModal,
    Dragger,
    SelectField,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      tab_active: 0,
      name: "",
      createdUserId: "",
      sortField: "",
      stageList: [],
      dealAddUpdateModalKey: 0,
      paramSearch: null,
      dataDeal: null,
      tab_active: 0,
      columns: [],
      tabs: [
        {
          isDraft: false,
          name: "All",
          query: function () {
            return {};
          },
        },
        {
          isDraft: true,
          name: "Draft",
          query: function () {
            return { isDraft: true };
          },
        },
      ],
      page: {
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
  watch: {
    "page.page": function (v) {
      debounce(this.getAll);
    },
    name(v) {
      this.page.page = 1;
      debounce(this.getAll);
    },
  },
  mounted() {
    if (appStore.state.profile && appStore.state.profile.userId > 0) {
      this.createdUserId = appStore.state.profile.userId;
      debounce(this.getAll);
    }
    this.$nextTick(() => {
      this.renderColumn();
      if (this.$route.query) {
        this.name = this.$route.query.name || "";
        this.paramSearch = this.tabs[this.tab_active].query();
      }
      debounce(this.getAll);
    });
  },
  methods: {
    renderColumn() {
      if (this.tabs[this.tab_active].isDraft) {
        this.columns = [
          {
            id: "name",
            name: "Name",
          },
          {
            id: "actions",
            name: "Actions",
            columnClass: "action-icon-group",
          },
        ];
      } else {
        this.columns = [
          {
            id: "name",
            name: "Name",
          },
          {
            id: "status",
            name: "Status",
          },
          {
            id: "actions",
            name: "Actions",
            columnClass: "action-icon-group",
          },
        ];
      }
    },
    getStageNameById(stage_id) {
      const item = DEAL_STAGES.find((x) => x.id == stage_id);
      return item && item.name ? item.name : null;
    },
    async getAll() {
      this.loading = true;
      let params = {
        name: this.name,
        isDraft: this.tabs[this.tab_active].isDraft,
        paging: {
          page: this.page.page,
          size: this.page.size,
        },
        createdUserId: this.createdUserId,
      };
      const res = await adminServices.getDealFounder(params);
      if (res && res.data && res.data.ok) {
        this.page.size = res.data.d.s;
        this.page.page = res.data.d.p;
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      if (generic.getJsonSessionStorage("deal-query"))
        generic.removeSessionStorage("deal-query");
      generic.setJsonSessionStorage("deal-query", {
        PageNumber: params.paging.page,
        PageSize: params.paging.size,
        ...params,
      });
      this.setParams({
        PageNumber: params.paging.page,
        PageSize: params.paging.size,
        ...params,
      });
      this.loading = false;
    },
    async view(props) {
      if (this.tabs[this.tab_active].isDraft) {
        DealServices.getSpecifyDeals(props.deal_id)
          .then((resp) => {
            if (resp.data.ok) {
              this.dataDeal = resp.data.d;
            }
          })
          .finally(() => {
            this.dealAddUpdateModalKey++;
            this.$nextTick(() => {
              this.$refs.dealAddUpdateModal.showUpdate(this.dataDeal);
            });
          });
      } else {
        this.$router.push({ path: `/rfm/deals/${props.deal_id}` });
      }
    },
    setParams(data) {
      let params = {
        name: data.name,
        isDraft: data.isDraft,
        PageNumber: data.PageNumber,
        PageSize: data.PageSize,
      };
      params = Object.assign(params, this.paramSearch);
      window.history.replaceState(
        {},
        document.querySelector("title").text,
        `${this.$router.currentRoute.value.path}?${authHttp.buildParams(
          params
        )}`
      );
    },
    async addDeal() {
      this.dealAddUpdateModalKey++;
      this.$nextTick(() => {
        this.$refs.dealAddUpdateModal.showAdd();
      });
    },
    changeTab: function (idx) {
      this.tab_active = idx;
      this.filteredUserType = this.tabs[idx].type;
      this.renderColumn();
      this.rows = [];
      debounce(this.getAll);
    },
    updatePaging(v) {
      this.page = Object.assign(this.page, v);
    },
  },
});
