<template>
  <Table
    :paging="paging"
    @updatePaging="updatePaging"
    :columns="columns"
    :rows="rows"
    :total-items="totalItems"
    v-loading="loading"
    :customRowsClass="`pointer`"
    @rowClick="rowClick"
  >
    <template #head_name>
      <TextField
        placeholder="Type to search .."
        hideError
        v-model:value="filteredName"
      />
    </template>
    <template #head_subject>
      <TextField
        placeholder="Type to search .."
        hideError
        v-model:value="filteredSubject"
      />
    </template>
    <template #row_checkbox="{ props }">
      <i
        class="far fa-check-circle"
        v-if="_value && _value.id && _value.id == props.id"
      ></i>
      <i class="fas fa-circle" v-else></i>
    </template>
    <template #row_subject="{ props }">
      {{ truncateText(props.subject, 50) }}
    </template>
  </Table>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.fa-check-circle{
  color: unset;
}
</style>
