<template>
  <Modal title="Add Reviewer" v-model:value="isShow">
    <div class="text-center">
      <div class="modal-title w-100">
        <div class="row mb-2">
          <div class="col col-12">
            <TextField
              placeholder="Search user by name..."
              hideError
              v-model:value="searchKey"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading">
      <div class="row mb-2">
        <div class="col col-12">
          <Table
            :paging="paging"
            @updatePaging="updatePaging"
            :columns="columns"
            :rows="rows"
            :total-items="totalItems"
            :showNoRow="true"
            :useSimpleLayout="true"
          >
            <template #row_name="{ props }">
              {{ props.name }}
            </template>
            <template #row_actions="{ props }">
              <button class="btn btn-primary" @click="AddUser(props)">
                <i class="fas fa-plus"></i>
              </button>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script src="./AddReviewerModel.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.item-title {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 180px);
}
.table-sm {
  font-size: 14px;
}
.sub-item {
  font-size: 10px;
  color: $text-color--secondary;
}
.search-wrapper {
  position: relative;
  width: calc(100% - 30px);
  height: 30px;
  overflow: hidden;
  .input-section {
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    box-sizing: border-box;
    font-size: 14px;
    background: 0 0;
    border: 1px solid #ddd;
    padding-left: 12px;
    padding-right: 30px;
    width: calc(100% - 30px);
    height: 30px !important;
    line-height: normal !important;
    border-radius: 16px;
    color: $text-color--secondary;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      opacity: 1;
      border-color: $color-primary;
      outline: 0;
    }
  }
  .btn-icon {
    position: absolute;
    top: 4px;
    right: 9px;
    z-index: 9;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 22px;
    // color: #666;
    border: none;
    background: 0 0;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}
.table-nav {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}
.pagination {
  margin-left: 15px;
}
.page-item {
  cursor: pointer;
  .page-link {
    background-color: transparent;
    border: 1px solid #585d6e;
    width: 35px;
    height: 35px;
    color: $text-color--secondary;
    display: block;
    text-align: center;
    &.active {
      background-color: #4b596a;
      color: white;
    }
  }
}
</style>
