<template>
  <div class="row stage">
    <template
      v-if="
        totalStage &&
        currentStage !== null &&
        currentStage >= 0 &&
        currentStage < totalStage.length - 1 &&
        deal &&
        !deal.is_rejected
      "
    >
      <div class="col col-12 d-flex align-items-center">
        <PerfectScrollbar :key="psKey">
          <div class="stage-wrapper">
            <div
              v-for="(item, index) in totalStage"
              :key="`item_${index}`"
              class="blockItem"
              :class="{
                active: index == currentStage,
                left_active: index < currentStage,
                right_active: index > currentStage,
                first: index === 0,
              }"
            >
              <div class="mstone d-inline-block"></div>
              <div class="block-wrapper">
                <div>
                  <component
                    v-if="index >= 0 && index <= 6"
                    :is="`stage${index + 1}`"
                  />
                </div>
                {{ item.name }}
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      <div class="col-12 action" v-loading="loading">
        <div class="adm-action" v-if="!isUserTypeFounder()">
          <template v-if="!deal.is_waiting">
            <button
              class="btn btn-success approve mb-1 me-1"
              @click="confirm('Approve')"
            >
              Approve
            </button>
            <button class="btn btn-info on-hold mb-1 me-1" @click="pickTime()">
              Extend Deadline
            </button>
            <!-- <button class="btn btn-warning waiting mb-1" @click="confirm('Consider')">Consider</button> -->
            <button
              class="btn btn-warning waiting mb-1 me-1"
              @click="showConsider()"
            >
              Consider
            </button>
            <button
              class="btn btn-danger reject mb-1 me-1"
              @click="confirm('Reject')"
            >
              Reject
            </button>
          </template>
          <template v-else>
            <div class="d-inline-flex flex-column flex-wrap-reverse">
              <button
                class="btn btn-info goodDeal mb-1 me-1"
                @click="confirm('Consider')"
              >
                Good Deal
              </button>
              <span v-if="deal.waiting_date" class="text-white font-14">
                Consider till date:<br />
                {{ $filters.parseEstTime(deal.waiting_date) }}
              </span>
            </div>
            <div class="d-inline-flex">
              <button
                class="btn btn-danger reject mb-1"
                @click="confirm('Reject')"
              >
                Reject
              </button>
            </div>
          </template>
        </div>
      </div>
    </template>

    <!-- <template v-if="(totalStage && currentStage !== null && currentStage <=0) || !stageAllow"> -->

    <template v-if="deal && deal.is_rejected">
      <div class="col-12 rejected text-center">
        <svg
          class="me-2"
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 835.000000 743.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,743.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M4072 7405 c-176 -38 -349 -172 -475 -366 -30 -46 -105 -172 -167
                -279 -61 -107 -154 -267 -205 -355 -51 -88 -204 -353 -341 -590 -136 -236
                -306 -531 -378 -655 -71 -124 -203 -353 -294 -510 -188 -326 -366 -635 -574
                -995 -380 -657 -458 -793 -600 -1037 -83 -145 -208 -362 -278 -483 -69 -121
                -195 -339 -280 -485 -383 -661 -392 -679 -436 -828 -33 -115 -40 -227 -20
                -322 61 -287 297 -459 673 -490 166 -13 6801 -14 6963 0 145 12 250 39 359 92
                154 74 276 220 312 373 19 82 14 230 -10 324 -38 144 -71 208 -446 856 -70
                121 -196 339 -280 485 -84 146 -222 384 -306 530 -84 146 -216 375 -294 510
                -78 135 -210 364 -295 510 -172 298 -697 1208 -860 1490 -58 102 -175 304
                -260 450 -204 353 -421 729 -565 980 -263 459 -329 554 -450 654 -82 66 -221
                131 -312 145 -88 13 -101 13 -181 -4z m201 -1182 c47 -82 151 -263 232 -403
                81 -140 207 -358 280 -485 73 -126 208 -360 300 -520 92 -159 225 -389 294
                -510 70 -121 273 -472 451 -780 178 -308 382 -661 453 -785 136 -236 230 -399
                517 -895 95 -165 223 -388 285 -495 62 -107 151 -261 198 -343 48 -81 87 -152
                87 -157 0 -7 -1108 -10 -3190 -10 -2249 0 -3190 3 -3190 11 0 6 31 63 69 127
                38 64 129 223 204 352 74 129 192 334 262 455 70 121 196 339 280 485 84 146
                210 364 280 485 70 121 204 353 297 515 94 162 232 401 306 530 75 129 199
                345 277 480 78 135 201 349 274 475 74 127 218 376 321 555 103 179 245 424
                315 545 70 121 165 286 212 368 46 81 88 147 93 147 5 0 47 -66 93 -147z"
              fill="#eaa21e"
            />
            <path
              d="M3554 4613 c8 -57 196 -2029 196 -2049 0 -12 67 -14 420 -14 l419 0
                6 33 c3 17 50 477 105 1022 55 545 102 1005 105 1023 l6 32 -631 0 -632 0 6
                -47z"
              fill="#eaa21e"
            />
            <path
              d="M3750 1695 l0 -415 420 0 420 0 0 415 0 415 -420 0 -420 0 0 -415z"
              fill="#eaa21e"
            />
          </g>
        </svg>
        <span class="colorWhite">Deal rejected !</span>
      </div>
    </template>

    <template v-if="currentStage === totalStage.length - 1 && deal && !deal.is_rejected">
      <div class="col-12 rejected text-center">
        <svg
          width="40"
          height="40"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100.353 100.353"
          style="enable-background: new 0 0 100.353 100.353"
          xml:space="preserve"
          class="me-2"
        >
          <g>
            <path
              d="M96.747,39.242c-0.186-0.782-0.97-1.265-1.755-1.079c-0.782,0.187-1.265,0.972-1.078,1.754
                            c0.766,3.212,1.155,6.604,1.155,10.083c0,24.85-20.67,45.067-46.078,45.067S2.913,74.85,2.913,50
                            c0-24.852,20.67-45.07,46.078-45.07c10.119,0,19.785,3.202,27.952,9.26c0.644,0.479,1.558,0.344,2.037-0.302
                            s0.344-1.558-0.302-2.037C70.006,5.417,59.74,2.018,48.991,2.018C21.977,2.018,0,23.542,0,50c0,26.456,21.977,47.98,48.991,47.98
                            c27.014,0,48.991-21.524,48.991-47.98C97.982,46.295,97.566,42.676,96.747,39.242z"
              stroke="#28a745"
              fill="#28a745"
            />
            <path
              d="M47.98,71.683c-0.386,0-0.756-0.153-1.03-0.426L19.637,43.948c-0.569-0.569-0.569-1.491,0-2.06
                            c0.568-0.569,1.49-0.569,2.059,0l26.223,26.219l49.538-55.486c0.536-0.6,1.456-0.652,2.056-0.116s0.652,1.456,0.117,2.056
                            L49.066,71.197c-0.267,0.299-0.645,0.475-1.045,0.486C48.007,71.683,47.994,71.683,47.98,71.683z"
              stroke="#28a745"
              fill="#28a745"
            />
          </g>
        </svg>
        <span class="colorWhite">Deal Completed !</span>
      </div>
    </template>

    <Dialog ref="dialog" />

    <newDeadline
      ref="stageModal"
      :hideModal="hideModal"
      :hasFloatingCloseButton="true"
    />

    <considerTillDate
      ref="considerTillDate"
      :hideConsiderModal="hideConsiderModal"
      :hasFloatingCloseButton="true"
    />

  </div>
</template>
<style scoped lang="scss">
.row {
  flex-direction: column;
}
.stage {
  padding: 30px 0;
  border-bottom: 1px solid #3a3e47;
  .action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    .adm-action {
      height: 100%;
    }
    button {
      flex: 1;
      border-radius: 6px;
      color: #fff !important;
      font-size: 12px;
      height: 100%;
      font-weight: bold;
      //text-transform: uppercase;
      width: 140px;
      &.approve {
        background-color: #00a276;
      }
      &.on-hold {
        background-color: #56c0e0;
      }
      &.reject {
        background-color: #f46767;
      }
      &.waiting {
        background-color: #f7af3e;
      }
      &.goodDeal {
        background-color: #56c0e0;
      }
    }
  }
  .ps {
    width: 100%;
  }
  .stage-wrapper {
    display: flex;
    width: 100%;
    overflow-x: auto;
    .blockItem {
      min-height: 70px;
      min-width: 140px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      position: relative;
      .block-wrapper {
        font-size: 0.8em;
        line-height: 1.25;
        padding: 5px;
        position: relative;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 34px;
          height: 26px;
          margin-right: 2px;
        }
      }
      .mstone {
        width: 100%;
        height: 100%;
        position: absolute;
        &:before {
          content: "";
          width: 0;
          height: 0;
          right: 0;
          position: absolute;
          border-top: 35px solid #2e323b;
          border-bottom: 35px solid #2e323b;
          border-left: 20px solid transparent;
        }
        &:after {
          content: "";
          width: 0;
          height: 0;
          left: 0;
          position: absolute;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          border-left: 20px solid #2e323b;
        }
      }
      &.active {
        color: white;
        .mstone {
          background-color: #0095ff;
        }
        :deep() svg {
          path,
          rect {
            fill: white;
          }
        }
      }
      &.left_active {
        color: white;
        .mstone {
          background-color: rgba(95, 97, 100, 0.3);
        }
        svg {
          path,
          rect {
            fill: #008aff;
          }
        }
      }
      &.right_active {
        color: #97999c;
        .mstone {
          background-color: rgba(191, 191, 191, 0.3);
          opacity: 12%;
        }
        svg {
          path,
          rect {
            fill: #97999c;
          }
        }
      }
    }
  }
}
</style>
<script src="./index.js"></script>
