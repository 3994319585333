<template>
  <div class="request-form">
    <div class="container">
      <div class="row">
        <div class="col-12 py-5" v-loading="loading">
          <h4 class="headerText w-100 text-center mb-3">
            Request Submission Form
          </h4>
          <form @submit.prevent="send" autocomplete="false">
            <div class="row">
              <div class="col-12">
                <TextField
                  title="First Name"
                  name="fisrt_name"
                  :autocomplete="false"
                  :errorMsg="errorMsg.first_name"
                  :fieldRequire="true"
                  v-model:value="form.first_name"
                />
              </div>
              <div class="col-12">
                <TextField
                  title="Last Name"
                  name="last_name"
                  :autocomplete="false"
                  :errorMsg="errorMsg.last_name"
                  :fieldRequire="true"
                  v-model:value="form.last_name"
                />
              </div>
              <div class="col-12">
                <TextField
                  title="Email"
                  name="email"
                  :autocomplete="false"
                  :errorMsg="errorMsg.email"
                  :fieldRequire="true"
                  v-model:value="form.email"
                />
              </div>
              <div class="col-12 text-right">
                <button class="btn btn-info" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss">
.request-form {
  input {
    &:-webkit-autofill {
      color: #949ba2 !important;
      -webkit-text-fill-color: #949ba2 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.request-form {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 560px;
}
.headerText {
  color: #fff;
  font-size: 40px;
  line-height: 1.2;
}
</style>
