<template>
  <div>
    <label v-if="title" class="title mt-0" :for="name">{{ title }}</label>
    <div
      class="position-relative"
      :class="[
        `text-field ${size ? 'size-' + size : ''} ${
          hideError ? 'hide-error' : ''
        }`,
        customeClass,
      ]"
    >
      <input
        type="text"
        class="mask-value position-absolute"
        :value="mask"
        :placeholder="format"
        readonly
      />
      <Timepicker
        v-model="_value"
        :disabled="disabled"
        :format="format"
        :drop-direction="direction"
      >
      </Timepicker>
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss">
.time-picker {
  input {
    text-indent: -9999px;
    opacity: 0;
  }
  .controls {
    .clear-btn {
      display: none;
    }
  }
}
.custome-dark-theme {
  input[type="text"] {
    background-color: #494b54;
    height: 34px;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 34px;
  }
  .vue {
    &__time-picker {
      opacity: 1;

      .dropdown,
      &-dropdown {
        background: #494b54;
        ul {
          /* width */
          &::-webkit-scrollbar {
            width: 6px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #393c45;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #747474;
            border-radius: 3px;
            transition: all 300ms;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #909090;
            border-radius: 3px;
            transition: all 300ms;
          }
          &.minutes,
          &.seconds,
          &.apms {
            border-left: 0px solid #505050;
          }
          li {
            color: #fff;
            &:not(.hint):not([disabled]):hover,
            &:not(.hint):not([disabled]):focus,
            &:not(.hint):not([disabled]):hover,
            &:not(.hint):not([disabled]):focus {
              color: #fff;
              background: rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  color: #838894;
}
.text-field {
  position: relative;
}
input {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 0;
  min-height: 38px;
  padding: 2px 25px 2px 7px;
  font-size: 0.9em;
  background: transparent;
  color: #949ba2;
  &:disabled {
    background: #fff;
    color: black;
  }
}
.input-wrapper {
  margin-bottom: 25px;
}
.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.remove-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 25px;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease;
  &:hover {
    border: 1px solid #4bd;
    color: #000;
  }
}
</style>
