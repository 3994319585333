<template>
  <div>
    <transition name="fade">
      <div class="modal custom-modal" role="dialog">
        <div class="mask" @click="close"></div>
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header text-center">
              <h4 class="modal-title colorWhite">
                {{ formType }} Portfolio Company
              </h4>
            </div>
            <div
              class="modal-body custom-modal-body custom-scrollbar"
              v-if="item"
            >
              <div class="row mb-0">
                <div class="col col-12">
                  <div class="custom-switch-wrapper mb-2">
                    <label class="switch me-1">
                      <input type="checkbox" v-model="item.active" />
                      <span class="slider round"></span>
                    </label>
                    <span style="color: #c4cad6">Display on LP Portal</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Name <small class="colorRed">*</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.name"
                      maxlength="255"
                    />
                    <span class="texterror colorRed" v-if="error.name"
                      ><small>Please input name</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"
                      >Title <small class="colorRed">*</small>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.data.Name"
                      maxlength="255"
                    />
                    <span class="texterror colorRed" v-if="error.dataName"
                      ><small>Please input Title</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"
                      >Sector<small class="colorRed">*</small>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.data.Sector"
                      maxlength="255"
                    />
                    <span class="texterror colorRed" v-if="error.dataSector"
                      ><small>Please input Sector</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Headquarters <small class="colorRed">*</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.data.Hq"
                      maxlength="255"
                    />
                    <span class="texterror colorRed" v-if="error.dataHq"
                      ><small>Please input Headquarters</small></span
                    >
                  </div>
                </div>
                <div class="col col-12">
                  <div class="form-group mb-1">
                    <TextArea
                      v-model:value="item.data.Description"
                      title="Description"
                      placeholder="Maximum 5000 characters"
                      :errorMsg="error.dataDescription"
                      :maxLength="5000"
                      :rows="2"
                      :fieldRequire="true"
                    />
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Doc title</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.data.DocTitle"
                      maxlength="255"
                    />
                    <span class="texterror colorRed" v-if="error.dataDocTitle"
                      ><small>Please input Doc title</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"
                      >Doc file
                    </label>
                    <div class="d-flex">
                      <div class="d-flex position-relative flex-grow-1">
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          :value="item.docName"
                        />
                        <i class="fas fa-times search-clear-btn" v-if="item.data.DocId" @click="clearDataroomItem('DOC')"></i>
                      </div>
                      <button
                        class="btn btn-secondary btn-small ml-1"
                        @click="selectDataroomItem('DOC')"
                      >
                        <div class="search-btn">
                          <i class="fas fa-search iconSearch"></i>
                          <span style="margin: 2px">Search</span>
                        </div>
                      </button>
                    </div>
                    <span class="texterror colorRed" v-if="error.dataDocId"
                      ><small>Please choose Doc file</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-3">
                  <div class="form-group mb-1">
                    <label class="mb-0">Show fueled</label>
                    <div class="custom-switch-wrapper mb-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          v-model="item.data.ShowFuelPercentage"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col col-12 col-md-3">
                  <div class="form-group mb-1">
                    <label class="mb-0"> Fuel percentage</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="item.data.FuelPercentage"
                    />
                    <!-- <span class="texterror colorRed" v-if="error.dataFuelPercentage"><small>Please input Fuel percentage</small></span> -->
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"> Fuel note</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.data.FuelNote"
                      maxlength="255"
                    />
                    <!-- <span class="texterror colorRed" v-if="error.dataFuelNote"><small>Please input Fuel note</small></span> -->
                  </div>
                </div>
                <div class="col col-12">
                  <div class="form-group mb-1">
                    <TextArea
                      v-model:value="item.data.CoInvestors"
                      title="Co-investors"
                      placeholder="Maximum 5000 characters"
                      :errorMsg="error.dataCoInvestors"
                      :maxLength="5000"
                      :rows="2"
                      :fieldRequire="true"
                    />
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"
                      >Photo <small class="colorRed">*</small>
                    </label>
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="item.photoName"
                      />
                      <button
                        class="btn btn-secondary btn-small ml-1"
                        @click="selectDataroomItem('PHOTO')"
                      >
                        <div class="search-btn">
                          <i class="fas fa-search iconSearch"></i>
                          <span style="margin: 2px">Search</span>
                        </div>
                      </button>
                    </div>
                    <span class="texterror colorRed" v-if="error.dataPhotoId"
                      ><small>Please choose photo</small></span
                    >
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0"
                      >Video <small class="colorRed">*</small>
                    </label>
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="item.videoName"
                      />
                      <button
                        class="btn btn-secondary btn-small ml-1"
                        @click="selectDataroomItem('VIDEO')"
                      >
                        <div class="search-btn">
                          <i class="fas fa-search iconSearch"></i>
                          <span style="margin: 2px">Search</span>
                        </div>
                      </button>
                    </div>
                    <span class="texterror colorRed" v-if="error.dataVideoId"
                      ><small>Please choose video</small></span
                    >
                  </div>
                </div>

                <div
                  class="col col-12 col-md-6 w-100"
                  style="margin-bottom: 25px"
                >
                  <div class="form-group mb-1">
                    <label class="title mb-0">
                      Select Funds
                      <small class="require" style="color: #f52727"
                        >*</small
                      ></label
                    >
                    <customSelect
                      v-model:value="item.lp_fund_ids"
                      :options="lpFundOptions"
                      isMultiple
                      label="Select Funds"
                      class="w-100"
                    />
                    <div
                      v-if="error.lp_fund_ids"
                      class="texterror colorRed md-error"
                    >
                      Please Select Funds
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center">
              <button class="btn btn-secondary me-1" @click="close">
                Close
              </button>
              <button class="btn btn-primary" @click="save">Save</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <publicFileDataroom
      v-if="isShowPublicDataroom"
      :mediaType="selectedMediaType"
      @close="closeSearchDataroomItem"
      @completed="completeSelectDataroomItem"
    />
  </div>
</template>
<style lang="scss" scoped>
.ml-1 {
  margin-left: 0.25rem;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 180px);
}
.modal-footer {
  display: block;
}
.search-btn {
  display: flex;
}
.search-clear-btn {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
}
.iconSearch {
  margin-top: 4px;
}

.custom-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #212529;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }
}
</style>
<script src="./index.js"></script>
