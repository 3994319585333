import { defineComponent } from "vue";
import _ from "lodash";
import moment from "moment";
import validateEmail from "@/misc/checkemail";
import adminServices from "@/services/admin";
import notify from "@/services/notify";

import Modal from "@/components/Modal";
import NavTab from "@/components/NavTab";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";
import Datepicker from "@/components/DatePicker";
import Switcher from "@/components/Switcher";
import customSelect from "@/components/commons/customSelect";

export default defineComponent({
  name: "updateInvestorModel",
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
    Datepicker,
    NavTab,
    Switcher,
    customSelect,
  },
  props: {
    roleList: { type: Array, default: [] },
    investorTypeList: { type: Array, default: [] },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { id: "profile", name: "Profile" },
        { id: "nda", name: "NDA" },
      ],
      loading: false,
      isShow: false,
      tmpExpiredTime: null,
      form: {
        id: null,
        fname: null,
        lname: null,
        email: null,
        investor_type: "",
        expires_datetime: null,
        role_id: null,
        nda_sign_required: null,
        nda_signed: null,
        nda_signed_datetime: null,
        old_investor: null,
        file: null,
        password: null,
        allow_referral: false,
        lp_fund_ids: [],
      },
      errorMsg: {
        fname: null,
        lname: null,
        email: null,
        investor_type: null,
        expiresDatetime: null,
        role_id: null,
        password: null,
        lp_fund_ids: null,
      },
      loading: false,
      initialLoading: true,
      fileUploaded: "",
      ndaSignRequired: false,
      lpFundOptions: [],
    };
  },
  methods: {
    async getAllLpFunds(v) {
      this.form.lp_fund_ids = v;
      const res = await adminServices.getAllLpFunds();
      if (res.error) return;

      if (res.data.ok) {
        const temp = res.data.d.map((x) => ({
          value: x.lpfund_id,
          text: x.is_enabled ? x.name : `${x.name} (Inactive)`,
        }));
        this.lpFundOptions = temp || [];
      }
    },
    async showUpdate(item) {
      this.isShow = true;
      const _item = _.cloneDeep(item);
      await this.getAllLpFunds(_item.lp_funds.map((x) => Number(x.lp_fund_id)));
      this.form.id = _item.id;
      this.form.fname = _item.fname;
      this.form.lname = _item.lname;
      this.form.email = _item.email;
      this.form.investor_type = _item.investor_type;
      this.form.expires_datetime = moment(_item.expires_datetime).format(
        "MM/DD/YYYY"
      );
      this.form.role_id = _item.role_id;
      this.form.lp_fund_ids = _item.lp_funds.map((x) => Number(x.lp_fund_id));
      this.form.nda_signed_datetime = _item.nda_signed_datetime;
      this.tmpExpiredTime = {
        days: moment(_item.expires_datetime).format("DD"),
        months: moment(_item.expires_datetime).format("MM"),
        years: moment(_item.expires_datetime).format("YYYY"),
      };

      this.fetchInvestorInfo();
    },
    async fetchInvestorInfo() {
      this.loading = true;
      const res = await adminServices.getInvestor(this.form.id);
      if (res.data.ok) {
        let respData = res.data.d;

        this.form.nda_signed = respData.nda_signed;
        this.form.nda_sign_required = respData.nda_sign_required;
        this.form.nda_signed_datetime = respData.nda_signed_datetime;
        this.form.old_investor = respData.old_investor;
        this.form.expires_datetime = respData.expires_datetime
          ? moment(respData.expires_datetime).format("MM/DD/YYYY")
          : "";
        this.ndaSignRequired = respData.nda_sign_required;
      }
      this.loading = false;
    },
    async downloadInvestorNdaForm() {
      this.loading = true;
      const res = await adminServices.requestDownloadNdaForm(this.form.id);
      if (res.data.ok) {
        window.open(res.data.d, "_blank");
      }
      this.loading = false;
    },
    removeFileUploaded() {
      this.$refs.file.value = "";
      this.fileUploaded = "";
      this.form.file = "";
    },
    renderFileNda() {
      let file = this.$refs.file.files[0];
      if (file && file.type !== "application/pdf") {
        return;
      }
      if (file) {
        this.fileUploaded = file.name;
        this.form.file = file;
      }
    },
    validData() {
      let flag = true;
      if ($.trim(this.form.fname) === "") {
        this.errorMsg.fname = "Please input your first name";
        flag = false;
      }
      if ($.trim(this.form.lname) === "") {
        this.errorMsg.lname = "Please input your last name";
        flag = false;
      }
      if (!validateEmail.validateEmail(this.form.email) && !this.dataInfo) {
        this.errorMsg.email = "Please input correct email";
        flag = false;
      }
      if (!this.form.role_id) {
        this.errorMsg.role_id = "Please select role";
        flag = false;
      }
      if (this.form.lp_fund_ids.length === 0) {
        this.errorMsg.lp_fund_ids = "Please select Funds";
        flag = false;
      }
      if (!this.form.investor_type) {
        this.errorMsg.investor_type = "Please select investor type";
        flag = false;
      }
      return flag;
    },
    async save() {
      if (!this.validData()) return;

      let formData = new FormData();
      formData.append("firstName", this.form.fname);
      formData.append("lastName", this.form.lname);
      formData.append(
        "expiresDatetime",
        this.form.expires_datetime
          ? new Date(this.form.expires_datetime).toUTCString()
          : ""
      );
      formData.append("investorType", this.form.investor_type);
      formData.append("roleId", this.form.role_id);
      formData.append("lpFundIds", this.form.lp_fund_ids.join(","));
      formData.append("allowReferral", this.form.allow_referral);
      if (
        !this.form.old_investor &&
        !this.form.nda_signed &&
        this.form.nda_sign_required
      ) {
        formData.append("ndaRequired", this.form.nda_sign_required);
      }
      if (this.form.file) {
        formData.append("file", this.form.file);
      }

      this.loading = true;
      const res = await adminServices.updateInvestorInfo(
        this.form.id,
        formData
      );
      if (res.data.ok) {
        notify.success("Update investor success!");
        this.$emit("refresh");
        this.isShow = false;
      }
      this.loading = false;
    },
    async resetPassword() {
      if (!this.form.id) return;
      if (
        !this.form.password ||
        $.trim(this.form.password) === "" ||
        !this.form.password ||
        (this.form.password && this.form.password.length < 6)
      ) {
        this.errorMsg.password =
          "The Password must be at least 6 characters long.";
        return;
      }
      this.loading = true;
      const res = await adminServices.resetUserPassword(this.form.id, {
        password: this.form.password,
      });
      if (res.data.ok) {
        notify.success("Reset password success!");
      }
      this.loading = false;
    },
    customFormatter(date) {
      return moment(date).format("YYYY/MM/DD");
    },
  },
  watch: {
    tmpExpiredTime(v) {
      if (v) {
        this.form.expires_datetime = moment(
          `${v.years}-${v.months}-${v.days}`
        ).format("MM/DD/YYYY");
      }
    },
    "form.lp_fund_ids"(v) {
      if (v) {
        this.errorMsg.lp_fund_ids = null;
      }
    },
  },
});
