import _ from "lodash";
import VueJwtDecode from "vue-jwt-decode";
import appStore, { APP_MUTATION, LOCAL_STORAGES } from "../store/app";
import { generic } from "./generic";
import { defineComponent } from "vue";

const mixinFunctions = {
  computed: {
    currentScrollHeight() {
      return appStore.state.currentScrollHeightMobile;
    },
  },
  methods: {
    fixedBody() {
      //if (window.innerWidth < 576) {
      this.setCurrentHeight();
      $("body").css({
        position: "fixed",
        width: "100%",
        top: -this.currentScrollHeight,
      });
      //}
    },
    staticBody() {
      //if (window.innerWidth < 576) {
      $("body").css({
        position: "static",
        width: "auto",
        top: "0",
      });
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("body").offset().top + this.currentScrollHeight,
        },
        0
      );
      //}
    },
    clearScrollHeight() {
      appStore.commit(APP_MUTATION.update_current_scroll_height, null);
    },
    setCurrentHeight() {
      const currentHeight = $(window).scrollTop();
      if (currentHeight) {
        appStore.commit(
          APP_MUTATION.update_current_scroll_height,
          currentHeight
        );
      }
    },
    hasPermission(permission) {
      const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
      if (token && token.access_token) {
        try {
          const jsonToken = VueJwtDecode.decode(token.access_token);
          const perArr = jsonToken.Permissions.split(",");
          return perArr.includes(permission);
        } catch (e) {
          return false;
        }
      }
      return false;
    },
    hasPermissions(permissions) {
      if (!permissions || permissions.length === 0) {
        return true;
      }
      return _.findIndex(permissions, (x) => this.hasPermission(x)) >= 0;
    },
    getDataRoomItemIconClass(type, extension) {
      let $iconClass = "";
      if (type === "Folder") {
        $iconClass = "far fa-folder";
      } else if (type === "Bookmark") {
        $iconClass = "fas fa-link";
      } else if (type === "File") {
        if ([".pdf"].includes(extension)) {
          $iconClass = "far fa-file-pdf";
        } else if ([".gif", ".jpg", ".jpeg", ".png"].includes(extension)) {
          $iconClass = "far fa-file-image";
        } else if ([".doc", ".docx"].includes(extension)) {
          $iconClass = "far fa-file-word";
        } else if ([".xls", ".xlsx"].includes(extension)) {
          $iconClass = "far fa-file-excel";
        } else if ([".ppt", ".pptx"].includes(extension)) {
          $iconClass = "far fa-file-powerpoint";
        } else {
          $iconClass = "far fa-file-alt";
        }
      }
      return $iconClass;
    },
    getDataRoomItemIconColor(type, extension) {
      let $iconColor = "#949ba2";
      if (type === "Folder") {
        $iconColor = "#FFCC00";
      } else if (type === "Bookmark") {
        $iconColor = "#0F52BA";
      } else if (type === "File") {
        if ([".pdf"].includes(extension)) {
          $iconColor = "#F4511E";
        } else if ([".gif", ".jpg", ".jpeg", ".png"].includes(extension)) {
          $iconColor = "#dfd3c3";
        } else if ([".doc", ".docx"].includes(extension)) {
          $iconColor = "#03A9F4";
        } else if ([".xls", ".xlsx"].includes(extension)) {
          $iconColor = "#43A047";
        } else if ([".ppt", ".pptx"].includes(extension)) {
          $iconColor = "#FDD835";
        } else {
          $iconColor = "#949ba2";
        }
      }
      return $iconColor;
    },
    getDataRoomItemIconClassByTypeAndFileName(type, fileName) {
      let $extension = "";
      if (type === "File") {
        $extension = fileName.slice(fileName.lastIndexOf("."));
      }
      return this.getDataRoomItemIconClass(type, $extension);
    },
    getDataRoomItemIconColorByTypeAndFileName(type, fileName) {
      let $extension = "";
      if (type === "File") {
        $extension = fileName.slice(fileName.lastIndexOf("."));
      }
      return this.getDataRoomItemIconColor(type, $extension);
    },
    validateFileAndDirectoryName(name) {
      const specialCharacters = /[*:"\\|<>/?]/;
      return specialCharacters.test(name) ? false : true;
    },
    getRandomKey(length) {
      let result = "";
      const characters = "abcdefghijklmnopqrstuvwxyzZ0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async hideLayerMaskCaptcha() {
      const x = await $("body").children().last().find(">div").trigger("click");
      return x;
    },
  },
};
export default defineComponent(mixinFunctions);
