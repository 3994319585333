import { defineComponent } from "vue";
import FPCChecklist from "../FPCChecklist.vue";

export default defineComponent({
  name: "fpcChecklist",
  components: {
    FPCChecklist,
  },
  props: ["currentStage", "totalStage", "specifyIdDeal", "stageId", "dataDeal"],
  emits: ["reloadDeal"],
  data() {
    return {
      fpcChecklistKey: 0,
    };
  },
  computed: {
    scoreCalc() {
      var res = {
        score_qualitative: 0,
        score_quantitative: 0,
        score_total: 0,
      };
      if (
        this.dataDeal &&
        this.dataDeal.fpc_checklist &&
        this.dataDeal.fpc_checklist.length > 0
      ) {
        this.dataDeal.fpc_checklist.forEach((el) => {
          res.score_qualitative += el.score_qualitative;
          res.score_quantitative += el.score_quantitative;
          res.score_total += el.score_total;
        });
        res.score_qualitative = Math.round(
          res.score_qualitative / this.dataDeal.fpc_checklist.length
        );
        res.score_quantitative = Math.round(
          res.score_quantitative / this.dataDeal.fpc_checklist.length
        );
        res.score_total = Math.round(
          res.score_total / this.dataDeal.fpc_checklist.length
        );
      }
      return res;
    },
    profile() {
      return this.$store.state.profile;
    },
  },
  methods: {
    isCurrentUserReviewed() {
      var result = false;
      if (
        this.dataDeal &&
        this.dataDeal.fpc_checklist &&
        this.dataDeal.fpc_checklist.length > 0
      ) {
        var user = this.dataDeal.fpc_checklist.find(
          (x) => x.user_id == this.profile.userId
        );
        if (user) {
          result = true;
        }
      }
      return result;
    },
    showFPC() {
      this.fpcChecklistKey++;
      this.$nextTick(() => {
        this.$refs.fpc.show(
          this.specifyIdDeal,
          null,
          this.dataDeal.fpc_checklist
        );
      });
    },
    showReviewQuestion() {
      this.fpcChecklistKey++;
      this.$nextTick(() => {
        this.$refs.fpc.show(
          this.specifyIdDeal,
          "viewOnly",
          this.dataDeal.fpc_checklist
        );
      });
    },
    showReviewQuestionContinute() {
      this.fpcChecklistKey++;
      this.$nextTick(() => {
        this.$refs.fpc.show(
          this.specifyIdDeal,
          "Continute",
          this.dataDeal.fpc_checklist
        );
      });
    },
    refreshDeal(specifyIdDeal, isTrue) {
      this.$emit("reloadDeal", specifyIdDeal, isTrue);
    },
  },
});
