import { defineComponent } from "vue";
import _ from "lodash";
import AdminServices from "@/services/admin";
import notify from "@/services/notify";
import validateEmail from "@/misc/checkemail";

import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import TextArea from "@/components/TextArea";
import NewButton from "@/components/NewButton";
import NavTab from "@/components/NavTab";
import SelectEmailTemplate from "@/components/SelectEmailTemplate";

export class userInfo {
  constructor() {
    this.firstname = "";
    this.lastname = "";
    this.email = "";
  }
}

export default defineComponent({
  props: ["partnerList"],

  components: {
    Modal,
    TextField,
    TextArea,
    NewButton,
    NavTab,
    SelectEmailTemplate,
    SelectField,
  },
  data() {
    return {
      loading: false,
      isShow: false,

      form: {
        users: [],
        portfolioId: 0,
        subject: null,
        body: null,
      },
      errorMsg: {
        users: [],
        portfolioId: null,
        subject: null,
        body: null,
      },

      selectedTab: 0,
      tabs: [
        { id: "email_content", name: "Email Content", contentClass: "boxed" },
        { id: "saved_templates", name: "Saved Templates" },
      ],

      appliedTmpl: null,
    };
  },
  created() {
    this.addUser();
  },
  watch: {
    appliedTmpl(val) {
      this.form.subject = val.subject;
      this.form.body = val.body;
      this.selectedTab = 0;
    },
    "form.subject": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.subject = null;
    },
    "form.body": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.body = null;
    },
  },
  methods: {
    showAdd() {
      this.isShow = true;
    },
    validData() {
      let flag = true;

      if ($.trim(this.form.subject) === "") {
        this.errorMsg.subject = "Please input subject";
        flag = false;
      }
      if ($.trim(this.form.body) === "") {
        this.errorMsg.body = "Please input body text";
        flag = false;
      }
      if (!this.validateDataUser()) {
        notify.warn("Please review founder info!");
      } else if (flag) {
        this.addNewUser();
      }
      return flag;
    },
    validateDataUser() {
      let flag = true;
      this.form.users.forEach((user, i) => {
        if ($.trim(user.firstname) === "") {
          this.errorMsg.users[i].firstname = "Please input first name";
          flag = false;
        }
        if ($.trim(user.lastname) === "") {
          this.errorMsg.users[i].lastname = "Please input last name";
          flag = false;
        }
        if (!validateEmail.validateEmail(user.email)) {
          this.errorMsg.users[i].email = "Email does not valid";
          flag = false;
        }
      });
      return flag;
    },
    removeUser(index) {
      if (this.form.users.length > 1 && this.errorMsg.users.length > 1) {
        this.form.users.splice(index, 1);
        this.errorMsg.users.splice(index, 1);
      }
    },
    async addNewUser() {
      this.loading = true;

      const _data = {
        body: this.form.body,
        portfolioId: this.form.portfolioId,
        subject: this.form.subject,
        founders: this.form.users.map((x) => {
          return {
            firstName: $.trim(x.firstname),
            lastName: $.trim(x.lastname),
            email: $.trim(x.email),
          };
        }),
      };
      AdminServices.inviteFounder(_data)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add founder success!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addUser() {
      this.form.users.push(new userInfo());
      this.errorMsg.users.push(new userInfo());
    },
    handleFirstName(name, value) {
      const index = name.split("fname")[1];
      if (value.trim().length > 0) this.errorMsg.users[index].firstname = "";
    },
    handleLastName(name, value) {
      const index = name.split("lname")[1];
      if (value.trim().length > 0) this.errorMsg.users[index].lastname = "";
    },
    handleEmail(name, value) {
      const index = name.split("email")[1];
      if (value.trim().length > 0) {
        if (!validateEmail.validateEmail(value)) return;
        this.errorMsg.users[index].email = "";
      }
    },
  },
});
