<template>
    <transition name="fade" v-if="isShow">
        <div class="modal show" tabindex="-1" role="dialog">
            <div class="modalMask" @click="hideModal"></div>
            <div class="modal-dialog m-0" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <div class="starRate mb-2">
                            <span v-for="(item, index) in totalStar" :key="`star_${index}`" class="d-inline-block me-2" :class="{active: index < currentStarTarget}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.429" height="25.289" viewBox="0 0 25.429 25.289">
                                    <g id="star" transform="translate(0)">
                                        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
                                        <path id="Path_10" data-name="Path 10" d="M20.931,5.889l2.244,4.488a2.8,2.8,0,0,0,1.8,1.337l4.058.668c2.594.43,3.2,2.308,1.337,4.185L27.2,19.734a2.748,2.748,0,0,0-.652,2.308l.907,3.915c.716,3.087-.939,4.3-3.66,2.674l-3.8-2.26a2.7,2.7,0,0,0-2.514,0l-3.8,2.26c-2.721,1.607-4.376.414-3.66-2.674l.907-3.915a2.777,2.777,0,0,0-.652-2.308L7.1,16.567c-1.862-1.862-1.257-3.74,1.337-4.185l4.058-.668a2.749,2.749,0,0,0,1.8-1.337l2.244-4.488C17.733,3.454,19.706,3.454,20.931,5.889Z" transform="translate(-6.021 -4.062)"/>
                                        </g>
                                    </g>
                                </svg>    
                            </span>
                        </div>
                        <p class="title">Rate {{currentStarTarget < 0 ? 0 : currentStarTarget }} stars for this stage?</p>
                    </div>
                    <div class="modal-footer d-block text-center">
                        <button type="button" class="btn btn-info confirm mb-2" @click="confirm()">Confirm</button>
                        <span @click="hideModal" class="d-block text-center pointer cancel">Maybe later</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<style scoped lang="scss">
    .modal.show{
        display: block;
        .modalMask{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000, 0.72);
        }
        .modal-dialog{
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 0px solid transparent;
            border-radius: 18px;
            overflow: hidden;
            width: 100%;
            max-width: 464px;
        }
        .modal-body{
            padding-bottom: 0;
            .title{
                margin-bottom: 0;
                color: rgb(255, 255, 255);
                font-weight: bold;
                font-size: 18px;
            }
        }
        .modal-content{
            padding: 20px 0;
            background-color: #393c45;
            color: #ccc;
        }
        .modal-footer{
            border: 0px;
        }
        .starRate{
            path{
                fill: #9e9e9e;
            }
            .active{
                path{
                    fill: #0095FF;
                }
            }
        }
        button.confirm{
            background-color: #0095FF;
            color: #fff !important;
            width: 180px;
            line-height: 34px;
        }
        a.cancel{
            color: rgba(#fff, 0.5);
        }
    }
</style>
<script src="./submitRate.js"></script>