<template>
  <div>
    <transition name="fade">
      <div class="modal custom-modal" role="dialog">
        <div class="mask" @click="close"></div>
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content" v-loading="loading">
            <div class="modal-header text-center">
              <h4 class="modal-title colorWhite">{{ formType }} Introduction Video</h4>
            </div>
            <div class="modal-body" v-if="item">
              <div class="row mb-0">
                <div class="col col-12">
                  <div class="custom-switch-wrapper mb-3">
                    <label class="switch me-1">
                      <input type="checkbox" v-model="item.active" />
                      <span class="slider round"></span>
                    </label>
                    <span style="color: #c4cad6">Display on LP Portal</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                  <TextField
                    name="name"
                    title="Name"
                    fieldRequire
                    :errorMsg="error.name ? 'Please input name' : ''"
                    v-model:value="item.name"
                    :maxLength="255"
                  />
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Video <small class="colorRed">*</small></label
                    >
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="item.videoName"
                      />
                      <button
                        class="btn btn-secondary btn-small ml-1"
                        @click="selectDataroomItem('VIDEO')"
                      >
                        <div class="search-btn">
                          <i class="fas fa-search iconSearch"></i>
                          <span style="margin: 2px">Search</span>
                        </div>
                      </button>
                    </div>
                    <span class="texterror colorRed" v-if="error.video"
                      ><small>Please choose video</small></span
                    >
                  </div>
                </div>

                <div
                  class="col col-12 col-md-6 w-100"
                  style="margin-bottom: 25px"
                >
                  <div class="form-group mb-1">
                    <label class="title mb-0">
                      Select Funds
                      <small class="require" style="color: #f52727"
                        >*</small
                      ></label
                    >
                    <customSelect
                      v-model:value="item.lp_fund_ids"
                      :options="lpFundOptions"
                      isMultiple
                      label="Select Funds"
                      class="w-100"
                    />
                    <div
                      v-if="error.lp_fund_ids"
                      class="texterror colorRed md-error"
                    >
                      Please Select Funds
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center">
              <button class="btn btn-secondary me-1" @click="close">
                Close
              </button>
              <button class="btn btn-primary" @click="save">Save</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <publicFileDataroom
      v-if="isShowPublicDataroom"
      :mediaType="selectedMediaType"
      @close="closeSearchDataroomItem"
      @completed="completeSelectDataroomItem"
    />
  </div>
</template>
<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
textarea {
  resize: none;
}
.modal-footer {
  display: block;
}
.custom-file {
  height: 150px;
  .custom-file-label {
    top: 30px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    background: #d9d9d9;
    height: 120px;
    &::after {
      display: none;
    }
    .dataImg {
      height: 105px;
    }
    i {
      font-size: 80px;
      &.dataPhoto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
  }
  .custom-file-input {
    height: 100%;
  }
}
.custom-row {
  height: 160px;
  @media screen and (max-width: 767.98px) {
    height: unset;
    .custom-file {
      height: 160px;
    }
    .custom-file-input {
      height: 100%;
    }
  }
}
.customUploadFile {
  background-color: #41505e;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  display: block;
  color: #fff;
  opacity: 0.7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fileinfo {
  color: #afafaf;
  font-weight: 400;
  .info {
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  .close {
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    float: none;
  }
}
.search-btn {
  display: flex;
}
.iconSearch {
  margin-top: 4px;
}
</style>
<script src="./index.js"></script>
