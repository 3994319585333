<template>
  <footer>
    <div class="wrapperFooter">
      <div class="mainContainer topFooter">
        <div class="row" v-if="localVersion === 'large'">
          <div class="col-lg-4">
            <!-- <router-link exact to="/"> -->
            <logo :size-width="200" :class-ext="'logo'" />
            <!-- </router-link> -->
            <h3 class="titleFooter mt-lg-3 mb-lg-1">Headquarters</h3>
            <p>2901 Florida Ave, Suite 840, Miami, FL 33133</p>
            <!-- <router-link exact to="/terms-and-conditions">Terms and Conditions</router-link> -->
            <p class="mb-lg-1">Follow Us</p>
            <div class="d-flex">
              <a
                :href="`${social.facebook}`"
                v-if="social.facebook"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 48 48"
                >
                  <g class="nc-icon-wrapper" fill="#000000">
                    <path
                      fill="#000000"
                      d="M46,0H2C0.89543,0,0,0.89543,0,2v44c0,1.10457,0.89543,2,2,2h23.63829V29.4119h-6.25529v-7.24417h6.25529 v-5.34235c0-6.19977,3.78661-9.57566,9.31723-9.57566c2.64922,0,4.92609,0.19724,5.58963,0.2854v6.47912l-3.83577,0.00174 c-3.00786,0-3.59026,1.42929-3.59026,3.52668v4.62507h7.17347l-0.93401,7.24417h-6.23946V48H46c1.10457,0,2-0.89543,2-2V2 C48,0.89543,47.10457,0,46,0z"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.instagram}`"
                v-if="social.instagram"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.633"
                  height="19.628"
                  viewBox="0 0 19.633 19.628"
                >
                  <g
                    id="instagram"
                    transform="translate(0.075 -31.825)"
                    opacity="0.999"
                  >
                    <path
                      id="Path_14325"
                      data-name="Path 14325"
                      d="M9.744,36.607a5.032,5.032,0,1,0,5.032,5.032A5.025,5.025,0,0,0,9.744,36.607Zm0,8.3a3.272,3.272,0,1,1,3.272-3.272,3.278,3.278,0,0,1-3.272,3.272Zm6.412-8.51a1.174,1.174,0,1,1-1.174-1.174A1.171,1.171,0,0,1,16.156,36.4Zm3.333,1.191A5.809,5.809,0,0,0,17.9,33.479a5.847,5.847,0,0,0-4.113-1.586c-1.621-.092-6.478-.092-8.1,0A5.839,5.839,0,0,0,1.58,33.475,5.828,5.828,0,0,0-.006,37.588c-.092,1.621-.092,6.478,0,8.1A5.809,5.809,0,0,0,1.58,49.8a5.855,5.855,0,0,0,4.113,1.586c1.621.092,6.478.092,8.1,0A5.809,5.809,0,0,0,17.9,49.8a5.847,5.847,0,0,0,1.586-4.113C19.581,44.066,19.581,39.213,19.489,37.592ZM17.4,47.425a3.312,3.312,0,0,1-1.866,1.866c-1.292.512-4.358.394-5.786.394s-4.5.114-5.786-.394a3.312,3.312,0,0,1-1.866-1.866C1.58,46.133,1.7,43.067,1.7,41.639s-.114-4.5.394-5.786a3.312,3.312,0,0,1,1.866-1.866c1.292-.512,4.358-.394,5.786-.394s4.5-.114,5.786.394A3.312,3.312,0,0,1,17.4,35.853c.512,1.292.394,4.358.394,5.786S17.908,46.137,17.4,47.425Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.linkedin}`"
                v-if="social.linkedin"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 19.92 19.92"
                >
                  <g id="linkedin" transform="translate(0 -32)" opacity="0.999">
                    <path
                      id="Path_14326"
                      data-name="Path 14326"
                      d="M18.5,32H1.418A1.429,1.429,0,0,0,0,33.436V50.484A1.429,1.429,0,0,0,1.418,51.92H18.5a1.433,1.433,0,0,0,1.423-1.436V33.436A1.433,1.433,0,0,0,18.5,32ZM6.021,49.074H3.068V39.568H6.025v9.507ZM4.544,38.27a1.712,1.712,0,1,1,1.712-1.712A1.713,1.713,0,0,1,4.544,38.27Zm12.543,10.8H14.135V44.45c0-1.1-.022-2.521-1.534-2.521-1.538,0-1.774,1.2-1.774,2.441v4.7H7.875V39.568h2.832v1.3h.04a3.11,3.11,0,0,1,2.8-1.534c2.988,0,3.544,1.97,3.544,4.531Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.medium}`"
                v-if="social.medium"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 48 48"
                >
                  <g class="nc-icon-wrapper" fill="#000000">
                    <path
                      d="M46,0H2A2,2,0,0,0,0,2V46a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V2A2,2,0,0,0,46,0ZM39.877,11.373,37.3,13.841a.752.752,0,0,0-.286.722V32.7a.753.753,0,0,0,.286.722l2.514,2.468v.542H27.171v-.542l2.6-2.528c.256-.256.256-.331.256-.722V17.979L22.79,36.371h-.979l-8.43-18.391V30.305a1.7,1.7,0,0,0,.467,1.415l3.387,4.109v.542h-9.6v-.542l3.387-4.109a1.64,1.64,0,0,0,.437-1.415V16.053A1.249,1.249,0,0,0,11.048,15L8.037,11.373v-.542h9.349l7.226,15.848,6.353-15.848h8.912Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-5">
                <h3 class="titleFooter">Fuel VC Submission Form</h3>
                <ul class="menuFooter">
                  <li>
                    <a href="javascript:void(0)" @click="openCreateDealModal"
                      >Click here</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div>
                  <h3 class="titleFooter">North America</h3>
                  <ul class="menuFooter">
                    <li><a href="#">Miami</a></li>
                  </ul>
                </div>
                <div>
                  <h3 class="titleFooter">Latin America</h3>
                  <ul class="menuFooter">
                    <li><a href="#">Guatemala</a></li>
                    <li><a href="#">Ecuador</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <h3 class="titleFooter">Europe</h3>
                <ul class="menuFooter">
                  <li><a href="#">Spain</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row fmobile" v-else>
          <div class="col-md-12 text-center">
            <router-link custom to="/">
              <span role="link">
                <logo :size-width="200" :class-ext="logo" />
              </span>
            </router-link>
            <p>
              Fuel Venture Capital 2901 Florida Ave, Suite 840, Miami, FL 33133
            </p>
            <p>
              <a
                :href="`${social.facebook}`"
                v-if="social.facebook"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 48 48"
                >
                  <g class="nc-icon-wrapper" fill="#000000">
                    <path
                      fill="#000000"
                      d="M46,0H2C0.89543,0,0,0.89543,0,2v44c0,1.10457,0.89543,2,2,2h23.63829V29.4119h-6.25529v-7.24417h6.25529 v-5.34235c0-6.19977,3.78661-9.57566,9.31723-9.57566c2.64922,0,4.92609,0.19724,5.58963,0.2854v6.47912l-3.83577,0.00174 c-3.00786,0-3.59026,1.42929-3.59026,3.52668v4.62507h7.17347l-0.93401,7.24417h-6.23946V48H46c1.10457,0,2-0.89543,2-2V2 C48,0.89543,47.10457,0,46,0z"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.twitter}`"
                v-if="social.twitter"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.267"
                  height="19.709"
                  viewBox="0 0 24.267 19.709"
                >
                  <g id="twitter" transform="translate(0 -48.082)">
                    <path
                      id="Path_14324"
                      data-name="Path 14324"
                      d="M21.772,52.994c.015.216.015.431.015.647A14.054,14.054,0,0,1,7.637,67.791,14.055,14.055,0,0,1,0,65.558a10.288,10.288,0,0,0,1.2.062A9.96,9.96,0,0,0,7.376,63.5a4.982,4.982,0,0,1-4.65-3.449,6.272,6.272,0,0,0,.939.077,5.26,5.26,0,0,0,1.309-.169A4.974,4.974,0,0,1,.985,55.073v-.062a5.009,5.009,0,0,0,2.248.631,4.981,4.981,0,0,1-1.54-6.652,14.137,14.137,0,0,0,10.255,5.2,5.615,5.615,0,0,1-.123-1.139,4.978,4.978,0,0,1,8.607-3.4,9.792,9.792,0,0,0,3.157-1.2A4.96,4.96,0,0,1,21.4,51.192a9.97,9.97,0,0,0,2.864-.77,10.691,10.691,0,0,1-2.494,2.571Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.instagram}`"
                v-if="social.instagram"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.633"
                  height="19.628"
                  viewBox="0 0 19.633 19.628"
                >
                  <g
                    id="instagram"
                    transform="translate(0.075 -31.825)"
                    opacity="0.999"
                  >
                    <path
                      id="Path_14325"
                      data-name="Path 14325"
                      d="M9.744,36.607a5.032,5.032,0,1,0,5.032,5.032A5.025,5.025,0,0,0,9.744,36.607Zm0,8.3a3.272,3.272,0,1,1,3.272-3.272,3.278,3.278,0,0,1-3.272,3.272Zm6.412-8.51a1.174,1.174,0,1,1-1.174-1.174A1.171,1.171,0,0,1,16.156,36.4Zm3.333,1.191A5.809,5.809,0,0,0,17.9,33.479a5.847,5.847,0,0,0-4.113-1.586c-1.621-.092-6.478-.092-8.1,0A5.839,5.839,0,0,0,1.58,33.475,5.828,5.828,0,0,0-.006,37.588c-.092,1.621-.092,6.478,0,8.1A5.809,5.809,0,0,0,1.58,49.8a5.855,5.855,0,0,0,4.113,1.586c1.621.092,6.478.092,8.1,0A5.809,5.809,0,0,0,17.9,49.8a5.847,5.847,0,0,0,1.586-4.113C19.581,44.066,19.581,39.213,19.489,37.592ZM17.4,47.425a3.312,3.312,0,0,1-1.866,1.866c-1.292.512-4.358.394-5.786.394s-4.5.114-5.786-.394a3.312,3.312,0,0,1-1.866-1.866C1.58,46.133,1.7,43.067,1.7,41.639s-.114-4.5.394-5.786a3.312,3.312,0,0,1,1.866-1.866c1.292-.512,4.358-.394,5.786-.394s4.5-.114,5.786.394A3.312,3.312,0,0,1,17.4,35.853c.512,1.292.394,4.358.394,5.786S17.908,46.137,17.4,47.425Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.linkedin}`"
                v-if="social.linkedin"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 19.92 19.92"
                >
                  <g id="linkedin" transform="translate(0 -32)" opacity="0.999">
                    <path
                      id="Path_14326"
                      data-name="Path 14326"
                      d="M18.5,32H1.418A1.429,1.429,0,0,0,0,33.436V50.484A1.429,1.429,0,0,0,1.418,51.92H18.5a1.433,1.433,0,0,0,1.423-1.436V33.436A1.433,1.433,0,0,0,18.5,32ZM6.021,49.074H3.068V39.568H6.025v9.507ZM4.544,38.27a1.712,1.712,0,1,1,1.712-1.712A1.713,1.713,0,0,1,4.544,38.27Zm12.543,10.8H14.135V44.45c0-1.1-.022-2.521-1.534-2.521-1.538,0-1.774,1.2-1.774,2.441v4.7H7.875V39.568h2.832v1.3h.04a3.11,3.11,0,0,1,2.8-1.534c2.988,0,3.544,1.97,3.544,4.531Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
              <a
                :href="`${social.medium}`"
                v-if="social.medium"
                class="social"
                target="_blank"
              >
                <svg
                  class="iconSocial"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.92"
                  height="19.92"
                  viewBox="0 0 48 48"
                >
                  <g class="nc-icon-wrapper" fill="#000000">
                    <path
                      d="M46,0H2A2,2,0,0,0,0,2V46a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V2A2,2,0,0,0,46,0ZM39.877,11.373,37.3,13.841a.752.752,0,0,0-.286.722V32.7a.753.753,0,0,0,.286.722l2.514,2.468v.542H27.171v-.542l2.6-2.528c.256-.256.256-.331.256-.722V17.979L22.79,36.371h-.979l-8.43-18.391V30.305a1.7,1.7,0,0,0,.467,1.415l3.387,4.109v.542h-9.6v-.542l3.387-4.109a1.64,1.64,0,0,0,.437-1.415V16.053A1.249,1.249,0,0,0,11.048,15L8.037,11.373v-.542h9.349l7.226,15.848,6.353-15.848h8.912Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="coppy-right">
      <span>©2019 Fuel Venture Capital</span>
    </div>
    <a
      href="javascript:void(0)"
      @click.prevent="backToTop"
      class="backToTop"
    ></a>
    <DealAddUpdateModal ref="dealAddUpdateModal" :key="dealAddUpdateModalKey" />
  </footer>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/config/router";
import ScrollTo from "vue-scrollto";
import config from "../../config";
import DealAddUpdateModal from "@/components/deal/DealAddUpdateModal.vue";
import logo from "@/components/commons/logo";

export default defineComponent({
  components: {
    DealAddUpdateModal,
    logo,
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      homePath: router.home.path,
      social: config.social,
      localVersion: "large",
      dealAddUpdateModalKey: 0,
    };
  },
  mounted() {
    let me = this;
    window.addEventListener("scroll", function () {
      me.handleScroll();
    });
    window.addEventListener("resize", this.onResize);
    this.onResize();

    if (
      this.$route &&
      this.$route.query.form &&
      this.$route.query.form === "DEAL_SUBMISSION"
    ) {
      this.openCreateDealModal();
    }
  },
  methods: {
    backToTop() {
      ScrollTo.scrollTo({
        el: ".pace-done",
        duration: 300,
        easing: "linear",
      });
    },

    handleScroll() {
      var scrollTop = window.scrollY;
      // scrollTop > this.isScrollPosition --> scroll down, otherwise scroll up
      if (scrollTop <= 100) {
        $(".backToTop").fadeOut();
      } else if (scrollTop > 100) {
        $(".backToTop").fadeIn();
      }

      this.isScrollPosition = scrollTop;
    },
    onResize() {
      if (window.innerWidth > 991) {
        this.localVersion = "large";
      } else {
        this.localVersion = "small";
      }
    },
    openCreateDealModal() {
      this.dealAddUpdateModalKey++;
      this.$nextTick(() => {
        this.$refs.dealAddUpdateModal.showAddPublic();
      });
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
</script>

<style lang="scss" scoped>
@keyframes rotate {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
footer {
  font-size: 0.8rem;

  .topFooter .logo {
    -webkit-animation: rotate 4s linear; /* Safari 4.0 - 8.0 */
    animation: rotate 4s linear;
  }
}

@media (max-width: 991px) {
  .wrapperFooter {
    .fmobile {
      .logo {
        margin-bottom: 20px;
        -webkit-animation: rotate 4s linear; /* Safari 4.0 - 8.0 */
        animation: rotate 4s linear;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
