import { defineComponent } from "vue";
import notify from "@/services/notify";
import adminServices from "@/services/admin";
import Dialog from "@/components/Dialog";
import momentTz from "moment-timezone";
import config from "@/config";
import Modal from "@/components/Modal";
import NewButton from "@/components/NewButton";
import MilestoneModal from "../milestone-components";
import filePreview, { FileTypes } from "@/components/commons/filePreview";

export default defineComponent({
  name: "milestone",
  inject: ["eventInfoData"],
  components: {
    Dialog,
    Modal,
    MilestoneModal,
    NewButton,
    filePreview,
  },
  data() {
    return {
      loadingMilestone: false,
      form: null,
      paging: {
        page: 1,
        size: 4,
        total: 0,
      },
      milestoneList: [
        {
          name: "One session",
          code: "ONE",
        },
        {
          name: "Multiple sessions",
          code: "MULTIPLE",
        },
      ],
      milestones: [],
      milestoneType: "ONE",
      totalItems: 0,
      currentPage: 1,
      itemModalKey: 0,
      milestoneId: null,
      preview: {
        fileUrl: null,
        fileType: null,
      },
      FileTypes: FileTypes,
      minCreateMilestoneStartDate: null,
    };
  },
  mounted() {
    if (this.eventInfoData) {
      this.form = this.eventInfoData;
    }
  },
  methods: {
    changeMilestoneType(code) {
      this.milestoneType = code;
    },
    getMilestones() {
      if (!this.form || !this.form.eventId || !this.form?.name) return;
      this.loadingMilestone = true;
      adminServices
        .getMilestonesOfEvent({
          paging: {
            page: this.paging.page,
            size: this.paging.size,
          },
          eventId: this.form.eventId,
          filteredName: this.filteredName,
        })
        .then((res) => {
          if (res.data.ok) {
            if (res.data.d.c.length > 0) {
              this.milestones = res.data.d.c;
              this.totalItems = res.data.d.t;
              const milestoneStartDate = Math.min(
                ...this.milestones.map((o) => o.startDate)
              );
              const milestoneEndDate = Math.max(
                ...this.milestones.map((o) => o.endDate)
              );

              this.minCreateMilestoneStartDate = this.milestones[0].endDate;
              this.milestoneType = this.milestones[0].type;

              this.updateDateMilestone(milestoneStartDate, milestoneEndDate);
              this.$emit("milestoneType", this.milestoneType);
            } else {
              //#TODO refactor
              /*
                      paging not refresh
                    */
              this.paging.page =
                this.paging.page > 1 ? this.paging.page - 1 : this.paging.page;
              if (this.paging.page == 1) this.milestones = [];

              this.minCreateMilestoneStartDate = null;
              this.updateDateMilestone(null, null);
              this.$emit("milestoneType", null);
            }
          }
        })
        .finally(() => {
          this.loadingMilestone = false;
        });
    },
    async addMilestone() {
      this.itemModalKey++;
      let startDate = this.minCreateMilestoneStartDate
        ? this.minCreateMilestoneStartDate
        : this.eventInfoData.startDateTime;
      this.$nextTick(() => {
        this.$refs.itemModal.create(
          startDate,
          this.eventInfoData.endDateTime,
          this.milestoneType,
          this.milestones
        );
      });
    },
    async deleteMilestone(props) {
      let message = {
        title: "Delete session",
        body: "Are you sure to delete this session? If you delete it, this session cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;

          this.loadingMilestone = true;
          const res = await adminServices.deleteMilestone(
            this.form.eventId,
            props.milestoneId
          );
          if (res.data.d.succeeded) {
            notify.success("Delete session successfully");
            this.getMilestones();
          } else {
            notify.error(res.data.d.errorMessage);
          }
          this.loadingMilestone = false;
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    async editMilestone(props) {
      this.itemModalKey++;
      const data = this.handleDateEditMilestone(props, this.milestones);

      this.$nextTick(() => {
        this.$refs.itemModal.show(
          props,
          data?.start,
          data?.end,
          this.milestoneType,
          this.milestones
        );
      });
    },
    handleDateEditMilestone(props, milestoneLists) {
      if (!props || !milestoneLists) return;
      if (milestoneLists.length < 2) {
        return {
          start: this.eventInfoData.startDateTime,
          end: this.eventInfoData.endDateTime,
        };
      } else {
        let start = null;
        let end = null;
        const index = milestoneLists.findIndex(
          (el) => el.milestoneId === props.milestoneId
        );

        if (index === 0) {
          start = milestoneLists[1].endDate;
          end = this.eventInfoData.endDateTime;
        } else if (index === milestoneLists.length - 1) {
          start = this.eventInfoData.startDateTime;
          end = milestoneLists[index - 1].startDate;
        } else {
          end = milestoneLists[index - 1].startDate;
          start = milestoneLists[index + 1].endDate;
        }
        return {
          start: start,
          end: end,
        };
      }
    },
    async getAttendee() {
      this.loading = true;
      const res = await adminServices.getAttendeesByEventId({
        eventId: this.form.eventId,
      });
      if (res && res.data && res.data.ok) {
        res.data.d.map((el) => {
          if (el.userId) {
            this.userIds.push(el.userId);
          }
        });
      }
      this.loading = false;
    },

    showPreview(id, fileType) {
      let vue = this;
      this.loading = true;
      adminServices
        .requestDownloadCIOAndCountView(id, "View")
        .then((resp) => {
          if (resp.data.ok) {
            this.fixedBody();
            this.preview.fileUrl = resp.data.d;
            this.preview.fileType = fileType;
            vue.$refs.filePreview.showModal();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageClick(event) {
      this.currentPage = event.target;
      this.updatePaging();
    },
    updatePaging() {
      if (this.isSizeChange) this.size = this.sizeChange;
      this.paging = {
        ...this.paging,
        sorts: this.sorts,
        page: this.currentPage,
      };
    },
    updateDateMilestone(minStartDate, maxStartDate) {
      this.$emit("updateDateMilestone", minStartDate, maxStartDate);
    },
  },
  computed: {
    _paging() {
      const totalPage = Math.ceil(this.totalItems / this.paging.size);
      if (totalPage == 1) return [];
      const showNumber = 4;
      const side = Math.floor(showNumber / 2);
      var arr = [];
      var left = [];
      var right = [];
      for (let i = 0; i < side; i++) {
        const res = this.currentPage - (side - i);
        if (res > 0) left.push({ id: res, target: res });
      }
      for (let i = 1; i <= side; i++) {
        const res = this.currentPage + i;
        if (res <= totalPage) right.push({ id: res, target: res });
      }
      if (left.length >= 1) {
        // arr.push({ id: "first", target: 1 });
        arr.push({ id: "prev", target: this.currentPage - 1 });
      }
      arr.push(...left);
      this.currentPage &&
        arr.push({ id: this.currentPage, target: this.currentPage });
      arr.push(...right);
      if (right.length >= 1) {
        arr.push({ id: "next", target: this.currentPage + 1 });
        // arr.push({ id: "last", target: totalPage });
      }
      return arr;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(v) {
        this.getMilestones();
      },
    },
    paging: {
      deep: true,
      handler(v) {
        this.getMilestones();
      },
    },
  },
});
