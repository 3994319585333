const conf = {
    app_name: 'rokk3rfuel',
    url_admin: process.env.VUE_APP_URL_ADMIN,
    url_admin_rokker:process.env.VUE_APP_URL_ADMIN_ROKKER,
    app_url: process.env.VUE_APP_URL,
    auth: true,
    api: process.env.VUE_APP_API,
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRECT,
    base_url: process.env.VUE_APP_BASE_URL,
    recaptcha_key: process.env.VUE_APP_RECAPTCHA_KEY,
    default_language: process.env.VUE_APP_DEFAULT_LANGUAGE,
    languages: process.env.VUE_APP_LANGUAGES,
    social: {
        facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
        instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
        linkedin: process.env.VUE_APP_SOCIAL_LINKEDIN,
        medium: process.env.VUE_APP_SOCIAL_MEDIUM
    },
    nda_form: process.env.VUE_APP_NDA_FORM,
    base_docs_url: process.env.VUE_APP_BASE_DOCS_URL,
    Newsletter_URL: process.env.VUE_APP_NEWSLETTER_URL,
    ignore_err_notify_paths: process.env.VUE_APP_IGNORE_ERR_NOTIFY_PATHS,
    dataroom_id_curve_pdf: process.env.dataroom_id_curve_pdf,
    dataroom_id_soundtrack_pdf: process.env.VUE_APP_DATA_ID_CURVE_PDF,
    dataroom_id_curve_mp4: process.env.VUE_APP_DATAROOM_ID_CURVE_MP4,
    dataroom_id_soundtrack_mp4: process.env.VUE_APP_DATAROOM_ID_SOUNDTRACK_MP4,
    dataroom_id_q2_replay: process.env.VUE_APP_DATAROOM_ID_Q2_REPLAY,
    dataroom_id_q2_deck: process.env.VUE_APP_DATAROOM_ID_Q2_DECK,
    dataroom_id_q3_replay: process.env.VUE_APP_DATAROOM_ID_Q3_REPLAY,
    dataroom_id_q3_deck: process.env.VUE_APP_DATAROOM_ID_Q3_DECK,
    time_zone_default: process.env.VUE_APP_TIMEZONE,
    segment_token: process.env.VUE_APP_SEGMENT_TOKEN,
    segment_api_host: process.env.VUE_APP_SEGMENT_APIHOST,
    segment_cdn_url: process.env.VUE_APP_SEGMENT_CDNURL,
    segment_enabled: process.env.VUE_APP_SEGMENT_ENABLED.toLowerCase() === 'true'
}

export default conf