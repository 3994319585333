<template>
  <div v-if="useEmptyLayout" class="h-100 container-fluid">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="row justify-content-center content-section">
          <div
            class="col-lg-9 align-items-center justify-content-center"
            v-loading="loading"
          >
            <div class="d-flex align-items-center mt-3">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>
            <div class="wrapper mt-lg-5">
              <form
                id="loginForm"
                class="mb-2"
                v-if="!registerSuccess"
                :disabled="disabled"
              >
                <div class="boxTitle">
                  <p class="signInText mb-lg-2">Get started now</p>
                  <p class="requireText colorText mb-0">
                    Join with us and feel the power
                  </p>
                </div>
                <!-- <h2 class="text-center font-weight-bold my-3">Join us</h2> -->
                <div class="row">
                  <div class="col-lg-6">
                    <!-- <md-field
                      v-bind:class="{ 'md-invalid': messageError.firstName }"
                    >
                      <md-input
                        v-model="model.firstName"
                        tabindex="1"
                        placeholder="First name"
                      ></md-input>
                      <small class="md-error">{{
                        messageError.firstName
                      }}</small>
                    </md-field> -->

                    <TextField
                      :indexField="1"
                      :fieldRequire="true"
                      v-model:value="model.firstName"
                      placeholder="First name"
                      class="m-0"
                      name="fname"
                      :errorMsg="messageError.firstName"
                    />
                  </div>
                  <div class="col-lg-6">
                    <!-- <md-field
                      v-bind:class="{ 'md-invalid': messageError.lastName }"
                    >
                      <md-input
                        v-model="model.lastName"
                        tabindex="1"
                        placeholder="Last name"
                      ></md-input>
                      <small class="md-error">{{
                        messageError.lastName
                      }}</small>
                    </md-field> -->

                    <TextField
                      :indexField="2"
                      :fieldRequire="true"
                      v-model:value="model.lastName"
                      placeholder="Last name"
                      name="lname"
                      :errorMsg="messageError.lastName"
                    />
                  </div>
                </div>
                <!-- <md-field v-bind:class="{ 'md-invalid': messageError.email }">
                  <md-input
                    v-model="model.email"
                    tabindex="2"
                    placeholder="Email"
                  ></md-input>
                  <small class="md-error">{{ messageError.email }}</small>
                </md-field> -->

                <TextField
                  :indexField="3"
                  :fieldRequire="true"
                  v-model:value="model.email"
                  placeholder="Email"
                  name="email"
                  :errorMsg="messageError.email"
                />

                <!-- <md-field
                  md-has-password
                  v-bind:class="{ 'md-invalid': messageError.password }"
                >
                  <md-input
                    v-model="model.password"
                    type="password"
                    maxlength="20"
                    tabindex="3"
                    placeholder="Password *"
                  ></md-input>
                  <small class="md-e md-error">{{
                    messageError.password
                  }}</small>
                </md-field> -->

                <TextField
                  :indexField="4"
                  :fieldRequire="true"
                  v-model:value="model.password"
                  placeholder="Password *"
                  :errorMsg="messageError.password"
                  maxlength="20"
                  name="password"
                />

                <!-- <md-field
                  md-has-password
                  v-bind:class="{ 'md-invalid': messageError.confirmassword }"
                  class="mb-4"
                >
                  <md-input
                    v-model="model.confirmPassword"
                    type="password"
                    tabindex="4"
                    placeholder="Confirm Password *"
                  ></md-input>
                  <small class="md-error">{{
                    messageError.confirmPassword
                  }}</small>
                </md-field> -->

                <TextField
                  :indexField="4"
                  :fieldRequire="true"
                  v-model:value="model.confirmPassword"
                  placeholder="Confirm Password *"
                  :errorMsg="messageError.confirmPassword"
                  type="password"
                  name="confirm-password"
                  iconPasswordColor="white"
                />

                <p class="text-center mb-0 mt-lg-4 pt-lg-1">
                  <button
                    @click.prevent="register"
                    class="btn button btn-large btn-primary btn-active w-100 btnReg"
                  >
                    Register
                  </button>
                </p>
              </form>
              <div v-else class="text-center">
                <h2>Registration successful</h2>
                <p>
                  A verification link has been sent to
                  <a :href="`mailto:${model.email}`">{{ model.email }}</a
                  >.
                  <br />
                  Please wait a few minutes and get verification link to active
                  your account.
                </p>
                <p class="colorPrimary">
                  Check your email in spam or junk folder
                </p>
              </div>
              <div class="text-center">
                <span class="colorGray mr-b-10 des-ita"
                  >Already have an account?</span
                >
                <router-link class="link" :to="loginPath">Sign in</router-link>
              </div>
              <div class="text-center my-4" v-if="registerSuccess">
                <span class="colorGray text-center"
                  >Didn't receive a confirmation email</span
                >
                <router-link class="link" :to="resendRegisterVerifyPath"
                  >Request a new one</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 login-section px-0">
        <div class="boxFix backgroundLogin"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.colorText {
  color: #8798ad;
}

.md-field.md-has-password {
  .md-e {
    bottom: -24px !important;
  }
  .md-error {
    bottom: -30px;
  }
}

.md-field {
  margin: 4px 0 0px;
}
.colorGray {
  color: #b0bac9;
}
#loginForm {
  font-size: 16px;
  margin-top: 1rem;
  padding: 0;
  //color: #2e384d;
  //opacity: 0.8;
  //background-color: #fff;
}
#loginForm .signInText {
  font-size: 34px;
}
.logoLogin img {
  object-fit: cover;
  width: 190px;
  height: auto;
}
.white-theme {
  span {
    &.colorGray {
      &.des-ita {
        color: #fff;
      }
    }
  }
  .logoLogin {
    display: block;
    height: 40px;
    width: 170px;
    text-indent: -9999px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .md-field label {
    color: #000;
  }
  .regform {
    max-width: 473px;
    form {
      border-radius: 7px;
      background-color: #fff;
      padding: 10px 3rem;
      h2 {
        font-size: 30px;
        color: #000;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
  .text-orange {
    color: #2177ff;
  }
  .button.btn-primary.btn-active {
    background-color: #2177ff;
    &:hover {
      background-color: #2177ff;
    }
  }
  .button.btn-gray.btn-border {
    border: 1px solid #fff;
    background-color: transparent;
  }
  .md-field {
    margin: 0;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .md-error {
      top: 100%;
      max-width: 335px;
    }
  }
  .md-field .md-input,
  .md-field .md-textarea {
    width: 100%;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    border: 1px solid #868f93;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    color: #000;
  }
  .md-field label {
    position: static;
    padding: 0;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    height: 40px;
    line-height: 40px;
  }
}
@media (max-width: 1024px) {
  .white-theme {
    .regform {
      form {
        padding: 2rem;
        .nickName {
          margin-bottom: 40px;
          .md-error {
            max-width: 240px;
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  #loginForm {
    .signInText {
      font-size: 26px;
      margin-bottom: 0px;
    }
  }
}
</style>
