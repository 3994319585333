// layout v2
import MainLayout from "../layouts/MainLayout.vue";
// page v2
import Home from "@/views/v2/home";
import OurTeam from "@/views/v2/our-team";
import AboutUs from "@/views/v2/about-us";
import Portfolio from "@/views/v2/portfolio";
import FuelTheMindLayout from "@/views/v2/fuel-the-mind";
import SubmitADeal from "@/views/v2/submit-a-deal";

import PortfolioNews from "@/views/v2/fuel-the-mind/comps/FuelTheMindTab/_PortfolioNews.vue";
import SocialMedia from "@/views/v2/fuel-the-mind/comps/FuelTheMindTab/_SocialMedia.vue";
import QuarterItem from "@/views/v2/fuel-the-mind/comps/FuelTheMindTab/_Perspectives/_QuarterItem.vue";
import FilePreview from "@/components/v2/filePreview";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/our-team",
        name: "Our Team",
        component: OurTeam,
      },
      {
        path: "/who-we-are",
        name: "Who We Are",
        component: AboutUs,
      },
      {
        path: "/portfolio",
        name: "Portfolio",
        component: Portfolio,
      },
      {
        path: "/fuel-the-mind",
        name: "Fuel The Mind",
        redirect: "/fuel-the-mind/portfolio-news",
        component: FuelTheMindLayout,
        children: [
          {
            path: "portfolio-news",
            name: "Portfolio News",
            component: PortfolioNews,
          },
          {
            path: "social-media",
            name: "Social Media",
            component: SocialMedia,
          },

          {
            path: "perspectives/:id",
            name: "Fuel Perspectives",
            component: QuarterItem,
          },

          {
            path: "perspectives/:id/:pdf",
            name: "Fuel Perspectives PDF",
            component: FilePreview,
          },
        ],
      },
      {
        path: "/pitch-us",
        name: "Fueling the future",
        component: SubmitADeal,
      },
    ],
  },
];

export default routes;
