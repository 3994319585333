<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideDeleteFileItems"></div>
            <div class="modal-dialog modal-dialog-sm">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">Delete Files</h4>
                    </div>
                    <div class="modal-body custom-modal-body" v-loading="loading">
                        <div class="content-wrapper">
                            <div class="text-center mb-3" v-if="!completed">Please press Confirm button to delete following files forever</div>
                            <ul class="list-group bordered" v-if="itemsToDelete">
                                <li class="list-group-item item-header text-center">Files to delete</li>
                                <li class="list-group-item item-content"
                                    v-for="(item, idx) in itemsToDelete"
                                    :key="idx">
                                    <div>
                                        <span :style="{'color': getDataRoomItemIconColor(item.type, item.current_extension)}"><i class="item-icon" :class="getDataRoomItemIconClass(item.type, item.current_extension)"></i></span><span class="noselect">{{item.name}}</span>
                                    </div>
                                    <span v-if="item.isTrashed" class="icon success"><i class="fas fa-check-double"></i></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer footer-center">
                        <button class="btn btn-primary" :disabled="completed || loading || !isValidToProcess" @click="processDelete">Confirm</button>
                        <button class="btn btn-secondary" :disabled="loading" @click="hideDeleteFileItems">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.custom-modal .modal-dialog.modal-dialog-sm {
    max-width: 550px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.custom-modal-body {
    overflow: hidden;
    overflow-y: auto;
    display: block;
    height: calc(100vh - 180px);
    .content-wrapper {
        display: flex;
        flex-direction: column;
    }
}
.list-group {
    &.bordered {
        border: 1px solid $color-default;
        border-radius: 4px;
        color: $text-color--secondary;
    }
    .list-group-item {
        border: none;
        background-color: transparent;
        padding: .25rem 1rem;
        font-size: 13px;
        font-weight: 200;
        color: $text-color--secondary;
        &:not(:first-child) {
            border-top: 1px solid $color-default;
        }
        &.item-header {
            font-size: 14px;
            font-weight: 500;
            color: $text-color;
        }
        &.item-content {
            display: flex;
            justify-content: space-between;
            .icon.success {
                color: #409EFF;
            }
        }
    }
}
.item-icon {
    width: 22px;
}

.modal-footer.footer-center {
    justify-content: center;
}
</style>