<template>
  <div class="adminContent p-3" v-loading="loading">
    <div class="treeList me-2">
      <div class="data-content py-2 pr-2 data-tree">
        <ul class="mainTree">
          <tree-item
            class="item"
            :item="treeData"
            @select-folder="selectFolder"
            :selectedId="selectedFolderId"
            :selectedParentId="selectedParentId"
          />
        </ul>
      </div>
    </div>
    <div class="treeDetailContent">
      <div class="data-content px-2 py-2">
        <div class="path-section">
          <div class="truncate">
            <span @click="refreshData" title="Refresh data" class="pointer"
              ><i class="fas fa-sync-alt"></i
            ></span>
            <span v-for="(item, idx) in parentPath" :key="idx">
              <i
                v-if="idx > 0"
                class="fas fa-chevron-right mx-1 breadcrum-next-icon"
              ></i
              ><span
                v-if="idx < parentPath.length - 1"
                class="noselect path-item"
                @click="selectFolder(item)"
                >{{ item.name }}</span
              ><span v-else class="noselect path-item">{{ item.name }}</span>
            </span>
          </div>
        </div>
        <hr class="my-2" />
        <div class="row">
          <div class="col-12">
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Title</th>
                    <th>Size</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="item-row"
                    v-for="(item, index) in selectedChildrenItems"
                    :key="index"
                    :class="{ pointer: item.type === 'Folder' }"
                    @click="oneClick(item)"
                  >
                    <td class="noselect">{{ index + 1 }}</td>
                    <td class="noselect">
                      <span
                        class="pr-1 item-icon"
                        :style="{
                          color: getDataRoomItemIconColor(
                            item.type,
                            item.current_extension
                          ),
                        }"
                      >
                        <i
                          :class="
                            getDataRoomItemIconClass(
                              item.type,
                              item.current_extension
                            )
                          "
                        ></i>
                      </span>
                      <span>{{ item.name }}</span>
                    </td>
                    <td class="noselect">
                      <span v-if="item.type === 'Folder'"
                        >{{ countDescendantFiles(item) }} files</span
                      >
                      <span v-if="item.type === 'File'">{{
                        $filters.formatBytes(item.file_size)
                      }}</span>
                    </td>
                    <td>
                      {{ $filters.parseEstTime(item.last_modified_datetime) }}
                    </td>
                    <td class="text-center noselect nowrap">
                      <span
                        title="View File"
                        v-if="item.type === 'File'"
                        class="btn-more-action pointer me-1"
                        @click.prevent.stop="viewFile(item)"
                      >
                        <i class="far fa-eye"></i>
                      </span>
                      <span
                        title="Download File"
                        v-if="checkItemDownloadable(item)"
                        class="btn-more-action pointer"
                        @click.prevent.stop="downloadFile(item)"
                      >
                        <i class="fas fa-download"></i>
                      </span>
                      <span
                        title="Open Link"
                        v-if="item.type === 'Bookmark'"
                        class="btn-more-action pointer"
                        @click.prevent.stop="openBookmarkUrl(item)"
                      >
                        <i class="far fa-eye"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />

    <Modal
      class="confirmDownloadfile"
      title="Warning"
      v-model:value="isShow"
      :hasFloatingCloseButton="true"
      :isVisible="true"
      size="sm"
    >
      <div>
        <p class="text-white">
          This file cannot be previewed. Please download it.
        </p>
      </div>
      <template #footer>
        <button class="btn btn-secondary me-2" @click="confirmDownload">
          Download file
        </button>
      </template>
    </Modal>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";
.table {
  th,
  td {
    border-top: 1px solid #484c5a;
  }
  td {
    vertical-align: middle;
    color: #949ba2;
  }
}
.adminContent {
  display: flex;
  .treeList {
    flex: 0 0 300px;
    max-width: 300px;
  }
  .treeDetailContent {
    flex: 1;
  }
}
ul {
  line-height: 1.5;
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
  }
}
.data-content {
  min-height: calc(100vh - 70px - 2rem);
  background-color: $color-background-secondary;
  display: block;
  border-radius: 3px;
  color: $text-color;
  height: 100%;
}
.data-section-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #409eff;
}
.item-row {
  font-size: 0.9rem;
  .item-icon {
    width: 20px;
    display: inline-flex;
  }
}
.btn-more-action {
  display: inline-flex;
  border: 1px solid #616779;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 29px;
  height: 25px;
  font-size: 0.7rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  .breadcrum-next-icon {
    font-size: 14px;
  }
}

.path-section {
  display: flex;
  align-items: center;
  .path-item {
    font-size: 16px;
    padding: 6px 12px 6px;
    border-radius: 8px;
    line-height: 26px;
    cursor: pointer;
    display: inline-block;
    padding: 1px 12px;
    &:hover {
      background-color: $color-grey;
      color: $color-dark;
    }
    i {
      color: #5f6368;
    }
  }
}
</style>
