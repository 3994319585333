import { defineComponent } from 'vue'
import appStore from '../../store/app'
import OtherServices from '../../services/other'

export default defineComponent({
    name: 'welcome-popup',
    data() {
        return {
            isShow: false,
            img: undefined
        }
    },
    mounted() {
        if (this.currentRouter.meta.static && this.currentRouter.fullPath.includes('/partner')) {
            this.show()
        } else if (!this.currentRouter.meta.static && !this.currentRouter.meta.auth && !this.currentRouter.path.includes('/event')) {
            this.show()
        } 
    },
    computed: {
        currentRouter() {
            return appStore.state.currentRoute
        },
    },
    methods:{
        close() {
            this.isShow = false
        },
        show() {
            OtherServices.getWelcomeData().then(resp => {
                if (resp.data.ok) {
                    this.isShow = resp.data.d.show_popup
                    this.img = resp.data.d.popup_img

                    setTimeout(() => {
                        this.$refs.modalContent ? this.$refs.modalContent.classList.add('visible') : ''
                    }, 500);
                }
            })
        }
    }
})