<template>
  <Modal v-model:value="isShow" v-if="items">
    <div v-loading="loading">
      <div class="row mb-3">
        <div class="col-12 upload mb-3">Thumbnail</div>
        <div
          class="col"
          :class="{ 'col-3': items.length > 4 }"
          v-for="(item, index) in items"
          :key="index"
        >
          <FileField2
            v-model:value="item.file"
            :init-preview-url="item.url"
            :accept="imageMime"
            :mimeType="item.mimeType"
            class="mb-3"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <NewButton @click="save" :loading="loading"> Save </NewButton>
    </template>
  </Modal>
</template>

<script>
import {defineComponent} from "vue";
import _ from "lodash";
import adminServices from "@/services/admin";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import notify from "@/services/notify";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";
import FileField2 from "@/components/FileField2";
import dialog from "@/services/dialog";

export default defineComponent({
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
    FileField2,
  },
  data() {
    return {
      isShow: false,
      loading: false,
      state: "CREATE",
      items: [],
      focuses: [],
      errorMsg: {
        thumbnail: null,
      },
      imageMime: ["image/gif", "image/jpeg", "image/png", "image/jpg"],
      form: {
        eventId: "",
        name: "",
        extension: "",
        mimeType: "",
        s3Key: "",
        fileSize: "",
      },
      id: "",
    };
  },
  methods: {
    show(files, id) {
      this.id = id;
      this.isShow = true;
      this.items = _.map(files, (item, index) => {
        this.focuses.push({
          id: index,
        });
        return {
          index: index,
          file: item,
          url: URL.createObjectURL(item),
          loading: false,
        };
      });
    },
    async save() {
      this.loading = true;
      const items = _.filter(this.items, (item) => !item.id);
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (!item.file) {
          notify.error("Please select image");
          this.loading = false;
          return;
        }
      }
      await this.uploadFiles(items);
      this.loading = false;
      this.isShow = false;
      notify.success("Create Successfully!");
      this.$emit("refresh");
    },
    async cancel() {
      const confirm = await dialog.confirm({
        title: "Close",
        content: "Some items do not update. Are you sure to discard them?",
      });

      if (!confirm) return;

      this.focuses = [];
      this.isShow = false;
      this.$emit("cancel");
    },
    async uploadFiles(items) {
      var _awsCred = await adminServices.getPublicSessionToken();
      AWS.config.update({
        region: _awsCred.data.d.region,
        credentials: new AWS.Credentials(
          _awsCred.data.d.access_key,
          _awsCred.data.d.secret_key,
          _awsCred.data.d.session_token
        ),
      });

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let lastDot = item.file.name.lastIndexOf(".");
        var keyname =
          "event/images/" +
          this.getRandomKey(12) +
          item.file.name.substring(lastDot);
        let upload = new S3.ManagedUpload({
          params: {
            Bucket: _awsCred.data.d.bucket,
            Key: keyname,
            Body: item.file,
          },
        });
        this.form.eventId = this.id;
        this.form.name = item.file.name;
        this.form.extension = item.file.name.split(".").pop();
        this.form.mimeType = item.file.type;
        this.form.s3Key = (await upload.promise()).Key;
        this.form.fileSize = item.file.size;
        await adminServices.createGallery(this.form);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.upload {
  font-size: 14px;
  font-weight: bold;
  color: #ffff;
}

.process {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-bar {
  background-color: whiteSmoke;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;

  /* Dimensions should be similar to the parent progress element. */
  width: 100%;
  height: 5px;
  max-width: 200px;
}

.progress-bar span {
  background-color: royalblue;
  border-radius: 3px;

  display: block;
  text-indent: -9999px;
  line-height: 5px;
  height: 5px;
  transition: width 0.2s linear;
}

p {
  position: relative;
  color: white;
}
</style>
