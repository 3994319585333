<template>
  <Card>
    <div class="styled-card">
      <div :style="`background-image: url('${url || '../../public/images/default.jpeg'}');`" class="img"  @click="showPreview(url, FileTypes.IMAGE)"/>

      <filePreview ref="filePreview" :fileUrl="preview.fileUrl" :fileType="preview.fileType" />
    </div>
  </Card>
</template>

<script>
import { defineComponent } from "vue";
import ImgDefault from "../../../../../public/public/images/default.jpeg";
import filePreview, { FileTypes } from '@/components/commons/filePreview'
import Card from './Card.vue'

export default defineComponent({
  props: {
    url: { type: String, default: ImgDefault },
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    item: { type: Object, default: undefined },
    editHandler: { type: Function, default: undefined },
    deleteHandler: { type: Function, default: undefined },
    status: { type: String, default: "" },
  },
  components: {
    Card,
    ImgDefault,
    filePreview
  },
  data() {
    return {
      FileTypes: FileTypes,
      loading: false,
      preview: {
        fileUrl: null,
        fileType: null,
      },
      checkInterval: null,
    }
  },
  methods: {
    showPreview(item, fileTypes) {
      this.loading = true
      this.fixedBody()
      this.preview.fileUrl = item
      this.preview.fileType = fileTypes
      this.loading = false
      this.$refs.filePreview.showModal()
    }
  }
});
</script>

<style lang="scss" scoped>
.styled-card {
  img {
    margin: 0 auto;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .desc {
    font-size: 12px;
    margin-bottom: 0;
  }

  .title,
  .desc {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: #000;
    text-align: left;
  }
}

.img {
  width: 100%;
  padding-top: 60%;
  // height: 340px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover {
    .overlay {
      opacity: 0;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 200ms ease;
    background-color: black;
    opacity: 0.35;
  }

  .play {
    font-size: 50px;
    color: #585866;
    z-index: 1;
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    position: absolute;
    height: calc(100% - 32px);
    width: calc(100% - 32px);
    ;
    top: 16px;
    left: 16px;
    border: 1px solid rgb(92, 88, 88)
  }
}

.action {
  i {
    padding: 5px;
    cursor: pointer;
  }
}
</style>