import AdminServices from '@/services/admin'
import popupAddEditCompany from './addEditCompany'
import notify from '@/services/notify'
import _ from 'lodash'
// import draggable from 'vuedraggable' //#todo update module dragable
import appStore from '@/store/app'
import router from '@/config/router'
import { PORTFOLIO_ICONS } from '@/misc/constants'
import filePreview from '@/components/commons/filePreview'
import Dragger from "@/components/Dragger"
import { defineComponent } from 'vue'

export default defineComponent({
	data() {
		return {
			isShowAddCompany: false,
			tableData: [],
			infoCompanyEdit: [],
			dashboardName: router.dashboard.name,
			dashboardPath: router.dashboard.path,
			type: '',
			portfolioIcons: PORTFOLIO_ICONS,
			selectedStatus: '',
			statusList: [
				{
					name: 'All',
					code: ''
				},
				{
					name: 'Active',
					code: 'ACTIVE'
				},
				{
					name: 'Inactive',
					code: 'INACTIVE'
				}
			],
			modalLoading: false,
            isShowFilePreview: false,
            fileToken: null,
            previewFileType: null
		}
	},
	components: {
		popupAddEditCompany,
		Dragger,
		filePreview
	},
	created() {
		this.getPortfolioData()
	},
	methods: {
		getPortfolioData() {
			AdminServices.getPortfolioData(this.selectedStatus).then(resp => {
				if (resp.data.ok) {
					this.tableData = resp.data.d
				}
			})
		},
		showFilePreview(token, previewFileType) { 
            this.fixedBody()
            this.fileToken = token
            this.previewFileType = previewFileType
            this.isShowFilePreview = true
        },
        hideFilePreview() {
            this.staticBody()
            this.isShowFilePreview = false
            this.fileToken = null
        },
		confirmAddCompany(data) {
			this.modalLoading = true
			AdminServices.addPortfolioData(data).then(resp => {
				this.modalLoading = false
				if (resp.data.ok) {
					notify.success('Add successfully')
					this.hideAddCompany()
					this.getPortfolioData()
				} else {
					notify.error(`Error ${error.resp.error.message}`)
				}
			})
		},
		editPortfolio(id) {
			this.infoCompanyEdit = _.cloneDeep(this.tableData).find(x => x.id === id)
			this.showAddCompany()
		},
		confirmUpdateCompany(data) {
			this.modalLoading = true
			AdminServices.updatePortfolioData(data).then(resp => {
				this.modalLoading = false
				if (resp.data.ok) {
					notify.success('Update successfully')
					this.isShowAddCompany = false
					this.getPortfolioData()
					this.infoCompanyEdit = []
				} else {
					notify.error(`Error ${error.resp.error.message}`)
				}
			})
		},
		/* removePortfolio(id) {
			let me = this
			let message = { title: 'Warning', body: 'This will permanently delete this item. Continue?' }
			let options = {
				html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"                
				okText: 'OK',
				cancelText: 'Cancel',
				animation: 'fade', // Available: "zoom", "bounce", "fade"
				type: 'basic', // coming soon: 'soft', 'hard'
				customClass: 'confirmDeleteMember' // Custom class to be injected into the parent node for the current dialog instance
			}
			this.$dialog.confirm(message, options)
				.then(function () {
					// This will be triggered when user clicks on proceed
					AdminServices.removePortfolioData(id).then(resp => {
						if (resp.data.ok) {
							notify.success('Delete success')
							me.getPortfolioData()
						} else {
							notify.error(`Error ${error.resp.error.message}`)
						}
					})
				})
				.catch(function () {
					// This will be triggered when user clicks on cancel
				})
		}, */
		showAddCompany() {
			this.isShowAddCompany = true
		},
		hideAddCompany() {
			this.isShowAddCompany = false
			this.infoCompanyEdit = []
		},
		onEnd(v) {
			let tmpData = _.cloneDeep(this.tableData)
			tmpData.map((x, idx) => {
				x.orderNumber = idx
				return x
			})
			AdminServices.sortPortfolioData(tmpData).then(resp => {
				if (resp.data.ok) {
					this.getPortfolioData()
				}
			})
		}
	},
	computed: {
		profile: function () {
			return appStore.state.profile
		}
	}
})