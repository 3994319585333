import AdminServices from "../../../services/admin";
import pdf from "../../../components/commons/pdf-viewer";
import { appStore, AUTH_ACTIONS } from "../../../store/app";
import router from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      status: "notsign",
      username: "",
      formData: {
        company: null,
        title: null,
        signature: null,
      },
      errorMsg: {
        signature: null,
      },
      onError: false,
      formLoading: false,
      finishSurvey: false,
      url: "",
      homepath: router.home.path,
      isAcceptTerm: false,
      isNoticeSign: false,
    };
  },
  components: {
    pdf,
  },
  methods: {
    submit() {
      this.onError = false;

      if (!this.formData.signature) {
        this.errorMsg.signature = "Please input your signature";
        this.onError = true;
      }
      if (this.onError) {
        this.$el.scrollTop = 0;
        return;
      }
      let obj = {
        company: this.formData.company,
        title: this.formData.title,
        signature: this.formData.signature,
      };
      this.formLoading = true;
      AdminServices.investorSignSig(obj).then((resp) => {
        if (resp.data.ok) {
          this.status = "signed";
          appStore.dispatch(AUTH_ACTIONS.get_user_profile);
        }
        this.formLoading = false;
      });
    },
    rejectTerm() {
      this.$router.push(this.homepath);
    },
    acceptTerm() {
      this.isAcceptTerm = true;
    },
    goback() {
      this.isNoticeSign = true;
    },
    sign() {
      this.isNoticeSign = false;
    },
  },
  computed: {
    profile() {
      return appStore.state.profile;
    },
    scale() {
      return appStore.state.mobile.isMobile ? "page-width" : "page-width";
    },
  },
  watch: {
    "profile.ndaFormUrl": function (v) {
      if (v) {
        this.url = v;
      }
    },
    "formData.signature": function (v) {
      if (v) {
        this.errorMsg.signature = null;
      }
    },
  },
  mounted() {
    if (this.profile) {
      this.url = this.profile.ndaFormUrl;
    }
  },
  created() {
    $("title").text("Sign NDA");
  },
});
