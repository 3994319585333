import { defineComponent } from "vue";
import router from "@/config/router";

export default defineComponent({
  data() {
    return {
      overviewPath: router.visualizationOverview.path,
      investmentPath: router.visualizationInvestments.path,
      portfolioPerformancetPath: router.visualizationPortfolioPerformance.path,
    };
  },
});
