<template>
  <div class="checklist" v-if="dataDeal.stage_id != 'STAGE_1'">
    <div class="row">
      <div class="col-6">
        <div class="d-flex">
          <div
            v-if="
              totalStage &&
              (currentStage !== null || currentStage !== undefined)
            "
          >
            <h3 class="me-3 mb-1">FPC CHECKLIST</h3>
            <div
              class="text"
              v-if="
                dataDeal &&
                dataDeal.fpc_checklist &&
                dataDeal.fpc_checklist.length > 0
              "
            >
              <div>Rated by: {{ dataDeal.fpc_checklist.length }} user(s)</div>
              <div>Qualitative score: {{ scoreCalc.score_qualitative }}%</div>
              <div>Quantitative score: {{ scoreCalc.score_quantitative }}%</div>
              <div>Total score: {{ scoreCalc.score_total }}%</div>
            </div>
            <div class="text" v-else>
              <div>Please complete the checklist</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 text-end">
        <div class="row">
          <div class="col-12">
            <div>
              <template
                v-if="!isCurrentUserReviewed() && !dataDeal.finish_submitted"
              >
                <button class="btn btn-info submit" @click="showFPC">
                  Submit
                </button>
              </template>
              <template
                v-else-if="
                  isCurrentUserReviewed() && !dataDeal.finish_submitted
                "
              >
                <button
                  class="btn btn-info submit"
                  @click="showReviewQuestionContinute"
                >
                  Continue
                </button>
              </template>
            </div>
            <div>
              <button
                class="btn btn-success fpc-btn"
                v-if="dataDeal.fpc_checklist.length"
                @click="showReviewQuestion"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FPCChecklist
      ref="fpc"
      :dealId="specifyIdDeal"
      :key="fpcChecklistKey + 'fpcChecklistKey'"
      @refreshDeal="refreshDeal"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.checklist {
  padding: 29px 0;
  border-bottom: 1px solid #3a3e47;
  h3 {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
  }
  .text {
    color: rgb(219, 219, 219);
  }
  button.submit {
    color: #fff !important;
    background-color: #0095ff;
    line-height: 28px;
    font-weight: bold;
    width: 180px;
    margin-bottom: 10px;
    // @media only screen and (max-width: 1440px) {
    // 	margin-bottom: 10px;
    // }
  }
  button.fpc-btn {
    line-height: 28px;
    font-weight: bold;
    width: 180px;
  }
}
</style>
