<template>
  <div>
    <FormModal v-model:value="isShow" @modalClose="modalClose">
      <div v-loading="loading">
        <div class="container-t">
          <form @submit.prevent="save" autocomplete="false">
            <div class="row form1">
              <template>
                <div class="submit1">
                  {{ state == "INSERT" ? "Submit a" : "Update" }} Deal
                </div>
              </template>
            </div>
            <div class="form1">
              <div
                v-for="(deal, index) in dataDealSubmission"
                :key="`dealsubmission_${index}`"
                class="row"
              >
                <div class="border">
                  <div class="sub-title">{{ index + 1 }}. {{ deal.text }}</div>
                </div>
                <div
                  class="col-12 col-md-6"
                  v-for="(item, idx) in deal.data"
                  :key="`dealsubmission_item_${idx}`"
                >
                  <TextField
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    name="name"
                    field="gray"
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="
                      item.type === 'LINK' ||
                      (item.type === 'TEXTFIELD' &&
                        item.validate_type !== 'TEXT_LENGTH')
                    "
                    :subTitle="`${
                      item.validate_use &&
                      item.validate_type == 'TEXT_LENGTH' &&
                      item.validate_value
                        ? 'Text length: ' + item.validate_value
                        : ''
                    }`"
                  />
                  <TextArea
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    name="name"
                    field="gray"
                    v-model:value="item.ans"
                    :max-length="item.validate_value"
                    v-if="
                      item.type === 'TEXTFIELD' &&
                      item.validate_type === 'TEXT_LENGTH'
                    "
                    :subTitle="`${
                      item.validate_use &&
                      item.validate_type === 'TEXT_LENGTH' &&
                      item.validate_value
                        ? 'Text length: ' + item.validate_value
                        : ''
                    }`"
                  />
                  <NumberField
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    name="number"
                    field="gray"
                    :type="`number`"
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="item.type === 'NUMBER'"
                    :max-length="item.validate_value.length"
                  />
                  <NumberField
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    name="name"
                    field="gray"
                    :type="item.type"
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="item.type === 'CURRENCY'"
                  />
                  <FileField
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    name="name"
                    :autocomplete="false"
                    field="gray"
                    :id="`dealsubmission_item_${idx}`"
                    :acceptValue="item.validate_value"
                    :acceptType="item.validate_type"
                    :custom="true"
                    dataField=""
                    :fileUploaded="
                      item.answer.length ? JSON.parse(item.answer) : ''
                    "
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="item.type === 'FILE'"
                  />
                  <MultiSelectField
                    class="mb-4"
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    field="white"
                    :options="parseArray(item.answers)"
                    :name="`dealsubmission_item_${idx}`"
                    :placeholder="`Your option${
                      item.type === 'MULTI_SELECT' ? 's' : ''
                    }`"
                    :multiple="item.type === 'MULTI_SELECT' ? true : false"
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="
                      item.type === 'SINGLE_SELECT' ||
                      item.type === 'MULTI_SELECT'
                    "
                  />

                  <Checkbox
                    :indexField="idx"
                    color="black"
                    :title="item.question"
                    field="white"
                    :name="`dealsubmission_item_${idx}`"
                    v-model:value="item.ans"
                    :errorMsg="item.errorMsg"
                    v-if="item.type === 'BOOLEAN'"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  routeQuery &&
                  routeQuery.form === 'DEAL_SUBMISSION' &&
                  routeQuery.request_id
                "
              >
                <div class="col-12 col-md-6">
                  <TextField
                    :indexField="0"
                    color="black"
                    title="Passcode"
                    field="white"
                    name="passcode"
                    :autocomplete="false"
                    :errorMsg="errorMsg.passcode"
                    v-model:value="form.ReferralPasscode"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <TextField
                    :indexField="0"
                    v-model:value="form.DealSubmissionRequestId"
                    color="black"
                    field="white"
                    name="dealRequestId"
                    type="hidden"
                    :autocomplete="false"
                  />
                </div>
              </div>
            </div>
          </form>

          <ReCaptcha
            ref="recaptcha"
            v-if="isFromPublic"
            v-model:value="form.captcha"
            :errorMsg="errorMsg.captcha"
          />
          <div class="btn-form" v-if="dataDealSubmission.length > 0">
            <NewButton
              @click="save(true)"
              :loading="loading"
              color="transparent"
              v-if="
                (!isFromPublic && state === 'INSERT') ||
                (state === 'UPDATE' && dataDeal && dataDeal.is_draft === true)
              "
              class="me-2"
            >
              {{ `Save Draft` }}
            </NewButton>
            <NewButton @click="save(false)" :loading="loading" color="blue">
              {{
                state === "INSERT" ||
                (state === "UPDATE" && dataDeal && dataDeal.is_draft === true)
                  ? "Submit Form"
                  : "Update"
              }}
            </NewButton>
          </div>
        </div>
      </div>

      <Dialog ref="dialog" />
    </FormModal>
  </div>
</template>

<script src="./DealAddUpdateModal.js"></script>

<style lang="scss" scoped>
.container-t {
  padding: 40px !important;
}
.form1 {
  padding-bottom: 10px;
  :deep() .custom-mb {
    margin-bottom: 25px !important;
  }
}
.submit1 {
  color: #000 !important;
  margin-left: 15px;
  padding-bottom: 15px;
  font-size: 1.8rem;
  display: block;
  font-weight: 650;
}
.sub-title {
  color: rgb(26, 156, 231) !important;
  font-size: 1rem;
  font-weight: 600;
}
.border {
  border: none;
  border-radius: 5px;
  background: rgb(213, 227, 240);
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.btn-form {
  display: flex;
  justify-content: center;
}
.pass {
  padding: 0px !important;
}
</style>
