import AdminServices from '../../../services/admin'
import notify from '../../../services/notify'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
	data() {
		return {
			loading: false,
			data: {
				token: '',
				expires_at: 0
			},
			daysLeft: 0,
			editIgAT: true,
			oldIgAT: ''
		}
	},
	components: {
	},
	mounted() {
		this.getIgAT()
	},
	computed: {
	},
	methods: {
		getIgAT() {
			this.loading = true
			AdminServices.getIgAccessToken().then(resp => {
				if (resp.data.ok) {
					this.data.token = resp.data.d.access_token
					this.data.expires_at = resp.data.d.expires_at

					let expiresDate = moment(this.data.expires_at);
					var todaysDate = moment();
					this.daysLeft = expiresDate.diff(todaysDate, 'days');

					this.editIgAT = this.data.token.length === 0
					this.loading = false
				}
			})
		},
		updateIgAT() {
			if (this.data.token.indexOf('*') > 0) {
				notify.error('Access token should not contains asterisk(*)')
				return
			}

			this.loading = true
			AdminServices.updateIgAccessToken(this.data.token).then(resp => {
				if (resp.data.ok) {
					notify.success('Update successfully!')
					this.getIgAT()
					this.loading = false
				}
			})
		},
		toggleEditIgAT() {
			this.editIgAT = !this.editIgAT
			if (this.editIgAT) {
				this.oldIgAT = this.data.token
				this.data.token = ''
			} else {
				this.data.token = this.oldIgAT
			}
		}
	}
})