<template>
  <div class="text-field-wrap">
    <label v-if="title" :class="`title title-color-${color}`" :for="name"
      >{{ title }}
      <template v-if="fieldRequire"
        ><small class="require">*</small></template
      ></label
    >
    <div
      :class="`text-field ${size ? 'size-' + size : ''} ${
        hideError ? 'hide-error' : ''
      }`"
    >
      <div v-if="subTitle" class="sub-title">{{ subTitle }}</div>
      <input
        :class="[`field-color-${field}`, className, type]"
        v-model="_value"
        :disabled="disabled"
        :id="name"
        :placeholder="placeholder"
        :tabindex="tabindex"
        :type="
          type !== 'password' ? type : !isShowPassword ? 'password' : 'text'
        "
        :autocomplete="autocomplete ? '' : 'off'"
        :autofill="autocomplete ? '' : 'off'"
        :maxlength="maxLength"
        :index="indexField"
        :min="min"
      />
      <i
        :class="`pointer icon icon-color-${iconPasswordColor}`"
        v-if="type === 'password'"
        @click="toggleShowPassword"
      >
        <template v-if="!isShowPassword">
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
              fill="none"
            ></path>
            <path
              class="eyes"
              d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
            ></path>
          </svg>
        </template>
        <template v-if="isShowPassword">
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
              class="eyes"
              d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
            ></path>
          </svg>
        </template>
      </i>
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
i.icon {
  position: absolute;
  top: 5px;
  right: 10px;
  &.icon-color-white {
    path.eyes {
      fill: #fff;
    }
  }
}
.text-field {
  position: relative;
}
.title {
  margin-top: 0px;
  .require {
    color: #f52727;
  }
}
input {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 4px;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 25px;
  font-size: 14px;
  background: #494b54;
  color: #949ba2;
  border: 1px solid #70707038;
  &.password {
    padding-right: 24px;
  }
  &:disabled {
    color: #949ba2;
    opacity: 0.5;
    cursor: no-drop;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:-webkit-autofill {
    background: #fff;
    box-shadow: unset;
    color: #949ba2;
    -webkit-text-fill-color: #949ba2;
  }
}
.md-error {
  position: absolute;
  top: 33px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
  margin-top: 3px;
  margin-bottom: 3px;
}

.hide-error {
  // input {
  //     margin-bottom: 0;
  // }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #35495e;
}
.field-color-gray {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #35495e;
}
</style>
