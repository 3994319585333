<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1723 22.4995H18.375V23.2495H19.4888C19.5774 23.2495 19.663 23.2182 19.7307 23.1611C19.7984 23.104 19.8436 23.0248 19.8585 22.9375L22.6697 6.43752L21.9303 6.31152L19.1723 22.4995Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M22.9401 2.6309L18.4401 1.8374L18.3099 2.57601L22.4427 3.30487L22.0581 5.56232L22.7974 5.68832L23.2446 3.06332C23.2613 2.96563 23.2387 2.86531 23.1816 2.78428C23.1245 2.70325 23.0377 2.64811 22.9401 2.6309Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M17.25 0.75H1.125C1.02554 0.75 0.930161 0.789509 0.859835 0.859835C0.789509 0.930161 0.75 1.02554 0.75 1.125V17.4286H1.5V1.5H16.875V22.5H1.5V17.25H0.75V22.875C0.75 22.9745 0.789509 23.0698 0.859835 23.1402C0.930161 23.2105 1.02554 23.25 1.125 23.25H17.25C17.3495 23.25 17.4448 23.2105 17.5152 23.1402C17.5855 23.0698 17.625 22.9745 17.625 22.875V1.125C17.625 1.02554 17.5855 0.930161 17.5152 0.859835C17.4448 0.789509 17.3495 0.75 17.25 0.75Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M4.39021 13.2343L3.85986 13.7646L4.98486 14.8896C5.01969 14.9244 5.06103 14.9521 5.10653 14.9709C5.15202 14.9898 5.20079 14.9995 5.25004 14.9994C5.25472 14.9994 5.25941 14.9994 5.26443 14.9992C5.31605 14.9972 5.36672 14.9846 5.41323 14.9621C5.45975 14.9396 5.50112 14.9078 5.53475 14.8685L7.78475 12.2435L7.21532 11.7554L5.22885 14.0729L4.39021 13.2343Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M2.625 14.8125C2.6254 15.1605 2.76381 15.4941 3.00986 15.7401C3.25592 15.9862 3.58953 16.1246 3.9375 16.125H5.8125C6.16047 16.1246 6.49408 15.9862 6.74014 15.7401C6.9862 15.4941 7.1246 15.1605 7.125 14.8125V14.625H6.375V14.8125C6.37484 14.9616 6.31552 15.1046 6.21007 15.2101C6.10461 15.3155 5.96163 15.3748 5.8125 15.375H3.9375C3.78837 15.3748 3.64538 15.3155 3.53993 15.2101C3.43448 15.1046 3.37516 14.9616 3.375 14.8125V12.9375C3.37516 12.7884 3.43448 12.6454 3.53993 12.5399C3.64538 12.4345 3.78837 12.3752 3.9375 12.375H5.625V11.625H3.9375C3.58953 11.6254 3.25592 11.7638 3.00986 12.0099C2.76381 12.2559 2.6254 12.5895 2.625 12.9375V14.8125Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M4.39021 18.8593L3.85986 19.3896L4.98486 20.5146C5.01969 20.5494 5.06103 20.5771 5.10653 20.5959C5.15202 20.6148 5.20079 20.6245 5.25004 20.6244C5.25472 20.6244 5.25941 20.6244 5.26443 20.6242C5.31605 20.6222 5.36672 20.6096 5.41323 20.5871C5.45975 20.5646 5.50112 20.5328 5.53475 20.4935L7.78475 17.8685L7.21532 17.3804L5.22885 19.6979L4.39021 18.8593Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M2.625 20.4375C2.6254 20.7855 2.76381 21.1191 3.00986 21.3651C3.25592 21.6112 3.58953 21.7496 3.9375 21.75H5.8125C6.16047 21.7496 6.49408 21.6112 6.74014 21.3651C6.9862 21.1191 7.1246 20.7855 7.125 20.4375V20.25H6.375V20.4375C6.37484 20.5866 6.31552 20.7296 6.21007 20.8351C6.10461 20.9405 5.96163 20.9998 5.8125 21H3.9375C3.78837 20.9998 3.64538 20.9405 3.53993 20.8351C3.43448 20.7296 3.37516 20.5866 3.375 20.4375V18.5625C3.37516 18.4134 3.43448 18.2704 3.53993 18.1649C3.64538 18.0595 3.78837 18.0002 3.9375 18H5.625V17.25H3.9375C3.58953 17.2504 3.25592 17.3888 3.00986 17.6349C2.76381 17.8809 2.6254 18.2145 2.625 18.5625V20.4375Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M8.625 17.25H14.25V18H8.625V17.25Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M8.625 19.125H11.4286V19.875H8.625V19.125Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M11.25 19.125H14.25V19.875H11.25V19.125Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M8.625 11.625H14.25V12.375H8.625V11.625Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M8.625 13.5H11.3709V14.25H8.625V13.5Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M11.25 13.5H14.25V14.25H11.25V13.5Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M12.8933 3.78537L9.52301 2.28251C9.47494 2.26108 9.42291 2.25 9.37029 2.25C9.31766 2.25 9.26563 2.26108 9.21757 2.28251L5.84725 3.78537C5.77195 3.81895 5.70981 3.87642 5.67046 3.94888C5.63111 4.02134 5.61674 4.10474 5.62957 4.18619L5.91049 5.96983C5.91096 5.97274 5.91143 5.97569 5.91199 5.9786C6.19654 7.52437 7.06321 8.9021 8.33341 9.82783L9.15096 10.4299C9.21507 10.4772 9.29255 10.5027 9.37217 10.503C9.4518 10.5032 9.52943 10.4781 9.59383 10.4313L10.3727 9.8651C11.6598 8.93977 12.5389 7.55225 12.8257 5.99323C12.8262 5.99032 12.8268 5.98741 12.8272 5.98451L13.1109 4.18633C13.1238 4.10487 13.1094 4.02144 13.0701 3.94896C13.0308 3.87647 12.9686 3.81897 12.8933 3.78537ZM12.0871 5.86329C11.8336 7.2307 11.0613 8.4472 9.93171 9.25844L9.37497 9.6633L8.7784 9.22394C7.66372 8.4124 6.90235 7.20462 6.65083 5.84894L6.41529 4.35363L9.37029 3.0356L12.3254 4.3533L12.0871 5.86329Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
<path d="M8.13125 5.7373L7.60095 6.2676L8.72595 7.3926C8.79628 7.46292 8.89165 7.50242 8.9911 7.50242C9.09055 7.50242 9.18592 7.46292 9.25625 7.3926L11.5062 5.1426L10.976 4.6123L8.99112 6.59732L8.13125 5.7373Z" fill="#5F6164" stroke="#5F6164" stroke-width="0.171429"/>
</svg>
</template>
