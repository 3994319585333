import { defineComponent } from "vue";
import { DEAL_STAGES } from "../../../../../misc/constants";

export default defineComponent({
  name: "reviewRate",
  props: {
    dataReviewerRate: { type: Array, default: null },
    currentStage: { type: Number, default: null },
  },
  data() {
    return {
      loading: false,
      isShow: false,
    };
  },
  computed: {
    stageId: {
      get() {
        return DEAL_STAGES[this.currentStage]
          ? DEAL_STAGES[this.currentStage].id
          : "";
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
    },
  },
});
