<template>
  <modal name="modalRegister" :width="1366" :height="768">
    <div class="boxFix">
      <div class="row">
        <div class="col-lg-6">
          <div class>
            <div
              class="align-items-center justify-content-center"
              v-loading="loading"
            >
              <div class="close-page" @click.prevent="close">
                <i class="pe pe-7s-close"></i>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <router-link :to="homePath" class="logoLogin">
                  <LogoIcon sizeWidth="200px" />
                </router-link>
              </div>
              <div class="w-45 wrapper">
                <form id="loginForm" class>
                  <div class="accounType">
                    <label for>User Type</label>
                    <div class="row">
                      <div class="col-4">
                        <button class="btn btnBlue w-100">Founder</button>
                      </div>
                      <div class="col-4">
                        <button class="btn btnBlue w-100">Rokker Team</button>
                      </div>
                      <div class="col-4">
                        <button class="btn btnBlue w-100">Investor</button>
                      </div>
                    </div>
                  </div>

                  <!-- <md-field
                    v-bind:class="{ 'md-invalid': messageError.email, 'md-has-value': clicked }"
                  >
                    <label>
                      Username or email
                      <small>*</small>
                    </label>
                    <md-input
                      name="email"
                      v-model="model.email"
                      placeholder="Enter your username or email"
                    ></md-input>
                    <small class="md-error">{{messageError.email}}</small>
                  </md-field> -->

                  <TextField
                    title="Username or email"
                    name="email"
                    :indexField="1"
                    :fieldRequire="true"
                    v-model:value="model.email"
                    placeholder="Enter your username or email"
                    class="m-0"
                    :errorMsg="messageError.email"
                  />

                  <!-- <md-field
                    v-bind:class="{ 'md-invalid': messageError.password, 'md-has-value': clicked }"
                  >
                    <label>
                      Password
                      <small>*</small>
                    </label>
                    <md-input
                      name="password"
                      v-model="model.password"
                      type="password"
                      class="password"
                      placeholder="Enter your password"
                    ></md-input>
                    <small class="md-error">{{messageError.password}}</small>
                  </md-field> -->

                  <TextField
                    title="Password"
                    name="password"
                    type="password"
                    :indexField="1"
                    :fieldRequire="true"
                    v-model:value="model.password"
                    placeholder="Enter your password"
                    :errorMsg="messageError.password"
                  />

                  <div class="form-group text-center mb-lg-0">
                    <button
                      @click.prevent="validate"
                      class="btn button btn-large btn-primary btn-bounce btn-active w-100 btnReg"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="backgroundLoginPopup backgroundImg"
            :style="{
              'background-image': `url(${require('../../../assets/images/login-banner.png')})`,
            }"
          ></div>
        </div>
      </div>

      <div class="container-fluid"></div>
      <verify-2fa-modal ref="verify2FaModal" />
    </div>
  </modal>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.boxFix {
  .logoLogin {
    img {
      object-fit: cover;
      width: 284px;
      height: auto;
    }
  }
}
.wrapper {
  background-color: #fff;
}
#loginForm {
  font-size: 0.875rem;
  margin-top: 2rem;
  padding: 2rem;
  color: #000;
  opacity: 0.8;
  background-color: #fff;
  .accounType {
    .row {
      margin-left: -10px;
      margin-right: -10px;
      [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .btn {
      font-size: 0.875rem;
      padding: 0.375rem 0.75rem;
      &.btnBlue {
        background-color: #007bff;
      }
    }
  }
}
</style>
