<template>
  <div class="adminContent p-3">
    <div class="data p-3">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Company Name</th>
                  <th>Fueled year</th>
                  <th>Website</th>
                  <th>Icon</th>
                  <th class="text-center">Managed By IDC</th>
                  <th>
                    Status
                    <select
                      class="form-control form-control-sm"
                      v-model="selectedStatus"
                      @change="getPortfolioData"
                    >
                      <option
                        v-for="(item, idx) in statusList"
                        :key="idx"
                        :value="item.code"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <Dragger
                v-model:value="tableData"
                component-tag="tbody"
                drag-element-tag="tr"
                :componentClass="''"
                @change="onEnd"
                handle=".handle"
              >
                <template #default="{ props, index }">
                  <td>{{ index + 1 }}</td>
                  <td>{{ props.name }}</td>
                  <td>{{ props.fueled }}</td>
                  <td>
                    <a
                      target="_blank"
                      :href="props.ext.Website"
                      v-if="props.ext.Website"
                      >{{ props.ext.Website }}</a
                    >
                  </td>
                  <td>
                    {{ $filters.getNameByCode(props.icon, portfolioIcons) }}
                  </td>
                  <td class="text-center">
                    <template
                      v-if="
                        props.managed_by && props.managed_by.includes('IDC')
                      "
                    >
                      <i class="fas fa-check"></i>
                    </template>
                  </td>
                  <td>{{ props.is_active ? "ACTIVE" : "INACTIVE" }}</td>
                  <td class="text-center">
                    <span class="nowrap">
                      <a
                        class="btn btn-primary btn-small me-1"
                        @click="editPortfolio(props.id)"
                      >
                        <i class="far fa-edit"></i>
                      </a>
                      <a
                        v-if="selectedStatus === ''"
                        class="btn btn-default btn-small handle all-scroll cursor-move handle"
                        ><i class="fas fa-bars"></i
                      ></a>
                      <!-- <a class="btn btn-danger btn-small" @click="removePortfolio(props.id)"><i class="far fa-trash-alt"></i></a> -->
                    </span>
                  </td>
                </template>
              </Dragger>
              <tfoot>
                <tr>
                  <td colspan="9">
                    <button class="btn btn-primary" @click="showAddCompany">
                      <i class="fas fa-plus me-1"></i>
                      <span>Add Company</span>
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12 text-right"></div>
      </div>
    </div>
    <popupAddEditCompany
      v-if="isShowAddCompany"
      :hideAddCompany="hideAddCompany"
      :confirmAddCompany="confirmAddCompany"
      :confirmUpdateCompany="confirmUpdateCompany"
      :infoCompanyEdit="infoCompanyEdit"
      :loading="modalLoading"
      :showFilePreview="showFilePreview"
      :hideFilePreview="hideFilePreview"
    />
    <!-- <filePreview
      v-if="isShowFilePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
      :hideFunc="hideFilePreview"
    /> -->
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.table thead th {
  vertical-align: top !important;
}
</style>
