<template>
  <div class="mapData">
    <div class="container">
      <topHeader></topHeader>
      <div class="mapContent">
        <div class="info">
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Number of startups</p>
            <p class="m-0 number text-center colorWhite">20</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">IDC Investment</p>
            <p class="m-0 number text-center colorWhite">41.9M</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Portfolio Valuation</p>
            <p class="m-0 number text-center colorWhite">69.5M</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">NET Profitability</p>
            <p class="m-0 number text-center colorWhite">66%</p>
          </div>
        </div>
        <div id="logarithmicScaleChart"></div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.mapData {
  display: block;
  overflow: hidden;
  .container {
    max-width: 1276px;
    width: 100%;
    background: #333333;
    .mapContent {
      .info {
        display: flex;
        height: 150px;
        #donutchart {
          width: 250px;
          height: 100%;
        }
        .item {
          padding-top: 20px;
          flex: 1;
          > p {
            line-height: 24px;
          }
          .number {
            font-size: 60px;
            line-height: 64px;
          }
        }
      }
      #logarithmicScaleChart {
        width: 100%;
        height: calc(100vh - 69px - 150px);
        padding: 10px 0;
      }
    }
  }
}
</style>