import { defineComponent } from "vue";

export default defineComponent({
  name: "banner-header",
  props: {
    bannerInfo: {
      default: Object,
    },
  },
});
