import { defineComponent } from "vue";
import moment from "moment";
import authHttp from "@/services/auth-http";
import adminServices from "@/services/admin";
import DealServices from "@/services/deal";
import { DEAL_STAGES } from "@/misc/constants";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import DealAddUpdateModal from "@/components/deal/DealAddUpdateModal";
import Dialog from "@/components/Dialog";
import generic from "@/misc/generic";
import appStore from "@/store/app";
import { PERMISSIONS } from "@/config/router";

const dealTypesData = [
  { deal_type: "", name: "All" },
  { deal_type: "PORTAL", name: "ADMIN" },
  { deal_type: "INVESTOR", name: "LP" },
  { deal_type: "PUBLIC", name: "GUEST" },
];

export default defineComponent({
  components: {
    Table,
    TextField,
    DealAddUpdateModal,
    SelectField,
    Dialog,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      page: {
        page: parseInt(this.$route.query.PageNumber) || 1,
        size: parseInt(this.$route.query.PageSize) || 10,
        sorts: [
          {
            id: "remaining_hours",
            sortDirection: "",
          },
        ],
      },
      permissionDealDelete: PERMISSIONS.Deal_Delete,
      tabs: [
        {
          isRejected: null,
          isDraft: null,
          isWaiting: null,
          isDeleted: null,
          name: "All",
          query: function () {
            return {};
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "Pre-Filtered",
          query: function () {
            return { filteredStage: "STAGE_1" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "First Pass Checklist (FPC)",
          query: function () {
            return { filteredStage: "STAGE_2" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "First Pass Analysis (FPA)",
          query: function () {
            return { filteredStage: "STAGE_3" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "In Depth Due Diligence",
          query: function () {
            return { filteredStage: "STAGE_4" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "Investment Committee Meeting",
          query: function () {
            return { filteredStage: "STAGE_5" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "Negotiation",
          query: function () {
            return { filteredStage: "STAGE_6" };
          },
        },
        {
          isRejected: false,
          isDraft: false,
          isWaiting: false,
          isDeleted: false,
          name: "Closing",
          query: function () {
            return { filteredStage: "STAGE_7" };
          },
        },
        {
          isRejected: true,
          isDeleted: false,
          name: "Rejected deals",
          query: function () {
            return { isRejected: true };
          },
        },
        {
          isDeleted: true,
          name: "Deleted deals",
          requiredPermissions: [PERMISSIONS.Deal_Read_Deleted_Deals],
          query: function () {
            return { isDeleted: true };
          },
        },
        {
          isDraft: true,
          name: "Draft",
          query: function () {
            return { isDraft: true };
          },
        },
        {
          isRejected: false,
          isDeleted: false,
          isWaiting: true,
          name: "Waiting",
          query: function () {
            return { isWaiting: true };
          },
        },
      ],
      tab_active: 0,
      name: this.$route.query.name || "",
      filteredStage: "",
      submittedBy: "",
      sortDirection: "",
      stageList: [],
      dealTypes: dealTypesData,
      dealAddUpdateModalKey: 0,
      confirmText: {
        header: "",
        body: "",
      },
      dataDeal: null,
      columns: [
        {
          id: "name",
          name: "Name",
        },
        {
          id: "status",
          name: "Status",
        },
        {
          id: "remaining_hours",
          name: "Remaining Days",
          sort: true,
          direction: this.$route.query.sortDirection || "",
        },
        {
          id: "created",
          name: "Submitted By",
        },
        {
          id: "score",
          name: "Score",
        },
        {
          id: "actions",
          name: "Actions",
          columnClass: "action-icon-group",
        },
      ],
      paramSearch: null,
    };
  },
  watch: {
    "page.page": function (v) {
      debounce(this.getAll);
    },
    name(v) {
      this.page.page = 1;
      debounce(this.getAll);
    },
    submittedBy(v) {
      this.page.page = 1;
      debounce(this.getAll);
    },
    paramSearch: {
      deep: true,
      handler(v) {
        this.filteredStage = v.filteredStage ? v.filteredStage : "";
        debounce(this.getAll);
      },
    },
    sortDirection(v) {
      debounce(this.getAll);
    },
    columns: {
      deep: true,
      handler: function (v) {
        let col = v.find((p) => typeof p.sort === "boolean" && p.sort);
        this.sortDirection = col && col.direction;
      },
    },
  },
  mounted() {
    this.stageList = generic.getJsonSessionStorage("stage-list") || null;
    if (
      generic.getJsonLocalStorage("TabActive") &&
      generic.getJsonLocalStorage("TabActive") < this.tabs.length
    ) {
      if (
        !this.hasPermissions(this.tabs[this.tab_active].requiredPermissions)
      ) {
        this.tab_active = 0;
      } else {
        this.tab_active = generic.getJsonLocalStorage("TabActive");
      }
    }
    this.$nextTick(() => {
      this.getStages();
      this.renderColumn();

      if (this.$route.query) {
        this.name = this.$route.query.name || "";
        this.submittedBy = this.$route.query.submittedBy || "";
        this.sortField = this.$route.query.sortField || "";
        this.page.sorts = [
          {
            id: "remaining_hours",
            sort:
              this.page.sorts &&
              (this.$route.query.sortDirection || this.page.sorts[0].sort),
          },
        ];
        this.filteredStage = this.$route.query.filteredStage || "";
        this.sortDirection = this.$route.query.sortDirection || "";
        this.paramSearch = this.tabs[this.tab_active].query();
      }

      debounce(this.getAll);
    });
  },
  computed: {
    profile() {
      return appStore.state.profile;
    },
  },
  methods: {
    renderColumn() {
      if (
        this.tabs[this.tab_active].isDraft ||
        this.tabs[this.tab_active].isWaiting
      ) {
        this.columns = [
          {
            id: "name",
            name: "Name",
          },
          {
            id: "created",
            name: "Submitted By",
          },
          {
            id: "actions",
            name: "Actions",
            columnClass: "action-icon-group",
          },
        ];
      } else {
        this.columns = [
          {
            id: "name",
            name: "Name",
          },
          {
            id: "status",
            name: "Status",
          },
          {
            id: "remaining_hours",
            name: "Remaining Days",
            sort: true,
            direction: this.$route.query.sortDirection || "asc",
          },
          {
            id: "created",
            name: "Submitted By",
          },
          {
            id: "score",
            name: "Score",
          },
          {
            id: "actions",
            name: "Actions",
            columnClass: "action-icon-group",
          },
        ];
      }
    },
    setParams(data) {
      let params = {
        name: data.name,
        PageNumber: data.PageNumber,
        PageSize: data.PageSize,
        submittedBy: data.submittedBy,
        sortField: data.sortField,
        sortDirection: data.sortDirection,
        isDeleted: data.isDeleted,
        isDraft: data.isDraft,
        isRejected: data.isRejected,
        isWaiting: data.isWaiting,
      };
      params = Object.assign(params, this.paramSearch);
      window.history.replaceState(
        {},
        document.querySelector("title").text,
        `${this.$router.currentRoute.value.path}?${authHttp.buildParams(
          params
        )}`
      );
    },
    calcRemainingDayByDueDate(timestamp) {
      var days = moment(timestamp).diff(moment(), "days");
      return (days > 0 ? days : 0) + " day(s)";
    },
    getStageNameById(stage_id) {
      const item = DEAL_STAGES.find((x) => x.id == stage_id);
      return item && item.name ? item.name : null;
    },
    async getStages() {
      if (generic.getJsonSessionStorage("stage-list")) return;
      const res = await adminServices.getDealStages();
      if (res && res.data && res.data.ok) {
        this.stageList = [{ stage_id: "", name: "All" }, ...res.data.d];
        generic.setJsonSessionStorage("stage-list", this.stageList);
      }
    },
    async getAll() {
      this.loading = true;
      let params = {
        name: this.name,
        submittedBy: this.submittedBy,
        filteredStage: this.filteredStage,
        isRejected: this.tabs[this.tab_active].isRejected,
        isDraft: this.tabs[this.tab_active].isDraft,
        isDeleted: this.tabs[this.tab_active].isDeleted,
        isWaiting: this.tabs[this.tab_active].isWaiting,
        sortField: this.sortField,
        sortDirection: this.sortDirection,
        paging: {
          page: this.page.page,
          size: this.page.size,
        },
      };
      const res = await adminServices.getAllDeals(params);
      if (res && res.data && res.data.ok) {
        this.page.size = res.data.d.s;
        this.page.page = res.data.d.p;
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      if (generic.getJsonSessionStorage("deal-query"))
        generic.removeSessionStorage("deal-query");
      generic.setJsonSessionStorage("deal-query", {
        PageNumber: params.paging.page,
        PageSize: params.paging.size,
        ...params,
      });
      this.setParams({
        PageNumber: params.paging.page,
        PageSize: params.paging.size,
        ...params,
      });
      this.loading = false;
    },
    async view(props) {
      if (this.tabs[this.tab_active].isDraft) {
        DealServices.getSpecifyDeals(props.deal_id)
          .then((resp) => {
            if (resp.data.ok) {
              this.dataDeal = resp.data.d;
            }
          })
          .finally(() => {
            this.dealAddUpdateModalKey++;
            this.$nextTick(() => {
              this.$refs.dealAddUpdateModal.showUpdate(this.dataDeal);
            });
          });
      } else {
        this.$router.push({ path: `/rfm/deals/${props.deal_id}` });
      }
    },
    async addDeal() {
      this.dealAddUpdateModalKey++;
      this.$nextTick(() => {
        this.$refs.dealAddUpdateModal.showAdd();
      });
    },
    async remove(param) {
      let message = {
        title: "Confirmation",
        body: "Are you sure? This action cannot be undone!",
      };

      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.confirmFunc(param);
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    async confirmFunc(param) {
      this.loading = true;
      if (param) {
        const resp = await DealServices.removeDeal(param.deal_id);
        if (resp && resp.data && resp.data.ok) {
          this.getAll();
        }
      }
      this.loading = false;
    },
    avgTotalScore: function (param) {
      let sumTotalScore = 0;
      let data = param.fpc_checklist;
      if (data.length) {
        data.map((p) => {
          sumTotalScore += p.score_total;
        });
        return Math.round(sumTotalScore / data.length);
      }
      return 0;
    },
    changeTab: function (idx) {
      this.tab_active = idx;
      this.page.page = 1;
      this.renderColumn();
      this.rows = [];
      debounce(this.getAll);
      generic.setJsonLocalStorage("TabActive", this.tab_active);
    },
    updatePaging(v) {
      this.page = Object.assign(this.page, v);
    },
  },
});
