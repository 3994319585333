<template>
  <div v-if="isReady" class="adminContent p-3">
    <!-- <h5 class="m-0 pt-3 pb-3 text-white-50">Recent Survey</h5>
        <div class="data">
            <div class="surveyDetailsPage">
                <div class="boxServey pl-3 pr-3 pb-1 pt-0 mb-0" v-for="item in dataSurvey" :key="item">
                    <p class="openTab mb-3" :class="profile.welcome_survey_stt" v-if="profile && profile.welcome_survey_stt">{{profile.welcome_survey_stt}}</p>
                    <div class="row">
                        <div class="col-6">
                            <p class="surveyName">{{item.name}}</p>
                            <p class="dateTimne">Created: {{$filters.date(item.created_at)}} | Last edited: {{$filters.date(item.finished_at?item.finished_at:item.created_at)}}</p>
                        </div>
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-6 text-center borderRight"></div>
                                <div class="col-6 text-center borderRight">
                                    <router-link class="btn btn-primary pl-0 pr-0" :to="`/rfm/survey/${item.survey_id}`" style="width: 90%;">Details</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-12">
                        <p class="showRecent">Showing {{paging.page}} of {{paging.total}} recent surveys.</p>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script src="./index.js"></script>
