<template>
  <section class="section-2" id="pin-parallax">
    <div class="logo-wrapper">
      <div class="logo-inner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="191"
          height="194"
          viewBox="0 0 191 194"
          fill="none"
        >
          <path
            d="M61.2287 15.2449C70.2737 10.1639 79.3227 5.08278 88.3756 0.00170898C88.3822 48.2667 88.3822 96.5317 88.3756 144.797L-0.000244141 193.751V167.173C20.4107 155.936 40.8204 144.697 61.2287 133.456C61.2221 122.614 61.2221 111.771 61.2287 100.927C47.8112 108.231 34.3983 115.536 20.99 122.841C20.9834 113.981 20.9834 105.126 20.99 96.2744C34.3943 88.9707 47.8026 81.6663 61.2149 74.3613C61.24 54.6623 61.2446 34.9569 61.2287 15.2449Z"
            fill="#fff"
          />
          <path
            d="M102.624 -0.000244141C111.676 5.07432 120.725 10.1554 129.771 15.243C129.771 34.9523 129.771 54.6597 129.771 74.3652C143.182 81.6611 156.586 88.9629 169.984 96.2705C169.977 105.13 169.977 113.985 169.984 122.837C156.58 115.533 143.175 108.231 129.771 100.931C129.764 111.774 129.764 122.617 129.771 133.46C150.175 144.71 170.585 155.949 191 167.177C190.993 176.036 190.993 184.892 191 193.743C161.541 177.432 132.086 161.116 102.634 144.797L102.624 -0.000244141Z"
            fill="#fff"
          />
        </svg>
      </div>
    </div>

    <div class="our-vision">
      <div class="our-vision-content">
        <h2
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          Our Mission
        </h2>
        <p
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          We are DRIVEN to serve Ultra-High Net Worth Individuals, Global Family
          Offices, and Institutions who provide the FUEL for our FOCUS on
          helping disruptive founders who are transforming the way consumers and
          businesses transact, consume, work and enjoy their life journey.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.handleScrollAnimation();
  },
  methods: {
    handleScrollAnimation() {
      const controller = new ScrollMagic.Controller({ vertical: true });

      // step pin parallax
      const tween = new TimelineMax();
      new ScrollMagic.Scene({
        triggerElement: "#pin-parallax",
        duration: 1000,
        triggerHook: 0,
      })
        .setTween(tween)
        .setPin("#pin-parallax")
        .addTo(controller);

      // step pin logo and scale
      const tween1 = new TimelineMax();
      tween1.to(".logo-inner", 1, {
        scale: 1,
        delay: 0.1,
        ease: Linear.easeNone,
      });

      new ScrollMagic.Scene({
        triggerElement: ".section-2",
        duration: "50%",
        triggerHook: 0.5,
      })
        .setTween(tween1)
        .setPin(".logo-wrapper")
        .addTo(controller);

      // step super scale
      const tween2 = new TimelineMax();
      tween2.to(".logo-inner", 1, {
        scale: 20,
        alpha: 0,
        fill: "blur(20px)",
        delay: 0.01,
        ease: Linear.easeNone,
      });
      new ScrollMagic.Scene({
        triggerElement: "#pin-parallax",
        triggerHook: 0,
        duration: 1200,
      })
        .setTween(tween2)
        .addTo(controller);

      // step show our vision section
      const tween3 = new TimelineMax();
      tween3.to(".our-vision", 1, {
        opacity: 1,
        ease: Linear.easeNone,
      });
      new ScrollMagic.Scene({
        triggerElement: "#pin-parallax",
        triggerHook: 0,
        duration: 600,
      })
        .setTween(tween3)
        .addTo(controller);

      // step show our vision content
      const tween4 = new TimelineMax();
      tween4.to(".our-vision-content", 1, {
        filter: "blur(0px)",
        ease: Linear.easeNone,
      });
      new ScrollMagic.Scene({
        triggerElement: "#pin-parallax",
        triggerHook: 0,
        duration: 600,
      })
        .setTween(tween4)
        .addTo(controller);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  color: #fff;
  background: #000;
  height: 100vh;
  z-index: 6;
  overflow: hidden;

  .logo-inner {
    position: absolute;
    top: -14%;
    transform: scale(0);
  }
}

.logo-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.our-vision {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #fff;
  color: #000;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  .our-vision-content {
    width: 80vw;
    margin: 0 auto;
    filter: blur(30px);
    padding: 20px;
    overflow: hidden;
    text-align: center;
    @media (min-width: 576px) {
      width: 70vw;
    }

    h2 {
      font-size: 70px;
      font-weight: 700;
      line-height: 80px;
      margin: 0;
      padding-bottom: 20px;

      @media (min-width: 576px) {
        padding-bottom: 40px;
        font-size: 100px;
        line-height: 116px;
      }
    }
    p {
      font-family: "SF Pro Text";
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: justify;
      margin: 0;
      text-align: center;

      @media (min-width: 576px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
</style>
