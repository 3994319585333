const processNotify = (type, title, message) => {
    const vm = window.vm || null
    if (vm) {
        vm.$refs.notify.setNotify(type, title, message)
    }
    // window.toastr[type](message || '', title || '')
}

const showNotify = {
    success(title, message) {
        processNotify('success', title, message)
    },
    info(title, message) {
        processNotify('info', title, message)
    },
    warn(title, message) {
        processNotify('warn', title, message)
    },
    error(title, message) {
        processNotify('error', title, message)
    },
    hidden() {
        processNotify('hidden', null, null)
    }
}

export default showNotify