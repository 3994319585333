<template>
  <div
    :class="`pointer btn btn-primary ${customClass} ${theStyle} bg-btn-${color} ${
      disabled ? 'disabled' : ''
    }`"
    @click="click"
  >
    <div :class="{ loading }" class="text"><slot /></div>
    <div
      class="the-spinner"
      :class="{ 'spinner-center': spinnerCenter }"
      v-if="loading"
    >
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.disabled {
  cursor: no-drop;
}
.btn-primary {
  position: relative;
  &:hover {
    background: rgb(20, 126, 226);
  }
}

.text {
  color: white;
  opacity: 0.8;
  margin-top: 2px;
  &:hover {
    opacity: 0.7;
  }
}
// .button {
//     position: relative;
//     cursor: pointer;
//     display: inline-block;
//     padding: 8px 20px;
//     background: #252ad4;
// }
.loading {
  opacity: 0 !important;
}
.the-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .spinner-border {
    color: white;
  }
  &.spinner-center {
    justify-content: center;
  }
}

.button.simple {
  padding: 0;
  background: unset;
  .text {
    font-size: 1.2em;
  }
}
.bg-btn-blue {
  background: rgb(20, 126, 226);
  justify-content: center;
  padding: 15px;
  width: 15%;
  border-radius: 10px !important;
}
.bg-btn-transparent {
  .text {
    color: black;
  }
  background: #d5e3f0;
  justify-content: center;
  padding: 15px;
  width: 15%;
  border-radius: 10px !important;
}
</style>
