<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideAddCompany"></div>
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title colorWhite">{{ typeName }} Company</h4>
          </div>
          <div class="modal-body">
            <div id="exTab1" class="container-fliud p-0">
              <ul class="nav nav-pills mb-3" id="memberTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="active show"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    >Profile</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    data-bs-toggle="tab"
                    data-bs-target="#social"
                    type="button"
                    role="tab"
                    aria-controls="social"
                    aria-selected="true"
                    >Social</a
                  >
                </li>
              </ul>

              <div class="tab-content clearfix">
                <div
                  class="tab-pane fade show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="row mb-0">
                    <div class="col-6">
                      <div class="form-group mt-3">
                        <label class="pointer">
                          <input
                            type="checkbox"
                            v-model="managed_by_idc"
                            class="me-1"
                            style="vertical-align: text-top"
                          />
                          <span class="mb-0">Managed By IDC</span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mt-3">
                        <label class="pointer">
                          <input
                            type="checkbox"
                            v-model="formData.is_active"
                            class="me-1"
                            style="vertical-align: text-top"
                          />
                          <span class="mb-0">Active</span>
                        </label>
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <TextField
                          title="Name"
                          name="company"
                          fieldRequire
                          :autocomplete="false"
                          :errorMsg="error.name"
                          placeholder="Enter name of company"
                          v-model:value="formData.name"
                          :max-length="255"
                        />
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <TextField
                          type="number"
                          title="Fueled year"
                          name="fuel"
                          fieldRequire
                          :autocomplete="false"
                          :errorMsg="error.fueled"
                          placeholder="Enter Fueled year"
                          v-model:value="formData.fueled"
                        />
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <label for="background" class="mb-0">Background</label>
                        <label class="form-control center-block input-file">
                          <span>Choose File</span>
                          <input
                            type="file"
                            id="background"
                            :value="formData.Background"
                            class="d-none"
                            ref="background"
                            @change="renderBackground"
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                        </label>
                        <div class="fileinfo mt-2" v-if="backgroundToUpload">
                          <i class="fas fa-file-image"></i>
                          <span class="info">{{ backgroundToUpload }}</span>
                          <span class="close" @click="removeBackgroundUploaded"
                            ><i class="fas fa-times"></i
                          ></span>
                        </div>
                        <div
                          class="fileinfo mt-2"
                          v-else-if="backgroundUploaded"
                        >
                          <i class="far fa-file-alt me-1"></i>
                          <a
                            class="info"
                            href="javascript:void(0)"
                            @click="
                              showFilePreview(
                                formData.background,
                                fileTypes.IMAGE
                              )
                            "
                            >{{ backgroundUploaded }}</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <TextField
                          title="Primary Color"
                          name="primaryColor"
                          :autocomplete="false"
                          v-model:value="formData.ext.PrimaryColor"
                          :max-length="255"
                          placeholder="Enter Primary Color"
                        />
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <TextField
                          title="Corner Label"
                          name="cornerLabel"
                          :autocomplete="false"
                          v-model:value="formData.corner_label"
                          :max-length="12"
                          placeholder="Enter Corner Label"
                        />
                      </div>
                    </div>
                    <div class="col col-12">
                      <div class="form-group mb-1">
                        <label for="icon" class="mb-0">Icon</label>
                        <select
                          id="icon"
                          class="form-control"
                          v-model="formData.icon"
                        >
                          <option
                            v-for="(item, idx) in portfolioIcons"
                            :key="idx"
                            :value="item.code"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-0 custom-row">
                    <div class="col col-md-6 col-12">
                      <div class="form-group mb-1">
                        <div class="custom-file">
                          <label for="customFileLang" class="mb-0"
                            ><small class="colorRed">*</small> Photo</label
                          >
                          <label
                            class="custom-file-label mb-0 pointer"
                            for="customFileLang"
                          >
                            <i
                              class="fas fa-cloud-upload-alt"
                              :class="{ dataPhoto: dataPhoto }"
                            ></i>
                            <p class="text-center mb-0" v-if="!dataPhoto">
                              click to upload
                            </p>
                            <img
                              :src="dataPhoto"
                              alt=""
                              class="dataImg"
                              v-else
                            />
                            <input
                              type="file"
                              id="customFileLang"
                              class="custom-file-input"
                              ref="fileimg"
                              accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
                              @change="renderFileImg"
                            />
                          </label>
                        </div>
                        <span
                          class="texterror colorRed"
                          v-if="error.photo && !error.invalidImage"
                          ><small>Please choose one picture</small></span
                        >
                        <span
                          class="texterror colorRed"
                          v-if="error.invalidImage"
                          ><small>Only choose image files</small></span
                        >
                      </div>
                    </div>
                    <div class="col col-md-6 col-12">
                      <div class="form-group mb-1">
                        <div class="custom-file">
                          <label for="customVideoFile" class="mb-0"
                            >Video</label
                          >
                          <input
                            type="file"
                            id="customVideoFile"
                            ref="video"
                            style="width: 100%"
                            accept="video/mp4"
                            @change="renderVideo"
                          />
                          <template>
                            <transition name="fade">
                              <div class="fileinfo mt-2" v-if="videoToUpload">
                                <i class="far fa-file-alt"></i>
                                <span class="info">{{ videoToUpload }}</span>
                                <span class="close" @click="removeVideoUploaded"
                                  ><i class="fas fa-times"></i
                                ></span>
                              </div>
                              <div
                                class="fileinfo mt-2"
                                v-else-if="videoUploaded"
                              >
                                <i class="far fa-file-alt"></i>
                                <a
                                  class="info"
                                  href="javascript:void(0)"
                                  @click="
                                    showFilePreview(
                                      formData.video,
                                      fileTypes.VIDEO
                                    )
                                  "
                                  >{{ videoUploaded }}</a
                                >
                              </div>
                            </transition>
                          </template>
                        </div>
                        <span
                          class="texterror colorRed"
                          v-if="error.invalidVideo"
                          ><small>Only choose video files</small></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="social"
                  role="tabpanel"
                  aria-labelledby="social-tab"
                >
                  <div class="row">
                    <div class="col col-12">
                      <TextField
                        title="Website"
                        :autocomplete="false"
                        placeholder="Link to Website"
                        v-model:value="formData.ext.Website"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Facebook"
                        :autocomplete="false"
                        placeholder="Link to Facebook"
                        v-model:value="formData.ext.Facebook"
                      />
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Twitter"
                        :autocomplete="false"
                        placeholder="Link to Twitter"
                        v-model:value="formData.ext.Twitter"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Linked in"
                        :autocomplete="false"
                        placeholder="Link to Linkedin"
                        v-model:value="formData.ext.Linkedin"
                      />
                    </div>
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Instagram"
                        :autocomplete="false"
                        placeholder="Link to Instagram"
                        v-model:value="formData.ext.Instagram"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-md-6 col-12">
                      <TextField
                        title="Medium"
                        :autocomplete="false"
                        placeholder="Link to Medium"
                        v-model:value="formData.ext.Medium"
                      />
                    </div>
                    <div class="col col-md-6 col-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button
              class="btn btn-secondary"
              :disabled="loading"
              @click="hideAddCompany"
            >
              <template v-if="!loading"> Cancel </template>
              <div v-else class="btn-loader"></div>
            </button>
            <button
              class="btn btn-primary"
              :disabled="loading"
              @click="validateForm"
            >
              <template v-if="!loading"> Publish </template>
              <div v-else class="btn-loader"></div>
            </button>
          </div>
          <!-- <filePreview v-if="isShowFilePreview"
                        :fileUrl="fileToken"
                        :fileType="previewFileType"
                        :hideFunc="hideFilePreview" /> -->
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
textarea {
  resize: none;
}
.modal-footer {
  display: block;
}
.custom-file {
  height: 150px;
  .custom-file-label {
    text-align: center;
    border: 1px dashed #d9d9d9;
    display: block;
    background: #d9d9d9;
    height: 120px;
    position: relative;
    &::after {
      display: none;
    }
    .dataImg {
      height: 105px;
      max-width: 250px;
      object-fit: cover;
    }
    i {
      font-size: 80px;
      &.dataPhoto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
    input {
      display: block;
      overflow: hidde;
      width: 1px;
      height: 1px;
      opacity: 0;
    }
  }
  .custom-file-input {
    height: 100%;
  }
}
.custom-row {
  height: 160px;
  @media screen and (max-width: 767.98px) {
    height: unset;
    .custom-file {
      height: 160px;
    }
    .custom-file-input {
      height: 100%;
    }
  }
}
.customUploadFile {
  background-color: #41505e;
  width: 120px;
  padding-left: 0;
  padding-right: 0;
  display: block;
  color: #fff;
  opacity: 0.7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fileinfo {
  color: #afafaf;
  font-weight: 400;
  .info {
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  .close {
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    float: none;
  }
}
.modal-footer {
  display: flex;
  justify-content: center;
}
.btn {
  display: flex;
  justify-content: center;
  min-width: 100px;
}
.btn-loader {
  margin: 0.8px 0;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #3f99ef;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.input-file {
  background-color: #494b54;
  height: 38px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script src="./index.js"></script>
