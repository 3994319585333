import { defineComponent } from 'vue'
import submitRate from './submitRate'
import reviewRate from '../timeline-discussion/reviewRate'
import DealServices from '../../../../../services/deal'
import notify from '../../../../../services/notify'

export default defineComponent({
    props: {
        currentStage: { type: Number, default: null },
        totalStage: { type: Array, default: null },
        specifyIdDeal: { type: String, default: null },
        stageId: { type: String, default: null },
        dataDeal: { type: Object, default: null },
    },
    components: {
        submitRate,
        reviewRate
    },
    emits: ['getDataRating'],
    data() {
        return {
            totalStar: 5,
            currentStarTarget: -1,
            loading: true,
            reload: false,
            dataRating: null,
            is_me: false,
        }
    },
    computed: {
        validData() {
            const { currentStage, totalStage } = this
            return {
                currentStage,
                totalStage
            }
        }
    },
    watch: {
        validData: {
            handler: function (val) {
                this.loading = false
                if (this.currentStage >= 0) {
                    let temp = this.dataRating.filter(p => p.stage_id === this.totalStage[this.currentStage].id)
                    if (temp.length && temp.find(p => p.is_me)) {
                        this.is_me = true
                    }
                }
                setTimeout(() => {
                    this.scoreRating()
                })
            },
            deep: true
            }
    },
    mounted() {
        //#bus on reloadRating
        // this.$root.$on('reloadRating', function (data) {
        //     vue.getDealRate()
        // })
        this.$nextTick(() => {
            this.getDealRate()
        })
    },
    methods: {
        getDealRate() {
            if (!this.specifyIdDeal) return
            this.reload = true
            this.dataRating = []
            DealServices.getDealRate(this.specifyIdDeal).then(resp => {
                if (resp.data.ok) {
                    this.dataRating = resp.data.d
                    if (this.dataRating.length > 0 && this.dataRating.find(p => p.is_me) && this.dataRating.find(p => p.stage_id === this.totalStage[this.currentStage].id)) {
                        this.is_me = true
                        this.currentStarTarget = this.dataRating.find(p => p.is_me && p.stage_id === this.totalStage[this.currentStage].id).rating
                    } else {
                        this.currentStarTarget = -1
                        this.is_me = false
                    }

                    //#bus on emit data
                    this.$emit('getDataRating', this.dataRating)
                }
            }).finally(() => {
                this.loading = false
                this.reload = false
                setTimeout(() => {
                    this.scoreRating()
                }, 50)
            })
        },
        scoreRating: function () {
            let vue = this
            if (this.$refs.score && !this.dataDeal.is_rejected && !this.is_me) {
                $(this.$refs.score).on('click touchstart', '.star', function () {
                    vue.currentStarTarget = $(this).index() + 1
                })
            }
        },
        showSubmitRate() {
            this.$refs.showRate.showModal()
        },
        confirmSubmitRate() {
            DealServices.updateDealRate(this.specifyIdDeal, this.currentStarTarget, this.stageId).then(resp => {
                if (resp.data.ok) {
                    this.getDealRate()
                    if (this.$refs.score) {
                        $(this.$refs.score).off('click touchstart')
                    }
                    notify.success('Rate for this stage successfully!')
                }
            })
        },
        showModalReviewRate() {
            this.$refs.showReviewRate.showModal()
        }
    }
})