import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: { type: String, default: "" },
    title: { type: String, default: "" },
    value: { type: Boolean, default: false },
    isHorizontal: { type: Boolean, default: false },
    isShowText: { type: Boolean, default: true },
    size: { type: String, default: "normal" },
    titleDirection: { type: String, default: "left" },
  },
  emits: ["update:value", "onChangeValue"],
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
        this.$emit("onChangeValue");
      },
    },
  },
});
