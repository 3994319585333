import { defineComponent } from "vue";

export default defineComponent({
    name: 'submitRate',
    props: {
        currentStarTarget: Number,
        totalStar: Number
    },
    emits: ['confirm'],
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm', true)
            this.hideModal()
        },
        showModal() {
            this.isShow = true
        },
        hideModal() {
            this.isShow = false
        }
    }
})