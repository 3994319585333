<template>
  <div class="section2 container-v2">
    <div
      v-for="(item, index) in list"
      :key="item.id"
      :class="['about-card', index % 2 !== 0 ? 'reverse' : '']"
    >
      <div
        class="img"
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="50"
        data-aos-duration="500"
      >
        <img :src="item.thumbnail" alt="picture" />
        <img />
      </div>

      <div
        class="content"
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="100"
        data-aos-duration="500"
      >
        <p
          class="heading"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="100"
          data-aos-duration="500"
        >
          {{ item.heading }}
        </p>
        <p
          class="sub-heading"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          {{ item.subHeading }}
        </p>

        <p
          class="desc"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          {{ item.desc }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AboutUsImage1 from "@/assets/images/v2/about-us-2.jpg";
import AboutUsImage2 from "@/assets/images/v2/about-us-3.jpeg";

export default {
  data() {
    return {
      list: [
        {
          id: 1,
          heading: "Our",
          subHeading: "Mission",
          desc: `We are DRIVEN to serve Ultra-High Net Worth Individuals, Global Family Offices, and Institutions who provide the FUEL for our FOCUS on helping disruptive founders who are transforming the way consumers and businesses transact, consume, work and enjoy their life journey.`,
          thumbnail: AboutUsImage1,
        },
        {
          id: 2,
          heading: "Our",
          subHeading: "Vision",
          desc: `To become the top-of-mind Global Venture Capital Fund known for ELITE intellectual firepower, work ethic, communication, results, and values.`,
          thumbnail: AboutUsImage2,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.section2 {
  padding-top: 134px; // 30px + 104px
}

.about-card {
  position: relative;
  margin-bottom: 200px;
  @media (min-width: 768px) {
    margin-bottom: 164px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 164px;
  }

  .img {
    width: 80%;
    max-height: 620px;
    margin-left: auto;

    position: relative;
    padding-top: 56.25%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    left: 0;
    bottom: -70%;

    width: 90%;

    padding: 15px;

    background: rgba(235, 246, 255, 0.75);

    @media (min-width: 768px) {
      width: 38%;
      bottom: -8%;
      padding: 20px;
    }

    @media (min-width: 1024px) {
      min-width: 504px;
      padding: 60px 32px;
    }

    .heading,
    .sub-heading {
      font-size: 38px;
      line-height: 100%;

      @media (min-width: 768px) {
        font-size: 70px;
        line-height: 70px;
        letter-spacing: -0.02em;
      }

      @media (min-width: 1024px) {
        font-size: 100px;
        line-height: 100px;
        letter-spacing: -0.02em;
      }
    }

    .heading {
      font-weight: 300;
      font-style: italic;
    }

    .sub-heading {
      font-weight: 700;
      padding-bottom: 12px;

      @media (min-width: 1024px) {
        padding-bottom: 48px;
      }
    }

    .desc {
      font-family: "SF Pro Text";
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      @media (min-width: 768px) {
        line-height: 135%;
        font-size: 20px;
      }

      @media (min-width: 1024px) {
        font-size: 24px;
      }
    }
  }
}

.reverse {
  .img {
    margin-left: unset;
  }

  .content {
    left: auto;
    right: 0;

    @media (min-width: 1024px) {
      width: 480px;
    }

    .heading,
    .sub-heading {
      text-align: right;
    }

    .desc {
      text-align: right;
    }
  }
}
</style>
