import AdminServices from "@/services/admin";
import { defineComponent } from "vue";
import notify from "../../../../services/notify";
import TextField from "@/components/TextField";

export default defineComponent({
  name: "addFolder",
  props: ["parentId", "hideAddFolder"],
  emits: ["add-complete"],
  components: { TextField },
  data() {
    return {
      form: {
        name: null,
      },
      errorMsg: {
        name: null,
      },
      loading: false,
    };
  },
  created() {},
  methods: {
    validData() {
      let flag = true;
      if ($.trim(this.form.name) === "") {
        this.errorMsg.name = "Please input folder name";
        flag = false;
      }
      if (flag) {
        this.loading = true;
        AdminServices.createDataroomFolder({
          parentId: this.parentId,
          name: this.form.name,
          isUploadFolder: false,
        })
          .then((resp) => {
            if (resp.data.ok) {
              this.$emit("add-complete");
            } else {
              notify.error("Error", resp.data.m);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = null;
    },
  },
});
