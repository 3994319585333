import { defineComponent } from "vue";
import logoIcon from "@/components/commons/logo";
import VueSignaturePad from "vue-signature-pad";

export default defineComponent({
  props: ["finishSurvey", "hideSignature", "sourceFileData"],
  components: {
    VueSignaturePad,
    logoIcon,
  },
  data() {
    return {
      // signaturePad: null
      empty: true,
    };
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.empty = true;
    },
    test() {
      this.empty = this.$refs.signaturePad.isEmpty();
    },
    saveImg() {
      if (this.$refs.signaturePad && this.$refs.signaturePad.saveSignature()) {
        let src = this.$refs.signaturePad.saveSignature();
        let img = new Image();
        img.src = src.data;
        let data = src.data.split("base64,")[1];
        this.sourceFileData(data);
        this.finishSurvey();
      }
    },
  },
  computed: {
    isSigned() {
      if (this.$refs.signaturePad && this.$refs.signaturePad.saveSignature())
        return true;
      return false;
    },
  },
});
