<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideUploadFile"></div>
            <div class="modal-dialog">
            <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title v-1">Upload file</h4>
                    </div>
                    <div class="modal-body" v-loading="loading">
                        <form @submit.prevent="validateForm" autocomplete="false">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <div class="form-group mb-1" :key="`up${uploadKey}`">
                                        <input type="file" id="files" ref="files" multiple @change="handleFilesUpload()"/>
                                        <template>
                                            <transition name="fade" v-for="(fileItem, idx) in files" :key="idx">
                                                <div class="file-item mt-2"
                                                    :class="{ error: fileItem.isDuplicated && !['UPGRADE'].includes(fileItem.duplicationMode) }">
                                                    <span class="pr-1 item-icon" :style="{'color': getDataRoomItemIconColorByTypeAndFileName('File', fileItem.file.name)}">
                                                        <i :class="getDataRoomItemIconClassByTypeAndFileName('File', fileItem.file.name)"></i>
                                                    </span>
                                                    <span class="info">{{ fileItem.file.name }}</span>
                                                    <div class="error-handling-wrapper" v-if="!fileItem.isNameValid">
                                                        <div class="info error-handling">
                                                            <span class="me-2">{{specialCharacterMsg}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="error-handling-wrapper" v-else-if="(fileItem.otherErrorMsg || fileItem.isDuplicated) && !fileItem.isUploading">
                                                        <div class="info error-handling duplicated-warning" v-if="fileItem.isDuplicated">
                                                            <span class="me-2">This file is existed in dataroom, do you want to upgrade its version?</span>
                                                            <div>
                                                                <label class="clickable inline-label mx-1">
                                                                    <input type="radio" v-model="fileItem.duplicationMode" value="UPGRADE" :name="'optradio'+idx" /> <span class="ml-1">Yes</span>
                                                                </label>
                                                                <label class="clickable inline-label mx-1">
                                                                    <input type="radio"
                                                                        v-model="fileItem.duplicationMode"
                                                                        @change="removeFile(idx)"
                                                                        value="NO_UPGRADE"
                                                                        :name="'optradio'+idx" /> <span class="ml-1">No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="info error-handling" v-if="fileItem.otherErrorMsg && !fileItem.isUploading">
                                                            <span class="me-2">{{fileItem.otherErrorMsg}}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="fileItem.isUploading" class="progress progress-indicator">
                                                        <div
                                                            class="progress-bar progress-bar-info progress-bar-striped"
                                                            role="progressbar"
                                                            :aria-valuenow="fileItem.progress"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            :style="{ width: fileItem.progress + '%' }"
                                                        >
                                                            {{ fileItem.progress }}%
                                                        </div>
                                                    </div>
                                                    <span v-if="!fileItem.isUploaded" class="icon close" @click="removeFile(idx)"><i class="fas fa-times"></i></span>
                                                    <span v-else class="icon success"><i class="fas fa-check-double"></i></span>
                                                </div>
                                            </transition>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12">
                                    <button :disabled="loading" type="button" class="btn btn-small btn-secondary float-right" @click="addFiles()">Add Files</button>
                                </div>
                            </div>
                        </form>                
                    </div>
                    <div class="modal-footer text-center">
                        <button :disabled="loading" class="btn btn-secondary" @click="hideUploadFile">Close</button>
                        <button :disabled="loading || !numberFilesToupload" class="btn btn-primary" @click="validateForm">Upload {{numberFilesToupload}} files</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
input[type="file"]{
    position: absolute;
    top: -500px;
}
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
textarea{
    resize: none;
}
.modal-footer{
    display: block;
}
.custom-file{
    height: 150px;
    .custom-file-label{
        top: 30px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        display: block;
        background: #d9d9d9;
        height: 120px;
        &::after{
            display: none;
        }
        .dataImg{
            height: 105px;
        }
        i{
            font-size: 80px;
            &.dataPhoto{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 40px;
            }            
        }
    }
    .custom-file-input{
        height: 100%;
    }
}
.custom-row{
    height: 160px;
    @media screen and (max-width: 767.98px) {
        height: unset;
        .custom-file{
            height: 160px;
        }
        .custom-file-input{
            height: 100%;
        }
    }
}
.customUploadFile{
    background-color: #41505e;
    width: 120px;
    padding-left: 0;
    padding-right: 0;
    display: block;
    color: #fff;
    opacity: 0.7;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.file-item {
    display: flex;
    align-items: center;
    color: #afafaf;
    font-weight: 400;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    .progress-indicator {
        flex-grow: 1;
        margin: 0 10px;
    }
    .error-handling-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-left: auto;
    }
    .info {
        font-size: 0.8rem;
        line-height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        &.error-handling {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            color: red;
            .inline-label {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                font-size: 0.8rem;
                color: red;
                line-height: 20px;
                vertical-align: middle;
            }
            &.duplicated-warning {
                color: #ffa500;
                .inline-label {
                    color: #ffa500;
                }
            }
        }
    }
    .icon {
        margin-left: auto;
        font-size: 1rem;
        display: inline-block;
        line-height: 20px;
        vertical-align: middle;
        float: none;
        &.close {
            cursor: pointer;
        }
        &.success {
            color: #409EFF;
        }
    }
    &.error {
        border-color: red;
    }
}
</style>
<script src="./index.js"></script>
