import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'detailFile',
    props: {
        hideModal: Function,
        model: {
            type: Object
        },
        itemId: Number
    },
    data() {
        return {
            detailInfo: null,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.loadDetails()
    },
    methods: {
        loadDetails() {
            AdminServices.getDataroomReportInvestorFocusDetails({
                id: this.itemId,
                investorTypes: this.model.selectedInvestorTypes,
                fromDate: this.model.from,
                toDate: this.model.to
            }).then(res => {
                if (res.data.ok) {
                    this.detailInfo = res.data.d
                } else {
                    this.detailInfo = null
                }
            }).finally(() => {
                this.loading = false
            })
        }
    }
})