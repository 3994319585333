import Datepicker from "@/components/DatePicker";
import moment from "moment";
import _ from "lodash";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import SimpleImage from "@editorjs/simple-image";
import Embed from "@editorjs/embed";
import { defineComponent } from "vue";
import SelectField from "@/components/SelectField";
import AdminServices from "@/services/admin";
import notify from "@/services/notify";
import TextField from "@/components/TextField";

const objFormdataDefault = {
  title: "",
  type: "",
  photo: "",
  link: "",
  publishDate: "",
};

const objErr = {
  title: "",
  type: "",
  photo: "",
  link: "",
  publishDate: null,
};

const tabsData = [
  {
    name: "LEADERSHIP",
    title: "Thought Leadership",
    active: false,
  },
  {
    name: "NEWS",
    title: "News",
    active: false,
  },
  {
    name: "PRIVATE_NEWS",
    title: "Private News",
    active: false,
  },
  {
    name: "VIDEO",
    title: "Video",
    active: false,
  },
];

export default defineComponent({
  components: {
    SelectField,
    Datepicker,
    TextField,
  },
  emits: ["reloadInfo"],
  data() {
    return {
      isShow: false,
      userTypeSelected: null,
      newsTabs: tabsData,
      tmpDate: null,
      formError: false,
      error: objErr,
      dataPhoto: "",
      fileUploaded: null,
      loading: false,
      formData: objFormdataDefault,
      typeName: "Add",
      editor: null,
    };
  },
  computed: {
    activeTab: {
      default: "",
      get: function () {
        let tab = "News type";
        if (_.cloneDeep(this.newsTabs).find((x) => x.active === true)) {
          tab = _.cloneDeep(this.newsTabs).find((x) => x.active === true).title;
        }
        return tab;
      },
    },
    screenSize: {
      get() {
        return this.$store.state.mobile.size;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    initEditor() {
      if (this.editor) return;
      this.editor = new EditorJS({
        holder: "editorjs",
        tools: {
          header: {
            class: Header,
            inlineToolbar: ["link"],
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: "unordered",
            },
          },
          image: SimpleImage,
          embed: Embed,
        },
        data:
          this.formData && this.formData.content
            ? this.formData.content
            : undefined,
      });
    },
    getNewsType(type) {
      this.newsTabs.map((x) => {
        x.active = false;
        if (x.name === type) {
          x.active = true;
          this.formData.type = x.name;
        }
      });
    },
    async validateForm() {
      this.formError = false;

      if (!this.formData.title || this.formData.title.trim() === "") {
        this.error.title = "Please input news title";
        this.formError = true;
      }

      if (!this.formData.type || this.formData.type.trim() === "") {
        this.error.type = "Please select news type";
        this.formError = true;
      }
      if (
        !this.formData.publishDate ||
        this.formData.publishDate.trim() === ""
      ) {
        this.error.publishDate = "Please pick a date";
        this.formError = true;
      }

      if (
        this.formData.type !== "PRIVATE_NEWS" &&
        (!this.formData.link || this.formData.link.trim() === "")
      ) {
        this.error.link = "Please input link";
        this.formError = true;
      }

      if (!this.dataPhoto) {
        this.error.photo = "Please choose one picture";
        this.formError = true;
      }
      if (this.formError) {
        return;
      }

      let formData = new FormData();
      formData.append("title", this.formData.title);
      formData.append("type", this.formData.type);
      formData.append(
        "publishDate",
        moment.utc(this.formData.publishDate).format("YYYY-MM-DD HH:mm")
      );
      formData.append("link", this.formData.link);
      formData.append("photo", this.formData.photo);
      if (this.editor) {
        formData.append("content", JSON.stringify(await this.editor.save()));
      }

      if (this.typeName === "Add") {
        this.confirmAddFueled(formData);
      } else {
        formData.append("id", this.formData.id);
        this.confirmUpdateFueled(formData);
      }
    },
    confirmAddFueled(item) {
      this.loading = true;
      AdminServices.addFueledData(item)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add successfully");
            this.$emit("reloadInfo");
            this.hideModal();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmUpdateFueled(item) {
      AdminServices.updateFueledData(item).then((resp) => {
        if (resp.data.ok) {
          notify.success("Update successfully");
          this.$emit("reloadInfo");
          this.hideModal();
        }
      });
    },
    renderFileImg() {
      let ExtAllow = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      let img = this.$refs.fileimg.files[0];
      if (!ExtAllow.includes(img.type)) {
        return;
      }
      let reader = new FileReader();

      reader.readAsDataURL(img);
      reader.onload = (e) => {
        this.dataPhoto = e.target.result;
        if (this.dataPhoto) {
          this.formData.photo = img;
          this.error.photo = false;
        }
      };
    },
    renderFile() {
      let file = this.$refs.file.files[0];
      if (file && file.type !== "application/pdf") {
        return;
      }
      if (file) {
        this.fileUploaded = file.name;
        this.formData.file = file;
        this.error.file = false;
      }
    },
    customFormatter(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    removeFileUploaded() {
      this.$refs.file.value = "";
      this.fileUploaded = "";
      this.formData.file = "";
    },
    onResize() {
      if ($(".custom-modal .modal-dialog").height() > $(window).height()) {
        $(".custom-modal .modal-dialog").css({ transform: "unset", top: "0" });
      } else {
        $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
      }
    },
    showModal(item) {
      this.isShow = true;

      if (item) {
        this.formData = item;
        this.dataPhoto = item.photo;
        this.tmpDate = {
          years: moment(item.pub_date).format("YYYY"),
          months: moment(item.pub_date).format("MM"),
          days: moment(item.pub_date).format("DD"),
        };
        this.newsTabs.find((x) => x.name === item.type).active = true;
        this.userTypeSelected = item.type;
        this.typeName = "Edit";
      } else {
        this.formData = { ...objFormdataDefault };
        this.dataPhoto = "";
        this.tmpDate = null;
        this.userTypeSelected = null;
        this.newsTabs = _.cloneDeep(tabsData);
        this.typeName = "Add";
        this.error = _.cloneDeep(objErr);
      }
      this.$nextTick(() => {
        this.initEditor();
      });
    },
    hideModal() {
      this.isShow = false;
      this.error = objErr;
      this.formData = objFormdataDefault;
    },
  },
  watch: {
    screenSize() {
      this.onResize();
    },
    userTypeSelected(v) {
      this.getNewsType(v);
    },
    tmpDate: function (v) {
      if (v) {
        this.formData.publishDate = moment(
          `${v.years}-${v.months}-${v.days}`
        ).format("MM/DD/YYYY");
        this.error.publishDate = "";
      }
    },
    "formData.title": function (v) {
      if (v && v.trim().length > 0) this.error.title = "";
    },
    "formData.link": function (v) {
      if (v && v.trim().length > 0) this.error.link = "";
    },
    dataPhoto(v) {
      if (v) this.error.photo = false;
    },
  },
  beforeUnmount() {
    $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
    this.typeName = "";
  },
});
