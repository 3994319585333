<template>
  <Modal title="Invite Investor" v-model:value="isShow">
    <form @submit.prevent="save" autocomplete="false">
      <div
        class="row"
        v-for="(user, userI) in form.users"
        :key="`${userI}userI`"
      >
        <div class="col-12 mb-1 position-relative">
          User {{ userI + 1 }}
          <div class="remove-user" v-if="userI > 0" @click="removeUser(userI)">
            <i class="fas fa-minus-circle"></i>
          </div>
        </div>
        <div class="col col-12 mb-3">
          <Switcher
            v-model:value="user.allowReferral"
            :name="`allowReferral${userI}`"
            title="Allow Referral"
          />
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="First name"
            :name="`fname${userI}`"
            :autocomplete="false"
            :errorMsg="errorMsg.users[userI].firstname"
            :fieldRequire="true"
            v-model:value="user.firstname"
          />
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="Last name"
            :name="`lname${userI}`"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.users[userI].lastname"
            v-model:value="user.lastname"
          />
        </div>
        <div class="col col-12 col-md-4">
          <TextField
            placeholder="Email"
            :name="`email${userI}`"
            :autocomplete="false"
            :fieldRequire="true"
            :errorMsg="errorMsg.users[userI].email"
            v-model:value="user.email"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <div class="add-user" @click="addUser">
            <i class="fas fa-plus"></i>
          </div>
        </div>
        <div class="col col-12 col-md-4">
          <Datepicker
            v-model:value="tmpDate"
            placeholder="MM/DD/YYYY"
            title="Expired Datetime"
            name="expired-datetime"
            field="black"
            :errorMsg="errorMsg.expires_datetime"
            format="MM/DD/YYYY"
            :minDate="new Date().toString()"
          />
        </div>
        <div class="col-12 col-md-4">
          <SelectField
            v-model:value="form.investor_type"
            title="Type"
            name="investor-type"
            :options="investorTypeList.filter((x) => x.code !== '')"
            :errorMsg="errorMsg.investor_type"
            :fieldRequire="true"
          />
        </div>
        <div class="col-12 col-md-4">
          <SelectField
            v-model:value="form.role_id"
            title="Role"
            name="role"
            :options="roleList.filter((x) => x.id !== '')"
            valueProp="id"
            :errorMsg="errorMsg.role_id"
            :fieldRequire="true"
          />
        </div>
        <div class="col-12">
          <label>
            <input
              type="checkbox"
              v-model="form.ndaSignRequired"
              value="true"
              class="me-1"
            />
            <span>Require investors to sign NDA</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-6 w-100" style="margin-bottom: 25px">
          <label class="title mb-0">
            Select Funds
            <small class="require" style="color: #f52727">*</small>
          </label>
          <customSelect
            v-model:value="form.lp_fund_ids"
            :options="lpFundOptions"
            isMultiple
            required
            label="Select Funds"
            class="w-100"
          />
          <div v-if="errorMsg.lp_fund_ids" class="md-error">
            {{ errorMsg.lp_fund_ids }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <NavTab v-model:value="selectedTab" :tabs="tabs">
            <template #email_content>
              <div class="row">
                <div class="col col-12 col-md-12">
                  <TextField
                    title="Subject"
                    name="subject"
                    :autocomplete="false"
                    :errorMsg="errorMsg.subject"
                    :fieldRequire="true"
                    v-model:value="form.subject"
                  />
                </div>
                <div class="col col-12 col-md-12">
                  <TextArea
                    v-model:value="form.body"
                    title="Body"
                    name="body"
                    :autocomplete="false"
                    :errorMsg="errorMsg.body"
                    :fieldRequire="true"
                  />
                </div>
              </div>
            </template>
            <template #saved_templates>
              <div class="col col-12 bg">
                <SelectEmailTemplate v-model:value="appliedTmpl" />
              </div>
            </template>
          </NavTab>
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save" :loading="loading"> Invite </NewButton>
    </template>
  </Modal>
</template>

<script src="./AddInvestorModel.js"></script>

<style lang="scss" scoped>
.remove-user {
  color: #ff7171;
  font-size: 1.2em;
  position: absolute;
  padding: 5px;
  right: 0px;
  top: -3px;
  z-index: 1;
  cursor: pointer;
}

.add-user {
  display: inline-block;
  background: #696a6d;
  cursor: pointer;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.md-error {
  position: absolute;
  color: #f52727;
  font-size: 0.8em;
}
</style>
