<template>
  <div class="Deal">
    <div class="container">
      <div class="row position-relative dealGrid">
        <div class="col">
          <div class="row top" :class="hideRightBlock ? 'pr-130' : ''">
            <div class="col-6 d-flex align-items-center">
              <router-link
                v-if="!isUserTypeFounder()"
                class="routeback"
                :to="`/rfm/deals${params}`"
              >
                <i class="fas fa-chevron-left"></i>
              </router-link>
              <router-link
                v-if="isUserTypeFounder()"
                class="routeback"
                :to="`/rfm/deal-founder${params}`"
              >
                <i class="fas fa-chevron-left"></i>
              </router-link>
              <h3 class="mb-0">
                {{ dataDeal ? dataDeal.name : "" }}
              </h3>
              <h3 class="mb-0 draft" v-if="isDraft">(draft)</h3>
            </div>
            <div class="col-6 justify-content-end d-flex align-items-center">
              <a href="javascript:void(0)" @click="showHistory"
                ><i class="fas fa-eye"></i> View history</a
              >
            </div>
          </div>

          <stage
            :deal="dataDeal"
            :currentStage="currentStage"
            :totalStage="totalStage"
            :specifyIdDeal="specifyIdDeal"
            :stageAllow="stageAllow"
            @reloadDeal="reloadDeal"
          />

          <div class="row detail">
            <div class="col-12">
              <detail
                :dataDeal="dataDeal"
                :currentStage="currentStage"
                :totalStage="totalStage"
              />
            </div>
          </div>
          <div
            class="row"
            v-if="
              currentStage >= 0 &&
              stageAllow &&
              specifyIdDeal &&
              !dataDeal.is_rejected
            "
          >
            <div class="col-12">
              <fpcChecklist
                :currentStage="currentStage"
                :totalStage="totalStage"
                :specifyIdDeal="specifyIdDeal"
                :stageId="dataDeal.stage_id"
                :dataDeal="dataDeal"
                @reloadDeal="reloadDeal"
              />
            </div>
          </div>
          <div
            class="row rating"
            v-if="currentStage >= 0 && stageAllow && specifyIdDeal"
          >
            <div class="col-12">
              <rating
                :currentStage="currentStage"
                :totalStage="totalStage"
                :specifyIdDeal="specifyIdDeal"
                :stageId="dataDeal.stage_id"
                :dataDeal="dataDeal"
                @getDataRating="getDataRating"
              />
            </div>
          </div>
          <div
            class="row discussion"
            v-if="
              currentStage >= 0 &&
              currentStage !== totalStage.length - 1 &&
              stageAllow &&
              dataDeal &&
              !dataDeal.is_rejected
            "
          >
            <div class="col-12">
              <discussion
                :specifyIdDeal="specifyIdDeal"
                :currentStage="currentStage"
                :totalStage="totalStage"
                @reloadDealMessage="reloadDealMessage"
                @reloadFileUploaded="reloadFileUploaded"
              />
            </div>
          </div>
          <div class="row timeline overflow-hidden">
            <div class="col-12 p-0">
              <timeline
                :dataRating="dataRating"
                :totalStage="totalStage"
                :specifyIdDeal="specifyIdDeal"
                :currentStage="currentStage"
                :reload="isReloadMessage"
              />
            </div>
          </div>
        </div>
        <div
          class="listFileUploaded"
          :class="[hideRightBlock ? 'float' : `col position-absolute `]"
        >
          <template v-if="!hideRightBlock">
            <fileUpload
              :specifyIdDeal="specifyIdDeal"
              :currentStage="currentStage"
              :stageAllow="stageAllow"
              :hideBlock="hideBlock"
              :additionalFiles="dataDealSubmissionFiles"
              :reload="isReloadFileUploaded"
            />
          </template>
          <template v-else>
            <div>
              <button @click="hideBlock(false)">
                <documentIcon width="18" class="me-1" />
                <span>View files</span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <dealHistory
      ref="dealHistory"
      :specifyIdDeal="specifyIdDeal"
      :totalStage="totalStage"
    />
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.Deal .container {
  max-width: 100% !important;
  background-color: #2e323b;
  min-height: 100vh;
  .dealGrid {
    > div:last-child {
      flex: 0 0 300px;
    }
  }
  .listFileUploaded.col {
    // @media screen and (max-width: 1366px) {
    top: 0;
    right: 0;
    max-width: 300px;
    height: 100%;
    background-color: #292c33;
    // }
  }
  .float {
    position: absolute;
    top: 18px;
    right: 5px;
    width: auto;
    button {
      background-color: transparent;
      border: 0px solid transparent;
      svg,
      span {
        vertical-align: middle;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
  .top {
    padding: 15px 0;
    background-color: #292c33;
    border-bottom: 1px solid #3a3e47;
    &.pr-130 {
      padding-right: 130px;
    }
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      line-height: 34px;
    }
    a {
      color: #0095ff;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 34px;
    }
    .draft {
      margin-left: 10px;
      text-transform: lowercase !important;
    }
  }
  .routeback {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    background: #0095ff;
    transition: all 200ms ease;
    margin-right: 10px;
    &:hover {
      background: #0070c0;
    }
  }
  .detail,
  .discussion,
  .timeline {
    border-bottom: 1px solid #3a3e47;
  }
}
</style>
