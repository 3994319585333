import { defineComponent } from "vue";
import adminServices from "@/services/admin";
import { PERMISSIONS } from "@/config/router";

import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import RoleManageModel from "./components/RoleManageModel.vue";

export default defineComponent({
  components: {
    Table,
    TextField,
    SelectField,
    RoleManageModel,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },

      filteredName: "",
      filteredStatus: "ACTIVE",

      roleManageModelKey: 0,
      permissionList: [],
      PERMISSIONS: PERMISSIONS,

      showInactiveStt: false,
      statusList: [
        {
          name: "Active",
          code: "ACTIVE",
        },
        {
          name: "Inactive",
          code: "INACTIVE",
        },
      ],
      columns: [
        {
          id: "name",
          name: "Name",
          sort: true,
          direction: "",
        },
        {
          id: "permissions",
          name: "Permissions",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-end",
          columnClass: "text-end action-icon-group",
        },
      ],
    };
  },
  watch: {
    paging: {
      deep: true,
      handler(v) {
        debounce(this.getAll);
      },
    },
    filteredName() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  mounted() {
    this.showInactiveStt = this.$route.meta.showInactiveStt || false;
    this.getAllInvestorPermissions();
    this.$nextTick(() => {
      this.showInactiveStt
        ? [
            {
              id: "name",
              name: "Name",
              sort: true,
              direction: "",
            },
            {
              id: "permissions",
              name: "Permissions",
            },
            {
              id: "stt",
              name: "Status",
            },
            {
              id: "actions",
              name: "Actions",
              titleClass: "text-right",
              columnClass: "text-right action-icon-group",
            },
          ]
        : [
            {
              id: "name",
              name: "Name",
              sort: true,
              direction: "",
            },
            {
              id: "permissions",
              name: "Permissions",
            },
            {
              id: "actions",
              name: "Actions",
              titleClass: "text-right",
              columnClass: "text-right action-icon-group",
            },
          ];
    });
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAllInvestorPermissions() {
      const res = await adminServices.getAllInvestorPermissions();
      if (res && res.data && res.data.ok) {
        this.permissionList = res.data.d;
      }
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getAllInvestorRoles({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        orderBy: `${this.columns.find((p) => typeof p.sort && p.sort).id} ${
          this.columns.find((p) => typeof p.sort && p.sort).direction
        }`,
        filteredName: this.filteredName,
        filteredStatus: this.filteredStatus,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async addRole() {
      this.roleManageModelKey++;
      this.$nextTick(() => {
        this.$refs.roleManageModel.showAdd();
      });
    },
    async updateRole(item) {
      this.roleManageModelKey++;
      this.$nextTick(() => {
        this.$refs.roleManageModel.showUpdate(item);
      });
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
