<template>
  <div class="adminContent p-3">
    <div class="data p-3">
      <div class="row">
        <div class="col-12" v-loading="loading">
          <div class="table-responsive-sm">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Positions</th>
                  <th>Fuel Perspective Author</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <Dragger
                v-model:value="tableData"
                component-tag="tbody"
                drag-element-tag="tr"
                component-class="handle"
                ghost-class="ghost"
                @change="onEnd"
                @orderChanged="onEnd"
                handle=".handle"
                class="pointer"
              >
                <template #default="{ props, index }">
                  <!-- <template v-for="(item, index) in tableData" :key="index"> -->
                  <td>{{ index + 1 }}</td>
                  <td>
                    <span class="wordBreak">{{ props.name }}</span>
                  </td>
                  <td>
                    <span class="wordBreak">{{ props.title }}</span>
                  </td>
                  <td>
                    <span class="wordBreak">{{ props.perspective_status ? "Active" : "Inactive" }}</span>
                  </td>
                  <!-- <td>
								<a class="btn btn-primary btn-small " @click="editTeamInfo(item.id)"><i class="far fa-edit"></i></a>
								<a class="btn btn-danger btn-small" @click="removeTeamInfo(item.id)"><i class="far fa-trash-alt"></i></a>
							</td> -->
                  <td class="d-flex">
                    <a
                      class="btn btn-primary styleBtn btn-small"
                      @click="editTeamInfo(props.id)"
                      ><i class="far fa-edit"></i
                    ></a>
                    <a
                      class="btn btn-danger styleBtn btn-small"
                      @click="removeTeamInfo(props.id)"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                    <a
                      class="btn btn-default styleBtn btn-small handle all-scroll cursor-move"
                      ><i class="fas fa-bars"></i
                    ></a>
                  </td>
                  <!-- </template> -->
                </template>
              </Dragger>
              <tfoot>
                <tr>
                  <td colspan="6">
                    <button class="btn btn-primary" @click="showAddMember">
                      <i class="fas fa-plus me-1"></i>
                      <span>Add Member</span>
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <popupAddEditMember ref="addEditMember" @reloadInfo="getTeamData" />
    <Dialog ref="dialog" />
  </div>
</template>
<style lang="scss" scoped>
td > p {
  width: 100%;
}
.styleBtn {
  margin-right: 5px;
}
</style>
<script src="./index.js"></script>
