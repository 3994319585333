<template>
  <div>
    <!-- <banner-header :bannerInfo="bannerInfo"/> -->
    <!-- <OurteamPartial :members="members" /> -->
    <OurTeamWithFund :fund-members="fundMembers" />
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.mainContainer {
  margin-top: -10rem;
}
.marginBottom4rem {
  margin-bottom: 4rem;
}
</style>
