import appStore, {
  AUTH_ACTIONS,
  APP_MUTATION,
  AUTH_MUTATION,
} from "../../../store/app";
import notify from "../../../services/notify";
import router from "@/config/router";
import _ from "lodash";
//import Verify2faLogin from "../../verify2faLogin/index"
import validate_email from "../../../misc/checkemail";
import conf from "../../../config";
import { handleErrors } from "../../../misc/commons";
import { defineComponent } from "vue";
import TextField from "@/components/TextField";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  props: ["redirect"],
  components: { TextField },
  data() {
    return {
      loading: false,
      captchaInstance: {},
      model: {
        email: "",
        password: "",
        recaptcha: "",
      },
      resendRegisterVerifyPath: router.resendVerifyRegisterEmail.path,
      registerPath: router.register.path,
      homePath: router.home.path,
      forgotPasswordPath: router.forgotPassword.path,
      frmInvalidate: false,
      messageError: {
        email: null,
        password: null,
      },
      clicked: false,
      sitekey: conf.recaptcha_key,
      email: "",
      password: "",
    };
  },
  components: {
    LogoIcon,
    //"verify-2fa-modal": Verify2faLogin
  },
  computed: {
    disabled() {
      return (
        this.loading ||
        !this.model.email ||
        !this.model.password ||
        !this.model.recaptcha
      );
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  methods: {
    submit(token) {
      this.loading = true;
      this.model.recaptcha = token;
      appStore
        .dispatch(AUTH_ACTIONS.login, {
          ...this.model,
          redirectExternal: this.redirect,
        })
        .then((resp) => {
          if (!$("body").children().last().attr("class")) {
            $("body").children().last().remove();
          }
          if (resp.error && !resp.data.require2Fa) {
            handleErrors(resp);
            this.resetCaptcha();
          } else if (resp.data.require2Fa) {
            this.$refs.verify2FaModal.show(
              resp.data.validateCode,
              (vresp) => {
                if (!vresp || vresp.error) {
                  this.resetCaptcha();
                }
              },
              this.redirect
            );
          }
          this.loading = false;
        });
    },
    validate() {
      this.resetVaildate();
      if (!this.model.email) {
        this.messageError.email = "Email field is not empty";
        this.frmInvalidate = true;
      }
      if (!validate_email.validateEmail(this.model.email)) {
        this.messageError.email = "Your email is invalid";
        this.frmInvalidate = true;
      }
      if (!this.model.password) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }
      // if (!this.model.recaptcha) {
      //     notify.error('You must solve the captcha first')
      //     this.frmInvalidate = true
      // }
      if (this.frmInvalidate) {
        return;
      }

      this.$refs.recaptcha?.execute();
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        email: null,
        password: null,
      };
    },
    login() {
      this.resetVaildate();
      if (!this.model.email) {
        this.messageError.email = "Email field is not empty";
        this.frmInvalidate = true;
      }
      if (!validate_email.validateEmail(this.model.email)) {
        this.messageError.email = "Your email is invalid";
        this.frmInvalidate = true;
      }
      if (!this.model.password) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }
      if (!this.model.recaptcha) {
        notify.error("You must solve the captcha first");
        this.frmInvalidate = true;
      }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      appStore
        .dispatch(AUTH_ACTIONS.login, {
          ...this.model,
          redirectExternal: this.redirect,
        })
        .then((resp) => {
          if (resp.error && !resp.data.require2Fa) {
            //notify.error(resp.data.err_code)
            this.resetCaptcha();
          } else if (resp.data.require2Fa) {
            this.$refs.verify2FaModal.show(
              resp.data.validateCode,
              (vresp) => {
                if (!vresp || vresp.error) {
                  this.resetCaptcha();
                }
              },
              this.redirect
            );
          }
          this.loading = false;
        });
    },
    resetCaptcha() {
      if (_.isFunction(this.captchaInstance.reset)) {
        this.captchaInstance.reset();
      }
    },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
  },
  mounted() {
    setTimeout(() => {
      if (
        $("body,html")
          .find("form.colorWhite .md-input")
          .css("background-color") === "rgb(250, 255, 189)"
      ) {
        this.clicked = true;
      }
      //let that = this;
      // $("#loginForm .md-field").each(function(i, item) {
      //     var $item = $(item);
      //     $item.addClass("md-has-value")
      // });
    }, 50);
  },
});
