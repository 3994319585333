import { defineComponent } from 'vue'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default defineComponent({
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  data(){
    return {
      isOpenStream: false
    }
  },
  methods: {
    openStream() {
      alert(this.isOpenStream)
      this.isOpenStream = !this.isOpenStream
    }
  },
  watch: {
    
  }
})