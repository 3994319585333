import AdminServices from "../../../services/admin";
import detaiSurvey from "./detailSurvey";
import router from "@/config/router";
import notify from "../../../services/notify";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      reviewSurveyDetailPath: router.reviewSurveyDetail.path,
      reviewSurveyDetailName: router.reviewSurveyDetail.name,
      page: {
        page: 1,
        size: 10,
        total: 0,
      },
      surveyStatus: "COMPLETED",
      tableData: [],
      isShowDetailSurvey: false,
      surveyDetailID: null,
    };
  },
  components: {
    detaiSurvey,
  },
  created() {
    this.getSurvey();
  },
  methods: {
    getSurvey() {
      AdminServices.getSurveyReview(this.surveyStatus, this.page).then(
        (resp) => {
          if (resp.data.ok) {
            this.tableData = resp.data.d.c;
            this.page.page = resp.data.d.p;
            this.page.size = resp.data.d.s;
            this.page.total = resp.data.d.t;
          }
        }
      );
    },
    viewSurveyDetail(id) {
      this.isShowDetailSurvey = true;
      this.surveyDetailID = id;
    },
    hideSurveyDetail() {
      this.isShowDetailSurvey = false;
    },
    approvedSurvey(id) {
      AdminServices.approvedSurvey(id, { status: "APPROVED" }).then((resp) => {
        if (resp.data.ok) {
          this.surveyStatus = "COMPLETED";
          this.page.page = 1;
          this.page.size = 10;
          notify.success("Survey Approved");
          setTimeout(() => {
            this.getSurvey();
          }, 1000);
        }
      });
    },
    rejectSurvey(id) {
      AdminServices.approvedSurvey(id, { status: "REJECTED" }).then((resp) => {
        if (resp.data.ok) {
          this.surveyStatus = "COMPLETED";
          this.page.page = 1;
          this.page.size = 10;
          notify.success("Survey Rejected");
          setTimeout(() => {
            this.getSurvey();
          }, 1000);
        }
      });
    },
    loadPage(value) {
      this.page.page = value;
      this.surveyStatus = "COMPLETED";
      this.getSurvey();
    },
  },
});
