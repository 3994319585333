import adminServices from "@/services/admin";
import notify from "@/services/notify";
import _ from "lodash";
import { defineComponent } from "vue";
import Switcher from "@/components/Switcher";
import PickColors from 'vue-pick-colors'
import publicFileDataroom from "@/views/admin/dataroom/publicFileDataroom";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  components: { Switcher, PickColors, publicFileDataroom },
  data() {
    return {
      dataLogo: "",
      dataVideoName: '',
      form: {
        name: null,
        description: "",
        bg_color: "#fff",
        is_enabled: false,
        video_id: null,
        video_name: null
      },
      errorMsg: {
        name: null,
        logo: null,
        // description: null,
        video: null,
      },
      loading: false,
      displayColor: '#fff',
      selectedMediaType: 'VIDEO',
      isShowPublicDataroom: false,
    };
  },
  emits: ['complete', 'close'],
  created() {
    if (this.id) {
      this.fetchLpFund(this.id);
    }
  },
  methods: {
    fetchLpFund(id) {
      this.loading = true;
      adminServices
        .getLpFund(id)
        .then((resp) => {
          if (resp.data.ok) {
            this.form = _.cloneDeep(resp.data.d);
            this.dataLogo = this.form.logo;
            this.displayColor = resp.data.d.bg_color?resp.data.d.bg_color: '#ffffffff'
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    onSave() {
      let flag = true;

      if (!this.form.name || (this.form.name && this.form.name.trim() === "")) {
        this.errorMsg.name = "Please input name";
        flag = false;
      }
      // if ($.trim(this.form.description) === "") {
      //   this.errorMsg.description = "Please input description";
      //   flag = false;
      // }

      if (this.form.name && this.form.name.length > 250) {
        flag = false;
      }

      if (!this.dataLogo) {
        this.errorMsg.logo = "Please choose an image";
        flag = false;
      }
      if (!this.form.bg_color) {
        this.errorMsg.bg = "Please input a color code (hex)";
        flag = false;
      }
      if(!this.form.video_id) {
        this.errorMsg.video = "Please select video";
        flag = false;
      }

      if (flag) {
        if (this.id) {
          this.updateLpFund();
        } else {
          this.createLpFund();
        }
      }
    },
    createLpFund() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("description", this.form.description || '');
      formData.append("isEnabled", this.form.is_enabled);
      formData.append("backgroundColor", this.form.bg_color);
      formData.append("photo", this.form.logo);
      formData.append("VideoId", this.form.video_id);

      adminServices
        .createLpFund(formData)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("complete", "Add");
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    updateLpFund() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("isEnabled", this.form.is_enabled);
      formData.append("backgroundColor", this.form.bg_color);
      formData.append("photo", this.form.logo);
      formData.append("VideoId", this.form.video_id);

      adminServices
        .updateLpFund(this.id, formData)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("complete", "Update");
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    closeModal() {
      this.$emit("close");
    },
    renderLogoImg() {
      let ExtAllow = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      if (this.$refs.logoimg.files.length > 0) {
        let img = this.$refs.logoimg.files[0];

        if (!ExtAllow.includes(img.type)) {
          return;
        }
        let reader = new FileReader();

        reader.readAsDataURL(img);
        reader.onload = (e) => {
          this.dataLogo = e.target.result;
          if (this.dataLogo) {
            this.form.logo = img;
            this.errorMsg.logo = false;
          }
        };
      }
    },
    selectDataroomItem(e) {
      // e.preventDefault();
      this.$nextTick(() => {
        this.isShowPublicDataroom = true;
      });
    },
    closeSearchDataroomItem() {
      this.isShowPublicDataroom = false;
    },
    completeSelectDataroomItem(data) {
      const { dataroomId, name } = data;
      this.form.video_name = name;
      this.form.video_id = dataroomId;
      this.closeSearchDataroomItem();
    },
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = null;
    },
    "form.description": function (v) {
      this.errorMsg.description = null;
    },
    "form.video_id": function (v) {
      this.errorMsg.video = null;
    },
    displayColor: function(v) {
      this.form.bg_color = v 
    }
  },
});
