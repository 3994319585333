<template>
  <div>
    <label v-if="title" :class="`title title-color-${color}`" :for="name"
      >{{ title }}
      <template v-if="fieldRequire"
        ><small class="require">*</small></template
      ></label
    >
    <div
      :class="`text-field ${size ? 'size-' + size : ''} ${
        hideError ? 'hide-error' : ''
      }`"
    >
      <textarea
        :class="`field-color-${field}`"
        v-model="_value"
        :disabled="disabled"
        :id="name"
        :placeholder="maxLength > 0 ? `Maximum ${maxLength} characters` : ''"
        :tabindex="tabindex"
        :type="type"
        :autocomplete="autocomplete"
        :rows="rowHeight"
        :maxlength="maxLength"
        @keyup="checkLength"
        :index="indexField"
      />
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.title {
  .require {
    color: #f52727;
  }
}
.text-field {
  position: relative;
}
.title {
  margin-top: 0;
  .require {
    color: #f52727;
  }
}
textarea {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 0;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 20px;
  font-size: 14px;
  background: #494b54;
  color: #949ba2;
  resize: none;
  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
  &::-webkit-resizer {
    border: 2px solid black;
    background: #fff;
    /* box-shadow: 0 0 5px 5px #fff; */
    /* outline: 2px solid yellow; */
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #393c45;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #909090;
    border-radius: 4px;
    transition: all 300ms;
  }
}
.md-error {
  position: absolute;
  bottom: 3px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
  font-weight: 400;
}

.hide-error {
  // textarea {
  //     margin-bottom: 0;
  // }
  .md-error {
    display: none;
  }
}

.size-lg {
  textarea {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
.field-color-gray {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #35495e;
}
</style>
