<template>
  <div class="h-100" v-if="useEmptyLayout">
    <!-- slideInDown -->
    <div class="container-fluid animated page404 bg-not-found im-bg h-100">
      <div class="row h-100">
        <div
          class="col-10 offset-1 col-md-6 offset-md-3 h-100 d-flex align-items-center justify-content-center"
        >
          <div class="text-center">
            <h2 class="text-uppercase text-white">Page not found</h2>
            <p class="text-white">
              We're fairly sure that page used to be here, but seems to have
              gone missing. We do apologise on it's behalf.
            </p>
            <p class="pt-3">
              <router-link
                :to="homePath"
                class="button btn-border btn-gray py-2 text-center btn-radius radius-sm btn-long"
                >Take me home</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bg-not-found {
  background-image: url("../assets/images/bg-404.jpg");
}
</style>

<script src="./notFound.js"></script>
