<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Add LP Users to Fund</h4>
          </div>
          <div class="modal-body custom-modal-body" v-loading="loading">
            <div class="table-responsive-sm">
              <Table
                useSimpleLayout
                :paging="paging"
                :columns="columns"
                :rows="members"
                :showNoRow="true"
                :checkAll="true"
                :hasCheckbox="true"
                :idsSelected="targetMembersIds"
                isFixedHeader
                @updatePaging="updatePaging"
                @onSelectAll="onSelectAllMember"
                @onChangeCheckbox="selectMember"
              >
                <template #head_name>
                  <TextField
                    placeholder="Type to search .."
                    hideError
                    v-model:value="filteredName"
                  />
                </template>
                <template #head_email>
                  <TextField
                    placeholder="Type to search .."
                    hideError
                    v-model:value="filteredEmail"
                  />
                </template>
                <template #head_is_device>
                  <SelectField
                    v-model:value="filteredStatus"
                    :options="selectDeviceList"
                    hideError
                  />
                </template>
                <template #row_name="{ props }">
                  {{ props.fname }} {{ props.lname }}
                </template>

                <template #row_is_device="{ props }">
                  {{ props.is_device ? "Installed" : "Not Installed" }}
                </template>
                <!-- <template #row_actions="{ props }">
                  <template v-if="isMemberSelecting(props)">
                    <i class="fas fa-check-circle"></i>
                  </template>
                  <template v-else>
                    <div class="fas fa-circle"></div>
                  </template>
                </template> -->
              </Table>
            </div>
          </div>
          <div class="modal-footer text-center">
            <div style="margin-right: auto; color: #838894">
              {{ targetMembersIds.length }} user{{
                targetMembersIds && targetMembersIds.length > 1 ? "s" : ""
              }}
              selected
            </div>
            <button class="btn btn-secondary" @click="closeModal">Close</button>
            <button class="btn btn-primary" @click="onSave">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.custom-modal {
  :deep() .modal-footer {
    z-index: 1;
  }
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 200px);
  padding-top: 0;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    /* background: #212529; */
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }
}
.inactive {
  color: $color-danger;
}
</style>
