import Datepicker from '@/components/DatePicker'
import moment from 'moment'
import detailFile from './detailFile'
import AdminServices from '@/services/admin'
import customSelect from '@/components/commons/customSelect'
import Table, { debounce } from "@/components/Table";
import { defineComponent } from 'vue'

const INVESTOR_TYPES = [
    {
        value: 'NoType',
        text: '--No type--'
    },
    {
        value: 'Current',
        text: 'Current'
    },
    {
        value: 'Delegate',
        text: 'Delegate'
    },
    {
        value: 'Potential',
        text: 'Potential'
    }
]

export default defineComponent({
    name: 'investorFocus',
    components: {
        Table,
        Datepicker,
        detailFile,
        customSelect
    },
    data() {
        return {
            loading: false,
            typesSelected: [],
            investorTypes: [],
            pickerFrom: null,
            pickerTo: null,
            model: {
                displayFrom: '',
                displayTo: '',
                from: '',
                to: '',
                selectedInvestorTypes: []
            },
            selectedItemId: null,
            files: [],
            viewDetailFile: false,
            searchParams: {
                paging: {
                    page: 1,
                    size: 10,
                    total: 0
                },
                totalItems: 0,
                investorTypes: [],
                fromDate: '',
                toDate: ''
            },
            disabledToDates:{ to:  ''},
            disabledFromDates:{ from:  ''},
            columns: [
                {
                    id: 'fileName',
                    name: 'File name'
                },
                {
                    id: 'investorRead',
                    name: 'Investor Read'
                }
            ]
        }
    },
    created() {
        this.investorTypes = INVESTOR_TYPES
    },
    computed: {
        disabled() {
            return !this.pickerFrom && !this.pickerTo
        }
    },
    methods: {
        // loadPage(value) {
        //     this.searchParams.paging.page = value
        //     this.loadReport()
        // },
        loadReport() {
            if (!this.searchParams.fromDate && !this.searchParams.toDate) return
            this.loading = true
            AdminServices.getDataroomReportInvestorFocusOverview(this.searchParams).then(resp => {
                if (resp.data.ok) {
                    this.files = resp.data.d.c
                    this.searchParams.paging.page = resp.data.d.p
                    this.searchParams.paging.size = resp.data.d.s
                    this.searchParams.totalItems = resp.data.d.t
                } else {
                    this.files = []
                }
            }).finally(() => {
                this.loading = false
            })
        },
        searchBy() {
            const from = moment(`${this.pickerFrom.months}/${this.pickerFrom.days}/${this.pickerFrom.years}`).format('MM/DD/YYYY')
            const to = moment(`${this.pickerTo.months}/${this.pickerTo.days}/${this.pickerTo.years}`).format('MM/DD/YYYY')
            this.model.displayFrom = this.pickerFrom ? from : ''
            this.model.displayTo = this.pickerTo ? to : ''
            this.model.from = this.pickerFrom ? moment(from).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') : ''
            this.model.to = this.pickerTo ? moment(to).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') : ''
            this.model.selectedInvestorTypes = this.typesSelected

            this.searchParams.paging = { page: 1, size: 10, total: 0 }
            this.searchParams = {
                ...this.searchParams,
                investorTypes: this.typesSelected,
                fromDate: this.pickerFrom ? moment(from).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') : '',
                toDate: this.pickerTo ? moment(to).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') : '',
            }
            this.loadReport()
        },
        async viewDetail(file) {
            this.selectedItemId = file.id
            this.$nextTick(() => {
                this.viewFile()
            })
        },
        viewFile() {
            this.viewDetailFile = true
        },
        hideFile() {
            this.viewDetailFile = false
            this.selectedItemId = null
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY')
        },
        updatePaging(v) {
            this.searchParams.paging = Object.assign(this.searchParams.paging, v)
            this.loadReport()
        },
        parseLocalTime(param) {
            return moment(`${param.months}/${param.days}/${param.years}`)
        }
    }/* ,
    watch: {
        'searchParams.paging': {
            deep: true,
            handler: function(v) {
                debounce(this.loadReport)
            }
        }
    } */
})