import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'
import notify from '../../../../services/notify'

export default defineComponent({
    props: ['hideAddPartner', 'updatePartner'],
    data() {
        return {
            tableData: null,
            paging: {
                page: 1,
                size: 10,
                total: 0
            },
            modal: {
                email: null
            },
            emailDisabled: true,
            errMsg: null,
            dataEmail: []
        }
    },
    methods: {
        getMailList() {
            this.dataEmail = []
            AdminServices.findEmailPartner({ email: this.modal.email, page: this.paging.page, size: this.paging.size }).then(resp => {
                if (resp.data.ok) {
                    this.tableData = resp.data.d.c
                    this.paging.page = resp.data.d.p
                    this.paging.size = resp.data.d.s
                    this.paging.total = resp.data.d.t
                    if (this.tableData.length === 0) {
                        this.errMsg = 'Result not found. Please try another email and search again.'
                    }
                }
            })
        },
        loadPage(value) {
            this.paging.page = value
            this.getMailList()
        },
        userName(item) {
            return item.fname + ' ' + item.lname
        },
        selectItem(ref, item) {
            // console.log(ref, item)
            let el = this.$refs[ref]

            if ($(el).find('input[type="checkbox"]').prop('checked')) {
                $(el).find('input[type="checkbox"]').prop('checked', false)
                let idx = null
                this.dataEmail.map((p, index) => {
                    if (p === item.email) {
                        idx = index
                    }
                })
                this.dataEmail.splice(idx, 1)
            } else {
                $(el).find('input[type="checkbox"]').prop('checked', true)
                if (!this.dataEmail) {
                    this.dataEmail = []
                }
                let bol = this.dataEmail.includes(item.email)
                if (!bol) {
                    this.dataEmail.push(item.email)
                }
            }
        },
        addPartner() {
            if (this.dataEmail.length === 0) {
                return
            }
            let that = this
            AdminServices.addPartner({ emails: this.dataEmail }).then(resp => {
                if (resp.data.ok) {
                    notify.success('Add partner success!')
                    setTimeout(() => {
                        that.hideAddPartner()
                        that.updatePartner()
                    }, 2000)
                }
            })
        }
    },
    watch: {
        'modal.email': function (v) {
            this.errMsg = null
            if ($.trim(v) === '') {
                this.emailDisabled = true
            } else {
                this.emailDisabled = false
            }
        }
    }
})