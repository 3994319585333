<template>
  <div class="adminContent p-3" v-loading="loading">
    <ul class="nav">
      <li
        v-for="(item, idx) in tabs"
        class="nav-item pointer"
        :key="`tabs_${idx}`"
      >
        <a
          class="nav-link pointer"
          :class="{ active: tab_active === idx }"
          href="javascript:void(0)"
          @click="changeTab(idx)"
          ><span>{{ item.name }}</span></a
        >
      </li>
    </ul>
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          name="searchName"
          v-model:value="filteredName"
        />
      </template>
      <template #head_email>
        <TextField
          placeholder="Type to search .."
          hideError
          name="searchEmail"
          v-model:value="filteredEmail"
        />
      </template>
      <template #head_stt>
        <SelectField
          v-model:value="filteredStatus"
          :options="statusList"
          hideError
        />
      </template>
      <template #head_portfolio>
        <SelectField
          v-model:value="filteredPortfofiloId"
          :options="partnerList"
          hideError
        />
      </template>
      <template #head_sentby>
        <TextField
          placeholder="Type to search .."
          hideError
          name="searchSentBy"
          v-model:value="filteredSentBy"
        />
      </template>
      <template #row_stt="{ props }">
        <div>{{ $filters.mapStatus(props.stt, statusList) }}</div>
        <small v-if="props.is_temporarily_locked"
          >Locked Until:
          {{ $filters.parseEstTime(props.lockout_end_datetime) }}</small
        >
      </template>
      <template #row_portfolio="{ props }">
        <div>{{ props.portfolio_name }}</div>
      </template>
      <template #row_sentby="{ props }">
        <div>{{ props.invitator_fullname }}</div>
        <small>{{ $filters.parseEstTime(props.invitation_datetime) }} </small>
      </template>
      <template #row_actions="{ props }">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle custom-menu-dropdown"
            type="button"
            id="founderManagement"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="founderManagement"
          >
            <li>
              <span
                class="dropdown-item pointer"
                @click="editFounder(props)"
                title="Edit"
                ><i class="far fa-edit me-1"></i>Edit</span
              >
            </li>
            <li>
              <span
                @click="updateStatus(props)"
                :title="props.stt === 'INACTIVE' ? 'Activate' : 'Inactivate'"
                v-if="['ACTIVE', 'INACTIVE'].includes(props.stt)"
                class="dropdown-item pointer"
              >
                <template v-if="props.stt === 'INACTIVE'">
                  <i class="fas fa-lock me-1"></i>Activate
                </template>
                <template v-else>
                  <i class="fas fa-lock-open me-1"></i>Inactivte
                </template>
              </span>
            </li>
            <li>
              <span
                @click="reinviteFounder(props)"
                title="Re-invite"
                v-if="props.stt === 'INVITATION_SENT'"
                class="dropdown-item pointer"
              >
                <i class="far fa-envelope-open me-1"></i>Reinvite
              </span>
            </li>
            <li>
              <span
                @click="confirmEmail(props)"
                title="Confirm email"
                v-if="props.stt === 'NEED_EMAIL_CONFIRMATION'"
                class="dropdown-item pointer"
              >
                <i class="fas fa-check me-1"></i>Confirm Email
              </span>
            </li>
            <li>
              <span
                @click="unlockUser(props)"
                title="Unlock user"
                v-if="props.is_temporarily_locked"
                class="dropdown-item pointer"
              >
                <i class="fas fa-history me-1"></i>Unlock user
              </span>
            </li>
            <li>
              <span
                v-if="hasPermission(PERMISSIONS.Users_Delete)"
                @click="deleteUser(props)"
                class="dropdown-item pointer"
              >
                <i class="far fa-trash-alt me-1"></i>Delete
              </span>
            </li>
            <li>
              <DropdownItem
                v-if="tabs[tab_active].type != founderType"
                @click="convertUserType(props)"
                title="Convert"
                class="dropdown-item pointer"
              >
                <i class="far fa-edit me-1"></i>Convert User Type
              </DropdownItem>
            </li>
          </ul>
        </div>
      </template>

      <template #footer>
        <button
          v-if="tabs[tab_active].type == founderType"
          class="btn btn-primary"
          @click="addUser"
        >
          <span class="text">Invite Founder</span>
        </button>
      </template>
    </Table>

    <AddFounderModel
      ref="addFounderModel"
      :key="addFounderModelKey"
      @refresh="getAll"
      :partnerList="partnerListForEdit"
    />

    <EditFounderModel
      ref="editFounderModel"
      :key="editFounderModelKey"
      @refresh="getAll"
      :partnerList="partnerListForEdit"
    />

    <Dialog ref="dialog"></Dialog>
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss">
.adminContent.ld-loading {
  height: auto;
}
.nav-tabs {
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
  margin-bottom: 1em;
  &:after {
    height: 0;
  }
}

.nav-item {
  cursor: pointer;
  .nav-link {
    border: 0;
    padding: 10px 0;
    margin-right: 16px;
    color: #7e7e7e;
    &.active {
      background-color: transparent;
      color: #ffffff;
      border-bottom: none;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 3.62px;
        background-color: #3d7cba;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.boxed {
  background-color: #44464f;
  padding: 15px;
  border-radius: 21px;
}
</style>
