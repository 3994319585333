import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4geodata_worldHigh from '@amcharts/amcharts4-geodata/worldHigh';
am4core.useTheme(am4themes_dark);

import topHeader from '../topHeader'
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            aaaaa: null
        }
    },
    components: {
        topHeader
    },
    mounted() {
        this.investChart()
        this.donutChart()
        this.donutChart1()
    },
    methods: {
        investChart: function () {

            let chart = am4core.create('investchart', am4charts.XYChart)
            chart.hiddenState.properties.opacity = 0

            chart.data = [
                {
                    branch: 'Curve',
                    invest: 10000000
                },
                {
                    branch: 'Brandhouse',
                    invest: 5000000
                },
                {
                    branch: 'Rokk3r fuel',
                    invest: 5000000
                },
                {
                    branch: 'Boatsetter',
                    invest: 4500000
                },
                {
                    branch: 'Evrythng',
                    invest: 3750000
                },
                {
                    branch: 'CookUnity',
                    invest: 3500000
                },
                {
                    branch: 'Recargapay',
                    invest: 3060000
                },
                {
                    branch: 'Hastee',
                    invest: 2970000
                },
                {
                    branch: 'BIPI',
                    invest: 2130000
                },
                {
                    branch: 'Tradeshift',
                    invest: 2000000
                }
            ]

            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
            categoryAxis.renderer.grid.template.location = 0
            categoryAxis.dataFields.category = 'branch'
            categoryAxis.renderer.minGridDistance = 40
            categoryAxis.fontSize = 11

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
            valueAxis.min = 0
            valueAxis.max = 10000000
            valueAxis.strictMinMax = true
            valueAxis.renderer.minGridDistance = 30
            valueAxis.renderer.labels.template.disabled = true

            // let axisBreak = valueAxis.axisBreaks.create()
            // axisBreak.startValue = 2100
            // axisBreak.endValue = 22900

            // let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min)
            // axisBreak.breakSize = 0.05 * (1 - d) / d

            // let hoverState = axisBreak.states.create('hover')
            // hoverState.properties.breakSize = 1
            // hoverState.properties.opacity = 0.1
            // hoverState.transitionDuration = 1500

            // axisBreak.defaultState.transitionDuration = 1000

            let series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.categoryX = 'branch'
            series.dataFields.valueY = 'invest'
            series.columns.template.tooltipText = '{valueY.value}'
            series.columns.template.tooltipY = 1
            series.columns.template.strokeOpacity = 0

            series.columns.template.adapter.add('fill', function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index)
            })
        },
        donutChart: function () {
            let vue = this
            let chart = am4core.create('donutchart', am4charts.PieChart)

            chart.data = [
                {
                    'distribution': 'EU',
                    'amount': 501.9
                }, {
                    'distribution': 'US',
                    'amount': 301.9
                }, {
                    'distribution': 'LATAM',
                    'amount': 201.1
                }
            ]

            chart.innerRadius = am4core.percent(60)

            let pieSeries = chart.series.push(new am4charts.PieSeries())
            pieSeries.dataFields.value = 'amount'
            pieSeries.dataFields.category = 'distribution'
            pieSeries.dataFields.fontSize = 10

            pieSeries.slices.template.stroke = am4core.color('#fff')
            pieSeries.slices.template.strokeWidth = 0
            pieSeries.slices.template.strokeOpacity = 1

            pieSeries.slices.template.fontSize = 14

            pieSeries.hiddenState.properties.opacity = 1
            pieSeries.hiddenState.properties.endAngle = -90
            pieSeries.hiddenState.properties.startAngle = -90

            pieSeries.fontSize = 8
            pieSeries.fixedWidthGrid = true

            pieSeries.tooltip.fixedWidthGrid = true
            pieSeries.tooltip.fontSize = 14
        },
        donutChart1: function () {
            let vue = this
            let chart = am4core.create('donutchart1', am4charts.PieChart)

            chart.data = [
                {
                    'Serie': '<A',
                    'amount': 8000000
                }, {
                    'Serie': 'A',
                    'amount': 10000000
                }, {
                    'Serie': 'B',
                    'amount': 14000000
                }, {
                    'Serie': '>C',
                    'amount': 5000000
                }, {
                    'Serie': '>C',
                    'amount': 1000000
                }
            ]

            chart.innerRadius = am4core.percent(60)

            let pieSeries = chart.series.push(new am4charts.PieSeries())
            pieSeries.dataFields.value = 'amount'
            pieSeries.dataFields.category = 'Serie'
            pieSeries.dataFields.fontSize = 10

            pieSeries.slices.template.stroke = am4core.color('#fff')
            pieSeries.slices.template.strokeWidth = 0
            pieSeries.slices.template.strokeOpacity = 1

            pieSeries.slices.template.fontSize = 14

            pieSeries.hiddenState.properties.opacity = 1
            pieSeries.hiddenState.properties.endAngle = -90
            pieSeries.hiddenState.properties.startAngle = -90

            pieSeries.fontSize = 8
            pieSeries.fixedWidthGrid = true

            pieSeries.tooltip.fixedWidthGrid = true
            pieSeries.tooltip.fontSize = 14
        }
    }
})