<template>
  <transition name="slide">
    <div class="popupDetailSurvey" v-loading="loading" v-if="ready">
      <template>
        <div class="popupHeader">
          <router-link class="logo" :to="homePath">
            <logo :size-width="200"
          /></router-link>
          <h4 class="title titleBlack text-center m-0">{{ info.title }}</h4>

          <button
            type="button"
            class="close goback"
            aria-label="Close"
            @click="goback"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <g
                class="nc-icon-wrapper"
                stroke-linecap="square"
                stroke-linejoin="miter"
                stroke-width="2"
                fill="#424242"
                stroke="#424242"
              >
                <g class="nc-interact_menu-close-2-o-32">
                  <path
                    fill="none"
                    stroke="#424242"
                    stroke-miterlimit="10"
                    d="M2 6h28"
                    transform="translate(0 10.00) rotate(45.00 16 6)"
                  />
                  <path
                    data-color="color-2"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M2 16h28"
                    opacity="0"
                  />
                  <path
                    fill="none"
                    stroke="#424242"
                    stroke-miterlimit="10"
                    d="M2 26h28"
                    transform="translate(0 -10) rotate(-45 16 26)"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="popupContent" ref="popupContent">
          <div class="container pt-3" v-for="item in info.section" :key="item">
            <template v-if="item.id === page.page">
              <h5 class="text-right mb-3" v-if="item.name">{{ item.name }}</h5>
              <div
                class="form-group"
                v-for="(q, idx) in item.questions"
                :key="idx"
              >
                <template v-if="permission !== 'edit'">
                  <label class="question-title">{{
                    `${idx + 1}. ${q.name}`
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-if="q.type === 'INPUT'"
                    :value="q.answers"
                    disabled
                    readonly
                  />
                  <div v-else-if="q.type === 'MULTI'">
                    <div v-for="n in q.details" :key="n" class="multiAnswer">
                      <input
                        type="checkbox"
                        disabled
                        readonly
                        :checked="checkActivebox(n.Id, q.answers)"
                      />
                      <label>{{ n.Answer }}</label>
                    </div>
                  </div>
                  <div v-else-if="q.type === 'ONE'">
                    <div
                      v-for="n in q.details"
                      :key="n"
                      class="oneAnswer"
                      :ref="q.id"
                      :id="n.Id"
                    >
                      <input
                        type="radio"
                        :name="q.id"
                        :id="`${q.id}_${n.Id}`"
                        :checked="checkActivebox(n.Id, q.answers)"
                      />
                      <label
                        class="pointer"
                        :for="`${q.id}_${n.Id}`"
                        :class="{ active: checkActivebox(n.Id, q.answers) }"
                        >{{ n.Answer }}</label
                      >
                    </div>
                  </div>
                  <div v-else-if="q.type === 'MULTI-INPUT'">
                    <div v-for="n in q.details" :key="n" class="multiAnswer">
                      <template v-if="n.AnswerType === 'LABEL'">
                        <input
                          type="checkbox"
                          disabled
                          readonly
                          :checked="checkActivebox(n.Id, q.answers)"
                        />
                        <label>{{ n.Answer }}</label>
                      </template>
                      <template v-if="n.AnswerType === 'INPUT'">
                        <input
                          type="checkbox"
                          :id="`${q.id}_${n.Id}`"
                          :checked="checkActivebox(n.Id, q.answers)"
                          disabled
                        />
                        <label
                          class="pointer"
                          :for="`${q.id}_${n.Id}`"
                          :class="{ active: checkActivebox(n.Id, q.answers) }"
                          >Other:</label
                        >
                        <!-- <input type="text" v-model="q.answers[0].Answer" class="form-control other" :class="{'active': checkActivebox(n.Id, q.answers)}" :ref="`${q.id}_${n.Id}`" /> -->
                        <template v-if="checkInputValue(q.answers, n).status">
                          <input
                            type="text"
                            :value="checkInputValue(q.answers, n).value"
                            class="form-control other"
                            @change="inputChange(`${q.id}_${n.Id}`, q, n)"
                            :ref="`${q.id}_${n.Id}`"
                          />
                        </template>
                        <template v-else>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :ref="`${q.id}_${n.Id}`"
                          />
                        </template>
                      </template>
                    </div>
                  </div>
                  <div v-else-if="q.type === 'FILE'">
                    File:
                    <a
                      class="pointer link"
                      @click="downloadFile(q.answers[0].S3Key)"
                      v-if="q.answers.length"
                      >{{ q.answers[0].Name }}</a
                    >
                  </div>
                </template>

                <template v-if="permission === 'edit'">
                  <label class="question-title">{{
                    `${q.id}. ${q.name}`
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-if="q.type === 'INPUT'"
                    v-model="q.answers[0]"
                    @change="textChange(`${q.type}_${q.id}`, q)"
                    :ref="`${q.type}_${q.id}`"
                  />
                  <div v-else-if="q.type === 'MULTI'">
                    <div v-for="n in q.details" :key="n" class="multiAnswer">
                      <input
                        type="checkbox"
                        :id="`${q.id}_${n.Id}`"
                        :checked="checkActivebox(n.Id, q.answers)"
                        @change="check(q, n)"
                      />
                      <label
                        class="pointer"
                        :for="`${q.id}_${n.Id}`"
                        :class="{ active: checkActivebox(n.Id, q.answers) }"
                        >{{ n.Answer }}</label
                      >
                    </div>
                  </div>
                  <div v-else-if="q.type === 'MULTI-INPUT'">
                    <div v-for="n in q.details" :key="n" class="multiAnswer">
                      <template v-if="n.AnswerType === 'LABEL'">
                        <input
                          type="checkbox"
                          :id="`${q.id}_${n.Id}`"
                          :checked="checkActivebox(n.Id, q.answers)"
                          @change="check(q, n)"
                        />
                        <label
                          class="pointer"
                          :for="`${q.id}_${n.Id}`"
                          :class="{ active: checkActivebox(n.Id, q.answers) }"
                          >{{ n.Answer }}</label
                        >
                      </template>
                      <template v-if="n.AnswerType === 'INPUT'">
                        <input
                          type="text"
                          class="form-control"
                          :value="
                            parseValue(n.Id, q.answers)
                              ? parseValue(n.Id, q.answers)
                              : n.Answer
                          "
                          @change="inputChange(`${q.id}_${n.Id}`, q, n)"
                          :ref="`${q.id}_${n.Id}`"
                        />
                      </template>
                    </div>
                  </div>
                  <div v-else-if="q.type === 'FILE'">
                    File:
                    <a
                      class="pointer link"
                      @click="downloadFile(q.answers[0].S3Key)"
                      v-if="q.answers.length"
                      >{{ q.answers[0].Name }}</a
                    >
                    <input
                      type="file"
                      @change="updateFile(`${q.type}_${q.id}`, q)"
                      :ref="`${q.type}_${q.id}`"
                      v-else
                    />
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
        <!-- <div class="popupFooter" :style="`width: ${fWidth}px;`"> -->
        <div class="popupFooter" style="width: 100%">
          <div class="finish py-2 mb-2">
            <div class="container">
              <div class="row">
                <div class="col-12 text-center">
                  <template v-if="page.page === page.total">
                    <button
                      class="btn btn-primary pointer mb-2"
                      @click="finishSurvey(detailSurveyId)"
                      v-if="
                        profile.welcome_survey_stt === 'NOT_STARTED' ||
                        profile.welcome_survey_stt === 'IN_PROGRESS'
                      "
                    >
                      Save</button
                    ><br />
                    <router-link :to="homePath"
                      ><img
                        src="../../../assets/images/Logo-Rokk3r.png"
                        alt=""
                        style="max-width: 200px"
                    /></router-link>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="control colorWhite pt-2 pb-2">
            <div class="container">
              <div class="row">
                <div class="col col-12 text-center">
                  <span>Founder: {{ info.founder }}</span>
                  <span>Scored: {{ sumScored }}</span>
                  <button
                    class="btn btn-primary"
                    @click="approvedSurvey(surveyID)"
                  >
                    Accept
                  </button>
                  <button
                    class="btn btn-danger"
                    @click="rejectSurvey(surveyID)"
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination colorWhite">
            <div class="container">
              <pager
                :loadContentAtPage="loadPage"
                :page="page.page"
                :size="page.size"
                :total="page.total"
                :pagerFull="false"
              ></pager>
            </div>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";

.blockProgress {
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  min-width: 375px;
  max-width: 500px;
  .text {
    flex: 0 0 125px;
    font-size: 14px;
  }
  .progress {
    vertical-align: middle;
    flex: 1;
    height: 5px;
    position: relative;
    background-color: #7b7b7b;
    margin-top: 7px;
    span {
      transition: width 300ms linear;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      z-index: 1;
      background-color: #fff;
      border-radius: 0.25rem;
      height: 100%;
    }
  }
}
.popupDetailSurvey {
  position: static;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
  background-color: $color-background-secondary; //#fff;
  clip-path: inset(0 0 0 0);
  .popupHeader {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 52px;
    background-color: $color-grey; //#409eff;
    position: relative;
    .logo {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      display: block;
      overflow: hidden;
      background-color: #fff;
      border-radius: 4px;
      padding: 10px;
      z-index: 2;
    }
    h4 {
      line-height: 52px;
      font-weight: 400;
      position: relative;
      button {
        position: absolute;
        right: 0px;
        top: calc(50% - 21px);
      }
    }
    .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 1;
      color: #fff;
      text-shadow: unset;
      z-index: 2;
    }
    .goback {
      width: 22px;
    }
    @media (max-width: 767.98px) {
      text-align: center;
      .logo {
        position: static;
        transform: unset;
        padding: 0 5px;
        display: inline-block;
        width: 100px;
      }
      h4 {
        line-height: 26px;
        position: static;
      }
    }
  }
  .popupContent {
    background-color: $color-background-secondary;
    display: block;
    .container {
      width: 100%;
      max-width: 610px;
      padding-bottom: 100px;
      color: #c0c4cc;
      h5 {
        border-bottom: 1px solid #a9a9a9;
        line-height: 30px;
      }
      label {
        &.question-title {
          color: $text-color; //#6b6b6b;
        }
      }
      input {
        color: $text-color--secondary; //#585858;
      }
      .multiAnswer,
      .oneAnswer {
        input[type="checkbox"],
        input[type="selectbox"] {
          vertical-align: top;
          margin-top: 3px;
        }
        label {
          display: inline-block;
          width: calc(100% - 20px);
          vertical-align: top;
          &.active {
            color: #001dff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .popupFooter {
    position: absolute;
    bottom: 0;
    // left: 0;
    display: block;
    overflow: hidden;
    // width: 100%;
    .control {
      display: block;
      overflow: hidden;
      width: 100%;
      background-color: #000;
      span {
        line-height: 32px;
        display: inline-block;
        margin-right: 5px;
      }
      button {
        margin-right: 5px;
        padding: 5px 2rem;
      }
    }
    .pagination {
      display: block;
      overflow: hidden;
      width: 100%;
      max-height: 52px;
      background-color: $color-dark;
      padding: 5px 0;
    }
    .paging {
      display: flex;
      justify-content: center;
    }
    .finish {
      button {
        background-color: #3e99ed;
        border: 1px solid #3e99ed;
      }
    }
  }
}
</style>
<script src="./index.js"></script>
