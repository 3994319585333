import { defineComponent } from 'vue'
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
	props: {
        disabled: { type: Boolean, default: false },
		value: { type: [String, Object], default: '' },
		options: { type: Array, default: ()=>[] },
        title: { type: String, default: '' },        
        errorMsg: { type: String, default: '' },
        hideError: { type: Boolean, default: false },
		color: { type: String, default: '' },
		multiple: {type: Boolean, default: false},
		placeholder: {type: String, default: ''},
        indexField:{type: Number, default : 0},
		name:{ type: String, default: null},
		field: { type: String, default: ''}
	},
	components: {
		VueMultiselect
	},
	emits: ['update:value', 'update:errorMsg'],
	data() {
		return {
			selected: null
		}
	},
	watch: {
		_value(v) {
			this._errorMsg = ''
		},
		selected(v) {
			this.$emit('update:value', v)
		}
	},
	computed: {
		_value: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('update:value', val)
			}
		},
		_errorMsg: {
			get() {
				return this.errorMsg
			},
			set(val) {
				this.$emit('update:errorMsg', val)
			}
		}
	}
})