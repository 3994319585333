<template>
  <div :class="`btn btn-style-v2`" @click="click">
    <div v-if="loading" class="spinner-border spinner-border-sm">
      <span class="sr-only">Loading...</span>
    </div>

    <div v-else>
      <div :class="{ loading }" class="text"><slot /></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: { type: Boolean, default: false },
    spinnerCenter: { type: Boolean, default: true },
    theStyle: { type: Boolean, default: true },
    color: { type: String, default: "" },
    iconClass: { type: String, default: "" },
  },
  methods: {
    click() {
      if (this.loading) return;
      this.$emit("submit");
    },
  },
});
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
}
.loading {
  opacity: 0 !important;
}
.the-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .spinner-border {
    color: white;
  }
  &.spinner-center {
    justify-content: center;
  }
}
</style>
