import { defineComponent } from "vue";

export default defineComponent({
  validateEmail(email) {
    if (!email) return;
    const re = email.split("@");
    if (re.length < 2) {
      return false;
    } else if (re.length >= 2) {
      const indexFinalSplit = re.length - 1;
      const itemFinal = re[indexFinalSplit];
      const checkDot = itemFinal.split(".");
      if (checkDot.length < 2) {
        return false;
      } else if (checkDot.length >= 2) {
        return true;
      }
    }
  },
  validateEmailRegex(email) {
    var re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
});
