import authService from "../../../services/auth";
import _ from "lodash";
// import Base64 from "base-64";
import router from "@/config/router";
import notify from "../../../services/notify";
import appStore, { APP_MUTATION } from "../../../store/app";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  components: {
    LogoIcon,
  },
  data() {
    return {
      loading: false,
      success: false,
      recaptcha: "",
      email: "",
      // captchaInstance: {},
      loginPath: router.login.path,
      homePath: router.home.path,
      resendRegisterVerifyPath: router.resendVerifyRegisterEmail.path,
    };
  },
  computed: {
    disabled() {
      return this.loading || !this.email || !this.recaptcha;
    },
    useEmptyLayout() {
      return (
        this.$store.state.useEmptyLayout ||
        (this.$route.meta && this.$route.meta.isEmptyLayout)
      );
    },
  },
  mounted() {
    $("body").addClass("bglogin");
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
    this.email = this.$route.query.e;
    this.code = this.$route.query.c;
    this.$nextTick(() => {
      this.verify();
    });
  },
  methods: {
    /* submit(token) {
            this.loading = true
            this.recaptcha = token
            authService.verifyEmail(this.email, this.code, this.recaptcha).then(resp => {
                if (resp.error) {
                    handleErrors(resp)
                    this.resetRecaptcha()
                    this.success = false
                } else {
                    notify.success('Your account has been activated')
                    setTimeout(() => {
                        this.$router.push({
                            path: this.loginPath
                        })
                        this.success = true
                    }, 5000)
                }
                this.loading = false
            })
        }, */
    /* validate() {
            this.resetVaildate()

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (!re.test(this.email)) {
                this.messageError.email = 'Invalid email address'
                this.frmInvalidate = true
            }

            if (this.frmInvalidate) {
                return
            }

            this.$refs.recaptcha?.execute()

        }, */
    verify() {
      this.loading = true;
      authService
        .verifyEmail(this.email, this.code, this.recaptcha)
        .then((resp) => {
          if (!resp.error) {
            if (!(resp.data.d && resp.data.d.warning_code)) {
              notify.success("Your account has been activated.");
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.$router.push({
              path: this.loginPath,
            });
          }, 2000);
        });
    },
    /* resetRecaptcha() {
            if (_.isFunction(this.captchaInstance.reset)) {
                this.captchaInstance.reset()
            }
        }, */
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
    /* resetVaildate() {
            this.frmInvalidate = false
            this.messageError = {
                email: null
            }
        } */
  },
});
