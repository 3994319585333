<template>
  <div class="header">
    <div class="headerWrapper">
      <div class="textLogo">
        <p class="m-0 title">IDCV</p>
        <p class="m-0 desc"><small>Dashboard</small></p>
      </div>
      <div class="menu">
        <ul>
          <li>
            <router-link :to="overviewPath" active-class="active"
              >Overview</router-link
            >
          </li>
          <li>
            <router-link :to="investmentPath" active-class="active"
              >Investments</router-link
            >
          </li>
          <li>
            <router-link :to="portfolioPerformancetPath" active-class="active"
              >Portfolio performance</router-link
            >
          </li>
          <li>
            <router-link :to="overviewPath">Valuation evolution</router-link>
          </li>
          <li>
            <router-link :to="overviewPath">Detailed portfolio</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.header {
  display: block;
  overflow: hidden;
  height: 65px;
  width: 100%;
  background-color: #f8f8f8;
  .headerWrapper {
    display: flex;
    .textLogo {
      flex: 0 0 100px;
      padding: 10px 0;
      text-align: center;
      .title {
        font-size: 18px;
        color: rgb(51, 51, 51);
        font-weight: bold;
      }
      .desc {
        font-size: 14px;
      }
    }
    .menu {
      flex: 1;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        width: 100%;
        li {
          flex: 1;
          text-align: center;
          a {
            display: block;
            line-height: 65px;
            color: rgb(0, 0, 0);
            &.active {
              position: relative;
              color: rgb(41, 68, 194);

              &:before {
                content: "";
                display: block;
                overflow: hidden;
                width: 100%;
                height: 10px;
                background-color: rgb(41, 68, 194);
                left: 0;
                bottom: 0;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}
</style>
