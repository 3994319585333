<template>
  <div class="about-us-container">
    <div class="about-us-main container-v2">
      <div class="about-us-wrapper">
        <div class="about-us-inner">
          <div class="text-a-wrapper a-text-1">
            <h1 class="heading">Miami Roots</h1>
          </div>

          <div class="text-a-wrapper a-text-2">
            <h2 class="sub-heading">Global Reach</h2>
          </div>

          <div class="a-content a-text-3">
            <span class="txt-blue">Fuel Venture Capital</span>
            was founded in 2017 in the heart of Miami with the goal of fueling a
            modern economy based on knowledge and vision. Industry agnostic in
            our venture sourcing, we target groundbreaking early and late-stage
            companies using disruptive technologies as a main differentiating
            factor, with proven product market fit demonstrated by traction
            across all metrics, at attractive valuations driven by exceptional
            teams of multidisciplinary entrepreneurs. Fuel Venture Capital's
            "founder focused, investor driven" approach balances a commitment to
            guiding portfolio companies to success and delivering enhanced,
            risk-mitigated returns to our investors - ultra-high net worth
            individuals, family offices and institutions.
          </div>
        </div>
      </div>
    </div>

    <div class="wave">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,224L120,240C240,256,480,288,720,288C960,288,1200,256,1320,240L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import ScrollOut from "scroll-out";

export default {
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [],
      haveIt2: [],
      translateY: 110,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.translateY = 48;
    }

    this.handleScrollTextReveal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();

      ScrollOut({
        targets: ".about-us-wrapper",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationBackground();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".a-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".a-text-2",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText2();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".a-text-3",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText3();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });
    },

    handleSliceText() {
      var textWrapper = document.querySelector(".a-text-1 .heading");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );

      // Wrap every letter in a span
      var textWrapper2 = document.querySelector(".a-text-2 .sub-heading");
      textWrapper2.innerHTML = textWrapper2.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );

      var textWrapper3 = document.querySelector(".a-text-3");
      if (textWrapper3.querySelector(".t-words")) return;

      textWrapper3.innerHTML = textWrapper3.innerHTML.replace(
        /(^|<\/?[^>]+>|\s+)([^\s<]+)/g,
        function (match, p1, p2) {
          return `${p1}<div class="t-words"><p class="letter">${p2}</p></div>`;
        }
      );
    },

    animationBackground() {
      anime({
        targets: ".about-us-wrapper",
        duration: 1000,
        easing: this.easing,
        opacity: [0, 1],
        translateY: [30, 0],
      });
    },

    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".a-text-1 .heading");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".a-text-1 .letter",
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });
    },

    animationText2() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".a-text-2 .sub-heading");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".a-text-2 .letter",
        delay: (el, i) => {
          this.haveIt2 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt2") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });
    },

    animationText3() {
      anime({
        targets: ".a-text-3 .letter",
        opacity: [0, 1],
        // delay: (el, i) => 400 + anime.random(0, 200),
        delay: 600,
        easing: this.easing,
        translateY: [24, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.about-us-container {
  position: relative;
  color: #000;

  background: url("../../../../assets/images/v2/about-us-banner.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.about-us-main {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 74px);

  display: flex;
  align-items: center;

  .about-us-inner {
    padding: 24px;
    background: rgba(255, 255, 255, 0.75);
  }

  .about-us-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 770px;
    margin: 24px auto;
    text-align: center;

    padding: 24px;
    background: rgba(255, 255, 255, 0.35);

    @media (min-width: 768px) {
      padding: 48px;
    }
  }

  .heading {
    font-size: 36px;
    font-weight: 700;

    @media (min-width: 768px) {
      font-size: 110px;
      line-height: 110px;
      letter-spacing: -0.02em;
    }
  }

  .sub-heading {
    font-size: 36px;
    font-weight: 200;
    font-style: italic;

    padding-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 110px;
      line-height: 110px;
      letter-spacing: -0.02em;
    }
  }

  .a-content {
    font-family: "SF Pro Text";
    text-align: justify;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .txt-blue {
      color: #0095ff;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}

.wave svg {
  position: absolute;
  width: 100%;
  bottom: -30px;
  left: 0;
}
</style>
