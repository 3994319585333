<template>
  <div
    class="adminContent p-3"
    v-loading="loading || loadingInitial"
    v-if="!loadingInitial"
  >
    <div class="data p-3">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center mb-2">
          <selectUser
            ref="selectInvestors"
            v-model:value="selectedInvestors"
            :options="filteredInvestors"
            isMultiple
            label="Select investors"
            :key="selectedInvestorComponentKey"
            class="w-100"
          />
        </div>
        <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center mb-2">
          <button class="btn btn-primary btn-search" @click="searchBy">
            Search
          </button>
          <span class="error-msg ml-3">
            <span v-if="errorMsg.commonError">{{ errorMsg.commonError }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="reports && reports.length > 0">
      <h5>Results</h5>
      <div class="row">
        <div class="col-12">
          <div class="usersReport">
            <Table
              :paging="searchParams.paging"
              @updatePaging="updatePaging"
              :columns="columns"
              :rows="reports"
              :total-items="searchParams.totalRows"
              :showNoRow="false"
              v-loading="loading"
              :hideHeader="true"
              useSimpleLayout
            >
              <template #row_report="{ props }">
                <div class="p-2">
                  <div class="d-flex flex-column title">
                    <span
                      >{{ props.fname }} {{ props.lname }}
                      <span class="sub-text">{{ props.username }}</span></span
                    >
                    <span class="sub-text"
                      >Invited by {{ props.inviter_fname }}
                      {{ props.inviter_lname }}</span
                    >
                  </div>
                  <hr class="my-2 separator" />
                  <div class="table-responsive-sm">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th class="fileName">File</th>
                          <th class="viewCount">
                            <p class="m-0">Views</p>
                          </th>
                          <th class="viewDownload">
                            <p class="m-0">Last viewed on</p>
                          </th>
                          <th class="whomShare">Shared by/on</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="props.dataroom_items">
                          <tr
                            v-for="(file, idx) in props.dataroom_items"
                            :key="idx"
                          >
                            <td>{{ file.name }}</td>
                            <td>{{ file.view_count }}</td>
                            <td>{{ $filters.parseEstTime(file.last_view_on) }}</td>
                            <td class="thin-text">
                              <p class="m-0">
                                {{ file.shared_by_fname }}
                                {{ file.shared_by_lname }}
                              </p>
                              <p class="m-0 sub-text">
                                {{ $filters.parseEstTime(file.shared_on) }}
                              </p>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr>
                            <td colspan="4" class="text-center py-3">
                              No files report
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.adminContent {
  h5 {
    color: #fff;
  }
  .usersReport {
    .separator {
      background: #ccc;
    }
    table {
      width: 100%;
      max-width: 100%;
      thead {
        th {
          padding: 0 7px;
          font-weight: 400;
        }
      }
    }
  }
}
.error-msg {
  font-size: 12px;
  color: orange;
  min-height: 20px;
}
.sub-text {
  font-size: 12px;
  line-height: 1.2;
}
.thin-text {
  font-weight: 200;
}
.title {
  line-height: 1.2;
  .sub-text {
    color: #ccc;
  }
}
</style>
