<template>
  <Modal
    :title="state === 'CREATE' ? 'Create Session' : 'Edit Session'"
    v-model:value="isShow"
  >
    <form @submit.prevent="save" autocomplete="false">
      <div class="row eventMilestone">
        <div class="col col-12 col-md-12">
          <TextField
            title="Session Name"
            :autocomplete="false"
            :errorMsg="errorMsg.name"
            :fieldRequire="true"
            placeholder="Enter session name"
            v-model:value="form.name"
          />
        </div>
        <div class="col col-12 col-md-12">
          <TextArea
            title="Description"
            v-model:value="form.description"
            name="Description"
            :autocomplete="false"
            :errorMsg="errorMsg.description"
            :fieldRequire="true"
          />
        </div>
        <div class="col-12 col-md-12">
          <div class="row">
            <div class="col-12 col-md-6 mb-4" style="position: relative">
              <div class="row">
                <div class="title-date">Start Date (EST)</div>
                <div class="col-7">
                  <Datepicker
                    v-model:value="startDate"
                    placeholder="MM/DD/YYYY"
                    :enableTimePicker="false"
                    :autocomplete="false"
                    :fieldRequire="true"
                    field="black"
                    :autoApply="false"
                    :minDate="startEvent"
                    :maxDate="endEvent"
                  />
                </div>
                <div class="col-5">
                  <Datepicker
                    v-model:value="startTime"
                    placeholder="--:-- --"
                    :autocomplete="false"
                    :fieldRequire="true"
                    :enableTimePicker="true"
                    field="hidden-icon"
                    :autoApply="false"
                    isTimePicker
                    :is24="false"
                  />
                </div>
                <div class="md-error" v-if="errorMsg.startDate">
                  {{ errorMsg.startDate }}
                </div>
              </div>
            </div>
            <!-- end date -->
            <div class="col-12 col-md-6 mb-4" style="position: relative">
              <div class="row">
                <div class="title-date">End Date (EST)</div>
                <div class="col-7">
                  <Datepicker
                    v-model:value="endDate"
                    placeholder="MM/DD/YYYY"
                    :enableTimePicker="false"
                    :autocomplete="false"
                    :fieldRequire="true"
                    field="black"
                    :autoApply="false"
                    :disabled="
                      !startDate ||
                      !startDate?.years ||
                      !startDate?.months ||
                      !startDate?.days
                        ? true
                        : false
                    "
                    :minDate="
                      new Date(
                        `${startDate?.years}-${startDate?.months}-${startDate?.days}`
                      )
                    "
                    :maxDate="endEvent"
                  />
                </div>
                <div class="col-5">
                  <Datepicker
                    v-model:value="endTime"
                    placeholder="--:-- --"
                    :autocomplete="false"
                    :fieldRequire="true"
                    field="hidden-icon"
                    :enableTimePicker="true"
                    :autoApply="false"
                    isTimePicker
                    :is24="false"
                    :disabled="
                      !startDate ||
                      !startDate?.years ||
                      !startDate?.months ||
                      !startDate?.days
                        ? true
                        : false
                    "
                  />
                </div>
                <div class="md-error" v-if="errorMsg.endDate">
                  {{ errorMsg.endDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="row">
            <div class="col-12 col-sm-5 col-md-6">
              <SelectField
                v-model:value="checkIn"
                title="Check-in before start time"
                name="checkIn"
                :options="checkInList"
              />
            </div>
            <div class="col-12 col-sm-7 col-md-6">
              <div class="form-group">
                <label class="document"
                  >Document <small class="colorRed">*</small>
                </label>
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control me-1"
                    readonly
                    :value="dataRoomItemName"
                  />
                  <NewButton
                    @click="selectDataroomItem('PDF')"
                    :loading="loading"
                    :iconClass="`fas fa-search`"
                  >
                    Search
                  </NewButton>
                </div>
                <span class="texterror colorRed" v-if="errorMsg.pdf"
                  ><small>Please choose pdf</small></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save()" :loading="loading"> Save </NewButton>
    </template>

    <publicFileDataroom
      v-if="isShowPublicDataroom"
      :mediaType="selectedMediaType"
      @close="closeSearchDataroomItem"
      @completed="completeSelectDataroomItem"
    />
  </Modal>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.title-date {
  font-family: "ProximaNova";
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 5px;
  &::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}
.eventMilestone :deep(.field-color-hidden-icon) {
  .dp__icon {
    display: none !important;
  }
  .dp__input_icon_pad {
    padding-left: 12px !important;
    color: #949ba2;
    border: none;
  }
}
.document {
  font-weight: 300;
  color: #ffffff;
}
.md-error {
  width: auto;
  color: #f52727;
  font-size: 0.8em;
  margin-top: -15px;
  position: absolute;
  bottom: -24px;
}
</style>

<style lang="scss">
.timepicker {
  // margin-top: 37px;

  .vue__time-picker .controls {
    display: none;
  }

  .vue__time-picker .controls .char {
    transform: translate(-22px, 4px);
  }

  .text-field {
    input {
      width: 100%;
      outline: none;
      border: 0;
      border-radius: 0;
      min-height: 34px;
      padding: 2px 25px 2px 7px;
      font-size: 0.9em;
      background: #494b54;
      color: #949ba2;
    }
  }
}
</style>
