import { defineComponent } from "vue";
import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import Dialog from "@/components/Dialog";
import notify from "@/services/notify";
import adminServices from "@/services/admin";
import AttendeeModal from "../components/attendee";
import TextField from "@/components/TextField";
import NewButton from "@/components/NewButton";

const columnsData = [
  {
    id: "name",
    name: "Name",
    columnClass: "multiple-line",
    _wordBreak: "break-word",
    _minWidth: "280px",
  },
  {
    id: "userType",
    name: "User Types",
  },
  {
    id: "schedule",
    name: "Schedule",
  },
  {
    id: "invitationSendDate",
    name: "Invitation Status",
    columnClass: "multiple-line",
  },
  {
    id: "answer",
    name: "Response",
    columnClass: "multiple-line",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "d-flex justify-content-center",
    columnClass: "text-center",
  },
];

export default defineComponent({
  inject: ["eventInfoData"],
  components: {
    Table,
    AttendeeModal,
    TextField,
    Dialog,
    DropdownItem,
    Dropdown,
    NewButton,
  },
  data() {
    return {
      rows: [],
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      form: null,
      itemModalKey: 0,
      filteredName: "",
      filteredEmail: "",
      totalItems: 0,
      columns: columnsData,
    };
  },
  watch: {
    paging: {
      deep: true,
      handler() {
        this.getAll();
      },
    },
    filteredName() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.eventInfoData) {
        this.form = this.eventInfoData;
      }
      this.getAll();
    });
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    deleteItem(item) {
      let message = {
        title: null,
        body: null,
      };
      let options = {
        html: false,
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "bounce",
        type: "basic",
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember",
      };

      if (item.invitation != null && item.invitation != "CANCELLED") {
        message = {
          title: `Can't delete`,
          body: `You can't delete the attendee. Please send a cancellation email to the user before deleting the attendee.`,
        };
        options = {
          okText: "",
        };
      } else {
        message = {
          title: "Delete Attendee",
          body: "Are you sure to delete the attendee? If you delete, the attendee cannot be reverted.",
        };
      }

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteAttendee(this.form.eventId, {
            email: item.email,
          });

          if (res && res.data && res.data.ok) {
            notify.success("Delete successfully");
            this.getAll();
          } else {
            notify.error(res.data.errorMsg);
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async addItem() {
      this.itemModalKey++;
      this.$nextTick(() => {
        this.$refs.itemModal.create();
      });
    },
    async getAll() {
      if (!this.form?.eventId) return;
      this.loading = true;
      const res = await adminServices.getAttendeesOfEvent({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        eventId: this.form.eventId,
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    invitationSendStatus(props) {
      let status = "-";
      if (props.invitation == "CANCELLED") status = "Cancellation sent";
      else if (props.invitation == "YES") status = "Invitation sent";
      return status;
    },
    answerStatus(props) {
      let status = "-";
      if (props.answer == 0) status = "Yes";
      else if (props.answer == 1) status = "No";
      else if (props.answer == 2) status = "Maybe";
      return status;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
