import authHttp, { API_ROOT } from "./auth-http";

export const AdminService = {
  addUrlParameter(paramName, data, existingParam = "") {
    if (data !== undefined) {
      if (existingParam === "") {
        existingParam = `${existingParam}?${paramName}=${data}`;
      } else {
        existingParam = `${existingParam}&${paramName}=${data}`;
      }
    }
    return existingParam;
  },
  getRobotCheckin(token) {
    return authHttp.get(`${API_ROOT}/robot/checkin?token=${token}`);
  },
  postRobotCheckin(params) {
    return authHttp.post(`${API_ROOT}/robot/checkin`, params);
  },
  getRobot(param) {
    return authHttp.get(`${API_ROOT}/robot?${authHttp.buildParams(param)}`);
  },
  submitRobot(data) {
    return authHttp.post(`${API_ROOT}/robot`, data);
  },
  getTeamData() {
    return authHttp.get(`${API_ROOT}/members`);
  },
  addTeamData(data) {
    return authHttp.put(`${API_ROOT}/members`, data);
  },
  removeTeamData(id) {
    return authHttp.delete(`${API_ROOT}/members/${id}`);
  },
  sortTeamData(data) {
    return authHttp.post(`${API_ROOT}/members/order`, data);
  },
  updateTeamData(data) {
    return authHttp.post(`${API_ROOT}/members/${data.get("id")}`, data);
  },
  //fund
  findFunds() {
    return authHttp.get(`${API_ROOT}/funds`);
  },
  getFund(id) {
    return authHttp.get(`${API_ROOT}/funds/${id}`);
  },
  createFund(data) {
    return authHttp.post(`${API_ROOT}/funds`, data);
  },
  updateFund(data) {
    return authHttp.put(`${API_ROOT}/funds/${data.id}`, data);
  },
  removeFund(id) {
    return authHttp.delete(`${API_ROOT}/funds/${id}`);
  },
  orderFund(data) {
    return authHttp.post(`${API_ROOT}/funds/order`, data);
  },
  //fund-member
  findMembersByFundId(id) {
    return authHttp.get(`${API_ROOT}/funds/${id}/members`);
  },
  addMembersToFund(data) {
    return authHttp.post(
      `${API_ROOT}/funds/${data.id}/add-members`,
      data.members
    );
  },
  orderMembersOfFund(data) {
    return authHttp.post(
      `${API_ROOT}/funds/${data.id}/order-members`,
      data.orders
    );
  },
  removeMemberFromFund(data) {
    return authHttp.delete(
      `${API_ROOT}/funds/${data.id}/remove-member/${data.memberId}`
    );
  },

  getPortfolioData(status) {
    return authHttp.get(`${API_ROOT}/portfolios?status=${status}`);
  },
  addPortfolioData(data) {
    return authHttp.put(`${API_ROOT}/portfolios`, data);
  },
  removePortfolioData(id) {
    return authHttp.delete(`${API_ROOT}/portfolios/${id}`);
  },
  sortPortfolioData(data) {
    return authHttp.post(`${API_ROOT}/portfolios/order`, data);
  },
  updatePortfolioData(data) {
    return authHttp.post(`${API_ROOT}/portfolios/${data.get("id")}`, data);
  },
  getFuelAndMindData(data) {
    if (!data) return;
    const { filteredType, filteredTitle, paging } = data;

    const params = {
      type: filteredType,
      title: filteredTitle,
      size: paging.size,
      page: paging.page,
    };
    return authHttp.get(`${API_ROOT}/news`, { params });
  },

  addFueledData(data) {
    return authHttp.put(`${API_ROOT}/news`, data);
  },
  removeFueledData(id) {
    return authHttp.delete(`${API_ROOT}/news/${id}`);
  },
  updateFueledData(data) {
    return authHttp.post(`${API_ROOT}/news/${data.get("id")}`, data);
  },
  sortFueledData(data) {
    return authHttp.post(`${API_ROOT}/news/order`, data);
  },
  getOverviewSurvey() {
    return authHttp.get(`${API_ROOT}/rokker/surveys/overview`);
  },
  getSurveyReview(status, data) {
    let param = `?status=${status}`;
    param = this.addUrlParameter("size", data.size, param);
    param = this.addUrlParameter("page", data.page, param);
    return authHttp.get(`${API_ROOT}/rokker/usersurveys/${param}`);
  },
  approvedSurvey(id, data) {
    return authHttp.post(`${API_ROOT}/rokker/usersurveys/${id}`, data);
  },
  rejectSurvey(id) {
    return authHttp.delete(`${API_ROOT}/rokker/usersurveys/${id}`);
  },
  getSurveyReviewDetails(id) {
    return authHttp.get(`${API_ROOT}/rokker/usersurveys/${id}`);
  },
  // getFuelAndMindData(param) {
  //     return authHttp.get(`${API_ROOT}/news`, authHttp.buildParams(param))
  // }
  requestDownloadS3(key) {
    return authHttp.get(
      `${API_ROOT}/file-download/request/s3?key=${encodeURIComponent(key)}`
    );
  },
  getUserInfo() {
    return authHttp.get(`${API_ROOT}/me`);
  },
  getSurveys(data) {
    let param = this.addUrlParameter("size", data.size);
    param = this.addUrlParameter("page", data.page, param);

    return authHttp.get(`${API_ROOT}/surveys${param}`);
  },
  getSurveyDetail(id) {
    return authHttp.get(`${API_ROOT}/surveys/${id}`);
  },
  getSurveyWelcome() {
    return authHttp.get(`${API_ROOT}/surveys/welcome`);
  },
  surveyAnwser(id, data) {
    return authHttp.post(`${API_ROOT}/surveys/${id}`, data);
  },
  surveyAnwserFile(userSurveyId, questionId, data) {
    return authHttp.post(
      `${API_ROOT}/surveys/${userSurveyId}/file/${questionId}`,
      data
    );
  },
  finishSurvey(id) {
    return authHttp.put(`${API_ROOT}/surveys/${id}`);
  },
  getKPIHistory() {
    return authHttp.get(`${API_ROOT}/kpi`);
  },
  getKPI(year, month) {
    return authHttp.get(`${API_ROOT}/kpi/${year}/${month}`);
  },
  saveKPI(year, month, data) {
    return authHttp.post(`${API_ROOT}/kpi/${year}/${month}`, { data });
  },
  getChartData(param) {
    return authHttp.get(
      `${API_ROOT}/kpi/charts/data?${authHttp.buildParams(param)}`
    );
  },
  getKpiView() {
    return authHttp.get(`${API_ROOT}/kpi/data-view`);
  },
  saveKpiView(chartIds) {
    return authHttp.post(`${API_ROOT}/kpi/data-view`, {
      kpiChartIds: chartIds,
    });
  },
  getPartnerInfo() {
    return authHttp.get(`${API_ROOT}/me/partner`);
  },
  getSurveyFinished(param) {
    return authHttp.get(
      `${API_ROOT}/rokker/usersurveys`,
      authHttp.buildParams(param)
    );
  },
  viewMySurvey(surveyId) {
    return authHttp.get(`${API_ROOT}/surveys/${surveyId}`);
  },
  getPartners(param) {
    return authHttp.get(`${API_ROOT}/partners?${authHttp.buildParams(param)}`);
  },
  getPartnerGoalFields(userId) {
    return authHttp.get(`${API_ROOT}/partners/${userId}/goal-fields`);
  },
  getPartnerGoal(userId, year, month) {
    return authHttp.get(
      `${API_ROOT}/partners/${userId}/goals/${year}/${month}`
    );
  },
  getPartnerKpi(portfolioId, year, month) {
    return authHttp.get(
      `${API_ROOT}/partners/${portfolioId}/kpi/${year}/${month}`
    );
  },
  getPartnerOwnershipInfo(userId, year, month) {
    return authHttp.get(
      `${API_ROOT}/partners/${userId}/ownership/${year}/${month}`
    );
  },
  getPartnerKpiFields(portfolioId) {
    return authHttp.get(`${API_ROOT}/partners/${portfolioId}/kpi-fields`);
  },
  saveGoal(userId, year, month, data) {
    return authHttp.post(
      `${API_ROOT}/partners/${userId}/goals/${year}/${month}`,
      { data }
    );
  },
  savePartnerKpi(portfolioId, year, month, data) {
    return authHttp.post(
      `${API_ROOT}/partners/${portfolioId}/kpi/${year}/${month}`,
      { data }
    );
  },
  saveOwnership(userId, year, month, cost, ownership) {
    return authHttp.post(
      `${API_ROOT}/partners/${userId}/ownership/${year}/${month}`,
      { InvestmentCost: cost, OwnershipPercentage: ownership }
    );
  },
  findEmailPartner(param) {
    return authHttp.get(
      `${API_ROOT}/partners/founders?${authHttp.buildParams(param)}`
    );
  },
  getPartnerDataView(userId) {
    return authHttp.get(`${API_ROOT}/partners/${userId}/data-view`);
  },
  savePartnerDataView(userId, chartIds) {
    return authHttp.post(`${API_ROOT}/partners/${userId}/data-view`, {
      kpiChartIds: chartIds,
    });
  },
  // addPartner(data) {
  //     return authHttp.post(`${API_ROOT}/partners`, data)
  // },
  // getPartnerKey(userId, year) {
  //     return authHttp.get(`${API_ROOT}/partners/${userId}/reports/kpi/${year}`)
  // },
  getChartDataByUserId(userId, param) {
    return authHttp.get(
      `${API_ROOT}/partners/${userId}/kpi/charts/data?${authHttp.buildParams(
        param
      )}`
    );
  },
  getExcelByUserId(userId, param) {
    return authHttp.get(
      `${API_ROOT}/partners/${userId}/kpi/excel/data?${authHttp.buildParams(
        param
      )}`
    );
  },
  searchSurvey(param) {
    return authHttp.get(
      `${API_ROOT}/rokker/usersurveys/search?${authHttp.buildParams(param)}`
    );
  },
  getGuestMember(param) {
    return authHttp.get(
      `${API_ROOT}/rokker/guest-register?${authHttp.buildParams(param)}`
    );
  },
  getAllRokkerUsers(data) {
    if (!data) return;
    const {
      paging,
      filteredName,
      filteredEmail,
      filteredRole,
      filteredStatus,
      orderBy,
      exceptUserIds,
    } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      name: encodeURIComponent(filteredName),
      email: filteredEmail,
      role: filteredRole,
      status: filteredStatus,
      exceptUserIds: exceptUserIds,
      orderBy,
    };
    return authHttp.get(
      `${API_ROOT}/users/rokkers?${authHttp.buildParams(params)}`
    );
  },
  createRokkerUser(data) {
    return authHttp.post(`${API_ROOT}/users/rokkers`, data);
  },
  updateRokkerUser(id, data) {
    return authHttp.put(`${API_ROOT}/users/rokkers/${id}`, data);
  },
  updateRokkerUserStatus(id, data) {
    return authHttp.put(`${API_ROOT}/users/rokkers/${id}/status`, data);
  },
  getPhoneCarrierMeta(data) {
    return authHttp.get(`${API_ROOT}/users/phone-carriers/meta`, data);
  },
  // resetRokkerUserPassword(id, data) {
  //     return authHttp.post(`${API_ROOT}/users/rokkers/${id}/reset-pwd`, data)
  // },
  resetUserPassword(id, data) {
    return authHttp.post(`${API_ROOT}/users/${id}/reset-pwd`, data);
  },
  getAllInvestors(data) {
    if (!data) return;
    const {
      paging,
      filteredName,
      filteredEmail,
      filteredStatus,
      filteredType,
      filteredRole,
      filteredSentBy,
      orderBy,
      filteredNda,
    } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      name: filteredName,
      email: filteredEmail,
      status: filteredStatus,
      investorType: filteredType,
      role: filteredRole,
      sentby: filteredSentBy,
      orderBy: orderBy,
      nda: filteredNda,
    };
    return authHttp.get(`${API_ROOT}/users/investors`, { params });
  },
  findAllInvestors(data) {
    if (!data) return;
    const { filteredName, filteredEmail, hasDevice } = data;
    const params = {
      name: filteredName,
      email: filteredEmail,
      hasDevice: hasDevice,
    };
    return authHttp.get(`${API_ROOT}/users/investors/get-all`, { params });
  },
  getInvestor(id) {
    return authHttp.get(`${API_ROOT}/users/investors/${id}`);
  },
  requestDownloadNdaForm(id) {
    return authHttp.get(`${API_ROOT}/users/investors/${id}/request-nda-form`);
  },
  inviteInvestor(data) {
    return authHttp.post(`${API_ROOT}/users/investors`, data);
  },
  reinviteInvestor(data) {
    return authHttp.post(
      `${API_ROOT}/users/investors/resend-invitation-email`,
      data
    );
  },
  updateInvestorStatus(id, data) {
    return authHttp.put(`${API_ROOT}/users/investors/${id}/status`, data);
  },
  updateInvestorInfo(id, data) {
    return authHttp.put(`${API_ROOT}/users/investors/${id}`, data);
  },
  requestDownloadCIO(id, action, version) {
    return version
      ? authHttp.get(
          `${API_ROOT}/dataroom/request-download?itemId=${id}&action=${action}&versionId=${version}`
        )
      : authHttp.get(
          `${API_ROOT}/dataroom/request-download?itemId=${id}&action=${action}`
        );
  },
  requestDownloadCIOAndCountView(id, action) {
    return authHttp.get(
      `${API_ROOT}/dataroom/request-download-count-view?itemId=${id}&action=${action}`
    );
  },
  // investorViewFile(id) {
  //     return authHttp.get(`${API_ROOT}/dataroom/view?itemId=${id}`)
  // },
  getUserRoles(data) {
    if (!data) return;
    const { paging, filteredName, filteredStatus, orderBy } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      name: filteredName,
      status: filteredStatus,
      orderBy: orderBy,
    };
    return authHttp.get(`${API_ROOT}/roles/user`, { params });
  },
  getAllInvestorRoles(data) {
    if (!data) return;
    const { paging, filteredName, filteredStatus, orderBy } = data;
    const params = {
      page: paging.page,
      size: paging.size,
      name: filteredName,
      status: filteredStatus,
      orderBy: orderBy,
    };
    return authHttp.get(`${API_ROOT}/roles/investor`, { params });
  },
  getAllInvestorPermissions() {
    return authHttp.get(`${API_ROOT}/permissions/investor`);
  },
  createInvestorRole(data) {
    return authHttp.post(`${API_ROOT}/roles/investor`, data);
  },
  updateInvestorRole(id, data) {
    return authHttp.put(`${API_ROOT}/roles/investor/${id}`, data);
  },
  getFounders(data) {
    if (!data) return;
    const {
      paging,
      filteredName,
      filteredEmail,
      filteredStatus,
      filteredSentBy,
      orderBy,
      filteredPortfofiloId,
      filteredUserType,
    } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      name: filteredName,
      email: filteredEmail,
      status: filteredStatus,
      sentby: filteredSentBy,
      orderBy: orderBy,
      portfolioId: filteredPortfofiloId,
      userType: filteredUserType,
    };

    if (filteredPortfofiloId) {
      params.portfolioId = filteredPortfofiloId;
    }
    return authHttp.get(`${API_ROOT}/users/founders`, { params });
  },
  updateFounderUserStatus(id, data) {
    return authHttp.put(`${API_ROOT}/users/founders/${id}/status`, data);
  },
  updateFounderConfirmEmail(id) {
    return authHttp.put(`${API_ROOT}/users/founders/${id}/confirm-email`);
  },

  convertUserType(id) {
    return authHttp.put(`${API_ROOT}/users/founders/${id}/convert-type`);
  },
  // unlockTemporaryLockedFounderUser(id) {
  //     return authHttp.put(`${API_ROOT}/users/founders/${id}/unlock`)
  // },
  getAllMessageTemplates(data) {
    if (!data) return;
    const { paging, filteredName, filteredSubject, filteredBody, orderBy } =
      data;
    const params = {
      size: paging.size,
      page: paging.page,
      name: filteredName,
      subject: filteredSubject,
      body: filteredBody,
      orderBy,
    };
    return authHttp.get(`${API_ROOT}/message-templates`, { params });
  },
  createMessageTemplate(data) {
    return authHttp.post(`${API_ROOT}/message-templates`, data);
  },
  updateMessageTemplate(id, data) {
    return authHttp.put(`${API_ROOT}/message-templates/${id}`, data);
  },
  deleteMessageTemplate(id) {
    return authHttp.delete(`${API_ROOT}/message-templates/${id}`);
  },
  getIgAccessToken() {
    return authHttp.get(`${API_ROOT}/system-params/ig-access-token`);
  },
  updateIgAccessToken(token) {
    return authHttp.post(`${API_ROOT}/system-params/ig-access-token`, {
      value: token,
    });
  },
  getAllDataroomItems() {
    return authHttp.get(`${API_ROOT}/dataroom`);
  },
  getSnapshotOfDataroomTreeView(id) {
    return authHttp.get(`${API_ROOT}/dataroom/tree-view/${id}`);
  },
  createDataroomFolder(data) {
    return authHttp.post(`${API_ROOT}/dataroom/folder`, data);
  },
  createDataroomFile(data, onUploadProgress) {
    return authHttp.post(`${API_ROOT}/dataroom/file`, data, {
      onUploadProgress,
    });
  },
  createDataroomFileV2(data) {
    return authHttp.post(`${API_ROOT}/dataroom/file-v2`, data);
  },
  getSessionToken() {
    return authHttp.get(`${API_ROOT}/dataroom/session-token`);
  },
  getPublicSessionToken() {
    return authHttp.get(`${API_ROOT}/dataroom/session-token/public`);
  },
  validateDataroomFile(data) {
    return authHttp.post(`${API_ROOT}/dataroom/file-validation`, data);
  },
  renameDataroomItem(data) {
    return authHttp.put(`${API_ROOT}/dataroom/${data.id}`, data);
  },
  deleteDataroomFolderItem(id) {
    return authHttp.delete(`${API_ROOT}/dataroom/${id}/folder`);
  },
  shareAndUnshareDataroomItem(data) {
    return authHttp.post(`${API_ROOT}/dataroom/${data.id}/share-unshare`, data);
  },
  getDataroomItemDetail(id) {
    return authHttp.get(`${API_ROOT}/dataroom/${id}`);
  },
  getDataroomItemFilesByParentId(id) {
    return authHttp.get(`${API_ROOT}/dataroom/${id}/children-files`);
  },
  getDataroomItemWithChildren(id) {
    return authHttp.get(`${API_ROOT}/dataroom/${id}/item-with-children`);
  },
  getDataroomFileItemsOfSameName(id) {
    return authHttp.get(`${API_ROOT}/dataroom/${id}/same-name`);
  },
  sortDataRoomItems(data) {
    return authHttp.put(`${API_ROOT}/dataroom/order`, data);
  },
  addVersionToDataroomFile(id, data, onUploadProgress) {
    return authHttp.post(`${API_ROOT}/dataroom/${id}/add-version`, data, {
      onUploadProgress,
    });
  },
  addVersionToDataroomFileV2(id, data) {
    return authHttp.post(`${API_ROOT}/dataroom/${id}/add-version-v2`, data);
  },
  validateBeforeAddingVersionFile(id) {
    return authHttp.post(
      `${API_ROOT}/dataroom/${id}/validate-before-add-version`
    );
  },
  deleteFileVersion(data) {
    return authHttp.delete(
      `${API_ROOT}/dataroom/${data.id}/delete-version/${data.versionId}`
    );
  },
  setFileVersionAsCurrent(data) {
    return authHttp.put(`${API_ROOT}/dataroom/current-version`, data);
  },
  searchDataroomItems(data) {
    // let param = "";
    // if (data) {
    //   param = this.addUrlParameter("size", data.paging.size, param);
    //   param = this.addUrlParameter("page", data.paging.page, param);
    //   param = this.addUrlParameter("searchKey", data.searchKey, param);
    // }
    // return authHttp.get(`${API_ROOT}/dataroom/search${param}`);
    if (!data) return;
    const { paging, searchKey } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      searchKey: searchKey,
    };
    return authHttp.get(`${API_ROOT}/dataroom/search`, { params });
  },
  searchPublicDataroomFileItems(data) {
    if (!data) return;
    const { paging, searchKey, mediaType } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      searchKey: searchKey,
      mediaType: mediaType,
    };
    return authHttp.get(`${API_ROOT}/dataroom/search-public-files`, { params });
  },
  searchPublicFileItemsWithParentId(data) {
    if (!data) return;
    const { paging, searchKey, mediaType, parentId } = data;
    const params = {
      size: paging.size,
      page: paging.page,
      searchKey: searchKey,
      mediaType: mediaType,
      parentId: parentId,
    };
    return authHttp.get(`${API_ROOT}/dataroom/search-public-files/parent-id`, {
      params,
    });
  },
  moveDataroomItems(data) {
    return authHttp.post(`${API_ROOT}/dataroom/move`, data);
  },
  copyDataroomItems(data) {
    return authHttp.post(`${API_ROOT}/dataroom/copy`, data);
  },
  trashDataroomFileItems(data) {
    return authHttp.post(`${API_ROOT}/dataroom/trash`, data);
  },
  restoreDataroomFileItems(data) {
    return authHttp.post(`${API_ROOT}/dataroom/restore`, data);
  },
  getTrashedDataroomFileItems() {
    return authHttp.get(`${API_ROOT}/dataroom/trashes`);
  },
  deleteDataroomFileItems(data) {
    return authHttp.post(`${API_ROOT}/dataroom/delete-files`, data);
  },
  createDataroomBookmark(data) {
    return authHttp.post(`${API_ROOT}/dataroom/bookmark`, data);
  },
  editDataroomBookmark(data) {
    return authHttp.put(`${API_ROOT}/dataroom/bookmark`, data);
  },
  deleteDataroomBookmarkItem(id) {
    return authHttp.delete(`${API_ROOT}/dataroom/${id}/bookmark`);
  },
  getSharedDataroomItemsBySharedUserId(id) {
    return authHttp.get(`${API_ROOT}/dataroom/shared-user/${id}`);
  },
  getDataroomReportInvestorFocusOverview(data) {
    let param = "";
    if (data) {
      if (data.investorTypes) {
        data.investorTypes.forEach((x) => {
          param = this.addUrlParameter(`ivt`, x, param);
        });
      }
      if (data.fromDate) {
        param = this.addUrlParameter("fromDate", data.fromDate, param);
      }
      if (data.toDate) {
        param = this.addUrlParameter("toDate", data.toDate, param);
      }
      param = this.addUrlParameter("size", data.paging.size, param);
      param = this.addUrlParameter("page", data.paging.page, param);
    }
    return authHttp.get(
      `${API_ROOT}/dataroom/report/investor-focus/overview${param}`
    );
  },
  getDataroomReportInvestorFocusDetails(data) {
    let param = "";
    if (data) {
      if (data.investorTypes) {
        data.investorTypes.forEach((x) => {
          param = this.addUrlParameter(`ivt`, x, param);
        });
      }
      if (data.fromDate) {
        param = this.addUrlParameter("fromDate", data.fromDate, param);
      }
      if (data.toDate) {
        param = this.addUrlParameter("toDate", data.toDate, param);
      }
    }
    return authHttp.get(
      `${API_ROOT}/dataroom/report/investor-focus/${data.id}/details${param}`
    );
  },
  getDataroomReportInvestorEngagement(data) {
    let param = "";
    if (data) {
      if (data.inviters) {
        data.inviters.forEach((x) => {
          param = this.addUrlParameter(`iv`, x, param);
        });
      }
      if (data.investors) {
        data.investors.forEach((x) => {
          param = this.addUrlParameter(`ivst`, x, param);
        });
      }
      if (data.fromDate) {
        param = this.addUrlParameter("fromDate", data.fromDate, param);
      }
      if (data.toDate) {
        param = this.addUrlParameter("toDate", data.toDate, param);
      }
      param = this.addUrlParameter("size", data.paging.size, param);
      param = this.addUrlParameter("page", data.paging.page, param);
    }
    return authHttp.get(
      `${API_ROOT}/dataroom/report/investor-engagement${param}`
    );
  },
  //dataroom: sharedUser
  getSharedDataroomItemsByAuthSharedUser() {
    return authHttp.get(`${API_ROOT}/dataroom/shared-user`);
  },
  createDataroomFileByAuthSharedUser(data, onUploadProgress) {
    return authHttp.post(`${API_ROOT}/dataroom/shared-user/file`, data, {
      onUploadProgress,
    });
  },
  createDataroomFileV2ByAuthSharedUser(data) {
    return authHttp.post(`${API_ROOT}/dataroom/shared-user/file-v2`, data);
  },
  validateDataroomFileByAuthSharedUser(data) {
    return authHttp.post(
      `${API_ROOT}/dataroom/shared-user/file-validation`,
      data
    );
  },
  getSharedDataroomItemsByAuthInvestor() {
    return authHttp.get(`${API_ROOT}/lp-portal/dataroom`);
  },
  getNewsletter() {
    return authHttp.get(`${API_ROOT}/lp-portal/newsletter`);
  },
  getInvestorCallReplayFiles() {
    return authHttp.get(`${API_ROOT}/lp-portal/call-replay`);
  },
  getCoInvesmentOppotunitiesFoldersAndFiles() {
    return authHttp.get(`${API_ROOT}/lp-portal/coinvestment-opportunities`);
  },
  getIntroVideo(lpFundId) {
    return authHttp.get(`${API_ROOT}/lp-portal/intro-video?lpFundId=${lpFundId}`);
  },
  // findAllInvestorsAndFounders() {
  //     return authHttp.get(`${API_ROOT}/users/investors-founders`)
  // },
  findUsersToShareDataroom() {
    return authHttp.get(`${API_ROOT}/users/share-dataroom`);
  },
  findUsersToShareUnauthenticatedDataroom() {
    return authHttp.get(`${API_ROOT}/users/share-unauthenticated-dataroom`);
  },
  getPartnerMetaList() {
    return authHttp.get(`${API_ROOT}/partners/meta`);
  },
  getPartnerWithPortfolioMetaList() {
    return authHttp.get(`${API_ROOT}/partners/meta-with-portfolio`);
  },
  updateFounderInfo(id, data) {
    return authHttp.put(`${API_ROOT}/users/founders/${id}`, data);
  },
  assignPortfolioToFounder(id, data) {
    return authHttp.put(`${API_ROOT}/users/founders/${id}/portfolio`, data);
  },
  inviteFounder(data) {
    return authHttp.post(`${API_ROOT}/users/founders`, data);
  },
  reinviteFounder(data) {
    return authHttp.post(
      `${API_ROOT}/users/founders/resend-invitation-email`,
      data
    );
  },
  findUsersByTypes(data) {
    let param = "";
    if (data) {
      if (data.types) {
        data.types.forEach((x) => {
          param = this.addUrlParameter(`ut`, x, param);
        });
      }
      param = this.addUrlParameter(`name`, data.filteredName, param);
      param = this.addUrlParameter(`email`, data.filteredEmail, param);
    }
    return authHttp.get(`${API_ROOT}/users/by-types${param}`);
  },
  investorSignSig(data) {
    return authHttp.post(`${API_ROOT}/me/investors/nda-form`, data);
  },
  deleteUser(id) {
    return authHttp.delete(`${API_ROOT}/users/${id}`);
  },
  resetUserLockout(id) {
    return authHttp.put(`${API_ROOT}/users/${id}/reset-lockout`);
  },

  //lp media
  sortLPMediaItems(data) {
    return authHttp.put(`${API_ROOT}/lp-media/order`, data);
  },
  getLPMediaItemsByType(type, paging) {
    return authHttp.get(
      `${API_ROOT}/lp-media/by-type?type=${type}&${authHttp.buildParams(
        paging
      )}`
    );
  },
  getLPMediaItem(id) {
    return authHttp.get(`${API_ROOT}/lp-media/${id}`);
  },
  createLPCallReplay(data) {
    return authHttp.post(`${API_ROOT}/lp-media/call-replay`, data);
  },
  updateLPCallReplay(data) {
    return authHttp.put(`${API_ROOT}/lp-media/call-replay/${data.id}`, data);
  },
  createLPCoInvestmentOp(data) {
    return authHttp.post(`${API_ROOT}/lp-media/co-investment-op`, data);
  },
  updateLPCoInvestmentOp(data) {
    return authHttp.put(
      `${API_ROOT}/lp-media/co-investment-op/${data.id}`,
      data
    );
  },
  createLPSeededCompanies(data) {
    return authHttp.post(`${API_ROOT}/lp-media/seeded-company`, data);
  },
  updateLPSeededCompanies(data) {
    return authHttp.put(
        `${API_ROOT}/lp-media/seeded-company/${data.id}`,
        data
    );
  },
  createLPIntroVideo(data) {
    return authHttp.post(`${API_ROOT}/lp-media/intro-video`, data);
  },
  updateLPIntroVideo(data) {
    return authHttp.put(`${API_ROOT}/lp-media/intro-video/${data.id}`, data);
  },
  getLPMediaItemsByTypeForDisplay(type) {
    return authHttp.get(`${API_ROOT}/lp-portal/lp-media-by-type?type=${type}`);
  },
  getLPMediaItemsByTypeAndLpFundId(type, lpFundId) {
    const params = { type, lpFundId };
    return authHttp.get(`${API_ROOT}/lp-portal/lp-media-by-type/lp-fund`, {
      params,
    });
  },
  deleteLPMediaItem(id) {
    return authHttp.delete(`${API_ROOT}/lp-media/${id}`);
  },
  verifyUserAndGetLink(id, username, recaptchaToken) {
    return authHttp.post(
      `${API_ROOT}/public/dataroom/${id}/verify-user-get-link`,
      {
        username: username,
        captcha: recaptchaToken,
      }
    );
  },

  //DealStages
  getStages(data) {
    return authHttp.get(`${API_ROOT}/deals/stages`, data);
  },
  getStageReviewers(stageId, data) {
    let param = this.addUrlParameter("stage", stageId);
    param = this.addUrlParameter("PageSize", data.paging.size, param);
    param = this.addUrlParameter("PageNumber", data.paging.page, param);
    param = this.addUrlParameter("status", data.status, param);
    return authHttp.get(`${API_ROOT}/deals/stage/reviewers/${param}`);
  },
  getStageById(stageId) {
    return authHttp.get(`${API_ROOT}/deals/stages/${stageId}`);
  },
  // getStageReviewersOfUserId(stageId, userId, data) {
  //     return authHttp.get(`${API_ROOT}/deals/stage/${stageId}/reviewers/${userId}`, data)
  // },
  updateStageReviewer(stageId, userId, key, value) {
    let param = this.addUrlParameter("key", key);
    param = this.addUrlParameter("value", value, param);
    return authHttp.put(
      `${API_ROOT}/deals/stage/${stageId}/reviewers/${userId}${param}`
    );
  },
  createStageReviewers(data) {
    return authHttp.post(`${API_ROOT}/deals/stage/reviewers`, data);
  },
  updateStage(stageId, data) {
    return authHttp.put(`${API_ROOT}/deals/stages/${stageId}`, data);
  },
  deleteReviewerItem(stageId, userId, data) {
    return authHttp.delete(
      `${API_ROOT}/deals/stage/${stageId}/reviewers/${userId}`,
      data
    );
  },
  //Deals
  getAllDeals(data) {
    if (!data) return;
    const {
      paging,
      name,
      filteredStage,
      submittedBy,
      isRejected,
      isDraft,
      isDeleted,
      isWaiting,
      sortField,
      sortDirection,
      createdUserId,
    } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      name: name,
      stage: filteredStage,
      submittedBy: submittedBy,
      isRejected,
      isDraft,
      isWaiting,
      isDeleted,
      sortField,
      sortDirection,
      createdUserId,
    };
    return authHttp.get(`${API_ROOT}/deals`, { params });
  },

  getDealFounder(data) {
    if (!data) return;
    const { paging, name, isDraft, createdUserId } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      name: name,
      isDraft: isDraft,
      createdUserId,
    };
    return authHttp.get(`${API_ROOT}/deals`, { params });
  },

  createInvestorRequest(data) {
    return authHttp.post(`${API_ROOT}/deals/investor-request`, data);
  },
  getDealStages(data) {
    return authHttp.get(`${API_ROOT}/deals/stages`, data);
  },
  updateDealTypes(id) {
    return authHttp.put(`${API_ROOT}/deals/${id}`);
  },
  getNotification(param) {
    return authHttp.get(
      `${API_ROOT}/notifications?${authHttp.buildParams(param)}`
    );
  },
  deleteNotification(id) {
    return authHttp.delete(`${API_ROOT}/notifications/${id}`);
  },
  createNotification_MESSAGE(data) {
    return authHttp.post(`${API_ROOT}/notifications`, data);
  },
  getNotiById(id) {
    return authHttp.get(`${API_ROOT}/notifications/${id}`);
  },
  getEmailQueue(data) {
    let param = "";
    param = this.addUrlParameter("size", data.paging.size, param);
    param = this.addUrlParameter("page", data.paging.page, param);
    param = this.addUrlParameter("orderBy", data.orderBy, param);
    param = this.addUrlParameter("name", data.filteredName, param);
    param = this.addUrlParameter("email", data.filteredEmail, param);
    param = this.addUrlParameter("status", data.filteredStatus, param);
    param = this.addUrlParameter(
      "createdName",
      data.filteredCreatedName,
      param
    );
    param = this.addUrlParameter(
      "createdEmail",
      data.filteredCreatedEmail,
      param
    );
    param = this.addUrlParameter(
      "dataContent",
      data.filteredDataContent,
      param
    );
    return authHttp.get(`${API_ROOT}/email/email-queue${param}`);
  },
  getUserLoginHistories(data) {
    if (!data) return;
    const { paging, filteredEmail, filteredPlatform } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      FilteredEmail: filteredEmail,
      FilteredPlatform: filteredPlatform,
    };
    return authHttp.get(`${API_ROOT}/users/login-history`, { params });
  },
  createEvent(data) {
    return authHttp.post(`${API_ROOT}/event`, data);
  },
  updateEvent(eventId, data) {
    return authHttp.put(`${API_ROOT}/event/${eventId}`, data);
  },
  getEvents(data) {
    if (!data) return;
    const { paging, filteredName, status } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      name: filteredName,
      status: status,
    };
    return authHttp.get(`${API_ROOT}/event`, { params });
  },
  getEvent(id) {
    return authHttp.get(`${API_ROOT}/event/${id}`);
  },
  deleteEvent(eventId) {
    return authHttp.delete(`${API_ROOT}/event/${eventId}`);
  },
  cancelEventStatus(eventId) {
    return authHttp.put(`${API_ROOT}/event/${eventId}/status/cancel`);
  },
  completeEventStatus(eventId) {
    return authHttp.put(`${API_ROOT}/event/${eventId}/status/complete`);
  },
  sendEventSchedule(eventId, data) {
    if (!data) return;
    const { emails, scheduleSendDatetime } = data;

    const params = {
      emails,
      scheduleSendDatetime,
    };
    return authHttp.post(
      `${API_ROOT}/event/${eventId}/attendee/schedule`,
      params
    );
  },
  getMilestonesOfEvent(data) {
    if (!data) return;
    const { paging, filteredName } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      name: filteredName,
    };
    return authHttp.get(`${API_ROOT}/event/${data.eventId}/milestone`, {
      params,
    });
  },
  createMilestone(eventId, data) {
    return authHttp.post(`${API_ROOT}/event/${eventId}/milestone`, data);
  },
  updateMilestone(eventId, milestoneId, data) {
    return authHttp.put(
      `${API_ROOT}/event/${eventId}/milestone/${milestoneId}`,
      data
    );
  },
  deleteMilestone(eventId, milestoneId) {
    return authHttp.delete(
      `${API_ROOT}/event/${eventId}/milestone/${milestoneId}`
    );
  },
  getAttendeesOfEvent(data) {
    if (!data) return;
    const { paging, filteredName, filteredEmail, filteredUserType } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      name: filteredName,
      email: filteredEmail,
      userType: filteredUserType,
    };
    return authHttp.get(`${API_ROOT}/event/${data.eventId}/attendee`, {
      params,
    });
  },

  getAttendeesByEventId(data) {
    if (!data) return;
    return authHttp.get(
      `${API_ROOT}/event/${data.eventId}/attendee-by-event-id`
    );
  },

  deleteAttendee(eventId, data) {
    if (!data) return;
    const { email } = data;
    const params = {
      email: email,
    };
    return authHttp.delete(`${API_ROOT}/event/${eventId}/attendee`, params);
  },
  attendeeResponse(data) {
    if (!data) return;
    const { confirmationCode } = data;

    const params = {
      confirmationCode,
    };
    return authHttp.get(`${API_ROOT}/event/${data.eventId}/attendee/response`, {
      params,
    });
  },
  findEventUsersByTypes(data) {
    let param = "";
    if (data) {
      if (data.types) {
        data.types.forEach((x) => {
          param = this.addUrlParameter(`userTypes`, x, param);
        });
      }
      param = this.addUrlParameter(`name`, data.filteredName, param);
      param = this.addUrlParameter(`email`, data.filteredEmail, param);
      param = this.addUrlParameter(`role`, data.filteredInvestorRole, param);
    }
    return authHttp.get(
      `${API_ROOT}/event/${data.eventId}/attendee/search${param}`
    );
  },
  createAttendees(data) {
    return authHttp.post(`${API_ROOT}/event/${data.eventId}/attendee`, data);
  },
  sendInvitation(eventId) {
    return authHttp.post(`${API_ROOT}/event/${eventId}/send-invitation`);
  },
  sendCancelInvitation(eventId) {
    return authHttp.post(`${API_ROOT}/event/${eventId}/send-cancellation`);
  },
  getGaleriesOfEvent(data) {
    if (!data) return;
    const { paging } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
    };
    return authHttp.get(`${API_ROOT}/event/${data.eventId}/gallery`, {
      params,
    });
  },
  createGallery(data) {
    return authHttp.post(`${API_ROOT}/event/${data.eventId}/gallery`, data);
  },
  updateGallery(data) {
    return authHttp.put(`${API_ROOT}/event/${data.eventId}/gallery`, data);
  },
  deleteGallery(eventId, galleryId) {
    return authHttp.delete(`${API_ROOT}/event/${eventId}/gallery/${galleryId}`);
  },
  attendeeCheckIn(data) {
    if (!data) return;
    const { invitationCode } = data;

    const params = {
      invitationCode,
    };
    return authHttp.get(
      `${API_ROOT}/event/${data.eventId}/milestone/${data.milestoneId}/attendee/checkin`,
      { params }
    );
  },
  getEventsByUserId() {
    return authHttp.get(`${API_ROOT}/event/investor`);
  },
  getAttendeeByUserId(eventId) {
    return authHttp.get(`${API_ROOT}/event/${eventId}/investor/response`);
  },
  findMapchart() {
    return authHttp.get(`${API_ROOT}/mapchart`);
  },

  updateMapchart(id, data) {
    if (!data) return;
    return authHttp.put(`${API_ROOT}/mapchart/${id}`, data);
  },
  getAllEvtCheckinByMilestone(paging, eventId, milestoneId, filteredEmail) {
    if (!eventId || !milestoneId) return;
    const params = {
      PageSize: paging.size,
      PageNumber: paging.page,
      searchEmail: filteredEmail,
    };
    return authHttp.get(
      `${API_ROOT}/event/${eventId}/milestone/${milestoneId}/checked-in`,
      { params }
    );
  },

  getPerspectives(data) {
    if (!data) return;
    const { filteredStatus, filteredTitle, filteredMember } = data;

    const params = {
      status: filteredStatus,
      title: filteredTitle,
      memeber: filteredMember,
    };
    return authHttp.get(`${API_ROOT}/perspectives`, { params });
  },
  deletePerspective(perspectiveId) {
    return authHttp.delete(`${API_ROOT}/perspectives/${perspectiveId}`);
  },

  updateStatusPerspective(perspectiveId, status) {
    return authHttp.put(
      `${API_ROOT}/perspectives/${perspectiveId}/status/${status}`
    );
  },
  createPerspective(data) {
    return authHttp.post(`${API_ROOT}/perspectives/`, data);
  },

  updatePerspective(perspectiveId, data) {
    return authHttp.put(`${API_ROOT}/perspectives/${perspectiveId}`, data);
  },

  orderChangedPerspective(data) {
    return authHttp.put(`${API_ROOT}/perspectives/order`, data);
  },

  getPerspectivesTeamData() {
    return authHttp.get(`${API_ROOT}/perspectives/members`);
  },

  getMeetFounders(data) {
    if (!data) return;
    const { filteredStatus, filteredCompanyName, filteredExited } = data;

    const params = {
      status: filteredStatus,
      companyName: filteredCompanyName,
      exited: filteredExited,
    };
    return authHttp.get(`${API_ROOT}/meetFounders`, { params });
  },
  deleteMeetFounder(meetFounderId) {
    return authHttp.delete(`${API_ROOT}/meetFounders/${meetFounderId}`);
  },

  updateStatusMeetFounder(meetFounderId, status) {
    return authHttp.put(
      `${API_ROOT}/meetFounders/${meetFounderId}/status/${status}`
    );
  },
  createMeetFounder(data) {
    return authHttp.post(`${API_ROOT}/meetFounders`, data);
  },

  updateMeetFounder(meetFounderId, data) {
    return authHttp.put(`${API_ROOT}/meetFounders/${meetFounderId}`, data);
  },

  orderChangedMeetFounder(data) {
    return authHttp.put(`${API_ROOT}/meetFounders/order`, data);
  },

  //watermark
  addWatermark(id) {
    return authHttp.post(`${API_ROOT}/dataroom/${id}/watermark`);
  },

  // Funds
  getAllLpFunds() {
    return authHttp.get(`${API_ROOT}/lpfunds/get-all`);
  },
  getLpFunds(data) {
    if (!data) return;
    const { paging, filteredName, filteredStatus } = data;

    const params = {
      PageNumber: paging.page,
      PageSize: paging.size,
      searchName: filteredName,
      searchStatus: filteredStatus,
    };
    return authHttp.get(`${API_ROOT}/lpfunds`, { params });
  },
  getLpFund(id) {
    return authHttp.get(`${API_ROOT}/lpfunds/${id}`);
  },
  createLpFund(data) {
    return authHttp.post(`${API_ROOT}/lpfunds`, data);
  },
  updateLpFund(id, data) {
    return authHttp.put(`${API_ROOT}/lpfunds/${id}`, data);
  },
  deleteLpFund(id) {
    return authHttp.delete(`${API_ROOT}/lpfunds/${id}`);
  },
  changeStatusLpFunds(data) {
    return authHttp.put(
      `${API_ROOT}/lpfunds/${data.lpfund_id}/status/${data.is_enabled}`
    );
  },
  getInvestorMembersData(data) {
    if (!data) return;
    const { filteredName, filteredEmail, hasDevice } = data;

    const params = {
      name: filteredName,
      email: filteredEmail,
      hasDevice: hasDevice,
    };

    return authHttp.get(`${API_ROOT}/users/investors/get-all`, { params });
  },
  findMembersByLPFundId(id) {
    return authHttp.get(`${API_ROOT}/lpfunds/${id}/users`);
  },
  addMembersToLpFund(data) {
    const body = data.members.map((x) => x.id);
    return authHttp.put(`${API_ROOT}/lpfunds/${data.id}/add-users`, body);
  },
  getAllInvestorMember() {
    return authHttp.get(`${API_ROOT}/investor-members`);
  },
  //

  //
  getLPMediaItemsByTypeWithoutPagination(type) {
    return authHttp.get(`${API_ROOT}/lp-media/by-type?type=${type}`);
  },
  createLPNewsletters(data) {
    return authHttp.post(`${API_ROOT}/lp-media/newsletter`, data);
  },
  updateLPNewsletters(id, data) {
    return authHttp.put(`${API_ROOT}/lp-media/newsletter/${id}`, data);
  },
  updateStatusLpMedia(id, status) {
    return authHttp.put(`${API_ROOT}/lp-media/${id}/status/${status}`);
  },
  getNewsletterByDocId(id) {
    return authHttp.get(`${API_ROOT}/lp-portal/newsletter-by-doc-id/${id}`);
  },
  findAllInvestorsByLpFund(data) {
    if (!data) return;
    const { filteredName, filteredEmail, hasDevice, lpFundIds } = data;
    const params = {
      searchName: filteredName,
      searchEmail: filteredEmail,
      hasDevice: hasDevice,
      lpFundIds,
    };
    return authHttp.get(`${API_ROOT}/lp-media/user-by-lp-funds`, { params });
  },
  createNotificationLpFund(id, data) {
    return authHttp.post(
      `${API_ROOT}/notifications/lpmedia/${id}`,
      data
    );
  },
  getNotiByLpFundId(id) {
    return authHttp.get(`${API_ROOT}/lp-media/${id}/notification`);
  },
};

export default AdminService;
