import { defineComponent } from "vue";
import moment from "moment";
import DealServices from "@/services/deal";
import notify from "@/services/notify";

import newDeadline from "./newDeadline";
import considerTillDate from "./considerTillDate";

import stage1 from "@/assets/images/deals/stage_1.vue";
import stage2 from "@/assets/images/deals/stage_2.vue";
import stage3 from "@/assets/images/deals/stage_3.vue";
import stage4 from "@/assets/images/deals/stage_4.vue";
import stage5 from "@/assets/images/deals/stage_5.vue";
import stage6 from "@/assets/images/deals/stage_6.vue";
import stage7 from "@/assets/images/deals/stage_7.vue";
import Dialog from "@/components/Dialog";
import { checkUserTypeFounder } from "@/misc/commons";
import { parseTimeTz } from "@/misc/commons";

export default defineComponent({
  name: "stage",
  props: {
    totalStage: { type: Array, default: [] },
    currentStage: { type: Number, default: null },
    specifyIdDeal: { type: String, default: null },
    stageAllow: { type: Boolean, default: false },
    deal: { type: Object, type: null },
  },
  components: {
    Dialog,
    newDeadline,
    considerTillDate,
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    stage6,
    stage7,
  },
  emits: ["reloadDeal"],
  data() {
    return {
      psKey: 0,
      loading: false,
      showModal: false,
      // confirmText: {
      //     header: '',
      //     body: ''
      // }
    };
  },
  computed: {
    screenSize() {
      return this.$store.state.mobile.size;
    },
  },
  methods: {
    showConsider() {
      this.$refs.considerTillDate.showModal();
    },
    pickTime() {
      this.$refs.stageModal.showModal();
    },
    async moveToWaitingDeal(id, time) {
      let resp = await DealServices.waitingDeal(id, time);
      if (resp.data.ok) {
        this.$emit("reloadDeal", id, true);
        notify.success("Update successfully!");
      }
    },
    isUserTypeFounder: function () {
      return checkUserTypeFounder();
    },
    reRenderScrollbar() {
      this.psKey++;
    },
    confirm: function (param) {
      let message = {
        title: `Confirmation ${param.toLowerCase()}`,
        body:
          param !== "Consider"
            ? "Are you sure? This action cannot be undone!"
            : `Are you sure? Move this deal ${
                this.deal.is_waiting ? "out" : "to"
              } waiting list!`,
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog.confirm({ ...message, ...options }).then(async (d) => {
        if (!d) return;

        switch (param) {
          case "Reject":
            this.rejectStage();
            break;
          case "Approve":
            this.approveStage();
            break;
          case "Consider":
            this.moveToWaitingDeal(this.deal.deal_id);
            break;
          default:
            console.log("This action is not available!");
            break;
        }
      });
    },
    hideConsiderModal(obj) {
      this.moveToWaitingDeal(this.deal.deal_id, parseTimeTz(obj.dateTime));
    },
    approveStage: function () {
      this.loading = true;
      DealServices.approveStage(
        this.specifyIdDeal,
        this.totalStage[this.currentStage].id
      )
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("reloadDeal", this.specifyIdDeal, false);
            notify.success("Stage approved!");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rejectStage: function () {
      this.loading = true;
      DealServices.rejectStage(
        this.specifyIdDeal,
        this.totalStage[this.currentStage].id
      )
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("reloadDeal", this.specifyIdDeal, false);
            notify.success("Stage rejected!");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onholdStage: function (datetime) {
      this.loading = true;
      DealServices.holdStage(
        this.specifyIdDeal,
        this.totalStage[this.currentStage].id,
        datetime
      )
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Stage onhold successfully!");
          }
        })
        .finally(() => {
          this.loading = false;
          this.$refs.stageModal.hideObjModal();
        });
    },
    hideModal: function (obj) {
      this.showModal = false;
      if (obj === "undefined" || obj === null) {
        return false;
      }
      if (typeof obj === "object" && obj.dateTime) {
        obj.dateTime = moment(`${obj.dateTime.months}-${obj.dateTime.days}-${obj.dateTime.years} ${obj.dateTime.hours}:${obj.dateTime.minutes}`).add(1, "days").format("MM-DD-YYYY HH:mm");
        this.onholdStage(parseTimeTz(obj.dateTime));
      }
    },
  },
  watch: {
    screenSize(v) {
      this.reRenderScrollbar();
    },
  },
});
