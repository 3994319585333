<template>
  <div class="our-team-page">
    <Section1 />
    <ListOurTeam />
  </div>
</template>

<script>
import Section1 from './comps/Section1.vue'
import ListOurTeam from './ListOurTeam'
// import ListOurTeam from './comps/ListOurTeam.vue'

export default {
  components: {
    Section1,
    ListOurTeam,
  },
}
</script>
<style lang="scss" scoped>
.our-team-page {
  background: #000;
  overflow: hidden;
}
</style>
