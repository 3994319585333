import { defineComponent } from 'vue'
import reviewRate from './reviewRate.vue'

export default defineComponent({
    name: "detailStage",
    props: {
        item: { type: Object, default: null },
        currentStage: { type: Number, default: -1 },
        totalStage: { type: Array, default: null }
    },
    components: {
        reviewRate
    },
    data() {
        return {
            
        }
    },
    methods: {
        showModalReviewRate() {
            this.$refs.showReviewRate.showModal()
        }
    }
})