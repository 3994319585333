import AdminServices from '@/services/admin'
import Datepicker from '@/components/DatePicker'
import selectUser from './selectUser'
import _ from 'lodash'
import Table, { debounce } from "@/components/Table";
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'investorEngagement',
    components: {
        Table,
        Datepicker,
        selectUser
    },
    data() {
        return {
            loading: false,
            loadingInitial: false,
            users: [],
            selectedInvestors: [],
            selectedInvestorComponentKey: 0,
            reports: [],
            searchParams: {
                paging: {
                    page: 1,
                    size: 10,
                    total: 0
                },
                totalRows: 0,
                investors: [],
            },
            investors: [],
            filteredInvestors: [],
            errorMsg: {
                commonError: null
            },
            columns: [
                {
                    id: 'report',
                    name: 'Report'
                }
            ]
        }
    },
    watch: {
        'selectedInvestors': function(v) {
            this.errorMsg.commonError = null
        },
        // 'searchParams.paging': {
        //     deep: true,
        //     handler: function() {
        //         debounce(this.loadReport)
        //     }
        // }
    },
    created() {
        this.loadingInitial = true
        this.loadUsers()
    },
    methods: {
        loadUsers() {
            AdminServices.findUsersByTypes({ types: ['Rokker', 'Investor'] }).then(res => {
                if (res.data.ok) {
                    this.investors = res.data.d.filter(x => x.user_type === 'Investor').map(y => {
                        return {
                            ...y,
                            value: y.id,
                            text: `${y.fname} ${y.lname}`
                        }
                    })
                    this.filteredInvestors = _.cloneDeep(this.investors)
                }
            }).finally(() => { this.loadingInitial = false })
        },
        validData() {
            let flag = true
            if (!this.selectedInvestors || this.selectedInvestors.length === 0) {
                this.errorMsg.commonError = 'Please select Investors'
                flag = false
            }
            return flag
        },
        searchBy() {
            if (this.validData()) {
                let _selectedInvestorIds = this.selectedInvestors
                this.searchParams.investors = _selectedInvestorIds
                this.searchParams.paging = { page: 1, size: 10, total: 0 }
                this.loadReport()
            }
        },
        loadReport() {
            this.loading = true
            AdminServices.getDataroomReportInvestorEngagement(this.searchParams)
            .then(resp => {
                if (resp.data.ok) {
                    this.reports = resp.data.d.c
                    this.searchParams.paging.page = resp.data.d.p
                    this.searchParams.paging.size = resp.data.d.s
                    this.searchParams.totalRows = resp.data.d.t
                }
            }).finally(() => {
                this.loading = false
            })
        },
        loadPage(value) {
            this.searchParams.paging.page = value
        },
        updatePaging(v) {
            this.searchParams.paging = Object.assign(this.searchParams.paging, v)
        }
    },
    
})