<template>
  <div
    id="modal"
    v-if="isShow"
    class="modal dialog-modal fade"
    :class="{ show: isShow }"
  >
    <div class="overlay" @click="cancel" />
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div :class="`modal-header ${color} ${dialog.customClassHeader}`">
          <div class="modal-title" v-html="dialog.title" />
          <button
            type="text"
            class="btn close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cancel"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body" v-html="dialog.body" />
        <div class="modal-footer">
          <slot name="customFooter" v-if="dialog.html"></slot>
          <button
            type="text"
            v-if="isShowConfirmBtn"
            :disabled="dialog.disabledOkButton"
            class="btn pointer btn btn-primary btn-secondary btn-sm ms-2 confirm-btn"
            @click="agree"
          >
            {{ dialog.okText ? dialog.okText : "Confirm" }}
          </button>
          <!-- <button
              type="button"
              class="btn btn-secondary btn-sm"
              @click="cancel"
            >
            {{ dialog.cancelText ? dialog.cancelText : "Close" }}
          </button> -->
          <NewButton class="btn btn-secondary btn-sm" @click="cancel">{{
            dialog.cancelText ? dialog.cancelText : "Close"
          }}</NewButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import NewButton from "@/components/NewButton";

export default defineComponent({
  name: "Dialog",
  props: {
    buttonConfirm: { type: Boolean, default: true },
  },
  components: {
    NewButton,
  },
  data() {
    return {
      dialog: {
        title: "Notice",
        content: "",
        type: "error",
      },
      isShowConfirmBtn: false,
      isShow: false,
      resolve: null,
      reject: null,
    };
  },
  computed: {
    color() {
      switch (this.dialog.type) {
        case "error":
          return "bg-danger";
        case "success":
          return "bg-primary";
        case "warning":
          return "bg-warning";
        default:
          return "";
      }
    },
  },
  methods: {
    process(options, useConfirm) {
      this.dialog = {
        ...this.dialog,
        ...options,
      };
      this.isShowConfirmBtn = useConfirm || false;
      this.isShow = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm: function (options) {
      return this.process(options, true);
    },
    show: function (options) {
      return this.process(options, false);
    },
    hide() {
      this.isShow = false;
    },
    agree() {
      this.resolve(true);
      this.isShow = false;
    },
    cancel() {
      this.resolve(false);
      this.isShow = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.modal {
  background: rgba($color: #000, $alpha: 0.5);

  &.show {
    display: block;
  }

  button.close {
    color: white;
  }

  .modal-title {
    color: white;
    font-size: 1.1em;
  }

  .modal-header {
    border: 0;
  }

  .modal-body {
    color: white;
  }

  .modal-footer {
    border-top: 1px solid rgba(225, 225, 225, 0.2);
  }
}

.modal-content {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
