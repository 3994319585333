<template>
  <Modal v-model:value="isShow" v-if="item">
    <div class="row">
      <div class="col-12" v-loading="loading">
        <FileField2
          class="mb-3"
          v-model:value="fileThumbnail"
          :init-preview-url="item.url"
          :accept="imageMime"
          :mimeType="item.mimeType"
        />
      </div>
    </div>
    <template #footer>
      <NewButton @click="save" :loading="loading"> Save </NewButton>
    </template>
  </Modal>
</template>

<script>
import {defineComponent} from "vue";
import _ from "lodash";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";
import FileField2 from "@/components/FileField2";
import TextArea from "@/components/TextArea";
import dialog from "@/services/dialog";

export default defineComponent({
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
    FileField2,
    TextArea,
  },
  data() {
    return {
      isShow: false,
      loading: false,
      imageMime: ["image/gif", "image/jpeg", "image/png", "image/jpg"],
      fileThumbnail: "",
      form: {
        eventId: "",
        galleryId: "",
        name: "",
        extension: "",
        mimeType: "",
        s3Key: "",
        fileSize: "",
      },
      item: {},
    };
  },
  methods: {
    show(item) {
      this.item = _.cloneDeep(item);
      this.isShow = true;
    },
    async save() {
      this.loading = true;
      await this.uploadFile(this.item);
      this.loading = false;
      this.isShow = false;
      notify.success("Update success!");
      this.$emit("refresh");
    },
    async cancel() {
      const confirm = await dialog.confirm({
        title: "Close",
        content: "Some items do not update. Are you sure to discard them?",
      });

      if (!confirm) return;
      this.isShow = false;
    },
    async uploadFile(item) {
      if (!item.eventId) {
        return;
      }
      if (this.fileThumbnail) {
        var _awsCred = await adminServices.getPublicSessionToken();
        AWS.config.update({
          region: _awsCred.data.d.region,
          credentials: new AWS.Credentials(
            _awsCred.data.d.access_key,
            _awsCred.data.d.secret_key,
            _awsCred.data.d.session_token
          ),
        });
        let lastDot = this.fileThumbnail.name.lastIndexOf(".");
        var keyname =
          "event/images/" +
          this.getRandomKey(12) +
          this.fileThumbnail.name.substring(lastDot);
        let upload = new S3.ManagedUpload({
          params: {
            Bucket: _awsCred.data.d.bucket,
            Key: keyname,
            Body: this.fileThumbnail,
          },
        });
        this.form.eventId = this.item.eventId;
        this.form.galleryId = this.item.galleryId;
        this.form.name = this.fileThumbnail.name;
        this.form.extension = this.fileThumbnail.name.split(".").pop();
        this.form.mimeType = this.fileThumbnail.type;
        this.form.s3Key = (await upload.promise()).Key;
        this.form.fileSize = this.fileThumbnail.size;
        await adminServices.updateGallery(this.form);
      }
    },
  },
});
</script>
