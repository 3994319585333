<template>
  <div class="section-10">
    <div class="switch-control">
      <!-- SVG -->
      <div class="sound-icon-wrapper" @click="toggleAudio">
        <svg
          class="sound-svg"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
          viewBox="20 20 185 145"
        >
          <g id="audio-icon">
            <g id="speakers">
              <path id="speaker" d="" />
            </g>
            <g id="maskGroup" clip-path="url(#mask)">
              <g id="lines">
                <path
                  id="line-shorter"
                  d="M126.8,128L126.8,128c-3.3,0-6-2.7-6-6V62.3c0-3.3,2.7-6,6-6h0c3.3,0,6,2.7,6,6v59.6C132.8,125.3,130.1,128,126.8,128z"
                />
                <path
                  id="line"
                  d="M126.8,139.3L126.8,139.3c-3.3,0-6-3.6-6-7.9V53c0-4.4,2.7-7.9,6-7.9h0c3.3,0,6,3.6,6,7.9v78.4    C132.8,135.7,130.1,139.3,126.8,139.3z"
                />
                <path
                  id="line-shorter-bent"
                  d="M143.9,128c0,0-0.1,0-0.1,0c-3.1-1.1-4.7-4.6-3.9-7.9c6.3-25,2.8-47.2,0.4-56.8c-0.8-3.3,0.8-6.6,3.8-7.7    c0,0,0.1,0,0.1-0.1c3.3-1.3,6.9,0.8,7.8,4.6c2.7,11.1,6.6,35.6-0.5,63.4C150.6,127.1,147.1,129.1,143.9,128z"
                />
                <path
                  id="line-bent"
                  d="M141.8,140.4c0,0-0.1,0-0.1-0.1c-2.9-1.5-4.2-6.2-3-10.6c8.8-33,3.8-62.8,0.4-75.4c-1.1-4.3,0.2-8.7,3-10.3    c0,0,0.1,0,0.1-0.1c3.1-1.8,7,0.9,8.3,6c3.9,14.6,9.5,47.7-0.5,84.7C148.6,139.5,144.8,142,141.8,140.4z"
                />
              </g>
              <g id="off">
                <path
                  id="line-off-two"
                  d="M159.7,134.1L159.7,134.1c-3.3,0-6-3.2-6-7.2V56.3c0-4,2.7-7.2,6-7.2h0c3.3,0,6,3.2,6,7.2V127    C165.8,130.9,163,134.1,159.7,134.1z"
                />
                <path
                  id="line-off-one"
                  d="M159.7,134.6L159.7,134.6c-3.3,0-6-3.2-6-7.2V56.8c0-4,2.7-7.2,6-7.2h0c3.3,0,6,3.2,6,7.2v70.6    C165.8,131.4,163,134.6,159.7,134.6z"
                />
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="mask">
              <rect
                fill="#000"
                id="maskrect"
                x="105"
                y="30"
                width="180.1"
                height="116.9"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div class="collapse-wrapper">
      <div class="logo-neon">
        <div class="video-box">
          <video class="video-fvc" playsinline autoplay muted loop>
            <source
              src="../../../../assets/images/v2/fvc-video.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <audio loop id="audio" ref="audio">
        <source
          src="../../../../assets/images/v2/fvc-audio.mp3"
          type="audio/mp3"
        />
      </audio>

      <div class="container-v2 city-group">
        <div class="city-group-item text-neon">Miami (HQ)</div>
        <div class="city-group-item">Guatemala City</div>
        <div class="city-group-item">Dubai</div>
        <div class="city-group-item">Madrid</div>
        <div class="city-group-item">Bogata</div>
        <div class="city-group-item">London</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counter: null,
      textActivePre: null,
      audio: null,
      isPlayed: false,

      mainTl: null,
      audioOn: null,
    };
  },

  watch: {
    isPlayed(val) {
      if (val) {
        this.playVideo();
      } else {
        this.stopVideo();
      }
    },
  },

  mounted() {
    this.audio = this.$refs.audio;
    this.counter = setInterval(this.textNeonAnimation, 4000);
    this.initSoundIconAnimation();
  },

  beforeUnmount() {
    clearInterval(this.counter);
  },

  methods: {
    initSoundIconAnimation() {
      var mainTl = new TimelineMax({
          paused: true,
        }),
        svg = $("svg.sound-svg"),
        speaker = $("#speaker"), //0.1c0,61.2,
        speakerAttr =
          "M5.6,35.7h44.6L89.3,5.5c0.1-0.1,0.2,0,0.2,0.1c0,59.3,0,118.6,0,118.6c0,0.1-0.1,0.1-0.2,0.1L52.2,97.7H5.6c-0.1,0-0.1,0-0.1-0.1V35.8C5.5,35.8,5.5,35.7,5.6,35.7z",
        lines = $("#lines"),
        lineShorter = $("#line-shorter"),
        line = $("#line"),
        offLines = $("#off"),
        offLineOne = $("#line-off-one"),
        offLineTwo = $("#line-off-two"),
        lineBent = $("#line-bent"),
        lineShorterBent = $("#line-shorter-bent");

      const setStage = () => {
        var setStageTl = new TimelineMax();
        setStageTl
          .set([line, lineShorter], {
            x: -50,
          })
          .set(speaker, {
            y: 24,
            x: 16,
            attr: {
              d: speakerAttr,
            },
            autoAlpha: 1,
          })
          .set(offLines, {
            x: -55,
            autoAlpha: 0,
          })
          .set(lineBent, {
            x: 25,
          })
          .set(svg, {
            autoAlpha: 1,
          });

        return setStageTl;
      };

      const audioIconAnimation = () => {
        var audioIconAnimationTl = new TimelineMax();

        audioIconAnimationTl
          .to(
            lines,
            0.3,
            {
              x: "+=8",
              ease: Power2.easeOut,
            },
            "a"
          )
          .to(
            lineBent,
            0.4,
            {
              x: -60,
              ease: Power2.easeIn,
            },
            "b-=.2"
          )
          .to(
            lineShorterBent,
            0.4,
            {
              x: -60,
              ease: Power2.easeIn,
            },
            "b-=.2"
          )
          .to(
            lineBent,
            0.3,
            {
              morphSVG: line,
              ease: Elastic.easeOut.config(1.5, 1),
            },
            "b"
          )
          .to(
            lineShorterBent,
            0.3,
            {
              morphSVG: lineShorter,
              ease: Elastic.easeOut.config(1.5, 1),
            },
            "b"
          )
          .to(
            lines,
            0.6,
            {
              scaleY: 0.85,
            },
            "-=.4"
          )
          .to(
            offLines,
            0.2,
            {
              x: "+=50",
              scaleY: 0.9,
              rotation: "+=3deg",
              autoAlpha: 1,
              transformOrigin: "50% 50%",
              ease: Elastic.easeOut.config(1.8, 2),
            },
            "linesIn-=.3"
          )
          .add("rotation")
          .to(
            offLineOne,
            0.3,
            {
              rotation: "225deg",
              transformOrigin: "50% 50%",
            },
            "rotation-=.2"
          )
          .to(
            offLineTwo,
            0.2,
            {
              rotation: "310deg",
              transformOrigin: "50% 50%",
            },
            "rotation-=.2"
          );

        return audioIconAnimationTl;
      };

      const init = () => {
        var audioOn = false;
        const vue = this;
        setStage();
        mainTl.add(audioIconAnimation(), "animation");

        $(svg).on("click", function () {
          if (audioOn) {
            mainTl.play();
            vue.isPlayed = false;
            audioOn = 0;
          } else {
            mainTl.reverse();
            vue.isPlayed = true;
            audioOn = !0;
          }
        });

        mainTl.play();
      };

      init();
    },

    playVideo() {
      this.isPlayed = true;
      this.audio.currentTime = 0;

      setTimeout(() => {
        this.audio.volume = 0.0;
      }, 100);

      this.audio.play();

      setTimeout(() => {
        this.audio.volume = 0.1;
      }, 250);
      setTimeout(() => {
        this.audio.volume = 0.3;
      }, 500);
      setTimeout(() => {
        this.audio.volume = 0.4;
      }, 750);
      setTimeout(() => {
        this.audio.volume = 0.5;
      }, 1000);
    },

    stopVideo() {
      this.isPlayed = false;

      setTimeout(() => {
        this.audio.volume = 0.5;
      }, 250);
      setTimeout(() => {
        this.audio.volume = 0.4;
      }, 500);
      setTimeout(() => {
        this.audio.volume = 0.3;
      }, 750);
      setTimeout(() => {
        this.audio.volume = 0.1;
        this.audio.pause();
      }, 1000);
    },

    generateRandom(min = 0, max = 5) {
      // text length = 5
      const num = Math.floor(Math.random() * (max - min + 1)) + min;
      return num === this.textActivePre ? this.generateRandom(min, max) : num;
    },

    textNeonAnimation() {
      const textList = [...document.querySelectorAll(".city-group-item")];
      textList.map((el) => el.classList.remove("text-neon"));

      const randomNumber = this.generateRandom();
      this.textActivePre = randomNumber;
      const textEl = textList[randomNumber];
      if (!textEl) return;

      textEl.classList.add("text-neon");
    },
  },
};
</script>
<style lang="scss" scoped>
.section-10 {
  position: relative;
  background: #000;
  height: 50vh;
  z-index: 100;

  @media screen and (min-width: 576px) {
    height: 70vh;
  }

  @media screen and (min-width: 768px) {
    height: 100vh;
  }
}

.play {
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out;
}

.pause {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-out;
}

.logo-neon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

  .video-box {
    width: 100%;

    video {
      width: 100%;
      height: 50vh;
      @media screen and (min-width: 576px) {
        height: 70vh;
      }

      @media screen and (min-width: 768px) {
        height: 100vh;
      }
    }
  }
}

.city-group {
  position: absolute;
  right: 0;
  top: 0;
  height: 50vh;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  user-select: none;
  @media screen and (min-width: 576px) {
    height: 70vh;
  }

  @media screen and (min-width: 768px) {
    height: 100vh;
    min-height: 708px !important;
  }

  .city-group-item {
    font-family: "Clip";
    font-size: 19px;
    line-height: 150%;

    color: #171717;
    text-transform: capitalize;
    transition: all 0.3s ease-out;

    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    @media screen and (min-width: 576px) {
      font-size: 40px;
    }

    @media screen and (min-width: 992px) {
      font-size: 50px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 70px;
    }

    @media screen and (min-width: 1500px) {
      line-height: 200%;
    }
  }

  .text-neon {
    transition: all 0.3s ease-out;

    color: #fff;
    animation: neon-flicker 2s linear infinite;
  }
}

@keyframes neon-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
      0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }
  2% {
    opacity: 1;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 35px rgba(202, 228, 225, 0.42), 0 0 17px rgba(30, 132, 242, 0.58),
      0 0 27px rgba(30, 132, 242, 0.84), 0 0 45px rgba(30, 132, 242, 0.88),
      0 0 65px rgba(30, 132, 242, 1);
  }
  4% {
    opacity: 0.1;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
      0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }

  8% {
    opacity: 1;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 35px rgba(202, 228, 225, 0.42), 0 0 17px rgba(30, 132, 242, 0.58),
      0 0 27px rgba(30, 132, 242, 0.84), 0 0 45px rgba(30, 132, 242, 0.88),
      0 0 65px rgba(30, 132, 242, 1);
  }
  70% {
    opacity: 0.7;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
      0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 35px rgba(202, 228, 225, 0.42), 0 0 17px rgba(30, 132, 242, 0.58),
      0 0 27px rgba(30, 132, 242, 0.84), 0 0 45px rgba(30, 132, 242, 0.88),
      0 0 65px rgba(30, 132, 242, 1);
  }
}

.switch-control {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 9;

  cursor: pointer;

  @media screen and (min-width: 992px) {
    top: 40px;
    left: 40px;
  }

  @media screen and (min-width: 1500px) {
    top: 100px;
    left: 100px;
  }
}
</style>

<style lang="scss" scoped>
//Icon color
$icon-color: #f1f1f1;

.switch-control {
  .sound-icon {
    width: 150px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#speakers path {
  fill: none !important;
  stroke: $icon-color;
  stroke-width: 11;
  stroke-miterlimit: 10;

  filter: drop-shadow(0px 15px 39px rgba(30, 132, 242, 0.92));
}

.sound-icon-wrapper {
  position: relative;

  width: 50px;
  height: 50px;

  @media screen and (min-width: 576px) {
    width: 80px;
    height: 80px;
  }

  @media screen and (min-width: 1500px) {
    width: 110px;
    height: 110px;
  }
}

.switch-control svg path {
  fill: $icon-color;
}

.switch-control svg {
  opacity: 0;
  cursor: pointer;
  transition: 0.2s all linear;
}
</style>

<style>
video.video-fvc::-webkit-media-controls {
  display: none !important;
}
video.video-fvc::-webkit-media-controls-start-playback-button {
  display: none !important;
}
video.video-fvc::-webkit-media-controls {
  opacity: 0;
}
video.video-fvc {
  pointer-events: none;
}
</style>
