import { createRouter, createWebHistory } from "vue-router";
import route from "../misc/route";
import routeV2 from "../config/router-v2";
//not auth view

import HomeV2 from "../views/v2/home";

// import auth view
import Login from "../views/auth/login";
import Register from "../views/auth/register";
import ForgotPassword from "../views/auth/forgotPassword";
import ResetPassword from "../views/auth/resetPassword";
import VerifyAccount from "../views/auth/verifyAccount";
import ResendVerifyEmail from "../views/auth/resendVerifyEmail";
// import AboutUs from "../views/about";
import TermsAndConditions from "../views/tnc";
// import WhyFuel from "../views/whyFuel";
// import OurTeam from "../views/ourTeam";
// import Portfolio from "../views/portfolio";
import NotFound from "../views/notFound";
import ConfirmInvitation from "../views/auth/confirmInvitation";
import DocViewer from "../views/docViewer/auth";
import UnauthDocViewer from "../views/docViewer/unauth";

import overView from "../views/admin/overview";
import ReviewSurvey from "../views/admin/reviewSurvey";
import ReviewSurveyDetail from "../views/admin/reviewSurveyDetail";
import Team from "../views/admin/teamManagement";
import Funds from "../views/admin/funds";
import LPFunds from "../views/admin/lp-media/lp-funds";
import NewsLetter from "../views/admin/lp-media/newsletter";
import Portfolios from "../views/admin/portfolios";
import FuelTheMind from "../views/admin/fuelTheMind";
import Profile from "../views/admin/profile";
import Dashboard from "../views/admin/dashboard";
import KPI from "../views/admin/kpi";
import KPIREPORT from "../views/admin/kpi-report";
import WELCOMESURVEY from "../views/admin/welcomeSurvey";
import PARTNER from "../views/admin/partner";
import PARTNEROWNERSHIP from "../views/admin/partner/ownership";
import PARTNERGOAL from "../views/admin/partner/partnerGoal";
import PARTNERDATAVIEW from "../views/admin/partner/dataView";
import PARTNERKPIINPUT from "../views/admin/kpi";
import SURVEYFINISHED from "../views/admin/surveyFinished";
import VIEWSURVEY from "../views/admin/viewSurvey";
import USERS from "../views/admin/users";
import EmailQueue from "../views/admin/emailQueue";
import UserLoginHistory from "../views/admin/userLoginHistory";
import FOUNDERS from "../views/admin/founders/index";
import INVESTORS from "../views/admin/investors";
import INVESTORROLES from "../views/admin/investorRoles";
import MESSAGETEMPLATES from "../views/admin/messageTemplates";
import ROBOTICADMIN from "../views/admin/robotic";
import SOCIAL from "../views/admin/social";
import PERSCPECTIVE from "../views/admin/perspective";
import MEETFOUNDER from "../views/admin/meetFounder";
//Deals
import Deal from "../views/admin/deal";
import DealDetail from "../views/admin/deal-detail";
//import DealArea from '../views/admin/deal-area'
//import OwnerType from '../views/admin/deal-owner-type'
import Stages from "../views/admin/deal-state-reviewer";
// import DealOverview from '../views/admin/deal-overview'
// import DealReport from '../views/admin/deal-report'

//import DEALAREA from '../views/admin/dealAreaManagement'
import IMCONFIG from "../views/admin/investors";
import IRCONFIG from "../views/admin/investorRoles";
import KPIVIEW from "../views/admin/partner/dataView";
import DEALFOUNDER from "../views/admin/dealFounder";

// import SIGNNDA from '../views/admin/signNDA'

// Report
import RptInvestorEngagement from "../views/admin/reports/dataroomTracking/investorEngagement";
import RptInvestorFocus from "../views/admin/reports/dataroomTracking/investorFocus";

//robotic page
import ROBOTIC from "../views/robotic";
import SIGNNDA from "../views/signNDA";

//Visualization
import VisualizationOverview from "../views/visualization/overview";
import VisualizationInvestments from "../views/visualization/investments";
import VisualizationPortfolioPerformance from "../views/visualization/PortfolioPerformance";

import DOCUMENTATION from "../views/admin/documentation";
import INVESTORPARNER from "../views/investor";
import INVESTORPARNERSIGNNDA from "../views/investor/signNDA";

import DATAROOM from "../views/admin/dataroom";
import SHAREDUSERDATAROOM from "../views/admin/dataroom-shared-user";
import LPMEDIACALLREPLAY from "../views/admin/lp-media/investor-call-replay";
import LPMEDIACOINVESTMENTOP from "../views/admin/lp-media/co-investment-op";
import LPMEDIASEEDEDCOMPANIES from "../views/admin/lp-media/seeded-companies";
import LPMEDIAINTROVIDEO from "../views/admin/lp-media/intro-video";
import EVENT from "../views/admin/event";
import NEWEVENT from "../views/admin/event/components/event-create";
import EDITEVENT from "../views/admin/event/components/event-edit";
import EventVerify from "../views/event-invitation/event-verify";
import notifications from "../views/admin/notifications";
import privateNews from "../views/privateNews";

import CAPTUREMEDIA from "@/views/admin/captureMedia";
import MAPCHART from "@/views/admin/mapchart";
import SCANQRCODE from "@/views/admin/event/components/event-scan-qr-code";

import page1 from '@/views/static/page1';
import page2 from '@/views/static/page2';

// var langjson = require(`../config/lang/${appStore.state.currentLanguage.code}.json`)
// const META_AUTH_REQUIRED = {
//   auth: true
// }

export const USER_TYPE = {
  FOUNDER: "Founder",
  ROKKER: "Rokker",
  IDC: "IDC",
  INVESTOR: "Investor",
  FOUNDER_POTENTIAL: "Founder_Potential",
};

export const PERMISSIONS = {
  Dashboard_Read: "Dashboard_Read",
  Surveys_Read: "Surveys_Read",
  Partners_KPI_Report: "Partners_KPI_Report",
  Partners_KPI_Manage: "Partners_KPI_Manage",
  IDC_Partners_KPI_Report: "IDC_Partners_KPI_Report",
  IDC_Partners_KPI_Manage: "IDC_Partners_KPI_Manage",
  Members_Read: "Members_Read",
  Portfolios_Read: "Portfolios_Read",
  News_Read: "News_Read",
  EmailQueue_Read: "EmailQueue_Read",
  Users_Read: "Users_Read",
  Users_Delete: "Users_Delete",
  Founders_Read: "Founders_Read",
  Guest_Registration_Report: "Guest_Registration_Report",
  Investors_Read: "Investors_Read",
  Investors_Create: "Investors_Create",
  Investors_Update: "Investors_Update",
  Investors_Delete: "Investors_Delete",
  InvestorRoles_Read: "InvestorRoles_Read",
  InvestorRoles_Update: "InvestorRoles_Update",
  InvestorRoles_Create: "InvestorRoles_Create",
  Config_Investors_Read: "Config_Investors_Read",
  Config_Investors_Create: "Config_Investors_Create",
  Config_Investors_Update: "Config_Investors_Update",
  Config_Investors_Delete: "Config_Investors_Delete",
  Config_InvestorRoles_Read: "Config_InvestorRoles_Read",
  Config_InvestorRoles_Update: "Config_InvestorRoles_Update",
  Config_InvestorRoles_Create: "Config_InvestorRoles_Create",
  Roles_Read: "Roles_Read",
  MessageTemplates_Read: "MessageTemplates_Read",
  Dataroom_Read: "Dataroom_Read",
  SystemParam_InstagramAccessToken: "SystemParam_InstagramAccessToken",
  Report_Dataroom_InvestorEngagement: "Report_Dataroom_InvestorEngagement",
  Report_Dataroom_InvestorFocus: "Report_Dataroom_InvestorFocus",
  LpMedia_Read: "LpMedia_Read",
  Funds_Read: "Funds_Read",
  Deal_Read: "Deal_Read",
  Deal_Read_Deleted_Deals: "Deal_Read_Deleted_Deals",
  Deal_Update: "Deal_Update",
  Deal_Delete: "Deal_Delete",
  Deal_StageReviewer_Create: "Deal_StageReviewer_Create",
  Deal_StageReviewer_Delete: "Deal_StageReviewer_Delete",
  Deal_StageReviewer_Read: "Deal_StageReviewer_Read",
  Deal_StageReviewer_Update: "Deal_StageReviewer_Read",
  Notification_Read: "Notification_Read",
  Notification_Create: "Notification_Create",
  Event_Read: "Event_Read",
  Event_Update: "Event_Update",
  Perspective_Read: "Perspective_Read",
  Perspective_Update: "Perspective_Update",
  Perspective_Delete: "Perspective_Delete",
  Perspective_Create: "Perspective_Create",
  MeetFounder_Read: "MeetFounder_Read",
  MeetFounder_Update: "MeetFounder_Update",
  MeetFounder_Delete: "MeetFounder_Delete",
  MeetFounder_Create: "MeetFounder_Create",
  LPFunds_Read: "LPFunds_Read",
  LPFunds_Create: "LPFunds_Create",
  LPFunds_Update: "LPFunds_Update",
  LPFunds_Delete: "LPFunds_Delete",
};

export const router = {
  // v2
  // homeV2: route.register("/v2", "Home", HomeV2),

  // not auth required
  home: route.register("/", "Home v1", HomeV2),

  // aboutUs: route.register("/about-us", "About us", AboutUs),
  termsAndConditions: route.register(
    "/terms-and-conditions",
    "Terms and Conditions",
    TermsAndConditions,
    { static: true }
  ),
  // whyFuel: route.register("/why-fuel", "Why fuel", WhyFuel),
  // ourTeam: route.register("/our-team", "Our Team", OurTeam),
  // portfolio: route.register("/portfolio", "Portfolio", Portfolio),
  // news: route.register("/news", "News", News),
  login: route.register("/login", "Login", Login),
  register: route.register("/register", "Register", Register),
  verifyRegister: route.register(
    "/verify-email",
    "Verify Email",
    VerifyAccount,
    { isEmptyLayout: true }
  ),
  resendVerifyRegisterEmail: route.register(
    "/register-resend-verify-email",
    "Resend Verify Register Email",
    ResendVerifyEmail
  ),
  forgotPassword: route.register(
    "/forgot-password",
    "Forgot Password",
    ForgotPassword
  ),
  resetPassword: route.register(
    "/reset-password",
    "Reset Password",
    ResetPassword
  ),
  confirmInvitation: route.register(
    "/confirm-invitation",
    "Confirm Invitation",
    ConfirmInvitation
  ),
  eventVerify: route.register("/event/verify", "Event verify", EventVerify, {
    isEmptyLayout: true,
  }),
  //auth required
  error404: route.register("/not-found", "Not found", NotFound),
  //static page

  //admin page
  overview: route.register("/rfm/overview", "Company Overview", overView, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
  }),
  reviewSurveyDetail: route.register(
    "/rfm/review-survey/:surveyID",
    "Submission Detail",
    ReviewSurveyDetail,
    {
      auth: true,
      showSidebar: false,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Surveys_Read],
    }
  ),
  reviewSurvey: route.register(
    "/rfm/review-survey",
    "Founder Submission Portal",
    ReviewSurvey,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Surveys_Read],
    }
  ),
  portfolios: route.register("/rfm/portfolios", "Portfolios", Portfolios, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Portfolios_Read],
  }),
  fuelTheMind: route.register(
    "/rfm/fuel-the-mind",
    "Fuel the mind",
    FuelTheMind,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.News_Read],
    }
  ),
  team: route.register("/rfm/team-management", "Team", Team, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Members_Read],
  }),
  fund: route.register("/rfm/fund-management", "Fund", Funds, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Members_Read],
  }),
  lpFund: route.register("/rfm/funds", "Funds", LPFunds, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Members_Read],
  }),
  newsLetter: route.register("/rfm/newsletter", "Newsletter", NewsLetter, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Members_Read],
  }),
  partner: route.register("/rfm/partner", "KPI Management", PARTNER, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
    permissions: [
      PERMISSIONS.Partners_KPI_Manage,
      PERMISSIONS.IDC_Partners_KPI_Manage,
    ],
  }),
  partnerOwnership: route.register(
    "/rfm/partner/:userId/ownership",
    "Partner Ownership",
    PARTNEROWNERSHIP,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
      permissions: [
        PERMISSIONS.Partners_KPI_Manage,
        PERMISSIONS.IDC_Partners_KPI_Manage,
      ],
    }
  ),
  partnerGoal: route.register(
    "/rfm/partner/:userId/goals",
    "Monthly Partner Goal",
    PARTNERGOAL,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
      permissions: [
        PERMISSIONS.Partners_KPI_Manage,
        PERMISSIONS.IDC_Partners_KPI_Manage,
      ],
    }
  ),
  partnerDataView: route.register(
    "/rfm/partner/:userId/data-view",
    "Partner Chart View",
    PARTNERDATAVIEW,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
      permissions: [
        PERMISSIONS.Partners_KPI_Manage,
        PERMISSIONS.IDC_Partners_KPI_Manage,
      ],
    }
  ),
  partnerKpiInput: route.register(
    "/rfm/partner/:portfolioId/kpi",
    "Partner KPI Input",
    PARTNERKPIINPUT,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER, USER_TYPE.IDC],
      permissions: [
        PERMISSIONS.Partners_KPI_Manage,
        PERMISSIONS.IDC_Partners_KPI_Manage,
      ],
    }
  ),
  surveyFinished: route.register("/rfm/surveys", "Surveys", SURVEYFINISHED, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Surveys_Read],
  }),
  users: route.register("/rfm/users", "Backend Users", USERS, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Users_Read],
  }),
  emailQueue: route.register("/rfm/email-queue", "Email Queue", EmailQueue, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.EmailQueue_Read],
  }),
  userLoginHistory: route.register(
    "/rfm/user-login-history",
    "User Login History",
    UserLoginHistory,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Users_Read],
    }
  ),
  founders: route.register("/rfm/founders", "Founder Management", FOUNDERS, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Founders_Read],
  }),
  investors: route.register("/rfm/investors", "User Management", INVESTORS, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    showInactiveStt: false,
    permissions: [PERMISSIONS.Investors_Read],
  }),
  investorRoles: route.register(
    "/rfm/investor-roles",
    "User Roles",
    INVESTORROLES,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      showInactiveStt: false,
      permissions: [PERMISSIONS.InvestorRoles_Read],
    }
  ),
  roboticadmin: route.register(
    "/rfm/guest-member",
    "Office Visitor",
    ROBOTICADMIN,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Guest_Registration_Report],
    }
  ),
  messageTmpls: route.register(
    "/rfm/message-templates",
    "Invitation Templates",
    MESSAGETEMPLATES,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.MessageTemplates_Read],
    }
  ),
  social: route.register("/rfm/social", "Social", SOCIAL, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.SystemParam_InstagramAccessToken],
  }),
  imConfig: route.register("/rfm/um-config", "UM Configurations", IMCONFIG, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    showInactiveStt: true,
    permissions: [PERMISSIONS.Config_Investors_Read],
  }),
  irConfig: route.register("/rfm/ur-config", "UR Configurations", IRCONFIG, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    showInactiveStt: true,
    permissions: [PERMISSIONS.Config_InvestorRoles_Read],
  }),
  dataroom: route.register("/rfm/dataroom", "Dataroom", DATAROOM, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Dataroom_Read],
  }),
  sharedUserDataroom: route.register(
    "/rfm/shared-dataroom",
    "Shared Dataroom",
    SHAREDUSERDATAROOM,
    { auth: true, userTypes: [USER_TYPE.FOUNDER, USER_TYPE.IDC] }
  ),
  notifications: route.register(
    "/rfm/notifications-management",
    "Notifications Management",
    notifications,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Notification_Read],
    }
  ),

  //deal
  deal: route.register("/rfm/deals", "Deals", Deal, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Deal_Read],
  }),
  dealDetail: route.register("/rfm/deals/:idDeal", "Deals Detail", DealDetail, {
    auth: true,
    userTypes: [
      USER_TYPE.ROKKER,
      USER_TYPE.FOUNDER,
      USER_TYPE.FOUNDER_POTENTIAL,
    ],
    permissions: [PERMISSIONS.Deal_Read],
  }),
  //dealArea: route.register('/rfm/deal-areas', 'Deals Areas', DealArea, { auth: true, userTypes: [USER_TYPE.ROKKER], permissions: [PERMISSIONS.Deal_Area_Read] }),
  //ownerType:route.register('/rfm/owner-types', 'Owner Types', OwnerType, { auth: true, userTypes: [USER_TYPE.ROKKER], permissions: [PERMISSIONS.Deal_OwnerType_Read] }),
  stages: route.register("/rfm/deal-stages", "Stages", Stages, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Deal_StageReviewer_Read],
  }),
  // dealOverview:route.register('/rfm/deal-overview', 'Overview', DealOverview, { auth: true, userTypes: [USER_TYPE.ROKKER], permissions: [PERMISSIONS.Deal_Read] }),
  // dealReport:route.register('/rfm/deal-report', 'Report', DealReport, { auth: true, userTypes: [USER_TYPE.ROKKER], permissions: [PERMISSIONS.Deal_Read] }),

  //lp-media
  // lPMediaIntroVideo: route.register(
  //   "/rfm/media-intro-videos",
  //   "Introduction Video",
  //   LPMEDIAINTROVIDEO,
  //   {
  //     auth: true,
  //     userTypes: [USER_TYPE.ROKKER],
  //     permissions: [PERMISSIONS.LpMedia_Read],
  //   }
  // ),
  lPMediaCallReplay: route.register(
    "/rfm/media-call-replays",
    "Investor Call Replay",
    LPMEDIACALLREPLAY,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.LpMedia_Read],
    }
  ),
  lPMediaCoInvestmentOp: route.register(
    "/rfm/media-co-investment-op",
    "Co-investment Op",
    LPMEDIACOINVESTMENTOP,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.LpMedia_Read],
    }
  ),
  lPMediaSeededCompanies: route.register(
      "/rfm/media-portfolio-companies",
      "Portfolio Companies",
      LPMEDIASEEDEDCOMPANIES,
      {
        auth: true,
        userTypes: [USER_TYPE.ROKKER],
        permissions: [PERMISSIONS.LpMedia_Read],
      }
  ),
  event: route.register("/rfm/event", "Event", EVENT, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Event_Read],
  }),
  newEvent: route.register("/rfm/event/create", "Event Create", NEWEVENT, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Event_Read],
  }),
  editEvent: route.register("/rfm/event/:id", "Event Edit", EDITEVENT, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
    permissions: [PERMISSIONS.Event_Read],
  }),
  scanEvent: route.register(
    "/rfm/event/:id/scan-qr-code",
    "scan event",
    SCANQRCODE,
    { static: true },
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Event_Read],
    }
  ),
  unauthDocViewer: route.register(
    "/unauth-docs/viewer",
    "Unauth Doc Viewer",
    UnauthDocViewer,
    { static: true }
  ),
  docViewer: route.register("/docs/viewer", "Doc Viewer", DocViewer, {
    auth: true,
    static: true,
    userTypes: [USER_TYPE.ROKKER, USER_TYPE.INVESTOR],
    ndaSignRequired: false,
  }),
  profile: route.register("/rfm/profile", "My Profile", Profile, {
    auth: true,
    userTypes: [
      USER_TYPE.ROKKER,
      USER_TYPE.FOUNDER,
      USER_TYPE.INVESTOR,
      USER_TYPE.FOUNDER_POTENTIAL,
    ],
  }),
  kpiReport: route.register("/rfm/kpi-report", "KPI Dashboard", KPIREPORT, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER, USER_TYPE.FOUNDER, USER_TYPE.IDC],
  }),

  rptInvestorEngagement: route.register(
    "/rfm/reports/dataroom/investor-engagement",
    "Investor engagement",
    RptInvestorEngagement,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Report_Dataroom_InvestorEngagement],
    }
  ),
  rptInvestorFocus: route.register(
    "/rfm/reports/dataroom/investor-focus",
    "Investor focus",
    RptInvestorFocus,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Report_Dataroom_InvestorFocus],
    }
  ),

  welcomeSurvey: route.register(
    "/rfm/welcome-survey",
    "Welcome Survey",
    WELCOMESURVEY,
    { auth: true, showSidebar: false, userTypes: [USER_TYPE.FOUNDER] }
  ),
  dashboard: route.register("/rfm/dashboard", "Dashboard", Dashboard, {
    auth: true,
    userTypes: [USER_TYPE.FOUNDER],
  }),
  viewSurvey: route.register(
    "/rfm/survey/:surveyID?",
    "Survey Detail",
    VIEWSURVEY,
    { auth: true, showSidebar: false, userTypes: [USER_TYPE.FOUNDER] }
  ),
  kpi: route.register("/rfm/kpi", "KPI Input", KPI, {
    auth: true,
    userTypes: [USER_TYPE.FOUNDER],
  }),
  kpiView: route.register("/rfm/kpi-view", "KPI Configuration", KPIVIEW, {
    auth: true,
    userTypes: [USER_TYPE.FOUNDER],
  }),
  dealFounder: route.register(
    "/rfm/deal-founder",
    "Deal Founder",
    DEALFOUNDER,
    {
      auth: true,
      userTypes: [USER_TYPE.FOUNDER, USER_TYPE.FOUNDER_POTENTIAL],
    }
  ),

  robotic: route.register("/robotic", "Robotic", ROBOTIC, { static: true }),
  signnda: route.register("/signnda", "Sign NDA", SIGNNDA, { static: true }),

  visualizationOverview: route.register(
    "/visualization",
    "Visualization Overviews",
    VisualizationOverview,
    { static: true }
  ),
  visualizationInvestments: route.register(
    "/visualization-investments",
    "Visualization Investments",
    VisualizationInvestments,
    { static: true }
  ),
  visualizationPortfolioPerformance: route.register(
    "/portfolio-performance",
    "Visualization Portfolio Performance",
    VisualizationPortfolioPerformance,
    { static: true }
  ),
  documentation: route.register(
    "/documentation",
    "Documentation",
    DOCUMENTATION,
    { static: true }
  ),
  investorpartner: route.register(
    "/partner",
    "Partner Investor",
    INVESTORPARNER,
    {
      auth: true,
      static: true,
      userTypes: [USER_TYPE.INVESTOR],
      ndaSignRequired: true,
    }
  ),
  investorsignnda: route.register(
    "/investor-sign-nda",
    "Investor Sign NDA",
    INVESTORPARNERSIGNNDA,
    { auth: true, static: true, userTypes: [USER_TYPE.INVESTOR] }
  ),

  privateNews: route.register(
    "/private-news/:id",
    "Private News",
    privateNews,
    { static: true }
  ),

  captureMedia: route.register(
    "/capture-media",
    "Capture Media",
    CAPTUREMEDIA,
    { static: true }
  ),
  mapchart: route.register("/mapchart", "Map Chart", MAPCHART, {
    auth: true,
    userTypes: [USER_TYPE.ROKKER],
  }),

  perspective: route.register(
    "/rfm/perspective",
    "Fuel Perspective",
    PERSCPECTIVE,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.Perspective_Read],
    }
  ),

  meetFounder: route.register(
    "/rfm/meet-founder",
    "Meet Founder & CEO",
    MEETFOUNDER,
    {
      auth: true,
      userTypes: [USER_TYPE.ROKKER],
      permissions: [PERMISSIONS.MeetFounder_Read],
    }
  ),

  staticPage1: route.register("/static/page1", "Page1", page1, {static: true}),
  staticPage2: route.register("/static/page2", "Page2", page2, {static: true})
};

export const routes = createRouter({
  history: createWebHistory("/"),
  routes: [
    ...routeV2,
    ...route.buildConfig(router),
    {
      path: "/:catchAll(.*)",
      redirect: "/",
    },
  ],
  linkActiveClass: "router-link-active",
  linkExactActiveClass: "router-link-exact-active",
  mode: "history",
});

export default router;
