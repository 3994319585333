<template>
  <transition name="fade" v-if="isShow">
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="maskModal" @click="hideModal"></div>
      <div class="modal-dialog m-0" role="document">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h5 class="title mb-0">View History</h5>
            <button type="button" class="btn close" @click="hideModal">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="list-review" v-loading="loading">
              <ul class="p-0 m-0" v-if="dataReview">
                <li
                  :class="[
                    { 'mb-3': index !== dataReview.length - 1 },
                    item.action,
                  ]"
                  v-for="(item, index) in dataReview"
                  :key="`review_${index}`"
                >
                  <div class="status">
                    <template v-if="item.action === 'completed'">
                      <StarBlueIcon />
                    </template>
                    <template v-if="item.action === 'DEAL_APPROVE'">
                      <DealApproveIcon />
                    </template>
                    <template v-if="item.action === 'DEAL_ONHOLD'">
                      <DealOnholdIcon />
                    </template>
                    <template v-if="item.action === 'DEAL_REJECT'">
                      <DealRejectIcon />
                    </template>
                    <template v-if="item.action === 'MESSAGE_CREATE'">
                      <DealMessageCreateIcon width="15px" />
                    </template>
                    <template
                      v-if="
                        item.action === 'DOCUMENT_CREATE' ||
                        item.action === 'DOCUMENT_DELETE'
                      "
                    >
                      <DealDocumentIcon />
                    </template>
                    <template
                      v-if="
                        item.action === 'RATING_CREATE' ||
                        item.action === 'RATING_UPDATE'
                      "
                    >
                      <DealStarIcon />
                    </template>
                    <template
                      v-if="
                        item.action === 'DEAL_UPDATE' ||
                        item.action === 'DEAL_CREATE' ||
                        item.action === 'DEAL_SAVE_DRAFT'
                      "
                    >
                      <DealUpdateIcon />
                    </template>
                    <template
                      v-if="
                        item.action === 'DEAL_REVIEW_CREATE' ||
                        item.action === 'DEAL_REVIEW_SAVE'
                      "
                    >
                      <DealReviewIcon />
                    </template>
                  </div>
                  <div class="content">
                    <span v-if="item.action === 'DEAL_APPROVE'" class="desc">
                      <b>{{ item.created_by }}</b> approve deal to
                      <b v-if="item.payload"
                        >{{
                          totalStage.find((p) => p.id === item.stage_id).name
                        }}
                        Stage</b
                      >
                    </span>
                    <span v-if="item.action === 'DEAL_ONHOLD'" class="desc">
                      <b>{{ item.created_by }}</b> change deal deadline.
                    </span>
                    <span v-if="item.action === 'DEAL_REJECT'" class="desc">
                      <b>{{ item.created_by }}</b> reject the deal.
                    </span>
                    <span v-if="item.action === 'DOCUMENT_CREATE'" class="desc">
                      <b>{{ item.created_by }}</b> uploaded document
                      <b v-if="item.payload"
                        >{{
                          item.payload.Name && item.payload.Extension
                            ? `${item.payload.Name}${item.payload.Extension}`
                            : ""
                        }}.</b
                      >
                    </span>
                    <span v-if="item.action === 'DOCUMENT_DELETE'" class="desc">
                      <b>{{ item.created_by }}</b> deleted document
                      <b v-if="item.payload"
                        >{{
                          item.payload.Name && item.payload.Extension
                            ? `${item.payload.Name}${item.payload.Extension}`
                            : ""
                        }}.</b
                      >
                    </span>
                    <span v-if="item.action === 'MESSAGE_CREATE'" class="desc">
                      <b>{{ item.created_by }}</b> create message
                      <b v-if="item.payload"
                        >{{
                          item.payload.Message ? `${item.payload.Message}` : ""
                        }}.</b
                      >
                    </span>
                    <span v-if="item.action === 'RATING_CREATE'" class="desc">
                      <b>{{ item.created_by }}</b> rated stage
                      {{ totalStage.find((p) => p.id === item.stage_id).name }}
                      <b v-if="item.payload"
                        >{{
                          item.payload.Rating ? `${item.payload.Rating}` : ""
                        }}
                        stars.</b
                      >
                    </span>
                    <span v-if="item.action === 'RATING_UPDATE'" class="desc">
                      <b>{{ item.created_by }}</b> re-submitted rate for stage
                      {{ totalStage.find((p) => p.id === item.stage_id).name }}
                      to
                      <b v-if="item.payload"
                        >{{
                          item.payload.Rating ? `${item.payload.Rating}` : ""
                        }}
                        stars.</b
                      >
                    </span>
                    <span
                      v-else-if="item.action === 'DEAL_UPDATE'"
                      class="desc"
                    >
                      <b>{{ item.created_by }}</b> edited Deal Details.
                    </span>
                    <span
                      v-else-if="item.action === 'DEAL_CREATE'"
                      class="desc"
                    >
                      <b>{{ item.created_by }}</b> save Deal.
                    </span>
                    <span
                      v-else-if="item.action === 'DEAL_SAVE_DRAFT'"
                      class="desc"
                    >
                      <b>{{ item.created_by }}</b> save a Draft Deal.
                    </span>
                    <span
                      v-else-if="item.action === 'DEAL_REVIEW_CREATE'"
                      class="desc"
                    >
                      Deal checklist created by <b>{{ item.created_by }}</b
                      >.
                    </span>
                    <span
                      v-else-if="item.action === 'DEAL_REVIEW_SAVE'"
                      class="desc"
                    >
                      Deal checklist saved by <b>{{ item.created_by }}</b
                      >.
                    </span>
                    <span class="date">{{
                      $filters.parseEstTime(item.created_at)
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style scoped lang="scss">
.modal.show {
  display: block;
  .maskModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(#000, 0.75);
  }
  .modal-dialog {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid transparent;
    border-radius: 18px;
    overflow: hidden;
    width: 100%;
    max-width: 510px;
    z-index: 2;
  }
  .modal-header {
    border-bottom: 1px solid rgba(222, 226, 230, 0.1);
  }
  .modal-content {
    padding: 0;
    color: #ccc;
    background-color: #393c45;
    .title {
      color: #ccc;
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
    }
    .close {
      line-height: 40px;
      border: 0;
    }
  }
  .modal-body {
    .list-review {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        display: block;
        overflow: hidden;
        width: 1px;
        height: 100%;
        background-color: #ebeaeb;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
      ul {
        list-style: none;
        overflow-y: auto;
        max-height: 500px;

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #393c45;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #747474;
          border-radius: 4px;
          transition: all 300ms;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #909090;
          border-radius: 4px;
          transition: all 300ms;
        }
        li {
          z-index: 2;
          display: flex;
          position: relative;
          &:last-child {
            background-color: #393c45;
          }
          &::before {
            flex: 1;
            max-width: 33px;
            content: "";
            display: block;
            overflow: hidden;
            width: 33px;
            height: 33px;
            border: 1px solid #ebeaeb;
            border-radius: 50%;
            background-color: #fff;
            margin-right: 10px;
          }
          .status {
            position: absolute;
            top: 4px;
            left: 16px;
            width: 16px;
            height: 26px;
            transform: translateX(-50%);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          // &.completed {
          //   &::before {
          //     background-image: url("@/assets/images/star-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 7px 6px;
          //   }
          // }
          // &.DEAL_APPROVE {
          //   &::before {
          //     background-image: url("@/assets/images/check-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 8px 10px;
          //   }
          // }
          // &.DEAL_REJECT {
          //   &::before {
          //     background-image: url("@/assets/images/e-remove-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 11px 11px;
          //   }
          // }
          // &.DEAL_ONHOLD {
          //   &::before {
          //     background-image: url("@/assets/images/iconmonstr-clock-thin-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 7px 7px;
          //     background-size: 50%;
          //   }
          // }
          // &.MESSAGE_CREATE {
          //   &::before {
          //     background-image: url("@/assets/images/comment-add-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 9px 9px;
          //   }
          // }
          // &.DOCUMENT_CREATE,
          // &.DOCUMENT_DELETE {
          //   &::before {
          //     background-image: url("@/assets/images/icon/documents-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 8px 8px;
          //     background-size: 14px 14px;
          //   }
          // }
          // &.RATING_CREATE,
          // &.RATING_UPDATE {
          //   &::before {
          //     background-image: url("@/assets/images/icon/star.svg");
          //     background-repeat: no-repeat;
          //     background-position: 8px 8px;
          //     background-size: 14px 14px;
          //   }
          // }
          // &.DEAL_UPDATE {
          //   &::before {
          //     background-image: url("@/assets/images/edit-2-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 10px 10px;
          //   }
          // }
          // &.DEAL_CREATE {
          //   &::before {
          //     background-image: url("@/assets/images/edit-2-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 10px 10px;
          //   }
          // }
          // &.DEAL_SAVE_DRAFT {
          //   &::before {
          //     background-image: url("@/assets/images/edit-2-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 10px 10px;
          //   }
          // }
          // &.DEAL_REVIEW_CREATE {
          //   &::before {
          //     background-image: url("@/assets/images/list-order-svgrepo-com-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 6px 6px;
          //     background-size: 60%;
          //   }
          // }
          // &.DEAL_REVIEW_SAVE {
          //   &::before {
          //     background-image: url("@/assets/images/list-order-svgrepo-com-blue.svg");
          //     background-repeat: no-repeat;
          //     background-position: 6px 6px;
          //     background-size: 60%;
          //   }
          // }
          .content {
            flex: 1;
          }
          span {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            &.date {
              font-size: 12px;
              color: rgba(#ccc, 0.3);
            }
          }
        }
      }
    }
  }
}
</style>
<script src="./index.js"></script>
