<template>
  <section class="section-6" id="#team">
    <div class="quote-wrapper container-v2">
      <div class="swiper-container quote-swiper" data-aos="fade-up">
        <div class="swiper-wrapper">
          <div v-for="item in data" :key="item.id" class="swiper-slide">
            <div class="quote-item">
              <div class="details">
                <div class="text blockquote">
                  <span>{{ item.quote }}</span>
                </div>

                <div class="said-by">
                  <span>{{ item.name }}</span>
                  <p class="position">
                    {{ item.position }}
                  </p>
                </div>
                <div class="quote-pagination">
                  <div class="quote-arrow quote-arrow-left">
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1106_16128)">
                        <path
                          d="M5.21973 6.09801L0.719727 3.50001L5.21973 0.902008V3.05001H11.7441V3.95001H5.21973V6.09801Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1106_16128">
                          <rect
                            width="12"
                            height="6"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="quote-arrow quote-arrow-right">
                    <svg
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1107_16134)">
                        <path
                          d="M7.28027 6.09801L11.7803 3.50001L7.28027 0.902008V3.05001H0.755875V3.95001H7.28027V6.09801Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1107_16134">
                          <rect
                            width="12"
                            height="6"
                            fill="white"
                            transform="matrix(-1 0 0 1 12.5 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="picture">
                <img :src="item.avatar" alt="picture" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      members: [],
      data: [
        {
          id: "1",
          name: "Jeff Ransdell",
          position: "Founding Partner and Managing Director",
          quote: `We’re not trying to find ways to use new technologies in
                    medicine. We start from the clinical need. From there, we
                    try to find technologies that will help us develop products
                    that are going to solve that need.`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/3c84fd1c49b2.png",
        },

        {
          id: "2",
          name: "Maggie Vo, CFA",
          position: "Managing General Partner and Chief Investment Officer",
          quote: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/15e4e0b1f51e.png",
        },

        {
          id: "3",
          name: "Olivia Gaudree",
          position: "Venture Partner, VP Operations",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/656fab55eefb.png",
        },

        {
          id: "4",
          name: "Juan P. Miró",
          position: "Venture Partner, Latin America Venture Market Executive",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/46c042cb90d3.png",
        },

        {
          id: "5",
          name: "Alex Owen",
          position: "Senior Venture Associate, Investments",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/e5705e61c142.jpeg",
        },

        {
          id: "6",
          name: "Vlad Cazacu",
          position: "Venture Associate, Investments",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/f85ce582a3d4.png",
        },

        {
          id: "7",
          name: "Mark Bugas",
          position: "Venture Associate, Investments",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/cf879e483bf4.png",
        },

        {
          id: "8",
          name: "Emilio Escartin",
          position:
            "Venture Partner, Europe/Middle East Venture Market Executive",
          quote: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable`,
          avatar:
            "https://rokk3rfuel.s3.amazonaws.com/images/team/4622162b7202.jpeg",
        },
      ],
      swiper: null,
    };
  },
  created() {},
  mounted() {
    this.swiper = new Swiper(".quote-swiper", {
      loop: true,
      autoplay: {
        delay: 8000,
      },
      spaceBetween: 0,
      speed: 1000,
      effect: "fade",
      fadeEffect: { crossFade: true },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".quote-arrow-left",
        prevEl: ".quote-arrow-right",
      },
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.section-6 {
  background: #fff;
  position: relative;
  z-index: 6;

  .quote-wrapper {
    padding-top: 120px;
    padding-bottom: 120px;

    .quote-item {
      display: grid;
      grid: auto-flow min-content/ 1fr;
      gap: 20px;

      @media screen and (min-width: 768px) {
        grid: auto-flow min-content/ 1fr 1fr;
        gap: 80px;
      }

      .details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px 0;

        .blockquote {
          margin-top: 20px;
          margin-bottom: auto;

          &::before {
            content: open-quote;
            margin-right: 2px;
          }
          &::after {
            content: close-quote;
            margin-left: 2px;
          }

          &::after,
          ::before {
            display: inline-block;
            vertical-align: bottom;
            color: #000;
            font-size: 1em;
            top: 0.2em;
            position: relative;
          }
        }

        .said-by {
          width: 70%;
        }
        .text,
        .said-by {
          font-size: 27px;
          font-weight: 700;
          line-height: 36px;

          @media screen and (min-width: 992px) {
            font-size: 30px;
            font-weight: 700;
            line-height: 40px;
          }
        }

        .said-by {
          .position {
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            padding: 0;
            margin: 0;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .quote-pagination {
          display: flex;

          gap: 20px;
          @media screen and (min-width: 768px) {
            position: absolute;
            bottom: 4px;
            right: 0;
            width: 120px;
            justify-content: space-between;
          }

          .quote-arrow {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid #000;
            background: #fff;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
      .picture {
        position: relative;
        height: 526px;
        width: 100%;
        overflow: hidden;
        @media screen and (min-width: 992px) {
          height: 95vh;
        }
        img {
          /* width: 100%;
          height: 100%;
          object-fit: cover; */
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.quote-arrow {
  &:hover {
    background: #000 !important;

    svg,
    path {
      fill: #fff;
    }
  }
}
</style>
