import { defineComponent } from "vue";

export default defineComponent({
  name: "FileField2",
  props: {
    title: { type: String, default: "" },
    initPreviewUrl: { type: String, default: null },
    modelValue: { type: [Blob, String], default: null },
    disabled: { type: Boolean, default: false },
    uploadBtnText: { type: String, default: "Select Image" },
    mimeType: { type: String, default: "" },
    fileName: { type: String, default: "" },
    note: { type: String, default: "" },
    accept: { type: String, default: `image/jpeg, image/png, image/jpg` },
    fieldRequire: { type: Boolean, default: false },
    errorMsg: { type: String, default: "" },
    classWrapper: { type: String, default: "wrapper" },
  },
  emits: ["update:value", "update:errorMsg", "changeValue"],
  data() {
    return {
      url: undefined,
      file: undefined,
      imageMime: [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
      ],
    };
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    is_image() {
      if (this.file) {
        try {
          return this.file && this.file.type.split("/")[0] === "image";
        } catch (e) {}
      }
      if (
        this.file &&
        this.file.type &&
        this.imageMime.includes(this.file.type)
      ) {
        return true;
      }
      if (!this.mimeType) {
        return true;
      }
      if (this.mimeType) {
        return this.imageMime.includes(this.mimeType);
      }
      return false;
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.url = URL.createObjectURL(this.file);
        this.$emit("update:value", this.file);
        this.$emit("changeValue", this.file);
      } else {
        this.url = undefined;
        this.$emit("update:value", undefined);
      }
    },
    labelClick() {
      let el = this.$refs;
      el.input.click();
    },
  },
});
