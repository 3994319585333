import appStore, { APP_MUTATION } from "@/store/app";
import moment from "moment";
import auth from "@/services/auth";
import notify from "@/services/notify";
import router, { USER_TYPE } from "@/config/router";
import AdminService from "@/services/admin";
import TextField from "@/components/TextField";
import NumberField from "@/components/NumberField";
import SelectField from "@/components/SelectField";
import Datepicker from "@/components/DatePicker";
import { defineComponent } from "vue";
import LPFundModal from "../../investor/lp-funds-modal";

export default defineComponent({
  data() {
    return {
      loading: false,
      formError: false,
      userProfile: {
        email: "",
        firstName: "",
        lastname: "",
        birthDate: "",
        phone: "",
        phonecarrierid: "",
      },
      errorMsg: {
        firstName: "",
        lastName: "",
        birthDate: "",
        phoneNumber: "",
        phoneCarrier: "",
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      error: {
        firstName: false,
        lastName: false,
        birthDate: false,
        phoneNumber: false,
        phoneCarrier: false,
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      userPassword: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      phoneCarrierId: null,
      tmpDate: null,
      welcomeSurveyPath: router.welcomeSurvey.path,
    };
  },
  components: {
    LPFundModal,
    TextField,
    NumberField,
    SelectField,
    Datepicker,
  },
  mounted() {
    if (this.profile) {
      this.setupUserProfile();
    }
  },
  computed: {
    profile: {
      get() {
        return this.$store.state.profile;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    checkUserTypeRokker: function () {
      let isUserType = false;
      if (this.profile.userType === USER_TYPE.ROKKER) isUserType = true;
      return isUserType;
    },
    getPhoneCarrier: function () {
      AdminService.getPhoneCarrierMeta({}).then(async (resp) => {
        if (resp.data.ok) {
          this.phoneCarrierId = await resp.data.d.map((p) => ({
            ...p,
            phone_carrier_id: p.phone_carrier_id + "",
          }));
          this.userProfile.phonecarrierid =
            this.userProfile.phonecarrierid === 0
              ? this.phoneCarrierId[0].phone_carrier_id || null
              : this.userProfile.phonecarrierid;
        }
      });
    },
    updateUserProfile: function () {
      if (!this.validateFormInfo()) {
        this.loading = true;
        auth
          .updateProfile({
            firstName: this.userProfile.firstName,
            lastName: this.userProfile.lastName,
            birthDay: this.userProfile.birthDate,
            phonenumber: this.userProfile.phone,
            phonecarrierid: this.userProfile.phonecarrierid,
          })
          .then((resp) => {
            if (resp.data.ok) {
              appStore.commit(APP_MUTATION.update_profile, this.userProfile);
              notify.success("Personal data updated succesfully.");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateUserPassword: function () {
      if (!this.validateFormPass()) {
        this.loading = true;
        auth
          .changePassword({
            currentPassword: this.userPassword.currentPassword,
            newPassword: this.userPassword.newPassword,
            confirmNewPassword: this.userPassword.confirmNewPassword,
          })
          .then((resp) => {
            if (resp.data.ok) {
              notify.success("Password changed succesfully.");
              this.userPassword = {
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
              };
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    // isNumber: function(e) {
    //     e = (e) ? e : window.event;
    //     var charCode = (e.which) ? e.which : e.keyCode
    //     if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43) {
    //         e.preventDefault()
    //     } else {
    //         return true
    //     }
    // },
    validateFormInfo() {
      var flag = false;
      if (
        !this.userProfile.firstName ||
        this.userProfile.firstName.trim() === ""
      ) {
        this.errorMsg.firstName = "Please input first name";
        flag = true;
      } else {
        this.error.firstName = false;
      }
      if (
        !this.userProfile.lastName ||
        this.userProfile.lastName.trim() === ""
      ) {
        this.errorMsg.lastName = "Please input last name";
        flag = true;
      } else {
        this.error.lastName = false;
      }
      if (this.checkUserTypeRokker() && !this.userProfile.phone) {
        this.errorMsg.phoneNumber = "Please input phone number";
        flag = true;
      } else {
        this.error.phoneNumber = false;
      }

      return flag;
    },
    validateFormPass() {
      var flag = false;

      if (
        !this.userPassword.currentPassword ||
        this.userPassword.currentPassword.trim() === ""
      ) {
        this.errorMsg.currentPassword = "Please input current password";
        flag = true;
      } else {
        this.error.currentPassword = false;
      }
      if (
        !this.userPassword.newPassword ||
        this.userPassword.newPassword.trim() === ""
      ) {
        this.errorMsg.newPassword = "Please input new password";
        flag = true;
      } else {
        this.error.newPassword = false;
      }
      if (
        !this.userPassword.confirmNewPassword ||
        this.userPassword.confirmNewPassword.trim() === ""
      ) {
        this.errorMsg.confirmNewPassword = "Please confirm password";
        flag = true;
      } else {
        this.error.confirmNewPassword = false;
      }
      return flag;
    },
    setupUserProfile() {
      if (
        this.profile.userType === "Founder" &&
        this.profile.welcome_survey_stt !== "APPROVED"
      ) {
        this.$router.push(this.welcomeSurveyPath);
      }
      this.userProfile.email = this.profile.email;
      this.userProfile.firstName = this.profile.firstName;
      this.userProfile.lastName = this.profile.lastName;
      this.userProfile.birthDate = this.profile.birthDate;
      this.userProfile.phone = this.profile.phone;
      this.userProfile.phonecarrierid = this.profile.phonecarrierid;
      if (this.userProfile.birthDate) {
        this.tmpDate = {
          years: moment(this.userProfile.birthDate).format("YYYY"),
          months: moment(this.userProfile.birthDate).format("MM"),
          days: moment(this.userProfile.birthDate).format("DD"),
        };
      }

      if (this.checkUserTypeRokker()) {
        this.getPhoneCarrier();
      }
    },
  },
  watch: {
    profile: {
      deep: true,
      handler(v) {
        if (v) {
          this.setupUserProfile();
        }
      },
    },
    tmpDate: function (v) {
      if (v) {
        this.userProfile.birthDate = `${v.months}/${v.days}/${v.years}`;
      }
    },
    "userProfile.firstName": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.firstName = "";
    },
    "userProfile.lastName": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.lastName = "";
    },
    "userPassword.currentPassword": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.currentPassword = "";
    },
    "userPassword.newPassword": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.newPassword = "";
    },
    "userPassword.confirmNewPassword": function (v) {
      if (v && v.trim().length > 0) this.errorMsg.confirmNewPassword = "";
    },
  },
});
