<template>
  <div class="h-100 container-fluid" v-if="useEmptyLayout">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="row justify-content-center">
          <div
            class="col-sm-9 align-items-center justify-content-center"
            v-loading="loading"
          >
            <div class="d-flex align-items-center mt-3">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>
            <div class="w-45 max-width margin-auto mt-lg-5">
              <div class="colorGray text-center">
                <h4>Verifying Account</h4>
              </div>
            </div>
            <!-- <div class="w-45 max-width margin-auto" :class="success?'mt-lg-5':''">
                  <div v-if="success" class="colorGray text-center">
                      <h4>Verify success</h4>
                      <p>
                          'Your account has been verified!' <br>
                              <router-link place="link" class="colorWhite" :to="loginPath">Login</router-link>
                      </p>
                  </div>
                  <div class="wrapper"  v-else>
                    <form id="loginForm" class="colorWhite" :disabled="disabled">
                        <h2 class="mb-2">Verify account</h2>
                        <md-field class="mb-4">
                            <label>Email</label>
                            <md-input :value="email" id="email" name="email" disabled></md-input>
                        </md-field>
                        <div class="form-group text-center">
                            <recaptcha ref="recaptcha" v-model="recaptcha" :instance="captchaInstance" :dataCallback="submit" />
                        </div>
                        <div class="form-group text-center mb-0">
                            <button @click.prevent="verify" class="btn button btn-large btn-primary btn-bounce btnReg btn-active w-100">Verify now</button>
                        </div>
                    </form>
                  </div>
                  <div class="text-center colorGray">
                      <span>Already have an account?
                          <router-link class="colorWhite" :to="loginPath">Login now</router-link>
                      </span>
                  </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-0">
        <div class="boxFix"></div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.iconAccountType {
  width: 50px;
  height: 50px;
  background-color: #dfe6ff;
  border: 1px solid #dfe6ff;
  border-radius: 50%;
  padding: 15px;
}
.colorDarkGray {
  color: #2e384d;
}
.colorGray {
  color: #b0bac9;
}
.logoLogin {
  img {
    object-fit: cover;
    width: 190px;
    height: auto;
  }
}
// .wrapper {
//   background-color: #fff;
// }
#loginForm {
  font-size: 0.9375rem;
  padding: 2rem;
  // color: #2e384d;
  // opacity: 0.8;
  // background-color: #fff;
  .signInText {
    font-size: 2.125rem;
  }
  .accounType {
    .row {
      margin-left: -10px;
      margin-right: -10px;
      [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
      }
      .btn {
        font-size: 0.875rem;
        &.btnAccountType {
          background-color: transparent;
          border: 1px solid #e0e7ff;
          padding: 2rem;
          &.active {
            border-color: #3e99ed;
            &:before {
              font-family: "Font Awesome 6 Free";
              font-weight: 900;
              content: "\F00C";
              display: inherit;
              color: #fff;
              position: absolute;
              top: 0;
              right: -12.5px;
              background-color: #3e99ed;
              border-radius: 50%;
              display: inline-block;
              width: 25px;
              height: 25px;
              line-height: 25px;
              -ms-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}
</style>
