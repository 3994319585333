import termconditions from './termAndConditions/index'
import signature from './signature'
import AdminServices from '../../services/admin'
import decode from 'jwt-decode'
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            status: 'invalid',
            token: null,
            username: '',
            formData: {
                firstname: null,
                lastname: null,
                phone: null,
                company: null,
                title: null,
                signature: null
            },
            errorMsg: {
                firstname: null,
                lastname: null,
                phone: null,
                company: null,
                title: null,
                signature: null
            },
            onError: false,
            formLoading: false,
            finishSurvey: false
        }
    },
    components: {
        termconditions,
        signature
    },
    methods: {
        validKey($event) {
            let charCode = ($event.which) ? $event.which : $event.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57))
                $event.preventDefault()
            return true
        },
        submit: function () {
            if (!this.formData.firstname) {
                this.errorMsg.firstname = 'Please input your first name'
                this.onError = true
            }

            if (!this.formData.lastname) {
                this.errorMsg.lastname = 'Please input your last name'
                this.onError = true
            }

            if (!this.formData.phone) {
                this.errorMsg.phone = 'Please input your phone'
                this.onError = true
            }

            if (!this.formData.company) {
                this.errorMsg.company = 'Please input your company'
                this.onError = true
            }

            if (!this.formData.title) {
                this.errorMsg.title = 'Please input your title'
                this.onError = true
            }
            
            if (this.onError) {
                this.$el.scrollTop = 0
                return
            }

            let obj = {
                token: this.token,
                firstName: this.formData.firstname,
                lastName: this.formData.lastname,
                phoneNumber: this.formData.phone,
                company: this.formData.company,
                title: this.formData.title,
                signature: this.formData.signature,
                extraInfo: {}
            }
            this.formLoading = true
            AdminServices.postRobotCheckin(obj).then(resp => {
                if (resp.data.ok) {
                    this.status = 'signed'
                }
                this.formLoading = false
            })

        },
        sourceFileData(data) {
            if (!data) return
            this.formData.signature = data
            this.submit()
        },
        decodeJwt(encoded) {
            try {
                return decode(encoded)
            } catch (e) {
                return {}
            }
        }
    },
    watch: {
        'formData.firstname': function (v) {
            if (v) {
                this.errorMsg.firstname = null
            }
        },
        'formData.lastname': function (v) {
            if (v) {
                this.errorMsg.lastname = null
            }
        },
        'formData.phone': function (v) {
            if (v) {
                this.errorMsg.phone = null
            }
        },
        'formData.company': function (v) {
            if (v) {
                this.errorMsg.company = null
            }
        },
        'formData.title': function (v) {
            if (v) {
                this.errorMsg.title = null
            }
        },
        token: function (v) {
            if (v) {
                if (v) {
                    const decodedToken = this.decodeJwt(v)
                    if (decodedToken.exp) {
                        const expiryDate = new Date(0)
                        expiryDate.setUTCSeconds(decodedToken.exp)
                        
                        this.username = decodedToken.iss
                        if (expiryDate > (new Date())) {
                            AdminServices.getRobotCheckin(this.token).then(resp => {
                                if (resp.data.ok) {
                                    this.status = resp.data.d
                                } else {
                                    this.status = 'invalid_token'
                                }
                            })
                        } else {
                            this.status = 'token_expired'
                        }
                    } else {
                        this.status = 'invalid_token'
                    }
                }
            } else {
                this.status = 'invalid_token'
            }
        }
    },
    created() {
        $('title').text('Sign NDA')
        if (!this.$route.query.token) {
            window.history.back()
        }
        this.token = this.$route.query.token
    }
})