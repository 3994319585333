import appStore from "../../../store/app";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { cloneDeep } from "lodash";
import { defineComponent } from "vue";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
import adminServices from "../../../services/admin";

const newValue = [
  {
    color: "#34435d",
    name: "F1",
    title: "Flagship Fund I",
    vintage: "Vintage: 2017",
    size: "AUM: $162M",
    comps: "# of Portfolio Companies: 33",
  },
  {
    color: "#029988",
    name: "F2",
    title: "Opportunity Pool",
    vintage: "Vintage: 2018",
    size: "AUM: $133M",
    comps: "# SPVs: 36",
  },
  {
    color: "#ff8800",
    name: "F3",
    title: "FinTech Growth Fund",
    vintage: "Vintage: 2020",
    size: "AUM: $100M",
    comps: "# of Portfolio Companies: 5",
  },
  {
    color: "#fb3540",
    name: "F4",
    title: "Flagship Fund II",
    vintage: "Vintage: 2023",
    size: "Target AUM: $200M",
    comps: "Target # of Portfolio Companies: 25",
  },
];

export default defineComponent({
  data() {
    return {
      newValue,
      chartReg: {},
      infoChart: [],
      startup: [],
      //   moic: { value: "0", text: "" },
      //   gross_irr: { value: "0", text: "" },
      //   board_positions: { value: "0" },
      //   regionchart: {},
      //   stagechart: {},
      mapchart_enddate: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getInfoMapchart();
    });
  },
  destroyed() {
    this.chartReg.mapchart.dispose();
    // this.chartReg.regionchart.dispose();
    // this.chartReg.stagechart.dispose();
  },
  methods: {
    async getInfoMapchart() {
      var res = await adminServices.findMapchart();
      if (res && res.data && res.data.ok) {
        this.infoChart = res.data.d;
        if (this.infoChart.length > 0) {
          this.infoChart.forEach((info) => {
            if (info.group_name == "startups") {
              this.startup = JSON.parse(info.param_value);
            }
            // } else if (info.group_name == "moic")
            //   this.moic = JSON.parse(info.param_value);
            // else if (info.group_name == "gross_irr")
            //   this.gross_irr = JSON.parse(info.param_value);
            // else if (info.group_name == "board_positions")
            //   this.board_positions = JSON.parse(info.param_value);
            // else if (info.group_name == "regionchart") this.regionchart = info;
            // else if (info.group_name == "stagechart") this.stagechart = info;
            else if (info.group_name === "mapchart_enddate") {
              this.mapchart_enddate = JSON.parse(info.param_value);
            }
          });
        }
        // this.regionDistribution();
        // this.stageDistribution();
        this.mapChart();
      }
    },
    disposeChartIfExists(chartDiv) {
      if (this.chartReg[chartDiv]) {
        this.chartReg[chartDiv].dispose();
        delete this.chartReg[chartDiv];
      }
    },
    regionDistribution() {
      return;
      //let chartDiv = 'regionchart'
      let chartDiv = this.regionchart.group_name;
      this.disposeChartIfExists(chartDiv);
      this.chartReg[chartDiv] = am4core.create(chartDiv, am4charts.PieChart);
      // this.chartReg[chartDiv].data = [
      //     {
      //         'distribution': 'West U.S.',
      //         'amount': 5
      //     }, {
      //         'distribution': 'East U.S.',
      //         'amount': 52
      //     }, {
      //         'distribution': 'Europe',
      //         'amount': 34
      //     }, {
      //         'distribution': 'LATAM',
      //         'amount': 9
      //     }
      // ]
      this.chartReg[chartDiv].data = JSON.parse(this.regionchart.param_value);
      this.chartReg[chartDiv].innerRadius = am4core.percent(60);

      var label = this.chartReg[chartDiv].seriesContainer.createChild(
        am4core.Label
      );
      label.text = "Regional\nDistribution";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.textAlign = "middle";
      label.color = "white";
      label.fontSize = appStore.state.mobile.isMobile ? 8 : 15;

      let pieSeries = this.chartReg[chartDiv].series.push(
        new am4charts.PieSeries()
      );
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "category";
      pieSeries.dataFields.fontSize = 10;

      pieSeries.labels.template.text = "{category}: {value.value}%";

      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.slices.template.strokeOpacity = 1;

      pieSeries.slices.template.tooltipText = "{category}: {value.value}%";
      pieSeries.slices.template.fontSize = 12;

      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      pieSeries.fontSize = appStore.state.mobile.isMobile ? 8 : 12;
      pieSeries.fixedWidthGrid = true;

      pieSeries.tooltip.fixedWidthGrid = true;
      pieSeries.tooltip.fontSize = 12;
    },
    stageDistribution() {
      return;
      //let chartDiv = 'stagechart'
      let chartDiv = this.stagechart.group_name;
      this.disposeChartIfExists(chartDiv);
      this.chartReg[chartDiv] = am4core.create(chartDiv, am4charts.PieChart);

      // this.chartReg[chartDiv].data = [
      //     {
      //         'Serie': '< Series A',
      //         'amount': 6
      //     },
      //     {
      //         'Serie': 'Series A',
      //         'amount': 36
      //     },
      //     {
      //         'Serie': 'Series B',
      //         'amount': 7
      //     },
      //     {
      //         'Serie': 'Series C',
      //         'amount': 23
      //     },
      //     {
      //         'Serie': 'Series D+',
      //         'amount': 28
      //     }
      // ]
      this.chartReg[chartDiv].data = JSON.parse(this.stagechart.param_value);
      this.chartReg[chartDiv].innerRadius = am4core.percent(60);

      var label = this.chartReg[chartDiv].seriesContainer.createChild(
        am4core.Label
      );
      label.text = "Stage\nDistribution";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.textAlign = "middle";
      label.color = "white";
      label.fontSize = appStore.state.mobile.isMobile ? 8 : 15;

      let pieSeries = this.chartReg[chartDiv].series.push(
        new am4charts.PieSeries()
      );
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "category";
      pieSeries.dataFields.fontSize = 10;

      pieSeries.labels.template.text = "{category}: {value.value}%";

      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.slices.template.strokeOpacity = 1;

      pieSeries.slices.template.tooltipText = "{category}: {value.value}%";
      pieSeries.slices.template.fontSize = 14;

      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      pieSeries.fontSize = appStore.state.mobile.isMobile ? 8 : 13;
      pieSeries.fixedWidthGrid = true;

      pieSeries.tooltip.fixedWidthGrid = true;
      pieSeries.tooltip.fontSize = 14;
    },
    mapChart() {
      let chartDiv = "mapchart";
      this.disposeChartIfExists(chartDiv);
      let sliderAnimation;
      let countryColor = am4core.color("#D6D6D6");
      let countryStrokeColor = am4core.color("#000000");

      // main container
      // https://www.amcharts.com/docs/v4/concepts/svg-engine/containers/
      this.chartReg[chartDiv] = am4core.create(chartDiv, am4core.Container);
      this.chartReg[chartDiv].width = am4core.percent(100);
      this.chartReg[chartDiv].height = am4core.percent(100);

      this.chartReg[chartDiv].tooltip = new am4core.Tooltip();
      this.chartReg[chartDiv].tooltip.background.fill =
        am4core.color("#000000");
      this.chartReg[chartDiv].tooltip.fontSize = "0.9em";
      this.chartReg[chartDiv].tooltip.getFillFromObject = false;
      this.chartReg[chartDiv].tooltip.getStrokeFromObject = false;

      // MAP CHART
      // https://www.amcharts.com/docs/v4/chart-types/map/
      let mapChart = this.chartReg[chartDiv].createChild(am4maps.MapChart);
      mapChart.height = am4core.percent(100);

      // https://www.amcharts.com/docs/v4/chart-types/map/#Map_data
      // you can use more accurate world map or map of any other country - a wide selection of maps available at: https://github.com/amcharts/amcharts4-geodata
      mapChart.geodata = am4geodata_worldLow;

      // let data = [
      //     {
      //         "date": "2017-06-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Taxfyle",
      //         "amount": 3775000
      //     },
      //     {
      //         "date": "2018-03-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Rokk3r",
      //         "amount": 766507
      //     },
      //     {
      //         "date": "2018-05-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "So Stereo",
      //         "amount": 700000
      //     },
      //     {
      //         "date": "2018-07-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Unitea (Joox)",
      //         "amount": 550000
      //     },
      //     {
      //         "date": "2018-07-01",
      //         "location": "Washington, DC",
      //         "latitude": 38.391100,
      //         "longitude": -81.189108,
      //         "name": "Eyrus",
      //         "amount": 1250000
      //     },
      //     {
      //         "date": "2018-07-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Bolt",
      //         "amount": 7250000
      //     },
      //     {
      //         "date": "2019-07-01",
      //         "location": "California",
      //         "latitude": 37.744317,
      //         "longitude": -120.718037,
      //         "name": "Emerge",
      //         "amount": 1000000
      //     },
      //     {
      //         "date": "2019-09-01",
      //         "location": "California",
      //         "latitude": 37.744317,
      //         "longitude": -120.718037,
      //         "name": "OhmniLabs",
      //         "amount": 1000000
      //     },
      //     {
      //         "date": "2019-10-01",
      //         "location": "Stockholm, SE",
      //         "latitude": 59.332082,
      //         "longitude": 18.068164,
      //         "name": "Soundtrack",
      //         "amount": 3000000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "Virginia",
      //         "latitude": 38.901328,
      //         "longitude": -77.038089,
      //         "name": "PredaSAR",
      //         "amount": 5000000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "England",
      //         "latitude": 51.501312,
      //         "longitude": -0.125519,
      //         "name": "Curve",
      //         "amount": 560000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "England",
      //         "latitude": 51.501312,
      //         "longitude": -0.125519,
      //         "name": "Brandhouse",
      //         "amount": 280000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Boatsetter",
      //         "amount": 252000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "England",
      //         "latitude": 51.501312,
      //         "longitude": -0.125519,
      //         "name": "Evrythng",
      //         "amount": 298200
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "New York",
      //         "latitude": 40.729761,
      //         "longitude": -74.041457,
      //         "name": "Cook Unity",
      //         "amount": 196000
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "Brazil",
      //         "latitude": -8.611196,
      //         "longitude": -56.616428,
      //         "name": "Recargapay",
      //         "amount": 175840
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "England",
      //         "latitude": 51.501312,
      //         "longitude": -0.125519,
      //         "name": "Hastee",
      //         "amount": 170800
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "Madrid, ES",
      //         "latitude": 40.4165,
      //         "longitude": -3.70256,
      //         "name": "Bipi",
      //         "amount": 201880
      //     },
      //     {
      //         "date": "2019-12-01",
      //         "location": "California",
      //         "latitude": 37.744317,
      //         "longitude": -120.718037,
      //         "name": "Tradeshift",
      //         "amount": 143046
      //     },
      //     {
      //         "date": "2020-07-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Ubicquia",
      //         "amount": 143046
      //     },
      //     {
      //         "date": "2020-07-01",
      //         "location": "Washington",
      //         "latitude": 47.133037,
      //         "longitude": -119.857230,
      //         "name": "Tomorrow",
      //         "amount": 143046
      //     },
      //     {
      //         "date": "2020-09-01",
      //         "location": "Madrid, ES",
      //         "latitude": 40.4165,
      //         "longitude": -3.70256,
      //         "name": "Osigu",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2020-11-01",
      //         "location": "Bogota, CO",
      //         "latitude": 4.7110,
      //         "longitude": -74.0721,
      //         "name": "Leal",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2020-12-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Axela",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-03-01",
      //         "location": "Bogota, CO",
      //         "latitude": 4.7110,
      //         "longitude": -74.0721,
      //         "name": "Merqueo",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-05-01",
      //         "location": "Copenhagen, DNK",
      //         "latitude": 55.6761,
      //         "longitude": 12.5683,
      //         "name": "Lunar",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-06-01",
      //         "location": "California",
      //         "latitude": 37.744317,
      //         "longitude": -120.718037,
      //         "name": "Securitize",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-06-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Rewardsweb",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-06-01",
      //         "location": "Cordoba, Argentina",
      //         "latitude": -31.4201,
      //         "longitude": -64.1888,
      //         "name": "Ceca",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-06-01",
      //         "location": "Helsinki, Finland",
      //         "latitude": 60.1699,
      //         "longitude": 24.9384,
      //         "name": "Kodit",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-09-01",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Novopayment",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2021-11-22",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Aexlab",
      //         "amount": 0
      //     },
      //     {
      //         "date": "2022-02-03",
      //         "location": "Florida",
      //         "latitude": 25.764842,
      //         "longitude": -80.192307,
      //         "name": "Instabet",
      //         "amount": 0
      //     }
      // ]
      //let mapChartEndDate = '2022-03-31'

      let data = this.startup;
      let mapChartEndDate = this.mapchart_enddate["value"];

      // Accepts the array and key
      const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
          // If an array already present for key, push it to the array. Else create an array and push the object
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
          return result;
        }, {}); // empty object is the initial value for result object
      };

      const sum = (items, prop) => {
        return items.reduce(function (a, b) {
          return a + b[prop];
        }, 0);
      };

      let dateGroup = groupBy(data, "date");
      let locationGroup = groupBy(data, "location");

      var pre_obj = undefined;
      var _data = [];
      for (const [key, value] of Object.entries(dateGroup)) {
        var obj = {};
        obj.date = key;
        obj.number_of_startup =
          value.length + (pre_obj ? pre_obj.number_of_startup : 0);
        obj.total_amount =
          sum(value, "amount") + (pre_obj ? pre_obj.total_amount : 0);
        var list = [];
        for (const [_key, _value] of Object.entries(locationGroup)) {
          var _obj = {};
          _obj.location = _key;
          _obj.latitude = _value[0].latitude;
          _obj.longitude = _value[0].longitude;
          _obj.value =
            _value.filter((item) => item.date == key).length +
            (pre_obj
              ? pre_obj.list.filter((i) => i.location == _key)[0].value
              : 0);
          list.push(_obj);
        }
        obj.list = list;
        _data.push(obj);

        pre_obj = obj;
      }

      if (mapChartEndDate && _data[_data.length - 1].date !== mapChartEndDate) {
        let lastObj = cloneDeep(_data[_data.length - 1]);
        lastObj.date = mapChartEndDate;
        _data.push(lastObj);
      }

      let slideData = _data;

      function getSlideData(index) {
        if (index == undefined) {
          index = slideData.length - 1;
        }
        let data = slideData[index];
        return data;
      }

      var maxValue = 0;
      slideData.forEach((item) => {
        let max = 0;
        item.list.forEach((location) => {
          if (location.value > max) {
            max = location.value;
          }
        });

        if (max > maxValue) {
          maxValue = max;
        }
      });

      var mapData = JSON.parse(JSON.stringify(getSlideData().list));
      // Set projection
      mapChart.projection = new am4maps.projections.Miller();
      mapChart.panBehavior = "move";
      //mapChart.homeZoomLevel = 1.8;
      //mapChart.homeGeoPoint = { longitude: -48, latitude: 25 };

      // Create map polygon series
      var polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
      // polygonSeries.dataFields.id = "name";
      // polygonSeries.dataFields.value = "value";
      // polygonSeries.interpolationDuration = 0;
      polygonSeries.exclude = ["AQ"];
      polygonSeries.useGeodata = true;
      polygonSeries.nonScalingStroke = true;
      polygonSeries.stroke = am4core.color("#000000");
      polygonSeries.strokeWidth = 0.5;
      // this helps to place bubbles in the visual middle of the area
      polygonSeries.calculateVisualCenter = true;
      //polygonSeries.data = mapData;

      // Configure series
      var polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{location}";
      polygonTemplate.fill = countryColor;
      polygonTemplate.fillOpacity = 1;
      polygonTemplate.stroke = countryStrokeColor;
      polygonTemplate.strokeOpacity = 0.15;
      polygonTemplate.setStateOnChildren = true;
      polygonTemplate.tooltipPosition = "fixed";

      // polygonSeries.heatRules.push({
      //     "target": polygonTemplate,
      //     "property": "fill",
      //     "min": am4core.color("#3b3b3b"),
      //     "max": am4core.color("#3b3b3b"),
      //     "dataField": "value"
      //   })

      // Create hover state and set alternative fill color
      //var hs = polygonTemplate.states.create("hover");
      //hs.properties.fill = am4core.color('#ffffff');

      var bubbleSeries = mapChart.series.push(new am4maps.MapImageSeries());
      bubbleSeries.data = mapData;
      bubbleSeries.dataFields.value = "value";
      bubbleSeries.dataFields.id = "location";
      bubbleSeries.mapImages.template.propertyFields.longitude = "longitude";
      bubbleSeries.mapImages.template.propertyFields.latitude = "latitude";

      bubbleSeries.tooltip.animationDuration = 0;
      bubbleSeries.tooltip.showInViewport = false;
      bubbleSeries.tooltip.background.fillOpacity = 0.2;
      bubbleSeries.tooltip.getStrokeFromObject = true;
      bubbleSeries.tooltip.getFillFromObject = false;
      bubbleSeries.tooltip.background.fillOpacity = 0.2;
      bubbleSeries.tooltip.background.fill = am4core.color("#000000");

      var imageTemplate = bubbleSeries.mapImages.template;
      imageTemplate.nonScaling = true;

      var circle = imageTemplate.createChild(am4core.Circle);
      // this makes the circle to pulsate a bit when showing it
      circle.hiddenState.properties.scale = 0.0001;
      circle.hiddenState.transitionDuration = 2000;
      circle.defaultState.transitionDuration = 2000;
      circle.fill = am4core.color("#4397F8");
      circle.defaultState.transitionEasing = am4core.ease.elasticOut;
      // later we set fill color on template (when changing what type of data the map should show) and all the clones get the color because of this
      circle.applyOnClones = true;
      circle.tooltipText = `{location} has {value} startup(s)`;
      bubbleSeries.heatRules.push({
        target: circle,
        property: "radius",
        min: 8,
        max: 16,
        dataField: "value",
        maxValue: maxValue,
      });
      // when data items validated, hide 0 value bubbles (because min size is set)
      bubbleSeries.events.on("dataitemsvalidated", function () {
        bubbleSeries.dataItems.each((dataItem) => {
          let mapImage = dataItem.mapImage;
          let circle = mapImage.children.getIndex(0);
          if (
            mapImage.dataItem.value == 0 ||
            mapImage.dataItem.dataContext.location == ""
          ) {
            circle.hide(0);
          } else if (circle.isHidden || circle.isHiding) {
            circle.show();
          }
        });
      });

      let buttonsAndChartContainer = this.chartReg[chartDiv].createChild(
        am4core.Container
      );
      buttonsAndChartContainer.width = am4core.percent(100);
      buttonsAndChartContainer.layout = "vertical";
      buttonsAndChartContainer.valign = "bottom";

      let nameAndButtonsContainer = buttonsAndChartContainer.createChild(
        am4core.Container
      );
      nameAndButtonsContainer.width = am4core.percent(100);
      nameAndButtonsContainer.layout = "horizontal";

      // name of a country and date label
      let countryName = nameAndButtonsContainer.createChild(am4core.Label);
      countryName.fontSize = "1.1em";
      countryName.paddingBottom = 8;
      countryName.paddingLeft = 2;
      countryName.fill = am4core.color("#ffffff");
      countryName.valign = "middle";

      let buttonsContainer = nameAndButtonsContainer.createChild(
        am4core.Container
      );
      buttonsContainer.layout = "grid";
      buttonsContainer.width = am4core.percent(100);
      buttonsContainer.x = 10;
      buttonsContainer.contentAlign = "right";

      let numberOfStartUpName = buttonsContainer.createChild(am4core.Label);
      numberOfStartUpName.fontSize = "1.1em";
      numberOfStartUpName.paddingBottom = 8;
      numberOfStartUpName.paddingLeft = 2;
      numberOfStartUpName.fill = am4core.color("#ffffff");
      numberOfStartUpName.valign = "middle";
      numberOfStartUpName.x = 10;

      // Chart & slider container
      let chartAndSliderContainer = buttonsAndChartContainer.createChild(
        am4core.Container
      );
      chartAndSliderContainer.layout = "vertical";
      //chartAndSliderContainer.height = am4core.percent(40);
      chartAndSliderContainer.width = am4core.percent(100);
      chartAndSliderContainer.background = new am4core.RoundedRectangle();
      chartAndSliderContainer.background.fill = am4core.color("#000000");
      chartAndSliderContainer.background.cornerRadius(30, 30, 0, 0);
      chartAndSliderContainer.background.fillOpacity = 0.25;
      chartAndSliderContainer.paddingTop = 12;
      chartAndSliderContainer.paddingBottom = 0;
      chartAndSliderContainer.valign = "bottom";

      // Slider container
      let sliderContainer = chartAndSliderContainer.createChild(
        am4core.Container
      );
      sliderContainer.width = am4core.percent(100);
      sliderContainer.padding(0, 15, 15, 10);
      sliderContainer.layout = "horizontal";

      let slider = sliderContainer.createChild(am4core.Slider);
      slider.width = am4core.percent(100);
      slider.valign = "middle";
      slider.background.opacity = 0.4;
      slider.opacity = 0.7;
      slider.background.fill = am4core.color("#ffffff");
      slider.marginLeft = 20;
      slider.marginRight = 35;
      slider.height = 15;
      slider.start = 0;

      // what to do when slider is dragged
      slider.events.on("rangechanged", function (event) {
        let index = Math.round((slideData.length - 1) * slider.start);
        let _data = getSlideData(index);
        updateMapData(_data.list);
        currentDate = _data?.date;
        currentNumber = _data.number_of_startup;

        updateCountryName();

        //updateTotals(index);
      });
      // stop animation if dragged
      slider.startGrip.events.on("drag", () => {
        stop();
        if (sliderAnimation) {
          sliderAnimation.setProgress(slider.start);
        }
      });
      // play button
      let playButton = sliderContainer.createChild(am4core.PlayButton);
      playButton.valign = "middle";
      // play button behavior
      playButton.events.on("toggled", function (event) {
        if (event.target.isActive) {
          play();
        } else {
          stop();
        }
      });
      // make slider grip look like play button
      slider.startGrip.background.fill = playButton.background.fill;
      slider.startGrip.background.strokeOpacity = 0;
      slider.startGrip.icon.stroke = am4core.color("#ffffff");
      slider.startGrip.background.states.copyFrom(playButton.background.states);

      // play behavior
      function play() {
        if (!sliderAnimation) {
          sliderAnimation = slider
            .animate(
              { property: "start", to: 1, from: 0 },
              15000,
              am4core.ease.linear
            )
            .pause();
          sliderAnimation.events.on("animationended", () => {
            playButton.isActive = false;
          });
        }

        if (slider.start >= 1) {
          slider.start = 0;
          sliderAnimation.start();
        }
        sliderAnimation.resume();
        playButton.isActive = true;
      }

      // stop behavior
      function stop() {
        if (sliderAnimation) {
          sliderAnimation.pause();
        }
        playButton.isActive = false;
      }

      function getObjectByName(list, location) {
        for (var i = 0; i < list.length; i++) {
          if (list.getIndex(i).dataItem.dataContext.location == location) {
            return list.getIndex(i);
          }
        }
      }

      // update map data
      var updateMapData = (data) => {
        for (var i = 0; i < data.length; i++) {
          let di = data[i];
          let image = getObjectByName(bubbleSeries.mapImages, di.location);
          if (image) {
            image.dataItem.dataContext.value = di.value;
          }
        }

        bubbleSeries.invalidateRawData();
      };

      setTimeout(play, 2000);

      let lastDate = new Date(slideData[slideData.length - 1].date);
      let currentDate = lastDate;
      let currentNumber = slideData[slideData.length - 1].number_of_startup;
      let backgroundColor = am4core.color("#1e2128");
      let activeColor = am4core.color("#ff8726");
      let confirmedColor = am4core.color("#d21a1a");
      let currentIndex;

      // updates country name and date
      function updateCountryName() {
        countryName.text = mapChart.dateFormatter.format(
          currentDate,
          "MMM dd, yyyy"
        );
        numberOfStartUpName.text =
          currentNumber + " startup" + (currentNumber > 1 ? "s" : "");
      }
      updateCountryName();
      /*
            // BOTTOM CHART
            // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
            let lineChart = chartAndSliderContainer.createChild(am4charts.XYChart);
            lineChart.fontSize = "0.8em";
            lineChart.paddingRight = 30;
            lineChart.paddingLeft = 30;
            lineChart.maskBullets = false;
            lineChart.zoomOutButton.disabled = true;
            lineChart.paddingBottom = 5;
            lineChart.paddingTop = 3;

            // make a copy of data as we will be modifying it
            lineChart.data = JSON.parse(JSON.stringify(slideData));

            // date axis
            // https://www.amcharts.com/docs/v4/concepts/axes/date-axis/
            let dateAxis = lineChart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 50;
            dateAxis.renderer.grid.template.stroke = am4core.color("#000000");
            dateAxis.renderer.grid.template.strokeOpacity = 0.25;
            dateAxis.max = lastDate.getTime() + am4core.time.getDuration("day", 5);
            dateAxis.tooltip.label.fontSize = "0.8em";
            dateAxis.tooltip.background.fill = activeColor;
            dateAxis.tooltip.background.stroke = activeColor;
            dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");

            // value axis
            // https://www.amcharts.com/docs/v4/concepts/axes/value-axis/
            let valueAxis = lineChart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.opposite = true;
            valueAxis.interpolationDuration = 3000;
            valueAxis.renderer.grid.template.stroke = am4core.color("#000000");
            valueAxis.renderer.grid.template.strokeOpacity = 0.25;
            valueAxis.renderer.minGridDistance = 30;
            valueAxis.renderer.maxLabelPosition = 0.98;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.tooltip.disabled = true;
            valueAxis.extraMax = 0.05;
            valueAxis.maxPrecision = 0;
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.verticalCenter = "bottom";
            valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
            valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
            valueAxis.adapter.add("max", function (max, target) {
                if (max < 5) {
                    max = 5
                }
                return max;
            })

            valueAxis.adapter.add("min", function (min, target) {
                return min;
            })

            // cursor
            // https://www.amcharts.com/docs/v4/concepts/chart-cursor/
            lineChart.cursor = new am4charts.XYCursor();
            lineChart.cursor.maxTooltipDistance = 0;
            lineChart.cursor.behavior = "none"; // set zoomX for a zooming possibility
            lineChart.cursor.lineY.disabled = true;
            lineChart.cursor.lineX.stroke = activeColor;
            lineChart.cursor.xAxis = dateAxis;
            // this prevents cursor to move to the clicked location while map is dragged
            am4core.getInteraction().body.events.off("down", lineChart.cursor.handleCursorDown, lineChart.cursor)
            am4core.getInteraction().body.events.off("up", lineChart.cursor.handleCursorUp, lineChart.cursor)

            // legend
            // https://www.amcharts.com/docs/v4/concepts/legend/  
            lineChart.legend = new am4charts.Legend();
            lineChart.legend.parent = lineChart.plotContainer;
            lineChart.legend.labels.template.fill = am4core.color("#ffffff");
            lineChart.legend.markers.template.height = 8;
            lineChart.legend.contentAlign = "left";
            lineChart.legend.fontSize = "10px";
            lineChart.legend.itemContainers.template.valign = "middle";
            let legendDown = false;
            lineChart.legend.itemContainers.template.events.on("down", function () {
                legendDown = true;
            })
            lineChart.legend.itemContainers.template.events.on("up", function () {
                setTimeout(function () {
                    legendDown = false;
                }, 100)
            })

            // create series
            let activeSeries = addSeries("total_amount", activeColor, true);
            // active series is visible initially
            activeSeries.tooltip.disabled = true;
            activeSeries.hidden = false;
            let confirmedSeries = addSeries("number_of_startup", confirmedColor, false);

            let series = { active: activeSeries, confirmed: confirmedSeries };
            // add series
            function addSeries(name, color, opposite) {
                var valueAxis = lineChart.yAxes.push(new am4charts.ValueAxis());
                if(lineChart.yAxes.indexOf(valueAxis) != 0){
                    valueAxis.syncWithAxis = lineChart.yAxes.getIndex(0);
                }
                valueAxis.renderer.opposite = opposite;
                valueAxis.renderer.labels.template.fill = color;
                valueAxis.renderer.line.strokeOpacity = 1;
                valueAxis.renderer.line.strokeWidth = 2;

                let series = lineChart.series.push(new am4charts.LineSeries())
                series.dataFields.valueY = name;
                series.dataFields.dateX = "date";
                series.name = name == 'total_amount' ? 'Fuel Investment' : 'Number of Startups';
                series.yAxis = valueAxis;
                series.strokeOpacity = 0.6;
                series.stroke = color;
                series.fill = color;
                series.maskBullets = false;
                series.minBulletDistance = 10;
                series.hidden = true;
                series.hideTooltipWhileZooming = true;

                // series bullet
                let bullet = series.bullets.push(new am4charts.CircleBullet());

                // only needed to pass it to circle
                let bulletHoverState = bullet.states.create("hover");
                bullet.setStateOnChildren = true;

                bullet.circle.fillOpacity = 1;
                bullet.circle.fill = backgroundColor;
                bullet.circle.radius = 2;

                let circleHoverState = bullet.circle.states.create("hover");
                circleHoverState.properties.fillOpacity = 1;
                circleHoverState.properties.fill = color;
                circleHoverState.properties.scale = 1.4;

                // tooltip setup
                series.tooltip.pointerOrientation = "down";
                series.tooltip.getStrokeFromObject = true;
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.fillOpacity = 0.2;
                series.tooltip.background.fill = am4core.color("#000000");
                series.tooltip.dy = -4;
                series.tooltip.fontSize = "0.8em";
                series.tooltipText = "{name}: " + (name == 'total_amount' ? '$' : '') + "{valueY}";

                return series;
            }

            lineChart.plotContainer.events.on("up", function () {
                if (!legendDown) {
                    slider.start = lineChart.cursor.xPosition * ((dateAxis.max - dateAxis.min) / (lastDate.getTime() - dateAxis.min));
                }
            })

            setTimeout(updateSeriesTooltip, 3000);

            function updateSeriesTooltip() {

                let position = dateAxis.dateToPosition(currentDate);
                position = dateAxis.toGlobalPosition(position);
                let x = dateAxis.positionToCoordinate(position);

                lineChart.cursor.triggerMove({ x: x, y: 0 }, "soft", true);
                lineChart.series.each(function (series) {
                    if (!series.isHidden) {
                        series.tooltip.disabled = false;
                        series.showTooltipAtDataItem(series.tooltipDataItem);
                    }
                })
            }

            // update total values in buttons
            function updateTotals(index) {
                if (!isNaN(index)) {
                    let di = slideData[index];
                    let date = new Date(di.date);
                    currentDate = date;

                    let position = dateAxis.dateToPosition(date);
                    position = dateAxis.toGlobalPosition(position);
                    let x = dateAxis.positionToCoordinate(position);

                    if (lineChart.cursor) {
                        lineChart.cursor.triggerMove({ x: x, y: 0 }, "soft", true);
                    }

                    currentIndex = index;
                }
            }
            */
    },
  },
});
