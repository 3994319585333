<template>
  <div>
    <div class="mb-3">
      <div class="reviewSurvey">
        <div class="data p-3">
          <div class="row m-0">
            <div class="col-12">
              <div class="table-responsive-sm">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Survey</th>
                      <th>Founder</th>
                      <th>Score</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                      <td>
                        {{ $filters.parseEstTime(item.created_at) }}
                      </td>
                      <td>
                        <router-link
                          class="pointer link"
                          :to="{
                            path: reviewSurveyDetailPath,
                            name: reviewSurveyDetailName,
                            params: { surveyID: item.id },
                          }"
                          >{{ item.name }}</router-link
                        >
                      </td>
                      <td>{{ item.username }}</td>
                      <td>
                        {{ item.score }}
                      </td>
                      <td>
                        <a
                          class="btn btn-default"
                          @click="approvedSurvey(item.id)"
                          ><i class="fas fa-check"></i
                        ></a>
                        <a
                          class="btn btn-default"
                          @click="rejectSurvey(item.id)"
                          ><i class="fas fa-times"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <pager
                          :loadContentAtPage="loadPage"
                          :page="page.page"
                          :size="page.size"
                          :total="page.total"
                          :pagerFull="false"
                        ></pager>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <detaiSurvey
      v-if="isShowDetailSurvey"
      :surveyDetailID="surveyDetailID"
      :hideSurveyDetail="hideSurveyDetail"
      :approvedSurvey="approvedSurvey"
      :rejectSurvey="rejectSurvey"
    />
  </div>
</template>

<script src="./index.js"></script>
