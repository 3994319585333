import authHttp, { API_ROOT } from "../services/auth-http";

export const DealServices = {
  addUrlParameter(paramName, data, existingParam = "") {
    if (data !== undefined) {
      if (existingParam === "") {
        existingParam = `${existingParam}?${paramName}=${data}`;
      } else {
        existingParam = `${existingParam}&${paramName}=${data}`;
      }
    }
    return existingParam;
  },
  getDeals() {
    return authHttp.get(`${API_ROOT}/deals`);
  },
  getDealHistory(id) {
    return authHttp.get(`${API_ROOT}/deals/${id}/histories`);
  },
  getSpecifyDeals(id) {
    return authHttp.get(`${API_ROOT}/deals/${id}`);
  },
  getDealRate(id) {
    return authHttp.get(`${API_ROOT}/deals/${id}/rating`);
  },
  updateDealRate(id, rate, stageId) {
    return authHttp.put(
      `${API_ROOT}/deals/${id}/rating/${rate}?stageId=${stageId}`
    );
  },
  getDealMessage(id) {
    return authHttp.get(`${API_ROOT}/deals/${id}/messages`);
  },
  submitDealmessage(id, obj) {
    return authHttp.post(`${API_ROOT}/deals/${id}/messages`, {
      stage_id: obj.stage_id,
      message: obj.message,
      notify: obj.notify,
    });
  },
  getDealDocument(id) {
    return authHttp.get(`${API_ROOT}/deals/${id}/documents`);
  },
  getDealDocumentFile(id, docId) {
    return authHttp.get(`${API_ROOT}/deals/${id}/documents/${docId}`);
  },
  submitDealDocument(id, obj) {
    return authHttp.post(`${API_ROOT}/deals/${id}/documents`, obj);
  },
  removeDealDocument(id, docId) {
    return authHttp.delete(`${API_ROOT}/deals/${id}/documents/${docId}`);
  },
  approveStage(id, stageId) {
    return authHttp.put(`${API_ROOT}/deals/${id}/stage/${stageId}/approve`);
  },
  rejectStage(id, stageId) {
    return authHttp.put(`${API_ROOT}/deals/${id}/stage/${stageId}/reject`);
  },
  holdStage(id, stageId, datetime) {
    return authHttp.put(
      `${API_ROOT}/deals/${id}/stage/${stageId}/on-hold/${datetime}`
    );
  },
  getReviewer(stage = "STAGE_1") {
    return authHttp.get(
      `${API_ROOT}/deals/stage/reviewers/getall?stage=${stage}`
    );
  },
  getSubmissionQuestions() {
    return authHttp.get(`${API_ROOT}/deals/submission/questions`);
  },
  getSubmissionQuestionsByDealId(dealId) {
    return authHttp.get(`${API_ROOT}/deals/${dealId}/submission/questions`);
  },
  createDeal(data) {
    return authHttp.post(`${API_ROOT}/deals`, data);
  },
  getReviewQuestions() {
    return authHttp.get(`${API_ROOT}/deals/review/questions`);
  },
  submitReviewQuestions(id, data, IsSubmit) {
    let param = this.addUrlParameter("isSubmit", IsSubmit);
    return authHttp.post(`${API_ROOT}/deals/${id}/reviews${param}`, data);
  },
  reviewQuestions(id, userId) {
    return authHttp.get(`${API_ROOT}/deals/${id}/reviews/user/${userId}`);
  },
  updateDeal(data, dealId) {
    return authHttp.put(`${API_ROOT}/deals/${dealId}`, data);
  },
  removeDeal(id) {
    return authHttp.delete(`${API_ROOT}/deals/${id}`);
  },
  waitingDeal(id, time) {
    let params = {
      dealId: id,
      waitingDate: time,
    };
    return authHttp.put(
      `${API_ROOT}/deals/${id}/waiting?${authHttp.buildParams(params)}`
    );
  },
};
export default DealServices;
