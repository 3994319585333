import _ from "lodash";
import Dragger from "@/components/Dragger";
import appStore, { APP_ACTIONS } from "@/store/app";
import AdminServices from "@/services/admin";
import notify from "@/services/notify";
import treeItem from "@/components/commons/treeItem";
import modalConfirm from "@/components/commons/modalConfirm"; // #todo consider use component dialog
import filePreview, { FileTypes } from "@/components/commons/filePreview";
import downloadItem from "@/components/commons/downloadItem";
import addFolder from "./addFolder";
import renameItem from "./renameItem";
import uploadFile from "./uploadFile";
import uploadFileV2 from "./uploadFileV2";
import shareItem from "./shareItem";
import shareUnauthenticatedItem from "./shareUnauthenticatedItem";
import manageVersions from "./manageVersions";
import getLink from "./getLink";
import uploadFolder from "./uploadFolder";
import searchItem from "./searchItem";
import moveItems from "./moveItems";
import copyItems from "./copyItems";
import trashFileItems from "./trashFileItems";
import restoreFileItems from "./restoreFileItems";
import deleteFileItems from "./deleteFileItems";
import addEditBookmark from "./addEditBookmark";
import viewSharedItems from "./viewSharedItems";
import Dialog from "@/components/Dialog";
import { generic } from "@/misc/generic";
import { defineComponent } from "vue";
import { swapObject } from "@/misc/commons";

export default defineComponent({
  components: {
    treeItem,
    addFolder,
    renameItem,
    uploadFile,
    uploadFileV2,
    shareItem,
    shareUnauthenticatedItem,
    modalConfirm,
    filePreview,
    Dragger,
    manageVersions,
    getLink,
    uploadFolder,
    searchItem,
    moveItems,
    copyItems,
    downloadItem,
    trashFileItems,
    restoreFileItems,
    deleteFileItems,
    addEditBookmark,
    viewSharedItems,
    Dialog,
  },
  data() {
    return {
      loading: true,
      selectedFolder: null,
      selectedChildrenItems: [],
      menuOptions: [],
      parentPath: [],
      //rawTree: [],
      rawFolders: [],
      selectedParentFolderId: null,
      isShowAddFolder: false,
      selectedItem: null,
      isShowRenameItem: false,
      isShowUploadFile: false,
      isShowUploadFileV2: false,
      isShowShareItem: false,
      isShowShareUnauthenticatedItem: false,
      isShowModalConfirm: false,
      modalTitle: "",
      modalMessage: "",
      delay: 300,
      clicks: 0,
      timer: null,
      fileToken: null,
      previewFileType: "",
      isShowManageVersions: false,
      isShowDownloadItem: false,
      allowViewFileExtensions: [
        ".pdf",
        ".mp4",
        ".gif",
        ".jpg",
        ".jpeg",
        ".png",
      ],
      rootDataroomItem: {
        id: 0,
        name: "FVC Dataroom",
        type: "Folder",
      },
      rootTrashDataroomFolder: {
        id: -1,
        name: "Trash",
        type: "Folder",
      },
      isShowGetLink: false,
      contextMenuComponentKey: 0,
      showDropdownMenu: false,
      isShowUploadFolder: false,
      searchKey: "",
      searchItemComponentKey: 0,
      isShowSearchItem: false,
      isShowMoveItems: false,
      itemsToBeMoved: [],
      isShowCopyItems: false,
      itemsToBeCopied: [],
      trashedItems: [],
      isShowTrashFileItems: false,
      itemsToBeTrashed: [],
      trashedItemsToBeRestored: [],
      isShowRestoreFileItems: false,
      trashedItemsToBeDeleted: [],
      isShowDeleteFileItems: false,
      isShowAddEditBookmark: false,
      isShowViewSharedItems: false,
    };
  },
  computed: {
    treeData() {
      return this.unflatten(this.rawFolders);
    },
    selectedFolderId() {
      return this.selectedFolder ? this.selectedFolder.id : null;
    },
    selectedParentId() {
      return this.selectedFolder ? this.selectedFolder.parent_id : null;
    },
    disabledMultipleAction() {
      return this.selectedChildrenItems.filter((x) => x.is_selected).length > 0
        ? false
        : true;
    },
    isTrash() {
      return this.selectedFolderId === -1 ? true : false;
    },
    disabledTrashAction() {
      let _selectedItems = this.selectedChildrenItems.filter(
        (x) => x.is_selected
      );
      if (_selectedItems.length > 0) {
        return _selectedItems.filter(
          (x) => x.type !== "File" || !x.allow_delete
        ).length > 0
          ? true
          : false;
      } else {
        return true;
      }
    },
    disabledRestoreAction() {
      let _selectedItemNames = this.trashedItems
        .filter((x) => x.is_selected)
        .map((y) => y.name);
      if (_selectedItemNames.length > 0) {
        let intersections = _selectedItemNames.filter(
          (e, index) => _selectedItemNames.indexOf(e) != index
        );
        if (intersections && intersections.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    disabledDeleteFilesAction() {
      return this.trashedItems.filter((x) => x.is_selected).length > 0
        ? false
        : true;
    },
  },
  created() {
    if (appStore.state.isShowAdminSidebar) {
      appStore.dispatch(APP_ACTIONS.toggle_admin_sidebar);
    }
    this.initialLoading();
  },
  beforeUnmount() {
    if (!appStore.state.isShowAdminSidebar) {
      appStore.dispatch(APP_ACTIONS.toggle_admin_sidebar);
    }
  },
  mounted() {
    //let currentFolderId = generic.getJsonLocalStorage("currentFolderId");
    //if (currentFolderId != undefined && currentFolderId != "") {
    //  this.getCurrentTreeView(currentFolderId);
    //}
  },
  methods: {
    getCurrentTreeView(folderId) {
      return new Promise((resolve) => {
        AdminServices.getSnapshotOfDataroomTreeView(folderId).then((resp) => {
          if (resp.data.ok) {
            let _resp = resp.data.d;
            this.rawFolders = _resp.folders;
            this.selectedFolder = _resp.current_folder;
            this.selectedChildrenItems = this.selectedFolder.children.map(
              (x) => {
                return {
                  ...x,
                  is_selected: false,
                };
              }
            );
          } else {
            this.rawFolders = [this.rootDataroomItem];
            this.selectedFolder = this.rootDataroomItem;
            this.selectedChildrenItems = [];
          }
          this.parentPath = this.findAncestors(
            this.selectedFolder,
            this.rawFolders
          );
          generic.setJsonLocalStorage("currentFolderId", folderId);
          resolve(true);
        });
      });
    },
    loadTrash() {
      this.selectedFolder = this.rootTrashDataroomFolder;
      this.loading = true;
      AdminServices.getTrashedDataroomFileItems()
        .then((resp) => {
          if (resp.data.ok) {
            this.trashedItems = resp.data.d;
          }
        })
        .finally(() => (this.loading = false));
    },
    onSort() {
      const tmpData = _.cloneDeep(this.selectedChildrenItems);
      tmpData.map((x, idx) => {
        x.orderNumber = idx;
        return x;
      });
      AdminServices.sortDataRoomItems(tmpData).then((resp) => {
        if (resp.data.ok) {
          this.refreshData();
        }
      });
    },
    initialLoading() {
      this.loading = true;
      let currentFolderId = generic.getJsonLocalStorage("currentFolderId");
      if (currentFolderId != undefined && currentFolderId != "") {
        this.getCurrentTreeView(currentFolderId).finally(() => {
          this.loading = false;
        });
      } else {
        this.getCurrentTreeView(this.rootDataroomItem.id).finally(() => {
          this.loading = false;
        });
      }
    },

    refreshData() {
      this.loading = true;
      this.getCurrentTreeView(
        this.selectedFolder ? this.selectedFolder.id : 0
      ).finally(() => {
        this.loading = false;
      });
    },

    selectFolder(item) {
      this.selectedFolder = item;
      this.refreshData();
    },

    unflatten(rawArr) {
      let arr = rawArr.filter((x) => x.type === "Folder");
      let tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

      // First map the nodes of the array to an object -> create a hash table.
      for (let i = 0; i < arr.length; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id]["children"] = [];
      }

      for (let id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
          mappedElem = mappedArr[id];
          // If the element is not at the root level, add it to its parent array of children.
          if (mappedElem.parent_id) {
            mappedArr[mappedElem["parent_id"]]["children"].push(mappedElem);
          }
          // If the element is at the root level, add it to first level elements array.
          else {
            tree.push(mappedElem);
          }
        }
      }
      return {
        ...this.rootDataroomItem,
        children: tree,
      };
    },

    findAncestors(item, rawArr) {
      if (item.id === 0) {
        return [this.rootDataroomItem];
      }
      let ancestors = [];
      let parentId = item.parent_id;
      ancestors.unshift(item);
      while (!!parentId) {
        ancestors.unshift(rawArr.find((x) => x.id === parentId));
        let _next_item = rawArr.find((x) => x.id === parentId);
        parentId = _next_item ? _next_item.parent_id : undefined;
      }
      ancestors.unshift(this.rootDataroomItem);
      return ancestors;
    },

    handleClick(event, item) {
      if (item.type === "Folder") {
        if (item.id === 0) {
          this.menuOptions = [
            { name: "New folder" },
            { name: "New bookmark" },
            { name: "Upload file" },
            { name: "Upload folder" },
          ];
        } else {
          this.menuOptions = [
            { name: "New folder" },
            { name: "New bookmark" },
            { name: "Upload file" },
            { name: "Upload folder" },
            { name: "Rename" },
          ];
          if (!item.is_public && !item.is_unauthenticated) {
            this.menuOptions.push({
              name: "Share",
              icon: "far fa-share-square",
            });
          }
          if (item.allow_delete) {
            this.menuOptions.push(
              { name: "Move to" },
              { name: "Copy to" },
              { name: "Remove" }
            );
          }
        }
      } else if (item.type === "File") {
        //const _fileItem = this.rawTree.find(x => x.id === item.id)
        if (this.allowViewFileExtensions.includes(item.current_extension)) {
          this.menuOptions = [
            { name: "Rename" },
            { name: "View" },
            { name: "Download" },
            { name: "Manage versions" },
            { name: "Get link" },
          ];
        } else {
          this.menuOptions = [
            { name: "Rename" },
            { name: "Download" },
            { name: "Manage versions" },
            { name: "Get link" },
          ];
        }
        if (item.current_extension === '.pdf' && !item.is_watermarked) {
          this.menuOptions.push(
              { name: 'Add watermark' },
          )
        }
        if (!item.is_public) {
          this.menuOptions.push({ name: "Share", icon: "far fa-share-square" });
        }
        if (item.allow_delete) {
          this.menuOptions.push(
            { name: "Move to" },
            { name: "Copy to" },
            { name: "Move to trash" }
          );
        }
      } else if (item.type === "Bookmark") {
        this.menuOptions = [{ name: "Edit bookmark" }, { name: "View" }];
        if (!item.is_public && !item.is_unauthenticated) {
          this.menuOptions.push({ name: "Share", icon: "far fa-share-square" });
        }
        if (item.allow_delete) {
          this.menuOptions.push(
            { name: "Move to" },
            { name: "Copy to" },
            { name: "Remove" }
          );
        }
      }
      this.contextMenuComponentKey++;
      this.$nextTick(() => {
        this.$refs.dataRoomMenu.showMenu(event, {
          id: item.id,
          type: item.type,
        });
      });
    },
    optionClicked(ev) {
      let { item, option } = ev;
      if (item.type === "Folder") {
        if (option.name === "New folder") {
          this.openDialogToAddFolder(item.id);
        }
        // else if (option.name === 'Upload file V1') {
        //     this.openDialogToUploadFile(item.id)
        // }
        else if (option.name === "Upload file") {
          this.openDialogToUploadFileV2(item.id);
        } else if (option.name === "Upload folder") {
          this.openDialogToUploadFolder(item.id);
        } else if (option.name === "Rename") {
          this.openDialogToRenameItem(item.id);
        } else if (option.name === "Share") {
          this.openDialogToShareItem(item.id);
        } else if (option.name === "Remove") {
          this.openModalConfirmToRemove(item.id);
        } else if (option.name === "Move to") {
          this.openDialogToMoveItems(this.selectedFolderId, item.id);
        } else if (option.name === "Copy to") {
          this.openDialogToCopyItems(this.selectedFolderId, item.id);
        } else if (option.name === "New bookmark") {
          this.openDialogToAddEditBookmark(item.id);
        }
      } else if (item.type === "File") {
        if (option.name === "Rename") {
          this.openDialogToRenameItem(item.id);
        } else if (option.name === "Share") {
          const _fileItem = this.selectedChildrenItems.find(
            (x) => x.id === item.id
          );
          if (_fileItem.is_unauthenticated) {
            this.openDialogToShareUnauthenItem(item.id);
          } else {
            this.openDialogToShareItem(item.id);
          }
        } else if (option.name === "Download") {
          this.downloadFile(item);
        } else if (option.name === "View") {
          this.viewFile(item);
        } else if (option.name === "Manage versions") {
          this.openDialogToManageVersions(item.id);
        } else if (option.name === "Get link") {
          this.openDialogToGetLink(item.id);
        } else if (option.name === "Move to") {
          this.openDialogToMoveItems(this.selectedFolderId, item.id);
        } else if (option.name === "Copy to") {
          this.openDialogToCopyItems(this.selectedFolderId, item.id);
        } else if (option.name === "Move to trash") {
          this.openDialogToTrashFileItems(item.id);
        } else if (option.name === 'Add watermark') {
          this.openModalConfirmToAddWatermark(item);
        }
      } else if (item.type === "Bookmark") {
        if (option.name === "Remove") {
          this.openModalConfirmToRemove(item.id);
        } else if (option.name === "Share") {
          this.openDialogToShareItem(item.id);
        } else if (option.name === "Move to") {
          this.openDialogToMoveItems(this.selectedFolderId, item.id);
        } else if (option.name === "Copy to") {
          this.openDialogToCopyItems(this.selectedFolderId, item.id);
        } else if (option.name === "Edit bookmark") {
          this.openDialogToAddEditBookmark(this.selectedFolderId, item.id);
        } else if (option.name === "View") {
          this.openBookmarkUrl(item);
        }
      }
    },

    openDialogToAddFolder(parentId) {
      this.selectedParentFolderId = parentId;
      this.isShowAddFolder = true;
    },
    hideAddFolder() {
      this.isShowAddFolder = false;
      this.selectedParentFolderId = null;
    },
    handleAddFolderComplete() {
      this.hideAddFolder();
      notify.success("Add folder success!");
      this.loading = true;
      this.refreshData();
    },

    openDialogToRenameItem(id) {
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      if (this.selectedItem) {
        this.isShowRenameItem = true;
      }
    },
    hideRenameItem() {
      this.isShowRenameItem = false;
      this.selectedItem = null;
    },
    handleRenameItemComplete(item) {
      this.parentPath.forEach((x) => {
        if (x.id === item.id) {
          x.name = item.name;
        }
      });
      this.hideRenameItem();
      notify.success("Rename success!");
      this.loading = true;
      this.refreshData();
    },

    // openDialogToUploadFile(parentId) {
    //     this.selectedParentFolderId = parentId
    //     this.isShowUploadFile = true
    // },
    // hideUploadFile() {
    //     this.isShowUploadFile = false
    //     this.selectedParentFolderId = null
    //     this.loading = true
    //     this.refreshData()
    // },

    openDialogToUploadFileV2(parentId) {
      this.selectedParentFolderId = parentId;
      this.isShowUploadFileV2 = true;
    },
    hideUploadFileV2() {
      this.isShowUploadFileV2 = false;
      this.selectedParentFolderId = null;
      this.loading = true;
      this.refreshData();
    },

    openDialogToUploadFolder(parentId) {
      this.selectedParentFolderId = parentId;
      this.isShowUploadFolder = true;
    },
    hideUploadFolder() {
      this.isShowUploadFolder = false;
      this.selectedParentFolderId = null;
      this.loading = true;
      this.refreshData();
    },

    openDialogToManageVersions(id) {
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      this.isShowManageVersions = true;
    },
    hideManageVersions() {
      this.isShowManageVersions = false;
      this.selectedItem = null;
      this.loading = true;
      this.refreshData();
    },

    openDialogToShareItem(id) {
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      if (this.selectedItem) {
        this.isShowShareItem = true;
      }
    },
    hideShareItem() {
      this.isShowShareItem = false;
      this.selectedItem = null;
    },
    handleShareItemComplete() {
      this.hideShareItem();
      notify.success("Share success!");
      this.loading = true;
      this.refreshData();
    },

    openDialogToShareUnauthenItem(id) {
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      if (this.selectedItem) {
        this.isShowShareUnauthenticatedItem = true;
      }
    },
    hideShareUnauthenItem() {
      this.isShowShareUnauthenticatedItem = false;
      this.selectedItem = null;
    },
    handleShareUnauthenItemComplete() {
      this.hideShareUnauthenItem();
      notify.success("Share success!");
      this.loading = true;
      this.refreshData();
    },

    openModalConfirmToRemove(id) {
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      let message = {
        title: "Remove Dataroom Item",
        body: `Press button confirm to remove ${this.selectedItem.type} ${this.selectedItem.name}`,
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.onConfirmToRemove();
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    closeModalConfirmToRemove() {
      this.isShowModalConfirm = false;
      this.modalTitle = null;
      this.modalMessage = null;
      this.selectedItem = null;
    },
    onConfirmToRemove() {
      let _item = Object.assign({}, this.selectedItem);
      this.closeModalConfirmToRemove();
      this.loading = true;
      if (_item.type === "Folder") {
        AdminServices.deleteDataroomFolderItem(_item.id)
          .then((resp) => {
            if (resp.data.ok) {
              notify.success("Remove folder success!");
              this.refreshData();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (_item.type === "Bookmark") {
        AdminServices.deleteDataroomBookmarkItem(_item.id)
          .then((resp) => {
            if (resp.data.ok) {
              notify.success("Remove bookmark success!");
              this.refreshData();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },

    oneClick(item, isTrash = false) {
      this.clicks++;
      if (this.clicks === 1) {
        let self = this;
        this.timer = setTimeout(() => {
          self.clicks = 0;
        }, this.delay);
        this.toggleSelection(item, isTrash);
      } else {
        clearTimeout(this.timer);
        this.toggleSelection(item, isTrash);
        if (isTrash) {
          if (item.type === "File") {
            this.processPreviewFile(item);
          }
        } else {
          if (item.type === "Folder") {
            this.selectFolder(item);
          } else if (item.type === "File") {
            this.processPreviewFile(item);
          } else if (item.type === "Bookmark") {
            this.openBookmarkUrl(item);
          }
        }
        this.clicks = 0;
      }
    },

    toggleSelection(item, isTrash) {
      if (isTrash) {
        const _index = this.trashedItems.findIndex((x) => x.id === item.id);
        this.trashedItems[_index].is_selected =
          !this.trashedItems[_index].is_selected;
        this.trashedItems.splice(); //reload trashedArray
      } else {
        if (item.allow_delete) {
          const _index = this.selectedChildrenItems.findIndex(
            (x) => x.id === item.id
          );
          this.selectedChildrenItems[_index].is_selected =
            !this.selectedChildrenItems[_index].is_selected;
        }
      }
    },

    processPreviewFile(item) {
      let _action = "Download";
      if (this.allowViewFileExtensions.includes(item.current_extension)) {
        _action = "View";
      }
      this.loading = true;
      AdminServices.requestDownloadCIO(item.id, _action)
        .then((resp) => {
          if (resp.data.ok) {
            this.fileToken = resp.data.d;
            if (item.current_extension === ".pdf") {
              this.showFilePreview(FileTypes.PDF);
            } else if (item.current_extension === ".mp4") {
              this.showFilePreview(FileTypes.VIDEO);
            } else if (
              this.allowViewFileExtensions.includes(item.current_extension)
            ) {
              this.showFilePreview(FileTypes.IMAGE);
            } else {
              this.showDownloadItem();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadFile(item) {
      if (item.type === "File") {
        this.loading = true;
        AdminServices.requestDownloadCIO(item.id, "Download")
          .then((resp) => {
            if (resp.data.ok) {
              window.open(resp.data.d, "_blank");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    viewFile(item) {
      const _fileItem = this.selectedChildrenItems.find(
        (x) => x.id === item.id
      );
      if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
        this.loading = true;
        AdminServices.requestDownloadCIO(item.id, "View")
          .then((resp) => {
            if (resp.data.ok) {
              this.fileToken = resp.data.d;
              if (_fileItem.current_extension === ".pdf") {
                this.showFilePreview(FileTypes.PDF);
              } else if (_fileItem.current_extension === ".mp4") {
                this.showFilePreview(FileTypes.VIDEO);
              } else {
                this.showFilePreview(FileTypes.IMAGE);
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    showDownloadItem() {
      this.isShowDownloadItem = true;
    },
    hideDownloadItem() {
      this.isShowDownloadItem = false;
      this.fileToken = null;
    },

    showFilePreview(previewFileType) {
      this.fixedBody();
      this.previewFileType = previewFileType;
      this.$refs.filePreview.showModal();
    },

    openDialogToGetLink(id) {
      //this.selectedItem = this.rawTree.find(x => x.id === id)
      this.selectedItem = this.selectedChildrenItems.find((x) => x.id === id);
      if (this.selectedItem) {
        this.isShowGetLink = true;
      }
    },
    hideGetLink() {
      this.isShowGetLink = false;
      this.selectedItem = null;
    },

    openDialogToSearchItem() {
      let _searchKey = this.searchKey.trim();
      if (_searchKey) {
        this.searchItemComponentKey++;
        this.$nextTick(() => {
          this.isShowSearchItem = true;
        });
      }
    },
    hideSearchItem() {
      this.searchKey = "";
      this.isShowSearchItem = false;
    },
    handleGoToItemLocation(parentId) {
      this.hideSearchItem();
      this.hideViewSharedItems();
      this.loading = true;
      this.getCurrentTreeView(parentId ? parentId : 0).finally(() => {
        this.loading = false;
      });
    },

    openDialogToMoveItems(parentId, itemId = null) {
      let _items = [];
      if (itemId) {
        _items = this.selectedChildrenItems.filter((x) => x.id === itemId);
      } else {
        _items = this.selectedChildrenItems.filter((x) => x.is_selected);
      }
      if (_items && _items.length > 0) {
        this.selectedParentFolderId = parentId;
        this.itemsToBeMoved = _items;
        this.isShowMoveItems = true;
      }
    },
    hideMoveItems() {
      this.isShowMoveItems = false;
      this.itemsToBeMoved = [];
      this.selectedParentFolderId = null;
      this.loading = true;
      this.refreshData();
    },

    openDialogToCopyItems(parentId, itemId = null) {
      let _items = [];
      if (itemId) {
        _items = this.selectedChildrenItems.filter((x) => x.id === itemId);
      } else {
        _items = this.selectedChildrenItems.filter((x) => x.is_selected);
      }
      if (_items && _items.length > 0) {
        this.selectedParentFolderId = parentId;
        this.itemsToBeCopied = _items;
        this.isShowCopyItems = true;
      }
    },
    hideCopyItems() {
      this.isShowCopyItems = false;
      this.itemsToBeCopied = [];
      this.selectedParentFolderId = null;
      this.loading = true;
      this.refreshData();
    },

    openDialogToTrashFileItems(itemId = null) {
      let _items = [];
      if (itemId) {
        _items = this.selectedChildrenItems.filter((x) => x.id === itemId);
      } else {
        _items = this.selectedChildrenItems.filter((x) => x.is_selected);
      }
      if (_items && _items.length > 0) {
        this.itemsToBeTrashed = _items;
        this.isShowTrashFileItems = true;
      }
    },
    hideTrashFileItems() {
      this.isShowTrashFileItems = false;
      this.itemsToBeTrashed = [];
      this.loading = true;
      this.refreshData();
    },

    //actions on trashedItems
    openDialogToRestoreFiles(itemId = null) {
      let _items = [];
      if (itemId) {
        _items = this.trashedItems.filter((x) => x.id === itemId);
      } else {
        _items = this.trashedItems.filter((x) => x.is_selected);
      }
      if (_items && _items.length > 0) {
        this.trashedItemsToBeRestored = _items;
        this.isShowRestoreFileItems = true;
      }
    },
    hideRestoreFileItems() {
      this.isShowRestoreFileItems = false;
      this.trashedItemsToBeRestored = [];
      this.loading = true;
      this.loadTrash();
    },

    openDialogToDeleteFiles(itemId = null) {
      let _items = [];
      if (itemId) {
        _items = this.trashedItems.filter((x) => x.id === itemId);
      } else {
        _items = this.trashedItems.filter((x) => x.is_selected);
      }
      if (_items && _items.length > 0) {
        this.trashedItemsToBeDeleted = _items;
        this.isShowDeleteFileItems = true;
      }
    },
    hideDeleteFileItems() {
      this.isShowDeleteFileItems = false;
      this.trashedItemsToBeDeleted = [];
      this.loading = true;
      this.loadTrash();
    },

    //bookmarks
    openDialogToAddEditBookmark(parentId, itemId = null) {
      if (itemId) {
        this.selectedItem = this.selectedChildrenItems.find(
          (x) => x.id === itemId && x.type === "Bookmark"
        );
      } else {
        this.selectedItem = null;
      }
      this.selectedParentFolderId = parentId;
      this.isShowAddEditBookmark = true;
    },
    hideAddEditBookmark() {
      this.isShowAddEditBookmark = false;
      this.selectedParentFolderId = null;
      this.selectedItem = null;
    },
    handleAddEditBookmarkComplete(result) {
      this.hideAddEditBookmark();
      notify.success(`${result.action} bookmark successfully!`);
      this.loading = true;
      this.refreshData();
    },

    openBookmarkUrl(item) {
      this.loading = true;
      AdminServices.requestDownloadCIO(item.id, "View")
        .then((resp) => {
          if (resp.data.ok) {
            window.open(resp.data.d, "_blank");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    countView(item) {
      if (["File", "Bookmark"].includes(item.type)) {
        return item.view_count_by_user_type.reduce((acc, val) => {
          return acc + val.view_count;
        }, 0);
      } else {
        return 0;
      }
    },

    openDialogToViewSharedItems() {
      this.isShowViewSharedItems = true;
    },
    hideViewSharedItems() {
      this.isShowViewSharedItems = false;
    },

    openModalConfirmToAddWatermark(item) {
      this.selectedItem = this.selectedChildrenItems.find(x => x.id === item.id)

      let message = {
        title: "Add Watermark",
        body: `Press button confirm to add watermark for file ${this.selectedItem.name}`,
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          let _item = Object.assign({}, this.selectedItem)
          if (_item.type === 'File' && _item.current_extension === '.pdf') {
              AdminServices.addWatermark(_item.id).then(resp => {
                  console.log(resp)
                  if (resp.data.ok) {
                      notify.success('Watermark added successfully!')   
                      this.refreshData()
                  } else {
                      notify.error('Failed when adding watermark.')
                  }
              }).finally(() => { this.loading = false })
          } else {
              notify.error('Invalid file.')
          }
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
  },
});
