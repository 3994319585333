<template>
  <div>
    <ul class="nav nav-tabs">
      <li
        class="nav-item"
        v-for="(tab, tabI) in tabs"
        :key="`${tabI}tabI`"
        @click="selectTab(tabI)"
      >
        <a
          v-if="tab.id && tab.name"
          :class="`nav-link ${_value == tabI ? 'active' : ''}`"
          >{{ tab.name }}</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        v-for="(tab, tabI) in tabs"
        :class="`tab-pane fade ${_value == tabI ? 'show active' : ''} ${
          tab.contentClass ? tab.contentClass : ''
        }`"
        :key="`${tabI}contentI`"
      >
        <slot v-if="tab.id" :name="tab.id" />
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss">
.nav {
  &.nav-tabs {
    border-bottom: 1px solid rgba(225, 225, 225, 0.2);
    margin-bottom: 1em;
    &:after {
      height: 0;
    }
  }
  .nav-item {
    cursor: pointer;
    .nav-link {
      border: 0;
      padding: 5px 0;
      margin-right: 16px;
      color: #7e7e7e;
      &.active {
        background-color: transparent;
        color: #ffffff;
        border-bottom: none;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          background-color: #3d7cba;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.boxed {
  background-color: #44464f;
  padding: 15px 20px;
  border-radius: 21px;
}
</style>
