// import AdminService from "@/services/admin"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import validate_email from '@/misc/checkemail'
import TextField from "@/components/TextField";
import notify from "@/services/notify";
import AdminServices from "@/services/admin";
import { defineComponent } from "vue";
import { mapState } from "vuex";
export class UploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return false;
    // return new Promise((resolve, reject) =>
    // {
    //     const reader = new window.FileReader();

    //     reader.addEventListener('load', () =>
    //     {
    //         resolve({'default': reader.result});
    //     });

    //     reader.addEventListener('error', err =>
    //     {
    //         reject(err);
    //     });

    //     reader.addEventListener('abort', () =>
    //     {
    //         reject();
    //     });

    //     this.loader.file.then(file =>
    //     {
    //         reader.readAsDataURL(file);
    //     });
    // });
  }

  // Aborts the upload process.
  abort() {
    //
  }
}

export const uploader = function (editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
    new UploadAdapter(loader);
};

const objDefault = {
  name: "",
  title: "",
  email: "",
  photo: "",
  home_town: "",
  introduction: "",
  signature: "",
  is_enabled: false,
  perspective_status: false,
  ext: {
    Facebook: "",
    Instagram: "",
    Linkedin: "",
    Medium: "",
    Twitter: "",
  },
};

const objErrDefault = {
  name: "",
  photo: "",
  intro: "",
  introLength: "",
  title: "",
};

export default defineComponent({
  name: "addEditMember",
  emits: ["reloadInfo"],
  components: {
    TextField,
  },
  data() {
    return {
      regExp: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
      isShow: false,
      formError: false,
      error: { ...objErrDefault },
      dataPhoto: "",
      dataSignature: "",
      formData: { ...objDefault },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "Link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "insertTable",
          "undo",
          "redo",
        ],
      },
      editorData: "",
      typeName: "Add",
      loading: false,
    };
  },
  methods: {
    resetData() {
      this.error = _.cloneDeep(objErrDefault);
      this.dataPhoto = "";
      this.formData = _.cloneDeep(objDefault);
      this.typeName = "Add";
    },
    validateForm() {
      this.formError = false;

      this.error.name = false;
      if (!this.formData.name || this.formData.name.trim() === "") {
        this.error.name = "Please input name";
        this.formError = true;
      }

      this.error.title = false;
      if (!this.formData.title || this.formData.title.trim() === "") {
        this.error.title = "Please input position";
        this.formError = true;
      }

      this.error.intro = false;
      if (!this.editorData || this.editorData.trim() === "") {
        this.error.intro = true;
        this.error.introLength = false;
        this.formError = true;
      } else {
        if (this.editorData.length > 4000) {
          this.error.introLength = true;
          this.error.intro = false;
          this.formError = true;
        }
      }
      this.formData.intro = this.editorData;

      this.error.photo = false;
      if (!this.dataPhoto) {
        this.error.photo = true;
        this.formError = true;
      }

      // this.error.email = false
      // if (!this.regExp.test(this.formData.email)) {
      //     this.error.email = 'Please input valid email'
      //     this.formError = true
      // }

      if (this.formError) {
        return;
      }

      let formData = new FormData();
      formData.append("name", this.formData.name);
      formData.append("title", this.formData.title);
      formData.append("email", this.formData.email);
      formData.append("introduction", this.formData.intro);
      formData.append("photo", this.formData.photo);
      formData.append("isEnabled", this.formData.is_enabled);
      formData.append("perspectiveStatus", this.formData.perspective_status);
      formData.append("extraInfo", JSON.stringify(this.formData.ext));
      formData.append("signature", this.formData.signature);
      if (this.typeName === "Add") {
        this.loading = true;
        this.confirmAddMember(formData);
      } else {
        this.loading = true;
        formData.append("id", this.formData.id);
        this.confirmUpdateMember(formData);
      }
    },
    confirmUpdateMember(item) {
      AdminServices.updateTeamData(item)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Update successfully");
            this.$emit("reloadInfo");
          }
        })
        .finally(() => {
          this.loading = false;
          this.hideModal();
        });
    },
    confirmAddMember(item) {
      AdminServices.addTeamData(item)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add successfully");
            this.$emit("reloadInfo");
          }
        })
        .finally(() => {
          this.loading = false;
          this.hideModal();
        });
    },
    renderFileImg() {
      let ExtAllow = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      if (this.$refs.fileimg.files.length > 0) {
        let img = this.$refs.fileimg.files[0];

        if (!ExtAllow.includes(img.type)) {
          return;
        }
        let reader = new FileReader();

        reader.readAsDataURL(img);
        reader.onload = (e) => {
          this.dataPhoto = e.target.result;
          if (this.dataPhoto) {
            this.formData.photo = img;
            this.error.photo = false;
          }
        };
      }
    },
    renderFileSignature() {
      let ExtAllow = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/bmp",
        "image/ifif",
        "image/pjpeg",
        "image/pjp",
      ];
      if (this.$refs.fileimgSignature.files.length > 0) {
        let img = this.$refs.fileimgSignature.files[0];

        if (!ExtAllow.includes(img.type)) {
          return;
        }
        let reader = new FileReader();

        reader.readAsDataURL(img);
        reader.onload = (e) => {
          this.dataSignature = e.target.result;
          if (this.dataSignature) {
            this.formData.signature = img;
          }
        };
      }
    },

    prefill(editor) {
      this.editorData = this.formData.intro;
    },
    onResize() {
      if ($(".custom-modal .modal-dialog").height() > $(window).height()) {
        $(".custom-modal .modal-dialog").css({ transform: "unset", top: "0" });
      } else {
        $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
      }
    },
    showModal(item) {
      if (item) {
        this.formData = item;
        this.dataPhoto = item.photo;
        this.typeName = "Edit";
      }
      this.isShow = true;
      setTimeout(() => {
        this.onResize();
      }, 200);
    },
    hideModal() {
      this.isShow = false;
      this.resetData();
    },
  },
  computed: mapState({
    screenSize: (state) => state.mobile.size,
  }),
  watch: {
    screenSize() {
      this.onResize();
    },
    "formData.name": function (v) {
      if (v && v.trim().length > 0) this.error.name = "";
    },
    "formData.title": function (v) {
      if (v && v.trim().length > 0) this.error.title = "";
    },
    dataPhoto(v) {
      if (v && v.trim().length > 0) this.error.photo = false;
    },
    editorData(v) {
      if (v && v.trim().length > 0) this.error.intro = false;
      if (_.size(v) <= 4000) this.error.introLength = false;
    },
  },
});
