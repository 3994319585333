<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="mask" @click.prevent="hide()"></div>
    <div class="modal-dialog simple modal-sm modal-2fa-custom">
      <div class="modal-content" v-loading="loading">
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="hide()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g
              class="nc-icon-wrapper"
              stroke-linecap="square"
              stroke-linejoin="miter"
              stroke-width="2"
              fill="#ffffff"
              stroke="#ffffff"
            >
              <g class="nc-interact_menu-close-2-o-32">
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-miterlimit="10"
                  d="M2 6h28"
                  transform="translate(0 10.00) rotate(45.00 16 6)"
                />
                <path
                  data-color="color-2"
                  fill="none"
                  stroke-miterlimit="10"
                  d="M2 16h28"
                  opacity="0"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-miterlimit="10"
                  d="M2 26h28"
                  transform="translate(0 -10) rotate(-45 16 26)"
                />
              </g>
            </g>
          </svg>
        </button>
        <form
          @submit.prevent="submit()"
          :disabled="!key || success"
          v-if="!enable2faLogin"
        >
          <div class="modal-body">
            <div class="subtitle mb-3">
              <h3 class="font-weight-bold text-center">Google authenticator</h3>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="description">
                  <small>Google authenticator is enabled</small>
                </div>
              </div>
            </div>

            <div class="boxContent">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      ref="passwordRef"
                      placeholder="password"
                      v-model="password"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      ref="codeRef"
                      placeholder="TwoFA code"
                      v-model="code"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6 offset-md-3">
                        <button
                          v-if="!success"
                          :disabled="!code"
                          type="submit"
                          class="button btn-block btn-large round btn-green btn-hover investor btn-bounce btn-active"
                        >
                          Deactive
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <form
          @submit.prevent="submit2FALogin()"
          :disabled="!key || success"
          v-else
        >
          <div class="modal-body p-3 p-sm-4">
            <div class="subtitle mb-3">
              <h3 class="font-weight-bold text-center">Google authenticator</h3>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="description">
                  <small>Google Twofa disabled</small>
                </div>
              </div>
            </div>

            <div class="boxContent">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      ref="codeRef"
                      placeholder="TwoFA code"
                      v-model="code"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6 offset-md-3">
                        <button
                          v-if="!success"
                          :disabled="!code"
                          type="submit"
                          class="button btn-block btn-large round btn-green btn-hover investor btn-bounce btn-active"
                        >
                          Turn off
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .white-theme {
    .button.btn-green.btn-bounce:hover {
      color: #2177ff;
    }
  }
}
</style>
