<template>
  <div class="adminContent p-3">
    <ul class="nav">
      <li
        class="nav-item pointer"
        v-for="(item, idx) in tabs"
        :key="`tabs_${idx}`"
      >
        <a
          class="nav-link pointer"
          :class="{ active: tab_active === idx }"
          href="javascript:void(0)"
          @click="
            changeTab(idx);
            paramSearch = item.query();
          "
          ><span>{{ item.name }}</span></a
        >
      </li>
    </ul>
    <Table
      :paging="page"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      cursorPointer
      @rowClick="view"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          :value="name"
          @update:value="name = $event"
        />
      </template>
      <template #row_name="{ props }">
        {{ props.name }}
      </template>
      <template #row_status="{ props }">
        <template v-if="!props.is_rejected">{{
          getStageNameById(props.stage_id)
        }}</template>
        <template v-else>{{ getStageNameById(props.stage_id) }}</template>
      </template>

      <template #row_actions="{ props }">
        <button class="btn btn-primary btn-view" title="View">
          <i class="far fa-eye dropdown-item-icon"></i>
        </button>
      </template>
      <template #footer>
        <div v-if="tab_active == 0">
          <button class="btn btn-primary" @click="addDeal">
            <span class="text">Add New</span>
          </button>
        </div>
      </template>
    </Table>
    <DealAddUpdateModal
      ref="dealAddUpdateModal"
      :key="dealAddUpdateModalKey"
      @refresh="getAll"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.nav {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  display: block;
  &::after {
    content: "";
    height: 2px;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #e4e7ed;
  }
  li {
    position: relative;
    z-index: 2;
    display: inline-block;
    a {
      font-size: 14px;
      color: #cdcdcd;
      line-height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-bottom: 2px solid transparent;
      height: 100%;
      span {
        line-height: 18px;
        display: inline-block;
        max-width: 80px;
        white-space: normal;
        height: 45px;
      }
      &.active {
        color: #409eff;
        // border-bottom: 2px solid #409eff;
      }
    }
  }
}
.nowrap {
  white-space: nowrap;
}
</style>
