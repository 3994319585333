import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'addEditBookmark',
    props: {
        item: {
            type: Object,
            required: false,
            default: null
        },
        parentId: {
            type: Number,
            required: true
        },
        hideAddEditBookmark: {
            type: Function,
            required: true
        }
    },
    emits: ['complete'],
    data() {
        return {
            form: {
                id: null,
                name: null,
                url: null,
            },
            errorMsg: {
                name: null,
                url: null
            },
            loading: false
        }
    },
    created() {
        if (this.item) {
            this.form.id = this.item.id
            this.form.name = this.item.name,
            this.form.url = this.item.bookmark_url
        }
    },
    watch: {
        'form.name': function (v) {
            this.errorMsg.name = null
        },
        'form.url': function (v) {
            this.errorMsg.url = null
        },
    },
    computed: {
        formType() {
            return this.item ? 'Edit' : 'Add'
        }
    },
    methods: {
        save() {
            let flag = true
            if (this.form.name.trim() === '') {
                this.errorMsg.name = 'Please input bookmark name'
                flag = false
            }
            if (this.form.url.trim() === '') {
                this.errorMsg.url = 'Please input bookmark url'
                flag = false
            }
            if (flag) {
                this.loading = true
                if (!this.form.id) {
                    //create
                    AdminServices.createDataroomBookmark({parentId: this.parentId, name: this.form.name.trim(), url: this.form.url.trim()})
                        .then(resp => {
                            if (resp.data.ok) {
                                this.$emit('complete', {id: resp.data.d, name: this.form.name.trim(), action: 'Create'})
                            }
                        })
                        .finally(() => this.loading = false)
                } else {
                    //edit
                    if ( (this.form.name.trim() === this.item.name.trim()) && (trim(this.form.url) === this.item.bookmark_url.trim()) ) {
                        this.loading = false
                        this.hideAddEditBookmark()
                    } else {
                        AdminServices.editDataroomBookmark({id: this.form.id, name: this.form.name.trim(), url: this.form.url.trim()})
                        .then(resp => {
                            if (resp.data.ok) {
                                this.$emit('complete', {id: this.form.id, name: this.form.name.trim(), action: 'Edit'})
                            }
                        })
                        .finally(() => this.loading = false)
                    }
                }
            }
        },
    }
})