import { defineComponent } from "vue";
import _ from "lodash";

import AdminServices from "@/services/admin";
import notify from "@/services/notify";

import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";
import NumberField from "@/components/NumberField";

export default defineComponent({
  name: "EditFounderModel",
  props: ["partnerList"],
  components: {
    Modal,
    TextField,
    NewButton,
    SelectField,
    NumberField,
  },
  data() {
    return {
      loading: false,
      isShow: false,

      form: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        // phoneNumber: null,
        portfolioId: "",
        password: null,
      },

      errorMsg: {
        fname: null,
        lname: null,
        email: null,
        // phoneNumber: null,
        portfolioId: null,
        password: null,
      },

      showPassword: false,
    };
  },
  methods: {
    showUpdate(item) {
      this.isShow = true;
      const _item = _.cloneDeep(item);

      this.form.id = _item.id;
      this.form.firstName = _item.fname;
      this.form.lastName = _item.lname;
      this.form.email = _item.email;
      this.form.portfolioId =
        _item.portfolio_id == null ? 0 : _item.portfolio_id;
      this.form.password = _item.password;
    },
    validData() {
      let flag = true;
      if (this.form.firstName.trim() === "") {
        this.errorMsg.fname = "Please input your first name";
        flag = false;
      }
      if (this.form.lastName.trim() === "") {
        this.errorMsg.lname = "Please input your last name";
        flag = false;
      }
      if (
        this.form.password &&
        this.form.password.trim() !== "" &&
        this.form.password.length < 6
      ) {
        this.errorMsg.password =
          "The Password must be at least 6 characters long.";
        flag = false;
      }
      return flag;
    },
    save() {
      if (this.loading) return;
      if (!this.validData()) return;
      this.updateFounderInfo();
    },
    async updateFounderInfo() {
      this.loading = true;
      const res = await AdminServices.updateFounderInfo(
        this.form.id,
        this.form
      );
      if (res.data.ok) {
        notify.success("Update user success!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't update this user!");
      }
      this.loading = false;
    },
    async resetPassword() {
      if (
        !this.form.password ||
        this.form.password.trim() === "" ||
        !this.form.password ||
        (this.form.password && this.form.password.length < 6)
      ) {
        this.errorMsg.password =
          "The Password must be at least 6 characters long.";
        return;
      }
      const res = await AdminServices.resetUserPassword(this.form.id, {
        password: this.form.password,
      });
      if (res.data.ok) {
        notify.success("Reset password success!");
        this.$emit("refresh");
      }
      this.loading = false;
    },
  },
  watch: {
    "form.firstName": function (v) {
      this.errorMsg.fname = null;
    },
    "form.lastName": function (v) {
      this.errorMsg.lname = null;
    },
    "form.portfolioName": function (v) {
      this.errorMsg.portfolioName = null;
    },
    "form.email": function (v) {
      this.errorMsg.email = null;
    },
    "form.password": function (v) {
      this.errorMsg.password = null;
    },
  },
});
