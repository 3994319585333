<template>
  <div class="mapContent d-flex flex-column">
    <div class="info">
      <!-- <div class="block">
        <div class="item">
          <p class="m-0 mb-3 text-center colorWhite">No. of startups</p>
          <p class="m-0 number text-center colorWhite">{{startup.length}}</p>
        </div>
        <div class="item">
          <p class="m-0 mb-3 text-center colorWhite">MOIC*</p>
          <p class="m-0 mb-3 number text-center colorWhite">{{moic.value}}</p>
          <p class="m-0 text-center colorWhite caption" style="line-height: normal">
            {{moic.text}}
          </p>
        </div>
        <div class="item">
          <p class="m-0 mb-3 text-center colorWhite">Gross IRR**</p>
          <p class="m-0 mb-3 number text-center colorWhite">{{gross_irr.value}}%</p>
          <p class="m-0 text-center colorWhite caption">{{gross_irr.text}}</p>
        </div>
        <div class="item">
          <p class="m-0 mb-3 text-center colorWhite">Board Positions</p>
          <p class="m-0 mb-3 number text-center colorWhite">{{board_positions.value}}</p>
        </div>
      </div>
      <div class="item hasChart" style="flex: 1">
        <div id="regionchart" class="m-0 number text-center colorWhite"></div>
      </div>
      <div class="item hasChart" style="flex: 1">
        <div id="stagechart" class="m-0 number text-center colorWhite"></div>
      </div> -->
      <div class="block newValue text-white">
        <div class="item d-flex" v-for="(item, index) in newValue" :key="index">
          <div class="f mr-2" :style="{ 'background-color': item.color }">
            {{ item.name }}
          </div>
          <div class="value">
            <p class="mb-2 mt-1">
              <strong>{{ item.title }}</strong>
            </p>
            <p class="mb-2">{{ item.vintage }}</p>
            <p class="mb-2">{{ item.size }}</p>
            <p class="mb-0">{{ item.comps }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="mapchart"></div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mapContent {
  background-color: #1e2128;
  height: 100%;
  font-family: "SF Pro Text";
  .info {
    display: flex;
    height: 140px;
    @media screen and (max-width: 1023.98px) {
      height: 230px;
    }
    @media screen and (max-width: 568px) {
      height: 410px;
    }
    // @media screen and (max-width: 480px) {
    //   display: block;
    // }
    .block {
      flex: 0 0 40%;
      display: flex;
      @media screen and (max-width: 992px) {
        display: block;
        flex: 0 0 33%;
      }
      @media screen and (max-width: 480px) {
        display: flex;
      }

      > div {
        flex: 1;
      }
      .item {
        padding: 15px 0px;
        @media screen and (max-width: 1023.98px) {
          padding: 8px 0px;
        }
      }
    }
    #regionchart,
    #stagechart {
      display: block;
      width: 100%;
      height: 100%;
    }
    .item {
      padding: 15px;
      @media screen and (max-width: 1023.98px) {
        padding: 8px;
      }
      &.hasChart {
        @media screen and (max-width: 480px) {
          height: 100px;
        }
      }
      p {
      }
      .number {
        font-size: 35px;
        @media screen and (max-width: 992px) {
          font-size: 35px;
        }
        @media screen and (max-width: 480px) {
          font-size: 26px;
        }
      }
      .caption {
        font-size: 10px;
      }
    }
    .newValue {
      flex: 1;
      &.block {
        display: flex;
        flex-wrap: wrap;
      }
      .item {
        flex: 1;
        @media screen and (max-width: 1023.98px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media screen and (max-width: 568px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .f {
          flex: 0 0 75px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: bold;
        }
        .value {
          flex: auto;

          p {
            font-size: 13px;
            line-height: 14px;
            @media screen and (min-width: 568px) {
              font-size: 14px;
              font-weight: 500;
            }
          }

          strong {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }
  #mapchart {
    width: 100%;
    height: calc(100vh - 140px - 65px);
    padding: 10px;
    @media screen and (max-width: 1023.98px) {
      height: calc(100vh - 230px);
      // padding-bottom: 75px;
    }
    @media screen and (max-width: 568px) {
      height: calc(100vh - 410px);
    }
  }
}
</style>
