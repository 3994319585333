import appStore, { AUTH_ACTIONS } from "../../store/app";

import tos from "./termAndConditions";
import signature from "./signature";
import AdminServices from "../../services/admin";
import validateEmail from "../../misc/checkemail";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  data() {
    return {
      isHideLoading: false,
      dataReady: false,
      step: null,
      isShowBlockInput: false,
      isShowTOS: false,
      acceptTOS: false,
      isShowSignature: false,
      photo: "",
      extraInfo: {},
      formDataAbove: [],
      formDataBelow: [],
      inputValue: null,
      formData: [
        {
          title: "Please enter your first name",
          value: null,
          style: "",
          type: "text",
        },
        {
          title: "Please enter your last name",
          value: null,
          style: "",
          type: "text",
        },
        {
          title: "Please enter your email",
          value: null,
          style: "",
          type: "email",
        },
        {
          title: "Please enter your phone",
          value: null,
          style: "",
          type: "text",
        },
        {
          title: "Please enter your company",
          value: null,
          style: "",
          type: "text",
        },
        {
          title: "Please enter your title",
          value: null,
          style: "",
          type: "text",
        },
      ],
      onAnimation: false,
      errorMsg: "",
      backToFirstTimer: null,
    };
  },
  components: {
    tos,
    signature,
    LogoIcon,
  },
  created() {
    $("title").text("Robotic");
    if (!this.$route.query.udid || !this.$route.query.key) {
      this.step = "failed";
      this.dataReady = true;
    } else {
      this.getRoboinfo(this.$route.query.udid, this.$route.query.key);
    }
  },
  mounted() {
    setTimeout(() => {
      this.isHideLoading = true;
    }, 2000);
  },
  methods: {
    setStepValue(step, value) {
      this.formData[step].value = value;
      this.inputValue = null;
    },
    next() {
      if (!this.validStep("next")) return;
      this.increaseStep();
    },
    prev() {
      if (!this.validStep("prev")) return;
      this.decreaseStep();
    },
    sourceFileData(value) {
      this.photo = value;
    },
    getRoboinfo(id, key) {
      AdminServices.getRobot({ udid: id, key: key }).then((resp) => {
        if (resp.data.ok) {
          if (!resp.data.d) {
            this.step = "failed";
          } else {
            this.step = "cover";
          }
          this.dataReady = true;
        }
      });
    },
    validStep(str) {
      let valid = true;
      this.errorMsg = "";
      if (this.formData[this.step + (str === "prev" ? -1 : +1)]) {
        if ((str === "prev" && !this.inputValue) || this.inputValue === "") {
          return valid;
        }

        switch (this.formData[this.step].type) {
          case "text":
          case "number":
            if (!this.inputValue || this.inputValue.trim() === "") {
              this.errorMsg = "Please fill out this field.";
              valid = false;
            }
            break;
          case "email":
            if (!validateEmail.validateEmail(this.inputValue)) {
              this.errorMsg = "Incorrect email. Please try again.";
              valid = false;
            }
            break;
          default:
            break;
        }
      }
      return valid;
    },
    increaseStep() {
      this.errorMsg = "";
      if (typeof this.step === "number") {
        switch (this.formData[this.step].type) {
          case "text":
            if (!this.inputValue || this.inputValue === "") {
              this.errorMsg = "Please fill out this field.";
              return;
            }
            break;
          case "email":
            if (!validateEmail.validateEmail(this.inputValue)) {
              this.errorMsg = "Incorrect email. Please try again.";
              return;
            }
            break;
          default:
            break;
        }
      }
      if (this.errorMsg) {
        return;
      }
      if (this.onAnimation) {
        return;
      }
      this.onAnimation = true;
      if (this.step === "cover") {
        this.step = 0;
      } else {
        if (this.step < this.formData.length - 1) {
          this.setStepValue(this.step, this.inputValue);
          this.step += 1;
          this.inputValue = this.formData[this.step].value;
        } else {
          this.step = this.formData.length - 1;
          this.setStepValue(this.step, this.inputValue);
          this.termAndConditions();
          return;
        }
      }

      this.$nextTick(() => {
        if (this.step === 3) {
          this.$refs.formEditText.setAttribute("type", "number");
        } else {
          this.$refs.formEditText.setAttribute("type", "text");
        }

        if (this.$refs.formEditText) {
          this.$refs.formEditText.focus();
        }
        this.onAnimation = false;
      });
    },
    decreaseStep() {
      if (this.onAnimation) {
        return;
      }
      this.onAnimation = true;
      this.step -= 1;
      if (this.step < 0) {
        this.step = 0;
      }
      this.inputValue = this.formData[this.step].value;
      this.$nextTick(() => {
        if (this.$refs.formEditText) {
          this.$refs.formEditText.focus();
        }
        this.onAnimation = false;
      });
    },
    hideInputText() {
      this.isShowBlockInput = false;
    },
    backToPrevious() {
      if (this.isShowBlockInput) {
        this.hideInputText();
      } else {
        this.decreaseStep();
      }
    },
    termAndConditions() {
      this.isShowTOS = true;
    },
    hideTermAndConditions() {
      this.isShowTOS = false;
    },
    moveSignature() {
      this.hideTermAndConditions();
      this.showSignature();
    },
    showSignature() {
      this.isShowSignature = true;
    },
    hideSignature() {
      this.isShowSignature = false;
      this.onAnimation = false;
    },
    finishSurvey() {
      this.isHideLoading = false;

      AdminServices.submitRobot({
        udid: this.$route.query.udid,
        authKey: this.$route.query.key,
        firstName: this.formData[0].value,
        lastName: this.formData[1].value,
        email: this.formData[2].value,
        phoneNumber: this.formData[3].value,
        company: this.formData[4].value,
        title: this.formData[5].value,
        signature: this.photo,
        extraInfo: {},
      }).then((resp) => {
        if (resp.data.ok) {
          this.step = "thankyou";
          this.backToFirstTimer = setTimeout(() => {
            this.backToFirst();
          }, 10000);
        } else {
          this.step = "failsubmit";
        }
        this.isHideLoading = true;
        this.hideSignature();
      });
    },
    backToFirst() {
      clearTimeout(this.backToFirstTimer);
      this.formData.map((p) => {
        p.value = null;
      });
      this.inputValue = null;

      this.step = "cover";
    },
    valid(value) {
      return typeof value;
    },
  },
  watch: {
    step: function (v) {
      if (typeof v === "number") {
        if (v >= 0) {
          this.formDataAbove = [];
          for (let i = 0; i < this.formData.length && i < v; i++) {
            this.formDataAbove.push(this.formData[i]);
          }

          this.formDataBelow = [];
          for (let i = this.step + 1; i < this.formData.length; i++) {
            if (this.formData[this.step].value) {
              this.formDataBelow.push(this.formData[i]);
            }
          }
        }
        if (this.formDataAbove.length > 0) {
          let tmp = this.formDataAbove.reverse();
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].style = `transform: scale(${
              (10 - (i * 2 + 4)) * 0.1
            }); opacity: ${(10 - (i * 2 + 5)) * 0.1};`;
          }
          this.formDataAbove = tmp.reverse();
        }
        if (this.formDataBelow.length > 0) {
          for (let i = 0; i < this.formDataBelow.length; i++) {
            this.formDataBelow[i].style = `transform: scale(${
              (10 - (i * 2 + 4)) * 0.1
            }); opacity: ${(10 - (i * 2 + 5)) * 0.1};`;
          }
        }
      }
    },
  },
});
