<template>
  <div>
    <label v-if="title" :class="`title title-color-${color}`" :for="name"
      >{{ title }}
      <template v-if="fieldRequire"
        ><small class="require">*</small></template
      ></label
    >
    <div :class="`select-field ${hideError ? 'hide-error' : ''}`">
      <select
        :class="`field-color-${field}`"
        v-model="_value"
        :disabled="disabled"
        :id="name"
        :tabindex="tabindex"
        v-if="type === 0"
      >
        <option
          v-for="(item, idx) in options"
          :key="idx"
          :value="item[valueProp]"
        >
          {{ item[labelBy] }}
        </option>
      </select>
      <select
        :class="`field-color-${field}`"
        v-model="_value"
        :disabled="disabled"
        :id="name"
        :tabindex="tabindex"
        v-else-if="type === 1"
      >
        <option
          v-for="(item, idx) in options"
          :key="idx"
          :value="item.phone_carrier_id"
          :selected="_value === item.phone_carrier_id"
        >
          {{ item.carrier_name }}
        </option>
      </select>
      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.select-field {
  position: relative;
}

select {
  border: 0;
  border-radius: 0;
  outline: none;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 25px;
  border-radius: 4px;
  width: 100%;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    color: #c4cad6;
    opacity: 0.5;
    cursor: no-drop;
  }
}

.title {
  margin-top: 0;
  .require {
    color: #f52727;
  }
}

.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
  font-weight: 400;
}

.hide-error {
  select {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
</style>
