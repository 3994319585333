<template>
  <div class="adminContent p-3" v-loading="loading">
    <div class="treeList me-2">
      <div
        class="data-content py-2 pr-2 data-tree content-left-scrollable scrollbar"
      >
        <ul class="mainTree">
          <tree-item
            class="item"
            :item="treeData"
            @select-folder="selectFolder"
            :selectedId="selectedFolderId"
            :selectedParentId="selectedParentId"
            :isMainTree="true"
          />
        </ul>
        <hr />
        <div
          class="sub-tree-node"
          :class="{ 'active-tree-node': isTrash }"
          @click="loadTrash"
        >
          <div
            class="tree-node-name noselect"
            :class="{ 'active-menu': isTrash }"
          >
            <i class="far fa-trash-alt mx-2 leaf-icon"></i>
            <span class="leaf-name">Trash</span>
          </div>
        </div>
      </div>
    </div>
    <div class="treeDetailContent">
      <div class="data-content pl-2 pr-3 py-2" v-if="isTrash">
        <div class="path-section">
          <div class="truncate">
            <span>
              <span class="noselect path-item">Trash folder</span>
            </span>
          </div>
          <div class="path-action d-flex flex-row">
            <button
              type="button"
              class="btn btn-primary btn-small me-1"
              :disabled="disabledRestoreAction"
              @click="openDialogToRestoreFiles()"
            >
              <i class="fas fa-history me-2"></i>Restore files
            </button>
            <button
              type="button"
              class="btn btn-default btn-small"
              :disabled="disabledDeleteFilesAction"
              @click="openDialogToDeleteFiles()"
            >
              <i class="far fa-trash-alt me-2"></i>Delete files forever
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <div class="row">
          <div class="col-12 content-scrollable scrollbar">
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Index</th>
                    <th>Title</th>
                    <th>Size</th>
                    <th>Trashed Datetime</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="item-row pointer"
                    v-for="(item, index) in trashedItems"
                    :key="`trashed-${index}`"
                    @click="oneClick(item, true)"
                  >
                    <td class="noselect">
                      <input
                        type="checkbox"
                        v-model="item.is_selected"
                        :value="true"
                        class="pointer"
                      />
                    </td>
                    <td class="noselect">{{ index + 1 }}</td>
                    <td class="noselect">
                      <span
                        class="item-icon me-1"
                        :style="{
                          color: getDataRoomItemIconColor(
                            item.type,
                            item.current_extension
                          ),
                        }"
                      >
                        <i
                          :class="
                            getDataRoomItemIconClass(
                              item.type,
                              item.current_extension
                            )
                          "
                        ></i>
                      </span>
                      <span>{{ item.name }}</span>
                    </td>
                    <td class="noselect">
                      <span v-if="item.type === 'File'">{{
                        $filters.formatBytes(item.file_size)
                      }}</span>
                    </td>
                    <td class="noselect">
                      {{ $filters.parseEstTime(item.last_modified_datetime) }}
                    </td>
                    <td class="text-center noselect">
                      <button
                        type="button"
                        class="btn btn-primary btn-small btn-more-action"
                        title="Restore"
                        @click="openDialogToRestoreFiles(item.id)"
                      >
                        <i class="fas fa-history"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default btn-small btn-more-action"
                        title="Delete forever"
                        @click="openDialogToDeleteFiles(item.id)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="data-content pl-2 pr-3 py-2" v-else>
        <div class="path-section">
          <div class="truncate">
            <span v-for="(item, idx) in parentPath" :key="idx">
              <i
                v-if="idx > 0"
                class="fas fa-chevron-right mx-1 breadcrum-next-icon"
              ></i
              ><span
                v-if="idx < parentPath.length - 1"
                class="noselect path-item"
                @click="selectFolder(item)"
                >{{ item.name }}</span
              ><span v-else class="noselect path-item">{{ item.name }}</span>
            </span>
          </div>
          <div class="path-action d-flex flex-row">
            <div class="search-wrapper me-1">
              <input
                type="text"
                class="input-section me-1"
                placeholder="enter to search..."
                v-model="searchKey"
                @keyup.enter="openDialogToSearchItem"
              />
              <i
                class="fas fa-search btn-icon"
                :class="{ disabled: !searchKey.trim() }"
                @click="openDialogToSearchItem"
              ></i>
            </div>
            <dropdown-menu v-model:value="showDropdownMenu" right>
              <button class="btn btn-primary btn-small dropdown-toggle">
                <i class="fas fa-plus me-1"></i> Action
              </button>
              <template #dropdown>
                <div>
                  <span
                    class="dropdown-item pointer"
                    @click="openDialogToAddFolder(selectedFolderId)"
                  >
                    <i class="far fa-folder dropdown-item-icon"></i>
                    New folder
                  </span>
                  <span
                    class="dropdown-item pointer"
                    @click="openDialogToAddEditBookmark(selectedFolderId)"
                  >
                    <i class="fas fa-link dropdown-item-icon"></i>
                    New bookmark
                  </span>
                  <div class="dropdown-divider"></div>
                  <!-- <span class="dropdown-item pointer" @click="openDialogToUploadFile(selectedFolderId)">
                                      <i class="fas fa-file-upload dropdown-item-icon"></i>
                                      File upload
                                  </span> -->
                  <!-- File upload V2 -->
                  <span
                    class="dropdown-item pointer"
                    @click="openDialogToUploadFileV2(selectedFolderId)"
                  >
                    <i class="fas fa-file-upload dropdown-item-icon"></i>
                    File upload
                  </span>
                  <span
                    class="dropdown-item pointer"
                    @click="openDialogToUploadFolder(selectedFolderId)"
                  >
                    <i class="fas fa-upload dropdown-item-icon"></i>
                    Folder upload
                  </span>
                  <div class="dropdown-divider"></div>
                  <span
                    class="dropdown-item pointer noselect"
                    @click="openDialogToViewSharedItems()"
                  >
                    <i class="far fa-eye dropdown-item-icon"></i>
                    View shared items
                  </span>
                  <div class="dropdown-divider"></div>
                  <span
                    class="dropdown-item pointer noselect"
                    :class="{ disabled: disabledMultipleAction }"
                    @click="openDialogToMoveItems(selectedFolderId)"
                  >
                    <i class="fas fa-angle-double-right dropdown-item-icon"></i>
                    Move to
                  </span>
                  <span
                    class="dropdown-item pointer noselect"
                    :class="{ disabled: disabledMultipleAction }"
                    @click="openDialogToCopyItems(selectedFolderId)"
                  >
                    <i class="far fa-copy dropdown-item-icon"></i>
                    Copy to
                  </span>
                  <div class="dropdown-divider"></div>
                  <span
                    class="dropdown-item pointer noselect"
                    :class="{ disabled: disabledTrashAction }"
                    @click="openDialogToTrashFileItems()"
                  >
                    <i class="far fa-trash-alt dropdown-item-icon"></i>
                    Move files to trash
                  </span>
                </div>
              </template>
            </dropdown-menu>
          </div>
        </div>
        <hr class="my-2" />
        <div class="row content-scrollable scrollbar">
          <div class="col-12">
            <div class="table-responsive-sm">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Index</th>
                    <th>Title</th>
                    <th>Size</th>
                    <th>Views</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <Dragger
                  v-model:value="selectedChildrenItems"
                  component-tag="tbody"
                  drag-element-tag="tr"
                  ghost-class="ghost"
                  component-class="handle"
                  @change="onSort"
                  @orderChanged="onSort"
                  @rowClick="oneClick"
                  handle=".handle"
                  class="pointer"
                >
                  <template #default="{ props, index }">
                    <td class="noselect">
                      <input
                        v-if="props.allow_delete"
                        type="checkbox"
                        v-model="props.is_selected"
                        :value="true"
                        class="pointer"
                      />
                    </td>
                    <td class="noselect">{{ index + 1 }}</td>
                    <td class="noselect">
                      <span
                        class="pr-1 item-icon me-1"
                        :style="{
                          color: getDataRoomItemIconColor(
                            props.type,
                            props.current_extension
                          ),
                        }"
                      >
                        <i
                          :class="
                            getDataRoomItemIconClass(
                              props.type,
                              props.current_extension
                            )
                          "
                        ></i>
                      </span>
                      <span class="break-word">{{ props.name }}</span>
                      <div
                        v-if="
                          props.current_extension === '.pdf' &&
                          props.is_watermarked
                        "
                        class="watermarked"
                      >
                        <span><i class="far fa-check-circle"></i></span>
                        <span>Watermarked</span>
                      </div>
                    </td>
                    <td class="noselect">
                      <span v-if="props.type === 'Folder'"
                        >{{ props.number_of_descendants }} files</span
                      >
                      <span v-if="props.type === 'File'">{{
                        $filters.formatBytes(props.file_size)
                      }}</span>
                    </td>
                    <td class="noselect">
                      <template
                        v-if="['File', 'Bookmark'].includes(props.type)"
                      >
                        {{ $filters.numberFormat(countView(props)) }}
                      </template>
                    </td>
                    <td class="noselect small-text">
                      <div class="subtext">
                        <i
                          class="fas fa-plus me-1 subtext-icon"
                          v-if="props.modified_at"
                        ></i>
                        {{ $filters.parseEstTime(props.created_at) }}<br /><span
                          class="thin-text no-wrap"
                          >{{ props.created_by }}</span
                        >
                      </div>
                      <div v-if="props.modified_at" class="subtext mt-1">
                        <i class="fas fa-pencil-alt me-1 subtext-icon"></i>
                        {{ $filters.parseEstTime(props.modified_at)
                        }}<br /><span class="thin-text no-wrap">{{
                          props.modified_by
                        }}</span>
                      </div>
                    </td>
                    <td class="text-center noselect no-wrap">
                      <button
                        type="button"
                        class="btn btn-primary btn-small btn-more-action me-1"
                        @click.prevent.stop="handleClick($event, props)"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <a
                        class="btn btn-default btn-small handle all-scroll cursor-move"
                        ><i class="fas fa-bars"></i
                      ></a>
                    </td>
                  </template>
                </Dragger>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <context-menu
      :key="contextMenuComponentKey"
      :elementId="'dataRoomMenu'"
      :options="menuOptions"
      :ref="'dataRoomMenu'"
      @option-clicked="optionClicked"
    />

    <addFolder
      v-if="isShowAddFolder"
      :hideAddFolder="hideAddFolder"
      :parentId="selectedParentFolderId"
      @add-complete="handleAddFolderComplete"
    />

    <addEditBookmark
      v-if="isShowAddEditBookmark"
      :hideAddEditBookmark="hideAddEditBookmark"
      :item="selectedItem"
      :parentId="selectedParentFolderId"
      @complete="handleAddEditBookmarkComplete"
    />

    <renameItem
      v-if="isShowRenameItem"
      :hideRenameItem="hideRenameItem"
      :dataInfo="selectedItem"
      @rename-complete="handleRenameItemComplete"
    />

    <uploadFile
      v-if="isShowUploadFile"
      :hideUploadFile="hideUploadFile"
      :parentId="selectedParentFolderId"
    />

    <uploadFileV2
      v-if="isShowUploadFileV2"
      :hideUploadFile="hideUploadFileV2"
      :parentId="selectedParentFolderId"
    />

    <uploadFolder
      v-if="isShowUploadFolder"
      :hideUploadFolder="hideUploadFolder"
      :parentId="selectedParentFolderId"
    />

    <manageVersions
      v-if="isShowManageVersions"
      :hideManageVersions="hideManageVersions"
      :item="selectedItem"
    />

    <shareItem
      v-if="isShowShareItem"
      :hideShareItem="hideShareItem"
      :dataInfo="selectedItem"
      @share-complete="handleShareItemComplete"
    />

    <shareUnauthenticatedItem
      v-if="isShowShareUnauthenticatedItem"
      :hideShareItem="hideShareUnauthenItem"
      :dataInfo="selectedItem"
      @share-complete="handleShareUnauthenItemComplete"
    />

    <searchItem
      v-if="isShowSearchItem"
      :hideSearchItem="hideSearchItem"
      :searchToken="searchKey"
      :key="searchItemComponentKey"
      @goToItemLocation="handleGoToItemLocation"
    />

    <modalConfirm
      v-if="isShowModalConfirm"
      :title="modalTitle"
      :message="modalMessage"
      :closeModalConfirm="closeModalConfirmToRemove"
      :onConfirm="onConfirmToRemove"
    />

    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />

    <downloadItem
      v-if="isShowDownloadItem"
      :fileToken="fileToken"
      :hideDownloadItem="hideDownloadItem"
    />

    <getLink
      v-if="isShowGetLink"
      :hideGetLink="hideGetLink"
      :item="selectedItem"
    />
    <moveItems
      v-if="isShowMoveItems"
      :hideMoveItems="hideMoveItems"
      :parentId="selectedParentFolderId"
      :items="itemsToBeMoved"
    />
    <copyItems
      v-if="isShowCopyItems"
      :hideCopyItems="hideCopyItems"
      :parentId="selectedParentFolderId"
      :items="itemsToBeCopied"
    />
    <trashFileItems
      v-if="isShowTrashFileItems"
      :hideTrashFileItems="hideTrashFileItems"
      :items="itemsToBeTrashed"
    />
    <restoreFileItems
      v-if="isShowRestoreFileItems"
      :hideRestoreFileItems="hideRestoreFileItems"
      :items="trashedItemsToBeRestored"
    />
    <deleteFileItems
      v-if="isShowDeleteFileItems"
      :hideDeleteFileItems="hideDeleteFileItems"
      :items="trashedItemsToBeDeleted"
    />
    <viewSharedItems
      v-if="isShowViewSharedItems"
      :hideViewSharedItems="hideViewSharedItems"
      @goToItemLocation="handleGoToItemLocation"
    />

    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables_dark.scss";
.adminContent {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  .treeList {
    flex: 0 0 300px;
    max-width: 300px;
  }
  .treeDetailContent {
    flex: 1;
  }
}
ul {
  line-height: 1.5;
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
  }
}
.data-content {
  min-height: calc(100vh - 70px - 2rem);
  background-color: $color-background-secondary;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  color: $text-color;
  height: 100%;
}
.content-scrollable {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.content-left-scrollable {
  max-height: calc(100vh - 76px);
  overflow-y: auto;
}

.sub-tree-node {
  padding: 2px 2px 2px 6px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #eeeeee;
    .tree-node-name {
      color: $color-dark;
    }
  }
  &.active-tree-node {
    background-color: #e8f0fe;
  }
  display: flex;
  align-items: flex-end;
  color: #bdbdbd;
  width: 100%;
  .tree-node-name {
    display: flex;
    align-items: baseline;
    color: #bdbdbd;
    margin-left: 16px;
    .leaf-icon {
      font-size: 1.2rem;
      margin-top: 2px;
      width: 22px;
    }
    .leaf-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.active-menu {
      .leaf-name {
        color: #409eff;
      }
      .leaf-icon {
        color: #5f6368;
      }
    }
  }
}

.data-section-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #409eff;
}
.item-row {
  font-size: 0.9rem;
  .item-icon {
    width: 20px;
    display: inline-flex;
  }
}
.btn-more-action {
  min-width: 29px;
}

.truncate {
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  .breadcrum-next-icon {
    font-size: 14px;
  }
}
.small-text {
  font-size: 12px;
}
.subtext {
  line-height: 1.2;
  .thin-text {
    font-weight: 200;
  }
  .subtext-icon {
    font-size: 8px;
    color: #e1d2bc;
    opacity: 0.5;
  }
}

.path-section {
  display: flex;
  align-items: center;
  padding-right: 12px;
  .path-item {
    font-size: 16px;
    padding: 6px 12px 6px;
    border-radius: 8px;
    line-height: 26px;
    cursor: pointer;
    display: inline-block;
    padding: 1px 12px;
    &:hover {
      background-color: $color-grey;
      color: $color-dark;
    }
    i {
      color: #5f6368;
    }
  }
  .path-action {
    .search-wrapper {
      position: relative;
      margin-left: 5px;
      width: 200px;
      height: 30px;
      //overflow: hidden;
      .input-section {
        position: absolute;
        top: 0;
        right: 0;
        //z-index: 8;
        box-sizing: border-box;
        font-size: 14px;
        background: 0 0;
        border: 1px solid #ddd;
        padding-left: 12px;
        padding-right: 30px;
        width: 180px;
        height: 30px !important;
        line-height: normal !important;
        border-radius: 16px;
        color: $text-color--secondary;
        opacity: 0.5;
        &:hover {
          opacity: 0.9;
        }
        &:focus {
          opacity: 1;
          border-color: $color-primary;
          outline: 0;
          width: 200px;
        }
        transition: width 0.5s;
      }
      .btn-icon {
        position: absolute;
        top: 4px;
        right: 9px;
        //z-index: 9;
        text-align: center;
        width: 16px;
        height: 16px;
        line-height: 22px;
        color: #666;
        border: none;
        background: 0 0;
        cursor: pointer;
        opacity: 0.5;
        &:hover {
          opacity: 0.9;
        }
        &.disabled {
          opacity: 0.3;
        }
      }
    }
    .dropdown-menu {
      background-color: $color-background-tertiary;
      .dropdown-item {
        color: $color-grey;
        padding: 0.25rem 0.75rem;
        &:hover {
          background-color: $color-grey;
          color: $color-dark;
        }
        &.disabled {
          color: $text-color--secondary;
          opacity: 0.4;
          &:hover {
            background-color: unset;
            color: $text-color--secondary;
            cursor: unset;
          }
        }
        .dropdown-item-icon {
          width: 20px;
        }
      }
    }
  }
}

.break-word {
  word-break: break-all;
}

.no-wrap {
  white-space: nowrap;
}
</style>
