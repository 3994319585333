import appStore from "@/store/app";
import AdminServices from "@/services/admin";
import pdf from "@/components/commons/pdf-viewer";
import filePreview, { FileTypes } from "@/components/commons/filePreview";
import { LP_MEDIA_TYPES } from "@/misc/constants";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    pdf,
    filePreview,
  },
  data() {
    return {
      loading: false,
      // item: null,
      // url: '',
      // files: [],
      FileTypes: FileTypes,
      preview: {
        fileUrl: null,
        fileType: null,
      },
      items: [],
    };
  },
  computed: {
    investorInfo() {
      return appStore.state.investorInfo;
    },
  },
  watch: {
    investorInfo: {
      deep: true,
      handler(v) {
        this.getItems();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getItems();
    });
  },
  methods: {
    getItems() {
      this.loading = true;
      const lpFundId = this.investorInfo
        ? this.investorInfo.selected_fund.lp_fund_id
        : null;
      AdminServices.getLPMediaItemsByTypeAndLpFundId(
        LP_MEDIA_TYPES.SEEDED_COMPANIES,
        lpFundId
      )
        .then((resp) => {
          if (resp.data.ok) {
            this.items = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // initialLoading() {
    //     AdminServices.getCoInvesmentOppotunitiesFoldersAndFiles().then(resp => {
    //         if (resp.data.ok) {
    //             this.folders = resp.data.d
    //         }
    //     }).finally(() => {
    //         this.loading = false
    //     })
    // },
    // viewItem(file) {
    //     this.url = ''
    //     this.item = file
    //     AdminServices.requestDownloadCIOAndCountView(this.item.id, 'View').then(resp => {
    //         if (resp.data.ok) {
    //             this.url = resp.data.d
    //         }
    //     }).finally(() => {
    //         this.loading = false
    //     })
    // },
    showPreview(id, fileType) {
      this.loading = true;
      AdminServices.requestDownloadCIOAndCountView(id, "View")
        .then((resp) => {
          if (resp.data.ok) {
            this.fixedBody();
            this.preview.fileUrl = resp.data.d;
            this.preview.fileType = fileType;
            this.$refs.filePreview.showModal();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
