import { defineComponent } from "vue";
import _ from "lodash";

import validateEmail from "@/misc/checkemail";
import adminServices from "@/services/admin";
import notify from "@/services/notify";

import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";

export default defineComponent({
    name: "RoleManageModel",
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
  },
  props: {
    statusList: { type: Array, default: [] },
    permissionList: { type: Array, default: [] },
  },
  emits: ['refresh'],
  data() {
    return {
      loading: false,
      isShow: false,
      state: "INSERT",
      userTypeList: [
        {
          id: "Rokker",
          name: "FuelVC",
        },
        {
          id: "IDC",
          name: "IDC",
        },
      ],
      form: {
        id: null,
        name: null,
        stt: "ACTIVE",
        permissions: [],
      },
      errorMsg: {
        name: null,
      },
    };
  },
  methods: {
    showAdd() {
      this.state = "INSERT";
      this.isShow = true;
    },
    showUpdate(item) {
      this.state = "UPDATE";
      this.isShow = true;
      const _item = _.cloneDeep(item);

      this.form.id = _item.id;
      this.form.name = _item.name;
      this.form.stt = _item.stt;
      this.form.permissions = [];

      if (_item.permissions) {
        let perArr = _item.permissions.split(",");
        for (var i = 0; i < this.permissionList.length; i++) {
          if (perArr.includes(this.permissionList[i].id)) {
            this.form.permissions[i] = true;
          } else {
            this.form.permissions[i] = false;
          }
        }
      }
    },
    save() {
      if (!this.validData()) return;

      if (this.state == "INSERT") {
        this.addRole();
      } else if (this.state == "UPDATE") {
        this.updateRole();
      }
    },
    async addRole() {
      this.loading = true;
      const res = await adminServices.createInvestorRole(this.prepareData());
      if (res.data.ok) {
        notify.success("Add role successfully!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't add this role!");
      }
      this.loading = false;
    },
    async updateRole() {
      this.loading = true;
      const res = await adminServices.updateInvestorRole(
        this.form.id,
        this.prepareData()
      );
      if (res.data.ok) {
        notify.success("Update role successfully!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("can't update this role!");
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if ($.trim(this.form.name) === "") {
        this.errorMsg.name = "Please input role name";
        flag = false;
      }
      return flag;
    },
    prepareData() {
      let perStr = "";
      for (var i = 0; i < this.form.permissions.length; i++) {
        if (this.form.permissions[i]) {
          perStr += this.permissionList[i].id + ",";
        }
      }
      if (perStr.length > 0) {
        perStr = perStr.slice(0, -1);
      }
      return {
        id: this.form.id,
        name: this.form.name,
        status: this.form.stt,
        permissions: perStr,
      };
    },
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = null;
    },
  },
});