import { defineComponent } from 'vue'
import AdminServices from '@/services/admin'
import { PERMISSIONS } from '@/config/router'
import { mapStringToTime, parseUTCTimeToLocal } from '@/misc/commons'
import Table, { debounce } from '@/components/Table'
import TextField from '@/components/TextField'
import UpdateReviewerModel from './components/UpdateReviewerModel'

const columnsData = [
    {
        id: 'name',
        name: 'Stage Name',
    },
    {
        id: 'number',
        name: 'Reviewers',
    },
    {
        id: 'deathline',
        name: 'DeadLine (days)',
    },
    {
        id: 'notify',
        name: 'Interval Days',
    },
    {
        id: 'notify_before_hour',
        name: 'Notify Hour',
    },
    {
        id: 'actions',
        name: 'Actions',
        titleClass: 'text-right',
        columnClass: 'text-right action-icon-group'
    },
]

export default defineComponent({
    name: 'deal-state-reviewer',
    components: {
        Table,
        TextField,
        UpdateReviewerModel,
    },
    data() {
        return {
            rows: [],
            totalItems: 0,
            loading: false,
			paging: {
                page: 1,
                size: 10,
                total: 0
            },

            name: '',
            roleList: [],
            columns: columnsData,
            PERMISSIONS: PERMISSIONS,
            updateReviewerModelkey: 0,
        }
    },
    watch: {
        paging: {
            deep: true,
            handler(v) {
                debounce(this.getAll)
            }
        }
    },
    methods: {
        mapTime: function(time) {
            return mapStringToTime(time)
        },
        async getAll() {
            this.loading = true
            const res = await AdminServices.getStages({
                paging: {
                    page: this.paging.page,
                    size: this.paging.size,
                },
            })
            if (res && res.data && res.data.ok) {
                this.rows = res.data.d
				this.totalItems = res.data.d.length
            }
            this.loading = false
        },
        async updateStage(props) {
            this.updateReviewerModelkey++
            this.$nextTick(() => {
                this.$refs.updateReviewerModel.showModal(props)
            })
        },
        updatePaging(v) {
            this.paging = Object.assign(this.paging, v)
        }
    }
})