<template>
  <div>
    <label v-if="title" :class="`title title-color-${color}`">{{
      title
    }}</label>
    <div :class="`select-field ${hideError ? 'hide-error' : ''}`">
      <VueMultiselect
        :class="`field-color-${field}`"
        :id="name"
        v-model="_value"
        :options="options"
        :multiple="multiple"
        :close-on-select="multiple ? false : true"
        :clear-on-select="false"
        :preserve-search="false"
        :placeholder="placeholder"
        :preselect-first="false"
        :index="indexField"
      >
        <template v-slot:selection="{ values, search, isOpen }">
          <span class="multiselect__single" v-if="values.length && !isOpen"
            >{{ values.length }} options selected</span
          ></template
        >
      </VueMultiselect>

      <div v-if="_errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.select-field {
  position: relative;
  .multiselect {
    min-height: 34px;
    border: 0px;
  }
}

select {
  border: 0;
  border-radius: 0;
  outline: none;
  min-height: 34px;
  margin-bottom: 25px;
  width: 100%;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #ffff;
    color: #c4cad6;
    opacity: 1;
  }
}

.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
  font-weight: 400;
}

.hide-error {
  select {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}

.multiselect__tags {
  min-height: 34px;
  padding: 6px 40px 0 6px;
}
.title {
  margin-top: 0;
}
</style>

<style scoped>
.select-field :deep() .multiselect__tags {
  min-height: 34px;
  padding: 6px 40px 0 6px;
  border: 1px solid #949ba2;
}

.select-field :deep() .multiselect__placeholder {
  margin-bottom: 4px;
}

.select-field :deep() .multiselect__input {
  margin-bottom: 4px;
}

.select-field :deep() .multiselect__select {
  width: 35px;
  height: 33px;
  margin-right: 6px;
}
:deep() .multiselect__single {
  margin-bottom: 6px;
}
</style>
