import { defineComponent } from "vue";
import router from "@/config/router";

export default defineComponent({
  props: {
    props: { type: Object, default: null },
    class: { type: String, default: "" },
  },
  data() {
    return {
      eventPath: router.event.path,
    };
  },
  methods: {
    backToList() {
      this.$router.push({ path: this.eventPath });
    },
  },
});
