import adminServices from "@/services/admin";
import addPerspective from "./addPerspective";
import _ from "lodash";
import notify from "@/services/notify";
import Table, { debounce, Dropdown, DropdownItem } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import Dialog from "@/components/Dialog";
export default {
  components: {
    Table,
    TextField,
    SelectField,
    addPerspective,
    Dialog,
    Dropdown,
    DropdownItem,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      filteredStatus: null,
      filteredTitle: null,
      filteredMember: null,
      columns: [
        {
          id: "title",
          name: "Title",
          _wordBreak: "break-word",
          _minWidth: "200px",
        },
        {
          id: "member_name",
          name: "Author",
          _wordBreak: "break-word",
          _minWidth: "150px",
        },
        {
          id: "created_at",
          name: "Created Date",
          columnClass: "multiple-line",
        },
        {
          id: "is_active",
          name: "Active",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-right",
          columnClass: "text-right",
        },
      ],
      statusList: [
        {
          name: "All",
          code: null,
        },
        {
          name: "Active",
          code: true,
        },
        {
          name: "Inactive",
          code: false,
        },
      ],

      confirmText: {
        header: "",
        body: "",
      },

      itemModalKey: 0,
      memberList: [
        {
          name: "All",
          code: null,
        },
      ],
    };
  },
  watch: {
    filteredStatus: {
      deep: true,
      handler(v) {
        this.setPageDefault()
        debounce(this.getAll);
      },
    },
    filteredTitle: {
      deep: true,
      handler(v) {
        this.setPageDefault()
        debounce(this.getAll);
      },
    },
    filteredMember: {
      deep: true,
      handler(v) {
        this.setPageDefault()
        debounce(this.getAll);
      },
    },
  },
  filters: {
    getName(val, list) {
      let type = _.find(list, (x) => x.code === val);
      return type && type.name ? type.name : "";
    },
  },
  mounted() {
    debounce(this.getAll);
    this.getPerspectivesTeamData();
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getPerspectives({
        filteredStatus: this.filteredStatus,
        filteredTitle: this.filteredTitle,
        filteredMember: this.filteredMember,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d;
      }
      this.loading = false;
    },
    async getPerspectivesTeamData() {
      const res = await adminServices.getPerspectivesTeamData();
      if (res && res.data && res.data.ok) {
        var list = res.data.d;
        list.forEach((member) => {
          this.memberList.push({ name: member.name, code: member.id });
        });
      }
    },
    async addItem() {
      this.itemModalKey++;
      this.$nextTick(() => {
        this.$refs.itemModal.create(this.memberList);
      });
    },
    async editItem(props) {
      this.itemModalKey++;
      this.$nextTick(() => {
        this.$refs.itemModal.show(props, this.memberList);
      });
    },
    async updateStatus(props) {
      this.loading = true;
      const res = await adminServices.updateStatusPerspective(
        props.perspective_id,
        props.is_active ? false : true
      );
      if (res && res.data && res.data.ok) {
        notify.success("Update status successfully");
        this.getAll();
      } else {
        notify.error(res.data.d.errorMessage);
      }
      this.loading = false;
    },
    async deleteItem(props) {
      let message = {
        title: "Delete perspective",
        body: `Are you sure to delete this perspective? If you delete it, this perspective cannot be reverted`,
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.onConfirmToRemove(props);
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    async onConfirmToRemove(props) {
      this.loading = true;
      if (props) {
        const res = await adminServices.deletePerspective(props.perspective_id);
        if (res && res.data && res.data.ok) {
          notify.success("Delete successfully");
          this.getAll();
        } else {
          notify.error(res.data.d.errorMessage);
        }
      }
      this.loading = false;
    },

    async orderChanged(items) {
      let tmpData = _.cloneDeep(items);
      tmpData.map((x, idx) => {
        x.order_number = idx + 1;
        return x;
      });
      this.loading = true;
      const res = await adminServices.orderChangedPerspective(tmpData);
      if (res.data.ok) {
        notify.success("Ordered successfully!");
        this.getAll();
      }
      this.loading = false;
    },
  },
};
