import authService from "../../../services/auth";
import TextField from "@/components/TextField";
import router from "@/config/router";
import route from "../../../misc/route";
import ResendVerifyEmail from "../../../views/auth/resendVerifyEmail";
import appStore, { APP_MUTATION } from "../../../store/app";
import _ from "lodash";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";
import ReCaptcha from "@/components/ReCaptcha";

export default defineComponent({
  components: {
    LogoIcon,
    TextField,
    ReCaptcha,
  },
  data() {
    return {
      email: "",
      recaptcha: "",
      captchaInstance: {},
      loading: false,
      loginPath: router.login.path,
      homePath: router.home.path,
      resendVerifyRegisterEmail: route.register(
        "/register-resend-verify-email",
        "Resend Verify Register Email",
        ResendVerifyEmail
      ),
      success: false,
      clicked: false,
      errorMsg: {
        captcha: "",
        email: "",
      },
    };
  },
  computed: {
    disabled() {
      return this.loading || !this.email || !this.recaptcha;
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");
    appStore.commit(APP_MUTATION.use_empty_layout, true);
  },
  methods: {
    submit(token) {
      this.recaptcha = token;
      authService.sendVerifyEmail(this.email, this.recaptcha).then((resp) => {
        if (resp.error) {
          // notify.error('Resend verification email fail', resp.errorMsg)
        } else {
          this.success = true;
        }
      });
    },
    validate() {
      this.resetVaildate();
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.email)) {
        this.messageError.email = "Invalid email address";
        this.frmInvalidate = true;
      }

      if (this.frmInvalidate) {
        return;
      }
      this.submit(this.recaptcha);
      // this.$refs.recaptcha?.execute();
    },
    resetRecaptcha() {
      if (_.isFunction(this.captchaInstance.reset)) {
        this.captchaInstance.reset();
      }
    },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        email: null,
      };
    },
    confirm() {
      this.resetVaildate();
      if (!this.model.email) {
        this.messageError.email = "Email field is not empty";
        this.frmInvalidate = true;
      }
      if (!validate_email.validateEmail(this.model.email)) {
        this.messageError.email = "Your email is invalid";
        this.frmInvalidate = true;
      }
      if (!this.model.password) {
        this.messageError.password = "Password field is not empty";
        this.frmInvalidate = true;
      }
      // if (!this.model.recaptcha) {
      //     notify.error('You must solve the captcha first')
      //     this.frmInvalidate = true
      // }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      authService.sendVerifyEmail(this.email, this.recaptcha).then((resp) => {
        if (resp.error) {
          // notify.error('Resend verification email fail', resp.errorMsg)
        } else {
          this.success = true;
        }
      });
    },
  },
});
