import { defineComponent } from "vue";
import _ from "lodash";

import adminServices from "@/services/admin";
import notify from "@/services/notify";
import { PERMISSIONS } from "@/config/router";

import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import AddInvestorModel from "./components/AddInvestorModel";
import UpdateInvestorModel from "./components/UpdateInvestorModel";
import Dialog from "@/components/Dialog";
import { mappingDataPagination } from "@/utils/helpers";

const statusList = [
  {
    name: "All",
    code: "",
  },
  {
    name: "Active",
    code: "ACTIVE",
  },
  {
    name: "Inactive",
    code: "INACTIVE",
  },
  {
    name: "Invitation sent",
    code: "INVITATION_SENT",
  },
  {
    name: "Expired",
    code: "EXPIRED",
  },
];

const ndaList = [
  {
    name: "All",
    code: "",
  },
  {
    name: "Not Required",
    code: "NOT_REQUIRED",
  },
  {
    name: "Not signed",
    code: "NOT_SIGNED",
  },
  {
    name: "Signed",
    code: "SIGNED",
  },
];

const columnsData = [
  {
    id: "name",
    name: "Name",
    sort: true,
    direction: "",
  },
  {
    id: "email",
    name: "Email",
    sort: true,
    direction: "",
  },
  {
    id: "stt",
    name: "Status",
    columnClass: "multiple-line",
  },
  {
    id: "role_name",
    name: "Role",
  },
  {
    id: "sent_by",
    name: "Sent by",
    columnClass: "multiple-line",
  },
  {
    id: "nda",
    name: "Nda",
    columnClass: "multiple-line",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-right",
    columnClass: "text-right",
  },
];

export default defineComponent({
  components: {
    Table,
    Dropdown,
    DropdownItem,
    TextField,
    SelectField,
    AddInvestorModel,
    UpdateInvestorModel,
    Dialog,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },

      filteredName: "",
      filteredEmail: "",
      filteredSentBy: "",
      filteredStatus: "",
      filteredRole: "",
      filteredNda: "",
      orderBy: "",
      roleList: [],
      investorTypeList: [
        { name: "All", code: "" },
        { name: "Current Investor", code: "Current" },
        { name: "Potential Investor", code: "Potential" },
        { name: "Delegate", code: "Delegate" },
      ],
      statusList: [...statusList],
      ndaList: [...ndaList],
      columns: columnsData,
      PERMISSIONS: PERMISSIONS,
      addInvestorModelKey: 0,
      updateInvestorModelKey: 0,
    };
  },
  watch: {
    paging: {
      deep: true,
      handler() {
        debounce(this.getAll);
      },
    },
    filteredName() {
      this.setPageDefault();
    },
    filteredEmail() {
      this.setPageDefault();
    },
    filteredStatus() {
      this.setPageDefault();
    },
    filteredSentBy() {
      this.setPageDefault();
    },
    filteredRole() {
      this.setPageDefault();
    },
    filteredNda() {
      this.setPageDefault();
    },
    columns: {
      deep: true,
      handler(v) {
        let col = v.find(
          (p) => typeof p.sort === "boolean" && p.sort && p.direction
        );
        if (!col) return;
        this.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
  created() {
    if (!this.$route.meta.showInactiveStt) {
      this.statusList.splice(2, 1);
    }
    this.getAllInvestorRoles();
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAllInvestorRoles() {
      const res = await adminServices.getAllInvestorRoles({
        paging: this.paging,
        filteredStatus: "ACTIVE",
      });
      if (res.data.ok) {
        this.roleList = res.data.d.c;
        this.roleList.unshift({ id: "", name: "All", stt: "ACTIVE" });
      }
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getAllInvestors({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        orderBy: this.orderBy,
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
        filteredSentBy: this.filteredSentBy,
        filteredStatus:
          this.filteredStatus === ""
            ? this.statusList
                .filter((x) => x.code !== "")
                .map((x) => x.code)
                .join(",")
            : this.filteredStatus,
        filteredRole: this.filteredRole,
        filteredNda: this.filteredNda,
      });
      if (res && res.data && res.data.ok) {
        const cvData = mappingDataPagination(
          res.data.d.c,
          res.data.d.p,
          Math.ceil(res.data.d.t / res.data.d.s)
        );
        this.rows = cvData;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    async reinviteInvestor(item) {
      let message = {
        title: "Reinvite Investor",
        body: "Press button confirm to reinvite investor",
      };
      let options = {
        html: false,
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce",
        type: "basic",
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember",
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.reinviteInvestor({
            email: item.email,
          });
          if (res.data.ok) {
            notify.success("Reinvite investor success!");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async updateStatus(item) {
      let message = {
        title: item.locked ? "Activate Investor" : "Inactivate Investor",
        body: item.locked
          ? "Press button confirm to activate this investor"
          : "Press button confirm to inactivate this investor",
      };
      let options = {
        html: false,
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce",
        type: "basic",
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember",
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.updateInvestorStatus(item.id, {
            isLocked: !item.locked,
          });
          if (res.data.ok) {
            notify.success(
              item.isLocked
                ? "Activate user successfully"
                : "Inactivate user successfully"
            );
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async deleteUser(item) {
      let message = {
        title: "Delete User",
        body: "Press button confirm to delete this user",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.deleteUser(item.id);
          if (res.data.ok) {
            notify.success("Deleted successfully!");
            if (item.isLastItemInPage) {
              this.paging.page = this.paging.page - 1;
            }
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async resetUserLockout(item) {
      let message = {
        title: "Reset User Lockout",
        body: "Press button confirm to reset user lockout",
      };
      let options = {
        html: false,
        okText: "OK",
        cancelText: "Cancel",
        animation: "bounce",
        type: "basic",
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember",
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          const res = await adminServices.resetUserLockout(item.id);
          if (res.data.ok) {
            notify.success("Reset user lockout successfully");
            this.getAll();
          }
          this.loading = false;
        })
        .catch(function () {});
    },
    async addInvestor() {
      this.addInvestorModelKey++;
      this.$nextTick(() => {
        this.$refs.addInvestorModel.showAdd();
      });
    },
    async updateInvestor(item) {
      this.updateInvestorModelKey++;
      this.$nextTick(() => {
        this.$refs.updateInvestorModel.showUpdate(item);
      });
    },
    isTemporarilyLockedout(lockoutDate) {
      return lockoutDate > new Date().getTime();
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
