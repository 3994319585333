<template>
  <div class="adminContent p-3" v-loading="loading">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
    >
      <template #head_subject>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredDataContent"
        />
      </template>
      <template #head_created_username>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredCreatedEmail"
        />
      </template>
      <template #head_username>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredEmail"
        />
      </template>
      <template #row_subject="{ props }">
        <div>{{ mapData(props.subject, props.content) }}</div>
      </template>
      <template #row_username="{ props }">
        <div>{{ props.username }}</div>
        <div>{{ props.user_email }}</div>
      </template>
      <template #row_created_username="{ props }">
        <div>{{ props.created_username }}</div>
        <div>{{ props.created_user_email }}</div>
      </template>
      <template #row_status="{ props }">
        <div>{{ props.status }}</div>
        <div>{{ $filters.parseEstTime(props.sent_at) }}</div>
      </template>
      <template #row_created_at="{ props }">
        <div>{{ $filters.parseEstTime(props.created_at) }}</div>
      </template>
      <template #row_actions="{ props }">
        <div class="btn btn-primary action" @click="view(props)">
          <i class="fas fa-eye"></i>
        </div>
      </template>
    </Table>
    <Modal title="Email Content" v-model:value="isModalShow">
      <div class="email-content" v-if="selectedEmail">
        <div class="title">
          {{ mapData(selectedEmail.subject, selectedEmail.content) }}
        </div>
        <div
          class="email-data"
          v-html="renderJson(selectedEmail.content)"
        ></div>
      </div>
    </Modal>
    <!-- <UserManageModel 
            ref="userManageModel"
            :key="userManageModelKey"
            :roleList="roleList" 
            @refresh="getAll"
        /> -->
  </div>
</template>

<script src="./index.js"></script>
<style lang="scss" scoped>
.action {
  cursor: pointer;
}
.email-content {
  .title {
    color: white;
  }
  .email-data {
    font-size: 0.85em;
    border-top: 1px solid #ffffff30;
    color: #ffffffc7;
    margin-top: 5px;
    padding-top: 5px;
    overflow-wrap: break-word;
  }
  span {
    font-weight: bold;
  }
}
</style>
