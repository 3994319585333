<template>
    <div class="call-replay" v-loading="loading || loadingFiles">
        <p class="page-title">
            Investor Call Replay
        </p>
        <carousel-3d :width="itemWidth" :height="itemHeight" :controls-visible="true" v-if="!loadingFiles" @after-slide-change="onAfterSlideChange">
            <slide v-for="(item, i) in carouselItems" :index="i" :key="i">
                <!-- <template v-slot:name="{ isCurrent }"> -->
                    <div class="replay-item">
                        <div class="img" @click="showPreview(item.video, FileTypes.VIDEO, i, false)" :style='`background-image: url("${item.thumbnail}"); height: ${itemImgHeight}px`'>
                            <div class="overlay"></div>
                            <i class="play far fa-play-circle"></i>
                        </div>
                        <div class="actions">
                            <button class="btn btn-default doc" @click.prevent="showPreview(item.pdf, FileTypes.PDF, i, !item.docTitle)">{{item.docTitle}}</button>
                        </div>
                    </div>
                <!-- </template> -->
            </slide>
        </carousel-3d>
        <filePreview ref="filePreview"
            :fileUrl="preview.fileUrl"
            :fileType="preview.fileType"
             />
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.call-replay {
    .carousel-3d-container {
        .carousel-3d-slider {
            .carousel-3d-slide {
                background: #ccc;
            }
        }
    }

    .page-title {
        color: #fff;
        margin: 3rem 2rem;
        font-size: 1.5rem;
    }
    .carousel-3d-slide.current {
        .replay-item {
            .img {
                cursor: pointer;

                &:hover {
                    .overlay {
                        opacity: 0.1;
                    }
                }
            }
            .actions {
                .doc {
                    cursor: pointer;
                    &:hover {
                        background: #2f323b;
                    }
                }
            }
        }
    }
    .replay-item {
        .img {
            width: 100%;
            height: 400px;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                opacity: 0;
            }
            .play {
                font-size: 70px;
                color: #fff;
            }
        }
        .actions {
            padding: 1rem;
            .doc {
                background: #000;
                color: #0069d9 !important;
                width: 100%;
                border-radius: 10px;
                font-weight: 600;
                cursor: default;
                min-height: 35px;
            }
        }
        
        .title {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 1rem;
            color: #3F99EF;
        }
    }
    @media screen and (max-width: 768px) {
        .replay-item {
            .img {
                height: 300px;
            }
        }
    }
    @media screen and (max-width: 576px) {
        .replay-item {
            .img {
                height: 225px;
            }
        }
    }
}
/*.replay{
    display: block;
    overflow: hidden;
    .row {
        margin: 0px !important;
    }
    .sidebar{
        height: calc(100vh - 65px);
        overflow-y: auto;
        background: #0d1d29;
        padding-right: 0px;
        .listCompany{
            padding: 10px;
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    &.border-bottom{
                        border-bottom-color: #b5bbc1 !important;
                    }
                    &.active{
                        .item{
                            background-color: #5897E7;
                            color: #fff;
                            i {
                                padding-right: 4px;
                            }
                        }
                    }
                    .item{
                        text-align: center;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        padding: 10px 5px;
                        h3{
                            margin: 0;
                            @media screen and (max-width: 1100px) {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    .main{
        background-color: #0d1d29;
        padding-left: 0px !important;
        .content-header{
            .item{
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 5px;
                color: #909090;
                &.active{
                    background-color: #fff;
                    color: #333;
                }
                .icon{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    svg{
                        width: 40px;
                    }
                }
                .text{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .content-body{
            height: calc(100vh - 65px);
        }
    }
}
@media screen and (max-width: 992px) {
    .replay{
        > .row{
            display: block;
        }
        .sidebar,
        .main{
            flex: unset;
            max-width: 100%;
            padding: 0px;
        }
        .sidebar{
            height: auto;
            .listCompany{
                ul{
                    li{
                        display: inline-block;
                        margin-bottom: 0 !important;
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0px;
                        }
                        .item{
                            padding: 5px;
                        }
                    }
                }
            }
        }
        .main{
            .content-body{
                height: calc(100vh - 247px);
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .replay{
        .sidebar{
            padding-right: 15px;
            padding-left: 15px;
            .listCompany{
                padding: 0;
                ul{
                    display: flex;
                    padding: 10px;
                    li{
                        flex: 1;
                    }
                }
            }
        }
    }
}*/
</style>