<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">{{ id ? "Edit Fund" : "Add Fund" }}</h4>
          </div>
          <div class="modal-body" v-loading="loading">
            <form @submit.prevent="onSave" autocomplete="false">
              <div class="row mb-2">
                <div class="col col-6">
                  <div class="form-group">
                    <label class="mb-0">
                      Name <small class="colorRed">*</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      v-model="form.name"
                    />
                    <p class="m-0" v-if="errorMsg.name">
                      <small class="colorRed">{{ errorMsg.name }}</small>
                    </p>
                    <p class="m-0" v-if="form.name && form.name.length > 250">
                      <small class="colorRed"
                        >Max length is 250 characters</small
                      >
                    </p>
                  </div>
                </div>
                <div class="col col-6 d-flex align-items-center">
                  <div class="custom-switch-wrapper mt-4">
                    <Switcher
                      v-model:value="form.is_enabled"
                      title="Enabled"
                      :isHorizontal="true"
                      titleDirection="right"
                      :isShowText="false"
                      name="fund-edit"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-12">
                  <div class="form-group mb-1">
                    <label class="mb-0">
                      Description <small class="colorRed">*</small></label
                    >
                    <textarea
                      v-model="form.description"
                      class="form-control"
                      rows="5"
                    ></textarea>
                    <p class="m-0" v-if="errorMsg.description">
                      <small class="colorRed">{{ errorMsg.description }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="closeModal">Close</button>
            <button class="btn btn-primary" @click="onSave">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
