<template>
  <div>
    <div id="viewerContainer" tabindex="0" ref="viewerContainer">
      <div v-if="loading" class="lds-dual-ring"></div>
      <resizeSensor :initial="true" @resize="resizeScale" />
      <div id="viewer" class="pdfViewer" ref="viewer"></div>
    </div>
    <div class="control">
      <i
        class="fa fit-zoom pointer zoom-control"
        v-bind:class="[
          currentScale == 'page-width'
            ? 'fa-expand'
            : currentScale == 'page-actual'
            ? 'fa-compress'
            : 'fa-arrows-alt',
        ]"
        @click="drawScaled()"
      ></i>
    </div>
  </div>
</template>

​<style lang="scss" scoped>
#viewerContainer {
  background-color: #0d1d29;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
  .lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  * {
    padding: 0;
    margin: 0;
  }
}
.control {
  position: absolute;
  text-align: center;
  height: auto;
  width: 100%;
  bottom: 24px;
  font-size: 14px;
  .fit-zoom {
    border-radius: 50%;
    border: 1px solid #a2a2a2;
    line-height: 30px;
    width: 35px;
    margin-right: 40px;
    color: #4e4d4d;
    background: whitesmoke;
    opacity: 0.2;
    &:hover {
      opacity: 0.8;
    }
  }
  .zoom-control {
    float: right;
    opacity: 0.2;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>

<script src="./index.js"></script>
