<template>
  <div class="mb-2">
    <label v-if="title" :class="`title title-color-${color}`" :for="name">{{
      title
    }}</label>
    <div class="sub-title mb-3" v-if="fileUploaded">
      Your file uploaded:
      <a
        href="javascript:void(0)"
        title="View File"
        @click="viewFile(fileUploaded)"
        ><b>{{ fileUploaded.file_name }}{{ fileUploaded.extension }}</b></a
      >
    </div>
    <div
      :class="`text-field ${size ? 'size-' + size : ''} ${
        hideError ? 'hide-error' : ''
      }`"
    >
      <div v-if="dataAcceptValue" class="sub-title">
        Accept file type: {{ dataAcceptValue }}
      </div>
      <label
        :for="id"
        :class="{ custom: custom }"
        class="label-hover btn-style-v2"
      >
        <input
          :class="`field-color-${field}`"
          type="file"
          :id="id"
          :ref="id"
          :accept="`${acceptType === 'FILE_EXTENSION' ? dataAcceptValue : ''}`"
          @change="valueDocumentFnc(id)"
          :index="indexField"
        />
      </label>
      <div v-if="errorMsg" class="md-error">{{ _errorMsg }}</div>
    </div>
    <filePreview
      v-if="isShowFilePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
      :hideFunc="hideFilePreview"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import adminServices from "@/services/admin";
import filePreview, { parseFileType } from "../commons/filePreview";

export default defineComponent({
  props: {
    value: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    id: { type: String, default: "" },
    acceptType: { type: String, default: "" },
    acceptValue: { type: String, default: "" },
    custom: { type: Boolean, default: false },
    fileUploaded: { type: [Object, String], default: "" },
    indexField: { type: Number, default: 0 },
    isShowFilePreview: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      loading: false,
      dataAcceptValue: null,
      fileToken: null,
      hideError: false,
      previewFileType: "",
      _value: null,
    };
  },
  components: {
    filePreview,
  },
  mounted() {
    this.$nextTick(() => {
      try {
        let acceptValue = JSON.parse(this.acceptValue);
        this.dataAcceptValue = acceptValue.map((x) => `.${x}`).join();
      } catch (e) {}
    });
  },
  methods: {
    valueDocumentFnc: function (string) {
      let vue = this;
      this.$refs[string].parentNode.setAttribute(
        "data-after",
        this.$refs[string].files.length > 0
          ? this.$refs[string].files[0].name
          : "..."
      );
      if (this.$refs[string].files[0].name) {
        this._value = vue.$refs[string].files[0];
        this.hideError = true;
        this.$emit("update:value", this._value);
      }
    },
    async viewFile(item) {
      const fileType = parseFileType(item.extension);
      this.loading = true;
      const res = await adminServices.requestDownloadS3(item.s3key);
      if (res.data.ok) {
        this.showFilePreview(res.data.d, fileType);
      }
      this.loading = false;
    },
    showFilePreview(token, previewFileType) {
      this.fixedBody();
      this.fileToken = token;
      this.previewFileType = previewFileType;
      this.$refs.filePreview.showModal();
    },
  },
  computed: {
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.sub-title {
  font-family: "SF Pro Text";
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;

  letter-spacing: -0.25px;

  color: #aaaaaa;
  padding-bottom: 8px;
  a {
    text-decoration: underline !important;
  }
}
.label-hover {
  cursor: pointer;
}
.text-field {
  position: relative;
}
input {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 0;
  min-height: 34px;
  padding: 2px 7px;
  margin-bottom: 25px;
  font-size: 0.9em;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
}
.md-error {
  position: absolute;
  padding-top: 10px;
  color: #ee6e73;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  font-family: "SF Pro Text";
  color: #000;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  padding-bottom: 8px;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
.field-color-gray {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #35495e;
}
.custom {
  font-family: "SF Pro";
  border: 1px solid #949ba2;
  font-weight: 600;
  border-radius: 6px;
  &:before {
    content: "Upload Pitch Deck";
  }
  &:after {
    content: attr(data-after);
  }
  > input {
    display: none;
  }
}
</style>
