<template>
  <div class="about-us-page">
    <Section1 />
    <Section2 />
    <Section3 />
  </div>
</template>

<script>
import Section1 from "./comps/Section1.vue";
import Section2 from "./comps/Section2.vue";
import Section3 from "./comps/Section3.vue";

export default {
  components: {
    Section1,
    Section2,
    Section3,
  },
};
</script>

<style></style>
