import AdminServices from "@/services/admin";
import notify from "../../../../services/notify";
import _ from "lodash";
import { defineComponent } from "vue";
import Switcher from "@/components/Switcher";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  components: { Switcher },
  data() {
    return {
      form: {
        name: null,
        description: null,
        is_enabled: true,
      },
      errorMsg: {
        name: null,
        description: null,
      },
      loading: false,
    };
  },
  created() {
    if (this.id) {
      this.fetchFund(this.id);
    }
  },
  methods: {
    fetchFund(id) {
      this.loading = true;
      AdminServices.getFund(id)
        .then((resp) => {
          if (resp.data.ok) {
            this.form = _.cloneDeep(resp.data.d);
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    onSave() {
      let flag = true;
      if ($.trim(this.form.name) === "") {
        this.errorMsg.name = "Please input name";
        flag = false;
      }
      if ($.trim(this.form.description) === "") {
        this.errorMsg.description = "Please input description";
        flag = false;
      }

      if ($.trim(this.form.name) !== "" && this.form.name.length > 250) {
        flag = false;
      }

      if (flag) {
        if (this.id) {
          this.updateFund();
        } else {
          this.createFund();
        }
      }
    },
    createFund() {
      this.loading = true;
      AdminServices.createFund({
        name: this.form.name,
        description: this.form.description,
        isEnabled: this.form.is_enabled,
      })
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("complete", "Add");
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    updateFund() {
      this.loading = true;
      AdminServices.updateFund({
        id: this.id,
        name: this.form.name,
        description: this.form.description,
        isEnabled: this.form.is_enabled,
      })
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("complete", "Update");
          } else {
            notify.error("Error", resp.data.m);
          }
        })
        .finally(() => (this.loading = false));
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = null;
    },
    "form.description": function (v) {
      this.errorMsg.description = null;
    },
  },
});
