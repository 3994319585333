<template>
  <div :class="`data ${useSimpleLayout ? 'simple-layout' : ''}`">
    <div
      class="table-responsive-2"
      :style="{ 'overflow-y': hasScrollY ? '' : 'hidden' }"
    >
      <table class="table table-hover">
        <thead v-if="!hideHeader" :class="{ 'fixed-header': isFixedHeader }">
          <tr>
            <th class="align-top" v-if="hasCheckbox">
              <div class="title d-flex align-items-center">
                <input
                  type="checkbox"
                  v-model="checked"
                  class="pointer mb-1"
                  name="checkall"
                  @change="selectAll($event)"
                />
              </div>
            </th>
            <th class="align-top" v-if="showNoRow">
              <div class="title d-flex align-items-center">No.</div>
            </th>
            <th
              v-for="(column, columnI) in _columns"
              :key="`${columnI}-columnI`"
              :class="column.titleClass"
              scope="col"
            >
              <div
                class="title d-flex align-items-center"
                @click="headerCellClick(column)"
              >
                <span class="me-1">
                  {{ column.name }}
                </span>
                <template v-if="column.sort">
                  <span
                    :class="`me-1 ${
                      column.direction === 'asc' ? 'active' : ''
                    }`"
                    ><i class="fas fa-arrow-up"></i
                  ></span>
                  <span
                    :class="`me-1 ${
                      column.direction === 'desc' ? 'active' : ''
                    }`"
                    ><i class="fas fa-arrow-down"></i
                  ></span>
                </template>
              </div>
              <div class="slot-table"><slot :name="`head_${column.id}`" /></div>
            </th>
          </tr>
        </thead>
        <Dragger
          v-if="rows && rows.length > 0 && useDrag"
          v-model:value="_rows"
          component-tag="tbody"
          drag-element-tag="tr"
          ghost-class="ghost"
          component-class=""
          @change="orderChanged"
          @rowClick="rowClick"
          @orderChanged="orderChanged"
        >
          <template #default="{ props }">
            <td v-if="hasCheckbox" class="align-middle">
              <input
                type="checkbox"
                v-model="rows.checkbox"
                class="pointer"
                @change="onChangeCheckbox($event)"
              />
            </td>
            <td v-if="showNoRow" class="align-middle">
              {{
                rows.findIndex((x) => x == props) +
                1 +
                (paging.page - 1) * paging.size +
                customCurrentPage
              }}
            </td>
            <template
              v-for="(column, idx) in _columns"
              :key="`${idx}-columnRowI`"
            >
              <td
                :class="column.columnClass"
                :style="`word-break: ${
                  column._wordBreak ? column._wordBreak : ''
                }; min-width: ${column._minWidth ? column._minWidth : ''};`"
              >
                <slot
                  v-if="column && $slots[`row_${column.id}`]"
                  :name="`row_${column.id}`"
                  :props="props"
                />
                <div v-else>
                  {{
                    column.formatter
                      ? column.formatter(props[column.id])
                      : props[column.id]
                  }}
                </div>
              </td>
            </template>
          </template>
        </Dragger>
        <tbody v-else-if="_rows && _rows.length > 0 && !useDrag">
          <tr
            v-for="(row, rowI) in _rows"
            :key="`${rowI}-rowI`"
            :class="`${rowClass(row)} ${
              cursorPointer ? 'cursor-pointer' : ''
            } ${customRowsClass}`"
            @click="rowClick(row)"
          >
            <td v-if="hasCheckbox" class="align-middle">
              <input
                type="checkbox"
                class="pointer align-middle mb-1"
                v-model="row.checkbox"
                name="checkbox"
                @change="onChangeCheckbox($event, row)"
              />
            </td>
            <td v-if="showNoRow" class="align-middle">
              {{
                rowI + 1 + (paging.page - 1) * paging.size + customCurrentPage
              }}
            </td>
            <td
              v-for="(column, columnI) in _columns"
              :key="`${columnI}-${rowI}-columnRowI`"
              :class="column.columnClass"
              :style="`word-break: ${
                column._wordBreak ? column._wordBreak : ''
              }; min-width: ${column._minWidth ? column._minWidth : ''};`"
            >
              <slot
                v-if="$slots[`row_${column.id}`]"
                :name="`row_${column.id}`"
                :props="row"
              />
              <div v-else>
                {{
                  column.formatter
                    ? column.formatter(row[column.id])
                    : row[column.id]
                }}
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="loading">
          <tr>
            <td
              :colspan="showNoRow ? _columns.length + 1 : _columns.length"
              class="text-center"
            >
              <div
                class="spinner-border text-light spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="detectColSpanNoData()" class="text-center">
              No data
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="table-nav" v-if="showNoRow"> -->
    <div class="table-nav">
      <div>
        <slot name="footer" />
      </div>
      <!-- <div class="info">
				Displaying {{ pageInfo.from }} to {{ pageInfo.to }} of {{ totalItems }} items
			</div> -->
      <div v-if="totalItems && totalItems >= paging.size" class="d-flex">
        <!-- <select class="form-select" @input="selectSize">
					<option v-for="(option, optionI) in sizeOptions" :value="option" :selected="option == size" :key="`${optionI}-optionI`">{{option}}</option>
				</select> -->
        <ul v-if="_paging.length >= 1" class="pagination mb-2">
          <li
            v-for="(page, pageI) in _paging"
            :key="`${pageI}-pageI`"
            :class="`page-item ${page.id == currentPage ? 'active' : ''}`"
            @click="pageClick(page)"
          >
            <a class="page-link">
              <span v-if="page.id == 'next'"
                ><i class="fas fa-angle-right"
              /></span>
              <span v-else-if="page.id == 'prev'"
                ><i class="fas fa-angle-left"
              /></span>
              <span v-else-if="page.id == 'first'"
                ><i class="fas fa-angle-double-left"
              /></span>
              <span v-else-if="page.id == 'last'"
                ><i class="fas fa-angle-double-right"
              /></span>
              <span v-else>{{ page.id }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables_dark.scss";

.table-responsive-2 {
  margin-bottom: 1rem;
  border-radius: 3px;
}

.data {
  padding: 0.5rem 1rem 1rem 1rem;
}

.simple-layout {
  padding: 0;
  box-shadow: none;
  .table-responsive {
    margin-bottom: 0;
  }
}

tbody {
  background-color: #393c45;
}

thead {
  background-color: #393c45;
  .fas.fa-caret-up,
  .fas.fa-caret-down {
    color: white;
    font-size: 1.5em;
  }
  .active {
    .fas {
      color: #409eff;
    }
  }
  .title {
    min-height: 25.6px;
    display: inline-flex !important;
  }
}

.table-responsive {
  margin-bottom: 1rem;
  border-radius: 3px;
}

.table {
  margin-bottom: 0;
  tr:last-child {
    border-bottom: none;
  }
}

.header-cell {
  cursor: pointer;
  font-weight: bold;
  &.text-right {
    .title {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.table-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .info {
    font-size: 0.8em;
    opacity: 0.8;
  }
}

.pagination {
  margin-left: 15px;
}

.page-item {
  cursor: pointer;
  .page-link {
    background-color: transparent;
    border: 1px solid #585d6e;
    min-width: 35px;
    height: 35px;
    color: $text-color--secondary;
    display: block;
    text-align: center;
    padding: 0;
    span {
      line-height: 35px;
    }
  }
  &.active {
    .page-link {
      background-color: #4b596a;
      color: white;
    }
  }
}
.highlight {
  background: #272a34;
}

:deep() .action-icon-group {
  white-space: nowrap;
  button {
    background-color: transparent;
    color: $text-color--secondary !important;
    border-color: $color-primary;
    border-radius: 0.25rem;
    line-height: 1.5;
    color: $text-color;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    &.btn-danger {
      border-color: $color-danger;
    }
    &:hover {
      background-color: $color-primary--shaded;
      border-color: $color-primary;
      color: $text-color !important;
    }
    &.btn-small {
      padding: 0.2rem 0.5rem;
    }
  }
}

:deep().btn-primary {
  background-color: transparent;
  color: $text-color--secondary !important;
}

:deep() .custom-menu-dropdown.dropdown {
  position: unset;
  .dropdown-toggle {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .dropdown-toggle:after {
    content: none;
  }
  .dropdown-menu {
    background-color: $color-background-tertiary;
    .dropdown-item {
      color: $color-grey;
      &.router-link-active {
        color: rgb(255, 255, 255);
      }
      &:hover {
        background-color: $color-grey;
        color: $color-dark;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
:deep() .multiple-line {
  font-size: 0.9em;
}

.fixed-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}
</style>
