<template>
  <section class="section-4" id="#funds">
    <div class="bg">
      <div class="div0"></div>
      <div class="div1"></div>
      <div class="div2"></div>
      <div class="div3"></div>
      <!-- <div class="div4"></div> -->
    </div>

    <div class="gif-container">
      <div class="home-gif-bg"></div>
    </div>

    <div class="panel panel-0">
      <div class="our-funds-wrapper content container-v2">
        <div class="left">
          <div
            v-for="(item, index) in ourFundsList"
            :key="item.id"
            class="our-funds-item"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="100"
            data-aos-duration="500"
          >
            <div class="color-number" :style="{ backgroundColor: item.color }">
              {{ item.id }}
            </div>
            <div class="info">
              <p>{{ item.title }}</p>

              <div class="statistical-list">
                <div class="statistical">
                  <span>Vintage: {{ item.vintage }}</span>
                </div>

                <div class="statistical">
                  <span>
                    {{ index === 3 ? "Target Fund Size:" : "Fund Size:" }}
                    {{ item.fundSize }}</span
                  >
                </div>

                <div class="statistical">
                  <span>
                    {{ index === 3 ? "Target " : "" }} # of Portfolio Companies:
                    {{ item.investment }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="hover-text"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="300"
            data-aos-duration="500"
          >
            <h5 class="heading">Our</h5>
            <br />
            <h5 class="sub-heading">Funds</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-1">
      <div class="content container-v2">
        <div
          class="stt"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="90"
          data-aos-duration="500"
        >
          <span> F1 </span>
        </div>
        <div class="hover-text">
          <h5
            class="heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="100"
            data-aos-duration="500"
          >
            Fuel Venture Capital
          </h5>
          <br />
          <h6
            class="sub-heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            Flagship Fund I
          </h6>
        </div>
        <p
          class="desc"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          Fuel Venture Capital Fund I, LP is our inaugural fund which invested
          in category-defining tech companies carving out a new market and
          leveraging technology to scale and enable new product offerings. The
          portfolio consists of 33 carefully curated, tech-driven companies
          across multiple geographic regions, financing stages and sectors.
        </p>
      </div>
    </div>

    <div class="panel panel-2">
      <div class="content container-v2">
        <div
          class="stt"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="90"
          data-aos-duration="500"
        >
          <span> F2 </span>
        </div>
        <div class="hover-text">
          <h5
            class="heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="100"
            data-aos-duration="500"
          >
            Fuel Venture Capital
          </h5>
          <br />
          <h6
            class="sub-heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            Opportunity Pool
          </h6>
        </div>
        <p
          class="desc"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          The Opportunity Pool is an open-ended fund that consists of a growing
          list of co-investment opportunities spawned from direct investment
          opportunities made by Fuel Venture Capital funds. Investors are given
          the opportunity to co-invest alongside the firm and leverage the
          firm's scale to concentrate on high-conviction companies.
        </p>
      </div>
    </div>

    <div class="panel panel-3">
      <div class="content container-v2">
        <div
          class="stt"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="90"
          data-aos-duration="500"
        >
          <span> F3 </span>
        </div>
        <div class="hover-text">
          <h5
            class="heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="100"
            data-aos-duration="500"
          >
            Fuel Venture Capital
          </h5>
          <br />
          <h6
            class="sub-heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            FinTech Growth Fund (FTG)
          </h6>
        </div>
        <p
          class="desc"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          The FinTech Growth Fund (FTG) was built from the best performing
          FinTech companies in the Fuel Venture Capital funds. The five
          companies are all in the growth stage, generating high levels of
          revenue, forming relationships with key industry players, and
          attracting capital from leading global funds. Leveraging long term
          relationships, deep involvement, great influence as lead /co-lead
          investors, we were able to lock in creative terms, attractive
          valuations, and additional options to offer our investors unique
          risk-adjusted returns compared to traditional, single-asset funds, or
          other similar growth vehicles.
        </p>
      </div>
    </div>

    <!-- <div class="panel panel-4">
      <div class="content container-v2">
        <div
          class="stt"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="90"
          data-aos-duration="500"
        >
          <span> F4 </span>
        </div>
        <div class="hover-text">
          <h5
            class="heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="100"
            data-aos-duration="500"
          >
            Fuel Venture Capital
          </h5>
          <br />
          <h6
            class="sub-heading"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            Flagship Fund II
          </h6>
        </div>
        <p
          class="desc"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          Fuel Venture Capital Flagship II, LP is built upon the success of
          Flagship Fund I following a refined investment thesis and well-defined
          proprietary model of Phased Investing. Fund II will start out with 7
          warehouse companies that are currently thriving and which Fuel has
          already gained asymmetric knowledge of through earlier positions in
          Fund I. Fuel will continue to find additional disruptive global,
          tech-driven companies to construct a portfolio across multiple
          geographic locations, sectors and financing stages.
        </p>
      </div>
    </div> -->
  </section>
</template>

<script>
import FvcGif from "@/assets/images/v2/fvc-pattern.gif";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Section5 from "./Section5.vue";

export default {
  components: { Section5 },
  data() {
    return {
      ourFundsList: [
        {
          id: "F1",
          title: "Flagship Fund I",
          vintage: 2017,
          fundSize: "$162MM",
          investment: 33,
          color: "#34435e",
        },
        {
          id: "F2",
          title: "Opportunity Pool",
          vintage: 2018,
          fundSize: "$127MM",
          investment: 14,
          color: "#009988",
        },
        {
          id: "F3",
          title: "Fintech Growth Fund",
          vintage: 2020,
          fundSize: "$100MM",
          investment: 5,
          color: "#ffa630",
        },
        // {
        //   id: "F4",
        //   title: "Flagship Fund II",
        //   vintage: 2022,
        //   fundSize: "$300MM",
        //   investment: "30 - 35",
        //   color: "#fb3640",
        // },
      ],
    };
  },
  mounted() {
    const vue = this;

    const controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: ".section-4",
      duration: $(".section-4").height() + window.innerHeight || 1000,
      triggerHook: 0,
      offset: -window.innerHeight,
    })
      // .addIndicators({ name: "section 4" })
      .addTo(controller)
      .on("enter", function () {
        vue.renderLogo();
      })
      .on("leave", function () {
        vue.removeLogo();
      });

    this.renderParallaxAnimation();
  },

  methods: {
    renderLogo() {
      const giftEl = document.querySelector(".home-gif-bg");
      if (!giftEl) return;
      const gifBgStyle = giftEl.style;

      gifBgStyle.height = `100%`;
      gifBgStyle.backgroundRepeat = "round";
      gifBgStyle.backgroundImage = `url("${FvcGif}")`;
    },

    removeLogo() {
      const giftEl = document.querySelector(".home-gif-bg");
      if (!giftEl) return;
      const gifBgStyle = giftEl.style;

      gifBgStyle.height = `auto`;
      gifBgStyle.backgroundImage = `none`;
    },

    renderParallaxAnimation() {
      ScrollTrigger.create({
        trigger: ".panel-0",
        start: "top top",
        pin: true,
        scrub: true,
        // markers: true,
        pinSpacing: false,
      });

      ScrollTrigger.create({
        trigger: ".panel-1",
        start: "top top",
        pin: true,
        scrub: true,
        // markers: true,
        pinSpacing: false,
      });

      ScrollTrigger.create({
        trigger: ".panel-2",
        start: "top top",
        pin: true,
        scrub: true,
        // markers: true,
        pinSpacing: false,
      });

      ScrollTrigger.create({
        trigger: ".panel-3",
        start: "top top",
        pin: true,
        scrub: true,
        // markers: true,
        pinSpacing: false,
      });

      // ScrollTrigger.create({
      //   trigger: ".panel-4",
      //   start: "top top",
      //   pin: true,
      //   scrub: true,
      //   // markers: true,
      //   pinSpacing: false,
      //   end: "+=-100",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-4 {
  position: relative;

  > div {
    z-index: 3;
    overflow: hidden;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.panel {
  width: 100%;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  .hover-text {
    z-index: 2;
    &:hover {
      .heading,
      .sub-heading {
        &:after {
          transform: rotateX(0deg);
        }
      }
    }
  }

  .content {
    .heading,
    .sub-heading {
      display: inline-block;
      font-size: 55px;
      font-weight: 700;
      letter-spacing: -0.025em;
      text-transform: capitalize;
      line-height: 50px;
      margin: 0;

      &::after {
        display: block;
        content: "";

        transform: rotateX(90deg);
        transition: 0.5s;
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transform-origin: bottom;
      }

      @media (min-width: 768px) {
        font-size: 80px;
        font-weight: 700;
        letter-spacing: -0.025em;
        text-transform: capitalize;
        line-height: 78px;
      }

      @media (min-width: 1200px) {
        font-size: 96px;
      }
    }

    .stt {
      margin-bottom: 20px;
      margin-left: 5px;
      span {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        padding: 10px;
        border: 2px solid #fff;
      }

      @media (min-width: 576px) {
        margin-bottom: 40px;
      }
    }

    .heading {
      color: #000;
      padding-bottom: 14px;
      &::after {
        border-bottom: solid 8px #000;
      }
    }
    .sub-heading {
      color: #fff;

      &::after {
        border-bottom: solid 8px #fff;
      }

      @media (min-width: 576px) {
        margin-bottom: 55px;
      }
    }

    .desc {
      font-family: "SF Pro Text";
      max-width: 500px;
      /* text-align: justify; */

      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: #fff;
      margin-bottom: 24px;

      @media (min-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .our-funds-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: dense;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .left {
      order: 1;

      @media (min-width: 768px) {
        order: unset;
      }
      .our-funds-item {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 20px;

          @media (min-width: 1024px) {
            margin-bottom: 12px;
          }

          @media (min-width: 1500px) {
            margin-bottom: 40px;
          }
        }

        .color-number {
          flex-shrink: 0;
          width: 95px;
          height: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;

          font-size: 32px;
          line-height: 53px;

          text-transform: capitalize;
          text-align: center;
          text-transform: capitalize;

          @media (min-width: 576px) {
            width: 107px;
            height: 130px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
          padding-top: 2px;

          p {
            font-size: 27px;
            font-weight: 700;
            line-height: 100%;
            white-space: nowrap;

            text-transform: capitalize;

            @media (min-width: 1024px) {
              font-size: 32px;
            }

            @media (min-width: 1440px) {
              font-size: 36px;
              line-height: 36px;
            }
          }

          .statistical {
            font-size: 24px;
            font-weight: 700;
            line-height: 20px;
            &:not(:last-child) {
              padding-bottom: 12px;
            }

            &:last-child {
              padding-bottom: 2px;
            }

            @media (min-width: 576px) {
              &:not(:last-child) {
                padding-bottom: 12px;
              }

              &:last-child {
                padding-bottom: 6px;
              }
            }

            @media (min-width: 1440px) {
              font-size: 26px;
            }

            @media (min-width: 1440px) {
              font-size: 27px;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: flex-end;

      @media (min-width: 768px) {
        justify-content: flex-end;
      }

      .hover-text {
        display: flex;
        gap: 10px;
        text-align: right;

        @media (min-width: 576px) {
          display: block;
          gap: unset;
        }
        .heading,
        .sub-heading {
          font-size: 70px;
          line-height: 80%;

          @media (min-width: 576px) {
            font-size: 100px;
          }

          @media (min-width: 1024px) {
            font-size: 160px;
          }
        }
      }
    }
  }
}

.bg {
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;

  .div0,
  .div1,
  .div2,
  .div3,
  .div4 {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }

  .div0 {
    background: #0095ff;
  }
  .div1 {
    background: #34435e;
  }
  .div2 {
    background: #009988;
  }
  .div3 {
    background: #ff8800;
  }
  .div4 {
    background: #fb3640;
  }
}

.gif-container {
  position: fixed;
  width: 45%;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 5vw;
  mix-blend-mode: screen;
  z-index: 1;

  display: none;
  @media (min-width: 576px) {
    display: block;
  }
}
</style>
