<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideRestoreFileItems"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">Restore Items</h4>
                    </div>
                    <div class="modal-body custom-modal-body" v-loading="loading">
                        <div class="content-wrapper">
                            <div class="section-left-wrapper">
                                <ul class="list-group bordered" v-if="itemsToRestore">
                                    <li class="list-group-item item-header text-center nowrap">Items to restore</li>
                                    <li class="list-group-item text-start item-content"
                                        v-for="(item, idx) in itemsToRestore"
                                        :key="idx">
                                        <div class="wordBreak">
                                            <span :style="{'color': getDataRoomItemIconColor(item.type, item.current_extension)}"><i class="item-icon" :class="getDataRoomItemIconClass(item.type, item.current_extension)"></i></span><span class="noselect">{{item.name}}</span>
                                        </div>
                                        <span v-if="item.isRestored" class="icon success"><i class="fas fa-check-double"></i></span>
                                    </li>
                                </ul>
                            </div>
                            <div class="section-right-wrapper"  v-loading="loadingFolder">
                                <div class="card card-wrapper" v-if="folderDetail">
                                    <div class="card-header">
                                        <div class="left-side">
                                            <div v-if="folderDetail.id > 0" class="icon-rounded-wrapper" @click="goToFolder(folderDetail.parent_id)">
                                                <i class="fas fa-arrow-left icon"></i>
                                            </div>
                                            <span>{{ folderDetail.name }}</span>
                                        </div>
                                        <button class="btn btn-default btn-small" @click="restore" :disabled="loading || !isValidToProcess">Restore here</button>
                                    </div>
                                    <div class="card-body">
                                        <template v-if="folderDetail.children">
                                            <div v-for="(item, idx) in folderDetail.children" :key="idx"
                                                @click="oneClick(item)"
                                                class="card-item" :class="{ 'selectable': item.type === 'Folder' }">
                                                <span :style="{'color': getDataRoomItemIconColor(item.type, item.current_extension)}"><i class="item-icon" :class="getDataRoomItemIconClass(item.type, item.current_extension)"></i></span><span class="noselect">{{item.name}}</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" :disabled="loading" @click="hideRestoreFileItems">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.custom-modal-body {
    overflow: hidden;
    overflow-y: auto;
    display: block;
    height: calc(100vh - 180px);
    padding-left: 0;
    padding-right: 0;
    .content-wrapper {
        display: flex;
        .section-left-wrapper {
            flex-basis: 250px;
            flex-grow: 1;
            flex-shrink: 1;
            flex-wrap: wrap;
            margin-right: 15px;
        }
        .section-right-wrapper {
            flex-basis: 300px;
            flex-grow: 2;
            flex-shrink: 1;
            flex-wrap: wrap;
        }
    }
}
.list-group {
    &.bordered {
        border: 1px solid $color-default;
        border-radius: 4px;
        color: $text-color--secondary;
    }
    .list-group-item {
        border: none;
        background-color: transparent;
        padding: .25rem 1rem;
        font-size: 13px;
        font-weight: 200;
        &:not(:first-child) {
            border-top: 1px solid $color-default;
        }
        &.item-header {
            font-size: 14px;
            font-weight: 500;
            color: $text-color;
        }
        &.item-content {
            display: flex;
            justify-content: space-between;
            color: $text-color--secondary;
            .icon.success {
                color: #409EFF;
            }
        }
    }
}
.card.card-wrapper {
    background-color: transparent;
    box-shadow: 0px 0px 4px 4px rgba(41,41,41,0.5);
    .card-header {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .75rem 1rem;
        .left-side {
            display: flex;
            align-items: center;
            .icon-rounded-wrapper {
                cursor: pointer;
                text-align: center;
                font-size: 11px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-left: -5px;
                &:hover {
                    background-color: $color-background-tertiary;
                    box-shadow: 0px 0px 4px 4px rgba(41,41,41,0.5);
                }
                .icon {
                    margin-top: 6px;
                }
            }
        }
    }
    .card-body {
        font-size: 14px;
        font-weight: 200;
        padding: 1rem 0;
        .card-item {
            padding: 0.25rem 1rem;
            &.selectable {
                cursor: pointer;
                &:hover {
                    background-color: $color-background-gray-dark;
                    color: #fafafa;
                }
            }
            &:not(.selectable) {
                opacity: 0.4;
            }
        }
    }
}
.item-icon {
    width: 22px;
}
</style>