import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'uploadFile',
    props: ['parentId', 'hideUploadFile'],
    data() {
        return {
            formData: {
                parentId: null,
            },
            loading: false,
            files: [],
            existingFilesInFolder: [],
            specialCharacterMsg: 'Name cannot contain characters * : " \\ | < > \/ ?',
            uploadKey: 0
        }
    },
    computed: {
        numberFilesToupload() {
            let _files = this.files.filter( x => !x.isUploaded && x.isNameValid && (!x.isDuplicated || (x.isDuplicated && ['UPGRADE'].includes(x.duplicationMode))) )
            return _files.length
        }
    },
    mounted() {
        if (this.parentId !== null && this.parentId !== undefined) {
            this.formData.parentId = this.parentId
            this.fetchFilesInFolder()
        }
    },
    methods: {
        fetchFilesInFolder() {
            this.loading = true
            AdminServices.getDataroomItemFilesByParentId(this.parentId).then(res => {
                if (!res.error) {
                    this.existingFilesInFolder = res.data.d
                }
            }).finally(() => { this.loading = false })
        },
        isFileNameInFolder(fileName) {
            const _index = this.existingFilesInFolder.findIndex(x => x.name === fileName)
            return _index > -1 ? true : false
        },
        async validateForm() {
            this.loading = true
            let v2Needed = await this.validateFilesBeforeUploading()
            await this.uploadFiles(v2Needed)
            this.fetchFilesInFolder()
            let _itemsNotUploaded = this.files.filter(x => !x.isUploaded)
            if (!_itemsNotUploaded || _itemsNotUploaded.length === 0) {
                this.hideUploadFile()
            }
        },

        isLargeFile(fileSize) {
            return fileSize >= 52428800
        },

        async validateFilesBeforeUploading() {
            let that = this
            let reqArr = []
            let needUploadV2 = false
            this.files.forEach(x => {
                if (!x.isUploaded && x.isNameValid && (!x.isDuplicated || (x.isDuplicated && ['UPGRADE'].includes(x.duplicationMode)))) {
                    let lastDot = x.file.name.lastIndexOf('.')
                    let _data = {
                        parentId: this.formData.parentId,
                        duplicationMode: x.duplicationMode,
                        fileName: x.file.name.substring(0, lastDot),
                        fileExtension: x.file.name.substring(lastDot)
                    }
                    if (that.isLargeFile(x.file.size)) {
                        needUploadV2 = true
                    }
                    x.isUploading = true
                    reqArr.push(AdminServices.validateDataroomFile(_data))
                } else {
                    reqArr.push(null)
                }
            })
    
            const resArr = await Promise.all(reqArr)
            resArr.forEach((resItem, index) => {
                if (resItem) {
                    if (resItem.data.ok) {
                        this.files[index].isDuplicated = false
                        this.files[index].otherErrorMsg = ''
                    } else {
                        if (resItem.data.c === 'file_name_existed') {
                            this.files[index].isDuplicated = true
                            this.files[index].otherErrorMsg = ''
                        } else if (resItem.data.m) {
                            this.files[index].otherErrorMsg = resItem.data.m
                        } else {
                            this.files[index].otherErrorMsg = 'Oops! An error has occurred. Please retry.'
                        }
                    }
                    this.files[index].isUploading = false
                }
            })
            return needUploadV2
        },

        async uploadFiles(v2Needed) {
            let that = this
            if (v2Needed) {
                var _awsCred = await AdminServices.getSessionToken()
                AWS.config.update({
                    region: _awsCred.data.d.region,
                    credentials: new AWS.Credentials(_awsCred.data.d.access_key, _awsCred.data.d.secret_key, _awsCred.data.d.session_token)
                })
            }
            let uploadArr = [], reqArr = [], reqArrData = []
            
            this.files.forEach(x => {
                if (!x.isUploaded && x.isNameValid && (!x.isDuplicated || (x.isDuplicated && ['UPGRADE'].includes(x.duplicationMode)))) {
                    x.isUploading = true
                    if (that.isLargeFile(x.file.size)) {
                        let lastDot = x.file.name.lastIndexOf('.')
                        let upload = new S3.ManagedUpload({
                            params: {
                                Bucket: _awsCred.data.d.bucket,
                                Key: this.getRandomKey(12) + x.file.name.substring(lastDot),
                                Body: x.file
                            }
                        })
                        upload.on('httpUploadProgress', progress => {
                            if (progress.total) {
                                x.progress = Math.round((100 * progress.loaded) / progress.total)
                            }
                        })

                        uploadArr.push(upload.promise())

                        reqArrData.push({
                            parentId: this.formData.parentId,
                            duplicationMode: x.duplicationMode,
                            fileName: x.file.name.substring(0, lastDot),
                            fileExtension: x.file.name.substring(lastDot),
                            fileSize: x.file.size,
                            isUploadV2: true,
                            isUploadFolder: false
                        })
                    } else {
                        let formData = new FormData()
                        formData.append('parentId', this.formData.parentId)
                        formData.append('duplicationMode', x.duplicationMode)
                        formData.append('dataroomItem', x.file)

                        uploadArr.push(null)
                        reqArrData.push({formData, isUploadV2: false})
                    }
                } else {
                    uploadArr.push(null)
                    reqArrData.push(null)
                }
            })

            let awsArrRes = await Promise.all(uploadArr)
            awsArrRes.forEach((_awsRes, idx) => {
                if (_awsRes) {
                    if (reqArrData[idx] && reqArrData[idx].isUploadV2) {
                        reqArrData[idx].fileS3Url = _awsRes.Key
                    }
                }
            })

            reqArrData.forEach((_req, idx) => {
                if (_req) {
                    if (_req.isUploadV2) {
                        reqArr.push(AdminServices.createDataroomFileV2(_req))
                    } else {
                        reqArr.push(AdminServices.createDataroomFile(_req.formData, event => {
                            this.files[idx].progress = Math.round((95 * event.loaded) / event.total)
                        }))
                    }
                } else {
                    reqArr.push(null)
                }
            })

            let resArr = await Promise.all(reqArr)
            resArr.forEach((resItem, index) => {
                if (resItem) {
                    if (resItem.data.ok) {
                        this.files[index].isUploaded = true
                        this.files[index].isDuplicated = false
                        this.files[index].otherErrorMsg = ''
                    } else {
                        if (resItem.data.c === 'file_name_existed') {
                            this.files[index].isDuplicated = true
                            this.files[index].otherErrorMsg = ''
                        } else if (resItem.data.m) {
                            this.files[index].otherErrorMsg = resItem.data.m
                        } else {
                            this.files[index].otherErrorMsg = 'Oops! An error has occurred. Please retry.'
                        }
                    }
                    this.files[index].isUploading = false
                    this.files[index].progress = 0
                }
            })
        },

        addFiles() {
            this.$refs.files.click()
        },
        removeFile(index) {
            this.files.splice(index, 1)
            this.$nextTick(() => { this.uploadKey += 1 })
        },
        handleFilesUpload() {
            this.loading = true
            let validFiles = []
            for (const _file of this.$refs.files.files) {
                if (_file.name.lastIndexOf('.') > 0) {
                    validFiles.push(_file)
                }
            }
            let uploadedFiles = validFiles
            let fileNameArr = this.files.map(x => {
                return (x.file && !x.isUploaded) ? x.file.name : ''
            })
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                if (!fileNameArr.includes(uploadedFiles[i].name)) {
                    this.files.push({
                        file: uploadedFiles[i],
                        isDuplicated: false,
                        duplicationMode: '',
                        isUploaded: false,
                        isUploading: false,
                        progress: 0,
                        otherErrorMsg: '',
                        isNameValid: this.validateFileAndDirectoryName(uploadedFiles[i].name)
                    })
                }
            }
            this.$refs.files.value = ''
            this.loading = false
        },
    },
    beforeUnmount() {
        $('.custom-modal .modal-dialog').css({ 'transform': '', top: '' })
    }
    
})