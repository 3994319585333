<template>
  <Modal
    :title="`INVITE YOUR ATTENDEE`"
    v-model:value="isShow"
    closeBtnText="Close"
    :showCloseBtn="false"
  >
    <div class="container createNotifications">
      <div class="col nav-right mb-2">
        <ul class="nav">
          <li class="nav-item">
            <a
              class="nav-link px-4 pointer"
              :class="{ active: currentTab === 'attendee' }"
              @click.prevent="toTab('attendee')"
              >Attendee</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link px-4 pointer"
              :class="{ active: currentTab === 'potential_attendee' }"
              @click.prevent="toTab('potential_attendee')"
              >Potential Attendee</a
            >
          </li>
        </ul>
      </div>
      <div class="container-fluid">
        <div class="users" v-if="currentTab === 'attendee'">
          <Table
            useSimpleLayout
            :paging="paging"
            @updatePaging="updatePaging"
            :columns="columns"
            :rows="rows"
            :showNoRow="true"
            @rowClick="selectUser"
          >
            <template #head_name>
              <TextField
                placeholder="Type to search .."
                hideError
                v-model:value="filteredName"
              />
            </template>
            <template #head_email>
              <TextField
                placeholder="Type to search .."
                hideError
                v-model:value="filteredEmail"
              />
            </template>
            <template #row_name="{ props }">
              {{ props.firstName }} {{ props.lastName }}
            </template>
            <template #head_roleName>
              <SelectField
                v-model:value="filteredInvestorRole"
                :options="InvestorRoleList"
                hideError
              />
            </template>
            <template #row_actions="{ props }">
              <template v-if="isUserSelecting(props)">
                <i class="fas fa-check-circle"></i>
              </template>
              <template v-else>
                <div class="empty-icon"></div>
              </template>
            </template>
          </Table>
        </div>
        <div
          class="potentialAttendee"
          v-if="currentTab === 'potential_attendee'"
        >
          <div
            class="row"
            v-for="(item, index) in listPotentialAttendee"
            :key="index"
          >
            <div class="col-12 col-sm-5">
              <TextField
                :name="`${item.id},name`"
                :id="item.id"
                title="Name"
                :indexField="item.id"
                :fieldRequire="true"
                :errorMsg="item.errorName"
                v-model:value="item.name"
                @handleValue="handleField"
              />
            </div>
            <div class="col-12 col-sm-5">
              <TextField
                :name="`${item.id},email`"
                :id="item.id"
                title="Email"
                :indexField="item.id"
                :fieldRequire="true"
                :errorMsg="item.errorEmail"
                v-model:value="item.email"
                @handleValue="handleField"
              />
            </div>
            <div class="col-12 col-sm-2 action">
              <NewButton
                class="btn btn-danger me-2"
                @click="deleteItem(item.id)"
              >
                <i class="far fa-trash-alt"></i>
              </NewButton>
              <NewButton @click="addItem" class="btn-success"
                ><i class="fas fa-plus"></i
              ></NewButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-right me-2"
      v-if="
        currentTab === 'potential_attendee' &&
        listPotentialAttendee.length === 0
      "
    >
      <NewButton @click="addItem" class="btn-success"
        ><i class="fas fa-plus"></i
      ></NewButton>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex align-items-center">
          <span v-if="currentTab === 'attendee'"
            >{{ usersGetNoti.length }} user{{
              usersGetNoti && usersGetNoti.length > 1 ? "s" : ""
            }}
            selected</span
          >
        </div>
        <div>
          <NewButton
            class="me-1"
            @click="getAllUsers()"
            v-if="currentTab === 'attendee'"
            >Select All</NewButton
          >
          <NewButton
            class="me-1"
            @click="clearUsers()"
            v-if="currentTab === 'attendee'"
            >UnSelect All</NewButton
          >
          <NewButton @click="addUsers()">OK</NewButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script src="./index.js"></script>

<style lang="scss">
.fa-check-circle {
  color: #00c500;
}

.empty-icon {
  width: 14px;
  height: 14px;
}

.createNotifications {
  .text-field input {
    font-size: 1rem;
    color: #91989f;
    border-radius: 3px;
  }
}
.potentialAttendee {
  .action {
    display: flex;
    align-items: center;
    margin-top: 9px;
  }
}
</style>
