<template>
  <div v-if="useEmptyLayout" class="main main-v2">
    <Header :showNavbar="showNavbar" />
    <div class="main-inner">
      <router-view />
    </div>
    <Footer />

    <button class="scroll-to-top-btn" @click="scrollToTop">
      <svg
        data-name="1-Arrow Up"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <path
          d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import appStore, { APP_MUTATION } from "@/store/app";

import Header from "./header/Header.vue";
import Footer from "./footer/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      displayMenu: false,
    };
  },
  computed: {
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
  },
  watch: {
    "$route.path": {
      handler: function(path) {
        if (path === "/") {
          // sticky header
          window.addEventListener("scroll", this.onScroll);
        } else {
          window.removeEventListener("scroll", this.onScroll);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    appStore.commit(APP_MUTATION.use_empty_layout, true);
    // button back to top
    window.addEventListener("scroll", this.handleScrollBackToTop);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScrollBackToTop);
  },
  methods: {
    handleScrollBackToTop() {
      let scrollToTopBtn = document.querySelector(".scroll-to-top-btn");
      let rootElement = document.documentElement;
      // Do something on scroll - 0.15 is the percentage the page has to scroll before the button appears
      // This can be changed - experiment
      let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.15) {
        // Show button
        scrollToTopBtn.classList.add("show-btn");
      } else {
        // Hide button
        scrollToTopBtn.classList.remove("show-btn");
      }
    },

    scrollToTop() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<style lang="scss">
.scroll-to-top-btn {
  background-color: #fff;

  cursor: pointer;

  width: 40px;
  height: 40px;
  border: 1px solid #000;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 100;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;

  &:hover {
    background-color: #f1f1f1;
  }

  svg {
    width: 14px;
  }
}

.show-btn {
  opacity: 1;
  transform: translateY(0);
}

.main-inner {
  padding-top: 74px;
}
/*fade*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*fade-transform*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
