import { BUS_TYPE } from "../../../misc/bus-type";
import appStore, { APP_MUTATION } from "../../../store/app";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      timeOut: null,
      timing: 100,
      windowHeight: null,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
        wheelSpeed: 0.5,
        swipeEasing: true,
      },
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initSlide(this.activeSlide);
    // });
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.staticBody();
        this.$emit("close");
      }
    });
    // window.addEventListener("resize", this.onResize);
    // this.onResize();
  },
  methods: {
    closeWithEsc() {
      window.addEventListener("keyup", (e) => {
        if (e.keyCode === 27) {
          this.staticBody();
        }
      });
    },
    initSlide(index) {
      if (!document.querySelector(".our-team-swiper")) {
        setTimeout(() => {
          this.initSlide(index);
        }, 500);
        return;
      }
      this.mySwiper = new Swiper(".our-team-swiper", {
        initialSlide: index,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".arrowRight",
          prevEl: ".arrowLeft",
        },
      });
    },
    hideSlide() {
      appStore.commit(APP_MUTATION.change_popup, false);
      this.staticBody();
      this.$emit("close");
    },
    // onResize() {
    //   if (window.innerwidth >= 768) {
    //     this.windowHeight = null;
    //   } else {
    //     this.windowHeight = window.innerHeight - 50;
    //   }
    // },
  },
  watch: {
    windowSize(v) {
      if (v >= 768) {
        this.windowHeight = null;
      } else {
        this.windowHeight = window.innerHeight - 50;
      }
    },
    modalShow(v) {
      if (v) {
        this.initSlide(this.activeSlide);
      } else {
        if(this.mySwiper) {
          this.mySwiper.destroy(true, true);
        }
      }
    },
  },
  computed: {
    activeSlide() {
      return appStore.state.activeSlideOurTeam;
    },
    windowSize() {
      return this.$store.state.mobile.size;
    },
  },
  beforeUnmount() {
    appStore.commit(APP_MUTATION.active_slide_our_team, 0);
    this.staticBody();

    // todo
    window.removeEventListener("keyup", null);
  },
});
