<template>
    <div class="row header">
      <div class="col d-flex align-items-center" @click="backToList">
        <div class="routeback"><i class="fas fa-chevron-left"></i></div>
        <h3 class="mb-0" v-if="props">
          <template v-if="props">{{props.name}}</template>
          <template v-else>Back</template>
        </h3>
      </div>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.header {
    border-bottom: 1px solid #3A3E47;
    padding: 15px 0;
    background-color: #292C33;
    max-height: 65px;

    .header-text {
      color: #fff;
      font-size: 24px;
      vertical-align: middle;
      line-height: 53px;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      line-height: 34px;
    }
  }
.routeback {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    background: #0095ff;
    transition: all 200ms ease;
    margin-right: 10px;

    &:hover {
    background: #0070c0;
    }
}
</style>