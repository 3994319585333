<template>
  <div>
    <div v-if="!isHorizontal">
      <label v-if="title" :class="`title title-color-${color}`" :for="name">{{
        title
      }}</label>
      <div :class="`text-field`">
        <input
          type="checkbox"
          :checked="value"
          :id="name"
          v-model="_value"
          :index="indexField"
        />
      </div>
    </div>
    <div v-else>
      <div class="custom-checkbox">
        <input
          class="h-checkbox"
          type="checkbox"
          :checked="value"
          :id="name"
          v-model="_value"
          :index="indexField"
        />
        <label v-if="title" :class="`title title-color-${color}`" :for="name">{{
          title
        }}</label>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.text-field {
  position: relative;
}

.custom-checkbox {
  display: flex;
  .h-checkbox {
    margin-right: 5px;
  }
}

input {
  width: auto;
  outline: none;
  border: 0;
  border-radius: 0;
  min-height: 34px;
  padding: 2px 7px;
  font-size: 0.9em;
  background: #494b54;
  color: #949ba2;
  &:disabled {
    background: #494b54;
    color: #949ba2;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.md-error {
  position: absolute;
  top: 37px;
  left: 0;
  color: #f52727;
  font-size: 0.8em;
}

.hide-error {
  input {
    margin-bottom: 0;
  }
  .md-error {
    display: none;
  }
}

.size-lg {
  input {
    min-height: 42px;
    padding: 2px 15px;
  }
}
.title-color-black {
  color: #000;
}
.field-color-white {
  background: #ffff;
  // color: #0000 !important;
  border: 1px solid #949ba2;
  border-radius: 5px;
  color: #949ba2;
}
</style>
