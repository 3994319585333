<template>
  <div>
    <div :id="id" :class="classList" class="collapse-animation">
      <slot name="content" :items="matches"></slot>
    </div>
    <p v-if="seeMore && currentTab !== 'Live now'">
      <a
        href="#"
        @click.prevent="collapse()"
        class="btn btn-block bgColorDark2 colorGray p-y-3 seemore"
        >...See more</a
      >
    </p>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.collapse-animation {
  transition: height 0.3s linear;
  overflow: hidden;
}
</style>
