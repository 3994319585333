<template>
  <div class="paging" id="paging" :class="customClass">
    <ul id="page">
      <li v-if="prev.link !== '' && prev.dataPage !== ''">
        <span>
          <a
            v-bind:href="prev.link"
            v-bind:data-page="prev.dataPage"
            @click.prevent="loadContentAtPage(prev.dataPage)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 4.68 8.109"
              style="width: 7px"
            >
              <path
                id="Path_24"
                data-name="Path 24"
                class="btnPrev"
                d="M16.43,16.859,13,13.43,16.43,10"
                transform="translate(-12.375 -9.375)"
              />
            </svg>
          </a>
        </span>
      </li>

      <li v-if="edgeLeft.link !== '' && edgeLeft.dataPage !== ''" class="pc">
        <span
          ><a
            v-bind:href="edgeLeft.link"
            v-bind:data-page="edgeLeft.dataPage"
            @click.prevent="loadContentAtPage(edgeLeft.dataPage)"
            >{{ edgeLeft.dataPage }}</a
          ></span
        >
      </li>

      <li v-if="dotLeft" class="pc">
        <span><a href="javascript:void(0);" class="text">...</a></span>
      </li>

      <li v-for="item in leftSide" v-bind:key="item">
        <span
          ><a
            v-bind:href="item.link"
            v-bind:data-page="item.dataPage"
            @click.prevent="loadContentAtPage(item.dataPage)"
            >{{ item.dataPage }}</a
          ></span
        >
      </li>

      <li v-if="current.link !== '' && current.dataPage !== ''">
        <span
          ><a
            class="active"
            href="javascript:void(0)"
            v-bind:data-page="current.dataPage"
            >{{ current.dataPage }}</a
          ></span
        >
      </li>

      <li v-for="item in rightSide" v-bind:key="item">
        <span
          ><a
            v-bind:href="item.link"
            v-bind:data-page="item.dataPage"
            @click.prevent="loadContentAtPage(item.dataPage)"
            >{{ item.dataPage }}</a
          ></span
        >
      </li>

      <li v-if="dotRight" class="pc">
        <span><a href="javascript:void(0);" class="text">...</a></span>
      </li>

      <li v-if="edgeRight.link !== '' && edgeRight.dataPage !== ''" class="pc">
        <span
          ><a
            v-bind:href="edgeRight.link"
            v-bind:data-page="edgeRight.datpage"
            @click.prevent="loadContentAtPage(edgeRight.dataPage)"
            >{{ edgeRight.dataPage }}</a
          ></span
        >
      </li>

      <li v-if="next.link !== '' && next.dataPage !== ''">
        <span>
          <a
            v-bind:href="next.link"
            v-bind:data-page="next.dataPage"
            @click.prevent="loadContentAtPage(next.dataPage)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 4.68 8.109"
              style="width: 7px"
            >
              <g
                id="small-left"
                transform="translate(17.055 17.484) rotate(180)"
              >
                <path
                  id="Path_24"
                  data-name="Path 24"
                  class="btnNext"
                  d="M16.43,16.859,13,13.43,16.43,10"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          </a>
        </span>
      </li>
    </ul>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped></style>
