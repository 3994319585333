<template>
  <Modal title="Add Reviewer" v-model:value="isShow" closeBtnText="Close">
    <div class="text-center">
      <div class="modal-title w-100">
        <div class="row mb-2">
          <div class="col col-12">
            <TextField
              placeholder="Search user by name..."
              hideError
              v-model:value="searchKey"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading">
      <div class="row mb-2">
        <div class="col col-12">
          <Table
            :paging="paging"
            @updatePaging="updatePaging"
            :columns="columns"
            :rows="rows"
            :total-items="totalItems"
            :showNoRow="true"
            :useSimpleLayout="true"
          >
            <template #row_name="{ props }">
              {{ props.name }}
            </template>
            <template #row_actions="{ props }">
              <button
                class="btn btn-danger"
                @click="AddUser(props)"
                v-if="matchUser(props)"
              >
                <i class="fas fa-minus"></i>
              </button>
              <button class="btn btn-primary" @click="AddUser(props)" v-else>
                <i class="fas fa-plus"></i>
              </button>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import adminServices from "@/services/admin";
import { PERMISSIONS } from "@/config/router";
import Modal from "@/components/Modal";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";

const columnsData = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-right",
    columnClass: "text-right action-icon-group",
  },
];

export default defineComponent({
  name: "AddNotifyUserModal",
  components: {
    Modal,
    Table,
    TextField,
  },
  props: {
    users: { type: Array, default: [] },
  },
  emits: ["addUser"],
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      isShow: false,
      searchKey: "",
      user_id: 0,
      notify_sms: true,
      notify_email: true,
      PERMISSIONS: PERMISSIONS,
      columns: columnsData,
    };
  },

  watch: {
    paging: {
      deep: true,
      handler() {
        this.doSearch();
      },
    },
    searchKey() {
      this.setPageDefault()
      debounce(this.doSearch);
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    matchUser(user) {
      return this.users.some((x) => x.user_id == user.id);
    },
    async doSearch() {
      this.loading = true;
      const res = await adminServices.getAllRokkerUsers({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredType: "Rokker",
        filteredStatus: "ACTIVE",
        filteredName: this.searchKey.trim(),
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    AddUser(item) {
      this.$emit("addUser", item);
      // this.loading = true
      // const res = await adminServices.createStageReviewers({
      //     user_id: item.id,
      //     notify_sms: true,
      //     notify_email: true
      // })
      // if (res.data.ok) {
      //     notify.success('Add Reviewer success!')
      //     this.$emit('refresh')
      //     this.isShow = false
      // } else {
      //     notify.error('can\'t completed!')
      // }
      // this.loading = false
    },
    show() {
      this.isShow = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.item-title {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 180px);
}
.table-sm {
  font-size: 14px;
}
.sub-item {
  font-size: 10px;
  color: $text-color--secondary;
}
.search-wrapper {
  position: relative;
  width: calc(100% - 30px);
  height: 30px;
  overflow: hidden;
  .input-section {
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    box-sizing: border-box;
    font-size: 14px;
    background: 0 0;
    border: 1px solid #ddd;
    padding-left: 12px;
    padding-right: 30px;
    width: calc(100% - 30px);
    height: 30px !important;
    line-height: normal !important;
    border-radius: 16px;
    color: $text-color--secondary;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      opacity: 1;
      border-color: $color-primary;
      outline: 0;
    }
  }
  .btn-icon {
    position: absolute;
    top: 4px;
    right: 9px;
    z-index: 9;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 22px;
    // color: #666;
    border: none;
    background: 0 0;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 0.9;
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}
</style>
