<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredName"
        />
      </template>
      <template #head_subject>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredSubject"
        />
      </template>
      <template #head_body>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredBody"
        />
      </template>
      <template #row_subject="{ props }">
        {{ truncate(props.subject, 100) }}
      </template>
      <template #row_body="{ props }">
        {{ truncate(props.body, 200) }}
      </template>
      <template #row_actions="{ props }">
        <button
          class="btn btn-primary btn-small me-1"
          @click="updateMessage(props)"
        >
          <i class="far fa-edit"></i>
        </button>
        <button class="btn btn-primary btn-small" @click="deleteMessage(props)">
          <i class="far fa-trash-alt"></i>
        </button>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="addMessage">
          <span class="text"
            ><i class="fas fa-plus me-1"></i>Add New Template</span
          >
        </button>
      </template>
    </Table>
    <MessageManageModel
      ref="messageManageModel"
      :key="messageManageModelKey"
      @refresh="getAll"
    />

    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>
