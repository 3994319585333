<template>
  <div class="section-3">
    <div class="wrap-3">
      <div class="container-v2">
        <div class="text-a-wrapper w-text-1">
          <h2 class="heading">Our Thesis</h2>
        </div>
      </div>

      <div
        class="our-values-wrapper"
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="150"
        data-aos-duration="1000"
      >
        <div v-for="item in list" :key="item.id" class="our-values-card">
          <div class="inner">
            <div class="info">
              <div class="title">
                <span>{{ item.title }}</span>
                <span>{{ item.subTitle }}</span>
              </div>
              <p class="desc">
                {{ item.desc }}
              </p>
            </div>

            <div class="picture">
              <img :src="item.picture" alt="picture" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutUsImage3 from "@/assets/images/v2/about-us-3.png";
import AboutUsImage4 from "@/assets/images/v2/about-us-4.png";
import AboutUsImage5 from "@/assets/images/v2/about-us-5.jpg";
import AboutUsImage6 from "@/assets/images/v2/about-us-6.png";
import anime from "animejs";
import ScrollOut from "scroll-out";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      randomArray: [],
      list: [
        {
          title: "Founder",
          subTitle: "Focused",
          desc: "We are hungry overachievers with a gut drive to win. We hail from humble backgrounds and understand those who do too. We work with founders who often mirror who we are-immigrants, idealists, underdogs, and futurists, providing not only smart capital but also strategic insights, diverse networks for relationships, and technology support. In our dual role as an investor and partner, we build relationships with our founders, align their goals with ours, and become their biggest advocates.",
          picture: AboutUsImage3,
        },
        {
          title: "Investor",
          subTitle: "Driven.",
          desc: "Our leadership team has more than eight decades of collective experience in managing investments across a wide spectrum of asset classes and serving investors in both the public and private market. Investing in a world of fast-paced changes and tech abundance, we recognize the need for a transformational investment portfolio, as well as the importance of gaining exposure to the creative economy through a venture capital allocation. Therefore, our mission is to provide both off-shore and on-shore investors access to venture capital investment opportunities that are highly selective and transformational.",
          picture: AboutUsImage4,
        },
        {
          title: "Technology",
          subTitle: "Focus",
          desc: "We believe that the world is still in its infancy as it relates to the technological evolution of computing, AI/ML, predictive analytics, automation, and collaboration. This is why we are looking for and investing in companies who are building software solutions, platforms and marketplaces that create efficiencies in global and local economies. We are backing both consumer technology and B2B Enterprise & SMB as we believe the combined leverage generated will have an outsized positive impact on society as a whole.",
          picture: AboutUsImage5,
        },
        {
          title: "Art and",
          subTitle: "Science.",
          desc: "We represent a fusion of financial services leadership and entrepreneurial careers, laying the foundation of our investment approach, which combines art and science. We've taken all that we've learned about risk-mitigation and spotting opportunities from the traditional finance ecosystem, then turned it on its head with modern investment principles focused solely on the private sector of the creative economy. We layer a scientific and financial discipline with artistic technology outlook.",
          picture: AboutUsImage6,
        },
      ],
    };
  },
  mounted() {
    // mobile is disable horizontal scroll
    if (window.innerWidth < 768) return;

    this.handleScrollTextReveal();

    this.handleScrollHorizontal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();

      ScrollOut({
        targets: ".w-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          // el.style.opacity = 0;
        },
      });
    },

    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".w-text-1 .heading");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".w-text-1 .letter",
        delay: (el, i) => {
          this.randomArray = [];
          const random =
            this.generateUniqueRandom(textLength, "randomArray") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [130, 0],
      });
    },

    handleSliceText() {
      var textWrapper = document.querySelector(".w-text-1 .heading");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );
    },

    handleScrollHorizontal() {
      const sectionWrapper = document.querySelector(".section-3");
      if (!sectionWrapper) return;
      let sections = gsap.utils.toArray(".our-values-card");
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: sectionWrapper,
          pin: true,
          scrub: 0.4,
          start: "top 74px",
          end: "+=4500",
        },
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          // console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-3 {
  height: auto;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    height: calc(100vh + 80px); // .our-values-wrapper margin-bottom
  }

  @media screen and (min-width: 1900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.our-values-wrapper {
  --spacing-left: 3%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 80px;

  overflow-y: auto;

  @media screen and (min-width: 768px) {
    width: calc(400% - calc(var(--spacing-left) * 4));
    flex-wrap: nowrap;
    overflow-y: unset;
  }
}

.heading {
  font-size: 100px;
  font-weight: 700;
  line-height: 119px;
  letter-spacing: -0.02em;
}

.our-values-card {
  width: 100%;
  padding-left: unset;

  @media screen and (min-width: 768px) {
    &:nth-child(1) {
      padding-left: var(--spacing-left);
    }
  }

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;

    @media (min-width: 768px) {
      border-left: 1px solid #000;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 42% 58%;
    }

    .info {
      .title {
        padding: 12px;
        border-bottom: 1px solid #000;

        @media (min-width: 768px) {
          padding: 34px 32px 12px;
        }

        span {
          color: #000;
          font-weight: 900;
          font-size: 35px;
          line-height: 100%;

          word-break: break-word;
          text-transform: uppercase;

          @media (min-width: 1024px) {
            font-weight: 900;
            font-size: 60px;
            line-height: 90%;
          }

          &:nth-child(2) {
            display: block;
            color: #fff;
            text-shadow: 1px 1px #000, -1px 1px #000, 1px -1px #000,
              -1px -1px #000, 1px 1px 0px #000;
          }
        }
      }

      .desc {
        font-family: "SF Pro Text";
        padding: 32px;

        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
      }
    }

    .picture {
      height: 580px;

      @media (min-width: 768px) {
        padding: 80px 0 40px;
      }

      @media (min-width: 768px) and (max-width: 1440px) {
        height: 500px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (min-width: 1024px) {
          object-fit: contain;
          object-position: left bottom;
        }
      }
    }
  }
}
</style>
