import authService from "../../../services/auth";
import appStore, { AUTH_ACTIONS, APP_MUTATION } from "../../../store/app";
import router from "@/config/router";
import notify from "../../../services/notify";
import _ from "lodash";
import conf from "../../../config";
import TextField from "@/components/TextField";
import VueCookies from "vue-cookies";
import { defineComponent } from "vue";
import LogoIcon from "@/components/commons/logo";

export default defineComponent({
  components: {
    LogoIcon,
    TextField,
  },
  data() {
    return {
      loading: false,
      loginPath: router.login.path,
      homePath: router.home.path,
      notSupported: false,
      captchaInstance: {},
      reCapchaKey: conf.recaptcha_key,
      termInstance: {},
      registerSuccess: false,
      disableRef: false,
      model: {
        email: "",
        password: "",
        confirmPassword: "",
        recaptcha: "",
        accept: false,
      },
      recoverPasswordCode: "",
      loginPath: router.login.path,
      resendRegisterVerifyPath: router.resendVerifyRegisterEmail.path,
      success: false,
      frmInvalidate: false,
      messageError: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted: function () {
    $(".boxFix").addClass("backgroundLogin");

    appStore.commit(APP_MUTATION.use_empty_layout, true);
    const pattern = new RegExp(/^[a-zA-Z0-9_]*$/);

    if (
      VueCookies.get("REFCODE") &&
      VueCookies.get("REFCODE").length === 7 &&
      pattern.test(VueCookies.get("REFCODE"))
    ) {
      this.model.referrerCode = VueCookies.get("REFCODE");
      this.disableRef = true;
    }
  },
  computed: {
    disabled() {
      return (
        this.loading ||
        !this.model.email ||
        !this.model.password ||
        !this.model.confirmPassword ||
        !this.model.recaptcha ||
        !this.model.accept
      );
    },
    useEmptyLayout() {
      return this.$store.state.useEmptyLayout;
    },
    appName: () => conf.app_name,
  },
  methods: {
    submit(token) {
      this.loading = true;
      this.model.recaptcha = token;
      appStore.dispatch(AUTH_ACTIONS.register, this.model).then((resp) => {
        if (!$("body").children().last().attr("class")) {
          $("body").children().last().remove();
        }
        if (resp.error) {
          if (
            resp.data.d &&
            resp.data.d.err_code &&
            resp.data.d.err_code == "DuplicateUserName"
          ) {
            // notify.error('Error: This email has been used for registration. If you have already created an account, please log in.')
          } else if (
            resp.data.d &&
            resp.data.d.err_code &&
            resp.data.d.err_code == "nickname_already_exists"
          ) {
            // notify.error('Error: Someone has picked this nickname. Please choose another one.')
          } else {
            // handleErrors(resp)
          }
          this.resetCaptcha();
        } else {
          this.registerSuccess = true;
          notify.success("Registration successful.");
          // this.recoverPasswordCode = resp.data.d
          // fbq("track", "CompleteRegistration");
        }
        this.loading = false;
      });
    },
    validate() {
      this.resetVaildate();
      if (!this.model.accept) {
        notify.error("You have not accept");
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.model.email = this.model.email.trim();
      this.model.password = this.model.password.trim();
      this.model.nickname = this.model.nickname.trim();

      if (!re.test(this.model.email)) {
        this.messageError.email = "Invalid email address";
        this.frmInvalidate = true;
      }
      if (this.model.password !== this.model.confirmPassword) {
        this.messageError.confirmPassword = "Your passwords does not match";
        this.frmInvalidate = true;
      }
      if (this.model.password.length < 6) {
        this.messageError.password =
          "Your password must be at least 6 characters long.";
        this.frmInvalidate = true;
      }
      if (this.model.password.length >= 100) {
        this.messageError.password = "Your password is too long.";
      }

      const regex = /^[a-zA-Z]{1}[a-zA-Z0-9._-]{5,19}$/g;
      if (!regex.test(this.model.nickname)) {
        this.messageError.nickname = "Nickname between 6 and 20 characters";
        this.frmInvalidate = true;
      }

      // if (!this.model.recaptcha) {
      // notify.error('You must solve the captcha first')
      // this.frmInvalidate = true
      // }
      if (this.frmInvalidate) {
        return;
      }

      this.$refs.recaptcha?.execute();
    },
    resetVaildate() {
      this.frmInvalidate = false;
      this.messageError = {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        nickname: null,
      };
    },
    register() {
      this.resetVaildate();
      // if (!this.model.accept) {
      //   notify.error('You have not accept');
      // }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // if (!this.model.fullName) {
      //   this.messageError.fullName = 'Your name field is required'
      //   this.frmInvalidate = true;
      // }
      if (!this.model.firstName) {
        this.messageError.firstName = "First name field is required";
        this.frmInvalidate = true;
      }
      if (!this.model.lastName) {
        this.messageError.lastName = "Last name field is required";
        this.frmInvalidate = true;
      }
      if (!re.test(this.model.email)) {
        this.messageError.email = "Invalid email address";
        this.frmInvalidate = true;
      }
      if (this.model.password !== this.model.confirmPassword) {
        this.messageError.confirmPassword = "Your passwords does not match";
        this.frmInvalidate = true;
      }
      if (this.model.password.length < 6) {
        this.messageError.password =
          "Your password must be at least 6 characters long.";
        this.frmInvalidate = true;
      }
      if (this.model.password.length >= 100) {
        this.messageError.password = "Your password is too long.";
      }

      // const regex = /^[a-zA-Z]{1}[a-zA-Z0-9._-]{5,19}$/g;
      // if (!regex.test(this.model.nickname)) {
      //   this.messageError.nickname = 'Nickname between 6 and 20 characters'
      //   this.frmInvalidate = true;
      // }

      // if (!this.model.recaptcha) {
      //   notify.error('You must solve the captcha first');
      //   this.frmInvalidate = true;
      // }
      if (this.frmInvalidate) {
        return;
      }
      this.loading = true;
      appStore.dispatch(AUTH_ACTIONS.register, this.model).then((resp) => {
        if (resp.error) {
          if (resp.data.c && resp.data.c == "DuplicateUserName") {
            //notify.error('Error: This email has been used for registration. If you have already created an account, please log in.')
          } else if (resp.data.c && resp.data.c == "nickname_already_exists") {
            //notify.error('Error: Someone has picked this nickname. Please choose another one.')
          } else {
            // handleErrors(resp)
          }
          //this.resetCaptcha();
        } else {
          this.registerSuccess = true;

          notify.success("Registration successfully.");
          // this.recoverPasswordCode = resp.data.d
          // fbq("track", "CompleteRegistration");
        }
        this.loading = false;
      });
    },
    resetCaptcha() {
      if (_.isFunction(this.captchaInstance.reset)) {
        this.captchaInstance.reset();
      }
    },
    // viewTermService() {
    //   if (_.isFunction(this.termInstance.show)) {
    //     this.termInstance.show()
    //   }
    // },
    close() {
      let previous = appStore.state.previousRoute;
      this.$router.push(previous.fullPath ? previous.fullPath : previous.path);
    },
    resendEmail() {
      authService
        .sendVerifyEmail(this.model.email, this.model.recaptcha)
        .then((resp) => {
          if (resp.error) {
            // notify.error('Resend verification email fail', resp.errorMsg)
          } else {
            // notify.error('Resend verification email success', resp.errorMsg)
          }
        });
    },
  },
});
