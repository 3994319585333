<template>
  <div class="partialsPortfolio">
    <div class="portfolioWrapper">
      <div class="portfolioInner">
        <div
          data-aos="zoom-in-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          class="boxItem"
          v-for="(item, index) in portfolios"
          :key="index"
        >
          <div v-if="item.icon" class="portfolio-icon" :title="item.icon">
            <img
              v-if="item.icon === 'unicorn'"
              src="../../../../assets/images/v2/unicorn.png"
            />
            <img
              v-else-if="item.icon === 'soonicorn'"
              src="../../../../assets/images/v2/soonicorn.png"
            />
          </div>
          <div
            class="background-cover"
            :style="{ backgroundImage: 'url(' + item.background + ')' }"
          >
            <div
              class="color-hover"
              v-if="item.ext.PrimaryColor && item.ext.PrimaryColor !== ''"
              :style="{ backgroundColor: item.ext.PrimaryColor }"
            ></div>
            <div
              class="color-hover"
              v-else
              style="background-color: #3e99ed"
            ></div>
            <div class="boxAvatar">
              <div v-if="item.corner_label" class="corner-label">
                <div class="corner-label-txt">
                  <div
                    :style="`background-color: #FFF; padding: 5px 0px; font-size: 13px; color: ${
                      item.ext &&
                      item.ext.PrimaryColor &&
                      item.ext.PrimaryColor !== ''
                        ? item.ext.PrimaryColor
                        : 'rgb(0, 149, 255)'
                    } `"
                  >
                    {{ item.corner_label }}
                  </div>
                </div>
                <div
                  class="corner-label-none"
                  style="
                    background-color: rgb(0, 149, 255);
                    color: #fff;
                    padding: 5px 0px;
                    font-size: 13px;
                  "
                >
                  {{ item.corner_label }}
                </div>
              </div>
              <!-- <div v-if="item.corner_label" class="corner-label">
            {{item.corner_label}}
                
              </div> -->

              <div>
                <img :src="item.photo" alt="" loading="lazy" />
                <span class="textUnderIcon">Fueled in {{ item.fueled }}</span>
              </div>
            </div>
            <div class="portfolio-info row">
              <span class="caseStudy pl-3">
                <a
                  v-if="item.ext.Website"
                  :href="`${item.ext.Website}`"
                  target="_blank"
                  >Website</a
                >
              </span>
              <div class="col social-container">
                <span
                  class="social-info"
                  v-if="
                    item.ext.Facebook ||
                    item.ext.Twitter ||
                    item.ext.Instagram ||
                    item.ext.Linkedin ||
                    item.ext.Medium
                  "
                >
                  <a
                    :href="`${item.ext.Facebook}`"
                    v-if="item.ext.Facebook"
                    class="social"
                    target="_blank"
                  >
                    <svg
                      class="iconSocial"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.92"
                      height="19.92"
                      viewBox="0 0 48 48"
                    >
                      <g class="nc-icon-wrapper" fill="#000000">
                        <path
                          fill="#000000"
                          d="M46,0H2C0.89543,0,0,0.89543,0,2v44c0,1.10457,0.89543,2,2,2h23.63829V29.4119h-6.25529v-7.24417h6.25529 v-5.34235c0-6.19977,3.78661-9.57566,9.31723-9.57566c2.64922,0,4.92609,0.19724,5.58963,0.2854v6.47912l-3.83577,0.00174 c-3.00786,0-3.59026,1.42929-3.59026,3.52668v4.62507h7.17347l-0.93401,7.24417h-6.23946V48H46c1.10457,0,2-0.89543,2-2V2 C48,0.89543,47.10457,0,46,0z"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    :href="`${item.ext.Twitter}`"
                    v-if="item.ext.Twitter"
                    class="social"
                    target="_blank"
                  >
                    <svg
                      class="iconSocial"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.267"
                      height="19.709"
                      viewBox="0 0 24.267 19.709"
                    >
                      <g id="twitter" transform="translate(0 -48.082)">
                        <path
                          id="Path_14324"
                          data-name="Path 14324"
                          d="M21.772,52.994c.015.216.015.431.015.647A14.054,14.054,0,0,1,7.637,67.791,14.055,14.055,0,0,1,0,65.558a10.288,10.288,0,0,0,1.2.062A9.96,9.96,0,0,0,7.376,63.5a4.982,4.982,0,0,1-4.65-3.449,6.272,6.272,0,0,0,.939.077,5.26,5.26,0,0,0,1.309-.169A4.974,4.974,0,0,1,.985,55.073v-.062a5.009,5.009,0,0,0,2.248.631,4.981,4.981,0,0,1-1.54-6.652,14.137,14.137,0,0,0,10.255,5.2,5.615,5.615,0,0,1-.123-1.139,4.978,4.978,0,0,1,8.607-3.4,9.792,9.792,0,0,0,3.157-1.2A4.96,4.96,0,0,1,21.4,51.192a9.97,9.97,0,0,0,2.864-.77,10.691,10.691,0,0,1-2.494,2.571Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    :href="`${item.ext.Instagram}`"
                    v-if="item.ext.Instagram"
                    class="social"
                    target="_blank"
                  >
                    <svg
                      class="iconSocial"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.633"
                      height="19.628"
                      viewBox="0 0 19.633 19.628"
                    >
                      <g
                        id="instagram"
                        transform="translate(0.075 -31.825)"
                        opacity="0.999"
                      >
                        <path
                          id="Path_14325"
                          data-name="Path 14325"
                          d="M9.744,36.607a5.032,5.032,0,1,0,5.032,5.032A5.025,5.025,0,0,0,9.744,36.607Zm0,8.3a3.272,3.272,0,1,1,3.272-3.272,3.278,3.278,0,0,1-3.272,3.272Zm6.412-8.51a1.174,1.174,0,1,1-1.174-1.174A1.171,1.171,0,0,1,16.156,36.4Zm3.333,1.191A5.809,5.809,0,0,0,17.9,33.479a5.847,5.847,0,0,0-4.113-1.586c-1.621-.092-6.478-.092-8.1,0A5.839,5.839,0,0,0,1.58,33.475,5.828,5.828,0,0,0-.006,37.588c-.092,1.621-.092,6.478,0,8.1A5.809,5.809,0,0,0,1.58,49.8a5.855,5.855,0,0,0,4.113,1.586c1.621.092,6.478.092,8.1,0A5.809,5.809,0,0,0,17.9,49.8a5.847,5.847,0,0,0,1.586-4.113C19.581,44.066,19.581,39.213,19.489,37.592ZM17.4,47.425a3.312,3.312,0,0,1-1.866,1.866c-1.292.512-4.358.394-5.786.394s-4.5.114-5.786-.394a3.312,3.312,0,0,1-1.866-1.866C1.58,46.133,1.7,43.067,1.7,41.639s-.114-4.5.394-5.786a3.312,3.312,0,0,1,1.866-1.866c1.292-.512,4.358-.394,5.786-.394s4.5-.114,5.786.394A3.312,3.312,0,0,1,17.4,35.853c.512,1.292.394,4.358.394,5.786S17.908,46.137,17.4,47.425Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    :href="`${item.ext.Linkedin}`"
                    v-if="item.ext.Linkedin"
                    class="social"
                    target="_blank"
                  >
                    <svg
                      class="iconSocial"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.92"
                      height="19.92"
                      viewBox="0 0 19.92 19.92"
                    >
                      <g
                        id="linkedin"
                        transform="translate(0 -32)"
                        opacity="0.999"
                      >
                        <path
                          id="Path_14326"
                          data-name="Path 14326"
                          d="M18.5,32H1.418A1.429,1.429,0,0,0,0,33.436V50.484A1.429,1.429,0,0,0,1.418,51.92H18.5a1.433,1.433,0,0,0,1.423-1.436V33.436A1.433,1.433,0,0,0,18.5,32ZM6.021,49.074H3.068V39.568H6.025v9.507ZM4.544,38.27a1.712,1.712,0,1,1,1.712-1.712A1.713,1.713,0,0,1,4.544,38.27Zm12.543,10.8H14.135V44.45c0-1.1-.022-2.521-1.534-2.521-1.538,0-1.774,1.2-1.774,2.441v4.7H7.875V39.568h2.832v1.3h.04a3.11,3.11,0,0,1,2.8-1.534c2.988,0,3.544,1.97,3.544,4.531Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    :href="`${item.ext.Medium}`"
                    v-if="item.ext.Medium"
                    class="social"
                    target="_blank"
                  >
                    <svg
                      class="iconSocial"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.92"
                      height="19.92"
                      viewBox="0 0 48 48"
                    >
                      <g class="nc-icon-wrapper" fill="#000000">
                        <path
                          d="M46,0H2A2,2,0,0,0,0,2V46a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V2A2,2,0,0,0,46,0ZM39.877,11.373,37.3,13.841a.752.752,0,0,0-.286.722V32.7a.753.753,0,0,0,.286.722l2.514,2.468v.542H27.171v-.542l2.6-2.528c.256-.256.256-.331.256-.722V17.979L22.79,36.371h-.979l-8.43-18.391V30.305a1.7,1.7,0,0,0,.467,1.415l3.387,4.109v.542h-9.6v-.542l3.387-4.109a1.64,1.64,0,0,0,.437-1.415V16.053A1.249,1.249,0,0,0,11.048,15L8.037,11.373v-.542h9.349l7.226,15.848,6.353-15.848h8.912Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </a>
                </span>
              </div>
              <span class="caseStudy pr-3">
                <a @click.prevent="showPortDetails(index)" v-if="item.video"
                  >Video</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center py-lg-5 py-sm-4"
        v-if="showPortfolioSeemore"
      >
        <router-link>
          <button data-aos="zoom-out-down" class="btn btnBlue">See more</button>
        </router-link>
      </div>

      <!-- <PortDetails :portfolios="portfolios"></PortDetails> -->
    </div>
    <VideoPartial :fileVideoUrl="fileVideoUrl"  />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import appStore, { APP_MUTATION } from "@/store/app";
// import PortDetails from "../portDetails";
import VideoPartial from './Video.vue'

export default defineComponent({
  props: {
    showPortfolioSeemore: {
      type: Boolean,
      default: false,
    },
    portfolios: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      fileVideoUrl: null
    }
  }, 
  components: {
    VideoPartial
    // PortDetails,
  },

  methods: {
    showPortDetails(index) {
      // this.fixedBody()
      appStore.commit(APP_MUTATION.set_active_portfolio_slide, index);
      appStore.commit(APP_MUTATION.change_popup, true);
      this.fileVideoUrl = this.portfolios[index].video;
      // this.$modal.show("modalPortfolio");
    },
  },
});
</script>

<style lang="scss" scoped>
.boxAvatar {
  border: 1px solid #ccc;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .corner-label-txt {
    display: none;
  }
  .corner-label-none {
    display: block;
  }
  &:hover {
    .corner-label-txt {
      display: block;
      cursor: pointer;
    }
    .corner-label-none {
      display: none;
    }
  }
  .corner-label {
    position: absolute;
    top: 20px;
    left: -69px;

    padding: 4px 16px;
    text-transform: uppercase;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 215px;
  }
  img {
    max-width: 250px;
    max-height: 250px;
    object-fit: contain;
  }
  .textUnderIcon {
    color: #fff;
    visibility: hidden;
    opacity: 0;
    font-size: 1.2rem;
  }
}
.display-flow {
  display: flow-root;
}
.partialsPortfolio {
  .portfolioWrapper {
    padding-top: 2px;
    .portfolioInner {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2px;
    }
    .boxItem {
      position: relative;
      height: calc(100vh / 2);
      -webkit-transition: all 0.5s;
      padding: 0;
      transition: all 0.5s;
      .portfolio-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        width: 100px;
        height: 100px;
        // border-radius: 50%;
        // background: rgba( 0, 0, 0, 0.5 );
        // box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.25 );
        // backdrop-filter: blur( 4px );
        // -webkit-backdrop-filter: blur( 4px );
        img {
          padding: 2px;
        }
      }
      .background-cover {
        position: relative;
        /* height: calc(100% - 8px); */
        height: 100%;
        background-size: cover;
        background-position: center;

        &:hover {
          .color-hover {
            display: block;
          }
          .textUnderIcon,
          .portfolio-info {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .boxPortfolio {
        background-size: contain;
      }

      img {
        object-fit: contain;
        margin-bottom: 6px;
        width: 100%;
        display: block;
      }
      .portfolio-info {
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0px;
        margin-left: 0px;
        opacity: 0;
        visibility: hidden;
        align-items: center;
        display: flex;
        .social-container {
          text-align: center;
          .social-info {
            display: inline-flex;
            background: transparent;
            padding: 8px;
            a {
              height: 19.7px;
            }
            .social {
              &:hover {
                .iconSocial {
                  g {
                    path {
                      fill: #000;
                    }
                  }
                }
              }
              .iconSocial {
                g {
                  path {
                    fill: #fff;
                  }
                }
              }
              &:not(:last-child) {
                margin-right: 0.5rem;
              }
            }
          }
        }
        .caseStudy {
          z-index: 10;
          width: 64px;
          font-size: 18px;
          -webkit-transition: all 200ms ease-in;
          -webkit-transform: scale(1);
          -ms-transition: all 200ms ease-in;
          -ms-transform: scale(1);
          -moz-transition: all 200ms ease-in;
          -moz-transform: scale(1);
          transition: all 200ms ease-in;
          transform: scale(1);
          a {
            cursor: pointer;
            color: #fff;
          }
          &:hover {
            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1.2);
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1.2);
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1.2);
            transition: all 200ms ease-in;
            transform: scale(1.2);
          }
        }
      }
      .color-hover {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.7;
        display: none;
      }
    }
    @media screen and (max-width: 991px) {
      .boxItem {
        background-color: #fff !important;
        &:nth-child(4n-7) {
          background-color: #efefef !important;
        }
        &:nth-child(4n-6) {
          background-color: #efefef !important;
        }
        .portfolio-info {
          opacity: 1;
          visibility: visible;
        }
        .textUnderIcon {
          opacity: 1;
          visibility: visible;
          font-size: 15px;
        }
      }
    }
    @media screen and (max-width: 916px) {
      .boxItem {
        background-color: #fff !important;
        &:nth-child(4n-7) {
          background-color: #fff !important;
        }
        &:nth-child(4n-6) {
          background-color: #fff !important;
        }
        &:nth-child(odd) {
          background-color: #fff !important;
        }
        &:nth-child(even) {
          background-color: #efefef !important;
        }
        .portfolio-info {
          opacity: 1;
          visibility: visible;
        }
        .textUnderIcon {
          opacity: 1;
          visibility: visible;
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .partialsPortfolio {
    .portfolioWrapper {
      .portfolioInner {
        grid-template-columns: 1fr;
      }
      .boxItem {
        width: 100%;
        .portfolio-info {
          .caseStudy {
            width: 95px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>
