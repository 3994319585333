import AdminServices from "../../../services/admin";
import popupAddEditMember from "./addEditMember";
import notify from "../../../services/notify";
import _ from "lodash";
// import draggable from 'vuedraggable' //#todo update module dragable
import Dragger from "@/components/Dragger";
import appStore from "../../../store/app";
import Dialog from "@/components/Dialog";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      tableData: [],
      loading: false,
    };
  },
  components: {
    popupAddEditMember,
    Dragger,
    Dialog,
  },
  mounted() {
    this.$nextTick(() => {
      this.getTeamData();
    });
  },
  methods: {
    getTeamData: function () {
      this.loading = true;
      AdminServices.getTeamData()
        .then((resp) => {
          if (resp.data.ok) {
            this.tableData = resp.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showAddMember() {
      this.$refs.addEditMember.showModal();
    },
    confirmAddMember(item) {
      AdminServices.addTeamData(item)
        .then((resp) => {
          if (resp.data.ok) {
            // this.isShowAddMember = false
            notify.success("Add successfully");
            this.getTeamData();
          }
        })
        .finally(() => {});
    },
    editTeamInfo(id) {
      const item = _.cloneDeep(this.tableData).find((x) => x.id === id);
      this.$refs.addEditMember.showModal(item);
      //   this.isShowAddMember = true;
    },
    confirmUpdateMember(item) {
      AdminServices.updateTeamData(item).then((resp) => {
        if (resp.data.ok) {
          // this.isShowAddMember = false
          notify.success("Update successfully");
          this.getTeamData();
          // this.infoUserEdit = null
        } else {
          notify.error(`Error ${error.resp.error.message}`);
        }
      });
    },
    removeTeamInfo(id) {
      let me = this;

      let message = {
        title: "Warning",
        body: "This will permanently delete this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then((d) => {
          if (!d) return;
          // This will be triggered when user clicks on proceed
          AdminServices.removeTeamData(id).then((resp) => {
            if (resp.data.ok) {
              notify.success("Delete successfully");
              me.getTeamData();
            } else {
              notify.error(`Error ${error.resp.error.message}`);
            }
          });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    // resetData() {
    // 	this.infoUserEdit = null
    // },
    onEnd(v) {
      let tmpData = _.cloneDeep(this.tableData);
      tmpData.map((x, idx) => {
        x.orderNumber = idx;
        return x;
      });
      AdminServices.sortTeamData(tmpData).then((resp) => {
        if (resp.data.ok) {
          this.getTeamData();
        }
      });
    },
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
  },
});
