import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: "publicFileDataroom",
    props: {
        mediaType: {
            type: String,
            default: '',
            required: true
        },
        parentId: { type: Int32Array, default: null}
    },
    emits: ['completed', 'close'],
    data() {
        return {
            loading: false,
            searchedItems: [],
            searchKey: '',
            searchParams: {
                paging: {
                    page: 1,
                    size: 10,
                    total: 0
                },
                searchKey: '',
                mediaType: '',
                parentId: ''
            },
            delay: 300,
            clicks: 0,

        }
    },
    created() {
        this.searchParams.mediaType = this.mediaType
        this.searchItems(this.searchKey)
        this.searchParams.parentId = this.parentId
    },
    methods: {
        searchItems(key) {
            const _key = key.trim()
            if (_key) {
                this.searchParams.searchKey = _key
                this.loadPage(1)
            }
        },

        loadPage(value) {
            this.searchParams.paging.page = value
            this.doSearch()
        },

        doSearch() {
            this.loading = true;
            if (this.parentId > 0)
                AdminServices.searchPublicFileItemsWithParentId(this.searchParams)
                .then(resp => {
                    if (resp.data.ok) {
                        this.searchedItems = resp.data.d.c
                        this.searchParams.paging.page = resp.data.d.p
                        this.searchParams.paging.size = resp.data.d.s
                        this.searchParams.paging.total = resp.data.d.t
                    }
                }).finally(() => {
                    this.loading = false
                })
            else 
                AdminServices.searchPublicDataroomFileItems(this.searchParams)
                    .then(resp => {
                        if (resp.data.ok) {
                            this.searchedItems = resp.data.d.c
                            this.searchParams.paging.page = resp.data.d.p
                            this.searchParams.paging.size = resp.data.d.s
                            this.searchParams.paging.total = resp.data.d.t
                        }
                    }).finally(() => {
                        this.loading = false
                    })
        },

        selectItem(item) {
            this.$emit('completed', { dataroomId: item.id, type: this.mediaType, name: item.name })
        },

        close() {
            this.$emit('close')
        },

        oneClick(item) {
            this.clicks++ 
            if (this.clicks === 1) {
              let self = this
              this.timer = setTimeout(() => {
                self.clicks = 0
              }, this.delay)
            } else {
               clearTimeout(this.timer)
               if (item.type === 'File') {
                this.selectItem(item)
               }
               this.clicks = 0
            }
        },
    }
})