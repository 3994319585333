<template>
  <div class="adminContent p-3">
    <ul class="nav">
      <li
        class="nav-item pointer"
        v-for="(item, idx) in tabs"
        :key="`tabs_${idx}`"
        v-show="hasPermissions(item.requiredPermissions)"
      >
        <a
          class="nav-link pointer"
          :class="{ active: tab_active === idx }"
          href="javascript:void(0)"
          @click="
            changeTab(idx);
            paramSearch = item.query();
          "
          ><span>{{ item.name }}</span></a
        >
      </li>
    </ul>
    <Table
      :paging="page"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      cursorPointer
      @rowClick="view"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="name"
        />
      </template>
      <template #head_status>
        <!-- <SelectField
          v-model="filteredStage"
          :options="stageList"
          valueProp="stage_id"
          hideError
        /> -->
      </template>
      <template #head_created>
        <TextField
          v-model:value="submittedBy"
          placeholder="Type to search .."
          hideError
        />
      </template>
      <template #row_name="{ props }">
        {{ props.name }}
      </template>
      <template #row_status="{ props }">
        <template v-if="!props.is_rejected">{{
          getStageNameById(props.stage_id)
        }}</template>
        <template v-else>{{ getStageNameById(props.stage_id) }}</template>
      </template>
      <template #row_remaining_hours="{ props }">
        <div v-if="!tabs[tab_active].isDraft">
          {{ calcRemainingDayByDueDate(props.due_date) }}
        </div>
      </template>
      <template #row_created="{ props }">
        <div>
          {{ props.created_by }}
        </div>
        <div class="small">
          {{ $filters.parseEstTime(props.created_at) }}
        </div>
      </template>
      <template #row_score="{ props }">
        <div class="nowrap">
          {{
            `${props.fpc_checklist.length} reviewer${
              props.fpc_checklist.length > 1 ? "s" : ""
            }`
          }}
        </div>
        <div class="nowrap">Score: {{ avgTotalScore(props) }}%<br /></div>
      </template>
      <template #row_actions="{ props }">
        <button class="btn btn-primary btn-view me-2" title="View">
          <i class="far fa-eye dropdown-item-icon"></i>
        </button>
        <template
          v-if="
            hasPermission(permissionDealDelete) &&
            props.is_rejected &&
            !props.is_deleted
          "
        >
          <button
            class="btn btn-danger btn-remove"
            @click.stop="remove(props)"
            title="Remove"
          >
            <i class="fa fa-trash-alt"></i>
          </button>
        </template>
      </template>
      <template #footer>
        <div v-if="tab_active == 0">
          <button class="btn btn-primary" @click="addDeal">
            <span class="text"><i class="fas fa-plus me-1"></i>Add New</span>
          </button>
        </div>
      </template>
    </Table>
    <DealAddUpdateModal
      ref="dealAddUpdateModal"
      :key="dealAddUpdateModalKey"
      @refresh="getAll"
    />
    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.nav {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  display: block;
  &::after {
    content: "";
    height: 2px;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #e4e7ed;
  }
  li {
    position: relative;
    z-index: 2;
    display: inline-block;
    a {
      font-size: 14px;
      color: #cdcdcd;
      line-height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-bottom: 2px solid transparent;
      height: 100%;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      span {
        line-height: 18px;
        display: inline-block;
        max-width: 80px;
        white-space: normal;
        height: 65px;
      }
      &.active {
        color: #409eff;
        // border-bottom: 2px solid #409eff;
        &:after {
          height: 2px;
        }
      }
    }
  }
}
button {
  &.btn-remove:hover {
    border-color: #db524b !important;
  }
}
.nowrap {
  white-space: nowrap;
}
</style>
