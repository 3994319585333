import VueApexCharts from "apexcharts";
import _ from "lodash";
import { defineComponent } from "vue";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default defineComponent({
  name: "chartView",
  props: ["chartData", "detailView", "tblData", "viewType"],
  data() {
    return {
      clonedChartData: null,
      chartObj: null,
      formatters: {
        showCashBurnOnly: (_, { seriesIndex, w }) => {
          return seriesIndex === 0
            ? "$" + this.numFormat(w.globals.series[seriesIndex])
            : "";
        },
        hideGoal: (_, { seriesIndex, w }) => {
          return seriesIndex % 2 === 0
            ? "$" + this.numFormat(w.globals.series[seriesIndex])
            : "";
        },
        decimal2Percentage: (val) => {
          return `${this.numFormat((parseFloat(val) * 100).toFixed(2), 0)}%`;
        },
        percentage: (val) => {
          return `${this.numFormat(parseFloat(val).toFixed(2), 0)}%`;
        },
        dollar: (val) => {
          if (val < 0) {
            return `-$${this.numFormat(parseFloat(val * -1).toFixed(2))}`;
          }
          return `$${this.numFormat(parseFloat(val).toFixed(2))}`;
        },
        integer: (val) => {
          if (val < 0) {
            return `-${this.numFormat(parseInt(val * -1))}`;
          }
          return this.numFormat(parseInt(val));
        },
        multiplier: (val) => {
          return `${this.numFormat(parseFloat(val).toFixed(2))}x`;
        },
        decimal: (val) => {
          return this.numFormat(parseFloat(val).toFixed(2));
        },
        showRunway: (val, { seriesIndex, dataPointIndex, w }) => {
          if (seriesIndex === 1) {
            return this.numFormat(
              Math.round(parseFloat(w.globals.series[2][dataPointIndex]))
            );
          } else {
            return val;
          }
        },
        currentAndGoal: (val, { seriesIndex, dataPointIndex, w }) => {
          debugger;
          if (seriesIndex === 1) {
            return this.numFormat(
              parseInt(w.globals.series[2][dataPointIndex])
            );
          } else {
            return val;
          }
        },
      },
    };
  },
  mounted() {
    this.setDefaultChartOptions();

    this.clonedChartData = _.cloneDeep(this.chartData.data);
    this.assignFormatters(this.clonedChartData);
    if (this.detailView) {
      this.clonedChartData.title.text = "";
      this.clonedChartData.chart.height = parseInt($(window).height() * 0.7);
    } else {
      let h = parseInt($(window).height() / 3);
      this.clonedChartData.chart.height = h > 270 ? h : 270;
    }

    this.renderView(this.viewType);
  },
  components: {
    VueApexCharts,
  },
  methods: {
    renderView(type) {
      if (type === "chart") {
        this.chartObj = new VueApexCharts(this.$el, this.clonedChartData);
        this.chartObj.render();
      } else {
        this.chartObj.destroy();
        this.chartObj = null;
      }
    },
    setDefaultChartOptions() {
      Apex.tooltip = {
        theme: "dark",
      };
      Apex.stroke = {
        width: 2,
        curve: "smooth",
      };
      Apex.title = {
        align: "left",
        offsetX: 10,
        margin: 0,
      };
      Apex.xaxis = {
        labels: {
          rotate: -90,
          rotateAlways: true,
          style: {
            fontSize: "10px",
          },
          offsetY: -3,
          minHeight: 40,
        },
        title: {
          style: {
            fontSize: "10px",
          },
        },
      };
      Apex.dataLabels = {
        enabled: false,
      };
      Apex.chart = {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      };
    },
    numFormat(num, shorten = 1) {
      let val = num;
      let suffix = "";
      if (shorten > 0 && this.viewType !== "table") {
        if (val >= 1000000 && shorten === 2) {
          val = (val / 1000000).toFixed(1);
          suffix = "Mil";
        } else if (val >= 1000) {
          val = (val / 1000).toFixed(1);
          suffix = "K";
        }
      }

      let numStr = val
        .toString()
        .replace(/\.0$/, "")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return `${numStr}${suffix}`;
    },
    getFormatter: function (name) {
      if (!name) return undefined;

      if (name.includes("getConfigValueDollar_")) {
        let fieldNames = name.replace("getConfigValueDollar_", "").split("_");
        return (w) => {
          if (fieldNames.length === 1) {
            let fieldName = fieldNames[0];
            return w.config[fieldName]
              ? `$${this.numFormat(
                  parseFloat(w.config[fieldName]).toFixed(2),
                  2
                )}`
              : 0;
          } else {
            let str = "";
            for (let i = 0; i < fieldNames.length; i++) {
              str += w.config[fieldNames[i]]
                ? `$${this.numFormat(
                    parseFloat(w.config[fieldNames[i]]).toFixed(2),
                    2
                  )}`
                : 0;
              if (i < fieldNames.length - 1) {
                str += " / ";
              }
            }
            return str;
          }
        };
      } else if (name.includes("getConfigValueMonth_")) {
        let fieldName = name.replace("getConfigValueMonth_", "");
        return (w) => {
          return w.config[fieldName]
            ? `${this.numFormat(
                parseFloat(w.config[fieldName]).toFixed(1)
              )} months`
            : "-";
        };
      } else if (name.includes("getConfigValueDollarWithSeriesIndex_")) {
        let fieldName = name.replace(
          "getConfigValueDollarWithSeriesIndex_",
          ""
        );
        return (seriesName, opts) => {
          return opts.w.config[fieldName]
            ? `${seriesName}: $${this.numFormat(
                opts.w.config[fieldName][opts.seriesIndex]
              )}`
            : seriesName;
        };
      } else if (this.formatters[name]) {
        return this.formatters[name];
      } else {
        return undefined;
      }
    },
    assignFormatters: function (data) {
      if (data.dataLabels && data.dataLabels.enabled) {
        data.dataLabels.formatter = this.getFormatter(
          data.dataLabels.formatterName
        );
      }
      if (data.legend && data.legend.show) {
        data.legend.formatter = this.getFormatter(data.legend.formatterName);
      }
      if (data.plotOptions) {
        if (
          data.plotOptions.pie &&
          data.plotOptions.pie.donut &&
          data.plotOptions.pie.donut.labels
        ) {
          if (data.plotOptions.pie.donut.labels.total) {
            data.plotOptions.pie.donut.labels.total.formatter =
              this.getFormatter(
                data.plotOptions.pie.donut.labels.total.formatterName
              );
          }
          if (data.plotOptions.pie.donut.labels.value) {
            data.plotOptions.pie.donut.labels.value.formatter =
              this.getFormatter(
                data.plotOptions.pie.donut.labels.value.formatterName
              );
          }
        }

        if (
          data.plotOptions.radialBar &&
          data.plotOptions.radialBar.dataLabels
        ) {
          if (data.plotOptions.radialBar.dataLabels.total) {
            data.plotOptions.radialBar.dataLabels.total.formatter =
              this.getFormatter(
                data.plotOptions.radialBar.dataLabels.total.formatterName
              );
          }
          if (data.plotOptions.radialBar.dataLabels.value) {
            data.plotOptions.radialBar.dataLabels.value.formatter =
              this.getFormatter(
                data.plotOptions.radialBar.dataLabels.value.formatterName
              );
          }
        }
      }

      _.each(data.yaxis, (y) => {
        if (y.labels) {
          y.labels.formatter = this.getFormatter(y.labels.formatterName);
        }
      });

      if (data.tooltip && data.tooltip.customTooltip) {
        data.tooltip.custom = this.getCustomTooltip(data.tooltip.customTooltip);
      }
    },
    getCustomTooltip(name) {
      var workaroundFunc = function (formatter) {
        return function ({ series, seriesIndex, dataPointIndex, w }) {
          let detail = "";
          for (let i = 0; i < series.length; i++) {
            if (series[i].length > 0) {
              let val = series[i][dataPointIndex];
              if (i === series.length - 1) {
                val = series[i][dataPointIndex] + series[i - 1][dataPointIndex];
              }
              detail += `<div class="apexcharts-tooltip-series-group" style="display: flex;">
                                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                                      w.config.colors[i]
                                    };"></span>
                                    <div class="apexcharts-tooltip-text">
                                        <div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-label">${
                                              w.config.series[i].name
                                            }</span>
                                            <span class="apexcharts-tooltip-text-value">${formatter(
                                              val
                                            )}</span>
                                        </div>
                                    </div>
                                </div>`;
            }
          }
          let title = `<div class="apexcharts-tooltip-title">${w.config.xaxis.categories[dataPointIndex]}</div>`;
          return title + detail;
        };
      };
      if (name === "totalStackedColumn") {
        return function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = w.config.series
            .filter((x) => x.type === "column")
            .reduce(
              (a, b) => a + (b.data.length > 0 ? b.data[dataPointIndex] : 0),
              0
            );
          let totalFormatted = "";
          let detail = "";
          for (let i = 0; i < series.length; i++) {
            if (series[i].length > 0) {
              detail += `<div class="apexcharts-tooltip-series-group" style="display: flex;">
                                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                                      w.config.colors[i]
                                    };"></span>
                                    <div class="apexcharts-tooltip-text">
                                        <div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-label">${
                                              w.config.series[i].name
                                            }</span>
                                            <span class="apexcharts-tooltip-text-value">${
                                              w.config.yaxis[i].labels.formatter
                                                ? w.config.yaxis[
                                                    i
                                                  ].labels.formatter(
                                                    series[i][dataPointIndex]
                                                  )
                                                : series[i][dataPointIndex]
                                            }</span>
                                        </div>
                                    </div>
                                </div>`;
            }
            if (
              w.config.series[i].type === "column" &&
              w.config.yaxis[i].labels.formatter &&
              totalFormatted.length === 0
            ) {
              totalFormatted = w.config.yaxis[i].labels.formatter(total);
            }
          }
          let title = `<div class="apexcharts-tooltip-title">${w.config.xaxis.categories[dataPointIndex]}: ${totalFormatted}</div>`;
          return title + detail;
        };
        // } else if (name === 'soundtrackRevenueWorkaround') {
        //     let that = this
        //     return function({ series, seriesIndex, dataPointIndex, w }) {
        //         let detail = ''
        //         for (let i = 0; i < series.length; i++) {
        //             if (series[i].length > 0) {
        //                 let val = series[i][dataPointIndex]
        //                 if (i === series.length - 1) {
        //                     val = series[i][dataPointIndex] + series[i - 1][dataPointIndex]
        //                 }
        //                 detail += `<div class="apexcharts-tooltip-series-group" style="display: flex;">
        //                         <span class="apexcharts-tooltip-marker" style="background-color: ${ w.config.colors[i] };"></span>
        //                         <div class="apexcharts-tooltip-text">
        //                             <div class="apexcharts-tooltip-y-group">
        //                                 <span class="apexcharts-tooltip-text-label">${ w.config.series[i].name }</span>
        //                                 <span class="apexcharts-tooltip-text-value">${ that.formatters.dollar(val) }</span>
        //                             </div>
        //                         </div>
        //                     </div>`
        //             }
        //         }
        //         let title = `<div class="apexcharts-tooltip-title">${ w.config.xaxis.categories[dataPointIndex] }</div>`
        //         return title + detail
        //     }
      } else if (name === "stackedColWithTwoLineWorkaround_dollar") {
        return workaroundFunc(this.formatters.dollar);
      } else if (name === "stackedColWithTwoLineWorkaround_integer") {
        return workaroundFunc(this.formatters.integer);
      }
    },
    formatTableValue(val, formatterName) {
      if (!val) {
        return 0;
      }
      if (formatterName && this.formatters[formatterName]) {
        return this.formatters[formatterName](val);
      } else {
        return val;
      }
    },
    downloadXlsx() {
      return XlsxPopulate.fromBlankAsync()
        .then((workbook) => {
          const sheet = workbook.sheet(0).name("Data");
          sheet.cell("A1").value(this.chartData.name);

          let startRow = 2;
          for (let i = 0; i <= this.tblData.categories.length; i++) {
            sheet
              .row(startRow)
              .cell(i + 2)
              .value(this.tblData.categories[i]);
          }

          startRow++;
          for (let i = 0; i < this.tblData.rows.length; i++) {
            let startCol = 1;
            sheet
              .row(startRow + i)
              .cell(startCol)
              .value(this.tblData.rows[i].name);
            startCol++;
            for (let j = 0; j < this.tblData.rows[i].data.length; j++) {
              sheet
                .row(startRow + i)
                .cell(startCol + j)
                .value(this.tblData.rows[i].data[j]);
            }
          }

          return workbook.outputAsync();
        })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = this.chartData.name.replace(" ", "_") + ".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
    },
  },
  watch: {
    viewType: function (v) {
      this.renderView(v);
    },
  },
});
