<template>
    <div class="discussion" v-if="!isUserTypeFounder()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mb-3">
                    <h2 class="mb-0">Timeline Discussion</h2>
                </div>
            </div>
        </div>
        <div v-loading="loading">
            <template v-for="(item, index) in reviewers" :key="`item_${index}`">
                <div v-if="(item.rating && item.rating.length) || (item.reviewers && item.reviewers.length)">
                    <detailStage :key="`item_${index}`" :item="item" :currentStage="item.index" :totalStage="totalStage" />
                </div>
            </template>
        </div>
    </div>
</template>
<style scoped lang="scss">
    .discussion{
        padding: 27px 0;
        h2{
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
</style>
<script src="./index.js"></script>