<template>
  <div
    class="modal fade changepassword"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog simple">
      <form @submit.prevent="confirm">
        <div class="modal-content" v-loading="loading">
          <div class="modal-body p-4">
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click.prevent="hide()"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g
                  class="nc-icon-wrapper"
                  stroke-linecap="square"
                  stroke-linejoin="miter"
                  stroke-width="2"
                  fill="#ffffff"
                  stroke="#ffffff"
                >
                  <g class="nc-interact_menu-close-2-o-32">
                    <path
                      fill="none"
                      stroke="#ffffff"
                      stroke-miterlimit="10"
                      d="M2 6h28"
                      transform="translate(0 10.00) rotate(45.00 16 6)"
                    />
                    <path
                      data-color="color-2"
                      fill="none"
                      stroke-miterlimit="10"
                      d="M2 16h28"
                      opacity="0"
                    />
                    <path
                      fill="none"
                      stroke="#ffffff"
                      stroke-miterlimit="10"
                      d="M2 26h28"
                      transform="translate(0 -10) rotate(-45 16 26)"
                    />
                  </g>
                </g>
              </svg>
            </button>
            <div class="row">
              <div class="col-12">
                <h2 class="colorWhite text-center font-weight-bold mb-4">
                  Change password
                </h2>
              </div>
              <div class="col-md-8 offset-md-2">
                <div class="row">
                  <div class="col-md-12">
                    <TextField
                      type="password"
                      id="current-password"
                      title="Current password"
                      :indexField="1"
                      :fieldRequire="true"
                      v-model:value="model.currentPassword"
                    />
                  </div>
                  <div class="col-md-12">
                    <!-- <md-field>
                                            <label>New password</label>
                                            <md-input v-model="model.newPassword" id="new-password" type="password"></md-input>
                                        </md-field> -->

                    <!-- <md-field md-has-password v-bind:class="{ 'md-invalid': messageError.new_password }">
                                            <label>New password</label> 
                                            <md-input v-model="model.newPassword" type="password" maxlength="20" id="new-password"></md-input>
                                            <small class="md-error">{{messageError.new_password}}</small>
                                        </md-field>  -->

                    <TextField
                      name="email"
                      title="New password"
                      :indexField="1"
                      :fieldRequire="true"
                      v-model:value="model.newPassword"
                      class="md-invalid"
                      type="password"
                      maxlength="20"
                      id="new-password"
                      :errorMsg="messageError.new_password"
                    />
                  </div>
                  <div class="col-md-12">
                    <!-- <md-field v-bind:class="{ 'md-invalid': messageError.password }">
                                            <label>Confirm new password</label>
                                            <md-input v-model="model.confirmNewPassword" id="confirm-password" type="password"></md-input>
                                            <small class="md-error">{{messageError.password}}</small>
                                        </md-field> -->

                    <TextField
                      name="email"
                      title="Confirm new password"
                      :indexField="1"
                      :fieldRequire="true"
                      v-model:value="model.confirmNewPassword"
                      class="md-invalid"
                      type="password"
                      id="confirm-password"
                      :errorMsg="messageError.password"
                    />
                  </div>
                  <div class="col-md-12">
                    <!-- <md-field v-if="twoFaEnbaled">
                                            <label>Google authenticator</label>
                                            <md-input v-model="model.twoFaCode" id="wo-fa-code" type="number"></md-input>
                                        </md-field> -->
                    <template v-if="twoFaEnbaled">
                      <TextField
                        name="email"
                        title="Google authenticator"
                        :indexField="1"
                        :fieldRequire="true"
                        v-model:value="model.twoFaCode"
                        class="md-invalid"
                        type="number"
                        id="wo-fa-code"
                      />
                    </template>
                  </div>
                  <div class="col-md-12">
                    <div class="bottomTools my-3">
                      <button
                        :disabled="disabled"
                        type="submit"
                        class="button btn-large round btn-border btn-primary btn-hover investor btn-bounce btn-active btn-full"
                      >
                        Change password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="box">
        <div class="subtitle">
            <span>Change password: </span>
        </div>
        <div class="frm">
            <form @submit.prevent="confirm" :disable="disabled">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <span class="labelInput">Current password</span>
                                    <input ref="oldPassword" v-model="model.currentPassword" id="current-password" type="password" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <span class="labelInput">New password</span>
                                    <input v-model="model.newPassword" id="new-password" type="password" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <span class="labelInput">Confirm new password</span>
                                    <input v-model="model.confirmNewPassword" id="confirm-new-password" type="password" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div v-if="twoFaEnbaled" class="form-group">
                                    <span class="labelInput">Google Authenticator</span>
                                    <input v-model="model.twoFaCode" id="two-fa-code" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="bottomTools">
                                    <button :disabled="disabled" type="submit" class="button btn-large round btn-border btn-primary btn-hover investor btn-bounce btn-active btn-full">Change password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div> -->
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.white-theme {
  .modal .modal-dialog .modal-content {
    background-color: #fff;
  }
  h2 {
    &.colorWhite {
      color: #5d6481;
    }
  }
  .md-field label {
    color: #5d6481;
  }
  .button.btn-primary.btn-border {
    border: 2px solid #2177ff;
  }
  .button.btn-primary.btn-active {
    background: #2177ff;
  }
  .button.btn-primary.btn-bounce:hover {
    -webkit-box-shadow: 0px 0px 19px 1px #2177ff;
    -moz-box-shadow: 0px 0px 19px 1px #2177ff;
    box-shadow: 0px 0px 19px 1px #2177ff;
  }
  .md-field .md-input,
  .md-field .md-textarea {
    color: #5d6481;
  }
}
</style>
