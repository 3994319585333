import { defineComponent } from "vue";

export default defineComponent({
  name: "TextField",
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    autocomplete: { type: Boolean, default: false },
    value: { type: String, default: "" },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: [String, Boolean], default: "" },
    placeholder: { type: String, default: "" },
    tabindex: { type: Number, default: 1 },
    size: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    field: { type: String, default: "" },
    className: { type: String, default: "" },
    maxLength: { type: Number, default: 100 },
    fieldRequire: { type: Boolean, default: false },
    indexField: { type: Number, default: 0 },
    min: { type: String, default: "" },
    iconPasswordColor: { type: String, default: "black" },
  },
  emits: ["update:value", "update:errorMsg", "handleValue"],
  data() {
    return {
      isShowPassword: false,
    };
  },
  watch: {
    _value(v) {
      this._errorMsg = "";
    },
  },
  mounted() {
    var intervalEle = document.getElementById("interval_in_day");
    if (intervalEle !== null)
      intervalEle.addEventListener("blur", (event) => {
        if (event.srcElement._value <= 1) this._value = 1;
      });
    var dayPublicEle = document.getElementById("daypublic");
    if (dayPublicEle !== null)
      dayPublicEle.addEventListener("blur", (event_daypublic) => {
        if (event_daypublic.srcElement._value <= 1) this._value = 1;
      });
    var dayInvestorEle = document.getElementById("dayinvestor");
    if (dayInvestorEle !== null)
      dayInvestorEle.addEventListener("blur", (event_dayinvestor) => {
        if (event_dayinvestor.srcElement._value <= 1) this._value = 1;
      });
    var deathlineEle = document.getElementById("deathline");
    if (deathlineEle !== null)
      deathlineEle.addEventListener("blur", (event_deathline) => {
        if (event_deathline.srcElement._value <= 1) this._value = 1;
      });
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
        this.$emit("handleValue", this.name, val);
      },
    },
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
  },
  methods: {
    toggleShowPassword() {
      this.isShowPassword = !this.isShowPassword;
    },
  },
});
