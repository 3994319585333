<template>
    <div ref="select">
      <div class="customize-select" :tabindex="tabindex">
        <div class="selected" :class="{'open': open}" @click="onClickSelected" ref="customizeSelectSelectedItem">
          <template v-if="open">
            <template v-if="!isMultiple">
              <div class="inline-search-wrapper">
                <input type="text"
                  ref="inlineSearchBox"
                  placeholder="Enter to search"
                  class="input-section"
                  v-model="searchKey"
                  @input="delayedFilter"/>
                <i v-if="searchKey" class="fa fa-times btn-icon" @click.prevent.stop="clearSearchInline"></i>
              </div>
            </template>
            <template v-else>
              <div class="selected-multiple">
                <span v-if="selectedOptions && selectedOptions.length > 0" class="badge badge-outline-warning">{{selectedOptions.length}}</span>
                <span v-if="selectedOptions && selectedOptions.length > 0" class="badge"> | </span>
                <span v-for="(op, idx) in selectedOptions" :key="idx" class="badge badge-outline-primary">
                  <span class="me-1" v-if="op.icon" v-html="op.icon"></span>
                  {{op.text}}
                </span>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="label && !selectedOption && (!selectedOptions || (selectedOptions && selectedOptions.length === 0))">
              <span class="item-text-placeholder">{{label}}</span>
            </template>
            <template v-if="selectedOption && !isMultiple">
              <span class="item-icon me-2" v-if="selectedOption.icon" v-html="selectedOption.icon"></span>
              <span class="item-text">{{selectedOption.fname}} {{selectedOption.lname}}</span>
            </template>
            <div v-if="isMultiple" class="selected-multiple">
              <span v-if="selectedOptions && selectedOptions.length > 0" class="badge badge-outline-warning">{{selectedOptions.length}}</span>
              <span v-if="selectedOptions && selectedOptions.length > 0" class="badge"> | </span>
              <span v-for="(op, idx) in selectedOptions" :key="idx" class="badge badge-outline-primary">
                <span class="me-1" v-if="op.icon" v-html="op.icon"></span>
                {{op.text}}
              </span>
            </div>
          </template>
        </div>
        <div class="items dropdown-wrapper"  ref="dropdown" v-show="open">
          <template v-if="isMultiple">
            <div class="search-wrapper mb-1">
              <input type="text"
                placeholder="Enter to search"
                class="input-section"
                v-model="searchKey"
                @input="delayedFilter"/>
              <i v-if="searchKey" class="fa fa-times btn-icon" @click.prevent.stop="clearSearch"></i>
            </div>
            <div class="item multiple"
                v-for="(option, i) of filteredOptions" :key="i"
                @click="onSelect(option)">
                <div>
                  <span class="item-icon me-2" v-if="option.icon" v-html="option.icon"></span>
                  <span class="item-text">{{option.text}}</span>
                </div>
                <span class="item-icon ml-2 px-2" v-if="checkSelected(option)"><i class="fas fa-check"></i></span>
            </div>
          </template>
          <template v-else>
            <div class="item" :class="{'active': (selectedOption && option.value === selectedOption.value)}"
                v-for="(option, i) of filteredOptions" :key="i"
                @click.prevent.stop="onSelect(option)">
                <span class="item-icon me-2" v-if="option.icon" v-html="option.icon"></span>
                <div class="item-content">
                  <div>
                    <span class="item-text">{{option.fname}} {{option.lname}}</span> <span class="item-subtext">{{option.user_type}}</span>
                  </div>
                  <span class="item-subtext">{{option.email}}</span>
                </div>
            </div>
          </template>
        </div>
      </div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.item-icon {
  font-size: 0.75rem;
}
.customize-select-label {
  font-size: 14px;
}
.customize-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 35px;
  line-height: 35px;
}

.selected {
  background-color: $color-input-background;
  border: 1px solid $color-input-background;
  border-radius: 6px;
  color: $text-color--tertiary;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  .selected-multiple {
    display: flex;
    width: 93%;
    overflow-x: hidden;
    .badge-outline-primary {
      color: #495057;
      border: 1px solid #cccccc;
      background-color: transparent;
      margin-right: 1px;
    }
    .badge-outline-warning {
      color: #495057;
      border: 1px solid #009efb;
      background-color: transparent;
      margin-right: 1px;
    }
  }
}

.item-text-placeholder {
  opacity: .4;
}

.selected.open {
  border: 1px solid #009efb;
}

.selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: $color-grey transparent transparent transparent;
}

.items.dropdown-wrapper {
  position: absolute;
  z-index: 1;
  color: #495057;
  border-radius: 5px;
  border: 1px solid $color-background;
  background-color: $color-background;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 2px 3px 3px rgba(30,33,40,0.5);
  -moz-box-shadow: 0px 2px 3px 3px rgba(30,33,40,0.5);
  box-shadow: 0px 2px 3px 3px rgba(30,33,40,0.5);
}

.item {
  font-size: 13px;
  color: #fff;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  user-select: none;
  display:flex;
  align-items: center;
  .item-content {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    .item-subtext {
      font-size: 12px;
      font-weight: 200;
      color: $color-grey;
    }
  }
  &:hover {
    background-color: #cccccc;
    color: $color-input-background;
    .item-subtext {
      color: $color-input-background;
    }
  }
  &.active {
    background-color: $color-primary;
  }
  &.multiple {
    justify-content: space-between;
  }
}

.search-wrapper {
    position: relative;
    width: 100%;
    height: 30px;
    overflow: hidden;
    .input-section {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        font-size: 14px;
        background: 0 0;
        background-color: #494b54;
        border: 1px solid #494b54;
        padding-left: 12px;
        padding-right: 30px;
        width: 100%;
        height: 30px!important;
        line-height: normal!important;
        border-radius: 5px;
        color: $text-color--secondary;
        opacity: .9;
        &:hover {
          opacity: 0.9;
        }
        &:focus {
          opacity: 1;
          border-color: $color-background-gray;
          outline: 0;
        }
    }
    .btn-icon {
        position: absolute;
        top: 4px;
        right: 9px;
        text-align: center;
        width: 16px;
        height: 16px;
        line-height: 22px;
        color: #666;
        border: none;
        background: 0 0;
        cursor: pointer;
        opacity: .5;
        &:hover {
            opacity: .9;
        }
        &.disabled {
            opacity: .3;
        }
    }
}

.inline-search-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .input-section {
    font-size: 16px;
    background-color: transparent;
    border: transparent;
    padding-left: 0;
    padding-right: 37px;
    width: 100%;
    height: 100%;
    line-height: 35px;
    color: $text-color--tertiary;
    &:focus {
      outline: 0;
    }
  }
  .btn-icon {
    position: absolute;
    top: 6px;
    right: 20px;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 22px;
    color: #666;
    border: none;
    background: 0 0;
    cursor: pointer;
    opacity: .5;
    &:hover {
        opacity: .9;
    }
    &.disabled {
        opacity: .3;
    }
  }
}
</style>