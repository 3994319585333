import { defineComponent } from "vue";
import appStore, { APP_MUTATION } from "@/store/app";
import conf from "@/config";
import customNotify from "@/components/commons/notify";
import moment from "moment";
import VueCookies from "vue-cookies";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import SideBar from "@/components/commons/adminSideBar";
import adminTitlePage from "@/components/commons/header-admin-page";
import welcomePopup from "@/components/WelcomePopup";
// import appLoading from "@/components/commons/appLoading";
// import NotificationPopup from '@/components/NotificationPopup.vue'
import { lowercase } from "@/misc/commons";
import { generic } from "@/misc";
import { segment } from '@/services/segment'

export default defineComponent({
  name: "app",
  components: {
    customNotify,
    Header,
    Footer,
    SideBar,
    adminTitlePage,
    welcomePopup,
    // appLoading,
    // NotificationPopup
    // CustomDialog
  },
  metaInfo() {
    const pageTitle =
      this.$route && this.$route.name
        ? `${this.$route.name} | Fueling Exponential Outcomes`
        : "Fuel Venture Capital | Fueling Exponential Outcomes";
    return {
      title: pageTitle,
      titleTemplate: "%s | Fuel Venture Capital",
    };
  },
  data() {
    return {
      enable: false,
      enabled: false,
      profileUserInfo: {
        headerText: "header text",
        bodyText: "body text",
        secret_key: "",
      },
      name: "secretKey",
      enableBalance: false,
      onAuthPage: "",
      onReloadPage: false,
      shouldStick: false,
      showPopup: undefined,
      popupImg: null,
    };
  },
  mounted() {
    let vue = this;
    window.addEventListener("scroll", vue.handleScroll());

    if (this.$route && this.$route.query?.r) {
      const pattern = new RegExp(/^[a-zA-Z0-9_]*$/);
      if (
        this.$route.query.r.length === 7 &&
        pattern.test(this.$route.query.r)
      ) {
        VueCookies.set("REFCODE", this.$route.query.r, 60 * 60 * 24 * 30);
      }
    }

    $(this.$el).find("#launching .progress .progress-bar").animate(
      {
        width: "98%",
      },
      "slow"
    );

    (window.vm = this), (window.vm = this);
    window.addEventListener("resize", this.onResize);

    this.onResize();
    this.onAuthPage = this.authRoutes.includes(this.currentRouter.path);
    if (generic.getJsonLocalStorage("ssid")) {
    }

    document.addEventListener('click', function(e) {
      const link = e.target.closest('a')
      if (link) {
        const evt = link.target && (link.target == 'blank' || link.target == "_blank")
          ? 'External link clicked'
          : 'Internal link clicked';

          if (segment.enabled) {
            segment.analytics.track(evt, { path: link.href, text: link.innerText ? link.innerText : link.textContent })
          }
      }
    });
  },

  watch: {
    secretKey: function (v) {
      if (this.secretKey !== "") {
        this.profileUserInfo.secret_key = this.secretKey;
        this.enable = true;
      }
    },
    currentRouter: function (v) {
      this.onAuthPage = this.authRoutes.includes(v.path);
    },
  },
  methods: {
    handleScroll() {
      var scrollTop = window.scrollY;
      if (scrollTop <= 100) {
        this.shouldStick = false;
      } else {
        this.shouldStick = true;
      }
    },
    clickChange() {
      this.enabled = true;
    },
    closeModal() {
      this.enabled = false;
    },
    onResize(event) {
      if (window.innerWidth > 1024) {
        appStore.commit(APP_MUTATION.client_version, {
          version: "pc",
        });
      } else if (window.innerWidth <= 1024 && window.innerWidth >= 768) {
        appStore.commit(APP_MUTATION.client_version, {
          version: "tablet",
        });
      } else {
        appStore.commit(APP_MUTATION.client_version, {
          version: "mobile",
        });
      }
      appStore.commit(APP_MUTATION.client_version, {
        size: window.innerWidth,
      });
    },
    closePopup() {
      this.onReloadPage = false;
    },
    onBlur() {
      let now = moment(new Date(), "DD/MM/YYYY HH:mm:ss");
      VueCookies.set("OUTFOCUS", now);
    },
    onFocus() {
      if (VueCookies.get("OUTFOCUS")) {
        let now = moment(new Date(), "DD/MM/YYYY HH:mm:ss");
        let then = moment(VueCookies.get("OUTFOCUS"), "DD/MM/YYYY HH:mm:ss");
        let minutes = moment
          .duration(
            moment(now, "DD/MM/YYYY HH:mm:ss").diff(
              moment(then, "DD/MM/YYYY HH:mm:ss")
            )
          )
          .minutes();

        if (minutes >= 30) {
          this.onReloadPage = true;
        } else {
          VueCookies.remove("OUTFOCUS");
        }
      }
    },
    idleTime() {
      let t = 0;
      function resTime() {
        t = 0;
      }
      let interval = setInterval(() => {
        t++;
        if (t >= 1800) {
          this.onReloadPage = true;
          clearInterval(interval);
          interval = null;
        }
      }, 1000);
      document.onload = resTime;
      document.onmousemove = resTime;
      document.onmousedown = resTime;
      document.ontouchstart = resTime;
      document.onclick = resTime;
      document.onscroll = resTime;
      document.onkeypress = resTime;
    },
    lowercase(string) {
      return lowercase(string);
    },
  },
  computed: {
    appName() {
      return conf.app_name;
    },
    auth() {
      return appStore.state.auth;
    },
    appInited() {
      return appStore.state.inited;
    },
    useEmptyLayout() {
      return appStore.state.useEmptyLayout || this.$route.meta?.isEmptyLayout;
    },
    hideSidebar() {
      return appStore.state.hideSidebar;
    },
    fullWidth() {
      return appStore.state.fullWidth;
    },
    showContactBanner() {
      return appStore.state.contactPage;
    },
    deviceVersion() {
      return appStore.state.mobile.version;
    },
    secretKey() {
      return appStore.state.secretKey;
    },
    authRoutes() {
      return appStore.state.authRoutes;
    },
    currentRouter() {
      return appStore.state.currentRoute;
    },
    hideHeader() {
      return appStore.state.isHideHeader;
    },
    hideFooter() {
      return appStore.state.isHideFooter;
    },
    profile() {
      return appStore.state.profile;
    },
  },
  beforeUnmount() {
    VueCookies.remove("OUTFOCUS");
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
});
