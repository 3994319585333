<template>
  <Modal
    :title="`${state == 'INSERT' ? 'Add' : 'Update'} Role`"
    v-model:value="isShow"
  >
    <form @submit.prevent="save" autocomplete="false">
      <div class="row">
        <div class="col-12 col-md-6">
          <TextField
            title="Name"
            name="name"
            :disabled="state == 'UPDATE'"
            :autocomplete="false"
            :errorMsg="errorMsg.name"
            :fieldRequire="true"
            v-model:value="form.name"
          />
        </div>
        <div class="col-12 col-md-6">
          <SelectField
            v-model:value="form.stt"
            title="Status"
            name="stt"
            :options="statusList"
          />
        </div>
        <div class="col-12">
          <label class="mb-2">Permissions</label>
          <div class="mb-2" v-for="(item, index) in permissionList" :key="item.id">
            <label class="checkbox-label pointer" :for="`chkPer_${item.id}`">
              <input
                type="checkbox"
                style="width: 16px; height: 16px"
                v-model="form.permissions[index]"
                :value="item.id"
                :id="`chkPer_${item.id}`"
                class="me-1"
              /> {{ item.desc }}</label>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <NewButton @click="save" :loading="loading">
        {{ state == "INSERT" ? "Add Role" : "Update" }}
      </NewButton>
    </template>
  </Modal>
</template>

<script src="./RoleManageModel.js"></script>
