import { defineComponent } from 'vue'
import detailStage from './detailStage'
import DealServices from '../../../../../services/deal'
import _ from 'lodash'
import { checkUserTypeFounder } from '../../../../../misc/commons'

export default defineComponent({
    name: 'discussion',
    props: {
        dataRating: { type: Array, default: null },
        totalStage: { type: Array, default: null },
        specifyIdDeal: { type: String, default: null },
        currentStage: { type: Number, default: null },
        reload: { type: Boolean, default: false }
    },
    components: {
        detailStage
    },
    data() {
        return {
            dealMessage: [],
            loading: false,
        }
    },
    mounted() {
        this.getDataReviewers()

        //#event bus dataRating
        // this.$root.$on('dataRating', function (data) {
        //     if (data) {
        //         vue.dataRating = data
        //     }
        // })
        
        //#event bus reloadDealMessage
        // this.$root.$on('reloadDealMessage', function (data) {
        //     if (data) {
        //         vue.getDataReviewers()
        //     }
        // })
    },
    methods: {
        getDataReviewers: function () {
            this.loading = true
            DealServices.getDealMessage(this.specifyIdDeal).then(resp => {
                if (resp.data.ok) {
                    this.dealMessage = resp.data.d
                }
            }).finally(() => {
                this.loading = false
            })
        },
        mapData: function (data) {
            let arr = []
            let _stages = _.cloneDeep(this.totalStage)
            _stages.reverse()
            _stages.map(p => {
                let obj = {}
                obj.stage = p.name
                obj.stage_id = p.id
                obj.index = p.order
                obj.rating = this.dataRating && this.dataRating.length ? this.dataRating.filter(i => i.stage_id === p.id) : null
                obj.reviewers = data.filter(d => d.stage_id === p.id)
                arr.push(obj)
            })
            return arr
        },
        isUserTypeFounder: function() {
            return checkUserTypeFounder();
        }
    },
    computed: {
        reviewers: {
            get() {
                let arr = []
                if (this.dealMessage?.length > 0 && this.dataRating?.length > 0) {
                    arr = this.mapData(this.dealMessage)
                } 
                return arr
            },
            set(value) {
                return value
            }
        }
    },
    watch: {
        dataRating (value) {
            if (value && this.reviewers?.length > 0) {
                this.reviewers.map(p => {
                    p.rating = value.filter(i => i.stage_id === p.stage_id)
                })
            }
        },
        reload(v) {
            this.getDataReviewers()
        }
    }
})