import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import NewButton from "@/components/NewButton";
import adminServices from "@/services/admin";
import Table, { debounce } from "@/components/Table";
import notify from "@/services/notify";
import SelectField from "@/components/SelectField";

const columnsData = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "roleName",
    name: "Role",
  },
  {
    id: "actions",
    name: "",
    titleClass: "text-right",
    columnClass: "text-right",
  },
];

export default defineComponent({
  inject: ["eventInfoData"],
  components: {
    Modal,
    TextField,
    NewButton,
    Table,
    SelectField,
  },
  emits: ["refresh"],
  data() {
    return {
      isShow: false,
      loading: false,
      rows: [],
      totalItems: 0,
      paging: {
        page: 1,
        total: 0,
        size: 10,
      },
      filteredName: "",
      filteredEmail: "",
      filteredUserType: "",
      orderBy: "",
      columns: columnsData,
      usersGetNoti: [],
      form: null,
      currentTab: null,
      listPotentialAttendee: [],
      nextPotentialAttendeeId: 1,
      error: false,
      InvestorRoleList: [],
      filteredInvestorRole: "",
    };
  },
  watch: {
    paging: {
      deep: true,
      handler() {
        this.getUsers();
      },
    },
    filteredName() {
      this.setPageDefault()
      debounce(this.getUsers);
    },
    filteredEmail() {
      this.setPageDefault()
      debounce(this.getUsers);
    },
    filteredInvestorRole() {
      this.setPageDefault()
      debounce(this.getUsers);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.eventInfoData) {
        this.form = this.eventInfoData;
      }
    });
    this.currentTab = "attendee";
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async create(props) {
      const res = await adminServices.getAllInvestorRoles({
        paging: { page: 1, size: 100 },
        filteredStatus: "ACTIVE",
      });
      if (
        res.data.ok &&
        res.data.d &&
        res.data.d.c &&
        res.data.d.c.length > 0
      ) {
        this.InvestorRoleList = res.data.d.c.map((x) => {
          return {
            code: x.id,
            name: x.name,
          };
        });
        this.InvestorRoleList.unshift({
          code: "",
          name: "All",
        });
      }

      this.isShow = true;
      // this.form = form;
    },
    selectUser(item) {
      if (!this.usersGetNoti.find((p) => p.userId === item.userId)) {
        this.usersGetNoti.push(item);
      } else {
        this.usersGetNoti = this.usersGetNoti.filter(
          (p) => p.userId !== item.userId
        );
      }
    },
    clearUsers() {
      this.usersGetNoti = [];
    },
    getAllUsers() {
      this.usersGetNoti = [];
      this.rows.forEach((el) => {
        this.selectUser(el);
      });
    },
    async getUsers() {
      if (!this.form.eventId) return;
      this.loading = true;
      const res = await adminServices.findEventUsersByTypes({
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
        filteredUserType: this.filteredUserType,
        types: this.form.userTypes,
        eventId: this.form.eventId,
        filteredInvestorRole: this.filteredInvestorRole || "",
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d;
      }
      this.loading = false;
    },
    isUserSelecting(props) {
      return this.usersGetNoti.some((x) => x.userId === props.userId)
        ? true
        : false;
    },
    async addUsers() {
      if (
        this.usersGetNoti.length == 0 &&
        this.listPotentialAttendee.length < 1
      )
        return notify.error("Please select attendee!");
      let potentialUsers = [];
      if (this.listPotentialAttendee.length > 0) {
        await this.handleData((arg) => {
          if (this.error)
            return notify.error("Please check Potential Attendee!");
          potentialUsers = arg.dataSave;
          this.callApiAddUsers(potentialUsers);
        });
      } else {
        this.callApiAddUsers(potentialUsers);
      }
    },
    async callApiAddUsers(potentialUsers) {
      this.loading = true;
      this.listUserId = [];
      this.listUserId = this.usersGetNoti.map((x) => {
        return x.userId;
      });

      const res = await adminServices.createAttendees({
        eventId: this.form.eventId,
        userIds: this.listUserId,
        potentialUsers: potentialUsers,
      });
      if (res && res.data && res.data.ok) {
        notify.success("Add successfully!");
        this.$emit("refresh");
        this.isShow = false;
      }
      this.loading = false;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    toTab(id) {
      this.currentTab = id;
    },
    deleteItem(id) {
      const index = this.listPotentialAttendee.findIndex((el) => el.id === id);
      this.listPotentialAttendee.splice(index, 1);
    },
    addItem() {
      this.listPotentialAttendee.push({
        id: this.nextPotentialAttendeeId++,
        name: "",
        email: "",
        errorName: "",
        errorEmail: "",
      });
    },
    validateValue(name, id, value) {
      const data = [...this.listPotentialAttendee];
      let messageError = "";
      if (value.trim().length < 1) {
        messageError =
          name === "name" ? "Please input name" : "Please input email";
      } else {
        messageError =
          name === "name"
            ? ""
            : this.validateEmail(value)
            ? ""
            : "Email is invalid";
      }
      const findIndx = data.findIndex((el) => `${el.id}` === id);
      const objTemp = data[findIndx];
      this.listPotentialAttendee[findIndx] = {
        ...objTemp,
        errorName: name === "name" ? messageError : objTemp.errorName,
        errorEmail: name === "name" ? objTemp.errorEmail : messageError,
      };
    },
    handleField(name, value) {
      const cutName = name.split(",");
      this.validateValue(cutName[1], cutName[0], value);
    },
    handleData: function (callback) {
      const data = [...this.listPotentialAttendee];
      const lstTemp = [];
      const dataSave = [];
      this.error = false;
      data.map((el, index) => {
        let valNew = { ...el };
        if (el.name.trim()?.length < 1) {
          valNew.errorName = "Please input name";
          this.error = true;
        }
        if (el.email.trim()?.length < 1) {
          valNew.errorEmail = "Please input email";
          this.error = true;
        }
        if (!this.validateEmail(el.email.trim())) {
          this.error = true;
        }
        lstTemp.push(valNew);
        dataSave.push({ fullName: valNew.name, email: valNew.email });
        if (index === data.length - 1) {
          this.listPotentialAttendee = lstTemp;
          callback({ error: this.error, dataSave });
        }
      });
    },
  },
});
