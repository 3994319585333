import _ from "lodash";
import Dragger from "@/components/Dragger";
import AdminServices from "@/services/admin";
import notify from "@/services/notify";
// import modalConfirm from '@/components/commons/modalConfirm'
import Dialog from "@/components/Dialog";
import addEditFund from "./addEditFund";
import addMemberToFund from "./addMemberToFund";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Dragger,
    // modalConfirm,
    Dialog,
    addEditFund,
    addMemberToFund,
  },
  data() {
    return {
      loading: false,
      displayedItemId: null,
      editItemId: null,
      isShowAddEdit: false,
      items: [],
      members: [],
      modalType: null,
      itemToConfirm: null,
      // isShowModalConfirm: false,
      modalTitle: null,
      modalMessage: null,
      isShowAddMemberToFund: false,
      message: {
        title: "",
        body: "",
      },
      options: {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Close",
        animation: "bounce", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "", // Custom class to be injected into the parent node for the current dialog instance
      },
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    displayedItem() {
      return this.items
        ? this.items.filter((x) => x.id === this.displayedItemId)[0]
        : null;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.items = await this.getFunds();
        if (this.items && this.items.length > 0) {
          if (!this.displayedItemId) {
            this.displayedItemId = this.items[0].id;
          }
          this.members = await this.getMembersByFundId(this.displayedItemId);
        } else {
          this.displayedItemId = null;
          this.members = [];
        }
      } catch (err) {
        console.log(err);
        notify.error("Error", "Some errors occurred.");
      }
      this.loading = false;
    },

    async getFunds() {
      try {
        const $fundRes = await AdminServices.findFunds();
        return !$fundRes.error ? $fundRes.data.d : [];
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    async getMembersByFundId(id) {
      try {
        const $memRes = await AdminServices.findMembersByFundId(id);
        return !$memRes.error ? $memRes.data.d : [];
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    openDialogToAddMemberToFund() {
      if (this.displayedItemId) {
        this.$nextTick(() => {
          this.isShowAddMemberToFund = true;
        });
      }
    },
    closeAddMemberToFund() {
      this.isShowAddMemberToFund = false;
    },
    onCompleteAddMemberToFund() {
      notify.success(`Add members successfully`);
      this.closeAddMemberToFund();
      this.loadData();
    },

    openDialogToAddEdit(itemId) {
      this.editItemId = itemId;
      this.$nextTick(() => {
        this.isShowAddEdit = true;
      });
    },
    closeAddEdit() {
      this.isShowAddEdit = false;
      this.editItemId = null;
    },
    onCompleteAddEdit(formType) {
      if (formType) {
        notify.success(`${formType} successfully`);
      }
      this.closeAddEdit();
      this.loadData();
    },

    openModalConfirm(type, item) {
      this.modalType = type;
      this.itemToConfirm = item;

      if (type === "deleteFund") {
        this.message.title = "Delete Fund";
        this.message.body = "Press button confirm to delete this fund";
      } else if (type === "removeMember") {
        this.message.title = "Remove member from fund";
        this.message.body =
          "Press button confirm to remove this member from fund";
      }

      this.$refs.dialog
        .confirm({ ...this.message, ...this.options })
        .then(async (d) => {
          if (!d) return;
          this.onConfirm();
        })
        .catch(function () {});
    },
    onConfirm() {
      const $item = Object.assign({}, this.itemToConfirm);
      const $type = this.modalType;
      this.closeModalConfirm();
      if ($type === "deleteFund") {
        this.deleteFund($item);
      } else if ($type === "removeMember") {
        this.removeMember($item);
      }
    },
    closeModalConfirm() {
      this.isShowModalConfirm = false;
      this.modalType = null;
      this.itemToConfirm = null;
      this.modalTitle = null;
      this.modalMessage = null;
    },
    async deleteFund(item) {
      try {
        this.loading = true;
        const $idToDelete = item.id;
        const $res = await AdminServices.removeFund($idToDelete);
        if (!$res.error) {
          notify.success("Delete fund successfully");
          if ($idToDelete === this.displayedItemId) {
            this.displayedItemId = null;
          }
          await this.loadData();
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async removeMember(item) {
      try {
        this.loading = true;
        const $memberIdToDelete = item.id;
        const $res = await AdminServices.removeMemberFromFund({
          id: this.displayedItemId,
          memberId: $memberIdToDelete,
        });
        if (!$res.error) {
          notify.success("Remove member successfully");
          await this.loadData();
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    onSortMemberEnd() {
      const tmpData = _.cloneDeep(this.members);
      tmpData.map((x, idx) => {
        x.orderNumber = idx;
        return x;
      });
      AdminServices.orderMembersOfFund({
        id: this.displayedItemId,
        orders: tmpData,
      }).then((resp) => {
        if (resp.data.ok) {
          this.loadData();
        }
      });
    },
    onSortFundEnd() {
      const tmpData = _.cloneDeep(this.items);
      tmpData.map((x, idx) => {
        x.orderNumber = idx;
        return x;
      });
      AdminServices.orderFund(tmpData).then((resp) => {
        if (resp.data.ok) {
          this.loadData();
        }
      });
    },
    selectFund(item) {
      this.displayedItemId = item.id;
      this.loadData();
    },
  },
});
