import { defineComponent } from 'vue'
import _ from 'lodash'

import adminServices from '@/services/admin'
import notify from '@/services/notify'
import Modal from '@/components/Modal'
import TextField from '@/components/TextField'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import NewButton from '@/components/NewButton'

export default defineComponent({
    components: {
        Modal,
        TextField,
        SelectField,
        NewButton,
        TextArea
    },
    data() {
        return {
            loading: false,
            state: 'INSERT',
            form: {
                first_name: null,
                last_name: null,
                email: null,
            },
            errorMsg: {
                first_name: null,
                last_name: null,
                email: null,
            },
        }
    },
    methods: {
        send() {
            if (!this.validData()) return

            if (this.state == 'INSERT') {
                this.addInvestorRequest()
            }
        },
        async addInvestorRequest() {
            this.loading = true
            const res = await adminServices.createInvestorRequest(this.form)
            if (res.data.ok) {
                notify.success('Create investor request success!')
                this.$emit('refresh')
                this.form = {
                    first_name: null,
                    last_name: null,
                    email: null,
                }
                this.isShow = false
            } else {
                notify.error('can\'t create investor request!')
            }
            this.loading = false
        },
        validData() {
            let flag = true
            if ($.trim(this.form.first_name) === '') {
                this.errorMsg.first_name = 'Please input first name'
                flag = false
            }
            if ($.trim(this.form.last_name) === '') {
                this.errorMsg.last_name = 'Please input last name'
                flag = false
            }
            if ($.trim(this.form.email) === '') {
                this.errorMsg.email = 'Please input email'
                flag = false
            }
            return flag
        }
    },
    watch: {
        'form.name': function (v) {
            this.errorMsg.first_name = null
        },
        'form.subject': function (v) {
            this.errorMsg.last_name = null
        },
        'form.body': function (v) {
            this.errorMsg.email = null
        }
    }
})