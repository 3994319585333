import QRCode from "qrcode";
import { defineComponent } from "vue";

export default defineComponent({
  name: "qr-code",
  props: ["value"],
  data() {
    return {
      canvas: null,
      width: 100,
      height: 100,
    };
  },
  watch: {
    value(value) {
      this.redraw(value);
    },
  },
  mounted() {
    this.canvas = $(this.$el).find("canvas")[0];
    this.redraw(this.value);
  },
  methods: {
    redraw(value) {
      QRCode.toCanvas(
        this.canvas,
        value || "Empty",
        {
          errorCorrectionLevel: "L",
          margin: 1,
          scale: 5,
          color: {
            dark: "#000",
            light: "#fff",
          },
          width: 100,
          height: 100,
        },
        function (error) {
          if (error) console.error(error);
        }
      );
    },
  },
});
