import _ from "lodash";
import axios from "axios";
import tracking from "../misc/tracking";
import appStore from "../store/app";
import conf from "../config";
import notify from "./notify";
import { ERROR_MSG } from "./error";
// import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";

export const API_ROOT = `${conf.api}`;
export const API_ROOT_AUTH = `${conf.api}/auth`;

export const authHttpConfig = {
  requiredAuth: true,
};
const error_mess = ["INVALID_CHECKIN", "INVALID_RESPONSE"];
export class AuthHttpResponse {
  static error(error, method, url, errorMsg) {
    console.warn(`Error [${method}] to ${url}`, error);
    this.errorObj = _.isObject(error) ? error : {};
    const res = new AuthHttpResponse(this.errorObj, this.errorObj);
    return res;
  }
  static success(data) {
    return new AuthHttpResponse(data);
  }
  constructor(data, error, errorObj, msg) {
    this.error = (data.error ? data.error : error) || false;
    this.errorObj = _.isObject(errorObj) ? errorObj : {};
    this.msg = msg;
    this.data = data;
    if (this.error) {
      tracking.error(
        `RESPONSE: ${JSON.stringify(data)} \n ERROR_OBJ: ${JSON.stringify(
          errorObj
        )} \n ERROR_MSG: ${msg}`
      );
    }
  }
  get isEmpty() {
    return _.isEmpty(this.data);
  }
  get errorMsg() {
    return ERROR_MSG[this.errorObj.reason]
      ? ERROR_MSG[this.errorObj.reason]
      : this.errorObj.message
      ? this.errorObj.message
      : this.msg
      ? this.msg
      : "Unknown error";
  }
}

const HTTP_METHOD = {
  get: "get",
  post: "post",
  delete: "delete",
  put: "put",
};

const processHttp = (method, url, data, config) => {
  return axios({
    method: method,
    url: `${url}`,
    data: data,
    withCredentials: false,
    headers: {
      // 'Authorization': 'Bearer ' + ssid,
      //'SiteSecretKey': 'zKJCoVZHCN'
    },
    ...config,
  })
    .then((response) => {
      if (process.env.NODE_ENV === "development") {
        console.warn(`[${method}]`, url, data || "");
      }
      if (response.status === 401) {
        //unauth
        // appStore.dispatch(AUTH_ACTIONS.not_auth)
        notify.error("Session expired or invalid");
        return AuthHttpResponse.error(response, method, url);
      }
      if (response.data.ok !== undefined && !response.data.ok) {
        //let lang = require(`../../src/config/lang/${appStore.state.currentLanguage.code}.json`)
        if (response.data.c) {
          if (
            response.data.m !== "Invalid data" &&
            !conf.ignore_err_notify_paths.includes(
              appStore.state.currentRoute.path
            )
          ) {
            notify.error(response.data.m);
          }
        }
      }
      var result;
      if (_.isObject(response) && _.isObject(response.data)) {
        let message = "";
        if (!_.isUndefined(response.data.m)) {
          message = response.data.m;
        }
        result = new AuthHttpResponse(
          response.data,
          !response.data.ok,
          response.data.error,
          message
        );
      } else {
        result = AuthHttpResponse.error(response, method, url);
      }

      //#todo research method return result is always fn
      // const result =
      //   _.isObject(response) && _.isObject(response.data)
      //     ? () => {
      //         let message = "";
      //         if (!_.isUndefined(response.data.m)) {
      //           message = response.data.m;
      //         }
      //         return new AuthHttpResponse(
      //           response.data,
      //           !response.data.ok,
      //           response.data.error,
      //           message
      //         );
      //       }
      //     : AuthHttpResponse.error(response, method, url);
      return result;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //notify.error('Session expired or invalid')
        // appStore.dispatch(AUTH_ACTIONS.not_auth)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return Promise.resolve(
        AuthHttpResponse.error(error, method, url, error.message)
      );
    });
};

export const authHttp = {
  get: (url, config) => processHttp(HTTP_METHOD.get, url, "", config),
  post: (url, data, config) => processHttp(HTTP_METHOD.post, url, data, config),
  put: (url, data, config) => processHttp(HTTP_METHOD.put, url, data, config),
  delete: (url, config) => processHttp(HTTP_METHOD.delete, url, config),
  success: AuthHttpResponse.success,
  error: AuthHttpResponse.error,
  buildParams(object) {
    const res = [];
    if (_.isObject(object)) {
      for (const k in object) {
        let val = object[k];
        if (val === undefined || val === null) {
          val = "";
        }
        if (_.isArray(val)) {
          val = val.join(",");
        }
        res.push(`${k}=${val}`);
      }
    }
    return res.length > 0 ? `${res.join("&")}` : "";
  },
  getUrl(root, url, isConfig) {
    const u = `${root}/${url}`;
    return isConfig ? { publicApi: u } : u;
  },
};

export default authHttp;
