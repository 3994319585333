<template>
    <div class="coinvestmentOp" v-loading="loading">
        <p class="page-title">
            Co-Investment Opportunities
        </p>
        <div class="row no-gutters">
            <!-- <div class="col-lg-6">
                <div class="portfolio">
                    <div class="portfolio-img">
                        <div class="img" @click.stop="showPreview(curve_mp4, FileTypes.VIDEO)" style='background-image: url("https://rokk3rfuel.s3.amazonaws.com/images/portfolio/f24cd7a4ca44.png");'>
                            <div class="overlay"></div>
                            <i class="play far fa-play-circle"></i>
                        </div>
                    </div>
                    <div class="info">
                        <div class="portfolio-info">
                            <p class="title">Curve</p>
                            <p class="subtext">Sector: FinTech<br />HQ: UK</p>
                            <p class="desc">
                                Curve is the world's first Over-The-Top (OTT) platform that allow users to aggregate multiple payment cards onto a single card and bundle all of the existing financial services in the banking system with an ultimate goal to create a seamless experience by removing any friction between the customers and their money.
                            </p>
                        </div>
                        <button class="btn btn-default doc" @click.prevent="showPreview(curve_pdf, FileTypes.PDF)">Series C Offering Document</button>
                        <div class="progress">
                            <span class="round">$10MM Round</span>
                            <div class="fueled">120% Fueled</div>
                            <div class="bar" style="width: 100%;"></div>
                        </div>
                        <p class="co-investors">Co-Investors: Connect Ventures, IDC Ventures, Banco Sabadell, Santander Bank and Gauss Venture</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="portfolio">
                    <div class="portfolio-img">
                        <div class="img" @click.stop="showPreview(soundtrack_mp4, FileTypes.VIDEO)" style='background-image: url("https://rokk3rfuel.s3.amazonaws.com/images/portfolio/09a9bdbf9735.jpeg");'>
                            <div class="overlay"></div>
                            <i class="play far fa-play-circle"></i>
                        </div>
                    </div>
                    <div class="info">
                        <div class="portfolio-info">
                            <p class="title">Soundtrack Your Brand</p>
                            <p class="subtext">Sector: Music Tech<br />HQ: Sweden</p>
                            <p class="desc">
                                Soundtrack Your Brand is the world's first direct licensing B2B music streaming platform, that has reshaped the B2B music landscape with a modern solution for playing and curating licensed music in public spaces.
                            </p>
                        </div>
                        <button class="btn btn-default doc" @click.prevent="showPreview(soundtrack_pdf, FileTypes.PDF)">Series D2 Offering Document</button>
                        <div class="progress">
                            <span class="round">$10MM Round</span>
                            <div class="fueled">88% Fueled</div>
                            <div class="bar" style="width: 88%;"></div>
                        </div>
                        <p class="co-investors">Co-Investors: Spotify, Balderton, Industrifonden, Telia</p>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-6" v-for="(item, idx) in items" :key="idx">
                <div class="portfolio">
                    <div class="portfolio-img">
                        <div class="img" @click.stop="showPreview(item.data.VideoId, FileTypes.VIDEO)" :style="`background-image: url('${item.photoUrl}');`">
                            <div class="overlay"></div>
                            <i class="play far fa-play-circle"></i>
                        </div>
                    </div>
                    <div class="info">
                        <div class="portfolio-info">
                            <p class="title">{{item.data.Name}}</p>
                            <p class="subtext">Sector: {{item.data.Sector}}<br />HQ: {{item.data.Hq}}</p>
                            <p class="desc scrollbar">
                                {{item.data.Description}}
                            </p>
                        </div>
                        <button class="btn btn-default doc" @click.prevent="showPreview(item.data.DocId, FileTypes.PDF)">{{item.data.DocTitle}}</button>
                        <div class="progress" v-if="item.data.ShowFuelPercentage">
                            <span class="round">{{item.data.FuelNote}}</span>
                            <div class="fueled">{{item.data.FuelPercentage}}% Fueled</div>
                            <div class="bar" :style="`width: ${item.data.FuelPercentage > 100 ? 100 : item.data.FuelPercentage}%;`"></div>
                        </div>
                        <p class="co-investors scrollbar">Co-Investors: {{item.data.CoInvestors}}</p>
                    </div>
                </div>
            </div>
        </div>

        <filePreview
            ref="filePreview"
            :fileUrl="preview.fileUrl"
            :fileType="preview.fileType"
        />
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
/* new layout */

.coinvestmentOp {
    .page-title {
        color: #fff;
        margin: 3rem 2rem;
        font-size: 1.5rem;
    }
    .portfolio {
        display: flex;
        margin-bottom: 1rem;
        .portfolio-img {
            display: flex;
            flex-grow: 1;
            align-items: center;
            .img {
                width: 100%;
                height: 300px;
                background-size: cover;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &:hover {
                    .overlay {
                        opacity: 0.1;
                    }
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    opacity: 0;
                }
                .play {
                    font-size: 70px;
                    color: #3F99EF;
                }
            }
        }
        .info {
            background: #ccc;
            padding: 1rem;
            flex: 0 0 275px;
            max-width: 275px;

            .portfolio-info {
                .title {
                    color: #3F99EF;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 1.2rem;
                }
                .subtext {
                    font-size: 0.7rem;
                    margin-bottom: 1rem;
                    line-height: 1rem;
                }
                .desc {
                    height: 220px;
                }
            }
            .doc {
                //font-size: 0.8rem;
                background: #000;
                color: #0069d9 !important;
                width: 100%;
                border-radius: 10px;
                margin-bottom: 1rem;
                font-weight: 600;

                &:hover {
                    background: #2f323b;
                }
            }
            .progress{
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                height: 28px;
                border-radius: 10px;
                background-color: #000;
                margin-bottom: 0.5rem;
                .bar{
                    display: block;
                    overflow: hidden;
                    height: 28px;
                    background-color: #3F99EF;
                }
                .fueled {
                    text-align: center;
                    font-weight: 600;
                    line-height: 28px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                }
                .round {
                    position: absolute;
                    top: -3px;
                    left: 10px;
                    font-size: 0.5rem;
                    font-style: italic;
                    font-weight: 600;
                }
            }
            .co-investors {
                font-size: 0.7rem;
                line-height: 1;
                margin-bottom: 0;
                height: 33px;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .portfolio {
            flex-direction: column;

            .info {
                max-width: 100%;
                .portfolio-info {
                    .desc {
                        height: auto;
                    }
                }
                .co-investors {
                    height: auto;
                }
            }
        }
    }
}
/*
.coinvestmentOp {
    display: block;
    overflow: hidden;

    .sidebar{
        height: calc(100vh - 65px);
        overflow-y: auto;
        background: #0d1d29;
        padding-right: 0px;
        .listCompany{
            padding: 10px;
            .cat {
                color: #fff;
                font-weight: 600;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    &.border-bottom{
                        border-bottom-color: #b5bbc1 !important;
                    }
                    &.active{
                        .item{
                            background-color: #5897E7;
                            color: #fff;
                        }
                    }
                    .item{
                        text-align: center;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        padding: 10px 5px;
                        h5 {
                            margin: 0;
                            @media screen and (max-width: 1100px) {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .main{
        background-color: #0d1d29;
        padding-left: 0px !important;
        .content-header{
            .item{
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 5px;
                color: #909090;
                &.active{
                    background-color: #fff;
                    color: #333;
                }
                .icon{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    svg{
                        width: 40px;
                    }
                }
                .text{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .content-body{
            height: calc(100vh - 65px);
        }
    }
}
@media screen and (max-width: 992px) {
    .coinvestmentOp{
        > .row{
            display: block;
        }
        .sidebar,
        .main{
            flex: unset;
            max-width: 100%;
        }
        .sidebar{
            height: auto;
            .listCompany{
                ul{
                    li{
                        display: inline-block;
                        margin-bottom: 0 !important;
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0px;
                        }
                        .item{
                            padding: 5px 20px;
                            h3{
                                font-size: 18px;
                            }
                            p{
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
        .main{
            .content-body{
                height: calc(100vh - 247px);
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .coinvestmentOp{
        .sidebar{
            padding-right: 15px;
            padding-left: 15px;
            .listCompany{
                padding: 0;
                ul{
                    display: flex;
                    padding: 10px;
                    li{
                        flex: 1;
                    }
                }
            }
        }
    }
}*/
</style>