<template>
  <div class="adminContent p-3">
    <!-- <div class="mb-3" v-if="readyRender">
      <div class="row mb-3">
        <div class="col-12 col-md-6">
          <div class="data p-3">
            <h5 class="card-title">
              Application Statistic
              <span class="time" style="float: right">Sep 2019</span>
            </h5>
            <hr />
            <div class="row">
              <div class="col-4">&nbsp;</div>
              <div class="col-4 text-center">
                <h4 class="colorBlue">Total: 50</h4>
                <p>Last Month: 40</p>
              </div>
              <div class="col-4">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col-4">
                <h5>Accept: 30</h5>
                <p>Last Month: 20</p>
              </div>
              <div class="col-4 text-center">
                <h5>Reject: 10</h5>
                <p>Last Month: 10</p>
              </div>
              <div class="col-4">
                <h5>Pending: 10</h5>
                <p>Last Month: 10</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="data p-3">
            <h5 class="card-title">Company Types</h5>
            <hr />
            <div id="chart" ref="barchart"></div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="text-right mb-2 status">
            <span class="iconGray colorWhite">Pending</span>
            <span class="iconRed colorWhite">Rejected</span>
            <span class="iconBlue colorWhite">Accepted</span>
          </p>
          <div class="data p-3">
            <div class="row">
              <div class="col-6">
                <h5 class="card-title">Submission Portal</h5>
              </div>
              <div class="col-6 text-right">
                <button
                  class="btn dropdown-toggle btn-link btn-small"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Montly
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <span class="dropdown-item pointer">Montly</span>
                  <span class="dropdown-item pointer">Quater</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  id="barstackedchart"
                  ref="barstackedchart"
                  height="200"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<style lang="scss" scoped>
.card-title {
  color: #8798ad;
  font-size: 16px;
}
.status {
  font-size: 14px;
  span {
    margin-left: 10px;
    &.iconGray,
    &.iconRed,
    &.iconBlue {
      &:before {
        content: "";
        display: inline-block;
        overflow: hidden;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 3px;
      }
    }
    &.iconGray {
      &:before {
        background-color: #c9c9c9;
      }
    }
    &.iconRed {
      &:before {
        background-color: #ff7777;
      }
    }
    &.iconBlue {
      &:before {
        background-color: #3e99ed;
      }
    }
  }
}
</style>
<script src="./index.js"></script>
