import AdminServices from "@/services/admin";
import popupAddEditFueled from "./addEditFueled";
import _ from "lodash";
import notify from "@/services/notify";
// import draggable from 'vuedraggable' //#todo update module dragable
import appStore from "@/store/app";
import Table, { debounce } from "@/components/Table";
import TextField from "@/components/TextField";
import Dialog from "@/components/Dialog";
import { defineComponent } from "vue";

const fuelTypesData = [
  {
    name: "All",
    code: "",
  },
  {
    name: "Thought Leadership",
    code: "LEADERSHIP",
  },
  {
    name: "News",
    code: "NEWS",
  },
  {
    name: "Private News",
    code: "PRIVATE_NEWS",
  },
  {
    name: "Video",
    code: "VIDEO",
  },
];

const columnsData = [
  {
    id: "date",
    name: "Date",
  },
  {
    id: "title",
    name: "Title",
  },
  {
    id: "type",
    name: "Type",
  },
  {
    id: "uploadImage",
    name: "Upload Image",
  },
  {
    id: "link",
    name: "Link",
  },
  {
    id: "actions",
    name: "Actions",
  },
];

export default defineComponent({
  data() {
    return {
      loading: false,
      fuelTypes: fuelTypesData,
      searchParams: {
        paging: {
          size: 10,
          page: 1,
          total: 0,
        },
        filteredTitle: "",
        filteredType: "",
      },
      tableData: [],
      tableCellWidth: 0,
      prefixWeb: "http",
      columns: columnsData,
    };
  },
  components: {
    popupAddEditFueled,
    // draggable,
    Table,
    TextField,
    Dialog,
  },
  mounted() {
    this.$nextTick(() => {
      this.getFuelAndMindData();
    });
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    updatePaging(v) {
      this.searchParams.paging = Object.assign(this.searchParams.paging, v);
    },
    loadPage(value) {
      this.searchParams.paging.page = value;
      this.getFuelAndMindData();
    },
    getFuelAndMindData() {
      this.loading = true;
      AdminServices.getFuelAndMindData(this.searchParams)
        .then((resp) => {
          if (resp.data.ok) {
            this.tableData = resp.data.d.c;
            this.searchParams.paging.total = resp.data.d.t;
            this.searchParams.totalItems = resp.data.d.t;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeFueled(id) {
      let me = this;
      let message = {
        title: "Warning",
        body: "This will permanently delete this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          AdminServices.removeFueledData(id).then((resp) => {
            if (resp.data.ok) {
              notify.success("Delete success");
              me.getFuelAndMindData();
            } else {
              notify.error(`Error ${error.resp.error.message}`);
            }
          });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    editFueled(id) {
      const item = _.cloneDeep(this.tableData).find((x) => x.id === id);
      this.$refs.popupAddEditFueled.showModal(item);
    },
    showAddFueled() {
      this.$refs.popupAddEditFueled.showModal();
    },
    onResize: function () {
      let w = $(this.$refs.table).parent().width() - 100 - 110 - 90;
      this.tableCellWidth = Math.round(w / 3);
    },
    onEnd: function (v) {
      AdminServices.sortFueledData(this.tableData).then((resp) => {
        if (resp.data.ok) {
          this.getFuelAndMindData();
        }
      });
    },
    delayedFilter() {
      if (this.filterTimer != null) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(this.doFilter, 500);
    },
    doFilter() {
      this.filterTimer = null;
      this.loadPage(1);
    },
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
    screenSize: {
      get() {
        return this.$store.state.mobile.size;
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    screenSize() {
      this.onResize();
    },
    "searchParams.paging.page": {
      deep: true,
      handler: function (v) {
        debounce(this.getFuelAndMindData);
      },
    },
    "searchParams.filteredTitle": function (v) {
      this.setPageDefault()
      debounce(this.doFilter);
    },
  },
});
