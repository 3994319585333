<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideAddFolder"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">Add folder</h4>
          </div>
          <div class="modal-body" v-loading="loading">
            <form @submit.prevent="validData" autocomplete="false">
              <div class="row mb-2">
                <div class="col col-6">
                  <TextField
                    title="Folder Name"
                    name="name"
                    fieldRequire
                    :autocomplete="false"
                    :errorMsg="errorMsg.name"
                    v-model:value="form.name"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="hideAddFolder">
              Cancel
            </button>
            <button class="btn btn-primary" @click="validData">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
