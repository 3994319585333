import { defineComponent } from "vue";
import _ from "lodash";
import moment from "moment";

import validateEmail from "@/misc/checkemail";
import adminServices from "@/services/admin";
import notify from "@/services/notify";

import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";
import Datepicker from "@/components/DatePicker";
import NavTab from "@/components/NavTab";
import TextArea from "@/components/TextArea";
import SelectEmailTemplate from "@/components/SelectEmailTemplate";
import Switcher from "@/components/Switcher";
import customSelect from "@/components/commons/customSelect";

export class userInfo {
  constructor() {
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.allowReferral = false;
  }
}

export default defineComponent({
  name: "AddInvestorModel",
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
    Datepicker,
    NavTab,
    TextArea,
    SelectEmailTemplate,
    Switcher,
    customSelect,
  },
  props: {
    roleList: { type: Array, default: [] },
    investorTypeList: { type: Array, default: [] },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      selectedTab: 0,
      tabs: [
        { id: "email_content", name: "Email Content", contentClass: "boxed" },
        { id: "saved_templates", name: "Saved Templates" },
      ],
      tmpDate: null,
      form: {
        users: [],
        expires_datetime: null,
        investor_type: "Current",
        role_id: null,
        ndaSignRequired: true,
        subject: null,
        body: null,
        lp_fund_ids: [],
      },
      errorMsg: {
        users: [],
        expires_datetime: null,
        investor_type: null,
        subject: null,
        body: null,
        lp_fund_ids: null,
      },
      appliedTmpl: {
        id: null,
        name: "",
      },
      lpFundOptions: [],
    };
  },
  created() {
    this.addUser();
  },
  watch: {
    "form.users": {
      deep: true,
      handler(v) {
        v.map((p, idx) => {
          if (p.firstname.trim() !== "") {
            this.errorMsg.users[idx].firstname = null;
          }
          if (p.lastname.trim() !== "") {
            this.errorMsg.users[idx].lastname = null;
          }
          if (p.email.trim() !== "") {
            this.errorMsg.users[idx].email = null;
          }
        });
      },
    },
    "form.lp_fund_ids"(v) {
      if (v) {
        this.errorMsg.lp_fund_ids = null;
      }
    },
    "form.subject": function (v) {
      if (v.trim() !== "") {
        this.errorMsg.subject = null;
      }
    },
    "form.body": function (v) {
      if (v.trim() !== "") {
        this.errorMsg.body = null;
      }
    },
    appliedTmpl(val) {
      this.form.subject = val.subject;
      this.form.body = val.body;
      this.selectedTab = 0;
    },
    tmpDate: {
      deep: true,
      handler(v) {
        if (v) {
          this.form.expires_datetime = new Date(
            `${v.years}-${v.months}-${v.days}`
          );
          this.errorMsg.expires_datetime = null;
        }
      },
    },
  },
  methods: {
    async getAllLpFunds() {
      const res = await adminServices.getAllLpFunds();
      if (res.error) return;

      if (res.data.ok) {
        const temp = res.data.d.map((x) => ({
          value: x.lpfund_id,
          text: x.is_enabled ? x.name : `${x.name} (Inactive)`,
        }));
        this.lpFundOptions = temp || [];
      }
    },
    showAdd() {
      this.isShow = true;
      this.getAllLpFunds();
    },
    async save() {
      if (!this.validData()) return;

      this.loading = true;
      const _data = {
        expiresDatetime: this.form.expires_datetime,
        investorType: this.form.investor_type,
        roleId: this.form.role_id,
        ndaSignRequired: this.form.ndaSignRequired,
        subject: this.form.subject,
        body: this.form.body,
        investors: this.form.users.map((x) => {
          return {
            firstName: x.firstname.trim(),
            lastName: x.lastname.trim(),
            email: x.email.trim(),
            allowReferral: x.allowReferral,
          };
        }),
        lpFundIds: this.form.lp_fund_ids.join(","),
      };
      const res = await adminServices.inviteInvestor(_data);
      if (res.data.ok) {
        notify.success("Add investor success!");
        this.$emit("refresh");
        this.isShow = false;
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if (!this.form.subject || this.form.subject.trim() === "") {
        this.errorMsg.subject = "Please input subject";
        flag = false;
      }
      if (!this.form.body || this.form.body.trim() === "") {
        this.errorMsg.body = "Please input body text";
        flag = false;
      }
      /* if (
        !!!this.form.expires_datetime ||
        moment().add(1, "d").isAfter(this.form.expires_datetime, "day")
      ) {
        this.errorMsg.expires_datetime = "Time should be null / in the future";
        flag = false;
      } */
      if (!this.form.role_id) {
        this.errorMsg.role_id = "Please select role";
        flag = false;
      }
      if (!this.form.investor_type) {
        this.errorMsg.investor_type = "Please select investor type";
        flag = false;
      }
      if (this.form.lp_fund_ids.length === 0) {
        this.errorMsg.lp_fund_ids = "Please select Funds";
        flag = false;
      }

      const validateDataUser = this.validateDataUser();
      if (!validateDataUser) {
        flag = validateDataUser;
      }
      return flag;
    },
    validateDataUser() {
      let flag = true;
      this.form.users.forEach((user, i) => {
        if ($.trim(user.firstname) === "") {
          this.errorMsg.users[i].firstname = "Please input first name";
          flag = false;
        }
        if ($.trim(user.lastname) === "") {
          this.errorMsg.users[i].lastname = "Please input last name";
          flag = false;
        }
        if (!validateEmail.validateEmail(user.email)) {
          this.errorMsg.users[i].email = "Email does not valid";
          flag = false;
        }
      });
      return flag;
    },
    addUser() {
      this.form.users.push(new userInfo());
      this.errorMsg.users.push(new userInfo());
    },
    removeUser(index) {
      if (this.form.users.length > 1 && this.errorMsg.users.length > 1) {
        this.form.users.splice(index, 1);
        this.errorMsg.users.splice(index, 1);
      }
    },
  },
});
