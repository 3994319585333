<template>
  <div class="our-team-container">
    <div class="our-team-main container-v2">
      <div class="our-team-inner">
        <div class="text-a-wrapper o-text-1">
          <h2 class="text-animation">Our Team</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import ScrollOut from "scroll-out";

export default {
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [],
      translateY: 110,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.translateY = 48;
    }
    this.handleScrollTextReveal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();

      ScrollOut({
        targets: ".our-team-inner",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationBackground();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });

      ScrollOut({
        targets: ".o-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });
    },

    handleSliceText() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".o-text-1 .text-animation");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );
    },
    animationBackground() {
      anime({
        targets: ".our-team-inner",
        duration: 1000,
        easing: this.easing,
        opacity: [0, 1],
        translateY: [30, 0],
      });
    },
    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".o-text-1 .text-animation");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".o-text-1 .letter",
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          console.log("No more numbers available.");
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.our-team-container {
  color: #fff;

  background: url("../../../../assets/images/v2/our-team-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 80%;
}
.our-team-main {
  width: 100%;
  min-height: calc(100vh - 74px);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .our-team-inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 892px;

    padding: 24px 48px;
    /* background: rgba(0, 0, 0, 0.25); */

    margin-top: 10%;

    @media (min-width: 768px) {
      margin-top: 30%;
    }
    @media (min-width: 1500px) {
      margin-top: 15%;
    }
  }

  .text-animation {
    font-size: 48px;
    font-weight: 900;

    padding-bottom: 8px;

    @media (min-width: 768px) {
      font-size: 110px;
      line-height: 110px;
      letter-spacing: 10px;
    }
  }

  .o-content {
    font-family: "SF Pro Text";
    text-align: justify;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .txt-blue {
      color: #0095ff;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}

/* .gif-container {
  position: relative;
  width: 100%;
  height: 60vh;
  mix-blend-mode: screen;
} */
</style>
