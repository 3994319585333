import AdminServices from '@/services/admin'
import notify from '../../../../services/notify'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'trashFileItems',
    props: ['items', 'hideTrashFileItems'],
    data() {
        return {
            loading: false,
            itemsToTrash: [],
            completed: false
        }
    },
    computed: {
        isValidToProcess() {
            let _invalidItems = this.itemsToTrash.filter(x => x.type === 'Folder' || !x.allow_delete)
            if (!this.itemsToTrash || this.itemsToTrash.length === 0 || _invalidItems.length > 0) {
                return false
            } else {
                return true
            }
        }
    },
    created() {
        this.itemsToTrash = this.items
    },
    methods: {
        processTrash() {
            if (this.isValidToProcess) {
                this.loading = true
                AdminServices.trashDataroomFileItems({
                    ids: this.itemsToTrash.map(x => x.id)
                }).then(resp => {
                    this.completed = true
                    if (resp.data.ok) {
                        this.itemsToTrash = this.itemsToTrash.map(x => {
                            return {
                                ...x,
                                isTrashed: true
                            }
                        })
                        notify.success('Move files to trash successfully!')
                        this.hideTrashFileItems()
                    }
                }).finally(() => { this.loading = false })
            }
        },
    }
})