import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import TextArea from "@/components/TextArea";
import NewButton from "@/components/NewButton";
import Checkbox from "@/components/Checkbox";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Datepicker from "@/components/DatePicker";
import moment from "moment";
import MultiSelectField from "@/components/MultiSelectField";
import Table, { debounce } from "@/components/Table";
import ArrowIcon from "@/assets/images/icon/icon-arrow.svg";
import SelectField from "@/components/SelectField";

export default defineComponent({
  name: "NotificationModal",
  props: ["reload"],
  components: {
    Modal,
    TextField,
    TextArea,
    NewButton,
    Checkbox,
    Datepicker,
    MultiSelectField,
    SelectField,
    Table,
    ArrowIcon,
  },
  data() {
    return {
      isShow: false,
      state: "CREATE",
      loading: false,
      loadingView: false,
      title: "",
      content: "",
      url: null,
      usersGetNoti: [],
      errorMsg: {
        title: "",
        content: "",
        datetime: "",
        selectUser: "",
      },
      addNotiUsers: false,

      rows: [],
      totalItems: 0,
      investorDataLoading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredName: "",
      filteredEmail: "",
      filteredStatus: null,
      orderBy: "",
      columns: [
        {
          id: "name",
          name: "Name",
          // sort: true,
          // direction: ''
        },
        {
          id: "email",
          name: "Email",
          direction: "",
        },
        {
          id: "is_device",
          name: "App installed",
          direction: "",
        },
        {
          id: "actions",
          name: "",
          titleClass: "text-end",
          columnClass: "text-end",
        },
      ],
      selectDeviceList: [
        {
          name: "All",
          code: null,
        },
        {
          name: "Installed",
          code: true,
        },
        {
          name: "Not yet",
          code: false,
        },
      ],
      itemSelected: null,
      checkAllUser: false,
    };
  },
  mounted() {
    this.getAllUser();
  },
  computed: {
    targetUsersIds() {
      return this.usersGetNoti.map((x) => x.id);
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAllUser() {
      if (!this.itemSelected) return;
      // this.clearUsers();
      this.investorDataLoading = true;
      const res = await adminServices.findAllInvestorsByLpFund({
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
        lpFundIds: this.itemSelected.lp_funds
          .map((x) => x.lp_fund_id)
          .join(","),
        hasDevice: this.filteredStatus,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d;
      }
      this.investorDataLoading = false;
    },
    show(props) {
      this.state = "VIEW";
      this.isShow = true;
      this.useSchedule = true;
      this.selectNotificationById(props.id);
      this.getAllUser();
    },
    create(props) {
      this.itemSelected = props;
      this.isShow = true;
      this.state = "CREATE";
    },
    async selectNotificationById(id) {
      this.loadingView = true;
      const result = await adminServices.getNotiByLpFundId(id);
      if (result.data.ok && result.data.d) {
        this.title = result.data.d.content_title;
        this.content = result.data.d.content_body;
        this.rows = result.data.d.target_users || [];
        this.loadingView = false;
      }
    },
    selectUser(item) {
      if (this.state !== "CREATE") return;
      if (!this.usersGetNoti.find((p) => p.id === item.id)) {
        this.usersGetNoti.push(item);
        this.errorMsg.selectUser = "";
      } else {
        this.usersGetNoti = this.usersGetNoti.filter((p) => p.id !== item.id);
        if (this.usersGetNoti.length > 0) this.errorMsg.selectUser = "";
      }
    },
    async sendNoti() {
      if (!this.validData()) {
        return;
      }

      this.loading = true;
      function appendArray(form_data, key, value) {
        if (!value) {
          // form_data.append(key, null)
        } else {
          if (typeof value === "object" && value.length) {
            for (let i = 0; i < value.length; i++) {
              form_data.append(`${key}[${i}]`, value[i]);
            }
          } else {
            form_data.append(key, value);
          }
        }

        return form_data;
      }
      let formData = new FormData();
      appendArray(formData, "ContentTitle", this.title);
      appendArray(formData, "ContentBody", this.content);
      appendArray(formData, "TargetUserIds", this.targetUsersIds);
      const result = await adminServices.createNotificationLpFund(
        this.itemSelected.id,
        formData
      );
      if (result.data.c == "invalid_notification") {
        this.resetValue();
      }
      if (result.data.ok) {
        notify.success("Your notification was sent successfully!");
        this.reload();
        this.resetValue();
        this.$refs.close.click();
      } else {
        notify.error("Error occur!");
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if ($.trim(this.title) === "") {
        this.errorMsg.title = "Please input title";
        flag = false;
      }
      if ($.trim(this.content) === "") {
        this.errorMsg.content = "Please input notification message";
        flag = false;
      }
      if (this.usersGetNoti.length <= 0) {
        this.errorMsg.selectUser = "Please select user";
        flag = false;
      }
      return flag;
    },
    resetValue() {
      this.title = "";
      this.content = "";
    },
    clearUsers() {
      this.usersGetNoti = [];
    },
    getAllUsers() {
      this.usersGetNoti = [];
      this.rows.forEach((el) => {
        this.selectUser(el);
      });
    },
    funcUseSchedule() {
      if (this.state !== "CREATE") {
        return;
      }
      this.useSchedule = !this.useSchedule;
    },
    showAddNotiUsers() {
      this.addNotiUsers = true;
    },
    isUserSelecting(props) {
      return this.usersGetNoti.some((x) => x.id === props.id) ? true : false;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
    onSelectAllUser(v, isChecked) {
      this.checkAllUser = isChecked;
      if (isChecked) {
        this.getAllUsers();
      } else {
        this.clearUsers();
      }
    },
    handleSelectUser(v) {
      this.selectUser(v);
    },
  },
  watch: {
    itemSelected() {
      this.getAllUser();
    },
    title(v) {
      this.errorMsg.title = null;
    },
    content(v) {
      this.errorMsg.content = null;
    },
    paging() {
      debounce(this.getAllUser);
    },
    filteredName() {
      this.setPageDefault();
      debounce(this.getAllUser);
    },
    filteredEmail() {
      this.setPageDefault();
      debounce(this.getAllUser);
    },
    filteredStatus() {
      this.setPageDefault();
      debounce(this.getAllUser);
    },
    columns: {
      deep: true,
      handler(v) {
        let col = v.find((p) => typeof p.sort === "boolean" && p.sort);
        this.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
});
