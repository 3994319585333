<template>
  <div>
    <div
      class="tab-wrapper container-v2"
      :class="`tab-wrapper-${$route.params.id}`"
    >
      <div class="wrap">
        <div class="perspectives-list">
          <div class="tabs-control">
            <PerspectivesTabs :isTabMobile="false" @changeView="changeView" />
          </div>

          <div
            v-for="item in list"
            :key="item.perspective_id"
            class="perspectives-card"
            :class="`perspectives-card-${$route.params.id}`"
          >
            <h5 class="perspectives-title">
              {{ item.title }}
            </h5>

            <div class="box">
              <div class="img">
                <img
                  :src="item.thumbnail"
                  alt="picture"
                  onerror="this.onerror=null;this.src='/public/images/default.jpeg';"
                />
              </div>

              <div class="content">
                <p class="heading">{{ item.description }}</p>

                <a class="btn-read-more" @click="showPdfDetails(item)"
                  >Read more
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1107_16134)">
                      <path
                        d="M7.28027 6.09801L11.7803 3.50001L7.28027 0.902008V3.05001H0.755875V3.95001H7.28027V6.09801Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1107_16134">
                        <rect
                          width="12"
                          height="6"
                          fill="white"
                          transform="matrix(-1 0 0 1 12.5 0.5)"
                        />
                      </clipPath>
                    </defs></svg></a>
              </div>

              <div class="footer desktop-small">
                <div class="inner">
                  <p class="author">{{ author }}</p>
                  <p class="position">{{ item.position }}</p>

                  <div v-if="item.signature" class="signature">
                    <img :src="item.signature" />
                  </div>

                  <div class="mine-card-1">
                    <div class="logo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 191 194"
                        fill="none"
                      >
                        <path
                          d="M61.2287 15.2449C70.2737 10.1639 79.3227 5.08278 88.3756 0.00170898C88.3822 48.2667 88.3822 96.5317 88.3756 144.797L-0.000244141 193.751V167.173C20.4107 155.936 40.8204 144.697 61.2287 133.456C61.2221 122.614 61.2221 111.771 61.2287 100.927C47.8112 108.231 34.3983 115.536 20.99 122.841C20.9834 113.981 20.9834 105.126 20.99 96.2744C34.3943 88.9707 47.8026 81.6663 61.2149 74.3613C61.24 54.6623 61.2446 34.9569 61.2287 15.2449Z"
                          fill="#0095FF"
                        />
                        <path
                          d="M102.624 -0.000244141C111.676 5.07432 120.725 10.1554 129.771 15.243C129.771 34.9523 129.771 54.6597 129.771 74.3652C143.182 81.6611 156.586 88.9629 169.984 96.2705C169.977 105.13 169.977 113.985 169.984 122.837C156.58 115.533 143.175 108.231 129.771 100.931C129.764 111.774 129.764 122.617 129.771 133.46C150.175 144.71 170.585 155.949 191 167.177C190.993 176.036 190.993 184.892 191 193.743C161.541 177.432 132.086 161.116 102.634 144.797L102.624 -0.000244141Z"
                          fill="#0095FF"
                        />
                      </svg>
                    </div>

                    <div class="fuel-title">
                      <span>Fuel Venture Capital</span>
                      {{ $filters.parseEstTime(item.created_date, "MMMM Do, YYYY") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="footer desktop-large">
              <div class="inner">
                <p class="author">{{ author }}</p>
                <p class="position">{{ item.position }}</p>

                <div v-if="item.signature" class="signature">
                  <img :src="item.signature" />
                </div>

                <div class="mine-card-1">
                  <div class="logo">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 191 194"
                      fill="none"
                    >
                      <path
                        d="M61.2287 15.2449C70.2737 10.1639 79.3227 5.08278 88.3756 0.00170898C88.3822 48.2667 88.3822 96.5317 88.3756 144.797L-0.000244141 193.751V167.173C20.4107 155.936 40.8204 144.697 61.2287 133.456C61.2221 122.614 61.2221 111.771 61.2287 100.927C47.8112 108.231 34.3983 115.536 20.99 122.841C20.9834 113.981 20.9834 105.126 20.99 96.2744C34.3943 88.9707 47.8026 81.6663 61.2149 74.3613C61.24 54.6623 61.2446 34.9569 61.2287 15.2449Z"
                        fill="#0095FF"
                      />
                      <path
                        d="M102.624 -0.000244141C111.676 5.07432 120.725 10.1554 129.771 15.243C129.771 34.9523 129.771 54.6597 129.771 74.3652C143.182 81.6611 156.586 88.9629 169.984 96.2705C169.977 105.13 169.977 113.985 169.984 122.837C156.58 115.533 143.175 108.231 129.771 100.931C129.764 111.774 129.764 122.617 129.771 133.46C150.175 144.71 170.585 155.949 191 167.177C190.993 176.036 190.993 184.892 191 193.743C161.541 177.432 132.086 161.116 102.634 144.797L102.624 -0.000244141Z"
                        fill="#0095FF"
                      />
                    </svg>
                  </div>

                  <div class="fuel-title">
                    <span>Fuel Venture Capital</span>
                    {{ $filters.parseEstTime(item.created_date, "MMMM Do, YYYY") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appStore, { APP_MUTATION } from "@/store/app";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PerspectivesTabs from "./_PerspectivesTabs.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    PerspectivesTabs,
  },
  props: {
    name: { type: String, default: "" },
  },

  data() {
    return {
      quarterItem: null,
      list: [],
      author: "",
    };
  },

  computed: {
    listPerspectives() {
      return this.$store.state.perspectivesList;
    },
  },

  created() {
    const path = this.$route.params.id;

    const findAuthor = this.listPerspectives.find((x) => x.path === path);
    if (!findAuthor) {
      this.$router.push("/fuel-the-mind/portfolio-news");
      return;
    }
    this.author = findAuthor.name;
    this.list = findAuthor.extra_info;
  },

  mounted() {
    // mobile is disable horizontal scroll
    if (window.innerWidth < 768) return;
    this.handleScrollHorizontal();
  },
  methods: {
    showPdfDetails(pdf) {
      const author = this.$route.params.id;
      const url = `/fuel-the-mind/perspectives/${author}/${pdf.pdfFileName}`;
      this.$router.push(url);
    },
    changeView(v) {
      appStore.commit(APP_MUTATION.set_active_perspectives_tab, v);
    },
    handleScrollHorizontal() {
      const sectionWrapper = document.querySelector(
        `.tab-wrapper-${this.$route.params.id}`
      );

      if (this.list.length <= 1) return;
      if (!sectionWrapper) return;
      let sections = gsap.utils.toArray(
        `.perspectives-card-${this.$route.params.id}`
      );

      let end = "+=8500";

      if (this.list.length < 3) {
        end = "+=" + sectionWrapper.offsetWidth;
      }
      if (this.list.length >= 10) {
        end = "+=10500";
      }

      this.quarterItem = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: sectionWrapper,
          pin: true,
          start: "top 11%",
          scrub: 0.3,
          // markers: true,
          // end: () => "+=" + sectionWrapper.offsetWidth,
          end,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  position: relative;
  @media (min-width: 768px) {
    /* height: 100vh; */
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }

  @media (min-width: 1500px) {
    /* height: 110vh; */
    /* padding-top: 40px; */
    padding-bottom: 40px;
  }
}

.wrap {
  height: 100%;
}

.perspectives-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: unset;
  }
}

.perspectives-card {
  min-width: 100%;
  position: relative;
  margin-bottom: 40px;
  color: #000;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 576px) {
    min-height: 100vh;
  }

  @media (min-width: 1024px) {
    min-height: 80vh;
  }

  @media (min-width: 1500px) {
    min-height: 70vh;
  }

  .perspectives-title {
    font-size: 23px;
    font-weight: 700;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (min-width: 768px) {
      max-width: 80%;
      /* padding-top: 42px; */
      font-size: 32px;
      font-weight: 700;
      line-height: 46px;
      margin-left: 30px;
    }

    @media (min-width: 1024px) {
      margin-left: 60px;
    }
    @media (min-width: 1500px) {
      margin-left: 100px;
    }
  }

  .box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: repeat(12, 1fr);
    }
    .img {
      margin-top: 5%;
      max-height: 60vh;

      width: 100%;
      max-height: 620px;
      margin-left: auto;

      position: relative;
      padding-top: 66.25%;

      img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;

        @media (min-width: 768px) {
          object-position: left bottom;
        }
      }

      @media (min-width: 768px) {
        max-height: 45vh;
        grid-column: 7 / 13;
      }

      @media (min-width: 1200px) {
        max-height: 60vh;
        grid-column: 7 / 13;
      }

      @media (min-width: 1500px) {
        max-height: 45vh;
      }
    }

    .content {
      position: relative;

      z-index: 1;

      width: 100%;
      height: auto;
      max-width: 492px;

      background: rgba(0, 149, 255, 0.1);

      margin-bottom: 20px;

      text-align: center;

      padding: 20px 5px;

      @media (min-width: 768px) {
        position: absolute;
        //
        left: 14%;
        top: 8%;
        max-width: 346px;

        width: 100%;
        height: auto;
        margin-bottom: 0;

        padding: 20px 0 20px 20px;
      }

      @media (min-width: 1200px) {
        max-width: 492px;
        left: 20%;
        top: 5%;
        padding: 35px 25px 20px;
      }

      @media (min-width: 1500px) {
        padding: 35px 0 35px 20px;
        left: 27%;
      }

      .heading {
        max-width: 346px;

        color: #000;
        font-weight: 400;
        font-size: 20px;
        line-height: 135%;
        text-align: center;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        @media (min-width: 768px) {
          max-width: 246px;
        }

        @media (min-width: 1200px) {
          max-width: 300px;
        }
      }

      .btn-read-more {
        font-size: 18px;
        display: inline-flex;
        padding-top: 16px;
        padding-bottom: 16px;
        cursor: pointer;

        @media (min-width: 768px) {
          position: absolute;
          bottom: -52px;
          left: 20px;
          padding-top: 0;
        }
      }
    }
  }

  .desktop-small {
    display: none;
    position: absolute;
    bottom: -8%;
    left: 23%;

    @media (min-width: 1200px) {
      display: flex;
    }

    @media (min-width: 1500px) {
      display: none;
    }
  }

  .desktop-large {
    display: flex;
    margin-top: 20px;

    @media (min-width: 1200px) {
      display: none;
    }

    @media (min-width: 1500px) {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 40px;
    }
  }

  .footer {
    justify-content: center;
    text-align: center;

    .inner {
      .author {
        display: block;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        padding-bottom: 8px;
      }

      .position {
        max-width: 300px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        padding-bottom: 12px;
      }

      .signature {
        width: 80px;
        height: 100px;
        margin: 0 auto;
        margin-bottom: 4px;

        border-radius: 40px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .mine-card-1 {
        justify-content: center;
      }
    }
  }
}

.tabs-control {
  width: 100%;
  min-width: unset;
  z-index: 100;
}
</style>
