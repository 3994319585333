<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- <div class="modal-header text-center">
                        <h4 class="modal-title">Verify User & View File</h4>
                    </div> -->
                    <div class="modal-body" v-loading="loading">
                        <div class="row mb-4">
                            <div class="col text-center">
                                <h4 class="modal-title">Verify email to view file</h4>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col d-flex align-items-end justify-content-center">
                                <div class="form-group mb-0 input-section">
                                    <label class="mb-0"><small class="colorRed">*</small> Please enter your email to view file</label>
                                    <span class="texterror colorRed" v-if="error.username"><small>Please input email</small></span>
                                    <input type="text" class="form-control" v-model="username" v-on:keyup.enter="verify" />
                                </div>
                                <button class="btn btn-primary ml-1 button-section" @click="verify">View</button>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <vue-recaptcha
                                    ref="recaptcha"
                                    @verify="onCaptchaVerified"
                                    @expired="onCaptchaExpired"
                                    size="invisible"
                                    :sitekey="reCaptchaSiteKey"
                                    :loadRecaptchaScript="true">
                                </vue-recaptcha>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.modal-content {
    height: 100vh;
}
.modal-dialog {
    max-width: 100%;
}
.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.input-section {
    display: flex;
    flex-direction: column;
    flex-basis: 250px;
}
.button-section {
    height: 38px;
}
</style>