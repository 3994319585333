import AdminServices from "../../../services/admin";
import _ from "lodash";
import conf from "../../../config/index";
import router from "@/config/router";
import notify from "../../../services/notify";
import appStore, { APP_MUTATION, AUTH_ACTIONS } from "../../../store/app";
import { defineComponent } from "vue";
import logo from "@/components/commons/logo";

export default defineComponent({
  name: "ReviewSurveyDetail",
  props: ["surveyID"],
  components: {
    logo,
  },
  data() {
    return {
      homePath: router.home.path,
      reviewPath: router.reviewSurvey.path,
      reviewName: router.reviewSurvey.name,
      ready: false,
      loading: false,
      page: {
        page: 1,
        size: 1,
        total: 0,
      },
      info: {
        title: "",
        section: null,
        founder: null,
        stt: null,
      },
      fWidth: 0,
      detailSurveyId: null,
      permission: null,
      readyLoadsurvey: false,
    };
  },
  mounted() {
    if (this.profile) {
      if (
        this.profile &&
        this.profile.welcome_survey_stt !== "NOT_STARTED" &&
        this.profile.welcome_survey_stt !== "IN_PROGRESS" &&
        this.profile.welcome_survey_stt !== "COMPLETED" &&
        this.profile.welcome_survey_stt !== "REVIEWING" &&
        this.profile.welcome_survey_stt !== "REJECTED"
      ) {
        if (this.surveyID) {
          this.getSurveyReviewDetails(this.surveyID);
        }
      } else {
        this.getWelcomeSurvey();
      }
    }
  },
  methods: {
    getSurveyReviewDetails(id) {
      this.loading = true;
      AdminServices.getSurveyReviewDetails(id).then((resp) => {
        if (resp.data.ok) {
          this.info.title = resp.data.d.name;
          this.info.section = resp.data.d.sections;
          this.detailSurveyId = resp.data.d.id;
          this.info.founder = resp.data.d.username;
          this.info.stt = resp.data.d.stt;
          this.page.total = this.info.section.length;
          this.permission =
            (this.info.stt === "NOT_STARTED" &&
              this.profile.userType === "Founder") ||
            (this.info.stt === "IN_PROGRESS" &&
              this.profile.userType === "Founder")
              ? "edit"
              : null;
          this.ready = true;
          if (this.info.stt === "APPROVED" || this.info.stt === "REJECTED") {
            this.$router.push({ name: this.reviewName, path: this.reviewPath });
          }
        }
        this.loading = false;
      });
    },
    downloadFile(key) {
      this.loading = true;
      AdminServices.requestDownloadS3(key).then((resp) => {
        if (resp.data.ok) {
          window.open(resp.data.d, "_blank");
        }
        this.loading = false;
      });
    },
    loadPage(value) {
      this.page.page = value;
    },
    approvedSurvey(id) {
      AdminServices.approvedSurvey(id, { status: "APPROVED" }).then((resp) => {
        if (resp.data.ok) {
          notify.success("Survey approved");
          setTimeout(() => {
            this.$router.push({ name: this.reviewName, path: this.reviewPath });
          }, 2000);
        } else {
          notify.error(`Error ${error.resp.error.message}`);
        }
      });
    },
    rejectSurvey(id) {
      let me = this;
      let message = {
        title: "Warning",
        body: "This will permanently reject this item. Continue?",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "OK",
        cancelText: "Cancel",
        animation: "zoom", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };
      this.$dialog
        .confirm(message, options)
        .then(function () {
          // This will be triggered when user clicks on proceed
          AdminServices.approvedSurvey(id, { status: "REJECTED" }).then(
            (resp) => {
              if (resp.data.ok) {
                notify.success("Survey rejected");
                setTimeout(() => {
                  me.$router.push({ name: me.reviewName, path: me.reviewPath });
                }, 2000);
              } else {
                notify.error(`Error ${error.resp.error.message}`);
              }
            }
          );
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    getSurveyDetail(id) {
      AdminServices.getSurveyDetail(id).then((resp) => {
        if (resp.data.ok) {
          this.info.title = resp.data.d.data.name;
          this.info.section = resp.data.d.data.sections;
          this.detailSurveyId = resp.data.d.data.id;
          this.info.founder = resp.data.d.data.username;
          this.page.total = this.info.section.length;
          this.ready = true;
        }
        this.loading = false;
      });
    },
    checkActivebox(id, list) {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          flag = true;
        }
      }
      return flag;
    },
    parseValue(id, list) {
      let str = null;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          str = list[i].Answer;
        }
      }
      return str;
    },
    surveyAnwser(id) {
      AdminServices.surveyAnwser(id, data).then((resp) => {
        if (resp.data.ok) {
          this.getSurveyReviewDetails(this.surveyID);
        }
      });
    },
    check(q, a) {
      let flag = true;
      q.answers.map((p, idx) => {
        if (p.Id === a.Id && p.Answer === a.Answer) {
          q.answers.splice(idx, 1);
          flag = false;
        }
      });
      if (flag) {
        q.answers.push({ Id: a.Id, Answer: a.Answer });
      }
      this.submitAnswer(this.detailSurveyId, [
        { id: q.id, answers: q.answers },
      ]);
    },
    checkInputValue(a, b) {
      let value = null;
      let status = false;
      a.map((p) => {
        if (p.Id === b.Id) {
          value = p.Answer;
          status = true;
        }
      });
      return { status: status, value: value };
    },
    inputChange: function (ref, q, a) {
      let flag = true;
      q.answers.map((p, idx) => {
        if (p.Id === a.Id) {
          if (this.$refs[ref][0].value) {
            p.Answer = this.$refs[ref][0].value;
          } else {
            q.answers.splice(idx, 1);
          }
          flag = false;
        }
      });
      if (flag) {
        q.answers.push({ Id: a.Id, Answer: this.$refs[ref][0].value });
      }
      this.submitAnswer(this.detailSurveyId, [
        { id: q.id, answers: q.answers },
      ]);
    },
    updateFile(ref, q) {
      let inputfile = this.$refs[ref][0];
      q.answers = [];
      q.answers.push({
        Name: inputfile.files.item(0).name,
        S3Key: inputfile.files.item(0).name,
      });
      let formData = new FormData();
      formData.append("ContentFile", inputfile.files.item(0));
      AdminServices.surveyAnwserFile(this.detailSurveyId, q.id, formData);
    },
    textChange: function (ref, q) {
      if (this.$refs[ref][0].value) {
        q.answers[0] = this.$refs[ref][0].value;
      } else {
        q.answers = [];
      }
      this.submitAnswer(this.detailSurveyId, [
        { id: q.id, answers: q.answers },
      ]);
    },
    submitAnswer(id, data) {
      AdminServices.surveyAnwser(id, data).then((resp) => {
        if (resp.data.ok) {
          // console.log('done')
        }
      });
    },
    getWelcomeSurvey() {
      this.loading = true;
      this.readyLoadsurvey = true;
      AdminServices.getSurveyWelcome().then((resp) => {
        if (resp.data.ok) {
          this.info.title = resp.data.d.name;
          this.info.section = resp.data.d.sections;
          this.info.founder = resp.data.d.username;
          this.info.stt = resp.data.d.stt;
          this.page.total = this.info.section.length;
          this.detailSurveyId = resp.data.d.id;
          this.permission =
            (this.info.stt === "NOT_STARTED" &&
              this.profile.userType === "Founder") ||
            (this.info.stt === "IN_PROGRESS" &&
              this.profile.userType === "Founder")
              ? "edit"
              : null;
          this.ready = true;
        }
        this.loading = false;
      });
    },
    finishSurvey: function (id) {
      AdminServices.finishSurvey(id).then((resp) => {
        if (resp.data.ok) {
          notify.success("Survey finished");
          if (
            this.profile.userType == "Founder" &&
            this.profile.welcome_survey_stt === "NOT_STARTED"
          ) {
            setTimeout(() => {
              this.getWelcomeSurvey();
            }, 2000);
          }
        }
      });
    },
    goback() {
      this.$router.go(-1);
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
  },
  computed: {
    sumScored: {
      default: 0,
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let score = 0;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let child = list[i].questions;
            for (let j = 0; j < child.length; j++) {
              score += child[j].score;
            }
          }
        }
        return score;
      },
    },
    countQuestionInfo: {
      default: {
        ans: 0,
        total: 0,
      },
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let obj = {
          ans: 0,
          total: 0,
        };
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let q = list[i].questions;
            obj.total += q.length;
            for (let j = 0; j < q.length; j++) {
              if (q[j].answers.length) {
                obj.ans += 1;
              }
            }
          }
        }
        return obj;
      },
    },
    profile() {
      return appStore.state.profile;
    },
    mobile() {
      return appStore.state.mobile;
    },
  },
  watch: {
    ready: function (v) {
      if (v) {
        setTimeout(() => {
          this.onResize();
        }, 1000);
      }
    },
    profile: {
      handler: function (v) {
        // if (v.type === 'Founder' && v.welcome_survey_stt === 'NOT_STARTED' || v.type === 'Founder' && v.welcome_survey_stt === 'IN_PROGRESS' || v.type === 'Founder' && v.welcome_survey_stt === 'COMPLETED' || v.type === 'Founder' && v.welcome_survey_stt === 'REVIEWING' || v.type === 'Founder' && v.welcome_survey_stt === 'REJECTED') {
        //     this.getWelcomeSurvey()
        // }
        if (
          v &&
          v.welcome_survey_stt !== "NOT_STARTED" &&
          v.welcome_survey_stt !== "IN_PROGRESS" &&
          v.welcome_survey_stt !== "COMPLETED" &&
          v.welcome_survey_stt !== "REVIEWING" &&
          v.welcome_survey_stt !== "REJECTED"
        ) {
          if (this.surveyID) {
            this.getSurveyReviewDetails(this.surveyID);
          }
        } else {
          if (!this.readyLoadsurvey) {
            this.getWelcomeSurvey();
          }
        }
      },
      deep: true,
    },
    mobile: {
      deep: true,
      handler(v) {
        this.fWidth = $(this.$refs.popupContent).width() || 0;
      },
    },
  },
});
