import { defineComponent } from "vue";
import _ from "lodash";

import adminServices from "@/services/admin";
import Modal from "@/components/Modal";
import TextArea from "@/components/TextArea";
import TextField from "@/components/TextField";
import NewButton from "@/components/NewButton";
import Datepicker from "@/components/DatePicker";
import FileField2 from "@/components/FileField2";
import Switcher from "@/components/Switcher";
import notify from "@/services/notify";
import moment from "moment";
import momentTz from "moment-timezone";
import publicFileDataroom from "@/views/admin/dataroom/publicFileDataroom";
import config from "@/config";
import { swapTimetampToObj } from "@/misc/commons";
import SelectField from "@/components/SelectField";

export default defineComponent({
  inject: ["eventInfoData"],
  components: {
    Modal,
    TextArea,
    TextField,
    NewButton,
    Datepicker,
    FileField2,
    notify,
    Switcher,
    publicFileDataroom,
    SelectField,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: "CREATE",
      checkInDate: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      form: {
        name: "",
        description: "",
        checkInDate: "",
        startDate: "",
        endDate: "",
      },
      errorMsg: {
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        pdf: "",
      },
      disabledEndDate: true,
      eventId: "",
      milestoneId: "",
      formatDate: "MM/DD/YYYY",
      isShowPublicDataroom: false,
      dataRoomItemName: null,
      parentId: 495, //Folder Event
      checkInList: [],
      checkIn: "1",
      startEvent: null,
      endEvent: null,
      milestones: null,
    };
  },
  watch: {
    "form.name": function (v) {
      this.errorMsg.name = "";
    },
    "form.description": function (v) {
      this.errorMsg.description = "";
    },

    startDate(v) {
      // this.startDate = { ...v, ...this.startTime };
      // this.convertTimeCheckIn(v);
    },
    // endDate(v) {
    //   this.endDate = { ...v, ...this.endTime };
    // },
    startTime(v) {
      this.startDate = { ...this.startDate, ...v };
    },
    endTime(v) {
      this.endDate = { ...this.endDate, ...v };
    },
    checkIn() {
      if (this.startDate) {
        this.convertTimeCheckIn(this.startDate);
      }
    },
    dataRoomItemName(v) {
      if (v) this.errorMsg.pdf = false;
    },
  },
  mounted() {
    let lstTemp = [];
    for (let i = 1; i <= 10; i++) {
      lstTemp.push({ name: `${i} hours`, code: i });
      if (i === 10) this.checkInList = lstTemp;
    }
  },
  methods: {
    create(start, end, type, milestones) {
      this.isShow = true;
      this.state = "CREATE";
      this.eventId = this.eventInfoData.eventId;
      this.startEvent = this.convertTZ(start);
      this.endEvent = this.convertTZ(end);
      this.form.type = type;
      this.milestones = milestones;
      if (type == "ONE") {
        this.startDate = swapTimetampToObj(
          this.convertTZ(new Date(this.eventInfoData.startDateTime))
        );
        this.endDate = swapTimetampToObj(
          this.convertTZ(new Date(this.eventInfoData.endDateTime))
        );

        this.startTime = this.convertTimeTZ(this.eventInfoData.startDateTime);
        this.endTime = this.convertTimeTZ(this.eventInfoData.endDateTime);
      }
    },
    show(props, start, end, type, milestones) {
      this.isShow = true;
      this.state = "VIEW";
      this.form.name = props.name;
      this.form.description = props.description;
      this.checkInDate = swapTimetampToObj(
        this.convertTZ(new Date(props.checkInDate))
      );
      this.checkIn = Number(
        moment(this.convertTZ(new Date(props.startDate)))
          .subtract(
            swapTimetampToObj(this.convertTZ(new Date(props.checkInDate)))
          )
          .format("HH")
      );
      this.startDate = swapTimetampToObj(
        this.convertTZ(new Date(props.startDate))
      );
      this.endDate = swapTimetampToObj(this.convertTZ(new Date(props.endDate)));

      this.startTime = this.convertTimeTZ(props.startDate);
      this.endTime = this.convertTimeTZ(props.endDate);

      this.eventId = props.eventId;
      this.milestoneId = props.milestoneId;

      this.startEvent = this.convertTZ(start);
      this.endEvent = this.convertTZ(end);

      this.dataRoomItemName = props.dataRoomItemName;
      this.form.dataRoomItemId = props.dataRoomItemId;
      this.isShowPublicDataroom = false;
      this.selectedMediaType = null;
      this.form.type = type;
      this.milestones = milestones;
    },
    parseTime(dateTime) {
      const utcDate = momentTz
        .tz(dateTime, config.time_zone_default)
        .utc()
        .format();
      return utcDate;
    },
    convertTimeCheckIn(startDate) {
      if (!startDate) return;
      const time = moment.duration(`${this.checkIn}:00`);
      const date = moment(
        `${startDate.years}-${startDate.months}-${startDate.days} ${this.startTime.hours}:${this.startTime.minutes}`
      );
      this.checkInDate = date.subtract(time).format("YYYY-MM-DD HH:mm");
    },
    async save() {
      if (!this.validData()) return;
      this.convertTimeCheckIn(this.startDate);
      this.form.checkInDate = this.parseTime(this.checkInDate);
      this.form.startDate = this.parseTime(
        moment(
          `${this.startDate.years}-${this.startDate.months}-${this.startDate.days} ${this.startTime.hours}:${this.startTime.minutes}`
        ).format("YYYY-MM-DD HH:mm")
      );
      this.form.endDate = this.parseTime(
        moment(
          `${this.endDate.years}-${this.endDate.months}-${this.endDate.days} ${this.endTime.hours}:${this.endTime.minutes}`
        ).format("YYYY-MM-DD HH:mm")
      );

      this.loading = true;
      if (this.state == "CREATE") {
        await this.createItem(this.form);
      } else {
        await this.updateItem(this.form);
      }
      this.loading = false;
    },
    async createItem(formData) {
      adminServices
        .createMilestone(this.eventId, formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Add successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateItem(formData) {
      adminServices
        .updateMilestone(this.eventId, this.milestoneId, formData)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Update successfully!");
            this.$emit("refresh");
            this.isShow = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validData() {
      let flag = true;
      if (this.form.name.trim() === "") {
        this.errorMsg.name = "Please input Name";
        flag = false;
      }
      if (this.form.description.trim() === "") {
        this.errorMsg.description = "Please input Description";
        flag = false;
      }
      if (!this.handleValidateDate()) flag = false;

      if (!this.dataRoomItemName || this.dataRoomItemName?.length < 1) {
        this.errorMsg.pdf = true;
        flag = false;
      }
      return flag;
    },
    convertTimeDate(time) {
      if (!time) return null;
      return moment
        .tz(moment.utc(time), config.time_zone_default)
        .format("YYYY/MM/DD HH:mm:ss ");
    },
    handleValidateDate() {
      this.errorMsg.startDate = "";
      this.errorMsg.endDate = "";

      if (!this.startDate || !this.startTime) {
        this.errorMsg.startDate = "Please input start time";
        return false;
      }
      if (!this.endDate || !this.endTime) {
        this.errorMsg.endDate = "Please input end time";
        return false;
      }
      const start = `${this.startDate.years}-${this.startDate.months}-${this.startDate.days} ${this.startTime.hours}:${this.startTime.minutes}`;

      const end = `${this.endDate.years}-${this.endDate.months}-${this.endDate.days} ${this.endTime.hours}:${this.endTime.minutes}`;

      if (start >= end) {
        this.errorMsg.endDate = "End time must be greater than start time";
        return false;
      }
      // moment(start).diff(this.convertTZ(moment())
      const currentTime = this.convertTZ(moment());

      if (moment(start).diff(moment(currentTime)) < 1) {
        this.errorMsg.startDate =
          "Start time must be greater than current time";
        return false;
      }

      const endDateTime = this.convertTimeDate(this.eventInfoData.endDateTime);
      // start > endDateTime
      if (moment(endDateTime).diff(moment(start)) < 1) {
        this.errorMsg.startDate = "Start time must be less than end event";
        return false;
      }
      // end > endDateTime
      if (moment(endDateTime).diff(moment(end)) < 0) {
        this.errorMsg.endDate = "End time must be less than end event time";
        return false;
      }
      const startDateTime = this.convertTimeDate(
        this.eventInfoData.startDateTime
      );
      // start < startDateTime
      if (moment(start).diff(moment(startDateTime)) < 0) {
        this.errorMsg.startDate =
          "Start time must be greater than start event time";
        return false;
      }
      // end < startDateTime
      if (moment(end).diff(moment(startDateTime)) < 1) {
        this.errorMsg.endDate =
          "End time must be greater than start event time";
        return false;
      }

      // check milestone
      const error = "End time must be not in other session time range";
      if (this.milestoneId) {
        const index = this.milestones.findIndex(
          (el) => el.milestoneId === this.milestoneId
        );
        const elFirst = this.milestones?.[index - 1];
        const elEnd = this.milestones?.[index + 1];
        if (elFirst) {
          const fst = this.convertTimeDate(elFirst.startDate);
          // start > this.parseTime(elFirst.startDate)
          if (moment(fst).diff(moment(start)) < 1) {
            this.errorMsg.startDate = error;
            return false;
          }
          // end > this.parseTime(elFirst.startDate)
          if (moment(fst).diff(moment(end)) < 1) {
            this.errorMsg.endDate = error;
            return false;
          }
        }
        // elEnd && start < this.parseTime(elEnd.endDate)
        if (
          elEnd &&
          moment(start).diff(moment(this.convertTimeDate(elEnd.endDate))) < 1
        ) {
          this.errorMsg.startDate = error;
          return false;
        }
      } else {
        if (this.milestones.length > 0) {
          // start < this.parseTime(this.milestones[0].endDate)
          if (
            moment(start).diff(
              moment(this.convertTimeDate(this.milestones[0].endDate))
            ) < 1
          ) {
            this.errorMsg.startDate = error;
            return false;
          }
        }
      }
      return true;
    },
    selectDataroomItem(type) {
      this.selectedMediaType = type;
      this.$nextTick(() => {
        this.isShowPublicDataroom = true;
      });
    },
    closeSearchDataroomItem() {
      this.isShowPublicDataroom = false;
      this.selectedMediaType = null;
    },

    completeSelectDataroomItem(data) {
      const { dataroomId, type, name } = data;
      this.closeSearchDataroomItem();
      switch (type) {
        case "PDF":
          this.form.dataRoomItemId = dataroomId;
          this.dataRoomItemName = name;
          break;
      }
    },
    getTimezoneOffset(atTime, timeZone) {
      const localizedTime = new Date(
        atTime.toLocaleString("en-US", { timeZone })
      );
      const utcTime = new Date(
        atTime.toLocaleString("en-US", { timeZone: "UTC" })
      );
      return (localizedTime.getTime() - utcTime.getTime()) / (60 * 60 * 1000);
    },
    convertTZ(dateTime) {
      if (dateTime == null) return;
      dateTime = new Date(dateTime);
      const timezoneHour = this.getTimezoneOffset(
        new Date(),
        config.time_zone_default
      );
      const dateConvert = new Date(
        dateTime.getTime() +
          dateTime.getTimezoneOffset() * 60 * 1000 +
          timezoneHour * 60 * 60 * 1000
      );
      return dateConvert;
    },

    convertTimeTZ(dateTime) {
      const formatDateTime = "MM/DD/YYYY hh:mm A";
      const timezoneMX = momentTz(dateTime)
        .tz(config.time_zone_default)
        .format(formatDateTime);
      return {
        hours:
          moment(timezoneMX).hours() === 0 ? 24 : moment(timezoneMX).hours(),
        minutes: moment(timezoneMX).minutes(),
      };
    },
  },
});
