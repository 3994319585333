<template>
    <div class="wrapper page page2">
      <header class="site-header">
          <Header :showNavbar="showNavbar" />
      </header>
      <div class="site-content">
        <div class="container container-fluid">
            <div class="block-blackstone-page">
                <div class="block-blackstone-page-header__image-container">
                    <img :src="imgHeader" :srcset="`${imgHeader} 2226w, ${imgHeader}?resize=300,169 300w, ${imgHeader}?resize=768,433 768w, ${imgHeader}?resize=1024,577 1024w, ${imgHeader}?resize=1536,866 1536w, ${imgHeader}?resize=2048,1155 2048w, ${imgHeader}?resize=776,438 776w, ${imgHeader}?resize=800,451 800w, ${imgHeader}?resize=1000,564 1000w`" sizes="100vw" alt="" class="blackstone-page-header__image" :style="{'object-position': '50% 50%'}">
                </div>
                <div class="block-blackstone-page-header__inner-container container">
                    <div class="row">
                        <div class="col text-white">
                            <h1 class="block-blackstone-page-header__title">Build wealth with Blackstone</h1>
                            <p class="block-blackstone-page-header__text">Blackstone Private Wealth Solutions has been partnering with financial advisors for over a decade, harnessing the power of private market investments for their clients.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="block-group__inner-container">
                <div class="block-column">
                    <div class="block"><h2>Delivering for advisors and their clients</h2></div>
                    <div class="block">&nbsp;</div>
                </div>
                <div class="block-column">
                    <div class="block">
                        <p>Private Wealth Solutions offers financial advisors the same discipline and foresight, that Blackstone has brought to institutional investors for nearly 40 years.</p>
                        <p>We equip advisors with differentiated solutions designed by leading investment teams, so they can help individual investors achieve their goals.</p>
                        <p><button class="btn btn-watch text-uppercase">Watch now</button></p>
                    </div>
                    <div class="block">&nbsp;</div>
                </div>
            </div>
            <div class="block-group__inner-container container">
                <div class="row">
                    <div class="col col-12">
                        <h2>The power of Blackstone</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="cover">
                            <img :src="imgScale" alt="">
                        </div>
                        <h3>Scale</h3>
                        <p>Blackstone is the world’s largest alternative asset manager, with $975B in AUM.</p>
                        <p class="footnote">*As of 12/31/2022</p>
                    </div>
                    <div class="col-4">
                        <div class="cover">
                            <img :src="imgInsight" alt="">
                        </div>
                        <h3>Insight</h3>
                        <p>Blackstone leverages its global scale to spot trends early and act on opportunities across asset classes — from real estate to credit to private equity.</p>
                    </div>
                    <div class="col-4">
                        <div class="cover">
                            <img :src="imgPartnership" alt="">
                        </div>
                        <h3>Partnership</h3>
                        <p>Our team of 250+ dedicated professionals partners with financial advisors, providing them with the private market expertise they need to serve their clients.</p>
                    </div>
                </div>
            </div>
            <div class="block-real-estate">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            djskagdhsjak
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-quote">
                <div class="row">
                    <div class="col">
                        <div class="block-quote-cover">
                            <img :src="imgJoanSolotar" alt="">
                        </div>
                        <div class="block-quote-cover">
                            <p>We want to be an authoritative resource for information and insight, making Blackstone and alternatives accessible to a wider audience of investors.</p>
                            <p class="name">Joan Solotar</p>
                            <p class="jd">Global Head of Private Wealth Solutions</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-insights-education">
                <div class="container">
                    <div class="row">
                        <div class="col-4">
                            <div class="block-insights-education-cover"></div>
                            <div class="block-insights-education-text">
                                <p class="type">Market Views</p>
                                <p class="title">Update from Jon Gray: Where You Invest Matters</p>
                                <p class="time">February 22, 2023</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="block-insights-education-cover"></div>
                            <div class="block-insights-education-text">
                                <p class="type">Firm News</p>
                                <p class="title">Byron Wien’s 20 Life Lessons</p>
                                <p class="time">February 14, 2023</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="block-insights-education-cover"></div>
                            <div class="block-insights-education-text">
                                <p class="type">Firm News</p>
                                <p class="title">Finding the Right Exposure with Private Markets</p>
                                <p class="time">February 2, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-footer-note">
                <p>Build Wealth with Blackstone Disclosure: Investing involves risks, including loss of capital.</p>
                <p>Build Wealth with Blackstone Video Disclaimer: Neither this video nor any of the information contained herein constitutes an offer to sell, or a solicitation of an offer to buy, any security or instrument in or to participate in any trading strategy with any Blackstone fund or other investment vehicle. Investments presented herein are not representative of all investments of a given type or of investments generally. <strong><b>Past performance is not indicative of future results</b></strong> and there is no assurance that any Blackstone fund will achieve its objectives or avoid significant losses. Statements contained in this video that are not facts, including statements regarding trends, market conditions and the expertise or experience of Blackstone, are based on current expectations, estimates, projections, opinions, and/or beliefs of Blackstone. Such statements are not facts and involve known and unknown risks, uncertainties, and other factors. This video may contain forward-looking statements; such statements are subject to various risks and uncertainties. Due to various risks and uncertainties, actual events or results or the actual performance of any investments made in this industry may differ materially from those reflected or contemplated in any forward-looking statements made in the video. For information about Blackstone’s business, including risks and financial information, please refer to our most recent Annual Report on Form 10-K filed with the Securities and Exchange Commission. For additional information, see Blackstone’s public filings at <a href="https://fuelventurecapital.com/" target="_blank" rel="noreferrer noopener">https://fuelventurecapital.com/</a></p>
            </div>
        </div>
      </div>
      <div class="site-footer">
          <Footer />
      </div>
      <button class="scroll-to-top-btn" @click="scrollToTop">
        <svg
          data-name="1-Arrow Up"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path
            d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z"
          />
        </svg>
      </button>
    </div>
  </template>
  
  <script>
  import '../style.scss'
  import {defineComponent} from 'vue'
  import imgHeader from '@/assets/static/page2/PWS-Bridge_Smaller-Size-Copy-edited.jpg'
  import imgVideo from '@/assets/static/page2/video.jpg'
  import imgScale from '@/assets/static/page2/PWS-Scale.png'
  import imgInsight from '@/assets/static/page2/PWS-Insight.png'
  import imgPartnership from '@/assets/static/page2/PWS-Partnership.png'
  import imgJoanSolotar from '@/assets/static/page2/Joan-Solotar.png'
  
  import Header from "@/layouts/header/Header";
  import Footer from  "@/layouts/footer/Footer";
  export default defineComponent({
      components: {
          Header,
          Footer
      },
      data() {
          return {
              imgHeader,
              imgVideo,
              imgScale,
              imgInsight,
              imgPartnership,
              imgJoanSolotar,
              showNavbar: true,
              lastScrollPosition: 0,
              displayMenu: false,
          }
      },
      watch: {
          "$route.path": {
              handler: function(path) {
                  if (path === "/") {
                  // sticky header
                  window.addEventListener("scroll", this.onScroll);
                  } else {
                  window.removeEventListener("scroll", this.onScroll);
                  }
              },
              immediate: true,
          },
      },
      methods: {
          handleScrollBackToTop() {
              let scrollToTopBtn = document.querySelector(".scroll-to-top-btn");
              let rootElement = document.documentElement;
              // Do something on scroll - 0.15 is the percentage the page has to scroll before the button appears
              // This can be changed - experiment
              let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
              if (rootElement.scrollTop / scrollTotal > 0.15) {
                  // Show button
                  scrollToTopBtn.classList.add("show-btn");
              } else {
                  // Hide button
                  scrollToTopBtn.classList.remove("show-btn");
              }
          },
          scrollToTop() {
              document.documentElement.scrollTo({
                  top: 0,
                  behavior: "smooth",
              });
          },
          onScroll() {
              const currentScrollPosition =
                  window.pageYOffset || document.documentElement.scrollTop;
              if (currentScrollPosition < 0) {
                  return;
              }
              if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                  return;
              }
              this.showNavbar = currentScrollPosition < this.lastScrollPosition;
              this.lastScrollPosition = currentScrollPosition;
          },
      }
  })
  </script>