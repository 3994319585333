import authHttp, { API_ROOT } from './auth-http'
import jsonp from 'browser-jsonp'

export const OtherService = {
  subscribeEmail(data) {
    return authHttp.put(`${API_ROOT}/email/subscribe`, data)
  },
  getInstagramProfile(accessToken, callback) {
    jsonp({
      url: `https://graph.instagram.com/me?fields=id,username,media_count,account_type&access_token=${accessToken}`,
      error: error => { throw error },
      complete: response => {
        callback(response)
      }
    })
  },
  getInstagramMedias(accessToken, limit, callback) {
    jsonp({
      url: `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,username,timestamp&limit=${limit}&access_token=${accessToken}`,
      error: error => { throw error },
      complete: response => {
          callback(response)
      }
    })
  },
  getInstagramMediaByPagingUrl(url, callback) {
    jsonp({
      url: url,
      error: error => { throw error },
      complete: response => {
        callback(response)
      }
    })
  },
  getWelcomeData() {
    return authHttp.get(`${API_ROOT}/public/welcome`)
  }
}

export default OtherService