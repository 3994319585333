<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hidedetail"></div>
            <div class="modal-dialog">                
                <div class="modal-content">
                    <button type="button" class="close pointer" aria-label="Close" @click="hidedetail">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g class="nc-icon-wrapper" stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#ffffff" stroke="#ffffff"><g class="nc-interact_menu-close-2-o-32"> <path fill="none" stroke="#ffffff" stroke-miterlimit="10" d="M2 6h28" transform="translate(0 10.00) rotate(45.00 16 6)"/> <path data-color="color-2" fill="none" stroke-miterlimit="10" d="M2 16h28" opacity="0"/> <path fill="none" stroke="#ffffff" stroke-miterlimit="10" d="M2 26h28" transform="translate(0 -10) rotate(-45 16 26)"/> </g> </g></svg>
                    </button>
                    <div class="modal-header d-flex flex-column align-items-center text-center">
                        <h4 class="modal-title">{{dataChart.name}}</h4>
                        <div class="btn-group btn-group-toggle view-toggle" v-if="dataChart.tbl_data">
                            <label class="btn btn-primary btn-small" :class="viewType === 'chart' ? 'active' : ''">
                                <input type="radio" name="viewTypes" v-model="viewType" value="chart"> Chart view
                            </label>
                            <label class="btn btn-primary btn-small" :class="viewType === 'table' ? 'active' : ''">
                                <input type="radio" name="viewTypes" v-model="viewType" value="table"> Table View
                            </label>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid p-0">
                            <chartView :chartData="dataChart" :detailView="true" :tblData="dataChart.tbl_data" :viewType="viewType" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.custom-modal {
    .modal-dialog {
        max-width: 1024px;
        button.close {
            position: absolute;
            top: 7px;
            right: 7px;
            display: block;
            overflow: hidden;
            width: 30px;
            height: 30px;
            color: #000;
            border: 1px solid #ccc;
            border-radius: 50%;
            padding: 0px;
            background-color: #ccc;
            opacity: 1;
        }

        .modal-header {
            padding: 10px 0 0 0;

            .view-toggle {
                .btn.active {
                    color: #FFFFFF !important;
                }
            }
        }
    }
}
</style>