<template>
  <div class="portfolio-container">
    <div class="portfolio-main container-v2">
      <div class="portfolio-inner">
        <div class="text-a-wrapper p-text-1">
          <h2 class="text-animation">Portfolio</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import anime from "animejs";
import ScrollOut from "scroll-out";

export default defineComponent({
  data() {
    return {
      easing: "cubicBezier(.45,0,.3,1)",
      haveIt1: [],
      translateY: 140,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.translateY = 48;
    }
    this.handleScrollTextReveal();
  },
  methods: {
    handleScrollTextReveal() {
      this.handleSliceText();
      ScrollOut({
        targets: ".p-text-1",
        onShown: (el) => {
          el.style.opacity = 1;
          this.animationText1();
        },
        onHidden: function (el) {
          el.style.opacity = 0;
        },
      });
    },

    handleSliceText() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".p-text-1 .text-animation");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<p class='letter'>$&</p>"
      );
    },
    animationBackground() {
      anime({
        targets: ".portfolio-inner",
        duration: 1000,
        easing: this.easing,
        opacity: [0, 1],
        translateY: [30, 0],
      });
    },
    animationText1() {
      // Wrap every letter in a span
      var textWrapper = document.querySelector(".p-text-1 .text-animation");
      const textLength =
        textWrapper.textContent.replaceAll(" ", "").length || 1;

      anime({
        targets: ".p-text-1 .letter",
        delay: (el, i) => {
          this.haveIt1 = [];
          const random = this.generateUniqueRandom(textLength, "haveIt1") || 1;
          return 50 + 50 * random;
        },
        duration: 1200,
        easing: this.easing,
        translateY: [this.translateY, 0],
      });
    },

    generateUniqueRandom(maxNr, nameArr) {
      let random = (Math.random() * maxNr).toFixed();
      random = Number(random);

      if (!this[nameArr].includes(random)) {
        this[nameArr].push(random);
        return random;
      } else {
        if (this[nameArr].length < maxNr) {
          return this.generateUniqueRandom(maxNr, nameArr);
        } else {
          return false;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.portfolio-container {
  color: #000;

  background: url("../../../../assets/images/v2/portfolio-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.portfolio-main {
  width: 100%;
  min-height: calc(100vh - 74px);

  display: flex;
  align-items: center;
  justify-content: center;

  .portfolio-inner {
    display: flex;
    align-items: center;
    height: 80%;
  }

  .text-animation {
    font-size: 48px;
    font-weight: 900;
    color: #fff;

    padding-bottom: 8px;

    @media (min-width: 768px) {
      font-size: 130px;
      line-height: 100%;
      letter-spacing: 5px;
    }
  }

  .o-content {
    font-family: "SF Pro Text";
    text-align: justify;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .txt-blue {
      color: #0095ff;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}
</style>
