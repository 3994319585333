import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      bannerInfo: {
        image: require("../../assets/images/banner/fuel-station-v2.jpg"),
        name: "Terms and Conditions",
        shortDescription: "",
      },
      textContent: `<h3 class="mb-3">Terms and Conditions</h3>
          <h4>1. Introduction</h4>
          <p>
            These Website Standard Terms And Conditions (these
            &ldquo;Terms&rdquo; or these &ldquo;Website Standard Terms And
            Conditions&rdquo;) contained herein on this webpage, shall govern
            your use of this website, including all pages within this website
            (collectively referred to herein below as this
            &ldquo;Website&rdquo;). These Terms apply in full force and effect
            to your use of this Website and by using this Website, you expressly
            accept all terms and conditions contained herein in full. You must
            not use this Website, if you have any objection to any of these
            Website Standard Terms And Conditions.
          </p>
          <p>
            This Website is not for use by any minors (defined as those who are
            not at least 18 years of age), and you must not use this Website if
            you are a minor.
          </p>
          <h4>2. Intellectual Property Rights</h4>
          <p>
            Other than content you own, which you may have opted to include on
            this Website, under these Terms, Fuel Venture Capital Partners LLC
            and/or its licensors own all rights to the intellectual property and
            material contained in this Website, and all such rights are
            reserved. You are granted a limited license only, subject to the
            restrictions provided in these Terms, for purposes of viewing the
            material contained on this Website.
          </p>
          <h4>3. Restrictions</h4>
          <p>
            You are expressly and emphatically restricted from all of the
            following:
          </p>
          <p>&nbsp; &nbsp; + Publishing any Website material in any media;</p>
          <p>
            &nbsp; &nbsp; + Selling, sublicensing and/or otherwise
            commercializing any Website material;
          </p>
          <p>
            &nbsp; &nbsp; + Publicly performing and/or showing any Website
            material;
          </p>
          <p>
            &nbsp; &nbsp; + Using this Website in any way that is, or may be,
            damaging to this Website;
          </p>
          <p>
            &nbsp; &nbsp; + Using this Website in any way that impacts user
            access to this Website;
          </p>
          <p>
            &nbsp; &nbsp; + Using this Website contrary to applicable laws and
            regulations, or in a way that causes, or may cause, harm to the
            Website, or to any person or business entity;
          </p>
          <p>
            &nbsp; &nbsp; + Engaging in any data mining, data harvesting, data
            extracting or any other similar activity in relation to this
            Website, or while using this Website;
          </p>
          <p>
            &nbsp; &nbsp; + Using this Website to engage in any advertising or
            marketing;
          </p>
          <p>
            Certain areas of this Website are restricted from access by you and
            &nbsp;Fuel Venture Capital Partners LLC may further restrict access
            by you to any areas of this Website, at any time, in its sole and
            absolute discretion. Any user ID and password you may have for this
            Website are confidential and you must maintain confidentiality of
            such information.
          </p>
          <h4>4. Your Content</h4>
          <p>
            In these Website Standard Terms And Conditions, &ldquo;Your
            Content&rdquo; shall mean any audio, video, text, images or other
            material you choose to display on this Website. With respect to Your
            Content, by displaying it, you grant &nbsp;Fuel Venture Capital
            Partners LLC a non-exclusive, worldwide, irrevocable, royalty-free,
            sublicensable license to use, reproduce, adapt, publish, translate
            and distribute it in any and all media.
          </p>
          <p>
            Your Content must be your own and must not be infringing on any
            third party&rsquo;s rights. Fuel Venture Capital Partners LLC
            reserves the right to remove any of Your Content from this Website
            at any time, and for any reason, without notice.
          </p>
          <h4>5. No warranties</h4>
          <p>
            This Website is provided &ldquo;as is,&rdquo; with all faults, and
            &nbsp;Fuel Venture Capital Partners LLC makes no express or implied
            representations or warranties, of any kind related to this Website
            or the materials contained on this Website. Additionally, nothing
            contained on this Website shall be construed as providing consult or
            advice to you.
          </p>
          <h4>6. Limitation of liability</h4>
          <p>
            In no event shall &nbsp;Fuel Venture Capital Partners LLC, nor any
            of its officers, directors and employees, be liable to you for
            anything arising out of or in any way connected with your use of
            this Website, whether such liability is under contract, tort or
            otherwise, and Fuel Venture Capital Partners LLC, including its
            officers, directors and employees shall not be liable for any
            indirect, consequential or special liability arising out of or in
            any way related to your use of this Website.
          </p>
          <h4>7. Indemnification</h4>
          <p>
            You hereby indemnify to the fullest extent &nbsp;Fuel Venture
            Capital Partners LLC from and against any and all liabilities,
            costs, demands, causes of action, damages and expenses (including
            reasonable attorney&rsquo;s fees) arising out of or in any way
            related to your breach of any of the provisions of these Terms.
          </p>
          <p>8. Severability</p>
          <p>
            If any provision of these Terms is found to be unenforceable or
            invalid under any applicable law, such unenforceability or
            invalidity shall not render these Terms unenforceable or invalid as
            a whole, and such provisions shall be deleted without affecting the
            remaining provisions herein.
          </p>
          <h4>9. Variation of Terms</h4>
          <p>
            Fuel Venture Capital Partners LLC is permitted to revise these Terms
            at any time as it sees fit, and by using this Website you are
            expected to review such Terms on a regular basis to ensure you
            understand all terms and conditions governing use of this Website.
          </p>
          <h4>10. Assignment</h4>
          <p>
            Fuel Venture Capital Partners LLC shall be permitted to assign,
            transfer, and subcontract its rights and/or obligations under these
            Terms without any notification or consent required. However, you
            shall not be permitted to assign, transfer, or subcontract any of
            your rights and/or obligations under these Terms.
          </p>
          <h4>11. Entire Agreement</h4>
          <p>
            These Terms, including any legal notices and disclaimers contained
            on this Website, constitute the entire agreement between &nbsp;Fuel
            Venture Capital Partners LLC and you in relation to your use of this
            Website, and supersede all prior agreements and understandings with
            respect to the same.
          </p>
          <h4>12. Governing Law &amp; Jurisdiction</h4>
          <p>
            These Terms will be governed by and construed in accordance with the
            laws of the State of Florida, and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in Florida for
            the resolution of any disputes.
          </p>`,
    };
  },
});
