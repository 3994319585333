import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import NewButton from "@/components/NewButton";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import moment from "moment";
import momentTz from "moment-timezone";
import Datepicker from "@/components/DatePicker";

export default defineComponent({
  components: {
    Modal,
    NewButton,
    Datepicker,
  },
  data() {
    return {
      isShow: false,
      state: "CREATE",
      loading: false,
      scheduleDate: null,
      scheduleTime: null,
      scheduleSendDatetime: null,
      errorMsg: {
        scheduleDate: false,
      },
      timezone: "America/New_York",
      emails: [],
      eventId: null,
      currentDateTime: null,
    };
  },
  mounted() {
    this.currentDateTime = this.convertTZ(new Date());
  },
  methods: {
    create(eventId, emails) {
      this.isShow = true;
      this.state = "CREATE";
      this.eventId = eventId;
      this.emails = emails;
    },
    async sendSchedule() {
      if (!this.validData()) return;
      this.loading = true;
      const res = await adminServices.sendEventSchedule(this.eventId, {
        emails: this.emails,
        scheduleSendDatetime: this.scheduleSendDatetime,
      });
      if (res && res.data && res.data.ok) {
        this.emails = [];
        notify.success("Send schedule successfully");
        this.$emit("completeEventStatus");
        this.isShow = false;
      } else {
        notify.error(res.data.errorMsg);
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if (
        !this.scheduleDate ||
        !this.scheduleTime ||
        $.trim(this.scheduleSendDatetime) === ""
      ) {
        this.errorMsg.scheduleDate = "Please input schedule date";
        flag = false;
      } else {
        const datetime = { ...this.scheduleDate, ...this.scheduleTime };
        const start = `${datetime.years}-${datetime.months}-${datetime.days} ${datetime.hours}:${datetime.minutes}`;

        if (moment(start).diff(moment(this.convertTZ(moment()))) < 1) {
          this.errorMsg.scheduleDate =
            "The schedule time is greater than the current time";
          flag = false;
        }
      }
      return flag;
    },

    getTimezoneOffset(atTime, timeZone) {
      const localizedTime = new Date(
        atTime.toLocaleString("en-US", { timeZone })
      );
      const utcTime = new Date(
        atTime.toLocaleString("en-US", { timeZone: "UTC" })
      );
      return (localizedTime.getTime() - utcTime.getTime()) / (60 * 60 * 1000);
    },

    convertTZ(dateTime) {
      if (dateTime == null) return;
      dateTime = new Date(dateTime);
      var timezoneHour = this.getTimezoneOffset(new Date(), this.timezone);
      var dateConvert = new Date(
        dateTime.getTime() +
          dateTime.getTimezoneOffset() * 60 * 1000 +
          timezoneHour * 60 * 60 * 1000
      );
      return dateConvert;
    },

    parseTime(datetime) {
      let localTime = null;
      if (typeof datetime === "object") {
        localTime = `${datetime.years}-${datetime.months}-${datetime.days} ${datetime.hours}:${datetime.minutes}`;
      } else {
        localTime = datetime;
      }
      return momentTz.tz(localTime, this.timezone).utc().format();
    },
  },
  watch: {
    scheduleDate(v) {
      this.scheduleSendDatetime = this.parseTime({
        ...v,
        ...this.scheduleTime,
      });
    },
    scheduleTime(v) {
      this.scheduleSendDatetime = this.parseTime({
        ...this.scheduleDate,
        ...v,
      });
    },
  },
});
