<template>
  <div class="adminContent p-3">
    <Table
      :paging="paging"
      @updatePaging="updatePaging"
      :columns="columns"
      :rows="rows"
      :total-items="totalItems"
      :showNoRow="true"
      v-loading="loading"
    >
      <template #head_name>
        <TextField
          placeholder="Type to search .."
          hideError
          v-model:value="filteredName"
        />
      </template>

      <template #head_is_enabled>
        <SelectField
          v-model:value="filteredStatus"
          :options="statusList"
          hideError
        />
      </template>

      <template #row_user_ids="{ props }">
        <span>{{ props.user_ids.length }}</span>
      </template>

      <template #row_is_enabled="{ props }">
        <td>{{ props.is_enabled ? "ACTIVE" : "INACTIVE" }}</td>
      </template>

      <template #row_description="{ props }">
        <td>
          <span class="ellipsis-3">{{ props.description }}</span>
        </td>
      </template>
      <template #row_intro_video="{ props }">
        <td>
          <span class="ellipsis-3">{{ props.video_name }}</span>
        </td>
      </template>

      <template #row_actions="{ props }">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle custom-menu-dropdown"
            type="button"
            id="newsletterManagement"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="newsletterManagement"
          >
            <li>
              <span
                class="dropdown-item pointer"
                @click="openDialogToAddEdit(props.lpfund_id)"
                title="Edit"
              >
                <i class="fas fa-comment-alt me-1"></i>Edit
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="openDialogToAddMemberToFund(props.lpfund_id)"
                title="Edit"
              >
                <i class="fas fa-plus me-1"></i>Add members to fund
              </span>
            </li>
            <li>
              <span
                class="dropdown-item pointer"
                @click="handleChangeStatus(props)"
                :title="props.is_enabled ? 'Activate' : 'Inactivate'"
              >
                <template v-if="props.is_enabled">
                  <i class="fas fa-lock me-1"></i>Inactivate
                </template>
                <template v-else>
                  <i class="fas fa-lock-open me-1"></i>Activate
                </template>
              </span>
            </li>
            <li>
              <span class="dropdown-item pointer" @click="deleteEvent(props)">
                <i class="far fa-trash-alt me-1"></i>Delete
              </span>
            </li>
          </ul>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="openDialogToAddEdit(null)">
          Create LP Fund
        </button>
      </template>
    </Table>

    <!-- <Popper /> -->
    <Dialog ref="dialog" />

    <addEditFund
      v-if="isShowAddEdit"
      :id="editItemId"
      @close="closeAddEdit"
      @complete="onCompleteAddEdit($event)"
    />

    <addMemberToFund
      v-if="isShowAddMemberToFund"
      :id="displayedItemId"
      @close="closeAddMemberToFund"
      @complete="onCompleteAddMemberToFund"
    />
  </div>
</template>
<!-- #todo check source link import script line 120 -->
<script src="./index.js"></script>

<style lang="scss">
@import "@/assets/styles/variables_dark";

.nav li a {
  font-size: 14px;
  color: #cdcdcd;
  line-height: 40px;
  display: flex;
  align-items: center;
  // #todo check line 132 , why add import?
  padding: 0 15px !important;
  border-bottom: 2px solid transparent;
  height: 100%;
  &.active {
    color: #409eff;
    // border-bottom: 2px solid #409eff !important;
  }
}
.nav-tabs {
  border-bottom: 2px solid rgba(225, 225, 225, 0.2);
  margin-bottom: 1em;
  &:after {
    height: 2px;
  }
}
.content-dropdown-popper {
  display: block;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  white-space: nowrap;
  background: #343a40;
  border: 1px solid #292c30;
  border-radius: 8px;
  margin-top: -10px;
  margin-left: -40px;
  .customer-el {
    padding: 6px 16px;
    text-align: left;
    min-width: 135px;
    &:hover {
      background: $color-background-gray-dark;
      color: #fafafa;
    }
  }
}
</style>
