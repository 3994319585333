<template>
  <div
    class="dropdown"
    :class="{ dropup: top }"
    @mouseleave="mouseLeave"
    @mouseover="mouseOver"
    @mouseenter="mouseEnter"
    @click="toggleMenu"
  >
    <slot></slot>
    <transition :name="transition">
      <div
        v-show="value"
        class="dropdown-menu show"
        :class="{ 'dropdown-menu-right': right }"
        :style="styles"
        @mouseleave="startTimer"
        @mouseenter="stopTimer"
        @click.stop
        ref="dropdown"
      >
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
//depend on bootstrap class
$color-background-tertiary: #24262d;
.dropdown-menu,
.dropdown-menu-right {
  background-color: $color-background-tertiary;
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
</style>
