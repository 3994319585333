<template>
  <div class="mapData">
    <div class="container">

      <topHeader></topHeader>
      <div class="mapContent">
        <div class="info">
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Number of startups</p>
            <p class="m-0 number text-center colorWhite">21</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">MOIC</p>
            <p class="m-0 number text-center colorWhite">2.0X</p>
          </div>
          <div class="item">
            <div id="regionchart" class="m-0 number text-center colorWhite"></div>
          </div>
          <div class="item">
            <div id="stagechart" class="m-0 number text-center colorWhite"></div>
          </div>
        </div>
        <div id="mapchart"></div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js">
</script>

<style lang="scss" scoped>
.mapData{
  display: block;
  overflow: hidden;
  .container{
    max-width: 1276px;
    width: 100%;
    background: #1e2128;
  }

  .mapContent{
    background-color: #1e2128;
    .info{
      display: flex;
      height: 150px;
      #regionchart, #stagechart{
        display: block;
        width: 100%;
        height: 80px;
      }
      .item{
        padding-top: 20px;
        flex: 1;
        >p{
          line-height: 24px;
        }
        .number{
          font-size: 60px;
          line-height: 64px;
        }
      }
    }
    #mapchart{
      width: 100%;
      height: calc(100vh - 69px - 150px);
      padding: 10px 0;
    }
  }
}
.map {
  min-height: 500px;
}
</style>