import { defineComponent } from "vue";
import appStore from "../../../store/app";
import pdf from "../pdf-viewer";

export const FileTypes = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  HTML: "HTML",
};

export const AllowViewFileExtensions = [
  ".pdf",
  ".mp4",
  ".gif",
  ".jpg",
  ".jpeg",
  ".png",
];

export function parseFileType(extension) {
  if (extension.toLowerCase() === ".pdf") {
    return FileTypes.PDF;
  } else if (extension.toLowerCase() === ".mp4") {
    return FileTypes.VIDEO;
  } else if (AllowViewFileExtensions.includes(extension)) {
    return FileTypes.IMAGE;
  } else {
    return "";
  }
}

export default defineComponent({
  name: "filePreview",
  props: ["fileUrl", "fileType"],
  components: {
    pdf,
  },
  data() {
    return {
      isShow: false,
      FileTypes: FileTypes,
    };
  },
  computed: {
    scale() {
      return appStore.state.mobile.isMobile ? "page-width" : "page-width";
    },
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
      this.staticBody();
    },
  },
});
