<template>
    <div class="ratingStage" v-loading="loading || reload">
        <div class="row" v-if="!loading">
            <div class="col-8">
                <div class="d-flex">
                    <div v-if="totalStage && (currentStage !== null || currentStage !== undefined)" class="d-flex align-items-center me-3">
                        <h3 class="mb-0">Rating</h3>
                        <!-- <div class="sub-title">{{totalStage[currentStage].name}}</div> -->
                    </div>
                    <div class="star-rating" ref="score">
                        <template v-for="(item, index) in totalStar" :key="`item_${index}`">
                            <span class="star me-2" :class="{active: index < currentStarTarget}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.429" height="25.289" viewBox="0 0 25.429 25.289">
                                    <g id="star" transform="translate(0)">
                                        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
                                        <path id="Path_10" data-name="Path 10" d="M20.931,5.889l2.244,4.488a2.8,2.8,0,0,0,1.8,1.337l4.058.668c2.594.43,3.2,2.308,1.337,4.185L27.2,19.734a2.748,2.748,0,0,0-.652,2.308l.907,3.915c.716,3.087-.939,4.3-3.66,2.674l-3.8-2.26a2.7,2.7,0,0,0-2.514,0l-3.8,2.26c-2.721,1.607-4.376.414-3.66-2.674l.907-3.915a2.777,2.777,0,0,0-.652-2.308L7.1,16.567c-1.862-1.862-1.257-3.74,1.337-4.185l4.058-.668a2.749,2.749,0,0,0,1.8-1.337l2.244-4.488C17.733,3.454,19.706,3.454,20.931,5.889Z" transform="translate(-6.021 -4.062)"/>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-4 text-end" v-if="currentStage < totalStage.length-1 && dataDeal && !dataDeal.is_rejected">
                <div class="row">
                    <div class="col-12">
                        <button v-if="!is_me" class="btn btn-info submit" :disabled="currentStarTarget <= 0" @click="showSubmitRate">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="dataRating.length > 0">
                <span class="subtitle-text pointer" @click="showModalReviewRate">Reviewers ratings</span>
            </div>
        </div>
        <submitRate ref="showRate" @confirm="confirmSubmitRate" :totalStar="totalStar" :currentStarTarget="currentStarTarget" />
        <reviewRate ref="showReviewRate" :currentStage="currentStage" :dataReviewerRate="dataRating" />
    </div>
</template>

<style scoped lang="scss">
.ratingStage{
    padding: 29px 0;
    border-bottom: 1px solid #3A3E47;
    h3{
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 38px;
    }
    .sub-title {
        color: rgb(167, 167, 167);
        font-size: 0.9em;
    }
    .star-rating{
        cursor: pointer;
        padding: 6px 0;
        path{
            fill: #24262d;
            transition: fill 200ms linear;
        }
        .active{
            path{
                fill: #0095FF;
            }
        }
    }
    button.submit{
        color: #fff !important;
        background-color: #0095FF;
        line-height: 28px;
        font-weight: bold;
        width: 180px;
        &:disabled{
            cursor: no-drop;
        }
        // @media only screen and (max-width: 1440px) {
		// 	margin-bottom: 10px;
		// }
    }
}
.subtitle-text {
    color: #1ca0ff;
}
</style>
<script src="./index.js"></script>