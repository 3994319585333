<template>
  <div v-if="isShow" class="lp-modal">
    <div class="backdrop">
      <template v-if="lpFunds.length > 0">
        <div :class="`fund-list wrap`" :style="`--item: ${lpFunds.length}`">
          <div
            v-for="(item) in lpFunds"
            :key="item.lp_fund_id"
            class="fund-item is-closed"
            :style="{ backgroundColor: item.bg_color }"
            @mouseenter="addClass"
            @mouseleave="removeClass"
            @click="handleSetLpFunds(item)"
          >
            <img class="fund-logo" :src="item.logo" alt="Fund logo"/>
            <!-- <p>{{ item.name }}</p> -->
          </div>
        </div>
      </template>

      <template v-else>
        <h5 class="empty-fund">
          You have not been added to any fund, please contact admin
        </h5>
      </template>
    </div>
  </div>
</template>

<script>
import appStore, { APP_ACTIONS } from "@/store/app";

export default {
  data() {
    return {
      windowWidth: 0,
      // colors: [
      //   "#134074",
      //   "#13315C",
      //   "#0B2545",
      //   "#8DA9C4",
      //   "#EEF4ED",
      //   "#f8f9fa",
      //   "#134074",
      //   "#13315C",
      //   "#0B2545",
      //   "#8DA9C4",
      //   "#EEF4ED",
      //   "#f8f9fa",
      //   "#134074",
      //   "#13315C",
      //   "#0B2545",
      //   "#8DA9C4",
      //   "#EEF4ED",
      //   "#f8f9fa",
      //   "#134074",
      //   "#13315C",
      //   "#0B2545",
      //   "#8DA9C4",
      //   "#EEF4ED",
      //   "#f8f9fa",
      // ],
    };
  },
  computed: {
    lpFunds() {
      return appStore.state.profile.lpFunds || [];
    },
    isShow() {
      return appStore.state.isShowLpFundsInvestorPopup;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleSetLpFunds(lpFund) {
      const pay = {
        selected_fund: this.lpFunds.find(x => x.lp_fund_id === lpFund.lp_fund_id)
      };
      appStore.dispatch(APP_ACTIONS.set_lp_investor_info, pay);
    },
    addClass(e) {
      if (this.windowWidth < 768 || this.lpFunds.length <= 1) return;
      e.target.classList.remove("is-closed");
      e.target.classList.add("is-expand");
    },
    removeClass(e) {
      if (this.windowWidth < 768 || this.lpFunds.length <= 1) return;
      e.target.classList.remove("is-expand");
      e.target.classList.add("is-closed");
    },
  },
};
</script>

<style lang="scss" scoped>
.lp-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;

  .backdrop {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #393d44;
  }

  .fund-list {
    display: flex;

    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .fund-item {
      height: 100%;
      /* background-image: url("https://maquia.s3.amazonaws.com/images/spacs/69914a71b00f.jpeg"); */
      transition: all 0.5s ease;
      /* background-size: cover; */
      /* background-position: center; */
      color: #fff;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      animation: background-breath 300s linear infinite;

      @media (max-width: 768px) {
        width: 100% !important;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.1;
      }

      p {
        color: #fff;
        font-size: 35px;
        padding-left: 10px;
        margin: 0;
        text-align: center;
      }

      .fund-logo {
        width: 80%;
        height: auto;
      }
    }
  }

  .wrap {
    --item: 2;
    .is-closed {
      width: calc(100% / var(--item));
    }
    .is-expand {
      width: 80%;
    }
  }

  .empty-fund {
    color: #fff;
  }
}
</style>
