<template>
  <div
    class="modal fade"
    id="FpcChecklisk"
    tabindex="-1"
    role="dialog"
    aria-labelledby="FpcChecklisk"
    aria-hidden="true"
    v-if="showModal"
  >
    <div class="modal-dialog m-0" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-center">
            <img
              class="img-logo my-0 mx-auto"
              src="../../../../assets/images/logo_FVC_black.png"
              width="150"
            />
          </div>
          <button type="button" class="btn close" @click="hide">
            <!-- <img :src="btnClose" alt="" /> -->
            <i class="close-btn far fa-times-circle"></i>
          </button>
        </div>
        <div class="px-3 pt-3 pb-0">
          <div class="modal-body pb-0" v-loading="loading">
            <div class="row info pt-2" v-if="false">
              <div class="col-md-3 col-6">Company:</div>
              <div class="col-md-3 col-6">Date:</div>
              <div class="col-md-3 col-6">Stage:</div>
              <div class="col-md-3 col-6">Capital Raise:</div>
              <div class="col-md-3 col-6">Industry:</div>
              <div class="col-md-3 col-6">Referer:</div>
              <div class="col-md-12 col-12">Brief Description:</div>
            </div>
            <div
              class="row infoReviewChecklist"
              v-if="usersReviewChecklist.length > 0 && showSelectChecklist"
            >
              <div class="col-12">
                <h2 class="title-group mb-0">Select FPC Checklist by User</h2>
              </div>
              <div class="col-12 mb-4">
                <button
                  v-for="(item, idx) in usersReviewChecklist"
                  :key="`userReview_${idx}`"
                  :disabled="
                    usersReviewSelected &&
                    usersReviewSelected.user_id === item.user_id
                  "
                  class="btn btn-secondary me-2"
                  :class="{
                    active:
                      usersReviewSelected &&
                      usersReviewSelected.user_id === item.user_id,
                  }"
                  @click="viewChecklistByUser(item)"
                >
                  {{ item.user_name }}
                </button>
              </div>
              <div class="col-12" v-if="usersReviewSelected">
                <p class="mb-1">
                  FPC checklist by <b>{{ usersReviewSelected.user_name }}</b>
                </p>
              </div>
            </div>
            <div class="row info">
              <div class="col-12">
                <h2 class="title mb-0">Investment Criteria</h2>
              </div>
            </div>
            <div class="row info" v-if="dataReviewQuestions">
              <div class="table-responsive">
                <table class="table" :class="!inViewmode ? 'mb-4' : 'mb-0'">
                  <thead>
                    <tr>
                      <th>Criteria</th>
                      <th class="description">Description</th>
                      <th class="score d-flex justify-content-between">
                        <div>Low Score</div>
                        <div>Average/Unknown</div>
                        <div>High Score</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(criteria, index) in dataReviewQuestions"
                      :key="`criteria_${index}`"
                    >
                      <td
                        class="criteria"
                        :class="{ hasFirstItem: criteria.firstItem }"
                      >
                        <div v-if="criteria.firstItem" class="row category">
                          <div class="col-12">{{ criteria.criteria }}</div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <p v-html="criteria.question"></p>
                          </div>
                        </div>
                      </td>
                      <td :class="{ hasFirstItem: criteria.firstItem }">
                        <div class="row pr-4">
                          <div class="col-12 description">
                            <p v-html="criteria.description"></p>
                          </div>
                        </div>
                      </td>
                      <td
                        class="score"
                        :class="{ hasFirstItem: criteria.firstItem }"
                      >
                        <div class="row" :class="{ inViewmode: inViewmode }">
                          <div
                            class="col"
                            v-for="score in criteria.max_score"
                            :key="`score_${score}`"
                          >
                            <template v-if="inViewmode">
                              <button
                                class="btn btn-secondary"
                                :class="{ active: score === criteria.score }"
                              >
                                {{ score }}
                              </button>
                            </template>
                            <template v-else>
                              <button
                                class="btn btn-success"
                                :class="{ active: score === criteria.score }"
                                @click="markScore(criteria.question_id, score)"
                              >
                                {{ score }}
                              </button>
                            </template>
                          </div>
                        </div>
                        <div class="error" v-if="criteria.errMsg">
                          {{ criteria.errMsg }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <template v-if="inViewmode">
                        <td>{{ ` ` }}</td>
                        <td colspan="2">
                          <div class="mt-3 score-bt">
                            Qualitative Score: {{ score.qualitativePercent }}%
                          </div>
                          <div class="mb-2 score-bt">
                            Quantitative Score: {{ score.quantitativePercent }}%
                          </div>
                          <div class="score-bt">
                            TOTAL SCORE: {{ score.totalPercent }}%
                          </div>
                        </td>
                      </template>
                      <template v-else>
                        <td>{{ ` ` }}</td>
                        <td colspan="2" class="text-end pt-3">
                          <NewButton
                            class="me-2"
                            @click="submitMarkScore(false)"
                            :loading="loading"
                            color="transparent"
                          >
                            Save Draft
                          </NewButton>
                          <NewButton
                            @click="submitMarkScore(true)"
                            :loading="loading"
                            color="blue"
                          >
                            Submit
                          </NewButton>
                        </td>
                      </template>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col-2">
                <span class="text">...</span>
              </div>
              <div class="col-10 text-right">
                <div class="line p-3">
                  <img
                    src="../../../../assets/images/icon-logo-black.png"
                    width="30"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import notify from "@/services/notify";
import { DEAL_REVIEW_QUESTIONS } from "../../../../misc/constants";
import DealServices from "@/services/deal";
import NewButton from "@/components/NewButton";

export default defineComponent({
  components: {
    NewButton,
  },
  emits: ["refreshDeal"],
  props: ["dealId"],
  data() {
    return {
      showModal: false,
      loading: false,
      dataReviewQuestions: null,
      inViewmode: false,
      specifyIdDeal: null,
      usersReviewChecklist: [],
      usersReviewSelected: null,
      showSelectChecklist: false,
    };
  },

  methods: {
    viewChecklistByUser: function (data) {
      if (data) this.usersReviewSelected = data;
      this.reviewQuestions();
    },
    validateScore() {
      let flag = true;
      this.dataReviewQuestions.map((p) => {
        p.errMsg = "";
        if (p.score === 0) {
          flag = false;
          p.errMsg = "Please provide score for this question";
        }
      });
      return flag;
    },
    submitMarkScore(isSubmit) {
      if (isSubmit) {
        if (!this.validateScore()) return;
      }

      let dataReviewers = [];
      this.dataReviewQuestions.map((p) => {
        dataReviewers.push({ question_id: p.question_id, score: p.score });
      });
      this.loading = true;
      DealServices.submitReviewQuestions(
        this.specifyIdDeal,
        dataReviewers,
        isSubmit
      )
        .then((resp) => {
          if (resp.data.ok) {
            if (!isSubmit) {
              notify.success("Checklist saved successfully!");
            } else {
              notify.success("Checklist submitted successfully!");
            }
            this.$emit("refreshDeal", this.specifyIdDeal, true);
            this.hide();
          } else {
            notify.error("Failed to save this checklist.");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    markScore: function (id, score) {
      this.dataReviewQuestions.find((p) => p.question_id === id).errMsg = "";
      this.dataReviewQuestions.find((p) => p.question_id === id).score = score;
    },
    breakLineDescription: function (value) {
      const key = ["\u0000", "\r\n", "\n"];
      key.map((p) => {
        value = value.includes(p) ? value.trim().replaceAll(p, "<br/>") : value;
      });
      return value;
    },
    getReviewQuestions: function () {
      function mapData(data) {
        data.map((p) => {
          for (let i = 0; i < DEAL_REVIEW_QUESTIONS.length; i++) {
            if (p.criteria === DEAL_REVIEW_QUESTIONS[i].code) {
              p.max_score = DEAL_REVIEW_QUESTIONS[i].max_score;
              p.score = 0;
              p.errMsg = "";
              return p;
            }
          }
        });

        let arr = DEAL_REVIEW_QUESTIONS.map((p) =>
          data.filter((i) => i.criteria === p.code)
        );
        let newarr = [];

        for (let i = 0; i < arr.length; i++) {
          arr[i].map((p, index) => (p.firstItem = index === 0 ? true : false));
          newarr = newarr.concat(arr[i]);
        }
        return newarr;
      }

      this.loading = true;
      DealServices.getReviewQuestions()
        .then((resp) => {
          if (resp.data.ok) {
            resp.data.d.map((p) => {
              p.question = this.breakLineDescription(p.question);
              p.description = this.breakLineDescription(p.description);
              return p;
            });
            this.dataReviewQuestions = mapData(resp.data.d);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reviewQuestions: function () {
      function mapData(data) {
        data.map((p) => {
          for (let i = 0; i < DEAL_REVIEW_QUESTIONS.length; i++) {
            if (p.criteria === DEAL_REVIEW_QUESTIONS[i].code) {
              p.max_score = DEAL_REVIEW_QUESTIONS[i].max_score;
              p.errMsg = "";
              return p;
            }
          }
        });

        let arr = DEAL_REVIEW_QUESTIONS.map((p) =>
          data.filter((i) => i.criteria === p.code)
        );
        let newarr = [];

        for (let i = 0; i < arr.length; i++) {
          arr[i].map((p, index) => (p.firstItem = index === 0 ? true : false));
          newarr = newarr.concat(arr[i]);
        }
        return newarr;
      }
      this.loading = true;
      DealServices.reviewQuestions(
        this.dealId,
        this.usersReviewSelected.user_id
      )
        .then((resp) => {
          if (resp.data.ok) {
            resp.data.d.map((p) => {
              p.description = this.breakLineDescription(p.description);
              return p;
            });
            this.dataReviewQuestions = mapData(resp.data.d);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    show: function (id, param, options = []) {
      this.specifyIdDeal = id;
      if (param === "viewOnly") {
        this.showSelectChecklist = true;
        this.inViewmode = true;
        this.usersReviewChecklist = [...options];
        this.usersReviewSelected = this.usersReviewChecklist[0];
        this.reviewQuestions();
      } else if (param === "Continute") {
        this.inViewmode = false;
        this.usersReviewChecklist = [...options];
        const item = this.usersReviewChecklist.find(
          (x) => x.user_id == this.$store.state.profile.userId
        );
        if (!item) {
          this.hide();
        }
        this.usersReviewSelected = item;
        this.reviewQuestions();
      } else {
        this.getReviewQuestions();
      }
      this.showModal = true;
    },
    hide: function () {
      this.showModal = false;
    },
  },
  computed: {
    score() {
      var score = {
        qualitative: 0,
        maxQualitative: 0,
        qualitativePercent: 0,
        quantitative: 0,
        maxQuantitative: 0,
        quantitativePercent: 0,
        totalPercent: 0,
      };
      this.dataReviewQuestions.forEach((x) => {
        if (x.criteria === "QUALITATIVE") {
          score.qualitative += x.score;
          score.maxQualitative += x.max_score;
        }
        if (x.criteria === "QUANTITATIVE") {
          score.quantitative += x.score;
          score.maxQuantitative += x.max_score;
        }
      });
      score.qualitativePercent = Math.round(
        (score.qualitative * 100) / score.maxQualitative
      );
      score.quantitativePercent = Math.round(
        (score.quantitative * 100) / score.maxQuantitative
      );
      score.totalPercent = Math.round(
        ((score.qualitative + score.quantitative) * 100) /
          (score.maxQualitative + score.maxQuantitative)
      );
      return score;
    },
  },
});
</script>

<style lang="scss" scoped>
#FpcChecklisk {
  display: block;
  opacity: 1;
  background: #fff;
  .modal-dialog {
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0, 0);
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    z-index: 2;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b3b3b3;
      border-radius: 4px;
      transition: all 300ms;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      border-radius: 4px;
      transition: all 300ms;
    }
  }
  .modal-header {
    border-bottom: 0px solid transparent;
    > div {
      flex: 1;
    }
  }
  .modal-content {
    padding: 0;
    border: 0px;
    background-color: #fff;
    .title-group {
      color: #000;
      font-weight: 700;
      font-size: 1.1em;
      position: relative;
    }
    .title {
      color: #000;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      text-transform: uppercase;
      position: relative;
      &:after {
        content: "...";
        position: absolute;
        left: 0;
        bottom: -8px;
        font-size: 24px;
        color: #000;
      }
    }
    .close {
      line-height: 40px;
      border: 0px !important;
    }
  }
  .modal-body {
    .info {
      background-color: #f2f6fd;
      color: #75b4f1;
      > .table-responsive {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
      border-top: 0px;
      padding-right: 0 !important;
    }
    table {
      .score {
        min-width: 320px;
      }
      thead {
        background-color: #f2f6fd;
        th {
          font-style: italic;
          color: #000;
          font-weight: bold;
          font-size: 13px;
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px !important;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #eaf0f4;
          }
          &:nth-child(even) {
            background-color: #f2f6fd;
          }
          td {
            color: #7e7f80;
            &:first-child {
              padding-left: 16px;
            }
            &:last-child {
              padding-right: 16px !important;
            }
            &.hasFirstItem {
              position: relative;
              padding-top: 30px;
            }
            &.criteria {
              color: #72b2ef;
              font-weight: 600;
            }
            .description {
              font-size: 12px;
              > p {
                font-size: 13px;
                margin-bottom: 0;
              }
            }
            .score {
              font-size: 14px;
            }
            .inViewmode {
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                overflow: hidden;
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
            .category {
              position: absolute;
              top: 10px;
              left: 18px;
              width: 100%;
              height: 20px;
              color: #000;
              font-weight: bold;
              font-style: italic;
              > * {
                height: 20px;
              }
            }
            .btn.active,
            .btn:hover {
              color: #fff !important;
              background-color: #1e7e34 !important;
            }
            .error {
              color: red;
              font-size: 12px;
            }
          }
        }
      }
      tfoot {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          overflow: hidden;
          width: 100%;
          height: 10px;
          background-color: #01a0ff;
        }
        td {
          font-weight: 600;
          button {
            &:hover {
              color: #fff !important;
              background-color: #117a8b !important;
            }
          }
        }
      }
    }
    .infoReviewChecklist {
      button {
        &.active {
          color: #01a0ff !important;
          border-color: #01a0ff;
          background-color: #fff;
        }
      }
    }
  }
  .modal-footer {
    position: relative;
    border-top: 0px;
    > div {
      background: #f1faff;
    }
    .text {
      color: #000;
      font-size: 50px;
      font-weight: bold;
      padding-left: 12px;
      line-height: 26px;
    }
    .line {
      background-color: #01a0ff;
    }
  }
}
</style>
