<template>
  <div class="fileUpload">
    <div class="row top mb-0">
      <div class="col-12">
        <h3 @click="hideBlock(true)" class="pointer">
          <button ref="toggleButton">
            <Menu8Icon />
          </button>
          <span>All Files Upload</span>
        </h3>
      </div>
    </div>
    <div class="item p-0">
      <div class="row">
        <div class="col-6"><span class="text">File</span></div>
        <div class="col-6 text-end"><span class="text">Actions</span></div>
      </div>
    </div>
    <div v-loading="loading">
      <template v-if="additionalFiles && additionalFiles.length > 0">
        <div
          class="item"
          v-for="(item, index) in additionalFiles"
          :key="`additionalFile_${index}`"
        >
          <div class="row">
            <div class="col">
              <span class="colorWhite">
                File name:
                <span
                  class="fileName d-inline-block pointer"
                  :title="`${item.name}${item.extension}`"
                  @click.prevent="viewFile(item)"
                  >{{ `${item.name}${item.extension}` }}</span
                >
              </span>
              <span class="date"
                >At {{ $filters.parseEstTime(item.created_at) }} <br />By
                <b>{{ item.created_by }}</b>
              </span>
            </div>
            <div class="col action text-end">
              <button
                @click="download(item.s3key, item.name, true)"
                class="download pointer"
              >
                <DownloadIcon />
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="dataDocument">
        <div
          class="item"
          v-for="(item, index) in dataDocument"
          :key="`file_${index}`"
        >
          <div class="row">
            <div class="col">
              <span class="colorWhite">
                File name:
                <a
                  href="#"
                  class="fileName d-inline-block"
                  :title="`${item.name}${item.extension}`"
                  @click.prevent="viewFile(item)"
                  >{{ `${item.name}${item.extension}` }}</a
                >
              </span>
              <span class="date"
                >At {{ $filters.parseEstTime(item.created_at) }} <br />By
                <b>{{ item.created_by }}</b></span
              >
            </div>
            <div class="col action text-end">
              <button
                @click="download(item.deal_document_id, item.name)"
                class="download pointer me-1"
              >
                <DownloadIcon />
              </button>
              <button
                class="remove pointer"
                @click="remove(item.deal_document_id)"
                v-if="currentStage >= 0 && currentStage < 5 && stageAllow"
              >
                <RemoveIcon width="11" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div>
      <template
        v-if="
          ((dataDocument && dataDocument.length === 0) || !dataDocument) &&
          additionalFiles &&
          additionalFiles.length === 0
        "
      >
        <div class="item">
          <div class="row">
            <div class="col-12">
              <p class="mb-0 colorWhite text-center">No Data</p>
            </div>
          </div>
        </div>
      </template>
    </div>
    <filePreview
      ref="filePreview"
      :fileUrl="fileToken"
      :fileType="previewFileType"
    />
  </div>
</template>
<style scoped lang="scss">
.fileUpload {
  position: relative;
  .top {
    h3 {
      margin-bottom: 0;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      line-height: 64px;
      button {
        border: 0px transparent;
        background-color: transparent;
      }
      img,
      span {
        vertical-align: middle;
      }
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .item {
    border-bottom: 1px solid #33353a;
    padding: 10px 0;
    font-size: 12px;
    .text {
      font-size: 12px;
      color: #f5f5f5;
      line-height: 40px;
    }
    .fileName {
      color: #0095ff;
      text-decoration: underline !important;
      font-size: 12px;
      display: block;
      word-break: break-all;
    }
    .date {
      display: block;
      font-size: 12px;
      color: #fff;
    }
    button,
    a.download {
      line-height: 25px;
      width: 25px;
      border: 0px;
      background-color: #fff;
      border-radius: 5px;
      display: inline-block;
      text-align: center;
    }
    .action {
      flex: 0 0 90px;
    }
  }
}
</style>
<script src="./index.js"></script>
