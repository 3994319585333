import { defineComponent } from "vue";
import _ from "lodash";

import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import Dialog from "@/components/Dialog";
import { mappingDataPagination } from "@/utils/helpers";

const tabsData = [
  {
    name: "All",
    code: "",
  },
  {
    name: "Inprogress",
    code: "INPROGRESS",
  },
  {
    name: "Completed",
    code: "COMPLETED",
  },
  {
    name: "Cancelled",
    code: "CANCELLED",
  },
];

const columnsData = [
  {
    id: "name",
    name: "Name",
    _wordBreak: "break-word",
    _minWidth: "250px",
  },
  {
    id: "eventDateTime",
    name: "Event time",
    columnClass: "multiple-line",
    _wordBreak: "break-word",
    _minWidth: "150px",
  },
  {
    id: "milestones",
    name: "Milestones",
    columnClass: "multiple-line",
    _wordBreak: "break-word",
    _minWidth: "180px",
  },
  {
    id: "totalInvitationSent",
    name: "Total Invited",
  },
  {
    id: "totalAttendee",
    name: "RSVP",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "actions",
    name: "Actions",
    titleClass: "text-right",
    columnClass: "text-right",
  },
];

export default defineComponent({
  components: {
    Table,
    Dropdown,
    DropdownItem,
    TextField,
    SelectField,
    Dialog,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredName: "",
      tab_active: 0,
      tabs: tabsData,
      columns: columnsData,
      confirmText: {
        header: "",
        body: "",
      },
      timezone: "America/New_York",
      statusTab: "",
    };
  },
  watch: {
    "paging.page": function (v) {
      if (!v) return;
      debounce(this.getAll);
    },
    filteredName() {
      this.setPageDefault();
      debounce(this.getAll);
    },
    columns: {
      deep: true,
      handler(v) {
        let col = v.find(
          (p) => typeof p.sort === "boolean" && p.sort && p.direction
        );
        this.orderBy = col ? `${col.id} ${col.direction}` : "";
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAll();
    });
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getEvents({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        filteredName: this.filteredName,
        sortField: this.sortField,
        sortDirection: this.sortDirection,
        status: this.statusTab,
      });
      const cvData = mappingDataPagination(
        res.data.d.c,
        res.data.d.p,
        Math.ceil(res.data.d.t/res.data.d.s)
      );
      if (res && res.data && res.data.ok) {
        this.rows = cvData;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    changeTab: function (idx) {
      this.tab_active = idx;
      this.statusTab = this.tabs[idx].code;
      this.rows = [];
      debounce(this.getAll);
    },
    deleteEvent(item) {
      let message = {
        title: "Delete Event",
        body: "Are you sure to delete this event? If you delete it, this event cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "custom-header-model",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;

          adminServices
            .deleteEvent(item.eventId)
            .then((resp) => {
              if (resp.data.d.succeeded) {
                notify.success("Delete successfully");
                if (item.isLastItemInPage) {
                  this.paging.page = this.paging.page - 1;
                }
                this.getAll();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    editEvent(param) {
      this.$router.push("/rfm/event/" + param.eventId);
    },
    cancelEventStatus(eventId) {
      this.loading = true;
      adminServices
        .cancelEventStatus(eventId)
        .then((resp) => {
          if (resp.data.ok) {
            notify.success("Cancel successfully");
            this.getAll();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancelEvent(item) {
      let message = {
        title: "Cancel Event",
        body: "Are you sure to cancel this event? If you cancel, you cannot be reverted.",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;
          adminServices
            .sendCancelInvitation(item.eventId)
            .then((resp) => {
              if (resp.data.ok) {
                this.cancelEventStatus(item.eventId);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },

    completeEvent(item) {
      let message = {
        title: "Complete Event",
        body: "Are you sure to complete this event? If you complete, you cannot be reverted",
      };
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        okText: "Confirm",
        cancelText: "Cancel",
        animation: "fade", // Available: "zoom", "bounce", "fade"
        type: "basic", // coming soon: 'soft', 'hard'
        customClassHeader: "pb-0",
        customClass: "confirmDeleteMember", // Custom class to be injected into the parent node for the current dialog instance
      };

      this.$refs.dialog
        .confirm({ ...message, ...options })
        .then(async (d) => {
          if (!d) return;
          this.loading = true;

          adminServices
            .completeEventStatus(item.eventId)
            .then((resp) => {
              if (resp.data.d.succeeded) {
                notify.success("Complete successfully");
                this.getAll();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(function () {
          // This will be triggered when user clicks on cancel
        });
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
    async addEvent() {
      this.$router.push("/rfm/event/create");
    },
  },
});
