import { defineComponent } from 'vue'
import adminServices from '../../services/admin'
import filePreview, { parseFileType } from '../commons/filePreview'

export default defineComponent({
    props: {
        value: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
        name: { type: String, default: '' },
        errorMsg: { type: String, default: '' },
        tabindex: { type: Number, default: 1 },
        size: { type: String, default: '' },
        title: { type: String, default: '' },
        color: { type: String, default: '' },
        field: {type: String, default: ''},
        id: {type: String, default: ''},
        acceptType: {type: String, default: ''},
        acceptValue: {type: String, default: ''},
        custom: {type: Boolean, default: false},
        fileUploaded: {type: Object, default: ''},
        indexField:{type: Number, default : 0}

    },
    emits: ['update:value', 'update:errorMsg'],
    data() {
        return {
            loading: false,
            dataAcceptValue: null,
            fileToken: null,
            hideError: false,
            previewFileType: '',
            _value: null
        }
    },
    components: {
        filePreview
    },
    mounted() {
        this.$nextTick(() => {
            try {
                let acceptValue = JSON.parse(this.acceptValue)
                this.dataAcceptValue = acceptValue.map(x=> `.${x}`).join()
            } catch (e) {}
        })
        
    },
    methods: {
        valueDocumentFnc: function(string) {
            let vue = this
            this.$refs[string].parentNode.setAttribute('data-after', this.$refs[string].files.length > 0 ? this.$refs[string].files[0].name : '...')
            if (this.$refs[string].files[0].name) {
                this._value = vue.$refs[string].files[0]
                this.hideError = true
                this.$emit('update:value', this._value)
            }
        },
        async viewFile(item) {           
            const fileType = parseFileType(item.extension)
            this.loading = true
            const res =  await adminServices.requestDownloadS3(item.s3key)
            if (res.data.ok){
                this.showFilePreview(res.data.d, fileType)
            }
            this.loading = false
        },
        showFilePreview(token, previewFileType) { 
            this.fixedBody()
            this.fileToken = token
            this.previewFileType = previewFileType
            this.$refs.filePreview.showModal()
        }
    },
    computed: {
        _errorMsg: {
			get() {
				return this.errorMsg
			},
			set(val) {
				this.$emit('update:errorMsg', val)
			}
		}
    }
})