import AdminServices from "../../../services/admin";
import { router, USER_TYPE } from "@/config/router";
import appStore, { APP_MUTATION } from "../../../store/app";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      dataSurvey: [],
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      surveyPath: router.reviewSurvey.path,
      surveyName: router.reviewSurvey.name,
      surveyDetailPath: router.reviewSurveyDetail.path,
      surveyDetailName: router.reviewSurveyDetail.name,
      welcomeSurveyPath: router.welcomeSurvey.path,
      viewSurveyPath: router.viewSurvey.path,
      isReady: false,
      dealFounderPath: router.dealFounder.path,
      dealFounderName: router.dealFounder.name,
    };
  },
  mounted() {
    if (this.profile.userType === USER_TYPE.FOUNDER_POTENTIAL) {
      this.$router.push({
        path: this.dealFounderPath,
      });
    }

    if (this.profile) {
      this.isReady = true;
      if (
        this.profile.userType === USER_TYPE.FOUNDER &&
        this.profile.welcome_survey_stt !== "APPROVED"
      ) {
        this.$router.push({
          path: this.welcomeSurveyPath,
        });
      } else {
        this.getSurveys();
      }
    }
  },
  methods: {
    getSurveys: function () {
      AdminServices.getSurveys(this.paging).then((resp) => {
        this.dataSurvey = resp.data.d.c;
        this.paging.page = resp.data.d.p;
        this.paging.size = resp.data.d.s;
        this.paging.total = resp.data.d.t;
      });
    },
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
  },
  watch: {
    profile: function (v) {
      if (v) {
        this.isReady = true;
        if (v.userType === "Founder" && v.welcome_survey_stt !== "APPROVED") {
          this.$router.push({
            path: this.welcomeSurveyPath,
          });
        }
      }
    },
  },
});
