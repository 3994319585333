<template>
  <div class="mapData">
    <div class="container">

      <topHeader></topHeader>
      <div class="mapContent">
        <div class="info">
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Investment count</p>
            <p class="m-0 number text-center colorWhite">20</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Realized investment</p>
            <p class="m-0 number text-center colorWhite">41.9M</p>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Region distribution</p>
            <div id="donutchart" class="m-0 number text-center colorWhite"></div>
          </div>
          <div class="item">
            <p class="m-0 mb-4 text-center colorWhite">Stage distribution</p>
            <div id="donutchart1" class="m-0 number text-center colorWhite"></div>
          </div>
        </div>
        <div id="investchart"></div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss">
.mapData{
    display: block;
    overflow: hidden;
    .container{
        max-width: 1276px;
        width: 100%;
        background: #333333;
        .mapContent{
          background-color: rgb(51, 51, 51);
            .info{
              display: flex;
              height: 150px;
              .item{
                padding-top: 20px;
                flex: 1;
                >p{
                  line-height: 24px;
                }
                .number{
                  font-size: 60px;
                  line-height: 64px;
                }
              }
            }
            #donutchart, #donutchart1{
              display: block;
              width: 100%;
              height: 80px;
            }
            #investchart{
              width: 100%;
              height: calc(100vh - 69px - 150px);
              padding: 10px 0;
            }
        }
    }
}
</style>