<template>
    <div class="post">
        <div class="container">
            <div class="row">
                <!-- <div class="col-12 post-title" v-if="post">
                    {{post.title}}
                </div>
                <div class="col-12 post-thumbnail" v-if="post">
                    <img :src="post.photo" alt="thumbnail">
                </div> -->
                <div class="col-12" id="editorjs">
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
    .post {
        img  {
            max-width: 100%;
            width: 100%;
        }
    }
    .post-title {
        font-weight: bold;
        font-size: 2em;
        margin-top: 50px;   
        margin-bottom: 25px;
    }
    .post-thumbnail {
        padding-bottom: 25px;
    }
</style>

<style scoped>
.post :deep() .cdx-simple-image img  {
    max-width: 100%;
    width: 100%;
}
.post :deep() .ce-block__content {
    max-width: 100%;
}
.post :deep() .ce-block__content .cdx-simple-image__caption {
    display: none;
}
.post :deep() .codex-editor__redactor {
    padding: 0 !important;
}
</style>