<template>
  <Modal
    v-model:value="isShow"
    :title="`Extend Deadline`"
    :hasFloatingCloseButton="hasFloatingCloseButton"
    :isVisible="true"
  >
    <div class="datepickerWhite">
      <div class="row">
        <div class="col-6 form-group">
          <Datepicker
            title="Select date"
            placeholder="mm/dd/yyyy"
            :mask="customFormatterDate"
            v-model:value="select_date"
            :minDate="yesterday"
            name="selectDate"
          />
        </div>
        <div class="col-6 form-group">
          <Datepicker
            title="Select time"
            :placeholder="`hh:mm a`"
            v-model:value="select_time"
            :is24="false"
            :errorMsg.="errorMsg.notify_before_hour"
            :isTimePicker="true"
            :mask="customFormatterTime"
            name="selectTime"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <button
        type="button"
        class="btn btn-success btnSubmit colorWhite"
        @click="hide"
        :disabled="!validDate || !select_date"
      >
        Submit
      </button>
    </template>
  </Modal>
</template>
<style scoped lang="scss">
.modal.show {
  display: block;
  .maskModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.7);
  }
  .modal-dialog {
    position: relative;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
  }
  .modal-content {
    background-color: #393c45;
    color: #ccc;
  }
  .modal-header {
    border-bottom: 1px solid rgba(222, 226, 230, 0.1);
    position: relative;
    z-index: 2;
    h5 {
      font-size: 16px;
      line-height: 56px;
      text-transform: uppercase;
    }
    button {
      margin: 0;
    }
  }
  .modal-body {
    border-bottom: 1px solid rgba(222, 226, 230, 0.1);
  }
  .modal-footer {
    border-top: 0px solid transparent;
    button {
      color: #fff !important;
      &.btnSubmit {
        background-color: #1bbf89;
      }
      &.btnClose {
        background-color: #db524b;
      }
    }
  }
}
</style>
<style lang="scss">
.datepickerWhite {
  input {
    background-color: transparent;
    line-height: 1.5;
    height: unset !important;
    border-radius: 4px;
    width: 100% !important;
    color: #949ba2;
    border: 1px solid #70707038 !important;
  }
  .vue__time-picker .controls {
    display: none;
  }

  .vdp-datepicker__calendar {
    background-color: #505050 !important;
    .disabled {
      background: #636161;
    }
  }
}
</style>
<script src="./newDeadline.js"></script>
