import appStore, { AUTH_ACTIONS, APP_ACTIONS } from "../../../store/app";
import router from "@/config/router";
import { defineComponent } from "vue";
import LogoIcon from "@/assets/images/logo_FVC_black.png";
import BackIcon from "@/assets/images/ctrl-left.svg";

export default defineComponent({
  props: ["currentRouterProps"],
  components: { BackIcon },
  data() {
    return {
      LogoIcon,
      profilePath: router.profile.path,
      profileName: router.profile.name,
    };
  },
  computed: {
    isShowSelectLpFund() {
      return appStore.state.profile.lpFunds.length !== 1;
    },
    currentRouter() {
      return this.currentRouterProps || appStore.state.currentRoute;
    },
    fullName() {
      return `${this.profile.firstName} ${this.profile.lastName}`;
    },
    isAuth() {
      return appStore.state.auth;
    },
    profile() {
      return appStore.state.profile || {};
    },
    isMobile() {
      return appStore.state.mobile.isMobile;
    },
  },
  methods: {
    handleChangeFund() {
      appStore.dispatch(APP_ACTIONS.set_show_lp_funds_investor_popup, true);
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
    goBack() {
      window.history.go(-1);
    },
    toogleAdminSidebar() {
      appStore.dispatch(APP_ACTIONS.toggle_admin_sidebar);
    },
  },
});
