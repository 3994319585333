<template>
  <div class="newsletter">
    <div class="overlay" v-if="loading" v-loading="loading"></div>
    <div class="row no-gutters">
      <!-- <div
        class="col content-left"
        :style="`height: calc(100vh - ${height}px)`"
      >
        <div class="row no-gutters text-center">
          <div
            class="col-12"
            v-for="(item, idx) in months"
            :key="idx"
            @click="selectDate(item.newsletterId, item.docId)"
          >
            <div
              class="dateItem pointer"
              :class="{ active: activeId === item.newsletterId }"
            >
              {{ item.publishDate }}
            </div>
          </div>
        </div>
      </div> -->
      <div
        v-if="loading"
        class="col content-right border-left"
      >
        <p class="text-center my-2">Loading...</p>
      </div>

      <div
        v-else-if="content"
        class="col content-right border-left"
      >
        <div class="row no-gutters">
          <div class="col html" v-html="content" />
        </div>
      </div>

      <div
        v-if="!months && !loading && !content"
        class="col content-right border-left"
      >
        <h6 class="text-center my-2">No data yet</h6>
      </div>
    </div>
  </div>
</template>

<script>
import AdminServices from "@/services/admin";
import moment from "moment";
import { defineComponent } from "vue";
import appStore from "@/store/app";
import { LP_MEDIA_TYPES } from "@/misc/constants";

export default defineComponent({
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      loading: false,
      content: "",
      months: "",
      url: "",
      routeQuery: null,
      activeId: null,
    };
  },
  computed: {
    investorInfo() {
      return appStore.state.investorInfo;
    },
  },
  created() {
    this.initialLoading();
  },
  watch: {
    investorInfo: {
      deep: true,
      handler(v) {
        this.initialLoading();
      },
    },
  },
  methods: {
    selectDate(newsletterId, docId) {
      this.loading = true;
      AdminServices.getNewsletterByDocId(docId).then((resp) => {
        if (resp.data.ok) {
          this.activeId = newsletterId;
          this.content = "";
          var url = resp.data.d;
          var request = new XMLHttpRequest();
          request.open("GET", url, true);
          request.send(null);
          request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
              var type = request.getResponseHeader("Content-Type");
              if (type.indexOf("text") !== 1) {
                this.content = request.responseText;
              }
            }
            this.loading = false;
          };
        }
      });
    },
    initialLoading() {
      this.months = "";
      this.activeId = null;
      this.content = "";
      this.loading = true;
      const lpFundId = this.investorInfo
        ? this.investorInfo.selected_fund.lp_fund_id
        : null;
      AdminServices.getLPMediaItemsByTypeAndLpFundId(
        LP_MEDIA_TYPES.NEWSLETTER,
        lpFundId
      )
        .then((resp) => {
          if (resp.data.ok) {
            if (resp.data.d.length === 0) return;
            this.months = resp.data.d.map((x) => {
              const timestampInSeconds = x.data.PublishDate / 1000;
              const cvPublishDate = moment
                .unix(timestampInSeconds)
                .format("MMM YYYY");
              return {
                newsletterId: x.id,
                publishDate: cvPublishDate,
                docId: x.data.DocId,
              };
            });
            this.activeId = this.months[0].newsletterId;
            this.selectDate(this.months[0].newsletterId, this.months[0].docId);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.newsletter {
  width: 100%;
  color: #fff;
  // padding: 20px 0;
  .container {
    @media screen and (max-width: 1200px) {
      max-width: 100%;
    }
  }
}
.header-text {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 30px;
  font-size: 30px;
}
.content-left {
  overflow-y: auto;
  max-width: 215px;
  flex: 0 0 215px;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    height: unset !important;
  }
  > div {
    @media screen and (max-width: 768px) {
      flex-direction: row;
      white-space: nowrap;
      overflow-x: auto;
      display: block;
      /* width */
      &::-webkit-scrollbar {
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(#f1f1f1, 0.1);
        overflow: hidden;
        border-radius: 4px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        overflow: hidden;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    > div {
      // flex: 0 0 200px;
      // max-width: 200px;
      display: inline-block;
    }
  }
}
.content-right {
  overflow-y: auto;
  flex: 1;
  @media screen and (max-width: 768px) {
    height: unset !important;
  }
}
.dateItem {
  padding: 15px 5px;
  margin: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #555555;
  }
}
.html {
  background-color: #282828;
  margin: 1rem;
}
.active {
  background-color: #555555;
}

.border-bottom {
  border-bottom: 1px solid #333a64 !important;
}
.border-left {
  border-left: 1px solid #333a64 !important;
}
</style>
