import _ from "lodash";

export const navigation = {
    types: {
        Header: 'Header',
        Link: 'Link'
    },
    $className: {
        Header: 'nav-category',
        Link: ''
    },
    $build(type, name, path, extras) {
        return {
            className: this.$className[type],
            name,
            path,
            hide() {
                return false
            },
            ...extras
        }
    },
    registerHeader(name, extras) {
        return this.$build(this.types.Header, name, null, extras)
    },
    registerLink(route, extras) {
        return this.$build(this.types.Link, route.name, route.path, extras)
    },
    registerAction(name, action, extras) {
        return {
            ...this.$build(this.types.Link, name),
            action,
            ...extras
        }
    }
}

export const route = {
    register(path, name, component, meta = {}, props) {
        props = props || function (r) {
            const p = {}
            if (_.isObject(r.query)) {
                for (const k in r.query) {
                    p[k] = r.query[k]
                }
            }
            if (_.isObject(r.params)) {
                for (const k in r.params) {
                    p[k] = r.params[k]
                }
            }
            return p
        }
        return {
            path,
            name,
            component,
            meta,
            props: props
        }
    },
    buildConfig(object) {
        const res = []
        if (_.isObject(object)) {
            for (const k in object) {
                res.push(object[k])
            }
        }

    return res;
  },
  navigation: navigation,
};

export default route;
