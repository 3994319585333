<template>
  <div
    class="perspectives-tab-wrapper"
    :class="isTabMobile ? 'tabs-mobile' : ''"
  >
    <ul class="tabs">
      <router-link
        v-for="tab in tabs"
        :key="tab.id"
        class="tab-item"
        :class="isActive(tab.path) ? 'active' : ''"
        :to="tab.path"
        @click="changeView(tab.id)"
      >
        <li>
          <span>{{ tab.title }}</span>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  props: {
    isTabMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState({ perspectivesList: (state) => state.perspectivesList }),
  },
  mounted() {
    this.tabs = this.perspectivesList.map((v, i) => {
      return {
        id: i,
        title: v.name,
        path: v.path,
      };
    });
  },
  methods: {
    isActive(path) {
      return this.$route.path.includes(path);
    },
    changeView(v) {
      this.$emit("changeView", v);
      if (window.innerWidth < 768) return;
      const el = document.querySelector(`.fuel-the-mind-tab`);
      el.scrollIntoView({ behavior: "auto", top: 0, left: 0 });
    },
  },
});
</script>

<style lang="scss" scoped>
.perspectives-tab-wrapper {
  position: relative;

  @media (min-width: 768px) {
    position: absolute;
    top: 30%;
    left: 1%;
    /* width: 50px; */
    z-index: 100;
  }
}

ul.tabs {
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 10px 0;

  @media (min-width: 768px) {
    flex-direction: column;
  }

  list-style: none;

  .tab-item {
    color: #000;
    background: #fff;
    border: 1px solid #000;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;

    @media (min-width: 768px) {
      padding: 12px;
    }

    span {
      font-size: 12px;
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .active {
    color: #fff !important;
    background: #000 !important;
  }
}

.tabs-mobile {
  display: block;

  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  width: auto;
  padding: 12px 0;

  @media (min-width: 768px) {
    display: none;
  }

  position: relative;
  ul.tabs {
    flex-direction: row !important;
  }
}
</style>
