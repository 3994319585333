import { defineComponent } from 'vue'
import conf from '@/config'

export default defineComponent({
    data() {
        return {
            nda_form: conf.nda_form,
            scale: 'page-width'
        }
    }
})