import route from "@/misc/route";
import router from "@/config/router";
import authService, { UserInfo } from "@/services/auth";
import { createStore } from "vuex";
import conf from "@/config";
import _ from "lodash";
import tracking from "@/misc/tracking";
import generic from "@/misc/generic";
import PerspectivesService from "@/services/news/perspectives";
import { segment } from '@/services/segment'
// Vue.use(CKEditor); //#todo import ckeditor for vue3

export const APP_MUTATION = {
  changed_router: "changed_router",
  init_app_finished: "init_app_finished",
  check_bonus_available: "check_bonus_available",
  update_realtime_wallet: "update_realtime_wallet",
  update_realtime_wallet_unit: "update_realtime_wallet_unit",
  update_realtime_wallet_id: "update_realtime_wallet_id",
  start_realtime_wallet: "start_realtime_wallet",
  stop_realtime_wallet: "stop_realtime_wallet",
  init_token: "init_token",
  set_token: "set_token",
  //account
  update_profile: "update_profile",
  update_wallet_from_socket: "update_wallet_from_socket",
  update_turnover_from_socket: "update_turnover_from_socket",
  //mobile version
  client_version: "client_version",
  update_mobile_menu_show: "update_mobile_menu_show",
  update_current_scroll_height: "update_current_scroll_height",
  //language
  // change_language: "change_language",
  update_2fa: "update_2fa",
  full_width: "full_width",
  use_empty_layout: "use_empty_layout",
  set_previous_route: "set_previous_route",
  //profile status for promotion
  update_jwb: "update_jwb",
  update_secretKey: "update_secretKey",

  //ourTeam
  active_slide_our_team: "active_slide_our_team",
  set_active_portfolio_slide: "set_active_portfolio_slide",
  change_popup: "change_popup",
  update_member_info: "update_member_info",

  toggle_admin_sidebar: "toggle_admin_sidebar",
  set_active_perspectives_tab: "set_active_perspectives_tab",
  set_list_perspectives: "set_list_perspectives",

  // investor public
  set_show_lp_funds_investor_popup: "set_show_lp_funds_investor_popup",
  set_lp_investor_info: "set_lp_investor_info",
};

export const AUTH_MUTATION = {
  login_success: "login_success",
  register_success: "register_success",
  logout_success: "logout_success",
  permission_denied: "permission_denied",
  not_auth: "not_auth",
  lasted_route_not_auth: "lasted_route_not_auth",
  update_twoFaEnabled: "update_twoFaEnabled",
  update_twoFaLoginEnabled: "update_twoFaLoginEnabled",
  update_affiliate_info: "update_affiliate_info",
  update_package_static: "update_package_static",
  update_photo: "update_photo",
  set_token_cookie: "set_token_cookie",
  set_token: "set_token",
  set_refresh_token: "set_refresh_token",
  remove_token: "remove_token",
  remove_refresh_token: "remove_refresh_token",
};

export const AUTH_ACTIONS = {
  logout: "logout",
  login: "login",
  verify_login_2fa: "verify_login_2fa",
  register: "register",
  get_user_profile: "get_user_profile",
  get_user_wallet: "get_user_wallet",
  get_session_transfer: "get_session_transfer",
  process_transfer: "process_transfer",
  check_session: "check_session",
  update_package: "update_package",
  update_my_package: "update_my_package",
  update_send_receive_coin: "update_send_receive_coin",
  not_auth: "not_auth",
  get_affiliate_info: "get_affiliate_info",
  get_package_static: "get_package_static",
  refresh_token: "refresh_token",
};

export const APP_ACTIONS = {
  set_previous_route: "set_previous_route",
  // set_new_lang: "set_new_lang",
  toggle_admin_sidebar: "toggle_admin_sidebar",
  fetch_list_perspectives: "fetch_list_perspectives",

  // investor public
  set_show_lp_funds_investor_popup: "set_show_lp_funds_investor_popup",
  set_lp_investor_info: "set_lp_investor_info",
};

export const LOCAL_STORAGES = {
  ssid: "ssid",
  profile: "profile",
  wallet: "wallet",
  lang: "lang",
  investorInfo: "investorInfo",
};

export const COOKIE_STORAGES = {
  ssid: "ssid",
  profile: "profile",
  refreshToken: "refreshToken",
};

function hasRedirectExternal(externalUrl, profile) {
  if (externalUrl && /^http(s)?/.test(externalUrl)) {
    tracking.loginSuccess(profile);
    window.location.href = externalUrl;
    return true;
  }
  return false;
}

export const appStore = createStore({
  state() {
    return {
      auth: !conf.auth,
      currentLanguage: generic.getJsonLocalStorage(LOCAL_STORAGES.lang) || null,
      inited: false,
      lastedRouteNotAuth: "",
      mobile: {
        version: "",
        menu_show: false,
        isMobile: false,
      },
      profile: new UserInfo(),
      token: generic.getJsonLocalStorage(LOCAL_STORAGES.ssid) || null,
      secretKey: "",
      currentRoute: {},
      navigations: router ? [route.navigation.registerLink(router.home)] : null,
      enable2Fa: false,
      fullWidth: false,
      useEmptyLayout: false,
      previousRoute: "/",
      authRoutes: router
        ? [
            router.login.path,
            router.register.path,
            router.forgotPassword.path,
            router.verifyRegister.path,
            router.resendVerifyRegisterEmail.path,
            router.resetPassword.path,
          ]
        : [],
      currentScrollHeightMobile: null,
      activeSlideOurTeam: 0,
      activeSlidePortfolios: 0,
      isShowPopup: false,
      isHideHeader: false,
      isShowAdminSidebar: true,
      isActivePerspectives: null,
      perspectivesList: [],
      // investor public
      isShowLpFundsInvestorPopup: false,
      investorInfo:
        generic.getJsonLocalStorage(LOCAL_STORAGES.investorInfo) || null,
    };
  },
  mutations: {
    [APP_MUTATION.set_active_perspectives_tab]: (state, data) => {
      state.isActivePerspectives = data;
    },
    [APP_MUTATION.toggle_admin_sidebar]: (state) => {
      state.isShowAdminSidebar = !state.isShowAdminSidebar;
    },
    [APP_MUTATION.change_popup]: (state, data) => {
      state.isShowPopup = data;
    },
    [APP_MUTATION.set_active_portfolio_slide]: (state, data) => {
      state.activeSlidePortfolios = data;
    },
    [APP_MUTATION.active_slide_our_team]: (state, data) => {
      state.activeSlideOurTeam = data;
    },
    [APP_MUTATION.update_current_scroll_height]: (state, data) => {
      state.currentScrollHeightMobile = data;
    },
    [APP_MUTATION.update_2fa]: (state, data) => {
      state.enable2Fa = data;
    },
    [AUTH_MUTATION.update_twoFaEnabled]: (state, data) => {
      state.profile.twoFaEnabled = data;
    },
    [AUTH_MUTATION.update_twoFaLoginEnabled]: (state, data) => {
      state.profile.twoFaLoginEnabled = data;
    },
    [APP_MUTATION.update_profile]: (state, data) => {
      state.auth = true;
      state.profile = Object.assign(state.profile, data);
      if (!state.profile.birthDate) {
        state.profile.birthDate = "";
      }
    },
    [AUTH_MUTATION.update_photo]: (state, data) => {
      state.profile.photo = data;
    },
    [APP_MUTATION.set_previous_route]: (state, path) => {
      state.previousRoute = path;
    },
    [APP_MUTATION.full_width]: (state, data) => {
      state.fullWidth = data;
    },
    [APP_MUTATION.use_empty_layout]: (state, data) => {
      state.useEmptyLayout = data;
    },
    // [APP_MUTATION.change_language]: (state, data) => {
    //   state.currentLanguage = data;
    //   generic.setJsonLocalStorage(LOCAL_STORAGES.lang, data);
    //   i18n.locale = data.code;
    // },
    [AUTH_MUTATION.set_token]: (state, token) => {
      generic.setJsonLocalStorage(LOCAL_STORAGES.ssid, token);
      state.token = token;
      state.auth = true;
    },
    [APP_MUTATION.start_realtime_wallet]: (state) => {
      state.realtimeWalletAvaliable = true;
    },
    [APP_MUTATION.changed_router]: (state, current) => {
      state.currentRoute = current;
    },
    [APP_MUTATION.init_app_finished]: (state) => {
      state.inited = true;
    },
    [AUTH_MUTATION.login_success]: (state, token) => {
      if (token) {
        state.auth = true;
      }
    },
    [AUTH_MUTATION.lasted_route_not_auth]: (state, currentRoute) => {
      state.lastedRouteNotAuth = currentRoute;
    },
    [AUTH_MUTATION.register_success]: (state, profile) => {},
    [AUTH_MUTATION.not_auth]: (state, currentRoute) => {
      state.auth = false;
      state.profile = new UserInfo();
      state.token = null;
      state.investorInfo = null;
      generic.removeLocalStorage(LOCAL_STORAGES.profile);
      generic.removeLocalStorage(LOCAL_STORAGES.ssid);
      generic.removeLocalStorage(LOCAL_STORAGES.investorInfo);
      generic.removeCookieStorage(LOCAL_STORAGES.ssid);
    },
    [APP_MUTATION.update_mobile_menu_show]: (state, data) => {
      state.mobile.menu_show = data;
    },
    [APP_MUTATION.client_version]: (state, data) => {
      let isMobile = false;
      let arr = [
        "Android",
        "webOS",
        "iPhone",
        "iPad",
        "iPod",
        "BlackBerry",
        "IEMobile",
        "Opera Mini",
      ];
      arr.map((x) => {
        if (navigator.userAgent.includes(x)) {
          isMobile = true;
        }
      });
      if (window.innerWidth <= 1024) {
        isMobile = true;
      }
      state.mobile.isMobile = isMobile;
      state.mobile.version = data.version ? data.version : state.mobile.version;
      state.mobile.size = data.size ? data.size : state.mobile.size;
    },
    [APP_MUTATION.is_contact_page]: (state, data) => {
      state.contactPage = data;
    },
    [APP_MUTATION.update_jwb]: (state, data) => {
      state.profile.cfgs.jwb = data;
    },
    [APP_MUTATION.update_secretKey]: (state, data) => {
      state.secretKey = data;
    },
    [APP_MUTATION.set_list_perspectives]: (state, data) => {
      state.perspectivesList = data;
    },
    [APP_MUTATION.set_show_lp_funds_investor_popup]: (state, data) => {
      state.isShowLpFundsInvestorPopup = data;
    },
    [APP_MUTATION.set_lp_investor_info]: (state, data) => {
      generic.setJsonLocalStorage(LOCAL_STORAGES.investorInfo, data);
      state.investorInfo = data;
    },
  },
  actions: {
    [APP_ACTIONS.toggle_admin_sidebar]: (context) => {
      context.commit(APP_MUTATION.toggle_admin_sidebar);
    },
    [APP_ACTIONS.set_previous_route]: (context, data) => {
      if (data) {
        if (!context.state.authRoutes.includes(data.path) && !data.meta.auth) {
          context.commit(APP_MUTATION.set_previous_route, data);
        }
      }
    },
    // ROBOT webkit doesnt support async await
    // async [APP_ACTIONS.set_new_lang](context, data) {
    // [APP_ACTIONS.set_new_lang]: (context, data) => {
    //   if (data.code in i18n.messages) {
    //     context.commit(APP_MUTATION.change_language, data);
    //   } else {
    //     try {
    //       // await import
    //       import(`../config/lang/${data.code}.json`).then((res) => {
    //         i18n.setLocaleMessage;
    //         i18n.setLocale(data.code, res);
    //         context.commit(APP_MUTATION.change_language, data);
    //       });
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
    // },
    [AUTH_ACTIONS.not_auth]: (context, currentRoute) => {
      if (currentRoute && currentRoute.meta && currentRoute.meta.auth) {
        context.commit(AUTH_MUTATION.lasted_route_not_auth, currentRoute);
      }
      context.commit(AUTH_MUTATION.not_auth);
    },
    [AUTH_ACTIONS.logout]: (context) => {
      if (segment.enabled) {
        segment.analytics.track("User Logout", {})
        segment.analytics.reset()
      }
      context.dispatch(AUTH_ACTIONS.not_auth);
    },
    [AUTH_ACTIONS.login]: (context, payload = {}) => {
      const {
        clientId,
        grant_type,
        userType,
        email,
        password,
        refresh_token,
        redirectExternal,
      } = _.isObject(payload) ? payload : {};
      if (grant_type === 'password') {
        if (segment.enabled) {
          segment.analytics.track('User login', {
            clientId,
            userType,
            email
          })
        }
      } else {
        if (segment.enabled) {
          segment.analytics.track('Extend user session', {
            clientId,
            userType,
            userId: appStore.state.profile.userId || undefined,
            refresh_token
          })
        }
      }
      
      return authService
        .login(clientId, grant_type, userType, email, password, refresh_token)
        .then((resp) => {
          if (resp.error) {
            context.dispatch(AUTH_ACTIONS.not_auth);
            if (segment.enabled) {
              segment.analytics.track(grant_type === 'password' ? 'User login failed' : 'Extend user session failed', {
                error: resp.error
              })
            }
          } else {
            let token = resp.data.d;
            context.commit(AUTH_MUTATION.set_token, {
              ...token,
              user_type: userType,
            });
            if (grant_type === 'password') {
              if (segment.enabled) {
                segment.analytics.track('User logged in', {
                  clientId,
                  userType: userType === 'Rokker' ? 'FVC' : userType,
                  email
                })
              }
            } else {
              if (segment.enabled) {
                segment.analytics.track('User session extended', {
                  clientId,
                  userType: userType === 'Rokker' ? 'FVC' : userType,
                  userId: appStore.state.profile.userId || undefined
                })
              }
            }
            context.dispatch(AUTH_ACTIONS.get_user_profile).then((resp) => {
              if (refresh_token === "") {
                // global.vueRouter.push(redirectExternal)
                if (resp.data.signNDA || !resp.data.ndaSignedRequired) {
                  global.vueRouter.push(redirectExternal);
                } else {
                  global.vueRouter.push(router.investorsignnda.path);
                }
              }
            });
          }
          return resp;
        })
        .catch((error) => {
          context.dispatch(AUTH_ACTIONS.not_auth);
        });
    },
    [AUTH_ACTIONS.verify_login_2fa]: (context, payload) => {
      const { code, validateCode, redirectExternal } = _.isObject(payload)
        ? payload
        : {};
      return authService.verifyLoginWith2Fa(validateCode, code).then((resp) => {
        if (resp.error) {
        } else if (!hasRedirectExternal(redirectExternal, resp.data)) {
          context.commit(APP_MUTATION.update_secretKey, resp.data.secret);
          context.commit(AUTH_MUTATION.set_token_cookie, resp.data.token);
        }
        return resp;
      });
    },
    [AUTH_ACTIONS.register]: (context, payload = {}) => {
      const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        nickname,
        recaptcha,
        referrerCode,
        refSource,
      } = _.isObject(payload) ? payload : {};

      return authService
        .register(
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          nickname,
          recaptcha,
          referrerCode,
          refSource
        )
        .then((resp) => {
          if (!resp.error) {
            context.commit(AUTH_MUTATION.register_success, resp.data);
          }
          return resp;
        });
    },
    [AUTH_ACTIONS.get_user_profile]: (context) => {
      const token = generic.getJsonLocalStorage(LOCAL_STORAGES.ssid);
      if (token) {
        return authService.getProfile().then((resp) => {
          if (!resp.error) {
            if (segment.enabled) {
              segment.analytics.identify(resp.data.userId.toString(), {
                name: `${resp.data.firstName} ${resp.data.lastName}`,
                email: resp.data.email,
                userType: resp.data.userType === 'Rokker' ? 'FVC' : resp.data.userType
              })
            }
            context.commit(APP_MUTATION.update_profile, resp.data);
          } else {
            context.dispatch(AUTH_ACTIONS.not_auth);
          }
          return resp;
        });
      } else {
        return new Promise((resolve, reject) => {
          context.dispatch(AUTH_ACTIONS.not_auth);
          resolve();
        });
      }
    },
    [APP_ACTIONS.fetch_list_perspectives]: async ({ commit }) => {
      try {
        const result = await PerspectivesService.getAll();
        if (!result.data.ok) return false;

        const convertToSlug = (Text) => {
          return Text.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
        };

        const cvData = result.data.d.map((v) => {
          const extra_info = v.extra_info.map((x) => {
            const pdf = JSON.parse(x.pdf_file);
            const pdfFileName = pdf.s3_key
              .replace(/^.*[\\\/]/, "")
              .split(".")[0];
            const thumb = JSON.parse(x.thumbnail);
            return {
              ...x,
              pdf_file: pdf.s3_key,
              thumbnail: thumb.s3_key,
              pdfFileName,
            };
          });
          return {
            ...v,
            extra_info,
            path: convertToSlug(v.name),
          };
        });
        commit(APP_MUTATION.set_list_perspectives, cvData);
      } catch (e) {
        return false;
      } finally {
        return true;
      }
    },

    [APP_ACTIONS.set_show_lp_funds_investor_popup]: ({ commit }, payload) => {
      commit(APP_MUTATION.set_show_lp_funds_investor_popup, payload);
    },
    [APP_ACTIONS.set_lp_investor_info]: ({ commit }, payload) => {
      commit(APP_MUTATION.set_lp_investor_info, payload);
      commit(APP_MUTATION.set_show_lp_funds_investor_popup, false);
    },
  },
});

export default appStore;
