import router from "@/config/router";
import AdminServices from "@/services/admin";
import _ from "lodash";
import conf from "../../../../config";
import appStore from "../../../../store/app";
import { defineComponent } from "vue";
import logo from "@/components/commons/logo";

export default defineComponent({
  name: "DetailSurvey",
  props: [
    "surveyDetailID",
    "hideSurveyDetail",
    "approvedSurvey",
    "rejectSurvey",
  ],
  components: {
    logo,
  },
  data() {
    return {
      homePath: router.home.path,
      loading: false,
      idDetail: null,
      info: {
        title: "",
        section: null,
        founder: null,
      },
      page: {
        page: 1,
        total: 0,
        size: 1,
      },
    };
  },
  mounted() {
    if (this.surveyDetailID) {
      this.getSurveyReviewDetails(this.surveyDetailID);
    }
  },
  methods: {
    getSurveyReviewDetails(id) {
      this.loading = true;
      AdminServices.getSurveyReviewDetails(id).then((resp) => {
        if (resp.data.ok) {
          this.info.title = resp.data.d.name;
          this.info.section = resp.data.d.sections;
          this.info.founder = resp.data.d.username;
          this.page.total = this.info.section.length;
        }
        this.loading = false;
      });
    },
    loadPage(value) {
      this.page.page = value;
    },
    downloadFile(key) {
      this.loading = true;
      AdminServices.requestDownloads3(key).then((resp) => {
        if (resp.data.ok) {
          window.open(resp.data.d, "_blank");
        }
        this.loading = false;
      });
    },
    checkInputValue(a, b) {
      let value = null;
      let status = false;
      a.map((p) => {
        if (p.Id === b.Id) {
          value = p.Answer;
          status = true;
        }
      });
      return { status: status, value: value };
    },
  },
  computed: {
    sumScored: {
      default: 0,
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let score = 0;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let child = list[i].questions;
            for (let j = 0; j < child.length; j++) {
              score += child[j].score;
            }
          }
        }
        return score;
      },
    },
    profile: function () {
      return appStore.state.profile;
    },
  },
});
