<template>
    <Card>
        <div class="drop-zone" @click="dropZoneClick" @drop.prevent="onDrop" @dragenter.prevent @dragover.prevent>
            <span class="drop-zone__prompt">Drag and drop or browse you files</span>
            <input
                draggable="true"
                type="file"
                name="uploadfile"
                id="uploadfile"
                multiple
                class="drop-zone__input"
                @change="inputChange"
                @click="onclick"
                :accept="accept"
                ref="dropZoneInput"
            />
        </div>
    </Card>
</template>

<script>
import { defineComponent } from "vue";
import Card from "./Card.vue";

export default defineComponent({
    name: 'DropZone',
    props: {
        accept: {type: String, default: 'image/*'}
    },
    emits: ['change'],
    components: {
        Card
    },
    methods: {
        dropZoneClick() {
            this.$refs.dropZoneInput.click()
        },
        inputChange(event) {
            this.$emit('change', event.target.files)
        },
        onclick (event){
            event.target.value = ''
        },
        onDrop(event) {
            this.$emit('change', event.dataTransfer.files)
        }
    }
})
</script>

<style lang="scss" scoped>
.drop-zone--over {
    border-style: solid;
}
.drop-zone__input {
    display: none;
}
.drop-zone__thumb {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    position: relative;
}
.drop-zone__thumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    text-align: center;
}
</style>