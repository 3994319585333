import appStore, { APP_ACTIONS } from '@/store/app'
import { mapState } from 'vuex'
import AdminServices from '@/services/admin'
import notify from '@/services/notify'
import treeItem from '@/components/commons/treeItem'
import filePreview, { FileTypes } from '@/components/commons/filePreview'
import downloadItem from '@/components/commons/downloadItem'
import uploadFile from './uploadFile'
import manageVersions from './manageVersions'
import { router, USER_TYPE } from '@/config/router'
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        treeItem,
        uploadFile,
        manageVersions,
        filePreview,
        downloadItem
    },
    data() {
        return {
            loading: true,
            selectedFolder: null,
            selectedChildrenItems: [],
            menuOptions: [],
            parentPath: [],
            rawTree: [],
            selectedParentFolderId: null,
            selectedItem: null,
            isShowUploadFile: false,
            modalTitle: '',
            modalMessage: '',
            delay: 300,
            clicks: 0,
            timer: null,
            fileToken: null,
            previewFileType: '',
            isShowManageVersions: false,
            allowViewFileExtensions: ['.pdf', '.mp4', '.gif', '.jpg', '.jpeg', '.png'],
            rootDataroomItem: {
                id: 0,
                name: 'Shared with me',
                type: 'Folder'
            },
            contextMenuComponentKey: 0,
            showDropdownMenu: false,
            isShowDownloadItem: false,
            dealFounderPath: router.dealFounder.path,
            dealFounderName: router.dealFounder.name,
        }
    },
    mounted() {
        if (this.profile.userType === USER_TYPE.FOUNDER_POTENTIAL) {
            this.$router.push({path: this.dealFounderPath})
        }
    },
    computed: {
        treeData() {
            return this.unflatten(this.rawTree)
        },
        selectedFolderId() {
            return this.selectedFolder ? this.selectedFolder.id : null
        },
        selectedParentId() {
            return this.selectedFolder ? this.selectedFolder.parent_id : null
        },
        ...mapState({
            authenticatedUser: state => state.profile
        }),
        profile: function() {
            return appStore.state.profile;
        },
    },
    created() {
        if (appStore.state.isShowAdminSidebar) {
            appStore.dispatch(APP_ACTIONS.toggle_admin_sidebar)
        }
        this.initialLoading()
    },
    beforeUnmount() {
        if (!appStore.state.isShowAdminSidebar) {
            appStore.dispatch(APP_ACTIONS.toggle_admin_sidebar)
        }
    },
    methods: {
        initialLoading() {
            AdminServices.getSharedDataroomItemsByAuthSharedUser().then(resp => {
                if (resp.data.ok) {
                    this.rawTree = resp.data.d
                    this.selectFolder(this.rootDataroomItem)
                }
            }).finally(() => this.loading = false)
        },
        refreshData() {
            AdminServices.getSharedDataroomItemsByAuthSharedUser().then(resp => {
                if (resp.data.ok) {
                    this.rawTree = resp.data.d
                    this.selectFolder(this.selectedFolder)
                }
            }).finally(() => this.loading = false)
        },

        selectFolder(item) {
            this.selectedFolder = item
            this.selectedChildrenItems = this.rawTree.filter(x => x.parent_id === item.id)
            this.parentPath = this.findAncestors(this.selectedFolder, this.rawTree)
        },
        unflatten(rawArr) {
            let arr = rawArr.filter(x => x.type === 'Folder')
            let tree = [],
                mappedArr = {},
                arrElem,
                mappedElem;
        
            // First map the nodes of the array to an object -> create a hash table.
            for(let i = 0; i < arr.length; i++) {
                arrElem = arr[i]
                mappedArr[arrElem.id] = arrElem
                mappedArr[arrElem.id]['children'] = []
            }
        
            for (let id in mappedArr) {
                if (mappedArr.hasOwnProperty(id)) {
                    mappedElem = mappedArr[id]
                    // If the element is not at the root level, add it to its parent array of children.
                    if (mappedElem.parent_id) {
                        mappedArr[mappedElem['parent_id']]['children'].push(mappedElem)
                    }
                    // If the element is at the root level, add it to first level elements array.
                    else {
                        tree.push(mappedElem)
                    }
                }
            }
            return {
                ...this.rootDataroomItem,
                children: tree
            }
        },

        countDescendantFiles(item) {
            if (item && item.type === 'Folder') {
                let descendants = [item.id]
                while (true) {
                    let _subDescendants = this.rawTree.filter(x => descendants.includes(x.parent_id) && !descendants.includes(x.id))
                    if (_subDescendants.length > 0) {
                        descendants.push(..._subDescendants.map(x => x.id))
                    } else {
                        break
                    }
                }
                return this.rawTree.filter(x => descendants.includes(x.id) && ['File', 'Bookmark'].includes(x.type)).length
            } else {
                return 0
            }
        },

        findAncestors(item, rawArr) {
            if (item.id === 0) {
                return [this.rootDataroomItem]
            }
            let ancestors = []
            let parentId = item.parent_id
            ancestors.unshift(item)
            while(!!parentId) {
                ancestors.unshift(rawArr.find(x => x.id === parentId))
                let _next_item = rawArr.find(x => x.id === parentId)
                parentId = _next_item ? _next_item.parent_id : undefined
            }
            ancestors.unshift(this.rootDataroomItem)
            return ancestors
        },

        handleClick(event, item) {
            if (item.type === 'Folder') {
                if (item.id === 0) {
                    this.menuOptions = []
                } else {
                    this.menuOptions = [
                        { name: 'Upload file' }
                    ]
                }
            } else if (item.type === 'File') {
                const _fileItem = this.rawTree.find(x => x.id === item.id)
                if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
                    this.menuOptions = [
                        { name: 'View' },
                        { name: 'Download' }
                    ]
                } else {
                    this.menuOptions = [
                        { name: 'Download' }
                    ]
                }
                if (this.authenticatedUser && this.authenticatedUser.userId === _fileItem.created_user_id) {
                    this.menuOptions.push({ name: 'Manage versions' })
                }
            } else if (item.type === 'Bookmark') {
                this.menuOptions = [
                    //{ name: 'Edit bookmark' },
                    { name: 'View' }
                ]
            }
            this.contextMenuComponentKey++
            this.$nextTick(() => {
                this.$refs.dataRoomMenu.showMenu(event, {id: item.id, type: item.type})
            })
        },
        optionClicked(ev) {
            let { item, option } = ev
            if (option.name === 'Upload file' && item.type === 'Folder') {
                this.openDialogToUploadFile(item.id)
            } else if (option.name === 'Download' && item.type === 'File') {
                this.downloadFile(item)
            } else if (option.name === 'View' && item.type === 'File') {
                this.viewFile(item)
            } else if (option.name === 'Manage versions' && item.type === 'File') {
                this.openDialogToManageVersions(item.id)
            } else if (option.name === 'View'  && item.type === 'Bookmark') {
                this.openBookmarkUrl(item)
            }
        },

        openDialogToUploadFile(parentId) {
            this.selectedParentFolderId = parentId
            this.isShowUploadFile = true
        },
        hideUploadFile() {
            this.isShowUploadFile = false
            this.selectedParentFolderId = null
            this.loading = true
            this.refreshData()
        },
        handleUploadFileComplete() {
            this.hideUploadFile()
            notify.success('Upload file success!')
            this.loading = true
            this.refreshData()
        },

        openDialogToManageVersions(id) {
            this.selectedItem = this.rawTree.find(x => x.id === id)
            if (this.authenticatedUser && this.authenticatedUser.userId === this.selectedItem.created_user_id) {
                this.isShowManageVersions = true
            }
        },
        hideManageVersions() {
            this.isShowManageVersions = false
            this.selectedItem = null
            this.loading = true
            this.refreshData()
        },

        oneClick(item){
            this.clicks++ 
            if (this.clicks === 1) {
              let self = this
              this.timer = setTimeout(() => {
                self.clicks = 0
              }, this.delay)
            } else {
               clearTimeout(this.timer)
               if (item.type === 'Folder') {
                this.selectFolder(item)
               } else if (item.type === 'File') {
                this.processPreviewFile(item)
               } else if (item.type === 'Bookmark') {
                this.openBookmarkUrl(item)
               }
               this.clicks = 0
            }        	
        },

        processPreviewFile(item) {
            const _fileItem = this.rawTree.find(x => x.id === item.id)
            let _action = 'Download'
            if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
                _action = 'View'
            }
            this.loading = true
            AdminServices.requestDownloadCIO(item.id, _action).then(resp => {
                if (resp.data.ok) {
                    this.fileToken = resp.data.d
                    if (_fileItem.current_extension === '.pdf') {
                        this.showFilePreview(FileTypes.PDF)
                    } else if (_fileItem.current_extension === '.mp4') {
                        this.showFilePreview(FileTypes.VIDEO)
                    } else if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
                        this.showFilePreview(FileTypes.IMAGE)
                    } else {
                        this.showDownloadItem()
                    }
                }
            }).finally(() => { this.loading = false })
        },

        downloadFile(item) {
            if (item.type === 'File') {
                this.loading = true
                AdminServices.requestDownloadCIO(item.id, 'Download').then(resp => {
                    if (resp.data.ok) {
                        window.open(resp.data.d, '_blank');
                    }
                }).finally(() => {this.loading = false})
            }
        },

        viewFile(item) {
            const _fileItem = this.rawTree.find(x => x.id === item.id)
            if (this.allowViewFileExtensions.includes(_fileItem.current_extension)) {
                this.loading = true
                AdminServices.requestDownloadCIO(item.id, 'View').then(resp => {
                    if (resp.data.ok) {
                        this.fileToken = resp.data.d
                        if (_fileItem.current_extension === '.pdf') {
                            this.showFilePreview(FileTypes.PDF)
                        } else if (_fileItem.current_extension === '.mp4') {
                            this.showFilePreview(FileTypes.VIDEO)
                        } else {
                            this.showFilePreview(FileTypes.IMAGE)
                        }
                    }
                }).finally(() => {this.loading = false})
            }
        },

        showDownloadItem() {
            this.isShowDownloadItem = true
        },
        hideDownloadItem() {
            this.isShowDownloadItem = false
            this.fileToken = null
        },
        
        showFilePreview(previewFileType) { 
            this.fixedBody()
            this.previewFileType = previewFileType
            this.$refs.filePreview.showModal()
        },

        openBookmarkUrl(item) {
            this.loading = true
            AdminServices.requestDownloadCIO(item.id, 'View').then(resp => {
                if (resp.data.ok) {
                    window.open(resp.data.d, '_blank')
                }
            }).finally(() => { this.loading = false })
        },

        countView(item) {
            if (['File', 'Bookmark'].includes(item.type)) {
                return item.view_count_by_user_type.reduce((acc, val) => {
                    return acc + val.view_count
                }, 0)
            } else {
                return 0
            }
        }
    }
})