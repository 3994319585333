import AdminServices from "@/services/admin";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import { defineComponent } from "vue";

export default defineComponent({
  name: "uploadFileV2",
  props: ["parentId", "hideUploadFile"],
  data() {
    return {
      formData: {
        parentId: null,
      },
      loading: false,
      files: [],
      existingFilesInFolder: [],
      specialCharacterMsg: 'Name cannot contain characters * : " \\ | < > / ?',
      uploadKey: 0,
    };
  },
  computed: {
    numberFilesToupload() {
      let _files = this.files.filter(
        (x) =>
          !x.isUploaded &&
          x.isNameValid &&
          (!x.isDuplicated ||
            (x.isDuplicated && ["UPGRADE"].includes(x.duplicationMode)))
      );
      return _files.length;
    },
  },
  mounted() {
    if (this.parentId !== null && this.parentId !== undefined) {
      this.formData.parentId = this.parentId;
      this.fetchFilesInFolder();
    }
  },
  methods: {
    fetchFilesInFolder() {
      this.loading = true;
      AdminServices.getDataroomItemFilesByParentId(this.parentId)
        .then((res) => {
          if (!res.error) {
            this.existingFilesInFolder = res.data.d;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isFileNameInFolder(fileName) {
      const _index = this.existingFilesInFolder.findIndex(
        (x) => x.name === fileName
      );
      return _index > -1 ? true : false;
    },
    validateForm() {
      this.loading = true;
      this.validateFilesBeforeUploading()
        .then((res) => {
          if (res) {
            this.uploadFiles().then(() => {
              this.fetchFilesInFolder();
              let _itemsNotUploaded = this.files.filter((x) => !x.isUploaded);
              if (!_itemsNotUploaded || _itemsNotUploaded.length === 0) {
                this.hideUploadFile();
              }
            });
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    validateFilesBeforeUploading() {
      return new Promise((resolve, reject) => {
        let reqArr = [];
        this.files.forEach((x) => {
          if (
            !x.isUploaded &&
            x.isNameValid &&
            (!x.isDuplicated ||
              (x.isDuplicated && ["UPGRADE"].includes(x.duplicationMode)))
          ) {
            let lastDot = x.file.name.lastIndexOf(".");
            let _data = {
              parentId: this.formData.parentId,
              duplicationMode: x.duplicationMode,
              fileName: x.file.name.substring(0, lastDot),
              fileExtension: x.file.name.substring(lastDot),
            };
            x.isUploading = true;
            reqArr.push(AdminServices.validateDataroomFile(_data));
          } else {
            reqArr.push(null);
          }
        });

        Promise.all(reqArr)
          .then((res) => {
            res.forEach((resItem, index) => {
              if (resItem) {
                if (resItem.data.ok) {
                  this.files[index].isDuplicated = false;
                  this.files[index].otherErrorMsg = "";
                } else {
                  if (resItem.data.c === "file_name_existed") {
                    this.files[index].isDuplicated = true;
                    this.files[index].otherErrorMsg = "";
                  } else if (resItem.data.m) {
                    this.files[index].otherErrorMsg = resItem.data.m;
                  } else {
                    this.files[index].otherErrorMsg =
                      "Oops! An error has occurred. Please retry.";
                  }
                }
                this.files[index].isUploading = false;
              }
            });
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    uploadFiles() {
      return new Promise((resolve, reject) => {
        AdminServices.getSessionToken().then((r) => {
          AWS.config.update({
            region: r.data.d.region,
            credentials: new AWS.Credentials(
              r.data.d.access_key,
              r.data.d.secret_key,
              r.data.d.session_token
            ),
          });
          let reqArr = [],
            uploadArr = [],
            uploadData = [];

          this.files.forEach((x) => {
            if (
              !x.isUploaded &&
              x.isNameValid &&
              (!x.isDuplicated ||
                (x.isDuplicated && ["UPGRADE"].includes(x.duplicationMode)))
            ) {
              x.isUploading = true;
              let lastDot = x.file.name.lastIndexOf(".");
              let uploadParam = {
                Bucket: r.data.d.bucket,
                Key: this.getRandomKey(12) + x.file.name.substring(lastDot),
                Body: x.file,
              };
              if (x.file.name.endsWith("html")) {
                uploadParam.ContentType = "text/html";
              }

              let upload = new S3.ManagedUpload({
                params: uploadParam,
              });
              upload.on("httpUploadProgress", (progress) => {
                if (progress.total) {
                  x.progress = Math.round(
                    (100 * progress.loaded) / progress.total
                  );
                }
              });

              uploadArr.push(upload.promise());
              uploadData.push({
                parentId: this.formData.parentId,
                duplicationMode: x.duplicationMode,
                fileName: x.file.name.substring(0, lastDot),
                fileExtension: x.file.name.substring(lastDot),
                fileSize: x.file.size,
                isUploadFolder: false,
              });
            } else {
              uploadArr.push(null);
              uploadData.push(null);
            }
          });

          Promise.all(uploadArr).then((awsArrRes) => {
            awsArrRes.forEach((_awsRes, index) => {
              if (_awsRes && uploadData[index]) {
                uploadData[index].fileS3Url = _awsRes.Key;
                reqArr.push(
                  AdminServices.createDataroomFileV2(uploadData[index])
                );
              } else {
                reqArr.push(null);
              }
            });
            Promise.all(reqArr)
              .then((res) => {
                res.forEach((resItem, index) => {
                  if (resItem) {
                    if (resItem.data.ok) {
                      this.files[index].isUploaded = true;
                      this.files[index].isDuplicated = false;
                      this.files[index].otherErrorMsg = "";
                    } else {
                      if (resItem.data.c === "file_name_existed") {
                        this.files[index].isDuplicated = true;
                        this.files[index].otherErrorMsg = "";
                      } else if (resItem.data.m) {
                        this.files[index].otherErrorMsg = resItem.data.m;
                      } else {
                        this.files[index].otherErrorMsg =
                          "Oops! An error has occurred. Please retry.";
                      }
                    }
                    this.files[index].isUploading = false;
                    this.files[index].progress = 0;
                  }
                });
                resolve(true);
              })
              .catch((err) => {
                console.log(err);
                reject(false);
              });
          });
        });
      });
    },

    addFiles() {
      this.$refs.files.click();
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$nextTick(() => {
        this.uploadKey += 1;
      });
    },
    handleFilesUpload() {
      this.loading = true;
      let validFiles = [];
      for (const _file of this.$refs.files.files) {
        if (_file.name.lastIndexOf(".") > 0) {
          validFiles.push(_file);
        }
      }
      let uploadedFiles = validFiles;
      let fileNameArr = this.files.map((x) => {
        return x.file && !x.isUploaded ? x.file.name : "";
      });
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (!fileNameArr.includes(uploadedFiles[i].name)) {
          this.files.push({
            id: i,
            file: uploadedFiles[i],
            isDuplicated: false,
            duplicationMode: "",
            isUploaded: false,
            isUploading: false,
            progress: 0,
            otherErrorMsg: "",
            isNameValid: this.validateFileAndDirectoryName(
              uploadedFiles[i].name
            ),
          });
        }
      }
      this.$refs.files.value = "";
      this.loading = false;
    },
  },
  beforeUnmount() {
    $(".custom-modal .modal-dialog").css({ transform: "", top: "" });
  },
});
