<template>
  <div class="adminContent p-3">
    <Table
      :columns="columns"
      :rows="rows"
      @orderChanged="orderChanged"
      v-loading="loading"
      useDrag
      :hasScrollY="false"
    >
      <template #head_title>
        <TextField
          v-model:value="filteredTitle"
          placeholder="Type to search .."
          hideError
        />
      </template>

      <template #row_title="{ props }">
        {{ props.title }}
      </template>
      <template #head_member_name>
        <SelectField
          v-model:value="filteredMember"
          :options="memberList"
          hideError
        />
      </template>
      <template #head_is_active>
        <SelectField
          v-model:value="filteredStatus"
          :options="statusList"
          hideError
        />
      </template>
      <template #row_is_active="{ props }">
        {{ $filters.getName(props.is_active, statusList) }}
      </template>
      <template #row_created_at="{ props }">
        {{ $filters.parseEstTime(props.created_at) }} <br />
        {{ props.created_by }}<br />
      </template>

      <template #row_actions="{ props }">
        <Popper>
          <button class="btn btn-primary custom-menu-dropdown" type="button">
            <i class="fas fa-ellipsis-h"></i>
          </button>
          <template #content="{ close }">
            <div class="content-dropdown-popper" @click="close">
              <div
                class="customer-el pointer mt-2"
                @click="editItem(props)"
                title="Edit"
              >
                <i class="fas fa-comment-alt me-1"></i>Edit
              </div>
              <div
                class="customer-el pointer mt-2"
                @click="updateStatus(props)"
                :title="props.is_active ? 'Activate' : 'Inactivate'"
              >
                <template v-if="props.is_active">
                  <i class="fas fa-lock me-1"></i>Inactivate
                </template>
                <template v-else>
                  <i class="fas fa-lock-open me-1"></i>Activate
                </template>
              </div>
              <div class="customer-el pointer mt-2" @click="deleteItem(props)">
                <i class="far fa-trash-alt me-1"></i>Delete
              </div>
            </div>
          </template>
        </Popper>
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="addItem">
          <i class="fas fa-plus me-1"></i>
          <span>Add Perspective</span>
        </button>
      </template>
    </Table>
    <addPerspective ref="itemModal" :key="itemModalKey" @refresh="getAll" />
    <Dialog ref="dialog" />
  </div>
</template>

<script src="./index.js"></script>
