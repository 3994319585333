import _ from "lodash";
import AdminServices from "@/services/admin";
import publicFileDataroom from "@/views/admin/dataroom/publicFileDataroom";
import { LP_MEDIA_TYPES } from "../../../../../misc/constants";
import { defineComponent } from "vue";
import TextField from "@/components/TextField";
import customSelect from "@/components/commons/customSelect";

export default defineComponent({
  components: {
    publicFileDataroom,
    TextField,
    customSelect,
  },
  props: {
    id: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      type: LP_MEDIA_TYPES.INVESTOR_CALL_REPLAY,
      loading: false,
      formType: "",
      item: null,
      defaultItem: {
        name: "",
        active: true,
        type: LP_MEDIA_TYPES.INVESTOR_CALL_REPLAY,
        data: {
          DocTitle: "",
          PhotoId: null,
          VideoId: null,
          PdfId: null,
        },
        photoName: "",
        videoName: "",
        pdfName: "",
        lp_fund_ids: [],
      },
      error: {
        name: false,
        docTitle: false,
        photo: false,
        video: false,
        pdf: false,
        lp_fund_ids: false,
      },
      selectedMediaType: null,
      isShowPublicDataroom: false,
      lpFundOptions: [],
    };
  },
  watch: {
    "item.name": function (v) {
      this.error.name = false;
    },
    "item.data.DocTitle": function (v) {
      this.error.docTitle = false;
    },
    "item.data.PhotoId": function (v) {
      this.error.photo = false;
    },
    "item.data.VideoId": function (v) {
      this.error.video = false;
    },
    "item.data.PdfId": function (v) {
      this.error.pdf = false;
    },
    "item.lp_fund_ids": function (v) {
      this.error.lp_fund_ids = false;
    },
  },
  created() {
    if (this.id) {
      this.formType = "Edit";
      this.getItem(this.id);
    } else {
      this.formType = "Add";
      this.item = _.cloneDeep(this.defaultItem);
    }
    this.getAllLpFunds();
  },
  methods: {
    async getAllLpFunds() {
      const res = await AdminServices.getAllLpFunds();
      if (res.error) return;

      if (res.data.ok) {
        const temp = res.data.d.map((x) => ({
          value: x.lpfund_id,
          text: x.is_enabled ? x.name : `${x.name} (Inactive)`,
        }));
        this.lpFundOptions = temp || [];
      }
    },
    getItem(id) {
      this.loading = true;
      AdminServices.getLPMediaItem(id)
        .then((res) => {
          if (res.data.ok) {
            const temp = {
              ...res.data.d,
              lp_fund_ids: res.data.d.lp_funds.map((x) => Number(x.lp_fund_id)),
            };
            this.item = temp;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    save() {
      let flag = true;
      if ($.trim(this.item.name) === "") {
        this.error.name = true;
        flag = false;
      }
      if ($.trim(this.item.data.DocTitle) === "") {
        this.error.docTitle = true;
        flag = false;
      }
      if (!this.item.data.PhotoId) {
        this.error.photo = true;
        flag = false;
      }
      if (!this.item.data.VideoId) {
        this.error.video = true;
        flag = false;
      }
      if (!this.item.data.PdfId) {
        this.error.pdf = true;
        flag = false;
      }
      if (this.item.lp_fund_ids.length === 0) {
        this.error.lp_fund_ids = true;
        flag = false;
      }

      if (flag) {
        if (this.formType === "Edit") {
          this.updateItem();
        } else {
          this.createItem();
        }
      }
    },

    createItem() {
      this.loading = true;
      AdminServices.createLPCallReplay(this.item)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("completed", {
              id: resp.data.d,
              formType: this.formType,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateItem() {
      this.loading = true;
      AdminServices.updateLPCallReplay(this.item)
        .then((resp) => {
          if (resp.data.ok) {
            this.$emit("completed", {
              id: this.item.id,
              formType: this.formType,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectDataroomItem(type) {
      this.selectedMediaType = type;
      this.$nextTick(() => {
        this.isShowPublicDataroom = true;
      });
    },

    closeSearchDataroomItem() {
      this.isShowPublicDataroom = false;
      this.selectedMediaType = null;
    },

    completeSelectDataroomItem(data) {
      const { dataroomId, type, name } = data;
      this.closeSearchDataroomItem();
      switch (type) {
        case "PHOTO":
          this.item.data.PhotoId = dataroomId;
          this.item.photoName = name;
          break;
        case "VIDEO":
          this.item.data.VideoId = dataroomId;
          this.item.videoName = name;
          break;
        case "PDF":
          this.item.data.PdfId = dataroomId;
          this.item.pdfName = name;
          break;
      }
    },
  },
});
