import appStore, { AUTH_ACTIONS } from "../../../store/app";
import notify from "../../../services/notify";
import router from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loading: false,
      code: "",
      validateCode: "",
      errorTitle: "Login fail",
      onHidden: null,
      redirectExternal: "",
      homePath: router.home.path,
      resp: null,
    };
  },
  created: function () {
    $("body").addClass("bglogin");
  },
  mounted() {
    $(".boxFix").addClass("backgroundLogin");

    $(this.$el).on("shown.bs.modal", () => {
      if (this.$refs.codeRef) {
        $(this.$refs.codeRef).focus();
      }
    });
    $(this.$el).on("hidden.bs.modal", () => {
      if (typeof this.onHidden === "function") {
        this.onHidden(this.resp);
      }
    });
  },
  methods: {
    show(validateCode = "", onHidden, redirectExternal) {
      this.fixedBody();
      this.code = "";
      this.resp = null;
      this.validateCode = validateCode;
      this.onHidden = onHidden;
      this.redirectExternal = redirectExternal;
      $(this.$el).modal("show");
    },
    hide() {
      this.staticBody();
    },
    submit() {
      if (!this.code) {
        notify.error("TwoFA code is empty");
        return;
      }
      this.loading = true;
      appStore
        .dispatch(AUTH_ACTIONS.verify_login_2fa, {
          validateCode: this.validateCode,
          code: this.code,
          redirectExternal: this.redirectExternal,
        })
        .then((resp) => {
          this.loading = false;
          this.resp = resp;
          if (resp.error) {
            if (resp.data.err_code === "invalid-2fa-code") {
              // notify.error('Google authentication incorrect')
            } else if (resp.data.err_code === "invalid-token") {
              // notify.error('invalid token')
            } else {
              // notify.error(resp.msg)
            }
          } else {
            this.staticBody();
          }
        });
    },
  },
  beforeUnmount() {
    this.clearScrollHeight();
    this.staticBody();
  },
});
