<template>
  <div>
    <ul
      :id="elementId"
      class="rk-vue-simple-context-menu"
      :class="{ 'light-theme': lightTheme }"
      v-click-outside="onClickOutside"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        @click.stop="optionClicked(option)"
        class="menu-item"
        :class="option.class"
      >
        <template v-if="showIcon">
          <i v-if="option.icon" :class="option.icon" class="icon me-1"></i>
          <i v-else class="far fa-caret-square-right icon me-1"></i>
        </template>
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
//color
$black-1: #24262d;
$black-2: #1e2128;
$black-3: #333;
$grey: #cccccc;
$light-grey: #ecf0f1;
$blue: #007aff;
$white: #fff;
//light theme
$l-bg: $light-grey;
$l-item-text: $black-3;
$l-item-hover-bg: $blue;
$l-item-hover-text: $white;
//dark theme
$d-bg: $black-1;
$d-item-text: $grey;
$d-item-hover-bg: darken($light-grey, 15%);
$d-item-hover-text: $black-2;

.rk-vue-simple-context-menu {
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: none;
  list-style: none;
  position: absolute;
  z-index: 1000000;
  background-color: $d-bg;
  border-bottom-width: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-shadow: 0 3px 6px 0 rgba($black-3, 0.2);
  border-radius: 4px;
  &--active {
    display: block;
  }
  .menu-item {
    display: flex;
    color: $d-item-text;
    cursor: pointer;
    padding: 5px 15px;
    align-items: center;
    font-size: 14px;
    &:hover {
      background-color: $d-item-hover-bg;
      color: $d-item-hover-text;
    }
    .icon {
      width: 20px;
    }
  }
  &.light-theme {
    background-color: $l-bg;
    .menu-item {
      color: $l-item-text;
      &:hover {
        background-color: $l-item-hover-bg;
        color: $l-item-hover-text;
      }
    }
  }
  // Have to use the element so we can make use of `first-of-type` and
  // `last-of-type`
  li {
    &:first-of-type {
      margin-top: 4px;
    }
    &:last-of-type {
      margin-bottom: 4px;
    }
  }
}
</style>
