import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'searchItem',
    props: ['searchToken', 'hideSearchItem'],
    emits: ['goToItemLocation'],
    data() {
        return {
            loading: false,
            searchedItems: [],
            searchKey: '',
            searchParams: {
                paging: {
                    page: 1,
                    size: 10,
                    total: 0
                },
                searchKey: ''
            },
        }
    },
    created() {
        this.searchKey = this.searchToken
        this.searchItems(this.searchKey)
    },
    methods: {
        searchItems(key) {
            const _key = key.trim()
            if (_key) {
                this.searchParams.searchKey = _key
                this.loadPage(1)
            }
        },

        loadPage(value) {
            this.searchParams.paging.page = value
            this.doSearch()
        },

        doSearch() {
            this.loading = true
            AdminServices.searchDataroomItems(this.searchParams)
            .then(resp => {
                if (resp.data.ok) {
                    this.searchedItems = resp.data.d.c
                    this.searchParams.paging.page = resp.data.d.p
                    this.searchParams.paging.size = resp.data.d.s
                    this.searchParams.paging.total = resp.data.d.t
                }
            }).finally(() => {
                this.loading = false
            })
        },

        goToItemLocation(item) {
            this.$emit('goToItemLocation', item.parent_id ? item.parent_id : 0)
        }

    }
})