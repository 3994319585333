import AdminServices from '@/services/admin'
import notify from '../../../../services/notify'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'deleteFileItems',
    props: ['items', 'hideDeleteFileItems'],
    data() {
        return {
            loading: false,
            itemsToDelete: [],
            completed: false
        }
    },
    created() {
        this.itemsToDelete = this.items
    },
    computed: {
        isValidToProcess() {
            if (this.completed) {
                return false
            } else {
                return this.itemsToDelete.length > 0 ? true : false
            }
        }
    },
    methods: {
        processDelete() {
            if (this.isValidToProcess) {
                this.loading = true
                AdminServices.deleteDataroomFileItems({
                    ids: this.itemsToDelete.map(x => x.id)
                }).then(resp => {
                    this.completed = true
                    if (resp.data.ok) {
                        this.itemsToDelete = this.itemsToDelete.map(x => {
                            return {
                                ...x,
                                isDeleted: true
                            }
                        })
                        notify.success('Delete files successfully!')
                        this.hideDeleteFileItems()
                    }
                }).finally(() => { this.loading = false })
            }
        },
    }
})