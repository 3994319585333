<template>
  <div class="adminContent p-2" v-loading="loading">
    <div class="data p-3 mb-2">
      <div class="row">
        <div class="col col-12 col-md-4" v-if="isRokker || isIDC">
          <label class="inline colorWhite me-1">Partner:</label>
          <div class="dropdown inline">
            <button
              class="btn btn-primary btn-small dropdown-toggle"
              role="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <template v-if="companySelected">
                {{ companySelected.name }}
              </template>
            </button>
            <div
              class="dropdown-menu p-0"
              aria-labelledby="dropdownMenuButton"
              v-if="dataCompany"
            >
              <span
                class="dropdown-item pointer py-2"
                @click="selectCompany(item)"
                :class="{
                  active: companySelected && companySelected.id === item.id,
                }"
                v-for="item in dataCompany"
                :key="item"
                >{{ `${item.cname}` }}</span
              >
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-8">
          <div class="row custom-date-picker">
            <div class="col col-12 col-md-4">
              <date-picker
                v-model="fromDate"
                monthPicker
                field="black"
                :auto-apply="true"
                :dark="true"
              />
            </div>
            <div class="col col-12 col-md-4">
              <date-picker
                v-model="toDate"
                monthPicker
                field="black"
                :auto-apply="true"
                :dark="true"
              />
            </div>
            <div class="col col-12 col-md-4">
              <button
                class="btn btn-primary btn-small mt-1"
                @click="getDataChart"
              >
                Search
              </button>
              <!-- <button class="btn btn-primary btn-small" @click="getExcel"><i class="fas fa-file-excel me-1"></i>Export</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx--2" v-if="listDataChart && listDataChart.length > 0">
      <div
        class="col col-12 col-md-6 col-lg-4 px-2 mb-3"
        v-for="item in listDataChart"
        :key="item"
      >
        <div
          class="data chart pt-2 pointer"
          @click="showdetail(item)"
          v-if="item.name !== 'Empty Block'"
        >
          <chartView :chartData="item" viewType="chart" />
          <!-- <div :id="`chart${index}`" :ref="`chart${index}`" :data-chart="item" height=200>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="row mx--2" v-else>
      <div class="col-12 py-5 text-center">
        <!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 2386 2104.9"
          style="enable-background: new 0 0 2386 2104.9; max-width: 300px"
          xml:space="preserve"
        >
          <g id="Group_11160" transform="translate(11616 -123.132)">
            <g
              id="undraw_marketing_v0iu_2_"
              transform="translate(-11294.254 123.132)"
            >
              <path
                id="Path_24149"
                class="st0"
                d="M932.4,1362.5C495.5,1329.3-25.6,990.7,1,642.2S532.1-30.9,969,2.4s688.4,684.7,661.9,1033.2S1369.3,1395.7,932.4,1362.5z"
              />
              <rect
                id="Rectangle_3518"
                x="2.2"
                y="128"
                class="st1"
                width="829.1"
                height="857.9"
              />
              <path
                id="Path_24150"
                class="st2"
                d="M420.9,384c-1.4,0-2.7-0.1-4.1-0.1c-95.5,0-172.9,77.5-172.9,173c0,95.5,77.5,172.9,173,172.9s172.9-77.5,172.9-173c0-28.7-7.2-57-20.8-82.2L425.8,582.2L420.9,384z"
              />
              <path
                id="Path_24151"
                class="st3"
                d="M569,474.6c45.5,84,14.3,189.1-69.8,234.6c-84,45.5-189.1,14.3-234.6-69.8s-14.3-189.1,69.8-234.6c25.3-13.7,53.6-20.9,82.4-20.9c1.4,0,2.7,0,4.1,0.1l-1.8-73.9c-0.8,0-1.5,0-2.3,0C280.5,310.1,170,420.6,170,557s110.6,246.8,246.9,246.8s246.8-110.6,246.8-246.9c0-44.7-12.2-88.6-35.2-127L569,474.6z"
              />
              <path
                id="Path_24152"
                class="st4"
                d="M569,474.6l59.5-44.7c-44.2-73.7-123.5-119-209.5-119.8l1.8,73.9C482.9,385.4,539.5,420,569,474.6z"
              />
              <line
                id="Line_1117"
                class="st5"
                x1="541"
                y1="770.4"
                x2="627.7"
                y2="858"
              />
              <line
                id="Line_1118"
                class="st5"
                x1="600"
                y1="234.2"
                x2="539.8"
                y2="341.6"
              />
              <rect
                id="Rectangle_3519"
                x="557.4"
                y="858"
                class="st0"
                width="142.4"
                height="32.4"
              />
              <rect
                id="Rectangle_3520"
                x="524.9"
                y="201.9"
                class="st0"
                width="142.4"
                height="32.4"
              />
              <rect
                id="Rectangle_3521"
                x="910.7"
                y="120.8"
                class="st1"
                width="829.1"
                height="346.1"
              />
              <path
                id="Path_24153"
                class="st6"
                d="M1245.6,387.5h-93.7c-6.4-37-6.4-74.8,0-111.7h93.7C1235,312.3,1235.1,351,1245.6,387.5z"
              />
              <path
                id="Path_24154"
                class="st6"
                d="M1507.5,387.5h-93.7c-6.4-37-6.4-74.8,0-111.7h93.7C1497,312.3,1497,351,1507.5,387.5z"
              />
              <path
                id="Path_24155"
                class="st3"
                d="M1112.5,311.8h-93.7c-6.4-37-6.4-74.8,0-111.7h93.7C1102,236.6,1102,275.3,1112.5,311.8z"
              />
              <path
                id="Path_24156"
                class="st3"
                d="M1280.1,311.8h93.7c6.4-37,6.4-74.8,0-111.7h-93.7C1290.7,236.6,1290.7,275.3,1280.1,311.8z"
              />
              <path
                id="Path_24157"
                class="st3"
                d="M1537.9,311.8h93.7c6.4-37,6.4-74.8,0-111.7h-93.7C1548.4,236.6,1548.4,275.3,1537.9,311.8z"
              />
              <path
                id="Path_24158"
                class="st1"
                d="M1581.1,1366.2c0,35.9-167.9,106.7-374,106.7s-372.2-70.8-372.2-106.7s166.1-23.4,372.2-23.4S1581.1,1330.3,1581.1,1366.2z"
              />
              <path
                id="Path_24159"
                class="st7"
                d="M1581.1,1366.2c0,35.9-167.9,106.7-374,106.7s-372.2-70.8-372.2-106.7s166.1-23.4,372.2-23.4S1581.1,1330.3,1581.1,1366.2z"
              />
              <ellipse
                id="Ellipse_1031"
                class="st1"
                cx="1207.1"
                cy="1366.4"
                rx="373.1"
                ry="65.1"
              />
              <ellipse
                id="Ellipse_1032"
                class="st7"
                cx="1207.1"
                cy="1366.4"
                rx="292"
                ry="50.9"
              />
              <path
                id="Path_24160"
                class="st3"
                d="M1078.3,980.5h-93.7c-6.4-37-6.4-74.8,0-111.7h93.7C1067.7,905.3,1067.7,944,1078.3,980.5L1078.3,980.5z"
              />
              <path
                id="Path_24161"
                class="st8"
                d="M1374.8,414.7l12.9,6.4c0,0,69.8,175.1,46.2,217c-23.6,41.9-97.7,120.3-97.7,120.3l-14-39.7l57.1-123.8L1374.8,414.7z"
              />
              <path
                id="Path_24162"
                class="st9"
                d="M1374.8,414.7l12.9,6.4c0,0,69.8,175.1,46.2,217c-23.6,41.9-97.7,120.3-97.7,120.3l-14-39.7l57.1-123.8L1374.8,414.7z"
              />
              <path
                id="Path_24163"
                class="st0"
                d="M1360.8,1290c0,0,18.3,41.9,8.6,45.1c-9.7,3.2-21.5,3.2-33.3,3.2s-25.8-4.3-27.9-8.6c-2.1-4.3,8.6-32.2,8.6-32.2L1360.8,1290z"
              />
              <path
                id="Path_24164"
                class="st0"
                d="M1114.9,1311.5c0,0,11.8,37.6,6.4,39.7c-5.4,2.1-22.6,11.8-32.2,8.6c-9.7-3.2-24.7-10.7-27.9-14s0-20.4,0-20.4l15-21.5L1114.9,1311.5z"
              />
              <path
                id="Path_24165"
                class="st10"
                d="M1326.5,753l15.4,5.4c0,0,3.9,36.5-0.4,49.4c-4.3,12.9-14,106.3-8.6,125.7c5.4,19.3,2.1,50.5,2.1,50.5s32.2,105.3,25.8,127.8c-6.4,22.6-5.4,54.8,1.1,70.9c6.4,16.1,11.8,22.6,8.6,35.4c-3.2,12.9-9.7,19.3-3.2,30.1c6.4,10.7,19.3,14,12.9,23.6s-12.9,17.2-10.7,23.6c2.1,6.4-56.9,18.3-62.3,8.6c0,0,17.2-15,0-23.6c-17.2-8.6-11.8-40.8-11.8-40.8s-30.1-123.5-29-133.2s-47.3-160-47.3-160s-70.9,214.8-75.2,224.5s5.4,33.3,0,39.7c-5.4,6.4,2.1,22.6-5.4,29c-7.5,6.4,12.9,30.1,0,38.7c-12.9,8.6-19.3,35.4-18.3,40.8c1.1,5.4-53.7-1.1-53.7-8.6c0-7.5,1.1-14-4.3-26.9c-5.4-12.9-8.6-21.5-4.3-25.8c4.3-4.3,7.5-9.7,3.2-24.7c-4.3-15,6.4-61.2,3.2-69.8c-3.2-8.6-2.1-66.6-2.1-66.6s10.7-84.8,7.5-98.8c-3.2-14,0-17.2,1.1-22.6s7.5-63.4,11.8-72s9.7-155.7,34.4-163.3S1326.5,753,1326.5,753z"
              />
              <path
                id="Path_24166"
                class="st10"
                d="M1335.1,1328.7c0,0,38.7-6.4,40.8,0c2.1,6.4,8.6,56.9-6.4,58c-15,1.1-40.8,4.3-45.1-5.4c-4.3-9.7-24.7-16.1-24.7-16.1s-30.1-8.6-30.1-18.3s1.1-24.7,6.4-25.8c5.4-1.1,26.9-12.9,26.9-17.2S1335.1,1328.7,1335.1,1328.7z"
              />
              <path
                id="Path_24167"
                class="st10"
                d="M1093.4,1345.9c0,0,27.9-8.6,34.4-3.2c6.4,5.4,15,64.4,0,63.4s-54.8,5.4-56.9-3.2c-2.1-8.6-31.1-10.7-31.1-10.7s-31.1-15-29-31.1s5.4-21.5,18.3-22.6c12.9-1.1,22.6-16.1,23.6-17.2c1.1-1.1,10.5-8.9,11.7-0.2C1065.5,1329.7,1079.4,1349.1,1093.4,1345.9z"
              />
              <circle
                id="Ellipse_1033"
                class="st11"
                cx="1181.5"
                cy="239.6"
                r="65.5"
              />
              <path
                id="Path_24168"
                class="st11"
                d="M1238.4,254.6c0,0,4.3,72,20.4,78.4c16.1,6.4-81.6,6.4-81.6,6.4s-1.1-45.1-15-55.9
                            S1238.4,254.6,1238.4,254.6z"
              />
              <path
                id="Path_24169"
                class="st12"
                d="M1264.2,316.9c0,0-66.6-24.7-90.2,14c0,0-75.2,35.4-80.6,56.9s45.1,182.6,45.1,182.6s0,133.2-10.7,147.1s-31.1,29-17.2,32.2s233.1,26.9,232,12.9s-15-25.8,0-34.4s2.1-18.3,11.8-46.2s33.3-258.8,33.3-258.8s-16.1-29-19.3-32.2C1365.1,387.8,1264.2,316.9,1264.2,316.9z"
              />
              <path
                id="Path_24170"
                class="st11"
                d="M1071.9,787.3c0,0-41.9,94.5-5.4,107.4c36.5,12.9,34.4-99.9,34.4-99.9L1071.9,787.3z"
              />
              <path
                id="Path_24171"
                class="st10"
                d="M1212.9,251.8c-3.8-7.6-9.5-16.4-18-15.9c-4.3,0.2-8,2.9-11.8,4.9s-8.7,3.2-12.2,0.7c-5.1-3.7-3.5-12.3-8.1-16.6c-5-4.7-15-2.1-18.3-8.1c-1.7-3.2-0.3-7.3-1.1-10.8c-5.2,0.1-10.6,0.2-15.5-1.8s-9-6.5-8.9-11.8c0.3-3.6,2-6.9,4.8-9.2c6.6-6,16.2-7.2,24.3-10.8c6.5-2.9,12.1-7.4,18.5-10.5c11-5.4,23.6-6.4,35.8-5.5c18.5,1.4,37.2,7.5,50.6,20.3s20.5,33,14.7,50.6c-3.3,10-10.2,18.4-14.1,28.1c-2.4,6.7-4,13.6-4.9,20.6l-2.7,16.7c0.4-2.6-5.3-9.1-7.1-10.7c-4.1-3.4-10-2.2-13.8-5.4C1219.4,271.9,1216.2,258.4,1212.9,251.8z"
              />
              <path
                id="Path_24172"
                class="st7"
                d="M1118.1,380.3l-17.2,10.7c0,0-27.9,168.6-24.7,192.3c3.2,23.6-12.9,156.8-7.5,167.6s7.5,12.9,7.5,19.3s-15,30.1-5.4,34.4s45.1,11.8,45.1,0s-6.4-27.9,5.4-35.4s20.4-55.9,20.4-55.9s5.4-49.4,10.7-59.1s12.9-45.1,12.9-60.1c0-15,12.9-76.3,5.4-107.4C1163.2,455.5,1161.1,371.7,1118.1,380.3z"
              />
              <path
                id="Path_24173"
                class="st13"
                d="M1110.6,377.1l-17.2,10.7c0,0-27.9,168.6-24.7,192.3c3.2,23.6-12.9,156.8-7.5,167.6s7.5,12.9,7.5,19.3s-15,30.1-5.4,34.4s45.1,11.8,45.1,0s-6.4-27.9,5.4-35.4s20.4-55.9,20.4-55.9s5.4-49.4,10.7-59.1s12.9-45.1,12.9-60.1c0-15,12.9-76.3,5.4-107.4C1155.7,452.2,1153.5,368.5,1110.6,377.1z"
              />
            </g>
            <g class="st14">
              <path
                d="M-11428.3,2159h-35l-92.3-142c-2.3-3.6-4.3-7.3-5.8-11.2h-0.8c0.7,4.1,1.1,13,1.1,26.5V2159h-31v-193.3h37.3l89.2,138.7c3.8,5.8,6.2,9.7,7.3,11.9h0.5c-0.9-5.1-1.3-13.8-1.3-26v-124.5h30.9V2159z"
              />
              <path
                d="M-11322.8,2162.2c-21.3,0-38.3-6.4-51-19.3c-12.7-12.9-19.1-30-19.1-51.3c0-23.2,6.6-41.3,19.9-54.3c13.3-13,31.1-19.5,53.6-19.5c21.6,0,38.4,6.3,50.4,19c12,12.7,18.1,30.2,18.1,52.7c0,22-6.5,39.6-19.5,52.9C-11283.4,2155.6-11300.8,2162.2-11322.8,2162.2z M-11321.3,2042.7c-12.2,0-21.9,4.3-29,12.8c-7.1,8.5-10.6,20.3-10.6,35.3c0,14.5,3.6,25.9,10.8,34.2c7.2,8.3,16.8,12.5,28.8,12.5c12.3,0,21.8-4.1,28.4-12.3s9.9-19.8,9.9-34.9c0-15.2-3.3-26.9-9.9-35.2S-11309,2042.7-11321.3,2042.7z"
              />
              <path
                d="M-11017.8,2159h-31.3v-23.4h-0.5c-10.1,17.8-25.6,26.7-46.5,26.7c-17,0-30.6-6.2-40.8-18.5c-10.2-12.4-15.3-29.2-15.3-50.5c0-22.8,5.6-41.1,16.9-54.8c11.3-13.7,26.3-20.6,45.1-20.6c18.6,0,32.1,7.5,40.6,22.4h0.5v-85.4h31.3V2159z M-11048.6,2095.9v-18.1c0-9.8-3.2-18.1-9.6-24.9c-6.4-6.8-14.5-10.2-24.4-10.2c-11.7,0-20.9,4.4-27.6,13.1c-6.7,8.7-10,20.8-10,36.3c0,14,3.2,25.1,9.6,33.2c6.4,8.1,15.1,12.2,25.9,12.2c10.7,0,19.4-3.9,26-11.8C-11052,2117.8-11048.6,2107.9-11048.6,2095.9z"
              />
              <path
                d="M-10871,2159h-30.3v-21.6h-0.5c-9.5,16.5-23.5,24.8-41.9,24.8c-13.6,0-24.2-3.7-31.9-11.1s-11.5-17.1-11.5-29.2c0-26.1,15-41.2,45-45.6l41-5.8c0-19.7-9.3-29.5-28-29.5c-16.4,0-31.3,5.7-44.5,17v-27.4c14.6-8.6,31.4-12.9,50.4-12.9c34.9,0,52.3,17.2,52.3,51.5V2159z M-10901.2,2091.2l-29,4c-9,1.2-15.7,3.3-20.3,6.5c-4.5,3.2-6.8,8.8-6.8,16.8c0,5.8,2.1,10.6,6.3,14.4c4.2,3.7,9.8,5.6,16.8,5.6c9.5,0,17.4-3.3,23.7-10c6.2-6.7,9.4-15.1,9.4-25.3V2091.2z"
              />
              <path
                d="M-10760.1,2157.5c-6.1,3.1-14.2,4.6-24.1,4.6c-26.8,0-40.2-12.8-40.2-38.5v-78h-23V2021h23v-31.9l31.3-8.9v40.8h33v24.5h-33v69c0,8.2,1.5,14,4.4,17.5s7.9,5.3,14.8,5.3c5.3,0,9.9-1.5,13.7-4.6V2157.5z"
              />
              <path
                d="M-10627.2,2159h-30.3v-21.6h-0.5c-9.5,16.5-23.5,24.8-41.9,24.8c-13.6,0-24.2-3.7-31.9-11.1s-11.5-17.1-11.5-29.2c0-26.1,15-41.2,45-45.6l41-5.8c0-19.7-9.3-29.5-28-29.5c-16.4,0-31.3,5.7-44.5,17v-27.4c14.6-8.6,31.4-12.9,50.4-12.9c34.9,0,52.3,17.2,52.3,51.5V2159z M-10657.4,2091.2l-29,4c-9,1.2-15.7,3.3-20.3,6.5c-4.5,3.2-6.8,8.8-6.8,16.8c0,5.8,2.1,10.6,6.3,14.4c4.2,3.7,9.8,5.6,16.8,5.6c9.5,0,17.4-3.3,23.7-10c6.2-6.7,9.4-15.1,9.4-25.3V2091.2z"
              />
              <path
                d="M-10440.4,2157.5c-6.1,3.1-14.2,4.6-24.1,4.6c-26.8,0-40.2-12.8-40.2-38.5v-78h-23V2021h23v-31.9l31.3-8.9v40.8h33v24.5h-33v69c0,8.2,1.5,14,4.4,17.5s7.9,5.3,14.8,5.3c5.3,0,9.9-1.5,13.7-4.6V2157.5z"
              />
              <path
                d="M-10353.9,2162.2c-21.3,0-38.3-6.4-51-19.3c-12.7-12.9-19.1-30-19.1-51.3c0-23.2,6.6-41.3,19.9-54.3c13.3-13,31.1-19.5,53.6-19.5c21.6,0,38.4,6.3,50.4,19c12,12.7,18.1,30.2,18.1,52.7c0,22-6.5,39.6-19.5,52.9C-10314.5,2155.6-10332,2162.2-10353.9,2162.2z M-10352.4,2042.7c-12.2,0-21.9,4.3-29,12.8c-7.1,8.5-10.6,20.3-10.6,35.3c0,14.5,3.6,25.9,10.8,34.2c7.2,8.3,16.8,12.5,28.8,12.5c12.3,0,21.8-4.1,28.4-12.3s9.9-19.8,9.9-34.9c0-15.2-3.3-26.9-9.9-35.2S-10340.1,2042.7-10352.4,2042.7z"
              />
              <path
                d="M-10048.9,2159h-31.3v-23.4h-0.5c-10.1,17.8-25.6,26.7-46.5,26.7c-17,0-30.6-6.2-40.8-18.5c-10.2-12.4-15.3-29.2-15.3-50.5c0-22.8,5.6-41.1,16.9-54.8c11.3-13.7,26.3-20.6,45.1-20.6c18.6,0,32.1,7.5,40.6,22.4h0.5v-85.4h31.3V2159z M-10079.8,2095.9v-18.1c0-9.8-3.2-18.1-9.6-24.9c-6.4-6.8-14.5-10.2-24.4-10.2c-11.7,0-20.9,4.4-27.6,13.1c-6.7,8.7-10,20.8-10,36.3c0,14,3.2,25.1,9.6,33.2c6.4,8.1,15.1,12.2,25.9,12.2c10.7,0,19.4-3.9,26-11.8C-10083.1,2117.8-10079.8,2107.9-10079.8,2095.9z"
              />
              <path
                d="M-9992.3,1992c-5.1,0-9.5-1.7-13.1-5s-5.5-7.5-5.5-12.7s1.8-9.4,5.5-12.8s8-5.1,13.1-5.1c5.3,0,9.8,1.7,13.5,5.1c3.7,3.4,5.5,7.7,5.5,12.8c0,4.9-1.8,9-5.5,12.5C-9982.6,1990.3-9987,1992-9992.3,1992z M-9976.8,2159h-31.3v-138h31.3V2159z"
              />
              <path
                d="M-9944.6,2154.7v-29c11.7,8.9,24.6,13.3,38.7,13.3c18.9,0,28.3-5.6,28.3-16.7c0-3.1-0.8-5.8-2.4-8c-1.6-2.2-3.8-4.2-6.5-5.9c-2.7-1.7-6-3.2-9.7-4.6c-3.7-1.3-7.9-2.9-12.5-4.6c-5.7-2.2-10.8-4.6-15.3-7.1c-4.5-2.5-8.3-5.4-11.3-8.6c-3-3.2-5.3-6.8-6.8-10.8c-1.5-4-2.3-8.8-2.3-14.2c0-6.6,1.6-12.5,4.7-17.6c3.1-5.1,7.4-9.3,12.7-12.8c5.3-3.5,11.3-6.1,18.1-7.8s13.7-2.6,20.9-2.6c12.8,0,24.2,1.9,34.2,5.8v27.4c-9.7-6.6-20.8-10-33.4-10c-4,0-7.5,0.4-10.7,1.2c-3.2,0.8-5.9,1.9-8.2,3.4c-2.2,1.4-4,3.2-5.3,5.2c-1.3,2-1.9,4.2-1.9,6.7c0,3,0.6,5.5,1.9,7.5c1.3,2.1,3.1,3.9,5.6,5.5c2.5,1.6,5.4,3,8.9,4.3c3.5,1.3,7.4,2.7,11.9,4.2c5.9,2.4,11.3,4.9,16,7.4s8.7,5.4,12.1,8.6c3.3,3.2,5.9,6.9,7.7,11.1c1.8,4.2,2.7,9.1,2.7,14.9c0,7-1.6,13.1-4.9,18.3s-7.5,9.5-12.9,12.9s-11.6,6-18.7,7.6c-7.1,1.7-14.5,2.5-22.3,2.5C-9920.5,2162.2-9933.6,2159.7-9944.6,2154.7z"
              />
              <path
                d="M-9785.2,2138.7h-0.5v83.8h-31.3V2021h31.3v24.3h0.5c10.7-18.3,26.3-27.5,46.9-27.5c17.5,0,31.2,6.2,41,18.5c9.8,12.3,14.7,28.8,14.7,49.6c0,23-5.5,41.4-16.6,55.3s-26.1,20.8-45.3,20.8C-9762.1,2161.8-9775.7,2154.1-9785.2,2138.7zM-9786,2084.2v17.1c0,10.1,3.2,18.6,9.6,25.6c6.4,7,14.6,10.5,24.5,10.5c11.6,0,20.7-4.5,27.3-13.5s9.9-21.6,9.9-37.8c0-13.6-3.1-24.2-9.2-31.9c-6.2-7.7-14.5-11.5-25-11.5c-11.1,0-20.1,4-26.9,11.9C-9782.6,2062.5-9786,2072.3-9786,2084.2z"
              />
              <path d="M-9619.3,2159h-31.4v-204.3h31.4V2159z" />
              <path
                d="M-9472.7,2159h-30.3v-21.6h-0.5c-9.5,16.5-23.5,24.8-41.9,24.8c-13.6,0-24.2-3.7-31.9-11.1s-11.5-17.1-11.5-29.2c0-26.1,15-41.2,45-45.6l41-5.8c0-19.7-9.3-29.5-28-29.5c-16.4,0-31.3,5.7-44.5,17v-27.4c14.6-8.6,31.4-12.9,50.4-12.9c34.9,0,52.3,17.2,52.3,51.5V2159z M-9502.9,2091.2l-29,4c-9,1.2-15.7,3.3-20.3,6.5c-4.5,3.2-6.8,8.8-6.8,16.8c0,5.8,2.1,10.6,6.3,14.4c4.2,3.7,9.8,5.6,16.8,5.6c9.5,0,17.4-3.3,23.7-10c6.2-6.7,9.4-15.1,9.4-25.3V2091.2z"
              />
              <path
                d="M-9315,2021l-62.3,160.1c-12.9,28.6-31.1,42.9-54.4,42.9c-6.6,0-12-0.6-16.4-1.8v-26c4.9,1.6,9.4,2.4,13.5,2.4c11.7,0,20.4-5.8,26.1-17.2l9.3-22.6l-54.8-137.7h34.6l32.9,100.3c0.4,1.3,1.2,4.6,2.4,10h0.7c0.4-2.1,1.3-5.3,2.4-9.7l34.5-100.5H-9315z"
              />
              <path
                d="M-9272.7,2161.7c-5.4,0-10-1.7-13.7-5.2c-3.8-3.5-5.7-7.7-5.7-12.6c0-5.1,1.9-9.5,5.7-13.1s8.4-5.4,13.7-5.4c5.2,0,9.7,1.8,13.5,5.4c3.8,3.6,5.7,8,5.7,13.1c0,4.9-1.9,9.1-5.7,12.6C-9263,2160-9267.5,2161.7-9272.7,2161.7z M-9257.7,1965.7l-2.6,136.4h-25.6l-3.5-136.4H-9257.7z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <detailChart
      v-if="isShowDetailChart"
      :dataChart="dataChart"
      :hidedetail="hidedetail"
    />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.st0 {
  fill: #eeeeee;
}
.st1 {
  fill: #3f3d56;
}
.st2 {
  fill: none;
}
.st3 {
  fill: #00b6ff;
}
.st4 {
  opacity: 0.3;
  fill: #00b6ff;
  enable-background: new;
}
.st5 {
  fill: none;
  stroke: #2f2e41;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st6 {
  fill: #ff6584;
}
.st7 {
  opacity: 0.1;
  enable-background: new;
}
.st8 {
  fill: #575a89;
}
.st9 {
  opacity: 0.377;
  fill: #00b5ff;
  enable-background: new;
}
.st10 {
  fill: #2f2e41;
}
.st11 {
  fill: #ffb9b9;
}
.st12 {
  fill: #1089cf;
}
.st13 {
  fill: #1e8acc;
}
.st14 {
  fill: #828282;
}
.inline {
  display: inline-block;
}
.mx--2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.data {
  &.chart {
    height: 100%;
    // &:before{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 2;
    // }
  }
}

.apexcharts-yaxis-label {
  fill: #fff;
}
.custom-date-picker ::v-deep(.dp__input_wrap) {
  .dp__pointer {
    border: 0 !important;
    color: #949ba2;
  }
}
</style>
