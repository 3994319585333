<template>
  <div v-if="loading" class="wrapperLoading">
    <div class="lds-dual-ring"></div>
  </div>
  <div v-else class="newsInfo">
    <div class="ig-wrapper mb-3" v-if="data.igProfile.username">
      <div class="d-flex pb-3">
        <a
          :href="`https://www.instagram.com/${data.igProfile.username}/`"
          target="blank"
          class="d-flex"
        >
          <!-- <img src="https://scontent.cdninstagram.com/v/t51.2885-19/s150x150/28158507_818777404974006_7520696745584492544_n.jpg?_nc_ht=scontent.cdninstagram.com&_nc_ohc=BA_VUmOKb9wAX_kD0Mf&oh=3dc8b52fbff6bd83939ab612d0250ee9&oe=5ED6F268" alt class="avatar me-3" /> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="4.03 0 290.19 290.19"
            style="margin: 3px"
          >
            <g
              id="_0095ffff"
              data-name="#0095ffff"
              transform="translate(-374.5 -84.97)"
            >
              <path
                id="Path_35073"
                data-name="Path 35073"
                d="M464.94,107.8q20.04-11.415,40.09-22.83.015,108.435,0,216.87L374.5,375.16v-39.8q45.225-25.245,90.44-50.5-.015-24.36,0-48.72-29.73,16.41-59.44,32.82-.015-19.89,0-39.79,29.7-16.41,59.42-32.82Q464.98,152.085,464.94,107.8Z"
                fill="#0095ff"
              />
              <path
                id="Path_35074"
                data-name="Path 35074"
                d="M526.09,84.97q20.055,11.4,40.1,22.83,0,44.28.01,88.55,29.715,16.38,59.4,32.81-.015,19.89-.01,39.79-29.7-16.41-59.4-32.81-.015,24.36-.01,48.72,45.21,25.275,90.45,50.5-.015,19.89-.01,39.79-65.265-36.645-130.53-73.31Z"
                fill="#0095ff"
              />
            </g>
          </svg>
          <div class="d-flex flex-column justify-content-center">
            <p class="mb-0">
              <b class="avatar-name">Fuel Venture Capital </b>
              <span class="bio d-md-inline-block d-sm-none ml-2"
                >Venture capital firm - "Founder focused, investor driven"</span
              >
            </p>
            <div class="d-flex align-items-center">
              <InstagramIcon class="d-inline-block me-1" />
              <span class="me-1">@fuelventurecapital</span>

              <p class="profile-info" style="display: inline-block">
                <span class="num"
                  >-{{ " " }}{{ data.igProfile.media_count }}</span
                >
                posts
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="ig-feeds row">
        <div
          class="col-lg-4 my-2 col-sm-6"
          v-for="item in data.igItems"
          :key="item"
        >
          <a class="ig-item" :href="item.permalink" target="blank">
            <div class="photo-wrapper">
              <img
                :src="item.media_url"
                alt
                class="photo"
                v-if="item.media_type !== 'VIDEO'"
              />
              <video
                :src="item.media_url"
                alt
                class="video"
                v-if="item.media_type === 'VIDEO'"
              />
              <div class="mask d-flex flex-column">
                <!-- <div class="d-flex flex-fill justify-content-center align-items-center">
                  <span class="igSprite heart"></span>
                  <span class="numLikes">{{props.feed.likes.count}}</span>
                  <span class="igSprite bubble"></span>
                  <span class="numCmts">{{props.feed.comments.count}}</span>
                </div> -->
                <span class="posted-date m-2">{{
                  $filters.parseEstTime(item.timestamp, "MMM DD, YYYY")
                }}</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12" v-if="nextPageLoading">
          <div class="lds-dual-ring"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
.st0 {
  fill: #3e99ed;
}
.ig-wrapper {
  .avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .avatar-name {
    font-size: 16px;
    color: #292f33;

    &:hover {
      color: #2b7bb9;
    }
  }
  .bio {
    color: #707070;
    font-size: 13px;
    font-style: italic;
  }
  .profile-info {
    color: #707070;
    font-size: 13px;

    .num {
      color: #262626;
    }
  }

  .ig-item {
    cursor: pointer;
    display: block;

    .photo-wrapper {
      position: relative;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      border-radius: 4px;

      .photo {
        border-radius: 4px;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        color: #ffffff;
        font-weight: bold;

        span {
          display: none;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);

          span {
            display: inline;
          }
        }

        .numLikes {
          margin-left: 5px;
          margin-right: 25px;
        }
        .numCmts {
          margin-left: 5px;
        }

        .igSprite {
          background-image: url("https://www.instagram.com/static/bundles/es6/sprite_core_4f48d3d2062b.png/4f48d3d2062b.png");
          background-repeat: no-repeat;

          &.heart {
            background-position: -320px -266px;
            height: 19px;
            width: 19px;
          }
          &.bubble {
            background-position: -320px -224px;
            height: 19px;
            width: 19px;
          }
        }

        .posted-date {
          position: absolute;
          bottom: 0;
          right: 0;
          font-weight: normal;
          font-size: 13px;
          font-style: italic;
        }
      }
    }
  }

  // .lds-dual-ring {
  //   display: inline-block;
  //   width: 80px;
  //   height: 80px;
  // }
  // .lds-dual-ring:after {
  //   content: " ";
  //   display: block;
  //   width: 64px;
  //   height: 64px;
  //   margin: 8px;
  //   border-radius: 50%;
  //   border: 6px solid #fff;
  //   border-color: #fff transparent #fff transparent;
  //   animation: lds-dual-ring 1.2s linear infinite;
  // }
  // @keyframes lds-dual-ring {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }
}
</style>
