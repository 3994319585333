// window.$ = window.jQuery = require("jquery");
import jQuery from "jquery";
global.jQuery = jQuery;
var $ = global.jQuery;
window.$ = $;

import mitt from "mitt";
import { createApp, inject } from "vue";
import { RouterView } from "vue-router";
import router, { USER_TYPE, routes } from "@/config/router";
import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/styles/loaders.scss'
import './assets/styles/style.scss'

import appStore, {
  APP_MUTATION,
  APP_ACTIONS,
  AUTH_ACTIONS,
  AUTH_MUTATION,
  LOCAL_STORAGES,
} from "./store/app";

import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";

import directives from "./directives";
import filters from "./filters";
import components from "./components";

import App from "@/views/app";
import _ from "lodash";
import { generic } from "./misc/generic";
import conf from "@/config";

import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import DateRangePicker from "vue3-daterange-picker";
import "vue3-daterange-picker/src/assets/daterangepicker.scss";

require("vue-grid-layout/dist/vue-grid-layout.umd");

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

// import Plugin from "./plugins/vuejs-dialog-plugin/js/index";
// import Dialog from 'vue3-dialog';

import VTooltip from "v-tooltip";

import Sticky from "vue-sticky-directive";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

import CKEditor from "@ckeditor/ckeditor5-vue";

import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

import vClickOutside from "click-outside-vue3";

import mixinFunctions from "./misc/mixin.js";

import VueScrollTo from "vue-scrollto";

import Popper from "vue3-popper";

import "./services/axiosInstance";

// Vue.config.productionTip = false;

// import lang from "@/config/lang/en.json";

import "./styles/index.scss";

import "./styles/index.scss";

import SvgIcon from "@/components/SvgIcon"; // global icon

import { segment } from '@/services/segment'

const initApp = () => {
  global.vueRouter = routes;
  global.vueRouter.beforeEach((to, from, next) => {
    let link = "";
    switch (appStore.state.profile.userType) {
      case USER_TYPE.FOUNDER_POTENTIAL:
        link = router.dealFounder;
        break;
      case USER_TYPE.FOUNDER:
        link = router.dashboard;
        break;
      case USER_TYPE.ROKKER:
      case USER_TYPE.IDC:
        link = router.overview;
        break;
      case USER_TYPE.INVESTOR:
        link = router.investorpartner;
        break;
      default:
        break;
    }

    if (to.meta.auth) {
      if (
        to.meta &&
        to.meta.ndaSignRequired &&
        appStore.state.profile.userType === USER_TYPE.INVESTOR &&
        !appStore.state.profile.signNDA &&
        appStore.state.profile.ndaSignedRequired
      ) {
        next(router.investorsignnda);
      } else if (
        to.meta &&
        to.meta.userTypes !== null &&
        appStore.state.auth &&
        !to.meta.userTypes.includes(appStore.state.profile.userType)
      ) {
        next(link);
      } else if (
        to.meta &&
        to.meta.permissions &&
        !mixinFunctions.methods.hasPermissions(to.meta.permissions)
      ) {
        next(link);
      } else {
        next();
      }
    } else {
      next();
    }

    // if (to.meta && to.meta.auth && to.meta.userTypes != null && appStore.state.auth &&
    //   !to.meta.userTypes.includes(appStore.state.profile.userType)) {
    //   next(link)
    // } else {
    //   next()
    // }
  });

  global.vueRouter.afterEach((to, from) => {
    if (
      !["Social Media", "Portfolio News", "Fuel Perspectives".includes(to.name)]
    ) {
      $("html, body")
        .animate(
          {
            scrollTop: 0,
          },
          "fast"
        )
        .removeClass("modal-open");
    }
    if ($(".modal-backdrop").length > 0) $(".modal-backdrop").remove();
    $("body").removeClass("bglogin");
    if (segment.enabled) {
      segment.analytics.page();
    }
    appStore.commit(APP_MUTATION.changed_router, to);
    appStore.commit(APP_MUTATION.use_empty_layout, false);
    appStore.commit(APP_MUTATION.full_width, false);
    appStore.dispatch(APP_ACTIONS.set_previous_route, from);
  });

  // if (!generic.getJsonLocalStorage(LOCAL_STORAGES.lang)) {
  //   const dLang = conf.default_language.split("|");
  //   appStore.dispatch(APP_ACTIONS.set_new_lang, {
  //     code: dLang[0],
  //     name: dLang[1],
  //   });
  // } else {
  //   appStore.dispatch(
  //     APP_ACTIONS.set_new_lang,
  //     generic.getJsonLocalStorage(LOCAL_STORAGES.lang)
  //   );
  // }

  //auth
  if (conf.auth) {
    const DEFAULT_ROUTE_PATH = "/";
    const LOGIN_PATH = router.login.path;

    global.vueRouter.isReady().then(() => {
      appStore.subscribe((mutations, store) => {
        switch (mutations.type) {
          case AUTH_MUTATION.login_success: {
            const redirect =
              store.lastedRouteNotAuth || global.vueRouter.currentRoute;
            if (redirect.value.meta.auth) {
              global.vueRouter.push(
                redirect.value.fullPath || redirect.value.path
              );
            } else {
              if (appStore.state.profile && appStore.state.profile.userType) {
                //location.href = conf.url_admin
              }
              let redirectTo = DEFAULT_ROUTE_PATH; //default return home page
              if (redirect.value.query && redirect.value.query.redirect) {
                //redirect url
                redirectTo = redirect.value.query.redirect;
              } else if (!_.isEmpty(redirect.value.query)) {
                //redirect full path
                redirectTo = redirect.value.fullPath || redirect.value.path;
              } else if (
                redirect.value.path &&
                !appStore.state.authRoutes.includes(redirect.value.path)
              ) {
                //dont return special authen url (login,register,forgotpass,...)
                redirectTo = redirect.value.path;
              } else if (appStore.state.previousRoute) {
                //return previous url
                if (appStore.state.previousRoute.path === DEFAULT_ROUTE_PATH) {
                  redirectTo = router.home.path;
                } else {
                  redirectTo =
                    appStore.state.previousRoute.fullPath ||
                    appStore.state.previousRoute.path;
                }
              }
              global.vueRouter.push(redirectTo);
            }
            break;
          }
          case AUTH_MUTATION.not_auth: {
            const redirect =
              store.lastedRouteNotAuth || global.vueRouter.currentRoute;
            if (
              redirect.value.meta.auth &&
              redirect.value.path !== LOGIN_PATH
            ) {
              global.vueRouter.push({
                path: LOGIN_PATH,
                query: {
                  redirect: redirect.value.fullPath || redirect.value.path,
                },
              });
            }
            break;
          }
          case APP_MUTATION.update_profile: {
            let link = "";
            switch (appStore.state.profile.userType) {
              case USER_TYPE.FOUNDER:
                link = router.dashboard;
                break;
              case USER_TYPE.ROKKER:
              case USER_TYPE.IDC:
                link = router.overview;
                break;
              case USER_TYPE.INVESTOR:
                link = router.investorpartner;
                break;
              default:
                break;
            }
            if (
              global.vueRouter.currentRoute.value.meta &&
              global.vueRouter.currentRoute.value.meta.ndaSignRequired &&
              appStore.state.profile.userType === USER_TYPE.INVESTOR &&
              !appStore.state.profile.signNDA &&
              appStore.state.profile.ndaSignedRequired
            ) {
              global.vueRouter.push(router.investorsignnda);
            } else if (
              global.vueRouter.currentRoute.value.path ===
                router.investorsignnda.path &&
              appStore.state.profile.userType === USER_TYPE.INVESTOR &&
              appStore.state.profile.signNDA
            ) {
              global.vueRouter.push(link);
            } else if (global.vueRouter.currentRoute.value.meta.auth) {
              if (
                global.vueRouter.currentRoute.value.meta &&
                global.vueRouter.currentRoute.value.meta.userTypes !== null &&
                global.vueRouter.currentRoute.value.meta.userTypes &&
                !global.vueRouter.currentRoute.value.meta.userTypes.includes(
                  appStore.state.profile.userType
                )
              ) {
                global.vueRouter.push(link);
              }
            }
            if (global.vueRouter.currentRoute.value.path === LOGIN_PATH) {
              global.vueRouter.push("/");
            }

            break;
          }
          default:
            break;
        }
      });

      appStore.dispatch(AUTH_ACTIONS.get_user_profile).then(() => {
        global.vueRouter.beforeEach((to, from, next) => {
          if (
            to.matched.some((record) => record.meta.auth) &&
            !appStore.state.auth
          ) {
            appStore.dispatch(AUTH_ACTIONS.not_auth, to);
            next({
              path: LOGIN_PATH,
              query: {
                redirect: to.fullPath,
              },
            });
          } else if (appStore.state.auth && to.path === LOGIN_PATH) {
            next({
              path:
                appStore.store && appStore.store.lastedRouteNotAuth
                  ? appStore.store.lastedRouteNotAuth.path
                  : DEFAULT_ROUTE_PATH,
            });
          } else {
            next();
          }
        });
        appStore.commit(APP_MUTATION.init_app_finished);
      });
      appStore.commit(APP_MUTATION.init_app_finished);
    });
  } else {
    appStore.commit(APP_MUTATION.init_app_finished);
  }
  const app = createApp(App);

  const emitter = mitt();

  const metaManager = createMetaManager();

  app.config.globalProperties = {
    $filters: filters,
    emitter: emitter,
    $route: routes,
    $store: appStore,
    $meta: metaManager,
    // $dialog: Dialog,
    $: jQuery,
  };

  app.mixin(mixinFunctions);
  app.use(BootstrapVue3);
  app.use(global.vueRouter);
  app.use(metaManager);
  app.use(vueMetaPlugin);
  app.use(Viewer);
  app.use(VTooltip);
  app.use(VueVideoPlayer);
  app.use(VueScrollTo);
  app.use(Sticky);
  app.use(PerfectScrollbar);
  app.use(vClickOutside);
  app.use(CKEditor);
  app.use(appStore);

  // register icons globally
  app.component("svg-icon", SvgIcon);
  //

  app.component("router-view", RouterView);
  app.component("date-picker", DatePicker);
  app.component("date-range-picker", DateRangePicker);
  app.component("Popper", Popper);
  for (let i in components) {
    app.component(components[i].name, components[i]);
  }
  for (let i in directives) {
    if (directives[i]) app.directive(i, directives[i]);
  }
  app.mount("#app");
};

export default initApp();
