import adminServices from "@/services/admin";
import moment from "moment";
import momentTz from "moment-timezone";
import notify from "@/services/notify";
import CardItem from "./gallery-components/CardItem.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "investorEvent",
  components: {
    CardItem,
  },
  data() {
    return {
      loading: false,
      events: [],
      eventActive: null,
      pagingMilestone: {
        page: 1,
        size: 3,
        total: 0,
      },
      currentPage: 1,
      milestoneId: "",
      milestones: [],
      id: "",
      galleries: [],
      pagingGallery: {
        page: 1,
        size: 8,
        total: 0,
      },
      activeAnwser: "",
      invitationKey: "TEST",
      isInvitation: false,
      confirmationCode: {
        answerYes: "",
        answerNo: "",
        answerMaybe: "",
      },
      timezone: "America/New_York",
      isReselect: false,
      isCornerLabel: true,
    };
  },
  computed: {
    _paging() {
      const totalPage = Math.ceil(
        this.pagingMilestone.total / this.pagingMilestone.size
      );
      if (totalPage == 1) return [];
      const showNumber = 6;
      const side = Math.floor(showNumber / 2);
      var arr = [];
      var left = [];
      var right = [];
      for (let i = 0; i < side; i++) {
        const res = this.currentPage - (side - i);
        if (res > 0) left.push({ id: res, target: res });
      }
      for (let i = 1; i <= side; i++) {
        const res = this.currentPage + i;
        if (res <= totalPage) right.push({ id: res, target: res });
      }
      if (left.length >= 1) {
        // arr.push({ id: "first", target: 1 });
        arr.push({ id: "prev", target: this.currentPage - 1 });
      }
      arr.push(...left);
      arr.push({ id: this.currentPage, target: this.currentPage });
      arr.push(...right);
      if (right.length >= 1) {
        arr.push({ id: "next", target: this.currentPage + 1 });
        // arr.push({ id: "last", target: totalPage });
      }
      return arr;
    },
  },
  watch: {
    eventActive(v) {
      if (!v) return;
      this.setDetailEvent(v);
    },
    pagingMilestone: {
      deep: true,
      handler(v) {
        this.getMilestones();
      }
    },
    pagingGallery: {
      deep: true,
      handler(v) {
        this.getGalleries(); 
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getEventsByUserId();
    });
  },
  methods: {
    convertStatus(status) {
      if (status == null) return;
      let newStatus = "";
      if (status == "INPROGRESS") newStatus = "Incoming";
      if (status == "COMPLETED") newStatus = "Completed";
      return newStatus;
    },
    convertInvitationStatus(status) {
      if (status == null) return;
      let newStatus = "";
      if (status == "NO") newStatus = "no";
      if (status == "MAYBE") newStatus = "maybe";
      return newStatus;
    },
    async getAttendeeByUserId() {
      this.loading = true;
      const res = await adminServices.getAttendeeByUserId(this.id);
      if (res && res.data && res.data.ok) {
        this.activeAnwser = res.data.d.answer;
        this.invitationKey = res.data.d.invitationKey;
        this.confirmationCode.answerYes = res.data.d.answerYes;
        this.confirmationCode.answerNo = res.data.d.answerNo;
        this.confirmationCode.answerMaybe = res.data.d.answerMaybe;
        this.isInvitation = res.data.d.isInvitation;
      }
      this.loading = false;
    },
    async checkResponse(confirmationCode, answer) {
      this.loading = true;
      const res = await adminServices.attendeeResponse({
        eventId: this.id,
        confirmationCode: confirmationCode,
      });
      if (res && res.data && res.data.ok) {
        notify.success("Response successfully");
        this.activeAnwser = answer;
      }
      this.loading = false;
    },
    async getEventsByUserId() {
      this.loading = true;
      const res = await adminServices.getEventsByUserId();
      if (res && res.data && res.data.ok) {
        this.events = res.data.d;
        if (this.$route.query && this.$route.query.id) {
          this.eventActive = res.data.d.find(
            (p) => p.eventId === parseInt(this.$route.query.id)
          );
        }
        if (!this.eventActive) {
          this.eventActive = res.data.d[0];
        }
      }
      this.loading = false;
    },
    async getMilestones() {
      this.loading = true;
      const res = await adminServices.getMilestonesOfEvent({
        paging: {
          page: this.pagingMilestone.page,
          size: this.pagingMilestone.size,
        },
        eventId: this.id,
      });
      if (res.data.ok) {
        this.milestones = res.data.d.c;
        this.pagingMilestone.total = res.data.d.t;
      }
      this.loading = false;
    },
    async getGalleries() {
      this.loading = true;
      const res = await adminServices.getGaleriesOfEvent({
        paging: {
          page: this.pagingGallery.page,
          size: this.pagingGallery.size,
        },
        eventId: this.id,
      });
      if (res.data.ok) {
        this.galleries = res.data.d.c;
        this.totalItems = res.data.d.t;
        this.pagingGallery.total = res.data.d.t;
      }
      this.loading = false;
    },
    loadPageGalleries(page) {
      this.pagingGallery.page = page;
      this.getGalleries();
    },
    setDetailEvent(item) {
      this.id = item.eventId;
      this.getMilestones();
      this.getGalleries();
      this.getAttendeeByUserId();
    },
    setActive(item) {
      this.eventActive = item;
      this.id = item.eventId;
    },
    pageClick(event) {
      this.currentPage = event.target;
      this.pagingMilestone = {
        ...this.pagingMilestone,
        page: this.currentPage,
      };
    },
    customFormatter(date) {
      var datetime = moment(date).format("MM/DD/YYYY hh:mm A");
      var hh = moment(date).format("hh");
      var mm = moment(date).format("mm");
      var aa = moment(date).format("A");
      if (hh == "12" && mm == "00" && aa == "AM")
        datetime = datetime.replace("12", "00");
      return datetime;
    },
    reselect(isReselect) {
      this.isReselect = isReselect ? false : true;
    },
  },
});
