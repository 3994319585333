import { defineComponent } from "vue";
import DealServices from "../../../services/deal";
import { DEAL_STAGES } from "../../../misc/constants";
import generic from "../../../misc/generic";
import fpcChecklist from "./components/fpc-checklist";
import stage from "./components/stage";
import detail from "./components/detail";
import rating from "./components/rating";
import discussion from "./components/discussion";
import timeline from "./components/timeline-discussion";
import DealHistory from "./components/deal-history";
import fileUpload from "./components/files-upload";
import { checkUserTypeFounder } from "../../../misc/commons";
import documentIcon from "@/assets/images/icon/documents.svg";

export default defineComponent({
  components: {
    DealHistory,
    stage,
    detail,
    rating,
    discussion,
    timeline,
    fileUpload,
    fpcChecklist,
    documentIcon,
  },

  props: {
    idDeal: { type: String, default: null },
  },
  data() {
    return {
      specifyIdDeal: this.idDeal || 1,
      totalStage: DEAL_STAGES,
      currentStage: null,
      dataDeal: null,
      dataDealSubmissionFiles: null,
      stageAllow: null,
      hideRightBlock: true,
      isDraft: false,
      params: "",
      dataRating: null,
      isReloadMessage: false,
      isReloadFileUploaded: false,
    };
  },
  mounted() {
    let vue = this;
    this.$nextTick(() => {
      this.getSpecifyDeals(this.idDeal);
      if (generic.getJsonSessionStorage("deal-query")) {
        this.params = `?${new URLSearchParams(
          generic.getJsonSessionStorage("deal-query")
        ).toString()}`;
      }
    });
  },
  computed: {
    screenSize() {
      return this.$store.state.mobile.size;
    },
  },
  methods: {
    showHistory() {
      this.$refs.dealHistory.showModal();
    },
    getSpecifyDeals: function (id, reload) {
      DealServices.getSpecifyDeals(id)
        .then((resp) => {
          if (resp.data.ok) {
            if (!resp.data.d) {
              this.$router.push("/rfm/deals");
              return;
            }
            this.dataDeal = resp.data.d;
            this.isDraft = this.dataDeal.is_draft;
            this.dataDealSubmissionFiles = resp.data.d.submission_answers
              .filter((x) => x.question_type === "FILE")
              .map((x) => {
                return {
                  name: x.file.file_name,
                  extension: x.file.extension,
                  created_at: resp.data.d.created_at,
                  created_by: resp.data.d.created_by,
                  s3key: x.file.s3key,
                };
              });
          }
        })
        .finally(() => {
          if (!reload) {
            reload = true;
            this.$root.$emit("reloadRating", true);
          }
        });
    },
    hideBlock: function (param) {
      this.hideRightBlock = param;
    },
    isUserTypeFounder: function () {
      return checkUserTypeFounder();
    },
    reloadDeal(data, reload) {
      if (data) {
        this.getSpecifyDeals(data, reload);
      }
    },

    reloadDealMessage() {
      this.isReloadMessage = !this.isReloadMessage;
    },
    reloadFileUploaded() {
      this.isReloadFileUploaded = !this.isReloadFileUploaded;
    },
    getDataRating(data) {
      this.dataRating = data;
    },
  },
  watch: {
    dataDeal: function (value) {
      if (value) {
        this.stageAllow = value.is_stage_allow;

        if (value.stage_id !== "REJECTED") {
          this.currentStage =
            this.totalStage.find((p) => p.id === value.stage_id).order || 0;
        } else {
          this.currentStage = -1;
        }
      }
    },
  },
  beforeUnmount() {
    this.specifyIdDeal = null;
    //#bus on event
    /* kill event bus */
    // this.$root.$off('reloadRating')
    // this.$root.$off('reloadDeal')
    // this.$root.$off('reloadDealMessage')
    // this.$root.$off('reloadDocument')
    // this.$root.$off('dataRating')
  },
});
