<template>
  <div
    class="modal fade"
    v-if="_value"
    :class="[{ show: _value }, classWrapper]"
  >
    <div class="overlay" @click="close"></div>
    <div
      :class="`modal-dialog modal-size-${size} modal-dialog-centered modal-dialog-scrollable`"
    >
      <div class="modal-content" :class="[{ visible: isVisible }]">
        <button
          class="close btn btn-secondary"
          @click="close"
          v-if="hasFloatingCloseButton"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg pointer"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
        <div
          :class="`modal-header modal-direction-${direction}`"
          v-if="!hideHeader"
        >
          <h5
            class="modal-title d-flex w-100"
            :class="alignHeader ? `justify-content-${alignHeader}` : ''"
          >
            {{ title }}
          </h5>
          <slot name="header" />
        </div>
        <div
          class="modal-body"
          :class="[{ 'pb-3': hideFooter }, { visible: isVisible }]"
        >
          <slot />
        </div>
        <div class="modal-footer" v-if="!hideFooter">
          <slot name="footer" v-bind="{ closeMethod: close }" />
          <NewButton
            v-if="showCloseBtn"
            customClass="btn-secondary"
            @click="close"
            >{{ closeBtnText }}</NewButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.modal {
  color: #838894;
  background: rgba($color: #000, $alpha: 0.5);

  .modal-dialog {
    max-width: 700px;
    // height: 100%;
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      display: flex;
      overflow: hidden;
      width: 30px;
      height: 30px;
      //   line-height: 30px;
      background-color: #393d44;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      padding: 2px 0;
      opacity: 1;
      z-index: 2;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      .pe-7s-close::before {
        content: "\e680";
      }
      svg{
        text-align: center;
        display: block;
      }
    }
  }
  .modal-size-2xlg {
    max-width: 1200px;
  }
  .modal-size-lg {
    max-width: 900px;
  }
  .modal-size-md {
    max-width: 700px;
  }
  .modal-size-sm {
    max-width: 500px;
  }

  .modal-header {
    position: relative;
    color: white;
    border-bottom: 1px solid rgba(225, 225, 225, 0.2);
    justify-content: center;
    padding: 1rem;
  }
  .modal-direction-left {
    position: relative;
    justify-content: flex-end;
  }

  .modal-content {
    position: relative;
    &.visible {
      overflow: visible;
    }
  }

  .modal-body {
    padding: 1rem 1rem 0 1rem;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #393c45;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #747474;
      border-radius: 4px;
      transition: all 300ms;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #909090;
      border-radius: 4px;
      transition: all 300ms;
    }
    @media only screen and (max-width: 575px) {
      padding: 1rem;
    }
    &.visible {
      overflow: visible;
    }
  }

  .modal-footer {
    // border: 0;
    border-color: rgba(225, 225, 225, 0.2);
    padding: 1rem;
    justify-content: flex-end;
  }

  &.show {
    display: block;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// .close-btn {
//     position: absolute;
//     right: 1.5rem;
//     top: 50%;
//     transform: translateY(-50%);
//     color: #FFFFFF;
//     cursor: pointer;
//     font-size: 25px;
// }
</style>
