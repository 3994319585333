<template>
  <footer>
    <div class="wrapper-footer">
      <div class="container-v2 top-footer">
        <div class="logo">
          <router-link exact to="/">
            <logo-component />
          </router-link>
        </div>

        <div class="location">
          <h5 class="title">Headquarters</h5>

          <p>2901 Florida Ave, Suite 840, Miami, FL 33133</p>

          <p class="text-link">
            <router-link exact to="/terms-and-conditions">
              Terms and Conditions
            </router-link>
          </p>
          <p class="text-link" v-html="copyright"></p>
        </div>

        <div class="social-wrapper">
          <h5 class="title">Follow Us</h5>
          <div class="social-list">
            <a
              :href="`${social.facebook}`"
              v-if="social.facebook"
              class="social-item"
              target="_blank"
            >
              <svg
                class="iconSocial"
                xmlns="http://www.w3.org/2000/svg"
                width="19.92"
                height="19.92"
                viewBox="0 0 48 48"
              >
                <g class="nc-icon-wrapper" fill="#000000">
                  <path
                    fill="#000000"
                    d="M46,0H2C0.89543,0,0,0.89543,0,2v44c0,1.10457,0.89543,2,2,2h23.63829V29.4119h-6.25529v-7.24417h6.25529 v-5.34235c0-6.19977,3.78661-9.57566,9.31723-9.57566c2.64922,0,4.92609,0.19724,5.58963,0.2854v6.47912l-3.83577,0.00174 c-3.00786,0-3.59026,1.42929-3.59026,3.52668v4.62507h7.17347l-0.93401,7.24417h-6.23946V48H46c1.10457,0,2-0.89543,2-2V2 C48,0.89543,47.10457,0,46,0z"
                  />
                </g>
              </svg>
            </a>
            <a
              :href="`${social.instagram}`"
              v-if="social.instagram"
              class="social-item"
              target="_blank"
            >
              <svg
                class="iconSocial"
                xmlns="http://www.w3.org/2000/svg"
                width="19.633"
                height="19.628"
                viewBox="0 0 19.633 19.628"
              >
                <g
                  id="instagram"
                  transform="translate(0.075 -31.825)"
                  opacity="0.999"
                >
                  <path
                    id="Path_14325"
                    data-name="Path 14325"
                    d="M9.744,36.607a5.032,5.032,0,1,0,5.032,5.032A5.025,5.025,0,0,0,9.744,36.607Zm0,8.3a3.272,3.272,0,1,1,3.272-3.272,3.278,3.278,0,0,1-3.272,3.272Zm6.412-8.51a1.174,1.174,0,1,1-1.174-1.174A1.171,1.171,0,0,1,16.156,36.4Zm3.333,1.191A5.809,5.809,0,0,0,17.9,33.479a5.847,5.847,0,0,0-4.113-1.586c-1.621-.092-6.478-.092-8.1,0A5.839,5.839,0,0,0,1.58,33.475,5.828,5.828,0,0,0-.006,37.588c-.092,1.621-.092,6.478,0,8.1A5.809,5.809,0,0,0,1.58,49.8a5.855,5.855,0,0,0,4.113,1.586c1.621.092,6.478.092,8.1,0A5.809,5.809,0,0,0,17.9,49.8a5.847,5.847,0,0,0,1.586-4.113C19.581,44.066,19.581,39.213,19.489,37.592ZM17.4,47.425a3.312,3.312,0,0,1-1.866,1.866c-1.292.512-4.358.394-5.786.394s-4.5.114-5.786-.394a3.312,3.312,0,0,1-1.866-1.866C1.58,46.133,1.7,43.067,1.7,41.639s-.114-4.5.394-5.786a3.312,3.312,0,0,1,1.866-1.866c1.292-.512,4.358-.394,5.786-.394s4.5-.114,5.786.394A3.312,3.312,0,0,1,17.4,35.853c.512,1.292.394,4.358.394,5.786S17.908,46.137,17.4,47.425Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </a>
            <a
              :href="`${social.linkedin}`"
              v-if="social.linkedin"
              class="social-item"
              target="_blank"
            >
              <svg
                class="iconSocial"
                xmlns="http://www.w3.org/2000/svg"
                width="19.92"
                height="19.92"
                viewBox="0 0 19.92 19.92"
              >
                <g id="linkedin" transform="translate(0 -32)" opacity="0.999">
                  <path
                    id="Path_14326"
                    data-name="Path 14326"
                    d="M18.5,32H1.418A1.429,1.429,0,0,0,0,33.436V50.484A1.429,1.429,0,0,0,1.418,51.92H18.5a1.433,1.433,0,0,0,1.423-1.436V33.436A1.433,1.433,0,0,0,18.5,32ZM6.021,49.074H3.068V39.568H6.025v9.507ZM4.544,38.27a1.712,1.712,0,1,1,1.712-1.712A1.713,1.713,0,0,1,4.544,38.27Zm12.543,10.8H14.135V44.45c0-1.1-.022-2.521-1.534-2.521-1.538,0-1.774,1.2-1.774,2.441v4.7H7.875V39.568h2.832v1.3h.04a3.11,3.11,0,0,1,2.8-1.534c2.988,0,3.544,1.97,3.544,4.531Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </a>
            <a
              :href="`${social.medium}`"
              v-if="social.medium"
              class="social-item"
              target="_blank"
            >
              <svg
                class="iconSocial"
                xmlns="http://www.w3.org/2000/svg"
                width="19.92"
                height="19.92"
                viewBox="0 0 48 48"
              >
                <g class="nc-icon-wrapper" fill="#000000">
                  <path
                    d="M46,0H2A2,2,0,0,0,0,2V46a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V2A2,2,0,0,0,46,0ZM39.877,11.373,37.3,13.841a.752.752,0,0,0-.286.722V32.7a.753.753,0,0,0,.286.722l2.514,2.468v.542H27.171v-.542l2.6-2.528c.256-.256.256-.331.256-.722V17.979L22.79,36.371h-.979l-8.43-18.391V30.305a1.7,1.7,0,0,0,.467,1.415l3.387,4.109v.542h-9.6v-.542l3.387-4.109a1.64,1.64,0,0,0,.437-1.415V16.053A1.249,1.249,0,0,0,11.048,15L8.037,11.373v-.542h9.349l7.226,15.848,6.353-15.848h8.912Z"
                    fill="#000000"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import config from "@/config";

export default {
  name: "Footer",
  data() {
    return {
      social: config.social,
      copyright: "&copy; 2022 Fuel Venture Capital",
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #000000;
}
.wrapper-footer {
  padding: 50px 0;
}

.top-footer {
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 150px;
  }
  .logo {
    max-width: 268px;
    margin: 0 auto;
    padding-bottom: 10px;

    @media screen and (min-width: 992px) {
      margin: unset;
      padding-bottom: unset;
    }
  }

  .location {
    text-align: center;
    @media screen and (min-width: 992px) {
      text-align: unset;
    }
    p {
      padding-bottom: 12px;
      line-height: 22px;
    }
  }

  h5.title {
    display: none;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    padding-bottom: 12px;

    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  .social-wrapper {
    .social-list {
      display: flex;
      justify-content: center;
      gap: 10px;
      @media screen and (min-width: 992px) {
        justify-content: unset;
      }

      .social-item {
        flex-shrink: 0;
        &:hover {
          .iconSocial {
            g {
              path {
                fill: #fff;
              }
            }
          }
        }
        .iconSocial {
          g {
            path {
              fill: #9094ad;
            }
          }
        }
      }
    }
  }
}
</style>
