import { defineComponent } from "vue";
import adminServices from "@/services/admin";
// import notify from '@/services/notify'
// import dialog from '@/services/dialog'
import { PERMISSIONS } from "@/config/router";

import Table, { debounce, DropdownItem, Dropdown } from "@/components/Table";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import Modal from "@/components/Modal";
// import { checkStatus } from '@services/commons'
import _ from "lodash";

export default defineComponent({
  components: {
    Table,
    TextField,
    SelectField,
    Modal,
    DropdownItem,
    Dropdown,
  },
  data() {
    return {
      rows: [],
      totalItems: 0,
      loading: false,
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      filteredName: "",
      filteredEmail: "",
      filteredStatus: "", //ACTIVE
      filteredCreatedName: "",
      filteredCreatedEmail: "",
      filteredDataContent: "",

      isModalShow: false,
      selectedEmail: null,

      roleList: [],
      StatusList: [
        {
          name: "All",
          code: "",
        },
        {
          name: "Active",
          code: "ACTIVE",
        },
        {
          name: "Inactive",
          code: "INACTIVE",
        },
      ],

      PERMISSIONS: PERMISSIONS,
      userManageModelKey: 0,
    };
  },
  watch: {
    "paging.page": function () {
      debounce(this.getAll);
    },
    filteredDataContent() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredEmail() {
      this.setPageDefault()
      debounce(this.getAll);
    },
    filteredCreatedEmail() {
      this.setPageDefault()
      debounce(this.getAll);
    },
  },
  mounted() {
    this.getAll();
  },
  computed: {
    columns() {
      return [
        {
          id: "subject",
          name: "Email Subject",
        },
        {
          id: "created_username",
          name: "Sender",
          // sort: true
        },
        {
          id: "username",
          name: "Recipient",
          // sort: true
        },
        {
          id: "created_at",
          name: "Created At",
        },
        {
          id: "status",
          name: "Status",
        },
        {
          id: "actions",
          name: "Actions",
          titleClass: "text-center",
          columnClass: "text-center",
        },
      ];
    },
  },
  methods: {
    setPageDefault() {
      this.paging = {
        page: 1,
        size: 10,
        total: 0,
      };
    },
    async getAll() {
      this.loading = true;
      const res = await adminServices.getEmailQueue({
        paging: {
          page: this.paging.page,
          size: this.paging.size,
        },
        orderBy:
          this.paging.sorts.length > 0
            ? `${this.paging.sorts[0].id} ${this.paging.sorts[0].sort}`
            : "",
        filteredName: this.filteredName,
        filteredEmail: this.filteredEmail,
        filteredStatus: this.filteredStatus,
        filteredCreatedName: this.filteredCreatedName,
        filteredCreatedEmail: this.filteredCreatedEmail,
        filteredDataContent: this.filteredDataContent,
      });
      if (res && res.data && res.data.ok) {
        this.rows = res.data.d.c;
        this.totalItems = res.data.d.t;
      }
      this.loading = false;
    },
    renderJson(data) {
      let html = "";
      for (var key of Object.keys(data)) {
        html += `<div><span class="font-weight-bold">${key}</span>: ${data[key]}</div>`;
      }
      return html;
    },
    mapData(text, jsonData) {
      if (!_.isObject(jsonData)) return "";
      for (const property in jsonData) {
        text = text
          ? text.replace("${" + property + "}", jsonData[property])
          : text;
      }
      return text;
    },
    async view(props) {
      this.isModalShow = true;
      this.selectedEmail = props;
    },
    updatePaging(v) {
      this.paging = Object.assign(this.paging, v);
    },
  },
});
