import { defineComponent } from "vue";

export default defineComponent({
  props: {
    closeModalConfirm: Function,
    onConfirm: Function,
    title: String,
    message: String,
  },
});
