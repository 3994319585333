import pdf from '@/components/commons/pdf-viewer'
import appStore from '@/store/app'
import conf from '@/config'
import AdminServices from '@/services/admin'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      isShow: false,
      baseDocsUrl: conf.base_docs_url,
      fileUrl: '',
      errorMsg: '',
      originFileUrl: ''
    }
  },
  components: {
    pdf
  },
  computed: {
    scale() {
      return appStore.state.mobile.isMobile ? 'page-width' : 'page-actual'
    },
    fileType() {
      if (this.originFileUrl) {
        return this.getFileType(this.originFileUrl)
      } else {
        return ''
      }
    }
  },
  created() {
    if (this.$route.query.file) {
      this.isShow = true
      this.originFileUrl = this.$route.query.file
      this.fileUrl = this.baseDocsUrl + this.$route.query.file + '#toolbar=0'
    } else if (this.$route.query.itemid) {
      this.isShow = true
      AdminServices.requestDownloadCIOAndCountView(this.$route.query.itemid, 'View').then(resp => {
        if (resp.data.ok) {
          this.originFileUrl = resp.data.d
          this.fileUrl = resp.data.d + '#toolbar=0'
        } else {
          if (resp.data && resp.data.m) {
            this.errorMsg = resp.data.m
          } else if (resp && resp.data && resp.data.response && resp.data.response.status === 403) {
            this.errorMsg = 'You are not allowed to view this file'
          } else {
            this.errorMsg = 'Oops! An error has occurred. Please try again later.'
          }
        }
      })
    }
  },
  methods: {
    handler: function(e) {
      e.preventDefault()
    },
    getFileType(fileLink) {
      let ext_part = fileLink.split('.')[fileLink.split('.').length - 1]
      let ext = ext_part ? ext_part.split('&')[0] : ''
      if (['pdf'].includes(ext)) {
        return 'pdf'
      } else if (['gif', 'jpg', 'jpeg', 'png'].includes(ext)) {
        return 'image'
      } else if (['mp4'].includes(ext)) {
          return 'video'
      } else {
        return ''
      }
    }
  }
})