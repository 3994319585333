import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'selectUser',
  props: {
      options: {
        type: Array,
        required: true
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0
      },
      value: { },
      label: String | null,
      isMultiple: {
        type: Boolean,
        default: false,
        required: false
      },
      required: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    $emit: ['update:value'],
    data() {
      return {
        selectedOption: null,
        selectedOptions: [],
        open: false,
        searchKey: '',
        filterTimer: null,
        filteredOptions: []
      }
    },
    mounted() {
      this.filteredOptions = _.cloneDeep(this.options)

      if (this.isMultiple) {
        this.filteredOptions.forEach(x => {
          if (this.value.includes(x.value)) {
            this.selectedOptions.push(x)
          }
        })
      } else {
        this.selectedOption = this.filteredOptions.find(x => x.value === this.value)
      }
    },
    created() {
      window.addEventListener('click', this.closeDropdown)
    },
    beforeUnmount() {
      window.removeEventListener('click', this.closeDropdown)
    },
    watch: {
      value: function(v) {
        if(!v) {
          if (this.isMultiple) {
            this.selectedOptions = []
          } else {
            this.selectedOption = null
          }
        }
      }
    },
    methods: {
      closeDropdown(e) {
        if (!($(e.target).hasClass('selected-multiple') || $(e.target).parents('.selected-multiple').length > 0 || $(e.target).parents('.selectUserEngagement').length > 0)) {
          this.open = false
          this.searchKey = ''
          this.filteredOptions = _.cloneDeep(this.options)
        }
      },
      onSelect(option) {
        if (this.isMultiple) {
          let _index = this.selectedOptions.findIndex(x => x.value === option.value)
          if(_index === -1) {
            this.selectedOptions.push(option)
          } else {
            this.selectedOptions.splice(_index,1)
          }
          this.$emit('update:value', this.selectedOptions.map(x => x.value))
        } else {
          this.selectedOption = option
          this.open = false
          this.$emit('update:value', option.value)
        }
      },
      onRemove(option) {
        if (this.isMultiple) {
          let _index = this.selectedOptions.findIndex(x => x.value === option.value)
          if(_index > -1) {
            this.selectedOptions.splice(_index,1)
            this.$emit('update:value', this.selectedOptions.map(x => x.value))
          }
        }
      },
      onClickSelected() {
        this.open = !this.open
        this.$nextTick(() => {
          if (this.open && !this.isMultiple) {
            this.clearSearch()
            if (this.selectedOption) {
              this.searchKey = `${this.selectedOption.fname ? this.selectedOption.fname : ''} ${this.selectedOption.lname ? this.selectedOption.lname : ''}`.trim()
            } else {
              this.searchKey = ''
            }
            this.$refs.inlineSearchBox.focus()
          }
        })
      },
      checkSelected(option) {
        if (this.isMultiple && this.selectedOptions) {
          return this.selectedOptions.find(x => x.value === option.value) ? true : false
        }
      },

      delayedFilter() {
        if (this.filterTimer != null) {
            clearTimeout(this.filterTimer)
            this.filterTimer = null
        }
        this.filterTimer = setTimeout(this.doFilter, 500)
      },
      doFilter() {
          this.filterTimer = null
          this.onSearch(this.searchKey)
      },

      onSearch(key) {
          const _key = key.trim().toLowerCase()
          if (_key) {
            this.filteredOptions = this.options.filter(x => (x.fname && x.fname.toLowerCase().indexOf(_key) > -1) || (x.lname && x.lname.toLowerCase().indexOf(_key) > -1) || (x.fname && x.lname && (x.fname + ' ' + x.lname).toLowerCase().indexOf(_key) > -1) || (x.email && x.email.toLowerCase().indexOf(_key) > -1))
          } else {
            this.filteredOptions = _.cloneDeep(this.options)
          }
      },

      clearSearch() {
        this.searchKey = ''
        this.filteredOptions = _.cloneDeep(this.options)
      },

      clearSearchInline() {
        this.searchKey = ''
        this.filteredOptions = _.cloneDeep(this.options)
        this.$refs.inlineSearchBox.focus()
      }
    }
})