<template>
  <!-- #todo verify conditions below this line -->
  <div
    v-if="
      isShowAdminSidebar &&
      (!currentRouter.meta.showSidebar ||
        currentRouter.meta.showSidebar === undefined ||
        currentRouter.meta.showSidebar === true)
    "
    :class="`nav-sidebar ${windowWidth < 576 ? 'custom-menu' : ''} `"
  >
    <nav id="sidebar">
      <div class="sidebar-header">
        <a href="/" class="logo">
          <logo-component :size-width="200" />
        </a>
      </div>

      <ul class="list-unstyled listSessions">
        <template v-if="['Rokker', 'IDC'].includes(profile.userType)">
          <li>
            <router-link
              :to="overviewPath"
              router-link-exact-active
              :class="renderActive(overviewPath)"
              >{{ overviewName }}</router-link
            >
          </li>
          <li v-if="hasPermission(PERMISSIONS.Surveys_Read)">
            <router-link
              :to="surveyPath"
              router-link-exact-active
              :class="renderActive(surveyPath)"
              >{{ surveyName }}</router-link
            >
          </li>

          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Partners_KPI_Report,
                PERMISSIONS.Partners_KPI_Manage,
                PERMISSIONS.IDC_Partners_KPI_Report,
                PERMISSIONS.IDC_Partners_KPI_Manage,
              ])
            "
          >
            <a
              href="#kpi"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="kpi"
              role="button"
              class="dropdown-toggle"
              :class="{ collapsed: !isPathActive([reportPath, partnerPath]) }"
              >KPI</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{ show: isPathActive([reportPath, partnerPath]) }"
              id="kpi"
            >
              <li
                v-if="
                  hasPermissions([
                    PERMISSIONS.Partners_KPI_Report,
                    PERMISSIONS.IDC_Partners_KPI_Report,
                  ])
                "
              >
                <router-link
                  :to="reportPath"
                  router-link-exact-active
                  :class="renderActive(reportPath)"
                  >{{ reportName }}</router-link
                >
              </li>
              <li
                v-if="
                  hasPermissions([
                    PERMISSIONS.Partners_KPI_Manage,
                    PERMISSIONS.IDC_Partners_KPI_Manage,
                  ])
                "
              >
                <router-link
                  :to="partnerPath"
                  router-link-exact-active
                  :class="renderActive(partnerPath)"
                  >{{ partnerName }}</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Members_Read,
                PERMISSIONS.Funds_Read,
                PERMISSIONS.Portfolios_Read,
                PERMISSIONS.News_Read,
                PERMISSIONS.Users_Read,
                PERMISSIONS.Founders_Read,
                PERMISSIONS.Guest_Registration_Report,
                PERMISSIONS.Perspective_Read,
                PERMISSIONS.MeetFounder_Read,
              ])
            "
          >
            <a
              href="#backoffice"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="backoffice"
              role="button"
              class="dropdown-toggle"
              :class="{
                collapsed: !isPathActive([
                  teamPath,
                  fundPath,
                  portfolioPath,
                  fuelTheMindPath,
                  usersPath,
                  foundersPath,
                  profilePath,
                  roboticadminPath,
                  perspectivePath,
                  meetFounderPath,
                ]),
              }"
              >Back Office</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{
                show: isPathActive([
                  teamPath,
                  fundPath,
                  portfolioPath,
                  fuelTheMindPath,
                  usersPath,
                  foundersPath,
                  profilePath,
                  roboticadminPath,
                  perspectivePath,
                  meetFounderPath,
                ]),
              }"
              id="backoffice"
            >
              <li
                v-if="
                  hasPermissions([
                    PERMISSIONS.Members_Read,
                    PERMISSIONS.Funds_Read,
                    PERMISSIONS.Portfolios_Read,
                    PERMISSIONS.News_Read,
                    PERMISSIONS.Perspective_Read,
                    PERMISSIONS.MeetFounder_Read,
                  ])
                "
              >
                <a
                  href="#webManagement"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="webManagement"
                  class="dropdown-toggle"
                  :class="{
                    collapsed: !isPathActive([
                      teamPath,
                      fundPath,
                      portfolioPath,
                      fuelTheMindPath,
                      perspectivePath,
                      meetFounderPath,
                    ]),
                  }"
                  >Website Management</a
                >
                <ul
                  class="collapse list-unstyled listSubmenu2"
                  :class="{
                    show: isPathActive([
                      teamPath,
                      fundPath,
                      portfolioPath,
                      fuelTheMindPath,
                      perspectivePath,
                      meetFounderPath,
                    ]),
                  }"
                  id="webManagement"
                >
                  <li v-if="hasPermission(PERMISSIONS.Members_Read)">
                    <router-link
                      :to="teamPath"
                      router-link-exact-active
                      :class="renderActive(teamPath)"
                      >{{ teamName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.Members_Read)">
                    <router-link
                      :to="fundPath"
                      router-link-exact-active
                      :class="renderActive(fundPath)"
                      >{{ fundName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.Portfolios_Read)">
                    <router-link
                      :to="portfolioPath"
                      router-link-exact-active
                      :class="renderActive(portfolioPath)"
                      >{{ portfolioName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.News_Read)">
                    <router-link
                      :to="fuelTheMindPath"
                      router-link-exact-active
                      :class="renderActive(fuelTheMindPath)"
                      >{{ fuelTheMindName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.Perspective_Read)">
                    <router-link
                      :to="perspectivePath"
                      router-link-exact-active
                      :class="renderActive(perspectivePath)"
                      >{{ perspectiveName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.MeetFounder_Read)">
                    <router-link
                      :to="meetFounderPath"
                      router-link-exact-active
                      :class="renderActive(meetFounderPath)"
                      >{{ meetFounderName }}</router-link
                    >
                  </li>
                </ul>
              </li>

              <li
                v-if="
                  hasPermissions([
                    PERMISSIONS.Users_Read,
                    PERMISSIONS.Founders_Read,
                    PERMISSIONS.Guest_Registration_Report,
                  ])
                "
              >
                <a
                  href="#rightsmanagement"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="rightsmanagement"
                  class="dropdown-toggle"
                  :class="{
                    collapsed: !isPathActive([
                      usersPath,
                      foundersPath,
                      profilePath,
                      roboticadminPath,
                    ]),
                  }"
                  >Rights Management</a
                >
                <ul
                  class="collapse list-unstyled listSubmenu2"
                  :class="{
                    show: isPathActive([
                      usersPath,
                      foundersPath,
                      profilePath,
                      roboticadminPath,
                    ]),
                  }"
                  id="rightsmanagement"
                >
                  <li v-if="hasPermission(PERMISSIONS.Users_Read)">
                    <router-link
                      :to="usersPath"
                      router-link-exact-active
                      :class="renderActive(usersPath)"
                      >{{ usersName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.Founders_Read)">
                    <router-link
                      :to="foundersPath"
                      router-link-exact-active
                      :class="renderActive(foundersPath)"
                      >{{ foundersName }}</router-link
                    >
                  </li>
                  <li v-if="hasPermission(PERMISSIONS.Users_Read)">
                    <router-link
                      :to="profilePath"
                      router-link-exact-active
                      :class="renderActive(profilePath)"
                      >{{ profileName }}</router-link
                    >
                  </li>
                  <li
                    v-if="hasPermission(PERMISSIONS.Guest_Registration_Report)"
                  >
                    <router-link
                      :to="roboticadminPath"
                      router-link-exact-active
                      :class="renderActive(roboticadminPath)"
                      >{{ roboticadminName }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Investors_Read,
                PERMISSIONS.InvestorRoles_Read,
                PERMISSIONS.MessageTemplates_Read,
                PERMISSIONS.LpMedia_Read,
                PERMISSIONS.Notification_Read,
              ])
            "
          >
            <a
              href="#lpportal"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="true"
              aria-controls="lpportal"
              class="dropdown-toggle"
              :class="{
                collapsed: !isPathActive([
                  investorsPath,
                  investorRolesPath,
                  messageTmplsPath,
                  // lPMediaIntroVideoPath,
                  lPMediaCallReplayPath,
                  lPMediaCoInvestmentOpPath,
                  lPMediaSeededCompaniesPath,
                  notificationsPath,
                  mapchartPath,
                  lpFundPath,
                  newsLetterPath,
                ]),
              }"
              >LP Portal</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{
                show: isPathActive([
                  investorsPath,
                  investorRolesPath,
                  messageTmplsPath,
                  // lPMediaIntroVideoPath,
                  lPMediaCallReplayPath,
                  lPMediaCoInvestmentOpPath,
                  notificationsPath,
                  mapchartPath,
                  lpFundPath,
                  newsLetterPath,
                ]),
              }"
              id="lpportal"
            >
              <li v-if="hasPermission(PERMISSIONS.LPFunds_Read)">
                <router-link
                  :to="lpFundPath"
                  router-link-exact-active
                  :class="renderActive(lpFundPath)"
                  >{{ lpFundName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.Investors_Read)">
                <router-link
                  :to="investorsPath"
                  router-link-exact-active
                  :class="renderActive(investorsPath)"
                  >{{ investorsName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.InvestorRoles_Read)">
                <router-link
                  :to="investorRolesPath"
                  router-link-exact-active
                  :class="renderActive(investorRolesPath)"
                  >{{ investorRolesName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.MessageTemplates_Read)">
                <router-link
                  :to="messageTmplsPath"
                  router-link-exact-active
                  :class="renderActive(messageTmplsPath)"
                  >{{ messageTmplsName }}</router-link
                >
              </li>
              <!-- <li v-if="hasPermission(PERMISSIONS.LpMedia_Read)">
                <router-link
                  :to="lPMediaIntroVideoPath"
                  router-link-exact-active
                  :class="renderActive(lPMediaIntroVideoPath)"
                  >{{ lPMediaIntroVideoName }}</router-link
                >
              </li> -->
              <li v-if="hasPermission(PERMISSIONS.LpMedia_Read)">
                <router-link
                  :to="newsLetterPath"
                  router-link-exact-active
                  :class="renderActive(newsLetterPath)"
                  >{{ newsLetterName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.LpMedia_Read)">
                <router-link
                  :to="lPMediaCallReplayPath"
                  router-link-exact-active
                  :class="renderActive(lPMediaCallReplayPath)"
                  >{{ lPMediaCallReplayName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.LpMedia_Read)">
                <router-link
                  :to="lPMediaCoInvestmentOpPath"
                  router-link-exact-active
                  :class="renderActive(lPMediaCoInvestmentOpPath)"
                  >{{ lPMediaCoInvestmentOpName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.LpMedia_Read)">
                <router-link
                    :to="lPMediaSeededCompaniesPath"
                    router-link-exact-active
                    :class="renderActive(lPMediaSeededCompaniesPath)"
                >{{ lPMediaSeededCompaniesName }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="notificationsPath"
                  router-link-exact-active
                  :class="renderActive(notificationsPath)"
                  >{{ notificationsName }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="mapchartPath"
                  router-link-exact-active
                  :class="renderActive(mapchartPath)"
                  >{{ mapchartName }}</router-link
                >
              </li>
            </ul>
          </li>
          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Report_Dataroom_InvestorEngagement,
                PERMISSIONS.Report_Dataroom_InvestorFocus,
              ])
            "
          >
            <a
              href="#reports"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="reports"
              role="button"
              class="dropdown-toggle"
              :class="{
                collapsed: !isPathActive([
                  rptInvestorEngagementPath,
                  rptInvestorFocusPath,
                ]),
              }"
              >Reports</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{
                show: isPathActive([
                  rptInvestorEngagementPath,
                  rptInvestorFocusPath,
                ]),
              }"
              id="reports"
            >
              <li
                v-if="
                  hasPermissions([
                    PERMISSIONS.Report_Dataroom_InvestorEngagement,
                    PERMISSIONS.Report_Dataroom_InvestorFocus,
                  ])
                "
              >
                <a
                  href="#rptdataroom"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="rptdataroom"
                  class="dropdown-toggle"
                  :class="{
                    collapsed: !isPathActive([
                      rptInvestorEngagementPath,
                      rptInvestorFocusPath,
                    ]),
                  }"
                  >Dataroom Tracking</a
                >
                <ul
                  class="collapse list-unstyled listSubmenu2"
                  :class="{
                    show: isPathActive([
                      rptInvestorEngagementPath,
                      rptInvestorFocusPath,
                    ]),
                  }"
                  id="rptdataroom"
                >
                  <li
                    v-if="
                      hasPermission(
                        PERMISSIONS.Report_Dataroom_InvestorEngagement
                      )
                    "
                  >
                    <router-link
                      :to="rptInvestorEngagementPath"
                      router-link-exact-active
                      :class="renderActive(rptInvestorEngagementPath)"
                      >{{ rptInvestorEngagementName }}</router-link
                    >
                  </li>
                  <li
                    v-if="
                      hasPermission(PERMISSIONS.Report_Dataroom_InvestorFocus)
                    "
                  >
                    <router-link
                      :to="rptInvestorFocusPath"
                      router-link-exact-active
                      :class="renderActive(rptInvestorFocusPath)"
                      >{{ rptInvestorFocusName }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            v-if="
              profile.userType === 'Rokker' &&
              hasPermission(PERMISSIONS.Dataroom_Read)
            "
          >
            <router-link
              :to="dataroomPath"
              router-link-exact-active
              :class="renderActive(dataroomPath)"
              >{{ dataroomName }}</router-link
            >
          </li>
          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Config_Investors_Read,
                PERMISSIONS.Config_InvestorRoles_Read,
                PERMISSIONS.SystemParam_InstagramAccessToken,
              ])
            "
          >
            <a
              href="#configurations"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="configurations"
              role="button"
              class="dropdown-toggle"
              :class="{
                collapsed: !isPathActive([
                  socialPath,
                  imConfigPath,
                  irConfigPath,
                ]),
              }"
              >Configurations</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{
                show: isPathActive([socialPath, imConfigPath, irConfigPath]),
              }"
              id="configurations"
            >
              <li
                v-if="
                  hasPermission(PERMISSIONS.SystemParam_InstagramAccessToken)
                "
              >
                <router-link
                  :to="socialPath"
                  router-link-exact-active
                  :class="renderActive(socialPath)"
                  >{{ socialName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.Config_Investors_Read)">
                <router-link
                  :to="imConfigPath"
                  router-link-exact-active
                  :class="renderActive(imConfigPath)"
                  >{{ imConfigName }}</router-link
                >
              </li>
              <li v-if="hasPermission(PERMISSIONS.Config_InvestorRoles_Read)">
                <router-link
                  :to="irConfigPath"
                  router-link-exact-active
                  :class="renderActive(irConfigPath)"
                  >{{ irConfigName }}</router-link
                >
              </li>
            </ul>
          </li>
          <li
            v-if="
              hasPermissions([
                PERMISSIONS.Deal_Read,
                PERMISSIONS.Deal_StageReviewer_Read,
              ])
            "
          >
            <a
              href="#deal-management"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="deal-management"
              role="button"
              class="dropdown-toggle"
              :class="{ collapsed: !isPathActive([dealPath, stagesPath]) }"
              >Deal Management</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{ show: isPathActive([dealPath, stagesPath]) }"
              id="deal-management"
            >
              <!-- <li v-if="hasPermission(PERMISSIONS.Deal_Read)">
                                <router-link :to="dealOverviewPath" router-link-exact-active>{{dealOverviewName}}</router-link>
                            </li> -->
              <li v-if="hasPermission(PERMISSIONS.Deal_Read)">
                <router-link
                  :to="dealPath"
                  router-link-exact-active
                  :class="renderActive(dealPath)"
                  >{{ dealName }}</router-link
                >
              </li>
              <!-- <li v-if="hasPermission(PERMISSIONS.Deal_Read)">
                                <router-link :to="dealReportPath" router-link-exact-active>{{dealReportName}}</router-link>
                            </li> -->
              <li v-if="hasPermission(PERMISSIONS.Deal_StageReviewer_Read)">
                <router-link
                  :to="stagesPath"
                  router-link-exact-active
                  :class="renderActive(stagesPath)"
                  >{{ stagesName }}</router-link
                >
              </li>
            </ul>
          </li>
          <li v-if="hasPermission(PERMISSIONS.Event_Read)">
            <router-link
              :to="eventPath"
              router-link-exact-active
              :class="renderActive(eventPath)"
              >{{ eventName }}</router-link
            >
          </li>
          <li v-if="hasPermissions([PERMISSIONS.EmailQueue_Read])">
            <a
              href="#it-support"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="it-support"
              role="button"
              class="dropdown-toggle"
              :class="{
                collapsed: !isPathActive([
                  emailQueuePath,
                  userLoginHistoryPath,
                ]),
              }"
              >IT Support</a
            >
            <ul
              class="collapse list-unstyled listSubmenu"
              :class="{
                show: isPathActive([emailQueuePath, userLoginHistoryPath]),
              }"
              id="it-support"
            >
              <li v-if="hasPermissions([PERMISSIONS.EmailQueue_Read])">
                <router-link
                  :to="emailQueuePath"
                  router-link-exact-active
                  :class="renderActive(emailQueuePath)"
                  >{{ emailQueueName }}</router-link
                >
              </li>
              <li v-if="hasPermissions([PERMISSIONS.Users_Read])">
                <router-link
                  :to="userLoginHistoryPath"
                  router-link-exact-active
                  :class="renderActive(userLoginHistoryPath)"
                  >{{ userLoginHistoryName }}</router-link
                >
              </li>
            </ul>
          </li>
        </template>
        <template v-else>
          <li v-if="profile.userType === 'Founder'">
            <router-link
              :to="dashboardPath"
              :class="renderActive(dashboardPath)"
              >{{ dashboardName }}</router-link
            >
          </li>
          <li v-if="profile.is_partner">
            <router-link :to="kpiPath" :class="renderActive(kpiPath)">{{
              kpiName
            }}</router-link>
          </li>
          <li v-if="profile.is_partner">
            <router-link
              :to="reportPath"
              router-link-exact-active
              :class="renderActive(reportPath)"
              >{{ reportName }}</router-link
            >
          </li>
          <li v-if="profile.is_partner">
            <router-link
              :to="kpiViewPath"
              router-link-exact-active
              :class="renderActive(kpiViewPath)"
              >{{ kpiViewName }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="dealFounderPath"
              router-link-exact-active
              :class="renderActive(dealFounderPath)"
              >{{ dealFounderName }}</router-link
            >
          </li>
        </template>
        <template v-if="['Founder', 'IDC'].includes(profile.userType)">
          <li>
            <router-link
              :to="sharedUserDataroomPath"
              router-link-exact-active
              :class="renderActive(sharedUserDataroomPath)"
              >{{ sharedUserDataroomName }}</router-link
            >
          </li>
        </template>
        <!-- <li>
                    <router-link :to="profilePath" router-link-exact-active>{{profileName}}</router-link>
                </li>
                <li>
                    <a href="javascript: void(0);" @click="logout">Logout</a>
                </li> -->
      </ul>
    </nav>
  </div>
</template>
<style lang="scss" scoped>
.listSessions {
  height: calc(100vh - 86px);
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #393c45;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 4px;
    transition: all 300ms;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #909090;
    border-radius: 4px;
    transition: all 300ms;
  }
}
#sidebar {
  .logo {
    :deep() svg {
      padding: 8px 0;
    }
  }
}
.dropdown-toggle {
  position: relative;

  &::after {
    right: 15px;
    position: absolute;
    top: 13px;
  }

  &.collapsed::after {
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    top: 13px;
  }
}
</style>
<script src="./index.js"></script>
