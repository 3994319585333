<template>
  <Modal
    :title="stageInfo?.name"
    class="adminContent p-3"
    size="lg"
    :closeBtnText="`Close`"
    :showCloseBtn="false"
    v-model:value="isShow"
    @close="refresh"
  >
    <div class="row mb-3">
      <div class="col">
        <ul class="nav">
          <li class="nav-item" v-for="(item, idx) in tabs" :key="idx">
            <a
              class="nav-link"
              :class="{ active: tab_selected && tab_selected.id === item.id }"
              href="javascript:void(0)"
              @click="changeTabs(item)"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div
      class="row"
      v-loading="loading"
      v-show="tab_selected && tab_selected.id === 0"
    >
      <div class="col col-12">
        <div class="row">
          <div class="col col-12 col-md-4">
            <TextField
              title="Team Deal Deadline (days)"
              name="deathline"
              placeholder="Input day"
              :autocomplete="false"
              :errorMsg="errorMsg.death_line_in_day"
              v-model:value="death_line_in_day"
              className="mb-2"
            />
          </div>
          <div class="col col-12 col-md-4">
            <TextField
              title="Investor Deal Deadline (days)"
              placeholder="Input day"
              name="dayinvestor"
              :autocomplete="false"
              :errorMsg="errorMsg.death_line_in_day_investor"
              v-model:value="death_line_in_day_investor"
              className="mb-2"
            />
          </div>
          <div class="col col-12 col-md-4">
            <TextField
              title="Public Deal Deadline (days)"
              name="daypublic"
              placeholder="Input day"
              :autocomplete="false"
              :errorMsg="errorMsg.death_line_in_day_public"
              v-model:value="death_line_in_day_public"
              className="mb-2"
            />
          </div>
          <div class="col col-12 col-md-4">
            <TextField
              title="Interval Days"
              name="interval_in_day"
              placeholder="Input hour"
              :autocomplete="false"
              :errorMsg="errorMsg.notify_interval_in_hour"
              v-model:value="interval_in_day"
              className="mb-2"
            />
          </div>
          <div class="col col-12 col-md-4">
            <Datepicker
              title="Notify Hour"
              :placeholder="`hh:mm a`"
              v-model:value="notify_hour"
              :is24="false"
              :errorMsg.="errorMsg.notify_before_hour"
              :isTimePicker="true"
              format="HH:mm a"
              name="notify_hour"
              field="black"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="tab_selected && tab_selected.id === 1">
      <div class="col col-12">
        <Table
          :paging="paging"
          @updatePaging="updatePaging"
          :columns="columns"
          :rows="rows"
          :total-items="totalItems"
          :showNoRow="true"
          :useSimpleLayout="true"
          v-loading="loading"
        >
          <template #row_name="{ props }">
            {{ props.user_name }}
          </template>
          <template #row_email="{ props }">
            {{ props.email }}
          </template>
          <template #row_sms="{ props }">
            <div class="custom-control custom-switch">
              <Switcher
                @onChangeValue="changeReviewSetting('notify_sms', props)"
                v-model:value="props.notify_sms"
                size="small"
                :isShowText="false"
                :name="`sms_${props.user_id}`"
              />
            </div>
          </template>
          <template #row_notify_email="{ props }">
            <div class="custom-control custom-switch">
              <Switcher
                @onChangeValue="changeReviewSetting('notify_email', props)"
                v-model:value="props.notify_email"
                size="small"
                :isShowText="false"
                :name="`email_${props.user_id}`"
              />
            </div>
          </template>
          <template #row_actions="{ props }">
            <button
              title="Delete"
              type="button"
              class="btn btn-danger btn-small"
              @click="deleteReviewer(props)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </template>
        </Table>
      </div>
    </div>

    <template #footer="{ closeMethod }">
      <div class="col" v-if="tab_selected && tab_selected.id === 0">
        <div class="row">
          <div class="col styleSave text-center">
            <button
              class="btn btn-primary me-2"
              @click="save"
              :disabled="loading"
              :loading="loading"
            >
              Update
            </button>
            <button class="btn btn-secondary" @click="closeMethod">
              Close
            </button>
          </div>
        </div>
      </div>

      <div
        class="col"
        v-if="componentInit && tab_selected && tab_selected.id === 1"
      >
        <div class="row">
          <div class="col styleButton text-center">
            <button class="btn btn-primary me-1" @click="AddReviewer()">
              Add reviewer
            </button>
            <button class="btn btn-secondary" @click="closeMethod">
              Close
            </button>
          </div>
        </div>
      </div>
    </template>

    <Dialog ref="dialog" />

    <AddReviewerModel
      ref="addReviewerModel"
      :key="addReviewerModelkey"
      :roleList="roleList"
      :stageId="stageInfo?.stage_id"
      :existData="rows"
      :exceptUserIds="exceptUserIds"
      @refresh="getReviewers"
    />
  </Modal>
</template>

<script src="./UpdateReviewerModel.js"></script>
<style lang="scss">
.timepicker {
  .time-picker {
    width: 100%;
    input {
      width: 100% !important;
      &.display-time {
        background-color: #494b54;
        border: 0px;
        color: #949ba2;
        &::placeholder {
          color: #949ba2;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.styleSave {
  // display: flex;
  align-items: center;
  // justify-content: flex-end;
}
.styleName {
  font-size: 0.8em;
  display: flex;
  flex-direction: column-reverse;
}
.styleButton {
  // display:flex;
  // justify-content: flex-end;
}
.nav {
  position: relative;
  &::after {
    content: "";
    height: 2px;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #e4e7ed;
  }
  .nav-item {
    position: relative;
    z-index: 2;
    a {
      font-size: 14px;
      color: #cdcdcd;
      line-height: 40px;
      display: block;
      padding: 0 15px;
      border-bottom: 2px solid transparent;
      &.active {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }
    }
  }
}
</style>
