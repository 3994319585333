<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideAddEditBookmark"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">{{formType}} bookmark</h4>
                    </div>
                    <div class="modal-body" v-loading="loading">
                        <form @submit.prevent="save" autocomplete="false">
                            <div class="row mb-2">
                                <div class="col col-6">
                                    <div class="form-group">
                                        <label for="newName">Name</label>
                                        <input type="text" class="form-control" id="name" autocomplete="off" v-model="form.name" maxlength="255" />
                                        <p class="m-0" v-if="errorMsg.name">
                                            <small class="colorRed">{{errorMsg.name}}</small>
                                        </p>
                                    </div>                                
                                </div>
                                <div class="col col-6">
                                    <div class="form-group">
                                        <label for="newName">Url</label>
                                        <input type="text" class="form-control" id="url" autocomplete="off" v-model="form.url" maxlength="255" />
                                        <p class="m-0" v-if="errorMsg.url">
                                            <small class="colorRed">{{errorMsg.url}}</small>
                                        </p>
                                    </div>                                
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-secondary" @click="hideAddEditBookmark">Cancel</button>
                        <button class="btn btn-primary" @click="save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
</style>