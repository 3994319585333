import { BUS_TYPE } from "../../../misc/bus-type";
import { defineComponent } from "vue";
export default defineComponent({
  name: "customNotify",
  props: ["status", "message"],
  data() {
    return {
      enable: false,
      timeout: null,
      title: "",
      list: [],
    };
  },
  methods: {
    setNotify(status, title, message) {
      if (status === "hidden") {
        this.disableNotify();
        return;
      }
      // this.status = status
      // this.title = title
      // this.message = message ? message : title // if message is empty --> use title
      if (title !== undefined) {
        const item = {
          id: Math.floor(Math.random() * 10000000000 + 1),
          message: title,
          status: status,
          enable: false,
        };

        this.list.push(item);
        setTimeout(() => {
          this.enabelNotify(item.id);
        }, 100);
      }
    },
    clearTimeout() {
      clearTimeout(this.timeout);
      this.timeout = null;
    },
    enabelNotify(id) {
      const index = this.list.findIndex((x) => x.id === id);

      if (index !== -1) {
        this.list[index].enable = true;
        setTimeout(() => {
          this.disableNotify(id);
        }, 4000);
      }
    },
    disableNotify(id) {
      const index = this.list.findIndex((x) => x.id === id);
      if (index !== -1) {
        this.list[index].enable = false;
        const idNotify = document.getElementById(id);
        setTimeout(() => {
          idNotify.remove();
        }, 3000);
      }
    },
  },
  watch: {
    $route() {
      this.disableNotify();
    },
  },
});
