<template>
  <transition name="fade">
    <div>
      <div
        v-if="viewType === 'chart'"
        :class="{
          detailView: detailView,
          'line-chart':
            chartData.data.chart && chartData.data.chart.type === 'line',
          'legend-top':
            chartData.data.legend && chartData.data.legend.position === 'top',
        }"
      ></div>
      <div
        v-if="viewType === 'table' && tblData"
        class="d-flex flex-column align-items-center"
      >
        <div class="scroll-x">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th v-for="(cat, index) in tblData.categories" :key="index">
                  {{ cat }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in tblData.rows" :key="row">
                <td>{{ row.name }}</td>
                <td v-for="(val, rowIdx) in row.data" :key="rowIdx">
                  {{ formatTableValue(val, row.formatterName) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button class="btn btn-primary btn-small" @click="downloadXlsx">
          <i class="fas fa-file-excel me-1"></i>Export
        </button>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.scroll-x {
  overflow-x: auto;
  width: 100%;
}
</style>
