import AdminServices from "../../../services/admin";
import _ from "lodash";
import conf from "../../../config/index";
import { router, USER_TYPE } from "@/config/router";
import notify from "../../../services/notify";
import appStore, { APP_MUTATION, AUTH_ACTIONS } from "../../../store/app";
import { defineComponent } from "vue";
import logo from "@/components/commons/logo";
import CompletedIcon from "@/assets/images/surveyAdmin/completed.svg";
import RejectedIcon from "@/assets/images/surveyAdmin/rejected.svg";
import ReviewedIcon from "@/assets/images/surveyAdmin/reviewed.svg";
import CtrlLeftIcon from "@/assets/images/ctrl-left.svg";

export default defineComponent({
  name: "WelcomeSurvey",
  props: ["surveyID"],
  components: {
    logo,
    CompletedIcon,
    RejectedIcon,
    ReviewedIcon,
    CtrlLeftIcon,
  },
  data() {
    return {
      homePath: router.home.path,
      reviewPath: router.reviewSurvey.path,
      reviewName: router.reviewSurvey.name,
      dashboardPath: router.dashboard.path,
      ready: false,
      loading: false,
      page: {
        page: 1,
        size: 1,
        total: 0,
      },
      info: {
        title: "",
        section: null,
        founder: null,
        stt: null,
      },
      fWidth: 0,
      detailSurveyId: null,
      readyLoadsurvey: false,
      sectionsStep: {
        currentStep: 0,
        maxStep: 0,
        sections: null,
      },
      dealFounderPath: router.dealFounder.path,
      dealFounderName: router.dealFounder.name,
    };
  },
  computed: {
    profile: function () {
      return appStore.state.profile;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    if (this.profile.userType === USER_TYPE.FOUNDER_POTENTIAL)
      this.$router.push({ path: this.dealFounderPath });

    this.getWelcomeSurvey();
  },
  methods: {
    loadPage(value) {
      this.page.page = value;
    },
    checkActivebox(id, list, type) {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          flag = true;
        }
      }
      return flag;
    },
    parseValue(id, list) {
      let str = null;
      for (let i = 0; i < list.length; i++) {
        if (id === list[i].Id) {
          str = list[i].Answer;
        }
      }
      return str;
    },
    surveyAnwser(id) {
      AdminServices.surveyAnwser(id, data).then((resp) => {
        if (resp.data.ok) {
          this.getSurveyReviewDetails(this.surveyID);
        }
      });
    },
    check(q, a, ref) {
      if (!ref) {
        let flag = true;
        q.answers.map((p, idx) => {
          if (p.Id === a.Id && p.Answer === a.Answer) {
            q.answers.splice(idx, 1);
            flag = false;
          }
        });
        if (flag) {
          q.answers.push({ Id: a.Id, Answer: a.Answer });
        }
        this.submitAnswer(this.detailSurveyId, [
          { id: q.id, answers: q.answers },
        ]);
      } else {
        $(this.$refs[ref]).each(function () {
          this.querySelector("label").classList.remove("active");
          if (parseInt(this.getAttribute("id")) === a.Id) {
            this.querySelector("label").classList.add("active");
          }
        });
        this.submitAnswer(this.detailSurveyId, [
          { id: q.id, answers: [{ Id: a.Id, Answer: a.Answer }] },
        ]);
      }
    },
    othercheck(q, a, ref) {
      $(this.$refs[ref]).prop("disabled", !$(event.target).prop("checked"));
      if ($(event.target).prop("checked")) {
        this.$refs[ref][0].value = "";

        let parent = this.$refs[ref][0].offsetParent;
        parent.querySelector("label").classList.add("active");
        // q.answers.push({ Id: a.Id, Answer: a.Answer })
        q.answers.push({ Id: a.Id, Answer: "" });
      } else {
        this.$refs[ref][0].value = "";

        let parent = this.$refs[ref][0].parentElement;
        parent.querySelector("label").classList.remove("active");
        this.inputChange(ref, q, a);
      }
    },
    inputChange: function (ref, q, a) {
      let flag = true;
      q.answers.map((p, idx) => {
        if (p.Id === a.Id) {
          if (this.$refs[ref][0].value) {
            p.Answer = this.$refs[ref][0].value;
          } else {
            q.answers.splice(idx, 1);
          }
          flag = false;
        }
      });
      if (flag) {
        q.answers.push({ Id: a.Id, Answer: this.$refs[ref][0].value });
      }
      this.submitAnswer(this.detailSurveyId, [
        { id: q.id, answers: q.answers },
      ]);
    },
    updateFile(ref, q) {
      let inputfile = this.$refs[ref][0];
      q.answers = [];
      q.answers.push({
        Name: inputfile.files.item(0).name,
        S3Key: inputfile.files.item(0).name,
      });
      let formData = new FormData();
      formData.append("ContentFile", inputfile.files.item(0));
      AdminServices.surveyAnwserFile(this.detailSurveyId, q.id, formData);
    },
    textChange: function (ref, q) {
      if (this.$refs[ref][0].value) {
        q.answers[0] = this.$refs[ref][0].value;
      } else {
        q.answers = [];
      }
      this.submitAnswer(this.detailSurveyId, [
        { id: q.id, answers: q.answers },
      ]);
    },
    checkInputValue(a, b) {
      let value = null;
      let status = false;
      a.map((p) => {
        if (p.Id === b.Id) {
          value = p.Answer;
          status = true;
        }
      });
      return { status: status, value: value };
    },
    submitAnswer(id, data) {
      this.updateAnswer(data);
      AdminServices.surveyAnwser(id, data).then((resp) => {
        if (resp.data.ok) {
          // console.log('done')
        }
      });
    },
    updateAnswer(data) {
      let item = this.info.section[
        this.sectionsStep.currentStep
      ].questions.find((p) => p.id === data[0].id);
      if (item.type === "ONE") {
        item.answers = data[0].answers;
      }
    },
    getWelcomeSurvey() {
      this.loading = true;
      this.readyLoadsurvey = true;
      AdminServices.getSurveyWelcome().then((resp) => {
        if (resp.data.ok) {
          if (resp.data.d.stt === "APPROVED") {
            this.$router.push({
              path: this.dashboardPath,
            });
          }

          this.info.title = resp.data.d.name;
          this.info.section = resp.data.d.sections;
          this.info.founder = resp.data.d.username;
          this.info.stt = resp.data.d.stt;
          this.page.total = this.info.section.length;
          this.detailSurveyId = resp.data.d.id;
          this.ready = true;
          this.calculateTotalAnsStep(resp.data.d.sections);
        }
        this.loading = false;
      });
    },
    calculateTotalAnsStep(data) {
      this.sectionsStep.maxStep = data.length;
      this.sectionsStep.sections = [];
      data.map((p) => {
        this.sectionsStep.sections.push({
          number: p.questions.length,
          progress: 0,
        });
      });
    },
    previousStep() {
      this.sectionsStep.currentStep -= 1;
      this.loadPage(this.sectionsStep.currentStep + 1);
    },
    nextStep() {
      this.sectionsStep.currentStep += 1;
      this.loadPage(this.sectionsStep.currentStep + 1);
    },
    finishSurvey: function (id) {
      AdminServices.finishSurvey(id).then((resp) => {
        if (resp.data.ok) {
          this.getWelcomeSurvey();
        }
      });
    },
    onResize() {
      this.fWidth = $(this.$refs.popupContent).width();
    },
    logout() {
      appStore.dispatch(AUTH_ACTIONS.logout);
    },
  },
  computed: {
    sumScored: {
      default: 0,
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let score = 0;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let child = list[i].questions;
            for (let j = 0; j < child.length; j++) {
              score += child[j].score;
            }
          }
        }
        return score;
      },
    },
    sectionComplete: {
      default: 0,
      get: function () {
        let list = _.cloneDeep(this.info.section)[
          this.sectionsStep.currentStep
        ];
        this.sectionsStep.sections[this.sectionsStep.currentStep].progress = 0;
        list.questions.map((p) => {
          if (p.answers.length) {
            for (let i = 0; i < p.answers.length; i++) {
              if (typeof p.answers[i] === "object" && p.answers[i].Answer) {
                this.sectionsStep.sections[
                  this.sectionsStep.currentStep
                ].progress += 1;
              }
              if (typeof p.answers[i] !== "object") {
                this.sectionsStep.sections[
                  this.sectionsStep.currentStep
                ].progress += 1;
              }
            }
          }
        });
        return true;
      },
    },
    countQuestionInfo: {
      default: {
        ans: 0,
        total: 0,
      },
      get: function () {
        let list = _.cloneDeep(this.info.section);
        let obj = {
          ans: 0,
          total: 0,
        };
        if (list) {
          for (let i = 0; i < list.length; i++) {
            let q = list[i].questions;
            obj.total += q.length;
            for (let j = 0; j < q.length; j++) {
              if (q[j].answers.length) {
                obj.ans += 1;
              }
            }
          }
        }
        return obj;
      },
    },
    profile() {
      return appStore.state.profile;
    },
    deviceVersion() {
      return appStore.state.mobile.version;
    },
  },
  watch: {
    ready: function (v) {
      if (v) {
        setTimeout(() => {
          this.onResize();
        }, 1000);
      }
    },
    profile: {
      handler: function (v) {
        // if (v.type === 'Founder' && v.welcome_survey_stt === 'NOT_STARTED' || v.type === 'Founder' && v.welcome_survey_stt === 'IN_PROGRESS' || v.type === 'Founder' && v.welcome_survey_stt === 'COMPLETED' || v.type === 'Founder' && v.welcome_survey_stt === 'REVIEWING' || v.type === 'Founder' && v.welcome_survey_stt === 'REJECTED') {
        //     this.getWelcomeSurvey()
        // }
        if (
          v &&
          v.welcome_survey_stt !== "NOT_STARTED" &&
          v.welcome_survey_stt !== "IN_PROGRESS" &&
          v.welcome_survey_stt !== "COMPLETED" &&
          v.welcome_survey_stt !== "REVIEWING" &&
          v.welcome_survey_stt !== "REJECTED"
        ) {
          if (this.surveyID) {
            this.getSurveyReviewDetails(this.surveyID);
          }
        } else {
          if (!this.readyLoadsurvey) {
            this.getWelcomeSurvey();
          }
        }
      },
      deep: true,
    },
  },
});
