<template>
  <div class="modal fade" :class="{ show: _value }" v-if="_value">
    <div class="overlay" @click="_value = false" />
    <div :class="`modal-dialog modal-dialog-centered`">
      <div class="modal-content">
        <div :class="`modal-header`">
          <img class="img-logo-white" src="../../assets/images/logo_FVC.png" />
          <i class="close-btn far fa-times-circle" @click="_value = false"></i>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <div>
            <div class="line"></div>
          </div>
          <div class="col-12">
            <div class="fvc-footer">
              <img
                class="img-logo"
                src="../../assets/images/logo_FVC_black.png"
              />
            </div>
            <div class="fvc">© Fuel Venture Capital</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./FormModal.js"></script>

<style lang="scss" scoped>
.modal {
  padding: 0 !important; // override inline padding-right added from js
  color: #838894;
  background: rgba($color: #000, $alpha: 0);

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-body {
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #747474;
      border-radius: 4px;
      transition: all 300ms;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #909090;
      border-radius: 4px;
      transition: all 300ms;
    }
  }
  .modal-size-lg {
    max-width: 900px;
  }
  .modal-size-xl {
    max-width: 100%;
  }
  .modal-header {
    position: relative;
    color: red;
    background: #000;
    // border: 0 !important;
    border-bottom: 1px solid rgba(225, 225, 225, 0.2);
    justify-content: left;
    padding: 1rem;
  }
  .modal-direction-left {
    position: relative;
    justify-content: flex-end;
  }

  .modal-body {
    padding: 1rem;
    background: #ffff;
    @media only screen and (max-width: 575px) {
      padding: 1rem;
    }
  }

  .modal-footer {
    //border: 0;
    padding: 0 1rem 1rem 1rem;
    justify-content: center;
    background: #ffffff;
    border-bottom: 1px solid rgba(225, 225, 225, 0.2);
  }

  &.show {
    display: block;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close-btn {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  cursor: pointer;
  font-size: 25px;
}
.img-logo-white {
  width: 10%;
}
.img-logo {
  margin-top: 20px;
  width: 10%;
}

.line {
  border-bottom: 1px solid red !important;
  align-content: center;
  display: block !important;
}
.fvc-footer {
  display: flex;
  justify-content: center;
}
.fvc {
  font-size: 0.8rem;
  align-items: center;
  padding-top: 3px;
  display: flex;
  justify-content: center;
}
</style>
