<template>
  <div class="adminContent p-3">
    <div class="data p-3">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label>From</label>
            <Datepicker
              :format="customFormatter"
              v-model:value="tmpFromDate"
              field="black"
            ></Datepicker>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label>To</label>
            <Datepicker
              :format="customFormatter"
              v-model:value="tmpToDate"
              field="black"
            ></Datepicker>
          </div>
        </div>
        <div class="col-md-2">
          <label class="empty">{{ ` ` }}</label>
          <button class="btn btn-primary" @click="getGuestMember">
            Search
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12">
          <Table
            :paging="paging"
            @updatePaging="updatePaging"
            :columns="columns"
            :rows="listData"
            :total-items="totalItems"
            :showNoRow="true"
            v-loading="loading"
            useSimpleLayout
          >
            <template #row_name="{ props }">
              {{ props.name }}
            </template>

            <template #row_email="{ props }">
              {{ props.email }}
            </template>

            <template #row_phone="{ props }">
              {{ props.phone }}
            </template>

            <template #row_created="{ props }">
              {{ $filters.parseEstTime(props.created_at) }}
            </template>

            <template #row_signature>
              <a
                href="#"
                class="pointer"
                @click.prevent="downloadSig(item.signed_url)"
                >Download</a
              >
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
label {
  &.empty {
    width: 100%;
  }
}
.guest-table {
  .header {
  }
  .body {
    .dataRow {
      > div {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.customButton {
  /* margin-top: 10px; */
}
</style>
<script src="./index.js"></script>
