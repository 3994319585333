<template>
  <transition name="fade">
    <div class="modal confirm">
      <div class="mask" @click="closeModalConfirm"></div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title w-100 text-center colorWhite initialism font-24"
            >
              {{ title }}
            </h5>
            <button type="button" class="close" @click="closeModalConfirm">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <p>{{ message }}</p>
          </div>
          <div class="modal-footer text-center">
            <button
              type="button"
              class="btn btn-primary btn-small"
              @click="onConfirm"
            >
              Confirm
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-small"
              @click="closeModalConfirm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
.modal.confirm {
  display: block;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .close {
    color: #fff;
  }
  .modal-header {
    background-color: #3b7a9a;
    padding: 5px 10px;
  }
  .modal-body {
    color: #ffffff;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .modal-footer {
    display: block;
  }
}
</style>
