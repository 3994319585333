import AdminServices from "../../../services/admin";
import router from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      listData: [],
      paging: {
        page: 1,
        size: 10,
        total: 0,
      },
      optionTypeSurvey: [
        {
          name: "Completed",
          key: "COMPLETED",
        },
        {
          name: "Reviewing",
          key: "REVIEWING",
        },
        {
          name: "Rejected",
          key: "REJECTED",
        },
        {
          name: "Approved",
          key: "APPROVED",
        },
        {
          name: "In Progress",
          key: "IN_PROGRESS",
        },
      ],
      typeSurvey: null,
      viewSurveyPath: router.viewSurvey.path,
    };
  },
  mounted() {
    if (!this.typeSurvey) {
      this.typeSurvey = this.optionTypeSurvey[0];
    }
    this.getData();
  },
  methods: {
    getData: function () {
      AdminServices.getSurveyFinished({
        status: this.typeSurvey,
        page: this.paging.page,
        size: this.paging.size,
      }).then((resp) => {
        if (resp.data.ok) {
          this.listData = resp.data.d.c;
          this.paging.page = resp.data.d.p;
          this.paging.size = resp.data.d.s;
          this.paging.total = resp.data.d.t;
        }
      });
    },
    changeType: function (item) {
      this.typeSurvey = item;
      this.paging.page = 1;
      this.paging.size = 10;
      this.paging.total = 0;
      this.getData();
    },
    loadPage: function (value) {
      this.paging.page = value;
      this.getData();
    },
  },
});
