<template>
  <div class="h-100 container-fluid" v-if="useEmptyLayout">
    <div class="row no-gutters h-100">
      <div class="close-page" @click.prevent="close">
        <i class="pe pe-7s-close"></i>
      </div>
      <div class="col-lg-6 dark-bg">
        <div class="loading" v-if="loading">
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else class="row justify-content-center content-section">
          <div
            class="col-lg-9 align-items-center justify-content-center detailLoginWrap"
          >
            <div class="d-flex align-items-center mt-3">
              <router-link :to="homePath" class="logoLogin">
                <LogoIcon sizeWidth="200px" />
              </router-link>
            </div>

            <div class="w-45 wrapper">
              <div class="login-type mt-3">
                <div class="boxTitle">
                  <p class="requireText colorText">
                    Please select your login portal.
                  </p>
                </div>
                <div class="accounType">
                  <div class="row">
                    <div class="col-4">
                      <div
                        @click.prevent="changeAccountType(AccountType.Founder)"
                        class="btn btnAccountType w-100"
                        :class="[
                          currentAccountType === AccountType.Founder
                            ? 'active'
                            : '',
                        ]"
                      >
                        <div class="d-flex flex-column align-items-center">
                          <span class="iconAccountType mb-lg-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 22.582 22.548"
                            >
                              <g id="star" transform="translate(0.509 0.476)">
                                <g
                                  id="Group_91"
                                  data-name="Group 91"
                                  transform="translate(0.001 0.024)"
                                >
                                  <path
                                    id="Path_13987"
                                    data-name="Path 13987"
                                    d="M20.991,7.721l-6.63-1.014L11.4.373a.7.7,0,0,0-1.213,0L7.218,6.708.572,7.721a.674.674,0,0,0-.38,1.136l4.816,4.937L3.869,20.773a.674.674,0,0,0,.991.7L10.782,18.2,16.7,21.472a.674.674,0,0,0,.991-.7L16.556,13.8l4.816-4.937a.674.674,0,0,0-.38-1.136Z"
                                    transform="translate(-0.001 -0.024)"
                                    fill="#3e99ed"
                                    stroke="#3e99ed"
                                    stroke-width="1"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                          <span>Founders</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div
                        @click.prevent="changeAccountType(AccountType.Investor)"
                        class="btn btnAccountType w-100"
                        :class="[
                          currentAccountType === AccountType.Investor
                            ? 'active'
                            : '',
                        ]"
                      >
                        <div class="d-flex flex-column align-items-center">
                          <span class="iconAccountType mb-lg-2">
                            <svg
                              id="handshake"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 15.998"
                            >
                              <g id="Group_92" data-name="Group 92">
                                <path
                                  id="Path_13989"
                                  data-name="Path 13989"
                                  d="M16,1a1,1,0,0,0-1-1H10.45A4.47,4.47,0,0,0,7.268,1.318L3.293,5.293a1,1,0,0,0,0,1.414,2.6,2.6,0,0,0,3.437.214l2.687-2.09L13.61,9.024a1.2,1.2,0,0,1-.069,1.761l-.566.483L11.354,9.646a.5.5,0,0,0-.708.708l1.566,1.565-.855.731-1.5-1.5a.5.5,0,1,0-.708.708L10.594,13.3l-.138.118a1.988,1.988,0,0,1-1.824.407,1.981,1.981,0,0,0-.57-1.24L4.477,9a2,2,0,0,0-2.829,2.828l3.586,3.586a2,2,0,0,0,2.542.234,4.017,4.017,0,0,0,1.376.253,3.977,3.977,0,0,0,2.608-.965l3.08-2.63a3.108,3.108,0,0,0,1.16-2.3Z"
                                  fill="#3e99ed"
                                />
                                <path
                                  id="Path_13990"
                                  data-name="Path 13990"
                                  d="M2,7V2H5A1,1,0,1,0,5,0H1A1,1,0,0,0,0,1V7A1,1,0,1,0,2,7Z"
                                  fill="#3e99ed"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Limited Partners</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div
                        @click.prevent="changeAccountType(AccountType.Rokker)"
                        class="btn btnAccountType w-100"
                        :class="[
                          currentAccountType === AccountType.Rokker
                            ? 'active'
                            : '',
                        ]"
                      >
                        <div class="d-flex flex-column align-items-center">
                          <span class="iconAccountType mb-lg-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 20 20"
                            >
                              <text
                                id="_F_"
                                data-name="(F)"
                                transform="translate(10 16)"
                                fill="#3e99ed"
                                font-size="15"
                                font-family="SegoeUI-Bold, Segoe UI"
                                font-weight="700"
                              >
                                <tspan x="-9.437" y="0">(F)</tspan>
                              </text>
                            </svg>
                          </span>
                          <span>Fuel Venture Capital</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form id="loginForm" v-on:keyup.enter="login">
                <template v-if="currentAccountType">
                  <div class="boxTitle">
                    <p class="requireText colorText m-0">
                      Please enter your credentials to proceed.
                    </p>
                  </div>

                  <TextField
                    name="email"
                    :indexField="1"
                    :autocomplete="true"
                    :fieldRequire="true"
                    v-model:value="model.email"
                    placeholder="Enter your Email *"
                    class="m-0"
                    :errorMsg="messageError.email"
                  />

                  <TextField
                    name="password"
                    :indexField="2"
                    :fieldRequire="true"
                    iconPasswordColor="white"
                    v-model:value="model.password"
                    placeholder="Enter your Password *"
                    class="m-0"
                    type="password"
                    :errorMsg="messageError.password"
                  />

                  <div class="form-group text-center">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <router-link
                        class="link"
                        style="margin-bottom: 8px"
                        :to="forgotPasswordPath"
                        >Forgot password?</router-link
                      >
                    </div>
                  </div>
                  <div class="form-group text-center mb-lg-0 mt-lg-4 pt-lg-1">
                    <button
                      @click.prevent="login"
                      class="btn button btn-large btn-primary btn-bounce btn-active w-100 btnReg"
                      tabindex="3"
                    >
                      Login
                    </button>
                  </div>
                  <!-- <div class="text-center mt-1">
                    <span class="colorGray opa-5 mr-b-10 des-ita">Dont’ have an account?</span>
                    <router-link class="link" :to="registerPath">Sign up</router-link>
                  </div> -->
                </template>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 login-section px-0">
        <div class="boxFix backgroundLogin"></div>
      </div>
    </div>
    <verify-2fa-modal ref="verify2FaModal" />
  </div>
</template>
<script src="./index.js"></script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 100;
}
.colorText {
  color: #8798ad;
}
.iconAccountType {
  width: 50px;
  height: 50px;
  background-color: #dfe6ff;
  border: 1px solid #dfe6ff;
  border-radius: 50%;
  padding: 15px;
}

.md-field {
  margin: 4px 0 0px;
}

.colorDarkGray {
  color: #2e384d;
}
.colorGray {
  color: #b0bac9;
}
.logoLogin {
  img {
    object-fit: cover;
    width: 190px;
    height: auto;
  }
}
// .wrapper {
//   background-color: #fff;
// }

.login-type {
  .accounType {
    .row {
      margin-left: -10px;
      margin-right: -10px;
      [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
      }
      .btn {
        font-size: 0.875rem;
        &.btnAccountType {
          height: 100%;
          background-color: transparent;
          border: 1px solid #e0e7ff;
          padding: 1rem;
          &:hover {
            background: #37393e;
            color: white;
            cursor: pointer;
          }
          &.active {
            border-color: #3e99ed;
            &:before {
              font-family: "Font Awesome 6 Free";
              font-weight: 900;
              content: "\F00C";
              display: inherit;
              color: #fff;
              position: absolute;
              top: 0;
              right: -12.5px;
              background-color: #3e99ed;
              border-radius: 50%;
              display: inline-block;
              width: 25px;
              height: 25px;
              line-height: 25px;
              -ms-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}
#loginForm {
  font-size: 16px;
  padding: 0;
  margin-top: 1.5rem;
  //color: #2e384d;
  //opacity: 0.8;
  //background-color: #fff;
  .signInText {
    font-size: 34px;
    // line-height: 34px;
  }
}
.detailLogin {
  overflow: hidden;
  width: 100%;
}
@media (max-width: 991px) {
  .detailLogin {
    margin: 0;
    .detail {
      padding: 20px 0 0 0;
      > div {
        margin: 0;
      }
      .detailLoginWrap {
        padding: 20px;
      }
    }
    .cover {
      display: none;
    }
  }
}
@media (max-width: 767.98px) {
  #loginForm {
    .signInText {
      font-size: 26px;
      margin-bottom: 0;
    }
  }
}
</style>
