<template>
  <transition name="fade">
    <div class="modal custom-modal" role="dialog">
      <div class="mask" @click="hideViewSharedItems"></div>
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">View Shared Dataroom Items</h4>
          </div>
          <div
            class="modal-body custom-modal-body"
            v-loading="loading"
            v-if="!loadingInitial"
          >
            <div class="row mb-2 justify-content-center">
              <div class="col col-md-8">
                <selectUser
                  :options="users"
                  label="Please select user"
                  @selected="(o) => loadTree(o)"
                />
              </div>
            </div>
            <hr />
            <div class="adminContent" v-if="rawTree">
              <div class="treeList me-2">
                <div
                  class="data-content py-2 pr-2 data-tree content-left-scrollable"
                >
                  <ul class="mainTree">
                    <tree-item
                      class="item"
                      :item="treeData"
                      @select-folder="selectFolder"
                      :selectedId="selectedFolderId"
                      :selectedParentId="selectedParentId"
                    />
                  </ul>
                </div>
              </div>
              <div class="treeDetailContent">
                <div class="data-content px-2 py-2">
                  <div class="path-section">
                    <div class="truncate">
                      <span v-for="(item, idx) in parentPath" :key="idx">
                        <i
                          v-if="idx > 0"
                          class="fas fa-chevron-right mx-1 breadcrum-next-icon"
                        ></i
                        ><span
                          v-if="idx < parentPath.length - 1"
                          class="noselect path-item"
                          @click="selectFolder(item)"
                          >{{ item.name }}</span
                        ><span v-else class="noselect path-item">{{
                          item.name
                        }}</span>
                      </span>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <div class="row">
                    <div class="col-12 content-scrollable dataroom-content">
                      <div
                        v-for="(item, idx) in selectedChildrenItems"
                        :key="idx"
                        @click="oneClick(item)"
                        class="dataroom-item"
                        :class="{ selectable: item.type === 'Folder' }"
                      >
                        <div>
                          <span
                            :style="{
                              color: getDataRoomItemIconColor(
                                item.type,
                                item.current_extension
                              ),
                            }"
                            ><i
                              class="item-icon"
                              :class="
                                getDataRoomItemIconClass(
                                  item.type,
                                  item.current_extension
                                )
                              "
                            ></i></span
                          ><span class="noselect">{{ item.name }}</span>
                          <div v-if="item.parent_id === 0" class="sub-text">
                            <span>FVC</span>
                            <span
                              v-for="(folder, index) in item.folder_path"
                              :key="index"
                            >
                              <i class="fas fa-chevron-right mx-1"></i>
                              {{ folder }}
                            </span>
                            <div v-if="item.shared_by_id" class="sub-text">
                              Shared by {{ item.shared_by_fname }}
                              {{ item.shared_by_lname }} on
                              {{ $filters.parseEstTime(item.shared_on) }}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="btn btn-default btn-small"
                          @click="goToItemLocation(item)"
                        >
                          <i class="fas fa-compress"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button class="btn btn-secondary" @click="hideViewSharedItems">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.mask {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.custom-modal .modal-dialog.modal-xl {
  max-width: 85%;
}
.custom-modal-body {
  overflow: hidden;
  overflow-y: auto;
  display: block;
  height: calc(100vh - 140px);
  padding-top: 0.3rem;
}
.adminContent {
  display: flex;
  .treeList {
    flex: 0 0 300px;
    max-width: 300px;
  }
  .treeDetailContent {
    flex: 1;
  }
}
ul {
  line-height: 1.5;
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
  }
}
.data-content {
  min-height: calc(100vh - 300px);
  background-color: $color-background;
  display: block;
  border-radius: 3px;
  color: $text-color;
  height: 100%;
}
.content-scrollable {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}
.content-left-scrollable {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

.data-section-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #409eff;
}
.item-row {
  font-size: 0.9rem;
  .item-icon {
    width: 20px;
    display: inline-flex;
  }
}
.btn-more-action {
  min-width: 29px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  .breadcrum-next-icon {
    font-size: 14px;
  }
}
.sub-text {
  font-size: 11px;
  color: $text-color--secondary;
}

.path-section {
  display: flex;
  align-items: center;
  .path-item {
    font-size: 16px;
    padding: 6px 12px 6px;
    border-radius: 8px;
    line-height: 26px;
    cursor: pointer;
    display: inline-block;
    padding: 1px 12px;
    &:hover {
      background-color: $color-grey;
      color: $color-dark;
    }
    i {
      color: #5f6368;
    }
  }
  .path-action {
    .dropdown-menu {
      background-color: $color-background-tertiary;
      .dropdown-item {
        color: $color-grey;
        &:hover {
          background-color: $color-grey;
          color: $color-dark;
        }
      }
    }
  }
}
.dataroom-content {
  font-size: 14px;
  font-weight: 200;
  padding: 0px 10px;
  .dataroom-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 1rem;
    line-height: 1.3;
    &.selectable {
      cursor: pointer;
      &:hover {
        background-color: $color-dark;
      }
    }
  }
}
.item-icon {
  width: 22px;
}
</style>
