<template>
  <LPFundModal />
  <div class="adminContent p-3">
    <div class="data mb-3" v-loading="loading">
      <h5 class="m-0 p-3">PERSONAL DATA</h5>
      <form class="px-3">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <TextField
                :title="`Email address`"
                name="email"
                readonly
                disabled
                v-if="userProfile && userProfile.email"
                v-model:value="userProfile.email"
              />
            </div>
          </div>
          <div class="col-4"></div>
          <div class="col-4"></div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <TextField
                :title="`First name`"
                name="fname"
                fieldRequire
                :errorMsg="errorMsg.firstName"
                v-if="userProfile"
                v-model:value="userProfile.firstName"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <TextField
                :title="`Last name`"
                name="lname"
                :errorMsg="errorMsg.lastName"
                fieldRequire
                v-if="userProfile"
                v-model:value="userProfile.lastName"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <Datepicker
                title="Birth date"
                name="bdate"
                v-model:value="tmpDate"
                :errorMsg="errorMsg.birthDate"
                field="black"
              />
            </div>
          </div>
          <div class="col-4" v-if="checkUserTypeRokker()">
            <div class="form-group">
              <NumberField
                :title="`Phone Number`"
                name="number"
                :type="`number`"
                :field="`dark`"
                :errorMsg="errorMsg.phoneNumber"
                v-model:value="userProfile.phone"
              />
            </div>
          </div>
          <div class="col-4" v-if="checkUserTypeRokker()">
            <div class="form-group">
              <SelectField
                :type="1"
                :title="`Phone Carrier`"
                v-model:value="userProfile.phonecarrierid"
                :options="phoneCarrierId"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary pointer mb-3"
              @click.prevent="updateUserProfile"
            >
              Save changes
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="data" v-loading="loading">
      <h5 class="m-0 p-3">PASSWORD</h5>
      <form class="px-3">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <TextField
                type="password"
                :title="`Current password`"
                name="cpass"
                fieldRequire
                :errorMsg="errorMsg.currentPassword"
                v-if="userProfile"
                v-model:value="userPassword.currentPassword"
                iconPasswordColor="white"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <TextField
                type="password"
                :title="`New password`"
                name="npass"
                fieldRequire
                :errorMsg="errorMsg.newPassword"
                v-if="userProfile"
                v-model:value="userPassword.newPassword"
                iconPasswordColor="white"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <TextField
                type="password"
                :title="`Confirm`"
                name="cform"
                fieldRequire
                :errorMsg="errorMsg.confirmNewPassword"
                v-if="userProfile"
                v-model:value="userPassword.confirmNewPassword"
                iconPasswordColor="white"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary pointer mb-3"
              @click.prevent="updateUserPassword"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="./index.js"></script>
