import appStore, { APP_MUTATION } from "../store/app";
import router from "@/config/router";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loginPath: router.login.path,
      homePath: router.home.path,
    };
  },
  computed: {
    isAuth() {
      return appStore.state.auth;
    },
    useEmptyLayout: {
      get() {
        return this.$store.state.useEmptyLayout;
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    appStore.commit(APP_MUTATION.use_empty_layout, true);
  },
});
