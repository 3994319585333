import { defineComponent } from "vue";
import moment from "moment";
import { any } from "promise-polyfill";

export default defineComponent({
  name: "Datepicker",
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    value: { type: [Object, Array], default: null },
    hideError: { type: Boolean, default: false },
    errorMsg: { type: [String, Boolean], default: "" },
    placeholder: { type: String, default: "" },
    size: { type: String, default: "" },
    title: { type: String, default: "" },
    mode: { type: String, default: "dateTime" },
    mask: { type: String, default: "" },
    field: { type: String, default: "" },
    fieldRequire: { type: Boolean, default: false },
    minDate: { type: [String, Date], default: null },
    maxDate: { type: [String, Date], default: null },
    ignoreTimeValidation: { type: Boolean, default: true },
    enableTimePicker: { type: Boolean, default: false },
    is24: { type: Boolean, default: true },
    isTimePicker: { type: Boolean, default: false },
    isMonthlyPicker: { type: Boolean, default: false },
    autoApply: { type: Boolean, default: true },
    range: { type: Boolean, default: false },
    multiCalendars: { type: Boolean, default: false },
    escClose: { type: Boolean, default: true },
  },
  emits: ["update:value", "update:errorMsg"],
  data() {
    return {
      test: null,
      popoverOptions: {
        positionFixed: true,
      },
    };
  },
  watch: {
    valueDatePicker(v) {
      this._errorMsg = "";
    },
  },
  computed: {
    _errorMsg: {
      get() {
        return this.errorMsg;
      },
      set(val) {
        this.$emit("update:errorMsg", val);
      },
    },
    disableFromPast() {
      const today = new Date();

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const afterTomorrow = new Date(tomorrow);
      afterTomorrow.setDate(tomorrow.getDate() + 1);

      return [tomorrow, afterTomorrow];
    },
    valueDatePicker: {
      get() {
        if (this.multiCalendars) {
          return [
            this.value && this.value[0]?.months && this.value[0].years
              ? new Date(
                  `${this.value[0].months}/${this.value[0].days}/${this.value[0].years}`
                )
              : new Date(),
            this.value && this.value[1]?.months && this.value[1].years
              ? new Date(
                  `${this.value[1].months}/${this.value[1].days}/${this.value[1].years}`
                )
              : new Date(),
          ];
        } else {
          if (this.isMonthlyPicker && this.value?.month && this.value?.year) {
            return {
              month: this.value.month,
              year: this.value.year,
            };
          }
          if (!this.isTimePicker && this.value?.months && this.value?.years) {
            if (!this.enableTimePicker)
              return new Date(
                `${this.value.months}/${this.value.days}/${this.value.years}`
              );
            else {
              return new Date(
                `${this.value.months}/${this.value.days}/${this.value.years} ${this.value.hours}:${this.value.minutes}`
              );
            }
          }
        }
        return null;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
    valueTimePicker: {
      get() {
        if (this.isTimePicker && this.value?.hours) {
          return this.value;
        }
        return null;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
  },
  methods: {
    handleDate(modelValue) {
      var obj = {};
      if (this.multiCalendars) {
        obj = [
          {
            days: moment(modelValue[0]).format("DD"),
            months: moment(modelValue[0]).format("MM"),
            years: moment(modelValue[0]).format("YYYY"),
          },
          {
            days: moment(modelValue[1]).format("DD"),
            months: moment(modelValue[1]).format("MM"),
            years: moment(modelValue[1]).format("YYYY"),
          },
        ];
      } else {
        if (!this.isTimePicker) {
          if (this.isMonthlyPicker) {
            obj = {
              month: modelValue.month,
              year: modelValue.year,
            };
          } else {
            obj = {
              days: moment(modelValue).format("DD"),
              months: moment(modelValue).format("MM"),
              years: moment(modelValue).format("YYYY"),
            };
            if (this.enableTimePicker) {
              obj = {
                ...obj,
                hours: moment(modelValue).format("HH"),
                minutes: moment(modelValue).format("mm"),
              };
            }
          }
        } else {
          obj = {
            hours: moment(modelValue).format("HH"),
            minutes: moment(modelValue).format("mm"),
          };
        }
      }
      this.$emit("update:value", obj);
    },
    remove() {
      this._value = null;
    },
    // isNumber: function(e) {
    //     e = (e) ? e : window.event;
    //     var charCode = (e.which) ? e.which : e.keyCode
    //     if ((charCode > 31 && (charCode < 47 || charCode > 57)) && charCode !== 46) {
    //         e.preventDefault()
    //     } else {
    //         return true
    //     }
    // }
  },
  beforeUnmount() {
    this.valueTimePicker = null;
  },
});
