<template>
  <div>
    <metainfo></metainfo>

    <div sticky-container>
      <template
        v-if="
          currentRouter.meta?.robotic ||
          currentRouter.meta?.signnda ||
          currentRouter.meta?.static
        "
      >
        <router-view :key="$route.fullPath" />
        <!-- <router-view /> -->
      </template>

      <template v-else>
        <div v-if="appInited">
          <div
            class="wrapper"
            v-if="!useEmptyLayout"
            :class="[
              lowercase(currentRouter.name),
              { admin: currentRouter.meta?.auth },
            ]"
          >
            <template v-if="!currentRouter.meta?.auth">
              <Header />
              <main class="" id="page-wrap">
                <div id="mainContent">
                  <router-view :key="$route.fullPath"></router-view>
                  <!-- <router-view></router-view> -->
                </div>
              </main>
              <Footer />
            </template>
            <template v-else>
              <SideBar v-if="profile.userType !== 'Investor'" />
              <div id="content">
                <adminTitlePage :currentRouter="currentRouter" />
                <router-view :key="$route.fullPath"></router-view>
                <!-- <router-view></router-view> -->
              </div>
            </template>
          </div>
          <div v-else>
            <section class="eml-wrapper">
              <router-view :key="$route.fullPath"></router-view>
              <!-- <router-view></router-view> -->
            </section>
          </div>
        </div>

        <div id="launching" v-else class="main-loading">
          <div v-loading="!appInited"></div>
        </div>
      </template>

      <customNotify ref="notify"></customNotify>
      <!-- <dialog ref="dialog"></dialog> -->
      <!-- <appLoading /> -->
      <!-- <welcome-popup></welcome-popup> -->
      <!-- <NotificationPopup /> -->
    </div>
  </div>
</template>

<script type="module" src="./index.js"></script>

<!-- App styles -->
<style lang="scss" scoped>
.main-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

// .white-theme{
//     .eml-wrapper{
//         background-image: url('../../assets/images/backgroundLogin.png')
//     }
// }
</style>
