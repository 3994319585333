import { defineComponent } from 'vue'
import _ from "lodash";
import adminServices from "@/services/admin";
import notify from "@/services/notify";
import Modal from "@/components/Modal";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import NewButton from "@/components/NewButton";

export default defineComponent({
  name: "MapchartModel",
  emits: ['refresh'],
  components: {
    Modal,
    TextField,
    SelectField,
    NewButton,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      state: "Update",
      mapId: -1,
      errorMsg: {
        groupDataValue: null,
        groupDataText: null,
      },
      groupName: "",
      chartData: [],
      groupData: {},
      isCount: false,
    };
  },
  watch:{
    "groupData.value"(e) {
      this.setInputFilter(this.groupData.value)
    }
  },
  methods: {
    setInputFilter(value) {
        return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    },
    showUpdate(info) {
      this.isShow = true;
      this.mapId = info.map_id;
      if (info.group_name == "regionchart" || info.group_name == "stagechart") {
        this.groupName = info.group_name;
        this.chartData = JSON.parse(info.param_value);
      } else {
        this.groupName = info.group_name;
        this.groupData = JSON.parse(info.param_value);
      }
    },
    save() {
      if (!this.validData()) return;
      this.updateMapchart();
    },
    async updateMapchart() {
      this.loading = true;
      let data = {};
      if (this.groupName == "regionchart" || this.groupName == "stagechart") {
        data = { paramValue: JSON.stringify(this.chartData) };
      } else {
        data = { paramValue: JSON.stringify(this.groupData) };
      }
      const res = await adminServices.updateMapchart(this.mapId, data);
      if (res.data.ok) {
        notify.success("Update successfully!");
        this.$emit("refresh");
        this.isShow = false;
      } else {
        notify.error("Can't update!");
      }
      this.loading = false;
    },
    validData() {
      let flag = true;
      if (this.groupName == "regionchart" || this.groupName == "stagechart") {
        let count = 0;
        this.chartData.forEach((e) => {
          if (e.category == "") return (flag = false);
          if (e.value == "") return (flag = false);
          count = count + parseFloat(e.value);
        });
        if (count < 100 || count > 100)
        {
          this.isCount = true;
          return flag = false;
        }
      } else {
        if (this.groupData.value.trim() === "") {
          this.errorMsg.groupDataValue = "Please input value";
          flag = false;
        }
        if (this.groupData.text && this.groupData.text.trim() === "") {
          this.errorMsg.groupDataText = "Please input text";
          flag = false;
        }
      }
      return flag;
    },
  },
  watch: {},
});