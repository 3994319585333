<template>
    <transition name="fade">
        <div class="modal custom-modal" role="dialog">
            <div class="mask" @click="hideRenameItem"></div>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h4 class="modal-title">Rename Dataroom Item</h4>
                    </div>
                    <div class="modal-body" v-if="dataInfo" v-loading="loading">
                        <template v-if="sameNameFiles && sameNameFiles.length > 0">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center">
                                    <span style="font-size: 15px;">This file name is also available at: </span>
                                </div>
                                <div class="col-12">
                                    <ul class="list-group constraint-list">
                                        <li class="list-group-item card-item"
                                            v-for="(_item, idx) in sameNameFiles"
                                            :key="idx">
                                            <div>
                                                <span>FVC</span>
                                                <span v-for="(folder, index) in _item.folder_path" :key="index">
                                                    <i class="fas fa-chevron-right mx-1"></i>
                                                    {{folder}}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <hr class="my-2"/>
                        </template>
                        <form @submit.prevent="validData" autocomplete="false">
                            <div class="row mb-2 align-items-end">
                                <div class="col col-6">
                                    <div class="form-group">
                                        <label for="newName" class="mb-0">Name</label>
                                        <input type="text" class="form-control" id="newName" autocomplete="off" v-model="form.newName" />
                                        <p class="m-0" v-if="errorMsg.newName">
                                            <small class="colorRed">{{errorMsg.newName}}</small>
                                        </p>
                                    </div>                                
                                </div>
                                <div class="col col-6" v-if="dataInfo.type === 'File' && sameNameFiles && sameNameFiles.length > 0">
                                    <div class="form-group">
                                        <label class="pointer mb-0">
                                            <input type="checkbox" v-model="form.renameAll">
                                            <span class="ml-1 noselect">Apply all</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-secondary" @click="hideRenameItem">Cancel</button>
                        <button class="btn btn-primary" @click="validData">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script src="./index.js"></script>

<style lang="scss" scoped>
@import "@/assets/styles/variables_dark";

.mask{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.constraint-list {
    max-height: 100px;
    overflow-y: auto;
}
.list-group-item {
    border: none;
    background-color: transparent;
    &:not(:first-child) {
        border-top: 1px solid $color-default;
    }
    padding: .35rem 1.25rem;
}
.card-item {
    font-size: 13px;
    font-weight: 200;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $text-color;
    .doc-type-icon {
        font-size: 1rem;
        margin-right: 0.75rem;
    }
    .icon {
        cursor: pointer;
        margin-left: auto;
    }
    .version {
        color: $text-color--tertiary;
    }
    .small {
        font-size: 12px;
        color: $text-color--tertiary;
    }
    .sub-footer-item {
        color: #949ba2;
    }
    &.active {
        color: $color-primary;
    }
}
</style>