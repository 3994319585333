import conf from "../../../config";
import auth from "../../../services/auth";
import notify from "../../../services/notify";
import appStore, { AUTH_ACTIONS } from "../../../store/app";
import { copyToClipboard, handleErrors } from "../../../misc/commons";
import { BUS_TYPE } from "../../../misc/bus-type";
import { defineComponent } from "vue";
import TextField from "@/components/TextField";

export default defineComponent({
  name: "enable-2fa-modal",
  props: {
    status2faLogin: Boolean,
    close: Function,
  },
  components: {
    TextField,
  },
  data() {
    return {
      loading: false,
      appUrl: {
        android: conf.ga_url_android,
        appStore: conf.ga_url_app_store,
        windowPhone: conf.ga_url_window_phone,
        desktop: conf.ga_url_desktop,
      },
      deepLink: "",
      key: "",
      code: "",
      password: "",
      success: false,
      backedUp: false,
      copied: false,
      enable2faLogin: false,
      verifyOldCode: "",
    };
  },
  computed: {
    enabled() {
      return appStore.state.profile && appStore.state.profile.twoFaEnabled;
    },
  },
  mounted() {
    $(this.$el).on("hidden.bs.modal", () => {
      if (!this.success) {
        if (!this.enable2faLogin) {
          this.$root.$emit(BUS_TYPE.change_2fa, false);
        } else {
          this.$root.$emit(BUS_TYPE.change_2fa_login, false);
        }
      }
    });
  },
  methods: {
    show(str) {
      this.enable2faLogin = str;
      if (!str) {
        this.fixedBody();
        this.prepare();
      } else {
        $(this.$el).modal("show");
      }
    },
    hide() {
      this.close();
      this.staticBody();
      $(this.$el).modal("hide");
    },
    submit() {
      if (!this.code) {
        notify.error(
          "Enable google TwoFA fail",
          "Authentication code is empty"
        );
      }
      if (!this.key) {
        return;
      }
      this.loading = true;
      if (this.verifyOldCode === this.code) {
        let m = "Google authentication incorrect";
        notify.error(m);
        this.loading = false;
        return;
      }
      auth.enable2Fa(this.code, this.password).then((resp) => {
        if (resp.error || !resp.data) {
          // handleErrors(resp)
        } else if (resp.data) {
          appStore.dispatch(AUTH_ACTIONS.get_user_profile);
          this.success = true;
          this.$root.$emit(BUS_TYPE.change_2fa, true);
          notify.success("Enable Google TwoFA successfully");
        } else {
          // notify.error(resp.msg)
        }
        this.loading = false;
        this.hide();
      });
    },
    prepare() {
      this.backedUp = false;
      this.key = "";
      this.code = "";
      this.password = "";
      this.deepLink = "";
      this.success = false;
      this.loading = true;
      auth.prepare2Fa().then((resp) => {
        if (resp.error) {
          // notify.error(resp.msg)
        } else {
          this.key = resp.data.key;
          this.deepLink = resp.data.deepLink;
          this.loading = false;
          $(this.$el).modal("show");
        }
      });
    },
    copyKey() {
      copyToClipboard(this.$refs.keyEl);
      this.copied = true;
    },
    submit2FALogin() {
      this.loading = true;
      if (this.verifyOldCode === this.code) {
        let m = "Google authentication incorrect";
        notify.error(m);
        this.loading = false;
        return;
      } else {
        auth.enable2FaLogin(this.status2faLogin, this.code).then((resp) => {
          if (resp.data && resp.data.ok) {
            notify.success("Enable Google TwoFA login successfully");
            appStore.dispatch(AUTH_ACTIONS.get_user_profile);
            this.success = true;
            this.$root.$emit(BUS_TYPE.change_2fa_login, true);
            this.hide();
            return;
          } else {
            this.$root.$emit(BUS_TYPE.change_2fa_login, false);
            this.hide();
            this.code = "";
            this.loading = false;
            return;
          }
        });
      }
    },
  },
  beforeUnmount() {
    this.clearScrollHeight();
    this.staticBody();
  },
});
